<div class="slider">
  <div class="slider-bar">
    <ng-content select=".bar"></ng-content>
  </div>
  <div class="slider-controls">
    <div class="from-bar">
      <ng-content select=".bar-from"></ng-content>
      <div class="from-control" (touchstart)="fromSliderActivated()" (mousedown)="fromSliderActivated()">
        <ng-content select=".handle-from"></ng-content>
      </div>
    </div>
    <div class="to-bar">
      <ng-content select=".bar-to"></ng-content>
      <div class="to-control" (touchstart)="toSliderActivated()" (mousedown)="toSliderActivated()">
        <ng-content select=".handle-to"></ng-content>
      </div>
    </div>
  </div>
</div>
