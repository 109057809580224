<a
  class="edit"
  [ngbPopover]="actions"
  popoverClass="actions-popover"
  #p="ngbPopover"
  triggers="manual"
  placement="bottom"
  (click)="p.toggle()">
  <span class="point"></span>
</a>
<ng-template #actions>
  <ng-content></ng-content>
</ng-template>
