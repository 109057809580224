import { createAction, props } from '@ngrx/store';
import { ContactViewModel } from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';
import { CustomFieldValue } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';

export const resetState = createAction('[Contacts] Reset all state');

export const fetchContact = createAction('[Contacts] Fetch guide contacts(or guide-relations-table)');
export const fetchContactSuccess = createAction(
  '[Contacts] Success load contacts',
  props<{ clients: ContactViewModel[] }>()
);

export const addContacts = createAction('[Contacts][Add] Try to add contact', props<{ clients: ContactViewModel[] }>());
export const addContactSuccess = createAction(
  '[Contacts][Add] Contact added',
  props<{ clients: ContactViewModel[] }>()
);
// Edit contact actions
export const editContact = createAction('[Contacts][Edit] Try update contact', props<{ client: ContactViewModel }>());
export const editContactSuccess = createAction(
  '[Contacts][Edit] Contact update success',
  props<{ client: ContactViewModel }>()
);
// Archive contact actions
export const archiveContact = createAction('[Contacts][Archive] archiveClient', props<{ client: ContactViewModel }>());
export const archiveSelectedContacts = createAction(
  '[Contacts][Archive] Archive Selected Contacts',
  props<{ contacts: ContactViewModel[] }>()
);

export const unarchiveSelectedContacts = createAction(
  '[Contacts][Archive] Unarchive Selected Contacts',
  props<{ contacts: ContactViewModel[] }>()
);

export const archiveContactSuccess = createAction(
  '[Contacts][Archive] archiveClientSuccess',
  props<{ client: ContactViewModel }>()
);
// Un archive contact actions
export const unArchiveContact = createAction(
  '[Contacts][Un archive] unArchiveClient',
  props<{ client: ContactViewModel }>()
);
export const unArchiveContactSuccess = createAction(
  '[Contacts][Un archive] unArchiveClientSuccess',
  props<{ client: ContactViewModel }>()
);

export const tagClient = createAction(
  '[Contacts][Stage] Tag client',
  props<{ client: ContactViewModel; tag: string | null }>()
);
export const tagClientSuccess = createAction(
  '[Contacts][Stage] Tag client success',
  props<{ client: ContactViewModel }>()
);

// ## Tags
// ### Attach tag
export const updateTagsToClient = createAction(
  '[Contacts] Update tags to client',
  props<{ client: ContactViewModel; tags: CustomFieldValue[] }>()
);
export const updateTagsToClientSuccess = createAction(
  '✅ [Contacts] Update tags to client',
  props<{ client: ContactViewModel }>()
);
export const updateTagsToClientError = createAction('⛔️ [Contacts] Update tags to client');

export const bulkUpdate = createAction(
  '[Contacts] Bulk update',
  props<{ update: { id: number; [key: string]: unknown }[] }>()
);
export const bulkUpdateSuccess = createAction(
  '[Contacts] Bulk update success',
  props<{ update: { id: number; [key: string]: unknown }[] }>()
);
