<app-stepper
  [steps]="['My account', 'Recipient info', 'Payment']"
  [activeStep]="currentStep"
  i18n-[steps]="@@giftCouponSelectorSteps"></app-stepper>

<!-- eslint-disable -->
<form
  [formGroup]="giftCouponMyInfoForm"
  (ngSubmit)="nextPartOne()"
  class="gift-coupon-form row"
  novalidate
  *ngIf="currentStep == 0">
  <div class="container">
    <div class="row value-head">
      <div class="col col-md-6 col-sm">
        <label i18n="@@chooseValueOfCertificate">Enter gift value</label>
        <input
          type="text"
          [value]="currency.defaultCurrencySign + giftCouponMyInfoForm.controls.value.value"
          class="form-control"
          (change)="valueChanged($event)" />
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col col-12 col-sm">
        <label class="required" for="firstName" i18n="@@yourFirstName">Your first name</label>
        <input
          type="text"
          formControlName="firstName"
          id="firstName"
          class="form-control"
          placeholder="David"
          i18n-placeholder="@@david" />
        <app-form-control-error
          [control]="giftCouponMyInfoForm.controls['firstName']"
          *ngIf="
            giftCouponMyInfoForm.controls['firstName'].errors &&
            giftCouponMyInfoForm.controls['firstName'].errors.required
          "
          i18n="@@firstNameRequired">
          First name is required.
        </app-form-control-error>
        <app-form-control-error
          [control]="giftCouponMyInfoForm.controls['firstName']"
          *ngIf="
            giftCouponMyInfoForm.controls['firstName'].errors &&
            giftCouponMyInfoForm.controls['firstName'].errors.maxlength
          "
          i18n="@@firstNameTooLong">
          Max length is {{ FIRST_NAME_MAX_LENGTH }} characters.
        </app-form-control-error>
      </div>
      <div class="col col-12 col-sm">
        <label class="required" for="lastName" i18n="@@yourLastName">Your last name</label>
        <input
          type="text"
          formControlName="lastName"
          id="lastName"
          class="form-control"
          placeholder="Owen"
          i18n-placeholder="@@owen" />
        <app-form-control-error
          [control]="giftCouponMyInfoForm.controls['lastName']"
          *ngIf="
            giftCouponMyInfoForm.controls['lastName'].errors &&
            giftCouponMyInfoForm.controls['lastName'].errors.required
          "
          i18n="@@lastNameRequired">
          Last name required.
        </app-form-control-error>
        <app-form-control-error
          [control]="giftCouponMyInfoForm.controls['lastName']"
          *ngIf="
            giftCouponMyInfoForm.controls['lastName'].errors &&
            giftCouponMyInfoForm.controls['lastName'].errors.maxlength
          "
          i18n="@@lastNameTooLong">
          Max length is {{ LAST_NAME_MAX_LENGTH }} characters.
        </app-form-control-error>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <label class="required" for="sEmail" i18n="@@yourEmail">Your email</label>
        <input
          type="text"
          formControlName="email"
          id="sEmail"
          class="form-control"
          placeholder="example@gmail.com"
          i18n-placeholder="@@emailExample" />
        <app-form-control-error
          [control]="giftCouponMyInfoForm.controls['email']"
          *ngIf="
            giftCouponMyInfoForm.controls['email'].errors && giftCouponMyInfoForm.controls['email'].errors.required
          "
          i18n="@@emailRequired">
          E-mail required.
        </app-form-control-error>
        <app-form-control-error
          [control]="giftCouponMyInfoForm.controls['email']"
          *ngIf="giftCouponMyInfoForm.controls['email'].errors && giftCouponMyInfoForm.controls['email'].errors.email">
          <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
        </app-form-control-error>
      </div>
    </div>
    <div class="form-buttons">
      <app-button className="btn-primary btn-md" buttonType="submit" [disabled]="isLoading" i18n="@@continue">
        Continue
      </app-button>
      <label class="required" i18n="@@giftCouponSelectorRequiredFieldLabel">required field</label>
    </div>
  </div>
</form>

<form
  [formGroup]="giftCouponRecipientInfoForm"
  (ngSubmit)="nextPartTwo()"
  class="gift-coupon-form row"
  novalidate
  [ngStyle]="{ display: currentStep == 1 ? 'block' : 'none' }">
  <div class="container">
    <div class="col-12 row amount-row">
      <div class="amount col-12">
        <h3 i18n="@@giftCertificateAmount">Gift Card Amount</h3>
        <div [ngClass]="{ 'value-page-2': true, 'currency-sign-after': isOtherCurrencySide }">
          {{ value | platformCurrency }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 col-sm">
        <label class="required" for="rFirstName" i18n="@@receiverName">Receiver's name</label>
        <input
          type="text"
          formControlName="firstName"
          id="rFirstName"
          class="form-control"
          placeholder="Betty"
          i18n-placeholder="@@receiverPlaceholder" />
        <app-form-control-error
          [control]="giftCouponRecipientInfoForm.controls['firstName']"
          *ngIf="
            giftCouponRecipientInfoForm.controls['firstName'].errors &&
            giftCouponRecipientInfoForm.controls['firstName'].errors.required
          "
          i18n="@@firstNameRequired">
          First name is required.
        </app-form-control-error>
        <app-form-control-error
          [control]="giftCouponRecipientInfoForm.controls['firstName']"
          *ngIf="
            giftCouponRecipientInfoForm.controls['firstName'].errors &&
            giftCouponRecipientInfoForm.controls['firstName'].errors.maxlength
          "
          i18n="@@firstNameTooLong">
          Max length is {{ FIRST_NAME_MAX_LENGTH }} characters.
        </app-form-control-error>
      </div>
      <div class="col col-12 col-sm">
        <label class="required" for="rLastName" i18n="@@receiverLastName">Receiver's last name</label>
        <input
          type="text"
          formControlName="lastName"
          id="rLastName"
          class="form-control"
          placeholder="Johnston"
          i18n-placeholder="@@receiverLastNamePlaceholder" />
        <app-form-control-error
          [control]="giftCouponRecipientInfoForm.controls['lastName']"
          *ngIf="
            giftCouponRecipientInfoForm.controls['lastName'].errors &&
            giftCouponRecipientInfoForm.controls['lastName'].errors.required
          "
          i18n="@@lastNameRequired">
          Last name required.
        </app-form-control-error>
        <app-form-control-error
          [control]="giftCouponRecipientInfoForm.controls['lastName']"
          *ngIf="
            giftCouponRecipientInfoForm.controls['lastName'].errors &&
            giftCouponRecipientInfoForm.controls['lastName'].errors.maxlength
          "
          i18n="@@lastNameTooLong">
          Max length is {{ LAST_NAME_MAX_LENGTH }} characters.
        </app-form-control-error>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <label class="required" for="rEmail" i18n="@@theirEmail">Their email</label>
        <input
          type="text"
          formControlName="email"
          id="rEmail"
          class="form-control"
          placeholder="example@gmail.com"
          i18n-placeholder="@@emailExample" />
        <app-form-control-error
          [control]="giftCouponRecipientInfoForm.controls['email']"
          *ngIf="
            giftCouponRecipientInfoForm.controls['email'].errors &&
            giftCouponRecipientInfoForm.controls['email'].errors.required
          "
          i18n="@@emailRequired">
          E-mail required.
        </app-form-control-error>
        <app-form-control-error
          [control]="giftCouponRecipientInfoForm.controls['email']"
          *ngIf="
            giftCouponRecipientInfoForm.controls['email'].errors &&
            giftCouponRecipientInfoForm.controls['email'].errors.email
          ">
          <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
        </app-form-control-error>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <label for="comment" i18n="@@addYourMsg">Please add your message</label>
        <textarea id="comment" formControlName="comment"></textarea>
        <app-form-control-error [control]="giftCouponRecipientInfoForm.controls['comment']" i18n="@@maxLengthIs">
          Max length is {{ MAX_COMMENT_LENGTH }} characters.
        </app-form-control-error>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-checkbox id="agree" formControlName="agree" class="auth-checkbox" i18n="@@agreeToCompanyTermsAndPP">
          I agree to the {{ platformName | titlecase }}
          <a href="{{ config.urlTerms }}" target="_blank" rel="noopener">Terms & Conditions</a>
          and
          <a href="{{ config.urlPrivacy }}" target="_blank" rel="noopener">Privacy Policy</a>
          .
        </app-checkbox>
      </div>
    </div>

    <div class="form-buttons">
      <app-button className="btn-lite btn-md" buttonType="button" (click)="back()" i18n="@@back">Back</app-button>
      <app-button className="btn-primary btn-md" buttonType="submit" [disabled]="isLoading" i18n="@@continue">
        Continue
      </app-button>
      <label class="required" i18n="@@giftCouponSelectorRequiredFieldLabel">required field</label>
    </div>
  </div>
</form>

<!-- eslint-enable -->

<div class="gift-coupon-form row" [ngStyle]="{ display: currentStep > 1 ? 'block' : 'none' }">
  <div class="container">
    <div class="col-12 row amount-row">
      <div class="amount col-12">
        <h3 i18n="@@giftCertificateAmount">Gift Card Amount</h3>
        <div class="value-page-2">
          {{ value | platformCurrency }}
        </div>
      </div>
    </div>

    <app-pay-with-selector
      (paymentSelected)="nextPartThree()"
      [disableSavedCards]="disableSavedCards"
      [isLoading]="isLoading"
      [hideWallet]="true"></app-pay-with-selector>

    <div class="form-buttons">
      <app-button className="btn-lite btn-md" buttonType="button" (click)="back()" i18n="@@back">Back</app-button>
    </div>
  </div>
</div>
