import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareNoteConfirmModalComponent } from '@app/modules/guide-notes/components/share-note-confirm-modal/share-note-confirm-modal.component';
import { GuideRelation, isGuideContact } from '@app/core/users/types';
import { GuideNotesBoardComponent } from '@app/modules/guide-notes/components/guide-notes-board/guide-notes-board.component';
import { GuideRelationNotesBoardService } from '../../guide-relation-notes-board.service';
import { FormControl } from '@angular/forms';
import { NOTES_UPDATE_TOKEN } from '@app/modules/guide-client/types/notes-update-token';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-relation-notes-board',
  templateUrl: './guide-relation-notes-board.component.html',
  styleUrls: ['./guide-relation-notes-board.component.scss'],
  providers: [GuideRelationNotesBoardService]
})
export class GuideRelationNotesBoardComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notesBoardRef: GuideNotesBoardComponent;

  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideRelation: GuideRelation;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _search: string;

  @Input()
  set guideRelation(value: GuideRelation) {
    this._guideRelation = value;
    this._guideNotesBoard.setRelationId(value.localId);
  }

  @Input()
  // @ts-expect-error TS2564
  isAssigned: boolean;

  @Input()
  // @ts-expect-error TS2564
  searchControl: FormControl;

  @ViewChild(GuideNotesBoardComponent)
  set notesBoard(value: GuideNotesBoardComponent) {
    this._notesBoardRef = value;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/explicit-function-return-type
  get guideRelation() {
    return this._guideRelation;
  }

  // @ts-expect-error TS2564
  reset: symbol;

  notes: GuideNote[] = [];

  readonly isLoading$ = this._guideNotesBoard.isLoading$;

  constructor(
    private modal: NgbModal,
    private readonly _guideNotesBoard: GuideRelationNotesBoardService,
    private readonly _cdr: ChangeDetectorRef,
    @Inject(NOTES_UPDATE_TOKEN) private readonly _notesUpdate: Subject<void>
  ) {}

  ngOnInit(): void {
    // @ts-expect-error TS2339
    this._guideNotesBoard.state$.pipe(takeUntil(this.destroy$)).subscribe(({ notes, scrollTo }) => {
      this.notes = notes;
      this._cdr.detectChanges();
      if (scrollTo) {
        this._notesBoardRef.scrollToCard(scrollTo);
      }
    });

    this._guideNotesBoard.resetEditorState$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.reset = Symbol();
    });

    this.searchControl?.valueChanges.pipe(debounceTime(600), takeUntil(this.destroy$)).subscribe(search => {
      this._search = search;
      this._guideNotesBoard.loadNotes(search);
    });

    this._notesUpdate
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._guideNotesBoard.loadNotes(this._search);
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteDelete(event) {
    this._guideNotesBoard.delete(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGrantViewerAccess(event) {
    // TODO: Move to the service
    const { componentInstance, result } = this.modal.open(ShareNoteConfirmModalComponent, { centered: true });
    componentInstance.name = this.guideRelation.name;
    componentInstance.email = this.guideRelation.contacts?.email;
    componentInstance.photo = this.guideRelation.photo;
    result
      .then(confirm => {
        if (confirm) {
          this._guideNotesBoard.grantViewerAccess(event);
        }
      })
      .catch(() => {});
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onRevokeViewerAccess(event) {
    this._guideNotesBoard.revokeViewerAccess(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteUpdate(event) {
    this._guideNotesBoard.update(event, false);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteEndEditing(event) {
    this._guideNotesBoard.update(event, true);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteCreate(event) {
    this._guideNotesBoard.create(event);
  }

  onLoadMoreNotes(): void {
    // @ts-expect-error TS2345
    this._guideNotesBoard.loadMoreNotes(null, this._search);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNotePin(event) {
    this._guideNotesBoard.pinNote(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteUnpin(event) {
    this._guideNotesBoard.unpinNote(event);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onManageAccess(event) {
    this._guideNotesBoard.manageAccess(event);
  }

  isContactRelation(): boolean {
    return isGuideContact(this._guideRelation);
  }
}
