import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IStorageFileInfo, MimeTypes } from '@app/core/files/types';
import { convertFileSize, defineMimeType } from '@app/modules/ui-kit/file-card/utils';
import { findExtensionByFilename } from '@app/modules/program/utils';
import { imageFileExtensions, videoFileExtensions } from '@app/core/uploader/types';

// Todo: needs refactor
@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileCardComponent {
  MimeTypes = MimeTypes;

  // @ts-expect-error TS2564
  fileExtension: string;

  // @ts-expect-error TS2564
  fileType: MimeTypes;

  imageExt = imageFileExtensions;

  // @ts-expect-error TS2564
  prettyFileSize: string;

  videoExt = videoFileExtensions;

  @Input()
  disableDownload = false;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _file: IStorageFileInfo;

  get file(): IStorageFileInfo {
    return this._file;
  }

  @Input()
  set file(fileInfo: IStorageFileInfo) {
    this.prettyFileSize = convertFileSize(fileInfo.size);
    this.fileType = defineMimeType(fileInfo.type);
    this.fileExtension = findExtensionByFilename(fileInfo.name);
    this._file = fileInfo;
  }

  @Input()
  set url(url: string) {
    this.fileType = MimeTypes.OTHER;
    this.fileExtension = findExtensionByFilename(url);
    this._file = {
      name: this.getFilename(url),
      size: 0,
      type: this.fileType,
      location: url,
      // @ts-expect-error TS2322
      etag: null,
      // @ts-expect-error TS2322
      bucket: null,
      // @ts-expect-error TS2322
      key: null
    };
  }

  private getFilename(src: string): string {
    // @ts-expect-error TS2322
    return src?.split('/').pop();
  }
}
