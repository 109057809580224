import { Injector, Provider } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { NAVIGATION_DEPENDENCIES } from '@app/modules/navigation/tokens';
import { navigationDependenciesFactory } from '@app/modules/navigation/providers/dependencies/factory';

export const NAVIGATION_DEPENDENCIES_PROVIDER: Provider = {
  provide: NAVIGATION_DEPENDENCIES,
  useFactory: navigationDependenciesFactory,
  deps: [AuthService, Injector]
};
