<div class="user">
  <pui-avatar class="user-photo" [avatarUrl]="user?.photo" [text]="user?.name"></pui-avatar>
  <div class="user-name">{{ user?.name }}</div>
  <div class="user-specialty">{{ user?.specialization }}</div>
</div>

<div class="divider"></div>

<div class="user-info" [ngClass]="{ 'p-b-82': user && !user.isBookAllowed }">
  <div class="short-quote">{{ user?.shortQuote }}</div>
  <div class="buttons">
    <app-button
      className="btn-md btn-main"
      (click)="emitBookSession()"
      *ngIf="user.isBookAllowed"
      i18n="@@BlogArticleAuthorComponentBook">
      Book a session
    </app-button>
    <app-button className="btn-md btn-full-bio" (click)="emitOpenBiography()" i18n="@@BlogArticleAuthorComponentRead">
      Read full bio
    </app-button>
  </div>
</div>
