import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiDropdownModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputModule,
  PuiLetModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CustomAvailabilityModule } from '../custom-availability-form/custom-availability-form.module';
import { SessionAvailabilityFormComponent } from './session-availability-form.component';
import { AvailabilityModule } from '@app/shared/components/availability/availability.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiLetModule,
    PuiAvatarModule,
    PuiButtonModule,
    PuiDropdownModule,
    NgbTooltipModule,
    PuiIconModule,
    PuiGroupModule,
    PuiTypographyModule,
    CustomAvailabilityModule,
    AvailabilityModule
  ],
  declarations: [SessionAvailabilityFormComponent],
  exports: [SessionAvailabilityFormComponent]
})
export class SessionAvailabilityFormModule {}
