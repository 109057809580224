import { DateTime } from 'luxon';
import { tz } from 'moment-timezone';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneOffset'
})
export class TimezoneOffsetPipe implements PipeTransform {
  /**
   * The Timezone Offset Pipe allows you to easily obtain the timezone offset.
   */
  transform(timezone: string, date?: string | Date): string {
    return getTimezoneOffset(timezone, date);
  }
}

/**
 * Get the timezone offset in the format '+HH:mm' for the specified timezone.
 *
 * @param timezone {string} The name of the timezone, e.g., 'Europe/Belgrade'
 * @param date {(string | Date)} The date, e.g., '2023-10-30' or '2023-10-30T11:00'(better for more accurate timezone).
 *                                For example. America/New_York timezone. Date 2024-03-10(by default it's mean 2024-03-10)
 *                                will return -05:00 offset. But 2024-03-10T10:00 will return -04:00 offset.
 *                                It happens because time offset happens after 00:00 oftenly.
 * @return {string} The timezone offset in the format '+01:00'
 */
export function getTimezoneOffset(
  timezone: string,
  date: string | Date = DateTime.now().toFormat('yyyy LLL dd')
): string {
  let formattedDate = '';

  // Should be without hours and minutes
  if (date instanceof Object) {
    formattedDate = DateTime.fromJSDate(date).toFormat('yyyy LLL dd HH:ss');
  } else if (typeof date === 'string') {
    formattedDate = DateTime.fromISO(date).toFormat('yyyy LLL dd HH:ss');
  }

  return tz(formattedDate, timezone).format('Z');
}
