import { WindowProvider } from './window-provider';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowserWindow extends WindowProvider {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  get window(): Window | Object {
    return window;
  }
}
