import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BREAKPOINTS } from '../config';

export enum ScreenSize {
  Mobile = 'Mobile',
  SMobile = 'SMobile',
  MMobile = 'MMobile',
  LMobile = 'LMobile',
  Tablet = 'Tablet',
  STablet = 'STablet',
  MTablet = 'MTablet',
  Desktop = 'Desktop',
  SDesktop = 'SDesktop',
  MDesktop = 'MDesktop',
  LDesktop = 'LDesktop'
}

export type ScreenBreakpoints = {
  [key in keyof typeof ScreenSize]: string;
};

export type ScreenObservers = {
  [key in keyof typeof ScreenSize]: Observable<boolean>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ScreenState<TScreen extends ScreenSize = ScreenSize> = {
  [key in keyof typeof ScreenSize]: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class StyleBreakpointsProviderService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _screenObservers: Map<ScreenSize, Observable<boolean>>;

  isMobile$: Observable<boolean>;

  constructor(private readonly _breakpointObserver: BreakpointObserver) {
    this._screenObservers = new Map(this._createFromBreakpoints(BREAKPOINTS));
    // @ts-expect-error TS2322
    this.isMobile$ = this._screenObservers.get(ScreenSize.Mobile);
  }

  observe(screens: ScreenSize[]): Observable<Partial<ScreenState>> {
    return this._getScreensState$(screens).pipe(
      // @ts-expect-error TS2698
      map(matches => matches.reduce((acc, cur) => ({ ...acc, ...cur }), null))
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getScreensState$(screens: ScreenSize[]): Observable<ScreenState[]> {
    return combineLatest<ScreenState[]>(
      // @ts-expect-error TS2532
      ...screens.map(screen => this._screenObservers.get(screen).pipe(map(state => ({ [screen]: state }))))
    ).pipe(debounceTime(100));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createFromBreakpoints(breakpoints: ScreenBreakpoints): [ScreenSize, Observable<boolean>][] {
    return Object.keys(breakpoints).map((screen: ScreenSize) => {
      return [screen, this._breakpointObserver.observe(breakpoints[screen]).pipe(map(result => result.matches))];
    });
  }
}
