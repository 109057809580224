import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import {
  _MatOptionBase,
  MAT_OPTGROUP,
  MAT_OPTION_PARENT_COMPONENT,
  MatOptgroup,
  MatOption
} from '@angular/material/core';
import { _MatAutocompleteBase, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ui-autocomplete',
  template: `
    <ng-template let-formFieldId="id">
      <div
        #panel
        class="mat-autocomplete-panel"
        role="listbox"
        [id]="id"
        [attr.aria-label]="ariaLabel || null"
        [attr.aria-labelledby]="_getPanelAriaLabelledby(formFieldId)"
        [ngClass]="_classList">
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  styleUrls: ['autocomplete.scss'],
  exportAs: 'uiAutocomplete',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['disableRipple'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mat-autocomplete ui-autocomplete'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_OPTION_PARENT_COMPONENT, useExisting: UiAutocomplete }]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiAutocomplete extends _MatAutocompleteBase {
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _multiple = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  get _optionsWithValue(): MatOption[] {
    // eslint-disable-next-line id-length
    return this.options?.filter(o => !!o.value) ?? [];
  }

  get selectedOptions(): MatOption[] {
    // eslint-disable-next-line id-length
    return this._optionsWithValue.filter(o => o.selected);
  }

  get selectedValues(): unknown[] {
    // eslint-disable-next-line id-length
    return this.selectedOptions.map(o => o.value);
  }

  get optionsValues(): unknown[] {
    // eslint-disable-next-line id-length
    return this._optionsWithValue.map(o => o.value);
  }

  get unselectedOptions(): MatOption[] {
    // eslint-disable-next-line id-length
    return this._optionsWithValue.filter(o => !o.selected);
  }

  get allSelected(): boolean {
    return !this._notAllSelected();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _selectionEventDisabled = false;

  /** Event that is emitted whenever an option from the list is selected.
   * Contains a full set of selected options for in multi-select mode. */
  @Output() optionSelected: EventEmitter<MatAutocompleteSelectedEvent> =
    new EventEmitter<MatAutocompleteSelectedEvent>();

  @ContentChildren(MAT_OPTGROUP, { descendants: true }) optionGroups!: QueryList<MatOptgroup>;

  @ContentChildren(MatOption, { descendants: true }) options!: QueryList<MatOption>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _visibleClass = 'mat-autocomplete-visible';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _hiddenClass = 'mat-autocomplete-hidden';

  /** Emits the `select` event (with multiple). */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _emitSelectEvent(option: _MatOptionBase): void {
    if (!this._selectionEventDisabled) {
      const event = new MatAutocompleteSelectedEvent(this, option);
      this.optionSelected.emit(event);
    }
  }

  disableEvents(): void {
    this._selectionEventDisabled = true;
  }

  enableEvents(): void {
    this._selectionEventDisabled = false;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notAllSelected(): boolean {
    // eslint-disable-next-line id-length
    return this._optionsWithValue.some(o => !o.selected);
  }
}
