import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [CommonModule, NgbModule, RouterModule, SharedModule],
  declarations: [],
  exports: []
})
export class PackageModule {}
