import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import config from '../../../../core/config/config';
import { GuideQuiz } from '@app/screens/guide/guide-programs/types';
import { AuthService } from '@app/core/auth/services';

@Injectable()
export class GuideQuizService {
  private readonly ENDPOINT = `${config.apiPath}/user/${
    this._auth.isPlatformAdmin() ? 'admin/programs-templates' : 'guide/programs'
  }/quizzes/`;
  constructor(private readonly _http: HttpClient, private _auth: AuthService) {}

  getGuideQuizzes$(): Observable<GuideQuiz[]> {
    return this._http.get<GuideQuiz[]>(this.ENDPOINT);
  }
}
