<pui-card>
  <pui-card-header>
    <div
      *ngIf="mode !== 'short'"
      class="current-title"
      data-qa-id="user-name-title"
      pui-card-header-title
      pui-tooltip-direction="top-right"
      [pui-tooltip]="title">
      {{ title }}
    </div>
    <span
      *ngIf="mode !== 'short' && !!subtitle"
      pui-card-header-subheader
      class="current-subtitle"
      data-qa-id="user-balance-subtitle">
      {{ subtitle }}
    </span>
    <pui-avatar
      pui-card-header-icon
      size="l"
      color="white"
      [avatarUrl]="avatar?.avatarUrl"
      [background]="avatar?.background"
      [rounded]="avatar.rounded"
      [text]="title"></pui-avatar>
  </pui-card-header>
</pui-card>
