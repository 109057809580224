import { createAction, props } from '@ngrx/store';
import { GUIDE_CALENDAR_FILTER_STORE } from './commons/consts';
import { GuideCalendarFilterState } from './commons/types';

// GUIDE_CALENDAR_FILTER_STORE
/**
 * This action will reset only data of
 */
export const resetGuideCalendarFilterData = createAction(`[${GUIDE_CALENDAR_FILTER_STORE}] Reset`);

export const setGuideCalendarFilterIsDisplayed = createAction(
  `[${GUIDE_CALENDAR_FILTER_STORE}] Set displayed`,
  props<{ isDisplayed: boolean }>()
);

export const toggleGuideCalendarFilterIsDisplayed = createAction(`[${GUIDE_CALENDAR_FILTER_STORE}] Toggle displayed`);

export const updateGuideCalendarFilterData = createAction(
  `[${GUIDE_CALENDAR_FILTER_STORE}] Update data`,
  props<Pick<GuideCalendarFilterState, 'filter'>>()
);

export const toggleCalendarFilterGuide = createAction(
  `[${GUIDE_CALENDAR_FILTER_STORE}] Toggle guide`,
  props<{ guideId: number; selected: boolean }>()
);

export const initialGuideCalendar = createAction(
  `[${GUIDE_CALENDAR_FILTER_STORE}] Initial`,
  props<{ payload: GuideCalendarFilterState }>()
);
