import { Observable } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { SessionTemplatePreviewModalComponent } from '@app/screens/guide/guide-sessions-templates/components';
import { SessionTemplateForm } from '@app/screens/guide/guide-sessions-templates/components/session-template-settings/types';
import { PUBLIC_SESSION_TEMPLATE_INFO } from '@app/screens/session-templates/services/public-session-template.providers';
import { PublicSessionTemplate } from '@app/shared/interfaces/services';
import { makeServiceLinkBuilder } from '@app/shared/utils/humanize-id-url';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class SessionTemplatePreviewService {
  private previewModalInstance!: Object | null;

  constructor(
    private readonly modal: NgbModal,
    @Inject(PUBLIC_SESSION_TEMPLATE_INFO) private readonly templateProvider$: Observable<PublicSessionTemplate>,
    private localeService: LocaleService
  ) {}

  preview(actualTemplateData: SessionTemplateForm): void {
    const passOrBuildServiceLink = makeServiceLinkBuilder<PublicSessionTemplate>(
      `${this.localeService.getLocale().baseUrl}/sessions`
    );

    this.templateProvider$
      .pipe(
        take(1),
        map(templateFromServer => this.setActualFormDataForTemplate(templateFromServer, actualTemplateData)),
        map(updatedTemplateData => passOrBuildServiceLink(updatedTemplateData)),
        switchMap(templatePreview => this.showPreviewModal$(templatePreview))
      )
      .subscribe();
  }

  private setActualFormDataForTemplate(
    template: PublicSessionTemplate,
    actualTemplateData: SessionTemplateForm
  ): PublicSessionTemplate {
    template.name = actualTemplateData.basicInfo?.name;
    template.descriptionMarkup = actualTemplateData.basicInfo?.descriptionMarkup;
    template.duration = actualTemplateData.basicInfo?.length;
    template.coverImage = actualTemplateData.basicInfo?.coverImage || '';
    template.coverImageThumb = actualTemplateData.basicInfo?.coverImageThumb || '';
    template.address = actualTemplateData.basicInfo?.location?.address || '';
    template.connectionType = actualTemplateData.basicInfo?.location?.connectionType;
    template.testimonials = actualTemplateData?.testimonials;
    template.price = actualTemplateData.pricing?.price;

    return template;
  }

  private showPreviewModal$(template: PublicSessionTemplate): Observable<void> {
    const { componentInstance, result } = this.modal.open(SessionTemplatePreviewModalComponent, {
      windowClass: 'full-screen-modal'
    });

    this.previewModalInstance = componentInstance;
    componentInstance.template = template;

    return modalResultToObservable$(result).pipe(finalize(() => (this.previewModalInstance = null)));
  }
}
