import { Pipe, PipeTransform } from '@angular/core';
import { humanize } from '@app/shared/utils/humanize';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

@Pipe({
  name: 'notificationDateFormatter',
  pure: false
})
export class NotificationDateFormatterPipe implements PipeTransform {
  constructor(private platformStyle: PlatformStyle) {}

  transform(dateStr: string): string {
    let result = '';

    result = this.platformStyle.datetime(dateStr, 'DATE, TIME', {
      humanize,
      hideWeekDay: true,
      hideLastYear: true
    });

    return result;
  }
}
