import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { SessionActionType } from '@libs/business/cdk/session';
import { ClientSessionActionsAppearanceType } from '../../types';
import { ClientSessionActionsService, ClientSessionCommunicationsService } from '../../../../services';
import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { SESSION_COMPONENT } from '@libs/business/client/client-session/tokens';
import { BaseSessionComponent } from '@libs/business/client/client-session';
import { GuideServiceParent } from '@app/modules/book-service';

@Component({
  selector: 'app-session-actions',
  templateUrl: './session-actions.component.html',
  styleUrls: ['./session-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionActionsComponent {
  @Input()
  readonly noNeedPaid: boolean = false;

  @Input()
  actionTypes: SessionActionType[] | undefined;

  @Input()
  appearance: ClientSessionActionsAppearanceType | undefined;

  @Input()
  ghost: boolean | undefined;

  constructor(
    @Inject(SESSION_COMPONENT) readonly parent: BaseSessionComponent<GuideServiceParent>,
    private readonly emitter: ClientSessionCommunicationsService,
    private readonly clientSessionActionsService: ClientSessionActionsService
  ) {
    if (!parent) {
      console.warn('Cant access Service Action parent Session');
    }
  }

  execute(type: SessionActionType): void {
    const meta = this.parent?.session?.meta;
    if (!meta) {
      console.warn('Cant execute Session action! Session Meta is not defined!');
      return;
    }

    switch (type) {
      case 'hide':
        this.emitter.archiveSession(meta);
        break;
      case 'book-again':
        this.emitter.reorderSession(meta, AnalyticSourceTypes.PRACTITIONER);
        break;
      case 'cancel':
        this.emitter.cancelSession(meta);
        break;
      case 'reschedule':
      case 'ask-to-reschedule':
        this.clientSessionActionsService.rescheduleSession(meta, this.noNeedPaid);
        break;
      case 'decline':
        this.emitter.declineSessionOffer(meta);
        break;
      case 'accept':
        this.emitter.acceptSessionOffer(meta);
        break;
      case 'approve-reschedule':
        this.clientSessionActionsService.approveReschedulement(meta, this.noNeedPaid);
        break;
      default:
        break;
    }
  }
}
