import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthService } from '@app/core/auth/services';
import { Languages } from '@app/shared/enums/languages';

import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import config from '../config/config';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ILanguageOption {
  id: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _languages: ILanguageOption[];

  get languages(): ILanguageOption[] {
    return this._languages;
  }

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _authService: AuthService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    // @ts-expect-error TS2532
    this._languages = this._runtimeConfigService.get('availableLanguages').map(lang => ({
      id: lang,
      // @ts-expect-error TS7053
      label: Languages[lang]
    }));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveSelectedLanguage(language): Observable<any> {
    if (this._authService.isAuthorized) {
      return this._httpClient.post(`${config.apiPath}/user/common/language`, { language });
    }

    return of(null);
  }
}
