export const NEW_GUIDE_CLIENT_TYPE = 'new_guide_client';

export class NewGuideClient {
  id?: number | null;
  firstName: string;
  lastName: string;
  email: string;
  photo?: string | null;

  get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim() || this.email;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return NEW_GUIDE_CLIENT_TYPE;
  }

  constructor(email: string, firstName?: string | null, lastName?: string | null) {
    this.email = email;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
  }
}

export function isNewGuideClient(user: { type: string }): user is NewGuideClient {
  return user.type === NEW_GUIDE_CLIENT_TYPE;
}
