<ng-container *ngIf="control">
  <ng-container *puiLet="guides$ | async as guides">
    <pui-select
      [formControl]="control"
      [multiple]="true"
      [valuesTemplate]="valuesTemplate"
      [optionContent]="optionContent"
      [virtualScroll]="false"
      [allowToUnselect]="true">
      <pui-option
        *ngFor="let guide of guides"
        [value]="guide.userId"
        [label]="guide.username"
        [readonly]="isGuideSelected(guide)"
        [disabled]="guide.role === null"
        [selected]="(assignedGuides$ | async | map: 'userId')?.includes(guide.userId)"
        [data]="guide">
      </pui-option>
    </pui-select>
  </ng-container>
</ng-container>

<ng-template #valuesTemplate let-options="options">
  <ng-container *ngIf="options.length > 1">
    <pui-avatar-group size="s">
      <pui-avatar
        size="s"
        *ngFor="let option of options.slice(0, MAX_DISPLAYED_GUIDES)"
        [avatarUrl]="option.data.photo"
        [text]="option.data.username"></pui-avatar>
      <ng-container *ngIf="options.length - MAX_DISPLAYED_GUIDES > 0" ngProjectAs="pui-avatar">
        <pui-avatar
          size="s"
          [text]="'+' + (options.length - MAX_DISPLAYED_GUIDES)"
          [autoColor]="false"
          role="button"
          aria-pressed="false"></pui-avatar>
      </ng-container>
    </pui-avatar-group>
  </ng-container>

  <ng-container *ngIf="options.length === 1">
    <pui-card *puiLet="options[0] as option">
      <pui-card-header>
        <pui-avatar
          size="s"
          pui-card-header-icon
          [avatarUrl]="option.data.photo"
          [text]="option.data.username"></pui-avatar>
        <span pui-card-header-title>
          {{ option.label }}
        </span>
      </pui-card-header>
    </pui-card>
  </ng-container>
</ng-template>

<ng-template #optionContent let-option="option">
  <div
    pui-input-checkbox
    class="team-member-option"
    [class.team-member-option_disabled]="option.data.role === null"
    [ngModel]="option.selected"
    [readonly]="true">
    <pui-card>
      <pui-card-header class="team-member-option__card">
        <pui-avatar
          size="l"
          pui-card-header-icon
          [avatarUrl]="option.data.photo"
          [text]="option.data.username || option.data.email"></pui-avatar>
        <span pui-card-header-title pui-typography="link">
          {{ option.label }}
        </span>
        <small pui-card-header-subheader pui-typography="body-s">
          {{ option.data.email }}
        </small>
      </pui-card-header>
    </pui-card>
  </div>
</ng-template>
