import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UserNotesModule } from '@app/modules/user-notes/user-notes.module';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';

import { SharedModule } from '@app/shared';
import { ShareNoteConfirmModalComponent } from '@app/modules/guide-notes/components/share-note-confirm-modal/share-note-confirm-modal.component';
import { PuiAlertModule, PuiAvatarModule, PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { GuideNoteCardMetaComponent } from './components/guide-note-card-meta/guide-note-card-meta.component';
import { GuideNotesBoardComponent } from './components/guide-notes-board/guide-notes-board.component';
import { GuideRelationNotesBoardComponent } from './components/guide-relation-notes-board/guide-relation-notes-board.component';
import { GuideEventsNotesBoardComponent } from './components/guide-events-notes-board/guide-events-notes-board.component';
import { BottomScrollDirective } from '@app/modules/guide-notes/components/guide-notes-board/bottom-scroll.directive';

@NgModule({
  declarations: [
    GuideNoteCardMetaComponent,
    ShareNoteConfirmModalComponent,
    GuideNotesBoardComponent,
    GuideRelationNotesBoardComponent,
    GuideEventsNotesBoardComponent,
    BottomScrollDirective
  ],
  providers: [],
  exports: [GuideRelationNotesBoardComponent, GuideEventsNotesBoardComponent],
  imports: [
    CommonModule,
    NgScrollbarModule,
    UserNotesModule,
    SharedModule,
    PuiAvatarModule,
    TextEditorModule,
    PuiIconModule,
    PuiAlertModule,
    PuiTypographyModule
  ]
})
export class GuideNotesModule {}
