<app-service-badge
  *ngIf="clientSession.session.serviceParent?.id"
  [service]="clientSession.session.serviceParent"></app-service-badge>

<div class="main-content">
  <app-user-info
    class="session-lg-left-col user-info"
    [user]="clientSession.guide"
    [uiPayload]="chatLink"
    [routerLink]="['/', clientSession.guide.namedUrl]"></app-user-info>

  <ng-template #chatLink let-user="$implicit">
    <a [routerLink]="['../chat', buildDirectChatId(user)]" class="user-chat-link">
      <span class="user-chat-link-icon"></span>
      <span class="user-chat-link-text" i18n="@@sendMsg">Send message</span>
    </a>
  </ng-template>

  <div class="session-lg-center-col dashboard-session-info with-separator" *ngIf="clientSession.session.isExpired">
    <div class="date">
      <ng-container i18n="@@sessionExpired">Expired</ng-container>
    </div>
    <div class="session-name">{{ clientSession.session.name }}</div>
  </div>

  <app-client-dashboard-session-details
    [clientSession]="clientSession"
    class="session-lg-center-col dashboard-session-info with-separator"
    *ngIf="!clientSession.session.isExpired"></app-client-dashboard-session-details>

  <div class="session-lg-right-col with-separator" *ngIf="!isPackage && canBookAgain">
    <app-button className="btn-link" (click)="reorder()" i18n="@@bookAgain">Book again</app-button>
  </div>

  <app-actions-menu
    *ngIf="!isPackage"
    [actions]="actions"
    (select)="emitSessionAction($event)"
    class="session-actions"></app-actions-menu>
</div>
