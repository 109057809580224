<form [formGroup]="form">
  <div class="work-time">
    <ng-container *ngFor="let item of weekDays; let index = index">
      <ng-container *ngFor="let control of form.get([item.dayId, 'hours']).controls; let controlIndex = index">
        <div class="work-time__row" *ngIf="controlIndex === 0 || form.get([item.dayId, 'isActive'])?.value">
          <ng-container *ngIf="controlIndex === 0; else timeSlots">
            <pui-toggle
              class="align-items-center"
              [class._active]="form.get([item.dayId, 'isActive'])?.value"
              [formGroupName]="item.dayId"
              [formControl]="form.get([item.dayId, 'isActive'])"
              (change)="changeActivity(item.dayId)">
              <div class="work-time__toggle-inner">
                <div class="work-time__toggle-name">
                  <h5 pui-typography="h5">{{ item?.name }}</h5>
                </div>
                <ng-container *ngIf="form.get([item.dayId, 'isActive'])?.value">
                  <ng-container
                    *ngTemplateOutlet="
                      timeSelectWrapper;
                      context: {
                        startControl: control.get('start'),
                        endControl: control.get('end'),
                        itemDayId: item.dayId,
                        index: controlIndex
                      }
                    ">
                  </ng-container>

                  <pui-icon
                    class="action-button pui-space_right-4"
                    (click)="addControl(item.dayId)"
                    svgIcon="pui:plus"></pui-icon>

                  <!-- TODO: need to refactor (click)="applyToAll(form.get([item.dayId, 'hours', '0']))" -->
                  <ng-container *ngIf="applyAllDaysPositionId && item.dayId === applyAllDaysPositionId">
                    <ng-container *ngIf="isMobile$ | async; else desktopButton">
                      <button
                        pui-button
                        type="button"
                        size="s"
                        class="all-day-button"
                        appearance="flat"
                        pui-tooltip="Apply to all"
                        i18n-pui-tooltip="@@workingTimeApplyToAllDays"
                        class="work-time__toggle-add-button"
                        (click)="applyToAll(form.get([item.dayId, 'hours', '0']))"
                        data-qa-id="apply-to-all-days-button">
                        <pui-icon svgIcon="pui:copy"></pui-icon>
                        Apply to all days
                      </button>
                    </ng-container>
                    <ng-template #desktopButton>
                      <button
                        pui-icon-button
                        type="button"
                        size="s"
                        appearance="flat"
                        pui-tooltip="Apply to all"
                        i18n-pui-tooltip="@@workingTimeApplyToAllDays"
                        class="work-time__toggle-add-button"
                        (click)="applyToAll(form.get([item.dayId, 'hours', '0']))"
                        data-qa-id="apply-to-all-days-button">
                        <pui-icon class="action-button" svgIcon="pui:copy"></pui-icon>
                      </button>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </pui-toggle>
          </ng-container>

          <ng-template #timeSlots>
            <div *ngIf="form.get([item.dayId, 'isActive'])?.value" class="work-time__selector-wrapper">
              <ng-container
                *ngTemplateOutlet="
                  timeSelectWrapper;
                  context: {
                    startControl: control.get('start'),
                    endControl: control.get('end'),
                    itemDayId: item.dayId,
                    index: controlIndex
                  }
                ">
              </ng-container>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>

<ng-template
  #timeSelectWrapper
  let-startControl="startControl"
  let-endControl="endControl"
  let-itemDayId="itemDayId"
  let-index="index">
  <app-working-time-slot
    class="work-time__toggle-time-slot"
    [dayId]="itemDayId"
    [startHours]="startHours"
    [endHours]="endHours[itemDayId]"
    [startControl]="startControl"
    [endControl]="endControl"
    [index]="index"
    [fullDayHours]="fullDayHours"
    (removeControl)="removeControl(itemDayId, index)">
  </app-working-time-slot>
</ng-template>
