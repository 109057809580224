import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalInfoDirective } from './directives/additional-info';

@NgModule({
  declarations: [AdditionalInfoDirective],
  imports: [CommonModule],
  exports: [AdditionalInfoDirective]
})
export class SmartPopupModule {}
