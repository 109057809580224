import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { FloatingPopoverRef } from '@app/modules/floating-popover/floating-popover-ref';
import { AnalyticCopyTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { EmbeddableBookButtonModalComponent } from '@app/modules/embeddable-book-button/components/embeddable-book-button-modal/embeddable-book-button-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FloatingPopoverService } from '@app/modules/floating-popover/floating-popover.service';
import { NotificationsService } from 'angular2-notifications';
import { EmbeddableCodeSource } from '@app/modules/embeddable-book-button/types';
import { BookingPopupNotification, NotificationComponent } from './notification/notification.component';

export interface SidenavCurrentBookingPopupView {
  teamBookingLink: string;
  myBookingLink: string;
  openPopup?: (event: MouseEvent, data: BookingPopupData) => void;
}

export type BookingPopupData = Pick<SidenavCurrentBookingPopupView, 'myBookingLink' | 'teamBookingLink'>;

@Component({
  selector: 'app-booking-popup',
  templateUrl: './booking-popup.component.html',
  styleUrls: ['./booking-popup.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-booking-popup'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingPopupComponent {
  // @ts-expect-error TS2564
  @Input() teamBookingLink: string;

  // @ts-expect-error TS2564
  @Input() myBookingLink: string;

  source: typeof EmbeddableCodeSource = EmbeddableCodeSource;

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _modal: NgbModal,
    private readonly _popoverSrv: FloatingPopoverService,
    private readonly _notification: NotificationsService,
    @Optional() private readonly _popoverRef: FloatingPopoverRef<BookingPopupData>
  ) {
    if (_popoverRef) {
      this.myBookingLink = _popoverRef.data.myBookingLink;
      this.teamBookingLink = _popoverRef.data.teamBookingLink;
    }
  }

  onBookLinkCopySuccess(origin: HTMLElement): void {
    this._analyticsService.event(InternalEvents.LINK_COPY, {
      copy_type: AnalyticCopyTypes.BUTTON,
      link_type: AnalyticSourceTypes.BOOKING
    });

    const message = `Book link copied`;

    this._popoverSrv.open<BookingPopupNotification>({
      origin,
      content: NotificationComponent,
      positionPair: [
        {
          originX: 'start',
          overlayX: 'start',
          originY: 'bottom',
          overlayY: 'top'
        }
      ],
      componentData: {
        state: 'success',
        message
      }
    });
  }

  onBookLinkCopyFailed(): void {
    const message = `Book link copy failed`;
    this._notification.alert(message);
  }

  openBookWidget(source: EmbeddableCodeSource): void {
    const modal = this._modal.open(EmbeddableBookButtonModalComponent, { windowClass: 'embeddable-book-modal' });
    modal.componentInstance.source = source;
  }
}
