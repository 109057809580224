import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { ISchedule, ISchedulePartitionUnit } from '../../types';
import { ScheduleDatesPartitionStrategy } from './schedule-dates-partition-strategy';

@Injectable()
export class DefaultScheduleDatesPartitionStrategy extends ScheduleDatesPartitionStrategy<
  ISchedule,
  ISchedulePartitionUnit
> {
  partition(schedule: ISchedule, timezone = 'local'): ISchedulePartitionUnit[] {
    if (!schedule || !schedule.ranges || !schedule.ranges.length) {
      return [];
    }

    return Object.entries(
      this.groupRangesByDates(this.partitionRangesByDatesEdges(schedule.ranges, timezone), timezone)
    ).map(([date, ranges]) => ({ date, ranges }));
  }

  protected groupRangesByDates(ranges: IScheduleItem[], timezone: string): { [date: string]: IScheduleItem[] } {
    return ranges.reduce((rangesByDates, range) => {
      const key = DateTime.fromISO(range.dateStart).setZone(timezone).toFormat('yyyy-MM-dd');

      // @ts-expect-error TS7053
      if (!rangesByDates[key]) {
        // @ts-expect-error TS7053
        rangesByDates[key] = [];
      }
      // @ts-expect-error TS7053
      rangesByDates[key].push(range);

      return rangesByDates;
    }, {});
  }

  protected partitionRangesByDatesEdges(ranges: IScheduleItem[], timezone: string): IScheduleItem[] {
    const newRanges = [];

    for (const range of ranges) {
      const startDateTime = DateTime.fromISO(range.dateStart).setZone(timezone);
      const endDateTime = DateTime.fromISO(range.dateEnd).setZone(timezone);

      if (startDateTime.hasSame(endDateTime, 'day')) {
        newRanges.push({
          dateStart: startDateTime.toISO(),
          dateEnd: endDateTime.toISO(),
          guide: range.guide
        });
      } else {
        let newStartDateTime = startDateTime;

        while (!newStartDateTime.hasSame(endDateTime, 'day')) {
          const newEndDateTime = newStartDateTime.plus({ day: 1 }).startOf('day');

          newRanges.push({
            dateStart: newStartDateTime.toISO(),
            dateEnd: newEndDateTime.toISO(),
            guide: range.guide
          });

          newStartDateTime = newEndDateTime;
        }

        newRanges.push({
          dateStart: newStartDateTime.toISO(),
          dateEnd: endDateTime.toISO()
        });
      }
    }

    return newRanges;
  }
}
