import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReschedulingSessionOptions } from '@app/modules/rescheduling-service/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-rescheduling-modal',
  templateUrl: './session-rescheduling-modal.component.html',
  styleUrls: ['./session-rescheduling-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'session-rescheduling-modal'
  }
})
export class SessionReschedulingModalComponent {
  @Input()
  // @ts-expect-error TS2564
  reschedulingOptions: ReschedulingSessionOptions;

  @Input()
  // @ts-expect-error TS2564
  onReschedulingOptionsUpdate: ((reschedulingOptionsUpdate: ReschedulingSessionOptions) => void) | null;

  constructor(readonly modal: NgbActiveModal) {}

  updateReschedulingOptions(reschedulingOptionsUpdate: ReschedulingSessionOptions | null): void {
    if (!reschedulingOptionsUpdate) {
      this.modal.dismiss();
    }
    if (this.onReschedulingOptionsUpdate) {
      // @ts-expect-error TS2345
      this.onReschedulingOptionsUpdate(reschedulingOptionsUpdate);
    }
  }
}
