import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { BlogEditorService } from '../../services/blog-editor.service';
import { BlogArticleFormComponent } from '../blog-article-form/blog-article-form.component';
import { BlogArticleStatuses } from '@app/screens/blog/types';
import { InternalEvents } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { HttpEventType } from '@angular/common/http';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-blog-article-editor',
  templateUrl: './blog-article-editor.component.html',
  styleUrls: ['./blog-article-editor.component.scss'],
  providers: [BlogEditorService]
})
export class BlogArticleEditorComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _routeSubscription: Subscription;
  private destroy$ = new Subject();

  @ViewChild(BlogArticleFormComponent, { static: false })
  // @ts-expect-error TS2564
  builderRef: BlogArticleFormComponent;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  article: any;
  tags = [];
  isSaving = false;

  get canCreateBusinessArticles(): boolean {
    return this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.ADMIN;
  }

  get canSendOut(): boolean {
    // https://profi-io.atlassian.net/browse/PR-4957
    // ToDo Blog article email notifications are temporarily disabled
    // return this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.ADMIN;
    return false;
  }

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _service: BlogEditorService,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.setSubscriptions();
    this.loadInitialData();
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  loadInitialData(): void {
    this._service
      .getTags$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(tags => (this.tags = tags));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publish(article: any): void {
    this.isSaving = true;
    this._service
      .updateArticle$(article.id, article)
      .pipe(
        tap(() => {
          if (article.status === BlogArticleStatuses.DRAFT) {
            this._analyticsService.event(InternalEvents.BLOG_STORY_DRAFT_SAVED, {});
          }
          if (article.status === BlogArticleStatuses.PUBLISHED) {
            this._analyticsService.event(InternalEvents.BLOG_STORY_PUBLISHED, {});
          }
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isSaving = false))
      )
      .subscribe(() => {
        if (article.isBusiness) {
          // eslint-disable-next-line rxjs/no-nested-subscribe, rxjs-angular/prefer-takeuntil
          this._route.paramMap.pipe(take(1)).subscribe(params => {
            this._router.navigate(['/business-blog', params.get('guide'), 'posts', params.get('id')]);
          });
        } else {
          this._router.navigate(['../'], { relativeTo: this._route });
        }
      });
  }

  uploadCover(image: File): void {
    this._service
      .uploadImage$(image)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.type === HttpEventType.Response) {
          const { url } = response.body;

          if (!url) {
            return;
          }

          this.builderRef.setCoverImage(url);
        }
      });
  }

  private setSubscriptions(): void {
    this._routeSubscription = this._route.paramMap
      .pipe(
        // @ts-expect-error TS2531
        map(params => +params.get('id')),
        switchMap(id => this._service.getArticle$(id)),
        takeUntil(this.destroy$)
      )
      .subscribe(article => (this.article = article));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  sendOut(id: number) {
    this._service.sendOutEmail$(id).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
