<h6 class="title" i18n="@@BlogArticleLatestArticlesComponentTitle">Latest Articles</h6>
<div class="row">
  <ng-container *ngFor="let article of articles">
    <div class="col-md-4">
      <app-blog-item-article
        [article]="article"
        (select)="emitSelectArticle($event)"
        (selectAuthor)="emitSelectAuthor($event)"
        class="latest-article"></app-blog-item-article>
    </div>
  </ng-container>
</div>
