import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IGuideServiceGroup, IGuideServiceGroupItem } from '../../types';
import { ModuleStatuses } from '@app/shared/interfaces/programs/client-programs';

@Component({
  selector: 'app-guide-program-service-group',
  templateUrl: './guide-program-service-group.component.html',
  styleUrls: ['./guide-program-service-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-program-service-group ui-app-guide-program-service-group'
  }
})
export class GuideProgramServiceGroupComponent {
  @Input()
  // @ts-expect-error TS2564
  serviceGroup: IGuideServiceGroup;

  @Output()
  serviceSelect = new EventEmitter<IGuideServiceGroupItem>();

  get services(): IGuideServiceGroupItem[] {
    return this.serviceGroup.services.filter(service => service.serviceParent.status !== ModuleStatuses.RESTRICTED);
  }

  readonly GuideServiceTypes = GuideServiceTypes;

  constructor(private readonly _domSanitizer: DomSanitizer) {}
}
