import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractControl, Validators } from '@angular/forms';
import { GuideNotesApiService } from '@app/modules/guide-notes/guide-notes-api.service';
import { GuideRelation } from '@app/core/users/types';
import { IGuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { INoteEditorContent } from '@app/shared/interfaces/notes';
import { TextEditorNotesComponent } from '@app/modules/text-editor/components';

import { GuideRelationInfoModalService } from '../../services/guide-relation-info-modal.service';

@Component({
  selector: 'app-guide-relation-info-modal',
  templateUrl: './guide-relation-info-modal.component.html',
  styleUrls: ['./guide-relation-info-modal.component.scss'],
  providers: [GuideRelationInfoModalService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideRelationInfoModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _relation: GuideRelation;

  // @ts-expect-error TS2564
  note: IGuideNote;

  @Input()
  set relation(value: GuideRelation) {
    this._guideRelationInfoModalService.setRelation(value.localId);
    this._relation = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get relation() {
    return this._relation;
  }

  @ViewChild('addNoteEditor', { static: true })
  // @ts-expect-error TS2564
  addNoteEditor: TextEditorNotesComponent;

  constructor(
    public modal: NgbActiveModal,
    private readonly _guideRelationInfoModalService: GuideRelationInfoModalService,
    private _guideApiService: GuideNotesApiService
  ) {}

  ngOnInit(): void {
    this._guideRelationInfoModalService.resetEditorState$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.addNoteEditor.reset();
      this.addNoteEditor.stopEditing();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isEmail(email: string): boolean {
    return !Validators.email({ value: email } as AbstractControl);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteChange($event: INoteEditorContent) {
    this._guideRelationInfoModalService.change($event);
  }
}
