import { IUserInfo } from './chat-user';

export enum ChatMessageStatuses {
  FILE_UPLOAD = 'file_upload',
  DELIVERED = 'delivered',
  READ = 'read'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IAttachment {
  name: string;
  url: string;
  size?: number | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IFileUploading {
  loaded: boolean;
  loadedPercent: number;
  file?: IAttachment | null;
}

export enum MessageTypes {
  FILE = 'file',
  FILE_UPLOAD = 'file_upload',
  MESSAGE = 'message',
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  SIGNATURE = 'signature',
  JOINED_CHAT = 'joined_chat'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUploadingAttachment {
  name: string;
  uploadStatus: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChatMessage {
  id: number;
  chatId: string;
  date: string;
  sender: IUserInfo;
  status: ChatMessageStatuses;
  text: string;
  file?: IAttachment;
  type?: MessageTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any | null;
  answer?: string | null;
}
