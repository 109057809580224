import { Pipe, PipeTransform } from '@angular/core';
import { GuideServiceParent } from '@app/modules/book-service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { ClientSessionsParentGrouping } from '@app/modules/client-sessions/components/available-client-sessions/grouping/index';
import { ClientSession } from '@libs/business/client/client-session';

export interface SessionsGroup<T> {
  id: number;
  type: GuideServiceTypes;
  name: string;
  available: number | undefined;
  total: number | undefined;
  children: T[];
  order: number;
}

export type SessionGroup = SessionsGroup<ClientSession<GuideServiceParent>>;

@Pipe({
  name: 'groupSessionsBy'
})
export class GroupSessionsByPipe implements PipeTransform {
  transform(sessions: ClientSession<GuideServiceParent>[]): (SessionGroup | ClientSession<GuideServiceParent>)[] {
    if (!sessions) {
      return [];
    }

    const parentTypes: [null, GuideServiceTypes.PACKAGE, GuideServiceTypes.PROGRAM] = [
      null,
      GuideServiceTypes.PACKAGE,
      GuideServiceTypes.PROGRAM
    ];

    const groupedSessions = parentTypes.map(parentType => {
      const grouperForParent = parentType && ClientSessionsParentGrouping[parentType];

      const sessionsByParent = sessions.filter(session => (session.parent?.type || null) === parentType);

      if (!grouperForParent) {
        return sessionsByParent;
      }

      return grouperForParent(sessionsByParent);
    });

    return Array.prototype.concat(...groupedSessions);
  }
}
