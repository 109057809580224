import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PuiButtonModule, PuiDropdownModule } from '@awarenow/profi-ui-core';

import { ServiceSelectorModule } from './components/service-selector/service-selector.module';
import { NewServiceButtonComponent } from './new-service-button.component';

@NgModule({
  imports: [CommonModule, ServiceSelectorModule, PuiButtonModule, PuiDropdownModule],
  declarations: [NewServiceButtonComponent],
  exports: [NewServiceButtonComponent]
})
export class NewServiceButtonModule {}
