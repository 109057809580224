import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StarRatingModule } from 'angular-star-rating';

import { OurCommunityComponent } from '@app/screens/our-community/our-community.component';
import { ProductCardComponent } from '@app/screens/our-community/components/product-card/product-card.component';
import { OurCommunityRoutingModule } from '@app/screens/our-community/our-community-routing.module';
import { ProgramsModule } from '@app/screens/programs/programs.module';
import { BlogModule } from '@app/screens/blog/blog.module';
import { SharedModule } from '@app/shared';
import { FindYourGuideModule } from '@app/screens/find-your-guide/find-your-guide.module';
import { PublicProgramsService } from '@app/screens/programs/services/public-programs.service';
import { BackgroundVideoDirective } from '@app/screens/our-community/directives/background-video.directive';
import { StringTagsPipe } from './pipes/string-tags.pipe';
import { GradientPipe } from './pipes/gradient.pipe';
import { CommunityConfigPipe } from './pipes/community-config.pipe';

@NgModule({
  declarations: [
    OurCommunityComponent,
    ProductCardComponent,
    BackgroundVideoDirective,
    StringTagsPipe,
    GradientPipe,
    CommunityConfigPipe
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    OurCommunityRoutingModule,
    StarRatingModule,
    ProgramsModule,
    BlogModule,
    SharedModule,
    FindYourGuideModule
  ],
  providers: [PublicProgramsService]
})
export class OurCommunityModule {}
