import { PuiNavigationItemRoute } from '@awarenow/profi-ui-core';
import { IMenuLink } from '@app/screens/admin/types';

export const createRouteFromLink = (link: IMenuLink): PuiNavigationItemRoute => {
  if (!link.url) {
    // @ts-expect-error TS2322
    return undefined;
  }

  const [routerLink, urlParams] = link.url.split('?');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const urlSearchParams: any = new URLSearchParams(urlParams);
  const queryParams = {};

  for (const [key, value] of urlSearchParams) {
    // @ts-expect-error TS7053
    queryParams[key] = value;
  }

  return {
    routerLink,
    queryParams,
    routerLinkActive: 'active',
    routerLinkActiveOptions: {
      exact: true
    }
  };
};
