import { Subject } from 'rxjs';

export interface QuillImageUploadRequest {
  id: string;
  file: File;
}

export interface QuillImage {
  id: string;
  url: string;
}

export class QuillImageUploadDriver {
  uploadRequests$ = new Subject<QuillImageUploadRequest>();
  fileUrls$ = new Subject<QuillImage>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  uploadFile(id: string, file: File) {
    this.uploadRequests$.next({ id, file });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  passUrl(id: string, url: string) {
    this.fileUrls$.next({ id, url });
  }
}
