<div class="modal-header no-border">
  <app-button class="closeBtn" (click)="modal.dismiss()" className="close"></app-button>
  <h2 class="modal-title" i18n="@@paymentOptions">Payment options</h2>
</div>
<div class="modal-body">
  <app-payment-options-selector
    #paymentOptionSelector
    [fullPrice]="fullPrice"
    [installmentsPrices]="installmentsPrices"></app-payment-options-selector>
</div>
<div class="modal-footer">
  <app-button className="btn-primary" (click)="submit()" i18n="@@continue">Continue</app-button>
</div>
