import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { CoverImageModule } from '@app/modules/ui-kit/cover-image/cover-image.module';
import {
  PuiButtonModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { LocationFormModule } from '../location-form/location-form.module';
import { RecurrenceFormModule } from '../recurrence-form/recurrence-form.module';
import { BasicInfoFormComponent } from './basic-info-form.component';
import { BasicInfoFormAppearanceDirective } from './basic-info-form-appearance.directive';
import { UpdateValueAndValidityModule } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.module';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiGroupModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule,
    TextEditorModule,
    CoverImageModule,
    PuiLinkModule,
    PuiIconModule,
    LocationFormModule,
    RecurrenceFormModule,
    PuiLetModule,
    UpdateValueAndValidityModule,
    PuiInputCheckboxModule,
    TextMaskModule
  ],
  declarations: [BasicInfoFormComponent, BasicInfoFormAppearanceDirective],
  exports: [BasicInfoFormComponent]
})
export class BasicInfoFormModule {}
