import { BaseChatSummary, ChatTypes } from '@app/core/chat/types';
import { User } from '@app/shared/interfaces/user';

export abstract class BoardBaseChatSummary extends BaseChatSummary {
  // @ts-expect-error TS2564
  name: string | null;

  photo?: string | null;
}

export class BoardDirectChatSummary extends BoardBaseChatSummary {
  // @ts-expect-error TS2564
  userId: number;

  isOnline?: boolean;

  bot?: boolean;

  draft?: boolean;

  archived?: boolean;

  archivedAt?: string;

  get type(): ChatTypes {
    return ChatTypes.DIRECT;
  }

  // @ts-expect-error TS7006
  constructor(chat) {
    super(chat);

    if (chat.user) {
      this.userId = chat.user.id;
      this.name = chat.user.name || chat.user.contacts?.email;
      this.photo = chat.user.photo;
      this.isOnline = chat.user.isOnline;
      this.archived = chat.user.archived;
      this.archivedAt = chat.user.archivedAt;
    }

    if (chat.bot) {
      this.bot = chat.bot;
    }

    if (chat.draft) {
      this.draft = chat.draft;
    }

    if (chat.workspaceId) {
      this.workspaceId = chat.workspaceId;
    }
  }
}

export class BoardGroupChatSummary extends BoardBaseChatSummary {
  description?: string | null;

  // @ts-expect-error TS2564
  users: User[];

  get type(): ChatTypes {
    return ChatTypes.GROUP;
  }

  // @ts-expect-error TS7006
  constructor(chat) {
    super(chat);

    if (chat.name) {
      this.name = chat.name;
    }

    if (chat.description) {
      this.description = chat.description;
    }

    if (chat.photo) {
      this.photo = chat.photo;
    }

    if (chat.users) {
      this.users = chat.users;
    }
  }
}

export class BoardContactChatSummary extends BoardBaseChatSummary {
  // @ts-expect-error TS2564
  userId: number;

  draft?: boolean;

  archived?: boolean;

  archivedAt?: boolean;

  get type(): ChatTypes {
    return ChatTypes.CONTACT;
  }

  // @ts-expect-error TS7006
  constructor(chat) {
    super(chat);

    if (chat.user) {
      this.userId = chat.id;
      this.name = chat.user.name || chat.user.contacts?.email;
      this.photo = chat.user.photo;
      this.archived = chat.user.archived;
      this.archivedAt = chat.user.archivedAt;
    }

    if (chat.draft) {
      this.draft = chat.draft;
    }
  }
}

export type BoardChatSummary = BoardDirectChatSummary | BoardGroupChatSummary | BoardContactChatSummary;

export function isBoardDirectChatSummary(chat: BoardChatSummary): chat is BoardDirectChatSummary {
  return chat.type === ChatTypes.DIRECT;
}

export function isBoardGroupChatSummary(chat: BoardChatSummary): chat is BoardGroupChatSummary {
  return chat.type === ChatTypes.GROUP;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChatBoardUserChats {
  [index: number]: BoardChatSummary;
  length: number;
}
