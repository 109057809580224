<div pui-dropdown [pui-dropdown-content]="stageDropdownContent" [items]="tags" pui-dropdown-align="center">
  <ng-container *ngIf="stage; else emptyClientStage">
    <ng-container *puiLet="getCurrentStage(stage) as tag">
      <span class="stage-item">
        <pui-badge [background]="tag.color" class="stage-item__badge" shape="square"></pui-badge>
        {{ tag.name }}
        <pui-icon svgIcon="pui:select" size="xs" class="stage-item__select-icon"></pui-icon>
      </span>
    </ng-container>
  </ng-container>

  <ng-template #emptyClientStage>
    <div class="stage-item stage-item_empty">
      <span i18n="@@tableActionChooseAClientStage:new" class="stage-item__value">Choose a stage</span>
      <pui-icon svgIcon="pui:select" size="xs" class="stage-item__select-icon"></pui-icon>
    </div>
  </ng-template>

  <ng-template #stageDropdownContent let-items="items" let-close="close">
    <ul pui-list>
      <li pui-list-item="button" *ngIf="stage" (click)="emitStageChanged(null); close()">
        <span class="stage-item stage-item_empty">Choose a stage</span>
      </li>
      <li
        pui-list-item="button"
        *ngFor="let item of items | filter: excludeCurrentTag(stage)"
        (click)="emitStageChanged(item.name); close()">
        <span class="stage-item">
          <pui-badge [background]="item.color" class="stage-item__badge" shape="square"></pui-badge>
          {{ item.name }}
        </span>
      </li>
    </ul>
  </ng-template>
</div>
