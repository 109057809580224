import {
  ExternalCalendarEventTypes,
  FullCalendarCustomEventCssClasses,
  FullCalendarEventTypesClassNames,
  FullCalendarSessionEventTypes
} from '@app/screens/guide/guide-sessions/types';
import { FullCalendarUnavailableTimeEventTypes } from '@app/screens/guide/guide-sessions/types/full-calendar-blocked-time-events';

export const fullCalendarEventTypesClassNames: FullCalendarEventTypesClassNames = {
  // After reschedule, session_offer it will change status. same class that "session_offer'.

  // FullCalendarSessionEventTypes
  [FullCalendarSessionEventTypes.FUTURE_SESSION]: [],
  [FullCalendarSessionEventTypes.SESSION_REQUEST]: [FullCalendarCustomEventCssClasses.PENDING_EVENT],
  [FullCalendarSessionEventTypes.PAST_SESSION]: [FullCalendarCustomEventCssClasses.PAST_SESSION],
  [FullCalendarSessionEventTypes.SESSION_OFFER]: [FullCalendarCustomEventCssClasses.PENDING_CONFIRMATION],
  [FullCalendarSessionEventTypes.GROUP_SESSION]: [FullCalendarCustomEventCssClasses.GROUP_SESSION],

  // ExternalCalendarEventTypes
  [ExternalCalendarEventTypes.GOOGLE]: [
    FullCalendarCustomEventCssClasses.CALENDAR_EVENT,
    FullCalendarCustomEventCssClasses.GOOGLE,
    FullCalendarCustomEventCssClasses.FULL_STORY_MASKED
  ],
  [ExternalCalendarEventTypes.OUTLOOK]: [
    FullCalendarCustomEventCssClasses.CALENDAR_EVENT,
    FullCalendarCustomEventCssClasses.OUTLOOK,
    FullCalendarCustomEventCssClasses.FULL_STORY_MASKED
  ],

  // FullCalendarBlockedTimeEventTypes
  [FullCalendarUnavailableTimeEventTypes.OTHER_WORKSPACE_EVENT]: [
    FullCalendarCustomEventCssClasses.CALENDAR_EVENT,
    FullCalendarCustomEventCssClasses.OTHER_WORKSPACE_EVENT
  ],
  [FullCalendarUnavailableTimeEventTypes.SCHEDULE_UNAVAILABILITY_INTERVAL]: [
    FullCalendarCustomEventCssClasses.BG_SCHEDULE
  ],
  [FullCalendarUnavailableTimeEventTypes.MY_TIME_BLOCK]: [
    FullCalendarCustomEventCssClasses.CALENDAR_EVENT,
    FullCalendarCustomEventCssClasses.OTHER_WORKSPACE_EVENT,
    FullCalendarCustomEventCssClasses.FULL_STORY_MASKED
  ],
  [FullCalendarUnavailableTimeEventTypes.OTHERS_TIME_BLOCK]: [FullCalendarCustomEventCssClasses.BG_BLOCK],
  [FullCalendarUnavailableTimeEventTypes.BUFFER_TIME]: [
    FullCalendarCustomEventCssClasses.BG_BLOCK,
    FullCalendarCustomEventCssClasses.BUFFER_TIME
  ]
};
