<section class="row" [formGroup]="form">
  <div class="col-md-3 offset-md-2 padding-right-md-05">
    <h3 class="section-title" i18n="@@pricing">Pricing</h3>

    <div class="section-note">
      <ng-container i18n="@@guideServicePricingEditorPricingNote">
        Set the cost and payment options for your
        <app-service-type [type]="type"></app-service-type>
        .
      </ng-container>
    </div>
  </div>

  <div class="col-md-6 padding-right-md-05">
    <div class="form-group">
      <div
        appRadioGroup
        class="program-free-radio"
        formControlName="paymentType"
        [disabled]="!!disablePaymentTypeChange"
        [ngClass]="{ 'price-type-disabled': disablePaymentTypeChange }"
        [attr.data-qa-id]="'guide-service-pricing-editor-payment-type-' + form.get('paymentType')?.value">
        <div
          class="payment-type-wrapper"
          [ngClass]="{
            'price-type-active': form.get('paymentType')?.value === ServicePricingTypes.Free
          }">
          <input appRadioInput type="radio" [value]="ServicePricingTypes.Free" id="program_free" />
          <label
            data-qa-id="guide-service-pricing-editor-free-radio-button"
            class="radio-label"
            for="program_free"
            i18n="@@free">
            Free
          </label>
          <p i18n="@@guideServicePricingEditorFreeNote" class="payment-type-note">Clients will not be charged.</p>

          <app-checkbox
            formControlName="doNotShowPrice"
            i18n="@@guideServicePricingDontShowFreeTag"
            class="do-not-show-price">
            Don't show free tag
          </app-checkbox>
          <ng-container *ngIf="isPackage">
            <ng-container *hasRuntimeFeature="'manageSubscriptionDates'">
              <div class="pui-form__row renew-container">
                <app-checkbox
                  formControlName="autoRenewalEnabled"
                  i18n="@@guideServicePricingAutoRenewalEnabled"
                  class="renew-container__checkbox">
                  Auto-renew sessions
                </app-checkbox>
                <ng-container *ngIf="form.get('autoRenewalEnabled')?.value === true">
                  <pui-select
                    class="pl-4 renew-container__select"
                    formControlName="autoRenewal"
                    puiPlaceholder="Choose a renewal period"
                    [virtualScroll]="false">
                    <pui-option [value]="AutoRenewalTypes.MONTHLY" label="Monthly"></pui-option>
                    <pui-option [value]="AutoRenewalTypes.ANNUAL" label="Annually"></pui-option>
                  </pui-select>

                  <div class="renew-container__description pui-space_top-2">
                    <small pui-typography="body-s">
                      Enabling auto-renewal of sessions will add sessions on the cadence chosen. You will be able to
                      edit the renewal date for each client once the client is enrolled.
                    </small>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div
          class="payment-type-wrapper"
          [ngClass]="{
            'price-type-active': form.get('paymentType')?.value === ServicePricingTypes.Paid
          }">
          <input
            appRadioInput
            type="radio"
            [value]="ServicePricingTypes.Paid"
            (change)="pricingChanged.emit($event.target.value)"
            id="program_paid" />

          <label class="radio-label" for="program_paid" i18n="@@oneTimePayment">One-time payment</label>
          <p i18n="@@guideServicePricingEditorOneTimePaymentNote" class="payment-type-note">
            Clients will be charged immediately after checkout or in installments.
          </p>

          <div *ngIf="form.get('paymentType')?.value === ServicePricingTypes.Paid" class="payment-type-data">
            <app-price-input
              class="mb-3"
              formControlName="price"
              [currencySign]="currency.defaultCurrencySign"></app-price-input>

            <app-checkbox
              formControlName="isInstallmentsEnabled"
              i18n="@@allowMyClientsToPay"
              [disabled]="disabledInstallments">
              Allow my clients to pay in installments
            </app-checkbox>

            <div *ngIf="form.get('isInstallmentsEnabled').value" class="installments-wrap">
              <div class="subscription-container">
                <app-price-input
                  [currencySign]="currency.defaultCurrencySign"
                  formControlName="subscriptionPrice"></app-price-input>
                <div class="subscription-recurrency">
                  <span i18n="@@per">per</span>
                  <ng-select
                    [searchable]="false"
                    [clearable]="false"
                    formControlName="subscriptionRecurrency"
                    [readonly]="disablePaymentTypeChange">
                    <ng-option [value]="SubscriptionRecurrency.Week" i18n="@@weekLower">week</ng-option>
                    <ng-option [value]="SubscriptionRecurrency.Month" i18n="@@monthLower">month</ng-option>
                    <ng-option [value]="SubscriptionRecurrency.Year" i18n="@@yearLower">year</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="subscription-length">
                <label i18n="@@totalPayments" class="label">Total payments</label>
                <input
                  type="number"
                  min="0"
                  formControlName="totalPayments"
                  class="form-control program-price total-payments" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="payment-type-wrapper"
          [ngClass]="{
            'price-type-active': form.get('paymentType')?.value === ServicePricingTypes.Subscription
          }">
          <input
            appRadioInput
            type="radio"
            [value]="ServicePricingTypes.Subscription"
            (change)="pricingChanged.emit($event?.target?.value)"
            id="program_subscription" />

          <label
            data-qa-id="guide-service-pricing-editor-subscription-radio-button"
            class="radio-label"
            for="program_subscription"
            i18n="@@guideServiceEditorSubscription">
            Subscription
          </label>
          <p i18n="@@guideServicePricingEditorRecurringSubscriptionNote" class="payment-type-note">
            Clients will be charged weekly, monthly, or yearly until their subscription is canceled.
          </p>
          <p
            i18n="@@guideServicePricingEditorRecurringSubscriptionPackageNote"
            class="payment-type-data payment-type-note-package"
            *ngIf="type === GuideServiceTypes.PACKAGE">
            Sessions are renewed every month.
          </p>

          <div *ngIf="form.get('paymentType')?.value === ServicePricingTypes.Subscription" class="payment-type-data">
            <div class="subscription-container">
              <app-price-input
                qaId="guide-service-pricing-editor-price-input"
                [currencySign]="currency.defaultCurrencySign"
                formControlName="subscriptionPrice"></app-price-input>
              <div class="subscription-recurrency">
                <span i18n="@@per">per</span>
                <ng-select
                  data-qa-id="guide-service-pricing-editor-per-unit-select"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="subscriptionRecurrency"
                  [readonly]="disablePaymentTypeChange">
                  <ng-option [value]="SubscriptionRecurrency.Week" i18n="@@weekLower">week</ng-option>
                  <ng-option [value]="SubscriptionRecurrency.Month" i18n="@@monthLower">month</ng-option>
                  <ng-option [value]="SubscriptionRecurrency.Year" i18n="@@yearLower">year</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <span
            *ngIf="
              form.get('paymentType')?.value === ServicePricingTypes.Subscription &&
              disablePaymentTypeChange &&
              !subscriptionInfo.subscriptionDeactivated
            ">
            <ng-container i18n="@@contactToDeactivateOfferV2">
              If you need to deactivate an offer, please
              <a href="javascript: void(0)" *ngIf="currentPlatformName === mainPlatformName" (click)="openIntercom()"
                >contact</a
              >
              <a href="{{ contactEmail }}" *ngIf="currentPlatformName !== mainPlatformName">contact</a> our support
              team</ng-container
            >.
          </span>
          <span *ngIf="willSubscriptionBeDeactivated" i18n="@@offerWillBeDeactivatedOn" class="deactivate-offer-text">
            Offer will be deactivated on
            {{ subscriptionInfo.subscriptionDeactivatedDate | date: 'dd MMM yyyy, hh:mm a' }}
          </span>
          <span *ngIf="wasSubscriptionDeactivated" class="deactivate-offer-text">
            <ng-container i18n="@@guideServicePricingEditorOfferDeactivatedOn">Offer deactivated on</ng-container>
            {{ subscriptionInfo.subscriptionDeactivatedDate | date: 'dd MMM yyyy, hh:mm a' }}
          </span>
        </div>

        <div class="error" i18n="@@invalidPrice" *ngIf="form.dirty && form.touched && form.errors?.invalidPrice">
          Invalid price
        </div>
      </div>
    </div>
  </div>
</section>
