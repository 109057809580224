import { DateTime } from 'luxon';
import {
  AvailabilityDateNumbers,
  AvailabilityTemplate,
  AvailabilityTemplates
} from '@app/screens/guide/guide-sessions-templates/types';

export interface FormattedAvailability {
  days: string[];
  values: string[];
  timezone: string;
}

const ALL_DAY = '00:00_00:00';
const WEEKDAYS = 'Weekdays';
const WEEKDAYS_COUNT = 5;
const SHORT_HOUR_FORMAT = 'ha';
const SHORT_WEEK_DAY_NAME = 'ccc';

const createWorkingsHoursMap = (data: AvailabilityTemplates): { [key: string]: string[] } => {
  const dataMap: { [key: string]: string[] } = {};

  Object.keys(data).forEach((key: AvailabilityDateNumbers) => {
    const daysArr: AvailabilityTemplate[] = data[key];

    daysArr.forEach((day: AvailabilityTemplate) => {
      const dataMapKey = `${day.start}_${day.end}`;

      if (dataMap.hasOwnProperty(dataMapKey)) {
        if (!dataMap[dataMapKey].includes(key)) {
          dataMap[dataMapKey].push(key);
        }
      } else {
        dataMap[dataMapKey] = [key];
      }
    });
  });

  return dataMap;
};

const createWorkingHoursFlatArr = (
  dataMap: { [key: string]: string[] },
  timezoneFromUserSettings: string
): FormattedAvailability[] => {
  const dataArr: FormattedAvailability[] = [];

  Object.keys(dataMap).forEach(key => {
    if (key === ALL_DAY) {
      return;
    }

    dataArr.push({
      days: dataMap[key],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      values: splitWorkingHoursSlots(key),
      timezone: DateTime.local().setZone(timezoneFromUserSettings).toFormat('ZZZZ')
    });
  });

  return dataArr;
};

const splitWorkingHoursSlots = (slots: string) => {
  return slots.split('_').map(slotTime => {
    const result = DateTime.fromFormat(slotTime, 'hh:mm').toFormat('hh:mma').toLowerCase();

    return result;
  });
};

const convertDaysToString = (dataArr: FormattedAvailability[]): FormattedAvailability[] => {
  const today = DateTime.local();

  const dataArrWithWeekDayName = dataArr.map((data: FormattedAvailability) => {
    return {
      timezone: data.timezone,
      values: data.values,
      days: data.days.map((day: string) => {
        const weekDay = +day - today.weekday;

        return today.plus({ day: weekDay }).toFormat(SHORT_WEEK_DAY_NAME);
      })
    };
  });

  return dataArrWithWeekDayName;
};

export const converterWorkingHours = (
  workingHours: AvailabilityTemplates,
  timezoneFromUserSettings: string
): FormattedAvailability[] => {
  const workingsHoursMap = createWorkingsHoursMap(workingHours);
  const workingHoursFlatArr = createWorkingHoursFlatArr(workingsHoursMap, timezoneFromUserSettings);
  const stringDays = convertDaysToString(workingHoursFlatArr);

  if (stringDays.length === 1 && stringDays[0].days.length === WEEKDAYS_COUNT) {
    stringDays[0].days = [WEEKDAYS];
  }

  return stringDays;
};
