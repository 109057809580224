import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IArticleSelection } from '../../types';

@Component({
  selector: 'app-blog-article-latest-articles',
  templateUrl: 'blog-article-latest-articles.component.html',
  styleUrls: ['./blog-article-latest-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleLatestArticlesComponent {
  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  articles: any[];

  @Output()
  selectArticle = new EventEmitter<IArticleSelection>();

  @Output()
  selectAuthor = new EventEmitter<string>();

  emitSelectArticle(event: IArticleSelection): void {
    this.selectArticle.emit(event);
  }

  emitSelectAuthor(authorUrl: string): void {
    this.selectAuthor.emit(authorUrl);
  }
}
