<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.user?.photo"
    [text]="notification.details?.user | userName"></pui-avatar>
  <dt class="notification-title">
    <ng-container [ngSwitch]="notification.description">
      <ng-container *ngSwitchCase="notificationType.WORKSPACE_ADMIN_SCHEDULED_SESSION_ACCEPTED_BY_CLIENT">
        <span
          [ngClass]="{ 'client-name': !!notification.details?.user?.id }"
          (click)="notification.details?.user?.id && showClientDetails(notification.details?.user?.id)">
          {{ memberName }}
        </span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span [ngClass]="{ 'client-name': !!clientId }" (click)="clientId && showClientDetails(clientId)">
          {{ memberName }}
        </span>
      </ng-container>
    </ng-container>

    <span class="notification-date">{{ notification.dateCreated | notificationDateFormatter }}</span>
  </dt>
  <dd class="event-status" [ngClass]="statusClasses[notification.type]">
    <span class="status-icon" [ngClass]="statusClasses[notification.type]"></span>
    <ng-container [ngSwitch]="notification.description">
      <ng-container *ngSwitchCase="notificationType.MEMBER_JOINED">
        <span class="status-text" i18n="@@workspaceNotificationMemberJoinMessage">
          {{ memberName }} accepted your invitation and joined {{ notification.details.workspaceName }}.
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.SERVICE_CREATED">
        <span class="status-text" i18n="@@workspaceNotificationServiceCreateMessage">
          {{ memberName }}
          has created
          <a [routerLink]="serviceLandingLinkParts">{{ notification.details.service.name }}</a>
          .
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.HOST_ADDED">
        <span class="status-text">
          <ng-container *ngIf="notification.details?.user?.namedUrl">
            <a [routerLink]="['/', notification.details.user.namedUrl]">{{ memberName }}</a>
          </ng-container>
          <ng-container *ngIf="!notification.details?.user?.namedUrl">
            {{ memberName }}
          </ng-container>
          assigned
          <a [routerLink]="serviceLandingLinkParts">{{ notification.details.service.name }}</a>
          to you.
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.MODULE_HOST_ADDED">
        <span class="status-text" i18n="@@workspaceNotificationModuleHostAddedMessage">
          <ng-container *ngIf="notification.details?.user?.namedUrl">
            <a [routerLink]="['/', notification.details.user.namedUrl]">{{ memberName }}</a>
          </ng-container>
          <ng-container *ngIf="!notification.details?.user?.namedUrl">
            {{ memberName }}
          </ng-container>
          has assigned {{ notification.details.service.moduleName }} in
          <a [routerLink]="serviceLandingLinkParts">{{ notification.details.service.name }}</a>
          to you.
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.SERVICE_BOOKED">
        <span class="status-text" i18n="@@workspaceNotificationServiceBookMessage">
          {{ memberName }} booked
          <a [routerLink]="serviceLandingLinkParts">{{ notification.details.service.name }}</a>
          hosted by {{ hostName }}.
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.MEMBER_INVITED">
        <span class="status-text" i18n="@@workspaceNotificationMemberInvitedMessage">
          {{ memberName }}
          has invited you to join
          <span class="status-text__pointer" (click)="changeWorkspace(notification.details.workspaceId)">
            {{ notification.details.workspaceName }}
          </span>
          .
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.CLIENT_ASSIGNED">
        <span class="status-text" i18n="@@workspaceNotificationClientAssignedMessageV2">
          {{ hostName }} assigned
          <span
            [ngClass]="{ 'client-name': !!notification.details?.user?.id }"
            (click)="notification.details?.user?.id && showClientDetails(notification.details?.user?.id)">
            {{ notification.details?.user?.firstName }} {{ notification.details?.user?.lastName }}
          </span>
          to you.
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.WORKSPACE_ADMIN_OFFER_DECLINED_BY_CLIENT">
        <span class="status-text" i18n="@@workspaceNotificationAdminOfferForTeamMemberDeclineByClient">
          Session declined
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.WORKSPACE_OFFER_CANCELED_BY_ADMIN">
        <span class="status-text" i18n="@@workspaceNotificationOfferDeclinedByAdminV3">
          Canceled: Session with
          <span
            [ngClass]="{ 'client-name': !!notification.details?.client?.id }"
            (click)="notification.details?.client?.id && showClientDetails(notification.details?.client?.id)"
            >{{ notification.details?.client?.firstName }} {{ notification.details?.client?.lastName }}</span
          >
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.WORKSPACE_SESSION_RESCHEDULED_BY_ADMIN">
        <span class="status-text">
          {{ notification.details?.workspaceAdmin?.firstName }}
          {{ notification.details?.workspaceAdmin?.lastName }} rescheduled {{ notification.details?.sessionName }}
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.WORKSPACE_SESSION_RESCHEDULED_BY_GUIDE">
        <span class="status-text" i18n="@@workspaceNotificationSessionRescheduledByGuide">
          Session {{ notification.details?.sessionName }} reschedule
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.WORKSPACE_ADMIN_SCHEDULED_SESSION_CANCELED_BY_CLIENT">
        <span class="status-text" i18n="@@workspaceNotificationAdminScheduledSessionCanceledByClient">
          Session cancelled
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="notificationType.WORKSPACE_ADMIN_SCHEDULED_SESSION_ACCEPTED_BY_CLIENT">
        <span class="status-text" i18n="@@workspaceNotificationAdminScheduledSessionAcceptedByClient">
          Reschedule approved
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.WORKSPACE_ADMIN_SESSION_OFFER_CREATED">
        <span class="status-text" i18n="@@workspaceNotificationSessionCreatedByAdmin">
          {{ notification.details?.workspaceAdmin?.firstName }}
          {{ notification.details?.workspaceAdmin?.lastName }} has scheduled a session for you
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.WORKSPACE_GUIDE_SESSION_OFFER_CREATED">
        <span class="status-text" i18n="@@workspaceNotificationSessionOfferCreatedByGuide">Offered a session</span>
      </ng-container>
      <ng-container *ngSwitchCase="notificationType.WORKSPACE_SESSION_CANCELED_BY_HOST">
        <span class="status-text" i18n="@@workspaceNotificationSessionCanceledByHost">
          {{ hostName }} canceled {{ notification.details?.sessionName }}
        </span>
      </ng-container>
    </ng-container>

    <ng-container [ngSwitch]="notification.type">
      <ng-container *ngSwitchCase="systemNotificationTypes.WORKSPACE_SESSION_CANCELED_BY_ADMIN">
        <span class="status-text">
          {{ notification.description }}
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="systemNotificationTypes.PROGRAM_CLIENT_COMPLETE_MODULE">
        <span class="status-text">
          {{ notification.description }}
        </span>
      </ng-container>
    </ng-container>
  </dd>
</dl>
