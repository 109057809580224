import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, Subscription } from 'rxjs';
import { tap, map, mergeMap, take, catchError, delay, filter } from 'rxjs/operators';

import config from '@app/core/config/config';

@Injectable()
export class PhoneVerificationService {
  private REQUEST_WAITING_TIME = 5 * 60;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timerSub: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _secondsForWaiting: BehaviorSubject<number> = new BehaviorSubject(0);

  get secondsForWaiting(): Observable<number> {
    return this._secondsForWaiting.asObservable();
  }

  constructor(private _http: HttpClient) {}

  generateCode(): Observable<boolean> {
    this.initTimer();

    return this.secondsForWaiting.pipe(
      take(1),
      mergeMap(seconds => (seconds > 0 ? throwError(new Error('already_generated')) : this.sendCodeRequest()))
    );
  }

  verifyCode(code: number): Observable<boolean> {
    return this.sendCode(code);
  }

  private initTimer(): void {
    this.clearTimer();

    this._timerSub = this.secondsForWaiting
      .pipe(
        filter(seconds => seconds > 0),
        delay(1000)
      )
      .subscribe(seconds => this._secondsForWaiting.next(seconds - 1));
  }

  private clearTimer(): void {
    if (this._timerSub) {
      this._timerSub.unsubscribe();
    }
  }

  private sendCodeRequest(): Observable<boolean> {
    return this._http.get(`${config.apiPath}/user/common/phone-verification/request`).pipe(
      catchError(this.catchErrorHandler),
      tap(() => this._secondsForWaiting.next(this.REQUEST_WAITING_TIME)),
      map(() => true)
    );
  }

  private sendCode(code: number): Observable<boolean> {
    return this._http.post(`${config.apiPath}/user/common/phone-verification/verify`, { code }).pipe(
      catchError(this.catchErrorHandler),
      map(() => true)
    );
  }

  // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
  private catchErrorHandler(e: any): Observable<never> {
    if (
      typeof e === 'object' &&
      typeof e.error === 'object' &&
      e.error.errors instanceof Array &&
      e.error.errors[0] &&
      e.error.errors[0].code
    ) {
      return throwError(new Error(e.error.errors[0].code));
    }
    return throwError(e);
  }
}
