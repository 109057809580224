import { Component, Input, EventEmitter, Output } from '@angular/core';
import { WistiaVideo } from '../../interfaces';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})
export class VideoGalleryComponent {
  // @ts-expect-error TS2564
  selectedVideo: number;

  @Input()
  videos: WistiaVideo[] = [];

  @Output()
  selectVideo = new EventEmitter<WistiaVideo>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  changeVideo(index: number) {
    this.selectedVideo = index;
    this.selectVideo.emit(this.videos[index]);
  }
}
