enum SocketEvents {
  EMAIL_VERIFICATION = 'email_verification',
  EXTEND = 'extend',
  EXTEND_ACCEPT = 'extend_accept',
  EXTEND_DECLINE = 'extend_decline',
  EXTERNAL_CALENDARS_CONNECTED = 'external_calendars_connected',
  EXTERNAL_CALENDARS_DISCONNECTED = 'external_calendars_disconnected',
  EXTERNAL_CALENDAR_CONNECTED = 'external_calendar_connected',
  EXTERNAL_CALENDAR_DISCONNECTED = 'external_calendar_disconnected',
  EXTERNAL_EVENTS_REMOVED = 'external_events_removed',
  EXTERNAL_EVENTS_UPDATE = 'external_events_update',
  GET_ONLINE = 'get-online',
  GOOGLE_CALENDAR_ERROR = 'google_calendar_error',
  GUIDE_CLIENTS_UPDATE = 'guide-clients-update',
  GUIDE_CONFIRMATION = 'guide_confirmation',
  GUIDE_PROFILE_NOTIFICATION = 'guide_profile_notification',
  GUIDE_RELATIONS_UPDATE = 'guide_relations_update',
  NEGATIVE_BALANCE_NOTIFICATION = 'negative-balance-notification',
  PROGRAM_ENROLLED = 'program-enrolled',
  PACKAGE_ENROLLED = 'package_enrolled',
  POST_SESSION_NOTIFICATION = 'post_session_notification',
  PROGRAMS_CHANGE = 'programs_change',
  SESSIONS_CHANGED = 'sessions_changed',
  SESSION_CLOSE = 'session_close',
  STRIPE_AUTH = 'stripe_auth',
  SURVEY = 'survey',
  UPDATE_MEMBERSHIP_SETTINGS = 'update_membership_settings',
  USER_NOTES_CREATE = 'user_notes_create',
  USER_NOTES_DELETE = 'user_notes_delete',
  USER_NOTES_GRANT_ACCESS = 'user_notes_grant_access',
  USER_NOTES_REVOKE_ACCESS = 'user_notes_revoke_access',
  USER_NOTES_UPDATE = 'user_notes_update',
  USER_WALLET_NOTIFICATION = 'user_wallet_notification',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  USER_WALLET_UPDATE = 'user_wallet_update'
}

export { SocketEvents };
