<section class="form-group" [class.invalid]="inputDirective?.error$ | async">
  <header>
    <ng-content select="app-input-label"></ng-content>
  </header>

  <main #main>
    <ng-content #input select="[appInput]"></ng-content>
    <ng-content></ng-content>
  </main>

  <footer></footer>
</section>
