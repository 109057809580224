<app-guide-service
  [service]="service"
  [badges]="badges || [defaultBadge]"
  [details]="details || defaultDetails"></app-guide-service>

<ng-template #defaultBadge>
  <app-guide-service-badge
    text="Webinar"
    i18n-text="@@guideServiceItemWebinarBadge"
    class="webinar-badge"></app-guide-service-badge>
</ng-template>

<ng-template #defaultDetails></ng-template>
