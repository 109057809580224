import { Injectable } from '@angular/core';
import { GuideOfferApiService } from '@app/core/session/guide-offer-api.service';
import { ISchedule } from '@app/modules/schedule-boards';
import { ScheduleProvider } from './schedule-provider.service';
import { IPrivateGuideScheduleOptions } from './types';

@Injectable()
export class PrivateGuideScheduleProviderService extends ScheduleProvider<ISchedule, IPrivateGuideScheduleOptions> {
  constructor(protected readonly _privateGuideScheduleApi: GuideOfferApiService) {
    super();
  }

  refresh(options: IPrivateGuideScheduleOptions): void {
    if (options.token) {
      this._privateGuideScheduleApi
        .getFreeTimeRangesByActionCode$(options.token, options.date)
        .subscribe(schedule => this._schedule$.next(schedule));
    } else {
      this._privateGuideScheduleApi
        .getFreeTimeRanges$(options.date, { clientId: options.clientId, exEvent: options.exEvent })
        .subscribe(ranges => this._schedule$.next({ ranges }));
    }
  }
}
