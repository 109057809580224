import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { BillingService } from '@app/modules/billing/services';
import { BrandingService } from '@app/core/branding/branding.service';
import { AbsComponentWithBilling } from '@app/modules/billing/abs';
import { IBillingInfo } from '@app/modules/billing/interfaces';

@Component({
  selector: 'app-service-billing',
  templateUrl: './service-billing.component.html',
  styleUrls: ['./service-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceBillingComponent extends AbsComponentWithBilling {
  @Output()
  billingDataChange: EventEmitter<IBillingInfo> = new EventEmitter<IBillingInfo>();

  constructor(
    readonly _billingService: BillingService,
    readonly _configProvider: BrandingService,
    readonly _cdr: ChangeDetectorRef
  ) {
    super(_billingService, _configProvider, _cdr);
  }
}
