import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { of, merge, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ChatsService } from '@app/core/chat/chats.service';
import { IChatBoardUserChats, isBoardGroupChatSummary } from '../../types';
import { convertFromUserChatToChatListItem } from '../../utils/converters';
import { isContactChatSummary } from '@app/core/chat/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-chats-board',
  templateUrl: './chats-board.component.html',
  styleUrls: ['./chats-board.component.scss']
})
export class ChatsBoardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _routeId: string | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _selectedChatId: string | null = null;

  chats: IChatBoardUserChats = [];

  get selectedChatId(): string | null {
    return this._selectedChatId;
  }

  constructor(
    public route: ActivatedRoute,
    private _router: Router,
    private _chat: ChatsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._chat.chatsSummaries$
      .pipe(
        // TODO Remove contact chats. Fixed issue [PR-10916]
        map(chats => chats.filter(chat => !isContactChatSummary(chat))),
        map(chats => chats.map(convertFromUserChatToChatListItem)),
        map(chats => {
          // eslint-disable-next-line id-length
          return chats.sort((a, b) => {
            let aCompareDate: string;
            let bCompareDate: string;

            if (isBoardGroupChatSummary(a)) {
              // @ts-expect-error TS2322
              aCompareDate = a.lastActiveDate;
            } else {
              // @ts-expect-error TS2322
              aCompareDate = a.archived && a.archivedAt > a.lastActiveDate ? '' : a.lastActiveDate;
            }

            if (isBoardGroupChatSummary(b)) {
              // @ts-expect-error TS2322
              bCompareDate = b.lastActiveDate;
            } else {
              // @ts-expect-error TS2322
              bCompareDate = b.archived && b.archivedAt > b.lastActiveDate ? '' : b.lastActiveDate;
            }

            return aCompareDate >= bCompareDate ? -1 : 1;
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(chats => {
        this.chats = chats;
        this.updateSelectedChatId();

        this.changeDetector.markForCheck();
      });

    this.setRouteSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectChat({ chatId, workspaceId }: { chatId: string; workspaceId: number }): void {
    this._router.navigate([chatId], {
      relativeTo: this.route,
      queryParams: { workspaceId }
    });
  }

  private setRouteSubscription(): void {
    // @ts-expect-error TS2531
    const initialParams$ = this.route.firstChild.params;

    // ATTENTION: router issue, activated route observables observed from parent doesn't emit changes
    // BUG: https://github.com/angular/angular/issues/11692
    // HACK: https://github.com/angular/angular/issues/11692#issuecomment-367500985
    const nextParams$ = this._router.events.pipe(
      // eslint-disable-next-line id-length
      filter(e => e instanceof NavigationEnd),
      switchMap(() => (this.route.firstChild && this.route.firstChild.params) || of({}))
    );

    merge(initialParams$, nextParams$)
      .pipe(
        map(params => (params && params.id ? params.id : null)),
        takeUntil(this.destroy$)
      )
      .subscribe(id => {
        this._routeId = id;
        this.updateSelectedChatId();
      });
  }

  private updateSelectedChatId(): void {
    this._selectedChatId = this._routeId != null ? this._chat.resolveChatId(this._routeId) : null;
  }
}
