import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateValueAndValidityDirective } from './update-value-and-validity.directive';
import { UpdateValueAndValidityService } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.service';

@NgModule({
  declarations: [UpdateValueAndValidityDirective],
  imports: [CommonModule],
  providers: [UpdateValueAndValidityService],
  exports: [UpdateValueAndValidityDirective]
})
export class UpdateValueAndValidityModule {}
