import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChatsService } from '@app/core/chat/chats.service';
import { DirectChatSummary } from '@app/core/chat/types';
import { IQuizBot } from '@app/core/quizzes/types';
import { QuizService } from '@app/core/quizzes/quiz.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  quizzes: IQuizBot[] = [];

  constructor(private readonly _quizService: QuizService, private readonly _chat: ChatsService) {}

  ngOnInit(): void {
    this._quizService.getUsers$();

    combineLatest([this._chat.chatsSummaries$, this._quizService.usersReplay$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([chats, quizzes]) => {
        this.quizzes = quizzes.map(quiz => {
          const chat = (chats as DirectChatSummary[]).find(_chat => _chat.user && Math.abs(_chat.user.id) === quiz.id);
          if (chat) {
            quiz.chatId = chat.id;
          }
          return quiz;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isQuizReadyToFill(quiz: IQuizBot): boolean {
    if (!quiz.lastQuestionDate) {
      return false;
    }
    const now = DateTime.local();
    return DateTime.fromISO(quiz.lastQuestionDate).plus({ hour: 8 }).toSeconds() > now.toSeconds();
  }
}
