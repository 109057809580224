import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { NotFoundComponent } from '@app/modules/not-found/not-found.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [NotFoundComponent],
  exports: [NotFoundComponent],
  providers: []
})
export class NotFoundModule {}
