import { Navigation } from '@angular/router';

export function extractUrlFromRouterNavigation(navigation: Navigation): string {
  let url = '/';
  if (navigation) {
    url = navigation.extractedUrl.toString();
  }

  return url;
}
