<app-guide-service
  [service]="service"
  [badges]="badges || [defaultBadge]"
  [details]="details || defaultDetails"
  [showMore]="showMore"
  [team]="team"
  [editorLink]="editorLink"
  [publicLink]="publicLink"
  [copyText]="copyText"></app-guide-service>

<ng-template #team>
  <app-guide-service-team [team]="service.team"></app-guide-service-team>
</ng-template>

<ng-template #defaultBadge>
  <app-guide-service-badge
    [attr.data-qa-id]="'guide-package-service-badge-' + service.name"
    text="Package"
    i18n-text="@@guideServiceItemPackageBadge"
    class="package-badge"></app-guide-service-badge>
</ng-template>

<ng-template #defaultDetails>
  <div *ngIf="service.sessions && service.sessions.length" class="service-details package-details">
    <ng-container *ngTemplateOutlet="packageSessions; context: { $implicit: service.sessions }"></ng-container>
  </div>
</ng-template>

<ng-template #packageSessions let-sessions>
  <div
    [attr.data-qa-id]="'guide-package-service-session-item-' + session.template.name"
    *ngFor="let session of sessions; let isLast = last"
    class="package-sessions mr-1">
    <pui-icon svgIcon="pui:grid" size="s" aria-hidden="true"></pui-icon>
    {{ session.count }} x {{ session.template.name }}
    <span *ngIf="!isLast">,</span>
  </div>
</ng-template>
