import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AlvisService } from '@libs/services/alvis/alvis.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AlvisEmbeddedViewComponent } from './alvis-embedded-view.component';

@NgModule({
  declarations: [AlvisEmbeddedViewComponent],
  providers: [AlvisService],
  exports: [AlvisEmbeddedViewComponent]
})
export class AlvisModule {
  static forRoot() {
    return {
      ngModule: AlvisModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (router: Router, alvisService: AlvisService) => () => {
            router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
              alvisService.emit('page');
            });
          },
          deps: [Router, AlvisService],
          multi: true
        }
      ]
    };
  }
}
