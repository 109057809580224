<div class="block">
  <app-quiz-info [clientQuiz]="clientQuiz"></app-quiz-info>
</div>

<form class="questions-form" [formGroup]="form">
  <app-module-quiz
    formControlName="answers"
    [editableAnswers]="editMode"
    [isCorrectDisplay]="false"
    [displayKey]="false"></app-module-quiz>

  <div class="controls">
    <button type="button" class="submit" (click)="saveAndGoToNextQuiz()" [disabled]="submitting">
      <span i18n="@@submit">Submit</span>
    </button>

    <button class="skip" type="button" (click)="saveAndGoToNextQuiz(true)">
      <span i18n="@@answerLater">Answer later on</span>
    </button>
  </div>
</form>
