import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { RangeSliderModule } from '@app/modules/ui-kit/range-slider/range-slider.module';
import { CollapseGroupModule } from '@app/modules/ui-kit/collapse-group/collapse-group.module';
import { CheckListModule } from '@app/modules/ui-kit/check-list/check-list.module';
import { RangeDatepickerModule } from '@app/modules/ui-kit/range-datepicker/range-datepicker.module';
import { ScrollbarModule } from '@app/modules/scrollbar/scrollbar.module';

import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { MarketplaceFilterPanelComponent } from './components/marketplace-filter-panel/marketplace-filter-panel.component';

import { MarketplaceFilterSidebarComponent } from './components/marketplace-filter-sidebar/marketplace-filter-sidebar.component';
import { MarketplaceSortingFilterComponent } from './components/marketplace-sorting-filter/marketplace-sorting-filter.component';
import { MarketplaceUserTypeaheadComponent } from './components/marketplace-user-typeahead/marketplace-user-typeahead.component';
import { PlatformStyleModule } from '../platform-style/platform-style.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  declarations: [
    FilterDropdownComponent,
    MarketplaceFilterPanelComponent,
    MarketplaceFilterSidebarComponent,
    MarketplaceSortingFilterComponent,
    MarketplaceUserTypeaheadComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    RangeSliderModule,
    CollapseGroupModule,
    CheckListModule,
    RangeDatepickerModule,
    ScrollbarModule,
    PlatformStyleModule,
    TranslocoRootModule
  ],
  exports: [
    FilterDropdownComponent,
    MarketplaceFilterPanelComponent,
    MarketplaceFilterSidebarComponent,
    MarketplaceSortingFilterComponent
  ]
})
export class MarketplaceFiltersModule {}
