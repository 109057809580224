<div class="modal-header">
  <h4 class="modal-title" i18n="@@shareNoteConfirmModalComponentTitle">Share this note?</h4>

  <app-button class="close-btn" className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <div class="content">
    <p class="question" i18n="@@shareNoteConfirmModalComponentQuestionWithName">
      Your client, {{ name }}, will be able to view but not edit your note.
    </p>
    <div class="client-info">
      <!--      <app-user-photo [photoUrl]="photo" [size]="35"></app-user-photo>-->
      <pui-avatar [avatarUrl]="photo" [text]="name" [style.width]="35" [style.height]="35"></pui-avatar>
      <div class="client-info-text">
        <p>{{ email }}</p>
        <p class="client-name">{{ name }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <app-button className="btn-lite" i18n="@@shareNoteConfirmModalComponentCancel" (click)="cancel()">Cancel</app-button>
  <app-button className="btn-primary btn-lg" i18n="@@shareNoteConfirmModalComponentShare" (click)="save()">
    Share
  </app-button>
</div>
