import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from './services/auth.service';

@Injectable()
export class SuperAdminGuard implements CanLoad, CanActivate {
  constructor(private readonly _router: Router, private readonly _auth: AuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.passThroughOrReAuth();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree {
    return this.passThroughOrReAuth();
  }

  private passThroughOrReAuth(): boolean | UrlTree {
    const isSuperAdmin = this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.SUPER_ADMIN;

    return isSuperAdmin || this._router.createUrlTree(['/auth/sign-in'], {});
  }
}
