import { Component, Input, ViewChild } from '@angular/core';
import { AbstractRangeSliderComponent } from '../abstract-range-slider/abstract-range-slider.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-marketplace-range-slider',
  templateUrl: './marketplace-range-slider.component.html',
  styleUrls: ['./marketplace-range-slider.component.scss']
})
export class MarketplaceRangeSliderComponent {
  // @ts-expect-error TS2564
  @ViewChild('abstractSlider', { static: true }) abstractSlider: AbstractRangeSliderComponent;

  @Input()
  // @ts-expect-error TS2564
  fromIndicatorValue: string;

  @Input()
  // @ts-expect-error TS2564
  toIndicatorValue: string;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this.abstractSlider.getForm();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm(data) {
    return this.abstractSlider.updateForm(data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetForm() {
    return this.abstractSlider.resetForm();
  }
}
