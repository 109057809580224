import { IStorageFileInfo } from '@app/core/files/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientQuestionAnswerDetails {
  readonly text: string;
  readonly file?: IStorageFileInfo;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientChoiceQuestionAnswerDetails {
  readonly text?: string;
  readonly optionText: string;
  readonly isCorrect: boolean;
  readonly optionId: number;
  readonly order: number;
}

export class ClientQuestionAnswer {
  readonly file: IStorageFileInfo;

  readonly text: string;

  constructor(answerDetails: IClientQuestionAnswerDetails) {
    if (!answerDetails) {
      throw new Error('Invalid argument');
    }

    // @ts-expect-error TS2322
    this.file = answerDetails.file;
    this.text = answerDetails.text;
  }
}

export class ClientChoiceQuestionAnswer {
  readonly text?: string;

  readonly optionText: string;

  readonly isCorrect: boolean;

  readonly optionId: number;

  readonly order: number;

  constructor(answerDetails: IClientChoiceQuestionAnswerDetails) {
    if (!answerDetails) {
      throw new Error('Invalid argument');
    }

    this.optionText = answerDetails.optionText;
    this.isCorrect = answerDetails.isCorrect;
    this.optionId = answerDetails.optionId;
    this.order = answerDetails.order;

    if (answerDetails.text != null) {
      this.text = answerDetails.text;
    }
  }
}
