import { User } from '@app/shared/interfaces/user';
import { assertNever } from '@app/shared/utils/assertions';
import { IWorkspace } from '@app/modules/workspaces/types';
import { ChatTypes } from './chat-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseChatSummaryArgs {
  id: string;
  notificationsCount?: number | null;
  messages: {
    text: string;
    date: string;
  }[];
  createdAt?: string;
}

export abstract class BaseChatSummary {
  id: string;

  lastMessage?: string | null;

  lastMessageDate?: string | null;

  notificationsCount?: number | null;

  createdAt?: string;

  // @ts-expect-error TS2564
  workspaceId: IWorkspace['id'];

  get lastActiveDate(): string | null {
    // @ts-expect-error TS2322
    return this.lastMessageDate || this.createdAt;
  }

  // @ts-expect-error TS7008
  abstract type;

  constructor(baseChatSummary: IBaseChatSummaryArgs) {
    this.id = baseChatSummary.id;
    this.createdAt = baseChatSummary.createdAt;

    if (baseChatSummary.notificationsCount !== undefined) {
      this.notificationsCount = baseChatSummary.notificationsCount;
    }

    if (baseChatSummary.messages != null && baseChatSummary.messages.length) {
      const [{ text: lastMessage, date: lastMessageDate }] = baseChatSummary.messages;

      this.lastMessage = lastMessage;
      this.lastMessageDate = lastMessageDate;
    }
  }
}

export class DirectChatSummary extends BaseChatSummary {
  bot?: boolean;

  // @ts-expect-error TS2564
  user: User;

  draft?: boolean;

  get type(): ChatTypes {
    return ChatTypes.DIRECT;
  }

  // @ts-expect-error TS7006
  constructor(chatSummary, user?: User, draft?: boolean) {
    super(chatSummary);

    if (chatSummary.bot) {
      this.bot = chatSummary.bot;
    }

    if (user) {
      this.user = user;
    }

    if (draft != null) {
      this.draft = draft;
    }

    if (chatSummary.workspaceId) {
      this.workspaceId = chatSummary.workspaceId;
    }
  }

  activate(): void {
    delete this.draft;
  }
}

export class GroupChatSummary extends BaseChatSummary {
  name: string;

  description?: string | null;

  photo?: string | null;

  get type(): ChatTypes {
    return ChatTypes.GROUP;
  }

  // @ts-expect-error TS7006
  constructor(chatSummary) {
    super(chatSummary);

    this.name = chatSummary.name;

    ['description', 'photo'].forEach(prop => {
      if (chatSummary[prop] !== undefined) {
        // @ts-expect-error TS7053
        this[prop] = chatSummary[prop];
      }
    });
  }
}

export class ContactChatSummary extends BaseChatSummary {
  // @ts-expect-error TS2564
  user: User;

  draft?: boolean;

  get type(): ChatTypes {
    return ChatTypes.CONTACT;
  }

  // @ts-expect-error TS7006
  constructor(chatSummary, user?: User, draft?: boolean) {
    super(chatSummary);

    this.lastMessage = 'Not yet registered';

    if (user) {
      this.user = user;
    }

    if (draft != null) {
      this.draft = draft;
    }
  }

  activate(): void {
    delete this.draft;
  }
}

export type ChatSummary = DirectChatSummary | GroupChatSummary | ContactChatSummary;

export function chatSummaryFactory<T extends { type: ChatTypes }>(chat: T): ChatSummary {
  switch (chat.type) {
    case ChatTypes.DIRECT:
      return new DirectChatSummary(chat);
    case ChatTypes.GROUP:
      return new GroupChatSummary(chat);
    case ChatTypes.CONTACT:
      // @ts-expect-error TS2345
      return new ContactChatSummary(chat, null);
    default:
      return assertNever(chat, '"type"-property not found in:');
  }
}

export function isDirectChatSummary(chat: ChatSummary): chat is DirectChatSummary {
  return chat.type === ChatTypes.DIRECT;
}

export function isGroupChatSummary(chat: ChatSummary): chat is GroupChatSummary {
  return chat.type === ChatTypes.GROUP;
}

export function isContactChatSummary(chat: ChatSummary): chat is ContactChatSummary {
  return chat.type === ChatTypes.CONTACT;
}
