import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { Recurrence } from '@app/modules/service-scheduling/modules/service-form/components/recurrence/recurrence-form.component';

interface AddBlockOffTimeResponce {
  created: {
    dates: string[];
  };
  blocked: {
    dates: string[];
  };
}

export interface AddBlockOffTimeTemplate {
  start: string;
  end: string;
  recurrence?: Recurrence;
  startTimezone: string;
  endTimezone: string;
}

@Injectable()
export class GuideScheduleBlocksTemplatesService {
  constructor(private http: HttpClient) {}

  addTemplates$(template: AddBlockOffTimeTemplate): Observable<AddBlockOffTimeResponce> {
    return this.http.post<AddBlockOffTimeResponce>(`${config.apiPath}/user/guide/availability/block-templates`, {
      template
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeTemplate$(id: string): Observable<any> {
    return this.http.delete(`${config.apiPath}/user/guide/availability/block-templates/${id}`);
  }
}
