import { Injectable, OnDestroy } from '@angular/core';
import { IUsersSearchService } from '@app/modules/ui-kit/users-typeahead';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { ProgramInstructorsApiService } from './program-instructors-api.service';
import { IModuleInstructorDetails, ModuleInstructor } from '../types';

@Injectable()
export class ProgramInstructorsService implements IUsersSearchService<ModuleInstructor>, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _instructors$ = new BehaviorSubject<ModuleInstructor[]>([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _instructorsLoading = false;

  get users$(): Observable<ModuleInstructor[]> {
    return this._instructors$.asObservable();
  }

  get loading(): boolean {
    return this._instructorsLoading;
  }

  constructor(
    private readonly _api: ProgramInstructorsApiService<IModuleInstructorDetails>,
    private readonly _workspace: WorkspacesService
  ) {}

  ngOnDestroy(): void {
    this._instructors$.complete();
  }

  search(searchString?: string): void {
    const isSoloWS = this._workspace.isSolo;
    this._workspace.isAdmin$
      .pipe(
        switchMap((isAdmin: boolean) => {
          if (!isAdmin) {
            return [];
          }
          if (!isSoloWS) {
            return this._workspace.getWorkspaceUsers().pipe(
              map((users: WorkspaceUser[]) => {
                return (
                  users
                    .map((user: WorkspaceUser) => ({
                      id: user.UserId,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      namedUrl: user.namedUrl,
                      photo: user.photo,
                      email: user.email
                    }))
                    // @ts-expect-error TS2345
                    .map((user: IModuleInstructorDetails) => new ModuleInstructor(user))
                );
              }),
              take(1)
            );
          }
          // @ts-expect-error TS2345
          return this.instructorsSearch(searchString);
        })
      )
      .subscribe((instructors: ModuleInstructor[]) => {
        this._instructors$.next(instructors);
      });
  }

  private instructorsSearch(searchString: string): Observable<ModuleInstructor[]> {
    return this._api
      .findInstructors(searchString)
      .pipe(
        map((instructorsDetails: IModuleInstructorDetails[]) =>
          instructorsDetails.map(
            (instructorDetails: IModuleInstructorDetails) => new ModuleInstructor(instructorDetails)
          )
        )
      );
  }
}
