import { Pipe, PipeTransform } from '@angular/core';
import { ICommunityConfig } from '@cnf/types';

@Pipe({
  name: 'communityConfig'
})
export class CommunityConfigPipe implements PipeTransform {
  transform(value: ICommunityConfig, key: keyof ICommunityConfig): unknown {
    return value[key];
  }
}
