import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import type { GuideClientDetailsModel } from './models/guide-client-details.model';
import { GuideClientsFeaturesService } from '@app/screens/guide/guide-clients/guide-client/services/features/guide-clients-features.service';
import { IS_ADMIN_WORKSPACE } from '@app/modules/workspaces/providers/is-admin-workspace';
import { Observable } from 'rxjs';
import { IS_SOLO_WORKSPACE } from '@app/modules/workspaces/providers/is-solo-workspace';
import { PUI_IS_DESKTOP } from '@awarenow/profi-ui-core';
import {
  clientsByClientId,
  guideClientsIsStatusIdle
} from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { guideClientsFetchAll } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';

type ClientFieldKey = keyof GuideClientDetailsModel;

@Component({
  selector: 'app-guide-client-details',
  templateUrl: './guide-client-details.component.html',
  styleUrls: ['./guide-client-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientDetailsComponent implements OnInit {
  readonly fields: readonly ClientFieldKey[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'organization',
    'stage',
    'tag'
  ];

  /**
   * The CRMClient id
   */
  @Input() readonly id!: CRMClient['id'];

  readonly client$: Observable<CRMClient> = this.store$
    .select(clientsByClientId)
    .pipe(map(clients => clients[this.id]));

  private currentEditableField: keyof GuideClientDetailsModel | null = null;

  constructor(
    private readonly store$: Store,
    readonly features: GuideClientsFeaturesService,
    @Inject(IS_ADMIN_WORKSPACE) public isWorkspaceAdmin$: Observable<boolean>,
    @Inject(IS_SOLO_WORKSPACE) readonly isSoloWorkspace$: Observable<boolean>,
    @Inject(PUI_IS_DESKTOP) readonly isDesktop$: Observable<boolean>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store$
      .select(guideClientsIsStatusIdle)
      .pipe(withLatestFrom(this.store$.select(clientsByClientId)), take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(([hasLoaded, clients]) => {
        if (!hasLoaded || !clients || (clients && !clients[this.id])) {
          this.store$.dispatch(guideClientsFetchAll());
        }
      });
  }

  /**
   * Show field editor form
   * @param fieldName {keyof GuideClientDetailsModel}
   */
  setCurrentEditableField(fieldName: ClientFieldKey | null): void {
    this.currentEditableField = fieldName;
    this.cdRef.markForCheck();
  }

  isCurrentEditableField(fieldName: ClientFieldKey): boolean {
    return this.currentEditableField === fieldName;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  saveEditFieldForm(id: CRMClient['id'], fieldName: ClientFieldKey, value: string) {
    switch (fieldName) {
      case 'organization':
        this.features.setOrganization(id, value);

        break;
      case 'phone':
        this.features.setPhoneNumber(id, value);

        break;
      case 'firstName':
        this.features.setFirstName(id, value);

        break;
      case 'lastName':
        this.features.setLastName(id, value);

        break;
      case 'email':
        this.features.setEmail(id, value);

        break;
    }

    this.setCurrentEditableField(null);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  closeEditFieldForm() {
    this.setCurrentEditableField(null);
  }
}
