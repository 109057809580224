import { Injectable } from '@angular/core';
import { DirectChatUserId } from '@app/core/chat/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ISimpleMap {
  [key: string]: string;
}

@Injectable()
export class ChatsUsersMapService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _chatsPerUser: ISimpleMap = {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _usersPerChat: ISimpleMap = {};

  getChatId(userId: DirectChatUserId): string | null {
    return this._chatsPerUser[userId.toString()];
  }

  getUserId(chatId: string): DirectChatUserId | null {
    return this._usersPerChat[chatId] ? new DirectChatUserId(this._usersPerChat[chatId]) : null;
  }

  removeChatId(chatId: string): void {
    const userId = this._usersPerChat[chatId];

    if (userId) {
      delete this._chatsPerUser[userId];
      delete this._usersPerChat[chatId];
    }
  }

  set(chatId: string, userId: DirectChatUserId): void {
    this._chatsPerUser[userId.toString()] = chatId;
    this._usersPerChat[chatId] = userId.toString();
  }

  updateChatIdForUser(userId: DirectChatUserId, oldChatId: string, newChatId: string): void {
    this._chatsPerUser[userId.toString()] = newChatId;

    delete this._usersPerChat[oldChatId];
    this._usersPerChat[newChatId] = userId.toString();
  }

  update(items: { chatId: string; userId: DirectChatUserId }[]): void {
    for (const { chatId, userId } of items) {
      const existingChatId = this.getChatId(userId);

      if (!existingChatId) {
        this.set(chatId, userId);
      } else if (existingChatId !== chatId) {
        this.updateChatIdForUser(userId, existingChatId, chatId);
      }
    }
  }
}
