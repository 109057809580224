import { FactoryProvider, InjectionToken, Optional, SkipSelf } from '@angular/core';
import { DATE_TIME_LOCALE, SCHEDULE_DATE_FORMAT, SCHEDULE_TIME_FORMAT } from '../schedule-options';
import { DefaultScheduleDateTimeFormatStrategy } from './default-schedule-date-time-format-strategy';
import { DefaultScheduleTimeFormatStrategy } from './default-schedule-time-format-strategy';
import { ScheduleDateTimeFormatStrategy } from './schedule-date-time-format-strategy';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function SCHEDULE_DATE_FORMAT_STRATEGY_FACTORY(
  parent: ScheduleDateTimeFormatStrategy<unknown>,
  format: string,
  locale: string
) {
  return parent || new DefaultScheduleDateTimeFormatStrategy(format, locale);
}

export const SCHEDULE_DATE_FORMAT_STRATEGY = new InjectionToken<ScheduleDateTimeFormatStrategy<string>>(
  'ScheduleDateFormatStrategy'
);

export const SCHEDULE_DATE_FORMAT_STRATEGY_PROVIDER: FactoryProvider = {
  provide: SCHEDULE_DATE_FORMAT_STRATEGY,
  deps: [[new Optional(), new SkipSelf(), SCHEDULE_DATE_FORMAT_STRATEGY], SCHEDULE_DATE_FORMAT, DATE_TIME_LOCALE],
  useFactory: SCHEDULE_DATE_FORMAT_STRATEGY_FACTORY
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function SCHEDULE_TIME_FORMAT_STRATEGY_FACTORY(
  parent: ScheduleDateTimeFormatStrategy<unknown>,
  format: string,
  locale: string
) {
  return parent || new DefaultScheduleTimeFormatStrategy(format, locale);
}

export const SCHEDULE_TIME_FORMAT_STRATEGY = new InjectionToken<ScheduleDateTimeFormatStrategy<string>>(
  'ScheduleTimeFormatStrategy'
);

export const SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER: FactoryProvider = {
  provide: SCHEDULE_TIME_FORMAT_STRATEGY,
  deps: [[new Optional(), new SkipSelf(), SCHEDULE_TIME_FORMAT_STRATEGY], SCHEDULE_TIME_FORMAT, DATE_TIME_LOCALE],
  useFactory: SCHEDULE_TIME_FORMAT_STRATEGY_FACTORY
};
