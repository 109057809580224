<app-alert [alert]="alert" *ngIf="alert"></app-alert>
<div class="program-clients row">
  <div class="col-md-3 clients-col" [class.hidden]="selectedClientId >= 0">
    <app-clients-list
      class="clients-list section"
      [clients]="{
        clients: clients,
        invitedClients: invitedClients,
        deletedClients: deletedClients,
        invitations: invitations
      }"
      [selectedClientId]="selectedClientId"
      [guideClients]="guideRelations"
      [progress]="progress"
      [editable]="editable"
      (unEnroll)="deleteClient($event)"
      (selectClient)="selectClient($event)"
      (deleteInvited)="deleteInvitedClient($event)"
      (selectInvited)="selectInvited($event)">
      <button
        class="title-action-button"
        *ngIf="editable"
        pui-button
        class="add-clients"
        data-qa-id="programs-clients-add-clients-button"
        (click)="addClient()"
        [disabled]="subscriptionDeactivated"
        size="s">
        <pui-icon svgIcon="pui:plus-circle"></pui-icon>

        <span i18n="@@addClients">Add client</span>
      </button>

      <button
        *ngIf="canDownloadAnswers"
        class="search-control-action-button pui-space_left-1 pui-space_right-1"
        pui-icon-button
        appearance="flat"
        size="m"
        pui-tooltip="Export all client's follow-up responses"
        i18n-pui-tooltip="@@guideProgramDownloadAnswers:Download answers"
        (click)="downloadAnswers(programId)">
        <pui-icon svgIcon="pui:download"></pui-icon>
      </button>
    </app-clients-list>
  </div>

  <div
    class="col-md-9 client-progress-col"
    [class.active]="selectedClientId >= 0 || !clients || !clients.length"
    *ngIf="!selectedInvitedClient">
    <ng-template [ngIf]="selectedClientId >= 0">
      <div class="back d-md-none" (click)="selectedClientId = -1"></div>
      <app-client-progress
        class="client-progress-board section"
        [client]="selectedClient"
        [programSessionsInfo]="programEvents"
        [progress]="progress[selectedClientId]"
        [modules]="clientModules$ | async"
        [programId]="programId"
        [services]="services"
        (update)="selectClient()"
        (downloadClientModuleAnswers)="onDownloadClientModuleAnswers($event)"
        (completeModule)="completeClientModule($event)"></app-client-progress>
    </ng-template>

    <ng-template [ngIf]="!clients || !clients.length">
      <section class="client-progress-board section no-clients-yet">
        <div data-qa-id="program-clients-empty-image" class="image"></div>

        <h3 class="invite-title" data-qa-id="program-clients-invite-title" i18n="@@inviteClientsToProgram">
          Invite clients to your program
        </h3>

        <div class="buttons-wrapper" *ngIf="editable">
          <app-button
            data-qa-id="program-clients-invite-button"
            className="btn-add-primary"
            i18n="@@invite"
            (click)="addClient()">
            Invite
          </app-button>
        </div>

        <p data-qa-id="program-clients-share-your-program-text" class="note" i18n="@@shareProgramLink">
          Share your program with this link
        </p>

        <div class="share-link-wrapper input-group">
          <input
            data-qa-id="program-clients-shared-link-input"
            type="text"
            readonly
            class="form-control shared-link"
            [value]="programLink" />

          <div class="input-group-append">
            <button
              type="text"
              class="input-group-text"
              ngxClipboard
              data-qa-id="program-clients-copy-link-button"
              [cbContent]="programLink"
              (cbOnSuccess)="onCopied()"
              (cbOnError)="onCopyFailed()"
              i18n="@@copyLink">
              Copy link
            </button>
          </div>
        </div>
      </section>
    </ng-template>
  </div>

  <div class="col-md-9 client-progress-col" *ngIf="selectedInvitedClient">
    <section class="client-progress-board section invited-client">
      <div class="email">{{ selectedInvitedClient.email }}</div>
      <div class="date-sent">
        <ng-container i18n="@@invitationHaveBeenSent">Invitation sent</ng-container>
        {{ convertDate(selectedInvitedClient.date) }}
      </div>
      <div class="invite-row">
        <div class="info">
          <label for="link">
            <h1 i18n="@@inviteViaPersonalLink">Invite via personal link</h1>
            <p i18n="@@copyUrlToShare">Copy this client’s unique invitation link to share it</p>
          </label>
        </div>
        <div class="params">
          <div>
            <input type="text" readonly id="link" [value]="selectedInvitedClient.inviteLink" #embeddableLink />
          </div>
          <app-button
            className="btn-primary btn btn-lg"
            [ngxClipboard]="embeddableLink"
            (cbOnSuccess)="onEmbeddableCodeCopied()"
            (cbOnError)="onEmbeddableCodeCopyFailed()"
            i18n="@@copyLink">
            Copy link
          </app-button>
        </div>
      </div>
      <div class="invite-row" *ngIf="canDeleteInvitation">
        <div class="info">
          <h1 i18n="@@deleteInvitation">Delete invitation</h1>
          <p i18n="@@invitationLinkWillBeDeactivated">
            Deactivate the invitation link and revoke this client’s access to the program
          </p>
        </div>
        <div class="params">
          <app-button className="btn-outline-danger btn btn-lg" (click)="deleteInvitation()">
            <ng-container i18n="@@deleteInvitation">Delete invitation</ng-container>
          </app-button>
        </div>
      </div>
    </section>
  </div>
</div>
