import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-chat-date-separator',
  templateUrl: './chat-date-separator.component.html',
  styleUrls: ['./chat-date-separator.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-app-chat-date-separator' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDateSeparatorComponent {
  @Input()
  // @ts-expect-error TS2564
  date: string;
}
