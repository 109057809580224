import {
  AuthorProgram,
  ContentModule,
  GroupSessionModule,
  IEditableProgram,
  InstructorProgram,
  isContentModule,
  isGroupSessionModule,
  isQuizModule,
  isRestrictedModule,
  isSessionModule,
  LocalProgramService,
  ModuleInstructor,
  Program,
  ProgramAccessRoles,
  ProgramModule,
  Question,
  QuestionOption,
  Quiz,
  QuizModule,
  SessionModule
} from '@app/screens/guide/guide-programs/types';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { GuideServiceTypes, ProgramService } from '@app/shared/interfaces/services';
import { assertNever } from '@app/shared/utils/assertions';
import { normalizeFullName } from '@app/shared/utils/full-name';

export function convertModuleTypeToServiceType(moduleType: ModuleTypes): GuideServiceTypes | null {
  let serviceType = null;

  if (moduleType === ModuleTypes.SESSION) {
    serviceType = GuideServiceTypes.SESSION;
  } else if (moduleType === ModuleTypes.GROUP_SESSION) {
    serviceType = GuideServiceTypes.GROUP_SESSION;
  }

  return serviceType;
}

export function convertLocalServicesToMap(services: LocalProgramService[]): {
  [serviceId: number]: ProgramService;
} {
  return services.reduce(
    (servicesMap, { programService }) => ({
      ...servicesMap,
      [programService.id]: programService
    }),
    {}
  );
}

// @ts-expect-error TS7006
export function convertEditableProgramModule(programModule): ProgramModule {
  const moduleDetails = programModule.instructor
    ? {
        ...programModule,
        instructor: new ModuleInstructor(programModule.instructor)
      }
    : programModule;

  // @ts-expect-error TS7006
  programModule.hosts?.forEach(host => (host.name = normalizeFullName(host)));

  switch (moduleDetails.moduleType) {
    case ModuleTypes.CONTENT:
      return new ContentModule({
        ...moduleDetails,
        localId: moduleDetails.id
      });
    case ModuleTypes.QUIZ:
      return new QuizModule({
        ...moduleDetails,
        localId: moduleDetails.id,
        quiz: new Quiz({
          id: moduleDetails.quiz.id,
          localId: moduleDetails.quiz.id,
          questions: moduleDetails.quiz.questions.map(
            // @ts-expect-error TS7006
            question =>
              new Question({
                ...question,
                options: question.options
                  ? // @ts-expect-error TS7006
                    question.options.map(questionOption => new QuestionOption(questionOption))
                  : question.options
              })
          )
        })
      });
    case ModuleTypes.GROUP_SESSION:
      return new GroupSessionModule({
        ...moduleDetails,
        localId: moduleDetails.id
      });
    case ModuleTypes.SESSION:
      return new SessionModule({
        ...moduleDetails,
        localId: moduleDetails.id
      });
    default:
      return assertNever(moduleDetails);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
function editableProgramModulesSorter(a: ProgramModule, b: ProgramModule) {
  return a.order > b.order ? 1 : -1;
}

export function convertEditableProgram(editableProgram: IEditableProgram): Program {
  if (editableProgram.accessRole === ProgramAccessRoles.AUTHOR) {
    return new AuthorProgram({
      id: editableProgram.id,
      modules: editableProgram.modules
        ? editableProgram.modules.map(convertEditableProgramModule).sort(editableProgramModulesSorter)
        : [],
      settings: editableProgram.settings,
      // @ts-expect-error TS2322
      questionnaires: editableProgram.questionnaires,
      subscriptions: editableProgram.subscriptions
    });
  }

  return new InstructorProgram({
    id: editableProgram.id,
    modules: editableProgram.modules
      ? editableProgram.modules.map(convertEditableProgramModule).sort(editableProgramModulesSorter)
      : [],
    settings: editableProgram.settings,
    subscriptions: editableProgram.subscriptions
  });
}

// @ts-expect-error TS2366
export function convertUnsavedModuleIntoModule(module: ProgramModule): ProgramModule {
  if (module.id || isRestrictedModule(module)) {
    return module;
  }

  if (isContentModule(module)) {
    return new ContentModule({ ...module, id: module.localId });
  }

  if (isQuizModule(module)) {
    return new QuizModule({ ...module, id: module.localId });
  }

  if (isGroupSessionModule(module)) {
    return new GroupSessionModule({ ...module, id: module.localId });
  }

  if (isSessionModule(module)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return new SessionModule({ ...(<SessionModule>module), id: (<SessionModule>module).localId });
  }
}
