import { Compiler, Inject, Injectable, Injector } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { filter, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { IntegrationsConfig } from '../types/integrations-config';
import { IntegrationToken } from '../interfaces/integration-token';
import { INTEGRATIONS } from '../tokens/integrations-token';
import { BaseIntegration } from '../classes/base-integration';

@Injectable()
export class LazyLoadIntegrationsService {
  constructor(
    private readonly compiler: Compiler,
    private readonly injector: Injector,
    private readonly runtimeConfigService: RuntimeConfigService,
    @Inject(INTEGRATIONS) private readonly integrations: IntegrationToken[]
  ) {}

  load(): void {
    this.runtimeConfigService.configuration$
      .pipe(
        take(1),
        switchMap(({ integrations }) => this.lazyLoadIntegrations$(integrations)),
        filter(i => !!i)
      )
      .subscribe((integrations: { module: typeof BaseIntegration; options: unknown }[]) =>
        integrations.forEach(({ module, options }) => this.compileModule(module, options))
      );
  }

  private compileModule(module: typeof BaseIntegration, options: unknown = {}): void {
    if (!module) {
      return;
    }

    // Compile the module
    this.compiler.compileModuleAsync(module).then(moduleFactory => {
      // Create a moduleRef, load Integration by service
      const moduleRef = moduleFactory.create(this.injector);
      moduleRef.instance.service.init(options);
    });
  }

  private lazyLoadIntegrations$(
    configuration: IntegrationsConfig
  ): Observable<{ module: typeof BaseIntegration; options: unknown }[] | null> {
    if (!configuration) {
      return of(null);
    }

    return from(this.integrations).pipe(
      // Filter disabled integrations
      filter(({ configKey }) => configuration[configKey]?.enabled),
      // Load integrations
      mergeMap(({ module, configKey }) => from(module).pipe(map(module => ({ module, configKey })))),
      // Merge with Integration options
      map(({ module, configKey }) => ({ module, options: configuration[configKey] })),
      toArray()
    );
  }
}
