import { AfterViewInit, Directive, ElementRef, HostBinding, OnDestroy, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

export const fromMutation = (target: Node, options?: MutationObserverInit): Observable<MutationRecord[]> => {
  return new Observable(observer => {
    const mutation = new MutationObserver(mutations => {
      observer.next(mutations);
    });
    mutation.observe(target, options);

    const unsubscribe = () => {
      mutation.disconnect();
    };

    return unsubscribe;
  });
};

/**
 * TODO Move to UI library
 */
@Directive({
  selector: '[pui-overflow-auto-height]'
})
export class PuiOverflowAutoHeightDirective implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private parentElement!: HTMLElement;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _height = 'auto';

  @HostBinding('style.height')
  get height(): string {
    return this._height;
  }

  constructor(private readonly elRef: ElementRef, private renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // @ts-expect-error TS2322
    this.parentElement = (this.elRef.nativeElement as HTMLElement)?.parentElement;
    this.updateHostHeight();

    fromMutation(this.parentElement, {
      childList: true,
      subtree: true,
      attributes: true
    })
      .pipe(
        map(() => this.parentElement),
        distinctUntilChanged((prev: HTMLElement, current: HTMLElement) => prev.clientHeight === current.clientHeight),
        takeUntil(this.destroy$)
      )
      .subscribe(parentElement => {
        this.updateHostHeight();
      });
  }

  private updateHostHeight(value: number = this.parentElement.clientHeight): void {
    this.renderer.setStyle(this.elRef.nativeElement, 'height', !value ? 'auto' : `${value}px`);
  }
}
