import { Injectable } from '@angular/core';
import { PuiDialogRef, PuiDialogService } from '@awarenow/profi-ui-core';
import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';
import { BookingWidgetOptions } from '@appWidget/modules/booking/interfaces';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { GuideServiceParent } from '@app/modules/book-service';
import { BookingModalService } from '@app/modules/book-service/components/booking-modal/booking-modal.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(private dialog: PuiDialogService, private bookingModalService: BookingModalService) {}

  private open(options: BookingWidgetOptions): PuiDialogRef<unknown> {
    return this.bookingModalService.openAsync(options);
  }

  openSoloGuideAvailablePackageSession(
    guideId: number,
    packageId: number,
    sessionTemplateId: number,
    workspaceType: WorkspacesTypes,
    serviceParent: GuideServiceParent
  ): void {
    this.open({
      guideId,
      packageId,
      sessionTemplateId,
      workspaceType,
      type: BookingTypes.SESSION,
      serviceParent
    });
  }

  openSoloGuideAvailableProgramSession(
    guideId: number,
    programId: number,
    sessionTemplateId: number,
    workspaceType: WorkspacesTypes
  ): void {
    this.open({
      guideId,
      programId,
      sessionTemplateId,
      workspaceType,
      type: BookingTypes.SESSION
    });
  }

  openTeamAvailablePackageSession(
    workspaceId: number,
    packageId: number,
    sessionTemplateId: number,
    workspaceType: WorkspacesTypes,
    serviceParent: GuideServiceParent
  ): void {
    this.open({
      workspaceId,
      packageId,
      sessionTemplateId,
      workspaceType,
      type: BookingTypes.CHOOSE_HOST_FROM_DASHBOARD,
      serviceParent
    });
  }

  openTeamAvailableProgramSession(
    workspaceId: number,
    programId: number,
    sessionTemplateId: number,
    workspaceType: WorkspacesTypes
  ): void {
    this.open({
      workspaceId,
      programId,
      sessionTemplateId,
      workspaceType,
      type: BookingTypes.CHOOSE_HOST_FROM_DASHBOARD
    });
  }

  guideIndividualPage(guideId: number, workspaceId: number): void {
    this.open({
      guideId,
      workspaceId,
      type: BookingTypes.INDIVIDUAL
    });
  }

  guideWorkspacePage(workspaceId: number): void {
    this.open({
      workspaceId,
      type: BookingTypes.TEAM
    });
  }

  openService(sessionTemplateId: number, guideId: number, workspaceId: number): PuiDialogRef<unknown> {
    return this.open({
      sessionTemplateId,
      guideId,
      workspaceId,
      type: BookingTypes.SESSION
    });
  }
}
