import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IChatMessage } from '@app/core/chat/types';

@Component({
  selector: 'app-chat-new-user',
  templateUrl: './chat-new-user.component.html',
  styleUrls: ['./chat-new-user.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-app-chat-new-user' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatNewUserComponent {
  // @ts-expect-error TS2564
  @Input() message: IChatMessage;
}
