<div *ngIf="subscriptions.length" class="subscriptions-wrapper">
  <h5 class="title" i18n="@@subscriptions">Subscriptions</h5>

  <div *ngFor="let subscription of subscriptions" class="subscription-item">
    <app-service-label
      *ngIf="subscription.type !== 'membership'"
      [type]="subscription.type === 'course' ? 'program' : subscription.type"></app-service-label>
    <div class="label label-membership" i18n="@@membership" *ngIf="subscription.type === 'membership'">Membership</div>
    <div class="price" *ngIf="!subscription.free">
      <span [ngClass]="{ discount: subscription.coupon }">{{ subscription.price | platformCurrency }}</span>
      <span *ngIf="subscription.coupon" class="coupon">
        {{ subscription.price - subscription.coupon.amount | simpleRound | platformCurrency }}
      </span>
      <span [ngClass]="{ highlighted: subscription.coupon }" *ngIf="subscription.recurrency">
        /{{ subscriptionReqStrings[subscription.recurrency] }}
      </span>
    </div>

    <p class="name">{{ subscription.name }}</p>

    <p class="date">
      <ng-template [ngIf]="subscription.status === SubscriptionStatus.ACTIVE">
        <ng-template [ngIf]="!subscription.free && !subscription.endless && subscription.currentPeriodEnd">
          <ng-container i18n="@@nextBillingDate">Next billing date</ng-container>
          :
          {{ subscription.currentPeriodEnd | platformDate }}
        </ng-template>
        <a
          (click)="cancelSubscription.emit(subscription.id)"
          class="cancel"
          i18n="@@cancel"
          *ngIf="subscription.type !== SubscriptionType.Membership || subscription.recurrency">
          Cancel
        </a>
      </ng-template>

      <ng-template [ngIf]="subscription.status === SubscriptionStatus.PAST_DUE">
        <ng-container i18n="@@pastDueDate" class="attention-box">Past due date</ng-container>
        <a (click)="reactivateSubscription.emit(subscription.id)" class="cancel" i18n="@@activate">Activate</a>
      </ng-template>

      <ng-template [ngIf]="subscription.status === SubscriptionStatus.CANCELED">
        <ng-container i18n="@@canceled">Canceled</ng-container>
        <ng-container *ngIf="subscription.currentPeriodEnd">
          ,
          <ng-container i18n="@@availableUpTo">available up to</ng-container>
          {{ subscription.currentPeriodEnd | date: 'd MMM yyyy' }}
        </ng-container>
        <a
          (click)="reactivateSubscription.emit(subscription.id)"
          class="cancel"
          *ngIf="subscription.type !== SubscriptionType.Membership">
          <ng-container i18n="@@restart">Restart</ng-container>
        </a>
      </ng-template>

      <ng-template [ngIf]="subscription.status === SubscriptionStatus.CANCELED_BY_OWNER">
        <span class="attention-box">
          <ng-container transloco="canceledByPractitioner">Canceled by practitioner</ng-container>
          <ng-container *ngIf="subscription.currentPeriodEnd">
            ,
            <ng-container i18n="@@availableUpTo">available up to</ng-container>
            {{ subscription.currentPeriodEnd | date: 'd MMM yyyy' }}
          </ng-container>
        </span>
      </ng-template>
    </p>
    <p class="date" *ngIf="subscription.totalPayments">
      <ng-container i18n="@@lengthOfSubscription">Length of subscription</ng-container>
      : {{ subscription.totalPayments }} {{ subscription.recurrency }} (
      <ng-container i18n="@@until">until</ng-container>
      {{ subscription.cancelsAt | date: 'd MMM yyyy' }})
    </p>
  </div>
</div>
