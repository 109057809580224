import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { ScriptInjector } from './script-injector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let FB: any;

/**
 * @deprecated
 */
@Injectable()
export class FacebookService extends ScriptInjector {
  readonly FB_SCRIPT_ID = 'fb_scr';

  readonly FB_SCRIPT_SRC = 'https://connect.facebook.net/en_US/sdk.js';

  constructor(
    private readonly _runtimeConfigService: RuntimeConfigService,
    public renderer: Renderer2,
    // @ts-expect-error TS7006
    @Inject(DOCUMENT) public document,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) public platformId
  ) {
    super(renderer, document);

    if (isPlatformBrowser(platformId)) {
      this.initialize$().subscribe();
    }
  }

  protected initialize$(): Observable<void> {
    const scriptOptions = { id: this.FB_SCRIPT_ID, src: this.FB_SCRIPT_SRC, defer: true };
    const fbOptions = {
      appId: this._runtimeConfigService.get('FB_APP_ID'),
      version: this._runtimeConfigService.get('FB_API_VERSION'),
      autoLogAppEvents: true,
      xfbml: true
    };

    return this.appendScript$(scriptOptions).pipe(tap(() => FB.init(fbOptions)));
  }

  /**
   * @deprecated
   */
  shareLink$(href: string, quote?: string, hashtag?: string): Observable<string | null> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shareOptions: any = { method: 'share', href };

    if (quote) {
      shareOptions.quote = quote;
    }

    if (hashtag) {
      shareOptions.hashtag = hashtag.charCodeAt(0) === 35 ? hashtag : `#${hashtag}`;
    }

    return new Observable<string | null>(observer => {
      if (FB && FB.ui) {
        FB.ui(shareOptions, (response: { error_message?: string }) => {
          if (response && response.error_message) {
            observer.error(response.error_message);
          } else {
            observer.next();
            observer.complete();
          }
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }
}
