import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { QuillModule } from 'ngx-quill';

import { PuiTextEditorModule } from '@awarenow/profi-ui-core';
import { SharedModule } from '@app/shared';

import { TextEditorImageDownloaderService, WistiaService } from './services';
import {
  HtmlUploaderComponent,
  IframeUploaderComponent,
  TextEditorImageComponent,
  VideoGalleryComponent,
  VideoUploaderComponent,
  TextEditorNotesComponent
} from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScriptLoaderModule,
    NgScrollbarModule,
    SharedModule,
    PuiTextEditorModule,
    QuillModule
  ],
  declarations: [
    TextEditorImageComponent,
    TextEditorNotesComponent,
    VideoUploaderComponent,
    VideoGalleryComponent,
    HtmlUploaderComponent,
    IframeUploaderComponent
  ],
  exports: [TextEditorImageComponent, PuiTextEditorModule, TextEditorNotesComponent],
  providers: [WistiaService, TextEditorImageDownloaderService]
})
export class TextEditorModule {}
