import { Injectable } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { BookShareInfoService } from './book-share-info.service';
import { EmbeddableCodeSource, IBookButtonCustomizationOptions } from '../types';
import { fromCamelToKebabCaseKeys } from '../utils/object-keys-transformers';
import { stringifyObjectEntries } from '../utils/stringify';

@Injectable({ providedIn: 'root' })
export class BookWidgetCodeService {
  constructor(
    private _bookShareInfo: BookShareInfoService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  generatePopupWidgetCode(options: IBookButtonCustomizationOptions & { source?: EmbeddableCodeSource }): string {
    const { bookWidgetInfo, defaultBookButtonOptions } = this._bookShareInfo;
    const text = options.text || defaultBookButtonOptions.text;
    const style = { ...defaultBookButtonOptions.style, ...options.style };

    let widgetSource;

    switch (options.source) {
      case EmbeddableCodeSource.TEAM: {
        widgetSource = [
          `<!-- ${this._runtimeConfigService.get('platformName')} widget start -->`,
          bookWidgetInfo.stylesheet,
          bookWidgetInfo.script,
          `<a href="" class="platform-book-btn" style="${stringifyObjectEntries(
            fromCamelToKebabCaseKeys(style)
          )}" onclick="platformwidget.initPopupWidget({ url: '${
            bookWidgetInfo.endpoints.workspace
          }' }); return false;">${text}</a>`,
          `<!-- ${this._runtimeConfigService.get('platformName')} widget end -->`
        ];
        break;
      }
      default:
        widgetSource = [
          `<!-- ${this._runtimeConfigService.get('platformName')} widget start -->`,
          bookWidgetInfo.stylesheet,
          bookWidgetInfo.script,
          `<a href="" class="platform-book-btn" style="${stringifyObjectEntries(
            fromCamelToKebabCaseKeys(style)
          )}" onclick="platformwidget.initPopupWidget({ url: '${
            bookWidgetInfo.endpoints.personal
          }' }); return false;">${text}</a>`,
          `<!-- ${this._runtimeConfigService.get('platformName')} widget end -->`
        ];
    }

    return widgetSource.join('\n');
  }
}
