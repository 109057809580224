import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientSessionTimeAttribute } from '../../types';
import { ClientSessionAttributesEnum } from '../../enums';
import { puiDefaultProp, PuiIconAppearanceType } from '@awarenow/profi-ui-core';
import { SessionAttributeIconEnum } from '@libs/business/cdk/session';

@Component({
  selector: 'app-client-session-time-attribute',
  templateUrl: './client-session-time-attribute.component.html',
  styleUrls: ['../client-session-attribute.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionTimeAttributeComponent implements ClientSessionTimeAttribute {
  type: ClientSessionAttributesEnum.TIME = ClientSessionAttributesEnum.TIME;

  @Input()
  @puiDefaultProp()
  leftIcon: SessionAttributeIconEnum.CALENDAR | SessionAttributeIconEnum.WARNING = SessionAttributeIconEnum.CALENDAR;

  @Input()
  @puiDefaultProp()
  leftIconAppearance: PuiIconAppearanceType = 'default';

  @Input() label!: string;
}
