import { RecurringEvent, SessionTemplateLocation } from '@app/screens/guide/guide-sessions-templates/types';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';

export interface BasicInfoForm {
  name: string;
  count?: number;
  descriptionRepresentation: string | null;
  descriptionMarkup?: string | null;
  descriptionText?: string | null;
  coverImage: string | null;
  coverImageThumb: string | null;
  length: number;
  recurring: Pick<RecurringEvent, 'count'> | null;
  status: GuideProfileTypes;
  location: SessionTemplateLocation;
  restrictClientBooking: boolean;
}

export enum BasicInfoFormFields {
  FORM_HEADER_TITLE = 'FORM_HEADER_TITLE',
  SESSION_TITLE = 'SESSION_TITLE',
  SESSION_DESCRIPTION = 'SESSION_DESCRIPTION',
  UPLOAD_COVER_IMAGE = 'UPLOAD_COVER_IMAGE',
  LOCATION = 'LOCATION',
  DURATION = 'DURATION',
  RECURRENCE = 'RECURRENCE',
  STATUS = 'STATUS',
  COUNT = 'COUNT',
  RESTRICT_CLIENT_BOOKING = 'RESTRICT_CLIENT_BOOKING'
}
