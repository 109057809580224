import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { GuideRelation } from '@app/core/users/types';
import { GuideRelationProfileService } from '@app/modules/guide-client/services';

@Component({
  selector: 'app-guide-client-info',
  templateUrl: './guide-client-info.component.html',
  styleUrls: ['./guide-client-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientInfoComponent {
  @Input()
  // @ts-expect-error TS2564
  client: GuideRelation;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uiPayload: TemplateRef<any>;

  @Input()
  avatarSize = 'm';

  constructor(private _profileService: GuideRelationProfileService) {}

  openProfile(): void {
    this._profileService.openProfile(this.client.localId);
  }
}
