import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';
import { take } from 'rxjs/operators';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services';
import { RedirectAfterSaveActions } from '@app/screens/guide/guide-shared/can-redirect-after-save';

export function confirmChangesForNewUsersOnly<T>() {
  return (
    target: Object,
    propertyKey: string | symbol,
    descriptor: Partial<
      TypedPropertyDescriptor<() => T | undefined> & {
        auth: AuthService;
        dialog: PuiDialogService;
      }
    >
  ) => {
    const method = descriptor.value;
    descriptor.value = function (...args: object[]) {
      if (!this.dialog || !this.auth) {
        throw new Error("Current class hasn't dialog dependency");
      }
      if (!this.auth.isPlatformAdmin()) {
        return method?.apply(this, args);
      }
      const ref = this.dialog.open(GuideChangesForNewUsersDialogComponent);
      ref.afterClosed$.pipe(take(1)).subscribe((res: never) => {
        if (res && res === RedirectAfterSaveActions.SAVE) {
          return method?.apply(this, args);
        } else {
          return null;
        }
      });
    };
  };
}
