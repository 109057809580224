import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { IClientGuideAvailableSession, IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';

// ToDo refactor
@Injectable()
export class ClientSessionCommunicationsService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _acceptSessionOffer$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _approveReschedulement$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _archiveSession$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _bookAvailableSession$ = new Subject<{
    session: IClientGuideAvailableSession;
    analyticSourceType?: AnalyticSourceTypes;
  }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _cancelSession$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _declineSessionOffer$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _endSession$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _leaveReview$: Subject<{ sessionId: number; privateOnly: boolean }> = new Subject<{
    sessionId: number;
    privateOnly: boolean;
  }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _reorderSession$: Subject<{
    session: IClientGuideSession;
    analyticSourceType?: AnalyticSourceTypes;
  }> = new Subject<{
    session: IClientGuideSession;
    analyticSourceType?: AnalyticSourceTypes;
  }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _rescheduleSession$: Subject<IClientGuideSession> = new Subject<IClientGuideSession>();

  get acceptSessionOffer$(): Observable<IClientGuideSession> {
    return this._acceptSessionOffer$.asObservable();
  }

  get approveReschedulement$(): Observable<IClientGuideSession> {
    return this._approveReschedulement$.asObservable();
  }

  get archiveSession$(): Observable<IClientGuideSession> {
    return this._archiveSession$.asObservable();
  }

  get bookAvailableSession$(): Observable<{
    session: IClientGuideAvailableSession;
    analyticSourceType?: AnalyticSourceTypes;
  }> {
    return this._bookAvailableSession$.asObservable();
  }

  get cancelSession$(): Observable<IClientGuideSession> {
    return this._cancelSession$.asObservable();
  }

  get declineSessionOffer$(): Observable<IClientGuideSession> {
    return this._declineSessionOffer$.asObservable();
  }

  get endSession$(): Observable<IClientGuideSession> {
    return this._endSession$.asObservable();
  }

  get leaveReview$(): Observable<{ sessionId: number; privateOnly: boolean }> {
    return this._leaveReview$.asObservable();
  }

  get reorderSession$(): Observable<{ session: IClientGuideSession; analyticSourceType?: AnalyticSourceTypes }> {
    return this._reorderSession$.asObservable();
  }

  get rescheduleSession$(): Observable<IClientGuideSession> {
    return this._rescheduleSession$.asObservable();
  }

  acceptSessionOffer(session: IClientGuideSession): void {
    return this._acceptSessionOffer$.next(session);
  }

  approveReschedulement(clientSession: IClientGuideSession): void {
    this._approveReschedulement$.next(clientSession);
  }

  archiveSession(session: IClientGuideSession): void {
    this._archiveSession$.next(session);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  bookAvailableSession(session: IClientGuideAvailableSession, analyticSourceType?: AnalyticSourceTypes) {
    this._bookAvailableSession$.next({ session, analyticSourceType });
  }

  cancelSession(session: IClientGuideSession): void {
    this._cancelSession$.next(session);
  }

  declineSessionOffer(session: IClientGuideSession): void {
    this._declineSessionOffer$.next(session);
  }

  endSession(session: IClientGuideSession): void {
    this._endSession$.next(session);
  }

  leaveReview(sessionId: number, privateOnly = false): void {
    this._leaveReview$.next({ sessionId, privateOnly });
  }

  reorderSession(session: IClientGuideSession, analyticSourceType?: AnalyticSourceTypes): void {
    this._reorderSession$.next({ session, analyticSourceType });
  }

  rescheduleSession(session: IClientGuideSession): void {
    this._rescheduleSession$.next(session);
  }
}
