import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { ContentModule, IContentModuleDetails } from './content-module';
import { GroupSessionModule, IGroupSessionModuleDetails } from './group-session-module';
import { ModuleAccessTypes } from './module-access-types';
import { IQuizModuleDetails, QuizModule } from './quiz-module';
import { RestrictedModule } from './restricted-module';
import { ISessionModuleDetails, SessionModule } from './session-module';

export type ProgramModule = RestrictedModule | ContentModule | GroupSessionModule | QuizModule | SessionModule;

export type EditableProgramModule = ContentModule | GroupSessionModule | QuizModule | SessionModule;

export type ProgramModulePatch =
  | Partial<ContentModule>
  | Partial<GroupSessionModule>
  | Partial<QuizModule>
  | Partial<SessionModule>;

export function isContentModule(
  programModule: Readonly<ProgramModule>,
  accessType: ModuleAccessTypes = ModuleAccessTypes.EDITABLE
): programModule is ContentModule {
  return (
    programModule.moduleType === ModuleTypes.CONTENT &&
    (!programModule.accessType || programModule.accessType === accessType)
  );
}

export function isGroupSessionModule(
  programModule: Readonly<ProgramModule>,
  accessType: ModuleAccessTypes = ModuleAccessTypes.EDITABLE
): programModule is GroupSessionModule {
  return (
    programModule.moduleType === ModuleTypes.GROUP_SESSION &&
    (!programModule.accessType || programModule.accessType === accessType)
  );
}

export function isQuizModule(
  programModule: Readonly<ProgramModule>,
  accessType: ModuleAccessTypes = ModuleAccessTypes.EDITABLE
): programModule is QuizModule {
  return (
    programModule.moduleType === ModuleTypes.QUIZ &&
    (!programModule.accessType || programModule.accessType === accessType)
  );
}

export function isSessionModule(
  programModule: Readonly<ProgramModule>,
  accessType: ModuleAccessTypes = ModuleAccessTypes.EDITABLE
): programModule is SessionModule {
  return (
    programModule.moduleType === ModuleTypes.SESSION &&
    (!programModule.accessType || programModule.accessType === accessType)
  );
}

export function isRestrictedModule(programModule: Readonly<ProgramModule>): programModule is RestrictedModule {
  return programModule.accessType === ModuleAccessTypes.RESTRICTED;
}

export function patchProgramModule(
  programModule: Readonly<ProgramModule>,
  patch: Readonly<ProgramModulePatch>
): ProgramModule {
  if (isRestrictedModule(programModule)) {
    return programModule;
  }

  if (isContentModule(programModule)) {
    return new ContentModule({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<IContentModuleDetails>programModule),
      ...patch
    });
  }

  if (isGroupSessionModule(programModule)) {
    return new GroupSessionModule({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<IGroupSessionModuleDetails>programModule),
      ...patch
    });
  }

  if (isQuizModule(programModule)) {
    return new QuizModule({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<IQuizModuleDetails>programModule),
      ...patch
    });
  }

  return new SessionModule({
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ...(<ISessionModuleDetails>programModule),
    ...patch
  });
}
