// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientsTag {
  name: string;
  color: string;
}

export const SALES_CLIENT_TAGS: IClientsTag[] = [
  {
    name: 'Suspect',
    color: '#7C3AED'
  },
  {
    name: 'Prospect',
    color: '#0F9BE3'
  },
  {
    name: 'Discovery',
    color: '#FBBF24'
  },
  {
    name: 'Proposal',
    color: '#EA580C'
  },
  {
    name: 'Contract signed',
    color: '#4FACBF'
  },
  {
    name: 'Client',
    color: '#059669'
  },
  {
    name: 'On hold',
    color: '#F30055'
  }
];
