<form class="participants-and-guests-form" [formGroup]="form">
  <div *ngIf="showTitle" class="pui-space_bottom-4">
    <h3 pui-typography="h3" i18n="@@sessionTemplateParticipantsAndGuests">Participants and guests</h3>
  </div>

  <pui-toggle class="pui-space_bottom-4" formControlName="disableGuests" data-qa-id="guide-service-editor-guests-form">
    <div class="d-flex flex-column pui-space_bottom-2">
      <span pui-typography="body-s-bold" i18n="@@EnableGuests">Enable guests</span>
      <span
        class="participants-and-guests-form__toggle-description"
        pui-typography="body-s"
        i18n="@@EnableClientGuestsDescriptionNewText"
        >Allow clients to invite guests while booking the session.</span
      >
    </div>
  </pui-toggle>

  <pui-toggle
    class="pui-space_bottom-4"
    formControlName="offerSeatsEnabled"
    data-qa-id="guide-service-editor-seats-form"
    #offerSeats
    (change)="toggleOfferSeats($event.checked)">
    <div class="d-flex flex-column">
      <span pui-typography="body-s-bold" i18n="@@OfferSeats">Offer seats</span>

      <span
        class="participants-and-guests-form__toggle-description"
        pui-typography="body-s"
        i18n="@@OfferSeatsDescription"
        >Offer seats for booking.</span
      >

      <ng-container *ngIf="offerSeats.checked">
        <div class="participants-and-guests-form__seats-count pui-space_top-2" pui-group [collapsed]="true">
          <pui-input-field>
            <input
              formControlName="seatsPerTimeSlot"
              data-qa-id="guide-service-editor-seats-per-time-slot"
              pui-input-text
              type="text"
              [textMask]="{ mask: offseatsMask, guide: false }" />
          </pui-input-field>
          <button
            class="disabled-button"
            data-qa-id="guide-service-editor-seats-button"
            [disabled]="true"
            pui-button
            i18n="@@seats">
            seats
          </button>
        </div>
        <pui-input-error [formControl]="form.get('seatsPerTimeSlot')" role="alert"></pui-input-error>

        <div class="pui-space_top-2">
          <ng-content select="[configuration-content]"></ng-content>
        </div>
      </ng-container>
    </div>
  </pui-toggle>

  <pui-toggle
    class="pui-space_bottom-4"
    formControlName="hasAutoConfirmation"
    data-qa-id="guide-service-editor-autoconfirm-form">
    <div class="d-flex flex-column">
      <span pui-typography="body-s-bold" i18n="@@AutoConfirmSessions">Auto-confirm sessions</span>
      <span
        class="participants-and-guests-form__toggle-description"
        pui-typography="body-s"
        i18n="@@AutoConfirmApprovalSessionsDescription"
        >Allow clients to book the session without approval.</span
      >
    </div>
  </pui-toggle>
</form>
