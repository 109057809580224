import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { CheckListModule } from '@app/modules/ui-kit/check-list/check-list.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, CheckListModule],
  declarations: [RangeDatepickerComponent],
  exports: [RangeDatepickerComponent]
})
export class RangeDatepickerModule {}
