<div
  *ngIf="user.namedUrl && !disableNamedUrl; else notAsLink"
  class="row-m0 user-info-container as-link"
  [ngClass]="{ opacity: isOpacity }"
  [routerLink]="['/', user.namedUrl]"
  [attr.data-qa-id]="'user-info-as-link-card-' + user.id">
  <dl [style.min-height.px]="photoSize" [style.padding-left.px]="photoSize + 8" class="user-info">
    <pui-avatar
      class="user-photo"
      [text]="user.name || userName"
      [avatarUrl]="user.photo"
      [size]="avatarSize"></pui-avatar>
    <dt [attr.data-qa-id]="'user-info-username-title-' + user.id" i18n="@@userInfoWithArchived">
      {{ userName }}{{ archived ? ' (archived)' : '' }}
    </dt>
    <dd>
      <ng-container
        *ngTemplateOutlet="
          uiPayload;
          context: { $implicit: user, specialization: user.specialization, id: user.id }
        "></ng-container>
    </dd>
  </dl>
</div>

<ng-template #notAsLink>
  <div
    class="row-m0 user-info-container"
    [attr.data-qa-id]="'user-info-select-option-' + user.id"
    [ngClass]="{ opacity: isOpacity }">
    <dl [style.min-height.px]="photoSize" [style.padding-left.px]="photoSize + 16" class="user-info">
      <pui-avatar
        class="user-photo"
        [text]="user.name || userName"
        [avatarUrl]="user.photo"
        [size]="avatarSize"></pui-avatar>
      <ng-container *ngIf="!showEmailAndGuides; else emailAndGuides">
        <dt [attr.data-qa-id]="'user-info-username-title-' + user.id" i18n="@@userInfoWithArchived">
          {{ userName }}{{ archived ? ' (archived)' : '' }}
        </dt>
      </ng-container>
      <dd [attr.data-qa-id]="'user-info-email-title-' + user.id">
        <ng-container
          *ngTemplateOutlet="
            uiPayload;
            context: { $implicit: user, specialization: user.specialization, id: user.id }
          "></ng-container>
      </dd>
    </dl>
  </div>
</ng-template>

<ng-template #emailAndGuides>
  <ui-card-header class="user-email">
    <ui-card-title>
      <span>{{ userName }}{{ archived ? ' (archived)' : '' }}</span>
    </ui-card-title>
    <ui-card-subtitle>
      <span>{{ userEmail }}</span>
    </ui-card-subtitle>
  </ui-card-header>
  <div class="user-guides">
    <ng-container *ngIf="user.guides?.length > 0">
      <avatar-group [items]="user.guides | toAvatarGroup: 'username':'photo'" size="m"></avatar-group>
    </ng-container>
  </div>
</ng-template>
