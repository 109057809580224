import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountrySelector } from './country-selector';
import { UiSelectorSingleModule } from '@app/modules/ui-kit/_base/ui-selector-single';
import { UiOptionModule } from '@app/modules/ui-kit/_base/ui-option';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CountrySelector],
  imports: [CommonModule, UiSelectorSingleModule, UiOptionModule, FormsModule, ReactiveFormsModule],
  exports: [CountrySelector]
})
export class CountrySelectorSingleModule {}
