import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PuiButtonModule,
  PuiIconModule,
  PuiInputModule,
  PuiLinkModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CustomAvailabilityDrawerComponent } from '../../drawers/custom-availability-drawer/custom-availability-drawer.component';
import { DateOverridesDrawerModule } from '../../drawers/date-overrides-drawer/date-overrides-drawer.module';
import { WorkingTimeFormModule } from '../working-time-form/working-time-form.module';
import { CustomAvailabilityComponent } from './custom-availability-form.component';
import { TimezoneFormModule } from '@app/modules/session-forms/forms/timezone-form/timezone-form.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiLinkModule,
    PuiIconModule,
    NgbTooltipModule,
    PuiTypographyModule,
    PuiButtonModule,
    WorkingTimeFormModule,
    DateOverridesDrawerModule,
    TimezoneFormModule
  ],
  declarations: [CustomAvailabilityComponent, CustomAvailabilityDrawerComponent],
  exports: [CustomAvailabilityComponent]
})
export class CustomAvailabilityModule {}
