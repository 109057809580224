import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { QuillViewComponent } from 'ngx-quill';
import { Observable, Subject } from 'rxjs';
import { INoteEditorContent } from '@app/shared/interfaces/notes';
import { TextEditorImageComponent } from '@app/modules/text-editor/components';
import { notesToolbarContainer } from '@app/modules/text-editor/modules';
import { takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-text-editor-notes',
  templateUrl: './text-editor-notes.component.html',
  styleUrls: ['./text-editor-notes.component.scss']
})
export class TextEditorNotesComponent implements OnDestroy, OnInit {
  private readonly isBrowser: boolean;

  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _destroyEditor$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isEditing: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _noteContent = '';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _noteEditorHtml = '';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _noteEditorText = '';

  isEmpty = true;

  modules = {
    toolbar: {
      container: notesToolbarContainer
    }
  };

  form = this._fb.group({
    noteEditorContent: this._fb.control('')
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get editorContent() {
    return this._noteContent;
  }

  get isEditing(): boolean {
    return this._isEditing;
  }

  @ViewChild('noteEditorInstance', { static: false })
  // @ts-expect-error TS2564
  noteEditor: TextEditorImageComponent;

  @ViewChild('quillView', { static: false })
  // @ts-expect-error TS2564
  quillView: QuillViewComponent;

  @Input()
  readonly = false;

  @Input()
  shouldClamp = true;

  @Input()
  noteIndex?: number;

  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  // @ts-expect-error TS7032
  set startEdit(value) {
    if (value) {
      this.startEditing();
    }
  }

  @HostBinding('style.--min-lines')
  @Input()
  minLines = 5;

  @HostBinding('class.clamped')
  @Input()
  clamped = false;

  @HostBinding('class.disabled') get disabledClass(): boolean {
    return this.form.disabled;
  }

  @Input()
  // @ts-expect-error TS7032
  set noteHtml(value) {
    this.isEmpty = !value;
    this._noteEditorHtml = value;
  }

  @Input()
  // @ts-expect-error TS7032
  set noteDelta(value) {
    this._noteContent = value;
  }

  @Input()
  // @ts-expect-error TS7032
  set noteText(value) {
    this._noteEditorText = value;
  }

  @Input()
  // @ts-expect-error TS2564
  emptyNotePreview: TemplateRef<unknown>;

  // Subscription to this observable occurs during OnInit
  @Input()
  // @ts-expect-error TS2564
  focusToTextField$: Observable<void>;

  @Output()
  noteChange = new EventEmitter<INoteEditorContent>();

  @Output()
  endEditing = new EventEmitter<INoteEditorContent>();

  @Output()
  isEditorOpened = new EventEmitter<boolean>();

  constructor(
    private readonly _fb: FormBuilder,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.focusToTextField$?.pipe(takeUntil(this.destroy$)).subscribe(() => this.startEditing());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  onEditorInit(quill): void {
    this.form.patchValue({ noteEditorContent: this._noteContent }, { emitEvent: false });

    // [TODO] On focus move cursor to the end of note
    quill.focus();
  }

  // @ts-expect-error TS7031
  contentChanged({ text, content, html }): void {
    this._noteContent = JSON.stringify(content);
    this._noteEditorHtml = html;
    this._noteEditorText = text;
    this.isEmpty = !html;
    this.emitValues(text, this._noteContent, html);
  }

  // @ts-expect-error TS7006
  emitValues(text, content, html): void {
    this.noteChange.next({ text, content, html });
  }

  onStartEditing(): void {
    if (!this.hasSelection()) {
      this.startEditing();
    }
  }

  // @ts-expect-error TS7006
  onStopEditing(event): void {
    if (this.isUploaderModal(event)) {
      return;
    }

    if (!this.hasSelection()) {
      this.stopEditing();
    }
  }

  private hasSelection(): boolean {
    // @ts-expect-error TS2531
    return this.isBrowser && this.document.getSelection().toString().length > 0;
  }

  // @ts-expect-error TS7006
  private isUploaderModal(event): boolean {
    const excludes = ['app-video-uploader', 'app-html-uploader', 'fsp-picker-holder'];
    let isUploaderModal = false;

    if (event.path) {
      event.path?.forEach((element: HTMLElement) => {
        if (excludes.includes(element.localName) || excludes.includes(element.className)) {
          isUploaderModal = true;
        }
      });
    }

    // file & video uploader modals
    if (event.target.closest('.fsp-picker') || event.target.closest('.modal-content')) {
      isUploaderModal = true;
    }

    return isUploaderModal;
  }

  startEditing(): void {
    if (this.form.enabled) {
      this._isEditing = true;
      this.isEditorOpened.emit(true);
    }
  }

  stopEditing(): void {
    this.endEditing.emit({
      text: this._noteEditorText,
      content: this._noteContent,
      html: this._noteEditorHtml
    });
    this._isEditing = false;
    this.isEditorOpened.emit(false);
    this.clamped = this.shouldClamp;
    this._destroyEditor$.next();
  }

  reset(): void {
    this.form.reset();
    this._noteContent = '';
    this._noteEditorHtml = '';
    this._noteEditorText = '';
    this.isEmpty = true;
  }

  // @ts-expect-error TS7006
  showMore($event): boolean {
    this.clamped = false;
    $event.preventDefault();
    return false;
  }

  // @ts-expect-error TS7006
  showLess($event): boolean {
    this.clamped = true;
    $event.preventDefault();
    return false;
  }

  // @ts-expect-error TS7006
  isOverflown(element): boolean {
    if (element && element.elementRef && this.shouldClamp) {
      const el = element.elementRef.nativeElement.getElementsByClassName('ql-editor').item(0);
      if (el && (el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth)) {
        this.clamped = true;
        return true;
      }
    }
    return false;
  }
}
