// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IAWSFileInfo {
  readonly location: string;
  readonly etag: string;
  readonly bucket: string;
  readonly key: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IFileInfoResponse extends IAWSFileInfo {
  readonly url: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IStorageFileInfo extends IAWSFileInfo {
  readonly name: string;
  readonly size: number;
  readonly type: string;
}

export enum MimeTypes {
  APPLICATION = 'application',
  AUDIO = 'audio',
  IMAGE = 'image',
  OTHER = 'other',
  VIDEO = 'video',
  TEXT = 'text'
}
