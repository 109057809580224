import { AbstractConstructor, Constructor } from '@angular/material/core/common-behaviors/constructor';
import { ElementRef } from '@angular/core';

export interface CanSize {
  /** Theme size for the component. */
  size: ThemeSize;

  /** Default size to fall back to if no value is set. */
  defaultSize: ThemeSize | undefined;
}

export type CanSizeCtor = Constructor<CanSize> & AbstractConstructor<CanSize>;

export interface HasElementRef {
  _elementRef: ElementRef;
}

/** Possible size values. */
export type ThemeSize = 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | undefined;

/** Mixin to augment a directive with a `color` property. */
export function mixinSize<T extends AbstractConstructor<HasElementRef>>(
  base: T,
  defaultSize?: ThemeSize
): CanSizeCtor & T;
export function mixinSize<T extends Constructor<HasElementRef>>(base: T, defaultSize?: ThemeSize): CanSizeCtor & T {
  return class extends base {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _size: ThemeSize;
    defaultSize = defaultSize;

    get size(): ThemeSize {
      return this._size;
    }

    set size(value: ThemeSize) {
      const newSize = value || this.defaultSize;

      if (newSize !== this._size) {
        if (this._size) {
          this._elementRef.nativeElement.classList.remove(`theme-size-${this._size}`);
        }
        if (newSize) {
          this._elementRef.nativeElement.classList.add(`theme-size-${newSize}`);
        }

        this._size = newSize;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(...args);

      // Set the default size that can be specified from the mixin.
      this.size = defaultSize;
    }
  };
}
