import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-section-wrapper',
  templateUrl: './editor-section-wrapper.component.html',
  styleUrls: ['./editor-section-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorSectionWrapperComponent {
  @Input() standard = true;

  // @ts-expect-error TS2564
  @Input() title: string;

  // @ts-expect-error TS2564
  @Input() note: string;

  // @ts-expect-error TS2564
  @Input() i18nTitle: string;

  // @ts-expect-error TS2564
  @Input() i18nNote: string;
}
