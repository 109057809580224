export class DirectChatUserId {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _id: number;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _workspaceId: number | null;

  get id(): number {
    return this._id;
  }

  get workspaceId(): number | null {
    return this._workspaceId;
  }

  constructor(idOrCode: number | string, workspaceId?: number | null) {
    if (typeof idOrCode === 'string') {
      const [idString, workspaceIdString] = idOrCode.split('W');

      // TODO: add validation if id, workspaceId - numbers
      this._id = +idString;
      this._workspaceId = workspaceIdString ? +workspaceIdString : null;
    }

    if (typeof idOrCode === 'number') {
      this._id = idOrCode;
      this._workspaceId = workspaceId || null;
    }
  }

  toString(): string {
    return this._workspaceId ? `${this._id}W${this._workspaceId}` : `${this.id}`;
  }
}
