import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionPaymentFormatterComponent } from './session-payment-formatter.component';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

@NgModule({
  declarations: [SessionPaymentFormatterComponent],
  imports: [CommonModule, PlatformStyleModule],
  exports: [SessionPaymentFormatterComponent]
})
export class SessionPaymentFormatterModule {}
