import { createReducer, on } from '@ngrx/store';
import { GuideClientsStoreState } from './commons/types';
import produce from 'immer';
import {
  guideClientsFetchAll,
  guideClientsFetchAllSuccess,
  guideClientsResetAll,
  GuideClientsStoreActions,
  setClients,
  updateClients,
  updateClientsError,
  updateClientsSuccess
} from './guide-clients-store.actions';

const initialState: GuideClientsStoreState = {
  status: 'initial',
  data: {}
};

export const guideClientsStoreReducer = createReducer<GuideClientsStoreState, GuideClientsStoreActions>(
  initialState,
  on(guideClientsResetAll, () => initialState),
  on(guideClientsFetchAll, state =>
    produce(state, draft => {
      draft.status = 'loading';
    })
  ),
  on(guideClientsFetchAllSuccess, (state, { clients }) =>
    produce(state, draft => {
      draft.status = 'idle';
      draft.data = clients.reduce(
        (acc, client) => ({
          ...acc,
          // @ts-expect-error TS7053
          [client.id]: acc[client.id] || client
        }),
        {}
      );
    })
  ),
  // Update actions
  on(updateClients, state =>
    produce(state, draft => {
      draft.status = 'saving';
    })
  ),
  on(updateClientsSuccess, updateClientsError, state =>
    produce(state, draft => {
      draft.status = 'idle';
    })
  ),
  on(setClients, (state, { payload }) =>
    produce(state, draft => {
      for (const id in payload) {
        const update = payload[id];

        if (!draft.data[id]) {
          return;
        }

        draft.data[id] = {
          ...draft.data[id],
          ...update
        };
      }
    })
  )
);
