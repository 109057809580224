import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, share, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Component({
  templateUrl: './external-link.component.html',
  providers: [PuiDestroyService],
  styleUrls: ['./external-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLinkComponent implements OnInit {
  private url$ = this.route.queryParams.pipe(map(params => params['url']));

  readonly redirectUrl$ = this.url$.pipe(
    switchMap(url => this.http.get<{ url: string }>(url).pipe(map(response => response.url))),
    share()
  );

  errorMessage?: string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private cdRef: ChangeDetectorRef,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    this.redirectUrl$
      .pipe(
        catchError(() => throwError('Sorry, you have no access to the given resource')),
        takeUntil(this.destroy$)
      )
      .subscribe(
        redirectUrl => {
          window.location.href = redirectUrl;
        },
        errorMessage => {
          this.errorMessage = errorMessage;
          this.cdRef.markForCheck();

          this.notificationsService.error(errorMessage);
        }
      );
  }
}
