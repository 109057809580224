import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServicesModule } from '@app/modules/services/services.module';
import { SessionTemplateModule } from '@app/modules/session-template/session-template.module';
import { SharedModule } from '@app/shared';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';

import { SessionTemplateLandingComponent } from './components/session-template-landing/session-template-landing.component';
import { IdGuard } from './guards/id.guard';

@NgModule({
  imports: [CommonModule, ServicesModule, SharedModule, SessionTemplateModule],
  exports: [SessionTemplateLandingComponent],
  declarations: [SessionTemplateLandingComponent],
  providers: [IdGuard, MetaTagService]
})
export class SessionTemplatesModule {}
