export enum SoundType {
  CALL = 'call',
  MESSAGE = 'message',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  ENDING_SESSION = 'ending_session',
  NEW_REQUEST = 'new_request',
  NOTIFICATION = 'notification'
}

export enum DisabledReason {
  ACTIVE_SESSION = 'ACTIVE_SESSION'
}

export const activeSessionDisabledSounds: Record<string, boolean> = {
  [SoundType.MESSAGE]: true,
  [SoundType.NEW_REQUEST]: true,
  [SoundType.NOTIFICATION]: true
};
