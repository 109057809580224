import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { KitBrandingStore } from '@app/modules/ui-kit/kit-branding/services/kit-branding.store';
import { BrandingColorsUtil } from '../utils';
import { ThemeType } from '../types/theme.type';
import { Color } from '../interfaces';
import {
  ACCENT,
  CONTRAST_TEXT_COLOR_PALETTE,
  LIGHT_THEME_BACKGROUND_PALETTE,
  LIGHT_THEME_FOREGROUND_PALETTE,
  PRIMARY
} from '../const';
import { prepareColors } from '@app/modules/ui-kit/_theming/config.mock';

/**
 * Service for branding configuration loading
 * @export
 * @class KitBrandingService
 */
@Injectable()
export class KitBrandingService {
  private readonly isBrowser: boolean;
  /**
   * HEX value for Primary theme
   *  @type string
   *  @memberOf KitBrandingService
   */
  // @ts-expect-error TS2564
  private currentPrimary: string;

  /**
   * HEX value for Accent theme
   *  @type string
   *  @memberOf KitBrandingService
   */
  // @ts-expect-error TS2564
  private currentAccent: string;

  private brandingUtil: BrandingColorsUtil = new BrandingColorsUtil();

  /**
   * Primary material color objects
   * @type Color[]
   * @memberof BrandingService
   */
  primary: Color[] = PRIMARY;

  /**
   * Accent material color objects
   * @type Color[]
   * @memberof BrandingService
   */
  accent: Color[] = ACCENT;

  /**
   * Ui-kit background palette
   * @type Color[]
   * @memberof BrandingService
   */
  background: Color[] = prepareColors(LIGHT_THEME_BACKGROUND_PALETTE);

  /**
   * Ui-kit foreground palette
   * @type Color[]
   * @memberof BrandingService
   */
  foreground: Color[] = prepareColors(LIGHT_THEME_FOREGROUND_PALETTE);

  /**
   * Ui-kit contrast text color palette
   * @type Color[]
   * @memberof BrandingService
   */
  contrastTextColors: Color[] = prepareColors(CONTRAST_TEXT_COLOR_PALETTE);

  /**
   * Creates an instance of BrandingService.
   * @param  {Document} document - HTML document
   * @param  {KitBrandingStore} kitBrandingStore - Local Storage Wrapper
   * @param platformId
   * @memberof BrandingService
   */
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly kitBrandingStore: KitBrandingStore,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId) && !!localStorage;
  }

  /**
   * Load primary and accent colors
   * Called for initializing default theme variables
   * @public
   * @return {void}
   * @memberof BrandingService
   */
  loadVariables(colors?: Color[]): void {
    if (this.isBrowser) {
      this.loadBackground(true, colors);
      this.loadForeground(true, colors);
    }
  }

  /**
   * Used to get colors from {BrandingColorsUtil}, and apply them with our private methods
   * We also check, if the values changed due, since applying themes is unnecessary in case values didn't change
   * @param {ThemeType} theme - used for detection, which is used for css variables keys
   * @param {string} value - HEX value, which will be applied
   * @void
   * @memberOf KitBrandingService
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeTheme(theme: ThemeType, value: string): void {}

  /**
   * @param {ThemeType} theme - used for detection, which is used for css variables keys
   * @param {string} value - HEX value, which will be applied
   * @private
   * @return {boolean}
   * @memberOf KitBrandingService
   */
  private isChanging(theme: ThemeType, value: string): boolean {
    switch (theme) {
      case 'primary':
        return this.currentPrimary !== value;
      case 'accent':
        return this.currentAccent !== value;
    }
    return false;
  }

  /**
   * Load Primary variables
   * @param {boolean} override - indicates that store values should be replaced with @param colors
   * @param {Color[]} colors - we build css variables from this array
   * @private
   * @return {void}
   * @memberof BrandingService
   */
  loadPrimary(override: boolean, colors: Color[] = this.primary): void {
    // temporary disabled before we implementing theme setup in Admin
    // const colorsFromStore: Color[] = this.getColors('primary');
    const colorsFromStore: Color[] = [];
    let colorsSource: Color[];
    if (override) {
      colorsSource = colors;
    } else {
      colorsSource = colorsFromStore.length ? colorsFromStore : colors;
    }
    for (const color of colorsSource) {
      const key1 = `--theme-primary-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-primary-contrast-${color.name}`;
      // @ts-expect-error TS2532
      const value2 = color.contrast.hex;
      this.document.documentElement.style.setProperty(key1, value1);
      this.document.documentElement.style.setProperty(key2, value2);
    }
    this.setStorage('primary', colors);
  }

  /**
   * Load Accent variables
   * @param {boolean} override - indicates that store values should be replaced with @param colors
   * @param {Color[]} colors - we build css variables from this array
   * @private
   * @return {void}
   * @memberof BrandingService
   */
  private loadAccent(override: boolean, colors: Color[] = this.accent): void {
    // temporary disabled before we implementing theme setup in Admin
    // const colorsFromStore: Color[] = this.getColors('accent');
    const colorsFromStore: Color[] = [];
    let colorsSource: Color[];
    if (override) {
      colorsSource = colors;
    } else {
      colorsSource = colorsFromStore.length ? colorsFromStore : colors;
    }
    for (const color of colorsSource) {
      const key1 = `--theme-secondary-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-secondary-contrast-${color.name}`;
      // @ts-expect-error TS2532
      const value2 = color.contrast.hex;
      this.document.documentElement.style.setProperty(key1, value1);
      this.document.documentElement.style.setProperty(key2, value2);
    }
    this.setStorage('accent', colors);
  }

  /**
   * Load Background variables
   * @param {boolean} override - indicates that store values should be replaced with @param colors
   * @param {Color[]} colors - we build css variables from this array
   * @private
   * @return {void}
   * @memberof BrandingService
   */
  loadBackground(override: boolean, colors: Color[] = this.background): void {
    // temporary disabled before we implementing theme setup in Admin
    // const colorsFromStore: Color[] = this.getColors('accent');
    const colorsFromStore: Color[] = [];
    let colorsSource: Color[];
    if (override) {
      colorsSource = colors;
    } else {
      colorsSource = colorsFromStore.length ? colorsFromStore : colors;
    }
    for (const color of colorsSource) {
      const key1 = `--b-${color.name}`;
      const value1 = color.hex;
      this.document.documentElement.style.setProperty(key1, value1);
    }
    this.setStorage('accent', colors);
  }

  loadForeground(override: boolean, colors: Color[] = this.foreground): void {
    const colorsFromStore: Color[] = [];
    let colorsSource: Color[];
    if (override) {
      colorsSource = colors;
    } else {
      colorsSource = colorsFromStore.length ? colorsFromStore : colors;
    }
    for (const color of colorsSource) {
      const key1 = `--f-${color.name}`;
      const value1 = color.hex;
      this.document.documentElement.style.setProperty(key1, value1);
    }
    this.setStorage('accent', colors);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadDefaults(defaults: { [key: string]: string }) {
    for (const color in defaults) {
      const key = `--theme-${color}-900`;
      const value = defaults[color];
      this.document.documentElement.style.setProperty(key, value);
    }
  }

  private loadContrastTextColors(override: boolean, colors: Color[] = this.contrastTextColors): void {
    const colorsFromStore: Color[] = [];
    let colorsSource: Color[];
    if (override) {
      colorsSource = colors;
    } else {
      colorsSource = colorsFromStore.length ? colorsFromStore : colors;
    }
    for (const color of colorsSource) {
      const key1 = `--c-t-${color.name}`;
      const value1 = color.hex;
      this.document.documentElement.style.setProperty(key1, value1);
    }
    this.setStorage('accent', colors);
  }

  private getColors(theme: ThemeType): Color[] {
    return this.kitBrandingStore.getColors(theme);
  }

  private setStorage(theme: ThemeType, colors: Color[]): void {
    this.kitBrandingStore.setColor(theme, colors);
  }
}
