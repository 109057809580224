<div class="header" *puiLet="quizBasicInfo$ | async as quizBasicInfo">
  <div
    class="avatar"
    *ngIf="quizBasicInfo?.avatar"
    [ngStyle]="{ 'background-image': 'url(' + quizBasicInfo?.avatar + ')' }"></div>
  <div class="name">
    {{ quizBasicInfo?.title }}
  </div>
  <div class="tabs" *ngIf="{ tabEnabled: tabEnabled$ | async } as options">
    <a
      *ngIf="!isAdmin"
      (click)="options.tabEnabled && navigateRelative('show-stats')"
      [class.active]="router.url.indexOf('/show-stats') > -1"
      i18n="@@responses">
      Responses
    </a>
    <a
      (click)="options.tabEnabled && navigateRelative('content')"
      [class.active]="router.url.indexOf('/content') > -1"
      i18n="@@content">
      Content
    </a>
    <a
      (click)="options.tabEnabled && navigateRelative('settings')"
      [class.active]="router.url.indexOf('/settings') > -1"
      i18n="@@automations">
      Automations
    </a>
  </div>

  <div class="close" (click)="close()"></div>
</div>
<router-outlet></router-outlet>
