import { Injectable } from '@angular/core';
import { IGuideScheduleRange } from './types';
import { ScheduleDatesStore } from './schedule-dates-store';
import { DateTime } from 'luxon';

@Injectable()
export class ScheduleBookDates extends ScheduleDatesStore {
  /*
   * Dictionary.
   * Key = date.
   * Value = array of intervals having start date equal to date.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleDates: {
    [index: string]: IGuideScheduleRange[];
  };

  constructor() {
    super();
    this._scheduleDates = {};
  }

  add(scheduleRanges: IGuideScheduleRange[], tz?: string): void {
    this.addScheduleDates(scheduleRanges, tz);
  }

  buildDateKey(year: number, month: number, day: number, zoneName?: string): string {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const formatter = number => (number >= 10 ? '' + number : '0' + number);
    return `${year}-${formatter(month)}-${formatter(day)}${zoneName ? '-' + zoneName : ''}`;
  }

  get(key: string): IGuideScheduleRange[] {
    return this._scheduleDates[key];
  }

  has(key: string): boolean {
    return !!this._scheduleDates[key];
  }

  reset(): void {
    this._scheduleDates = {};
  }

  private addScheduleDate(scheduleRange: IGuideScheduleRange, tz?: string): void {
    let startDateTime = DateTime.fromISO(scheduleRange.dateStart);
    let endDateTime = DateTime.fromISO(scheduleRange.dateEnd);

    if (tz) {
      startDateTime = startDateTime.setZone(tz);
      endDateTime = endDateTime.setZone(tz);
    }

    this.addScheduleRangeToDate(this.createDateKeyFromDateString(startDateTime, tz), scheduleRange);

    if (
      this.checkIfDatesAreDifferent(startDateTime, endDateTime) &&
      !this.checkIfNextDayStart(startDateTime, endDateTime) &&
      this.checkIfNextDayRangeHasAppropriateSize(startDateTime, endDateTime)
    ) {
      const nextDayRange = { ...scheduleRange };
      nextDayRange.dateStart = startDateTime.plus({ day: 1 }).startOf('day').toISO();
      this.addScheduleDate(nextDayRange);
    }
  }

  private addScheduleDates(scheduleRanges: IGuideScheduleRange[], tz?: string): void {
    for (let i = 0, count = scheduleRanges.length; i < count; i++) {
      this.addScheduleDate({ ...scheduleRanges[i] }, tz);
    }
  }

  private addScheduleRangeToDate(key: string, value: IGuideScheduleRange): void {
    if (!this._scheduleDates[key]) {
      this._scheduleDates[key] = [];
    }
    if (
      !this._scheduleDates[key].find(
        i => i.dateEnd === value.dateEnd && i.dateStart === value.dateStart && i.id === value.id
      )
    ) {
      this._scheduleDates[key].push(value);
    }
  }

  private createDateKeyFromDateString(dateString: DateTime, tz?: string): string {
    return dateString.toFormat('yyyy-MM-dd') + (tz ? '-' + tz : '');
  }

  private checkIfDatesAreDifferent(startDateTime: DateTime, endDateTime: DateTime): boolean {
    return (
      startDateTime.day !== endDateTime.day ||
      startDateTime.month !== endDateTime.month ||
      startDateTime.year !== endDateTime.year
    );
  }

  private checkIfNextDayRangeHasAppropriateSize(startDateTime: DateTime, endDateTime: DateTime): boolean {
    return endDateTime.diff(startDateTime.plus({ day: 1 }).startOf('day'), 'minutes').minutes >= 15;
  }

  private checkIfNextDayStart(startDateTime: DateTime, endDateTime: DateTime): boolean {
    return startDateTime.plus({ day: 1 }).startOf('day').day === endDateTime.day;
  }
}
