import { ClipboardModule } from 'ngx-clipboard';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GuideClientModule } from '@app/modules/guide-client/guide-client.module';
// DO NOT USE LAZY loading for these two modules
import { FindYourGuideModule } from '@app/screens/find-your-guide/find-your-guide.module';
import { GuidePublicProfileModule } from '@app/screens/guide-public-profile/guide-public-profile.module';
import { SurveysModule } from '@app/screens/surveys/surveys.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthModule } from './auth/auth.module';
import { BlogModule } from './blog/blog.module';
import { ChatModule } from './chat/chat.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GuideSurveysModule } from './guide/guide-surveys/guide-surveys.module';
import { HomepageComponent } from './homepage/homepage.component';
import { PackagesModule } from './packages/packages.module';
import { PlatformSubscriptionCommonComponent } from './platform-subscription/platform-subscription.component';
import { ProgramsModule } from './programs/programs.module';
import { SessionTemplatesModule } from './session-templates/session-templates.module';
import { WikiModule } from './wiki/wiki.module';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    AuthModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    NgbModule,
    ChatModule,
    FindYourGuideModule,
    GuidePublicProfileModule,
    GuideSurveysModule,
    BlogModule,
    PackagesModule,
    ProgramsModule,
    WikiModule,
    SessionTemplatesModule,
    SurveysModule,
    GuideClientModule
  ],
  declarations: [DashboardComponent, HomepageComponent, PlatformSubscriptionCommonComponent],
  exports: []
})
export class ScreensModule {}
