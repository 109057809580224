import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

export abstract class ApplicationEvent {}

@Injectable({
  providedIn: 'root'
})
export class ApplicationEventService {
  private readonly event$ = new ReplaySubject<ApplicationEvent>();

  constructor() {
    console.log('== ApplicationEventService init');
  }

  next(event?: ApplicationEvent) {
    if (event instanceof ApplicationEvent) {
      this.event$.next(event);
    }
  }

  on<R>(eventType: ApplicationEvent): Observable<R> {
    console.log('=== handle', eventType);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.event$.asObservable().pipe(
      filter(
        event =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          event instanceof eventType
      )
    );
  }
}
