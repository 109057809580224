import { NotificationsService } from 'angular2-notifications';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, pluck, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { GuideServicesApiService } from '@app/modules/service-scheduling/services/guide-services-api.service';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { SessionType } from '@app/shared/enums/session-type';
import { ComponentStore } from '@ngrx/component-store';

export interface ServiceState {
  status: 'initial' | 'loading' | 'idle';
  // TODO Issues with service model
  service: Partial<GuideServices.RootObject> | undefined;
}

const initialState: ServiceState = {
  status: 'initial',
  service: undefined
};

@Injectable({ providedIn: 'root' })
export class SelectedServiceStore extends ComponentStore<ServiceState> {
  // ** SELECTORS
  readonly service$ = this.select(state => state.service);
  readonly serviceDebounced$ = this.select(state => state.service, { debounce: true });
  readonly hosts$ = this.select(this.service$, service => service?.hosts);
  readonly sessionType$ = this.select(this.service$, service => service?.sessionType);
  readonly isPersonalSessionType$ = this.select(
    this.sessionType$,
    sessionType$ => sessionType$ === SessionType.PERSONAL
  );
  readonly isRoundRobinSessionType$ = this.select(
    this.sessionType$,
    sessionType$ => sessionType$ === SessionType.ROUND_ROBIN
  );
  readonly isCollectiveSessionType$ = this.select(
    this.sessionType$,
    sessionType$ => sessionType$ === SessionType.COLLECTIVE
  );
  readonly status$ = this.select(state => state.status);
  readonly isLoading$ = this.select(this.status$, status => status === 'loading');
  readonly isIdle$ = this.select(this.status$, status => status === 'idle');

  // ** EFFECTS
  readonly getService = this.effect((serviceId$: Observable<number>) =>
    serviceId$.pipe(
      tap(() => {
        this.patchState({
          status: 'loading'
        });
      }),
      // 👇 Handle race condition with the proper choice of the flattening operator.
      switchMap(serviceId =>
        this.guideServicesApiService.getGuideService(serviceId).pipe(
          pluck('template'),
          // 👇 Act on the result within inner pipe.
          tap({
            next: service => {
              this.patchState({
                status: 'idle',
                service
              });
            },
            error: error => {
              this.patchState({
                status: 'idle'
              });

              this.notificationsService.error('Server error', 'Error while fetching session', null, error);
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly setStatus = this.effect((data$: Observable<'initial' | 'loading' | 'idle'>) =>
    data$.pipe(
      tap((status: 'initial' | 'loading' | 'idle') => {
        this.patchState({
          status
        });
      })
    )
  );

  // *** 🧪 Experimental

  // Return stable state, but if service not loaded will fetch
  readonly getServiceById$ = (serviceId: number) =>
    this.serviceDebounced$.pipe(
      tap((service: ServiceState['service']) => {
        // Check if service has in store and is this current service.
        if (!service || service?.id !== serviceId) {
          // Then fetch service by serviceId.
          this.getService(serviceId);
        }
      }),
      filter((service: ServiceState['service']) => !!service && service?.id === serviceId)
    );

  constructor(
    private guideServicesApiService: GuideServicesApiService,
    private notificationsService: NotificationsService
  ) {
    super(initialState);
  }
}
