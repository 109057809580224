import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { UserWalletService } from '@app/core/user-wallet/user-wallet.service';
import { filter, map } from 'rxjs/operators';
import { UserService } from '@app/core/users/user.service';
import { SidenavModeService } from '@app/modules/sidenav/services';
import { IWidgetData, IWidgetService } from '@app/cdk/widgets';
import { ISidenavCurrentWithSubtitleView } from '@app/modules/sidenav/components/sidenav-current/interface';
import { SidenavCurrentWithSubtitleViewComponent } from '@app/modules/sidenav/components/sidenav-current/view/current-view.component';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';
import { BrandingService } from '@app/core/branding/branding.service';

@Injectable()
export class CurrentClientService implements IWidgetService<ISidenavCurrentWithSubtitleView> {
  view$: Observable<IWidgetData<ISidenavCurrentWithSubtitleView>>;

  constructor(
    private readonly user$: UserService,
    private readonly _walletService: UserWalletService,
    private readonly _modeService$: SidenavModeService,
    private readonly _platformStyle: PlatformStyle,
    private readonly _brandingService: BrandingService
  ) {
    // @ts-expect-error TS2322
    this.view$ = combineLatest([user$, _walletService.wallet$, _modeService$, _brandingService.globalConfig$]).pipe(
      filter(([user]) => !!user),
      map(([user, wallet, mode, globalConfig]) => {
        const title = `${user!.firstName} ${user!.lastName}`.trim();
        const balance = _platformStyle.transformCurrency(wallet?.balance || 0);
        const subtitle = globalConfig.hideWallet ? null : `${`Balance`}: ${balance}`;

        return {
          component: SidenavCurrentWithSubtitleViewComponent,
          context: {
            avatar: {
              text: title,
              avatarUrl: user!.photo,
              rounded: true,
              background: '#ccc',
              color: '#333'
            },
            title,
            subtitle,
            mode
          }
        };
      })
    );
  }
}
