import { NgModule } from '@angular/core';
import { GuideClientsStoreEffects } from './guide-clients-store.effects';
import { GUIDE_CLIENTS_STORE_KEY } from './commons/consts';
import { guideClientsStoreReducer } from './guide-clients-store.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { GuideClientsApiService } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';

@NgModule({
  imports: [
    StoreModule.forFeature(GUIDE_CLIENTS_STORE_KEY, guideClientsStoreReducer),
    EffectsModule.forFeature([GuideClientsStoreEffects])
  ],
  providers: [GuideClientsApiService]
})
export class GuideClientsStoreModule {}
