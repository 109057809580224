import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComingSoonBadgeComponent } from './coming-soon-badge/coming-soon-badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ComingSoonBadgeComponent],
  exports: [ComingSoonBadgeComponent]
})
export class ComingSoonBadgeModule {}
