<div class="sms-otp-input">
  <span pui-typography="h5">
    <ng-container i18n="@@tfaSmsCodeSentToNumber">Enter the code sent to </ng-container>
    {{ phoneNumber }}
  </span>

  <pui-otp-input
    class="pui-space_top-2 pui-space_bottom-2"
    [formControl]="otpCodeControl"
    numOfDigits="5"
    puiPlaceholder="00000"></pui-otp-input>

  <span
    *ngIf="showInvalidCodeError$ | async"
    class="sms-otp-input__invalid-code-error"
    pui-typography="body-s"
    i18n="@@tfaInvalidOtpCode"
    >Invalid code provided. Please try again
  </span>

  <span
    *ngIf="showServerError$ | async"
    class="sms-otp-input__invalid-code-error"
    pui-typography="body-s"
    i18n="@@tfaCodeSentServerError"
    >Failed to send code. Please try again
  </span>

  <span *ngIf="showTimer$ | async" class="sms-otp-input__subtitle sms-otp-input__secondary" pui-typography="body-s">
    <ng-container i18n="@@tfaSmsResendTimeout">You can request another code in </ng-container>
    {{ seconds$ | async | secondsToTime }}
  </span>

  <span
    *ngIf="!(showTimer$ | async)"
    class="sms-otp-input__subtitle"
    i18n="@@tfaSmsResendCodeButton"
    pui-typography="body-s"
    pui-link
    (click)="sendTfaSmsOtp()">
    Send a code again
  </span>
</div>
