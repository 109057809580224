<section *ngIf="widget">
  <div class="header" [style.backgroundImage]="widget?.icon | safe: 'styleUrl'">
    {{ widget?.title }}
  </div>
  <div class="description">{{ widget?.content }}</div>
  <div class="actions row-m0">
    <div class="col-auto">
      <a class="main-btn" (click)="handle()">
        <span>{{ widget?.link?.text }}</span>
      </a>
    </div>
    <div class="col note">*{{ widget?.note }}</div>
  </div>
</section>
