import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvailableSessionSchedulingService } from './available-session-scheduling.service';
import { AvailableClientSessionsAppearanceEnum } from '@app/modules/client-sessions/enums';
import { IScheduleGuide } from '@app/modules/schedule-boards';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-available-session-scheduling',
  templateUrl: './available-session-scheduling.component.html',
  styleUrls: ['./available-session-scheduling.component.scss'],
  providers: [AvailableSessionSchedulingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableSessionSchedulingComponent {
  availableClientSessionsAppearance = AvailableClientSessionsAppearanceEnum.CLIENT_BOOKING_MODAL;

  guideAvailableSessions$ = this.service.available$.pipe(
    map(sessions => {
      return sessions.filter(({ guide }) => {
        return guide.id === this.guide?.id && guide.workspaceId === this.guide.workspaceId;
      });
    })
  );

  @Input()
  guide?: IScheduleGuide;

  constructor(readonly service: AvailableSessionSchedulingService) {}
}
