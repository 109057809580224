import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { sortTags } from '@app/shared/utils/sort-tags';
import {
  BlogArticle,
  IBlog,
  IPersonalBlog,
  RecommendedArticle,
  blogItemsFactory,
  blogAuthorFactory,
  IBlogCursors
} from '../types';
import {
  ALL_BLOGS_ARTICLES_URL,
  BLOG_ARTICLE_URL,
  BLOG_ARTICLE_RELATED_ARTICLES_URL,
  BLOG__ARTICLE_RELATED_TAGS_URL,
  BLOG_TAGS_URL,
  USER_BLOG_ARTICLES_URL,
  LATEST_ARTICLES_URL
} from '../endpoints';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IBlogServerResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  cursors?: IBlogCursors;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IPersonalBlogServerResponse extends IBlogServerResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  author: any;
}

@Injectable()
export class BlogReaderService {
  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}
  // ToDo: check and fix returning type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAllBlogsArticles$(params: any = {}): Observable<IBlog | any> {
    const httpParams = new HttpParams({ fromObject: params });

    return this.load(ALL_BLOGS_ARTICLES_URL, httpParams, this.mapBlog, 'Cannot load blog content');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUserBlogArticles$(userNameOrId: string, params: any = {}): Observable<IPersonalBlog> {
    const httpParams = new HttpParams({ fromObject: params });

    return this.load(
      `${USER_BLOG_ARTICLES_URL}/${userNameOrId}`,
      httpParams,
      this.mapPersonalBlog,
      'Cannot load blog content'
    );
  }

  getBlogArticle$(id: number): Observable<BlogArticle> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = response => new BlogArticle(response.article);

    return this.load(`${BLOG_ARTICLE_URL}/${id}`, new HttpParams(), mapper, 'Cannot load article');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBlogArticleRelatedArticles$(): Observable<any> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = response => response.articles.map(article => new RecommendedArticle(article));

    return this.load(BLOG_ARTICLE_RELATED_ARTICLES_URL, new HttpParams(), mapper, 'Cannot load related articles');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBlogArticleRelatedTags$(): Observable<any> {
    const httpParams = new HttpParams({ fromObject: { popularOnly: 'true' } });
    // @ts-expect-error TS7031
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = ({ tags }) => tags;

    return this.load(BLOG__ARTICLE_RELATED_TAGS_URL, httpParams, mapper, 'Cannot load related tags');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBlogTags$(): Observable<any> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = response => sortTags(response.tags);

    return this.load(BLOG_TAGS_URL, new HttpParams(), mapper, 'Cannot load tags');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLatestArticles$(): Observable<any> {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const mapper = response => blogItemsFactory(response.articles);

    return this.load(LATEST_ARTICLES_URL, new HttpParams(), mapper, 'Cannot load latest articles');
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleLoadError(error, description: string): Observable<any> {
    this._notifications.error(`Load Error`, description);
    return throwError(error);
  }

  protected load<T1, T2>(
    url: string,
    httpParams: HttpParams,
    mapper: (a: T1) => T2,
    errorMessage: string
  ): Observable<T2> {
    return this._http.get<T1>(url, { params: httpParams }).pipe(
      map(mapper),
      catchError(error => this.handleLoadError(error, errorMessage))
    );
  }

  protected mapBlog(serverBlog: IBlogServerResponse): IBlog {
    return { items: blogItemsFactory(serverBlog.items), cursors: serverBlog.cursors };
  }

  protected mapPersonalBlog(serverBlog: IPersonalBlogServerResponse): IPersonalBlog {
    return {
      author: blogAuthorFactory(serverBlog.author),
      items: blogItemsFactory(serverBlog.items),
      cursors: serverBlog.cursors
    };
  }
}
