import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { BlogReaderService } from '@app/screens/blog/services/blog-reader.service';
import { FindYourGuideService } from '@app/core/public/find-your-guide.service';
import { PublicProgramsService } from '@app/screens/programs/services/public-programs.service';
import { IPublicProgramItem } from '@app/shared/interfaces/programs';
import { BrandingService } from '@app/core/branding/branding.service';
import { BlogItemPodcastsPack, BlogItemTypes, IArticleSelection } from '@app/screens/blog/types';
import { ICommunityConfig } from '@cnf/types';
import { environment } from '@env/environment';
import { noop, Subject } from 'rxjs';
import { makeUriFromString } from '@app/screens/blog/utils';
import { ProgramsActiveTab, ProgramsQueryParams } from '@app/screens/programs/enums';
import { IProduct } from '@app/screens/our-community/interfaces';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-our-community',
  templateUrl: './our-community.component.html',
  styleUrls: ['./our-community.component.scss']
})
export class OurCommunityComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guides: any[];

  // @ts-expect-error TS2564
  products: IProduct[];

  // @ts-expect-error TS2564
  programs: IPublicProgramItem[];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  articles: any[];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  podcasts: any[];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videos: any[];

  // @ts-expect-error TS2564
  introSectionTags: string;

  pageData: ICommunityConfig = {};

  mainLogo = '';

  programsQueryParams = 'limit=8';

  hideGiftCertificate = true;

  guidesRoute = environment.guidesRoute;

  // Programs extension
  programsQuery: typeof ProgramsQueryParams = ProgramsQueryParams;

  programsActiveTab: typeof ProgramsActiveTab = ProgramsActiveTab;

  constructor(
    private readonly _brandingService: BrandingService,
    private readonly _findYourGuide: FindYourGuideService,
    private readonly _publicPrograms: PublicProgramsService,
    private readonly _blogService: BlogReaderService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.mainLogo = logo));

    this._brandingService.globalConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ hideGiftCertificate }) => (this.hideGiftCertificate = hideGiftCertificate));

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._publicPrograms.getMarketplacePrograms$(this.programsQueryParams).subscribe(({ programs }) => {
      this.programs = programs;
    });

    this._blogService
      .getAllBlogsArticles$()
      .pipe(
        map(data => {
          if (data.items && data.items.length) {
            // @ts-expect-error TS7006
            const podcastsItem = data.items.filter(item => item instanceof BlogItemPodcastsPack);
            return {
              // @ts-expect-error TS7006
              articles: data.items.filter(item => item.type === BlogItemTypes.ARTICLE).slice(0, 6),
              podcasts: podcastsItem.length && podcastsItem.find(Boolean).podcasts.slice(0, 6),
              // @ts-expect-error TS7006
              videos: data.items.filter(item => item.type === BlogItemTypes.VIDEO).slice(0, 1)
            };
          }
          return {};
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(({ articles, podcasts, videos }) => {
        this.articles = articles;
        this.podcasts = podcasts;
        this.videos = videos;
      });

    this._brandingService.communityConfig$
      .pipe(
        tap((data: ICommunityConfig) => (this.pageData = data)),
        switchMap(data => this._findYourGuide.getPublicGuides(data.certainGuidesId)),
        tap(({ guides }) => (this.guides = guides))
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        // temp solution
        this.products = [
          {
            // @ts-expect-error TS2322
            title: this.pageData.productSectionCardOneTitle,
            // @ts-expect-error TS2322
            description: this.pageData.productSectionCardOneText,
            // @ts-expect-error TS2322
            imageUrl: this.pageData.productSectionCardOneImage,
            imageUrlMobile: this.pageData.productSectionCardOneImageMobile,
            // @ts-expect-error TS2322
            tags: this.pageData.productSectionCardOneTags
          },
          {
            // @ts-expect-error TS2322
            title: this.pageData.productSectionCardTwoTitle,
            // @ts-expect-error TS2322
            description: this.pageData.productSectionCardTwoText,
            // @ts-expect-error TS2322
            imageUrl: this.pageData.productSectionCardTwoImage,
            imageUrlMobile: this.pageData.productSectionCardTwoImageMobile,
            // @ts-expect-error TS2322
            tags: this.pageData.productSectionCardTwoTags
          },
          {
            // @ts-expect-error TS2322
            title: this.pageData.productSectionCardThreeTitle,
            // @ts-expect-error TS2322
            description: this.pageData.productSectionCardThreeText,
            // @ts-expect-error TS2322
            imageUrl: this.pageData.productSectionCardThreeImage,
            imageUrlMobile: this.pageData.productSectionCardThreeImageMobile,
            // @ts-expect-error TS2322
            tags: this.pageData.productSectionCardThreeTags
          },
          {
            // @ts-expect-error TS2322
            title: this.pageData.productSectionCardFourTitle,
            // @ts-expect-error TS2322
            description: this.pageData.productSectionCardFourText,
            // @ts-expect-error TS2322
            imageUrl: this.pageData.productSectionCardFourImage,
            imageUrlMobile: this.pageData.productSectionCardFourImageMobile,
            // @ts-expect-error TS2322
            tags: this.pageData.productSectionCardFourTags
          }
        ];
        // @ts-expect-error TS2322
        this.introSectionTags = this.pageData.introSectionTags;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openArticle(selectedArticle: IArticleSelection): void {
    const urlParts = ['/blog', selectedArticle.authorUrl, 'posts', selectedArticle.articleId];
    this._router.navigate(urlParts, { relativeTo: this._route }).then(() => noop());
  }

  openVideoAuthorPage(namedUrl: string): void {
    this._router.navigate(['/', namedUrl]).then(() => noop());
  }

  getProgramsQuery(): { [key in ProgramsQueryParams]: ProgramsActiveTab } {
    return { [this.programsQuery.ACTIVE_TAB]: this.programsActiveTab.ALL };
  }

  // @ts-expect-error TS7006
  makeProgramPath(programName, programId): string {
    return `/programs/${makeUriFromString(programName, programId)}`;
  }
}
