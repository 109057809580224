import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionConnectionTypeAttribute } from '@libs/business/client/client-session';

import { ClientSessionAttributesEnum } from '../../enums';

@Component({
  selector: 'app-client-session-connection-type-attribute',
  templateUrl: './client-session-connection-type-attribute.component.html',
  styleUrls: ['../client-session-attribute.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionConnectionTypeAttributeComponent implements ClientSessionConnectionTypeAttribute {
  type: ClientSessionAttributesEnum.CONNECTION_TYPE = ClientSessionAttributesEnum.CONNECTION_TYPE;

  @Input()
  leftIcon!: SessionAttributeIconEnum.VIDEO | SessionAttributeIconEnum.PLACE | SessionAttributeIconEnum.PHONE;

  @Input() label!: string;

  @Input() labelRouterLink?: string;

  @Input() showTooltip?: boolean;

  @Input() tooltipText?: string;
}
