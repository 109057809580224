import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PuiIconModule } from '@awarenow/profi-ui-core';

import { GuideServiceLocationIconComponent } from './guide-service-location-icon.component';

@NgModule({
  imports: [CommonModule, PuiIconModule],
  declarations: [GuideServiceLocationIconComponent],
  exports: [GuideServiceLocationIconComponent]
})
export class GuideServiceLocationIconModule {}
