// eslint-disable-next-line @typescript-eslint/naming-convention
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';

export interface ModuleInstructorDetails {
  readonly id: number;
  readonly workspaceId: number;
  readonly namedUrl: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photo?: string;
}

export class ModuleInstructor {
  readonly id: number;
  readonly workspaceId: number;
  readonly namedUrl: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photo: string;
  readonly connectionType?: SessionConnectionTypes;
  readonly address?: string;

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(instructorDetails: ModuleInstructorDetails) {
    if (!instructorDetails) {
      throw new Error('Invalid argument');
    }

    this.id = instructorDetails.id;
    this.workspaceId = instructorDetails.workspaceId;
    this.namedUrl = instructorDetails.namedUrl;
    this.firstName = instructorDetails.firstName;
    this.lastName = instructorDetails.lastName;
    // @ts-expect-error TS2322
    this.photo = instructorDetails.photo;
  }
}
