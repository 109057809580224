import { Injectable } from '@angular/core';
import {
  CLIENT_SESSIONS_ENDPOINT,
  CLIENT_SESSIONS_FEEDBACKS_ENDPOINT,
  GUIDE_EVENTS_ENDPOINT,
  GUIDE_SESSIONS_ENDPOINT
} from '@app/shared/constants/endpoints';
import { UserRoles } from '@app/shared/enums/user-roles';

import { AuthService } from '@app/core/auth/services';

@Injectable()
export class SessionsEndpointResolverService {
  constructor(private _authService: AuthService) {}

  getApproveRescheduleUrl(): string {
    const url = this._resolveEndpoint();
    const approveSegment = this.resolveApproveSegment();
    return url ? `${url}/${approveSegment}` : url;
  }

  getApproveSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/approve` : url;
  }

  getApproveSessionOfferUrl(): string {
    return `${CLIENT_SESSIONS_ENDPOINT}/offers/approve`;
  }

  getArchiveSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/archive` : url;
  }

  getDeclineOfferUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/offers/cancel` : url;
  }

  getEndSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/end` : url;
  }

  getExtendGroupSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/group/extended` : url;
  }

  getExtendSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/extend` : url;
  }

  getFeedbacksUrl(): string {
    // @ts-expect-error TS2322
    return this._authService.isAuthorized && this._authService.user.RoleId === UserRoles.CLIENT
      ? CLIENT_SESSIONS_FEEDBACKS_ENDPOINT
      : null;
  }

  getLoadSessionsUrl(): string {
    return this._resolveEndpoint();
  }

  getRefuseGroupSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/group/cancelled` : url;
  }

  getRefuseSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/cancel` : url;
  }

  getRescheduleGuideSessionUrl(): string {
    return `${GUIDE_SESSIONS_ENDPOINT}/reschedule`;
  }

  getRescheduleClientSessionUrl(): string {
    return `${CLIENT_SESSIONS_ENDPOINT}/reschedule`;
  }

  getStartGroupSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/group/started` : url;
  }

  getStartSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/start` : url;
  }

  getTrackSessionUrl(): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/histories/interval` : url;
  }

  // @ts-expect-error TS7006
  getEventByUuidUrl(uuid): string {
    const url = this._resolveEndpoint();
    return url ? `${url}/by-event-uuid/${uuid}` : url;
  }

  getInviteToSessionUrl(): string {
    return `${GUIDE_SESSIONS_ENDPOINT}/invite`;
  }

  getJoinRunningSessionUrl(): string {
    return `${CLIENT_SESSIONS_ENDPOINT}/join`;
  }

  joinTheCallUrl(): string {
    return `${CLIENT_SESSIONS_ENDPOINT}/join-call`;
  }

  addSessionChat(): string {
    return `${GUIDE_SESSIONS_ENDPOINT}/add-chat`;
  }

  markAsMissed(): string {
    return `${GUIDE_EVENTS_ENDPOINT}/missed`;
  }

  markAsCompleted(): string {
    return `${GUIDE_EVENTS_ENDPOINT}/done`;
  }

  markAsAbsent(): string {
    return `${GUIDE_SESSIONS_ENDPOINT}/missed`;
  }

  markAsPresent(): string {
    return `${GUIDE_SESSIONS_ENDPOINT}/done`;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _resolveEndpoint(): string {
    let url = null;
    if (this._authService.isAuthorized) {
      if (this._authService.user.RoleId === UserRoles.CLIENT) {
        url = CLIENT_SESSIONS_ENDPOINT;
      } else if (this._authService.user.RoleId === UserRoles.GUIDE) {
        url = GUIDE_SESSIONS_ENDPOINT;
      }
    }
    // @ts-expect-error TS2322
    return url;
  }

  private resolveApproveSegment(): string {
    let url = '';
    if (this._authService.isAuthorized) {
      if (this._authService.user.RoleId === UserRoles.CLIENT) {
        url = 'offers/approve';
      } else if (this._authService.user.RoleId === UserRoles.GUIDE) {
        url = 'approve';
      }
    }

    return url;
  }
}
