import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { LogService } from '@app/core/log/log.service';
import { LogType } from '@app/core/log/types';
import { IServiceBookingResult } from '@app/modules/book-service';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { PayService } from '@app/modules/current-payment/types';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

type BookingDetailsType = {
  readonly serviceId: number;
  readonly type: GuideServiceTypes;
  paymentOption: PaymentOptions;
};

type CurrentPaymentMethodType = keyof Pick<PayService, 'service$' | 'package$' | 'program$'>;

const getCurrentPaymentMethodAndParams = (
  details: BookingDetailsType
): {
  method: CurrentPaymentMethodType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
} | null => {
  switch (details.type) {
    case GuideServiceTypes.GROUP_SESSION:
    case GuideServiceTypes.SESSION:
      return {
        method: 'service$',
        params: details
      };
    case GuideServiceTypes.PACKAGE:
      return {
        method: 'package$',
        params: details.serviceId
      };
    case GuideServiceTypes.PROGRAM:
      return {
        method: 'program$',
        params: details.serviceId
      };
    case GuideServiceTypes.WEBINAR:
    default:
      return null;
  }
};

@Injectable({
  providedIn: 'root',
  deps: [HttpClient, CurrentPaymentService, LogService, JsonPipe],
  useFactory: (
    http: HttpClient,
    currentPayment: CurrentPaymentService,
    logger: LogService,
    jsonTransformer: JsonPipe
  ) => new DefaultServiceBookingApiService(http, currentPayment, logger, jsonTransformer)
})
export abstract class ServiceBookingApiService<TBookingDetails, TBookingResult> {
  abstract book$(bookingDetails: TBookingDetails, isFree?: boolean): Observable<TBookingResult>;
}

@Injectable()
export class DefaultServiceBookingApiService<
  TBookingDetails extends BookingDetailsType,
  TBookingResult extends IServiceBookingResult
> extends ServiceBookingApiService<TBookingDetails, TBookingResult> {
  readonly BOOK_ENDPOINT = `${config.apiPath}/user/client/services/books`;

  readonly PREBOOK_ENDPOINT = `${config.apiPath}/user/client/services/prebook`;

  constructor(
    private readonly _http: HttpClient,
    private readonly _currentPayment: CurrentPaymentService,
    private readonly _logger: LogService,
    private readonly _jsonTransformer: JsonPipe
  ) {
    super();
  }

  book$(bookingDetails: TBookingDetails, isFree = false): Observable<TBookingResult> {
    const paymentInfo = getCurrentPaymentMethodAndParams(bookingDetails);
    return (
      // @ts-expect-error TS7053
      this._currentPayment
        .pay(isFree)
        [
          paymentInfo?.method || ''
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ](paymentInfo?.params, bookingDetails as any)
        .pipe(catchError(error => this._handleBookError$(error))) as Observable<TBookingResult>
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _handleBookError$(error): Observable<TBookingResult> {
    this._log('book$', error);
    return throwError(error);
  }

  // TODO: better use decorator
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _log(methodName: string, error: any, details?: any): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorLog: any = { error, location: `BookApiService.${methodName}` };

    if (details) {
      errorLog.details = details;
    }

    this._logger.sendLog(LogType.ERROR, this._jsonTransformer.transform(errorLog));
  }
}
