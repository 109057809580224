// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyObjectEntries(obj: { [propName: string]: any }): string {
  let result = '';

  if (obj) {
    for (const [key, value] of Object.entries(obj)) {
      result += `${key}: ${value};`;
    }
  }

  return result;
}
