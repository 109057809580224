import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { SessionsTypes } from '@app/shared/enums/sessions-types';
import { AvailableSession, isSimpleSession, Session } from '@app/shared/interfaces/session';

import { convertAvailableServerSessions, convertServerSessions } from './converters';
import { filter, map } from 'rxjs/operators';

export abstract class SessionsObservables {
  /* https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Maximum_delay_value */
  private readonly MAX_TIMEOUT_VALUE = 2147483647;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _future$: BehaviorSubject<Session[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _available$: BehaviorSubject<AvailableSession[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _past$: BehaviorSubject<Session[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _requests$: BehaviorSubject<Session[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _offers$: BehaviorSubject<Session[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _update$: BehaviorSubject<{
    future: Session[];
    past: Session[];
    requests: Session[];
    offers: Session[];
    available: AvailableSession[];
  }>;

  get pureUpdate(): BehaviorSubject<{
    future: Session[];
    past: Session[];
    requests: Session[];
    offers: Session[];
    available: AvailableSession[];
  }> {
    return this._update$;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private readonly _expire$: Subject<any>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timeouts = [];

  get futureSessions$(): Observable<Session[]> {
    return this._future$.asObservable();
  }

  get availableSessions$(): Observable<AvailableSession[]> {
    return this._available$.asObservable();
  }

  get pastSessions$(): Observable<Session[]> {
    return this._past$.asObservable();
  }

  get sessionOffers$(): Observable<Session[]> {
    return this._offers$.asObservable();
  }

  get sessionRequests$(): Observable<Session[]> {
    return this._requests$.asObservable();
  }

  get update$(): Observable<{
    future: Session[];
    past: Session[];
    requests: Session[];
    offers: Session[];
  }> {
    return this._update$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get expire$(): Observable<any> {
    return this._expire$.asObservable();
  }

  protected constructor() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._expire$ = new Subject<any>();

    this._future$ = new BehaviorSubject<Session[]>([]);
    this._available$ = new BehaviorSubject<AvailableSession[]>([]);
    this._past$ = new BehaviorSubject<Session[]>([]);
    this._requests$ = new BehaviorSubject<Session[]>([]);
    this._offers$ = new BehaviorSubject<Session[]>([]);

    this._update$ = new BehaviorSubject<{
      future: Session[];
      past: Session[];
      requests: Session[];
      offers: Session[];
      available: AvailableSession[];
    }>({
      future: [],
      past: [],
      requests: [],
      offers: [],
      available: []
    });
  }

  session$(id: number): Observable<Session> {
    return this.update$.pipe(
      map((update: { future: Session[]; past: Session[]; requests: Session[]; offers: Session[] }) => {
        const sessionTypes = Object.keys(update) as ('future' | 'past' | 'requests' | 'offers')[];

        for (const sessionType of sessionTypes) {
          const sessions = update[sessionType];
          const session = sessions.find((session: Session) => session.id === id);
          if (session) {
            return session;
          }
        }

        return null;
      }),
      filter<Session>(session => !!session)
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected addSession(session: Session, type: SessionsTypes) {
    if (!session) {
      return;
    }

    const sessionsObservable = this.getSessionsObservable(type);
    if (sessionsObservable) {
      const newSessions = this.cloneSessions(sessionsObservable.getValue());
      newSessions.push(session);
      sessionsObservable.next(newSessions);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected cloneSessions(sessions: Session[]) {
    return sessions.slice();
  }

  protected findSession(id: number, type: SessionsTypes): Session | null {
    let session = null;

    const sessionsObservable = this.getSessionsObservable(type);
    if (sessionsObservable) {
      const sessions = sessionsObservable.getValue();
      // eslint-disable-next-line id-length
      session = sessions.find(s => s.id === id);
    }

    // @ts-expect-error TS2322
    return session;
  }

  protected getSessionsObservable(type: SessionsTypes): BehaviorSubject<Session[]> | null {
    // @ts-expect-error TS2322
    let sessionsObservable: BehaviorSubject<Session[]> = null;

    if (type === SessionsTypes.REQUEST) {
      sessionsObservable = this._requests$;
    } else if (type === SessionsTypes.FUTURE) {
      sessionsObservable = this._future$;
    } else if (type === SessionsTypes.PAST) {
      sessionsObservable = this._past$;
    } else if (type === SessionsTypes.OFFER) {
      sessionsObservable = this._offers$;
    }

    return sessionsObservable;
  }

  protected removeSession(sessionId: number, type: SessionsTypes): Session {
    // @ts-expect-error TS2322
    let removedSession: Session = null;
    const sessionsObservable = this.getSessionsObservable(type);
    if (sessionsObservable) {
      const newSessionCollection = this.cloneSessions(sessionsObservable.getValue());
      const indexOfSession = newSessionCollection.findIndex(sr =>
        // eslint-disable-next-line id-length
        isSimpleSession(sr) ? sr.id === sessionId : sr.sessions && sr.sessions.some(s => s.id === sessionId)
      );
      if (indexOfSession > -1) {
        const session = newSessionCollection[indexOfSession];
        if (isSimpleSession(session)) {
          removedSession = session;
          newSessionCollection.splice(indexOfSession, 1);
        } else {
          // @ts-expect-error TS2532
          const sessionInstanceIndex = session.sessions.findIndex(sessionInstance => sessionInstance.id === sessionId);
          // @ts-expect-error TS2532
          removedSession = session.sessions[sessionInstanceIndex];
          // @ts-expect-error TS2532
          session.sessions.splice(sessionInstanceIndex, 1);
        }
        sessionsObservable.next(newSessionCollection);
      }
    }

    return removedSession;
  }

  // @ts-expect-error TS7006
  protected updateSessions(sessions): void {
    const futureSessions = convertServerSessions(sessions.next || [], SessionsTypes.FUTURE);
    const pastSessions: Session[] = convertServerSessions(sessions.past || [], SessionsTypes.PAST);
    const sessionRequests = convertServerSessions(sessions.requests || [], SessionsTypes.REQUEST);
    const sessionOffers = convertServerSessions(sessions.offers || [], SessionsTypes.OFFER);
    const availableSessions = convertAvailableServerSessions(sessions.available || []);

    this._future$.next(futureSessions);
    this._past$.next(pastSessions);
    this._requests$.next(sessionRequests);
    this._offers$.next(sessionOffers);
    this._available$.next(availableSessions);

    this._update$.next({
      future: futureSessions,
      past: pastSessions,
      requests: sessionRequests,
      offers: sessionOffers,
      available: availableSessions
    });
    this.clearExpirationTimeouts();
    this.setExpirationTimeouts(sessionRequests);
  }

  // @ts-expect-error TS7006
  protected setExpirationTimeouts(sessionRequests): void {
    // @ts-expect-error TS7006
    sessionRequests.forEach(request => {
      let ms = new Date(request.dateStart).getTime() - new Date().getTime();
      /* https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Maximum_delay_value */
      if (ms > this.MAX_TIMEOUT_VALUE) {
        ms = this.MAX_TIMEOUT_VALUE;
      } else if (ms <= 0) {
        const timeToAcceptBuffer = 5 * 60 * 1000;
        if (ms <= -timeToAcceptBuffer) {
          return;
        } else {
          ms = timeToAcceptBuffer - -ms;
        }
      }
      const timeout = setTimeout(() => this._expire$.next(), ms);
      // TODO: why array instead of one with closest expiration date?
      // @ts-expect-error TS2345
      this._timeouts.push(timeout);
    });
  }

  protected clearExpirationTimeouts(): void {
    this._timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
    this._timeouts = [];
  }
}
