import { PaymentMethods } from '@app/shared/enums/payment-methods';

export enum AnalyticClientTypes {
  EXTERNAL = 'external',
  INTERNAL = 'internal'
}

export enum AnalyticCopyTypes {
  BUTTON = 'button',
  CALENDAR = 'calendar',
  FACEBOOK = 'facebook',
  LOWER_BUTTON = 'lower button',
  SERVICE_LIST = 'service list',
  STANDARD = 'standard',
  TWITTER = 'twitter'
}

export enum AnalyticCreateSourceTypes {
  CALENDAR = 'calendar',
  SERVICE_LIST = 'service list'
}

export enum AnalyticPricingTypes {
  FREE = 'free',
  ONE_TIME = 'one-time',
  SUBSCRIPTION = 'subscription'
}

export enum AnalyticServiceTypes {
  GROUP_SESSION = 'group session',
  INDIVIDUAL_SESSION = 'individual session',
  PACKAGE = 'package',
  PROGRAM = 'program'
}

export enum AnalyticSourceTypes {
  BOOKING = 'booking',
  CLIENTS = 'clients',
  EMBEDDABLE_WIDGET = 'embeddable widget',
  INVITATION = 'invitation',
  PACKAGE = 'package',
  PRACTITIONER = 'practitioner',
  PROFILE = 'profile',
  SERVICE = 'service'
}

export enum AnalyticSubscriptionFrequency {
  MONTH = 'month',
  NONE = 'none',
  WEEK = 'week',
  YEAR = 'year'
}

export enum InternalEvents {
  ACCEPT_SESSION_OFFER = 'ACCEPT_OFFER',
  ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO',
  BEGIN_BOOK = 'BEGIN_BOOK',
  BLOCK_TIME_SAVED = 'Block Time Added',
  BLOG = 'BLOG',
  BLOG_ARTICLE = 'BLOG_ARTICLE',
  BLOG_STORY_DRAFT_SAVED = 'BLOG_STORY_DRAFT_SAVED',
  BLOG_STORY_PUBLISHED = 'BLOG_STORY_PUBLISHED',
  BOOK_SESSION = 'BOOK_SESSION',
  BOOK_STEP_DATE = 'BOOK_STEP_DATE',
  BOOK_STEP_TIME = 'BOOK_STEP_TIME',
  CALENDAR_CONNECT = 'Calendar Connected',
  CALENDAR_DISCONNECT = 'Calendar Disconnected',
  CANCEL_SESSION = 'CANCEL_SESSION',
  CLIENT_INVITE = 'CLIENT_INVITE',
  COPIED_EMBEDDABLE_WIDGET_CODE = 'COPIED_EMBEDDABLE_WIDGET_CODE',
  EMAIL_CONFIRMED = 'EMAIL_CONFIRMED',
  FEE_SET = 'FEE_SET',
  FREE_PLAN_STARTED = 'FREE_PLAN_STARTED',
  FREE_PROGRAM_CREATED = 'FREE_PROGRAM_CREATED',
  GIFT_COUPON = 'GIFT_COUPON',
  GUIDES_LIST = 'GUIDES_LIST',
  GUIDE_PROFILE = 'GUIDE_PROFILE',
  HELP_FIND_GUIDE = 'HELP_FIND_GUIDE',
  JOURNALING_PROMPT_CONNECTED_TO_PROGRAM = 'JOURNALING_PROMPT_CONNECTED_TO_PROGRAM',
  JOURNALING_PROMPT_CREATED = 'JOURNALING_PROMPT_CREATED',
  JOURNALING_PROMPT_INVITED_CLIENT = 'JOURNALING_PROMPT_INVITED_CLIENT',
  LINK_COPY = 'LINK_COPY',
  LOGIN = 'LOGIN',
  MESSAGE_SENT_BY_CLIENT = 'MESSAGE_SENT_BY_CLIENT',
  MESSAGE_SENT_BY_COACH = 'MESSAGE_SENT_BY_COACH',
  PAID_PROGRAM_CREATED = 'PAID_PROGRAM_CREATED',
  PLATFORM_SUBSCRIBED = 'PLATFORM_SUBSCRIBED',
  PLATFORM_SUBSCRIPTION_CANCELED = 'PLATFORM_SUBSCRIPTION_CANCELED',
  PLATFORM_SUBSCRIPTION_PAID = 'PLATFORM_SUBSCRIPTION_PAID',
  PROFILE_PRIVACY_CHANGED = 'PROFILE_PRIVACY_CHANGED',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  PROGRAM_INVITED_CLIENT = 'PROGRAM_INVITED_CLIENT',
  PROGRAM_MODULE_ADDED = 'PROGRAM_MODULE_ADDED',
  QUIZ_CREATED = 'QUIZ_CREATED',
  QUIZ_INVITED_CLIENT = 'QUIZ_INVITED_CLIENT',
  RUN_TEST_COMPLETED = 'RUN_TEST_COMPLETED',
  RUN_TEST_INITIATED = 'RUN_TEST_INITIATED',
  SEARCH = 'SEARCH',
  SERVICE_BOOK = 'SERVICE_BOOK',
  SERVICE_CREATE = 'SERVICE_CREATE',
  SESSION_ADDED = 'SESSION_ADDED',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_CLIENT = 'SIGN_UP_CLIENT',
  SIGN_UP_PRACTITIONER = 'SIGN_UP_PRACTITIONER',
  TRIAL_STARTED = 'TRIAL_STARTED',
  WIKI = 'WIKI',
  WITHDRAW_ACCOUNT_CONNECT = 'WITHDRAW_ACCOUNT_CONNECT',
  WORKING_HOURS_SAVED = 'WORKING_HOURS_SAVED',
  ZOOM_INTEGRATED = 'ZOOM_INTEGRATED',

  //
  SESSION_TEMPLATE_CREATED = 'Session Template Created',
  SESSION_BOOKED = 'Session Booked',
  SESSION_RESCHEDULED = 'Session Rescheduled',
  SESSION_CANCELED = 'Session Canceled',
  SESSION_TIME_SELECTED = 'Session Booking Time Selected'
}

export enum GoogleAnalyticsCategories {
  SIGNUP = 'SIGNUP',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export enum GtagEvents {
  // ADD_TO_CART = 'add_to_cart',
  // ADD_TO_WISHLIST = 'add_to_wishlist',
  // GENERATE_LEAD = 'generate_lead',
  // REMOVE_FROM_CART = 'remove_from_cart',
  // custom events
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_PROGRESS = 'checkout_progress',
  HELP_FIND_GUIDE = 'help_find_guide',
  LOGIN = 'login',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  SEARCH = 'search',
  SELECT_CONTENT = 'select_content',
  SET_CHECKOUT_OPTION = 'set_checkout_option',
  SHARE = 'share',
  SIGN_UP = 'sign_up',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_SEARCH_RESULTS = 'view_search_results'
}

export enum FbqEvents {
  // ADD_TO_CART = 'AddToCart',
  // ADD_TO_WISHLIST = 'AddToWishlist',
  // CUSTOMIZE_PRODUCT = 'CustomizeProduct',
  // DONATE = 'Donate',
  // FIND_LOCATION = 'FindLocation',
  // LEAD = 'Lead',
  // SCHEDULE = 'Schedule',
  // SUBMIT_APPLICATION = 'SubmitApplication',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  CONTACT = 'Contact',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PAGE_VIEW = 'PageView',
  PURCHASE = 'Purchase',
  RATE = 'Rate',
  SEARCH = 'Search',
  START_TRIAL = 'StartTrial',
  SUBSCRIBE = 'Subscribe',
  VIEW_CONTENT = 'ViewContent'
}

export enum SegmentEvents {
  BLOCK_TIME_SAVED = 'BLOCK_TIME_SAVED',
  BLOG_STORY_DRAFT_SAVED = 'BLOG_STORY_DRAFT_SAVED',
  BLOG_STORY_PUBLISHED = 'BLOG_STORY_PUBLISHED',
  CALENDAR_CONNECT = 'CALENDAR_CONNECT',
  CALENDAR_DISCONNECT = 'CALENDAR_DISCONNECT',
  CLIENT_INVITE = 'CLIENT_INVITE',
  EMAIL_CONFIRMED = 'EMAIL_CONFIRMED',
  FEE_SET = 'FEE_SET',
  FREE_PLAN_STARTED = 'FREE_PLAN_STARTED',
  FREE_PROGRAM_CREATED = 'FREE_PROGRAM_CREATED',
  JOURNALING_PROMPT_CONNECTED_TO_PROGRAM = 'JOURNALING_PROMPT_CONNECTED_TO_PROGRAM',
  JOURNALING_PROMPT_CREATED = 'JOURNALING_PROMPT_CREATED',
  JOURNALING_PROMPT_INVITED_CLIENT = 'JOURNALING_PROMPT_INVITED_CLIENT',
  LINK_COPY = 'LINK_COPY',
  LOGIN = 'LOGIN',
  MESSAGE_SENT_BY_CLIENT = 'MESSAGE_SENT_BY_CLIENT',
  MESSAGE_SENT_BY_COACH = 'MESSAGE_SENT_BY_COACH',
  PAID_PROGRAM_CREATED = 'PAID_PROGRAM_CREATED',
  PLATFORM_SUBSCRIBED = 'PLATFORM_SUBSCRIBED',
  PLATFORM_SUBSCRIPTION_CANCELED = 'PLATFORM_SUBSCRIPTION_CANCELED',
  PLATFORM_SUBSCRIPTION_PAID = 'PLATFORM_SUBSCRIPTION_PAID',
  PROFILE_PRIVACY_CHANGED = 'PROFILE_PRIVACY_CHANGED',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  PROGRAM_INVITED_CLIENT = 'PROGRAM_INVITED_CLIENT',
  PROGRAM_MODULE_ADDED = 'PROGRAM_MODULE_ADDED',
  QUIZ_CREATED = 'QUIZ_CREATED',
  QUIZ_INVITED_CLIENT = 'QUIZ_INVITED_CLIENT',
  RUN_TEST_COMPLETED = 'RUN_TEST_COMPLETED',
  RUN_TEST_INITIATED = 'RUN_TEST_INITIATED',
  SERVICE_BOOK = 'SERVICE_BOOK',
  SERVICE_BOOK_CHOOSE_TIME = 'SERVICE_BOOK_CHOOSE_TIME',
  SERVICE_CREATE = 'SERVICE_CREATE',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_CLIENT = 'SIGN_UP_CLIENT',
  SIGN_UP_PRACTITIONER = 'SIGN_UP_PRACTITIONER',
  STRIPE_CONNECTED = 'STRIPE_CONNECTED',
  TRIAL_STARTED = 'TRIAL_STARTED',
  WITHDRAW_ACCOUNT_CONNECT = 'WITHDRAW_ACCOUNT_CONNECT',
  WORKING_HOURS_SAVED = 'WORKING_HOURS_SAVED',
  ZOOM_INTEGRATED = 'ZOOM_INTEGRATED'
}

export enum BookTypes {
  BOOK = 'book',
  OFFER = 'offer'
}

export enum IntercomUserProperties {
  CURRENT_CLIENTS_NUMBER = '*Current Clients Number',
  FIRST_PAYMENT_DATE = '*First Payment Date_at',
  NAME = 'name',
  PLAN = '*Plan'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IAnalyticParams {
  referrer?: string;
  landing?: string;
  visitDate?: string;
  utmSource?: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmHost?: string;
  firstPlatformSubscriptionDate?: string;
}

export interface GtagEvent {
  event_category?: string;
  event_label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  search_term?: string;
  transaction_id?: string;
  items?: GtagEventItem[];
  currency?: string;
  checkout_step?: number;
  method?: string;
  // [key: string]: any;
  // custom dimensions
  book_type?: BookTypes;
  payment_method?: PaymentMethods;
}

export interface GtagEventItem {
  id?: string;
  name?: string;
  list_name?: string;
  brand?: string;
  category?: string;
  variant?: string;
  list_position?: number;
  quantity?: number;
  price?: string;
}

export interface GtagConfig {
  page_path: string;
  page_title?: string;
  page_location?: string;
  custom_map?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [propName: string]: any;
  };
}

export interface FbqEvent {
  value?: number;
  currency?: string;
  predicted_ltv?: string;
  search_string?: string;

  content_ids?: string[];
  content_name?: string;
  content_type?: string;
  contents?: string;
  num_items?: string[];
}
