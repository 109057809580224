import { IPublicProgram } from '@app/modules/program/types';
import {
  IClientModuleService,
  IClientProgramModuleDetails,
  IClientQuizQuestion
} from '@app/screens/client/client-programs/types';
import { buildClientProgram } from '@app/screens/client/client-programs/utils';
import {
  IProgramOptions,
  isContentModule,
  isGroupSessionModule,
  isQuizModule,
  isRestrictedModule,
  isSessionModule,
  ProgramContent,
  ProgramModule,
  QuizStatuses
} from '@app/screens/guide/guide-programs/types';
import { ProgramGuide } from '@app/shared/interfaces/programs';
import { ClientProgram, ModuleInstructor } from '@app/shared/interfaces/programs/client-programs';
import { IUser } from '@app/shared/interfaces/user';

export function buildProgramModulePreview(
  programModule: ProgramModule,
  workspaceId: number
): IClientProgramModuleDetails {
  const isRestricted = isRestrictedModule(programModule);
  const isSession = isSessionModule(programModule) || isGroupSessionModule(programModule);
  const isContent = isContentModule(programModule);
  const isQuiz = isQuizModule(programModule);

  let service: IClientModuleService | undefined;

  if (isSession) {
    service = {
      name: programModule.service?.name || programModule.sessionTemplateDraft?.name!,
      description: programModule.service?.description,
      type: programModule.service?.serviceType! || programModule.sessionTemplateDraft?.serviceType,
      id: programModule.service?.id || programModule.sessionTemplateDraft?.id!,
      connectionType: programModule.service?.connectionType!,
      descriptionText: programModule.service?.descriptionText,
      duration: programModule.service?.duration! || programModule.sessionTemplateDraft?.duration!,
      guideId: programModule.instructor?.id!
    };
  }

  return {
    ...programModule,
    status: isRestricted ? 'restricted' : 'seen',
    title: isSession
      ? programModule.service?.name || programModule.sessionTemplateDraft?.name!
      : (programModule.titleText as string),
    description: programModule.description || '',
    service,
    instructor: programModule.instructor
      ? {
          ...programModule.instructor,
          workspaceId
        }
      : undefined,
    completionType: programModule.completionType!,
    hosts: programModule.hosts as ModuleInstructor[],
    moduleType: programModule.moduleType,
    id: programModule.id!,
    content: isContent ? programModule.content! : undefined,
    quiz: isQuiz
      ? {
          status: QuizStatuses.ACTIVE,
          questions: (programModule.quiz?.questions || []) as IClientQuizQuestion[]
        }
      : undefined,
    order: programModule.order
  };
}

export function createProgramContentPreview([content, modules, guide]: [
  ProgramContent,
  ProgramModule[],
  IUser
]): ClientProgram {
  return buildClientProgram({
    author: {
      ...guide,
      namedUrl: guide.namedUrl || ''
    },
    programId: content.id!,
    programName: content.name,
    notStarted: false,
    modules: modules.map(module => buildProgramModulePreview(module, guide.workspaceId!))
  });
}

export function createProgramLandingPreview([content, modules, options, guide]: [
  ProgramContent,
  ProgramModule[],
  IProgramOptions,
  ProgramGuide
]): IPublicProgram {
  const preview: IPublicProgram = [
    'id',
    'name',
    'description',
    'length',
    'level',
    'startType',
    'startDate',
    'keyPoints',
    'isFree',
    'price',
    'hidePrice',
    'fixedPrice',
    'subscriptionPrice',
    'subscriptionRecurrency',
    'totalPayments',
    'additionalNotes',
    'testimonials',
    'coauthors',
    'coverImage',
    'faq'
  ].reduce((prev, key) => {
    // @ts-expect-error TS7053
    prev[key] = content[key];

    return prev;
  }, {} as IPublicProgram);

  const tagsDict = options.tags.reduce((dict, tagInstance) => {
    dict[tagInstance.id] = tagInstance.name;

    return dict;
  }, {} as Record<number, string>);

  preview.issues = (content.issues || []).map(issue => tagsDict[issue.id]);

  preview.approaches = (content.approaches || []).map(approach => tagsDict[approach.id]);

  preview.guide = guide;

  preview.modules = (modules || []).map(module => buildProgramModulePreview(module, guide.workspaceId!));

  return preview;
}
