<div class="auth-body">
  <p i18n="@@weSentEmailTo">We sent an email to</p>
  <div class="email-box">
    {{ email }}
  </div>
  <p *ngIf="!emailVerified" i18n="@@checkYourInbox">Please check your inbox to activate your account.</p>
  <p *ngIf="emailVerified" i18n="@@checkYourInboxReset">Please check your inbox to set your password.</p>

  <p i18n="@@didntReceiveEmail" class="confirm-text">
    Didn't receive it? Check your spam/junk folder or
    <a (click)="resendEmail()">resend the email</a>
    .
  </p>
</div>
