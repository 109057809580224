import { EMPTY, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { isPlatformBrowser, Location } from '@angular/common';
import { Component, HostBinding, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { TagsSelectorModalComponent } from '@app/shared/components/tags-selector/components/tags-selector-modal/tags-selector-modal.component';
import { GlobalConfig } from '@cnf/types';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { MetaTagsNames } from '@libs/services/meta-tag/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BlogReaderService } from '../../services/blog-reader.service';
import { DisqusWidgetsService } from '../../services/disqus-widgets.service';
import { EmbedDisqusCountScriptService } from '../../services/embed-disqus-count-script.service';
import { BlogAuthor, BlogAuthorGuide, BlogItem, BlogItemTypes, IArticleSelection } from '../../types';

// import { locale } from '@env/locale';

enum BlogItemFilterTypes {
  ARTICLE = 'article',
  PODCAST = 'podcast',
  WIKI = 'wiki'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IBlogFilter {
  search?: string;
  tags?: string[];
  type?: BlogItemFilterTypes;
}

// SEO Keywords
const generalWords = [{ name: 'Coach' }, { name: 'Coach Software' }];

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  providers: [BlogReaderService, EmbedDisqusCountScriptService, DisqusWidgetsService]
})
export class BlogComponent implements OnInit, OnDestroy {
  readonly BlogItemFilterTypes = BlogItemFilterTypes;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentFilter: IBlogFilter;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentAuthorAlias: string;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentAuthor: BlogAuthor;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scrollPosition: [number, number];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _routeSubscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scrollSubscription: Subscription;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _searchControlSubscription: Subscription;

  private destroy$ = new Subject<void>();
  private locale: ILocale;

  // ANNOTATION: tmp solution, for business blogs
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _showWikiFilter = true;

  // @ts-expect-error TS2564
  activeTagsNames: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeTags: any[] = [];
  // @ts-expect-error TS2564
  isLoading: boolean;
  // @ts-expect-error TS2564
  items: BlogItem[];
  itemTypes = BlogItemTypes;
  searchControl = new FormControl('');
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: any[];
  config: {
    blogPageTitle: string | null;
    blogBusinessSubTitle: string | null;
    defaultAppTitle: string | null;
  } = {
    blogPageTitle: null,
    blogBusinessSubTitle: null,
    defaultAppTitle: null
  };
  // @ts-expect-error TS2564
  currentRoute: string;

  @HostBinding('class.container')
  isContainer = true;

  get blogAuthorName(): string {
    let name = null;

    if (this._currentAuthor) {
      name = this._currentAuthor instanceof BlogAuthorGuide ? this._currentAuthor.name : this._currentAuthor.namedUrl;
    }

    // @ts-expect-error TS2322
    return name;
  }

  get currentFilter(): IBlogFilter {
    return this._currentFilter;
  }

  // ANNOTATION: tmp solution, for business blogs
  get showWikiFilter(): boolean {
    return this._showWikiFilter;
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _title: Title,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    private _auth: AuthService,
    private _service: BlogReaderService,
    private _embedDisqusCountScript: EmbedDisqusCountScriptService,
    private _disqusWidgets: DisqusWidgetsService,
    private _modal: NgbModal,
    private _analytics: AnalyticsService,
    private _branding: BrandingService,
    private _location: Location,
    private _localeService: LocaleService,
    private metaTagService: MetaTagService
  ) {
    this._currentFilter = {};

    this._scrollSubscription = this._router.events
      // eslint-disable-next-line id-length
      .pipe(filter(e => e instanceof Scroll))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil, id-length
      .subscribe(e => this.onRouterScrollEvent(e));

    this._router.events
      .pipe(
        // eslint-disable-next-line id-length
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
      .subscribe((e: any) => {
        this.currentRoute = e.url;
      });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._route.queryParams.subscribe(({ activeTab }) => {
      switch (activeTab) {
        case BlogItemFilterTypes.ARTICLE:
          this._currentFilter.type = BlogItemFilterTypes.ARTICLE;
          break;
        case BlogItemFilterTypes.PODCAST:
          this._currentFilter.type = BlogItemFilterTypes.PODCAST;
          break;
        case BlogItemFilterTypes.WIKI:
          this._currentFilter.type = BlogItemFilterTypes.WIKI;
          break;
      }
    });

    this._location.replaceState('blog', '');

    this.locale = this._localeService.getLocale();
  }

  ngOnInit(): void {
    this.setSubscriptions();
    this.loadTags();

    this._analytics.event(InternalEvents.BLOG);
    this._branding.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.config.blogBusinessSubTitle = config.blogBusinessSubTitle;
      this.setConfig(config);
    });
  }

  ngOnDestroy(): void {
    // TODO: refactor subscriptions
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }

    if (this._scrollSubscription) {
      this._scrollSubscription.unsubscribe();
    }

    if (this._searchControlSubscription) {
      this._searchControlSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
    // @ts-expect-error TS2345
    this.setTitle(this.config.defaultAppTitle);
  }

  setTitle(title = ''): void {
    this._title.setTitle(title);
  }

  addTag(tag: string): void {
    const tags = this.activeTagsNames && this.activeTagsNames.length ? this.activeTagsNames.slice() : [];
    if (!tags.includes(tag)) {
      tags.push(tag);
      this._router.navigate(['./', { tags: tags.join(',') }], { relativeTo: this._route });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  addTags() {
    const { result, componentInstance } = this._modal.open(TagsSelectorModalComponent, {
      size: 'lg',
      centered: true
    });

    componentInstance.allTags = this.tags;
    componentInstance.selectedTags = this.activeTags.map(i => i.id);
    componentInstance.tagsLimit = 100;

    result
      .then(({ selected }) => {
        this.activeTags = this.tags.filter(i => selected.includes(i.id));
        const tags = Array.from(new Set(this.tags.filter(i => selected.includes(i.id)).map(i => i.name)));
        this.activeTagsNames = tags;
        this._router.navigate(['./', { tags: tags.join(',') }], { relativeTo: this._route });
      })
      .catch(() => {});
  }

  changeBlogItemFilterType(type?: BlogItemFilterTypes): void {
    this.updateCurrentFilter({ type });
  }

  openArticle(selectedArticle: IArticleSelection): void {
    const urlParts = ['./', 'posts', selectedArticle.articleId];

    // angular router treats '' at index 1 incorrectly
    if (!this._currentAuthorAlias) {
      // @ts-expect-error TS2345
      urlParts.splice(1, 0, selectedArticle.authorUrl);
    }

    this._router.navigate(urlParts, { relativeTo: this._route });
  }

  openAuthorBlog(author: string): void {
    if (this._currentAuthorAlias && this._currentAuthorAlias === author) {
      return;
    }

    this._router.navigate([author], { relativeTo: this._route });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openVideoAuthorPage(namedUrl: string) {
    this._router.navigate(['/', namedUrl]);
  }

  removeActiveTag(tagToRemove: string): void {
    this.activeTags = this.activeTags.filter(i => i.name !== tagToRemove);
    const newActiveTags = this.activeTags.map(i => i.name);
    const newActiveTagsParams = newActiveTags.length ? { tags: newActiveTags.join(',') } : {};
    this._router.navigate(['./', newActiveTagsParams], { relativeTo: this._route });
  }

  private loadBlogItems(): void {
    this.isLoading = true;

    const loader$ = this._currentAuthorAlias
      ? this._service.getUserBlogArticles$(this._currentAuthorAlias, this._currentFilter)
      : this._service.getAllBlogsArticles$(this._currentFilter);

    loader$
      .pipe(
        // eslint-disable-next-line id-length
        catchError(e => {
          this.isLoading = false;
          return throwError(e);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((blog: any) => {
          this.isLoading = false;

          this.items = blog.items;

          if (blog.author) {
            this._currentAuthor = blog.author;
          }
        }),
        mergeMap(() => {
          if (
            isPlatformBrowser(this.platformId) &&
            this.currentFilter &&
            (!this.currentFilter.type || this.currentFilter.type === BlogItemFilterTypes.ARTICLE)
          ) {
            return timer(0);
          }

          return EMPTY;
        }),
        tap(() => this.updateDisqusCounters()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private loadTags(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._service.getBlogTags$().subscribe(tags => {
      this.tags = tags;
      if (this.activeTagsNames) {
        this.activeTags = this.tags.filter(i => this.activeTagsNames.includes(i.name));
      }
      this.setBlogMetaKeywords();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onRouterScrollEvent(event: any): void {
    const { position } = event;
    if (position) {
      this._scrollPosition = position;
    }
  }

  private searchByText(search: string): void {
    this.updateCurrentFilter({ search });
  }

  private setBlogMetaKeywords(): void {
    if (this.tags && this.tags.length) {
      const keywords = this.tags.concat(generalWords);
      this.metaTagService.upsertMetaTags({
        keywords: keywords.map(keyword => keyword.name).join(', ')
      });
    } else {
      this.metaTagService.removeTags([MetaTagsNames.Keywords]);
    }
  }

  private setSubscriptions(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._routeSubscription = this._route.paramMap.subscribe(params => {
      // @ts-expect-error TS2322
      this._currentAuthorAlias = params.get('guide');

      const tagsString = params.get('tags');
      const tags = tagsString ? tagsString.split(',') : null;
      // @ts-expect-error TS2322
      this.activeTagsNames = tags;
      if (this.tags && this.activeTagsNames) {
        this.activeTags = this.tags.filter(i => this.activeTagsNames.includes(i.name));
      }

      this.updateCurrentFilter({ tags });
    });

    this._searchControlSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(300))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(search => this.searchByText(search));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateCurrentFilter(patch: any): void {
    const newFilterValue = { ...this._currentFilter };
    const patchKeys = Object.keys(patch);

    patchKeys.forEach(filterProp => {
      if (!patch[filterProp]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete newFilterValue[filterProp];
      } else {
        // @ts-expect-error TS7053
        newFilterValue[filterProp] = patch[filterProp];
      }
    });

    this._currentFilter = newFilterValue;

    this.loadBlogItems();
  }

  private updateDisqusCounters(): void {
    if (this._disqusWidgets.DISQUSWIDGETS) {
      this._disqusWidgets.DISQUSWIDGETS.getCount({ reset: true });
    }
  }

  private setConfig({
    metaTitleMainPage,
    blogPageTitle,
    metaKeywordsBlogPage,
    metaTitleBlogPage,
    metaDescriptionBlogPage,
    metaImageBlogPage
  }: GlobalConfig): void {
    this.config.blogPageTitle = blogPageTitle;
    this.config.defaultAppTitle = metaTitleMainPage;

    this._title.setTitle(metaTitleBlogPage);

    let title = metaTitleBlogPage;

    if (this._currentAuthor && this._currentAuthor instanceof BlogAuthorGuide) {
      title = `${title} - ${this._currentAuthor.name}`;
    }

    this.metaTagService.upsertMetaTags({
      title: title,
      keywords: metaKeywordsBlogPage,
      description: metaDescriptionBlogPage,
      image: metaImageBlogPage,
      url: `${this.locale.baseUrl}${this._router.url}`
    });
  }
}
