import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';

import { SessionActionResultComponent } from './components/session-action-result/session-action-result.component';
import { SessionActionResultModalComponent } from './components/session-action-result-modal/session-action-result-modal.component';
import { SessionDetailsComponent } from './components/session-details/session-details.component';

import { SessionDateTimePipe } from './pipes/session-date-time.pipe';
import { PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { SharedModule as CommonSharedModule } from '@libs/shared/shared.module';
import { GuideServiceLocationIconModule } from '@app/modules/guide-service/components/guide-service-location-icon/guide-service-location-icon.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    PuiIconModule,
    PuiTypographyModule,
    CommonSharedModule,
    GuideServiceLocationIconModule,
    PlatformStyleModule
  ],
  declarations: [
    SessionActionResultComponent,
    SessionActionResultModalComponent,
    SessionDetailsComponent,
    SessionDateTimePipe
  ],
  exports: [SessionActionResultComponent, SessionActionResultModalComponent]
})
export class SessionActionResultModule {}
