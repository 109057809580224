import { DateTime } from 'luxon';
import { Pipe, PipeTransform } from '@angular/core';
import { PlatformStyle } from '../services/platform-style.service';
import { DateTimeOptions } from '../model/interfaces';

@Pipe({
  name: 'platformDateTimeHybrid'
})
export class PlatformDateTimeHybridPipe implements PipeTransform {
  constructor(private platformStyle: PlatformStyle) {}

  transform(value: string, options: DateTimeOptions = {}): string {
    const isThisDate = Math.floor(DateTime.local().diff(DateTime.fromISO(value), 'day').days) === 0;

    if (isThisDate) {
      return this.platformStyle.datetime(value, 'TIME', options);
    }

    const isThisYear = Math.floor(DateTime.local().diff(DateTime.fromISO(value), 'year').years) === 0;

    return this.platformStyle.datetime(value, 'DATE', {
      ...options,
      hideLastYear: isThisYear
    });
  }
}
