import { Component, Input } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-collapse-group',
  templateUrl: './collapse-group.component.html',
  styleUrls: ['./collapse-group.component.scss']
})
export class CollapseGroupComponent {
  @Input()
  isCollapsed = false;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
