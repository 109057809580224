import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayVia'
})
export class DisplayViaPipe<O, R> implements PipeTransform {
  transform(option: O, fn: (option: O) => R): R {
    return fn(option);
  }
}
