// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export function registerHtmlBlot(Quill: any) {
  const BlockEmbed = Quill.import('blots/block/embed');

  class HtmlBlot extends BlockEmbed {
    static blotName = 'html';
    static tagName = 'iframe';
    static className = 'ql-html';

    // @ts-expect-error TS2564
    domNode: HTMLElement;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static create(value: { html: string; width: string; height: string }) {
      const node: HTMLElement = super.create(value.html);

      node.setAttribute('srcdoc', value.html);

      node.setAttribute('frameborder', '0');
      node.setAttribute('width', '100%');
      if (value.width) {
        node.setAttribute('width', value.width);
      }
      if (value.height) {
        node.setAttribute('height', value.height);
      }
      return node;
    }

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static formats(iFrame) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const format = {} as { height: any; width: any; srcdoc: string };

      if (iFrame.hasAttribute('height')) {
        format.height = iFrame.getAttribute('height');
      }

      if (iFrame.hasAttribute('width')) {
        format.width = iFrame.getAttribute('width');
      }

      if (iFrame.hasAttribute('srcdoc')) {
        format.srcdoc = iFrame.getAttribute('srcdoc');
      }

      return format;
    }

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static value(node) {
      return node.getAttribute('srcdoc');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format(formatName: string, value: any): void {
      if (formatName === 'height' || formatName === 'width' || formatName === 'srcdoc') {
        if (value) {
          this.domNode.setAttribute(formatName, value);
        } else {
          this.domNode.removeAttribute(formatName);
        }
      } else {
        super.format(formatName, value);
      }
    }
  }

  Quill.register(HtmlBlot);
}
