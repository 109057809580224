<h1
  data-qa-id="guide-save-changes-dialog-save-changes-title"
  class="header"
  pui-typography="h2"
  i18n="@@saveChangesGuideModal">
  Save changes?
</h1>
<p pui-typography="body-s">
  <ng-container *ngIf="isSimpleDialogText; else serviceDescription" i18n="@@youHaveUnsavedChangesGuideModalText">
    You have unsaved changes on this page.
  </ng-container>

  <ng-template #serviceDescription i18n="@@youHaveUnsavedChangesInServiceText">
    You have unsaved changes in this service.
  </ng-template>
</p>
<pui-modal-buttons>
  <button
    data-qa-id="guide-save-changes-dialog-save-button"
    i18n="@@save"
    (click)="close(redirectAfterSaveTypes.SAVE)"
    pui-button>
    Save
  </button>
  <button
    data-qa-id="guide-save-changes-dialog-discard-button"
    i18n="@@discard"
    (click)="close(redirectAfterSaveTypes.DISCARD)"
    pui-button
    appearance="error">
    Discard
  </button>
</pui-modal-buttons>
