import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import config from '@app/core/config/config';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { User, IUser } from '@app/shared/interfaces/user';
import { IProgramGeneralOptions } from '@app/screens/guide/guide-programs/types';

const DISPLAY_LIMIT = 10;

@Injectable({
  providedIn: 'root'
})
export class MarketplaceProgramApiService {
  constructor(private _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadOptions$() {
    return this._http.get<IProgramGeneralOptions>(`${config.apiPath}/public/marketplace/common-data`).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        console.log(e);
        return throwError(e);
      }),
      map(({ tags, languages }) => ({ tags, languages }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getProgramsPrices$() {
    return this._http.get<{ min: number; max: number }>(`${config.apiPath}/public/marketplace/programs/prices`).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        console.log(e);
        return throwError(e);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getProgramsCount$() {
    return this._http.get<{ count: number }>(`${config.apiPath}/public/marketplace/programs/count`).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        console.log(e);
        return throwError(e);
      }),
      map(({ count }) => count)
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getGuidesShortInfoByIds$(guideIds) {
    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: {
        'guideIds[]': guideIds,
        t: '' + Date.now() // Required to beat Angular inner caching
      }
    });
    return this._http.get<{ guides: IUser[] }>(`${config.apiPath}/public/marketplace/get-coaches`, { params }).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        console.log(e);
        return throwError(e);
      }),
      map(({ guides }) => guides.map(guide => new User(guide.id, guide.firstName, guide.lastName, guide.photo)))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getGuidesShortInfoByName$(search: string, exclude: string[] = []) {
    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: {
        search,
        'exclude[]': exclude,
        t: '' + Date.now() // Required to beat Angular inner caching
      }
    });
    return this._http.get<{ guides: IUser[] }>(`${config.apiPath}/public/marketplace/search-coaches`, { params }).pipe(
      // eslint-disable-next-line id-length
      catchError(e => {
        return throwError(e);
      }),
      map(({ guides }) =>
        guides.length
          ? [
              ...guides
                .map(guide => new User(guide.id, guide.firstName, guide.lastName, guide.photo))
                .slice(0, DISPLAY_LIMIT),
              { countLeft: guides.length - Math.min(guides.length, DISPLAY_LIMIT) }
            ]
          : []
      )
    );
  }
}
