import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceSchedulingStubModule } from '@app/modules/service-scheduling/components/service-scheduling-loading-stub/service-scheduling-stub.module';
import { UserSelectModule } from '@app/modules/service-scheduling/components/user-select/user-select.module';
import { OfferGuideApiService } from '@app/modules/service-scheduling/services';
import {
  PuiAlertModule,
  PuiButtonModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputCurrencyModule,
  PuiInputModule,
  PuiInputRadioModule,
  PuiLetModule,
  PuiLinkModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { SelectedUsersCardModule } from '@libs/components/selected-users-card/selected-users-card.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { FullDateModule } from './components/full-date/full-date.module';
import { RecurrenceFormModule } from './components/recurrence/recurrence.module';
import { ServiceFormRoutingModule } from './service-form-routing.module';
import { ServiceFormComponent } from './service-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ServiceFormRoutingModule,
    ReactiveFormsModule,
    UserSelectModule,
    RecurrenceFormModule,
    PuiInputCurrencyModule,
    FullDateModule,
    NgbTooltipModule,
    PuiLetModule,
    PuiInputCheckboxModule,
    PuiButtonModule,
    PuiInputModule,
    PuiLinkModule,
    PuiIconModule,
    PuiTypographyModule,
    PuiInputRadioModule,
    ServiceSchedulingStubModule,
    PuiAlertModule,
    SelectedUsersCardModule
  ],
  declarations: [ServiceFormComponent],
  providers: [OfferGuideApiService],
  exports: [ServiceFormComponent]
})
export class ServiceFormModule {}
