import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSetMetaToFirstChild]'
})
export class SetMetaToFirstChildDirective implements OnInit {
  @Input('appSetMetaToFirstChild')
  // @ts-expect-error TS2564
  dataBeforeKey: number | null;

  constructor(private readonly _el: ElementRef, private readonly _renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.dataBeforeKey) {
      this.setFirstNodeMetaData();
    }
  }

  private setFirstNodeMetaData(): void {
    const firstNode = this._el.nativeElement.children[0];
    this._renderer.setAttribute(firstNode, 'data-before', `${this.dataBeforeKey}.`);
    this._renderer.addClass(firstNode, 'numbered');
  }
}
