import { AutosizeModule } from 'ngx-autosize';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GuideSessionModule } from '@app/modules/guide-session/guide-session.module';
import { ScheduleDateTimePickerModule } from '@app/modules/ui-kit/schedule';
import { SharedModule } from '@app/shared';
import { ScheduleSessionModule } from '@app/shared/feature/schedule-session/schedule-session.module';
import { SharedModule as SharedCoreModule } from '@libs/shared/shared.module';

import { GuideOfferFormComponent } from './components/guide-offer-form/guide-offer-form.component';
import { GuideOfferModalComponent } from './components/guide-offer-modal/guide-offer-modal.component';

@NgModule({
  imports: [
    AutosizeModule,
    CommonModule,
    ReactiveFormsModule,
    ScheduleDateTimePickerModule,
    SharedModule,
    SharedCoreModule,
    ScheduleSessionModule,
    GuideSessionModule
  ],
  declarations: [GuideOfferModalComponent, GuideOfferFormComponent],
  entryComponents: [GuideOfferModalComponent]
})
export class GuideOfferModule {}
