import { NgModule } from '@angular/core';
import { CustomFieldsStoreModule } from '@app/screens/guide/guide-clients/guide-relations-table/store/custom-fields/custom-fields-store.module';
import { ContactsStoreModule } from '@app/screens/guide/guide-clients/guide-relations-table/store/relations/contacts-store.module';
import { ClientsTagsFeatureService } from './clients-tags-feature.service';
import { PuiDialogModule } from '@awarenow/profi-ui-core';

@NgModule({
  providers: [ClientsTagsFeatureService],
  imports: [CustomFieldsStoreModule, ContactsStoreModule, PuiDialogModule]
})
export class ClientsTagsFeatureModule {}
