import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Testimonial } from '../types/testimonial';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-service-testimonials-editor',
  templateUrl: './guide-service-testimonials-editor.component.html',
  styleUrls: [
    '../common-styles/forms.scss',
    '../common-styles/layout.scss',
    '../common-styles/program.scss',
    './guide-service-testimonials-editor.component.scss'
  ]
})
export class GuideServiceTestimonialsEditorComponent implements OnInit, OnChanges {
  @Input()
  // @ts-expect-error TS2564
  formTestimonials: FormArray;

  @Input()
  // @ts-expect-error TS2564
  disabled: boolean;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.disabled) {
      this.formTestimonials.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue) {
      this.formTestimonials.disable();
    }
  }

  addTestimonial(): void {
    const newTestimonial = new Testimonial();

    const newTestimonialFormGroup = this._formBuilder.group({
      localId: [newTestimonial.localId],
      clientInfo: [newTestimonial.clientInfo],
      text: [newTestimonial.text]
    });

    this.formTestimonials.push(newTestimonialFormGroup);
  }

  removeTestimonial(index: number): void {
    this.formTestimonials.removeAt(index);
  }
}
