import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionTypes } from '@app/shared/enums/session-types';
import { ClientGuide } from '@app/core/users/types';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { IClientSessionActionsMenuItem } from '@app/shared/interfaces/session';

import { Guide, IClientGuideSession } from '../../types/types';
import { ClientSessionsCommunicationsService } from '../../services/client-sessions-communications.service';

enum SessionActions {
  Decline = 'decline'
}

const declineOfferMsg = `Decline`;

@Component({
  selector: 'app-rescheduling-client-session',
  templateUrl: './rescheduling-client-session.component.html',
  styleUrls: ['../../styles/session.scss', '../../../../../../scss/sessions/sessions-invitations.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReschedulingClientSessionComponent {
  readonly SessionTypes = SessionTypes;

  actions: IClientSessionActionsMenuItem[] = [
    {
      icon: 'pui:x-circle',
      title: declineOfferMsg,
      className: 'icon-cancel',
      action: SessionActions.Decline
    }
  ];

  @Input()
  // @ts-expect-error TS2564
  clientSession: IClientGuideSession;

  constructor(private _emitter: ClientSessionsCommunicationsService) {}

  approve(): void {
    this._emitter.approveReschedulement(this.clientSession);
  }

  reschedule(): void {
    this._emitter.rescheduleSession(this.clientSession);
  }

  cancel(): void {
    this._emitter.cancelSession(this.clientSession);
  }

  buildDirectChatId(user: ClientGuide | Guide): string {
    return buildDirectChatLinkId(user);
  }
}
