import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PuiButtonModule,
  PuiIconModule,
  PuiInputModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { WorkingTimeSlotComponent } from './components/working-time-slot/working-time-slot.component';
import { WorkingTimeFormComponent } from './working-time-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiTooltipModule,
    PuiIconModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule
  ],
  declarations: [WorkingTimeFormComponent, WorkingTimeSlotComponent],
  exports: [WorkingTimeFormComponent]
})
export class WorkingTimeFormModule {}
