import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ServiceScheduleApiService } from './service-schedule-api.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleDate {
  date: string;
  timeSlots: string[];
}

@Injectable()
export class ServiceScheduleService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleSlots: string[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _schedule$ = new BehaviorSubject<IScheduleDate[]>([]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timezone = DateTime.local().zoneName;

  get schedule$(): Observable<IScheduleDate[]> {
    return this._schedule$.asObservable();
  }

  get timezone(): string {
    return this._timezone;
  }

  set timezone(timezone: string) {
    if (this._timezone === timezone) {
      return;
    }

    this._timezone = timezone;
    this.refreshSchedule();
  }

  constructor(private _http: HttpClient, private _api: ServiceScheduleApiService) {}

  ngOnDestroy(): void {
    this._schedule$.complete();
  }

  reset(guideId: number, serviceId: number): void {
    this._api
      .getGuideServiceSchedule$(guideId, serviceId)
      .pipe(
        map(dates => dates.map(({ dateStart }) => dateStart)),
        tap(scheduleSlots => (this._scheduleSlots = scheduleSlots))
      )
      .subscribe(() => this.refreshSchedule());
  }

  private buildSchedule(dates: string[], timezone: string): IScheduleDate[] {
    const days = new Map<number, string[]>();

    dates.forEach(date => {
      const dateTime = DateTime.fromISO(date).setZone(timezone);
      const startOfDateTime = dateTime.startOf('day').toMillis();

      if (days.has(startOfDateTime)) {
        // @ts-expect-error TS2532
        days.get(startOfDateTime).push(dateTime.toISO());
      } else {
        days.set(startOfDateTime, [dateTime.toISO()]);
      }
    });

    // @ts-expect-error TS7034
    const schedule = [];
    days.forEach((timeSlots, date) =>
      schedule.push({
        date: DateTime.fromMillis(date).setZone(this.timezone).toISO(),
        timeSlots
      })
    );

    // @ts-expect-error TS7005
    return schedule;
  }

  private refreshSchedule(): void {
    if (!this._scheduleSlots.length) {
      return;
    }

    this._schedule$.next(this.buildSchedule(this._scheduleSlots, this._timezone));
  }
}
