import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IGuideService } from '../../types';
import {
  GuidePublicService,
  GuidePublicServiceKinds,
  GuideServiceTypes,
  isGuidePublicSingleService
} from '@app/shared/interfaces/services';
import { IGuideServiceGroupItem } from '@app/modules/guide-service/types';
import { ModuleStatuses } from '@app/shared/interfaces/programs/client-programs';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-service-picker',
  templateUrl: './service-picker.component.html',
  styleUrls: ['./service-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServicePickerComponent),
      multi: true
    }
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'service-picker',
    '[attr.disabled]': 'isDisabled || null',
    '[class.disabled]': 'isDisabled || null'
  }
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ServicePickerComponent<T extends { readonly id: number; readonly type: string }>
  implements ControlValueAccessor
{
  readonly GuideServiceKinds = GuidePublicServiceKinds;

  readonly GuideServiceTypes = GuideServiceTypes;

  readonly collapsedDescriptions: { [serviceId: number]: boolean | null } = {};

  @Input()
  services: GuidePublicService[] = [];

  @Output()
  serviceSelect = new EventEmitter<IGuideService>();

  isDisabled = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectService(service: IGuideService): void {
    if (this.isDisabled) {
      return;
    }

    this.onChange(service);
    this.serviceSelect.emit(service);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(service: IGuideService | null): void {
    if (!service || !this.services || !this.services.length) {
      return;
    }

    // eslint-disable-next-line id-length
    const foundService = this.services.find(s => s.id === service.id);
    if (foundService && isGuidePublicSingleService(foundService)) {
      // @ts-expect-error TS2345
      this.selectService(foundService);
    }
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onChange = _ => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onTouched = () => {};

  canDisplayProgram(services: IGuideServiceGroupItem[]): number {
    return services.filter(service => service.serviceParent.status !== ModuleStatuses.RESTRICTED)?.length;
  }
}
