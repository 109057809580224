import { NgModule } from '@angular/core';
import { ForgotPasswordResetComponent } from './forgot-password-reset.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PuiButtonModule, PuiInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [ForgotPasswordResetComponent],
  imports: [CommonModule, ReactiveFormsModule, PuiButtonModule, PuiInputModule, PuiTypographyModule],
  exports: [ForgotPasswordResetComponent]
})
export class ForgotPasswordResetModule {}
