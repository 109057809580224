interface DataURIParts {
  byteString: string;
  mimeType: string;
}

function splitDataURI(dataURI: string): DataURIParts {
  const dataURISplitted = dataURI.split(',');
  const mimeType = dataURISplitted[0].split(':')[1].split(';')[0];

  let byteString;
  if (dataURISplitted[0].includes('base64')) {
    byteString = atob(dataURISplitted[1]);
  } else {
    byteString = decodeURIComponent(dataURISplitted[1]);
  }

  return { byteString, mimeType };
}

export function dataURIToBlob(dataURI: string): Blob {
  const { byteString, mimeType } = splitDataURI(dataURI);
  const byteArray = new Uint8Array(byteString.length);
  for (let i = 0, len = byteString.length; i < len; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([byteArray], { type: mimeType });
}
