import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseGroupModule } from '@app/modules/ui-kit/collapse-group/collapse-group.module';

import { CategoryCheckListComponent } from './components/category-check-list/category-check-list.component';
import { FlatCheckListComponent } from './components/flat-check-list/flat-check-list.component';
import { CheckboxReactiveComponent } from './components/checkbox-reactive/checkbox-reactive.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { FlatListComponent } from './components/flat-list/flat-list.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CollapseGroupModule],
  declarations: [
    CategoryCheckListComponent,
    FlatCheckListComponent,
    CheckboxReactiveComponent,
    CategoryListComponent,
    FlatListComponent
  ],
  exports: [
    CategoryCheckListComponent,
    FlatCheckListComponent,
    CheckboxReactiveComponent,
    CategoryListComponent,
    FlatListComponent
  ]
})
export class CheckListModule {}
