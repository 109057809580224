import { Injectable, OnDestroy } from '@angular/core';
import { GuidePublicServicesService } from '@app/core/public/guide-public-services.service';
import { IScheduleGuide, IScheduleOptions, IScheduleProvider } from '@app/modules/schedule-boards';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { GuidePublicService, GuideServiceTypes } from '@app/shared/interfaces/services';
import { Observable, ReplaySubject } from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ServicesRefreshService } from '@appWidget/screens/book-session/services/services-refresh.service';
import { ScheduleProxyService, ScheduleTypes } from './schedule-proxy.service';

@Injectable()
export class ServicesDetailsProviderService
  implements IScheduleProvider<IScheduleItem[] | { ranges: IScheduleItem[] }>, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _scheduleType$ = new ReplaySubject<ScheduleTypes>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _services$ = new ReplaySubject<GuidePublicService[]>(1);

  get schedule$(): Observable<IScheduleItem[] | { ranges: IScheduleItem[] }> {
    return this._scheduleProxy.schedule$;
  }

  get scheduleType$(): Observable<ScheduleTypes> {
    return this._scheduleType$.asObservable();
  }

  get services$(): Observable<GuidePublicService[]> {
    return this._services$.asObservable();
  }

  constructor(
    private readonly _servicesStore: GuidePublicServicesService,
    private readonly _refreshService: ServicesRefreshService,
    private readonly _scheduleProxy: ScheduleProxyService
  ) {}

  ngOnDestroy(): void {
    this._scheduleType$.complete();
    this._services$.complete();
  }

  refreshSchedule(scheduleOptions: Readonly<IScheduleOptions>): void {
    let scheduleType: ScheduleTypes;

    if (scheduleOptions.type === GuideServiceTypes.SESSION) {
      scheduleType = 'guideSchedule';
      this._scheduleProxy.activateGuideSchedule(scheduleOptions);
    } else if (scheduleOptions.type === GuideServiceTypes.GROUP_SESSION) {
      scheduleType = 'serviceSchedule';
      this._scheduleProxy.activateGuideServiceSchedule(scheduleOptions);
    }

    // @ts-expect-error TS2454
    this._scheduleType$.next(scheduleType);
  }

  refreshServices(guide: Readonly<IScheduleGuide>, splitByWorkspaces = true): void {
    this._servicesStore
      // TODO: return workspaceId after merge new public pages
      // @ts-expect-error TS2322
      .getServices$({ id: guide.id, workspaceId: splitByWorkspaces ? guide.workspaceId : null })
      .subscribe(services => this._services$.next(services));
    this._refreshService.refresh$.next();
  }
}
