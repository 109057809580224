import { WithCoverImage, WithDuration, WithHostSelectionRequirement, WithID, WithWorkspaceID } from '@app/base';
import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { GuideServiceParent } from '@app/modules/book-service';
import { Testimonial } from '@app/modules/guide-service-editor/types/testimonial';
import { IScheduleGuide } from '@app/modules/schedule-boards';
import { IWorkspace } from '@app/modules/workspaces/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionType } from '@app/shared/enums/session-type';
import { GuideServiceTypes } from '@app/shared/interfaces/services/guide-service';
import { IUser } from '@app/shared/interfaces/user';
import { Recurrence } from '@appWidget/modules/booking/interfaces';

import { IServiceGuide, ServiceGuide } from './service-guide';
import { ServiceScheduleItem } from './service-schedule';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookSessionServiceEvent extends WithID, Readonly<WithDuration>, WithHostSelectionRequirement {
  readonly analyticSourceType: AnalyticSourceTypes;
  readonly name: string;
  readonly type: GuideServiceTypes;
  readonly guide: Pick<IScheduleGuide, 'id' | 'workspaceId'> & Pick<IUser, 'firstName' | 'lastName'>;
  readonly price?: number;
  readonly hidePrice?: boolean | null;
  readonly time?: {
    readonly dateStart: string;
    readonly timezone?: string;
    readonly serviceHost?: number;
  };
  readonly serviceParent?: GuideServiceParent;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPublicSessionTemplate extends WithDuration, Partial<WithWorkspaceID>, WithCoverImage {
  id: number;
  name: string;
  connectionType: SessionConnectionTypes;
  description: string | null;
  descriptionMarkup: string | null;
  format: string;
  price: number | null;
  hidePrice: boolean | null;
  overtimeCharge: number | null;
  type: GuideServiceTypes;
  status: string;
  participantsLimit: number | null;
  address: string | null;
  hosts: { userId: number }[];
  guide: IServiceGuide;
  workspace: IWorkspace;
  schedule?: ServiceScheduleItem[];
  testimonials: { clientInfo: string; text: string }[];
  hasSingleHost: boolean;
  sessionType: SessionType;
  reccuringEvent: Recurrence;
  seatsPerTimeSlot: number | null;
  restrictClientBooking: boolean;
  link?: string;
}

export class PublicSessionTemplate {
  // @ts-expect-error TS2564
  id: number;

  // @ts-expect-error TS2564
  name: string;

  // @ts-expect-error TS2564
  connectionType: SessionConnectionTypes;

  description?: string | null;

  descriptionMarkup?: string | null;

  // @ts-expect-error TS2564
  duration: number;

  // @ts-expect-error TS2564
  format: string;

  link?: string;

  workspaceId?: number;

  price?: number | null;

  overtimeCharge?: number | null;

  // @ts-expect-error TS2564
  type: GuideServiceTypes;

  // @ts-expect-error TS2564
  status: string;

  // @ts-expect-error TS2564
  guide: ServiceGuide;

  schedule?: ServiceScheduleItem[];

  // @ts-expect-error TS2564
  testimonials: Testimonial[];

  // @ts-expect-error TS2564
  participantsLimit: number | null;

  // @ts-expect-error TS2564
  coverImage: string;

  // @ts-expect-error TS2564
  coverImageThumb: string;

  // @ts-expect-error TS2564
  address: string | null;

  constructor(template?: IPublicSessionTemplate) {
    if (template) {
      Object.entries(template).forEach(([prop, value]) => {
        if (prop === 'guide') {
          this.guide = new ServiceGuide(value);
        } else {
          // @ts-expect-error TS7053
          this[prop] = value;
        }
      });
    }
  }
}
