import { Injectable } from '@angular/core';
import { GuideScheduleEndpointService } from '@app/core/schedule/guide-schedule-endpoint.service';
import { IServerGuideSchedulesResponse, IServiceScheduleOptions } from '@app/core/schedule/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceScheduleFacadeService {
  constructor(private readonly _guide: GuideScheduleEndpointService) {}

  loadSchedule$(scheduleOptions: IServiceScheduleOptions): Observable<IServerGuideSchedulesResponse> {
    return this._guide.loadGuideSchedule$(scheduleOptions);
  }
}
