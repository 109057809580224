import { createAction, props } from '@ngrx/store';
import { WORKSPACE_GUIDES_STORE } from './commons/consts';
import type { WorkspaceGuidesState } from './commons/types';

export const workspaceGuidesFetch = createAction(`[${WORKSPACE_GUIDES_STORE}] Fetch`);
export const workspaceGuidesFetchSuccess = createAction(
  `[${WORKSPACE_GUIDES_STORE}] Fetch success`,
  props<Pick<WorkspaceGuidesState, 'data'>>()
);
export const workspaceGuidesFetchError = createAction(`[${WORKSPACE_GUIDES_STORE}] Fetch error`);

export const workspaceGuidesResetAll = createAction(`[${WORKSPACE_GUIDES_STORE}] Reset`);
