import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { RecurrenceFormControlService } from '@app/modules/service-scheduling/modules/service-form/components/recurrence/recurrence-form-control.service';
import { Frequency } from '@app/screens/guide/guide-sessions-templates/types';
import { WeekdayStr } from '@appWidget/modules/booking/interfaces';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

export interface Recurrence {
  count: number;
  interval: number;
  freq: Frequency;
  byday?: WeekdayStr[] | number[];
  until?: string;
}

@Component({
  selector: 'app-recurrence-form',
  templateUrl: './recurrence-form.component.html',
  styleUrls: ['./recurrence-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RecurrenceFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecurrenceFormComponent),
      multi: true
    },
    RecurrenceFormControlService,
    PuiDestroyService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecurrenceFormComponent implements ControlValueAccessor, Validator {
  MAX_COUNT = 20;

  form = this.recurrenceFormControlService;

  count: number | undefined;

  @Input() isBlockOffTime = false;

  constructor(
    @Inject(PuiDestroyService) private destroy$: Subject<void>,
    public recurrenceFormControlService: RecurrenceFormControlService
  ) {}

  onChange = (_: unknown): void => undefined;
  onTouched = (): void => undefined;

  registerOnChange(fn: (value: Recurrence | null) => void): void {
    this.form.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(value => {
      if (this.recurrenceFormControlService.recurrenceSwitcherControl.value) {
        // TODO use only the validator for this without the [maxNumber] directive
        value.count = this.count === undefined || value.count <= this.count ? value.count : this.count;
        fn(value);
      } else {
        fn(null);
      }
    });
  }

  registerOnTouched(fn: () => undefined): void {
    this.onTouched = fn;
  }

  writeValue(value: Recurrence): void {
    if (value) {
      if (value?.count > 0) {
        this.count = value.count;
      }
      this.MAX_COUNT = value?.count > 20 ? 20 : value?.count;
      this.form.patchValue({ ...value, count: value?.count > 20 ? 20 : value?.count });
    }
  }

  validate(): ValidationErrors | null {
    return {
      ...this.form.get('count')?.errors,
      ...this.form.get('interval')?.errors,
      ...this.form.get('freq')?.errors
    };
  }
}
