import { DateTime } from 'luxon';
import { getAbbrByTimezone } from '@app/shared/utils/date';
import { DateTimeResolverOptions, Fragment, Template } from '../model/interfaces';
import { clearDateTimeString, convertToDateTime, resolve } from '../utils';
import { getDefaultFragments, getDefaultLocale, getDefaultTemplates } from '../utils/get-defaults';

export function platformDateTime(template: Template | Fragment[], options?: DateTimeResolverOptions): string {
  // @ts-expect-error TS2532
  const date = convertToDateTime(options.value);
  if (!date) {
    return '';
  }

  const locale =
    (options &&
      options.templateOptions &&
      options.templateOptions.localeParams &&
      options.templateOptions.localeParams) ||
    getDefaultLocale();
  const fragments = (options && options.fragments) || {};
  const templates = (options && options.templates) || {};

  const params = {
    ...options,
    fragments: {
      ...getDefaultFragments(locale),
      ...fragments
    },
    template: {
      ...getDefaultTemplates(locale),
      ...templates
    },
    value: date
  };

  const timeZone = params?.templateOptions?.timezone || DateTime.local().zoneName;
  const isTimezoneVisible = params?.templateOptions?.showTimezone;

  let timezoneFormatted = '';
  if (isTimezoneVisible) {
    timezoneFormatted = ` (${getAbbrByTimezone(timeZone)})`;
  }

  return clearDateTimeString(resolve(template, 'toDateTime', params)) + timezoneFormatted;
}
