import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemingComponent {
  // @ts-expect-error TS2564
  formGroup: FormGroup;
  formArray: FormArray = new FormArray([new FormControl('Test'), new FormControl('Test 2')]);
}
