import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';

import { getNumericValue } from '@app/shared/utils/converters';
import { maxValue } from '@app/shared/form-validators/max-price.validator';
import { CurrencyService } from '@app/core/currency/currency.service';

const MAX_VALUE = 99999999.99;

@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PriceInputComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => PriceInputComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceInputComponent implements ControlValueAccessor, Validator, OnInit {
  // @ts-expect-error TS2564
  priceControl: FormControl;

  @Input()
  readonly maxValue?: number = MAX_VALUE;

  @Input()
  readonly currencySign?: string;

  @Input() qaId = '';

  constructor(private readonly _currencyService: CurrencyService) {
    this.currencySign = this._currencyService.defaultCurrencySign;
  }

  ngOnInit(): void {
    this.priceControl = new FormControl(null, [
      Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/),
      // @ts-expect-error TS2345
      maxValue(this.maxValue)
    ]);
  }

  onBlur(): void {
    this.onTouched();
  }

  onInput(): void {
    this.onChange(getNumericValue(this.priceControl.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.priceControl.disable();
    } else {
      this.priceControl.enable();
    }
  }

  validate(): ValidationErrors | null {
    return this.priceControl.valid || this.priceControl.disabled ? null : { invalidPrice: false };
  }

  writeValue(price: number | null): void {
    this.priceControl.setValue((price ?? '').toString());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onTouched = () => {};
}
