import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import config from '@app/core/config/config';
import { AuthService } from '@app/core/auth/services';
import { IntercomService } from '@app/core/intercom/intercom.service';
import { SegmentService } from '@app/core/analytics/segment.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Injectable({ providedIn: 'root' })
export class GuideBalanceService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _balance$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private http: HttpClient,
    private readonly _intercomService: IntercomService,
    private readonly _segmentService: SegmentService,
    private readonly _authService: AuthService
  ) {
    _authService
      .onAuth()
      .pipe(filter(user => user && user.RoleId === UserRoles.GUIDE))
      .subscribe(() => this.loadBalance());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBalance$(): Observable<any> {
    return this._balance$.asObservable();
  }

  loadBalance(): void {
    this.http
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .get<any>(`${config.apiPath}/user/guide/payments/balance`)
      .pipe(
        // temporary solution to handle backend error "division by zero"
        catchError(() => {
          return of({
            balance: 0,
            availableBalance: 0,
            incomeOnly: 0
          });
        }),
        filter(data => !!data),
        tap(data => this._intercomService.update({ revenue_generated_amount: data.incomeOnly })),
        tap(data =>
          this._segmentService.identify(this._authService.user.id, { revenue_generated_amount: data.incomeOnly })
        )
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: any) =>
        this._balance$.next({ balance: data.balance, availableBalance: data.availableBalance })
      );
  }
}
