import { Pipe, PipeTransform } from '@angular/core';
import { MenuOption } from '@app/modules/ui-kit/_base/dropdown-menu/dropdown-menu-option.type';

/**
 * Example of usage
 * toDropdownMenuOptions : 'title' : 'className' : 'clickEvent': 'svgIcon' : 'iconColor'
 */
@Pipe({
  name: 'toDropdownMenuOptions'
})
export class ToDropdownMenuOptionsPipe implements PipeTransform {
  transform(
    items: Object[],
    title: string,
    className: string,
    event: string,
    svgIcon: string,
    appearance: string
  ): MenuOption[] {
    if (!items) {
      // @ts-expect-error TS2322
      return null;
    }

    return items.map(subject => ({
      // @ts-expect-error TS7053
      title: subject[title],
      // @ts-expect-error TS7053
      className: subject[className],
      // @ts-expect-error TS7053
      event: subject[event],
      // @ts-expect-error TS7053
      svgIcon: subject[svgIcon],
      // @ts-expect-error TS7053
      appearance: subject[appearance]
    }));
  }
}
