import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { IFilterSearchParams, IFilterModel } from '../types';

@Injectable({
  providedIn: 'root'
})
export class PublicEventsFeedFilterService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _filter$: ReplaySubject<IFilterSearchParams> = new ReplaySubject<IFilterSearchParams>(1);

  updateFilters(filters: IFilterModel): void {
    const { date, type, coach, segment, cursor, limit } = filters;
    const today = DateTime.local().toISO();
    const filterParams: IFilterSearchParams = {
      appendResults: !!cursor
    };
    if (date) {
      const day = DateTime.fromObject(date);
      filterParams.from = day.startOf('day').toISO();
      filterParams.to = day.endOf('day').toISO();
      if (cursor) {
        const cursorDateTime = DateTime.fromISO(cursor);
        filterParams.from = cursorDateTime.toISO();
      }
    }

    if (segment === 'upcoming') {
      filterParams.from = today;
      if (cursor) {
        const cursorDateTime = DateTime.fromISO(cursor);
        filterParams.from = cursorDateTime.toISO();
      }
    }

    if (segment === 'past') {
      filterParams.to = today;
      if (cursor) {
        const cursorDateTime = DateTime.fromISO(cursor);
        filterParams.to = cursorDateTime.toISO();
      }
    }

    if (type && type !== 'all') {
      filterParams.type = type;
    }

    if (coach) {
      filterParams.host = '' + coach;
    }

    if (limit) {
      filterParams.limit = '' + limit;
    }

    this._filter$.next(filterParams);
  }

  get filter$(): Observable<IFilterSearchParams> {
    return this._filter$.asObservable();
  }
}
