import { IMenuLink, MenuLinkTypes } from '@app/screens/admin/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { PuiNavigationEntity } from '@awarenow/profi-ui-core';
import { of } from 'rxjs';
import { UrlTypes } from '@app/shared/enums/url-types';
import { createEventFromLink } from '@app/modules/navigation/utils/create-event-from-link';
import { createRouteFromLink } from '@app/modules/navigation/utils/create-route-from-link';
import { filterByRole } from '@app/modules/navigation/utils/filter-by-role';

// @ts-expect-error TS7006
export const menuLinkToNavigationEntity = (link: IMenuLink, role: UserRoles, windowService): PuiNavigationEntity => {
  return {
    item: {
      // @ts-expect-error TS2322
      icon: link.iconName,
      title: link.label
    },
    route:
      link.urlType === UrlTypes.RELATIVE && link.type === MenuLinkTypes.INTERNAL
        ? createRouteFromLink(link)
        : undefined,
    // @ts-expect-error TS2322
    events:
      link.urlType === UrlTypes.ABSOLUTE || (link.urlType === UrlTypes.RELATIVE && link.type === MenuLinkTypes.EXTERNAL)
        ? createEventFromLink(link, windowService)
        : undefined,
    visibility$: filterByRole(link.visibleFor, role).includes(role) ? of(true) : of(false)
  };
};
