<div class="slider" [ngStyle]="{ height: contentHeight > 0 ? contentHeight + 'px' : 'auto' }">
  <div class="left" [class.active]="scrollLeft !== 0" (click)="scrollLeftAct()"></div>
  <div
    class="scroll"
    #scroll
    (scroll)="scrollListener($event)"
    [scrollLeft]="scroll.scrollWidth"
    [ngStyle]="{ height: contentHeight > 0 ? contentHeight + 20 + 'px' : 'auto' }">
    <div class="wrap" #wrap [ngStyle]="{ width: contentWidth > 0 ? contentWidth + 'px' : 'auto' }">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    class="right"
    [class.active]="scrollLeft < scroll.scrollWidth - scroll.offsetWidth"
    (click)="scrollRightAct()"></div>
</div>
