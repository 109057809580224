import { BehaviorSubject, EMPTY, from, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SelectedServiceStore, ServiceState } from 'stores/selected-service.store';

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROOT_PATH, ROUTER_OUTLET } from '@app/modules/service-scheduling/routes';
import { ServiceSchedulingComponent } from '@app/modules/service-scheduling/service-scheduling.component';
import { ServiceSchedulingLSService } from '@app/modules/service-scheduling/services/scheduling-local-storage.service';
import { ServiceSchedulingFiltersService } from '@app/modules/service-scheduling/services/service-scheduling-filters.service';
import { ServiceSchedulingStep } from '@app/modules/service-scheduling/store/service-scheduling/commons/types';
import {
  resetStepHistory,
  setClients,
  setSeriviceSchedulingStep
} from '@app/modules/service-scheduling/store/service-scheduling/service-scheduling.actions';
import { Events, GuideServices } from '@app/modules/service-scheduling/types';
import { GET_IS_ADMIN_WORKSPACE_VALUE } from '@app/modules/workspaces/providers/is-admin-workspace';
import { GET_IS_SOLO_WORKSPACE } from '@app/modules/workspaces/providers/is-solo-workspace';
import { GET_IS_TEAM_WORKSPACE_VALUE } from '@app/modules/workspaces/providers/is-team-workspace';
import { GET_MY_GUIDE_ID_VALUE } from '@app/modules/workspaces/providers/my-guide-id';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { SessionType } from '@app/shared/enums/session-type';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PuiDrawerConfig, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { Store } from '@ngrx/store';

export interface ScheduleSessionPrimaryFilters {
  serviceTypes?: GuideServiceTypes[];
  serviceIds?: number[];
}

export interface ScheduleSessionOptions {
  // Will disable back button for component
  disableBackButton?: boolean;
  hideClientSelectorInServiceForm?: boolean;
  /**
   * That parameter adds priority for clients entities.
   */
  onlyClients?: boolean;
}

export interface ScheduleSessionConfig {
  service?: Partial<GuideServices.RootObject> & { availableSessions?: number };
  clientsIds?: number[] | string[];
  serviceParent?: {
    id: number;
    type: GuideServiceTypes;
  };
  predefinedData?: {
    date?: Date;
    time?: string;
    hostId?: number;
  };
  filters?: ScheduleSessionPrimaryFilters;
}

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable()
export class ServiceSchedulingService implements OnDestroy {
  private drawerRef: PuiDrawerRef | null = null;

  private destroy$ = new Subject<void>();

  readonly config$ = new BehaviorSubject<ScheduleSessionConfig>({});
  readonly service$ = this.config$.pipe(map(config => config?.service));
  readonly options$ = new BehaviorSubject<ScheduleSessionOptions>({});

  get afterClose$(): Observable<void> | undefined {
    return this.drawerRef?.afterClosed$;
  }

  constructor(
    private readonly drawerService: PuiDrawerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly schedulingLocalStorageService: ServiceSchedulingLSService,
    private readonly serviceSchedulingFiltersService: ServiceSchedulingFiltersService,
    private store$: Store,
    private readonly router: Router,
    @Inject(GET_IS_SOLO_WORKSPACE) private getIsSoloWorkspace: () => boolean,
    @Inject(GET_IS_ADMIN_WORKSPACE_VALUE) private geIsWorkspaceAdmin: () => boolean,
    @Inject(GET_IS_TEAM_WORKSPACE_VALUE) private getIsTeam: () => boolean,
    @Inject(GET_MY_GUIDE_ID_VALUE) private getMyGuideId: () => number,
    private selectedServiceStore: SelectedServiceStore
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  scheduleSession(config: ScheduleSessionConfig, options?: ScheduleSessionOptions): Observable<boolean> {
    const { clientsIds, filters } = config;

    this.config$.next(config);
    this.options$.next(options || {});

    this.schedulingLocalStorageService.setChosenClientsIds(clientsIds || []);
    this.serviceSchedulingFiltersService.primaryFilters$.next(filters || {});
    this.store$.dispatch(setSeriviceSchedulingStep({ currentStep: ServiceSchedulingStep.SERVICE_SCHEDULE_LIST }));

    return from(
      this.router.navigate([{ outlets: { [ROUTER_OUTLET]: [ROOT_PATH] } }], {
        relativeTo: this.activatedRoute,
        state: {
          clientsIds: clientsIds
        }
      })
    );
  }

  /**
   * This method determines the appropriate redirection.
   */
  scheduleService(config: ScheduleSessionConfig, options?: ScheduleSessionOptions): Observable<void | boolean> {
    if (!config.service) {
      // TODO oleg.m@profi.io Write cool error message
      throw Error('Expected service!');
    }

    // Set component options
    this.options$.next(options || {});

    this.store$.dispatch(setSeriviceSchedulingStep({ currentStep: ServiceSchedulingStep.SERVICE_SCHEDULE_LIST }));

    // TODO oleg.m@profi.io Don't load service before open drawer.
    return this.selectedServiceStore.getServiceById$(config.service.id as number).pipe(
      // Required take only first value
      take(1),
      switchMap((service: ServiceState['service']) => {
        const isSolo = this.getIsSoloWorkspace();
        const isTeam = this.getIsTeam();
        const isAdminOfTeam = this.geIsWorkspaceAdmin();
        const isSessionTypeCollective = service?.sessionType === SessionType.COLLECTIVE;

        if (
          // User is a solo guide will be redirected to scheduling
          isSolo ||
          // User is a team member will redirect to scheduling
          (!isAdminOfTeam && isTeam) ||
          // a session connection type is collective.
          isSessionTypeCollective
        ) {
          return this.openServiceForm({
            ...config,
            service: {
              ...service,
              type: config.service?.type || GuideServiceTypes.SESSION,
              availableSessions: config.service?.availableSessions
            },
            predefinedData: {
              hostId: this.getMyGuideId()
            }
          });
        }

        // User is team admin will redirect to choose host
        if (isAdminOfTeam) {
          return this.openChooseHost({
            ...config,
            service: {
              ...service,
              type: config.service?.type,
              id: config.service?.id,
              availableSessions: config.service?.availableSessions
            }
          });
        }

        return EMPTY;
      })
    );
  }

  proposeService(config: ScheduleSessionConfig, options?: ScheduleSessionOptions): Observable<void> {
    const { clientsIds, filters } = config;

    this.config$.next(config);
    this.options$.next(options || { disableBackButton: false });
    this.schedulingLocalStorageService.setChosenClientsIds(clientsIds || []);
    this.serviceSchedulingFiltersService.primaryFilters$.next(filters || {});
    this.store$.dispatch(setSeriviceSchedulingStep({ currentStep: ServiceSchedulingStep.PROPOSE_SERVICE_LIST }));

    return from(
      this.router
        .navigate([{ outlets: { [ROUTER_OUTLET]: [ROOT_PATH, 'propose-service'] } }], {
          relativeTo: this.activatedRoute
        })
        .then(() => {
          this.openServicesListDrawer();
        })
    );
  }

  openChooseHost(config: ScheduleSessionConfig): Observable<void> {
    if (!config.service) {
      throw Error('Expected service!');
    }

    const { clientsIds } = config;

    this.config$.next(config);
    this.schedulingLocalStorageService.setChosenClientsIds(clientsIds || []);
    this.store$.dispatch(setSeriviceSchedulingStep({ currentStep: ServiceSchedulingStep.CHOOSE_HOST }));

    return from(
      this.router
        .navigate([{ outlets: { [ROUTER_OUTLET]: [ROOT_PATH, config.service?.type, config.service?.id, 'host'] } }], {
          relativeTo: this.activatedRoute,
          queryParams: {
            availableSessions: config.service.availableSessions
          }
        })
        .then(() => {
          this.openServicesListDrawer();
        })
    );
  }

  openServiceForm(config: ScheduleSessionConfig): Observable<boolean> {
    const { service } = config;

    const serviceType = (service?.type || service?.serviceType) as GuideServiceTypes;

    this.config$.next(config);
    this.store$.dispatch(
      setSeriviceSchedulingStep({
        currentStep: [GuideServiceTypes.PACKAGE, GuideServiceTypes.PROGRAM].includes(serviceType)
          ? ServiceSchedulingStep.PROGRAM_AND_PACKAGE_FORM
          : ServiceSchedulingStep.SERVICE_FORM
      })
    );

    return from(
      this.router.navigate(
        [
          {
            outlets: {
              [ROUTER_OUTLET]: [ROOT_PATH, serviceType, service?.id?.toString()]
            }
          }
        ],
        {
          relativeTo: this.activatedRoute
        }
      )
    );
  }

  openServicesListDrawer(): void {
    if (!this.drawerRef) {
      this.drawerRef = this.drawerService.open(ServiceSchedulingComponent, DRAWER_CONFIG);
    }

    this.drawerRef.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.drawerRef?.close();
      this.drawerRef = null;
      this.router.navigate([{ outlets: { [ROUTER_OUTLET]: null } }]);
      this.store$.dispatch(setClients({ clients: [], chosenClientsIds: [] }));
      this.store$.dispatch(resetStepHistory());
    });
  }

  openFailedSchedule(
    type: GuideServiceTypes,
    service: GuideServices.RootObject,
    host: IWorkspaceMember,
    clients: CRMClient[],
    events: Events
  ): void {
    this.router.navigate([{ outlets: { [ROUTER_OUTLET]: [ROOT_PATH, type, service.id.toString(), 'fail'] } }], {
      state: {
        host,
        service,
        clients,
        events
      }
    });
  }

  scheduleSessionOfProgram(programId: number, sessionId: number, clients: number[] = []): Observable<boolean | void> {
    return this.scheduleService(
      {
        service: {
          id: sessionId,
          type: GuideServiceTypes.GROUP_SESSION
        },
        clientsIds: clients,
        serviceParent: {
          id: programId,
          type: GuideServiceTypes.PROGRAM
        }
      },
      {
        disableBackButton: true,
        hideClientSelectorInServiceForm: true
      }
    ).pipe(take(1));
  }
}
