import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleBoardsModule } from '@app/modules/schedule-boards';
import { SingleDateScheduleTimePickerModule } from '@app/modules/ui-kit/schedule';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';

import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';

import { GuideServiceScheduleComponent } from './components/guide-service-schedule/guide-service-schedule.component';
import { PrivateGuideScheduleComponent } from './components/private-guide-schedule/private-guide-schedule.component';
import { PublicGuideScheduleComponent } from './components/public-guide-schedule/public-guide-schedule.component';
import { SessionReschedulingComponent } from './components/session-rescheduling/session-rescheduling.component';
import { SessionReschedulingModalComponent } from './components/session-rescheduling-modal/session-rescheduling-modal.component';
import { SessionTimePickerComponent } from './components/session-time-picker/session-time-picker.component';
import { ReschedulingMessageComponent } from './components/rescheduling-message/rescheduling-message.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ScheduleBoardsModule,
    SingleDateScheduleTimePickerModule,
    FormsModule,
    CurrentPaymentModule
  ],
  declarations: [
    GuideServiceScheduleComponent,
    PrivateGuideScheduleComponent,
    PublicGuideScheduleComponent,
    SessionReschedulingComponent,
    SessionReschedulingModalComponent,
    SessionTimePickerComponent,
    ReschedulingMessageComponent
  ]
})
export class ReschedulingServiceModule {}
