import { FactoryProvider, inject, Optional, SkipSelf } from '@angular/core';
import { IMultiDateScheduleGroup } from '@app/modules/ui-kit/schedule';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { LocaleService } from '@app/core/locale/locale.service';
import { SCHEDULE_PROVIDER } from '../schedule-provider';
import { ISchedule, ISchedulePartitionUnit, IScheduleProvider, ITimeSlot } from '../../types';
import { ScheduleDatesPartitionStrategy } from '../schedule-dates-partition-strategies';
import { ScheduleTimeSlotsBuildStrategy } from '../schedule-time-slots-build-strategies';
import { DefaultMultiDateScheduleTimeSlotsFactory } from './default-multi-date-schedule-time-slots-factory';
import { DefaultScheduleDateTimeSlotsFactory } from './default-schedule-date-time-slots-factory';
import { ScheduleDateTimeSlotsFactory } from './schedule-date-time-slots-factory';
import { ScheduleTimeSlotsTimezoneFactory } from './schedule-time-slots-timezone-factory';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function scheduleDateTimeSlotsFactory(
  parent: ScheduleDateTimeSlotsFactory<unknown, unknown, unknown, unknown, unknown>,
  scheduleProvider: IScheduleProvider<ISchedule>,
  partitionStrategy: ScheduleDatesPartitionStrategy<ISchedule, ISchedulePartitionUnit>,
  timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<IScheduleItem[], ITimeSlot>
) {
  return (
    parent ||
    new DefaultScheduleDateTimeSlotsFactory(
      scheduleProvider,
      partitionStrategy,
      timeSlotsBuildStrategy,
      true,
      inject(LocaleService).getLocale()
    )
  );
}

export const SCHEDULE_DATE_TIME_SLOTS_PROVIDER: FactoryProvider = {
  provide: ScheduleDateTimeSlotsFactory,
  deps: [
    [new Optional(), new SkipSelf(), ScheduleDateTimeSlotsFactory],
    SCHEDULE_PROVIDER,
    ScheduleDatesPartitionStrategy,
    ScheduleTimeSlotsBuildStrategy
  ],
  useFactory: scheduleDateTimeSlotsFactory
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function multiDateScheduleTimeSlotsFactory(
  parent: ScheduleTimeSlotsTimezoneFactory<unknown, unknown, unknown>,
  scheduleProvider: IScheduleProvider<ISchedule>,
  timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<ISchedule, IMultiDateScheduleGroup<ITimeSlot>>
) {
  return (
    parent ||
    new DefaultMultiDateScheduleTimeSlotsFactory(
      scheduleProvider,
      timeSlotsBuildStrategy,
      inject(LocaleService).getLocale()
    )
  );
}

export const MULTI_DATE_SCHEDULE_TIME_SLOTS_PROVIDER: FactoryProvider = {
  provide: ScheduleTimeSlotsTimezoneFactory,
  deps: [
    [new Optional(), new SkipSelf(), ScheduleTimeSlotsTimezoneFactory],
    SCHEDULE_PROVIDER,
    ScheduleTimeSlotsBuildStrategy
  ],
  useFactory: multiDateScheduleTimeSlotsFactory
};
