import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { AnalyticCopyTypes } from '@app/core/analytics/types';
import { FacebookService } from '@app/core/social-services/facebook.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { TwitterService } from '@app/core/social-services/twitter.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-program-promotion',
  templateUrl: './program-promotion.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    './program-promotion.component.scss'
  ],
  providers: [FacebookService, TwitterService]
})
export class ProgramPromotionComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programLink = '';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _twitterLink = '';

  @Input()
  set programLink(value: string) {
    this._programLink = value;

    this.generateTwitterLink();
  }

  @Output()
  linkCopied = new EventEmitter<AnalyticCopyTypes>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get programLink(): string {
    return this._programLink;
  }

  get twitterLink(): string {
    return this._twitterLink;
  }

  constructor(
    public clients: GuideClientsService,
    private _facebook: FacebookService,
    private _twitter: TwitterService,
    private _notifications: NotificationsService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  generateTwitterLink(): void {
    this._twitterLink = this._twitter.generateShareLink(this._programLink, 'some initial message', [
      `#${this._runtimeConfigService.get('platformName')}`
    ]);
  }

  onCopyFailed(): void {
    this._notifications.error(`Copy failed`);
  }

  onBtnCopied(): void {
    this.linkCopied.emit(AnalyticCopyTypes.BUTTON);
    this._notifications.success(`Copied`);
  }

  onStandardCopied(): void {
    this.linkCopied.emit(AnalyticCopyTypes.STANDARD);
    this._notifications.success(`Copied`);
  }
}
