import { environment } from '@env/environment';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WikiTerm } from '../../types';

@Component({
  selector: 'app-wiki-term',
  templateUrl: './wiki-term.component.html',
  styleUrls: ['./wiki-term.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WikiTermComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _term: WikiTerm;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _termTagNames: string[];

  guidesRoute = environment.guidesRoute;

  @Input()
  set term(termValue: WikiTerm) {
    this._term = termValue;
    // @ts-expect-error TS2322
    this._termTagNames =
      this._term && this._term.tags && this._term.tags.length ? this._term.tags.map(tag => tag.name) : null;
  }

  get term(): WikiTerm {
    return this._term;
  }

  get termTagNames(): string[] {
    return this._termTagNames;
  }
}
