import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISystemNotification } from '../../../types';
import { GuideClientCardService } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Component({
  selector: 'app-package-notification',
  templateUrl: './package-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './package-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageNotificationComponent {
  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;

  readonly UserRoles = UserRoles;

  constructor(private readonly clientCardService: GuideClientCardService) {}

  showClientDetails(clientId: number): void {
    this.clientCardService.openDetails(0, clientId);
  }
}
