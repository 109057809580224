import { environment } from '@env/environment';
import { ConfigInterface } from './config-interface';

const config: ConfigInterface = {
  /**
   * ssrHttpEndpoint:
   * It's URL to access server from itself. (Must be without '/' at the end!)
   */
  ssrHttpEndpoint: 'http://localhost:8090',

  socket: {
    host: environment.signalHost,
    path: environment.production ? '/socket.io' : '/signal/socket.io'
  },

  chatSocket: {
    host: environment.chatHost,
    path: environment.production ? '/socket.io' : '/chat/socket.io'
  },

  notificationsSocket: {
    host: environment.notificationsHost,
    path: environment.production ? '/socket.io' : '/notifications/socket.io'
  },

  pingOnlineInterval: 30000,

  apiPath: `${environment.apiHost}/v2`,

  sessionCancellationFeePeriod: 12 // hours
};

export default config;
