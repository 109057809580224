import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BlogItemArticle, IArticleSelection } from '../../types';
import { makeUriFromString } from '@app/screens/blog/utils';

@Component({
  selector: 'app-blog-item-wide-article',
  templateUrl: './blog-item-wide-article.component.html',
  styleUrls: ['./blog-item-wide-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemWideArticleComponent {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get source() {
    return this.article.url || this.article.title;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return makeUriFromString(this.source, this.article.id);
  }

  @Input()
  // @ts-expect-error TS2564
  article: BlogItemArticle;

  @Output()
  select = new EventEmitter<IArticleSelection>();

  @HostListener('click')
  emitSelect(): void {
    this.select.emit({
      articleId: this.article.id,
      // @ts-expect-error TS2322
      authorUrl: this.article.author ? this.article.author.namedUrl : null
    });
  }
}
