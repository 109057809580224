import { DateTime } from 'luxon';

import { WithServicePermission } from '@app/base';
import { FAQuestion } from '@app/modules/guide-service-editor/types/faq';
import { Testimonial } from '@app/modules/guide-service-editor/types/testimonial';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';
import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
import { ProgramStartType } from '@app/shared/interfaces/programs';
import { ModuleCompletionTypes } from '@app/shared/interfaces/programs/program-module';
import { IUser, User } from '@app/shared/interfaces/user';

import { areNullableArraysEqual, getConstantOrderArraysPatch } from './helpers';
import { IIdentity } from './util-types';

export type ProgramLevel = 'beginner' | 'intermediate' | 'advanced';

export type ProgramStatuses = 'active' | 'archive' | 'draft' | 'unlisted';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramContent extends Partial<WithServicePermission> {
  additionalNotes: string | null;
  approaches: { id: number }[] | null;
  author: Pick<IUser, 'id' | 'firstName' | 'lastName' | 'namedUrl' | 'photo'> | null;
  authorId: number | null;
  coauthors: Partial<User>[] | null;
  coverImage: string | null;
  coverImageThumb: string | null;
  description: string | null;
  faq: FAQuestion[] | null;
  isFree: boolean;
  fixedPrice: boolean;
  hidePrice: boolean;
  issues: { id: number }[] | null;
  keyPoints: string | null;
  languages: number[] | null;
  length: string | null;
  level: ProgramLevel | null;
  moduleCompletionType: ModuleCompletionTypes | null;
  name: string;
  price: number | null;
  subscriptionPrice: number | null;
  subscriptionRecurrency: SubscriptionRecurrency | null;
  startDate: string | null;
  startType: ProgramStartType | null;
  status: ProgramStatuses | null;
  isHiddenForBook: boolean | null;
  testimonials: Testimonial[] | null;
  totalPayments: number | null;
}

export type IProgramContentPatch = IIdentity & Partial<IProgramContent>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramContentPersistenceAttributes {
  id: number;
  isFree: boolean;
  testimonials: { [localId: number]: number };
  faq: { [localId: number]: number };
}

export class ProgramContent implements IProgramContent, Partial<IIdentity>, Partial<WithServicePermission> {
  id?: number;

  additionalNotes: string | null;

  approaches: { id: number }[] | null;

  authorId: number | null;

  author: Pick<IUser, 'id' | 'firstName' | 'lastName' | 'namedUrl' | 'photo'> | null;

  coauthors: User[] | null;

  coverImage: string | null;

  coverImageThumb: string | null;

  description: string | null;

  faq: FAQuestion[] | null;

  isFree: boolean;

  fixedPrice: boolean;

  // @ts-expect-error TS2416
  hidePrice: boolean | null;

  issues: { id: number }[] | null;

  keyPoints: string | null;

  languages: number[] | null;

  length: string | null;

  level: ProgramLevel | null;

  // @ts-expect-error TS2564
  moduleCompletionType: ModuleCompletionTypes | null;

  name: string;

  price: number | null;

  subscriptionPrice: number | null;

  subscriptionRecurrency: SubscriptionRecurrency | null;

  startDate: string | null;

  startType: ProgramStartType | null;

  status: ProgramStatuses | null;

  isHiddenForBook: boolean | null;

  testimonials: Testimonial[] | null;

  totalPayments: number | null;

  permission?: ServiceAssigneePermission;

  constructor() {
    this.additionalNotes = null;
    this.author = null;
    this.authorId = null;
    this.coverImage = null;
    this.coverImageThumb = null;
    this.description = null;
    this.isFree = true;
    this.fixedPrice = true;
    this.hidePrice = false;
    this.keyPoints = null;
    this.length = null;
    this.level = null;
    this.name = '';
    this.price = null;
    this.subscriptionPrice = null;
    this.subscriptionRecurrency = null;
    this.startDate = null;
    this.startType = null;
    this.status = null;
    this.isHiddenForBook = null;
    this.totalPayments = null;
    // @ts-expect-error TS2322
    this.permission = null;

    this.approaches = [];
    this.coauthors = [];
    this.faq = [];
    this.issues = [];
    this.languages = [];
    this.testimonials = [];
  }

  static clean(content: IProgramContent): IProgramContent {
    return {
      additionalNotes: (content.additionalNotes && content.additionalNotes.trim()) || null,
      approaches: content.approaches,
      author: content.author,
      authorId: content.authorId,
      coauthors: content.coauthors,
      coverImage: content.coverImage,
      coverImageThumb: content.coverImageThumb,
      description: (content.description && content.description.trim()) || null,
      faq:
        // eslint-disable-next-line id-length
        (content.faq && content.faq.filter(q => (q.question && q.question.trim()) || (q.answer && q.answer.trim()))) ||
        null,
      isFree: content.isFree,
      fixedPrice: content.fixedPrice,
      hidePrice: content.hidePrice,
      issues: content.issues,
      keyPoints: (content.keyPoints && content.keyPoints.trim()) || null,
      languages: content.languages,
      length: (content.length && content.length.trim()) || null,
      level: content.level,
      moduleCompletionType: content.moduleCompletionType,
      name: (content.name && content.name.trim()) || '',
      price: content.price,
      subscriptionPrice: content.subscriptionPrice,
      subscriptionRecurrency: content.subscriptionRecurrency,
      startDate: (content.startDate && DateTime.fromISO(content.startDate).toISO({ includeOffset: false })) || null,
      startType: content.startType,
      status: content.status,
      isHiddenForBook: content.isHiddenForBook,
      totalPayments: content.totalPayments,
      permission: content.permission,
      testimonials:
        (content.testimonials &&
          // eslint-disable-next-line id-length
          content.testimonials.filter(t => (t.text && t.text.trim()) || (t.clientInfo && t.clientInfo.trim()))) ||
        null
    };
  }

  clone(): ProgramContent {
    const contentClone = new ProgramContent();

    contentClone.id = this.id;
    contentClone.authorId = this.authorId;
    contentClone.author = this.author;
    contentClone.additionalNotes = this.additionalNotes;
    contentClone.coverImage = this.coverImage;
    contentClone.coverImageThumb = this.coverImageThumb;
    contentClone.description = this.description;
    contentClone.keyPoints = this.keyPoints;
    contentClone.isFree = this.isFree;
    contentClone.fixedPrice = this.fixedPrice;
    contentClone.hidePrice = this.hidePrice;
    contentClone.length = this.length;
    contentClone.level = this.level;
    contentClone.moduleCompletionType = this.moduleCompletionType;
    contentClone.name = this.name;
    contentClone.price = this.price;
    contentClone.subscriptionRecurrency = this.subscriptionRecurrency;
    contentClone.subscriptionPrice = this.subscriptionPrice;
    contentClone.startDate = this.startDate;
    contentClone.startType = this.startType;
    contentClone.status = this.status;
    contentClone.isHiddenForBook = this.isHiddenForBook;
    contentClone.totalPayments = this.totalPayments;

    // @ts-expect-error TS2531
    contentClone.approaches = this.approaches.slice();
    // @ts-expect-error TS2531
    contentClone.coauthors = this.coauthors.map(({ id, firstName, lastName }) => new User(id, firstName, lastName));
    // @ts-expect-error TS2531
    contentClone.faq = this.faq.map(faQuestion => faQuestion.clone());
    // @ts-expect-error TS2531
    contentClone.issues = this.issues.slice();
    // @ts-expect-error TS2531
    contentClone.languages = this.languages.slice();
    // @ts-expect-error TS2531
    contentClone.testimonials = this.testimonials.map(testimonial => testimonial.clone());

    return contentClone;
  }

  getDiffFrom(newContent: IProgramContent): IProgramContentPatch | null {
    // @ts-expect-error TS2322
    const diff: IProgramContentPatch = { id: this.id };

    [
      'additionalNotes',
      'coverImage',
      'coverImageThumb',
      'description',
      'isFree',
      'keyPoints',
      'length',
      'level',
      'name',
      'startDate',
      'startType',
      'price',
      'status',
      'isHiddenForBook',
      'fixedPrice',
      'hidePrice',
      'subscriptionPrice',
      'subscriptionRecurrency',
      'totalPayments',
      'moduleCompletionType'
    ].forEach(prop => {
      if (prop === 'startDate' && this.startType === newContent['startType']) {
        return;
      }
      // @ts-expect-error TS7053
      if (this[prop] !== newContent[prop]) {
        // @ts-expect-error TS7053
        diff[prop] = newContent[prop];
      }
    });

    if (!areNullableArraysEqual(this.approaches, newContent.approaches)) {
      diff.approaches = newContent.approaches;
    }

    if (!areNullableArraysEqual(this.coauthors, newContent.coauthors)) {
      diff.coauthors = newContent.coauthors;
    }

    if (!areNullableArraysEqual(this.issues, newContent.issues)) {
      diff.issues = newContent.issues;
    }

    if (!areNullableArraysEqual(this.languages, newContent.languages)) {
      diff.languages = newContent.languages;
    }

    const testimonialsDiff = this.getItemsDiff(this.testimonials, newContent.testimonials);

    if (Object.keys(testimonialsDiff).length) {
      diff.testimonials = testimonialsDiff;
    }

    const faqDiff = this.getItemsDiff(this.faq, newContent.faq);

    if (Object.keys(faqDiff).length) {
      diff.faq = faqDiff;
    }

    return Object.keys(diff).length > 1 ? diff : null;
  }

  setPersistenceAttributes(attributes: IProgramContentPersistenceAttributes): ProgramContent {
    this.id = attributes.id;

    if (attributes.testimonials) {
      // @ts-expect-error TS2531
      this.testimonials.forEach(testimonial => {
        const persistentTestimonialId = attributes.testimonials[testimonial.localId];

        if (persistentTestimonialId) {
          testimonial.setId(persistentTestimonialId);
        }
      });
    }

    if (attributes.faq) {
      // @ts-expect-error TS2531
      this.faq.forEach(faQuestion => {
        const persistentQuestionId = attributes.faq[faQuestion.localId];

        if (persistentQuestionId) {
          faQuestion.setId(persistentQuestionId);
        }
      });
    }

    return this;
  }

  setValues(content: IProgramContent & IIdentity): ProgramContent {
    this.id = content.id;
    this.additionalNotes = content.additionalNotes;
    this.approaches = content.approaches;
    this.author = content.author;
    this.coauthors = content.coauthors
      ? // @ts-expect-error TS2345
        content.coauthors.map(coauthor => new User(coauthor.id, coauthor.firstName, coauthor.lastName))
      : [];
    this.coverImage = content.coverImage;
    this.coverImageThumb = content.coverImageThumb;
    this.description = content.description;
    this.faq = content.faq ? content.faq.map(faQuestion => new FAQuestion().setValues(faQuestion)) : [];
    this.isFree = content.isFree;
    this.fixedPrice = content.fixedPrice;
    this.hidePrice = content.hidePrice;
    this.issues = content.issues;
    this.keyPoints = content.keyPoints;
    this.languages = content.languages;
    this.length = content.length;
    this.level = content.level;
    this.moduleCompletionType = content.moduleCompletionType;
    this.name = content.name;
    this.price = content.price;
    this.subscriptionPrice = content.subscriptionPrice;
    this.subscriptionRecurrency = content.subscriptionRecurrency;
    this.startDate = content.startDate;
    this.startType = content.startType;
    this.status = content.status;
    this.isHiddenForBook = content.isHiddenForBook;
    this.testimonials = content.testimonials
      ? content.testimonials.map(testimonial => new Testimonial().setValues(testimonial))
      : [];
    this.totalPayments = content.totalPayments;
    this.permission = content.permission;

    return this;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private getItemsDiff(currentItems, newContentItems) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const diff: any = {};
    const { created, deleted, updated } = getConstantOrderArraysPatch(currentItems, newContentItems);

    if (created.length) {
      diff.created = created;
    }

    if (deleted.length) {
      diff.deleted = deleted;
    }

    if (updated.length) {
      diff.updated = updated;
    }

    return diff;
  }

  withAuthor(user: IUser): ProgramContent {
    this.author = user;
    this.authorId = user.id;

    return this;
  }
}
