import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { GuideServicesUtilsService } from '@app/screens/guide/services/guide-services-utils.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CanCreateServicesGuard implements CanActivate {
  constructor(private readonly _router: Router, private readonly _guideServicesUtils: GuideServicesUtilsService) {}

  canActivate(): Observable<boolean> | UrlTree {
    return this._guideServicesUtils.canCreateService$.pipe(
      map(canCreateServices => {
        if (!canCreateServices) {
          this._router.navigateByUrl('/not-found').then();
        }
        return canCreateServices;
      })
    );
  }
}
