import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientGuide } from '@app/core/users/types';
import { ClientSessionsCommunicationsService } from '@app/screens/client/client-dashboard/services/client-sessions-communications.service';
import { IMenuAction } from '@app/shared/components/actions-menu/actions-menu.component';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';

import { IClientGuideSession } from '../../types/types';

enum SessionActions {
  Cancel = 'cancel',
  Hide = 'hide',
  Reschedule = 'reschedule'
}

@Component({
  selector: 'app-upcoming-client-session',
  templateUrl: './upcoming-client-session.component.html',
  styleUrls: ['../../styles/session.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingClientSessionComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientSession!: IClientGuideSession;

  @Input()
  set clientSession(value: IClientGuideSession) {
    this._clientSession = value;
    this.generateActions();
  }

  get clientSession(): IClientGuideSession {
    return this._clientSession;
  }

  get canBookAgain(): boolean {
    return (
      this.isSessionDefined &&
      this._clientSession.session.status !== SessionStatuses.APPROVED &&
      this._clientSession.session.status !== SessionStatuses.PENDING_APPROVEMENT &&
      this.clientSession.guide.pricePerHour != null
    );
  }

  get canSessionBeRescheduled(): boolean {
    return this.isSessionDefined && this._clientSession.session.serviceType === SessionTypes.SESSION;
  }

  get isAchievable(): boolean {
    return (
      this.isSessionDefined &&
      (this._clientSession.session.status === SessionStatuses.CANCELLED ||
        this._clientSession.session.status === SessionStatuses.DECLINED ||
        this._clientSession.session.status === SessionStatuses.GUIDE_OFFER_CANCELLED ||
        this._clientSession.session.status === SessionStatuses.GUIDE_OFFER_DECLINED)
    );
  }

  get isCancellable(): boolean {
    return (
      this.isSessionDefined &&
      (this._clientSession.session.status === SessionStatuses.APPROVED ||
        this._clientSession.session.status === SessionStatuses.PENDING_APPROVEMENT ||
        this._clientSession.session.status === SessionStatuses.RESCHEDULE_BY_CLIENT)
    );
  }

  get isSessionApproved(): boolean {
    return this.isSessionDefined && this._clientSession.session.status === SessionStatuses.APPROVED;
  }

  get isSessionPending(): boolean {
    return (
      this.isSessionDefined &&
      (this._clientSession.session.status === SessionStatuses.PENDING_APPROVEMENT ||
        this._clientSession.session.status === SessionStatuses.RESCHEDULE_BY_CLIENT)
    );
  }

  get isSessionDefined(): boolean {
    return this._clientSession != null && this._clientSession.session != null;
  }

  actions: IMenuAction[] = [];

  constructor(private _emitter: ClientSessionsCommunicationsService) {}

  emitSessionAction(actionId: string): void {
    switch (actionId) {
      case SessionActions.Cancel:
        this._emitter.cancelSession(this._clientSession);
        break;
      case SessionActions.Hide:
        this._emitter.archiveSession(this._clientSession);
        break;
      case SessionActions.Reschedule:
        this._emitter.rescheduleSession(this._clientSession);
        break;
      default:
        break;
    }
  }

  reorder(): void {
    this._emitter.reorderSession(this.clientSession);
  }

  buildDirectChatId(user: ClientGuide): string {
    return buildDirectChatLinkId(user);
  }

  private generateActions(): void {
    const actions = [] as IMenuAction[];

    if (this.isAchievable) {
      actions.push({
        id: SessionActions.Hide,
        label: `Hide`,
        class: 'remove'
      });
    }

    if (this.isCancellable) {
      actions.push({
        id: SessionActions.Cancel,
        label: `Cancel`,
        class: 'cancel'
      });
    }

    if (this.canSessionBeRescheduled && this.isSessionApproved) {
      actions.push({
        id: SessionActions.Reschedule,
        label: `Ask to reschedule`,
        class: 'reschedule'
      });
    }

    if (this.canSessionBeRescheduled && this.isSessionPending) {
      actions.push({
        id: SessionActions.Reschedule,
        label: `Reschedule request`,
        class: 'reschedule'
      });
    }

    this.actions = actions;
  }
}
