<div class="booking-cancellation__container" [class._not-modal]="!isModal">
  <!--TITLE-->
  <div class="booking-cancellation__row booking-cancellation__header booking-cancellation__icon">
    <pui-icon size="l" class="icon" svgIcon="pui:check-circle"></pui-icon>
    <h3
      pui-typography="h3"
      i18n="@@bookingCancellationTitleCanceledText"
      data-qa-id="booking-final-cancellation-header">
      This session is canceled
    </h3>
  </div>

  <!--DETAILS-->
  <div class="booking-cancellation__row booking-cancellation__details">
    <app-session-details
      [service]="service$ | async"
      [bookings]="(bookings$ | async) || []"
      [cancellation]="true"></app-session-details>
  </div>

  <!--ACTIONS-->
  <div
    *ngIf="!!isModal"
    class="booking-cancellation__row booking-cancellation__row_small booking-cancellation__actions booking-cancellation__actions_center">
    <button
      pui-button
      i18n="bookingCancellationComplete"
      (click)="complete()"
      data-qa-id="booking-final-cancellation-ok-button">
      OK
    </button>
  </div>
</div>
