<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.session?.user?.photo"
    [text]="notification.details?.session?.user?.name"></pui-avatar>
  <dt class="notification-title">
    <span
      class="client-name"
      (click)="notification.userRole === UserRoles.GUIDE && showClientDetails(notification.details?.session?.user?.id)"
      >{{ notification.details?.session?.user?.name }}</span
    >
    <span class="notification-date">{{ notification.dateCreated | notificationDateFormatter }}</span>
  </dt>
  <dd [ngClass]="sessionClasses[notification.details?.status]">
    <span class="session-status-icon" [ngClass]="sessionClasses[notification.details?.status]"></span>
    <ng-container [ngSwitch]="notification.description">
      <ng-container *ngSwitchCase="'Booked Session'">
        <span class="session-status-text">
          <span i18n="@@autoSessionBook">Booked &ngsp;</span>
          {{ notification.details.session?.name }}
        </span>
      </ng-container>

      <ng-container *ngSwitchCase="'Session request'">
        <span class="session-status-text" *ngIf="notification.details.session?.isSessionFree; else usual">
          <ng-container i18n="@@freeSessionRequest">Free session request</ng-container>
        </span>
        <ng-template #usual>
          <span class="session-status-text" i18n="@@sessionRequest">Session request</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'Session declined'">
        <span class="session-status-text" *ngIf="notification.details.session?.isSessionFree; else usual">
          <ng-container i18n="@@freeSessionDeclined">Free session declined</ng-container>
        </span>
        <ng-template #usual>
          <span class="session-status-text" i18n="@@sessionDeclined">Session declined</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'Session cancelled'">
        <span class="session-status-text" *ngIf="notification.details.session?.isSessionFree; else usual">
          <ng-container i18n="@@freeSessionCancelled">Free session cancelled</ng-container>
        </span>
        <ng-template #usual>
          <span class="session-status-text" i18n="@@sessionCancelled">Session cancelled</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'Session offer'">
        <span class="session-status-text" i18n="@@newSessionOffer">New session offer</span>
      </ng-container>

      <ng-container *ngSwitchCase="'Session offer approved'">
        <span class="session-status-text" *ngIf="notification.details.session?.isSessionFree; else usual">
          <ng-container i18n="@@freeSessionOfferApproved">Free session offer approved</ng-container>
        </span>
        <ng-template #usual>
          <span class="session-status-text" i18n="@@sessionOfferApproved">Session offer approved</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'Session offer declined'">
        <span class="session-status-text" *ngIf="notification.details.session?.isSessionFree; else usual">
          <ng-container i18n="@@freeSessionOfferDeclined">Free session offer declined</ng-container>
        </span>
        <ng-template #usual>
          <span class="session-status-text" i18n="@@sessionOfferDeclined">Session offer declined</span>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'Session auto-approved'">
        <span class="session-status-text">Session booked with {{ notification.details.session?.user.name }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="'Session reschedule'">
        <span i18n="@@sessionNotificationSessionReschedule" class="session-status-text">
          Would like to reschedule Session
          <a [routerLink]="notification.details.session?.templateId | sessionsLandingPageLink">{{
            notification.details.session?.name
          }}</a>
        </span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <span class="session-status-text">
          {{ notification.description | freeSessionOfferFormatter: notification.details.session?.isSessionFree }}
        </span>
      </ng-container>
    </ng-container>
  </dd>
</dl>
