import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { SurveyFormService } from '../../services/survey-form.service';

@Component({
  selector: 'app-quiz-questions',
  templateUrl: './quiz-questions.component.html',
  styleUrls: ['../quiz-basic-info/quiz-basic-info.component.scss', './quiz-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizQuestionsComponent implements OnInit, OnDestroy {
  readonly QuizQuestionType = QuizQuestionType;

  form: FormGroup = this.surveyFormService.quizForm;

  showQuestionTypes = false;
  explanationsEnabled = false;

  get questionsControlAccess(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly surveyFormService: SurveyFormService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.surveyFormService.quiz$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form = this.surveyFormService.quizForm;
      this.changeDetector.markForCheck();
    });
  }

  ngOnInit(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addQuestion(type: QuizQuestionType): void {
    this.questionsControlAccess.push(this.surveyFormService.createQuestion({ question: '', options: null, type }));
  }

  removeQuestion(i: number): void {
    this.questionsControlAccess.removeAt(i);
  }

  removeQuestionOptionAtIndex(questionIndex: number, optionIndex: number): void {
    const options = this.getQuizQuestionOptions(questionIndex);
    options.removeAt(optionIndex);
  }

  getQuizQuestionOptions(questionIndex: number): FormArray {
    const question = this.questionsControlAccess.at(questionIndex);

    return question.get('options') as FormArray;
  }

  handleQuestionOptionKeyUp(
    _event: Event,
    questionFormGroup: FormGroup,
    questionIndex: number,
    sourceOptionIndex: number
  ): void {
    const { options } = questionFormGroup.value;

    if (sourceOptionIndex === options.length - 1) {
      this.getQuizQuestionOptions(questionIndex).push(this.surveyFormService.createQuestionOption());
    }
  }

  toggleQuestionTypes(): void {
    this.showQuestionTypes = !this.showQuestionTypes;
  }

  duplicateQuestion(index: number): void {
    const question = { ...this.form.value.questions[index] };

    if (question.hasOtherOption) {
      question.options = [...question.options];

      question.options.push({ ...question.other, other: true });
    }

    this.questionsControlAccess.push(this.surveyFormService.createQuestion(question));
  }

  markFormAsTouched(): void {
    this.form.markAllAsTouched();
  }

  moveItem(fromIndex: number, toIndex: number) {
    const control = this.questionsControlAccess.controls[fromIndex];

    if (fromIndex > toIndex) {
      this.questionsControlAccess.insert(toIndex, control);
      this.questionsControlAccess.removeAt(fromIndex + 1);
    } else {
      this.questionsControlAccess.removeAt(fromIndex);
      this.questionsControlAccess.insert(toIndex, control);
    }
  }
}
