import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-note-pinned-badge',
  template: `
    <div class="pinned-meta">
      <pui-icon svgIcon="pui:pin"></pui-icon>
      <span i18n="@@noteCardPinnedBadge">Pinned</span>
    </div>
  `,
  styleUrls: ['./note-pinned-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotePinnedBadgeComponent {}
