import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'embedVideo'
})
export class EmbedVideoPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, isAutoplay = true): any {
    let videoUrl = null;

    const youtubeVideoRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const matchYoutube = value.match(youtubeVideoRegExp);

    if (matchYoutube) {
      if (matchYoutube[2].length === 11) {
        videoUrl = `https://youtube.com/embed/${matchYoutube[2]}`;
      }
    } else {
      const vimeoVideoRegexp = /^(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/;
      const matchVimeo = value.match(vimeoVideoRegexp);

      if (matchVimeo && matchVimeo[5]) {
        videoUrl = `https://player.vimeo.com/video/${matchVimeo[5]}${isAutoplay ? '?autoplay=1' : ''}`;
      }
    }

    return videoUrl;
  }
}
