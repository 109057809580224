export const toolbarDefaultModules = {
  container: [
    'bold',
    'italic',
    'underline',
    'strike',
    {
      align: ['', 'center', 'right', 'justify']
    },
    {
      color: []
    }
  ]
};
