import { ActivatedRouteSnapshot } from '@angular/router';

type AllowedProps = keyof Pick<ActivatedRouteSnapshot, 'data' | 'params' | 'queryParams'>;

export function getParentParams(routeSnapshot: ActivatedRouteSnapshot, key: AllowedProps): Record<string, unknown> {
  const params = routeSnapshot[key]; // Get the parameters from the current snapshot

  return { ...params, ...(routeSnapshot.parent ? getParentParams(routeSnapshot.parent, key) : {}) };
}

export function getChildrenParams(routeSnapshot: ActivatedRouteSnapshot, key: AllowedProps): Record<string, unknown> {
  const params = routeSnapshot[key]; // Get the parameters from the current snapshot

  return {
    ...params,
    ...(routeSnapshot.children.length > 0
      ? routeSnapshot.children.reduce(
          (acc, children) => ({
            ...acc,
            ...getChildrenParams(children, key)
          }),
          {}
        )
      : {})
  };
}

export function findRouteKey(routeSnapshot: ActivatedRouteSnapshot, key: AllowedProps): Record<string, unknown> {
  const params = routeSnapshot[key]; // Get the parameters from the current snapshot

  return {
    // Own params
    ...params,
    // Parent params
    ...(routeSnapshot.parent ? getParentParams(routeSnapshot.parent, key) : {}),
    // Children params
    ...(routeSnapshot.children.length > 0
      ? routeSnapshot.children.reduce(
          (acc, children) => ({
            ...acc,
            ...getChildrenParams(children, key)
          }),
          {}
        )
      : {})
  };
}
