import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { IPublicSessionTemplate, PublicSessionTemplate } from '@app/shared/interfaces/services/public-session-template';

@Injectable()
export class PublicSessionsTemplatesApi {
  readonly ENDPOINT = `${config.apiPath}/public/sessions`;

  constructor(private readonly _http: HttpClient) {}

  getTemplateById$(templateId?: number): Observable<PublicSessionTemplate> {
    if (templateId) {
      return this._http
        .get<{ template: IPublicSessionTemplate }>(`${this.ENDPOINT}/${templateId}`)
        .pipe(map(({ template }) => new PublicSessionTemplate(template)));
    }

    return of({} as PublicSessionTemplate);
  }
}
