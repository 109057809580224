import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getNumericValue } from '@app/shared/utils/converters';

export function maxValue(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const priceString = control.value;

    if (priceString) {
      const priceNumeric = getNumericValue(priceString);
      if (!Number.isNaN(priceNumeric) && priceNumeric > max) {
        return { maxvalue: true };
      }
    }

    return null;
  };
}
