// eslint-disable-next-line no-restricted-imports
import { uniq } from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GuideRelationTypes } from '@app/core/users/types';
import { StubViewMode } from '@app/modules/service-scheduling/components/service-scheduling-loading-stub/service-scheduling-stub.component';
import { UserSelectComponent } from '@app/modules/service-scheduling/components/user-select/user-select.component';
import { SelectedParticipantsNoteMessageService } from '@app/modules/service-scheduling/services';
import { OfferGuideApiService } from '@app/modules/service-scheduling/services/offer-guide-api.service';
import { SchedulingStoreService } from '@app/modules/service-scheduling/services/scheduling-store.service';
import { ServiceSchedulingFiltersService } from '@app/modules/service-scheduling/services/service-scheduling-filters.service';
import {
  ScheduleSessionConfig,
  ServiceSchedulingService
} from '@app/modules/service-scheduling/services/service-scheduling.service';
import { GuideOfferEvent } from '@app/modules/service-scheduling/services/types';
import { ServiceSchedulingStep } from '@app/modules/service-scheduling/store/service-scheduling/commons/types';
import { selectAllClients } from '@app/modules/service-scheduling/store/service-scheduling/service-scheduling.selectors';
import { Events } from '@app/modules/service-scheduling/types';
import { isProposeServiceFlow } from '@app/modules/service-scheduling/utils';
import { IS_ADMIN_WORKSPACE } from '@app/modules/workspaces/providers/is-admin-workspace';
import { IS_SOLO_WORKSPACE } from '@app/modules/workspaces/providers/is-solo-workspace';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { guideClientsRefetchAll } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';
import { GuidePackageService } from '@app/screens/guide/guide-packages/guide-package.service';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import {
  GuideProgramClientsService,
  ModuleStatuses
} from '@app/screens/guide/guide-programs/services/guide-program-clients.service';
import { EMAIL_REGEXP } from '@app/shared/constants';
import { SessionType } from '@app/shared/enums/session-type';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';
import { AvailabilityStore } from '@libs/stores/availability/availability.store';
import { Store } from '@ngrx/store';

import { FullDateComponent } from './components/full-date/full-date.component';
import { RecurrenceFormControlService } from './components/recurrence/recurrence-form-control.service';
import { RecurrenceFormComponent } from '@app/modules/service-scheduling/modules/service-form/components/recurrence/recurrence-form.component';

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss'],
  providers: [GuideProgramClientsService, PuiDestroyService, AvailabilityStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceFormComponent implements OnInit, AfterViewInit {
  StubViewMode = StubViewMode;
  @ViewChild(FullDateComponent) dateForm!: FullDateComponent;
  @ViewChild(UserSelectComponent) userSelectForm!: UserSelectComponent;
  @ViewChild(RecurrenceFormComponent) recurrenceForm: RecurrenceFormComponent | undefined;

  readonly showNoteParticipantsMessage$ = new BehaviorSubject(false);
  readonly activeClientsWarningMessage$ = new BehaviorSubject(false);

  clients$: Observable<CRMClient[]> = this.routeService.config$.pipe(
    switchMap(config => {
      // # If a service is child of package or program need to filter clients
      if (config.serviceParent) {
        if (config.serviceParent?.type === GuideServiceTypes.PROGRAM) {
          return this.loadProgramClients$(config);
        }

        if (config.serviceParent?.type === GuideServiceTypes.PACKAGE) {
          return this.loadPackageClients$(config);
        }
      }

      return this.store$.select(selectAllClients);
    })
  );

  blocked$ = new BehaviorSubject(false);
  isInitiated$ = new BehaviorSubject(false);

  isIdle$ = this.guideAvailabilityStore.isIdle$;
  isLoading$ = this.guideAvailabilityStore.isLoading$;

  isRecurring = true;

  isProposeServiceFlow = isProposeServiceFlow;

  form = this.fb.group({
    service: this.fb.control(null, [Validators.required]),
    host: this.fb.control(null, [Validators.required]),
    price: this.fb.control(null, [Validators.required]),
    schedule: this.fb.control(null),
    clients: this.fb.control([], [Validators.required]),
    note: this.fb.control(''),
    guests: this.fb.array([]),
    recurrence: this.fb.control(null),
    inviteToAllRecurring: this.fb.control(false),
    doubleBooking: this.fb.control(false, [Validators.requiredTrue])
  });

  get showPrice(): boolean {
    return isProposeServiceFlow(this.schedulingStoreService.stepsHistory);
  }

  get invalid(): boolean {
    return this.form?.invalid || this.dateForm?.form?.invalid || this.userSelectForm?.form?.invalid;
  }

  get hideClientSelector(): boolean | undefined {
    const options = this.routeService.options$.value;

    return isProposeServiceFlow(this.schedulingStoreService.stepsHistory) || options.hideClientSelectorInServiceForm;
  }

  get isUnavailableDateTime(): boolean {
    return this.form?.controls.schedule.value.available === false;
  }

  private readonly scheduleChanges$ = this.form.controls.schedule.valueChanges.pipe(
    distinctUntilChanged((first, second) => JSON.stringify(first) === JSON.stringify(second)),
    tap(schedule => {
      if (schedule.available) {
        this.form?.get('doubleBooking')?.disable();
      } else {
        this.form?.get('doubleBooking')?.enable();
      }
    })
  );

  readonly inviteToAllRecurring$ = this.scheduleChanges$.pipe(map(schedule => !!schedule.recurringEventId));
  readonly hasRepeat$ = this.scheduleChanges$.pipe(map(schedule => !schedule.isGroupWithoutRecurrent));

  constructor(
    readonly drawerRef: PuiDrawerRef,
    readonly schedulingStoreService: SchedulingStoreService,
    private fb: FormBuilder,
    private readonly store$: Store,
    private readonly cdRef: ChangeDetectorRef,
    public workspacesService: WorkspacesService,
    private guideAvailabilityStore: AvailabilityStore,
    private offerGuideApiService: OfferGuideApiService,
    readonly serviceSchedulingFiltersService: ServiceSchedulingFiltersService,
    @Inject(IS_SOLO_WORKSPACE) readonly isSoloWorkspace$: Observable<boolean>,
    @Inject(IS_ADMIN_WORKSPACE) readonly isWorkspaceAdmin$: Observable<boolean>,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    readonly routeService: ServiceSchedulingService,
    private timezoneStore: UserTimezoneStore,
    private readonly programClients: GuideProgramClientsService,
    private readonly guidePackageService: GuidePackageService,
    private readonly selectedParticipantsNoteMessageService: SelectedParticipantsNoteMessageService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.routeService.config$,
      this.serviceSchedulingFiltersService.filteredServices$,
      this.timezoneStore.timezone$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([config, services, timezone]) => {
        const { service: serviceTemplate } = config;
        const [serviceItem] = services.filter(service => service.id === config.service?.id);

        const service = serviceTemplate || serviceItem;
        const [host] = this.workspacesService.members.filter(member => member.userId === config.predefinedData?.hostId);

        // TODO: PR-5909 Need to choose one name for following field
        const recurrence = service?.recurrence || service?.recurring;

        this.isRecurring = !!recurrence?.count && recurrence?.count > 1;

        if (recurrence && serviceTemplate?.availableSessions !== void 0) {
          recurrence.count = serviceTemplate.availableSessions;
        }

        this.form.patchValue({
          price: service?.price || 0,
          service,
          host: { userId: host?.userId, workspaceId: host?.workspaceId },
          recurrence: recurrence?.count ? recurrence : null,
          schedule: {
            date: config.predefinedData?.date,
            time: config.predefinedData?.time,
            timezone,
            extended: false,
            available: true
          },
          doubleBooking: false
        });
      });

    this.selectedParticipantsNoteMessageService
      .showNoteParticipantsMessage$(this.form.get('clients')?.valueChanges!)
      .pipe(takeUntil(this.destroy$))
      .subscribe(show => {
        this.showNoteParticipantsMessage$.next(show);
      });

    this.setClientsSubscription();
  }

  ngAfterViewInit(): void {
    const isLoaded$ = timer(3000).pipe(map(() => true));

    combineLatest([this.isIdle$, isLoaded$, this.clients$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isIdle, isLoaded, clients]) => {
        const { service } = this.routeService.config$.value;

        if ((isIdle || clients.length || service?.sessionType === SessionType.ROUND_ROBIN) && isLoaded) {
          this.isInitiated$.next(true);
        }
      });
  }

  change(clients: CRMClient[]): void {
    this.form.patchValue({
      clients
    });
  }

  removeGuestAt(index: number): void {
    (this.form.get('guests') as FormArray).removeAt(index);
  }

  addGuest(): void {
    if (this.form.get('guests')?.valid) {
      (this.form.get('guests') as FormArray).push(
        new FormControl('', {
          validators: [
            customValidatorWrapper(Validators.required, `Correct email is required.`),
            customValidatorWrapper(Validators.pattern(EMAIL_REGEXP), `Correct email is required.`)
          ],
          updateOn: 'blur'
        })
      );
    }
  }

  submit(
    {
      schedule,
      service,
      price,
      note,
      clients,
      host,
      recurrence,
      guests,
      inviteToAllRecurring,
      doubleBooking
    } = this.form.getRawValue()
  ): void {
    const isRecurrenceSwitcherEnabled =
      this.recurrenceForm?.recurrenceFormControlService?.recurrenceSwitcherControl.value;

    this.form.updateValueAndValidity();

    if (this.invalid) {
      return;
    }

    this.blocked$.next(true);

    this.markAllFormsAsTouched();

    let recurrentRequestData;

    if (inviteToAllRecurring) {
      recurrentRequestData = { inviteToAllRecurring, recurringEventId: schedule.recurringEventId };
    } else {
      if (!schedule.isGroupWithoutRecurrent && isRecurrenceSwitcherEnabled) {
        recurrentRequestData = {
          ...(recurrence?.count ? { recurrence: RecurrenceFormControlService.adaptForQuery(recurrence) } : {})
        };
      }
    }

    const data: GuideOfferEvent = {
      serviceId: service.id,
      date: schedule.date,
      timezone: schedule.timezone,
      duration: service.duration,
      price: price || 0,
      host,
      note,
      ...recurrentRequestData,
      attendees: ((clients || []) as CRMClient[]).map(client => ({
        id: client.type === 'guideClient' ? client.clientId : client.relationId,
        type: client.type,
        email: client.email,
        name: `${client.firstName} ${client.lastName}`
      })),
      serviceParent: this.routeService.config$?.value?.serviceParent,
      guests: uniq(guests),
      doubleBooking
    };

    of(schedule.isBookingExist)
      .pipe(
        switchMap(isBookingExist => {
          if (isBookingExist) {
            return this.offerGuideApiService.offer$(data, service.name, true);
          }

          return this.offerGuideApiService.offerEvents$(data, service.name, true);
        }),
        catchError(() => {
          this.blocked$.next(false);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(offers => {
        this.blocked$.next(false);
        if ('blockedEvents' in offers && this.isFailedBooking(offers)) {
          this.routeService.openFailedSchedule(GuideServiceTypes.GROUP_SESSION, service, host, clients, offers);
        } else {
          this.drawerRef?.close();
        }

        const needToRefreshContacts = ((clients || []) as CRMClient[]).some(
          ({ type }) => type === GuideRelationTypes.GUIDE_CONTACT
        );
        if (needToRefreshContacts) {
          this.store$.dispatch(guideClientsRefetchAll());
        }
      });
  }

  back(): void {
    const config = this.routeService.config$.value;

    this.schedulingStoreService.stepsHistory$.pipe(take(1), takeUntil(this.destroy$)).subscribe(stepsHistory => {
      switch (stepsHistory[ServiceSchedulingStep.SERVICE_FORM]?.previousStep) {
        case ServiceSchedulingStep.CHOOSE_HOST:
          this.routeService.openChooseHost(config);
          break;
        case ServiceSchedulingStep.PROPOSE_SERVICE_LIST:
          this.routeService.proposeService(config);
          break;
        default:
          this.routeService.scheduleSession(config);
          break;
      }
    });
  }

  private setClientsSubscription(): void {
    combineLatest([this.serviceSchedulingFiltersService.chosenClients$, this.clients$])
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(([chosenClients, clients]) => {
        const clientsId = clients.map(client => client.clientId);
        // Filter chosen clients considering available clients in Session, Package or Program
        const selectedClients = chosenClients.length
          ? chosenClients.filter(chosenClient => clientsId.includes(chosenClient.clientId))
          : [];
        // Show warning If client selector is hidden and no active clients
        this.activeClientsWarningMessage$.next(this.hideClientSelector === true && !selectedClients.length);

        this.form.patchValue({
          clients: selectedClients
        });
      });
  }

  private loadProgramClients$(
    config: ScheduleSessionConfig
  ): Observable<(CRMClient & { moduleStatus: ModuleStatuses })[]> {
    const programId = config.serviceParent?.id;
    const sessionTemplateId = config.service?.id;
    if (programId && sessionTemplateId) {
      return this.programClients.getBySessionTemplate$(programId, sessionTemplateId).pipe(
        map(clients => {
          return clients.filter(item => !item?.moduleStatus || item?.moduleStatus !== ModuleStatuses.SEEN);
        })
      );
    }

    return of([]);
  }

  private loadPackageClients$(config: ScheduleSessionConfig): Observable<CRMClient[]> {
    const packageId = config.serviceParent?.id;
    const sessionTemplateId = config.service?.id;
    if (packageId && sessionTemplateId) {
      return this.guidePackageService.getClientsBySessionTemplate$(packageId, sessionTemplateId);
    }

    return of([]);
  }

  private isFailedBooking(events: Events): boolean {
    return (
      Array.isArray(events.blockedEvents) && Array.isArray(events.blockedEvents[0]) && !!events.blockedEvents[0].length
    );
  }

  private markAllFormsAsTouched(): void {
    Object.values({
      ...this.dateForm?.form?.controls,
      ...this.form.controls
    }).forEach(control => {
      if (control.errors === null) {
        return;
      }

      control.markAsTouched();
    });

    this.userSelectForm?.form.markAsTouched();

    this.cdRef.markForCheck();
    this.dateForm?.cdRef?.markForCheck();
    this.userSelectForm?.cdRef?.markForCheck();
  }
}
