import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class HelpMeFindAGuideCommunicatorService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _startHelp$ = new Subject<void>();

  get startHelp$(): Observable<void> {
    return this._startHelp$.asObservable();
  }

  run(): void {
    this._startHelp$.next();
  }
}
