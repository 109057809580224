import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { POPOVER_COMPONENT_DATA } from '@app/modules/floating-popover/floating-popover.service';
import { FloatingPopoverRef } from '@app/modules/floating-popover/floating-popover-ref';
import { BookingPopupData } from '@app/modules/ui-kit/widgets/booking-popup/booking-popup.component';

export interface BookingPopupNotification {
  state: 'success' | 'warning' | undefined;
  message: string | undefined;
}

@Component({
  selector: 'ui-booking-popup-notification',
  templateUrl: 'notification.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-booking-popup-notification',
    '[class.notification-warning]': 'state === "warning"',
    '[class.notification-success]': 'state === "success"'
  },
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  @Input() state: 'success' | 'warning' | undefined;

  @Input() message: string | undefined;

  icons = {
    success: 'notification:done',
    warning: 'notification:alert'
  };

  constructor(
    @Optional() @Inject(POPOVER_COMPONENT_DATA) private readonly popoverData: BookingPopupNotification,
    @Optional() readonly _popoverRef: FloatingPopoverRef<BookingPopupData>
  ) {
    if (popoverData) {
      this.state = popoverData.state;
      this.message = popoverData.message;
    }
  }
}
