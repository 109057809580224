<app-group-services
  *ngIf="serviceDetails || serviceDraft"
  [services]="[serviceDetails, serviceDraft] | moduleServiceDetailsAdaptor"
  [appearance]="{
    header: false,
    badge: false,
    hostsAvatars: false
  }"
  (click)="detailsClick.emit()"></app-group-services>
<ng-container *ngIf="showRecurringAlert">
  <pui-alert class="pui-space_bottom-6 pui-space_top-2" type="info">
    <pui-icon class="icon" svgIcon="pui:paperclip-1" size="s"></pui-icon>
    <span i18n="@@moduleServiceDetailsRecurringAlert"
      >Recurrence is not currently available in programs. This session can only be booked as a single event.</span
    >
  </pui-alert>
</ng-container>
