import { combineLatest, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { PuiNavigationComponent } from '@awarenow/profi-ui-core';
import { map } from 'rxjs/operators';
import { SidenavModeService } from '@app/modules/sidenav/services/mode.service';
import { IWidgetData, IWidgetService } from '@app/cdk/widgets';
import { INavigationWidget } from '@app/modules/navigation/interfaces/navigation-widget';
import { INavigationEntriesService, NAVIGATION_ENTRIES } from '@app/modules/navigation';

@Injectable()
export class SidenavNavigationSecondaryWidgetService implements IWidgetService<INavigationWidget> {
  view$: Observable<IWidgetData<INavigationWidget>>;

  constructor(
    @Inject(NAVIGATION_ENTRIES) entriesService: INavigationEntriesService,
    readonly mode$: SidenavModeService
  ) {
    this.view$ = combineLatest([
      mode$,
      entriesService.get$('secondary').pipe(
        map(entities => ({
          component: PuiNavigationComponent,
          context: {
            list: entities,
            mode: 'full'
          }
        }))
      )
    ]).pipe(
      map(([mode, data]) => {
        return {
          ...data,
          context: {
            ...data.context,
            mode
          }
        };
      })
    );
  }
}
