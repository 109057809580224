import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingCancellationConfirmationComponent } from './booking-cancellation-confirmation.component';
import { PuiButtonModule, PuiIconModule, PuiInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { SessionDetailsModule } from '@appWidget/modules/booking/common/components/session-details/session-details.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BookingLoaderModule } from '@appWidget/modules/booking/components/booking-loader/booking-loader.module';

@NgModule({
  declarations: [BookingCancellationConfirmationComponent],
  imports: [
    CommonModule,
    PuiTypographyModule,
    PuiButtonModule,
    SessionDetailsModule,
    PuiInputModule,
    ReactiveFormsModule,
    PuiIconModule,
    BookingLoaderModule
  ],
  exports: [BookingCancellationConfirmationComponent]
})
export class BookingCancellationConfirmationModule {}
