import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReCaptchaV3Service } from '@libs/captcha/re-captcha-v3.service';
import { reCaptchaScriptProvider } from '@libs/captcha/greacptcha.provider';
import { RECAPTCHA_V3_SITE_KEY } from '@libs/captcha/tokens';
import { CaptchaInterceptor } from '@libs/captcha/captcha.interceptor';

@NgModule({
  providers: [ReCaptchaV3Service, reCaptchaScriptProvider]
})
export class CaptchaModule {
  static forFeature() {
    return {
      ngModule: CaptchaModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CaptchaInterceptor,
          multi: true
        },
        // TODO Move to environment variables
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdqA8coAAAAAPMaG_XX3aYySy_bzEYEUEcUEvy0' }
      ]
    };
  }
}
