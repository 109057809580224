import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SystemVersionPopupComponent } from '@app/modules/system-version-popup/system-version-popup.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SystemVersionPopupComponent],
  exports: [SystemVersionPopupComponent]
})
export class SystemVersionPopupModule {}
