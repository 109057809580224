<h4 class="title" i18n="@@BlogArticleRelatedArticlesComponentTitle">Related articles</h4>

<div class="related-article" *ngFor="let article of articles" (click)="emitSelect(article.author.namedUrl, article.id)">
  <div class="article-cover-image" [style.backgroundImage]="article?.coverImage | safe: 'styleUrl'"></div>

  <div class="article-title">{{ article?.title }}</div>

  <ng-container *ngIf="isArticleAuthorGuide(article)">
    <app-user-info [user]="article?.author" [photoSize]="28" class="user-info"></app-user-info>
  </ng-container>
</div>
