<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>

  <app-button class="close-btn" className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <div class="content">
    <p class="question">{{ message }}</p>
  </div>
</div>

<div class="modal-footer">
  <app-button className="btn-lite" (click)="cancel()">
    {{ rejectText }}
  </app-button>
  <app-button className="btn-primary btn-lg" (click)="save()">
    {{ confirmText }}
  </app-button>
</div>
