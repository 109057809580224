import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BookingStepsStore } from '@appWidget/modules/booking-root/service/booking-steps-store.service';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StepperItem } from '@appWidget/modules/booking/consts';

type BookingNavigationExtras = Omit<NavigationExtras, 'relativeTo'>;

@Injectable()
export class BookingNavigationService {
  constructor(private router: Router, private route: ActivatedRoute, private bookingStepsStore: BookingStepsStore) {}

  nextStep(extras?: BookingNavigationExtras): void {
    this.navigateToStep(this.bookingStepsStore.nextStep$, extras);
  }

  previousStep(extras?: BookingNavigationExtras): void {
    this.navigateToStep(this.bookingStepsStore.previousStepIndex$, extras);
  }

  navigateToStep(step$: Observable<StepperItem>, extras?: BookingNavigationExtras): void {
    step$.pipe(first()).subscribe(step => {
      this.router.navigate([step.path], {
        ...extras,
        relativeTo: this.route
      });
    });
  }
}
