import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';
import { IUserMembership } from '@app/core/membership/types';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';
import { DatePipe } from '@angular/common';

interface MembershipDialogData {
  membership?: IUserMembership | null;
}

@Component({
  templateUrl: './membership-congratulations-dialog.component.html',
  styleUrls: ['./membership-congratulations-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembershipCongratulationsDialogComponent {
  description = 'description';
  constructor(
    readonly dialogRef: PuiDialogRef,
    @Inject(PUI_DIALOG_DATA) readonly data: MembershipDialogData,
    private readonly timezoneStore: UserTimezoneStore,
    private readonly datePipe: DatePipe
  ) {
    if (!data.membership?.currentPeriodEnd) {
      this.description = `You have successfully subscribed to ${data.membership?.name} plan. You can manage your subscription at any time in your Settings.`;
    } else {
      this.description = `You have successfully subscribed to ${
        data.membership.name
      } plan. The next payment is scheduled for ${this.datePipe.transform(
        data.membership.currentPeriodEnd,
        'MMMM d, yyyy',
        this.timezoneStore.timezone
      )}. You can manage your subscription at any time in your settings.`;
    }
  }

  save(): void {
    this.dialogRef.close('value');
  }
}
