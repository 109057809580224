<ui-selector-multiple
  #selector
  [(ngModel)]="value"
  [multiple]="true"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [required]="required"
  [overlayPanelClassName]="'ui-client-selector-panel'"
  [separatorKeysCodes]="separatorKeysCodes"
  [compareWith]="compareWith"
  [getOptionLabel]="getOptionLabel"
  [emptyListTemplateRef]="noUsers"
  [autocompleteConnectedTo]="autocompleteConnectedTo"
  [getChipLabel]="getBadgeLabel"
  [ariaLabel]="placeholder"
  aria-orientation="vertical"
  (ngModelChange)="selected($event)"
  (opened)="onPanelOpen()"
  (closed)="onPanelClose()"
  (typed)="handleTypeahead($event)"
  (inputTokenEnd)="handleInputEnd($event)">
  <ui-option
    class="client-option"
    *ngFor="let option of users$ | async"
    [value]="option"
    [disabled]="option.disabled"
    [id]="option.id">
    {{ option | userName }}
  </ui-option>
</ui-selector-multiple>

<ng-template #noUsers>
  <div class="no-users">
    <p i18n="@@noUsersInTeam">No users in your team</p>
  </div>
</ng-template>
