import { createAction, props } from '@ngrx/store';
import { ContactViewModel } from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';
import { CustomFieldValue } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';

// Reset
export const reset = createAction('[Clients tags] Reset');
// Update
export const update = createAction('[Clients tags] Update Clients', props<{ clients: ContactViewModel[] }>());
export const updateTags = createAction('[Clients tags] Update tags', props<{ tags: CustomFieldValue[] }>());

// Tag
export const updateClientTags = createAction(
  '[Client tags] Update client tags',
  props<{ tags: CustomFieldValue[]; client: ContactViewModel }>()
);
export const updateClientTagsSuccess = createAction('✅ [Client tags] Update client tags success');
export const updateClientTagsError = createAction('⛔️ [Client tags] Update client tags error');

// Untag
export const untagClient = createAction(
  '[Client tags] Untag client',
  props<{ tags: CustomFieldValue[]; client: ContactViewModel }>()
);
export const untagClientSuccess = createAction('✅ [Client tags] Untag client success');
export const untagClientError = createAction('⛔️ [Client tags] Untag client error');

// Create
export const createTag = createAction('[Client tags] Create tag', props<{ tag: CustomFieldValue }>());
export const createTagSuccess = createAction('✅ [Client tags] Create tag success');
export const createTagError = createAction('⛔️ [Client tags] Create tag error');

// Update
export const clientTagUpdate = createAction(
  '[Client tags] updateClientTag',
  props<{ tagId: number; update: Partial<CustomFieldValue> }>()
);
export const clientTagUpdateSuccess = createAction('✅ [Client tags] updateClientTag success');
export const clientTagUpdateError = createAction('⛔️ [Client tags] updateClientTag error');

// DELETE
export const deleteTag = createAction('[Client tags] Delete tag', props<{ tagId: number }>());
export const deleteTagSuccess = createAction('✅ [Client tags] Delete tag success');
export const deleteTagError = createAction('⛔️ [Client tags] Delete tage error');
