<div class="modal-header">
  <app-button
    class="closeBtn"
    className="close"
    routerLink="/"
    *ngIf="!isAuthenticated"
    (click)="cancel()"></app-button>
  <app-button
    class="closeBtn"
    className="close"
    routerLink="/dashboard"
    *ngIf="isAuthenticated"
    (click)="cancel()"></app-button>
</div>
<img src="/assets/images/gift-ok.svg" alt="ok" class="image" />
<h4 class="modal-title" i18n="@@thankYouForBeingCaring">Thank you for being so caring!</h4>
<div class="modal-body">
  <p i18n="@@recipientWillgetEmail">
    Your recipient will get an email from us with your certificate and the instruction on how to enjoy the gift!
  </p>

  <app-button className="btn-primary btn-md" routerLink="/" *ngIf="!isAuthenticated" (click)="cancel()" i18n="@@okay">
    Okay
  </app-button>
  <app-button
    className="btn-primary btn-md"
    routerLink="/dashboard"
    *ngIf="isAuthenticated"
    (click)="cancel()"
    i18n="@@okay">
    Okay
  </app-button>
</div>
