<ng-container [formGroup]="customizationForm">
  <div class="form-group">
    <label class="top-label" for="button_text" i18n="@@customizationFormButtonText">Button text</label>
    <input type="text" id="button_text" class="form-control" formControlName="text" />
  </div>

  <div class="form-group color-selector-group">
    <label class="side-label" i18n="@@customizationFormButtonBackgroundColor">Button background color</label>
    <div class="color-picker-wrapper">
      <input
        [(colorPicker)]="backgroundColor"
        [style.background]="backgroundColor"
        class="color-picker-input"
        cpPosition="bottom"
        [ngClass]="{ bordered: backgroundColor === '#ffffff' }" />
    </div>
  </div>

  <div class="form-group color-selector-group">
    <label class="side-label" i18n="@@customizationFormButtonTextColor">Button text color</label>
    <div class="color-picker-wrapper">
      <input
        [(colorPicker)]="color"
        [style.background]="color"
        class="color-picker-input"
        cpPosition="bottom"
        [ngClass]="{ bordered: color === '#ffffff' }" />
    </div>
  </div>
</ng-container>
