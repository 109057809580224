import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidenavSlots, ViewHostMap, ViewHostType } from '../types';

@Injectable()
export class ViewHostService<T extends SidenavSlots> extends BehaviorSubject<ViewHostMap<T>> {
  constructor() {
    // @ts-expect-error TS2345
    super(null);
  }

  isHostPresent(host: ViewHostType<T>): boolean {
    const value = this.getValue();

    return !!(value && value[host.name]);
  }

  addHost(host: ViewHostType<T>): void {
    const value = this.getValue();
    this.next({ ...value, [host.name]: host });
  }
}
