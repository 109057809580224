// eslint-disable-next-line max-classes-per-file
import { User } from '@app/shared/interfaces/user';
import { assertNever } from '@app/shared/utils/assertions';
import { platformDateTime } from '@platformStyle/functions/platform-datetime';
import { WithCoverImage } from '@app/base';

export enum BlogArticleStatuses {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export enum BlogAuthorTypes {
  GUIDE = 'guide',
  PLATFORM = 'platform'
}

export enum BlogItemTypes {
  ARTICLE = 'article',
  BUSINESS_ARTICLE = 'business_article',
  BUSINESS_PODCAST = 'business_podcast',
  PODCAST = 'podcast',
  PODCASTS_PACK = 'podcasts_pack',
  VIDEO = 'video',
  WIKI_TERM = 'wiki_term'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IArticleSelection {
  articleId: number;
  authorUrl?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookEvent {
  guide: BlogAuthorGuide;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBlog {
  items: BlogItem[];
  cursors?: IBlogCursors;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBlogCursors {
  articleCursor?: number;
  podcastCursor?: number;
  wikiCursor?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBlogDisqus {
  id: string;
  title?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBlogTag {
  id: number;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBlogArticleUpdate extends WithCoverImage {
  id?: number;
  title: string;
  url: string | null;
  description: string;
  content: string;
  contentRepresentation: string;
  contentText: string;
  tags: number[];
  status?: BlogArticleStatuses;
  isPodcast?: boolean;
  isBusiness?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPersonalBlog extends IBlog {
  author: BlogAuthor;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWikiCategory {
  id: number;
  name: string;
}

export class BlogAuthorPlatform {
  namedUrl: string;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogAuthorTypes.PLATFORM;
  }

  // @ts-expect-error TS7006
  constructor(author) {
    this.namedUrl = author.namedUrl;
  }
}

export class BlogAuthorGuide extends User {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _namedUrlOrId: string;

  fullPhoto: string;
  shortQuote: string;
  specialization: string;
  isBookAllowed?: boolean;
  workspaceId: number;

  get namedUrl(): string {
    return this._namedUrlOrId;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogAuthorTypes.GUIDE;
  }

  // @ts-expect-error TS7006
  constructor(authorDetails) {
    super(authorDetails.id, authorDetails.firstName, authorDetails.lastName, authorDetails.photo);

    this._namedUrlOrId = authorDetails.namedUrl || authorDetails.id.toString();
    this.workspaceId = authorDetails.workspaceId;

    this.shortQuote = authorDetails.shortQuote;
    this.specialization = authorDetails.specialization;
    this.fullPhoto = authorDetails.fullPhoto;

    if (authorDetails.isBookAllowed) {
      this.isBookAllowed = authorDetails.isBookAllowed;
    }
  }
}

abstract class ArticleDate {
  // @ts-expect-error TS2564
  isoDate: string;
  // @ts-expect-error TS2564
  updatedAt: string;

  get date(): string {
    return platformDateTime('DATE', { value: this.isoDate });
  }
}

export class BlogArticle extends ArticleDate {
  id: number;
  title: string;
  url: string;
  description: string;
  coverImage: string;
  content: string;
  tags: number[];
  author: BlogAuthor;
  disqus?: IBlogDisqus;

  // @ts-expect-error TS7006
  constructor(article) {
    super();

    this.id = article.id;
    this.title = article.title;
    this.url = article.url;
    this.description = article.description;
    this.coverImage = article.coverImage;
    this.content = article.content;
    this.isoDate = article.date;
    this.tags = article.tags;
    this.updatedAt = article.updatedAt;

    if (article.disqus) {
      this.disqus = article.disqus;
    }

    this.author = blogAuthorFactory(article.author);
  }
}

export class BlogItemArticle extends ArticleDate {
  id: number;
  title: string;
  url: string;
  description: string;
  coverImage: string;
  author?: BlogAuthor;
  tags: IBlogTag[];
  disqus?: IBlogDisqus;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.ARTICLE;
  }

  // @ts-expect-error TS7006
  constructor(article) {
    super();

    this.id = article.id;
    this.title = article.title;
    this.url = article.url;
    this.description = article.description;
    this.coverImage = article.coverImage;
    this.isoDate = article.date;
    this.tags = article.tags;

    if (article.disqus) {
      this.disqus = article.disqus;
    }

    if (article.author) {
      this.author = blogAuthorFactory(article.author);
    }
  }
}

export class BlogItemPodcast extends ArticleDate {
  id: number;
  title: string;
  url: string;
  coverImage: string;
  duration?: number;
  index?: number;
  author?: BlogAuthor;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.PODCAST;
  }

  // @ts-expect-error TS7006
  constructor(podcast) {
    super();

    this.id = podcast.id;
    this.title = podcast.title;
    this.url = podcast.url;
    this.isoDate = podcast.date;
    this.coverImage = podcast.coverImage;

    if (podcast.duration) {
      this.duration = podcast.duration;
    }

    if (podcast.index) {
      this.index = podcast.index;
    }

    this.author = blogAuthorFactory(podcast.author);
  }
}

export class BlogItemPodcastsPack {
  podcasts: BlogItemPodcast[];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.PODCASTS_PACK;
  }

  // @ts-expect-error TS7006
  constructor(podcastsPack) {
    // @ts-expect-error TS7006
    this.podcasts = podcastsPack.podcasts.map(item => new BlogItemPodcast(item));
  }
}

export class BlogItemVideo {
  url: string;
  author?: BlogAuthor;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.VIDEO;
  }

  // @ts-expect-error TS7006
  constructor(video) {
    this.url = video.url;

    if (video.author) {
      this.author = blogAuthorFactory(video.author);
    }
  }
}

export class BlogItemWiki {
  id: number;
  name: string;
  url: string;
  description: string;
  category: IWikiCategory;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.WIKI_TERM;
  }

  // @ts-expect-error TS7006
  constructor(wikiTerm) {
    this.id = wikiTerm.id;
    this.name = wikiTerm.name;
    this.url = wikiTerm.url;
    this.description = wikiTerm.description;
    this.category = wikiTerm.category;
  }
}

export class BusinessBlogItemArticle extends BlogItemArticle {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.BUSINESS_ARTICLE;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(article) {
    super(article);
  }
}

export class BusinessBlogItemPodcast extends BlogItemPodcast {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return BlogItemTypes.BUSINESS_PODCAST;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(podcast) {
    super(podcast);
  }
}

export class RecommendedArticle extends ArticleDate {
  id: number;
  title: string;
  url: string;
  coverImage: string;
  author: BlogAuthor;

  // @ts-expect-error TS7006
  constructor(article) {
    super();

    this.id = article.id;
    this.title = article.title;
    this.url = article.url;
    this.coverImage = article.coverImage;
    this.isoDate = article.date;

    this.author = blogAuthorFactory(article.author);
  }
}

export type BlogItem = BlogItemArticle | BlogItemPodcast | BlogItemPodcastsPack | BlogItemVideo | BlogItemWiki;

export type BlogAuthor = BlogAuthorPlatform | BlogAuthorGuide;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function blogItemsFactory(items: { type: BlogItemTypes }[]) {
  return items.map(item => {
    switch (item.type) {
      case BlogItemTypes.ARTICLE:
      case BlogItemTypes.BUSINESS_ARTICLE:
        return new BlogItemArticle(item);
      case BlogItemTypes.BUSINESS_PODCAST:
      case BlogItemTypes.PODCAST:
        return new BlogItemPodcast(item);
      case BlogItemTypes.PODCASTS_PACK:
        return new BlogItemPodcastsPack(item);
      case BlogItemTypes.VIDEO:
        return new BlogItemVideo(item);
      case BlogItemTypes.WIKI_TERM:
        return new BlogItemWiki(item);
      default:
        return assertNever(item);
    }
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function regularAndBusinessBlogItemsFactory(items: { type: BlogItemTypes }[]) {
  return items.map(item => {
    switch (item.type) {
      case BlogItemTypes.ARTICLE:
        return new BlogItemArticle(item);
      case BlogItemTypes.BUSINESS_ARTICLE:
        return new BusinessBlogItemArticle(item);
      case BlogItemTypes.BUSINESS_PODCAST:
        return new BusinessBlogItemPodcast(item);
      case BlogItemTypes.PODCAST:
        return new BlogItemPodcast(item);
      case BlogItemTypes.PODCASTS_PACK:
        return new BlogItemPodcastsPack(item);
      case BlogItemTypes.VIDEO:
        return new BlogItemVideo(item);
      case BlogItemTypes.WIKI_TERM:
        return new BlogItemWiki(item);
      default:
        return assertNever(item);
    }
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function blogAuthorFactory(author: { type: BlogAuthorTypes }) {
  switch (author.type) {
    case BlogAuthorTypes.PLATFORM:
      return new BlogAuthorPlatform(author);
    case BlogAuthorTypes.GUIDE:
      return new BlogAuthorGuide(author);
    default:
      return assertNever(author);
  }
}
