import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Frequency } from '@app/screens/guide/guide-sessions-templates/types';

import { Recurrence } from './recurrence-form.component';

interface FrequencyOption {
  label: string;
  value: Frequency;
}

@Injectable()
export class RecurrenceFormControlService extends FormGroup implements OnDestroy {
  // TODO: refactor it
  static adaptForQuery(recurrence: Recurrence): {
    type: string;
    value: {
      byday?: number[] | undefined;
      freq: number;
      interval: number;
      count: number;
    };
  }[] {
    return [
      {
        type: 'rrule',
        value: {
          ...recurrence,
          /**
           * TODO oleg.m@profi.io & andrey.s@profi.io
           * Требуется доработать Backend приложение так чтобы
           * оно могло принимать кастомный frequency параметр
           */
          freq: Number(recurrence?.freq) === Frequency.WEEKDAY ? Frequency.WEEKLY : recurrence.freq,
          byday: Number(recurrence?.freq) === Frequency.WEEKDAY ? [1, 2, 3, 4, 5] : undefined
        }
      }
    ];
  }

  static readonly RECURRENCE_FREQUENCIES: FrequencyOption[] = [
    {
      label: 'day',
      value: Frequency.DAILY as number
    },
    {
      label: 'weekday',
      value: Frequency.WEEKDAY as number
    },
    {
      label: 'week',
      value: Frequency.WEEKLY as number
    },
    {
      label: 'month',
      value: Frequency.MONTHLY as number
    },
    {
      label: 'year',
      value: Frequency.YEARLY as number
    }
  ];

  readonly recurrenceSwitcherControl = new FormControl(false);
  readonly recurrenceFrequencies = RecurrenceFormControlService.RECURRENCE_FREQUENCIES;

  private readonly destroy$ = new Subject<void>();

  constructor() {
    super({
      count: new FormControl(1, [Validators.required]),
      interval: new FormControl(1, [Validators.required]),
      freq: new FormControl(RecurrenceFormControlService.RECURRENCE_FREQUENCIES[0].value, [Validators.required])
    });

    // Reset value if turn off
    this.recurrenceSwitcherControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.patchValue(this.value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
