import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { buildUsersGuideMapKey } from '@app/core/users/utils';
import config from '../config/config';
import { OnlineStatusService } from '../status/online-status.service';
import { RelatedUsers } from './related-users';
import { ClientGuide } from './types';

@Injectable()
export class ClientGuidesService extends RelatedUsers<string, ClientGuide> {
  constructor(_http: HttpClient, _notifications: NotificationsService, _onlineStatuses: OnlineStatusService) {
    super(`${config.apiPath}/user/client/guides`, _http, _notifications, _onlineStatuses);
  }

  protected loadUser$(id: string): Observable<ClientGuide> {
    if (!id) {
      // @ts-expect-error TS2322
      return of(null);
    }

    // @ts-expect-error TS2322
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(`${this.ENDPOINT}/${id}`).pipe(
      map(({ guide }) => (guide ? new ClientGuide(guide) : null)),
      tap(guide => {
        if (guide) {
          this._users.set(buildUsersGuideMapKey({ id: guide.id, workspaceId: guide.workspaceId }), guide);
          this.fireUsersUpdated();
        }
      }),
      tap(() => this._refreshUsersOnlineStatuses$.next())
    );
  }

  protected getUserIdsToTrackOnlineStatus(): number[] {
    return [...this._users.keys()].map(key => Number(key.split('_')[0]));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected mapToUsers(serverResponse) {
    const clientGuidesDict = {};

    // @ts-expect-error TS7006
    serverResponse.guides.forEach(guide => {
      // @ts-expect-error TS7053
      clientGuidesDict[guide.id] = clientGuidesDict[guide.id] ? clientGuidesDict[guide.id] + 1 : 1;
    });

    // @ts-expect-error TS7006
    serverResponse.guides.forEach(guide => {
      // @ts-expect-error TS7053
      guide.workspaceName = clientGuidesDict[guide.id] > 1 ? guide.workspaceName : null;
    });

    return serverResponse.guides;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected prepareHttpParamsObjectForUsersLoader(ids: string[]) {
    return {
      ids: ids.map(id => id.split('_')[0])
    };
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected updateUsers(serverUsers) {
    // @ts-expect-error TS7006
    serverUsers.forEach(serverUser => {
      const key = buildUsersGuideMapKey({ id: serverUser.id, workspaceId: serverUser.workspaceId });
      this._users.set(key, new ClientGuide({ ...serverUser, key }));
    });
    this.fireUsersUpdated();
  }
}
