<div class="wrapper" [routerLink]="['/', guideRoute, 'surveys', quiz.id, 'show-stats']">
  <div class="content">
    <div class="wrap">
      <div class="image" [ngStyle]="{ 'background-image': 'url(' + quiz.basic.avatar + ')' }"></div>
      <div class="name">{{ quiz.basic.quizName | cutString: 60 }}</div>
    </div>
  </div>

  <div class="footer">
    <a
      (click)="$event.preventDefault(); $event.stopPropagation(); sendToClient.emit(quiz.id)"
      class="send-client-link"
      i18n="@@sendToClient">
      Send to client
    </a>
    <app-edit-link (click)="$event.preventDefault(); $event.stopPropagation()">
      <a class="action-btn edit-btn" [routerLink]="['/', guideRoute, 'surveys', quiz.id, 'settings']" i18n="@@edit">
        Edit
      </a>
      <a
        (click)="$event.preventDefault(); $event.stopPropagation(); duplicate.emit(quiz.id)"
        class="action-btn duplicate-btn"
        i18n="@@duplicate">
        Duplicate
      </a>
      <a
        (click)="$event.preventDefault(); $event.stopPropagation(); removeQuiz.emit(quiz.id)"
        class="action-btn delete-btn"
        i18n="@@delete">
        Delete
      </a>
    </app-edit-link>
  </div>
</div>
