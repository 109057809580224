<div
  [routerLink]="programLink"
  [ngClass]="{ program: isCommunity }"
  class="program-card"
  [attr.data-qa-id]="'program-item-card-' + program?.id">
  <div class="program-header">
    <div class="program-info auto-scale">
      <h6 [attr.data-qa-id]="'program-item-program-name-title-' + program?.id" class="program-name">
        {{ program?.name }}
      </h6>
      <div [attr.data-qa-id]="'program-item-program-description-text-' + program?.id" class="program-description">
        {{ program?.description }}
      </div>
      <div *ngIf="isNotActiveSubscription" class="program-not-active">
        <span *ngIf="canceledSubscriptionEndDate" i18n="@@availableUpToDate">
          Available up to {{ canceledSubscriptionEndDate | date: 'd MMM, yyyy' }}
        </span>
        <span *ngIf="program?.subscriptionStatus === SubscriptionStatus.PAST_DUE">
          <ng-container i18n="@@pastDueDate">Past due date</ng-container>
        </span>
      </div>
    </div>
  </div>
  <div class="program-footer">
    <!-- eslint-disable -->
    <div class="program-author" [class.no-start-date]="program?.startDate == null">
      <ng-container i18n="@@by">by</ng-container>
      &nbsp;
      <a class="program-author-link">
        {{ program?.guide.name }}
        <ng-container *ngIf="program.workspace as workspace">({{ workspace.name }})</ng-container>
      </a>
    </div>
    <!-- eslint-enable -->

    <div class="program-start-date" *ngIf="programStartDate">
      {{
        program?.startType === 'user_enrollment' && !program?.enrolled
          ? programEnrolledDateCaption
          : (programStartDate | localDate: 'd MMM, yyyy')
      }}
    </div>
    <div class="program-cover" [style.backgroundImage]="program?.coverImageThumb | safe: 'styleUrl'">
      <div class="program-badges">
        <span class="program-badge inverted-color" *ngIf="program?.freeSessionsCount">
          {{ program?.freeSessionsCount }}
          <ng-container i18n="@@sessionsIncludedLowerCase">sessions included</ng-container>
        </span>

        <ng-container *ngIf="!program.hidePrice">
          <strong class="program-badge highlight" *ngIf="program?.isFree" i18n="@@freeLowerCase">free</strong>
          <strong class="program-badge highlight" *ngIf="program?.price && !program?.isSubscription">
            {{ program?.price | platformCurrency }}
          </strong>
          <strong
            class="program-badge highlight"
            *ngIf="program?.subscriptionPrice && (!program?.price || program?.isSubscription)">
            {{ program?.subscriptionPrice | platformCurrency }}
            / {{ subscriptionReqStrings[program?.subscriptionRecurrency] }}
          </strong>
        </ng-container>
      </div>
    </div>

    <div class="program-notifications">
      <div *ngIf="program.enrolled === true">
        <!-- eslint-disable -->

        <div class="program-progress" *ngIf="program?.progress != null">
          <span class="icon progress" [class.complete]="completed"></span>
          <ng-container *ngIf="!completed" i18n="@@program-item.in-progress">
            In progress: {{ program?.progress | percent }}
          </ng-container>
          <ng-container *ngIf="completed" i18n="@@program-item.completed">Completed</ng-container>
        </div>

        <!-- eslint-enable -->
      </div>

      <div *ngIf="program.enrolled === false">
        <div class="program-invite">
          <span class="icon invite"></span>
          <ng-container i18n="@@program-item.not-enrolled-yet">You haven't enrolled yet</ng-container>
        </div>
      </div>

      <div class="program-notification" *ngIf="program?.notification">
        <span class="program-notification-icon">1</span>
        {{ program?.notification?.text }}
      </div>

      <app-action-dropdown *ngIf="program.enrolled === false" (click)="preventBubbling($event)">
        <a (click)="inviteDecline($event)" class="action-btn delete-btn" i18n="@@delete">Delete</a>
      </app-action-dropdown>

      <ng-template *ngIf="program.enrolled === undefined">
        <div class="style-bumper"></div>
      </ng-template>
    </div>
    <div class="invited" *ngIf="program?.invited">
      {{ program?.guide.name }}&nbsp;
      <ng-container i18n="@@invitedYouToEnroll">invited you to enroll</ng-container>
    </div>

    <div class="invited" *ngIf="program?.moduleAvailable" i18n="@@newModuleAvailable">New module is available</div>

    <div class="invited" *ngIf="program?.notStarted" i18n="@@programReadyToStart">Program ready to start</div>
  </div>
</div>
