<pui-layout>
  <pui-header *ngIf="!(isDesktop$ | async)" class="app-bar">
    <pui-app-bar>
      <ng-content select="[app-bar]"></ng-content>
    </pui-app-bar>
  </pui-header>
  <pui-layout>
    <pui-side *ngIf="isDesktop$ | async" class="sider" [class.sider_fullwidth]="true">
      <div class="sider__sticky">
        <div class="sider__content">
          <app-sidenav></app-sidenav>
        </div>
      </div>
    </pui-side>
    <pui-layout class="wrapper">
      <router-outlet></router-outlet>
    </pui-layout>
  </pui-layout>
</pui-layout>
