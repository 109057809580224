<app-module-service-host [user]="instructor">
  <div *ngIf="sessionDateTime">
    {{ sessionDateTime | sessionDateTime }}
  </div>
</app-module-service-host>

<div class="session-status-container">
  <app-button
    className="btn-primary"
    i18n="@@moduleGroupSessionBookSessionBtn"
    *ngIf="!isBooked && scheduleDateTime"
    (click)="book.emit()">
    Book session
  </app-button>
</div>
