import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimezoneFormModule } from '@app/modules/session-forms/forms/timezone-form/timezone-form.module';
import {
  PuiDateModule,
  PuiDatepickerModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputDateModule,
  PuiInputModule,
  PuiInputTimeZoneModule,
  PuiLetModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { SharedModule } from '@libs/shared/shared.module';

import { FullDateComponent } from './full-date.component';

@NgModule({
  declarations: [FullDateComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PuiInputModule,
    TimezoneFormModule,
    PuiDateModule,
    PuiIconModule,
    PuiSelectModule,
    PuiInputDateModule,
    PuiInputTimeZoneModule,
    PuiLetModule,
    PuiTypographyModule,
    PuiDatepickerModule,
    FormsModule,
    PuiToggleModule,
    PuiTooltipModule,
    PuiDropdownModule
  ],
  exports: [FullDateComponent]
})
export class FullDateModule {}
