<ng-container *ngIf="status">
  <ng-container *ngIf="status.icon">
    <app-client-session-status-icon [icon]="status.icon"></app-client-session-status-icon>
  </ng-container>
  <ng-container *ngIf="status.label">
    <app-client-session-status-label
      [attr.data-qa-id]="'session-details-status-label-' + status.label.trim()"
      [label]="status.label"></app-client-session-status-label>
  </ng-container>

  <ng-container *ngIf="status.icon || status.label">
    <ng-content select=".separator"></ng-content>
  </ng-container>
</ng-container>
