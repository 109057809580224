import { IEnvironment } from '@env/enviroment.interface';

export const environment: IEnvironment = {
  NAME: 'local',
  production: false,

  baseUrl: 'http://localhost:4200',
  signalHost: 'http://localhost:8888',
  chatHost: 'http://localhost:8889',
  notificationsHost: 'http://localhost:8890',

  apiHost: 'http://localhost:3000',
  // @ts-expect-error TS2322
  sentryKey: null,

  disqusId: null, // 'awarenow-local',

  bookWidgetBaseHref: '/book',

  guidesRoute: 'coaches',
  guideRoute: 'coach',

  dateTimeLocale: 'en-US',

  membershipRoute: 'subscription',

  enableLinksInMainHeader: true,
  publicEventsRoute: 'public-events',

  /**
   * @description Sets the time format
   * @type {string}
   * @see https://angular.io/api/common/DatePipe#pre-defined-format-options
   */
  timeFormat: 'h:mma',

  featureFlagServiceId: '62391d3c7c57a814b9c0c6ee',

  platformStyle: {
    DAY: 'd',
    WEEK_DAY: 'EEEE',
    MONTH: 'MMM',
    YEAR: 'yyyy',
    TIME: 'HH:mm',
    HUMANIZE_MILLISECONDS: 24 * 60 * 60 * 1000,
    DEFAULT: 'yyyy-MM-dd',

    // custom for 'en-US'
    TIME_WITH_MINUTES: 'h:mma',
    TIME_WITHOUT_MINUTES: 'ha'
  }
};
