import { Pipe, PipeTransform } from '@angular/core';
import { MemberRoleEnum } from '@app/modules/workspaces/types';

const TeamRole: Record<MemberRoleEnum, string> = {
  [MemberRoleEnum.ADMIN]: `Team admin`,
  [MemberRoleEnum.MEMBER]: `Team member`
};

@Pipe({
  name: 'workspaceRoleText'
})
export class WorkspaceRoleTextPipe implements PipeTransform {
  transform(role: MemberRoleEnum): string {
    return TeamRole[role];
  }
}
