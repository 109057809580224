import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { catchError, finalize, map } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import config from '../config/config';

@Injectable()
export class GuidesWikiService {
  private readonly API_ENDPOINT = `${config.apiPath}/public/wiki/for-guides`;

  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGuideWiki$(params: any = {}): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<{ wiki: any }>(this.API_ENDPOINT, { params: httpParams }).pipe(
      map(response => response.wiki),
      catchError(error => {
        const message = `Cannot load wiki`;
        this._notifications.error(message);
        return throwError(error);
      })
    );
  }
}
