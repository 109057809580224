// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocalStorageService } from '@app/core';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { isChoiceQuestion } from '@app/screens/client/client-programs/utils';
import {
  IClientQuestionAnswer,
  isMultipleChoiceQuestion,
  isQuizChoiceQuestion,
  isSingleChoiceQuestion,
  QuizModule,
  QuizQuestion,
  QuizStatuses
} from '@app/shared/interfaces/programs/client-programs';

@Component({
  selector: 'app-quiz-module',
  templateUrl: './quiz-module.component.html',
  styleUrls: ['./quiz-module.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'quiz-module'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizModuleComponent implements OnDestroy {
  allTextAnswersEmpty = true;
  @HostBinding('class._canFinishQuiz')
  canFinishQuiz = false;
  @HostBinding('class._canRetryQuiz')
  canRetryQuiz = false;
  @HostBinding('class._canSubmitQuiz')
  canSubmitQuiz = false;
  isSubmitted = false;
  quizForm!: FormGroup;
  private destroy$ = new Subject();
  private module!: QuizModule;
  private quizFormSub: Subscription | null = null;

  @Input('module')
  set setModule(value: QuizModule) {
    this.quizFormSub?.unsubscribe();
    this.quizFormSub = null;

    if (!isEqual(value, this.module)) {
      this.module = value;
      this.setQuizForm(value);
      this.setFlags();
    }
  }

  @Output()
  finishQuiz = new EventEmitter<{ moduleId: number }>();

  @Output()
  nextModule = new EventEmitter();

  @Output()
  retryQuiz = new EventEmitter<{ moduleId: number }>();

  @Output()
  submitQuiz = new EventEmitter<{ moduleId: number; answers: IClientQuestionAnswer[] }>();

  get notEditableAnswers(): boolean {
    return this.canRetryQuiz || this.module.quiz.status === QuizStatuses.COMPLETED;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  emitSubmitQuiz(): void {
    if (this.allTextAnswersEmpty) {
      return;
    }
    const submitData = this.getSubmitData();

    if (submitData.answers.length) {
      this.isSubmitted = true;
      this.submitQuiz.emit(submitData);
      this.clearLocalStorage();
    }
  }

  private getSubmitData(): { moduleId: number; answers: IClientQuestionAnswer[] } {
    const answers = this.quizForm
      .get('questions')
      // @ts-expect-error TS7031
      ?.value.filter(({ answer }) => !!answer && (!!answer.text || !!answer.options))
      // @ts-expect-error TS7031
      .map(({ id: questionId, answer: { text, options, fileInfo } }) => ({
        questionId,
        fileInfo,
        // @ts-expect-error TS7031
        options: options && options.length ? options.map(({ id }) => id) : undefined,
        text
      }));

    return {
      moduleId: this.module.id,
      answers
    };
  }

  private clearLocalStorage(): void {
    this.localStorageService.removeItem(this.getLocalStorageKey());
  }

  private loadDataFromLocalStorage(): { questions: QuizQuestion[] } | null {
    const id = this.getLocalStorageKey();
    const value = this.localStorageService.getItem(id);

    return value ? JSON.parse(value) : null;
  }

  saveChangesToLocalStorage(): void {
    this.localStorageService.setItem(this.getLocalStorageKey(), JSON.stringify(this.quizForm.value));
  }

  private getLocalStorageKey(): string {
    return this.module.id ? `quiz_module_${this.module.id}` : '';
  }

  private setQuizForm(value?: QuizModule): void {
    const questions = value?.quiz?.questions || [];

    if (this.quizForm) {
      this.quizForm.setValue({ questions }, { emitEvent: false });
      const hasQuestionsRequiresAnswer = questions.some(question => question.type !== QuizQuestionType.TEXT);

      if (!hasQuestionsRequiresAnswer && this.module.quiz.status !== QuizStatuses.COMPLETED) {
        this.submitQuiz.emit({
          moduleId: this.module.id,
          answers: []
        });
      }
    } else {
      this.quizForm = this.formBuilder.group({
        questions: [questions]
      });
    }
    this.quizForm
      ?.get('questions')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((quizQuestions: QuizQuestion[]) => {
        this.allTextAnswersEmpty = quizQuestions
          .filter(({ type }) =>
            [
              QuizQuestionType.LONG_ANSWER,
              QuizQuestionType.SHORT_ANSWER,
              QuizQuestionType.FILE_UPLOAD,
              QuizQuestionType.MULTIPLE_CHOICE,
              QuizQuestionType.SINGLE_CHOICE,
              QuizQuestionType.SIGNATURE,
              QuizQuestionType.QUIZ
            ].includes(type)
          )
          .every(({ type, answer }) => {
            if (isChoiceQuestion(type)) {
              // @ts-expect-error TS2533
              return !answer.options?.length;
            }
            // @ts-expect-error TS2533
            return !answer.text;
          });
        this.isSubmitted = false;

        this.cdr.markForCheck();
      });

    const localQuestions = this.loadDataFromLocalStorage();

    if (localQuestions) {
      const localQuestionsMap =
        localQuestions?.questions.reduce<Record<number, QuizQuestion>>((map, question: QuizQuestion) => {
          if (question.answer) {
            question.answer.dirty = true;
          }
          map[question.id] = question;

          return map;
        }, {}) ?? {};

      const mappedLocalQuestions = (this.module.quiz.questions || []).map(question => {
        if (localQuestionsMap[question.id]) {
          return localQuestionsMap[question.id];
        }
        return question;
      });

      this.quizForm.setValue({ questions: mappedLocalQuestions }, { emitEvent: false });
    }

    if (!this.quizFormSub && this.module.quiz.status === QuizStatuses.ACTIVE) {
      this.quizFormSub = this.quizForm.valueChanges
        .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
        .subscribe(() => {
          this.saveChangesToLocalStorage();
        });
    }
  }

  private setFlags(): void {
    const questions = this.module.quiz.questions || [];
    const answersCount = questions.reduce((count, { answer }) => count + (answer ? 1 : 0), 0);
    const isIncompleteQuizWithOptions =
      (questions.some(isMultipleChoiceQuestion) ||
        questions.some(isSingleChoiceQuestion) ||
        questions.some(isQuizChoiceQuestion)) &&
      (!this.module.quiz.status || this.module.quiz.status !== QuizStatuses.COMPLETED);

    const filteredQuestions = questions.filter(question => question.type !== QuizQuestionType.TEXT);
    this.canFinishQuiz = isIncompleteQuizWithOptions ? filteredQuestions.length === answersCount : false;
    this.canRetryQuiz = isIncompleteQuizWithOptions ? answersCount > 0 : false;
    this.canSubmitQuiz = filteredQuestions.length > answersCount;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.quizFormSub) {
      this.quizFormSub.unsubscribe();
    }
  }
}
