import { Inject, Injectable, OnDestroy } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { AuthService } from '@app/core/auth/services/auth.service';
import { filter, mapTo, takeUntil } from 'rxjs/operators';
import { UserRoles } from '@app/shared/enums/user-roles';
import { SocketService } from '@app/core/socket/socket.service';
import { SIMPLE_ID } from '@app/core/simple-id';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IClientNoteEvent {
  serviceId: symbol | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IClientNoteCreateEvent extends IClientNoteEvent {
  noteId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IClientNoteUpdateEvent extends IClientNoteEvent {
  noteId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IClientNoteDeleteEvent extends IClientNoteEvent {
  noteId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IClientNotesShareEvent extends IClientNoteEvent {
  noteIds: number[];
}

@Injectable({
  providedIn: 'root'
})
export class ClientNotesService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _deAuthenticate$ = new Subject();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientNoteUpdate$ = new Subject<IClientNoteUpdateEvent>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientNoteCreate$ = new Subject<IClientNoteCreateEvent>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientNoteDelete$ = new Subject<IClientNoteDeleteEvent>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _grantAccess$ = new Subject<IClientNotesShareEvent>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _revokeAccess$ = new Subject<IClientNotesShareEvent>();
  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get clientNotesUpdate$() {
    return this._clientNoteUpdate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get clientNoteCreate$() {
    return this._clientNoteCreate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get clientNoteDelete$() {
    return this._clientNoteDelete$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get grantAccess$() {
    return this._grantAccess$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get revokeAccess$() {
    return this._revokeAccess$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get clientNotesListChange$() {
    return merge(
      this.clientNoteDelete$,
      this.clientNoteCreate$,
      this.clientNotesUpdate$,
      this.grantAccess$,
      this.revokeAccess$
    ).pipe(mapTo(true));
  }

  // @ts-expect-error TS7006
  constructor(private readonly _auth: AuthService, private _socket: SocketService, @Inject(SIMPLE_ID) private _token) {
    _auth
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (!user || user.RoleId !== UserRoles.CLIENT) {
          this._deAuthenticate$.next();
          return;
        }

        _socket
          .onUserNotesCreate()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteId }) => {
            this.createNote({ serviceId: null, noteId });
          });

        _socket
          .onUserNotesDelete()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteId }) => {
            this.deleteNote({ serviceId: null, noteId });
          });

        _socket
          .onUserNotesUpdate()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteId }) => {
            this.updateNote({ serviceId: null, noteId });
          });

        _socket
          .onUserNotesGrantAccess()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteIds }) => {
            this.grantAccess({ serviceId: null, noteIds });
          });

        _socket
          .onUserNotesRevokeAccess()
          .pipe(
            filter(({ token }) => token !== _token),
            takeUntil(this._deAuthenticate$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(({ noteIds }) => {
            this.revokeAccess({ serviceId: null, noteIds });
          });
      });
  }

  ngOnDestroy(): void {
    this._deAuthenticate$.next();
    this._deAuthenticate$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  createNote(event: IClientNoteCreateEvent): void {
    this._clientNoteCreate$.next(event);
  }

  deleteNote(event: IClientNoteDeleteEvent): void {
    this._clientNoteDelete$.next(event);
  }

  updateNote(event: IClientNoteUpdateEvent): void {
    this._clientNoteUpdate$.next(event);
  }

  grantAccess(event: IClientNotesShareEvent): void {
    this._grantAccess$.next(event);
  }

  revokeAccess(event: IClientNotesShareEvent): void {
    this._revokeAccess$.next(event);
  }
}
