import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@app/core/auth/services';
import { isPlatformBrowser } from '@angular/common';
import { UserRoles } from '@app/shared/enums/user-roles';
import { environment } from '@env/environment';
import { take, switchMap } from 'rxjs/operators';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';

@Injectable({
  providedIn: 'root'
})
export class CheckTokenInterceptor implements HttpInterceptor {
  isBrowser: boolean;

  constructor(
    private _injector: Injector,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private _platform: any,
    @Inject(GLOBAL_WINDOW) private _window: Window
  ) {
    this.isBrowser = isPlatformBrowser(_platform);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this._injector.get(AuthService);
    const token = this._getToken(authService);
    const checkTokenUrl = `${environment.apiHost}/v2/auth/token`;

    if (request.url === checkTokenUrl || !token) {
      return next.handle(request);
    }

    const { workspaceId, role } = this._getTokenPayload(token);

    if (role === UserRoles.GUIDE && !workspaceId) {
      const requestOptions = {
        headers: new HttpHeaders({ token })
      };

      const http = this._injector.get(HttpClient);

      return http.get<{ token: string | null }>(checkTokenUrl, requestOptions).pipe(
        take(1),
        switchMap(({ token: newToken }) => {
          if (newToken) {
            authService.removeToken();
            authService.setToken(newToken);
            authService.user.authToken = newToken;
          }
          return next.handle(request);
        })
      );
    }

    return next.handle(request);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _getToken(authService: AuthService) {
    return this.isBrowser ? authService.getTokenFromCookie() : authService.user?.authToken;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _getTokenPayload(token: string) {
    const splitToken = token.split('.');
    return JSON.parse(this._window.atob(splitToken[1]));
  }
}
