import { ProgramGuide, ProgramStartType } from '@app/shared/interfaces/programs';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { User } from '@app/shared/interfaces/user';
import { ProgramStatuses } from '@app/screens/guide/guide-programs/types';
import { SessionTemplateInterface } from '@app/screens/guide/guide-sessions-templates/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramModule {
  title: string;
  order: number;
  moduleType: ModuleTypes;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramTestimonial {
  id: number;
  programId: number;
  clientInfo: string | null;
  text: string | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramFAQuestion {
  question: string;
  answer: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPublicProgram {
  additionalNotes: string | null;
  approaches: string[] | null;
  authorId: number;
  coauthors: User[];
  coverImage: string | null;
  description: string | null;
  enrolled?: boolean;
  enrolledAt?: string | null;
  faq: IProgramFAQuestion[];
  fixedPrice: boolean;
  freeSessionsCount: number | null;
  guide: ProgramGuide;
  hidePrice: boolean;
  id: number;
  isAlreadyEnrolled?: boolean | null;
  isFree: boolean;
  issues: string[] | null;
  keyPoints: string | null;
  languages: string | null;
  length: string | null;
  level: string | null;
  modules: IProgramModule[];
  name: string;
  order: number;
  price: number;
  programType: 'offline' | 'online' | null;
  startDate: string;
  startType: ProgramStartType;
  status: ProgramStatuses;
  subscriptionDeactivated: boolean;
  subscriptionDeactivatedDate: string | null;
  subscriptionPrice: number;
  subscriptionRecurrency: number | null;
  testimonials: IProgramTestimonial[] | null;
  totalPayments: number | null;
  workspaceId: number;
}

export enum ModuleSessionStates {
  NO_STATE = 'no_state',
  CAN_BOOK = 'can_book',
  OFFER = 'offer',
  REQUEST = 'request',
  CONFIRMED = 'confirmed',
  DONE = 'done'
}

// https://profi-io.atlassian.net/browse/PR-7288
export type ProgramSessionTemplateInterface = Omit<
  SessionTemplateInterface,
  | 'coverImage'
  | 'coverImageThumb'
  | 'descriptionRepresentation'
  | 'descriptionMarkup'
  | 'descriptionText'
  | 'price'
  | 'status'
  | 'hidePrice'
  | 'testimonials'
  | 'recurrence'
>;
