import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  transform(url, type: 'styleUrl' | 'url' | null = null) {
    if (!url) {
      return null;
    }
    if (type === 'styleUrl') {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    }
    if (type === 'url') {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
