<div class="row-m0 chat-row" [ngClass]="{ 'no-active-chat': selectedChatId === null }">
  <section class="col-lg-4 chat-selector">
    <app-chats-list
      [chats]="chats"
      [selectedChatId]="selectedChatId"
      (selectChat)="selectChat($event)"></app-chats-list>
  </section>
  <section class="col-lg-8 current-chat">
    <router-outlet></router-outlet>
  </section>
</div>
