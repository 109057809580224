import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { GuideRelation, isAssignedToGuide, isGuideContact } from '@app/core/users/types';
import { FormControl } from '@angular/forms';
import { AuthService } from '@app/core/auth/services';
import { Subject } from 'rxjs';
import { CLIENT_VIEW_TAB_TOKEN } from '@app/modules/guide-client/types/client-view-tab-token';
import { takeUntil } from 'rxjs/operators';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';

export enum GuideClientCardTab {
  DETAILS,
  NOTES,
  HISTORY
}

@Component({
  selector: 'app-guide-client-view',
  templateUrl: './guide-client-view.component.html',
  styleUrls: ['./guide-client-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientViewComponent implements OnDestroy {
  @Input()
  // @ts-expect-error TS2564
  guideRelation: GuideRelation;

  @Input()
  // @ts-expect-error TS2564
  disableAddNewClient: boolean;

  @Output()
  back = new EventEmitter();

  @Output()
  offer = new EventEmitter();

  @Input()
  set selectedTabId(value: GuideClientCardTab) {
    this.tab = value;
  }

  tab: GuideClientCardTab = GuideClientCardTab.DETAILS;

  readonly searchControl: FormControl = new FormControl();

  private readonly destroy$ = new Subject();

  get canShareNotes(): boolean {
    return !isGuideContact(this.guideRelation);
  }

  get isAssigned(): boolean {
    return isAssignedToGuide(this.guideRelation, this.authService.user?.email) || !this.workSpace.isTeamAdmin;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly workSpace: WorkspacesService,
    @Inject(CLIENT_VIEW_TAB_TOKEN) private readonly selectedTab: Subject<GuideClientCardTab>
  ) {
    this.selectedTab
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(tab => {
        this.tab = tab;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
