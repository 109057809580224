import { InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspace } from '@app/modules/workspaces/types';

export const WORKSPACE_TYPE = new InjectionToken<Observable<IWorkspace>>('WORKSPACE_TYPE');

export const workspaceTypeProvider: Provider = {
  provide: WORKSPACE_TYPE,
  useFactory: (workspacesService: WorkspacesService) => () => workspacesService.activeWorkspace$,
  deps: [WorkspacesService]
};
