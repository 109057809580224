import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IGuideService, IServiceBookingOptions, IServiceDetails } from '@app/modules/book-service';
import { ScheduleProxyService } from '@app/modules/book-service/services/schedule-proxy.service';
import { IScheduleGuide, IScheduleOptions, ITimeSlot } from '@app/modules/schedule-boards';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { ServicesDetailsProviderService } from '../../services/services-details-provider.service';
import { WithServiceHost } from '@app/base';

enum ServiceDetailsFormSteps {
  SERVICE_PICKER = 0,
  TIME_PICKER
}

@Component({
  selector: 'app-service-details-form',
  templateUrl: './service-details-form.component.html',
  styleUrls: ['./service-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScheduleProxyService, ServicesDetailsProviderService],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'service-details-form'
  }
})
export class ServiceDetailsFormComponent {
  readonly ServiceDetailsFormSteps = ServiceDetailsFormSteps;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeStep: ServiceDetailsFormSteps | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _canManuallySelectService = true;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guide: IScheduleGuide;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleOptions: { duration?: number; date?: string; timezone?: string };

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _selectedService: IGuideService;

  @Input()
  set options(value: IServiceBookingOptions<IGuideService>) {
    this.refresh(value);
  }

  get activeStep(): number {
    // @ts-expect-error TS2322
    return this._activeStep;
  }

  get canManuallySelectService(): boolean {
    return this._canManuallySelectService;
  }

  get guide(): IScheduleGuide {
    return this._guide;
  }

  get scheduleOptions(): { duration?: number; date?: string; timezone?: string } {
    return this._scheduleOptions;
  }

  get selectedService(): IGuideService {
    return this._selectedService;
  }

  @Output()
  requestMoreOptions: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output()
  serviceDetailsChange: EventEmitter<IServiceDetails<IGuideService>> = new EventEmitter<
    IServiceDetails<IGuideService>
  >();

  constructor(readonly provider: ServicesDetailsProviderService) {}

  backToServices(): void {
    // @ts-expect-error TS2322
    this._selectedService = null;
    this._activeStep = ServiceDetailsFormSteps.SERVICE_PICKER;
  }

  onServiceSelected(service: IGuideService, serviceHost?: WithServiceHost['serviceHost']): void {
    if ([GuideServiceTypes.PACKAGE, GuideServiceTypes.PROGRAM].includes(service.type)) {
      this.serviceDetailsChange.emit({ service });
      return;
    }

    this._selectedService = service;
    this._scheduleOptions = { duration: service.duration };

    this.refreshSchedule(service, serviceHost);
    this._activeStep = ServiceDetailsFormSteps.TIME_PICKER;
  }

  onTimeSelected(time: ITimeSlot): void {
    this.serviceDetailsChange.emit({ date: time.value, service: this._selectedService, serviceHost: time.guide?.id });
  }

  private refresh(serviceOptions: IServiceBookingOptions<IGuideService>): void {
    const { serviceHost, service } = serviceOptions;
    this._guide = serviceOptions.guide;

    if (service) {
      this._canManuallySelectService = false;
      this.onServiceSelected(service, serviceHost);
      return;
    }

    this.provider.refreshServices(this._guide, serviceOptions.splitByWorkspaces);
    this._activeStep = ServiceDetailsFormSteps.SERVICE_PICKER;
  }

  private refreshSchedule(service: IGuideService, serviceHost?: WithServiceHost['serviceHost']): void {
    const scheduleOptions: Readonly<IScheduleOptions> = {
      guide: this._guide,
      type: service.type,
      serviceId: service.id,
      // @ts-expect-error TS2322
      duration: service.type === GuideServiceTypes.SESSION ? service.duration : null,
      requiresHostSelection: service.requiresHostSelection,
      serviceHost: serviceHost || this._guide.id,
      workspaceId: service.workspaceId,
      serviceParent: service.serviceParent
    };

    this.provider.refreshSchedule(scheduleOptions);
  }
}
