import { createAction, props } from '@ngrx/store';
import { ClientTimelineResponse } from '../../types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';

export const resetClientsHistoryState = createAction('[Clients History] Reset all state');
export const resetClientHistoryState = createAction(
  '[Clients History] Reset client history state',
  props<{
    clientId: number;
  }>()
);
export const fetchClientHistory = createAction(
  '[Clients History] Fetch client history',
  props<{ clientId: CRMClient['id'] }>()
);
export const fetchClientHistorySuccess = createAction(
  '[Clients History] ✅ Fetch client history',
  props<{ clientId: number; data: ClientTimelineResponse }>()
);
export const fetchClientHistoryError = createAction(
  '[Clients History] ⛔️ Fetch client history',
  props<{ clientId: number }>()
);
