import { Directive } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingService } from '@app/modules/billing/services';
import { ServicePricingTypes } from '@app/modules/guide-service-editor/types/pricing';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { BillingFormModal } from '@app/modules/billing/modals/billing-form/billing-form.modal';
import { IBillingInfo } from '@app/modules/billing/interfaces';
import { AbstractControl } from '@angular/forms';

@Directive()
abstract class AbsComponentWithGuideBillingValidation {
  protected constructor(
    protected readonly modal: NgbModal,
    protected readonly billing: BillingService,
    protected readonly _platformConfiguration: PlatformConfigurationService
  ) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    billing.requestBillingInfo().subscribe();
  }

  protected validatePaidService(value: ServicePricingTypes, control: AbstractControl): boolean {
    const billingDataRequired: boolean = this._platformConfiguration.billingDataRequired;
    const paidTypes: ServicePricingTypes[] = [ServicePricingTypes.Paid, ServicePricingTypes.Subscription];

    if (paidTypes.includes(value) && billingDataRequired) {
      const billingData: IBillingInfo = this.billing.getBillingValue();
      if (!billingData) {
        this.openBillingModal(control);
        return false;
      }
    }
    return true;
  }

  protected openBillingModal(control: AbstractControl): void {
    let added = false;
    const modal = this.modal.open(BillingFormModal);
    modal.componentInstance.billingDataChange.subscribe((data: IBillingInfo) => {
      this.billing.updateBillingForm(data);
      added = true;
      modal.close();
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    modal.closed.subscribe(() => {
      if (!added) {
        this.returnDefaultPaymentType(control);
      }
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    modal.dismissed.subscribe(() => {
      if (!added) {
        this.returnDefaultPaymentType(control);
      }
    });
  }

  protected returnDefaultPaymentType(control: AbstractControl): void {
    control.patchValue(ServicePricingTypes.Free);
  }
}

export { AbsComponentWithGuideBillingValidation };
