import { HUMANIZE_MILLISECONDS } from '@platformStyle/model/const';

import { Fragment, ResolverOptions, ResolverTypes, Template } from '../model/interfaces';
import convertToDateTime from './convert-to-datetime';
import { toDateTime, toFormat, toFragment } from './converters';

export default function resolve(
  alias: Template | Fragment[],
  resolver: ResolverTypes,
  params?: ResolverOptions
): string {
  const humanize = params && params.templateOptions && params.templateOptions.humanize;

  if (humanize) {
    const date = params && params.value;
    const now = convertToDateTime(Date.now());
    const needHumanize = date && now.valueOf() - date.valueOf() < HUMANIZE_MILLISECONDS;
    if (needHumanize) {
      return humanize(date.valueOf() - now.valueOf());
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let resolveFunction: (...args: any) => string = () => '';

  switch (resolver) {
    case 'toDateTime':
      resolveFunction = toDateTime;
      break;
    case 'toFormat':
      resolveFunction = toFormat;
      break;
  }

  const fragments = Array.isArray(alias) ? alias : toFragment(alias, params);
  return fragments.reduce((acc, fragment) => acc + resolveFunction(fragment, params), '');
}
