import config from '@app/core/config/config';

const ADMIN_ENDPOINT = `${config.apiPath}/user/admin`;

export const CLIENTS_ENDPOINT = `${ADMIN_ENDPOINT}/clients`;
export const COUPONS_ENDPOINT = `${ADMIN_ENDPOINT}/coupons`;
export const DASHBOARD_ENDPOINT = `${ADMIN_ENDPOINT}/dashboard`;
export const EXTERNAL_CLIENTS_ENDPOINT = `${ADMIN_ENDPOINT}/external-clients`;
export const GIFT_COUPONS_ENDPOINT = `${COUPONS_ENDPOINT}/gift-coupons`;
export const GUIDES_ENDPOINT = `${ADMIN_ENDPOINT}/guides`;
export const WORKSPACES_ENDPOINT = `${ADMIN_ENDPOINT}/workspaces`;
export const PROGRAMS_ENDPOINT = `${ADMIN_ENDPOINT}/programs`;
export const SESSIONS_ENDPOINT = `${ADMIN_ENDPOINT}/sessions`;
export const TAGS_ENDPOINT = `${ADMIN_ENDPOINT}/tags`;
export const TAGS_PARENT_REFERENCES = `${TAGS_ENDPOINT}/parentreferences`;
export const TAGS_PROPOSED_ENDPOINT = `${TAGS_ENDPOINT}/proposed`;
export const WIKI_ENDPOINT = `${ADMIN_ENDPOINT}/wiki`;
export const WIKI_CATEGORIES_ENDPOINT = `${WIKI_ENDPOINT}/categories`;
export const WIKI_TERMS_ENDPOINT = `${WIKI_ENDPOINT}/terms`;
export const ADMIN_BRANDING_ENDPOINT = `${ADMIN_ENDPOINT}/branding`;
export const ADMIN_QUALIFICATION_ENDPOINT = `${ADMIN_ENDPOINT}/branding/qualifications`;
export const ADMIN_GLOBAL_CONFIG_ENDPOINT = `${ADMIN_ENDPOINT}/branding/global-config`;
export const ADMIN_SSO_CONFIG_ENDPOINT = `${ADMIN_ENDPOINT}/branding/sso-config`;
export const ADMIN_COMMUNITY_PAGE_CONFIG_ENDPOINT = `${ADMIN_ENDPOINT}/branding/community-config`;
export const ADMIN_MENU_CONFIG_ENDPOINT = `${ADMIN_ENDPOINT}/branding/menu-config`;
export const ADMIN_MEMBERSHIP_ENDPOINT = `${ADMIN_ENDPOINT}/membership`;
export const ADMIN_VERSIONS_ENDPOINT = `${ADMIN_ENDPOINT}/version`;
export const ADMIN_PROFILE_ENDPOINT = `${ADMIN_ENDPOINT}/profile`;
