import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isGroupSession, isSimpleSession, SimpleSession } from '@app/shared/interfaces/session';
import { GuideCalendarEventService, GuideSessionsService } from '../../guide-sessions/services/events';
import { GuideSessionsCommunicationsService } from '../../guide-dashboard/services/guide-sessions-communications.service';

@Injectable()
export class GuideSessionsAdaptorService implements OnDestroy {
  private destroy$ = new Subject();

  constructor(
    private _guideSessions: GuideSessionsService,
    private _calendarEvent: GuideCalendarEventService,
    private _sessionActions: GuideSessionsCommunicationsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSessionActionsListeners(): void {
    this._sessionActions.acceptSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe((session: SimpleSession) => this._guideSessions.acceptRequest(session));

    this._sessionActions.approveReschedulement$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ session }) => isSimpleSession(session) && this._guideSessions.accept(session));

    this._sessionActions.archiveSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ session }) => this._guideSessions.archive(session));

    this._sessionActions.endSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ session }) => this._guideSessions.end(session));

    this._sessionActions.cancelGroupSession$.pipe(takeUntil(this.destroy$)).subscribe(({ session }) => {
      if (isGroupSession(session)) {
        // @ts-expect-error TS2345
        // eslint-disable-next-line rxjs/no-nested-subscribe
        this._calendarEvent.cancelEvent$(session, 'cancel', session.isRecurring).subscribe();
      }
    });

    this._sessionActions.cancelSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ session }) => isSimpleSession(session) && this._guideSessions.decline(session));

    this._sessionActions.rescheduleSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ session }) => this._guideSessions.reschedule(session));
  }
}
