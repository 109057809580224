export enum MetaTagsNames {
  Title = 'title',
  Type = 'type',
  Image = 'image',
  URL = 'url',
  Description = 'description',
  SiteName = 'site_name',
  Locale = 'locale',
  Keywords = 'keywords',
  Video = 'video',
  Audio = 'audio',
  Determiner = 'determiner',
  LocaleAlternate = 'locale:alternate',
  Article = 'article'
}

export enum OpenGraphTags {
  Title = 'og:title',
  Type = 'og:type',
  Image = 'og:image',
  URL = 'og:url',
  Description = 'og:description',
  SiteName = 'og:site_name',
  Locale = 'og:locale',
  Video = 'og:video',
  Audio = 'og:audio',
  Determiner = 'og:determiner',
  LocaleAlternate = 'og:locale:alternate',
  Article = 'og:article'
}

export enum ArticleProperties {
  AUTHOR = 'article:author',
  SECTION = 'article:section',
  TAG = 'article:tag',
  PUBLISHED_TIME = 'article:published_time',
  MODIFIED_TIME = 'article:modified_time',
  PUBLISHER = 'article:publisher',
  EXPIRATION_TIME = 'article:expiration_time'
}

export enum ProgramProperties {
  AUTHOR = 'og:program:author'
}

export enum SessionProperties {
  AUTHOR = 'og:session:author'
}

export const metaTagsNamesArray = [
  MetaTagsNames.Title,
  MetaTagsNames.Type,
  MetaTagsNames.Image,
  MetaTagsNames.URL,
  MetaTagsNames.Description,
  MetaTagsNames.SiteName,
  MetaTagsNames.Locale,
  MetaTagsNames.Keywords,
  MetaTagsNames.Video,
  MetaTagsNames.Audio,
  MetaTagsNames.Determiner,
  MetaTagsNames.LocaleAlternate,
  MetaTagsNames.Article,
  // ArticleProperties
  ArticleProperties.AUTHOR,
  ArticleProperties.SECTION,
  ArticleProperties.TAG,
  ArticleProperties.PUBLISHED_TIME,
  ArticleProperties.MODIFIED_TIME,
  ArticleProperties.PUBLISHER,
  ArticleProperties.EXPIRATION_TIME,
  // ProgramProperties
  ProgramProperties.AUTHOR,
  // SessionProperties
  SessionProperties.AUTHOR
];

export type MetaTagsKeys = MetaTagsNames | ArticleProperties | ProgramProperties | SessionProperties;
export type MetaTagsValues = OpenGraphTags | MetaTagsNames | ArticleProperties | ProgramProperties | SessionProperties;

export const metaTagsMap: {
  [key in MetaTagsKeys]: MetaTagsValues;
} = {
  // OpenGraphTags
  [MetaTagsNames.Title]: OpenGraphTags.Title,
  [MetaTagsNames.Type]: OpenGraphTags.Type,
  [MetaTagsNames.Image]: OpenGraphTags.Image,
  [MetaTagsNames.URL]: OpenGraphTags.URL,
  [MetaTagsNames.Description]: OpenGraphTags.Description,
  [MetaTagsNames.SiteName]: OpenGraphTags.SiteName,
  [MetaTagsNames.Locale]: OpenGraphTags.Locale,
  [MetaTagsNames.Video]: OpenGraphTags.Video,
  [MetaTagsNames.Audio]: OpenGraphTags.Audio,
  [MetaTagsNames.Determiner]: OpenGraphTags.Determiner,
  [MetaTagsNames.LocaleAlternate]: OpenGraphTags.LocaleAlternate,
  [MetaTagsNames.Article]: OpenGraphTags.Article,
  // MetaTagsNames
  [MetaTagsNames.Keywords]: MetaTagsNames.Keywords,
  // ArticleProperties
  [ArticleProperties.AUTHOR]: ArticleProperties.AUTHOR,
  [ArticleProperties.SECTION]: ArticleProperties.SECTION,
  [ArticleProperties.TAG]: ArticleProperties.TAG,
  [ArticleProperties.PUBLISHED_TIME]: ArticleProperties.PUBLISHED_TIME,
  [ArticleProperties.MODIFIED_TIME]: ArticleProperties.MODIFIED_TIME,
  [ArticleProperties.PUBLISHER]: ArticleProperties.PUBLISHER,
  [ArticleProperties.EXPIRATION_TIME]: ArticleProperties.EXPIRATION_TIME,
  // ProgramProperties
  [ProgramProperties.AUTHOR]: ProgramProperties.AUTHOR,
  // SessionProperties
  [SessionProperties.AUTHOR]: SessionProperties.AUTHOR
};
