<div
  class="product-card"
  [ngStyle]="{
    backgroundImage:
      (isMobile$ | async)
        ? 'url(' + product.imageUrlMobile + '), url(' + product.imageUrl + ')'
        : 'url(' + product.imageUrl + ')'
  }">
  <div class="card-content">
    <div class="title">{{ product.title | cutString: 50:'' }}</div>
    <p class="info">{{ product.description }}</p>
    <div class="tags">
      <a
        [routerLink]="['/', guidesRoute, { tags: tag.name }]"
        class="tag"
        *ngFor="let tag of product.tags | stringTags">
        {{ tag.name }}
      </a>
    </div>
  </div>
</div>
