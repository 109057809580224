import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiOption } from './option';

@NgModule({
  declarations: [UiOption],
  imports: [CommonModule, FormsModule],
  exports: [UiOption]
})
export class UiOptionModule {}
