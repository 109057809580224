import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, skip, takeUntil, tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import config from '@app/core/config/config';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SocketService } from '@app/core/socket/socket.service';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProfileType {
  type: GuideProfileTypes;
  name: string;
  description?: string;
}

@Injectable()
export class ProfileTypeService implements OnDestroy {
  private readonly API_ENDPOINT = `${config.apiPath}/user/guide/profile/types`;

  private destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _profileTypes$ = new ReplaySubject<IProfileType[]>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userProfile$ = new BehaviorSubject<{
    profileType: GuideProfileTypes;
    confirmed: boolean;
  } | null>(null);

  private profileTypeServicePublicName = `Public`;

  private profileTypeServicePublicDescr;

  private profileTypeServicePrivateName = `Private`;

  private profileTypeServicePrivateDescr = `Only clients with link can see your page`;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _profileTypesData: IProfileType[];

  get profileTypes$(): Observable<IProfileType[]> {
    return this._profileTypes$.asObservable();
  }

  get userProfile$(): Observable<{ profileType: GuideProfileTypes; confirmed: boolean }> {
    // NOTE: ignore initial null value
    // @ts-expect-error TS2322
    return this._userProfile$.pipe(skip(1));
  }

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _auth: AuthService,
    private _analyticsService: AnalyticsService,
    private _socket: SocketService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.profileTypeServicePublicDescr = `All users can find your profile on ${this._runtimeConfigService.get(
      'platformName'
    )} marketplace`;
    this._profileTypesData = [
      {
        type: GuideProfileTypes.PUBLIC,
        name: this.profileTypeServicePublicName,
        description: this.profileTypeServicePublicDescr
      },
      {
        type: GuideProfileTypes.PRIVATE,
        name: this.profileTypeServicePrivateName,
        description: this.profileTypeServicePrivateDescr
      }
    ];
    this._socket
      .onGuideConfirmation()
      .pipe(takeUntil(this.destroy$))
      // @ts-expect-error TS2345
      .subscribe(confirmed => this._userProfile$.next({ ...this._userProfile$.getValue(), confirmed }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._profileTypes$.complete();
    this._userProfile$.complete();
  }

  refreshTypes(): void {
    this.loadProfileTypesAndUserProfileDetails();
  }

  updateOwnType$(profileType: GuideProfileTypes): Observable<{ profileType: GuideProfileTypes; confirmed: boolean }> {
    return this._http
      .post<{ profileType: GuideProfileTypes; confirmed: boolean }>(this.API_ENDPOINT, {
        profileType
      })
      .pipe(
        tap(userProfile => {
          this._analyticsService.event(InternalEvents.PROFILE_PRIVACY_CHANGED, {
            profileType: userProfile.profileType
          });
          this._auth.addToUser({ profileType: userProfile.profileType });
          this._userProfile$.next(userProfile);
        }),
        catchError(error => {
          this._notifications.error(`Cannot update profile type`);
          this.reEmitUserProfileOnUpdateError();
          return throwError(error);
        })
      );
  }

  private loadProfileTypesAndUserProfileDetails(): void {
    this._http
      .get<{
        profileTypes: IProfileType[];
        userProfile: { profileType: GuideProfileTypes; confirmed: boolean };
      }>(this.API_ENDPOINT)
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot load profile types`);
          return throwError(error);
        })
      )
      .subscribe(({ userProfile }) => {
        this._profileTypes$.next(this._profileTypesData);
        this._userProfile$.next(userProfile);
      });
  }

  private reEmitUserProfileOnUpdateError(): void {
    // @ts-expect-error TS2345
    this._userProfile$.next({ ...this._userProfile$.getValue() });
  }
}
