import { GlobalConfig, ICommunityConfig, IConfigItem } from '@cnf/types';

import { communityConfigManifest } from './community-config-manifest';
import { globalConfigManifest } from './global-config-manifest';

export function getConfigBase(): GlobalConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalConfigBase: GlobalConfig | any = {};

  Object.entries(globalConfigManifest).forEach(([key, value]: [string, IConfigItem]) => {
    globalConfigBase[key] = value.defaultValue;
  });

  return globalConfigBase;
}

export function getCommunityConfigBase(): ICommunityConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const communityPageConfigBase: ICommunityConfig | any = {};
  Object.entries(communityConfigManifest).forEach(([key, value]) => {
    communityPageConfigBase[key] = value.defaultValue;
  });
  return communityPageConfigBase;
}
