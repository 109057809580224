import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { formatDate } from '@angular/common';
import { LocaleService } from '@app/core/locale/locale.service';

function convertToDateTime(date: DateTime | Date | string): DateTime | null {
  let dateTime = null;

  if (date instanceof DateTime) {
    dateTime = date;
  } else if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date);
  } else if (typeof date === 'string') {
    dateTime = DateTime.fromISO(date);
  }

  return dateTime;
}

@Pipe({
  name: 'sessionDate'
})
export class SessionDatePipe implements PipeTransform {
  private dateTimeLocale: string;

  constructor(private _localeService: LocaleService) {
    this.dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  transform(date: DateTime | string | null, withTime?: boolean): string | null {
    if (!date) {
      return null;
    }

    const dateTime = convertToDateTime(date);
    if (!dateTime) {
      return null;
    }

    const dateFormatted = formatDate(dateTime.toISO(), 'EEEE, LLL d, yyyy', this.dateTimeLocale);

    if (withTime) {
      const time = formatDate(dateTime.toISO(), 'shortTime', this.dateTimeLocale).replace(/\s/gi, '').toLowerCase();
      const timeZone = formatDate(dateTime.toISO(), 'ZZZZ', this.dateTimeLocale);
      return `${dateFormatted}, ${time} (${timeZone})`;
    }

    return dateFormatted;
  }
}
