<ui-selector-single
  #selector
  [formControl]="countryControl"
  [placeholder]="placeholder"
  [ariaLabel]="placeholder"
  [autocompleteConnectedTo]="autocompleteConnectedTo"
  [displayWith]="displayWith"
  (selected)="selected.emit($event)"
  (opened)="onPanelOpen()"
  (closed)="onPanelClose()"
  (typed)="onTyped($event)">
  <ui-option class="country-option" *ngFor="let option of filteredCountries()" [value]="option" [id]="option.id">
    {{ option.name }}
  </ui-option>
</ui-selector-single>
