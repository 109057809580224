import { UserRoles } from '@app/shared/enums/user-roles';
import { environment, environment as env } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { VisibilityCondition } from '@app/cdk/conditions';
import { INavigationEntry } from '@app/modules/navigation/interfaces/navigation-entry';
import { INavigationDependenciesService } from '@app/modules/navigation/interfaces/navigation-dependencies-service';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class NavigationEntryFactory {
  constructor(private translocoService: TranslocoService) {}

  // sidenav - primary

  guideWorkspaceStats$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Dashboard') },
      condition: VisibilityCondition.team_workspace_admin,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/workspaces/stats`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideCalendar$({ guideSessions }: INavigationDependenciesService): Observable<INavigationEntry> {
    // @ts-expect-error TS2532
    return guideSessions.requests$.pipe(
      startWith([]),
      map(requests => ({
        item: {
          icon: 'menu:calendar',
          title: this.translocoService.translate('Calendar'),
          badge$: of(!!requests?.length)
        },
        condition: VisibilityCondition.always,
        role: [UserRoles.GUIDE],
        widget: ['primary'],
        route: {
          routerLink: `/${env.guideRoute}/dashboard`,
          routerLinkActive: 'active',
          routerLinkActiveOptions: { exact: false }
        }
      }))
    );
  }

  guideInbox$({ notifier }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:notifications',
        title: this.translocoService.translate('Notifications'),
        // @ts-expect-error TS2532
        badge$: notifier.isNotificationsPresents$
      },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/inbox`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideClients$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:clients', title: this.translocoService.translate('Clients') },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/clients`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideSecureMessaging$({ chatService }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:chats',
        title: this.translocoService.translate('Secure Messaging'),
        // @ts-expect-error TS2532
        badge$: chatService.isNotificationsPresents$
      },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/chat`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: false }
      }
    });
  }

  legacyGuideServices$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:services', title: this.translocoService.translate('Services') },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/services`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideServices$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:services', title: this.translocoService.translate('Services(New)') },
      condition: VisibilityCondition.services,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/_services`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideForms$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:forms', title: this.translocoService.translate('Forms') },
      condition: VisibilityCondition.forms,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/questionnaires`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guidePayments$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:wallet', title: 'Payments' },
      condition: VisibilityCondition.payments,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/wallet`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideBlogs$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:blog', title: this.translocoService.translate('Blogs') },
      condition: VisibilityCondition.blogs,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/blog`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideCommunity$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:community', title: this.translocoService.translate('Community') },
      condition: VisibilityCondition.community,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/community`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideReports$(): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'pui:clipboard',
        title: this.translocoService.translate('Reports')
      },
      condition: VisibilityCondition.reports,
      role: [UserRoles.GUIDE],
      widget: ['primary'],
      route: {
        routerLink: `/${env.guideRoute}/reports`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientSessions$({ sessions }: INavigationDependenciesService): Observable<INavigationEntry> {
    // @ts-expect-error TS2532
    const badge$ = sessions.sessionOffers$.pipe(map(offers => !!offers?.length));

    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Sessions'), badge$ },
      condition: VisibilityCondition.always,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/client/dashboard`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientInbox$({ notifier }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:notifications',
        title: this.translocoService.translate('Notifications'),
        // @ts-expect-error TS2532
        badge$: notifier.isNotificationsPresents$
      },
      condition: VisibilityCondition.always,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/client/inbox`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientForms$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:forms', title: this.translocoService.translate('Forms') },
      condition: VisibilityCondition.forms,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/surveys`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientCommunity$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:community', title: this.translocoService.translate('Community') },
      condition: VisibilityCondition.community,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/community`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientSecureMessaging$({ chatService }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:chats',
        title: this.translocoService.translate('Secure Messaging'),
        // @ts-expect-error TS2532
        badge$: chatService.isNotificationsPresents$
      },
      condition: VisibilityCondition.always,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/client/chat`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientPrograms$({ clientProgramsService }: INavigationDependenciesService): Observable<INavigationEntry> {
    // @ts-expect-error TS2532
    const badge$: Observable<boolean> = clientProgramsService.doesClientHaveUnenrolledPrograms$;

    return of({
      item: { icon: 'menu:courses', title: this.translocoService.translate('Programs'), badge$ },
      condition: VisibilityCondition.programs,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/programs`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  publicEvents$(): Observable<INavigationEntry> {
    // TODO: Public events could be displayed for guides, they have the same icon as calendar, should be reworked
    return of({
      item: { icon: 'menu:calendar', title: this.translocoService.translate('Events') },
      condition: VisibilityCondition.public_events,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/public-events`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientMarketplace$(): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:clients',
        title: this.translocoService.translate('Find your coach')
      },
      condition: VisibilityCondition.marketplace,
      role: [UserRoles.CLIENT],
      widget: ['primary'],
      route: {
        routerLink: `/${environment.guidesRoute}`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  // sidenav - secondary

  whatsNew$({ loopedin }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:info',
        title: this.translocoService.translate(`What's New`),
        badge$: of(!!loopedin?.notificationCount())
      },
      condition: VisibilityCondition.whats_new,
      role: [UserRoles.GUIDE, UserRoles.CLIENT],
      widget: ['secondary'],
      events: {
        click: event => {
          event.preventDefault();
          event.stopPropagation();

          loopedin?.toggle();
        }
      }
    });
  }

  guideAccountAndSettings$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:settings', title: this.translocoService.translate('Settings') },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['secondary'],
      route: {
        routerLink: `/${env.guideRoute}/settings`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: false }
      }
    });
  }

  clientSettings$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:settings', title: this.translocoService.translate('Settings') },
      condition: VisibilityCondition.always,
      role: [UserRoles.CLIENT],
      widget: ['secondary'],
      route: {
        routerLink: `/client/settings`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: false }
      }
    });
  }

  // settings - profile

  guideSettingsMyAccount$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-user-circle', title: this.translocoService.translate('My account') },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['profile'],
      route: {
        routerLink: `/${env.guideRoute}/settings/account`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideSettingsEarnAffiliateRewards$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-gift', title: this.translocoService.translate('Earn affiliate rewards') },
      condition: VisibilityCondition.earn_affiliates,
      role: [UserRoles.GUIDE],
      widget: ['profile'],
      route: {
        routerLink: `/${env.guideRoute}/settings/affiliate-rewards`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  // settings - workspace

  guideSettingsTeamSettings$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-house', title: this.translocoService.translate('Team settings') },
      condition: VisibilityCondition.team_workspace_admin,
      role: [UserRoles.GUIDE],
      widget: ['workspace'],
      route: {
        routerLink: `/${env.guideRoute}/settings/workspace-settings`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideSettingsTeamMembers$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-user', title: this.translocoService.translate('Team members') },
      condition: VisibilityCondition.team_workspace_admin,
      role: [UserRoles.GUIDE],
      widget: ['workspace'],
      route: {
        routerLink: `/${env.guideRoute}/settings/workspace-members`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideTeamPermissions$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:sliders', title: this.translocoService.translate('Permissions') },
      condition: VisibilityCondition.team_workspace_admin,
      role: [UserRoles.GUIDE],
      widget: ['workspace'],
      route: {
        routerLink: `/${env.guideRoute}/settings/workspace-permissions`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  settingsGuidePublicPage$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-edit', title: this.translocoService.translate('Public page') },
      condition: VisibilityCondition.always,
      role: [UserRoles.GUIDE],
      widget: ['workspace'],
      route: {
        routerLink: `/${env.guideRoute}/settings/edit-profile`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  guideSettingsSubscription$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-shopping', title: this.translocoService.translate('Subscription') },
      condition: VisibilityCondition.subscription,
      role: [UserRoles.GUIDE],
      widget: ['workspace'],
      route: {
        routerLink: `/${env.guideRoute}/settings/subscription`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  settingsGuideBranding$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-sliders', title: this.translocoService.translate('Branding') },
      condition: VisibilityCondition.solo_workspace,
      role: [UserRoles.GUIDE],
      widget: ['workspace'],
      route: {
        routerLink: `/${env.guideRoute}/settings/branding`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  // settings - client

  clientSettingsMyAccount$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-user-circle', title: this.translocoService.translate('My account') },
      condition: VisibilityCondition.always,
      role: [UserRoles.CLIENT],
      widget: ['client'],
      route: {
        routerLink: `/client/settings/edit-profile`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientSettingsWallet$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-wallet', title: this.translocoService.translate('Wallet') },
      condition: VisibilityCondition.wallet,
      role: [UserRoles.CLIENT],
      widget: ['client'],
      route: {
        routerLink: `/client/settings/wallet`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  clientSettingsSubscription$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-shopping', title: this.translocoService.translate('Subscription') },
      condition: VisibilityCondition.subscription,
      role: [UserRoles.CLIENT],
      widget: ['client'],
      route: {
        routerLink: `/client/settings/subscription`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  // settings - links

  clientSettingsSignupAsCoach$({ authService }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      // @ts-expect-error TS2769
      item: { icon: null, title: this.translocoService.translate('Sign up as practitioner') },
      condition: VisibilityCondition.can_signup_as_coach,
      role: [UserRoles.GUIDE],
      widget: ['links'],
      events: {
        // eslint-disable-next-line id-length
        click: e => {
          e.preventDefault();
          e.stopPropagation();
          // @ts-expect-error TS2532
          authService.signupAlternativeAccount().subscribe();
        }
      }
    });
  }

  guideSettingsSignupAsClient$({ authService }: INavigationDependenciesService): Observable<INavigationEntry> {
    return of({
      // @ts-expect-error TS2769
      item: { icon: null, title: this.translocoService.translate('Sign up as client') },
      condition: VisibilityCondition.can_signup_as_client,
      role: [UserRoles.CLIENT],
      widget: ['links'],
      events: {
        // eslint-disable-next-line id-length
        click: e => {
          e.preventDefault();
          e.stopPropagation();
          // @ts-expect-error TS2532
          authService.signupAlternativeAccount().subscribe();
        }
      }
    });
  }

  urlVisitHelpCenter$({ branding, window }: INavigationDependenciesService): Observable<INavigationEntry> {
    return branding!.globalConfig$.pipe(
      map(({ urlVisitHelpCenter }) => ({
        item: {
          icon: '',
          title: this.translocoService.translate('Visit help center')
        },
        condition: VisibilityCondition.url_visit_help_center,
        role: [UserRoles.GUIDE],
        widget: ['links'],
        events: {
          click: event => {
            event.preventDefault();
            event.stopPropagation();
            if (window && 'open' in window.window) {
              window.window.open(`${urlVisitHelpCenter}`);
            }
          }
        }
      }))
    );
  }

  urlTerms$({ branding, window }: INavigationDependenciesService): Observable<INavigationEntry> {
    return branding!.globalConfig$.pipe(
      map(({ urlTerms }) => ({
        item: {
          icon: '',
          title: this.translocoService.translate('Terms and conditions')
        },
        condition: VisibilityCondition.url_terms,
        role: [UserRoles.GUIDE, UserRoles.CLIENT],
        widget: ['links'],
        events: {
          click: event => {
            event.preventDefault();
            event.stopPropagation();
            if (window && 'open' in window.window) {
              window?.window.open(`${urlTerms}`);
            }
          }
        }
      }))
    );
  }

  emailHelp$({ branding, window }: INavigationDependenciesService): Observable<INavigationEntry> {
    return branding!.globalConfig$.pipe(
      map(({ emailHelp }) => ({
        item: {
          icon: '',
          title: this.translocoService.translate('Email us')
        },
        condition: VisibilityCondition.email_help,
        role: [UserRoles.GUIDE, UserRoles.CLIENT],
        widget: ['links'],
        events: {
          click: event => {
            event.preventDefault();
            event.stopPropagation();
            if (window && 'open' in window.window) {
              window.window.open(`mailto:${emailHelp}`, '_blank', 'noopener,noreferrer');
            }
          }
        }
      }))
    );
  }

  urlPrivacy$({ branding, window }: INavigationDependenciesService): Observable<INavigationEntry> {
    return branding!.globalConfig$.pipe(
      map(({ urlPrivacy }) => ({
        item: {
          icon: '',
          title: this.translocoService.translate('Privacy policy')
        },
        condition: VisibilityCondition.url_privacy,
        role: [UserRoles.GUIDE, UserRoles.CLIENT],
        widget: ['links'],
        events: {
          click: event => {
            event.preventDefault();
            event.stopPropagation();
            if (window && 'open' in window.window) {
              window.window.open(`${urlPrivacy}`);
            }
          }
        }
      }))
    );
  }

  urlCancellationPolicy$({ branding, window }: INavigationDependenciesService): Observable<INavigationEntry> {
    return branding!.globalConfig$.pipe(
      map(({ urlCancellationPolicy }) => ({
        item: {
          icon: '',
          title: this.translocoService.translate('Cancellation policy')
        },
        condition: VisibilityCondition.url_cancellation_policy,
        role: [UserRoles.GUIDE, UserRoles.CLIENT],
        widget: ['links'],
        events: {
          click: event => {
            event.preventDefault();
            event.stopPropagation();
            if (window && 'open' in window.window) {
              window.window.open(`${urlCancellationPolicy}`);
            }
          }
        }
      }))
    );
  }

  // admin - primary

  adminDashboard$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Dashboard') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/dashboard`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminCoaches$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Coaches') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/${env.guidesRoute}`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminClients$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Clients') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/clients`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminGiftCoupons$(): Observable<INavigationEntry> {
    return of({
      item: {
        icon: 'menu:dashboard',
        title: this.translocoService.translate('Gift coupons')
      },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/gift-coupons`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminSessions$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Sessions') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/sessions`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminTags$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Tags') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/tags`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminWiki$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Wiki') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/wiki`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminBlog$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Blog') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/blog`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminPrograms$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Programs') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/programs`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminDefaultServices$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:grid', title: this.translocoService.translate('Default services') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/default-services`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminDefaultForms$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:file-text', title: this.translocoService.translate('Default forms') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/default-forms`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminCommunity$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:heart', title: `Community` },
      condition: VisibilityCondition.community,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/community`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminCommunitySettings$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:heart', title: this.translocoService.translate('Community settings') },
      condition: VisibilityCondition.community,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/community-settings`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminOtherBlogs$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Other blogs') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/blog`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminBusinessBlog$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Business blog') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/business-blog`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminBranding$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:menu-sliders', title: this.translocoService.translate('Branding') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/branding`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminNotificationsConfig$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:bell', title: this.translocoService.translate('Notifications config') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/notifications-config`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminGlobalConfig$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Global config') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/global-config`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminCommunityConfig$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Landing config') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/community-config`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminHeaderFooterConfig$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Header footer config') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/header-footer-config`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminPages$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Pages') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/pages`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminPlatformSubscriptions$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Platform subscriptions') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/membership`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminPaymentOptions$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Payment options') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/payment-options`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminAccountSettings$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:settings', title: this.translocoService.translate('Account') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/account-settings`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  adminReports$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'pui:download', title: this.translocoService.translate('Reports') },
      condition: VisibilityCondition.always,
      role: [UserRoles.ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/admin/reports`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  // super-admin

  superAdminOrganizations$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Organizations') },
      condition: VisibilityCondition.always,
      role: [UserRoles.SUPER_ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/super-admin/organizations`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }

  superAdminAdministrators$(): Observable<INavigationEntry> {
    return of({
      item: { icon: 'menu:dashboard', title: this.translocoService.translate('Administrators') },
      condition: VisibilityCondition.always,
      role: [UserRoles.SUPER_ADMIN],
      widget: ['primary'],
      route: {
        routerLink: `/super-admin/administrators`,
        routerLinkActive: 'active',
        routerLinkActiveOptions: { exact: true }
      }
    });
  }
}
