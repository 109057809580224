import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BookingStepsStore } from '@appWidget/modules/booking-root/service/booking-steps-store.service';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-booking-stepper',
  templateUrl: './booking-stepper.component.html',
  styleUrls: ['./booking-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingStepperComponent {
  readonly steps$ = this.bookingStepsStore.steps$;
  readonly currentStepIndex$ = this.bookingStepsStore.currentStepIndex$;

  constructor(
    private bookingStepsStore: BookingStepsStore,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>
  ) {}
}
