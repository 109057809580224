// https://github.com/angular/components/blob/master/src/material/icon/icon-module.ts

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { CanSize, mixinSize, ThemeSize } from '../_common/mixins/size';
import { IconStore } from './icon-store';

export function iconInitialize(initService: IconStore) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (): Promise<any> => {
    return initService.init();
  };
}

// Boilerplate for applying mixins to MatIcon.
const _UiIconBase = mixinSize(MatIcon, 'm');

@Component({
  selector: 'ui-icon',
  exportAs: 'uiIcon',
  styleUrls: ['icon.scss'],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['size', 'svgIcon', 'inline'],
  template: `<ng-content></ng-content>`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-icon'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiIcon extends _UiIconBase implements CanSize {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_size: ThemeSize;
}
