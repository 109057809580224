<h5 class="title" i18n="@@pastSessionTitle" *ngIf="!hideHeader">Past sessions</h5>

<ng-template [ngIf]="sessions && sessions.length" [ngIfElse]="noArchiveSessions">
  <app-past-client-session
    *ngFor="let session of sessions | sortOnlySessionDate: 'dateStart':'desc'"
    [clientSession]="session"
    class="user-session with-actions-menu flex-wrap"
    [attr.data-qa-id]="'past-client-session-item-' + session?.session?.name"></app-past-client-session>
</ng-template>

<ng-template #noArchiveSessions>
  <div class="no-sessions" i18n="@@pastClientSessionsEmptyText">Past sessions will show up here</div>
</ng-template>
