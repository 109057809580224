import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientInvitationActivationComponent } from './client-invitation-activation.component';
import { ClientInvitationActivationGuard } from './client-invitation-activation.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [ClientInvitationActivationComponent],
  exports: [ClientInvitationActivationComponent],
  providers: [ClientInvitationActivationGuard]
})
export class ClientInvitationActivationModule {}
