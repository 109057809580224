import { QuizClientsPolicy } from '@app/core/quizzes/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISystemNotification {
  id: number;
  description: string;
  type: SystemNotificationTypes;
  priority: number;
  isRead: boolean;
  dateCreated: string;
  details?: INotificationDetails;
  userRole?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INotificationDetails {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  package?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  program?: any;
  packageId?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionId?: any;
  quizTemplateId?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromGuideId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromClientId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scheduleId?: any;
  status?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authorId?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  note?: any;
  workspaceId?: string | number;
  workspaceName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  host?: any;
  invitationCode?: string;
  notesLink?: string;
  clientId?: number;
  clientName?: string;
  singleNoteAccess?: boolean;
  quizClientsPolicy?: QuizClientsPolicy;
  quizId?: number;
  communityId?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRedirectEvent {
  handlerName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export enum SystemNotificationTypes {
  SESSION_REQUEST = 'session-request',
  SESSION_REQUEST_APPROVEMENT = 'session-request-approvement',
  SESSION_DECLINE = 'session-decline',
  SESSION_CANCEL = 'session-cancel',
  SESSION_GUIDE_OFFER = 'session-guide-offer',
  SESSION_GUIDE_OFFER_APPROVEMENT = 'session-guide-offer-approvement',
  SESSION_GUIDE_OFFER_CANCEL = 'session-guide-offer-cancel',
  SESSION_GUIDE_OFFER_DECLINE = 'session-guide-offer-decline',
  SESSION_RESCHEDULE_APPROVEMENT = 'session-reschedule-approvement',
  SESSION_RESCHEDULE_BY_CLIENT = 'session-reschedule-by-client',
  SESSION_RESCHEDULE_BY_GUIDE = 'session-reschedule-by-guide',
  PROGRAM_INVITE_INFORMATIVE = 'program-invite',
  PROGRAM_INVITE_PREPAID = 'program-invite-prepaid',
  PROGRAM_MODULE_AVAILABLE = 'program-module-available',
  PROGRAM_COMPLETE = 'program_complete',
  PROGRAM_CLIENT_COMPLETE_MODULE = 'program-client-complete-module',
  PACKAGE_INVITE = 'package-invite',
  PACKAGE_ENROLLED = 'package-enrolled',
  PACKAGE_PAID = 'package-paid',
  CLIENT_REGISTERED = 'client-registered',
  WORKSPACE_JOINED_MEMBER = 'workspace-joined-member',
  WORKSPACE_SERVICE_CREATED = 'workspace-service-created',
  WORKSPACE_SERVICE_BOOKED = 'workspace-service-booked',
  WORKSPACE_INVITED = 'workspace-invited',
  WORKSPACE_CLIENT_ASSIGNED = 'workspace-client-assigned',
  WORKSPACE_SERVICE_ASSIGNED = 'workspace-service-assigned',
  WORKSPACE_ADMIN_OFFER_DECLINED_BY_CLIENT = 'workspace-admin-offer-decline-by-client',
  WORKSPACE_OFFER_CANCELED_BY_ADMIN = 'workspace-offer-canceled-by-admin',
  WORKSPACE_SESSION_RESCHEDULED_BY_ADMIN = 'workspace-session-rescheduled-by-admin',
  WORKSPACE_SESSION_RESCHEDULED_BY_GUIDE = 'workspace-session-rescheduled-by-guide',
  WORKSPACE_GUIDE_SESSION_OFFER_CREATED = 'workspace-guide-created-a-session-offer',
  WORKSPACE_ADMIN_SCHEDULED_SESSION_CANCELED_BY_CLIENT = 'workspace-admin-scheduled-session-canceled-by-client',
  WORKSPACE_ADMIN_SCHEDULED_SESSION_ACCEPTED_BY_CLIENT = 'workspace-admin-scheduled-session-accepted-by-client',
  WORKSPACE_SESSION_CANCELED_BY_HOST = 'workspace-session-canceled-by-host',
  NOTE_SHARED_WITH_TEAM_MEMBER = 'note-shared-with-team-member',
  WORKSPACE_SESSION_CANCELED_BY_ADMIN = 'workspace-session-canceled-by-admin',
  COMMUNITY_NEW_POST = 'community-new-post'
}
