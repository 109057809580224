import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { UrlTypes } from '@app/shared/enums/url-types';
import { prepareLogoUrlData } from '@app/shared/utils/url';
import { GlobalConfig } from '@cnf/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  URL_TYPE = UrlTypes;

  config = {
    displayHIPAACompliant: false,
    displayMoneyBackGuarantee: false,
    emailHelp: '',
    facebook: '',
    instagram: '',
    logoUrl: { value: '', type: '' },
    twitter: '',
    urlPrivacy: '#',
    urlTerms: '#'
  };

  curYear = DateTime.local().year;

  mainLogo = '';

  platformName: string;

  showPoweredBy = false;

  get canShowLanguageSelector(): boolean {
    // @ts-expect-error TS2532
    return this._runtimeConfigService.get('availableLanguages').length > 1;
  }

  get displayDisclaimer(): boolean {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('displayDisclaimer');
  }

  get displayPoweredBy(): boolean {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('displayPoweredBy');
  }

  constructor(private _brandingService: BrandingService, private readonly _runtimeConfigService: RuntimeConfigService) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }

  ngOnInit(): void {
    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.mainLogo = logo));

    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));

    this._brandingService.branding$.pipe(takeUntil(this.destroy$)).subscribe(branding => {
      if (branding && branding.isGuideBranding) {
        // @ts-expect-error TS2322
        this.showPoweredBy = this._runtimeConfigService.get('displayPoweredByForSoloCoaches');
      }
    });

    this._brandingService.menuConfig$.pipe(takeUntil(this.destroy$)).subscribe(menuConfig => {
      // @ts-expect-error TS2322
      this.config.facebook = menuConfig.facebook;
      // @ts-expect-error TS2322
      this.config.twitter = menuConfig.twitter;
      // @ts-expect-error TS2322
      this.config.instagram = menuConfig.instagram;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setConfig({
    displayHIPAACompliant,
    displayMoneyBackGuarantee,
    emailHelp,
    urlInLogo,
    urlPrivacy,
    urlTerms
  }: GlobalConfig): void {
    this.config.displayHIPAACompliant = displayHIPAACompliant;
    this.config.displayMoneyBackGuarantee = displayMoneyBackGuarantee;
    this.config.emailHelp = emailHelp;
    this.config.logoUrl = prepareLogoUrlData(urlInLogo);
    this.config.urlPrivacy = urlPrivacy;
    this.config.urlTerms = urlTerms;
  }
}
