import { UserRoles } from '@app/shared/enums/user-roles';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUserUtilityObject {
  RoleId: UserRoles;
}

class UserRoleUtility {
  static isClient(user: IUserUtilityObject): boolean {
    return this._isInRole(user, UserRoles.CLIENT);
  }

  static isGuide(user: IUserUtilityObject): boolean {
    return this._isInRole(user, UserRoles.GUIDE);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static _isInRole(user: IUserUtilityObject, roleToCompare: UserRoles): boolean {
    return user?.RoleId === roleToCompare;
  }
}

export { UserRoleUtility };
