import { ChatSummary } from './types';

export function sortChatSummaries(chats: ChatSummary[]): ChatSummary[] {
  return chats.sort((chat1, chat2) => {
    if (!chat1.lastActiveDate && !chat2.lastActiveDate) {
      return 0;
    }

    if (chat1.lastActiveDate && !chat2.lastActiveDate) {
      return -1;
    }

    if (!chat1.lastActiveDate && chat2.lastActiveDate) {
      return 1;
    }

    // @ts-expect-error TS2769
    return -1 * (new Date(chat1.lastActiveDate).valueOf() - new Date(chat2.lastActiveDate).valueOf());
  });
}
