<section
  class="intro"
  appStyle
  [backgroundVideo]="pageData | communityConfig: 'introSectionBackgroundVideoUrl'"
  [lGradient]="pageData | gradient"
  [backgroundImage]="pageData | communityConfig: 'introSectionBackgroundUrl'">
  <h1 class="intro-title">{{ pageData | communityConfig: 'introSectionTitle' }}</h1>
  <p class="section-description light">{{ pageData | communityConfig: 'introSectionDescription' }}</p>
  <div class="intro-tags">
    <ng-container *ngFor="let tag of introSectionTags | stringTags">
      <div class="tag-wrap">
        <a [routerLink]="['/', guidesRoute, { tags: tag.name }]" class="tag">{{ tag.name }}</a>
      </div>
    </ng-container>
  </div>

  <div class="wrap-btn" *ngIf="!pageData.hideSectionButton">
    <a
      class="find-btn"
      appStyle
      [hoverColor]="pageData | communityConfig: 'whiteButtonsBackgroundHoverColor'"
      [backgroundColor]="'white'"
      [textColor]="pageData | communityConfig: 'gradientEnd'"
      [routerLink]="['/', guidesRoute]">
      {{ pageData | communityConfig: 'introSectionButton' }}
    </a>
  </div>
</section>

<section class="section practitioners" *ngIf="guides && guides.length">
  <h2 class="section-title">{{ pageData | communityConfig: 'guideSectionTitle' }}</h2>
  <p class="section-description">{{ pageData | communityConfig: 'guideSectionDescription' }}</p>
  <div class="section-cards">
    <div class="container">
      <div class="row practitioner-cards-row">
        <ng-container *ngFor="let guide of guides">
          <app-guide-card class="col-md-6 col-lg-4" [guide]="guide"></app-guide-card>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="wrap-btn">
    <a
      class="practitioners-btn"
      appStyle
      [hoverColor]="pageData | communityConfig: 'coloredButtonsBackgroundHoverColor'"
      [backgroundColor]="pageData | communityConfig: 'coloredButtonsBackgroundColor'"
      [routerLink]="['/', guidesRoute]">
      {{ pageData | communityConfig: 'guideSectionButton' }}
    </a>
  </div>
</section>

<section class="section product" *ngIf="products && products.length">
  <div class="brand-logo">
    <img [src]="mainLogo" alt="logo" />
  </div>
  <p class="description">{{ pageData | communityConfig: 'productSectionDescription' }}</p>

  <div class="section-cards">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-xl-10">
          <div class="row product-card-row">
            <ng-container *ngFor="let product of products">
              <div class="col-md-6 product-card-col">
                <app-product-card [product]="product"></app-product-card>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section courses" appStyle [lGradient]="pageData | gradient" *ngIf="programs && programs.length">
  <h2 class="section-title light">{{ pageData | communityConfig: 'coursesSectionTitle' }}</h2>
  <p class="section-description light">{{ pageData | communityConfig: 'coursesSectionDescription' }}</p>

  <div class="section-cards">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 program-wrapper" *ngFor="let program of programs">
          <app-program-item
            [isCommunity]="true"
            [displayProgress]="false"
            [program]="program"
            [programLink]="makeProgramPath(program?.name, program?.id)"></app-program-item>
        </div>
      </div>
    </div>
  </div>

  <div class="wrap-btn">
    <a
      class="courses-btn"
      appStyle
      [hoverColor]="pageData | communityConfig: 'whiteButtonsBackgroundHoverColor'"
      [backgroundColor]="'white'"
      [textColor]="pageData | communityConfig: 'gradientEnd'"
      [routerLink]="['/programs']"
      [queryParams]="getProgramsQuery()">
      {{ pageData | communityConfig: 'coursesSectionButton' }}
    </a>
  </div>
</section>

<section class="section blogs" *ngIf="articles && articles.length">
  <h2 class="section-title">{{ pageData | communityConfig: 'blogsSectionTitle' }}</h2>
  <p class="section-description">{{ pageData | communityConfig: 'blogsSectionDescription' }}</p>

  <div class="section-cards">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-xl-10" *ngIf="videos && videos.length; else withoutVideo">
          <div class="row">
            <ng-container *ngFor="let article of articles | slice: 0:3">
              <div class="col-lg-4 col-md-6 order-1">
                <app-blog-item-article
                  class="blog-item article-item"
                  [article]="article"
                  (select)="openArticle($event)"></app-blog-item-article>
              </div>
            </ng-container>
            <div class="col-lg-8 col-md-12 order-3 order-lg-2">
              <app-blog-item-video
                class="blog-item article-item"
                [video]="videos[0]"
                (selectAuthor)="openVideoAuthorPage($event)"></app-blog-item-video>
            </div>
            <div class="col-lg-4 col-md-6 order-2 order-lg-3">
              <app-blog-item-article
                *ngIf="articles.length > 3"
                class="blog-item article-item"
                [article]="articles[3]"
                (select)="openArticle($event)"></app-blog-item-article>
            </div>
          </div>
        </div>
        <ng-template #withoutVideo>
          <div class="col-lg-12 col-xl-10">
            <div class="row">
              <ng-container *ngFor="let article of articles">
                <div class="col-lg-4 col-md-6">
                  <app-blog-item-article
                    class="blog-item article-item"
                    [article]="article"
                    (select)="openArticle($event)"></app-blog-item-article>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="wrap-btn">
    <a
      class="blogs-btn"
      appStyle
      [hoverColor]="pageData | communityConfig: 'coloredButtonsBackgroundHoverColor'"
      [backgroundColor]="pageData | communityConfig: 'coloredButtonsBackgroundColor'"
      [routerLink]="['/blog']">
      {{ pageData | communityConfig: 'blogsSectionButton' }}
    </a>
  </div>
</section>

<section class="section podcasts" *ngIf="podcasts && podcasts.length">
  <h2 class="section-title">{{ pageData | communityConfig: 'podcastsSectionTitle' }}</h2>
  <p class="section-description">{{ pageData | communityConfig: 'podcastsSectionDescription' }}</p>

  <div class="section-cards">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-xl-10">
          <div class="row">
            <ng-container *ngFor="let podcast of podcasts">
              <div class="col-lg-4 col-md-6">
                <app-blog-item-podcast
                  appStyle
                  [rGradient]="pageData | gradient"
                  class="blog-item podcast-item"
                  [podcast]="podcast"
                  (select)="openArticle($event)"></app-blog-item-podcast>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wrap-btn">
    <a
      class="podcasts-btn"
      appStyle
      [hoverColor]="pageData | communityConfig: 'coloredButtonsBackgroundHoverColor'"
      [backgroundColor]="pageData | communityConfig: 'coloredButtonsBackgroundColor'"
      [routerLink]="['/blog']">
      {{ pageData | communityConfig: 'podcastsSectionButton' }}
    </a>
  </div>
</section>

<section class="certificate" *ngIf="!hideGiftCertificate">
  <div class="container">
    <div class="row content">
      <div class="col-md-6">
        <div class="certificate-card">
          <img [src]="pageData | communityConfig: 'certificateSectionImage'" alt="gift certificate" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="certificate-info">
          <h2 class="info-title">{{ pageData | communityConfig: 'certificateSectionOffer' }}</h2>
          <div class="info-text">{{ pageData | communityConfig: 'certificateSectionDescription' }}</div>
          <a
            routerLink="/gift-coupons"
            appStyle
            [hoverColor]="pageData | communityConfig: 'gradientStart'"
            [lGradient]="pageData | gradient"
            class="certificate-btn">
            {{ pageData | communityConfig: 'certificateSectionButton' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
