import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { ExternalCalendarSources, ICalendarsRequestOptions, IExternalCalendars } from './types';
import { Observable } from 'rxjs';
import config from '../config/config';

@Injectable()
export class ExternalCalendarsApiService {
  readonly ENDPOINT = `${config.apiPath}/user/common/calendars`;

  constructor(private readonly _http: HttpClient) {}

  changeCalendarSyncMode$(calendarId: number, source: ExternalCalendarSources, newSyncMode: boolean): Observable<void> {
    // @ts-expect-error TS2322
    return this._http.post(`${this.ENDPOINT}/${source}/${calendarId}`, { sync: newSyncMode }).pipe(mapTo(null));
  }

  disconnect$(source: ExternalCalendarSources, accountId: number): Observable<void> {
    // @ts-expect-error TS2322
    return this._http.delete(`${this.ENDPOINT}/${source}/accounts/${accountId}`).pipe(mapTo(null));
  }

  loadCalendars$(options: ICalendarsRequestOptions = {}): Observable<IExternalCalendars> {
    const params = new HttpParams({
      fromObject: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(<{ [prop: string]: string }>options),
        nocache: Math.random().toString()
      }
    });

    return this._http
      .get<{ readonly calendars: IExternalCalendars }>(this.ENDPOINT, { params })
      .pipe(map(({ calendars }) => calendars));
  }
}
