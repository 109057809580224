import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonBehaviorsModule } from '@app/modules/common-behaviors/common-behaviors.module';
import { ServicesModule } from '@app/modules/services/services.module';
import { SharedModule } from '@app/shared';
import { PublicSessionTemplateComponent } from './components/public-session-template/public-session-template.component';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { PuiAvatarModule, PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CommonBehaviorsModule,
    ServicesModule,
    SharedModule,
    PlatformStyleModule,
    PuiAvatarModule,
    GuideServiceModule,
    PuiIconModule,
    RouterModule,
    PuiTypographyModule
  ],
  exports: [PublicSessionTemplateComponent],
  declarations: [PublicSessionTemplateComponent]
})
export class SessionTemplateModule {}
