import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SessionBookOptionsComponent } from './components/session-book-options/session-book-options.component';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [CommonModule, NgbTooltipModule, SharedModule, PlatformStyleModule, TranslocoRootModule],
  declarations: [SessionBookOptionsComponent],
  exports: [SessionBookOptionsComponent]
})
export class SessionBookOptionsModule {}
