<form [formGroup]="form" class="d-flex flex-column pui-space_bottom-4">
  <div class="pui-space_bottom-4">
    <label pui-input-label for="date-overrides" i18n="@@Date">Date</label>
    <pui-input-date
      puiPlaceholder="Choose date"
      id="date-overrides"
      formControlName="date"
      [puiDatepickerMinDate]="minDate"></pui-input-date>
  </div>

  <ng-container *ngIf="form.get('date')?.value">
    <ng-container *ngIf="!form.get('unavailable')?.value">
      <div class="pui-space_bottom-1">
        <h5 pui-typography="h5" i18n="@@WhichHoursAreYouFree">Which hours are you free?</h5>
      </div>
      <div class="pui-space_bottom-2">
        <ng-container *ngFor="let item of form.get('overrides')?.controls; let index = index">
          <div class="d-flex flex-row align-items-center pui-space_bottom-2">
            <pui-select
              [formControl]="item.get('startTime')"
              class="date-overrides-form__time-select"
              [maxHeight]="300"
              [virtualScroll]="false">
              <pui-option *ngFor="let option of startHours" [value]="option.iso" [label]="option.label"></pui-option>
            </pui-select>
            <span class="delimiter">—</span>
            <pui-select
              [formControl]="item.get('endTime')"
              class="date-overrides-form__time-select"
              [maxHeight]="300"
              [virtualScroll]="false">
              <pui-option *ngFor="let option of endHours" [value]="option.iso" [label]="option.label"></pui-option>
            </pui-select>

            <ng-container *ngIf="index === 0; else removeButton">
              <pui-icon class="date-overrides-form__button" (click)="addControl()" svgIcon="pui:plus"></pui-icon>
            </ng-container>

            <ng-template #removeButton>
              <pui-icon
                class="date-overrides-form__button"
                (click)="removeControl(index)"
                svgIcon="pui:trash"></pui-icon>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <pui-toggle formControlName="unavailable" (change)="toggleUnavailableByAllDay()">
      <span pui-typography="body-s-bold" i18n="@@MarkUnavailableAllDay">Mark unavailable all day</span>
    </pui-toggle>
  </ng-container>
</form>
