<div class="top-row">
  <button
    data-qa-id="program-constructor-close-page-button"
    type="button"
    class="btn close-btn"
    aria-label="Close"
    (click)="closeConstructor()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <header class="header">
        <a
          data-qa-id="program-constructor-back-to-templates-button"
          (click)="backToTemplates()"
          class="btn btn-link back-btn"
          i18n="@@backToTemplates">
          Back to templates
        </a>

        <h1 data-qa-id="program-constructor-program-title" class="col page-title" i18n="@@program">Program</h1>
      </header>
    </div>
  </div>

  <section class="row program-constructor">
    <div class="col">
      <ul
        ngbNav
        class="nav nav-step justify-content-center"
        (activeIdChange)="beforeStepChange($event)"
        #stepNavigator="ngbNav">
        <!-- FIRST STEP -->
        <li [ngbNavItem]="1" class="nav-item ng-star-inserted" (click)="setStep(1)">
          <a class="nav-link" ngbNavLink>
            <div class="d-flex align-items-center">
              <i class="circle">1</i>
              <span data-qa-id="program-constructor-add-basic-info-stepper" class="name" i18n="@@addBasicInfo">
                Add basic info
              </span>
              <span class="name-mobile" i18n="@@basicInfo">Basic info</span>
              <div class="horizontal-line"></div>
            </div>
          </a>
          <ng-template ngbNavContent>
            <app-program-content
              [author]="author"
              [contentForm]="contentForm"
              [options]="programOptions.options$ | async"
              [possibleCoauthors]="programCoauthors.coauthors$ | async"
              (addCoauthor)="addCoauthor()"
              (addFAQuestion)="addFAQuestion()"
              (addKeyPoint)="addKeyPoint()"
              (addLanguage)="addLanguage()"
              (removeCoauthor)="removeCoauthor($event)"
              (removeFAQuestion)="removeFAQuestion($event)"
              (removeKeyPoint)="removeKeyPoint($event)"
              (skipCoauthorPropositions)="programCoauthors.reset()"
              (uploadCovers)="uploadImage($event)"
              (removeCover)="removeCover()"
              class="program-content"
              appScrollToFirstInvalid
              triggers="manual"></app-program-content>

            <div class="program-content-buttons">
              <app-button className="btn-outline-preview" (click)="previewProgramContent()">
                <ng-container i18n="@@preview">Preview</ng-container>
              </app-button>
              <app-button
                data-qa-id="program-constructor-next-button"
                className="btn-primary"
                (click)="nextStep()"
                i18n="@@next">
                Next
              </app-button>
            </div>
          </ng-template>
        </li>
        <!-- SECOND STEP -->
        <li [ngbNavItem]="2" class="nav-item ng-star-inserted" (click)="setStep(2)">
          <a class="nav-link" ngbNavLink>
            <div class="d-flex align-items-center" [ngClass]="{ inactivated: lastStepOpened < 2 }">
              <i class="circle">2</i>
              <span data-qa-id="program-constructor-add-content-stepper" class="name" i18n="@@addContent">
                Add content
              </span>
              <span class="name-mobile" i18n="@@content">Content</span>
            </div>
          </a>
          <ng-template ngbNavContent>
            <div class="row section step-content program-modules-section">
              <div class="col-12">
                <app-program-modules
                  [modulesForm]="modulesForm"
                  [editable]="true"
                  [editorId]="authorId"
                  [isTeamAdmin]="isTeamAdmin"
                  [options]="programOptions.options$ | async"
                  [programAccessRole]="ProgramAccessRoles.AUTHOR"
                  (addModule)="addModule($event)"
                  (addQuestion)="addQuestion($event)"
                  (addQuestionOption)="addQuestionOption($event)"
                  (cloneModule)="cloneModule($event)"
                  (removeModule)="removeModule($event)"
                  (removeQuestion)="removeQuestionAtIndex($event)"
                  (removeQuestionOption)="removeQuestionOptionAtIndex($event)"
                  (reorderModules)="reorderModules($event)"
                  (updateModuleTitle)="updateModuleTitle($event)"
                  (updateModuleDescription)="updateModuleDescription($event)"
                  (loadQuiz)="loadQuiz($event)"
                  class="program-modules"></app-program-modules>
              </div>

              <div class="d-none d-md-flex col-md-7 offset-md-4 program-modules-buttons">
                <div>
                  <app-button
                    data-qa-id="program-constructor-preview-button"
                    className="btn-outline-preview"
                    (click)="previewProgramModules()">
                    <ng-container i18n="@@preview">Preview</ng-container>
                  </app-button>
                </div>
                <app-button
                  data-qa-id="program-constructor-done-with-modules-button"
                  className="btn-outline-primary"
                  [disabled]="!canSaveChanges"
                  (click)="saveChanges()">
                  <ng-container i18n="@@doneWithModules">Done with modules</ng-container>
                </app-button>
              </div>
            </div>
            <div class="row d-md-none program-modules-buttons mobile">
              <app-button className="btn-outline-preview" (click)="previewProgramModules()">
                <ng-container i18n="@@preview">Preview</ng-container>
              </app-button>
              <app-button className="btn-outline-primary" (click)="saveChanges()" [disabled]="!canSaveChanges">
                <ng-container i18n="@@doneWithModules">Done with modules</ng-container>
              </app-button>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="stepNavigator"></div>
    </div>
  </section>
</div>
