import { Routes } from '@angular/router';

import { ProgramLandingComponent } from './components/program-landing/program-landing.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { PublicProgramsGuard } from '@app/core/public/public-programs.guard';

export const programsRoutes: Routes = [
  {
    path: '',
    canLoad: [PublicProgramsGuard],
    canActivate: [PublicProgramsGuard],
    component: ProgramsComponent
  }
  // WARNING: Do not set redirection for path: '**' here until you're sure that program landing route still works
];

export const programRoutes: Routes = [
  {
    path: ':id',
    component: ProgramLandingComponent
  },
  {
    path: '**',
    redirectTo: '/programs'
  }
];
