import { Injectable } from '@angular/core';
import { INavigationDependenciesService } from '@app/modules/navigation';
import { SessionsService } from '@app/core/session/sessions.service';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';
import { ChatsService } from '@app/core/chat/chats.service';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { WindowProvider } from '@app/core/browser-window/window-provider';
import { AuthService } from '@app/core/auth/services';
import { LoopedinService } from '@app/core/integrations/modules/loopedin';
import { BrandingService } from '@app/core/branding/branding.service';

@Injectable()
export class NavigationDependenciesClientService implements INavigationDependenciesService {
  constructor(
    public sessions: SessionsService,
    public notifier: SystemNotificationsService,
    public chatService: ChatsService,
    public clientProgramsService: ClientProgramsService,
    public window: WindowProvider,
    public authService: AuthService,
    public loopedin: LoopedinService,
    public branding: BrandingService
  ) {}
}
