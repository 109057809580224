import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatChipList } from '@angular/material/chips';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AppFormFieldControl } from '@app/modules/ui-kit/form/components/form-field/form-field.component';
import { UiChip } from './chip';
import { UiChipListInput } from './chip-list-input';

@Component({
  selector: 'ui-chip-list',
  template: `
    <mat-chip-list
      #chipList
      [(value)]="value"
      [disabled]="disabled"
      [selectable]="selectable"
      [multiple]="multiple"
      [required]="required"
      [compareWith]="compareWith"
      [placeholder]="placeholder"
      [aria-orientation]="ariaOrientation"
      [errorStateMatcher]="errorStateMatcher"
      [tabIndex]="tabIndex">
      <mat-chip
        class="ui-chip"
        #originChip="matChip"
        *ngFor="let chip of chips"
        [attr.data-qa-id]="'mat-chip-' + chip.innerText.trim()"
        [value]="chip.value"
        [selected]="chip.selected"
        [disabled]="chip.disabled"
        [selectable]="chip.selectable || selectable"
        [removable]="chip.removable"
        [color]="chip.color"
        [tabIndex]="chip.tabIndex"
        [ngClass]="chip.classList"
        style="{{ chip.styles }}"
        (removed)="chip.remove($event)"
        (selectionChange)="chip.selectionChange.emit($event)"
        (focus)="chip.focus.emit(chip.value)"
        disableRipple>
        <div class="ui-chip-text">{{ chip.innerText }}</div>

        <pui-icon
          [attr.data-qa-id]="'mat-chip-remove-button-' + chip.innerText.trim()"
          class="app-chip-remove"
          svgIcon="chip-remove"
          matChipRemove
          *ngIf="chip.removable"></pui-icon>
      </mat-chip>

      <ng-container *ngIf="input">
        <ng-container *ngTemplateOutlet="input.inputRef; context: { $implicit: input }"></ng-container>
      </ng-container>
    </mat-chip-list>
  `,
  styleUrls: ['chip-list.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.aria-label]': 'ariaLabel',
    class: 'ui-chip-list'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => UiChipList) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiChipList implements ControlValueAccessor, AppFormFieldControl, OnDestroy, AfterViewInit {
  private readonly destroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  @Input()
  get value(): unknown {
    return this._value;
  }

  set value(value: unknown) {
    this._value = value;
    this._onChange(this.value);
    this._onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _value: unknown = null;

  // @ts-expect-error TS2564
  focused: boolean;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? !!this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _disabled = false;

  @ContentChildren(UiChip, { emitDistinctChangesOnly: true }) chips: QueryList<UiChip> | undefined;

  @ContentChild(UiChipListInput, { static: true }) input: UiChipListInput | undefined;

  @ViewChild('chipList', { static: true }) originChipList: MatChipList | undefined;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _required = false;

  @Input()
  get selectable(): boolean {
    return this._selectable;
  }

  set selectable(value: boolean) {
    this._selectable = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _selectable = false;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _multiple = false;

  @Input() placeholder: string | undefined;

  @Input('aria-orientation') ariaOrientation: 'horizontal' | 'vertical' = 'horizontal';

  @Input() errorStateMatcher: ErrorStateMatcher | undefined;

  @Input() tabIndex: number | undefined;

  @Input()
  get ariaLabel(): string {
    return this._ariaLabel;
  }

  set ariaLabel(value: string) {
    this._ariaLabel = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _ariaLabel = '';

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get compareWith(): (o1: any, o2: any) => boolean {
    return this._compareWith;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    this._compareWith = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _compareWith = (o1: any, o2: any) => o1 === o2;

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  _onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Optional() @Self() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.cdr.detectChanges(), 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
    this.stateChanges.next();
  }
}
