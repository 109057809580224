<div class="gift-certificate">
  <div class="icon">
    <img src="/assets/images/gift-certificate.svg" alt="give a gift" />
  </div>
  <p i18n="@@giftCertificateReason">Because nothing is more important then the wellbeing of those you love</p>
  <div class="divider"></div>
  <div class="button-wrapper">
    <app-button type="button" className="brand-button btn-lg" routerLink="/gift-coupons" i18n="@@giftCertificate">
      Gift certificate
    </app-button>
  </div>
</div>
