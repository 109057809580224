<div class="layout-wrapper" [ngClass]="{ 'shown-menu': shownMenu }">
  <div class="layout-header">
    <app-common-header [coloredHeader]="true" (showMenuChange)="toggledMenu($event)"></app-common-header>
  </div>
  <div class="container">
    <nav>
      <ul>
        <li routerLinkActive="active-route">
          <a routerLink="/marketplace/programs" i18n="@@programs">Programs</a>
        </li>
        <li routerLinkActive="active-route">
          <div class="practitioners-wrap">
            <span transloco="practitioners">Coaches</span>
            <app-coming-soon-badge></app-coming-soon-badge>
          </div>
        </li>
      </ul>
    </nav>
  </div>
  <div class="separator"></div>
  <div class="layout-body container">
    <router-outlet></router-outlet>
  </div>
  <div class="layout-footer">
    <app-common-footer></app-common-footer>
  </div>
</div>
