export enum PaymentTypes {
  PAYMENT = 'payment',
  PAYPAL_PAYMENT = 'paypal_payment',
  PREPAYMENT = 'prepayment',
  REFUNDED_PAYMENT = 'refunded_payment',
  REFUNDED_STRIPE_PAYMENT = 'refunded_stripe_payment',
  REFUNDED_PAYPAL_PAYMENT = 'refunded_paypal_payment',
  REFUNDED_PREPAYMENT = 'refunded_prepayment',
  REFUNDED_WALLET_PAYMENT = 'refunded_wallet_payment',
  REFUNDED_WALLET_PREPAYMENT = 'refunded_wallet_prepayment',
  WALLET_PAYMENT = 'wallet_payment',
  WALLET_PREPAYMENT = 'wallet_prepayment',
  WITHDRAWAL = 'withdrawal',
  STRIPE_PAYMENT = 'stripe_payment'
}
