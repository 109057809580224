import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IClientQuiz } from '@app/core/quizzes/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-form-quizzes',
  templateUrl: './form-quizzes.component.html',
  styleUrls: ['./form-quizzes.component.scss']
})
export class FormQuizzesComponent implements OnInit {
  // @ts-expect-error TS7008
  currentQuiz;

  currentQuizIndex = 0;

  // @ts-expect-error TS2564
  @Input() quizzes: IClientQuiz[];

  constructor(readonly modal: NgbActiveModal) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.currentQuiz = this.quizzes[this.currentQuizIndex];
  }

  showNextFormQuiz(): void {
    if (this.currentQuizIndex < this.quizzes.length - 1) {
      this.currentQuizIndex += 1;
      this.currentQuiz = this.quizzes[this.currentQuizIndex];
    } else {
      this.modal.close(true);
    }
  }
}
