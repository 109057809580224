import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPublicProgram } from '@app/modules/program/types';

@Component({
  selector: 'app-program-content-preview-modal',
  templateUrl: './program-content-preview-modal.component.html',
  styleUrls: ['./program-content-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramContentPreviewModalComponent {
  @Input()
  program: IPublicProgram | undefined;

  constructor(public modal: NgbActiveModal) {}
}
