<div class="container">
  <div class="row">
    <div class="col-md-8 offset-md-2 breadcrumbs">
      <a [routerLink]="['/']" i18n="@@blogArticleCreatorHome">Home</a>
      <span class="breadcrumb-delimiter">/</span>
      <a [routerLink]="['/blog']" i18n="@@blogArticleCreatorBlog">Blog</a>
      <span class="breadcrumb-delimiter" *ngIf="author">/</span>
      <a [routerLink]="['/blog', author?.namedUrl]">{{ author?.name }}</a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <app-blog-article-form
        [tags]="tags"
        (save)="publish($event)"
        [isBusinessTypeAllowed]="canCreateBusinessArticles"
        [isSaveDisabled]="isSaving"
        [uploaderService]="uploaderService"
        (uploadCover)="uploadCover($event)"></app-blog-article-form>
    </div>
  </div>
</div>
