import produce from 'immer';

import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { createReducer, on } from '@ngrx/store';

import { GuideServicesState, serviceSchedulingStepsPriorityIndex } from './commons/types';
import * as guideServices from './service-scheduling.actions';
import { encodeClientId } from '@app/screens/guide/guide-clients/guide-client/services/features/guide-clients-features.service';

const initialState: GuideServicesState = {
  status: 'initial',
  chosenClients: [],
  allClients: [],
  services: [],
  currentStep: null,
  stepsHistory: {}
};

export const serviceSchedulingReducer = createReducer(
  initialState,
  on(guideServices.fetchAllServices, state =>
    produce(state, draft => {
      draft.status = 'loading';
    })
  ),
  on(guideServices.fetchAllServicesSuccess, (state, { services }) =>
    produce(state, draft => {
      draft.services = services;
    })
  ),
  on(guideServices.setClients, (state, { clients, chosenClientsIds: chosenClientsIds = [] }) =>
    produce(state, draft => {
      draft.status = 'idle';
      draft.allClients = clients;
      draft.chosenClients = chosenClientsIds
        .map(
          id =>
            clients.find(client => {
              if (Number.isInteger(id)) {
                return client.relationId === id;
              } else {
                const { clientId, relationId } = encodeClientId(id as string);

                return clientId !== null
                  ? client.clientId === Number(clientId)
                  : client.relationId === Number(relationId);
              }
            }) as CRMClient
        )
        .filter(client => !!client);
    })
  ),
  on(guideServices.setChosenClientsIds, (state, { chosenClientsIds }) =>
    produce(state, draft => {
      draft.chosenClients = chosenClientsIds
        .map(id => state.allClients.find(client => client.id === id) as CRMClient)
        .filter(client => !!client);
    })
  ),
  on(guideServices.setSeriviceSchedulingStep, (state, { currentStep }) =>
    produce(state, draft => {
      if (
        (serviceSchedulingStepsPriorityIndex[currentStep] || 0) >=
        (serviceSchedulingStepsPriorityIndex[draft.currentStep!] || 0)
      ) {
        draft.stepsHistory[currentStep] = {
          previousStep: draft.currentStep,
          index: serviceSchedulingStepsPriorityIndex[currentStep!] || 1
        };
      }
      draft.currentStep = currentStep;
    })
  ),
  on(guideServices.resetStepHistory, state =>
    produce(state, draft => {
      draft.stepsHistory = {};
      draft.currentStep = null;
    })
  )
);
