import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { SystemVersionService } from '@app/core/system-version/system-version.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-system-version-popup',
  templateUrl: './system-version-popup.component.html',
  styleUrls: ['./system-version-popup.component.scss']
})
export class SystemVersionPopupComponent implements OnDestroy {
  private readonly isBrowser: boolean;

  readonly platformName: string;

  private onDestroy$ = new Subject();

  // @ts-expect-error TS2564
  private temporarilyHide: boolean;

  show = false;

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  private static handleWindowUnload(e) {
    e.preventDefault();
    return (e.returnValue = '');
  }

  constructor(
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private _platformId: any,
    private _route: ActivatedRoute,
    private _router: Router,
    private _runtimeConfigService: RuntimeConfigService,
    private _systemVersionService: SystemVersionService
  ) {
    this.isBrowser = isPlatformBrowser(_platformId);
    this._router.events
      .pipe(
        // eslint-disable-next-line id-length
        filter(e => e instanceof NavigationEnd && _route.firstChild != null),
        takeUntil(this.onDestroy$)
      )
      .subscribe((event: NavigationEnd) => {
        // @ts-expect-error TS2322
        this.temporarilyHide = event.url && event.url.includes('session');
      });

    if (this.isBrowser) {
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      _systemVersionService.newVersion$.subscribe(() => {
        this.show = !this.temporarilyHide;
      });
    }

    // @ts-expect-error TS2322
    this.platformName = _runtimeConfigService.get('platformName');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  later() {
    this.show = false;
    this._systemVersionService.remindAfter(1);
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  refresh() {
    if (this.isBrowser) {
      this._browserWindow.addEventListener('beforeunload', SystemVersionPopupComponent.handleWindowUnload, false);
      this._browserWindow.location?.reload();
      this._browserWindow.removeEventListener('beforeunload', SystemVersionPopupComponent.handleWindowUnload);
    }
    return false;
  }
}
