import { Injectable } from '@angular/core';
// import { locale } from '@env/locale';
import { Observable, Subject } from 'rxjs';
import { ScheduleDatesStore } from './schedule-dates-store';
import { DateTime } from 'luxon';
import {
  IGuideSchedule,
  IGuideScheduleRange,
  ISessionDuration,
  ISessionTimeFrame,
  NgbDateStructWithZoneName
} from './types';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';

@Injectable()
export class BookSessionService {
  protected readonly SESSION_DURATIONS = [30, 60]; /* in minutes*/
  protected readonly MIN_SESSION_TIME_FRAME = 30; /* minutes*/
  protected readonly TIME_UNIT = 'minutes';

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _selectedScheduleDate: NgbDateStructWithZoneName;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _sessionDurations: ISessionDuration[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _sessionDurations$: Subject<ISessionDuration[]>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _sessionTimeFrames: ISessionTimeFrame[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _sessionTimeFrames$: Subject<ISessionTimeFrame[]>;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _firstDayRangeStart: string;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _timezone: string;

  locale: ILocale;

  get sessionDurations$(): Observable<ISessionDuration[]> {
    return this._sessionDurations$.asObservable();
  }

  get sessionTimeFrames$(): Observable<ISessionTimeFrame[]> {
    return this._sessionTimeFrames$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get minDate() {
    return this._firstDayRangeStart;
  }

  constructor(private _scheduleDatesStore: ScheduleDatesStore, private _localeService: LocaleService) {
    this._sessionDurations = [];
    this._sessionDurations$ = new Subject<ISessionDuration[]>();
    this._sessionTimeFrames = [];
    this._sessionTimeFrames$ = new Subject<ISessionTimeFrame[]>();
    this.locale = this._localeService.getLocale();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  reset() {
    this._sessionDurations = [];
    this._sessionTimeFrames = [];
    // @ts-expect-error TS2322
    this._firstDayRangeStart = undefined;
    // @ts-expect-error TS2322
    this._timezone = undefined;
    this._scheduleDatesStore.reset();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setTimezone(tz: string) {
    this._timezone = tz;
  }

  checkScheduleAvailabilityForDate(date: NgbDateStructWithZoneName): boolean {
    const { year, month, day, zone } = date;
    const scheduleDate = this._scheduleDatesStore.buildDateKey(year, month, day, zone);
    let isDateAvailable = this._scheduleDatesStore.has(scheduleDate);
    if (isDateAvailable && this.isToday(date)) {
      const todayFirstRangeStart = this.getTodayFirstRangeStart(date);
      isDateAvailable = this._scheduleDatesStore
        .get(scheduleDate)
        .some(range => DateTime.fromISO(range.dateEnd) > DateTime.fromMillis(todayFirstRangeStart));
    }
    return isDateAvailable;
  }

  getTodayFirstRangeStart(date: NgbDateStructWithZoneName): number {
    let minSessionStartDateTime;
    const now = DateTime.local().setZone(date.zone).set({ second: 0, millisecond: 0 });
    if (this.isToday(date)) {
      const nearestHalfHourRemainder = this.MIN_SESSION_TIME_FRAME - (now.minute % this.MIN_SESSION_TIME_FRAME);
      minSessionStartDateTime = now.plus({ minutes: nearestHalfHourRemainder });
    }
    // @ts-expect-error TS2532
    return minSessionStartDateTime.valueOf();
  }

  updateScheduleTz(schedule: IGuideSchedule, timezone: string, replace?: boolean): void {
    this._timezone = timezone;

    if (replace) {
      // @ts-expect-error TS2322
      this._firstDayRangeStart = null;
      this._scheduleDatesStore.reset();
    }

    if (schedule.events.length === 0) {
      return;
    }

    let events = [];
    events.push({
      dateStart: DateTime.fromISO(schedule.events[0].dateStart).setZone(timezone)
    });

    const now = DateTime.local();

    for (const i of schedule.events) {
      if (DateTime.fromISO(i.dateStart) < now && DateTime.fromISO(i.dateEnd) > now) {
        this._firstDayRangeStart = now.toISO();
      }
    }

    const cEvents = schedule.events.map(i => Object.assign({}, i));

    for (let i = 0; i < cEvents.length; ++i) {
      // @ts-expect-error TS7022
      const j = events.length - 1;
      const dateEnd = DateTime.fromISO(cEvents[i].dateEnd).setZone(timezone);

      if (events[j].dateStart.endOf('day') > dateEnd) {
        events[j].dateEnd = dateEnd.plus({ seconds: 1 });

        if (i + 1 < cEvents.length) {
          events.push({
            dateStart: DateTime.fromISO(cEvents[i + 1].dateStart).setZone(timezone)
          });
        }
      } else {
        events[j].dateEnd = events[j].dateStart.endOf('day').plus({ minutes: Math.max(...this.SESSION_DURATIONS) });
        events.push({
          // @ts-expect-error TS2322
          dateStart: events[j].dateEnd.startOf('day'),
          plus: true,
          dateEnd: DateTime.fromISO(cEvents[i].dateEnd).setZone(timezone).plus({ seconds: 1 })
        });
        i--;
      }
    }

    events = events.filter(i => {
      return !(i.plus && i.dateStart.plus({ seconds: 1 }).equals(i.dateEnd));
    });
    this._scheduleDatesStore.add(
      // @ts-expect-error TS2345
      events.map(i => {
        i.dateStart = i.dateStart.toISO();
        // @ts-expect-error TS2339
        i.dateEnd = i.dateEnd.toISO();
        return i;
      }),
      timezone
    );
  }

  updateSessionDurations(pickedDate: NgbDateStructWithZoneName): void {
    const scheduleRanges = this.getScheduleRanges(pickedDate);
    this._sessionDurations = scheduleRanges.length ? this.generateSessionDurations(scheduleRanges) : [];
    this._selectedScheduleDate = { ...pickedDate };
    this._sessionDurations$.next(this._sessionDurations);
  }

  updateSessionTimeFrames(sessionDuration: ISessionDuration): void {
    const { value: duration } = sessionDuration;
    const scheduleRanges = this.getScheduleRanges(this._selectedScheduleDate, true);
    this._sessionTimeFrames = scheduleRanges.length ? this.generateSessionTimeFrames(scheduleRanges, duration) : [];
    this._sessionTimeFrames$.next(this._sessionTimeFrames);
  }

  protected compareDateTimes(dateTimeFirstString: string, dateTimeSecondString: string): number {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    return <any>new Date(dateTimeFirstString) - <any>new Date(dateTimeSecondString);
  }

  protected dateTimeFromISO(date: string): DateTime {
    return this._timezone ? DateTime.fromISO(date).setZone(this._timezone) : DateTime.fromISO(date);
  }

  protected findMaxScheduleRangeDuration(scheduleRanges: IGuideScheduleRange[]): number {
    return scheduleRanges.reduce(
      (maxValue, scheduleRange) =>
        Math.max(maxValue, new Date(scheduleRange.dateEnd).getTime() - new Date(scheduleRange.dateStart).getTime()),
      0
    );
  }

  protected generateSessionDurations(scheduleRanges: IGuideScheduleRange[]): ISessionDuration[] {
    const maxScheduleRangeDuration = this.findMaxScheduleRangeDuration(scheduleRanges);
    return this.SESSION_DURATIONS.filter(duration => duration * 60 * 1000 <= maxScheduleRangeDuration).map(
      duration => ({ label: `${duration} min`, value: duration })
    );
  }

  protected generateSessionTimeFrames(scheduleRanges: IGuideScheduleRange[], duration: number): ISessionTimeFrame[] {
    // @ts-expect-error TS2769
    return [].concat(...scheduleRanges.map(range => this.generateSessionTimeFramesPerScheduleRange(range, duration)));
  }

  protected generateSessionTimeFramesPerScheduleRange(
    scheduleRange: IGuideScheduleRange,
    duration: number
  ): ISessionTimeFrame[] {
    const timeFrames = [];

    let timeFrameStart = this.dateTimeFromISO(scheduleRange.dateStart);
    let timeFrameEnd = timeFrameStart.plus({ [this.TIME_UNIT]: duration });

    const scheduleRangeStart = this.dateTimeFromISO(scheduleRange.dateStart);
    const scheduleRangeEnd = this.dateTimeFromISO(scheduleRange.dateEnd);

    while (scheduleRangeStart.hasSame(timeFrameStart, 'day') && timeFrameEnd <= scheduleRangeEnd) {
      const label = timeFrameStart.toLocaleString(
        Object.assign({ locale: this.locale.dateTimeLocale }, DateTime.TIME_SIMPLE)
      );

      timeFrames.push({ label, duration, value: timeFrameStart.toISO() });
      timeFrameStart = timeFrameStart.plus({ [this.TIME_UNIT]: duration });
      timeFrameEnd = timeFrameEnd.plus({ [this.TIME_UNIT]: duration });
    }

    return timeFrames;
  }

  protected getScheduleRanges(pickedDate: NgbDateStructWithZoneName, shouldSort?: boolean): IGuideScheduleRange[] {
    const { year, month, day } = pickedDate;
    const scheduleDate = this._scheduleDatesStore.buildDateKey(year, month, day, pickedDate.zone);
    // @ts-expect-error TS7034
    let scheduleRanges = [];
    if (this._scheduleDatesStore.has(scheduleDate)) {
      scheduleRanges = this._scheduleDatesStore.get(scheduleDate);
      if (this.isToday(pickedDate)) {
        const todayFirstRangeStart = this.getTodayFirstRangeStart(pickedDate);
        const todayScheduleRanges = [];
        for (let i = 0, len = scheduleRanges.length; i < len; i++) {
          const range = scheduleRanges[i];
          if (new Date(range.dateStart) >= new Date(todayFirstRangeStart)) {
            todayScheduleRanges.push(range);
          } else if (new Date(range.dateEnd) > new Date(todayFirstRangeStart)) {
            range.dateStart = DateTime.fromMillis(todayFirstRangeStart).toString();
            todayScheduleRanges.push(range);
          }
        }
        scheduleRanges = todayScheduleRanges;
      }

      if (shouldSort) {
        scheduleRanges = scheduleRanges.sort((sr1, sr2) => this.compareDateTimes(sr1.dateStart, sr2.dateStart));
      }
    }
    // @ts-expect-error TS7005
    scheduleRanges = scheduleRanges.filter(
      // eslint-disable-next-line id-length
      (v, index) =>
        // @ts-expect-error TS2339
        scheduleRanges.findIndex(i => i.dateEnd === v.dateEnd && i.dateStart === v.dateStart && i.id === v.id) === index
    ); // TODO: Fix removing same elements
    return scheduleRanges;
  }

  protected isToday(pickedDate: NgbDateStructWithZoneName): boolean {
    const now = DateTime.local().setZone(pickedDate.zone);
    const pd = Object.assign({}, pickedDate);
    // @ts-expect-error TS2790
    delete pd.zone;
    return now.equals(now.set(pd));
  }
}
