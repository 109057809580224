import { IStorageFileInfo } from '@app/core/files/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuestionAnswerDetails {
  readonly text: string;
  readonly fileInfo?: IStorageFileInfo;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChoiceQuestionAnswerDetails {
  readonly options: { readonly id: number; readonly isCorrect?: boolean | null }[];
  readonly explanation?: string | null;
  readonly text?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientQuestionAnswer {
  questionId: number;
  text?: string | null;
  options?: number[];
  fileInfo?: IStorageFileInfo;
}

export class QuestionAnswer {
  readonly text: string;
  readonly fileInfo?: IStorageFileInfo;
  dirty?: boolean | undefined;

  constructor(answerDetails: IQuestionAnswerDetails) {
    this.text = answerDetails.text;
    this.fileInfo = answerDetails.fileInfo;
  }
}

export class ChoiceQuestionAnswer {
  readonly options: { readonly id: number; readonly isCorrect?: boolean | null }[];
  readonly explanation?: string | null;
  readonly text?: string;
  dirty?: boolean | undefined;

  constructor(answerDetails: IChoiceQuestionAnswerDetails) {
    this.options = answerDetails.options;

    if (answerDetails.explanation) {
      this.explanation = answerDetails.explanation;
    }

    if (answerDetails.text) {
      this.text = answerDetails.text;
    }
  }
}
