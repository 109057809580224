<section class="row p-2 p-md-5 questionnaires">
  <div class="col-md-3 offset-md-2 padding-right-md-05">
    <h3 i18n="@@forms">Forms</h3>

    <div class="section-note" i18n="@@questionnaireInfoText">Create a form for clients who enroll in this program.</div>
  </div>

  <div class="col-md-6 col-lg-6" [formGroup]="formQuestionnaires">
    <div
      class="questionnaire form-group"
      *ngFor="let questionnaire of formQuestionnaires.controls; let questionnaireIndex = index"
      [formGroupName]="questionnaireIndex">
      <div class="quiz-row">
        <label class="label top-label" i18n="@@connectQuestionnaire">Connect form:</label>
        <ng-select
          [searchable]="false"
          [clearable]="false"
          formControlName="survey"
          placeholder="Select a form"
          i18n-placeholder="@@SelectAForm">
          <ng-option *ngFor="let s of surveys" [value]="s.id">{{ s.basic.quizName }}</ng-option>
        </ng-select>
      </div>

      <div class="quiz-row">
        <label class="label top-label">
          <ng-container i18n="@@sendForm">Send form:</ng-container>
        </label>
        <ng-select [searchable]="false" [clearable]="false" formControlName="sendPolicy" id="sendPolicy">
          <ng-option [value]="QuizSendPolicy.AfterBuyProgram" i18n="@@afterJoiningACourse">
            After joining a program
          </ng-option>
          <ng-option [value]="QuizSendPolicy.Scheduling">
            <ng-container i18n="@@scheduling">Scheduling</ng-container>
            ...
          </ng-option>
        </ng-select>
      </div>

      <app-quiz-scheduling
        [ngStyle]="{
          display:
            formQuestionnaires.controls[questionnaireIndex].controls.sendPolicy.value === QuizSendPolicy.Scheduling
              ? 'block'
              : 'none'
        }"
        [formControl]="formQuestionnaires.controls[questionnaireIndex].controls.schedule"></app-quiz-scheduling>

      <button
        *ngIf="formQuestionnaires.controls.length > 1 && !isFormDisabled"
        (click)="removeQuestionnaire(questionnaireIndex)"
        class="questionnaire__remove-btn"
        placement="top"
        ngbTooltip="Remove form"
        i18n-ngbTooltip="@@removeForm"
        pui-icon-button
        size="s"
        appearance="flat">
        <pui-icon svgIcon="pui:trash"></pui-icon>
      </button>
    </div>

    <a *ngIf="!isFormDisabled" (click)="addQuestionnaire()" class="btn btn-link" i18n="@@addForm">Add form</a>
  </div>
</section>
