<ng-container *ngFor="let attribute of attributes; trackBy: trackByType">
  <ng-container [ngSwitch]="attribute.type">
    <ng-container *ngSwitchCase="attributeTypes.GUIDE">
      <app-client-session-guide-attribute
        class="client-session-attribute"
        *puiLet="asGuideAttribute(attribute) as guideAttribute"
        [leftIcon]="guideAttribute.leftIcon"
        [label]="guideAttribute.label"
        [rightIcon]="guideAttribute.rightIcon"
        [rightIconRouterLink]="guideAttribute.rightIconRouterLink"></app-client-session-guide-attribute>
    </ng-container>
    <ng-container *ngSwitchCase="attributeTypes.TIME">
      <app-client-session-time-attribute
        class="client-session-attribute"
        *puiLet="asTimeAttribute(attribute) as timeAttribute"
        [leftIcon]="timeAttribute.leftIcon"
        [leftIconAppearance]="timeAttribute.leftIconAppearance"
        [label]="timeAttribute.label"></app-client-session-time-attribute>
    </ng-container>
    <ng-container *ngSwitchCase="attributeTypes.CONNECTION_TYPE">
      <app-client-session-connection-type-attribute
        class="client-session-attribute"
        *puiLet="asConnectionTypeAttribute(attribute) as connectionTypeAttribute"
        [leftIcon]="connectionTypeAttribute.leftIcon"
        [label]="connectionTypeAttribute.label"
        [labelRouterLink]="showJoinSessionLink && attribute.labelRouterLink"
        [showTooltip]="attribute.showTooltip"
        [tooltipText]="attribute.tooltipText"></app-client-session-connection-type-attribute>
    </ng-container>
    <ng-container *ngSwitchCase="attributeTypes.RECURRING">
      <app-client-session-recurring-attribute
        class="client-session-attribute"
        *puiLet="asRecurringAttribute(attribute) as recurringAttribute"
        [leftIcon]="recurringAttribute.leftIcon"
        [label]="recurringAttribute.label"></app-client-session-recurring-attribute>
    </ng-container>
    <ng-container *ngSwitchCase="attributeTypes.GUESTS">
      <app-client-session-guests-attribute
        class="client-session-attribute"
        *puiLet="asGuestsAttribute(attribute) as guestsAttribute"
        [leftIcon]="guestsAttribute.leftIcon"
        [label]="guestsAttribute.label"
        [items]="attribute.items"></app-client-session-guests-attribute>
    </ng-container>
  </ng-container>
</ng-container>
