import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import config from '@app/core/config/config';
import { User } from '@app/shared/interfaces/user';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideRelation, GuideRelationTypes } from '@app/core/users/types';
import { GuideMiniProgram } from '../types/program';
import { AuthService } from '@app/core/auth/services';
import { AllGuideServicesApiService } from '../../guide-services/services/all-guide-services-api.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Injectable()
export class QuizProgramsService {
  private readonly loadingSubject$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject$.asObservable();

  private readonly GUIDE_PROGRAMS_ENDPOINT = `${config.apiPath}/user/guide/programs/mini`;

  constructor(
    private _http: HttpClient,
    private guideClientsService: GuideClientsService,
    private readonly auth: AuthService,
    private readonly allGuideServicesApi: AllGuideServicesApiService
  ) {}

  getGuidePrograms$(): Observable<GuideMiniProgram[]> {
    this.loadingSubject$.next(true);

    if (this.auth.isPlatformAdmin()) {
      return this.getDefaultPrograms$();
    }

    return this._http.get<GuideMiniProgram[]>(this.GUIDE_PROGRAMS_ENDPOINT).pipe(
      tap(() => this.loadingSubject$.next(false)),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getAllClientsAndPrograms$(): Observable<{
    users: GuideRelation[];
    programs: GuideMiniProgram[];
  }> {
    return combineLatest([this.guideClientsService.users$, this.getGuidePrograms$()]).pipe(
      // eslint-disable-next-line id-length
      map(([u, programs]) => {
        const users = programs.reduce(
          // @ts-expect-error TS2769
          (acc, i) => acc.concat(i.customers.map(j => new User(j.id, j.firstName, j.lastName, j.photo))),
          []
        );
        const allUsers = u.filter(user => user.type === GuideRelationTypes.GUIDE_CLIENT).concat(users);
        const filteredUsers = allUsers.filter((i, index) => allUsers.findIndex(j => i.id === j.id) === index);
        return {
          users: filteredUsers,
          programs
        };
      })
    );
  }

  private getDefaultPrograms$(): Observable<GuideMiniProgram[]> {
    return this.allGuideServicesApi.getServices$().pipe(
      map(services => {
        return services
          .filter(service => service.type === GuideServiceTypes.PROGRAM)
          .map(service => ({
            id: service.id,
            name: service.name,
            customers: []
          }));
      }),
      tap(() => this.loadingSubject$.next(false)),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
