import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform<T = any>(items: T[], predicate: (value: T, index?: number, array?: T[]) => boolean): T[] {
    return items.filter(predicate);
  }
}
