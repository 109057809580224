import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IClientGuideAvailableSession } from '../../types/types';

@Component({
  selector: 'app-available-client-sessions',
  templateUrl: './available-client-sessions.component.html',
  styleUrls: ['../../styles/sessions.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableClientSessionsComponent {
  @Input()
  // @ts-expect-error TS2564
  sessions: IClientGuideAvailableSession[];

  @Input()
  hideHeader = false;
}
