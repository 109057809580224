import { generateFontSizes } from '@awarenow/profi-ui-core';

export const notesToolbarContainer = [
  {
    size: generateFontSizes(8, 72, 14)
  },
  {
    color: []
  },
  'bold',
  'italic',
  'underline',
  'strike',
  { list: 'ordered' },
  { list: 'bullet' },
  {
    align: ['', 'center', 'right', 'justify']
  },
  'link',
  'image',
  'video',
  'fs'
];
