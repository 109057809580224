export enum SessionStatusIconEnum {
  SUCCEED = 'succeed',
  PENDING = 'pending',
  WARNING = 'warning',
  DEFAULT = 'default',
  MARKED = 'marked'
}

export enum SessionStatusIconNameEnum {
  DONE = 'pui:status-done',
  PENDING = 'pui:status-pending',
  CANCELLED = 'pui:status-cancelled',
  IN_PROGRESS = 'pui:status-in-progress'
}
