export const keyboardBindings = {
  bindings: {
    tab: false,
    handleEnterWithShift: {
      key: 13,
      shiftKey: true,
      handler: () => {
        /* Do nothing, hack to prevent multi-line */
      }
    },
    handleEnter: {
      key: 13,
      shiftKey: false,
      handler: () => {
        /* Do nothing, hack to prevent multi-line */
      }
    }
  }
};
