import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, switchMap } from 'rxjs/operators';

import { FormQuizzesComponent } from '@app/modules/book-service/components/form-quizzes/form-quizzes.component';
import { IClientQuiz } from '@app/core/quizzes/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IQuizAnswers } from '@appWidget/screens/book-session/types';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import config from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class BookQuizService {
  private readonly SAVE_ANSWERS_API_ENDPOINT = '/user/client/chat-bot/answer-booking-quizzes';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _quizzesAnswers$ = new BehaviorSubject<IQuizAnswers[]>([]);

  constructor(private _modal: NgbModal, private _http: HttpClient) {}

  checkIfFormsExist$(guideId: number, templateId: number): Observable<{ quizzes: IClientQuiz[] }> {
    let url = `${config.apiPath}/user/client/chat-bot/booking/${guideId}`;

    if (templateId) {
      url += `?templateId=${templateId}`;
    }

    return this._http.get<{ quizzes: IClientQuiz[] }>(url);
  }

  openFormQuizzesModal(quizzes: IClientQuiz[], guideId: number | string): Observable<void> {
    const { componentInstance, result } = this._modal.open(FormQuizzesComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'form-quizzes-modal'
    });
    componentInstance.quizzes = quizzes;

    return modalResultToObservable$(result).pipe(
      switchMap(value => (value ? this.saveAnswers$(guideId) : of(null))),
      finalize(() => this.clearQuizAnswers())
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  collectQuizAnswers(answers: IQuizAnswers) {
    const currentAnswers = this._quizzesAnswers$.getValue();
    const newAnswers = [...currentAnswers, answers];
    this._quizzesAnswers$.next(newAnswers);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clearQuizAnswers() {
    this._quizzesAnswers$.next([]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveAnswers$(guideId: number | string, quizzesInfoWithAnswers = this._quizzesAnswers$.getValue()): Observable<any> {
    return this._http.post(`${config.apiPath}${this.SAVE_ANSWERS_API_ENDPOINT}`, {
      quizzesInfoWithAnswers,
      guideId
    });
  }
}
