import { Component, ChangeDetectionStrategy, Input, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { OtpCodeService } from '../auth/services/otp-code.service';
import { TfaSmsOtpTokenService } from './tfa-sms-otp-service.interface';
import { TfaSmsTimerService } from './tfa-sms-timer.service';
import { TFA_SMS_OTP_SERVICE } from './tokens';

@Component({
  selector: 'sms-otp-input',
  templateUrl: './sms-otp-input.component.html',
  styleUrls: ['./sms-otp-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PuiDestroyService, OtpCodeService]
})
export class SmsOtpInputComponent implements OnInit {
  @Input() phoneNumber = '';

  @Output() validCodeTyped = new EventEmitter();

  readonly showTimer$ = new BehaviorSubject<boolean>(false);
  readonly showServerError$ = new Subject<boolean>();

  readonly showInvalidCodeError$: Subject<boolean>;
  readonly otpCodeControl: FormControl;

  get seconds$(): Observable<number> {
    return this.tfaSmsTimerService.secondsForWaiting;
  }

  constructor(
    private readonly tfaSmsTimerService: TfaSmsTimerService,
    private readonly otpCodeService: OtpCodeService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    @Inject(TFA_SMS_OTP_SERVICE) private readonly tfaService: TfaSmsOtpTokenService
  ) {
    this.otpCodeControl = this.otpCodeService.control;
    this.showInvalidCodeError$ = this.otpCodeService.showError$;
  }

  ngOnInit(): void {
    this.otpCodeService.subscribeOnOtpType(this.verifyTfaSmsOtp.bind(this), 5);

    this.subscribeOnSeconds();
    this.sendTfaSmsOtp();
  }

  sendTfaSmsOtp(): void {
    if (this.tfaSmsTimerService.timerInProgress) {
      this.showTimer$.next(true);

      return;
    }

    this.tfaSmsTimerService.startTimer();
    this.showTimer$.next(true);
    this.showServerError$.next(false);
    this.tfaService
      .sendTfaSmsToken()
      .pipe(
        catchError(error => {
          if (error.status === 500) {
            this.showServerError$.next(true);
            this.tfaSmsTimerService.clearTimer();
            this.showTimer$.next(false);
          }

          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private subscribeOnSeconds(): void {
    this.tfaSmsTimerService.secondsForWaiting
      .pipe(
        tap(seconds => {
          if (seconds <= 0) {
            this.showTimer$.next(false);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private verifyTfaSmsOtp(otpToken: string): void {
    this.tfaSmsTimerService.startTimer();
    this.showTimer$.next(true);
    this.tfaService
      .verifyTfaSmsToken(otpToken)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.validCodeTyped.emit(result);
        },
        () => {
          this.otpCodeControl.enable({ emitEvent: false });
          this.showInvalidCodeError$.next(true);
        }
      );
  }
}
