import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '@app/core';

@Injectable()
export class CouponActivationService {
  constructor(private _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activateGiftCoupon$(hash: string): Observable<any> {
    return this._http.post(`${config.apiPath}/user/common/coupons/gift-coupons/activation`, {
      hash
    });
  }
}
