import { Injectable } from '@angular/core';
import { PuiDrawerService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { SetPaymentDateComponent } from '../components/set-payment-date/set-payment-date.component';
import { SetPaymentDate, SetPaymentDateResult } from '../types';

@Injectable()
export class SubscriptionPaymentDateService {
  constructor(private drawer: PuiDrawerService) {}

  setPaymentDate(data: SetPaymentDate): Observable<SetPaymentDateResult> {
    const ref = this.drawer.open<SetPaymentDateResult, SetPaymentDate>(
      SetPaymentDateComponent,
      {
        position: 'right',
        maxWidth: '600px'
      },
      data
    );

    return ref.afterClosed$;
  }
}
