import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { BookingStep, confirmation, ParentServices } from '@appWidget/modules/booking/consts';
import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';
import { BookingWidgetOptions } from '@appWidget/modules/booking/interfaces';
import { PUI_DIALOG_DATA, PuiDestroyService, PuiDialogRef } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingModalComponent implements OnInit, OnDestroy {
  @ViewChild(RouterOutlet, { static: true }) outlet!: RouterOutlet;

  private readonly options: BookingWidgetOptions = this.data.options;

  constructor(
    private router: Router,
    @Inject(PUI_DIALOG_DATA) private readonly data: { options: BookingWidgetOptions },
    @Inject(PuiDestroyService) private destroy$: Observable<void>,
    private dialogRef: PuiDialogRef
  ) {}

  ngOnInit() {
    /**
     * Handle {ActivationStart} and destroy outlet if is already active
     */
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof ActivationStart && event.snapshot.outlet === 'modal') {
        if (this.outlet.isActivated) {
          this.outlet.deactivate();

          /**
           * Re-Activate outlet
           */
          this.onRedirect();
        }
      }
    });

    this.onRedirect();
  }

  /**
   * Redirect to outlet
   * TODO Need refactor
   */
  private onRedirect() {
    const {
      workspaceId: workspace,
      guideId,
      guideLink,
      sessionTemplateId: service,
      type,
      packageId,
      programId
    } = this.options;
    const bookingPath = ['booking', ...(workspace ? ['t', workspace] : []), guideId || guideLink, service];

    if (type === BookingTypes.SESSION_ACCEPTED) {
      bookingPath.push(confirmation.path, 'complete');
    }
    if (type === BookingTypes.SESSION_PAY_ACCEPTED) {
      bookingPath.push(confirmation.path, 'payment');
    }

    // SOLO PACKAGE
    if (this.options.packageId && this.options.workspaceType === WorkspacesTypes.SOLO) {
      return this.router.navigate(
        [
          {
            outlets: {
              modal: this.getClearPath(['booking', guideId, service, BookingStep.SCHEDULING])
            }
          }
        ],
        {
          state: { ...this.options },
          queryParams: {
            package: packageId,
            type,
            availableSessions: this.options.serviceParent?.childrenAvailableCount
          },
          skipLocationChange: true,
          replaceUrl: false
        }
      );
    }

    // SOLO PROGRAM
    if (this.options.programId && this.options.workspaceType === WorkspacesTypes.SOLO) {
      return this.router.navigate(
        [
          {
            outlets: {
              modal: this.getClearPath(['booking', guideId, service, BookingStep.SCHEDULING])
            }
          }
        ],
        {
          state: { ...this.options },
          queryParams: {
            program: programId,
            type
          },
          skipLocationChange: true,
          replaceUrl: false
        }
      );
    }

    // TEAM PACKAGE
    if (this.options.packageId && this.options.workspaceType === WorkspacesTypes.TEAM) {
      let path = ['booking', 't', workspace, ParentServices.PACKAGE, packageId, service, BookingStep.CHOOSE_HOST];
      if (type === BookingTypes.RESCHEDULE) {
        path = ['booking', 't', workspace, guideId || guideLink, service];
      }

      return this.router.navigate(
        [
          {
            outlets: {
              modal: this.getClearPath(path)
            }
          }
        ],
        {
          state: { ...this.options },
          queryParams: {
            package: packageId,
            type,
            availableSessions: this.options.serviceParent?.childrenAvailableCount
          },
          skipLocationChange: true,
          replaceUrl: false
        }
      );
    }

    // TEAM PROGRAM
    if (this.options.programId && this.options.workspaceType === WorkspacesTypes.TEAM) {
      let path = ['booking', 't', workspace, ParentServices.PROGRAM, programId, service, BookingStep.CHOOSE_HOST];
      if (type === BookingTypes.RESCHEDULE) {
        path = ['booking', 't', workspace, guideId || guideLink, service];
      }

      return this.router.navigate(
        [
          {
            outlets: {
              modal: this.getClearPath(path)
            }
          }
        ],
        {
          state: { ...this.options },
          queryParams: {
            program: programId,
            type
          },
          skipLocationChange: true,
          replaceUrl: false
        }
      );
    }

    if (type === BookingTypes.CANCELLATION && this.options.session) {
      // TODO Temporary solution
      return this.router.navigate(
        [
          {
            outlets: {
              modal: this.getClearPath(['booking', 'v2', BookingStep.CANCELLATION, this.options.session.id])
            }
          }
        ],
        {
          skipLocationChange: true,
          replaceUrl: false,
          queryParams: {
            type,
            isModal: 'true'
          }
        }
      );
    }

    this.router.navigate(
      [
        {
          outlets: {
            modal: this.getClearPath(bookingPath)
          }
        }
      ],
      {
        state: { ...this.options },
        queryParams: {
          // Booking widget type
          type
        },
        skipLocationChange: true,
        replaceUrl: false
      }
    );
  }

  ngOnDestroy(): void {
    // Destroy Route
    this.router.navigate([{ outlets: { modal: null } }]);
  }

  private getClearPath(path: (string | number | undefined | null)[]): (string | number | undefined | null)[] {
    return path.filter(pathPart => pathPart !== undefined && pathPart !== null);
  }

  close(): void {
    this.dialogRef.close();
  }
}
