import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { SIDENAV_SLOT_PARENT } from '@app/modules/sidenav/tokens/slot-parent';
import { SidenavModeService, ViewWidgetService } from '@app/modules/sidenav/services';
import { ISidenavHeaderViewHost } from '../../interfaces';
import { SidenavHeaderSlotEnum, SidenavHostEnum } from '../../enums';
import { SidenavHeaderSlotType } from '../../types';

const SIDENAV_SLOT_PARENT_PROVIDER = {
  provide: SIDENAV_SLOT_PARENT,
  useExisting: forwardRef(() => SidenavHeaderComponent)
};

@Component({
  selector: 'app-sidenav-header',
  templateUrl: 'header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UiDestroyService, SIDENAV_SLOT_PARENT_PROVIDER]
})
export class SidenavHeaderComponent implements ISidenavHeaderViewHost, AfterViewInit {
  readonly name = SidenavHostEnum.Header;

  readonly slots: SidenavHeaderSlotType[] = [
    SidenavHeaderSlotEnum.Logo,
    SidenavHeaderSlotEnum.Current,
    SidenavHeaderSlotEnum.WorkspaceSwitch
  ];

  @ViewChild('logo')
  // @ts-expect-error TS2564
  logo: CdkPortalOutlet;

  @ViewChild('current')
  // @ts-expect-error TS2564
  current: CdkPortalOutlet;

  @ViewChild('workspaceSwitch')
  // @ts-expect-error TS2564
  workspaceSwitch: CdkPortalOutlet;

  constructor(
    readonly service: ViewWidgetService<SidenavHeaderSlotType>,
    readonly mode$: SidenavModeService,
    private readonly _destroy$: UiDestroyService,
    private readonly _elRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this.service.attachHost(this);
    this.service.observeWidgets$(this).pipe(takeUntil(this._destroy$)).subscribe();
  }

  getOrigin(): ElementRef {
    return this._elRef;
  }
}
