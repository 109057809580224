import { ITagLike } from '@app/screens/find-your-guide/components/find-your-guide-home/components/language-tags/language-tags.component';

import { ITag } from '../interfaces/tag';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function sortTags(unsortedTags: ITag[]) {
  const tags = [];

  for (const parent of unsortedTags) {
    if (parent.parents) {
      continue;
    }

    tags.push(parent);

    for (const tag of unsortedTags) {
      if (!tag.parents) {
        continue;
      }

      if (tag.parents.includes(parent.id)) {
        tags.push(tag);
      }
    }
  }

  return tags;
}

export function sortPlatformLanguagesByName(languages: ITagLike[]): ITagLike[] {
  return languages ? languages.sort((tagA, tagB) => tagA.name.localeCompare(tagB.name)) : [];
}
