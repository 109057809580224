import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuiButtonModule, PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { ChangeTimezoneModalComponent } from './change-timezone-modal.component';
import { ChangeTimezoneModalService } from './change-timezone-modal.service';

@NgModule({
  imports: [CommonModule, PuiTypographyModule, PuiIconModule, PuiButtonModule],
  declarations: [ChangeTimezoneModalComponent],
  providers: [ChangeTimezoneModalService],
  exports: [ChangeTimezoneModalComponent]
})
export class ChangeTimezoneModalModule {}
