import {
  SessionTemplateAvailabilityOverride,
  SessionTemplateAvailabilityOverrides
} from '@app/screens/guide/guide-sessions-templates/types';

export interface ProfiOverrideFlat {
  date: string;
  values: {
    startTime: string;
    endTime: string;
  }[];
}

export class OverridesMapper {
  static fromCalToProfi(overrides: SessionTemplateAvailabilityOverrides): ProfiOverrideFlat[] {
    if (!overrides) {
      return [];
    }

    const profiOverride: {
      [date: string]: {
        startTime: string;
        endTime: string;
      }[];
    } = {};

    overrides.forEach(({ date, startTime, endTime }: SessionTemplateAvailabilityOverride) => {
      if (profiOverride.hasOwnProperty(date)) {
        profiOverride[date].push({ startTime, endTime });
      } else {
        profiOverride[date] = [{ startTime, endTime }];
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const profiOverrideFlat: ProfiOverrideFlat[] = Object.keys(profiOverride)
      .map(key => {
        return {
          date: key,
          values: profiOverride[key]
        };
      })
      .sort((fistOverride, secondOverride) => {
        return fistOverride.date > secondOverride.date ? 1 : -1;
      });

    return profiOverrideFlat;
  }

  static fromProfiToCal(overrides: ProfiOverrideFlat[]): SessionTemplateAvailabilityOverrides {
    const calOverride: SessionTemplateAvailabilityOverrides = [];

    overrides.forEach((override: ProfiOverrideFlat) => {
      override.values.forEach((date: { startTime: string; endTime: string }) => {
        calOverride.push({
          date: override.date,
          startTime: date.startTime,
          endTime: date.endTime
        });
      });
    });

    return calOverride;
  }
}
