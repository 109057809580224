import { ModuleTypes } from '../../program-module';
import { ContentModule } from './content-module';
import { GroupSessionModule } from './group-session-module';
import { QuizModule } from './quiz-module';
import { RestrictedModule } from './restricted-module';
import { SessionModule } from './session-module';
import { ModuleAccessTypes } from '@app/screens/guide/guide-programs/types';

export type ProgramModule = RestrictedModule | ContentModule | QuizModule | GroupSessionModule | SessionModule;

export function isContentModule(programModule: Readonly<ProgramModule>): programModule is ContentModule {
  return programModule.type === ModuleTypes.CONTENT && programModule.status !== 'restricted';
}

export function isGroupSessionModule(programModule: Readonly<ProgramModule>): programModule is GroupSessionModule {
  return programModule.type === ModuleTypes.GROUP_SESSION && programModule.status !== 'restricted';
}

export function isQuizModule(programModule: Readonly<ProgramModule>): programModule is QuizModule {
  return programModule.type === ModuleTypes.QUIZ && programModule.status !== 'restricted';
}

export function isRestrictedModule(programModule: Readonly<ProgramModule>): programModule is RestrictedModule {
  return programModule.status === ModuleAccessTypes.RESTRICTED;
}

export function isSessionModule(programModule: Readonly<ProgramModule>): programModule is SessionModule {
  return programModule.type === ModuleTypes.SESSION && programModule.status !== 'restricted';
}
