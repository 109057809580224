<ng-container [ngSwitch]="activeStep">
  <ng-template #dateSelect [ngSwitchCase]="ReschedulingSteps.RESCHEDULING_DETAILS">
    <app-session-time-picker
      [options]="reschedulingOptions"
      (timeSelect)="onNewDateChange($event)"></app-session-time-picker>
  </ng-template>
  <ng-template #messageInput [ngSwitchCase]="ReschedulingSteps.MESSAGE">
    <app-rescheduling-message
      *ngIf="activeStep === ReschedulingSteps.MESSAGE"
      [user]="reschedulingOptions.user"
      (attachMessage)="onMessageChange($event)"></app-rescheduling-message>
  </ng-template>
  <ng-template #paymentInfo [ngSwitchCase]="ReschedulingSteps.PAYMENT">
    <app-pay-with-selector
      [amount]="reschedulingOptions.price"
      (paymentSelected)="onPaymentInfoChange()"></app-pay-with-selector>
  </ng-template>
</ng-container>
