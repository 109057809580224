import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PaymentOptions } from '@app/shared/enums/payment-options';

@Component({
  selector: 'app-service-payment-options-form',
  templateUrl: './service-payment-options-form.component.html',
  styleUrls: ['./service-payment-options-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicePaymentOptionsFormComponent<T> {
  @Input()
  // @ts-expect-error TS2564
  options: T;

  @Output()
  paymentOptionsInfoChange = new EventEmitter<PaymentOptions>();

  // @ts-expect-error TS7008
  @ViewChild('paymentOptionSelector', { static: false }) paymentOptionSelector;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  submit() {
    this.paymentOptionsInfoChange.emit(this.paymentOptionSelector.paymentOption);
  }
}
