import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SupportedCountries } from '@app/modules/billing/types';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { NotificationsService } from 'angular2-notifications';
import config from '../../../core/config/config';
import { IBillingInfo } from '../interfaces';

interface SupportedCountriesResponse {
  countries: SupportedCountries;
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private readonly replacementExtension = '{ACTIVE_ROLE}';

  private API_ENDPOINT = `${config.apiPath}/user/${this.replacementExtension}/billing`;

  // @ts-expect-error TS2345
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _billingInfoBeh$: BehaviorSubject<IBillingInfo | null> = new BehaviorSubject<IBillingInfo>(null);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _countriesBeh$: BehaviorSubject<SupportedCountries> = new BehaviorSubject<SupportedCountries>([]);

  billingInfo$: Observable<IBillingInfo | null> = this._billingInfoBeh$.asObservable();

  countries$: Observable<SupportedCountries> = this._countriesBeh$.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly _authService: AuthService,
    private readonly _formBuilder: FormBuilder,
    private readonly _notifications: NotificationsService
  ) {
    _authService.onAuth().subscribe(() => {
      const activeRole: UserRoles = this._authService.getUserRoleId();
      switch (activeRole) {
        case UserRoles.CLIENT:
          this.API_ENDPOINT = this.API_ENDPOINT.replace(this.replacementExtension, 'client');
          break;
        case UserRoles.GUIDE:
          this.API_ENDPOINT = this.API_ENDPOINT.replace(this.replacementExtension, 'guide');
          break;
      }
    });
  }

  getSupportedCountries$(): Observable<SupportedCountries> {
    return this._http
      .get<SupportedCountriesResponse>(`${this.API_ENDPOINT}/supported-countries`)
      .pipe(map((response: SupportedCountriesResponse) => response.countries));
  }

  getBillingValue(): IBillingInfo {
    // @ts-expect-error TS2322
    return this._billingInfoBeh$.getValue();
  }

  requestBillingInfo(): Observable<IBillingInfo> {
    if (this._authService.isPlatformAdmin()) {
      return of();
    }
    return this._http
      .get<IBillingInfo>(this.API_ENDPOINT)
      .pipe(tap((billingInfo: IBillingInfo) => this._billingInfoBeh$.next(billingInfo)));
  }

  getBillingForm$(): Observable<FormGroup> {
    return this.requestBillingInfo().pipe(
      switchMap((billingInfo: IBillingInfo) =>
        this.getSupportedCountries$().pipe(
          map((countries: SupportedCountries) => {
            return { billingInfo, countries };
          })
        )
      ),
      tap(billingWithCountries => {
        this._billingInfoBeh$.next(billingWithCountries.billingInfo);
        this._countriesBeh$.next(billingWithCountries.countries);
      }),
      map(billingWithCountries => {
        const { billingInfo, countries } = billingWithCountries;
        return billingWithCountries.billingInfo
          ? this._formBuilder.group({
              country: new FormControl(
                // eslint-disable-next-line id-length
                countries.find(c => c.id === billingInfo.countryId),
                [Validators.required]
              ),
              addressLine1: new FormControl(billingInfo.addressLine1, [Validators.required]),
              addressLine2: new FormControl(billingInfo.addressLine2),
              addressLine3: new FormControl(billingInfo.addressLine3),
              postalCode: new FormControl(billingInfo.postalCode, [Validators.required]),
              city: new FormControl(billingInfo.city, [Validators.required]),
              taxId: new FormControl(billingInfo.taxId)
            })
          : this.defaultForm();
      })
    );
  }

  updateBillingRequest(billingInfo: IBillingInfo): Observable<unknown> {
    return this._http.put(this.API_ENDPOINT, billingInfo).pipe(switchMap(() => this.requestBillingInfo()));
  }

  updateBillingForm(billingInfo: IBillingInfo): void {
    this.updateBillingRequest(billingInfo).subscribe(() => {
      const message = `Billing Data Saved`;
      this._notifications.success(message);
    });
  }

  defaultForm(): FormGroup {
    return this._formBuilder.group({
      country: new FormControl(null, [Validators.required]),
      addressLine1: new FormControl(null, [Validators.required]),
      addressLine2: new FormControl(null),
      addressLine3: new FormControl(null),
      postalCode: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      taxId: new FormControl(null)
    });
  }
}
