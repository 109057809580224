import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-weekdays-selector',
  templateUrl: './weekdays-selector.component.html',
  styleUrls: ['./weekdays-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekdaysSelectorComponent),
      multi: true
    }
  ]
})
export class WeekdaysSelectorComponent implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value = '';
  weekdays = [
    { name: 'Monday', active: false },
    { name: 'Tuesday', active: false },
    { name: 'Wednesday', active: false },
    { name: 'Thursday', active: false },
    { name: 'Friday', active: false },
    { name: 'Saturday', active: false },
    { name: 'Sunday', active: false }
  ];

  @Input()
  disabled = false;

  @Input()
  set value(val: string) {
    val.split('').forEach(i => {
      this.weekdays[+i - 1].active = true;
    });

    this._value = this.getValue();
    this._onChange(val);
    this._onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get value() {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onTouched: any = () => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  writeValue(value) {
    // @ts-expect-error TS7006
    (value || '').split('').forEach(i => {
      this.weekdays[+i - 1].active = true;
    });

    this._value = this.getValue();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  weekdayChanged(i: number) {
    this.weekdays[i].active = !this.weekdays[i].active;
    this.value = this.getValue();
  }

  private getValue(): string {
    // eslint-disable-next-line id-length
    return this.weekdays.reduce((a, item, index) => (item.active ? a + (index + 1).toString() : a), '');
  }
}
