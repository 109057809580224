<div *ngIf="startControl && endControl" class="work-time-selectors">
  <div class="work-time-selectors__wrapper">
    <pui-select
      [formControl]="startControl"
      class="work-time-selectors__selector"
      [maxHeight]="230"
      [virtualScroll]="false">
      <pui-option
        class="work-time-selectors__option"
        *ngFor="let option of startHours"
        [value]="option.iso"
        [label]="option.label"></pui-option>
    </pui-select>
    <span class="work-time-selectors__delimiter">—</span>
    <pui-select
      [formControl]="endControl"
      class="work-time-selectors__selector"
      [maxHeight]="230"
      [virtualScroll]="false">
      <pui-option
        class="work-time-selectors__option"
        *ngFor="let option of getEndTime(startControl.value)"
        [value]="option.iso"
        [label]="option.label"></pui-option>
    </pui-select>
  </div>

  <pui-icon
    *ngIf="index !== 0"
    class="action-button action-button_remove"
    (click)="onRemoveControl()"
    svgIcon="pui:trash"></pui-icon>
</div>
