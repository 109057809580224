import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IProgramServiceDetails } from '@app/screens/guide/guide-programs/types';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';

@Component({
  selector: 'app-module-service-details',
  templateUrl: './module-service-details.component.html',
  styleUrls: ['./module-service-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleServiceDetailsComponent {
  @Input() serviceDetails: IProgramServiceDetails | undefined;

  @Input() serviceDraft: ProgramSessionTemplateInterface | undefined;

  @Input() showRecurringAlert = false;

  @Input() disabled = true;

  @Output() detailsClick = new EventEmitter<void>();
}
