<ng-container *ngIf="module.service">
  <app-module-service [moduleService]="module.service"></app-module-service>
  <app-module-individual-session
    class="module-session"
    [instructor]="module.instructor"
    [session]="module.session"
    [module]="module"
    (book)="book.emit()"
    (reschedule)="reschedule.emit()"
    (cancel)="cancel.emit()"></app-module-individual-session>
</ng-container>
