<div class="modal-body">
  <form [formGroup]="uploadIframeForm" class="upload-iframe-form">
    <div class="form-group">
      <label for="iframeUrl">Add link to your frame</label>
      <input type="text" class="form-control" formControlName="iframeUrl" id="iframeUrl" />
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button className="btn-primary" (click)="save()" [disabled]="!uploadIframeForm.valid">Insert</app-button>
  <app-button className="btn-lite" (click)="cancel()">Cancel</app-button>
</div>
