import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPublicSessionTemplate } from '@app/shared/interfaces/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-template-preview-modal',
  templateUrl: './session-template-preview-modal.component.html',
  styleUrls: ['./session-template-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTemplatePreviewModalComponent {
  @Input()
  template!: IPublicSessionTemplate;

  constructor(public modal: NgbActiveModal) {}
}
