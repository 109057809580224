import { ChangeDetectorRef, Directive, Inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  ScreenSize,
  ScreenState,
  StyleBreakpointsProviderService
} from '@app/modules/ui-kit/_base/_common/screen/services/style-breakpoints-provider.service';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';

@Directive()
export abstract class ScreeSelector<TSize extends ScreenSize> implements OnInit {
  abstract screens: TSize[];

  abstract comparor(params: Partial<ScreenState<TSize>>): boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected readonly templateRef: TemplateRef<any>,
    protected readonly viewContainer: ViewContainerRef,
    protected readonly breakpoints: StyleBreakpointsProviderService,
    protected readonly cdr: ChangeDetectorRef,
    @Inject(UiDestroyService) protected destroy$: UiDestroyService
  ) {}

  ngOnInit(): void {
    this._init();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _init(): void {
    this.breakpoints
      .observe(this.screens)
      .pipe(map(this.comparor), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(this._render);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _render = (state: boolean): void => {
    if (state) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdr.markForCheck();
    } else {
      this.viewContainer.clear();
    }
  };
}
