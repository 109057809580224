import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-client-session-status-label',
  templateUrl: './client-session-status-label.component.html',
  styleUrls: ['./client-session-status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionStatusLabelComponent {
  @Input()
  // @ts-expect-error TS2564
  label: string;
}
