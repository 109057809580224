export enum UiKitPhrases {
  ClientSelectorDefaultPlaceholder = 'Choose client',
  ClientSelectorSelectAll = 'Select all',
  ClientSelectorInvite = 'invite',
  ClientSelectorViaEmail = 'via email',
  ClientSelectorNothingFound = 'Nothing found',
  ClientSelectorChooseInstructor = 'Choose instructor',
  CountrySelectorDefaultPlaceholder = 'Choose country'
}

export const PHRASES: Record<UiKitPhrases, string> = {
  [UiKitPhrases.ClientSelectorDefaultPlaceholder]: `Choose clients`,
  [UiKitPhrases.ClientSelectorSelectAll]: `Select all`,
  [UiKitPhrases.ClientSelectorInvite]: `Invite`,
  [UiKitPhrases.ClientSelectorViaEmail]: `via email`,
  [UiKitPhrases.ClientSelectorNothingFound]: `Nothing found`,
  [UiKitPhrases.ClientSelectorChooseInstructor]: `Choose Instructor`,
  [UiKitPhrases.CountrySelectorDefaultPlaceholder]: `Choose country`
};
