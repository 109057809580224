import { shareReplay, tap } from 'rxjs/operators';
import { of } from 'rxjs';

interface MemoStorageMap extends Pick<Map<any, any>, 'has' | 'get' | 'set'> {}

export function MemoizeStorageStrategy(storage: Map<any, any> | Storage | Cache): MemoStorageMap {
  if (storage instanceof Map) {
    return storage;
  }

  if (storage instanceof Storage) {
    const map: MemoStorageMap = {
      has: (key: string) => {
        return storage.getItem(key) !== null;
      },
      get: (key: string) => {
        const item = storage.getItem(key);

        if (item) {
          return JSON.parse(item);
        }

        return undefined;
      },
      // @ts-ignore
      set: (key: string, value: any) => {
        storage.setItem(key, JSON.stringify(value));
      }
    };

    return map;
  }

  throw 'Error {}';
}

export function memo$(storage?: MemoStorageMap, prefix?: string) {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = memoize$(oldFunc, storage, prefix);

    descriptor.value = function () {
      return newFunc.apply(this, arguments as any);
    };
  };
}

export function memoize$(fn: Function, cache: MemoStorageMap = new Map(), prefix: string = '_'): Function {
  return function (): any {
    const key = `${prefix}:${JSON.stringify(arguments)}`;

    if (cache.has(key)) {
      return of(cache.get(key));
    }

    // @ts-ignore
    return fn.apply(this, arguments).pipe(
      shareReplay(),
      tap(result => {
        cache.set(key, result);
      })
    );
  };
}
