import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { ExternalCalendarsService } from '@app/core/calendars/external-calendars.service';
import { ExternalCalendarSources, IExternalAccount, IExternalCalendar } from '@app/core/calendars/types';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-account',
  templateUrl: './calendar-account.component.html',
  styleUrls: ['../../styles/source-card-common.scss', './calendar-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'calendar-account'
  },
  providers: [PuiDestroyService]
})
export class CalendarAccountComponent {
  @Input()
  // @ts-expect-error TS2564
  account: IExternalAccount;

  @Input() source: ExternalCalendarSources = ExternalCalendarSources.GOOGLE;

  @Output()
  disconnect = new EventEmitter<number>();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly calendarsService: ExternalCalendarsService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  changeSync(calendar: IExternalCalendar, event: Event): void {
    calendar.isSyncInProcess = true;
    this.changeDetector.markForCheck();

    this.calendarsService
      .changeCalendarSyncMode$(calendar.id, this.source, (event.target as HTMLInputElement).checked)
      .pipe(
        finalize(() => {
          calendar.isSyncInProcess = false;

          this.changeDetector.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
