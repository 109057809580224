import { Pipe, PipeTransform } from '@angular/core';
import { SessionTypes } from '@app/shared/enums/session-types';

@Pipe({
  name: 'paymentPendingDate'
})
export class PaymentPendingDatePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(transaction: any): string {
    const paymentTime =
      transaction.service.type === SessionTypes.SESSION || transaction.service.type === SessionTypes.GROUP_SESSION
        ? new Date(transaction.service.date).getTime() + transaction.service.duration * 60 * 1000
        : new Date(transaction.payment.date).getTime();
    const moneyExpectedAt = paymentTime + 24 * 60 * 60 * 1000;
    const hoursLeft = Math.ceil((moneyExpectedAt - new Date().getTime()) / 1000 / 60 / 60);

    if (hoursLeft < 0) {
      return '';
    }

    let daysLeft = Math.ceil(hoursLeft / 24);
    daysLeft -= daysLeft > 1 ? 1 : 0;

    return `${hoursLeft < 24 ? hoursLeft + 'h' : daysLeft + 'd'}`;
  }
}
