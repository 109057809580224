import { ProgramStartType } from '@app/shared/interfaces/programs';
import { ModuleCompletionTypes } from '@app/shared/interfaces/programs/program-module';
import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
import { WithServicePermission, WithWorkspaceID } from '@app/base';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';
import { IIdentity } from './util-types';
import { ProgramStatuses } from './program-content';
import { DateTime } from 'luxon';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramSettings extends Partial<WithServicePermission>, Partial<WithWorkspaceID> {
  disableContentAfterComplete: boolean | null;
  disableContentAfterRemoval: boolean | null;
  hasCommonChat: boolean | null;
  hidePrice: boolean | null;
  isFree: boolean;
  fixedPrice: boolean;
  length: string | null;
  moduleCompletionType: ModuleCompletionTypes | null;
  price: number | null;
  subscriptionPrice: number | null;
  subscriptionRecurrency: SubscriptionRecurrency | null;
  startDate: string | null;
  startType: ProgramStartType | null;
  status: ProgramStatuses | null;
  isHiddenForBook: boolean | null;
  totalPayments: number | null;
}

export type IProgramSettingsPatch = IIdentity & Partial<IProgramSettings>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IDeactivationProps {
  subscriptionDeactivated: boolean | null;
  subscriptionDeactivatedDate: string | null;
}

export class ProgramSettings
  implements IProgramSettings, Partial<IIdentity>, IDeactivationProps, Partial<WithServicePermission>
{
  id?: number;

  disableContentAfterComplete: boolean | null;

  disableContentAfterRemoval: boolean | null;

  hasCommonChat: boolean | null;

  hidePrice: boolean | null;

  isFree: boolean;

  fixedPrice: boolean;

  length: string | null;

  // @ts-expect-error TS2564
  moduleCompletionType: ModuleCompletionTypes | null;

  price: number | null;

  subscriptionPrice: number | null;

  subscriptionRecurrency: SubscriptionRecurrency | null;

  subscriptionDeactivated: boolean | null;

  subscriptionDeactivatedDate: string | null;

  startDate: string | null;

  startType: ProgramStartType | null;

  status: ProgramStatuses | null;

  totalPayments: number | null;

  permission: ServiceAssigneePermission;

  isHiddenForBook: boolean | null;

  constructor() {
    this.disableContentAfterComplete = null;
    this.disableContentAfterRemoval = null;
    this.hasCommonChat = null;
    this.hidePrice = null;
    this.isFree = true;
    this.fixedPrice = true;
    this.length = null;
    this.price = null;
    this.subscriptionPrice = null;
    this.subscriptionRecurrency = null;
    this.subscriptionDeactivated = null;
    this.subscriptionDeactivatedDate = null;
    this.startDate = null;
    this.startType = null;
    this.status = null;
    this.totalPayments = null;
    // @ts-expect-error TS2322
    this.permission = null;
    this.isHiddenForBook = null;
  }

  static clean(settings: IProgramSettings): IProgramSettings {
    return {
      disableContentAfterComplete: settings.disableContentAfterComplete,
      disableContentAfterRemoval: settings.disableContentAfterRemoval,
      hasCommonChat: settings.hasCommonChat,
      hidePrice: settings.hidePrice,
      fixedPrice: settings.fixedPrice,
      isFree: settings.isFree,
      length: (settings.length && settings.length.trim()) || null,
      moduleCompletionType: settings.moduleCompletionType,
      price: settings.price,
      subscriptionPrice: settings.subscriptionPrice,
      subscriptionRecurrency: settings.subscriptionRecurrency,
      startDate: (settings.startDate && DateTime.fromISO(settings.startDate).toISO({ includeOffset: false })) || null,
      startType: settings.startType,
      status: settings.status,
      isHiddenForBook: settings.isHiddenForBook,
      totalPayments: settings.totalPayments,
      permission: settings.permission
    };
  }

  clone(): ProgramSettings {
    const settingsClone = new ProgramSettings();

    settingsClone.disableContentAfterComplete = this.disableContentAfterComplete;
    settingsClone.disableContentAfterRemoval = this.disableContentAfterRemoval;
    settingsClone.hasCommonChat = this.hasCommonChat;
    settingsClone.hidePrice = this.hidePrice;
    settingsClone.isFree = this.isFree;
    settingsClone.fixedPrice = this.fixedPrice;
    settingsClone.length = this.length;
    settingsClone.moduleCompletionType = this.moduleCompletionType;
    settingsClone.price = this.price;
    settingsClone.subscriptionRecurrency = this.subscriptionRecurrency;
    settingsClone.subscriptionPrice = this.subscriptionPrice;
    settingsClone.startDate = this.startDate;
    settingsClone.startType = this.startType;
    settingsClone.status = this.status;
    settingsClone.totalPayments = this.totalPayments;
    settingsClone.permission = this.permission;
    settingsClone.isHiddenForBook = this.isHiddenForBook;

    return settingsClone;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDiffFrom(newSettings: IProgramSettings): any | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const diff: any = { id: this.id, fixedPrice: this.fixedPrice };

    [
      'disableContentAfterComplete',
      'disableContentAfterRemoval',
      'hasCommonChat',
      'hidePrice',
      'isFree',
      'fixedPrice',
      'length',
      'moduleCompletionType',
      'price',
      'subscriptionPrice',
      'subscriptionRecurrency',
      'startDate',
      'startType',
      'status',
      'isHiddenForBook',
      'totalPayments'
    ].forEach(prop => {
      // @ts-expect-error TS7053
      if (this[prop] !== newSettings[prop]) {
        // @ts-expect-error TS7053
        diff[prop] = newSettings[prop];
      }
    });

    if (diff.subscriptionPrice && !diff.subscriptionRecurrency) {
      diff.subscriptionRecurrency = newSettings.subscriptionRecurrency;
    }

    if (diff.subscriptionRecurrency && !diff.subscriptionPrice) {
      diff.subscriptionPrice = newSettings.subscriptionPrice;
    }

    return Object.keys(diff).length > 1 ? diff : null;
  }

  setValues(settings: IProgramSettings & IIdentity & Partial<IDeactivationProps>): ProgramSettings {
    this.id = settings.id;
    this.disableContentAfterComplete = settings.disableContentAfterComplete;
    this.disableContentAfterRemoval = settings.disableContentAfterRemoval;
    this.hasCommonChat = settings.hasCommonChat;
    this.hidePrice = settings.hidePrice;
    this.isFree = settings.isFree;
    this.fixedPrice = settings.fixedPrice;
    this.length = settings.length;
    this.moduleCompletionType = settings.moduleCompletionType;
    this.price = settings.price;
    this.subscriptionPrice = settings.subscriptionPrice;
    this.subscriptionRecurrency = settings.subscriptionRecurrency;
    // @ts-expect-error TS2322
    this.subscriptionDeactivated = settings.subscriptionDeactivated;
    // @ts-expect-error TS2322
    this.subscriptionDeactivatedDate = settings.subscriptionDeactivatedDate;
    this.startDate = settings.startDate;
    this.startType = settings.startType;
    this.status = settings.status;
    this.isHiddenForBook = settings.isHiddenForBook;
    this.totalPayments = settings.totalPayments;
    // @ts-expect-error TS2322
    this.permission = settings.permission;

    return this;
  }
}
