import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { map } from 'rxjs/operators';
import { ProgramGuide } from '@app/shared/interfaces/programs';

@Injectable()
export class GuideProfileService {
  constructor(private _http: HttpClient) {}

  getPublicProfile$(): Observable<ProgramGuide> {
    return this._http
      .get<unknown>(`${config.apiPath}/user/guide/profile/public`)
      .pipe(
        map(
          ({ profile }) =>
            new ProgramGuide(
              profile.UserId,
              profile.firstName,
              profile.lastName,
              profile.photo,
              profile.namedUrl,
              profile.specialization,
              profile.shortQuote
            )
        )
      );
  }
}
