import { exhaustMap, pluck, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  Availability,
  AvailabilityService,
  GetAvailabilityRequest
} from '@libs/services/http/http-availability.service';
import { ComponentStore } from '@ngrx/component-store';

export interface AvailabilityState {
  status: 'initial' | 'loading' | 'idle';
  availability: Availability;
}

const initialState: AvailabilityState = {
  status: 'initial',
  availability: {}
};

export interface GetAvailabilityParameters extends GetAvailabilityRequest {}

@Injectable()
export class AvailabilityStore extends ComponentStore<AvailabilityState> {
  /**
   * That property allow to store availability time ONLY for one guide.
   * @private
   */
  private prevGuideId?: number;
  readonly isIdle$ = this.select(state => state.status === 'idle');
  readonly isLoading$ = this.select(state => state.status === 'loading');

  readonly availability$ = this.select(state => state.availability);
  readonly availabilityDates$ = this.select(this.availability$, availability => Object.keys(availability));
  readonly lastAvailabilityDate$ = this.select(this.availabilityDates$, dates =>
    dates.length > 0 ? dates[dates.length - 1] : undefined
  );

  /**
   * Actions.
   */

  /**
   * It will fetch availability data.
   */
  readonly getAvailability = this.effect<GetAvailabilityParameters>(parameters$ =>
    parameters$.pipe(
      tap(() => this.patchState({ status: 'loading' })),
      exhaustMap(parameters =>
        this.availabilityService.getAvailability(parameters).pipe(
          pluck('slots'),
          tap(availability => {
            /**
             * Merge previous guide state.
             */
            if (this.prevGuideId === parameters.hostId) {
              this.patchState(state => ({
                status: 'idle',
                availability: {
                  ...state.availability,
                  ...availability
                }
              }));
            } else {
              /**
               * Set state for a new guide.
               */
              this.prevGuideId = parameters.hostId;

              this.patchState({
                status: 'idle',
                availability
              });
            }
          })
        )
      )
    )
  );

  constructor(private availabilityService: AvailabilityService) {
    super(initialState);
  }

  /**
   * Reset state to initial value.
   */
  reset() {
    this.setState(initialState);
  }
}
