import { GuideContact } from '@app/core/users/types';
import { BlockedInterval } from '@app/screens/guide/guide-sessions/services/schedule';
import {
  FullCalendarCustomEventCssClasses,
  FullCalendarEventType,
  FullCalendarSessionEventTypes
} from '@app/screens/guide/guide-sessions/types';
import { FullCalendarUnavailableTimeEventTypes } from '@app/screens/guide/guide-sessions/types/full-calendar-blocked-time-events';
import { isCanceledOrDeclinedStatus, isOfferOrPending, SessionStatuses } from '@app/shared/enums/session-statuses';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { GroupSession, isSimpleSession } from '@app/shared/interfaces/session';

import { fullCalendarEventTypesClassNames } from './full-calendar-data';

// @ts-expect-error TS7019
export function compose(...fns) {
  // @ts-expect-error TS7006
  return function (arg) {
    // eslint-disable-next-line id-length
    return fns.reduceRight((curVal, f) => f(curVal), arg);
  };
}

export const getBlockedIntervalType = (
  interval: BlockedInterval,
  guideId: number
): FullCalendarUnavailableTimeEventTypes.MY_TIME_BLOCK | FullCalendarUnavailableTimeEventTypes.OTHERS_TIME_BLOCK => {
  return guideId === interval.userId
    ? FullCalendarUnavailableTimeEventTypes.MY_TIME_BLOCK
    : FullCalendarUnavailableTimeEventTypes.OTHERS_TIME_BLOCK;
};

export const getServiceTypeClass = (service: GroupSession): FullCalendarCustomEventCssClasses => {
  if (service.sessions && service.sessions.length) {
    const [session] = service.sessions;

    if (session.serviceParent?.type === GuideServiceTypes.PROGRAM) {
      return FullCalendarCustomEventCssClasses.PROGRAM_EVENT;
    }

    if (session.serviceParent?.type === GuideServiceTypes.PACKAGE) {
      return FullCalendarCustomEventCssClasses.PACKAGE_EVENT;
    }
  }

  return FullCalendarCustomEventCssClasses.GROUP_SESSION;
};

export const getTitle = (service: GroupSession): string => {
  const participantsCount = service.sessions?.filter(session => !isCanceledOrDeclinedStatus(session.status)).length;

  let title = service.sessions && service.sessions.length ? `${service.name} (${participantsCount})` : service.name;

  if (!service.isSeatsPerTimeSlot && service.sessions?.length === 1 && isSimpleSession(service.sessions[0])) {
    const [session] = service.sessions;

    title = session.user.name || (session.user as GuideContact)?.contacts?.email || '';
  }

  return title || '';
};

export const getSessionStatusClasses = (
  service: GroupSession,
  type?: FullCalendarEventType
): FullCalendarCustomEventCssClasses[] => {
  let classes: FullCalendarCustomEventCssClasses[] = [];

  if (service.sessions && service.sessions.length) {
    const statuses = service.sessions.map(instance => instance.status);

    // Offer Or Pending statuses classes
    // Note: shouldn't be pending if at least one session status approved
    if (
      statuses.filter(status => isOfferOrPending(status) || isCanceledOrDeclinedStatus(status)).length ===
      statuses.length
    ) {
      classes = classes.concat(fullCalendarEventTypesClassNames[FullCalendarSessionEventTypes.SESSION_OFFER]);
    }

    if (statuses.includes(SessionStatuses.RESCHEDULE_BY_GUIDE) && !statuses.includes(SessionStatuses.APPROVED)) {
      classes = classes.concat(fullCalendarEventTypesClassNames[FullCalendarSessionEventTypes.SESSION_OFFER]);
    }

    if (type === FullCalendarSessionEventTypes.PAST_SESSION || isExpired(statuses)) {
      classes = classes.concat(pastSessionClasses(statuses));
    }
  }

  return classes;
};

const pastSessionClasses = (statuses: SessionStatuses[]): FullCalendarCustomEventCssClasses[] => {
  const classes: FullCalendarCustomEventCssClasses[] = [];

  if (!statuses.includes(SessionStatuses.IN_PROGRESS)) {
    if (isDone(statuses)) {
      classes.push(FullCalendarCustomEventCssClasses.SESSION_DONE);
    } else if (isMissed(statuses)) {
      classes.push(FullCalendarCustomEventCssClasses.SESSION_MISSED);
    } else if (isExpired(statuses)) {
      classes.push(FullCalendarCustomEventCssClasses.SESSION_EXPIRED);
    } else {
      classes.push(FullCalendarCustomEventCssClasses.SESSION_EXPIRED);
    }
  }

  return classes;
};

const isExpired = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.EXPIRED) && !isDone(statuses) && !statuses.includes(SessionStatuses.MISSED);
};

const isMissed = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.MISSED) && !isDone(statuses);
};

const isDone = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.DONE);
};
