<div class="wrap">
  <div class="session-info with-separator">
    <div class="session-name" [ngClass]="{ 'session-request-session-name': enableSessionRequestSection }">
      {{ !enableSessionRequestSection && session ? 'Next session: ' : '' }}{{ session?.name }}
    </div>
    <div
      class="session-range"
      [ngClass]="{ 'session-request-session-range': enableSessionRequestSection }"
      *ngIf="session?.dateStart">
      {{ session | dateRange: 'EEEE, MMM dd, h:mm':"h:mmaaaaa'm'" | async }}
    </div>
  </div>
  <div class="actions" *ngIf="!session">
    <button
      class="propose-button"
      pui-button
      appearance="primary"
      size="m"
      (click)="actionHandler(actionTypes.ADD)"
      data-qa-id="in-chat-guide-session-details-propose-service-button">
      <ng-container i18n="@@proposeAService">Propose a service</ng-container>
    </button>
  </div>
  <div class="actions" *ngIf="session">
    <button
      pui-button
      appearance="primary"
      [ghost]="true"
      class="accept-button"
      *ngIf="enableSessionRequestSection"
      (click)="actionHandler(isReschedulement)"
      [ngClass]="{
        'individual-session-request': isIndividualSessionRequest,
        'group-session-request': isGroupSessionRequest
      }">
      Accept
    </button>
  </div>
</div>
<div class="actions" *ngIf="session">
  <button
    pui-dropdown
    [pui-dropdown-content]="dropdownContent"
    pui-icon-button
    size="m"
    type="button"
    [appearance]="session.status !== 'approved' ? 'adaptive' : 'flat'"
    shape="square"
    [ghost]="session.status !== 'approved'"
    [ngClass]="{ 'session-request-kebab-menu': enableSessionRequestSection }">
    <pui-icon svgIcon="pui:more-vertical"></pui-icon>
  </button>

  <ng-template #dropdownContent let-close="close">
    <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
      <button
        pui-list-item="button"
        pui-a11y-keyboard-widget-item
        *ngFor="let action of actions"
        (click)="close(); actionHandler(action.action)">
        <pui-list-item-icon>
          <pui-icon
            [appearance]="action.color"
            [svgIcon]="action.icon"
            [ngClass]="[action.className]"
            size="s"></pui-icon>
        </pui-list-item-icon>
        <span>{{ action.text }}</span>
      </button>
    </div>
  </ng-template>
</div>
