<div [formGroup]="form">
  <div formArrayName="items" *ngFor="let item of itemsFormArray.controls; let i = index">
    <div [formGroupName]="i" class="item">
      <app-checkbox-reactive>
        <input class="checkbox-input" type="checkbox" formControlName="selected" (change)="onChange(item)" />
        <span class="checkbox-label-text">{{ item.value.name }}</span>
      </app-checkbox-reactive>
    </div>
  </div>
</div>
