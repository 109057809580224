<div class="d-flex pui-space_bottom-4 justify-content-between w-100">
  <h1 pui-typography="h1" i18n="@@addSession">Add session</h1>
  <button
    *ngIf="!isPlatformAdmin && !isProgramInstructorRole"
    pui-button
    appearance="secondary"
    (click)="loadTemplate()">
    Use template
  </button>
</div>

<form [formGroup]="form" class="session-settings-form">
  <!-- Basic info -->
  <pui-collapse
    [expanded]="true"
    class="pui-space_bottom-4"
    view="accordion"
    i18n-headerText="@@SessionTemplateDrawerBasic"
    headerText="Basic">
    <ng-template pui-collapse-content>
      <div class="pui-space_left-7 scroll-margin" id="basicInfo">
        <app-basic-info-form formControlName="basicInfo" [appearance]="basicInfoAppearance"></app-basic-info-form>
      </div>
    </ng-template>
  </pui-collapse>

  <!-- Location and Hosts -->
  <ng-container *ngIf="isTeamAdmin">
    <pui-collapse
      [expanded]="true"
      class="pui-space_bottom-4"
      view="accordion"
      i18n-headerText="@@SessionTemplateDrawerLocationAndHosts"
      headerText="Location and Hosts">
      <ng-template pui-collapse-content>
        <div id="locationsAndHosts" class="pui-space_left-7 pui-space_bottom-4 scroll-margin">
          <div class="pui-space_bottom-5">
            <app-location-form formControlName="location"></app-location-form>
          </div>

          <app-hosts-form formControlName="hosts"></app-hosts-form>
        </div>
      </ng-template>
    </pui-collapse>
  </ng-container>

  <!-- Session availability -->
  <ng-container *ngIf="!isPlatformAdmin">
    <pui-collapse
      class="pui-space_bottom-4"
      view="accordion"
      i18n-headerText="@@SessionTemplateDrawerSessionAvailability"
      headerText="Session availability">
      <ng-template pui-collapse-content>
        <div id="sessionAvailability" class="pui-space_top-2 pui-space_left-7 scroll-margin">
          <app-session-availability-form
            [showTitle]="false"
            formControlName="availability"></app-session-availability-form>
        </div>
      </ng-template>
    </pui-collapse>
  </ng-container>

  <!-- Participants and guests -->
  <pui-collapse
    class="pui-space_bottom-4"
    view="accordion"
    i18n-headerText="@@SessionTemplateDrawerParticipantsAndGuests"
    headerText="Participants and guests">
    <ng-template pui-collapse-content>
      <div id="participantsAndGuests" class="pui-space_top-2 pui-space_left-7 scroll-margin">
        <app-participants-and-guests-form
          [showTitle]="false"
          formControlName="participantsAndGuests"></app-participants-and-guests-form>
      </div>
    </ng-template>
  </pui-collapse>

  <!-- Restrictions -->
  <ng-container *ngIf="!isPlatformAdmin">
    <pui-collapse
      class="pui-space_bottom-4"
      view="accordion"
      i18n-headerText="@@SessionTemplateDrawerRestrictions"
      headerText="Restrictions">
      <ng-template pui-collapse-content>
        <div id="restrictions" class="pui-space_left-7 scroll-margin">
          <app-restrictions-form
            formControlName="restrictions"
            [appearance]="restrictionsAppearance"></app-restrictions-form>
        </div>
      </ng-template>
    </pui-collapse>
  </ng-container>
</form>

<button
  [disabled]="!didFormChange()"
  class="session-settings-form__submit pui-space_top-3"
  pui-button
  appearance="primary"
  (click)="createSessionTemplateDraft()">
  <ng-container *ngIf="parentType">{{
    isModified || !isDraft ? submitButtonLabel['save'] : submitButtonLabel[parentType]
  }}</ng-container>
</button>
