<div ngbDropdown class="d-inline-block" autoClose="outside" (openChange)="onStateChange($event)">
  <div ngbDropdownToggle [class.filter-active]="filterDirty">
    <ng-content select=".filter-title"></ng-content>
  </div>
  <div ngbDropdownMenu>
    <div class="filter-content" *ngIf="opened">
      <ng-content select=".filter-form"></ng-content>
    </div>
    <div class="dropdown-divider"></div>
    <div class="filter-footer">
      <button class="filter-button filter-apply" (click)="applyFilter()" i18n="@@apply">Apply</button>
      <button class="filter-button filter-reset" *ngIf="filterDirty" (click)="resetFilter()">
        <ng-container i18n="@@reset">Reset</ng-container>
      </button>
    </div>
  </div>
</div>
