import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceConnectionTypePipe } from './service-connection-type.pipe';
import { SessionDetailsConnectionLinkPipe } from './session-details-connection-link.pipe';
import { BookingLinkPipe } from './bookingLink.pipe';
import { ServiceDurationPipe } from './service-duration.pipe';

@NgModule({
  declarations: [ServiceConnectionTypePipe, SessionDetailsConnectionLinkPipe, BookingLinkPipe, ServiceDurationPipe],
  imports: [CommonModule],
  exports: [ServiceConnectionTypePipe, SessionDetailsConnectionLinkPipe, BookingLinkPipe, ServiceDurationPipe]
})
export class BookingPipesModule {}
