<div class="auth-body">
  <h2 i18n="@@emailSent">Email Sent</h2>
  <p i18n="@@emailSentNote">An email has been sent to your email address with instructions to reset password.</p>
  <p i18n="@@emailSentNote2">Be sure to use the email link within 24 hours.</p>
  <div class="auth-buttons">
    <app-button className="btn-primary btn-lg" routerLink="/auth/sign-in">
      <ng-container i18n="@@returnToSignIn">Return to Sign In</ng-container>
    </app-button>
    <app-button className="btn-link btn-lg" routerLink="/auth/forgot-password" i18n="@@emailDidNotCome">
      Email did not come
    </app-button>
  </div>
</div>
