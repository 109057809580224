import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionStatus } from '@libs/business/cdk/session';

@Component({
  selector: 'app-client-session-status',
  templateUrl: './client-session-status.component.html',
  styleUrls: ['./client-session-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionStatusComponent {
  @Input()
  status: SessionStatus | undefined;
}
