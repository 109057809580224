export const allowedMimeTypes: string[] = [
  'application/epub+zip',
  'application/fb2',
  'application/json',
  'application/ld+json',
  'application/msword',
  'application/pdf',
  'application/php',
  'application/rtf',
  'application/vnd.amazon.ebook',
  'application/vnd.apple.mpegurl',
  'application/vnd.ms-excel',
  'application/vnd.ms-fontobject',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-abiword',
  'application/x-freearc',
  'application/x-mpegurl',
  'application/xhtml+xml',
  'application/xml',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/aac',
  'audio/midi',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
  'audio/x-midi',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/vnd.microsoft.icon',
  'image/webp',
  'image/x-icon',
  'text/calendar',
  'text/css',
  'text/csv',
  'text/html',
  'text/javascript',
  'text/plain',
  'text/xml',
  'video/3gpp',
  'video/3gpp2',
  'video/avi',
  'video/mp2t',
  'video/mp4',
  'video/mpeg',
  'video/ms-asf',
  'video/ogg',
  'video/quicktime',
  'video/webm',
  'video/x-m4v',
  'video/x-matroska',
  'video/x-ms-wmv',
  'video/x-msvideo'
];

export const restrictedFileExtensions = ['dmg', 'bat'];

export const imageFileExtensions = ['png', 'jpg', 'jpeg', 'png', 'gif', 'ico', 'svg'];

export const videoFileExtensions = ['mp4', 'webm', 'ogg', 'mov', 'mkv'];
