import { Pipe, PipeTransform } from '@angular/core';
import { IClientGuideSession } from '../types/types';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';
import { ILocale } from '@env/locale.interface';
import { LocaleService } from '@app/core/locale/locale.service';

@Pipe({
  name: 'clientGuideSessionRangeFormatter',
  pure: true
})
export class ClientGuideSessionRangeFormatterPipe implements PipeTransform {
  private locale: ILocale;

  constructor(private _localeService: LocaleService) {
    this.locale = this._localeService.getLocale();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(guideSession: IClientGuideSession, customFormat?: string): string {
    const { timezone, session } = guideSession;
    const { duration } = session;
    const dateStart = session.dateStart;

    if (!dateStart) {
      return '';
    }

    return platformDateTimeRange('DATE, TIME', {
      value: [dateStart],
      templateOptions: {
        durationInMinutes: duration,
        timezone,
        localeParams: this.locale
      }
    });
  }
}
