import { Injectable, InjectionToken } from '@angular/core';
import { PayWithStripeService } from '@app/modules/current-payment/services/pay-with-stripe.service';
import {
  LinkPayment,
  Payment,
  PaymentType,
  PaypalPayment,
  PayService,
  RequisitesPayment,
  StripePayment,
  WalletPayment
} from '../types';
import { PayWithPaypalService } from './pay-with-paypal.service';
import { PayWithOtherPaymentsService } from './pay-with-other-payments.service';

export const CURRENT_PAYMENT_TYPE = new InjectionToken<PaymentType>('CURRENT_PAYMENT_TYPE');

@Injectable({
  providedIn: 'root'
})
export class CurrentPaymentService {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _pay: PayService;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentPayment: Payment | null = null;

  get currentPayment(): Payment | null {
    return this._currentPayment;
  }

  pay(isFree = false): PayService {
    if (isFree || !this._pay) {
      return this._otherPayments; // for free products
    }

    return this._pay;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _paypal: PayWithPaypalService,
    private _stripe: PayWithStripeService,
    private _otherPayments: PayWithOtherPaymentsService
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createNewPayment(type: PaymentType) {
    switch (type) {
      case PaymentType.STRIPE:
        this._currentPayment = new StripePayment();
        break;
      case PaymentType.PAYPAL:
        this._currentPayment = new PaypalPayment();
        break;
      case PaymentType.WALLET:
        this._currentPayment = new WalletPayment();
        break;
      case PaymentType.PAYMENT_REQUISITES:
        this._currentPayment = new RequisitesPayment();
        break;
      case PaymentType.PAYMENT_LINK:
        this._currentPayment = new LinkPayment();
        break;
      default:
        this._currentPayment = null;
        break;
    }

    this._initPaySystem();

    return this._currentPayment;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clearPayment() {
    this._currentPayment = null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _initPaySystem() {
    if (!this._currentPayment) {
      // @ts-expect-error TS2322
      this._pay = null;
      return;
    }

    // @ts-expect-error TS2531
    switch (this.currentPayment.type) {
      case PaymentType.PAYPAL:
        this._pay = this._paypal;
        break;
      case PaymentType.STRIPE:
        this._pay = this._stripe;
        break;
      default:
        this._pay = this._otherPayments;
    }

    // @ts-expect-error TS2345
    this._pay.init(this.currentPayment);
  }
}
