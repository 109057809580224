import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { guideClientsFetchAll } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';
import { Injectable } from '@angular/core';
import { fetchClientTags } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/client-tags-store.actions';

@Injectable()
export class GuideClientsResolver implements Resolve<void> {
  constructor(private readonly store$: Store) {}

  resolve(): void {
    this.store$.dispatch(guideClientsFetchAll());
    this.store$.dispatch(fetchClientTags());
  }
}
