import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FindYourGuideHomeComponent } from './components/find-your-guide-home/find-your-guide-home.component';
import { SingleUrlSegmentGuard } from './guards/single-url-segment.guard';

export const routes: Routes = [
  {
    path: 'free',
    canActivate: [SingleUrlSegmentGuard],
    component: FindYourGuideHomeComponent,
    data: { free: true },
    runGuardsAndResolvers: 'always'
  },
  {
    path: '**',
    canActivate: [SingleUrlSegmentGuard],
    component: FindYourGuideHomeComponent,
    data: { free: false },
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SingleUrlSegmentGuard]
})
export class FindYourGuideRoutingModule {}
