import { DateTime } from 'luxon';
import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import config from '@app/core/config/config';
import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services';
import { AuthorisedReschedulingApplicant, ServiceReschedulingService } from '@app/modules/rescheduling-service';
import { CancelSessionModalComponent } from '@app/modules/session-cancellation/components/cancel-session-modal/cancel-session-modal.component';
import {
  ClientEventActionTypes,
  IEventActionResult,
  LinkTypes,
  SessionEvent
} from '@app/core/shared-event-actions/types';
import { ClientGuidesService } from '@app/core/users/client-guides.service';
import { ClientReschedulingSessionOptions, ReschedulingSessionDate } from '@app/modules/rescheduling-service/types';
import { ClientSessionFeedbackComponent } from '@app/shared/components/client-session-feedback/client-session-feedback.component';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IClientGuideAvailableSession, IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';
import {
  IGuideService,
  IServiceBookingOptions,
  IServiceBookingResult,
  ServiceBookingService
} from '@app/modules/book-service';
import { Session } from '@app/shared/interfaces/session';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { SessionActionResultModalComponent } from '@app/modules/session-action-result/components/session-action-result-modal/session-action-result-modal.component';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionsService } from '@app/core/session/sessions.service';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { IPayWithModalOptions } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { Router } from '@angular/router';
import {
  ConfirmSessionCancelResult,
  ConfirmSessionCancelService
} from '@app/modules/confirm-session-cancel/confirm-session-cancel.service';

@Injectable()
export class ClientSessionsActionsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  sessionOfferSucceeded$ = new Subject<void>();

  constructor(
    private сlientGuidesService: ClientGuidesService,
    private _sessions: SessionsService,
    private _modal: NgbModal,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, any>,
    private _rescheduler: ServiceReschedulingService,
    private _currentPayments: CurrentPaymentService,
    private _auth: AuthService,
    private _notificationsService: NotificationsService,
    private _router: Router,
    private readonly confirmSessionCancelService: ConfirmSessionCancelService
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  acceptOffer(clientSession: IClientGuideSession, showResult = true): void {
    const { session } = clientSession;

    const paymentInfo$ = session.isSessionFree ? of(null) : this._getPaymentInfo$('Accept offer', session.payRate);

    paymentInfo$
      .pipe(
        // @ts-expect-error TS2554
        switchMap(() => {
          return this._currentPayments.pay(session.isSessionFree).sessionOffer$(session.id);
        })
      )
      // @ts-expect-error TS2554
      .subscribe(() => {
        if (showResult) {
          this.showSessionActionResult(
            this._createClientSessionActionResult(ClientEventActionTypes.ACCEPT_REQUEST, clientSession)
          );
        }
        this.sessionOfferSucceeded$.next();
      });
  }

  approveReschedule(clientSession: IClientGuideSession, showResult = true): void {
    const { session } = clientSession;

    this._sessions.approveReschedule$(session.id, session.collectionType).subscribe(() => {
      if (showResult) {
        this.showSessionActionResult(
          this._createClientSessionActionResult(ClientEventActionTypes.ACCEPT_RESCHEDULE, clientSession)
        );
      }
    });
  }

  archiveSession(clientSession: IClientGuideSession): void {
    this._sessions.archiveSession$(clientSession.session).subscribe();
  }

  cancelSession(clientSession: IClientGuideSession, showResult = true): void {
    const { session } = clientSession;
    const startDateDiff = Math.abs(DateTime.local().diff(DateTime.fromISO(session.dateStart)).as('hours'));

    if (startDateDiff < 12 && session.isSessionFree) {
      // eslint-disable-next-line id-length, @typescript-eslint/no-unused-vars
      this._sessions.refuseSession$(session.id, null, session.collectionType, session.guideId, session).subscribe(r => {
        if (showResult) {
          this.showSessionActionResult(
            this._createClientSessionActionResult(ClientEventActionTypes.CANCEL, clientSession)
          );
        }
      });
    } else {
      this._confirmSessionCancel$(clientSession)
        .pipe(
          switchMap(msg =>
            this._sessions.refuseSession$(session.id, msg, session.collectionType, session.guideId, session)
          )
        )
        .subscribe(() => {
          if (showResult) {
            this.showSessionActionResult(
              this._createClientSessionActionResult(ClientEventActionTypes.CANCEL, clientSession)
            );
          }
        });
    }
  }

  declineOffer(clientSession: IClientGuideSession, showResult = true): void {
    const { session } = clientSession;

    this._confirmSessionOfferDecline$(clientSession)
      .pipe(
        switchMap(msg =>
          // @ts-expect-error TS2345
          this._sessions.declineSessionOffer$(session.id, msg, session.collectionType, session.guideId, session)
        )
      )
      .subscribe(() => {
        if (showResult) {
          this.showSessionActionResult(
            this._createClientSessionActionResult(ClientEventActionTypes.CANCEL, clientSession)
          );
        }
      });
  }

  findSessionAndLeaveReview(sessionDetails: { sessionId: number; privateOnly: boolean }): void {
    this._sessions.pastSessions$
      .pipe(
        take(1),
        map(pastSessions => {
          const { sessionId } = sessionDetails;
          return pastSessions.find(pastSession => pastSession.id === sessionId);
        }),
        filter(session => !!session),
        // @ts-expect-error TS2345
        switchMap(session => this._leaveReview$(session, sessionDetails.privateOnly)),
        switchMap(review => {
          const { sessionId, mark, comment, privateComment } = review;
          return this._sessions.sendFeedback$(sessionId, mark, comment, privateComment);
        }),
        tap(() => this.сlientGuidesService.refresh())
      )
      .subscribe();
  }

  reorderSession(clientSession: IClientGuideSession, analyticSourceType?: AnalyticSourceTypes): void {
    this._serviceBooking
      .book$({ guide: clientSession.guide, analyticSourceType })
      .pipe(take(1))
      .subscribe(
        (bookingSuccess: IServiceBookingResult) => {
          if (bookingSuccess.serviceType === GuideServiceTypes.PROGRAM) {
            this._router.navigate(['/client/programs', bookingSuccess.id, 'modules']);
          }
        },
        error => {
          if (error && error.error && error.error.msg) {
            this._notificationsService.error(error.error.msg);
          } else {
            this._notificationsService.error(`Failed to book`);
          }
        }
      );
  }

  rescheduleSession(clientSession: IClientGuideSession, showResult = true): void {
    const { session, guide } = clientSession;

    const reschedulingOptions = new ClientReschedulingSessionOptions(guide.id, {
      id: session.id,
      // @ts-expect-error TS2322
      name: session.name,
      collectionType: session.collectionType,
      currentDate: new ReschedulingSessionDate(session.dateStart),
      duration: session.duration,
      status: session.status,
      exEvent: session.eventId,
      serviceType: GuideServiceTypes.SESSION,
      // @ts-expect-error TS2322
      price: session.isSessionFree || session.payRate === 0 ? null : session.payRate,
      // @ts-expect-error TS2322
      serviceId: session.templateId,
      applicant: new AuthorisedReschedulingApplicant({
        id: this._auth.user.id,
        role: this._auth.user.RoleId
      }),
      user: session.user
    });

    this._rescheduler.reschedule$(reschedulingOptions).subscribe(({ newDate: { date } }) => {
      if (showResult) {
        this.showSessionActionResult(
          this._createClientSessionActionResult(ClientEventActionTypes.RESCHEDULE, clientSession, {
            oldDate: session.dateStart,
            date
          })
        );
      }
    });
  }

  showSessionActionResult(eventActionResult: IEventActionResult): void {
    this._showSessionActionResultModal$(eventActionResult).subscribe(() => {
      // ToDo https://profi-io.atlassian.net/browse/PR-3525
      this._serviceBooking._modalRef?.close();
    });
  }

  bookAvailableSession(clientSession: IClientGuideAvailableSession, analyticSourceType?: AnalyticSourceTypes): void {
    const requiresHostSelection = WorkspaceUtility.isTeam(clientSession.session.workspace);

    this._serviceBooking
      .book$({
        guide: clientSession.guide,
        service: {
          // @ts-expect-error TS2322
          id: clientSession.session.templateId,
          guideId: clientSession.guide.id,
          name: clientSession.session.name,
          type: clientSession.session.serviceType,
          // @ts-expect-error TS2322
          duration: clientSession.session.duration,
          serviceParent: clientSession.session.serviceParent,
          requiresHostSelection
        },
        requiresHostSelection,
        analyticSourceType
      })
      .subscribe(
        () => {},
        error => {
          if (error && error.error && error.error.msg) {
            this._notificationsService.error(error.error.msg);
          } else {
            this._notificationsService.error(`Failed to book`);
          }
        }
      );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _confirmSessionCancel$(clientSession: IClientGuideSession): Observable<string | null> {
    const { guide, session } = clientSession;

    const sessionStartMoment = DateTime.fromISO(session.dateStart);
    const fullRefundStartMoment = DateTime.local().plus({
      hours: config.sessionCancellationFeePeriod
    });

    const beforeConfirm$ =
      session.status === SessionStatuses.APPROVED && sessionStartMoment <= fullRefundStartMoment
        ? this.confirmSessionCancelService.open().pipe(filter(result => result === ConfirmSessionCancelResult.CANCEL))
        : of(null);

    return beforeConfirm$.pipe(
      // @ts-expect-error TS2554
      switchMap(() => {
        const { componentInstance, result } = this._modal.open(CancelSessionModalComponent);
        componentInstance.user = guide;
        componentInstance.sessionName = session.name;
        componentInstance.sessionDate = session.dateStart;
        return modalResultToObservable$(result);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _confirmSessionOfferDecline$(clientSession: IClientGuideSession): Observable<string | null> {
    const { guide, session } = clientSession;

    const { componentInstance, result } = this._modal.open(CancelSessionModalComponent);
    componentInstance.user = guide;
    componentInstance.cancellationType = 'decline';
    componentInstance.sessionName = session.name;
    componentInstance.sessionDate = session.dateStart;
    return modalResultToObservable$(result);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createClientSessionActionResult(
    actionType: ClientEventActionTypes,
    clientSession: IClientGuideSession,
    replacements = {}
  ): IEventActionResult {
    const { session, guide } = clientSession;

    return {
      complete: true,
      actionType,
      eventDetails: new SessionEvent({
        date: session.dateStart,
        duration: session.duration,
        // eslint-disable-next-line id-length
        links: session.zoomMeetings ? session.zoomMeetings.map(m => ({ ...m, type: LinkTypes })) : null,
        status: session.status,
        name: session.name,
        user: guide,
        ...replacements
      })
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _leaveReview$(session: Session, privateOnly = false): Observable<any> {
    const { componentInstance, result } = this._modal.open(ClientSessionFeedbackComponent, {
      keyboard: false,
      centered: true,
      backdrop: 'static',
      windowClass: `session-feedback-modal`
    });

    componentInstance.session = session;
    componentInstance.privateOnly = privateOnly;

    return modalResultToObservable$(result);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getPaymentInfo$(title: string, amount): Observable<void> {
    const modalOptions: IPayWithModalOptions = {
      title,
      amount
    };
    return this._serviceBooking.selectPaymentType$(modalOptions);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _showSessionActionResultModal$(eventActionResult: IEventActionResult): Observable<any> {
    const actionTypesToShow: string[] = [
      ClientEventActionTypes.ACCEPT_REQUEST,
      ClientEventActionTypes.ACCEPT_RESCHEDULE,
      ClientEventActionTypes.RESCHEDULE,
      ClientEventActionTypes.CANCEL
    ];

    if (!actionTypesToShow.includes(eventActionResult.actionType)) {
      return EMPTY;
    }

    const { actionType, eventDetails } = eventActionResult;
    const { componentInstance, result } = this._modal.open(SessionActionResultModalComponent);

    componentInstance.actionResult = { actionType, session: eventDetails };
    componentInstance.canConfirm = true;

    return modalResultToObservable$(result);
  }
}
