<div class="auth-body">
  <div class="auth-form">
    <h2 i18n="@@forgotPassword">Forgot Password?</h2>
    <p i18n="@@resetPasswordInstructions">
      To reset your password, enter your login email and we'll send you instructions.
    </p>

    <form [formGroup]="forgotPasswordForm" novalidate>
      <label for="email" i18n="@@email">Email</label>
      <input
        formControlName="email"
        (change)="onEmailChanged()"
        id="email"
        type="email"
        placeholder="Email"
        class="form-control"
        i18n-placeholder="@@email" />
      <app-form-control-error [control]="forgotPasswordForm.controls['email']" [show]="wrongEmail">
        <ng-template [ngIf]="wrongEmail" [ngIfElse]="invalidEmail" i18n="@@emailDoesntExist">
          This email doesn't exist. Please check the spelling.
        </ng-template>
        <ng-template #invalidEmail>
          <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
        </ng-template>
      </app-form-control-error>
    </form>

    <div class="auth-buttons">
      <app-button className="btn-primary btn-lg" (click)="sendInstructions()" i18n="@@sendInstructions">
        Send instructions
      </app-button>
      <app-button className="btn-link btn-lg" [routerLink]="['/auth/sign-in']">
        <ng-container i18n="@@returnToSignIn">Return to Sign In</ng-container>
      </app-button>
    </div>
  </div>
</div>
