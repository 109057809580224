<form updateValueAndValidity [formGroup]="form">
  <div class="pui-space_bottom-4">
    <h5 pui-typography="h5" i18n="@@SessionType">Session type</h5>
    <p i18n="@@SessionTypeDescription" class="description-text">How do you want a client to book a session?</p>

    <div
      *ngFor="let item of sessionsTypes"
      class="session-type-item pui-space_bottom-2"
      [class.session-type-item_active]="item.value === form.get('sessionType')?.value">
      <label
        pui-input-radio
        formControlName="sessionType"
        [value]="item.value"
        [attr.data-qa-id]="'guide-session-type-title-' + service?.sessionType">
        <div class="d-flex align-items-center">
          <div class="session-type-item-icon">
            <pui-icon [svgIcon]="item.icon" [size]="40"></pui-icon>
          </div>
          <div>
            <h5 pui-typography="h5">{{ item.title }}</h5>
            <span class="description-text">
              {{ item.description }}
            </span>
          </div>
        </div>
      </label>
    </div>
  </div>

  <div *puiLet="this.form.get('hosts')?.value?.selectedHosts as selectedHosts" class="pui-space_bottom-4">
    <div class="pui-space_bottom-2">
      <label pui-input-label [required]="true" data-qa-id="guide-session-assigned-host-label">
        <span pui-typography="h5" i18n="@@AssignedHosts">Assigned hosts ({{ selectedHosts?.length || 0 }})</span>
      </label>
      <pui-input-error [formControl]="form.get('hosts')" role="alert"></pui-input-error>
    </div>

    <button
      class="pui-space_bottom-2"
      (click)="openDrawer()"
      pui-button
      appearance="secondary"
      data-qa-id="guide-session-assigned-host-button">
      <pui-icon svgIcon="pui:plus-circle"></pui-icon>
      <span>Add host</span>
    </button>

    <app-selected-hosts
      *ngIf="selectedHosts?.length"
      [hosts]="selectedHosts"
      [canRemove]="true"
      (remove)="removeMember($event)"></app-selected-hosts>
  </div>
</form>
