import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingStepperComponent } from '@appWidget/modules/booking-root/modules/booking-stepper/booking-stepper.component';
import { PuiStepperModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [BookingStepperComponent],
  imports: [CommonModule, PuiStepperModule],
  exports: [BookingStepperComponent]
})
export class BookingStepperModule {}
