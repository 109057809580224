import { Component, Input } from '@angular/core';
import { ClientNote, ProgramModulesNoteMeta } from '../../client-notes.type';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-client-note-card-meta',
  templateUrl: './client-note-card-meta.component.html',
  styleUrls: ['./client-note-card-meta.component.scss']
})
export class ClientNoteCardMetaComponent {
  @Input()
  // @ts-expect-error TS2564
  note: ClientNote;

  get metaProgramModules(): ProgramModulesNoteMeta {
    // @ts-expect-error TS2322
    return this.note.metaProgramModules;
  }
}
