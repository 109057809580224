import { Injectable } from '@angular/core';
import { ITimezoneOption } from '@app/shared/utils/generate-timezones';

@Injectable({ providedIn: 'root' })
export class ScheduleDateTimePickerConfig {
  readonly dateInputAllowed = false;
  readonly dateInputPlaceholder = '';
  readonly datePickerAutoClose = 'inside';
  readonly datePickerNavigation = 'arrows';
  readonly datePickerPlacement = 'bottom';
  readonly firstDayOfWeek = 7;
  readonly timezoneClearable = false;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  readonly searchTimezone = (term: string, timezone: ITimezoneOption) =>
    timezone.name && timezone.name.toLocaleLowerCase().includes(term.toLocaleLowerCase());
}
