import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import config from '@app/core/config/config';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';

@Injectable()
export class EmailVerificationService {
  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _analyticsService: AnalyticsService
  ) {}

  verify$(
    verificationToken: string
  ): Observable<{ error?: boolean; message: string } | { withoutPassword?: boolean; email?: string }> {
    return this._http
      .get<{ error?: boolean; message: string } | { withoutPassword?: boolean; email?: string }>(
        `${config.apiPath}/public/email-verification/${verificationToken}`
      )
      .pipe(
        tap(() => this._analyticsService.event(InternalEvents.EMAIL_CONFIRMED, {})),
        catchError(() => of({ error: true, message: '' })),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((res: any) => {
          if (res && res.error) {
            this._notifications.error(`Email verification error`, res.message);
          }
        })
      );
  }

  resendEmailVerification(email: string): Observable<void> {
    return this._http.post(`${config.apiPath}/public/verify-email`, { email }).pipe(
      catchError(() => of({ error: true, message: '' })),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((res: any) => {
        if (res && res.error) {
          this._notifications.error(`Failed to send email`, res.message);
        }
      })
    );
  }
}
