import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class NavigationConditionsAdminService implements Partial<ConditionsMap<VisibilityCondition>> {
  always: Observable<boolean> = of(true);

  never: Observable<boolean> = of(false);

  [VisibilityCondition.community]: Observable<boolean> = this.runtimeConfigService.configuration$.pipe(
    map(configuration => configuration.amity.isEnabled)
  );

  constructor(private readonly runtimeConfigService: RuntimeConfigService) {}
}
