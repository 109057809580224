import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Observable, of, Subject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { FormService } from '@app/core';
import { CurrencyService } from '@app/core/currency/currency.service';
import { TimezonesService } from '@app/core/timezones/timezones.service';
import { IGuideService } from '@app/modules/book-service';
import { toClientOffer } from '@app/shared/utils/offer-converters';
import {
  ITimeSlot,
  LOCALE_PROVIDERS,
  SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER,
  SCHEDULE_FORMATS_PROVIDERS,
  SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
  SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER
} from '@app/modules/schedule-boards';
import { IScheduleOptions } from '@app/modules/ui-kit/schedule';
import { SchedulableGuideServicesService } from '@app/screens/guide/guide-sessions/services/events';
import { ScheduleSessionService } from '@app/shared/feature/schedule-session/schedule-session.service';
import { GuideRelation } from '@app/core/users/types';
import { GUIDE_AVAILABILITY_PROVIDERS } from '../../services/guide-availability-provider.service';
import {
  IRefreshableScheduleTimeSlotsFactory,
  REFRESHABLE_SCHEDULE_SLOTS_PROVIDER,
  REFRESHABLE_SCHEDULE_SLOTS_PROVIDERS
} from '../../services/guide-availability-slots-provider.service';
import {
  GUIDE_SERVICES_PROVIDER,
  GUIDE_SERVICES_PROVIDERS,
  IServiceProvider
} from '../../services/guide-services-provider.service';
import { GUIDE_OFFER_CONFIG_PROVIDER } from '../../services/guide-offer.config';
import { GUIDE_OFFER_CONFIG } from '../../tokens';

import { IBaseGuideOfferDetails, IGuideOfferConfig, IGuideOfferDetails, IGuideOfferOptions } from '../../types';
import { isServiceDateRequired } from '../../helpers';
import { guideOfferDateValidatorBuilder } from '../../validators/guide-offer-date.validator';
import { GuideServicesService } from '@app/screens/guide/guide-services/services/guide-services.service';
import { AllGuideServicesApiService } from '@app/screens/guide/guide-services/services/all-guide-services-api.service';
import { SessionTemplateServerStoreService } from '@app/screens/guide/guide-sessions-templates/services/session-template-server-store.service';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { GuideScheduleOptionsApiService } from '@app/screens/guide/guide-sessions/services/schedule';
import { filterEmptyDaysFromScheduleTemplate } from '@app/screens/guide/guide-sessions/services/schedule/converters';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceDate {
  year: number;
  month: number;
  day: number;
}

@Component({
  selector: 'app-guide-offer-form',
  templateUrl: './guide-offer-form.component.html',
  styleUrls: ['./guide-offer-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SchedulableGuideServicesService,
    GUIDE_SERVICES_PROVIDERS,
    GUIDE_AVAILABILITY_PROVIDERS,
    GUIDE_OFFER_CONFIG_PROVIDER,
    REFRESHABLE_SCHEDULE_SLOTS_PROVIDERS,
    SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER,
    SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER,
    SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
    LOCALE_PROVIDERS,
    SCHEDULE_FORMATS_PROVIDERS,
    TimezonesService,
    ScheduleSessionService,
    GuideServicesService,
    AllGuideServicesApiService,
    SessionTemplateServerStoreService
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-offer-form'
  }
})
export class GuideOfferFormComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _canShowFormGroupErrors = false;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideOfferOptions: IGuideOfferOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _maxServiceDate: IServiceDate | null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _minServiceDate: IServiceDate | null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleOptions: IScheduleOptions;

  readonly MESSAGE_MAX_LENGTH: number;

  readonly MESSAGE_MAX_ROWS: number;

  readonly MESSAGE_MIN_ROWS: number;

  template$ = this.guideScheduleOptionsApiService.getScheduleOptions$().pipe(
    map(filterEmptyDaysFromScheduleTemplate),
    map(item => item.template)
  );

  @Input()
  set options(value: IGuideOfferOptions) {
    this._guideOfferOptions = value;
    this.updateFormWithOfferOptions(value);
  }

  @Input() disableService = false;

  @Input() hidePrice = false;

  @Output()
  offerDetailsChange = new EventEmitter<IGuideOfferDetails>();

  readonly guideOfferForm: FormGroup = this._generateForm();

  get canShowGroupErrors(): boolean {
    return this._canShowFormGroupErrors;
  }

  get isDateRequired(): boolean {
    // @ts-expect-error TS2531
    const service: IGuideService = this.guideOfferForm.get('service').value;
    return service != null && isServiceDateRequired(service);
  }

  get maxServiceDate(): IServiceDate | null {
    return this._maxServiceDate;
  }

  get minServiceDate(): IServiceDate | null {
    return this._minServiceDate;
  }

  get scheduleOptions(): IScheduleOptions {
    return this._scheduleOptions;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get shouldShowRestScheduleOptions() {
    return this.scheduleSessionService.isTeamAdmin
      ? // @ts-expect-error TS2531
        this.guideOfferForm.get('host').valid && this.guideOfferForm.get('service').valid
      : // @ts-expect-error TS2531
        this.guideOfferForm.get('service').valid;
  }

  constructor(
    @Inject(GUIDE_SERVICES_PROVIDER)
    readonly guideServicesProvider: IServiceProvider<IGuideService, void>,
    @Inject(REFRESHABLE_SCHEDULE_SLOTS_PROVIDER)
    readonly guideScheduleProvider: IRefreshableScheduleTimeSlotsFactory<unknown, void, ITimeSlot>,
    readonly currency: CurrencyService,
    readonly timezones$: TimezonesService,
    private readonly _formUtils: FormService,
    @Inject(GUIDE_OFFER_CONFIG) guideOfferConfig: IGuideOfferConfig,
    readonly scheduleSessionService: ScheduleSessionService,
    readonly guideServices: GuideServicesService,
    private readonly _sessionTemplateServerStoreService: SessionTemplateServerStoreService,
    private readonly _workspacesService: WorkspacesService,
    private readonly guideScheduleOptionsApiService: GuideScheduleOptionsApiService
  ) {
    this.MESSAGE_MAX_LENGTH = guideOfferConfig.messageMaxLength;
    this.MESSAGE_MAX_ROWS = guideOfferConfig.messageMaxRows;
    this.MESSAGE_MIN_ROWS = guideOfferConfig.messageMinRows;

    // @ts-expect-error TS2722
    this.guideServicesProvider.refresh();

    const { year: minDateYear, month: minDateMonth, day: minDateDay } = DateTime.local().toObject();
    // @ts-expect-error TS2322
    this._minServiceDate = { year: minDateYear, month: minDateMonth, day: minDateDay };
  }

  ngOnInit(): void {
    // As Team Admin. I can choose the host of current service.
    if (this.scheduleSessionService.isTeamAdmin && this.disableService) {
      // Reset default host
      // @ts-expect-error TS2345
      this.scheduleSessionService.hostChange$.next(null);

      // Fetch hosts of current service
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      this.fetchServiceHosts().subscribe((hosts = []) => {
        // Define hosts list
        this.scheduleSessionService.hosts = of(hosts);
        // Set default host
        this.scheduleSessionService.hostChange$.next(hosts[0]);
      });
    }

    this.scheduleSessionService.init({ sessionsKind: 'all', shouldServiceChangeOnHost: !this.disableService });

    // @ts-expect-error TS2531
    this.guideOfferForm
      .get('service')
      .valueChanges.pipe(
        map((service: IGuideService) => service.price || null),
        takeUntil(this.destroy$)
      )
      .subscribe(price => this.guideOfferForm.patchValue({ price }));

    // @ts-expect-error TS2531
    this.guideOfferForm
      .get('service')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.hidePrice = data.hidePrice;
        this.updateFormDate();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitOffer(): void {
    this._canShowFormGroupErrors = true;

    if (this._formUtils.markInvalidForm(this.guideOfferForm)) {
      return;
    }

    const { service, date, client, ...restValues } = this.guideOfferForm.value;

    const baseOfferDetails: IBaseGuideOfferDetails = {
      serviceId: service.id,
      date: date ? date.date : null,
      duration: date ? date.duration || service.duration : null,
      timezone: date ? date.timezone : null,
      ...restValues
    };

    const guideOfferDetails = toClientOffer(baseOfferDetails, client);

    this.offerDetailsChange.emit(guideOfferDetails);
  }

  private refreshScheduleForSelectedDate(selectedDate: ITimeSlot): void {
    const { timezone, value: selectedDateTime } = selectedDate;
    const startOfSelectedDate = DateTime.fromISO(selectedDateTime).startOf('day').toISO();
    // @ts-expect-error TS2531
    const { duration } = this.guideOfferForm.get('service').value as IGuideService;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const scheduleOptions = {
      duration,
      timezone,
      date: startOfSelectedDate
    };
  }

  private toggleDateControl(): void {
    const dateControl = this.guideOfferForm.get('date');

    // @ts-expect-error TS2531
    if (this.isDateRequired && dateControl.disabled) {
      // @ts-expect-error TS2531
      dateControl.enable({ emitEvent: false });
      // @ts-expect-error TS2531
    } else if (!this.isDateRequired && dateControl.enabled) {
      // @ts-expect-error TS2531
      dateControl.setValue(null);
      // @ts-expect-error TS2531
      dateControl.disable({ emitEvent: false });
    }
  }

  private updateFormDate(): void {
    this.toggleDateControl();

    if (!this.isDateRequired) {
      return;
    }

    this.updateScheduleOptions();

    const dateControl = this.guideOfferForm.get('date');
    // @ts-expect-error TS2531
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectedDate = dateControl.value;
  }

  private updateFormWithOfferOptions(offerOptions: IGuideOfferOptions): void {
    const immediateFormUpdate: Partial<IGuideOfferOptions> = {};

    if (offerOptions && offerOptions.client) {
      this.scheduleSessionService.clientChange$.next(offerOptions.client as GuideRelation);
    }

    ['message'].forEach(prop => {
      // @ts-expect-error TS7053
      if (offerOptions[prop] != null) {
        // @ts-expect-error TS7053
        immediateFormUpdate[prop] = offerOptions[prop];
      }
    });

    this.guideOfferForm.patchValue(immediateFormUpdate);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const setPrice = () => {
      if (offerOptions.price || offerOptions.price === 0) {
        this.guideOfferForm.patchValue({ price: offerOptions.price });
      }
    };

    if (offerOptions.service) {
      let services$: Observable<IGuideService[]>;

      if (this.scheduleSessionService.isTeamAdmin) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        services$ = this.guideServices.services$ as any;
        this.guideServices.refresh();
      } else {
        services$ = this.guideServicesProvider.services$.pipe(take(1));
      }

      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      services$.subscribe(services => {
        // @ts-expect-error TS2532
        const _service = services.find(service => service.id === offerOptions.service.id);

        if (_service) {
          this.guideOfferForm.patchValue({ service: _service });
        }
        setPrice();
      });
    } else {
      setPrice();
    }
  }

  private updateScheduleOptions(): void {
    this._scheduleOptions = {
      // @ts-expect-error TS2531
      duration: (this.guideOfferForm.get('service').value as IGuideService).duration
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _generateForm(): FormGroup {
    const formMap = {
      client: this.scheduleSessionService.clientChange$,
      host: this.scheduleSessionService.hostChange$,
      service: this.scheduleSessionService.sessionChange$,
      date: this.scheduleSessionService.dateChange$
    };

    if (!this.scheduleSessionService.isTeamAdmin) {
      // When I'm not team. I can't choose host
      // @ts-expect-error TS2790
      delete formMap.host;
    }

    const controls: {
      [key: string]: AbstractControl;
    } = Object.entries(formMap).reduce(
      (group, [key, state$]) => ({
        ...group,
        [key]: new FormControl(state$.getValue(), Validators.required)
      }),
      {}
    );

    const form = new FormGroup(
      {
        ...controls,
        price: new FormControl(null),
        message: new FormControl(null)
      },
      {
        validators: [guideOfferDateValidatorBuilder()]
      }
    );

    for (const [key, state$] of Object.entries(formMap)) {
      const control = form.get(key);

      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      control?.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        state$.next(value);
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any, rxjs-angular/prefer-takeuntil, id-length
      (state$ as Subject<any>).pipe(distinctUntilChanged()).subscribe(v => {
        control?.setValue(v, {
          onlySelf: true,
          emitEvent: false
        });
      });
    }

    return form;
  }

  // Method fetch host of service
  // @ts-expect-error TS2532
  private fetchServiceHosts(serviceId = this._guideOfferOptions.service.id): Observable<IWorkspaceMember[]> {
    return this._sessionTemplateServerStoreService.getTemplateAssignees$(serviceId).pipe(
      take(1),
      // Map service assignees user to workspace user
      map(assignees => {
        return assignees.reduce((acc, assignee) => {
          // Only service provider can by the host of service
          if (
            assignee.permission === ServiceAssigneePermission.PROVIDER ||
            assignee.permission === ServiceAssigneePermission.OWNER_X_PROVIDER
          ) {
            // @ts-expect-error TS2322
            const host: IWorkspaceMember = this._workspacesService.members.find(
              member => member.userId === assignee.guideId
            );

            // @ts-expect-error TS2345
            acc.push(host);
          }

          return acc;
        }, []);
      })
    );
  }
}
