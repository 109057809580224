import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GuideClient, GuideRelationTypes, isAssignedToGuide, isGuideContact } from '@app/core/users/types';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { fetchRelations } from '@app/screens/guide/guide-clients/guide-client/store/relations-store/relations-store.actions';
import { Store } from '@ngrx/store';
import {
  hasRelationsLoaded,
  relationsSelector
} from '@app/screens/guide/guide-clients/guide-client/store/relations-store/relations-store.selectors';
import { filter, map, take } from 'rxjs/operators';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { getRelationId } from '@app/screens/guide/guide-clients/guide-client/store/relations-store/utils/get-relation-id';

@Component({
  selector: 'app-guide-client-notes',
  templateUrl: './guide-client-notes.component.html',
  styleUrls: ['./guide-client-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientNotesComponent implements OnInit {
  relation$ = this.store$.select(relationsSelector).pipe(
    filter(relations => !!relations),
    map(
      relations =>
        relations[getRelationId({ id: this.client.relationId, type: this.client.type as GuideRelationTypes })]
    )
  );

  @Input() readonly client!: CRMClient;

  readonly searchControl: FormControl = new FormControl();

  constructor(
    private readonly authService: AuthService,
    private readonly workspacesService: WorkspacesService,
    private readonly store$: Store,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store$
      .select(hasRelationsLoaded)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(hasLoaded => {
        if (!hasLoaded) {
          this.store$.dispatch(fetchRelations());
        }
      });
  }

  canShareNotes(relation: GuideClient): boolean {
    return !isGuideContact(relation);
  }

  isAssigned(relation: GuideClient): boolean {
    return isAssignedToGuide(relation, this.authService.user?.email) || !this.workspacesService.isTeamAdmin;
  }
}
