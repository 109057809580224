import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Inject } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { RecurringEvent } from '@app/screens/guide/guide-sessions-templates/types';
import { integerValidator } from '@app/shared/form-validators/integer.validator';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

type RecurrenceForm = Pick<RecurringEvent, 'count'> & { enabled: boolean };

function createForm(fb: FormBuilder): FormGroup {
  return fb.group({
    enabled: fb.control(false),
    count: fb.control(null, [
      customValidatorWrapper((control: AbstractControl) => {
        const count = Number(control.value);
        const isRecurrenceEnabled = control.parent?.get('enabled')?.value;
        const isPositiveInteger = typeof count === 'number' && Number.isInteger(count) && count > 0;

        if (isRecurrenceEnabled && !isPositiveInteger) {
          return {
            required: true
          };
        }
        return null;
      }, `Value must be greater than or equal to 1.`),
      integerValidator(`Value must be greater than or equal to 1.`)
    ])
  });
}

@Component({
  selector: 'app-recurrence-form',
  templateUrl: './recurrence-form.component.html',
  styleUrls: ['./recurrence-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RecurrenceFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecurrenceFormComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecurrenceFormComponent implements ControlValueAccessor, Validator {
  MAX_COUNT = 20;
  private readonly defaultRecurrence: RecurrenceForm = {
    enabled: true,
    count: 20
  };

  form: FormGroup = createForm(this.fb);

  maximumMask = [/\d/, /\d/, /\d/];

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly fb: FormBuilder
  ) {}

  toggleRecurring(checked: boolean | undefined): void {
    if (checked === undefined) {
      return;
    }

    const recurrence = checked ? this.defaultRecurrence : { enabled: false, count: null };

    this.form.patchValue(recurrence);
  }

  onTouched: () => void = () => {};

  writeValue(value: Pick<RecurringEvent, 'count'> | null): void {
    if (value) {
      this.form.patchValue({
        ...value,
        enabled: true
      });
    }
  }

  registerOnChange(fn: (value: RecurrenceForm | null) => void): void {
    this.form.valueChanges
      .pipe(
        map((recurrence: RecurrenceForm) => {
          return recurrence.enabled
            ? {
                count: Number(recurrence.count)
              }
            : null;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(): ValidationErrors | null {
    return {
      ...this.form.get('count')?.errors
    };
  }
}
