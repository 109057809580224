// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IModuleInstructorDetails {
  readonly id: number;
  readonly namedUrl: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photo?: string;
  readonly email?: string;
}

export class ModuleInstructor {
  // @ts-expect-error TS2564
  readonly id: number;
  // @ts-expect-error TS2564
  readonly namedUrl: string;
  // @ts-expect-error TS2564
  readonly firstName: string;
  // @ts-expect-error TS2564
  readonly lastName: string;
  readonly photo?: string;
  readonly email?: string;

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(moduleInstructorDetails: IModuleInstructorDetails) {
    if (!moduleInstructorDetails) {
      throw new Error('Invalid argument');
    }

    ['id', 'namedUrl', 'firstName', 'lastName', 'photo', 'email'].forEach(prop => {
      // @ts-expect-error TS7053
      if (moduleInstructorDetails[prop] != null) {
        // @ts-expect-error TS7053
        this[prop] = moduleInstructorDetails[prop];
      }
    });
  }

  getDiffFrom(anotherInstructor: Readonly<ModuleInstructor>): { readonly id: number } | null {
    return this.id !== anotherInstructor.id ? { id: anotherInstructor.id } : null;
  }
}
