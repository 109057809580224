import { Pipe, PipeTransform } from '@angular/core';
import { RestrictionsFormFields } from './types';
import { bufferTimesAppearance, fullAppearance, packageAppearance, programAppearance } from './data';

@Pipe({
  name: 'restrictionsFormAppearance'
})
export class RestrictionsFormAppearancePipe implements PipeTransform {
  fields: { [key in RestrictionsFormFields | string]: boolean } = fullAppearance;

  transform(
    value: 'full' | 'buffer-times' | 'package' | 'program' | undefined,
    field: RestrictionsFormFields
  ): boolean {
    if (value === 'full') {
      this.fields = fullAppearance;
    } else if (value === 'buffer-times') {
      this.fields = bufferTimesAppearance;
    } else if (value === 'package') {
      this.fields = packageAppearance;
    } else if (value === 'program') {
      this.fields = programAppearance;
    }

    return this.fields[field];
  }
}
