import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class SearchStringService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _searchInputValue = new Subject<string>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _routeSearchValue$ = new Subject<string>();

  get routeSearchValue$(): Observable<string> {
    return this._routeSearchValue$.asObservable();
  }

  get searchInputValue$(): Observable<string> {
    return this._searchInputValue.asObservable();
  }

  updateRouteSearchValue(routeSearchValue: string): void {
    this._routeSearchValue$.next(routeSearchValue);
  }

  updateSearchInputValue(searchInputValue: string): void {
    this._searchInputValue.next(searchInputValue);
  }
}
