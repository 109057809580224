import { Component, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { sortPlatformLanguagesByName } from '@app/shared/utils/sort-tags';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITagLike {
  id: number;
  name: string;
}

@Component({
  selector: 'app-language-tags',
  templateUrl: './language-tags.component.html',
  styleUrls: ['./language-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageTagsComponent),
      multi: true
    }
  ]
})
export class LanguageTagsComponent implements ControlValueAccessor {
  @Input()
  set items(value: ITagLike[]) {
    this.allItems = sortPlatformLanguagesByName(value) || [];
    this.selectedItemsValues = this.allItems
      .filter(items => this.selectedItemsIds.includes(items.id))
      .map(item => item.name);
    this.setItems();
  }

  @Input()
  disabled = false;

  private allItems: ITagLike[] = [];

  filteredItems: string[] = [];
  selectedItemsIds: number[] = [];
  selectedItemsValues: string[] = [];

  writeValue(value: number[]): void {
    this.selectedItemsIds = value || [];
    this.selectedItemsValues = this.allItems
      .filter(items => this.selectedItemsIds.includes(items.id))
      .map(item => item.name);
    this.setItems();
  }

  private onChange = (_: unknown): void => {};
  registerOnChange(fn: (_: Function) => void): void {
    this.onChange = fn;
  }

  private onTouched = (): void => {};
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  selectItem(value: string[]): void {
    if (!this.disabled) {
      this.selectedItemsIds = this.allItems.filter(items => value.includes(items.name)).map(item => item.id);
      this.update();
    }
  }

  private setItems(): void {
    if (!this.allItems.length) {
      this.filteredItems = [];

      return;
    }

    this.filteredItems = this.allItems.map(item => item.name);
  }

  private update(): void {
    this.setItems();
    this.onChange(this.selectedItemsIds);
    this.onTouched();
  }
}
