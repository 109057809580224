import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PuiDrawerService } from '@awarenow/profi-ui-core';
import { DRAWER_CONFIG } from '@app/modules/guide-client/services/guide-relation-panel.service';
import { GuideRelationShareNotesComponent } from '@app/modules/guide-client/components/guide-relation-share-notes/guide-relation-share-notes.component';

@Component({
  selector: 'app-guide-relation-notes-board-toolbar',
  templateUrl: './guide-relation-notes-board-toolbar.component.html',
  styleUrls: ['./guide-relation-notes-board-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideRelationNotesBoardToolbarComponent {
  @Input()
  // @ts-expect-error TS2564
  clientId: number;

  @Input()
  // @ts-expect-error TS2564
  canShareNotes: boolean;

  constructor(private readonly _drawer: PuiDrawerService) {}

  openSharingSettings(): void {
    this._drawer.open(
      GuideRelationShareNotesComponent,
      {
        ...DRAWER_CONFIG,
        hideCloseButton: true
      },
      { clientId: this.clientId }
    );
  }
}
