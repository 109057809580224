import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PuiIconModule } from '@awarenow/profi-ui-core';

import { SessionTypeComponent } from './session-type.component';

@NgModule({
  declarations: [SessionTypeComponent],
  imports: [CommonModule, PuiIconModule],
  exports: [SessionTypeComponent]
})
export class SessionTypeModule {}
