import { Injectable } from '@angular/core';
import { PuiDrawerConfig, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { SessionTemplatesDrawerComponent } from './session-templates-drawer.component';

const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable()
export class SessionTemplatesDrawerService {
  constructor(private readonly drawerService: PuiDrawerService) {}

  open$(): PuiDrawerRef<{ id: number; recurring: unknown } | undefined> {
    return this.drawerService.open(SessionTemplatesDrawerComponent, DRAWER_CONFIG);
  }
}
