import { TextMaskModule } from 'angular2-text-mask';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UpdateValueAndValidityModule } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.module';
import {
  PuiButtonModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputDateModule,
  PuiInputModule,
  PuiInputRadioModule,
  PuiLetModule,
  PuiLinkModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { LimitBookingFrequencyModule } from './components/limit-booking-frequency/limit-booking-frequency.module';
import { RestrictionsFormAppearancePipe } from './restrictions-form-appearance.pipe';
import { RestrictionsFormComponent } from './restrictions-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiIconModule,
    PuiInputDateModule,
    PuiInputRadioModule,
    PuiInputModule,
    PuiGroupModule,
    PuiLetModule,
    PuiLinkModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule,
    UpdateValueAndValidityModule,
    TextMaskModule,
    LimitBookingFrequencyModule
  ],
  declarations: [RestrictionsFormComponent, RestrictionsFormAppearancePipe],
  exports: [RestrictionsFormComponent]
})
export class RestrictionsFormModule {}
