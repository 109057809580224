import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Observable, ReplaySubject, Subject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { io, Socket } from 'socket.io-client';
import { isPlatformBrowser } from '@angular/common';
import { take } from 'rxjs/operators';
import { SocketEvents } from '@app/cdk/enums';

import config from '../config/config';
import { EmailAlertsService } from '../email/email-alerts.service';
import {
  IExternalCalendarsConnectedPayload,
  IExternalCalendarsDisconnectedPayload,
  IExternalEventsRemovedPayload,
  IExternalEventsUpdatePayload
} from './types';
import { LogService } from '../log/log.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

export interface ProgramsChange {
  programDetails: {
    guideId: number;
    programId: number;
    workspaceId: number;
    type: SocketEvents.PROGRAM_ENROLLED;
  };
}

export interface SessionChange {
  type: GuideServiceTypes;
  templateId: number;
  // Private Session id sent only for client
  id: number;
}

export type PackageEnrolled = undefined;

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _membershipChanged$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _emailVerification$ = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _externalCalendarsConnected$ = new Subject<IExternalCalendarsConnectedPayload>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _externalCalendarsDisconnected$ = new Subject<IExternalCalendarsDisconnectedPayload>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _externalCalendarConnected$ = new Subject<IExternalCalendarsConnectedPayload>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _externalCalendarDisconnected$ = new Subject<IExternalCalendarsDisconnectedPayload>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _externalEventsRemoved$ = new Subject<IExternalEventsRemovedPayload>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _externalEventsUpdate$ = new Subject<IExternalEventsUpdatePayload>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _googleCalendarError$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideConfirmation$ = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _guideProfileNotification$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _guideRelationsUpdate$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _negativeBalanceNotification$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _packageEnrolled$ = new Subject<PackageEnrolled>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _postSessionNotification$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programsChange$ = new Subject<ProgramsChange>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessionChanged$ = new Subject<SessionChange>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessionClose$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _sessionExtend$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _sessionExtendAccept$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _sessionExtendDecline$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _socket: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _socketError$ = new ReplaySubject<any>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _socketSuccess$ = new ReplaySubject<typeof Socket>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _stripeAuth$ = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _surveys$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userNotesCreate$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userNotesDelete$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userNotesUpdate$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userNotesGrantAccess$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userNotesRevokeAccess$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userWalletNotification$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _userWalletUpdate$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _guideClientsUpdate$ = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call: any = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storageListener: any;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private _platformId: any,
    private _emailAlerts: EmailAlertsService,
    private _logService: LogService,
    private _notifyService: NotificationsService
  ) {}

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  init(token, role) {
    if (isPlatformBrowser(this._platformId)) {
      this.disconnect();

      this._socket = io(config.socket.host, {
        transports: ['websocket'],
        path: config.socket.path,
        auth: {
          token,
          role
        }
      });

      this._socket.on('connect', () => this._socketSuccess$.next(this._socket));
      // @ts-expect-error TS7006
      this._socket.on('error', err => this._socketError$.next(err));

      this._configureSocketListeners();
      this.configureStorageListener();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOnlineStatuses$(userIds: { id: number; role: number }[]): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Observable<any>(observer =>
      // @ts-expect-error TS7006
      this._socket.emit(SocketEvents.GET_ONLINE, userIds, onlineStatuses => observer.next(onlineStatuses))
    ).pipe(take(1));
  }

  onMembershipChanged$(): Observable<void> {
    return this._membershipChanged$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onEmailVerification() {
    return this._emailVerification$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onExtendAccept() {
    return this._sessionExtendAccept$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onExtendDecline() {
    return this._sessionExtendDecline$.asObservable();
  }

  onExternalCalendarsConnected$(): Observable<IExternalCalendarsConnectedPayload> {
    return this._externalCalendarsConnected$.asObservable();
  }

  onExternalCalendarsDisconnected$(): Observable<IExternalCalendarsDisconnectedPayload> {
    return this._externalCalendarsDisconnected$.asObservable();
  }

  onExternalCalendarConnected$(): Observable<IExternalCalendarsConnectedPayload> {
    return this._externalCalendarConnected$.asObservable();
  }

  onExternalCalendarDisconnected$(): Observable<IExternalCalendarsDisconnectedPayload> {
    return this._externalCalendarDisconnected$.asObservable();
  }

  onExternalEventsRemoved$(): Observable<IExternalEventsRemovedPayload> {
    return this._externalEventsRemoved$.asObservable();
  }

  onExternalEventsUpdate$(): Observable<IExternalEventsUpdatePayload> {
    return this._externalEventsUpdate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGetRequestToExtendSession() {
    return this._sessionExtend$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGoogleCalendarError() {
    return this._googleCalendarError$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGuideConfirmation() {
    return this._guideConfirmation$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGuideProfileNotification() {
    return this._guideProfileNotification$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserNotesUpdate() {
    return this._userNotesUpdate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserNotesDelete() {
    return this._userNotesDelete$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserNotesCreate() {
    return this._userNotesCreate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserNotesGrantAccess() {
    return this._userNotesGrantAccess$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserNotesRevokeAccess() {
    return this._userNotesRevokeAccess$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onGuideRelationsUpdate() {
    return this._guideRelationsUpdate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNegativeBalanceNotification() {
    return this._negativeBalanceNotification$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onPostSessionNotification() {
    return this._postSessionNotification$.asObservable();
  }

  onProgramsChange(): Observable<ProgramsChange> {
    return this._programsChange$.asObservable();
  }

  onPackageEnrolled(): Observable<PackageEnrolled> {
    return this._packageEnrolled$.asObservable();
  }

  onSessionChanged(): Observable<SessionChange> {
    return this._sessionChanged$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSessionClose() {
    return this._sessionClose$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onStripeAuth() {
    return this._stripeAuth$.asObservable();
  }

  onSuccess$(): Observable<typeof Socket> {
    return this._socketSuccess$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onError() {
    return this._socketError$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserWalletNotification() {
    return this._userWalletNotification$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUserWalletUpdate() {
    return this._userWalletUpdate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSurvey() {
    return this._surveys$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  guideClientsUpdate() {
    return this._guideClientsUpdate$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  disconnect() {
    if (this._socket) {
      this._socket.off();
      this._socket.disconnect(true);
      this._socket = null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  connectToNsp(nsp: string) {
    return io(`${config.socket.host}/${nsp}`, { path: config.socket.path, transports: ['websocket'] });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _configureSocketListeners(): void {
    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EMAIL_VERIFICATION, data => {
      this._emailAlerts.notifySuccessfulVerification();
      this._emailVerification$.next(data);
    });

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTEND, data => this._sessionExtend$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTEND_ACCEPT, data => this._sessionExtendAccept$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTEND_DECLINE, data => this._sessionExtendDecline$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTERNAL_CALENDARS_CONNECTED, data => this._externalCalendarsConnected$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTERNAL_CALENDARS_DISCONNECTED, data =>
      this._externalCalendarsDisconnected$.next(data)
    );

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTERNAL_CALENDAR_CONNECTED, data => this._externalCalendarConnected$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTERNAL_CALENDAR_DISCONNECTED, data =>
      this._externalCalendarDisconnected$.next(data)
    );

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTERNAL_EVENTS_REMOVED, data => this._externalEventsRemoved$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.EXTERNAL_EVENTS_UPDATE, data => this._externalEventsUpdate$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.GOOGLE_CALENDAR_ERROR, errorMessage => {
      const title = `Google Calendar Error`;
      this._notifyService.error(title, errorMessage);
      this._googleCalendarError$.next();
    });

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.GUIDE_CLIENTS_UPDATE, data => this._guideClientsUpdate$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.GUIDE_CONFIRMATION, confirmed => {
      if (confirmed) {
        const title = `Congratulations!`;
        const message = `Your profile has been confirmed`;
        this._notifyService.create(title, message, NotificationType.Success);
      }
      this._guideConfirmation$.next(confirmed);
    });

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.GUIDE_PROFILE_NOTIFICATION, data => this._guideProfileNotification$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.GUIDE_RELATIONS_UPDATE, data => this._guideRelationsUpdate$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.NEGATIVE_BALANCE_NOTIFICATION, data => this._negativeBalanceNotification$.next(data));

    this._socket.on(SocketEvents.PACKAGE_ENROLLED, () => this._packageEnrolled$.next());

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.POST_SESSION_NOTIFICATION, data => this._postSessionNotification$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.PROGRAMS_CHANGE, data => this._programsChange$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.SESSIONS_CHANGED, data => this._sessionChanged$.next(data));

    this._socket.on(SocketEvents.SESSION_CLOSE, () => this._sessionClose$.next());

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.STRIPE_AUTH, data => this._stripeAuth$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.SURVEY, data => this._surveys$.next(data));

    this._socket.on(SocketEvents.UPDATE_MEMBERSHIP_SETTINGS, () => this._membershipChanged$.next());

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_NOTES_CREATE, data => this._userNotesCreate$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_NOTES_DELETE, data => this._userNotesDelete$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_NOTES_GRANT_ACCESS, data => this._userNotesGrantAccess$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_NOTES_REVOKE_ACCESS, data => this._userNotesRevokeAccess$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_NOTES_UPDATE, data => this._userNotesUpdate$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_WALLET_NOTIFICATION, data => this._userWalletNotification$.next(data));

    // @ts-expect-error TS7006
    this._socket.on(SocketEvents.USER_WALLET_UPDATE, data => this._userWalletUpdate$.next(data));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private configureStorageListener() {
    if (this.storageListener) {
      window.removeEventListener('storage', this.storageListener);
      this.storageListener = null;
    }

    // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
    this.storageListener = (e: any) => {
      let notifTitle = '';
      let notifContent = '';
      let notifType = NotificationType.Info;
      switch (e.key) {
        case 'user_is_offline':
          notifTitle = `User is offline.`;
          notifContent = `Click to close...`;
          this._logService.log(e.key);
          break;
        case 'user_declined':
          notifTitle = `User declined the call.`;
          notifContent = `Click to close...`;
          this._logService.log(e.key);
          break;
        case 'io_server_disconnect':
          notifTitle = `Io server disconnect.`;
          notifContent = `Disconnected by signal server`;
          notifType = NotificationType.Error;
          this._logService.error(e.key);
          break;
        // endregion
        default:
          return;
      }
      this._notifyService.create(notifTitle, notifContent, notifType);
    };

    window.addEventListener('storage', this.storageListener);
  }
}
