import { Pipe, PipeTransform } from '@angular/core';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

@Pipe({
  name: 'platformCurrency'
})
export class PlatformCurrencyPipe implements PipeTransform {
  constructor(private readonly _platformStyle: PlatformStyle) {}

  transform(value: number | string, textFree: boolean = false): string {
    if (!value && textFree) {
      return 'Free';
    }
    return this._platformStyle.transformCurrency(value);
  }
}
