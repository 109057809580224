<form [formGroup]="form">
  <div class="pui-space_bottom-6">
    <app-timezone-form formControlName="timeZone"></app-timezone-form>
  </div>

  <div class="pui-space_bottom-6">
    <app-working-time-form formControlName="template"></app-working-time-form>
  </div>

  <div class="pui-space_bottom-3">
    <div class="d-flex pui-space_bottom-1">
      <h5 pui-typography="h5" i18n="@@DateOverrides">Date overrides</h5>
      <pui-icon
        class="custom-availability-form__info-button pui-space_left-1"
        size="s"
        svgIcon="pui:info"
        ngbTooltip="Date overrides are archived automatically after the date has passed"
        i18n-ngbTooltip="@@sessionTemplateDataOverridesTooltip"></pui-icon>
    </div>
    <span class="custom-availability-form__description" pui-typography="body-s" i18n="@@DateOverridesDescription">
      Add dates when your availability changes from your daily hours.
    </span>
  </div>

  <div
    class="custom-availability-form__override-item pui-space_bottom-3"
    *ngFor="let item of form.get('overrides')?.value; let idx = index">
    <div>
      <span>{{ convertToDateString(item.date) }}</span>

      <ng-container *ngFor="let override of item.values; let index = index">
        <div>
          <ng-container *ngIf="!isUnavailable(override); else unavailable">
            <p class="custom-availability-form__description">
              {{ formatTime(override.startTime) }} - {{ formatTime(override.endTime) }}
            </p>
          </ng-container>

          <ng-template #unavailable>
            <span class="custom-availability-form__description">All day</span>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div class="d-flex align-items-center">
      <button
        class="custom-availability-form__override-button pui-space_right-5"
        pui-icon-button
        size="s"
        type="button"
        appearance="flat"
        (click)="editOverride(item, idx)"
        ngbTooltip="Edit"
        i18n-ngbTooltip="@@Edit:new"
        shape="rounded">
        <pui-icon size="xs" svgIcon="pui:edit"></pui-icon>
      </button>
      <button
        class="custom-availability-form__override-button"
        pui-icon-button
        size="s"
        type="button"
        appearance="flat"
        (click)="removeControl(idx)"
        ngbTooltip="Delete"
        i18n-ngbTooltip="@@Delete"
        shape="rounded">
        <pui-icon size="xs" svgIcon="pui:trash"></pui-icon>
      </button>
    </div>
  </div>

  <button pui-button appearance="secondary" type="button" size="s" (click)="openOverrides()">
    <pui-icon size="s" svgIcon="pui:plus-circle"></pui-icon>
    <span i18n="@@AddAnOverride">Add an override</span>
  </button>
</form>
