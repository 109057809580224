import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { MembershipService } from '@app/core/membership/membership.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { FirstpromoterService } from '@app/core/firstpromoter/firstpromoter.service';
import { LoopedinService } from '@app/core/integrations/modules';

@Injectable()
export class NavigationConditionsGuideService implements Partial<ConditionsMap<VisibilityCondition>> {
  always: Observable<boolean> = of(true);

  never: Observable<boolean> = of(false);

  [VisibilityCondition.url_cancellation_policy] = this.brandingService.globalConfig$.pipe(
    map(({ urlCancellationPolicy }) => !!urlCancellationPolicy)
  );

  [VisibilityCondition.url_visit_help_center] = this.brandingService.globalConfig$.pipe(
    map(({ urlVisitHelpCenter }) => !!urlVisitHelpCenter)
  );

  [VisibilityCondition.url_terms] = this.brandingService.globalConfig$.pipe(map(({ urlTerms }) => !!urlTerms));

  [VisibilityCondition.email_help] = this.brandingService.globalConfig$.pipe(map(({ emailHelp }) => !!emailHelp));

  [VisibilityCondition.url_privacy] = this.brandingService.globalConfig$.pipe(map(({ urlPrivacy }) => !!urlPrivacy));

  [VisibilityCondition.community] = this.runtimeConfigService.configuration$.pipe(
    map(({ amity }) => amity.isEnabled && amity.permissions.guide)
  );

  // visible only for guides ho have "namedUrl" property
  // and in Branding service parameter enableECourcesNav = true
  [VisibilityCondition.forms] = combineLatest([
    this.authService.onAuth().pipe(map(auth => auth && !!auth.namedUrl)),
    this.brandingService.globalConfig$.pipe(map(({ enableECourcesNav }) => enableECourcesNav))
  ]).pipe(map(([namedUrl, enableECourcesNav]) => namedUrl && enableECourcesNav));

  [VisibilityCondition.blogs] = combineLatest([
    this.brandingService.globalConfig$.pipe(map(({ enableBlogNav }) => enableBlogNav))
  ]).pipe(map(([enableBlogNav]) => enableBlogNav));

  // visible only for guides and in Branding service parameter hideWallet = false
  [VisibilityCondition.payments] = combineLatest([
    this.brandingService.globalConfig$.pipe(map(({ hideWallet }) => hideWallet))
  ]).pipe(map(([hideWallet]) => !hideWallet));

  // only if client is admin for active solo workspace
  [VisibilityCondition.solo_workspace] = this.workspacesService.isSolo$;

  // only if client is team admin for active team workspace
  [VisibilityCondition.team_workspace_admin] = this.workspacesService.isTeamAdmin$;

  // only if loopedin script installed
  [VisibilityCondition.whats_new] = of(!!this.loopedinService.getLoopedin());

  // only if client is admin for active team workspace
  [VisibilityCondition.workspace_admin] = this.workspacesService.isAdmin$;

  // only if user can signup as client
  [VisibilityCondition.can_signup_as_client] = combineLatest([
    this.brandingService.globalConfig$,
    this.authService.onAuth()
  ]).pipe(
    map(([config, user]) => {
      return !config.hideSignUpAs && user.RoleId === UserRoles.GUIDE && !user?.hasAlternativeProfile;
    })
  );

  // only if membership for coaches enabled by platform admins.
  // Should be extended by team workspace settings after team workspace subscription functionality will be implemented
  [VisibilityCondition.subscription] = combineLatest([
    this.workspacesService.isSolo$,
    this.membershipService.isMembershipEnabledForCoaches$
  ]).pipe(
    map(([isSolo, isMembershipEnabledForCoaches]) => {
      return isSolo && isMembershipEnabledForCoaches;
    })
  );

  // only if FIRSTPROMOTER_ID, FIRSTPROMOTER_DOMAIN, FIRSTPROMOTER_PRODUCT_NAME is setted up in superadmin front config
  // and Platform Promoter checkbox enabled in admin panel
  [VisibilityCondition.earn_affiliates]: Observable<boolean> = combineLatest([
    this.authService.onAuth().pipe(
      filter(user => !!user),
      map(({ isPlatformPromoter }) => isPlatformPromoter)
    ),
    of(this.firstPromoterService.isAvailable)
  ]).pipe(map(flags => flags.every(flag => flag === true)));

  // only if account_switcher enabled in Global config
  [VisibilityCondition.account_switcher]: Observable<boolean> = combineLatest([
    this.authService.onAuth().pipe(
      filter(user => !!user),
      map(({ hasAlternativeProfile }) => hasAlternativeProfile)
    ),
    this.brandingService.globalConfig$.pipe(
      filter(config => !!config),
      map(({ hideSignInAs }) => !hideSignInAs)
    )
  ]).pipe(map(flags => flags.every(flag => flag === true)));

  [VisibilityCondition.reports]: Observable<boolean> = of(true);

  [VisibilityCondition.services]: Observable<boolean> = of(!!this.runtimeConfigService.get('services')?.enabled);

  constructor(
    private readonly authService: AuthService,
    private readonly brandingService: BrandingService,
    private readonly workspacesService: WorkspacesService,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly membershipService: MembershipService,
    private readonly firstPromoterService: FirstpromoterService,
    private readonly loopedinService: LoopedinService
  ) {}
}
