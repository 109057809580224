import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';
import {
  GuideRelationShareNotesService,
  TeamMemberAccessType,
  TeamMemberSharingSettingsUserType
} from '@app/modules/guide-client/services/guide-relation-share-notes.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ITeamMembersSharingSettings } from '@app/modules/guide-client/types/team-members';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { NOTES_UPDATE_TOKEN } from '@app/modules/guide-client/types/notes-update-token';

interface MenuOption {
  label: string;
  value: TeamMemberAccessType;
}

@Component({
  selector: 'app-guide-relation-share-notes',
  templateUrl: './guide-relation-share-notes.component.html',
  styleUrls: ['./guide-relation-share-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideRelationShareNotesComponent implements OnInit, OnDestroy {
  readonly noteId: number | null;

  readonly accessOptions: MenuOption[];

  // @ts-expect-error TS2564
  teamMembers: ITeamMembersSharingSettings[];

  readonly teamMemberSharingSettingsUserType = TeamMemberSharingSettingsUserType;

  private destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notesAccessChanged = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _clientId: number;

  get isTeam(): boolean {
    return this._workspacesService.isTeam;
  }

  get isTeamAdmin(): boolean {
    return this._workspacesService.isTeamAdmin;
  }

  constructor(
    private readonly _drawerRef: PuiDrawerRef,
    private readonly _guideRelationShareNotes: GuideRelationShareNotesService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _workspacesService: WorkspacesService,
    @Inject(NOTES_UPDATE_TOKEN) private readonly _notesUpdate: Subject<void>
  ) {
    // @ts-expect-error TS2322
    this._clientId = (this._drawerRef.data['clientId'] as number) || null;
    this.noteId = (this._drawerRef.data['noteId'] as number) || null;
    this.accessOptions = [
      {
        label: `Can view`,
        value: TeamMemberAccessType.VIEW
      },
      {
        label: `No access`,
        value: TeamMemberAccessType.FORBIDDEN
      }
    ];
  }

  closeSharingSettings(): void {
    if (this._notesAccessChanged) {
      this._notesUpdate.next();
    }

    this._drawerRef.close();
  }

  updateTeamMemberAccess(event: MenuOption, userId: number): void {
    this._notesAccessChanged = true;
    this._guideRelationShareNotes
      // @ts-expect-error TS2345
      .setAccess$(this._clientId, userId, event.value, this.noteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnInit(): void {
    this._guideRelationShareNotes
      // @ts-expect-error TS2345
      .getPossibleTeamMembers$(this._clientId, this.noteId)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(teamMembers => {
        this.teamMembers = teamMembers;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
