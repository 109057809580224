import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { AlertsService } from '@app/modules/alerts/services/alerts.service';
import { AlertKey } from '@app/modules/alerts/types/alert';

@Injectable()
export class EmailAlertsService {
  readonly SUCCESSFUL_VERIFICATION_TIMEOUT = 5400;
  private readonly ALERTS_CLOSING_TIMEOUT = 400;

  constructor(private _alerts: AlertsService) {}

  notifySuccessfulVerification(): void {
    this._alerts.closeByTemplate(AlertKey.EMAIL_WARNING, true);
    timer(this.ALERTS_CLOSING_TIMEOUT).subscribe(() => this._alerts.template(AlertKey.EMAIL_SUCCESS));
    timer(this.SUCCESSFUL_VERIFICATION_TIMEOUT).subscribe(() =>
      this._alerts.closeByTemplate(AlertKey.EMAIL_SUCCESS, true)
    );
  }

  showNotVerified(): void {
    this._alerts.template(AlertKey.EMAIL_WARNING);
  }
}
