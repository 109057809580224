import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GuideOfferService } from '@app/modules/guide-offer/services/guide-offer.service';
import { GuideClient, GuideRelation } from '@app/core/users/types';
import { switchMap } from 'rxjs/operators';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideRelationPanelService } from '@app/modules/guide-client/services';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';

@Component({
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDetailsComponent {
  readonly selectedRelation$: Observable<GuideRelation>;

  constructor(
    private readonly _guideClients: GuideClientsService,
    private readonly _guideOfferService: GuideOfferService,
    private readonly _guidRelationPanel: GuideRelationPanelService,
    @Optional() readonly ref?: PuiDrawerRef
  ) {
    // @ts-expect-error TS2322
    this.selectedRelation$ = this._guideClients.selectedRelationId.pipe(
      switchMap(relationId => (relationId ? this._guideClients.getUser$(relationId) : of(null)))
    );
  }

  createOffer(relation: GuideRelation): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._guideOfferService.offer$({ client: relation as GuideClient }).subscribe();
  }

  back(): void {
    this._guidRelationPanel.drawer.close();
  }
}
