<div class="modal-header">
  <h3 i18n="@@selectClients">Select clients</h3>
</div>
<!--do not remove ngbAutofocus-->
<div class="modal-body" ngbAutofocus>
  <app-form-field #field>
    <app-input-label i18n="@@clientsSelectorModalLabel">Clients</app-input-label>
    <ui-client-selector-multiple
      appInput
      [(ngModel)]="selectedClients"
      [clients]="clients"
      [autocomplete-connected-to]="field.origin"></ui-client-selector-multiple>
  </app-form-field>
</div>
<div class="modal-footer">
  <button class="modal-footer__btn" pui-button appearance="primary" (click)="save()" i18n="@@submit">Submit</button>
  <button class="modal-footer__btn" pui-button appearance="secondary" (click)="cancel()" i18n="@@cancel">Cancel</button>
</div>
