import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IReschedulingSessionDate,
  ISessionRescheduleMessage,
  ReschedulingSessionOptions
} from '@app/modules/rescheduling-service/types';

enum ReschedulingSteps {
  RESCHEDULING_DETAILS = 0,
  MESSAGE = 1,
  PAYMENT = 2
}

@Component({
  selector: 'app-session-rescheduling',
  templateUrl: './session-rescheduling.component.html',
  styleUrls: ['./session-rescheduling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'session-rescheduling'
  }
})
export class SessionReschedulingComponent {
  readonly ReschedulingSteps: typeof ReschedulingSteps = ReschedulingSteps;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _skippedMessage: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeStep: ReschedulingSteps | null = null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _reschedulingOptions: ReschedulingSessionOptions;

  @Input()
  set reschedulingOptions(value: ReschedulingSessionOptions) {
    this._reschedulingOptions = value;

    this._calculateActiveStep();
  }

  @Output()
  reschedulingOptionsChange: EventEmitter<ReschedulingSessionOptions> = new EventEmitter<ReschedulingSessionOptions>();

  get activeStep(): number {
    // @ts-expect-error TS2322
    return this._activeStep;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get reschedulingOptions(): ReschedulingSessionOptions {
    return this._reschedulingOptions;
  }

  onNewDateChange(newDate: IReschedulingSessionDate): void {
    const reschedulingOptionsUpdate: ReschedulingSessionOptions = {
      ...this.reschedulingOptions,
      newDate
    };

    this.reschedulingOptionsChange.emit(reschedulingOptionsUpdate);
  }

  onPaymentInfoChange(): void {
    this.reschedulingOptionsChange.emit(this.reschedulingOptions);
  }

  onMessageChange(messageInfo: ISessionRescheduleMessage): void {
    this._skippedMessage = messageInfo.skipped;

    const reschedulingOptionsUpdate: ReschedulingSessionOptions = {
      ...this.reschedulingOptions,
      message: messageInfo.message
    };

    this.reschedulingOptionsChange.emit(reschedulingOptionsUpdate);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _calculateActiveStep(): void {
    if (this._reschedulingOptions) {
      this._activeStep = ReschedulingSteps.RESCHEDULING_DETAILS;
      if (this._reschedulingOptions.newDate) {
        this._activeStep = ReschedulingSteps.MESSAGE;
      }
      if ((this._reschedulingOptions.message && !this._skippedMessage) || this._skippedMessage) {
        this._activeStep = ReschedulingSteps.PAYMENT;
      }
    }
  }
}
