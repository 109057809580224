const nodeType = 1; // Node.ELEMENT_NODE;

export const clipboardPasteMatcher = {
  matchers: [
    [
      nodeType,
      // @ts-expect-error TS7006
      (node, delta) => {
        // @ts-expect-error TS7034
        const ops = [];

        // @ts-expect-error TS7006
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            const data = {
              insert: op.insert,
              attributes: op.attributes
            };

            ops.push(data);
          }
        });

        // @ts-expect-error TS7005
        delta.ops = ops;

        return delta;
      }
    ]
  ]
};
