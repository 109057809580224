import { Directive, OnInit } from '@angular/core';
import {
  ScreenSize,
  ScreenState
} from '@app/modules/ui-kit/_base/_common/screen/services/style-breakpoints-provider.service';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { ScreeSelector } from '@app/modules/ui-kit/_base/_common/screen/entries/_base.directive';

@Directive({
  selector: '[MobileAOrSmallTablet]',
  providers: [UiDestroyService]
})
export class MobileOrSmallTabletDirective extends ScreeSelector<ScreenSize> implements OnInit {
  screens = [ScreenSize.Mobile, ScreenSize.STablet];

  comparor(params: Partial<ScreenState>): boolean {
    // @ts-expect-error TS2322
    return params[ScreenSize.Mobile] || params[ScreenSize.STablet];
  }
}
