import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AbstractNavigationEntriesService } from '@app/modules/navigation/abstracts';
import { INavigationEntry } from '@app/modules/navigation/interfaces';

@Injectable()
export class NavigationEntriesAdminService extends AbstractNavigationEntriesService {
  role = UserRoles.ADMIN;

  entries: Observable<INavigationEntry>[] = [
    // widget - primary
    this.navigationEntryFactory.adminDashboard$(),
    this.navigationEntryFactory.adminCoaches$(),
    this.navigationEntryFactory.adminClients$(),
    this.navigationEntryFactory.adminGiftCoupons$(),
    this.navigationEntryFactory.adminSessions$(),
    this.navigationEntryFactory.adminTags$(),
    this.navigationEntryFactory.adminWiki$(),
    this.navigationEntryFactory.adminBlog$(),
    this.navigationEntryFactory.adminPrograms$(),
    this.navigationEntryFactory.adminDefaultServices$(),
    this.navigationEntryFactory.adminDefaultForms$(),
    this.navigationEntryFactory.adminOtherBlogs$(),
    this.navigationEntryFactory.adminBusinessBlog$(),
    this.navigationEntryFactory.adminBranding$(),
    this.navigationEntryFactory.adminNotificationsConfig$(),
    this.navigationEntryFactory.adminGlobalConfig$(),
    this.navigationEntryFactory.adminCommunityConfig$(),
    this.navigationEntryFactory.adminHeaderFooterConfig$(),
    this.navigationEntryFactory.adminPages$(),
    this.navigationEntryFactory.adminPlatformSubscriptions$(),
    this.navigationEntryFactory.adminPaymentOptions$(),
    this.navigationEntryFactory.adminAccountSettings$(),
    this.navigationEntryFactory.adminReports$(),
    this.navigationEntryFactory.adminCommunity$(),
    this.navigationEntryFactory.adminCommunitySettings$()
  ];
}
