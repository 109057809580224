import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { ContentModuleProgress } from './content-module-progress';
import { GroupSessionModuleProgress } from './group-session-module-progress';
import { QuizModuleProgress } from './quiz-module-progress';
import { SessionModuleProgress } from './session-module-progress';

export type ClientModuleProgress =
  | ContentModuleProgress
  | QuizModuleProgress
  | GroupSessionModuleProgress
  | SessionModuleProgress;

export function isContentModuleProgress(
  programModule: Readonly<ClientModuleProgress>
): programModule is ContentModuleProgress {
  return programModule.moduleType === ModuleTypes.CONTENT;
}

export function isGroupSessionModuleProgress(
  programModule: Readonly<ClientModuleProgress>
): programModule is GroupSessionModuleProgress {
  return programModule.moduleType === ModuleTypes.GROUP_SESSION;
}

export function isQuizModuleProgress(
  programModule: Readonly<ClientModuleProgress>
): programModule is QuizModuleProgress {
  return programModule.moduleType === ModuleTypes.QUIZ;
}

export function isSessionModuleProgress(
  programModule: Readonly<ClientModuleProgress>
): programModule is SessionModuleProgress {
  return programModule.moduleType === ModuleTypes.SESSION;
}
