import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Injectable()
export class FormService {
  /**
   * Marks all controls in a form group as touched
   * @param formGroup
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  markFormDirtyAndTouched(formGroup: FormGroup | FormArray) {
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
      control.updateValueAndValidity({ onlySelf: true });

      if (control.markAsDirtyAndTouched) {
        control.markAsDirtyAndTouched();
      } else if (control.controls) {
        this.markFormDirtyAndTouched(control);
      }
    });
  }

  /**
   * Marks all controls in a form group as touched if the form is invalid. Returns true if the form is invalid
   * @param formGroup
   */
  markInvalidForm(formGroup: FormGroup | FormArray): boolean {
    if (formGroup.invalid) {
      formGroup.markAsDirty();
      formGroup.markAllAsTouched();
      this.markFormDirtyAndTouched(formGroup);
      return true;
    }
    return false;
  }
}
