import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgSelectModule } from '@ng-select/ng-select';

import { ScheduleDateTimePickerComponent } from './components/schedule-date-time-picker/schedule-date-time-picker.component';

@NgModule({
  imports: [ClickOutsideModule, CommonModule, FormsModule, NgbDatepickerModule, NgSelectModule],
  exports: [ScheduleDateTimePickerComponent],
  declarations: [ScheduleDateTimePickerComponent]
})
export class ScheduleDateTimePickerModule {}
