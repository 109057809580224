import { ConfigItemTypes, IConfigManifest } from './types';
import { SsoMode } from './types/global-config';
import { LOCATION_ORIGIN } from '../consts';

const defaultOgImage = `${LOCATION_ORIGIN}/assets/images/branding/platform-meta-og-image.png?v=2`;

export const globalConfigManifest: IConfigManifest = {
  inPersonSessionsAvailable: {
    separatorBefore: 'Permissions management',
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Allow providing in-person sessions'
  },
  inCallSessionsAvailable: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Allow providing phone call sessions'
  },
  enableServiceCreationForTeamMembers: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Allow team members to create services'
  },
  enableMarketplace: {
    separatorBefore: 'Main settings',
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Display [Find your guide] menu item (Client)'
  },
  enablePrograms: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display [Programs] menu item (Client)'
  },
  enableSurveys: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display [Forms] menu item (Client)'
  },
  enableHelpMeFindAGuide: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display [Get Personalized Help] button (Client)'
  },
  enableECourcesNav: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display [Forms] menu item (Coach)'
  },
  enableBlogNav: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display [Blog] menu item (Coach/Header)'
  },
  enableSessionRoomNav: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display [Session room] menu item'
  },
  displayMoneyBackGuarantee: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display 100% Money back guarantee'
  },
  displayHIPAACompliant: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Display HIPAA Compliant'
  },
  displayCoachesOfClient: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: "Display Client's Coaches"
  },
  enablePaymentsRequiredTaxIdWithAddresses: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Request address and Tax ID for payment'
  },
  enableSecureMessagesInNotifications: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide the body of secure messages in emails and SMS'
  },
  hideWallet: {
    separatorBefore: "Coach's Wallet",
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide wallet'
  },
  enablePayoutsOfCoach: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Enable payout section'
  },
  enablePaypalPayoutOfCoach: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Enable PayPal payout'
  },
  enableStripePayoutOfCoach: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    title: 'Enable Stripe payout',
    separatorAfter: true
  },
  urlInLogo: {
    type: ConfigItemTypes.text,
    defaultValue: `our-community`,
    title: 'URL in the platform logo'
  },
  urlPrivacy: {
    type: ConfigItemTypes.text,
    defaultValue: `${LOCATION_ORIGIN}/page/privacy`,
    title: 'URL to privacy page'
  },
  urlTerms: {
    type: ConfigItemTypes.text,
    defaultValue: `${LOCATION_ORIGIN}/page/terms`,
    title: 'URL to terms page'
  },
  urlCancellationPolicy: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title: 'URL to cancellation policy page'
  },
  emailHelp: {
    type: ConfigItemTypes.text,
    defaultValue: 'mailto:help@profi.io',
    title: 'Help email'
  },
  urlVisitHelpCenter: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title: 'URL to Visit help center page'
  },
  hideProfileTags: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide tags "I help with" section (Public profile)'
  },
  hideProfileSalesText: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide sales text "About" section (Public profile)'
  },
  hideProfileLocationLanguage: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide "Location and Language" section (Public profile)'
  },
  hideProfileQualifications: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide "Qualifications" section (Public profile)'
  },
  hideHeadersDashboardSessionsBlocks: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide block\'s headers "Session Invitations / Upcoming sessions / Past sessions"'
  },
  hideGiftCertificate: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide Gift certificate banner on the platform'
  },
  loginScreenBgUrl: {
    type: ConfigItemTypes.uploader,
    defaultValue: '',
    title: 'Background image for "/auth" page'
  },
  hideNavigatorLanguages: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide default system languages in languages filter on /coaches (/consultants) page'
  },
  hideAddLanguages: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide + languages filter on /coaches (/consultants) page'
  },
  defaultLanguages: {
    type: ConfigItemTypes.text,
    defaultValue: 'en',
    title:
      'Default languages in iso format splitted by comma (,) to use in filters on /coaches (/consultants) page. Ex.: en, ru. Check https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes for reference.'
  },
  hideSignInAs: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide "Sign in as Guide/Client" in user menu (role switcher)'
  },
  hideSignUpAs: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide "Sign up as Guide/Client" in user menu',
    hidden: true
  },
  homePageRoute: {
    type: ConfigItemTypes.text,
    defaultValue: '/auth/sign-in',
    title: 'The route to redirect not authorized users. Start with /. Ex.: /auth/sign-in'
  },
  hideCoachReviews: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Hide reviews in coach card on /coaches (/consultants) page and in coach public profile'
  },
  showPriceStartsFrom: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: `Allow practitioners to display their starting price on practitioner's public page, "/our-community" and "/coaches" pages`
  },
  showWhiteLogoOnCoachesPage: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: `Show White logo on "/coaches" page`
  },
  separatedAuth: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Sign up all new users as Clients',
    hidden: true
  },
  ssoMode: {
    separatorBefore: 'Login page',
    type: ConfigItemTypes.select,
    defaultValue: SsoMode.DISABLED,
    title: 'SSO required for',
    templateAfter: 'samlAuthTemplate',
    options: [
      {
        label: 'No SSO required',
        value: SsoMode.DISABLED
      },
      {
        label: 'Practitioners',
        value: SsoMode.PRACTITIONER
      },
      {
        label: 'Clients',
        value: SsoMode.CLIENT
      },
      {
        label: 'All Users ',
        value: SsoMode.ALL_USERS
      }
    ]
  },
  isGoogleAuthEnabled: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    hidden: true,
    title: 'External auth provider Google enabled status'
  },
  isFacebookAuthEnabled: {
    type: ConfigItemTypes.checkbox,
    defaultValue: true,
    hidden: true,
    title: 'External auth provider Facebook enabled status'
  },
  showNewsletter: {
    type: ConfigItemTypes.checkbox,
    defaultValue: false,
    title: 'Show checkbox “I agree to receive an email newsletter” in sign up pop-up.'
  },
  blogPageTitle: {
    separatorBefore: 'Blog',
    type: ConfigItemTypes.text,
    defaultValue: 'A constant source of inspiration for holistic health',
    title: 'Title for /blog page'
  },
  blogLogoText: {
    type: ConfigItemTypes.text,
    defaultValue: 'Blog',
    title: 'Text after logo for /blog page'
  },
  blogBusinessLogoText: {
    type: ConfigItemTypes.text,
    defaultValue: 'Business Blog',
    title: 'Text after logo for /business-blog page'
  },
  blogBusinessSubTitle: {
    type: ConfigItemTypes.text,
    defaultValue: 'Tips to build, manage, and scale your business',
    title: 'Subtitle fo business blog'
  },
  blogBannerTitle: {
    separatorBefore: 'Blog banner content',
    type: ConfigItemTypes.text,
    defaultValue: 'Join awarenow',
    title: 'Title for banner on the /blog page (max 40 chars)'
  },
  blogBannerText: {
    type: ConfigItemTypes.text,
    defaultValue:
      'All the coaching tools you need in one place. Manage and grow your business with our all-in-one platform.',
    title: 'Text for banner on the /blog page (max 110 chars)'
  },
  blogBannerLink: {
    type: ConfigItemTypes.text,
    defaultValue: `[Try awarenow](${LOCATION_ORIGIN})`,
    title: 'Link (special format): [link name](https://example.com)'
  },
  blogBannerBackgroundImage: {
    type: ConfigItemTypes.uploader,
    defaultValue: `${LOCATION_ORIGIN}/assets/images/blog-item-platform-ads-bg.svg`,
    title: 'Background image'
  },
  metaKeywordsMainPage: {
    separatorBefore: 'Meta',
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health &amp; wellness',
    title: 'Meta key words for main page'
  },
  metaTitleMainPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'coaching platform | awarenow',
    title: 'Meta title for main page'
  },
  metaDescriptionMainPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'Streamline and maximize your coaching impact with our all-in-one platform',
    title: 'Meta description for main page'
  },
  metaImageMainPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for main page'
  },
  metaKeywordsGiftCouponsPage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'gift, gift cards, gift coupons, holistic coaching, alternative therapy, mindfulness, wellness, wellbeing',
    title: 'Meta key words for gift coupon page'
  },
  metaTitleGiftCouponsPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'awarenow - Gift certificate',
    title: 'Meta title for gift coupon page'
  },
  metaDescriptionGiftCouponsPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'Share the care. Give the gift of wellbeing to your loved ones!',
    title: 'Meta description for gift coupon page'
  },
  metaImageGiftCouponsPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: `${LOCATION_ORIGIN}/assets/images/gift-certificate.png`,
    title: 'Meta image for gift coupon page'
  },
  metaKeywordsBlogPage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health &amp; wellness',
    title: 'Meta key words for blog page'
  },
  metaTitleBlogPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'awarenow - blog',
    title: 'Meta title for blog page'
  },
  metaDescriptionBlogPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'taking care of yourself with us is easy - anywhere, anytime',
    title: 'Meta description for blog page'
  },
  metaImageBlogPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for b page'
  },
  metaKeywordsGuidesPage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health & wellness',
    title: 'Meta key words for guides page'
  },
  metaTitleGuidesPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'awarenow - wellbeing on demand platform',
    title: 'Meta title for guides page'
  },
  metaDescriptionGuidesPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'taking care of yourself with us is easy - anywhere, anytime',
    title: 'Meta description for guides page'
  },
  metaImageGuidesPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for guides page'
  },
  metaKeywordsGuideProfilePage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title:
      'Meta key words for guide profile page. Guide tags will be added automatically, add more keywords or leave empty.'
  },
  metaTitleGuideProfilePage: {
    type: ConfigItemTypes.text,
    defaultValue: 'awarenow',
    title: 'Meta title for guide profile page. Guide full name will be added to your title automatically.'
  },
  metaDescriptionGuideProfilePage: {
    type: ConfigItemTypes.text,
    defaultValue: 'taking care of yourself with us is easy - anywhere, anytime',
    title:
      'Meta description for guide page. Guide short quote will be used automatically. Add a default description to use if guide does not have short quote.'
  },
  metaImageGuideProfilePage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title:
      'Meta image for gift profile page. Guide profile image will be used by default. Add a default image to use if guide does not have an image.'
  },
  metaKeywordsProgramPage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health &amp; wellness',
    title: 'Meta key words for program page.'
  },
  metaTitleProgramPage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title: 'Meta title for program page. Program name will be added to your title automatically.'
  },
  metaDescriptionProgramPage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title:
      'Meta description for program page. Program description will be used automatically. Add a default description to use if program does not have a description.'
  },
  metaImageProgramPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for program page.'
  },
  metaKeywordsWikiPage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health &amp; wellness',
    title: 'Meta key words for wiki page.'
  },
  metaTitleWikiPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'awarenow - wiki',
    title: 'Meta title for wiki page.'
  },
  metaDescriptionWikiPage: {
    type: ConfigItemTypes.text,
    defaultValue: 'taking care of yourself with us is easy - anywhere, anytime',
    title: 'Meta description for wiki page.'
  },
  metaImageWikiPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for wiki page.'
  },
  metaTitleBookPage: {
    type: ConfigItemTypes.text,
    defaultValue: `Book`,
    title: 'Meta title for book page. [Guide name] - [platform name] will be added automatically.'
  },
  metaTitleSessionPage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title: 'Meta title for session page. Session name will be added to your title automatically.'
  },
  metaDescriptionSessionPage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title:
      'Meta description for session page. Session description will be used automatically. Add a default description to use if session does not have a description.'
  },
  metaImageSessionPage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for session page.'
  },
  metaKeywordsSessionPage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health &amp; wellness',
    title: 'Meta key words for session page.'
  },
  metaTitlePackagePage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title: 'Meta title for package page. Package name will be added to your title automatically.'
  },
  metaDescriptionPackagePage: {
    type: ConfigItemTypes.text,
    defaultValue: '',
    title:
      'Meta description for package page. Package description will be used automatically. Add a default description to use if package does not have a description.'
  },
  metaImagePackagePage: {
    type: ConfigItemTypes.uploader,
    defaultValue: defaultOgImage,
    title: 'Meta image for package page.'
  },
  metaKeywordsPackagePage: {
    type: ConfigItemTypes.text,
    defaultValue:
      'holistic coaching, alternative therapy, mindfulness, wellness, wellbeing, holistic health &amp; wellness',
    title: 'Meta key words for package page.'
  }
};
