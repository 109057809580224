<app-header></app-header>

<app-alerts-provider></app-alerts-provider>

<div class="container">
  <div class="row no-gutters flex-column-sm">
    <app-sidebar *ngIf="isAuth" class="col sidebar d-none d-sm-block"></app-sidebar>
    <div *ngIf="!isAuth" class="vertical-aggregate"></div>

    <app-body class="col-sm" [ngClass]="{ centered: !isAuth }">
      <!-- temporary commented as we do not promote marketplace atm -->
      <!-- <ng-container *ngIf="isUnconfirmedGuide">
        <div class="unconfirmed-guide-notification-container">
          <h6 class="notification-title" i18n="@@yourProfileHasNotBeenPublished">
            Your profile hasn't been published yet
          </h6>

          <div class="notification">
            <p class="notification-text">
              <i class="notification-icon"></i>
              <ng-container i18n="@@profileWillBeAvailableForOthers">
                The profile will be available for viewing by other users after moderation by the
                administrator
              </ng-container>
            </p>
          </div>
        </div>
      </ng-container> -->

      <div class="app-body-overlay" (click)="hideMenu()"></div>

      <router-outlet></router-outlet>
    </app-body>
  </div>
</div>
<app-footer></app-footer>
