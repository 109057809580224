import { Component, OnInit } from '@angular/core';
import { BookingStepsStore } from '@appWidget/modules/booking-root/service/booking-steps-store.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking-root.component.html',
  styleUrls: ['./booking-root.component.scss'],
  providers: [BookingStepsStore]
})
export class BookingRootComponent implements OnInit {
  constructor(private bookingStepsStore: BookingStepsStore) {}

  ngOnInit(): void {
    this.bookingStepsStore.handleActivatedRouteChange();
  }
}
