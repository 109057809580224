import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatUserDetailed } from '@app/core/chat/types';
import { GuideRelationTypes } from '@app/core/users/types';

@Component({
  selector: 'app-not-yet-registered',
  templateUrl: './not-yet-registered.component.html',
  styleUrls: ['./not-yet-registered.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotYetRegisteredComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userName: string;

  // @ts-expect-error TS2564
  private contactId: number;

  // @ts-expect-error TS2564
  email: string;

  @Input()
  set contact(contact: ChatUserDetailed) {
    this.contactId = contact.id;
    // @ts-expect-error TS2322
    this._userName = contact.firstName || contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.email;
    // @ts-expect-error TS2322
    this.email = contact.email;
  }

  @Output()
  goToUser = new EventEmitter<{ id: number; type: GuideRelationTypes }>();

  get userName(): string {
    return this._userName;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  goToContactInfo() {
    this.goToUser.emit({ id: this.contactId, type: GuideRelationTypes.GUIDE_CONTACT });
  }
}
