<div class="modal-body">
  <app-button class="close-btn" className="close" (click)="modal.dismiss()"></app-button>

  <section class="guide-client-container">
    <div class="guide-client-photo-container">
      <!--      <app-user-photo [photoUrl]="relation?.photo" [size]="68"></app-user-photo>-->
      <pui-avatar
        [avatarUrl]="relation?.photo"
        [text]="relation?.name"
        [style.width]="68"
        [style.height]="68"></pui-avatar>
    </div>

    <div class="guide-client-name-container">
      {{ relation.name }}
    </div>

    <div class="guide-client-contacts-container" *ngIf="relation?.contacts">
      <div class="guide-client-email-container" *ngIf="relation?.contacts?.email && isEmail(relation.contacts.email)">
        {{ relation.contacts.email }}
      </div>

      <div class="guide-client-phone-container" *ngIf="relation?.contacts?.phone">
        {{ relation.contacts.phone }}
      </div>
    </div>
  </section>

  <section class="guide-client-notes-container">
    <app-text-editor-notes
      #addNoteEditor
      (noteChange)="onNoteChange($event)"
      (endEditing)="onNoteChange($event)"
      [emptyNotePreview]="emptyNotePreview"
      [minLines]="15"
      [shouldClamp]="false"></app-text-editor-notes>

    <ng-template #emptyNotePreview>
      <div class="empty-note">
        <span i18n="@@guideRelationInfoModalTakeNote">Take a note...</span>
      </div>
    </ng-template>
  </section>
</div>
