import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { ITimezoneOption } from '@app/shared/utils/generate-timezones';
import { NgbDateTimeStringAdapter } from '@app/shared/ng-bootstrap/ngb-date-time-string-adapter';
import { CUSTOM_NGB_DATE_PARSER_CONFIG } from '@app/shared/ng-bootstrap/ngb-custom-date-parser-formatter';
import { ngbCustomDateFormatterFactory } from '@app/shared/ng-bootstrap/ngb-custom-date-formatter-factory';
import { ScheduleDateTimePickerConfig } from '../../schedule-date-time-picker.config';
import { IScheduleOptions, ITimePickerContext } from '../../types';
import resolve from '@platformStyle/utils/resolve';
import { LocaleService } from '@app/core/locale/locale.service';

export const SCHEDULE_DATE_TIME_PICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ScheduleDateTimePickerComponent),
  multi: true
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-schedule-date-time-picker',
  templateUrl: './schedule-date-time-picker.component.html',
  styleUrls: [
    '../../../../../../../scss/datepicker/datepicker.scss',
    '../../../common-styles/timezone-picker.scss',
    './schedule-date-time-picker.component.scss'
  ],
  providers: [
    SCHEDULE_DATE_TIME_PICKER_VALUE_ACCESSOR,
    { provide: NgbDateAdapter, useClass: NgbDateTimeStringAdapter },
    { provide: CUSTOM_NGB_DATE_PARSER_CONFIG, useValue: resolve('DATE', 'toFormat') },
    {
      provide: NgbDateParserFormatter,
      useFactory: ngbCustomDateFormatterFactory,
      deps: [CUSTOM_NGB_DATE_PARSER_CONFIG, LocaleService]
    }
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'schedule-date-time-picker'
  }
})
export class ScheduleDateTimePickerComponent<TScheduleTimeSlot extends { label: string }, TSelectedTime>
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
  private readonly destroy$ = new Subject();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _duration: number;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _time: TSelectedTime;
  readonly timeControl = this._formBuilder.control(null);

  @Input()
  dateInputAllowed: boolean;

  @Input()
  dateInputPlaceholder: string;

  @Input()
  datePickerAutoClose: boolean | 'inside' | 'outside';

  @Input()
  datePickerNavigation: 'select' | 'arrows' | 'none';

  @Input()
  datePickerPlacement: PlacementArray;

  @Input()
  firstDayOfWeek: 1 | 2 | 3 | 4 | 5 | 6 | 7;

  @Input()
  // @ts-expect-error TS2564
  markDateDisabled: ((date: NgbDate, current?: { year: number; month: number }) => boolean) | null;

  @Input()
  // @ts-expect-error TS2564
  maxDate: NgbDateStruct;

  @Input()
  // @ts-expect-error TS2564
  minDate: NgbDateStruct;

  @Input()
  disabledWithoutSlots = false;

  @Input()
  set scheduleOptions(value: IScheduleOptions) {
    if (!value) {
      return;
    }

    if (value.currentDate?.date) {
      this.date = DateTime.fromISO(value.currentDate.date).toString();
    } else if (value.date) {
      this.date = value.date;
    }

    if (value.timezone) {
      this.timezone = value.timezone;
    }

    if (value.duration) {
      this._duration = value.duration;
    }
  }

  @Input()
  scheduleTimeSlots: TScheduleTimeSlot[] = [];

  @Input()
  searchTimezone: (term: string, timezone: ITimezoneOption) => boolean;

  @Input()
  set time(value: TSelectedTime) {
    this.writeValue(value);
  }

  @Input()
  // @ts-expect-error TS2564
  timePicker: TemplateRef<ITimePickerContext<TScheduleTimeSlot>>;

  @Input()
  timezoneClearable: boolean;

  @Input()
  timezones: ITimezoneOption[] = [];

  @Output()
  readonly scheduleOptionsChange = new EventEmitter<IScheduleOptions>();

  @Output()
  readonly timeChange = new EventEmitter<TSelectedTime>();

  // @ts-expect-error TS2564
  date: string;
  isDisabled = false;
  timezone = DateTime.local().zoneName;
  isSlotsLoaded = false;

  constructor(config: ScheduleDateTimePickerConfig, private _formBuilder: FormBuilder) {
    this.dateInputAllowed = config.dateInputAllowed;
    this.dateInputPlaceholder = config.dateInputPlaceholder;
    this.datePickerAutoClose = config.datePickerAutoClose;
    this.datePickerNavigation = config.datePickerNavigation;
    this.datePickerPlacement = config.datePickerPlacement;
    this.firstDayOfWeek = config.firstDayOfWeek;
    // @ts-expect-error TS2322
    this.searchTimezone = config.searchTimezone;
    this.timezoneClearable = config.timezoneClearable;
  }

  ngOnInit(): void {
    this.timeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: TSelectedTime) => {
      if (!this.isDisabled) {
        this.onTouched();
        this.onChange(value);
        this.timeChange.emit(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('scheduleTimeSlots')) {
      // Enable datepicker after slots will be load
      this.checkLoadedSlots(changes.scheduleTimeSlots);
    }
  }

  onScheduleOptionsChange(): void {
    this.scheduleOptionsChange.emit({
      date: this.date,
      duration: this._duration,
      timezone: this.timezone
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.isDisabled) {
      this.timeControl.disable();
    } else {
      this.timeControl.enable();
    }
  }

  writeValue(value: TSelectedTime): void {
    this._time = value;
    this.timeControl.setValue(value, { emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};

  private checkLoadedSlots(scheduleTimeSlots: SimpleChange): void {
    const prevValue = scheduleTimeSlots.previousValue;
    const currentValue = scheduleTimeSlots.currentValue;

    if (prevValue === undefined) {
      return;
    }

    if ((prevValue === null || prevValue.length === 0) && (currentValue.length === 0 || currentValue.length > 0)) {
      this.isSlotsLoaded = true;
    }
  }
}
