import { Quiz } from '../quiz';
import { ModuleTypes } from '../../program-module';
import { BaseAccessibleModule, IBaseAccessibleModuleDetails } from './base-accessible-module';
import { OpenedModuleStatus } from './module-statuses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizModuleDetails extends IBaseAccessibleModuleDetails {
  readonly quiz: Quiz;
  readonly status: OpenedModuleStatus;
}

export class QuizModule extends BaseAccessibleModule {
  readonly quiz: Quiz;
  readonly status: OpenedModuleStatus;

  get type(): ModuleTypes {
    return ModuleTypes.QUIZ;
  }

  constructor(moduleDetails: Readonly<IQuizModuleDetails>) {
    super(moduleDetails);

    this.quiz = moduleDetails.quiz;
    this.status = moduleDetails.status;
  }
}
