import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  clientTagUpdate,
  deleteTag,
  untagClient,
  updateClientTags
} from '@app/screens/guide/guide-clients/guide-relations-table/store/clients-tags/clients-tags.actions';
import { PuiDialog, PuiDialogService, PuiOption } from '@awarenow/profi-ui-core';
import { ContactViewModel } from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';
import {
  clientTagsAsOptionSelector,
  sortedTagsAsOptionSelector
} from '@app/screens/guide/guide-clients/guide-relations-table/store/clients-tags/client-tags.selectors';
import { map, take } from 'rxjs/operators';
import { CustomFieldValue } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';
import { MAX_TAGS_COUNT } from '@app/screens/guide/guide-clients/consts/max-tags-count';

const DEFAULT_TAG_COLOR = '#F0F0F0';

const COLOR_VARIANTS: string[] = [
  '#FFCCDD',
  '#FFDDCC',
  '#FFF2B2',
  '#DDFFCC',
  '#B3FFB3',
  '#CCEEFF',
  '#CCDDFF',
  '#FFCCFF',
  '#FFB3B3',
  DEFAULT_TAG_COLOR
];

const enum DialogOptionDeleteResult {
  CLOSE,
  DELETE_OPTION
}
const MAX_VALUE_COUNT = MAX_TAGS_COUNT;

@Injectable()
export class ClientsTagsFeatureService {
  readonly DEFAULT_TAG_COLOR = DEFAULT_TAG_COLOR;
  readonly COLOR_VARIANTS = COLOR_VARIANTS;
  readonly MAX_VALUE_COUNT = MAX_VALUE_COUNT;

  options$ = this.store$.select(sortedTagsAsOptionSelector);

  constructor(private readonly store$: Store<unknown>, private readonly dialogService: PuiDialogService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getSelectedOptions$(client: ContactViewModel) {
    // @ts-expect-error TS7053
    return this.store$.select(clientTagsAsOptionSelector).pipe(map(clients => clients[client.id]));
  }

  handleSelectedOptionsChange(client: ContactViewModel, options: PuiOption[]): void {
    // Clear client tags If next option is empty.
    if (options.length === 0) {
      this.store$.dispatch(
        // This action will tag client
        updateClientTags({
          client,
          tags: []
        })
      );

      return;
    }

    if (options.length > MAX_VALUE_COUNT) {
      console.warn(`You trying to add more that ${MAX_VALUE_COUNT} tags`);

      return;
    }

    this.store$.dispatch(
      updateClientTags({
        client,
        tags: options.map(this.toField)
      })
    );
  }

  deleteSelectedOption(client: ContactViewModel, option: PuiOption): void {
    this.store$.dispatch(
      untagClient({
        client,
        tags: [this.toField(option)]
      })
    );
  }

  deleteOptionConfirm(option: PuiOption): void {
    const dialog = new PuiDialog();

    dialog.title = 'Delete tag';
    dialog.description = 'Deleting a tag removes it from all client records. Continue?';

    dialog.buttons = [
      {
        label: 'Delete',
        appearance: 'error',
        click: ref => {
          ref.close(DialogOptionDeleteResult.DELETE_OPTION);
        }
      },
      {
        label: 'Cancel',
        appearance: 'secondary',
        click: ref => {
          ref.close(DialogOptionDeleteResult.CLOSE);
        }
      }
    ];

    const ref = this.dialogService.open(dialog, {
      size: 's',
      hasCloseButton: true
    });

    ref.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result === DialogOptionDeleteResult.DELETE_OPTION) {
        this.store$.dispatch(deleteTag({ tagId: option.data?.id }));
      }
    });
  }

  updateTag(option: PuiOption, update: { [key: string]: string }): void {
    const field: CustomFieldValue = this.toField(option);

    this.store$.dispatch(
      clientTagUpdate({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        tagId: field.id,
        update: {
          ...field,
          ...update
        }
      })
    );
  }

  private toField(option: PuiOption): CustomFieldValue {
    return {
      id: option.data?.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      name: option.value,
      color: option.data?.color || DEFAULT_TAG_COLOR
    };
  }
}
