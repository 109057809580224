<h5 class="title" i18n="@@upcomingSessionTitle" *ngIf="!hideHeader">Upcoming sessions</h5>

<ng-template [ngIf]="sessions && sessions.length" [ngIfElse]="noUpcomingSessions">
  <app-upcoming-client-session
    *ngFor="let session of sessions | sortOnlySessionDate: 'dateStart':'asc'"
    [clientSession]="session"
    class="user-session with-actions-menu flex-wrap"
    [attr.data-qa-id]="'upcoming-client-session-item-' + session?.session?.name"></app-upcoming-client-session>
</ng-template>

<ng-template #noUpcomingSessions>
  <div class="no-sessions" i18n="@@upcomingClientSessionsEmptyText">Upcoming sessions will show up here</div>
</ng-template>
