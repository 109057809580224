import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, combineLatest, Observable, partition, ReplaySubject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { AlertTemplates } from '@app/modules/alerts/services/helpers/alert-templates';
import { Alert } from '@app/modules/alerts/types/alert';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { UserRoles } from '@app/shared/enums/user-roles';

import config from '../config/config';
import { MembershipService } from '../membership/membership.service';
import { ClientsSettingOptions, MembershipSettings } from '../membership/types';
import { SocketService } from '../socket/socket.service';
import { OnlineStatusService } from '../status/online-status.service';
import { RelatedUsers } from './related-users';
import {
  GuideClient,
  GuideRelation,
  guideRelationFactory,
  GuideRelationTypes,
  IServerGuideRelation,
  isGuideClient,
  isGuideRelation,
  relationTypeToPathResolver
} from './types';

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createSessionClientLocalId = session => {
  if (!session || !session.clientId) {
    return null;
  }

  return GuideClient.createLocalId({ id: session.clientId, type: GuideRelationTypes.GUIDE_CLIENT });
};

type GuideRelationWithNotes = GuideRelation & { notes: string };

@Injectable()
export class GuideClientsService extends RelatedUsers<string, GuideRelation> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientsNumberAllowed$ = new ReplaySubject<number>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientsNumberLeft$ = new ReplaySubject<number>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientsLimitAlert$ = new ReplaySubject<Alert | null>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientsLimitReached$ = new ReplaySubject<boolean>(1);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideId: number | null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _selectedRelationId$ = new BehaviorSubject<string | null>(null);

  get clients$(): Observable<GuideClient[]> {
    return this.users$.pipe(map(users => users.filter(isGuideClient)));
  }

  get relations$(): Observable<GuideRelation[]> {
    return this.users$.pipe(
      map(users => {
        return users.filter(isGuideRelation);
      })
    );
  }

  get clientsNumberAllowed(): Observable<number> {
    return this._clientsNumberAllowed$.asObservable();
  }

  get clientsNumberLeft(): Observable<number> {
    return this._clientsNumberLeft$.asObservable();
  }

  get clientsLimitAlert(): Observable<Alert | null> {
    return this._clientsLimitAlert$.asObservable();
  }

  get clientsLimitReached(): Observable<boolean> {
    return this._clientsLimitReached$.asObservable();
  }

  get selectedRelationId(): BehaviorSubject<string | null> {
    return this._selectedRelationId$;
  }

  constructor(
    _http: HttpClient,
    _notifications: NotificationsService,
    _onlineStatuses: OnlineStatusService,
    _auth: AuthService,
    _socket: SocketService,
    private _membershipService: MembershipService,
    private readonly _activeWorkspace: WorkspacesService
  ) {
    super(`${config.apiPath}/user/guide/relations`, _http, _notifications, _onlineStatuses);

    _auth.onAuth().subscribe(user => {
      if (user && user.RoleId === UserRoles.GUIDE && user.id !== this._guideId) {
        this._guideId = user.id;

        _socket
          .onSessionChanged()
          .pipe(
            map(session => createSessionClientLocalId(session)),
            filter(clientLocalId => !!clientLocalId),
            // @ts-expect-error TS2345
            mergeMap(clientLocalId => this.getUser$(clientLocalId)),
            takeUntil(this.destroy$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe();

        // eslint-disable-next-line rxjs/no-nested-subscribe
        _socket.onPackageEnrolled().subscribe(() => this.refresh());
        // eslint-disable-next-line rxjs/no-nested-subscribe
        _socket.guideClientsUpdate().subscribe(() => this.refresh());

        const [definiteRelationUpdate$, allRelationsUpdate$] = partition(
          _socket.onGuideRelationsUpdate().pipe(map(response => response.relation)),
          relation => !!relation
        );

        // eslint-disable-next-line rxjs/no-nested-subscribe
        allRelationsUpdate$.pipe(takeUntil(this.destroy$)).subscribe(() => this.refresh());

        definiteRelationUpdate$
          .pipe(
            switchMap(relation => this.loadUser$(GuideClient.createLocalId(relation))),
            takeUntil(this.destroy$)
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe();
      }
      // Todo: refactor subscribe in subscribe
      _socket
        .onSessionChanged()
        .pipe(
          filter(() => user && user.RoleId === UserRoles.GUIDE),
          takeUntil(this.destroy$)
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe(() => this.refresh());
    });

    combineLatest([_membershipService.userPlan$, this._activeWorkspace.isSolo$]).subscribe(([plan, isSolo]) => {
      if (plan && isSolo) {
        const _setting = plan.settings.find(({ setting }) => setting.name === MembershipSettings.CLIENTS);

        if (_setting && _setting.value !== ClientsSettingOptions.UNLIMITED) {
          return this._clientsNumberAllowed$.next(+_setting.value);
        }
      }

      return this._clientsNumberAllowed$.next(Infinity);
    });

    combineLatest([this.users$, this.clientsNumberAllowed]).subscribe(([guideRelations, clientsAllowed]) => {
      const filteredRelations = guideRelations.filter(
        relation => relation.type === GuideRelationTypes.GUIDE_CLIENT && !relation.archived
      );

      if (filteredRelations.length - 1 >= clientsAllowed) {
        this._clientsNumberLeft$.next(0);
        this._clientsLimitReached$.next(true);
        this._clientsLimitAlert$.next(AlertTemplates.get('clients-limit-reached', clientsAllowed));
        return;
      }

      this._clientsNumberLeft$.next(clientsAllowed - filteredRelations.length + 1);
      this._clientsLimitReached$.next(false);
      this._clientsLimitAlert$.next(null);
    });
  }

  getClient$(id: number | undefined): Observable<GuideClient | null> {
    const localId = id && GuideClient.createLocalId({ id, type: GuideRelationTypes.GUIDE_CLIENT });
    // @ts-expect-error TS2345
    return this.getUser$(localId).pipe(filter(isGuideClient));
  }

  protected addOrUpdateUser(user: GuideRelation): void {
    if (this._users.has(user.localId)) {
      this._users.set(user.localId, user);
    } else {
      const newUsers = [...this._users.values(), user].sort();
      this._users.clear();
      newUsers.forEach(guideRelation => this._users.set(guideRelation.localId, guideRelation));
    }

    this.fireUsersUpdated();
  }

  protected getUserIdsToTrackOnlineStatus(): number[] {
    return [...this._users.values()].filter(isGuideClient).map(guideClient => guideClient.id);
  }

  protected loadUser$(localId: string): Observable<GuideRelation> {
    const { id, type: guideRelationType } = GuideClient.parseLocalId(localId);
    const pathType = relationTypeToPathResolver(guideRelationType);

    if (!pathType) {
      return throwError(new Error('Cannot resolve user type'));
    }

    // @ts-expect-error TS2322
    return this._http.get<{ relation: IServerGuideRelation }>(`${this.ENDPOINT}/${pathType}/${id}`).pipe(
      map(({ relation }) => (relation ? { relation: guideRelationFactory(relation), notes: relation.notes } : null)),
      tap(relationAndNotes => {
        if (relationAndNotes) {
          const { relation } = relationAndNotes;

          this.addOrUpdateUser(relation);

          this._refreshUsersOnlineStatuses$.next();
        }
      }),
      map(relationAndNotes => (relationAndNotes ? relationAndNotes.relation : null))
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected mapToUsers(serverResponse) {
    return serverResponse.relations;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  protected prepareHttpParamsObjectForUsersLoader(localIds: string[]) {
    return localIds.reduce(
      (paramsObject, localId) => {
        const { id, type: guideRelationType } = GuideClient.parseLocalId(localId);

        // WARNING: implicit check for clients ids being untyped
        const typeArray =
          guideRelationType === GuideRelationTypes.GUIDE_CONTACT ? paramsObject.contacts : paramsObject.clients;
        // @ts-expect-error TS2345
        typeArray.push(id);

        return paramsObject;
      },
      {
        contacts: [],
        clients: []
      }
    );
  }

  protected updateUsers(serverUsers: GuideRelationWithNotes[]): void {
    this._users.clear();
    serverUsers.forEach(serverUser => {
      const guideRelation = guideRelationFactory(serverUser);
      this._users.set(guideRelation.localId, guideRelation);
    });

    this.fireUsersUpdated();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setSelectedRelationId(id: number, type: GuideRelationTypes) {
    const localId = GuideClient.createLocalId({ id, type });
    this._selectedRelationId$.next(localId);
  }

  // @ts-expect-error TS7006
  addGuides(userId: number, guideIds: number[], onSaveHandler): void {
    this.addGuides$(userId, guideIds)
      .pipe(
        catchError(error => {
          const msg = `No guide was assigned`;
          this._notifications.error(msg);
          return throwError(error);
        })
      )
      .subscribe(() => {
        const title = `Assigned team members saved`;
        this._notifications.success(title);
        onSaveHandler();
      });
  }
}
