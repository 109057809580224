import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributeOptions, ClientSessionRecurringAttribute } from '../types';
import { ClientSessionAttributesEnum } from '@libs/business/client/client-session/modules';

export const clientSessionRecurringAttribute = ({
  isRecurrent
}: ClientSessionAttributeOptions): ClientSessionRecurringAttribute | null => {
  if (!isRecurrent) {
    return null;
  }

  return {
    type: ClientSessionAttributesEnum.RECURRING,
    leftIcon: SessionAttributeIconEnum.REPEAT,
    label: ` Recurring`
  };
};
