import { Injector, Provider } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { SIDENAV_CANDIDATES } from '@app/modules/sidenav/tokens';
import { sidenavSCHEMAFactory } from './factory';

export const SIDENAV_SCHEMA_PROVIDER: Provider = {
  provide: SIDENAV_CANDIDATES,
  useFactory: sidenavSCHEMAFactory,
  deps: [AuthService, Injector]
};
