import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { PuiDialogRef } from '@awarenow/profi-ui-core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-client-all-notes',
  templateUrl: './client-all-notes.component.html',
  styleUrls: ['./client-all-notes.component.scss']
})
export class ClientAllNotesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private readonly router: Router, @Optional() private dialogRef?: PuiDialogRef) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.close());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.dialogRef?.close();
  }
}
