import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITimezoneOption } from '@app/shared/utils/generate-timezones';
import { IScheduleOptions } from '@app/modules/ui-kit/schedule/schedule-date-time-picker';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export const SINGLE_DATE_SCHEDULE_TIME_PICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SingleDateScheduleTimePickerComponent),
  multi: true
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-single-date-schedule-time-picker',
  templateUrl: './single-date-schedule-time-picker.component.html',
  styleUrls: ['./single-date-schedule-time-picker.component.scss'],
  providers: [SINGLE_DATE_SCHEDULE_TIME_PICKER_VALUE_ACCESSOR]
})
export class SingleDateScheduleTimePickerComponent<TScheduleTimeSlot extends { label: string }, TSelectedTime>
  implements ControlValueAccessor
{
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _time: TSelectedTime;

  @Input()
  // @ts-expect-error TS2564
  markDateDisabled: ((date: NgbDate, current?: { year: number; month: number }) => boolean) | null;

  @Input()
  // @ts-expect-error TS2564
  maxDate: { readonly year: number; readonly month: number; readonly day: number } | null;

  @Input()
  // @ts-expect-error TS2564
  minDate: { readonly year: number; readonly month: number; readonly day: number } | null;

  @Input()
  // @ts-expect-error TS2564
  scheduleOptions: IScheduleOptions;

  @Input()
  scheduleTimeSlots: TScheduleTimeSlot[] = [];

  @Input()
  set time(value: TSelectedTime) {
    this.writeValue(value);
  }

  @Input()
  timezones: ITimezoneOption[] = [];

  @Output()
  readonly scheduleOptionsChange = new EventEmitter<IScheduleOptions>();

  @Output()
  readonly timeChange = new EventEmitter<TSelectedTime>();

  isDisabled = false;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get time(): TSelectedTime {
    return this._time;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectTime(time: TSelectedTime): void {
    if (this.isDisabled) {
      return;
    }

    this.writeValue(time);
    this.onChange(time);
    this.timeChange.emit(time);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: TSelectedTime): void {
    this._time = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};
}
