import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMenuAction {
  id: string;
  label: string;
  class: string;
}

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsMenuComponent {
  @Input()
  actions: IMenuAction[] = [];

  @Output()
  select = new EventEmitter<string>();

  open = false;

  emit(action: IMenuAction): void {
    this.select.emit(action.id);
    this.toggle();
  }

  toggle(): void {
    if (this.actions.length === 0) {
      return;
    }

    this.open = !this.open;
  }
}
