import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export interface GoogleButtonProps {
  theme: 'light' | 'dark' | 'neutral';
  shape: 'square' | 'rounded';
  text: string;
}

@Component({
  selector: 'button[google-button]',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleButtonComponent implements GoogleButtonProps {
  @Input()
  @HostBinding('attr.data-google-button-shape')
  shape!: 'square' | 'rounded';

  @Input()
  text!: string;

  @Input()
  @HostBinding('attr.data-google-button-theme')
  theme!: 'light' | 'dark' | 'neutral';
}
