import { Injectable } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { GuideServiceParent } from '@app/modules/book-service';
import { IClientGuideAvailableSession, IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { SessionActionType, SessionIcon, SessionPropertyFactory, SessionStatus } from '@libs/business/cdk/session';
import {
  ClientSessionAttribute,
  ClientSessionAttributesFactory,
  clientSessionStatusIconFactory,
  clientSessionStatusLabelFactory
} from '@libs/business/client/client-session/modules';

@Injectable()
export class ClientSessionPropertyFactory
  implements SessionPropertyFactory<IClientGuideSession, GuideServiceParent, ClientSessionAttribute>
{
  constructor(private readonly runtimeConfig: RuntimeConfigService, private readonly localeService: LocaleService) {}

  getId(meta: IClientGuideSession): number {
    return meta.session.id;
  }

  getIcon(meta: IClientGuideSession | undefined): SessionIcon | undefined {
    return (
      meta && {
        text: meta.guide.name,
        url: meta.guide.photo
      }
    );
  }

  getStatus(meta: IClientGuideSession | undefined): SessionStatus | undefined {
    if (!meta) {
      return undefined;
    }

    const sessionStatusValue = meta.session.status;
    const icon = clientSessionStatusIconFactory(sessionStatusValue);
    const label = clientSessionStatusLabelFactory({
      status: sessionStatusValue,
      name: this.getGuideName(meta),
      missed: meta.session.missed,
      isFreeSession: meta.session.isSessionFree,
      isExpired: meta.session.isExpired
    });

    return icon || label ? { icon, label } : undefined;
  }

  getSessionName(meta: IClientGuideSession | undefined): string {
    if (!meta) {
      return '';
    }

    return meta.session.name || '';
  }

  getAttributes(meta: IClientGuideSession | undefined): ClientSessionAttribute[] {
    if (!meta) {
      return [];
    }

    return ClientSessionAttributesFactory.getAttributes({
      locale: this.localeService.getLocale(),
      platformName: this.runtimeConfig.get('platformName'),
      name: this.getGuideName(meta),
      address: meta.session.address,
      phoneForSessions: meta.session.guide?.phoneForSessions || undefined,
      connectionType: [
        SessionStatuses.CANCELLED,
        SessionStatuses.GUIDE_OFFER_CANCELLED,
        SessionStatuses.GUIDE_OFFER_DECLINED,
        SessionStatuses.DECLINED,
        SessionStatuses.EXPIRED
      ].includes(meta.session.status)
        ? null
        : meta.session.connectionType,
      callInitiator: meta.session.callInitiator,
      dateStart: meta.session.dateStart,
      serviceType: meta.session.serviceType,
      timezone: meta.timezone,
      duration: meta.session.duration,
      chatLinkId: buildDirectChatLinkId(meta.guide),
      isRecurrent: meta.session.isRecurrent,
      guests: meta.session.guests?.map(({ email }) => email) || [],
      hideAddress: !!meta.session.hideAddress,
      eventUuid: meta.session.eventUuid
    });
  }

  getParent(meta: IClientGuideSession): GuideServiceParent | undefined {
    return meta.session.serviceParent;
  }

  getGuideName(meta: IClientGuideSession): string {
    return meta.guide.name;
  }

  getAvailable(meta: IClientGuideAvailableSession): number {
    return meta.session.availableSessions;
  }

  getConnectionType(meta: IClientGuideAvailableSession): SessionConnectionTypes {
    return meta.session.connectionType;
  }

  getTotal(meta: IClientGuideAvailableSession): number {
    return meta.session.totalSessions;
  }

  getTemplateId(meta: IClientGuideSession): number {
    return meta.session.templateId!;
  }

  getActions(meta: IClientGuideSession): SessionActionType[] {
    const actionTypes: SessionActionType[] = [];
    const isCancellable =
      !meta.session.isPastSession &&
      (meta.session.status === SessionStatuses.APPROVED ||
        meta.session.status === SessionStatuses.PENDING_APPROVEMENT ||
        meta.session.status === SessionStatuses.RESCHEDULE_BY_CLIENT);

    const canAskToRescheduleSession =
      meta.session.serviceType === SessionTypes.GROUP_INSTANCE &&
      !meta.session.restrictClientBooking &&
      (meta.session.status === SessionStatuses.APPROVED ||
        meta.session.status === SessionStatuses.PENDING_APPROVEMENT ||
        meta.session.status === SessionStatuses.RESCHEDULE_BY_CLIENT);

    let canBookAgain = true;
    if (meta.session.serviceParent?.type === GuideServiceTypes.PACKAGE) {
      // We cant book again Package session  from this UI component
      canBookAgain = false;
    } else if (
      meta.session.serviceParent?.type === GuideServiceTypes.PROGRAM &&
      [
        SessionStatuses.PENDING_APPROVEMENT,
        SessionStatuses.PENDING,
        SessionStatuses.APPROVED,
        SessionStatuses.IN_PROGRESS,
        SessionStatuses.EXPIRED,
        SessionStatuses.DONE
      ].includes(meta.session.status)
    ) {
      canBookAgain = false;
    } else if (meta.session.restrictClientBooking) {
      canBookAgain = false;
    }

    const isArchivable =
      meta.session.serviceParent?.type !== GuideServiceTypes.PACKAGE &&
      (meta.session.status === SessionStatuses.CANCELLED ||
        meta.session.status === SessionStatuses.DECLINED ||
        meta.session.status === SessionStatuses.GUIDE_OFFER_CANCELLED ||
        meta.session.status === SessionStatuses.GUIDE_OFFER_DECLINED ||
        meta.session.isPastSession);

    if (canAskToRescheduleSession) {
      actionTypes.push('ask-to-reschedule');
    }

    if (canBookAgain) {
      actionTypes.push('book-again');
    }

    if (isArchivable) {
      actionTypes.push('hide');
    }

    if (isCancellable) {
      actionTypes.push('cancel');
    }

    return actionTypes;
  }

  getSessionType(meta: IClientGuideSession): GuideServiceTypes {
    return meta.session.serviceType as unknown as GuideServiceTypes;
  }

  getShowJoinButton(meta: IClientGuideSession): boolean {
    const isVideoConnectionType = [SessionConnectionTypes.ZOOM, SessionConnectionTypes.IN_PLATFORM].includes(
      meta.session.connectionType
    );
    const isInProgressSession = meta.session.status === SessionStatuses.IN_PROGRESS;
    const isApprovedSession = meta.session.status === SessionStatuses.APPROVED;

    return isVideoConnectionType && (isApprovedSession || isInProgressSession);
  }
}
