import { createAction, props } from '@ngrx/store';
import {
  CreateGuideClient,
  Guide,
  CRMClient
} from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';

export const guideClientsResetAll = createAction(`[guide-clients-store] Reset all`);
/**
 * This action will set state status to "loading". And start fetching all clients.
 */
export const guideClientsFetchAll = createAction(`[guide-clients-store] Fetch all`);
/**
 * Same with {guideClientsFetchAll} action, this is action never set {status} state.
 */
export const guideClientsRefetchAll = createAction(`[guide-clients-store] Refetch all`);
export const guideClientsFetchAllSuccess = createAction(
  `[guide-clients-store] Fetch all success`,
  props<{
    clients: CRMClient[];
  }>()
);
export const guideClientsFetchAllError = createAction(`[guide-clients-store] Fetch all error`);

// CREATE
export const createClients = createAction(
  `[guide-clients-store] Create clients`,
  props<{ create: CreateGuideClient }>()
);

// Client Actions
export const setClients = createAction(
  `[guide-clients-store] Set clients`,
  props<{
    payload: Record<CRMClient['id'], Partial<CRMClient>>;
  }>()
);

// API
export const updateClients = createAction(
  `[guide-clients-store] Update clients`,
  props<{
    update: Record<CRMClient['id'], Partial<CRMClient>>;
  }>()
);
export const updateClientsSuccess = createAction(`[guide-clients-store] Update clients success`);
export const updateClientsError = createAction(`[guide-clients-store] Update clients error`);

/**
 * @deprecated
 */
export const assignGuidesForClient = createAction(
  '[guide-clients-store] Assign guides for client',
  props<{ id: CRMClient['id']; guides: Guide['userId'][] }>()
);
/**
 * @deprecated
 */
export const assignGuidesForClientSuccess = createAction(
  '[guide-clients-store] Assign guides for client success',
  props<{ id: CRMClient['id']; guides: Guide[] }>()
);
/**
 * @deprecated
 */
export const assignGuidesForClientError = createAction('[guide-clients-store] Assign guides for client error');

export type GuideClientsStoreActions =
  | typeof guideClientsResetAll
  | typeof guideClientsFetchAll
  | typeof guideClientsFetchAllSuccess
  | typeof guideClientsFetchAllError;
