import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { AuthModalComponent } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { UserRoles } from '@app/shared/enums/user-roles';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject, throwError } from 'rxjs';
import { delay, finalize, take } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IDefaultPrepaymentValidationOptions {
  canTryFix: boolean;
  onBeforeFixHook?: () => void;
}

@Injectable({
  providedIn: 'root',
  deps: [AuthService, NgbModal],
  useFactory: (auth: AuthService, modal: NgbModal) => new DefaultPrepaymentValidationStrategy(auth, modal)
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class PrepaymentValidationStrategyService<TValidationArgs = any, TValidationSuccess = void> {
  abstract validate$(
    args: TValidationArgs,
    canTryFix?: boolean,
    onBeforeFixHook?: () => void
  ): Observable<TValidationSuccess>;
}

@Injectable()
export class DefaultPrepaymentValidationStrategy extends PrepaymentValidationStrategyService<IDefaultPrepaymentValidationOptions> {
  constructor(private _auth: AuthService, private _modal: NgbModal) {
    super();
  }

  validate$(options: IDefaultPrepaymentValidationOptions): Observable<void> {
    if (this._auth.isAuthorized) {
      // @ts-expect-error TS2322
      return this._auth.user.RoleId === UserRoles.CLIENT ? of(null) : throwError(null);
    }

    if (options.canTryFix) {
      if (options.onBeforeFixHook) {
        options.onBeforeFixHook();
      }

      const authFinished$ = new Subject<void>();
      this.tryAuthenticateClient$()
        .pipe(
          // TODO: find out why finalize called before the modal was cleared and get rid of delay
          delay(2000),
          finalize(() => {
            if (this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.CLIENT) {
              // @ts-expect-error TS2345
              authFinished$.next(null);
            } else {
              authFinished$.error('Invalid auth');
            }
          })
        )
        .subscribe();

      return authFinished$.pipe(take(1));
    }

    return throwError(null);
  }

  protected tryAuthenticateClient$(): Observable<void> {
    const { componentInstance, result } = this._modal.open(AuthModalComponent, {
      windowClass: 'auth-modal'
    });
    componentInstance.onlyClient = true;

    return modalResultToObservable$(result);
  }
}
