<ng-container *ngFor="let notification of notifications; let last = last">
  <div class="notification-item" *ngIf="systemNotificationService.isSessionNotification(notification)">
    <app-session-notification
      [notification]="notification"
      (click)="emitNotificationClick()"></app-session-notification>
  </div>
  <div class="notification-item" *ngIf="notification.details && notification.details.package">
    <app-package-notification
      [notification]="notification"
      (click)="emitNotificationClick()"></app-package-notification>
  </div>
  <div class="notification-item" *ngIf="notification.details && notification.details.programId">
    <app-program-notification
      [notification]="notification"
      (click)="emitNotificationClick()"></app-program-notification>
  </div>
  <div class="notification-item" *ngIf="notification.details && notification.details.quizTemplateId">
    <app-form-notification
      [notification]="notification"
      (click)="emitNotificationClick(notification.details)"></app-form-notification>
  </div>
  <div class="notification-item" *ngIf="notification.details && notification.details.notesLink">
    <app-note-notification
      [notification]="notification"
      (click)="emitNotificationClick(notification.details)"></app-note-notification>
  </div>
  <div
    class="notification-item"
    *ngIf="notification.details && notification.details.workspaceId && !notification.details?.communityId">
    <app-workspace-notification
      data-qa-id="workspace-notification"
      [notification]="notification"
      (changeWorkspaceEvent)="redirectEvent.emit($event)"></app-workspace-notification>
  </div>
  <div class="notification-item" *ngIf="notification.details && notification.details.communityId">
    <app-community-notification
      data-qa-id="workspace-notification"
      [notification]="notification"></app-community-notification>
  </div>
  <div class="notification-item" *ngIf="!notification.details">
    <app-simple-notification [notification]="notification"></app-simple-notification>
  </div>
  <div class="divider-container">
    <pui-divider *ngIf="!last"></pui-divider>
  </div>
</ng-container>
