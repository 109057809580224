import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {
  constructor(private readonly _datePipe: DatePipe) {}

  transform(date: string, format: string): string | null {
    if (!date) {
      return null;
    }

    return this._datePipe.transform(date, format, 'UTC');
  }
}
