import { Inject, Injectable } from '@angular/core';
import { AbstractConditionService, ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import { NAVIGATION_CONDITIONS } from '@app/modules/navigation/tokens/navigation-conditions';

@Injectable()
export class ConditionsService extends AbstractConditionService<VisibilityCondition> {
  constructor(@Inject(NAVIGATION_CONDITIONS) readonly conditions: Partial<ConditionsMap<VisibilityCondition>>) {
    super();
  }
}
