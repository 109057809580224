import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class CoverImageService {
  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  uploadCoverImage(
    coverImage: File,
    coverImageThumb: File,
    formPrefix = 'package',
    endpoint: string
  ): Observable<{ coverImage: string | null; coverImageThumb: string | null }> {
    const formData: FormData = new FormData();
    formData.append(`${formPrefix}cover`, coverImage, coverImage.name);
    formData.append(`${formPrefix}coverthumb`, coverImage, coverImageThumb.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this._http
      .post<{ coverImage: string | null; coverImageThumb: string | null }>(`${endpoint}/upload-cover`, formData, {
        headers
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Image failed to upload`);
          return throwError(error);
        })
      );
  }

  removeCoverImage(endpoint: string, id: number | string): Observable<null> {
    return this._http.delete<null>(`${endpoint}/cover-image/${id}`).pipe(
      catchError(error => {
        this._notifications.error(`Image not deleted`);
        return throwError(error);
      })
    );
  }
}
