<div class="change-timezone__close-button">
  <pui-icon (click)="close()" svgIcon="pui:x"></pui-icon>
</div>
<div class="change-timezone-container">
  <div class="pui-space_bottom-4">
    <h2 i18n="@@ChangeYourTimeZone" pui-typography="h2">Change your time zone?</h2>
  </div>

  <div class="pui-space_bottom-5">
    <div pui-typography="body-s">
      <span i18n="@@YourSystemTimeChangedTo" class="pui-space_right-1">Your system time changed to</span>
      <span pui-typography="body-s-bold">{{ timezoneName }}</span>
    </div>
  </div>

  <button class="pui-space_right-3" (click)="change()" pui-button>
    <span i18n="@@ChangeTimeZone">Change time zone</span>
  </button>
  <button (click)="close()" pui-button appearance="secondary">
    <span i18n="@@DontChange">Don’t change</span>
  </button>
</div>
