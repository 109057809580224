<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.user?.photo"
    [text]="notification.details?.user | userName"></pui-avatar>
  <dt class="notification-title">
    <span>{{ notification.details?.user | userName }}</span>
    <span class="notification-date">{{ notification.dateCreated | notificationDateFormatter }}</span>
  </dt>
  <dd>
    <span class="description-text">
      <ng-container *ngIf="isClient">
        <ng-container *ngIf="notification.details?.singleNoteAccess; else allNotesWithClient">
          <ng-container>
            {{ notification.details?.user | userName }}
            <span i18n="@@shareNoteNotificationHasSharedAText">has shared a&nbsp;</span>
            <app-button
              className="btn-link"
              routerLink="/client/dashboard"
              [queryParams]="{ openDialog: 'notes' }"
              i18n="@@shareNoteNotificationLink">
              note
            </app-button>
            <span i18n="@@shareNoteNotificationWithYouText">&nbsp;with you.</span>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isClient">
        <ng-container *ngIf="notification.details?.singleNoteAccess; else allNotesWithTeamMember">
          <ng-container>
            {{ notification.details?.user | userName }}
            <span i18n="@@shareNoteNotificationHasSharedAText">has shared a&nbsp;</span>
            <app-button
              className="btn-link"
              (click)="showClientDetails(notification.details?.clientId!)"
              i18n="@@shareNoteNotificationLink">
              note
            </app-button>
            <span i18n="@@shareNoteNotificationWithYouText">&nbsp;with you.</span>
          </ng-container>
        </ng-container>
      </ng-container>
    </span>
  </dd>
</dl>

<ng-template #allNotesWithTeamMember>
  <ng-container>
    {{ notification.details?.user | userName }}
    <span i18n="@@shareAllNotesNotificationText">has provided you with access to all</span>
    {{ notification.details?.clientName }}'s

    <app-button
      className="btn-link"
      routerLink="/coach/clients"
      [queryParams]="{ relationId: notification.details?.clientId, tab: GuideClientCardTab.NOTES }"
      i18n="@@shareAllNotesNotificationLinkText">
      notes.
    </app-button>
  </ng-container>
</ng-template>

<ng-template #allNotesWithClient>
  <ng-container>
    {{ notification.details?.user | userName }}
    <span i18n="@@shareAllNotesWithClientNotificationText">has provided you access to</span>

    <app-button
      className="btn-link"
      routerLink="/client/dashboard"
      [queryParams]="{ openDialog: 'notes' }"
      i18n="@@shareAllNotesNotificationLink">
      &nbsp;notes.
    </app-button>
  </ng-container>
</ng-template>
