import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '@app/core';
import { pluck, tap } from 'rxjs/operators';
import { SimpleSession } from '@app/shared/interfaces/session';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTemplateLocation } from '@app/screens/guide/guide-sessions-templates/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { SessionCanceledEvent } from '@app/core/analytics/events/session-canceled.event';

export type PrivateSession = SimpleSession;
export interface SessionResponse {
  id: number;
  name: string;
  guideId: number;
  guideEventUid: string;
  scheduledBy: number;
  clientId: number;
  dateStart?: string;
  date?: string;
  status?: SessionStatuses;
  duration: number;
  free?: boolean;
  templateId?: number;
  eventId: number;
  description?: string;
  price?: number;
  location: SessionTemplateLocation;
  recurringSessionsDetails?: {
    id: number;
    dateStart?: string;
    date?: string;
    duration: number;
  }[];
  guide: {
    firstName: string;
    lastName: string;
    phoneForSessions: string | null;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PrivateSessionsService {
  constructor(private httpClient: HttpClient, private analyticsService: AnalyticsService) {}

  getAll(): Observable<PrivateSession[]> {
    return this.httpClient.get<PrivateSession[]>(`${config.apiPath}/user/client/sessions`);
  }

  // TODO return SessionResponse from backend
  getById(sessionId: number): Observable<SessionResponse> {
    return this.httpClient
      .get<{ session: SessionResponse }>(`${config.apiPath}/user/client/sessions/${sessionId}`)
      .pipe(pluck('session'));
  }

  declineSession$(id: number, message: string, cancelAllRecurring = true): Observable<unknown> {
    const body = message ? { id, reason: message, cancelAllRecurring } : { id, cancelAllRecurring };
    return this.httpClient
      .post<void>(`${config.apiPath}/user/client/sessions/offers/cancel`, body)
      .pipe(tap(() => this.analyticsService.emitEvent(new SessionCanceledEvent())));
  }

  cancelSession$(id: number, message: string, cancelAllRecurring = true): Observable<unknown> {
    const body = message ? { id, reason: message, cancelAllRecurring } : { id, cancelAllRecurring };
    return this.httpClient
      .post<void>(`${config.apiPath}/user/client/sessions/cancel`, body)
      .pipe(tap(() => this.analyticsService.emitEvent(new SessionCanceledEvent())));
  }
}
