import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { UiAutocomplete } from '../ui-autocomplete/autocomplete';
import { UiAutocompleteTrigger } from '../ui-autocomplete/autocomplete-trigger';

@Component({
  selector: 'ui-chip-list-input',
  template: `
    <ng-template #inputTemplateRef let-input>
      <input
        #inputElement
        #trigger="uiAutocompleteTrigger"
        [(ngModel)]="value"
        [placeholder]="input.placeholder"
        matAutocompleteOrigin
        [attr.data-qa-id]="input.qaId"
        #defaultOrigin="matAutocompleteOrigin"
        [uiAutocomplete]="autocomplete"
        [matAutocompleteDisabled]="autocompleteDisabled"
        [matAutocompleteConnectedTo]="autocompleteConnectedTo || defaultOrigin"
        [matChipInputFor]="input.chipList"
        [matChipInputAddOnBlur]="false"
        [matChipInputSeparatorKeyCodes]="input.separatorKeysCodes"
        (matChipInputTokenEnd)="onInputTokenEnd($event)" />
    </ng-template>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-chip-list-input'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiChipListInput {
  @Input()
  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.valueChanged.emit(this._value);
  }

  // @ts-expect-error TS2322
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _value: string = null;

  @Input() placeholder = '';

  @Input() qaId = '';

  @Input() separatorKeysCodes!: number[] | ReadonlySet<number>;

  @Input() autocomplete!: UiAutocomplete;

  @Input() autocompleteConnectedTo!: MatAutocompleteOrigin;

  @Input() autocompleteDisabled = false;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get chipList(): any {
    return this._chipList;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set chipList(value: any) {
    if (value) {
      this._chipList = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  _chipList!: any;

  @Output() inputTokenEnd: EventEmitter<string> = new EventEmitter<string>();

  @Output() valueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

  @ViewChild('inputTemplateRef', {
    static: true,
    read: TemplateRef
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: TemplateRef<any> | null = null;

  @ViewChild('inputElement', { read: ElementRef }) inputElement: ElementRef | undefined;

  @ViewChild(UiAutocompleteTrigger) trigger: UiAutocompleteTrigger | undefined;

  reset(): void {
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
  }

  onInputTokenEnd($event: MatChipInputEvent): void {
    this.inputTokenEnd.emit($event.value);
    this.reset();
  }
}
