<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.user?.photo"
    [text]="notification.details?.user | userName"></pui-avatar>
  <dt class="notification-title">
    <span class="client-name">
      {{ userName }}
    </span>

    <span class="notification-date">{{ notification.dateCreated | notificationDateFormatter }}</span>
  </dt>
  <dd class="event-status">
    <span class="status-text">
      {{ notification.description }}
    </span>
  </dd>
</dl>
