import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISystemNotification, SystemNotificationTypes } from '@app/modules/notifications/types';

export const ProgramNotificationText = {
  [SystemNotificationTypes.PROGRAM_INVITE_INFORMATIVE]: `invited you to`,
  [SystemNotificationTypes.PROGRAM_INVITE_PREPAID]: `You are enrolled in`,
  [SystemNotificationTypes.PROGRAM_MODULE_AVAILABLE]: `shared a new module`,
  [SystemNotificationTypes.PROGRAM_COMPLETE]: `You completed`
};

@Component({
  selector: 'app-program-notification',
  templateUrl: './program-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './program-notification.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-app-program-notification' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramNotificationComponent {
  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;

  NOTIFICATION_TYPE = SystemNotificationTypes;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getNotificationMessage(type: SystemNotificationTypes) {
    // @ts-expect-error TS7053
    return ProgramNotificationText[type]
      ? // @ts-expect-error TS7053
        ProgramNotificationText[type]
      : this.notification.details?.note || this.notification.description;
  }
}
