import { Component, Inject } from '@angular/core';

import { CURRENT_PAYMENT_TYPE, CurrentPaymentService } from '../../services/current-payment.service';
import { PaymentType, Payment } from '../../types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-current-payment',
  template: ''
})
export class CurrentPaymentComponent {
  protected currentPayment: Payment;

  constructor(
    @Inject(CURRENT_PAYMENT_TYPE) private paymentType: PaymentType,
    private _currentPaymentService: CurrentPaymentService
  ) {
    // @ts-expect-error TS2322
    this.currentPayment = this._currentPaymentService.createNewPayment(paymentType);
  }
}
