import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailabilityViewComponent } from './components/availability-view/availability-view.component';
import { PuiTypographyModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [AvailabilityViewComponent],
  exports: [AvailabilityViewComponent],
  imports: [CommonModule, PuiTypographyModule]
})
export class AvailabilityModule {}
