import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfiAvailability } from '@libs/services/http/http-workspace-availability.service';

import { converterWorkingHours, FormattedAvailability } from './helper';

@Component({
  selector: 'app-availability-view',
  templateUrl: './availability-view.component.html',
  styleUrls: ['./availability-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailabilityViewComponent {
  @Input() accentName = false;

  @Input() showNamePostfix = false;

  @Input() isOwnAvailability = false;

  @Input('availability') set setWorkingDates(data: { availability: ProfiAvailability }) {
    this.availability = data.availability;
    this.workingDates = converterWorkingHours(data.availability.template, data.availability.timeZone);
  }

  availability: ProfiAvailability | null = null;
  workingDates: FormattedAvailability[] = [];
}
