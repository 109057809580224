import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { IGuideClientSession } from '@app/screens/guide/guide-dashboard/types';
import { Session } from '@app/shared/interfaces/session';

@Injectable()
export class GuideSessionsCommunicationsService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _acceptSession$ = new Subject<Session>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _approveReschedulement$ = new Subject<IGuideClientSession>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _archiveSession$ = new Subject<IGuideClientSession>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _cancelSession$ = new Subject<IGuideClientSession>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _cancelGroupSession$ = new Subject<IGuideClientSession>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _endSession$ = new Subject<IGuideClientSession>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _rescheduleSession$ = new Subject<IGuideClientSession>();

  get acceptSession$(): Observable<Session> {
    return this._acceptSession$.asObservable();
  }

  get approveReschedulement$(): Observable<IGuideClientSession> {
    return this._approveReschedulement$.asObservable();
  }

  get archiveSession$(): Observable<IGuideClientSession> {
    return this._archiveSession$.asObservable();
  }

  get endSession$(): Observable<IGuideClientSession> {
    return this._endSession$.asObservable();
  }

  get cancelGroupSession$(): Observable<IGuideClientSession> {
    return this._cancelGroupSession$.asObservable();
  }

  get cancelSession$(): Observable<IGuideClientSession> {
    return this._cancelSession$.asObservable();
  }

  get rescheduleSession$(): Observable<IGuideClientSession> {
    return this._rescheduleSession$.asObservable();
  }

  acceptSession(session: Session): void {
    this._acceptSession$.next(session);
  }

  approveReschedulement(guideSession: IGuideClientSession): void {
    this._approveReschedulement$.next(guideSession);
  }

  archiveSession(guideSession: IGuideClientSession): void {
    this._archiveSession$.next(guideSession);
  }

  cancelGroupSession(guideSession: IGuideClientSession): void {
    this._cancelGroupSession$.next(guideSession);
  }

  cancelSession(guideSession: IGuideClientSession): void {
    this._cancelSession$.next(guideSession);
  }

  endSession(guideSession: IGuideClientSession): void {
    this._endSession$.next(guideSession);
  }

  rescheduleSession(guideSession: IGuideClientSession): void {
    this._rescheduleSession$.next(guideSession);
  }
}
