<ng-container *ngFor="let service of services" [ngSwitch]="service.kind">
  <ng-container *ngSwitchCase="GuideServiceKinds.SINGLE">
    <ng-container [ngSwitch]="service.type">
      <app-guide-group-session-service
        *ngSwitchCase="GuideServiceTypes.GROUP_SESSION"
        [service]="service"
        [description]="defaultDescription"
        (click)="selectService(service)"
        class="guide-services-item"></app-guide-group-session-service>

      <app-guide-package-service
        *ngSwitchCase="GuideServiceTypes.PACKAGE"
        [service]="service"
        (click)="selectService(service)"
        class="guide-services-item"></app-guide-package-service>

      <app-guide-program-service
        *ngSwitchCase="GuideServiceTypes.PROGRAM"
        [service]="service"
        (click)="selectService(service)"
        class="guide-services-item"></app-guide-program-service>

      <app-guide-session-service
        *ngSwitchCase="GuideServiceTypes.SESSION"
        [service]="service"
        [description]="defaultDescription"
        (click)="selectService(service)"
        class="guide-services-item"></app-guide-session-service>

      <app-guide-webinar-service
        *ngSwitchCase="GuideServiceTypes.WEBINAR"
        [service]="service"
        (click)="selectService(service)"
        class="guide-services-item"></app-guide-webinar-service>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="GuideServiceKinds.GROUP">
    <ng-container [ngSwitch]="service.type">
      <ng-container *ngSwitchCase="GuideServiceTypes.PROGRAM">
        <app-guide-program-service-group
          *ngIf="canDisplayProgram(service.services)"
          [serviceGroup]="service"
          (serviceSelect)="selectService($event)"></app-guide-program-service-group>
      </ng-container>
      <app-guide-package-service-group
        *ngSwitchCase="GuideServiceTypes.PACKAGE"
        [serviceGroup]="service"
        (serviceSelect)="selectService($event)"></app-guide-package-service-group>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultDescription let-service="service">
  <div *ngIf="service.description" class="service-description">
    <button
      type="button"
      class="btn-link"
      [class.less]="collapsedDescriptions[service.id]"
      [attr.aria-expanded]="!collapsedDescriptions[service.id]"
      [attr.aria-controls]="'description_container' + service.id"
      (click)="$event.stopPropagation(); collapsedDescriptions[service.id] = !collapsedDescriptions[service.id]">
      <ng-container *ngIf="!collapsedDescriptions[service.id]" i18n="@@showMore">Show more</ng-container>
      <ng-container *ngIf="collapsedDescriptions[service.id]" i18n="@@showLess">Show less</ng-container>
    </button>

    <div [ngbCollapse]="!collapsedDescriptions[service.id]" [id]="'description_container' + service.id">
      <div [innerHTML]="service?.description | safeHtml"></div>
    </div>
  </div>
</ng-template>
