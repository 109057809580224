import { DateTime } from 'luxon';

import { ExternalCalendarSources } from '@app/core/calendars/types';
import { GuideClient } from '@app/core/users/types';
import { IGuideClientSession } from '@app/screens/guide/guide-dashboard/types';
import { BlockedInterval } from '@app/screens/guide/guide-sessions/services/schedule';
import {
  BufferTime,
  ExternalCalendarEventTypes,
  FullCalendarCustomEventCssClasses,
  FullCalendarEvent,
  FullCalendarEventType,
  FullCalendarSessionEventTypes,
  IExternalEvent
} from '@app/screens/guide/guide-sessions/types';
import { FullCalendarUnavailableTimeEventTypes } from '@app/screens/guide/guide-sessions/types/full-calendar-blocked-time-events';
import { GroupSession } from '@app/shared/interfaces/session';
import { collectServiceTypesFromSession } from '@app/shared/utils/badges';

import { fullCalendarEventTypesClassNames } from './full-calendar-data';
import { getBlockedIntervalType, getServiceTypeClass, getSessionStatusClasses, getTitle } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function blockedIntervalsToFullCalendarEvents(intervals: any[], guideId: number): FullCalendarEvent[] {
  return intervals.map(interval => blockedIntervalToFullCalendarEvents(interval, guideId));
}

export function blockedIntervalToFullCalendarEvents(
  interval: BlockedInterval & { parentType: string },
  guideId: number
): FullCalendarEvent {
  const blockIntervalType = getBlockedIntervalType(interval, guideId);
  const type =
    interval.parentType === 'schedule'
      ? FullCalendarUnavailableTimeEventTypes.SCHEDULE_UNAVAILABILITY_INTERVAL
      : blockIntervalType;

  const fullCalendarEvent: FullCalendarEvent = {
    type,
    id: interval.bookingId,
    start: interval.start,
    end: interval.end,
    classNames: fullCalendarEventTypesClassNames[type],
    additionalData: {
      session: {
        guideId: interval.userId
      }
    }
  };

  if (guideId !== interval.userId) {
    fullCalendarEvent.display = 'background';
  } else {
    fullCalendarEvent.title = `Time Block`;
  }

  return fullCalendarEvent;
}

export function futureSessionsToFullCalendarEvents(sessions: GroupSession[]): FullCalendarEvent[] {
  return sessionsToFullCalendarEvents(sessions, FullCalendarSessionEventTypes.FUTURE_SESSION);
}

export function pastSessionsToFullCalendarEvents(sessions: GroupSession[]): FullCalendarEvent[] {
  return sessionsToFullCalendarEvents(sessions, FullCalendarSessionEventTypes.PAST_SESSION);
}

export function sessionOffersToFullCalendarEvents(sessions: GroupSession[]): FullCalendarEvent[] {
  return sessionsToFullCalendarEvents(sessions, FullCalendarSessionEventTypes.SESSION_OFFER);
}

export function sessionRequestsToFullCalendarEvents(sessions: GroupSession[]): FullCalendarEvent[] {
  return sessionsToFullCalendarEvents(sessions, FullCalendarSessionEventTypes.SESSION_REQUEST);
}

export function sessionsToFullCalendarEvents(
  sessions: GroupSession[],
  type?: FullCalendarEventType
): FullCalendarEvent[] {
  return sessions.reduce((acc, session) => {
    const event = sessionToFullCalendarEvents(session, type);
    const result = [event];

    if (session.beforeEventBuffer || session.afterEventBuffer) {
      const bufferTime = new BufferTime(session?.beforeEventBuffer, session.afterEventBuffer);
      result.push(createEventBufferTime(event, bufferTime));
    }

    return [...acc, ...result];
  }, []);
}

export function sessionToFullCalendarEvents(service: GroupSession, type?: FullCalendarEventType): FullCalendarEvent {
  const classes: FullCalendarCustomEventCssClasses[] = [];

  // Apply service type classes such as program, package, group session
  classes.push(getServiceTypeClass(service));

  // Apply session calendar types such as session_request, session_offer, future_session, past_session, group_session
  if (type) {
    classes.push(...fullCalendarEventTypesClassNames[type]);
  }

  classes.push(...getSessionStatusClasses(service, type));

  return {
    id: `${service.id}`,
    eventId: String(service.eventId),
    type: FullCalendarSessionEventTypes.GROUP_SESSION,
    title: getTitle(service),
    start: service.dateStart,
    end: DateTime.fromISO(service.dateStart).plus({ minutes: service.duration }).toISO(),
    classNames: classes,
    additionalData: {
      session: service,
      badges: collectServiceTypesFromSession(service)
    }
  };
}

export function buildGuideClientsMap(clients: GuideClient[], clientsNumberLeft: number): { [id: number]: GuideClient } {
  return clients.reduce((dict, client) => {
    const clientWithNullableStatus = client.clone();

    if (!clientsNumberLeft) {
      // @ts-expect-error TS2322
      clientWithNullableStatus.disabled = clientWithNullableStatus.archived;
    }

    if (!client.isOnline) {
      clientWithNullableStatus.isOnline = null;
    }
    return { ...dict, [client.id]: clientWithNullableStatus };
  }, {});
}

// @ts-expect-error TS7006
export function prepareGuideClientSessions(sessions, guide, clientsMap): IGuideClientSession[] {
  // @ts-expect-error TS7006
  return sessions.map(session => ({
    session,
    guide,
    client: clientsMap[session.user.id]
  }));
}

export function createEventBufferTime(event: FullCalendarEvent, bufferTime: BufferTime): FullCalendarEvent {
  const type = FullCalendarUnavailableTimeEventTypes.BUFFER_TIME;

  return {
    type,
    start: DateTime.fromISO(event.start).minus({ minutes: bufferTime.before }).toISO(),
    end: DateTime.fromISO(event.end).plus({ minutes: bufferTime.after }).toISO(),
    display: 'background',
    classNames: fullCalendarEventTypesClassNames[type],
    additionalData: event.additionalData || null
  };
}

export function externalCalendarEventToFullCalendarEvent(event: IExternalEvent): FullCalendarEvent {
  let type = null;

  switch (event.source) {
    case ExternalCalendarSources.GOOGLE:
      type = ExternalCalendarEventTypes.GOOGLE;
      break;
    case ExternalCalendarSources.OUTLOOK:
      type = ExternalCalendarEventTypes.OUTLOOK;
      break;
    case ExternalCalendarSources.OTHER_WORKSPACE:
      type = FullCalendarUnavailableTimeEventTypes.OTHER_WORKSPACE_EVENT;
      break;
  }

  const classes: FullCalendarCustomEventCssClasses[] = [];

  const start = event.allDay ? DateTime.fromFormat(event.start, 'yyyy-MM-dd').startOf('day').toISO() : event.start;

  const end = event.allDay ? DateTime.fromFormat(event.end, 'yyyy-MM-dd').endOf('day').toISO() : event.end;

  return {
    type,
    start,
    end,
    title: event.name || '',
    classNames: classes.concat(fullCalendarEventTypesClassNames[type]),
    additionalData: {
      event
    }
  };
}

export function externalCalendarEventsToFullCalendarEvents(events: IExternalEvent[]): FullCalendarEvent[] {
  return events.map(externalCalendarEventToFullCalendarEvent);
}
