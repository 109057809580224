import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable()
export class NoteRecordingService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _playVideo$ = new Subject<string>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  videoPlay(uid: string) {
    this._playVideo$.next(uid);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  otherVideoPlay$(uid: string) {
    return this._playVideo$.asObservable().pipe(filter(incomingUid => incomingUid !== uid));
  }

  getArchiveExpirationDate(archiveCreatedAt: string): DateTime {
    return DateTime.fromISO(archiveCreatedAt).plus({
      hours: 72
    });
  }
}
