<div class="modal-header">
  <app-button className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <h3 class="title" i18n="@@link">Link</h3>

  <p class="note" i18n="@@linkToYourProgramPage">Direct link to your program page</p>

  <div class="input-group share-link-wrapper">
    <input type="text" readonly class="form-control shared-link" (copy)="onCopied()" [value]="programLink" />

    <div class="input-group-append">
      <button
        type="text"
        class="input-group-text"
        ngxClipboard
        [cbContent]="programLink"
        (cbOnSuccess)="onCopied()"
        (cbOnError)="onCopyFailed()"
        i18n="@@copyLink">
        Copy link
      </button>
    </div>
  </div>
</div>
