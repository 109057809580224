import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import config from '@app/core/config/config';
import { environment } from '@env/environment';
import { AuthService } from '../auth/services/auth.service';
import { PostPopupWindowService } from './post-popup-window-service';

const ADD_PAYPAL_ACCOUNT_ENDPOINT = `${config.apiPath}/user/guide/payments/paypal/add-account`;

const AUTHENTICATION_ENDPOINT = `${config.apiPath}/auth/paypal/identity`;

const MESSAGE_TYPE = 'paypal_payment_response';

const WINDOW_TITLE = 'paypal_payment_window';

@Injectable()
export class PaypalPaymentService {
  constructor(private _auth: AuthService, private _popupWindow: PostPopupWindowService, private _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authenticate$(): Observable<any> {
    if (!this._auth.isAuthorized || !this._auth.user.authToken) {
      return throwError('Not authorised.');
    }

    return this._popupWindow
      .openPopup$(
        WINDOW_TITLE,
        AUTHENTICATION_ENDPOINT,
        { token: this._auth.user.authToken },
        environment.apiHost,
        MESSAGE_TYPE
      )
      .pipe(
        concatMap(message => {
          if (message.error) {
            return throwError(message.error);
          }

          return of(message.payload || null);
        }),
        concatMap(data => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let innerObservable$: Observable<any>;

          if (data.email) {
            innerObservable$ = this._http.post(ADD_PAYPAL_ACCOUNT_ENDPOINT, {
              email: data.email
            });
          } else {
            innerObservable$ = of();
          }

          return innerObservable$;
        })
      );
  }
}
