import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ServiceGuide } from '@app/shared/interfaces/services';
import { IWorkspaceSettingsData } from '@app/modules/workspaces/types';

@Component({
  selector: 'app-service-hosted',
  templateUrl: './service-hosted.component.html',
  styleUrls: ['./service-hosted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceHostedComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _members: ServiceGuide[] = [];

  @Input()
  // @ts-expect-error TS2564
  guide: (ServiceGuide & { logo?: string | null }) | null;

  @Input()
  set members(members: ServiceGuide[]) {
    this._members = members.filter(member => member?.namedUrl);
  }

  @Input()
  // @ts-expect-error TS2322
  workspaceId: number = null;

  @Input()
  // @ts-expect-error TS2322
  workspaceInfo: IWorkspaceSettingsData = null;

  @Input()
  contactReadonly = false;

  @Input()
  directChatId = '';

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get members(): ServiceGuide[] {
    return this._members;
  }

  visibleDescriptionIndex = null;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get logo() {
    return this.workspaceId ? this.workspaceInfo?.branding?.logo : this.guide?.logo;
  }

  // @ts-expect-error TS7006
  onClickToggleDescription(memberIndex): void {
    if (this.visibleDescriptionIndex === memberIndex) {
      this.visibleDescriptionIndex = null;
    } else {
      this.visibleDescriptionIndex = memberIndex;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  trackByFn(index: number, user: ServiceGuide) {
    return user.id;
  }
}
