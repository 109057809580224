import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NormalizeSessionActionPipe } from './pipes/normalize-session-action.pipe';
import {
  PuiButtonModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiKeyboardWidgetModule,
  PuiLetModule,
  PuiList2Module
} from '@awarenow/profi-ui-core';
import { SessionActionsComponent } from './components/session-actions/session-actions.component';

@NgModule({
  declarations: [NormalizeSessionActionPipe, SessionActionsComponent],
  exports: [SessionActionsComponent],
  imports: [
    CommonModule,
    PuiIconModule,
    PuiDropdownModule,
    PuiButtonModule,
    PuiList2Module,
    PuiKeyboardWidgetModule,
    PuiLetModule
  ]
})
export class ClientSessionActionModule {}
