import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GUIDE_API } from '@app/shared/constants/endpoints';
import { ProfiAvailability } from '@libs/services/http/http-workspace-availability.service';

export interface SessionTemplatePredefinedFields {
  beforeEventBuffer: number;
  afterEventBuffer: number;
  minimumBookingNotice: number;
  schedules: ProfiAvailability[][];
}

@Injectable()
export class SessionTemplatePredefinedFieldsApiService {
  constructor(private readonly http: HttpClient) {}

  getPredefinedFields(): Observable<SessionTemplatePredefinedFields> {
    return this.http.get<SessionTemplatePredefinedFields>(`${GUIDE_API}/session-templates/predefined-fields`);
  }
}
