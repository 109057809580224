import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { QuizSendPolicy } from '@app/core/quizzes/types';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { SessionsService } from '@app/core/session/sessions.service';
import {
  EditableProgramModule,
  EditableProgramSettings,
  IEditableProgram,
  IProgramContentPersistenceAttributes,
  IProgramModulePersistenceAttributes,
  IProgramOptions,
  IProgramStore,
  isAuthorProgram,
  isRestrictedModule,
  LocalProgramService,
  Program,
  ProgramAccessRoles,
  ProgramContent,
  ProgramModule,
  ProgramQuestionnaire,
  ProgramService,
  ProgramSettings,
  SessionModule
} from '../types';
import { getArbitraryOrderArraysPatch } from '../types/helpers';
import { applyModulePersistenceAttributes } from '../types/program-module/utils';
import { convertEditableProgram } from '../utils/converters';
import { GuideProgramOptionsService } from './guide-program-options.service';
import { PROGRAMS_STORE } from './guide-program-server-store.service';
import { ProgramInstructorsServicesService } from './program-instructors-services.service';

@Injectable()
export class GuideProgramStateService implements OnDestroy {
  protected destroy$: Subject<void> = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _content$: BehaviorSubject<ProgramContent> = new BehaviorSubject<ProgramContent>(
    new ProgramContent().withAuthor(this._auth.user)
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _modules$: BehaviorSubject<ProgramModule[]> = new BehaviorSubject<ProgramModule[]>([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _settings$: BehaviorSubject<ProgramSettings> = new BehaviorSubject<ProgramSettings>(new ProgramSettings());

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _questionnaires$: BehaviorSubject<ProgramQuestionnaire[]> = new BehaviorSubject<ProgramQuestionnaire[]>([
    new ProgramQuestionnaire()
  ]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _programAccessRole$: ReplaySubject<ProgramAccessRoles> = new ReplaySubject<ProgramAccessRoles>(1);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _programId: number;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _program: Program;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _programOptions: IProgramOptions;

  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  protected _isProgramLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get content$(): Observable<ProgramContent> {
    return this._content$.asObservable();
  }

  get questionnaires$(): Observable<ProgramQuestionnaire[]> {
    return this._questionnaires$.asObservable();
  }

  get isProgramLoading$(): Observable<boolean> {
    return this._isProgramLoading$.asObservable();
  }

  get isProgramLoading(): boolean {
    return this._isProgramLoading$.getValue();
  }

  get modules$(): Observable<ProgramModule[]> {
    return this._modules$.asObservable();
  }

  get programAccessRole$(): Observable<ProgramAccessRoles> {
    return this._programAccessRole$.asObservable();
  }

  get settings$(): Observable<ProgramSettings> {
    return this._settings$.asObservable();
  }

  get program(): Program {
    return this._program;
  }

  set programId(value: number) {
    this._programId = value;
  }

  constructor(
    @Inject(PROGRAMS_STORE)
    private readonly _externalStore: IProgramStore,
    private readonly _options: GuideProgramOptionsService,
    private readonly _notifications: NotificationsService,
    private readonly _router: Router,
    private readonly _analyticsService: AnalyticsService,
    private readonly _instructorsServices: ProgramInstructorsServicesService,
    private readonly _auth: AuthService,
    private readonly _workspaceService: WorkspacesService,
    private readonly _sessionsService: SessionsService
  ) {
    _options.options$.pipe(takeUntil(this.destroy$)).subscribe(options => (this._programOptions = options));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this._content$.complete();
    this._isProgramLoading$.complete();
    this._modules$.complete();
    this._questionnaires$.complete();
  }

  applyProgramContentPersistenceAttributes(attributes: IProgramContentPersistenceAttributes): void {
    this._content$.next(this._content$.getValue().clone().setPersistenceAttributes(attributes));
  }

  applyProgramModulesPersistenceAttributes(attributes: Record<string, IProgramModulePersistenceAttributes>): void {
    this._modules$.next(
      this._modules$.getValue().map(module => {
        const moduleAttributes = attributes && attributes[module.localId];
        return moduleAttributes ? applyModulePersistenceAttributes(module, moduleAttributes) : module;
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContentDiff(content: ProgramContent): any {
    const currentContentState: ProgramContent = this._content$.getValue();
    // @ts-expect-error TS2345
    return currentContentState.getDiffFrom(content);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getModulesDiff(modules: ProgramModule[]): any {
    const currentModules: ProgramModule[] = this._modules$.getValue();
    const user = this._auth.user;

    const editableProgramModules: EditableProgramModule[] = modules.filter((module: ProgramModule) => {
      const isUserHost = this.isUserHost(module, user.id);
      const existingModule =
        module.accessType || module.id || module.localId
          ? module
          : currentModules.find((currentModule: ProgramModule) => currentModule.id === module.id);

      // @ts-expect-error TS2345
      const isRestrict = !isRestrictedModule(existingModule) || isUserHost;

      return isRestrict;
    }) as EditableProgramModule[];

    const editableCurrentProgramModules: EditableProgramModule[] = currentModules.filter((module: ProgramModule) => {
      const isUserHost = this.isUserHost(module, user.id);
      return !isRestrictedModule(module) || isUserHost;
    }) as EditableProgramModule[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const diff: any = {};
    const { created, deleted, updated } = getArbitraryOrderArraysPatch(
      editableProgramModules,
      editableCurrentProgramModules
    );

    if (created.length) {
      diff.created = created;
    }

    if (deleted.length) {
      diff.deleted = deleted;
    }

    if (updated.length) {
      diff.updated = updated;
    }

    return Object.keys(diff).length ? diff : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSettingsDiff(settings: ProgramSettings): any {
    const currentSettingsState: ProgramSettings = this._settings$.getValue();
    const diff = currentSettingsState.getDiffFrom(settings);

    if (diff && diff.events && diff.events.created && diff.events.created.length) {
      // @ts-expect-error TS7006
      diff.events.created = diff.events.created.map(event => ({
        eventType: event.type,
        count: event.count
      }));
    }

    return diff;
  }

  loadProgram(): void {
    this._isProgramLoading$.next(true);
    this._externalStore
      .getProgram$(this._programId)
      .pipe(
        catchError(error => {
          this._router.navigate(['/not-found'], { replaceUrl: true }).then();
          return throwError(error);
        }),
        tap(program => this.excludeInstructorsServices(program)),
        map(program => convertEditableProgram(program)),
        finalize(() => this._isProgramLoading$.next(false))
      )
      .subscribe(program => this.setProgram(program));
  }

  updateContent(content: ProgramContent, noExternalSync?: boolean): void {
    if (!noExternalSync) {
      const diff = this.getContentDiff(content);

      if (diff) {
        this._externalStore.updateContent$(this._programId, diff).subscribe();
      } else {
        this._notifications.success(`Program content saved`);
      }
    }

    this._content$.next(content);
  }

  updateModules(modules: ProgramModule[], noExternalSync?: boolean): Observable<void> {
    if (!noExternalSync) {
      const diff = this.getModulesDiff(modules);
      let shouldUpdateSessions = false;

      if (diff) {
        if (diff.updated) {
          diff.updated = this.filterUpdatedModulesDiff(diff.updated);
        }

        if (diff.deleted) {
          const oldModules = this._modules$.getValue();
          // @ts-expect-error TS7006
          shouldUpdateSessions = diff.deleted.some(moduleId => {
            const module = oldModules.find(mdl => mdl.localId === moduleId);
            return module !== undefined && module instanceof SessionModule;
          });
        }

        this._externalStore.updateModules$(this._programId, diff).subscribe(({ modules: ids }) => {
          if (shouldUpdateSessions) {
            this._sessionsService.refresh();
          }
          this.applyProgramModulesPersistenceAttributes(ids);
        });
      } else {
        this._notifications.success(`Program modules saved`);
      }
    }

    this._modules$.next(modules);

    return of(void 0);
  }

  updateSettings(settings: ProgramSettings, noExternalSync?: boolean): void {
    if (!noExternalSync) {
      const diff = this.getSettingsDiff(settings);

      if (diff && diff.surveys && diff.surveys.length) {
        this._analyticsService.event(InternalEvents.JOURNALING_PROMPT_CONNECTED_TO_PROGRAM, {});
      }

      if (diff) {
        this._externalStore.updateSettings$(this._programId, diff).subscribe();
      } else {
        this._notifications.success(`Program settings saved`);
      }
    }

    this._settings$.next(settings);
    this._content$.next(this.mergeProgramContentWithSettings(this._content$.getValue(), settings));
  }

  updateSurveys$(surveys: ProgramQuestionnaire[], noExternalSync?: boolean): Observable<void> {
    this._questionnaires$.next(surveys.length ? surveys : [new ProgramQuestionnaire()]);

    if (!noExternalSync) {
      return this._externalStore.updateSurveys$(this._programId, surveys);
    }

    // @ts-expect-error TS2322
    return of(null);
  }

  uploadCover$(images: { coverImage: File; coverImageThumb: File }): Observable<{
    coverImage: string | null;
    coverImageThumb: string | null;
  }> {
    return this._externalStore.storeCover$(images.coverImage, images.coverImageThumb);
  }

  removeCover$(): Observable<null> {
    if (this._programId) {
      // TODO: PR-2565
      return this._externalStore.removeCover$(this._programId);
    }
    return EMPTY;
  }

  deactivateProgram$(data: { date: Date | null; message: string }): Observable<{ lastActiveDay: string }> {
    return this._externalStore.deactivateProgram$(this._programId, data);
  }

  updateProgramState(update: (program: IEditableProgram) => IEditableProgram): void {
    this.setProgram(convertEditableProgram(update(this._program.toEditable())));
  }

  private excludeInstructorsServices(program: IEditableProgram): void {
    const {
      accessRole,
      modules,
      settings: { author }
    } = program;

    if (!modules || !modules.length) {
      return;
    }

    const instructorsServices = modules
      .filter(
        module =>
          ((accessRole === ProgramAccessRoles.AUTHOR && module.instructor && module.instructor.id !== author?.id) ||
            (accessRole === ProgramAccessRoles.INSTRUCTOR &&
              module.instructor &&
              module.instructor.id !== this._auth.user.id)) &&
          module.service
      )
      .map(module => new LocalProgramService(new ProgramService(module.service)));

    this._instructorsServices.setServices(instructorsServices);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private filterUpdatedModulesDiff(updatedModules: any[]): any[] {
    return updatedModules.map(moduleDiff =>
      moduleDiff.instructorId ? { ...moduleDiff, serviceId: null } : moduleDiff
    );
  }

  private setProgram(program: Program): void {
    this._program = program;

    this._programAccessRole$.next(program.programAccessRole);

    const { content, settings, questionnaires } = this.parseProgram(program);

    this._modules$.next(program.modules);
    this._content$.next(content);
    this._settings$.next(settings);

    if (isAuthorProgram(program)) {
      // @ts-expect-error TS2531
      this._questionnaires$.next(questionnaires.length ? questionnaires : [new ProgramQuestionnaire()]);
    }
  }

  private parseProgram(program: Program): {
    content: ProgramContent;
    settings: ProgramSettings;
    questionnaires: ProgramQuestionnaire[] | null;
  } {
    return {
      content: this.parseProgramContent(program.id, program.settings),
      settings: this.parseSettings(program.id, program.settings),
      questionnaires: isAuthorProgram(program) ? this.parseProgramQuestionnaires(program.questionnaires) : null
    };
  }

  private parseProgramContent(programId: number, programSettings: EditableProgramSettings): ProgramContent {
    return new ProgramContent().setValues({
      ...programSettings,
      id: programId,
      author: programSettings.author,
      permission: programSettings.permission
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseProgramQuestionnaires(questionnairesSchedules: any[]): ProgramQuestionnaire[] {
    if (!questionnairesSchedules || !questionnairesSchedules.length) {
      return [];
    }

    return questionnairesSchedules
      .map(questionnaireSchedules => this.parseProgramQuestionnaireSchedules(questionnaireSchedules))
      .flat();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseProgramQuestionnaireSchedules(questionnaireSchedules: any): ProgramQuestionnaire {
    const { id: surveyId, schedules } = questionnaireSchedules;

    // @ts-expect-error TS7031
    return schedules.map(({ sendPolicy, id, ...schedule }) =>
      new ProgramQuestionnaire().setValues({
        survey: surveyId,
        sendPolicy,
        id,
        schedule: sendPolicy === QuizSendPolicy.Scheduling ? schedule : null
      } as ProgramQuestionnaire)
    );
  }

  private parseSettings(programId: number, programSettings: EditableProgramSettings): ProgramSettings {
    return new ProgramSettings().setValues({
      id: programId,
      disableContentAfterComplete:
        programSettings.disableContentAfterComplete != null ? programSettings.disableContentAfterComplete : false,
      disableContentAfterRemoval:
        programSettings.disableContentAfterRemoval != null ? programSettings.disableContentAfterRemoval : false,
      hasCommonChat: programSettings.hasCommonChat != null ? programSettings.hasCommonChat : false,
      hidePrice: programSettings.hidePrice,
      isFree: programSettings.isFree,
      fixedPrice: programSettings.fixedPrice,
      length: programSettings.length,
      moduleCompletionType: programSettings.moduleCompletionType,
      price: programSettings.price,
      subscriptionPrice: programSettings.subscriptionPrice,
      subscriptionRecurrency: programSettings.subscriptionRecurrency,
      subscriptionDeactivated: programSettings.subscriptionDeactivated,
      subscriptionDeactivatedDate: programSettings.subscriptionDeactivatedDate,
      startDate: programSettings.startDate,
      startType: programSettings.startType,
      status: programSettings.status,
      isHiddenForBook: programSettings.isHiddenForBook,
      totalPayments: programSettings.totalPayments,
      permission: programSettings.permission
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private mergeProgramContentWithSettings(programContent: ProgramContent, programSettings: ProgramSettings) {
    const newProgramContent = programContent.clone();

    [
      'startDate',
      'startType',
      'isFree',
      'price',
      'fixedPrice',
      'hidePrice',
      'subscriptionPrice',
      'subscriptionRecurrency',
      'totalPayments',
      'permission',
      'length'
    ].forEach(prop => {
      // @ts-expect-error TS7053
      newProgramContent[prop] = programSettings[prop];
    });

    return newProgramContent;
  }

  private isUserHost(module: ProgramModule, userId: number): boolean {
    return module.hosts?.find(host => host.id === userId) ? true : false;
  }
}
