import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionStatusIconEnum, SessionStatusIconNameEnum } from '@libs/business/cdk/session';

import { ClientSessionStatusIconsMap } from '../types';

export const CLIENT_SESSION_STATUSES_ICONS: ClientSessionStatusIconsMap = {
  [SessionStatuses.APPROVED]: {
    type: SessionStatusIconEnum.SUCCEED,
    name: SessionStatusIconNameEnum.DONE
  },
  [SessionStatuses.CANCELLED]: {
    type: SessionStatusIconEnum.WARNING,
    name: SessionStatusIconNameEnum.CANCELLED
  },
  [SessionStatuses.DECLINED]: {
    type: SessionStatusIconEnum.WARNING,
    name: SessionStatusIconNameEnum.CANCELLED
  },
  [SessionStatuses.PENDING_APPROVEMENT]: {
    type: SessionStatusIconEnum.PENDING,
    name: SessionStatusIconNameEnum.PENDING
  },
  [SessionStatuses.GUIDE_OFFER]: {
    type: SessionStatusIconEnum.PENDING,
    name: SessionStatusIconNameEnum.PENDING
  },
  [SessionStatuses.GUIDE_OFFER_CANCELLED]: {
    type: SessionStatusIconEnum.WARNING,
    name: SessionStatusIconNameEnum.CANCELLED
  },
  [SessionStatuses.GUIDE_OFFER_DECLINED]: {
    type: SessionStatusIconEnum.WARNING,
    name: SessionStatusIconNameEnum.CANCELLED
  },
  [SessionStatuses.DONE]: {
    type: SessionStatusIconEnum.SUCCEED,
    name: SessionStatusIconNameEnum.DONE
  },
  [SessionStatuses.RESCHEDULE_BY_CLIENT]: {
    type: SessionStatusIconEnum.PENDING,
    name: SessionStatusIconNameEnum.PENDING
  },
  [SessionStatuses.IN_PROGRESS]: {
    type: SessionStatusIconEnum.MARKED,
    name: SessionStatusIconNameEnum.IN_PROGRESS
  },
  [SessionStatuses.RESCHEDULE_BY_GUIDE]: {
    type: SessionStatusIconEnum.PENDING,
    name: SessionStatusIconNameEnum.PENDING
  },
  [SessionStatuses.MISSED]: {
    type: SessionStatusIconEnum.WARNING,
    name: SessionStatusIconNameEnum.CANCELLED
  },
  [SessionStatuses.PENDING]: {
    type: SessionStatusIconEnum.PENDING,
    name: SessionStatusIconNameEnum.PENDING
  }
};
