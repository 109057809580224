import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';
import { HideAuthBodyComponent } from './hide-auth-body/hide-auth-body.component';
import { SignUpGuideComponent } from './sign-up-guide/sign-up-guide.component';

import { AllowedAuthQueryParamsGuard } from './guards/allowed-auth-query-params.guard';
import { AuthModalGuard } from './guards/auth-modal.guard';
import { CanBecomeAGuideGuard } from './guards/can-become-a-guide.guard';
import { RedirectGuard } from './guards/redirect.guard';
import { PuiPhoneNumberModule } from '@awarenow/profi-ui-core';
import { ForgotPasswordResetModule } from '@app/screens/auth/forgot-password-reset/forgot-password-reset.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PuiPhoneNumberModule,
    NgbModule,
    RouterModule,
    SharedModule,
    ForgotPasswordResetModule,
    TranslocoRootModule
  ],
  declarations: [ForgotPasswordComponent, ForgotPasswordSentComponent, HideAuthBodyComponent, SignUpGuideComponent],
  providers: [AllowedAuthQueryParamsGuard, AuthModalGuard, CanBecomeAGuideGuard, RedirectGuard]
})
export class AuthModule {}
