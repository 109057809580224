import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SubscriptionRecurrency, SubscriptionLength } from '@app/shared/enums/subscription';
import { IProgramSettings, ProgramSettings } from '../../types';
import { servicePricingValidator } from '../../validators/service-pricing.validator';

export type SettingsProgramPaymentTypes = 'free' | 'paid' | 'subscription';
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SettingsFormManager implements OnDestroy {
  private readonly destroy$ = new Subject();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _settingsForm: FormGroup;

  get settingsForm(): FormGroup {
    return this._settingsForm;
  }

  constructor(private readonly _formBuilder: FormBuilder, private readonly _auth: AuthService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSettingsForm(originalSettings: ProgramSettings): void {
    const settings = this.applyDefaultSettingsValues(originalSettings);

    if (!this.settingsForm) {
      this.initializeSettingsForm(settings);
    } else {
      this.updateSettingsForm(settings);
    }

    if (settings.startType === 'user_enrollment') {
      // @ts-expect-error TS2531
      this._settingsForm.get('startDate').disable();
    }

    if (!this._auth.user.confirmed) {
      // @ts-expect-error TS2531
      this._settingsForm.get('isPublic').setValue(false);
      // @ts-expect-error TS2531
      this._settingsForm.get('isPublic').disable();
    }
  }

  toProgramSettings(): IProgramSettings {
    const { pricingFormGroup, ...formValues } = this._settingsForm.value;

    return {
      disableContentAfterComplete: formValues.disableContentAfterComplete,
      disableContentAfterRemoval: formValues.disableContentAfterRemoval,
      hasCommonChat: formValues.hasCommonChat,
      moduleCompletionType: formValues.moduleCompletionType,
      hidePrice: pricingFormGroup.paymentType === 'free' && pricingFormGroup.doNotShowPrice,
      length: formValues.length,
      isFree: pricingFormGroup.paymentType === 'free',
      fixedPrice: pricingFormGroup.paymentType === 'paid',
      price: pricingFormGroup.paymentType === 'paid' ? pricingFormGroup.price : 0,
      subscriptionPrice:
        pricingFormGroup.paymentType === 'subscription' ||
        (pricingFormGroup.paymentType === 'paid' && pricingFormGroup.isInstallmentsEnabled)
          ? pricingFormGroup.subscriptionPrice
          : 0,
      subscriptionRecurrency:
        pricingFormGroup.paymentType === 'subscription' ||
        (pricingFormGroup.paymentType === 'paid' && pricingFormGroup.isInstallmentsEnabled)
          ? pricingFormGroup.subscriptionRecurrency
          : null,
      startDate: formValues.startDate,
      startType: formValues.startType,
      status: formValues.isPublic ? 'active' : 'unlisted',
      isHiddenForBook: formValues.isHiddenForBook,
      totalPayments:
        (pricingFormGroup.paymentType === 'subscription' &&
          pricingFormGroup.subscriptionLength === SubscriptionLength.Finite) ||
        (pricingFormGroup.paymentType === 'paid' && pricingFormGroup.isInstallmentsEnabled)
          ? pricingFormGroup.totalPayments
          : null
    };
  }

  private applyDefaultSettingsValues(programSettings: ProgramSettings): ProgramSettings {
    const newSettings = programSettings.clone();

    newSettings.startType = newSettings.startType || 'program_release';
    newSettings.startDate = newSettings.startDate || DateTime.local().startOf('day').toISO();

    newSettings.subscriptionRecurrency = newSettings.subscriptionRecurrency || SubscriptionRecurrency.Month;

    return newSettings;
  }

  private initializeSettingsForm(settings: ProgramSettings): void {
    this._settingsForm = this._formBuilder.group({
      id: [settings.id],
      disableContentAfterComplete: [settings.disableContentAfterComplete],
      disableContentAfterRemoval: [settings.disableContentAfterRemoval],
      hasCommonChat: [settings.hasCommonChat],
      isPublic: [this.getIsPublic(settings)],
      isHiddenForBook: [settings.isHiddenForBook],
      length: [settings.length],
      pricingFormGroup: this.toPricingFromGroup(settings),
      startDate: [settings.startDate],
      startType: [settings.startType],
      moduleCompletionType: [settings.moduleCompletionType]
    });

    // @ts-expect-error TS2531
    this._settingsForm
      .get('startType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value === 'program_release') {
          // @ts-expect-error TS2531
          this._settingsForm.get('startDate').enable();
        } else {
          // @ts-expect-error TS2531
          this._settingsForm.get('startDate').disable();
        }
      });
  }

  private getIsPublic(settings: ProgramSettings): boolean {
    return settings.status === 'active';
  }

  private getPaymentType(settings: ProgramSettings): SettingsProgramPaymentTypes {
    if (settings.isFree) {
      return 'free';
    }

    if (settings.fixedPrice) {
      return 'paid';
    }

    return 'subscription';
  }

  private toPricingFromGroup(settings: ProgramSettings): FormGroup {
    return this._formBuilder.group(
      {
        paymentType: [this.getPaymentType(settings)],
        price: [settings.price],
        subscriptionPrice: [settings.subscriptionPrice],
        subscriptionRecurrency: [settings.subscriptionRecurrency],
        totalPayments: [settings.totalPayments],
        doNotShowPrice: [settings.hidePrice],
        isInstallmentsEnabled: [!!settings.price && !!settings.subscriptionPrice],
        subscriptionLength: [settings.totalPayments ? SubscriptionLength.Finite : SubscriptionLength.Infinite]
      },
      {
        validators: servicePricingValidator()
      }
    );
  }

  private updateSettingsForm(settings: ProgramSettings): void {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      status,
      isHiddenForBook,
      hidePrice,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isFree,
      price,
      subscriptionPrice,
      subscriptionRecurrency,
      totalPayments,
      ...otherSettings
    } = settings;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (otherSettings as any).isPublic = this.getIsPublic(settings);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (otherSettings as any).pricingFormGroup = {
      price,
      subscriptionPrice,
      subscriptionRecurrency,
      totalPayments,
      doNotShowPrice: hidePrice,
      paymentType: this.getPaymentType(settings),
      isInstallmentsEnabled: !!price && !!subscriptionPrice,
      subscriptionLength: totalPayments ? SubscriptionLength.Finite : SubscriptionLength.Infinite
    };

    this._settingsForm.patchValue({
      ...otherSettings,
      isHiddenForBook: isHiddenForBook
    });
  }
}
