import { Pipe, PipeTransform } from '@angular/core';
import { IWorkspace } from '@app/modules/workspaces/types';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';

@Pipe({
  name: 'workspaceSubheader'
})
export class WorkspaceSubheaderPipe implements PipeTransform {
  transform(workspace: IWorkspace): string {
    const isTeam = WorkspaceUtility.isTeam(workspace);

    return isTeam ? `Team plan: ${workspace.membersCount} members` : workspace.membership?.name || '';
  }
}
