// https://gitlab.com/awarenow/awareapi/-/blob/develop/server/services/crm-service/timeline-service.ts

export type UserId = number;
export type WorkspaceId = number;

// TODO Pick from Client
export interface UserInfo {
  id: number;
  photoUrl: string;
  firstName: string;
  lastName: string;
}

export enum TimelineEventType {
  FORM_SUBMIT = 'FORM_SUBMIT',
  FORM_REQUEST = 'FORM_REQUEST',
  CLIENT_ENROLLED_PROGRAM = 'CLIENT_ENROLLED_PROGRAM',
  CLIENT_ENROLLED_PACKAGE = 'CLIENT_ENROLLED_PACKAGE',
  SESSION_OFFERED_BY_HOST = 'SESSION_OFFERED_BY_HOST',
  SESSION_OFFERED_BY_ADMIN = 'SESSION_OFFERED_BY_ADMIN',
  SESSION_CANCELLED_BY_CLIENT = 'SESSION_CANCELLED_BY_CLIENT',
  SESSION_CANCELLED_BY_HOST = 'SESSION_CANCELLED_BY_HOST',
  SESSION_CANCELLED_BY_ADMIN = 'SESSION_CANCELLED_BY_ADMIN',
  SESSION_RESCHEDULED_BY_HOST = 'SESSION_RESCHEDULED_BY_HOST',
  SESSION_RESCHEDULED_BY_ADMIN = 'SESSION_RESCHEDULED_BY_ADMIN',
  SESSION_RESCHEDULED_BY_CLIENT = 'SESSION_RESCHEDULED_BY_CLIENT',
  SESSION_COMPLETED = 'SESSION_COMPLETED', // means - by client
  SESSION_BOOKED = 'SESSION_BOOKED', // means - by client
  SESSION_ACCEPTED = 'SESSION_ACCEPTED', // means -  by client
  SESSION_MISSED_BY_CLIENT = 'SESSION_MISSED_BY_CLIENT',
  SESSION_MISSED_BY_HOST = 'SESSION_MISSED_BY_HOST'
}

export interface ClientTimelineEvent {
  timestamp: string;
  eventType: TimelineEventType;
  eventName: string;
  productName: string;
  client: UserInfo;
  guide: UserInfo;
  hostedBy?: UserInfo;
  recurringSessionsNumber?: number;
  contentText: string;
  // for self forms
  quizId?: number;
  quizName?: string;
}

export interface ClientTimelineResponse {
  guideId: UserId;
  clientId: UserId;
  workspaceId: WorkspaceId;
  events: ClientTimelineEvent[];
}
