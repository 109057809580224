import { ModuleCompletionTypes, ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { ClientModuleStatuses } from './client-module-statuses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseModuleProgressDetails {
  readonly id: number;
  readonly order: number;
  readonly title: string | null;
  readonly status: ClientModuleStatuses;
  readonly completionType: ModuleCompletionTypes;
}

export abstract class BaseModuleProgress {
  readonly id: number;
  readonly order: number;
  readonly title: string | null;
  readonly status: ClientModuleStatuses;
  readonly completionType: ModuleCompletionTypes;

  abstract get moduleType(): ModuleTypes;

  protected constructor(moduleDetails: Readonly<IBaseModuleProgressDetails>) {
    if (!moduleDetails) {
      throw new Error('Invalid argument');
    }

    this.id = moduleDetails.id;
    this.order = moduleDetails.order;
    this.title = moduleDetails.title;
    this.status = moduleDetails.status;
    this.completionType = moduleDetails.completionType;
  }
}
