import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDateAdapter } from '../utils';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(date: string, format = 'mediumDate', timezone?: string, locale?: string): string | null {
    if (date == null || date === '' || date !== date) {
      return null;
    }

    return formatDateAdapter(date, format, locale || this.locale, timezone);
  }
}
