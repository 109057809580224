import { Subject } from 'rxjs';
import { IScheduleProvider } from '../../types';
import { ScheduleTimeSlotsFactory } from './schedule-time-slots-factory';
import { IScheduleDateTimeSlotsUpdateOptions } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleTimeSlotsFactoryWithVariableTimezone<TTimezone> {
  changeTimezone: (timezone: TTimezone, options?: IScheduleDateTimeSlotsUpdateOptions) => void;
}

export abstract class ScheduleTimeSlotsTimezoneFactory<TSchedule, TTimezone, TTimeSlot>
  extends ScheduleTimeSlotsFactory<TSchedule, TTimeSlot>
  implements IScheduleTimeSlotsFactoryWithVariableTimezone<TTimezone>
{
  abstract timezone: TTimezone;

  protected constructor(scheduleProvider: IScheduleProvider<TSchedule>, timeSlotsEmitter$?: Subject<TTimeSlot[]>) {
    super(scheduleProvider, timeSlotsEmitter$);
  }

  abstract changeTimezone(timezone: TTimezone, options?: IScheduleDateTimeSlotsUpdateOptions): void;
}
