<ng-container *ngIf="form">
  <button
    type="button"
    role="button"
    class="selection-list__button"
    [class.selection-list__button_invalid]="form?.touched && getUsersArray()?.invalid"
    [(pui-dropdown)]="isDropdownOpen"
    [pui-dropdown-width]="290"
    [pui-dropdown-content]="selectionListTemplate"
    [items]="getUsersArray()?.value">
    <ng-container *puiLet="selectedList$ | async as selectedList">
      <div class="selected-list" *ngIf="selectedList?.length">
        <pui-avatar-group class="pui-space_right-2" size="s">
          <pui-avatar
            *ngFor="let listItem of selectedList | slice: 0:MAX_DISPLAYED_USERS"
            size="s"
            [text]="(listItem?.info || listItem | userName) || listItem.username"
            [avatarUrl]="listItem.photo"></pui-avatar>
          <ng-container *ngIf="selectedList.length - MAX_DISPLAYED_USERS > 0" ngProjectAs="pui-avatar">
            <pui-avatar
              size="s"
              [text]="'+' + (selectedList.length - MAX_DISPLAYED_USERS)"
              [autoColor]="false"
              role="button"
              aria-pressed="false"></pui-avatar>
          </ng-container>
        </pui-avatar-group>

        <span
          *ngIf="selectedList?.length === 1"
          class="selected-list__user-name users__item-text"
          pui-typography="body-s"
          >{{ (selectedList[0] | userName) || selectedList[0].username }}</span
        >
      </div>
      <div class="d-flex align-items-center" *ngIf="!selectedList?.length">
        <pui-icon class="pui-space_right-1" svgIcon="pui:search" size="s"></pui-icon>
        <small pui-typography="body-s">{{ placeholder }}</small>
      </div>
    </ng-container>
    <pui-icon svgIcon="pui:select" size="xs"></pui-icon>
  </button>
</ng-container>

<div *ngIf="form?.touched && getUsersArray()?.invalid" class="pui-space_top-2">
  <span pui-typography="body-s" class="pui-error pui-space_top-2">{{ errorMessage }}</span>
</div>

<ng-template #selectionListTemplate let-items="items">
  <form [formGroup]="form">
    <div class="selection-list-dropdown">
      <div
        *ngIf="isMultiOption"
        class="selection-list-dropdown__row selection-list-dropdown__row_divider selection-list-dropdown__buttons">
        <button pui-link i18n="@@selectAll" [disabled]="selectAllDisabled()" (click)="selectAll($event)">
          Select all
        </button>
        <button pui-link i18n="@@reset" [disabled]="!(selectedList$ | async)?.length" (click)="resetAll()">
          Reset
        </button>
      </div>
      <div class="selection-list-dropdown__row selection-list-dropdown__row_divider">
        <label class="search-field">
          <pui-icon svgIcon="pui:search" size="s" class="pui-space_right-2 search-field__icon"></pui-icon>
          <input
            pui-typography="body-s"
            class="search-field__native"
            pui-input-text
            (keypress)="disableKey($event, 'Enter')"
            id="search-for-clients"
            type="search"
            placeholder="Search by name or e-mail..."
            i18n-placeholder="@@searchByNameOrEmail"
            formControlName="search" />
        </label>
      </div>
      <div class="selection-list-dropdown__row">
        <ng-container *puiLet="items | filter: filterByNameOrEmail(form?.get('search').value) as filteredList">
          <ng-container *ngIf="filteredList.length > 0; else noItems">
            <ng-container *ngFor="let control of getUsersArray().controls">
              <ng-container *puiLet="getUser(control.get('id').value, filteredList) as user">
                <div
                  *ngIf="user"
                  (click)="selectUser(control)"
                  class="users__item"
                  [attr.data-qa-id]="'propose-service__client-' + user.username"
                  [class.users__item_selected]="control.get('value').value">
                  <pui-input-checkbox
                    *ngIf="isMultiOption"
                    [readonly]="true"
                    [disabled]="disabled(user)"
                    [formControl]="control.get('value')"
                    class="users__item-checkbox">
                  </pui-input-checkbox>
                  <pui-card>
                    <pui-card-header>
                      <pui-avatar
                        pui-card-header-icon
                        size="l"
                        [text]="(user | userName) || user?.username"
                        [avatarUrl]="user?.photo"></pui-avatar>
                      <span class="users__item-text" pui-card-header-title pui-typography="body-s-bold">
                        {{ (user | userName) || user?.username }}
                      </span>
                      <small class="users__item-text" pui-card-header-subheader pui-typography="body-s">
                        {{ user?.email || user?.contacts?.email }}
                      </small>
                    </pui-card-header>
                  </pui-card>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #noItems>
            <span class="users__item users__empty" pui-typography="body-s" i18n="@@UserSelectNotFoundMessage"
              >Not found</span
            >
          </ng-template>
        </ng-container>
      </div>
    </div>
  </form>
</ng-template>
