import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonBehaviorsModule } from '@app/modules/common-behaviors/common-behaviors.module';

import { TimePickerGridComponent } from './components/time-picker-grid/time-picker-grid.component';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [CommonBehaviorsModule, CommonModule, SharedModule],
  declarations: [TimePickerGridComponent],
  exports: [TimePickerGridComponent]
})
export class TimePickerGridModule {}
