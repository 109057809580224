// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITestimonial {
  id?: number;
  localId: number;
  clientInfo: string | null;
  text: string | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITestimonialPatch {
  id: number;
  clientInfo?: string | null;
  text?: string | null;
}

export class Testimonial implements ITestimonial {
  id?: number;
  localId: number;
  clientInfo: string | null;
  text: string | null;

  constructor() {
    this.localId = new Date().getTime();
    this.clientInfo = null;
    this.text = null;
  }

  clone(): Testimonial {
    const testimonialClone = new Testimonial();

    testimonialClone.id = this.id;
    testimonialClone.localId = this.localId;
    testimonialClone.text = this.text;
    testimonialClone.clientInfo = this.clientInfo;

    return testimonialClone;
  }

  getDiffFrom(newTestimonial: ITestimonial): ITestimonialPatch | null {
    // @ts-expect-error TS2322
    const diff: ITestimonialPatch = { id: this.id };

    if (this.text !== newTestimonial.text) {
      diff.text = newTestimonial.text;
    }

    if (this.clientInfo !== newTestimonial.clientInfo) {
      diff.clientInfo = newTestimonial.clientInfo;
    }

    return Object.keys(diff).length > 1 ? diff : null;
  }

  setId(id: number): Testimonial {
    this.id = id;
    this.localId = id;

    return this;
  }

  setValues(testimonial: ITestimonial): Testimonial {
    this.id = testimonial.id;
    this.clientInfo = testimonial.clientInfo;
    this.text = testimonial.text;

    if (testimonial.localId) {
      this.localId = testimonial.localId;
    }

    return this;
  }

  update(testimonial: ITestimonial): Testimonial {
    if (testimonial.id) {
      this.id = testimonial.id;
    }

    if (testimonial.localId) {
      this.localId = testimonial.localId;
    }

    this.clientInfo = testimonial.clientInfo;
    this.text = testimonial.text;

    return this;
  }
}
