import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareFormComponent {
  readonly idsToExclude: number[] = [];
  readonly control = new FormControl([]);

  constructor(
    @Inject(PUI_DIALOG_DATA)
    puiDialogData: {
      idsToExclude: number[];
    },
    private readonly dialogRef: PuiDialogRef<WorkspaceUser[]>
  ) {
    this.idsToExclude = puiDialogData.idsToExclude;
  }

  handleSubmit(): void {
    this.dialogRef.close(this.control.value);
  }
}
