import { Routes } from '@angular/router';

import { PublicPackageComponent } from './components/public-package/public-package.component';

export const packagesRoutes: Routes = [
  {
    path: ':id',
    component: PublicPackageComponent
  },
  {
    path: '**',
    redirectTo: '/programs'
  }
];
