import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UpdateValueAndValidityModule } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.module';
import { AddHostsDrawerModule } from '@app/modules/session-forms/drawers/add-hosts-drawer/add-hosts-drawer.module';
import { SelectedHostsModule } from '@app/modules/session-forms/forms/selected-hosts/selected-hosts.module';
import {
  PuiButtonModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputModule,
  PuiInputRadioModule,
  PuiLetModule,
  PuiPaperModule,
  PuiSelectModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { HostsFormComponent } from './hosts-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiInputModule,
    PuiIconModule,
    AddHostsDrawerModule,
    PuiInputRadioModule,
    PuiGroupModule,
    PuiPaperModule,
    PuiLetModule,
    SelectedHostsModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule,
    UpdateValueAndValidityModule
  ],
  declarations: [HostsFormComponent],
  exports: [HostsFormComponent]
})
export class HostsFormModule {}
