import { Component, Input } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _steps = [1];
  @Input()
  set steps(steps) {
    this._steps = steps;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get steps() {
    return this._steps;
  }
  @Input() activeStep = 1;
}
