import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Session } from '@app/shared/interfaces/session';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { PuiIconAppearanceType } from '@awarenow/profi-ui-core';

const rescheduleOffer = `Reschedule offer`;
const cancelOffer = `Cancel offer`;
const proposeAnotherSession = `Propose another session`;
const rescheduleNextSession = `Reschedule next session`;
const cancelNextSession = `Cancel next session`;
const cancelForThisClient = `Cancel for this client`;
const proposeDifferentTime = `Propose different time`;
const declineSessionRequest = `Decline session request`;

const actionTypes = {
  ADD: 'add',
  DECLINE: 'decline',
  CANCEL: 'cancel',
  RESCHEDULE: 'reschedule',
  ACCEPT: 'accept',
  ACCEPT_RESCHEDULING: 'accept_rescheduling'
};

const icons = {
  RESCHEDULE: 'pui:rotate-right',
  CANCEL: 'pui:x-circle',
  ADD: 'pui:plus-circle'
};

const colors: Record<string, PuiIconAppearanceType> = {
  ACCENT: 'warning'
};

const offerIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: rescheduleOffer,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.ADD,
    text: proposeAnotherSession,
    className: 'icon-add',
    action: actionTypes.ADD
  },
  {
    icon: icons.CANCEL,
    text: cancelOffer,
    className: 'icon-cancel',
    action: actionTypes.CANCEL,
    color: colors.ACCENT
  }
];

const offerGroupActions = [
  {
    icon: icons.ADD,
    text: proposeAnotherSession,
    className: 'icon-add',
    action: actionTypes.ADD
  },
  {
    icon: icons.CANCEL,
    text: cancelOffer,
    className: 'icon-cancel',
    action: actionTypes.CANCEL,
    color: colors.ACCENT
  }
];

const approvedIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: rescheduleNextSession,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.ADD,
    text: proposeAnotherSession,
    className: 'icon-add',
    action: actionTypes.ADD
  },
  {
    icon: icons.CANCEL,
    text: cancelNextSession,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const approvedGroupActions = [
  {
    icon: icons.ADD,
    text: proposeAnotherSession,
    className: 'icon-add',
    action: actionTypes.ADD
  },
  {
    icon: icons.CANCEL,
    text: cancelForThisClient,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const pendingApprovementIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: proposeDifferentTime,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.CANCEL,
    text: declineSessionRequest,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const pendingApprovementGroupActions = [
  {
    icon: icons.CANCEL,
    text: declineSessionRequest,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

@Component({
  selector: 'app-in-chat-guide-session-details',
  templateUrl: './in-chat-guide-session-details.component.html',
  styleUrls: ['./in-chat-guide-session-details.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-in-chat-guide-session-details' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InChatGuideSessionDetailsComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any[] = [];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _session: Session;

  actionTypes = actionTypes;

  @Input() set session(session: Session) {
    this._session = session;
    if (this._session) {
      switch (this._session.status) {
        case SessionStatuses.APPROVED:
          if (this.canReschedule()) {
            this.actions = approvedIndividualActions;
            break;
          }
          this.actions = approvedGroupActions;
          break;
        case SessionStatuses.GUIDE_OFFER:
          if (this.canReschedule()) {
            this.actions = offerIndividualActions;
            break;
          }
          this.actions = offerGroupActions;
          break;
        case SessionStatuses.PENDING_APPROVEMENT:
        case SessionStatuses.RESCHEDULE_BY_CLIENT:
        case SessionStatuses.RESCHEDULE_BY_GUIDE:
          if (this.canReschedule()) {
            this.actions = pendingApprovementIndividualActions;
            break;
          }
          this.actions = pendingApprovementGroupActions;
          break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get session() {
    return this._session;
  }

  @Output()
  accept = new EventEmitter();

  @Output()
  approveReschedulement = new EventEmitter();

  @Output()
  decline = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @Output()
  offer = new EventEmitter();

  @Output()
  reschedule = new EventEmitter();

  get enableSessionRequestSection(): boolean {
    return (
      !!this._session &&
      [SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(this._session.status)
    );
  }

  get isIndividualSessionRequest(): boolean {
    return (
      !!this._session &&
      [SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(this._session.status) &&
      this._session.serviceType === SessionTypes.SESSION
    );
  }

  get isGroupSessionRequest(): boolean {
    return (
      !!this._session &&
      [SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(this._session.status) &&
      this._session.serviceType === SessionTypes.GROUP_INSTANCE
    );
  }

  get isReschedulement(): string {
    return !!this._session && this._session.status === SessionStatuses.RESCHEDULE_BY_CLIENT
      ? actionTypes.ACCEPT_RESCHEDULING
      : actionTypes.ACCEPT;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  actionHandler(type: string) {
    switch (type) {
      case actionTypes.DECLINE:
        this.decline.emit();
        break;
      case actionTypes.CANCEL:
        this.cancel.emit(this.session);
        break;
      case actionTypes.RESCHEDULE:
        this.reschedule.emit();
        break;
      case actionTypes.ADD:
        this.offer.emit();
        break;
      case actionTypes.ACCEPT:
        this.accept.emit();
        break;
      case actionTypes.ACCEPT_RESCHEDULING:
        this.approveReschedulement.emit();
        break;
    }
  }

  private canReschedule(): boolean {
    return !this._session.isRecurrent && !this._session.isSeatsPerTimeSlot;
  }
}
