import { Component } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-community-layout',
  templateUrl: './community-layout.component.html',
  styleUrls: ['./community-layout.component.scss']
})
export class CommunityLayoutComponent {
  shownMenu = false;

  toggledMenu(status: boolean): void {
    this.shownMenu = status;
  }
}
