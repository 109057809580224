import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availableSeats'
})
export class AvailableSeatsPipe implements PipeTransform {
  transform(seats: number, attendees: number | undefined | null): string {
    const availableSeats = seats - (attendees || 0);
    const text = `seats available`;

    return `${availableSeats}/${seats} ${text}`;
  }
}
