<ui-selector-multiple
  #selector
  [(ngModel)]="value"
  [multiple]="true"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [required]="required"
  [overlayPanelClassName]="'ui-client-selector-panel'"
  [separatorKeysCodes]="separatorKeysCodes"
  [compareWith]="compareWith"
  [getOptionLabel]="getOptionLabel"
  [getChipLabel]="getBadgeLabel"
  [errorStateMatcher]="errorStateMatcher"
  [optionTemplateRef]="clientOptionTemplateRef"
  [emptyListTemplateRef]="showEmptyListTemplate ? (canAdd ? addClientTemplateRef : defaultEmptyListTemplateRef) : null"
  [autocompleteConnectedTo]="autocompleteConnectedTo"
  [autocompleteDisabled]="autocompleteDisabled"
  [ariaLabel]="placeholder"
  aria-orientation="vertical"
  (ngModelChange)="selected($event)"
  (opened)="onPanelOpen()"
  (closed)="onPanelClose()"
  (typed)="typed.emit($event)"
  (inputTokenEnd)="inputTokenEnd.emit($event)">
  <ui-option
    class="client-option"
    *ngFor="let option of clients$ | async | filterByNameOrEmail: selector.typedValue"
    [value]="option"
    [disabled]="option.disabled"
    [id]="option.id">
    {{ option | displayVia : getOptionLabel }}
  </ui-option>
</ui-selector-multiple>

<ng-template let-option #clientOptionTemplateRef>
  <app-user-info
    matTooltip="You've reached your active client limit — upgrade your plan or archive other clients."
    [matTooltipDisabled]="!option.disabled"
    [matTooltipPosition]="'above'"
    i18n-matTooltip="@@contactDetailsTemplateUnarchiveClientTooltipClientLimitIsReached"
    [archived]="option.archived"
    [user]="option"></app-user-info>
</ng-template>

<ng-template #defaultEmptyListTemplateRef>
  <div class="client-option">
    <div class="client-invite-option">
      <div class="invite-option-text">{{ nothingFoundText || text['Nothing found'] }}</div>
    </div>
  </div>
</ng-template>

<ng-template #addClientTemplateRef>
  <div class="client-option" (click)="addClientByClick()">
    <div class="client-invite-option">
      <div class="invite-option-button">
        <ui-icon class="invite-option-icon" svgIcon="plus"></ui-icon>
      </div>
      <div class="invite-option-text">
        {{ nothingFoundText || text['Nothing found'] }}. {{ text['invite'] }} «{{ selector?.chipListInput?.value }}» {{
        text['via email'] }}
      </div>
    </div>
  </div>
</ng-template>
