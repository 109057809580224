import { Directive, ElementRef, HostListener, Inject, Input, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appScrollToFirstInvalid]'
})
export class ScrollToFirstInvalidDirective {
  readonly DEFAULT_INVALID_ELEMENT_SELECTOR = '*[formcontrolname].ng-invalid.ng-dirty.ng-touched';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _invalidElementSelector = this.DEFAULT_INVALID_ELEMENT_SELECTOR;

  @Input()
  set invalidElementSelector(value: string | null) {
    this._invalidElementSelector = value || this.DEFAULT_INVALID_ELEMENT_SELECTOR;
  }

  @Input()
  offset = 35; // offset to top in pixels

  @Input()
  triggers: 'submit' | 'manual' = 'submit';

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get invalidElementSelector(): string {
    return this._invalidElementSelector;
  }

  constructor(
    private elem: ElementRef,
    private _ngZone: NgZone,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  @HostListener('submit', ['$event'])
  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  onSubmit(event) {
    if (this.triggers === 'submit') {
      this._scroll();
    }
  }

  scroll(): void {
    if (this.triggers === 'manual') {
      this._scroll();
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scroll(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      const element = this.elem.nativeElement.querySelector(this.invalidElementSelector);

      if (element) {
        const scrollTop = this.getOffsetTop(element) - this.offset;
        this._browserWindow.scrollTo(0, scrollTop);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private getOffsetTop(el: any) {
    return el.getBoundingClientRect().top + this._document.body.scrollTop + this._document.documentElement.scrollTop;
  }
}
