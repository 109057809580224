import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { RecurringEvent, SessionTemplateLocation } from '@app/screens/guide/guide-sessions-templates/types';
import { IUser } from '@app/shared/interfaces/user';
import { SessionType } from '@app/shared/enums/session-type';
import { CallInitiators } from '@app/shared/enums/call-initiators';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramServiceEvent {
  date: string;
  duration: number;
  timezone?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramServiceDraft {
  serviceType: GuideServiceTypes;
  connectionType: SessionConnectionTypes;
  description?: string | null;
  descriptionRepresentation?: string | null;
  descriptionText?: string | null;
  duration: number;
  name: string;
  event?: IProgramServiceEvent;
  seatsPerTimeSlot: number;
  recurring: RecurringEvent;
  locations: SessionTemplateLocation;
  length: number;
  disableGuests: boolean;
  sessionType: SessionType;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramServiceDetails extends IProgramServiceDraft {
  id: number;
  isProgrammatic?: boolean;
  recurring: RecurringEvent;
  location?: {
    address: string | null;
    callInitiator: CallInitiators;
    hideAddress: boolean;
    connectionType: SessionConnectionTypes;
  };
  team: IUser[];
}

export class ProgramServiceDraft {
  // @ts-expect-error TS2564
  readonly serviceType: GuideServiceTypes;
  // @ts-expect-error TS2564
  readonly connectionType: SessionConnectionTypes;
  readonly description?: string | null;
  readonly descriptionRepresentation?: string | null;
  readonly descriptionText?: string | null;
  // @ts-expect-error TS2564
  readonly duration: number;
  // @ts-expect-error TS2564
  readonly name: string;
  readonly event?: IProgramServiceEvent;
  readonly seatsPerTimeSlot: number | undefined;
  readonly recurring: RecurringEvent | undefined;
  readonly locations: SessionTemplateLocation | undefined;
  readonly length: number | undefined;
  readonly disableGuests: boolean | undefined;
  readonly hosts: IUser[] | undefined;
  readonly sessionType: SessionType | undefined;

  constructor(programServiceDraft: Readonly<IProgramServiceDraft>) {
    if (!programServiceDraft) {
      throw new Error('Invalid argument');
    }

    Object.entries(programServiceDraft).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
  }
}

export class ProgramService extends ProgramServiceDraft {
  readonly id: number;
  readonly isProgrammatic?: boolean;
  readonly recurring!: RecurringEvent;

  constructor(programServiceDetails: Readonly<IProgramServiceDetails>) {
    super(programServiceDetails);

    this.id = programServiceDetails.id;

    this.recurring = programServiceDetails.recurring;

    if (programServiceDetails.isProgrammatic != null) {
      this.isProgrammatic = programServiceDetails.isProgrammatic;
    }
  }
}
