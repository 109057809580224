import { DateTime } from 'luxon';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NoteRecordingService } from '@app/modules/user-notes/services/note-recording.service';
import { INotesMetaRecording } from '@app/shared/interfaces/notes';
import { RecordingsService } from '@app/core/public/recordings.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-note-recording',
  templateUrl: './note-recording.component.html',
  styleUrls: ['./note-recording.component.scss']
})
export class NoteRecordingComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private archiveMetadataTimer!: NodeJS.Timeout;

  private noteRecordingValue!: INotesMetaRecording;

  private uid: string;

  isRecordingAvailable = false;

  recordingExpirationDate!: DateTime;

  videoUrl$ = new ReplaySubject<string | null>(1);

  downloadArchiveUrl: string | null = null;

  @Input()
  noteText!: string;

  recordingVideoUrl: string | null = null;

  @Input()
  recordingId: string | null = null;

  @Input()
  recordingDuration: number | null = null;

  /* @Input()
  set recordingUrl(url: string | null) {
    this.recordingVideoUrl = url;
    this.downloadArchiveUrl = url;
    this.videoUrl$.next(url);
  }*/

  @Input()
  set noteRecording(val: INotesMetaRecording) {
    if (this.recordingId) {
      this.noteRecordingValue = val as INotesMetaRecording;
      this.isRecordingAvailable = true;
      this.recordingExpirationDate = this.noteRecordingService.getArchiveExpirationDate(
        this.noteRecordingValue.createdAt
      );
    }
  }

  get noteRecording(): INotesMetaRecording {
    return this.noteRecordingValue;
  }

  @ViewChild('video', { static: false })
  video!: ElementRef;

  constructor(private noteRecordingService: NoteRecordingService, private recordingsService: RecordingsService) {
    this.uid = Math.random().toString(36).substr(2, 9);
  }

  ngOnInit(): void {
    this.noteRecordingService
      .otherVideoPlay$(this.uid)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.pauseVideo());

    this.recordingsService
      .getRecording$(this.recordingId!)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ url }) => {
        this.downloadArchiveUrl = url;
        this.recordingVideoUrl = url;
        this.videoUrl$.next(url);
      });

    this.videoUrl$.pipe(takeUntil(this.destroy$)).subscribe(videoUrl => {
      if (this.recordingVideoUrl) {
        this.downloadArchiveUrl = videoUrl;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  pauseVideo(): void {
    try {
      this.video?.nativeElement?.pause();
    } catch (err) {}
  }

  onPlay(): void {
    if (!this.recordingVideoUrl) {
      this.noteRecordingService.videoPlay(this.uid);
    }
  }

  private clearArchiveMetadata(): void {
    clearTimeout(this.archiveMetadataTimer);
    // @ts-expect-error TS2322
    this._archiveMetadata = null;
  }
}
