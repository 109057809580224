import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetters'
})
export class FirstLettersPipe implements PipeTransform {
  transform(text: string | null, numberOfLetters = 1): string | null {
    return text == null ? null : text.substring(0, numberOfLetters);
  }
}
