import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserSelectModule } from '@app/modules/service-scheduling/components/user-select/user-select.module';
import { PuiButtonModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

import { AddHostsDrawerComponent } from './add-hosts-drawer.component';

@NgModule({
  imports: [CommonModule, UserSelectModule, PuiTypographyModule, PuiButtonModule],
  declarations: [AddHostsDrawerComponent],
  exports: [AddHostsDrawerComponent]
})
export class AddHostsDrawerModule {}
