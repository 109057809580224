import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilKeyChanged, take, takeUntil } from 'rxjs/operators';
import { IServerSession } from '@app/shared/interfaces/session';
import { CLIENT_WALLETS_ENDPOINT } from '@app/shared/constants/endpoints';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from '@app/core/auth/services';
import { SocketService } from '../socket/socket.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPostSessionData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  session: IServerSession;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INegativeBalanceData {
  balance: number;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IUserWalletStateServerResponse {
  userWallet: IUserWallet;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUserWallet {
  balance: number;
}

@Injectable()
export class UserWalletService implements OnDestroy {
  private destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _negativeBalance$ = new ReplaySubject<INegativeBalanceData>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _postSession$ = new Subject<IPostSessionData>();

  // @ts-expect-error TS2345
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _wallet$ = new BehaviorSubject<IUserWallet>(null);

  get negativeBalance$(): Observable<INegativeBalanceData> {
    return this._negativeBalance$.asObservable();
  }

  get postSession$(): Observable<IPostSessionData> {
    return this._postSession$.asObservable();
  }

  get wallet$(): Observable<IUserWallet> {
    return this._wallet$.asObservable();
  }

  constructor(private _http: HttpClient, private _socket: SocketService, private _authService: AuthService) {
    this._authService
      .onAuth()
      .pipe(take(1))
      .subscribe(() => {
        if (!this._authService.isAuthorized || this._authService.user.RoleId !== UserRoles.CLIENT) {
          return;
        }
        this._getUserWalletState();

        this._socket
          .onNegativeBalanceNotification()
          .pipe(takeUntil(this.destroy$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(data => this._onNegativeBalanceChange(data));
        this._socket
          .onPostSessionNotification()
          .pipe(takeUntil(this.destroy$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(data => this._onPostSessionNotification(data));
        this._socket
          .onSessionChanged()
          .pipe(takeUntil(this.destroy$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(() => this._getUserWalletState());
        this._socket
          .onUserWalletNotification()
          .pipe(takeUntil(this.destroy$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(() => this._getUserWalletState());
        this._socket
          .onUserWalletUpdate()
          .pipe(distinctUntilKeyChanged('balance'), takeUntil(this.destroy$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(() => this._getUserWalletState());
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkIfBalanceIsNegativeAndFireNotificationIfTrue(): boolean {
    const wallet = this._wallet$.getValue();
    const isBalanceNegative = wallet ? wallet.balance < 0 : false;

    if (isBalanceNegative) {
      this._fireNegativeBalance(wallet.balance);
    }

    return isBalanceNegative;
  }

  refresh(): void {
    this._getUserWalletState();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _fireNegativeBalance(balance: number): void {
    this._onNegativeBalanceChange({ balance, message: '' });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getUserWalletState(): void {
    const noCacheUrl = `${CLIENT_WALLETS_ENDPOINT}?nocache=${new Date().getTime()}`;
    this._http
      .get<IUserWalletStateServerResponse>(noCacheUrl)
      .subscribe(response => this._onUserWalletStateChange(response.userWallet));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onNegativeBalanceChange(data: INegativeBalanceData): void {
    this._negativeBalance$.next(data);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onPostSessionNotification(data: IPostSessionData): void {
    this._postSession$.next(data);
    this.refresh();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onUserWalletStateChange(userWallet: IUserWallet): void {
    if (userWallet) {
      const currentWalletState = this._wallet$.getValue();
      if (!currentWalletState || userWallet.balance !== currentWalletState.balance) {
        this._wallet$.next(userWallet);
      }

      if (userWallet.balance < 0) {
        this._fireNegativeBalance(userWallet.balance);
      }
    }
  }
}
