import { FactoryProvider, Optional, SkipSelf } from '@angular/core';
import { DefaultScheduleDatesPartitionStrategy } from './default-schedule-dates-partition-strategy';
import { ScheduleDatesPartitionStrategy } from './schedule-dates-partition-strategy';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function scheduleDatesPartitionStrategyFactory(parent: ScheduleDatesPartitionStrategy<unknown, unknown>) {
  return parent || new DefaultScheduleDatesPartitionStrategy();
}

export const SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER: FactoryProvider = {
  provide: ScheduleDatesPartitionStrategy,
  deps: [[new Optional(), new SkipSelf(), ScheduleDatesPartitionStrategy]],
  useFactory: scheduleDatesPartitionStrategyFactory
};
