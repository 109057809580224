import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { IGuideService } from '../../types';

@Component({
  selector: 'app-guide-webinar-service',
  templateUrl: './guide-webinar-service.component.html',
  styleUrls: ['../../styles/common.scss', './guide-webinar-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-webinar-service'
  }
})
export class GuideWebinarServiceComponent<T extends IGuideService> {
  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  badges: TemplateRef<any>[];

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  service: T;
}
