import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonPipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PUBLIC_PROFILE_ENDPOINT, PUBLIC_WORKSPACE_ENDPOINT } from '@app/shared/constants/endpoints';
import { IServerGuideSchedulesResponse, IServiceScheduleOptions } from '@app/core/schedule/types';
import { ScheduleUtility } from '@app/core/schedule/utils';
import { LogType } from '../log/types';
import { LogService } from '../log/log.service';
import { BookingAvailability } from '@appWidget/modules/booking/interfaces';

@Injectable()
export class GuideScheduleEndpointService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _logger: LogService,
    private readonly _toJson: JsonPipe
  ) {}

  loadWorkspaceSchedule$(scheduleOptions: IServiceScheduleOptions): Observable<IServerGuideSchedulesResponse> {
    const params = ScheduleUtility.buildParams(scheduleOptions).delete('guideId');

    return this._http.get<IServerGuideSchedulesResponse>(`${PUBLIC_WORKSPACE_ENDPOINT}/availability`, { params }).pipe(
      catchError(error => {
        const errorLog = { error, location: 'WorkspaceScheduleApiService.loadWorkspaceSchedule' };
        this._logger.sendLog(LogType.ERROR, this._toJson.transform(errorLog));
        return throwError(error);
      })
    );
  }

  loadGuideSchedule$(scheduleOptions: IServiceScheduleOptions): Observable<IServerGuideSchedulesResponse> {
    if (scheduleOptions.requiresHostSelection && !scheduleOptions.serviceHost) {
      return this.loadWorkspaceSchedule$(scheduleOptions);
    }
    const params = ScheduleUtility.buildParams(scheduleOptions);

    return this._http.get<IServerGuideSchedulesResponse>(`${PUBLIC_PROFILE_ENDPOINT}/availability`, { params }).pipe(
      catchError(error => {
        const errorLog = { error, location: 'GuideScheduleEndpointService.loadGuideSchedule' };
        this._logger.sendLog(LogType.ERROR, this._toJson.transform(errorLog));
        return throwError(error);
      })
    );
  }

  loadGuideScheduleNew$(scheduleOptions: {
    guideId: number;
    workspaceId: number;
    startTime: string;
    endTime: string;
    duration: number;
    timeZone: string;
    eventTypeId: number;
    eventTypeSlug: string;
  }): Observable<BookingAvailability[]> {
    const params = new HttpParams({
      fromObject: scheduleOptions
    });

    return this._http.get<BookingAvailability[]>(`${PUBLIC_PROFILE_ENDPOINT}/public-availability`, { params }).pipe(
      catchError(error => {
        const errorLog = { error, location: 'GuideScheduleEndpointService.loadGuideSchedule' };
        this._logger.sendLog(LogType.ERROR, this._toJson.transform(errorLog));
        return throwError(error);
      })
    );
  }
}
