import { ChangeDetectionStrategy, Component } from '@angular/core';

// [TODO] Review if this component can be reused in other places like:
// app-client-guides-list
// app-guide-service-item
// app-quiz-card
// This component originally was taken from app-edit-link
@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionDropdownComponent {}
