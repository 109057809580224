import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AppFormFieldControl } from '@app/modules/ui-kit/form/components/form-field/form-field.component';
import { UiSelectorMultiple } from '@app/modules/ui-kit/_base/ui-selector-multiple/selector';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-team-members-select',
  templateUrl: './team-members-select.component.html',
  styleUrls: ['./team-members-select.component.scss'],
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => TeamMembersSelectComponent) }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TeamMembersSelectComponent implements ControlValueAccessor, AppFormFieldControl, OnInit, OnDestroy {
  // @ts-expect-error TS2564
  keyword: string;

  @Input() idsToExclude: number[] = [];

  constructor(
    private readonly _workspacesService: WorkspacesService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  usersBeh$: BehaviorSubject<WorkspaceUser[]> = new BehaviorSubject<WorkspaceUser[]>([]);

  get users$(): Observable<WorkspaceUser[]> {
    return this.keyword
      ? this.usersBeh$
          .asObservable()
          .pipe(
            map((users: WorkspaceUser[]) =>
              users.filter((user: WorkspaceUser) => this.userSelection(user, this.keyword))
            )
          )
      : this.usersBeh$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private destroy$: Subject<void> = new Subject<void>();

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any[] {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(newValue: any[]) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
      this._onTouched();
      this.valueChanged.emit(this._value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any[] = [];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild(UiSelectorMultiple, { static: true }) selector: UiSelectorMultiple;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostBinding('attr.area-describedby') userAriaDescribedBy = '';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  required = false;

  /* eslint-disable-next-line @angular-eslint/no-input-rename, @typescript-eslint/member-ordering */
  @Input()
  // @ts-expect-error TS2564
  autocompleteConnectedTo: MatAutocompleteOrigin;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  placeholder = '';

  /* TemplateRef that is displayed when the option list is empty  */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyListTemplateRef: TemplateRef<any> | undefined;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? !!this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering,@typescript-eslint/naming-convention
  private _disabled = false;

  /* get a text label for an option in the options list */
  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getOptionLabel() {
    return this._getOptionLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getOptionLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getOptionLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getOptionLabel = (option: any) => option?.name || option?.email || option?.contacts?.email;

  /* get text label for selected options */
  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getBadgeLabel() {
    return this._getBadgeLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getBadgeLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getBadgeLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getBadgeLabel = (option: any) => option?.name || option?.email || option?.contacts?.email;

  /* match already selected options with the general list of options */
  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get compareWith() {
    return this._compareWith;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set compareWith(fn: (option1: any, option2: any) => boolean) {
    if (!fn) {
      return;
    }
    this._compareWith = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _compareWith = (option1: any, option2: any) =>
    option1?.id && option2?.id ? option1.id === option2.id : option1?.email === option2?.email;

  get inputValue(): string | undefined {
    return this.selector?.typedValue;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output()
  typed: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output()
  inputTokenEnd: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  stateChanges: Subject<void> = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  focused = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _onTouched: () => void = () => {};

  ngOnInit(): void {
    this._workspacesService
      .getWorkspaceUsers()
      .pipe(
        map((users: WorkspaceUser[]) => {
          return users.filter((user: WorkspaceUser) => !this.idsToExclude.includes(user.id));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((users: WorkspaceUser[]) => this.usersBeh$.next(users));
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected(value: any): void {
    this._onChange(value);
    this._onTouched();
    this.valueChanged.emit(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
  }

  onPanelOpen(): void {
    this.focused = true;
    this.stateChanges.next();
  }

  onPanelClose(): void {
    this.focused = false;
    this.stateChanges.next();
  }

  handleTypeahead($event: string): void {
    this.keyword = $event;
  }

  // @ts-expect-error TS7006
  handleInputEnd($event): void {
    this.findUserOnInputEnd($event);
  }

  private findUserOnInputEnd(value: string): void {
    const users: WorkspaceUser[] = this.usersBeh$.getValue();
    // @ts-expect-error TS2322
    // eslint-disable-next-line id-length
    const filteredUser: WorkspaceUser = users.find((u: WorkspaceUser) => this.userSelection(u, value));

    if (filteredUser) {
      this.selected([filteredUser]);
      this.value = [filteredUser];
    }
  }

  private userSelection(user: WorkspaceUser, value: string): boolean {
    let firstNameIncludesValue: boolean;
    let lastNameIncludesValue: boolean;

    const separatedValues: string[] = value.split(' ');
    const containsWhitespace: boolean = separatedValues.length > 1;

    if (containsWhitespace) {
      const firstNameSearch: string = separatedValues[0].trim();
      const lastNameSearch: string = separatedValues[1].trim();

      firstNameIncludesValue = user.firstName.toLowerCase().includes(firstNameSearch.toLowerCase());
      lastNameIncludesValue = user.lastName.toLowerCase().includes(lastNameSearch.toLowerCase());
    } else {
      firstNameIncludesValue = user.firstName.toLowerCase().includes(value.toLowerCase());
      lastNameIncludesValue = user.lastName.toLowerCase().includes(value.toLowerCase());
    }
    const emailIncludesValue: boolean = user.email.toLowerCase().includes(value.toLowerCase());

    return emailIncludesValue || firstNameIncludesValue || lastNameIncludesValue;
  }
}
