<form class="field-editor-form" (submit)="$event.preventDefault(); save()">
  <!--Custom form BEGIN-->
  <ng-content></ng-content>
  <!--Custom form END-->
  <div class="field-editor-form__row">
    <button
      pui-button
      size="s"
      type="submit"
      class="field-editor-form__button"
      i18n="@@save:new"
      [disabled]="control.invalid || control.pristine">
      Save
    </button>
    <button
      pui-button
      type="button"
      appearance="secondary"
      size="s"
      class="field-editor-form__button"
      (click)="cancel()"
      i18n="@@cancel:new">
      Cancel
    </button>
  </div>
</form>
