export enum SessionStatuses {
  APPROVED = 'approved',
  CANCELLED = 'canceled', // client cancels
  DECLINED = 'declined', // guide declines
  DONE = 'done',
  /**
   * @deprecated Marked as deprecated (and renamed as IN_PROGRESS_OLD) due to divide old logic and new logic
   */
  IN_PROGRESS_OLD = 'in_progress',
  IN_PROGRESS = 'in_progress',
  PENDING_APPROVEMENT = 'not_yet_approved',
  GUIDE_OFFER = 'guide_offer',
  GUIDE_OFFER_CANCELLED = 'guide_offer_canceled', // guide cancels
  GUIDE_OFFER_DECLINED = 'guide_offer_declined', // client declines
  RESCHEDULE_BY_CLIENT = 'reschedule_by_client',
  RESCHEDULE_BY_GUIDE = 'reschedule_by_guide',
  MISSED = 'missed',
  EXPIRED = 'expired',
  // deprecated (use PENDING_APPROVEMENT)
  PENDING = 'pending',
  // ---  ToDo: [PR-5031] Not come from the API.  ------
  // ---  ToDo: We need to add these statuses to the API and
  // ---  ToDo: then remove the mechanism for using Session.getMeta()
  CONFIRMED = 'confirmed',
  CONFIRMED_AND_PAID = 'confirmed_and_paid'
  // ----------------------------------------------
}

export const getSessionStatus = (statuses: SessionStatuses[]): SessionStatuses | null => {
  if (statuses.length === 0) {
    return null;
  }

  if (statuses.length === 1) {
    return statuses[0];
  }

  if (isDone(statuses)) {
    return SessionStatuses.DONE;
  }

  if (isExpired(statuses)) {
    return SessionStatuses.EXPIRED;
  }

  if (isMissed(statuses)) {
    return SessionStatuses.MISSED;
  }

  if (isApproved(statuses)) {
    return SessionStatuses.APPROVED;
  }

  if (isGuideOffer(statuses)) {
    return SessionStatuses.GUIDE_OFFER;
  }

  if (isDeclined(statuses)) {
    return SessionStatuses.DECLINED;
  }

  if (isCancelled(statuses)) {
    return SessionStatuses.CANCELLED;
  }

  if (isGuideOfferCancelled(statuses)) {
    return SessionStatuses.GUIDE_OFFER_CANCELLED;
  }

  if (isGuideOfferDeclined(statuses)) {
    return SessionStatuses.GUIDE_OFFER_DECLINED;
  }

  return statuses[0];
};

const isGuideOffer = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.GUIDE_OFFER);
};

const isGuideOfferCancelled = (statuses: SessionStatuses[]): boolean => {
  const filteredStatuses = statuses.filter(status => status === SessionStatuses.GUIDE_OFFER_CANCELLED);

  return filteredStatuses.length === statuses.length;
};

const isGuideOfferDeclined = (statuses: SessionStatuses[]): boolean => {
  const filteredStatuses = statuses.filter(status => status === SessionStatuses.GUIDE_OFFER_DECLINED);

  return filteredStatuses.length === statuses.length;
};

const isCancelled = (statuses: SessionStatuses[]): boolean => {
  const filteredStatuses = statuses.filter(status => status === SessionStatuses.CANCELLED);

  return filteredStatuses.length === statuses.length;
};

const isDeclined = (statuses: SessionStatuses[]): boolean => {
  const filteredStatuses = statuses.filter(status => status === SessionStatuses.DECLINED);

  return filteredStatuses.length === statuses.length;
};

const isExpired = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.EXPIRED) && !isDone(statuses) && !statuses.includes(SessionStatuses.MISSED);
};

const isMissed = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.MISSED) && !isDone(statuses);
};

const isDone = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.DONE);
};

const isApproved = (statuses: SessionStatuses[]): boolean => {
  return statuses.includes(SessionStatuses.APPROVED);
};

export function isOffer(status: SessionStatuses): boolean {
  return [SessionStatuses.GUIDE_OFFER].includes(status);
}

export function isOfferOrPending(status: SessionStatuses): boolean {
  return [SessionStatuses.GUIDE_OFFER, SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.PENDING].includes(status);
}

export function isCancelableStatus(status: SessionStatuses): boolean {
  return [
    SessionStatuses.APPROVED,
    SessionStatuses.PENDING_APPROVEMENT,
    SessionStatuses.GUIDE_OFFER,
    SessionStatuses.RESCHEDULE_BY_GUIDE,
    SessionStatuses.RESCHEDULE_BY_CLIENT
  ].includes(status);
}

export function isActiveStatus(status: SessionStatuses): boolean {
  return [
    SessionStatuses.APPROVED,
    SessionStatuses.DONE,
    SessionStatuses.PENDING_APPROVEMENT,
    SessionStatuses.GUIDE_OFFER,
    SessionStatuses.RESCHEDULE_BY_GUIDE,
    SessionStatuses.RESCHEDULE_BY_CLIENT
  ].includes(status);
}

export function isCanceledOrDeclinedStatus(status: SessionStatuses): boolean {
  return [
    SessionStatuses.CANCELLED,
    SessionStatuses.DECLINED,
    SessionStatuses.GUIDE_OFFER_CANCELLED,
    SessionStatuses.GUIDE_OFFER_DECLINED
  ].includes(status);
}
