<div class="row">
  <div [ngClass]="{ 'col-sm-6': showDatepicker, col: !showDatepicker }">
    <label pui-input-label i18n="@@contentModuleSendModuleToClients">Open this module for clients</label>
    <ng-select
      data-qa-id="module-activation-select"
      [formControl]="activationValueControl"
      [searchable]="false"
      [clearable]="false"
      [items]="activationValues"
      bindLabel="label"
      bindValue="value"
      class="options-select"></ng-select>
  </div>
  <div class="col-sm-6 sm-pl-0" *ngIf="showDatepicker">
    <label pui-input-label>&nbsp;</label>
    <pui-input-date [formControl]="activationDateControl" size="s"></pui-input-date>
    <div *ngIf="activationDateControl.hasError('required') && activationDateControl.touched" class="pui-space_top-2">
      <span pui-typography="body-s" class="pui-error" i18n="@@dateIsRequired">Date is required</span>
    </div>
  </div>
</div>
