<ng-container *ngIf="program">
  <app-service-landing
    [type]="GuideServiceTypes.PROGRAM"
    [title]="program.name"
    [coverImageUrl]="program.coverImage"
    [description]="program.description"
    [link]="link"
    [workspaceId]="program.workspaceId || program.guide?.workspaceId"
    [guide]="program.guide"
    [assignees]="assignees"
    [testimonials]="program.testimonials"
    [showBookButton]="false"
    [faq]="program.faq">
    <ng-template #submitButtonTemplate>
      <app-button
        className="btn-primary btn-lg"
        class="enroll-btn-wrapper submit-btn"
        (click)="handleEnroll()"
        [disabled]="!program?.isAlreadyEnrolled && program?.subscriptionDeactivated">
        <ng-template [ngIf]="program?.isAlreadyEnrolled" [ngIfElse]="enrollButtonContent">
          <ng-template [ngTemplateOutlet]="openButtonContent"></ng-template>
        </ng-template>
      </app-button>
    </ng-template>

    <ng-container tabs>
      <app-service-landing-tab
        *ngIf="!program.hidePrice"
        title="Price"
        i18n-title="@@price"
        [value]="program | pricing"
        icon="/assets/images/guide-services/price.svg"></app-service-landing-tab>
      <app-service-landing-tab
        title="Start"
        i18n-title="@@start"
        [value]="
          program?.startType === 'user_enrollment'
            ? programEnrolledDateCaption
            : (programStartDate | localDate: 'MMMM dd, yyyy')
        "
        icon="/assets/images/guide-services/calendar-transparent.svg"></app-service-landing-tab>
      <app-service-landing-tab
        *ngIf="program.length"
        title="Duration"
        i18n-title="@@duration"
        [value]="program.length + ''"
        icon="/assets/images/guide-services/duration.svg"></app-service-landing-tab>
    </ng-container>

    <div id="program-included">
      <ng-container *ngIf="program?.keyPoints?.length">
        <h3 class="title title-items" i18n="@@whatYouWillLearn">What's included?</h3>
        <div class="learn">
          <div class="learn-item" *ngFor="let value of program.keyPoints | publicProgramKeyPoints">
            <div>
              <ui-icon svgIcon="notification:done" color="primary"></ui-icon>
            </div>
            <div class="learn-text">{{ value }}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="program?.issues && program?.issues?.length">
        <h3 class="title title-items" i18n="@@programHelpsWith">Program helps with</h3>
        <ui-chip-list>
          <ui-chip *ngFor="let issue of program?.issues" color="accent">{{ issue }}</ui-chip>
        </ui-chip-list>
      </ng-container>
      <ng-container *ngIf="program?.approaches && program?.approaches?.length">
        <h3 class="title title-items" i18n="@@approachesIncluded">Approaches included</h3>
        <ui-chip-list>
          <ui-chip *ngFor="let approach of program?.approaches">{{ approach }}</ui-chip>
        </ui-chip-list>
      </ng-container>
      <ng-container *ngIf="program?.additionalNotes">
        <h3 class="title" i18n="@@programAdditionalDetails">Additional details</h3>
        <p class="notes">{{ program?.additionalNotes }}</p>
      </ng-container>
      <ng-container *ngIf="modules">
        <h3 data-qa-id="program-landing-template-modules-included-title" class="title" i18n="@@modulesIncluded">
          Modules included
        </h3>
        <ng-container *ngFor="let module of modules; let i = index; trackBy: trackModulesByTitle">
          <div *ngIf="!showMoreModules || i < lessModulesCount" class="module-item">
            <div class="module-item-image">
              <ng-container [ngSwitch]="module.moduleType">
                <img *ngSwitchCase="ModuleTypes.CONTENT" src="/assets/images/client-programs/book-default.svg" alt="" />
                <img *ngSwitchCase="ModuleTypes.QUIZ" src="/assets/images/client-programs/quiz-default.svg" alt="" />
                <img *ngSwitchCase="ModuleTypes.SESSION" src="/assets/images/programs/session-uniq.svg" alt="" />
                <img *ngSwitchCase="ModuleTypes.GROUP_SESSION" src="/assets/images/programs/group-session.svg" alt="" />
              </ng-container>
            </div>
            <div>
              <b [attr.data-qa-id]="'program-landing-template-module-number-title-' + i">Module {{ i + 1 }}:</b>
              <span
                [attr.data-qa-id]="'program-landing-template-module-name-title-' + i"
                [innerHtml]="module.title"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="modules.length > lessModulesCount">
          <div class="modules-toggle" (click)="onClickToggleVisibleModules()">
            <ng-container *ngIf="showMoreModules">
              <span class="modules-toggle-text" i18n="@@showMoreModules">Show more modules</span>
            </ng-container>
            <ng-container *ngIf="!showMoreModules">
              <span class="modules-toggle-text" i18n="@@showLessModules">Show less modules</span>
            </ng-container>
            <ui-icon
              color="primary"
              size="xs"
              [svgIcon]="showMoreModules ? 'menu:one-arrow-down' : 'menu:one-arrow-up'"></ui-icon>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div id="bottom-content" class="bottom-enroll">
      <app-button
        className="btn-primary btn-lg"
        class="enroll-btn-wrapper submit-btn"
        (click)="handleEnroll()"
        [disabled]="!program?.isAlreadyEnrolled && program?.subscriptionDeactivated">
        <ng-template [ngIf]="program?.isAlreadyEnrolled" [ngIfElse]="enrollButtonContent">
          <ng-template [ngTemplateOutlet]="openButtonContent"></ng-template>
        </ng-template>
      </app-button>
    </div>
  </app-service-landing>
</ng-container>

<ng-template #enrollButtonContent>
  <span data-qa-id="program-landing-template-enroll-button">
    <span transloco="Enroll">Enroll</span>
    <ng-container *ngIf="program && !program.hidePrice">
      -
      <span *ngIf="program?.isFree" i18n="@@freeProgram">free program</span>
      <span *ngIf="!program?.isFree">{{ price }}</span>
    </ng-container>
  </span>
</ng-template>

<ng-template #openButtonContent>
  <span i18n="@@open">Open</span>
</ng-template>
