import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISystemNotification } from '@app/modules/notifications/types';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { QuizClientsPolicy } from '@app/core/quizzes/types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { GuideClientCardService } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.service';

@Component({
  selector: 'app-form-notification',
  templateUrl: './form-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './form-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormNotificationComponent {
  readonly GuideServiceType = GuideServiceTypes;
  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;

  QuizClientPolicy = QuizClientsPolicy;

  constructor(private _authService: AuthService, private readonly clientCardService: GuideClientCardService) {}

  get formLink(): string {
    // @ts-expect-error TS2532
    if (this.notification.details.quizClientsPolicy === this.QuizClientPolicy.Author) {
      // @ts-expect-error TS2532
      return `/coach/surveys/answers/${this.notification.details.quizId}`;
    }
    return this._authService.getUserRoleId() === UserRoles.CLIENT
      ? // @ts-expect-error TS2532
        `client/programs/quiz/${this.notification.details.quizTemplateId}`
      : // @ts-expect-error TS2532
        `/coach/surveys/${this.notification.details.quizTemplateId}`;
  }

  showClientDetails(clientId: number): void {
    this.clientCardService.openDetails(0, clientId);
  }
}
