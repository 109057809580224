import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScheduleDateTimePickerModule } from '@app/modules/ui-kit/schedule/schedule-date-time-picker/schedule-date-time-picker.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonBehaviorsModule } from '@app/modules/common-behaviors/common-behaviors.module';
import { TimePickerGridModule } from '@app/modules/ui-kit/schedule/time-picker-grid/time-picker-grid.module';

import { SingleDateScheduleTimePickerComponent } from './components/single-date-schedule-time-picker/single-date-schedule-time-picker.component';

@NgModule({
  imports: [
    CommonBehaviorsModule,
    FormsModule,
    NgbDatepickerModule,
    NgSelectModule,
    ReactiveFormsModule,
    ScheduleDateTimePickerModule,
    TimePickerGridModule
  ],
  declarations: [SingleDateScheduleTimePickerComponent],
  exports: [SingleDateScheduleTimePickerComponent]
})
export class SingleDateScheduleTimePickerModule {}
