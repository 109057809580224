import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISystemNotification } from '@app/modules/notifications/types';

@Component({
  selector: 'app-simple-notification',
  templateUrl: './simple-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './simple-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleNotificationComponent {
  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;
}
