import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionTemplatesDrawerComponent } from './session-templates-drawer.component';
import { GroupServicesModule } from '@app/modules/service-scheduling/components/group-services/group-services.module';
import { SessionTemplatesDrawerService } from '@app/modules/session-forms/drawers/session-templates-drawer/session-templates-drawer.service';
import { PuiIconModule, PuiInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SessionTemplatesDrawerComponent],
  imports: [
    CommonModule,
    GroupServicesModule,
    PuiTypographyModule,
    PuiInputModule,
    PuiIconModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [SessionTemplatesDrawerService]
})
export class SessionTemplatesDrawerModule {}
