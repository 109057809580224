// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { environment } from '@env/environment';

// Check if user id string is of form "<userId>" | "<userId>W<workspaceId>", where userId is numeric
function isEncodedUserId(testString: string): boolean {
  const [trialUserId] = testString.split('W');

  return !Number.isNaN(+trialUserId);
}

export function checkChatIdType(
  chatId: string | number | null,
  configChatPrefix: string
): {
  chatId: string;
  type: 'chat' | 'user' | 'contact' | 'unknown';
} {
  let type: 'chat' | 'user' | 'contact' | 'unknown' = 'unknown';

  if (typeof chatId === 'string' && chatId.startsWith(configChatPrefix)) {
    type = 'chat';
  } else if (typeof chatId === 'number' || (typeof chatId === 'string' && isEncodedUserId(chatId))) {
    type = 'user';
    // @ts-expect-error TS2531
  } else if (chatId.includes('contact')) {
    type = 'contact';
  }

  return { chatId: `${chatId}`, type };
}

export function getOppositeRole(role: number): number {
  if (!role) {
    return role;
  }

  return role === 1 ? 2 : 1;
}
