import { ModuleCompletionTypes, ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { IUser } from '@app/shared/interfaces/user';

import { DiffDetails } from '../util-types';
import { ModuleActivationTypes } from './activation-types';
import { ModuleAccessTypes } from './module-access-types';
import { ModuleInstructor } from './module-instructor';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseModuleDetails {
  id?: number;
  localId: number;
  order: number;
  title: string | null;
  titleText: string | null;
  titleDeltaFormat?: string | null;
  description: string | null;
  descriptionDeltaFormat?: string | null;
  activationType: ModuleActivationTypes | null;
  activationValue: string | null;
  accessType: ModuleAccessTypes;
  instructor?: ModuleInstructor;
  hosts?: IUser[];
  instructorId?: number;
  completionType?: ModuleCompletionTypes | null;
}

export abstract class BaseModule {
  readonly id?: number;

  readonly localId: number;

  readonly order: number;

  readonly title: string | null;

  readonly titleText: string | null;

  readonly titleDeltaFormat?: string | null;

  readonly description: string | null;

  readonly descriptionDeltaFormat?: string | null;

  readonly activationType: ModuleActivationTypes | null;

  readonly activationValue: string | null;

  readonly instructor?: ModuleInstructor;

  readonly hosts?: IUser[];

  readonly instructorId?: number;

  readonly completionType?: ModuleCompletionTypes | null;

  accessType: ModuleAccessTypes;

  /**
   * In case Team Workspace, we ignore necessary session validation
   * @type {boolean}
   */
  readonly ignoreSessionValidation?: boolean;

  abstract get moduleType(): ModuleTypes;

  protected constructor(moduleDetails: Readonly<IBaseModuleDetails>) {
    if (!moduleDetails) {
      throw new Error('Invalid argument');
    }

    this.id = moduleDetails.id;
    this.localId = moduleDetails.localId;
    this.order = moduleDetails.order;
    this.title = moduleDetails.title;
    this.titleText = moduleDetails.titleText;
    this.titleDeltaFormat = moduleDetails.titleDeltaFormat;
    this.description = moduleDetails.description;
    this.descriptionDeltaFormat = moduleDetails.descriptionDeltaFormat;
    this.accessType = moduleDetails.accessType;
    this.activationType = moduleDetails.activationType;
    this.activationValue = moduleDetails.activationValue;
    this.instructor = moduleDetails.instructor;
    this.instructorId = moduleDetails.instructorId;
    this.completionType = moduleDetails.completionType;
    this.hosts = this.getSortedHosts(moduleDetails.hosts || []);
  }

  getDiffFrom(anotherBaseModule: Readonly<BaseModule>): DiffDetails<IBaseModuleDetails> | null {
    const diff: DiffDetails<IBaseModuleDetails> = {};

    [
      'title',
      'titleText',
      'description',
      'order',
      'activationType',
      'activationValue',
      'completionType',
      'ignoreSessionValidation'
    ].forEach(prop => {
      // @ts-expect-error TS7053
      if (this[prop] !== anotherBaseModule[prop]) {
        // @ts-expect-error TS7053
        diff[prop] = anotherBaseModule[prop];
      }
    });

    if (diff.description !== null && diff.description !== undefined) {
      diff.descriptionDeltaFormat = anotherBaseModule.descriptionDeltaFormat;
    }

    let instructorDiff = null;
    if (this.instructor || anotherBaseModule.instructor) {
      instructorDiff =
        this.instructor && anotherBaseModule.instructor
          ? this.instructor.getDiffFrom(anotherBaseModule.instructor)
          : {
              id: !this.instructor && anotherBaseModule.instructor ? anotherBaseModule.instructor.id : null
            };
    }

    if (instructorDiff) {
      diff.instructorId = instructorDiff.id;
    }

    if (Object.keys(diff).length) {
      diff.localId = this.localId;
      diff.id = this.id;
      diff.title = anotherBaseModule.title;
      diff.titleDeltaFormat = anotherBaseModule.titleDeltaFormat;

      if (diff.order === null || diff.order === undefined) {
        diff.order = this.order;
      }

      return diff;
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON(): any {
    return {
      id: this.id,
      localId: this.localId,
      order: this.order,
      title: this.title,
      titleText: this.titleText,
      titleDeltaFormat: this.titleDeltaFormat,
      description: this.description,
      descriptionDeltaFormat: this.descriptionDeltaFormat,
      accessType: this.accessType,
      activationType: this.activationType,
      activationValue: this.activationValue,
      instructorId: this.instructor ? this.instructor.id : undefined,
      completionType: this.completionType,
      ignoreSessionValidation: this.ignoreSessionValidation
    };
  }

  private getSortedHosts(hosts: IUser[]): IUser[] {
    return hosts?.sort((firstUser: IUser, secondUser: IUser) => (firstUser.id > secondUser.id ? 1 : -1));
  }
}

export function cleanBaseModule(baseModule: IBaseModuleDetails): IBaseModuleDetails {
  return {
    id: baseModule.id,
    localId: baseModule.localId,
    title: (baseModule.title && baseModule.title.trim()) || '',
    titleText: baseModule.titleText,
    titleDeltaFormat: (baseModule.titleDeltaFormat && baseModule.titleDeltaFormat.trim()) || '',
    description: (baseModule.description && baseModule.description.trim()) || '',
    descriptionDeltaFormat: (baseModule.descriptionDeltaFormat && baseModule.descriptionDeltaFormat.trim()) || '',
    order: baseModule.order,
    activationType: baseModule.activationType,
    accessType: baseModule.accessType,
    activationValue: baseModule.activationValue,
    instructor: baseModule.instructor,
    hosts: baseModule.hosts,
    completionType: baseModule.completionType
  };
}
