import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionsEndpointResolverService } from '@app/core/session/sessions-endpoint-resolver.service';
import { IGuideRelation } from '@app/core/users/types';
import { UserRoles } from '@app/shared/enums/user-roles';

@Injectable()
export class SessionsApiService {
  constructor(private readonly _http: HttpClient, private readonly _urlResolver: SessionsEndpointResolverService) {}

  acceptSessionRequest$<T>(id: number): Observable<T> {
    return this._post$(this._urlResolver.getApproveSessionUrl(), { id, withAllRecurring: true });
  }

  approveReschedule$<T>(id: number): Observable<T> {
    return this._post$(this._urlResolver.getApproveRescheduleUrl(), { id, withAllRecurring: false });
  }

  archiveSession$<T>(id: number): Observable<T> {
    return this._post$(this._urlResolver.getArchiveSessionUrl(), { id });
  }

  declineSessionOffer$<T>(id: number, reason?: string | null, cancelAllRecurring = true): Observable<T> {
    return this._post$(
      this._urlResolver.getDeclineOfferUrl(),
      reason ? { id, reason, cancelAllRecurring } : { id, cancelAllRecurring }
    );
  }

  endSession$<T>(eventId: number): Observable<T> {
    return this._postNoLoader$(this._urlResolver.getEndSessionUrl(), { id: eventId });
  }

  extendSession$<T>(eventId: number, duration: number): Observable<T> {
    return this._postNoLoader$(this._urlResolver.getExtendSessionUrl(), { id: eventId, duration });
  }

  getFeedbackReadySession$<T>(profileUrl: string): Observable<T> {
    return this._urlToObservable$(this._urlResolver.getFeedbacksUrl()).pipe(
      switchMap(url => {
        const params = new HttpParams({ fromObject: { guide: profileUrl } });
        return this._http.get<T>(`${url}/feedback-ready-session`, { params });
      })
    );
  }

  loadSessions$<T>(): Observable<T> {
    return this._urlToObservable$(this._urlResolver.getLoadSessionsUrl()).pipe(
      map(url => `${url}?nocache=${Math.random()}`),
      switchMap(url => this._http.get<T>(url))
    );
  }

  refuseSession$<T>(id: number, reason?: string | null, cancelAllRecurring = true): Observable<T> {
    return this._post$(
      this._urlResolver.getRefuseSessionUrl(),
      reason ? { id, reason, cancelAllRecurring } : { id, cancelAllRecurring }
    );
  }

  reschedule$<T>(
    id: number,
    date: string,
    timezone: string,
    reason?: string,
    userRole: UserRoles = UserRoles.GUIDE
  ): Observable<T> {
    const data = { id, date, reason, timezone };
    if (userRole === UserRoles.CLIENT) {
      return this._post$(this._urlResolver.getRescheduleClientSessionUrl(), data);
    }
    return this._post$(this._urlResolver.getRescheduleGuideSessionUrl(), data);
  }

  sendFeedback$<T>(feedback: {
    sessionId: number;
    mark: number;
    comment?: string;
    privateComment?: string;
  }): Observable<T> {
    return this._postNoLoader$(this._urlResolver.getFeedbacksUrl(), feedback);
  }

  startSession$<T>(eventId: number): Observable<T> {
    return this._postNoLoader$(this._urlResolver.getStartSessionUrl(), { id: eventId });
  }

  trackSession$<T>(eventId: number): Observable<T> {
    return this._postNoLoader$(this._urlResolver.getTrackSessionUrl(), { eventId });
  }

  getEventByUuid$<T>(eventUuid: string): Observable<T> {
    return this._urlToObservable$(this._urlResolver.getEventByUuidUrl(eventUuid)).pipe(
      map(url => `${url}?nocache=${Math.random()}`),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap(url => this._http.get<any>(url)),
      map(res => res.event)
    );
  }

  inviteToRunningSession$<T>(eventId: number, clients: IGuideRelation[]): Observable<T> {
    return this._post$(this._urlResolver.getInviteToSessionUrl(), { eventId, clients });
  }

  joinRunningSession$<T>(eventUuid: string): Observable<T> {
    return this._post$(this._urlResolver.getJoinRunningSessionUrl(), { eventUuid });
  }

  joinTheCall$<T>(sessionId: number): Observable<T> {
    return this._post$(this._urlResolver.joinTheCallUrl(), { id: sessionId });
  }

  addSessionChat$<T>(sessionId: number): Observable<T> {
    return this._post$(this._urlResolver.addSessionChat(), { id: sessionId });
  }

  markAsMissed$<T>(eventId: number, reasonText: string): Observable<T> {
    return this._post$(this._urlResolver.markAsMissed(), reasonText ? { eventId, reasonText } : { eventId });
  }

  markAsCompleted$<T>(eventId: number): Observable<T> {
    return this._post$(this._urlResolver.markAsCompleted(), { eventId });
  }

  markAsAbsent$<T>(sessionId: number, reasonText: string): Observable<T> {
    return this._post$(this._urlResolver.markAsAbsent(), reasonText ? { sessionId, reasonText } : { sessionId });
  }

  markAsPresent$<T>(sessionId: number): Observable<T> {
    return this._post$(this._urlResolver.markAsPresent(), { sessionId });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _postNoLoader$<T>(urlString: string | null, data): Observable<T> {
    // @ts-expect-error TS2322
    return this._urlToObservable$(urlString).pipe(switchMap(url => this._http.post<T>(url, data)));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _post$<T>(urlString: string | null, data): Observable<T> {
    // @ts-expect-error TS2322
    return this._urlToObservable$(urlString).pipe(
      switchMap(url => {
        // @ts-expect-error TS2345
        return this._http.post<T>(url, data);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _urlToObservable$(url: string | null): Observable<string | null> {
    if (!url) {
      return throwError(null);
    }

    return of(url);
  }
}
