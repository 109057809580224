import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { CommonHeaderModule } from '@app/modules/common-header/common-header.module';
import { CommonFooterModule } from '@app/modules/common-footer/common-footer.module';
import { ComingSoonBadgeModule } from '@app/modules/ui-kit/coming-soon-badge/coming-soon-badge.module';
import { MarketplaceLayoutComponent } from './marketplace-layout.component';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  declarations: [MarketplaceLayoutComponent],
  imports: [
    CommonModule,
    ComingSoonBadgeModule,
    RouterModule,
    NotificationsModule,
    CommonHeaderModule,
    CommonFooterModule,
    TranslocoRootModule
  ]
})
export class MarketplaceLayoutModule {}
