import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthModalComponent, AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { ILogoLink, prepareLogoUrlData } from '@app/shared/utils/url';
import { UrlTypes } from '@app/shared/enums/url-types';
import { environment } from '@env/environment';
import { PUI_IS_DESKTOP } from '@awarenow/profi-ui-core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  URL_TYPE = UrlTypes;
  guidesRoute = environment.guidesRoute;
  headerBackground = '';
  menuBackgroundColor = '';
  // @ts-expect-error TS2564
  logoUrl: ILogoLink;
  scrolled = false;
  showMenu = false;
  whiteLogo = '';

  @Input()
  coloredHeader = false;

  @Output()
  showMenuChange = new EventEmitter<boolean>();

  @HostListener('window:scroll', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handleScroll() {
    if (!this.coloredHeader) {
      this.scrolled = !!window.scrollY;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    readonly authService: AuthService,
    private readonly _modal: NgbModal,
    private readonly _brandingService: BrandingService,
    @Inject(PUI_IS_DESKTOP) private readonly isDesktop$: Observable<boolean>
  ) {}

  ngOnInit(): void {
    this.scrolled = this.coloredHeader;

    this._brandingService.whiteLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.whiteLogo = logo));

    combineLatest([this.isDesktop$, this._brandingService.menuConfig$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isDesktop, menuConfig]) => {
        // @ts-expect-error TS2322
        this.headerBackground = menuConfig.headerBackground;
        // @ts-expect-error TS2322
        this.menuBackgroundColor = !isDesktop ? menuConfig.mobileMenuBackgroundColor : '';
      });

    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ urlInLogo }) => {
      this.logoUrl = prepareLogoUrlData(urlInLogo);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.showMenuChange.emit(this.showMenu);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openSignIn(event: Event) {
    event.preventDefault();
    const ref = this._modal.open(AuthModalComponent, { windowClass: 'auth-modal' });
    ref.componentInstance.activeState = AuthStates.INIT;
    return false;
  }
}
