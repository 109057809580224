import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AvailableClientSessionsComponent,
  PastClientSessionsComponent,
  UpcomingClientSessionsComponent
} from './components';
import {
  PuiButtonModule,
  PuiCollapseModule,
  PuiDataBoxModule,
  PuiDividerModule,
  PuiIconModule,
  PuiLetModule,
  PuiPaperModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ClientSessionModule } from '@libs/business/client/client-session/client-session.module';
import { SortOnlySessionDateModule } from '@app/shared/pipes/sort-only-session-date/sort-only-session-date.module';
import { SESSION_PROPERTY_FACTORY } from '@libs/business/cdk/session';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { GroupSessionsByPipe } from './components/available-client-sessions/grouping/group-sessions-by.pipe';
import { NormalizeSessionsPipe } from './components/available-client-sessions/grouping/normalize-sessions.pipe';
import { StackSessionsByPipe } from './components/available-client-sessions/grouping/stack-sessions-by.pipe';
import { SessionStackerComponent } from './components/available-client-sessions/grouping/session-stacker';
import { NormalizeSessionPipe } from '@app/modules/client-sessions/components/available-client-sessions/grouping/normalize-session.pipe';
import { ServiceLabelModule } from '@app/shared/components/service-label/service-label.module';
import { SessionWithParentGroupNamePipe } from './_private';
import { ClientSessionPropertyFactory } from './services/client-session-property-factory.service';

@NgModule({
  declarations: [
    // components
    AvailableClientSessionsComponent,
    PastClientSessionsComponent,
    UpcomingClientSessionsComponent,
    SessionStackerComponent,
    // pipes
    GroupSessionsByPipe,
    NormalizeSessionsPipe,
    NormalizeSessionPipe,
    StackSessionsByPipe,
    SessionWithParentGroupNamePipe
  ],
  imports: [
    CommonModule,
    ClientSessionModule,
    PuiDataBoxModule,
    SortOnlySessionDateModule,
    PuiDividerModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiLetModule,
    PuiIconModule,
    PuiCollapseModule,
    ServiceLabelModule,
    PuiPaperModule
  ],
  exports: [UpcomingClientSessionsComponent, AvailableClientSessionsComponent, PastClientSessionsComponent],
  providers: [
    ClientSessionPropertyFactory,
    {
      provide: SESSION_PROPERTY_FACTORY,
      useExisting: ClientSessionPropertyFactory,
      deps: [RuntimeConfigService, LocaleService]
    }
  ]
})
export class ClientSessionsModule {}
