import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalProgramService } from '../types';

@Injectable()
export class ProgramInstructorsServicesService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _services$ = new BehaviorSubject<LocalProgramService[]>([]);

  get services$(): Observable<LocalProgramService[]> {
    return this._services$.asObservable();
  }

  setServices(services: LocalProgramService[]): void {
    this._services$.next(services);
  }
}
