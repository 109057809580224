import { DateTime } from 'luxon';
import { combineLatest, Observable } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, Optional } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { LimitBookingFrequency, LimitFutureBookings } from '@app/screens/guide/guide-sessions-templates/types';
import { integerValidator } from '@app/shared/form-validators/integer.validator';
import { PUI_IS_MOBILE, PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';

import { bookingFrequencyOptions, bufferOptions, futureFrequencyOptions, minimumNoticeOptions } from './data';
import {
  BookingLimit,
  DayType,
  LimitFutureBookingsForm,
  PeriodType,
  RestrictionsForm,
  RestrictionsFormFields,
  TimeMeasureType
} from './types';

function splitExpirationPeriod(expirationPeriod: string): { count: number; type: string } | null {
  const regex = /(\d+)(\D+)/;
  const match = expirationPeriod.match(regex);

  if (match) {
    const count = match[1];
    const type = match[2];
    return { count: +count, type };
  } else {
    return null;
  }
}

function createForm(fb: FormBuilder): FormGroup {
  return fb.group({
    beforeEventBuffer: [bufferOptions[0].value],
    afterEventBuffer: [bufferOptions[0].value],
    minimumBookingNotice: [
      0,
      [
        customValidatorWrapper((control: AbstractControl) => {
          const minimumBookingNotice = Number(control.value);
          const isPositiveInteger =
            typeof minimumBookingNotice === 'number' &&
            Number.isInteger(minimumBookingNotice) &&
            minimumBookingNotice >= 0;

          if (!isPositiveInteger) {
            return {
              required: true
            };
          }
          return null;
        }, `Value must be greater than or equal to 0.`),
        integerValidator(`Value must be greater than or equal to 0.`)
      ]
    ],
    slotInterval: [
      1,
      [
        customValidatorWrapper((control: AbstractControl) => {
          const slotInterval = Number(control.value);
          const isPositiveInteger =
            typeof slotInterval === 'number' &&
            Number.isInteger(slotInterval) &&
            slotInterval >= 15 &&
            slotInterval <= 120;

          if (!isPositiveInteger) {
            return {
              required: true
            };
          }
          return null;
        }, `Value must be greater than 15 and less than 120 or equal.`),
        integerValidator(`Value must be greater than 15 and less than 120 or equal.`)
      ]
    ],
    limitBookingFrequency: [null],
    limitFutureBookings: [null],
    expirationPeriod: [null]
  });
}

@Component({
  selector: 'app-restrictions-form',
  templateUrl: './restrictions-form.component.html',
  styleUrls: ['./restrictions-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RestrictionsFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RestrictionsFormComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestrictionsFormComponent implements ControlValueAccessor, Validator {
  @Input()
  appearance: 'full' | 'buffer-times' | 'package' | 'program' = 'full';

  RestrictionsFormFields = RestrictionsFormFields;
  bufferOptions = bufferOptions;
  minimumNoticeOptions = minimumNoticeOptions;
  futureFrequencyOptions = futureFrequencyOptions;
  bookingFrequencyOptions = bookingFrequencyOptions;
  periodType = PeriodType;

  form: FormGroup = createForm(this.fb);

  minimumNoticeMask = [/\d/, /\d/, /\d/];
  timeSlotMask = [/\d/, /\d/, /\d/];
  limitsMask = [/\d/, /\d/];
  limitsFutureMask = [/\d/, /\d/, /\d/];
  expirationMask = [/\d/, /\d/, /\d/];

  limitFutureBookingsFormGroup: FormGroup = this.fb.group({
    periodType: [PeriodType.ROLLING],
    periodDays: [
      1,
      [
        customValidatorWrapper((control: AbstractControl) => {
          const periodDays = +control.value;
          const limitFutureBookingsEnabled = !!this.form?.controls.limitFutureBookings?.value;
          const periodType = control.parent?.get('periodType')?.value as PeriodType;
          const isPositiveInteger = Number.isInteger(periodDays) && periodDays > 0;

          if (limitFutureBookingsEnabled && periodType === PeriodType.ROLLING && !isPositiveInteger) {
            return {
              required: true
            };
          }

          return null;
        }, `Value must be greater than or equal to 1.`),
        integerValidator(`Value must be greater than or equal to 1.`)
      ]
    ],
    periodDaysType: [futureFrequencyOptions[0].value],
    periodStartDate: [new Date()],
    periodEndDate: [new Date()]
  });

  minimumNoticeMeasureControl: FormControl = this.fb.control(this.minimumNoticeOptions[0].value);

  expirationPeriodForm: FormGroup = this.fb.group({
    count: [
      1,
      [
        customValidatorWrapper((control: AbstractControl) => {
          const expirationPeriodCount = Number(control.value);
          const isExpirationPeriodEnabled = !!this.form.controls.expirationPeriod?.value;

          const isPositiveInteger = typeof Number.isInteger(expirationPeriodCount) && expirationPeriodCount > 0;

          if (isExpirationPeriodEnabled && !isPositiveInteger) {
            return {
              required: true
            };
          }
          return null;
        }, `Value must be greater than or equal to 1.`),
        integerValidator(`Value must be greater than or equal to 1.`)
      ]
    ],
    type: ['month']
  });

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>,
    private readonly fb: FormBuilder,
    @Optional() readonly drawerRef?: PuiDrawerRef
  ) {
    this.limitFutureBookingsFormGroup
      .get('periodStartDate')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((periodStartDate: Date) => {
        const periodEndDate = this.limitFutureBookingsFormGroup.get('periodEndDate')?.value;

        if (periodStartDate > periodEndDate) {
          this.limitFutureBookingsFormGroup.get('periodEndDate')?.setValue(periodStartDate);
        }
      });
  }

  limitFutureBookingToggle(checked: boolean): void {
    const limitFutureBookingsControl = this.form.controls.limitFutureBookings;
    if (checked) {
      limitFutureBookingsControl.setValue(this.limitFutureBookingsFormGroup.value);
    } else {
      limitFutureBookingsControl.setValue(null);
    }
  }

  expirationPeriodToggle(checked: boolean): void {
    const expirationPeriodControl = this.form.controls.expirationPeriod;
    const count = this.expirationPeriodForm.controls.count.value;
    const type = this.expirationPeriodForm.controls.type.value;

    if (checked) {
      expirationPeriodControl.setValue(`${count}${type}`);
    } else {
      expirationPeriodControl.setValue(null);
    }

    this.expirationPeriodForm.controls.count.updateValueAndValidity();
  }

  onTouched: () => void = () => {};

  writeValue(value: RestrictionsForm): void {
    if (value) {
      let minimumBookingNotice = value.minimumBookingNotice;
      let minimumNoticeMeasure = TimeMeasureType.MINUTES;

      if (value.minimumBookingNotice % 60 === 0) {
        const isDay = value.minimumBookingNotice % (60 * 24) === 0;
        minimumBookingNotice = isDay ? value.minimumBookingNotice / (60 * 24) : value.minimumBookingNotice / 60;
        minimumNoticeMeasure = isDay ? TimeMeasureType.DAYS : TimeMeasureType.HOURS;
      }

      this.minimumNoticeMeasureControl.setValue(minimumNoticeMeasure);

      if (value.limitFutureBookings) {
        const byDays = value.limitFutureBookings[PeriodType.ROLLING];
        const byRange = value.limitFutureBookings[PeriodType.RANGE];

        this.limitFutureBookingsFormGroup.setValue({
          periodType: byDays ? PeriodType.ROLLING : PeriodType.RANGE,
          periodDays: byDays ? value.limitFutureBookings[PeriodType.ROLLING]!.count : 1,
          periodDaysType: byDays ? value.limitFutureBookings[PeriodType.ROLLING]?.type : DayType.CALENDAR,
          periodStartDate: byRange
            ? DateTime.fromISO(value.limitFutureBookings[PeriodType.RANGE]![0]).toJSDate()
            : new Date(),
          periodEndDate: byRange
            ? DateTime.fromISO(value.limitFutureBookings[PeriodType.RANGE]![1]).toJSDate()
            : new Date()
        });
      }

      if (value.expirationPeriod) {
        const expirationPeriod = splitExpirationPeriod(value.expirationPeriod);
        if (expirationPeriod) {
          this.expirationPeriodForm.setValue({
            count: expirationPeriod.count,
            type: expirationPeriod.type
          });
        }
      }

      this.form.patchValue({
        minimumBookingNotice,
        limitFutureBookings: value.limitFutureBookings,
        limitBookingFrequency: value.limitBookingFrequency || null,
        afterEventBuffer: value.afterEventBuffer || 0,
        beforeEventBuffer: value.beforeEventBuffer || 0,
        slotInterval: value.slotInterval,
        expirationPeriod: value.expirationPeriod
      } as RestrictionsForm);
    }
  }

  registerOnChange(fn: (value: RestrictionsForm) => void): void {
    combineLatest([
      this.form.valueChanges.pipe(startWith(this.form.value as RestrictionsForm)),
      this.minimumNoticeMeasureControl.valueChanges.pipe(
        startWith(this.minimumNoticeMeasureControl.value as TimeMeasureType)
      ),
      this.limitFutureBookingsFormGroup.valueChanges.pipe(
        startWith(this.limitFutureBookingsFormGroup.value as LimitFutureBookingsForm)
      ),
      this.expirationPeriodForm.valueChanges.pipe(
        startWith(this.expirationPeriodForm.value as { count: number; type: string })
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([restrictions, minimumNoticeMeasure, limitFutureBookingsFormValue, expirationPeriodFormValue]: [
          RestrictionsForm,
          TimeMeasureType,
          LimitFutureBookingsForm,
          { count: number; type: string }
        ]) => {
          const bookingLimit = restrictions.limitBookingFrequency as BookingLimit[];
          let minimumBookingNotice = Number(restrictions.minimumBookingNotice);
          let limitBookingFrequency: LimitBookingFrequency | null;
          let limitFutureBookings: LimitFutureBookings | null;

          if (minimumNoticeMeasure !== TimeMeasureType.MINUTES) {
            const isDay = minimumNoticeMeasure === TimeMeasureType.DAYS;
            minimumBookingNotice = isDay
              ? restrictions.minimumBookingNotice * (60 * 24)
              : restrictions.minimumBookingNotice * 60;
          }

          if (!bookingLimit || bookingLimit.length === 0) {
            limitBookingFrequency = null;
          } else {
            limitBookingFrequency = Array.isArray(bookingLimit)
              ? bookingLimit.reduce(
                  (acc, next) => ({ ...acc, [next.bookingLimitsType]: Number(next.bookingLimitsNum) }),
                  {} as LimitBookingFrequency
                )
              : bookingLimit;
          }

          if (restrictions && restrictions.limitFutureBookings) {
            limitFutureBookings = {
              [limitFutureBookingsFormValue.periodType]:
                limitFutureBookingsFormValue.periodType === PeriodType.ROLLING
                  ? {
                      count: Number(limitFutureBookingsFormValue.periodDays) || 0,
                      type: limitFutureBookingsFormValue.periodDaysType || DayType.CALENDAR
                    }
                  : [
                      limitFutureBookingsFormValue.periodStartDate?.toISOString(),
                      DateTime.fromJSDate(limitFutureBookingsFormValue.periodEndDate!)
                        .plus({ day: 1 })
                        .minus({ second: 1 })
                        .toJSDate()
                        .toISOString()
                    ]
            };
          } else {
            limitFutureBookings = null;
          }

          const expirationPeriod = !this.form.controls.expirationPeriod?.value
            ? null
            : `${expirationPeriodFormValue.count}${expirationPeriodFormValue.type}`;

          fn({
            minimumBookingNotice,
            limitBookingFrequency,
            limitFutureBookings,
            slotInterval: Number(restrictions.slotInterval),
            beforeEventBuffer: Number(restrictions.beforeEventBuffer) || 0,
            afterEventBuffer: Number(restrictions.afterEventBuffer) || 0,
            expirationPeriod
          } as RestrictionsForm);
        }
      );
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
      this.minimumNoticeMeasureControl.disable();
      this.form.get('limitBookingFrequency')?.disable();
      this.limitFutureBookingsFormGroup.disable();
      this.expirationPeriodForm.disable();
    } else {
      this.form.enable();
      this.minimumNoticeMeasureControl.enable();
      this.form.get('limitBookingFrequency')?.enable();
      this.limitFutureBookingsFormGroup.enable();
      this.expirationPeriodForm.enable();
    }
  }

  validate(): ValidationErrors | null {
    const limitBookingFrequencyErrors = this.form.get('limitBookingFrequency')?.errors;
    const periodDaysErrors = this.limitFutureBookingsFormGroup.get('periodDays')?.errors;
    const minimumBookingNotice = this.form.get('minimumBookingNotice')?.errors;
    const slotInterval = this.form.get('slotInterval')?.errors;
    const expirationPeriodCount = this.expirationPeriodForm.get('count')?.errors;

    return {
      ...limitBookingFrequencyErrors,
      ...periodDaysErrors,
      ...minimumBookingNotice,
      ...slotInterval,
      ...expirationPeriodCount
    };
  }

  togglePeriodType(): void {
    this.limitFutureBookingsFormGroup.get('periodDays')?.updateValueAndValidity();
  }

  close() {
    this.drawerRef?.close();
  }
}
