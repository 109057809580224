import { Component, Output, EventEmitter } from '@angular/core';

import { CurrentPaymentComponent } from '@app/modules/current-payment/components/current-payment/current-payment.component';

import { CurrentPaymentService } from '../../services/current-payment.service';
import { PaymentType } from '../../types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-pay-with-wallet',
  templateUrl: './pay-with-wallet.component.html',
  styleUrls: ['./pay-with-wallet.component.scss']
})
export class PayWithWalletComponent extends CurrentPaymentComponent {
  @Output()
  submitEvent = new EventEmitter();

  constructor(private _currentPayment: CurrentPaymentService) {
    super(PaymentType.WALLET, _currentPayment);
  }

  handleWalletSelected(): void {
    this.submitEvent.emit();
  }
}
