import { BookingLimitsType, DayType, RestrictionsFormFields, RestrictionsFormOption } from './types';

export const bufferOptions: RestrictionsFormOption[] = [
  { value: '0', label: `No buffer time` },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
  { value: '120', label: '120' }
];

export const minimumNoticeOptions: RestrictionsFormOption[] = [
  { value: 'minutes', label: 'Minutes' },
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' }
];

export const bookingFrequencyOptions: RestrictionsFormOption[] = [
  { value: BookingLimitsType.PER_DAY, label: 'per day' },
  { value: BookingLimitsType.PER_WEEK, label: 'per week' },
  { value: BookingLimitsType.PER_MONTH, label: 'per month' },
  { value: BookingLimitsType.PER_YEAR, label: 'per year' }
];

export const futureFrequencyOptions: RestrictionsFormOption[] = [
  { value: DayType.CALENDAR, label: 'calendar days' },
  { value: DayType.BUSINESS, label: 'business days' }
];

export const fullAppearance: { [key in RestrictionsFormFields]: boolean } = {
  [RestrictionsFormFields.FORM_HEADER_TITLE]: true,
  [RestrictionsFormFields.BEFORE_EVENT_BUFFER]: true,
  [RestrictionsFormFields.AFTER_EVENT_BUFFER]: true,
  [RestrictionsFormFields.MINIMUM_BOOKING_NOTICE]: true,
  [RestrictionsFormFields.LIMIT_BOOKING_FREQUENCY]: true,
  [RestrictionsFormFields.LIMIT_FUTURE_BOOKINGS]: true,
  [RestrictionsFormFields.SLOT_INTERVAL]: true,
  [RestrictionsFormFields.EXPIRATION_PERIOD]: false
};

export const bufferTimesAppearance: { [key in RestrictionsFormFields]: boolean } = {
  [RestrictionsFormFields.FORM_HEADER_TITLE]: true,
  [RestrictionsFormFields.BEFORE_EVENT_BUFFER]: true,
  [RestrictionsFormFields.AFTER_EVENT_BUFFER]: true,
  [RestrictionsFormFields.MINIMUM_BOOKING_NOTICE]: true,
  [RestrictionsFormFields.LIMIT_BOOKING_FREQUENCY]: false,
  [RestrictionsFormFields.LIMIT_FUTURE_BOOKINGS]: false,
  [RestrictionsFormFields.SLOT_INTERVAL]: false,
  [RestrictionsFormFields.EXPIRATION_PERIOD]: false
};

export const packageAppearance: { [key in RestrictionsFormFields]: boolean } = {
  [RestrictionsFormFields.FORM_HEADER_TITLE]: false,
  [RestrictionsFormFields.BEFORE_EVENT_BUFFER]: true,
  [RestrictionsFormFields.AFTER_EVENT_BUFFER]: true,
  [RestrictionsFormFields.MINIMUM_BOOKING_NOTICE]: true,
  [RestrictionsFormFields.LIMIT_BOOKING_FREQUENCY]: true,
  [RestrictionsFormFields.LIMIT_FUTURE_BOOKINGS]: false,
  [RestrictionsFormFields.SLOT_INTERVAL]: true,
  [RestrictionsFormFields.EXPIRATION_PERIOD]: true
};

export const programAppearance: { [key in RestrictionsFormFields]: boolean } = {
  [RestrictionsFormFields.FORM_HEADER_TITLE]: false,
  [RestrictionsFormFields.BEFORE_EVENT_BUFFER]: true,
  [RestrictionsFormFields.AFTER_EVENT_BUFFER]: true,
  [RestrictionsFormFields.MINIMUM_BOOKING_NOTICE]: true,
  [RestrictionsFormFields.LIMIT_BOOKING_FREQUENCY]: false,
  [RestrictionsFormFields.LIMIT_FUTURE_BOOKINGS]: true,
  [RestrictionsFormFields.SLOT_INTERVAL]: true,
  [RestrictionsFormFields.EXPIRATION_PERIOD]: false
};
