<app-service-badge
  *ngIf="clientSession.session.serviceParent?.id"
  [service]="clientSession.session.serviceParent"></app-service-badge>

<div class="main-content">
  <app-user-info
    class="session-lg-left-col user-info"
    [user]="clientSession.guide"
    [uiPayload]="chatLink"
    [routerLink]="['/', clientSession.guide.namedUrl]"></app-user-info>

  <ng-template #chatLink let-user="$implicit">
    <a [routerLink]="['../chat', buildDirectChatId(user)]" class="user-chat-link">
      <span class="user-chat-link-icon"></span>
      <span class="user-chat-link-text" i18n="@@sendMsg">Send message</span>
    </a>
  </ng-template>

  <app-client-dashboard-session-details
    [clientSession]="clientSession"
    class="session-lg-center-col dashboard-session-info with-separator"></app-client-dashboard-session-details>

  <ng-container *ngIf="isSessionApproved">
    <div class="session-lg-right-col with-separator humanized-session-start-date">
      <app-time-to-session-start [sessionDateStart]="clientSession?.session?.dateStart"></app-time-to-session-start>
      <div class="join-waiting-room-link-wrapper" *ngIf="!clientSession?.session?.isOfflineConnectionType">
        <a [routerLink]="['/waiting-room']" i18n="@@goToSessionRoom">Go to session room</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="canBookAgain">
    <div class="session-lg-right-col with-separator">
      <app-button className="btn-link" (click)="reorder()" i18n="@@bookAgain">Book again</app-button>
    </div>
  </ng-container>

  <app-actions-menu [actions]="actions" (select)="emitSessionAction($event)" class="session-actions"></app-actions-menu>
</div>
