/**
 * Foreground palette for light themes.
 */
const LIGHT_THEME_FOREGROUND_PALETTE: { [key: string]: string } = {
  base: '#32495b',
  divider: '#c2c8ce',
  dividers: '#c2c8ce',
  disabled: '#adb6bd',
  'disabled-button': 'rgba(black, 0.26)',
  'disabled-text': '#adb6bd',
  elevation: '#000000',
  'hint-text': '#adb6bd',
  'secondary-text': '#5b6d7c',
  icon: '#8196a7',
  icons: '#8196a7',
  text: '#32495b',
  'light-text': '#84929D',
  'banner-text': '#0389a4'
};

export { LIGHT_THEME_FOREGROUND_PALETTE };
