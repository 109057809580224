import { Pipe, PipeTransform } from '@angular/core';
import { ICommunityConfig } from '@cnf/types';
import { IGradient } from '@app/screens/our-community/interfaces';

@Pipe({
  name: 'gradient'
})
export class GradientPipe implements PipeTransform {
  transform(configuration: ICommunityConfig): IGradient {
    const { gradientStart: start, gradientEnd: end } = configuration;
    // @ts-expect-error TS2322
    return { start, end };
  }
}
