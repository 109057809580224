import { Component, Output, Input, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CurrentPaymentComponent } from '@app/modules/current-payment/components/current-payment/current-payment.component';
import { NotificationModalComponent } from '@app/shared/components/notification-modal/notification-modal.component';

import { CurrentPaymentService } from '../../services/current-payment.service';
import { PaymentType } from '../../types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-pay-with-link',
  templateUrl: './pay-with-link.component.html',
  styleUrls: ['./pay-with-link.component.scss']
})
export class PayWithLinkComponent extends CurrentPaymentComponent {
  // @ts-expect-error TS2564
  @Input() paymentLink: string;

  @Output()
  submitEvent = new EventEmitter<{ id: string }>();

  constructor(private _currentPayment: CurrentPaymentService, private readonly _modal: NgbModal) {
    super(PaymentType.PAYMENT_LINK, _currentPayment);
  }

  handleExternalLinkClick(): void {
    const { componentInstance } = this._modal.open(NotificationModalComponent);
    componentInstance.type = 'paymentWithOuterLink';
    this.submitEvent.emit();
  }
}
