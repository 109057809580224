import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { WorkspacesMenuComponent } from '@app/modules/workspaces/components/workspaces-menu/workspaces-menu.component';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { FloatingPopoverService } from '@app/modules/floating-popover/floating-popover.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StyleBreakpointsProviderService } from '@app/modules/ui-kit/_base/_common/screen/services';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { startWith, take, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { IWorkspace } from '@app/modules/workspaces/types';
import { MembershipService } from '@app/core/membership/membership.service';
import { IMembershipPlan } from '@app/core/membership/types';
import { ISidenavHeaderViewHost } from '@app/modules/sidenav/interfaces';
import { SIDENAV_SLOT_PARENT } from '@app/modules/sidenav/tokens/slot-parent';
import { ISidenavWorkspaceSwitchView } from '@app/modules/sidenav/components/sidenav-workspace-switch';
import { SignUpAsProfiComponent } from '@app/modules/sidenav/components/sidenav-workspace-switch/view/sign-up-as-profi.component';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-sidenav-workspace-switch-button',
  templateUrl: 'workspace-switch-button.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'd-flex align-items-center'
  },
  providers: [UiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavWorkspaceSwitchButtonComponent implements ISidenavWorkspaceSwitchView, OnInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() popup: ComponentType<any>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isMobile: boolean;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _workspaces: IWorkspace[];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeWorkspace: IWorkspace;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userPlan: IMembershipPlan | null;

  constructor(
    private readonly _screenService: StyleBreakpointsProviderService,
    private readonly _bottomSheet: MatBottomSheet,
    private readonly _floatingPopover: FloatingPopoverService,
    private readonly _workspacesService: WorkspacesService,
    private readonly _destroy$: UiDestroyService,
    private readonly _membershipService: MembershipService,
    @Optional() @Inject(SIDENAV_SLOT_PARENT) public parent?: ISidenavHeaderViewHost
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this._screenService.isMobile$.pipe(takeUntil(this._destroy$)).subscribe(isMobile => {
      this._isMobile = isMobile;
    });

    combineLatest([
      this._workspacesService.workspaces$.pipe(startWith(null)),
      this._workspacesService.activeWorkspace$.pipe(startWith(null))
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([workspaces, activeWorkspace]) => {
        // @ts-expect-error TS2322
        this._workspaces = workspaces;
        // @ts-expect-error TS2322
        this._activeWorkspace = activeWorkspace;
      });
  }

  openWorkspaceMenu(origin: HTMLDivElement): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component: any = this._activeWorkspace ? WorkspacesMenuComponent : SignUpAsProfiComponent;

    const componentData = {
      workspaces: this._workspaces,
      activeWorkspace: this._activeWorkspace
    };

    if (this._isMobile) {
      this._bottomSheet.open(component, {
        hasBackdrop: true,
        disableClose: false,
        data: { ...componentData },
        panelClass: 'workspaces-menu-panel__mobile'
      });
    } else {
      const popRef = this._floatingPopover.open({
        origin,
        content: component,
        componentData,
        panelClass: 'workspaces-menu-panel',
        positionPair: [
          new ConnectionPositionPair(
            { originX: 'end', originY: 'center' },
            { overlayX: 'start', overlayY: 'top' },
            16,
            -5
          )
        ]
      });
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      this._floatingPopover.closeModalSource.pipe(take(1)).subscribe(() => {
        popRef.close();
      });
    }
  }
}
