import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialGoogleButtonComponent } from './social-google-button.component';

@NgModule({
  declarations: [SocialGoogleButtonComponent],
  imports: [CommonModule],
  exports: [SocialGoogleButtonComponent]
})
/**
 * @deprecated
 * This is a quick solution for issue https://profi-io.atlassian.net/browse/PR-2890.
 * Soon we add this component as part of the library and replace this module.
 * https://profi-io.atlassian.net/browse/PR-2894
 * In charge @oleg.m
 */
export class SocialGoogleButtonModule {}
