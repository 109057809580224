enum WorkspaceNotifications {
  MEMBER_JOINED = 'Member joined',
  SERVICE_CREATED = 'Service created',
  SERVICE_BOOKED = 'Service booked',
  HOST_ADDED = 'Host added',
  MEMBER_INVITED = 'Member invited',
  CLIENT_ASSIGNED = 'Client assigned',
  MODULE_HOST_ADDED = 'Module host added',
  WORKSPACE_ADMIN_OFFER_DECLINED_BY_CLIENT = 'Admin offer for team member declined by client',
  WORKSPACE_OFFER_CANCELED_BY_ADMIN = 'Offer/Session canceled by admin',
  WORKSPACE_SESSION_RESCHEDULED_BY_ADMIN = 'Offer/Session rescheduled by admin',
  WORKSPACE_SESSION_RESCHEDULED_BY_GUIDE = 'Offer/Session rescheduled by guide',
  WORKSPACE_ADMIN_SCHEDULED_SESSION_CANCELED_BY_CLIENT = 'Admin scheduled session canceled by client',
  WORKSPACE_ADMIN_SCHEDULED_SESSION_ACCEPTED_BY_CLIENT = 'Admin scheduled session accepted by client',
  WORKSPACE_ADMIN_SESSION_OFFER_CREATED = 'Admin created a session offer',
  WORKSPACE_GUIDE_SESSION_OFFER_CREATED = 'Guide created a session offer',
  WORKSPACE_SESSION_CANCELED_BY_HOST = 'Host cancelled session'
}

export { WorkspaceNotifications };
