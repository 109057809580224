import { Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

import { DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER } from '../../services';
import { SessionTemplateEditorService } from '../../services/session-template-editor.service';

@Component({
  selector: 'app-session-template-editor',
  templateUrl: './session-template-editor.component.html',
  styleUrls: ['../../../../../../scss/guide-services/main-container.scss', './session-template-editor.component.scss'],
  providers: [DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER, SessionTemplateEditorService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTemplateEditorComponent implements OnInit, OnDestroy {
  readonly GuideServiceTypes = GuideServiceTypes;

  private destroy$: Subject<void> = new Subject<void>();

  isPlatformAdmin = false;

  constructor(
    public editor: SessionTemplateEditorService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly workspace: WorkspacesService,
    private readonly authService: AuthService
  ) {
    this.isPlatformAdmin = this.authService.isPlatformAdmin();
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        map(params => params.get('id')),
        switchMap(templateId =>
          templateId !== null ? this.editor.refresh$(+templateId) : throwError('Invalid templateId')
        ),
        catchError(() => this.router.navigate(['/not-found'])),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.editor.close();
  }
}
