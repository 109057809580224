import { ITag } from '../interfaces/tag';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ICategorizedTags {
  [prop: string]: {
    name: string;
    items: ISelectedTag[];
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISelectedTag extends ITag {
  selected: boolean;
}

export function categorizeTags(tags: ITag[]): ICategorizedTags {
  const [tagsCategories, uncategorizedTags] = tags.reduce(
    ([tagsCategoriesList, uncategorizedTagsList], tag) =>
      tag.parents
        ? [tagsCategoriesList, [...uncategorizedTagsList, { ...tag, selected: false }]]
        : [[...tagsCategoriesList, tag], uncategorizedTagsList],
    [[], []]
  );
  const categoriesMap = tagsCategories.reduce(
    // @ts-expect-error TS2322
    (categories, { id, name }) => ({ ...categories, [id]: { name, items: [] } }),
    {}
  );
  // @ts-expect-error TS2322
  return uncategorizedTags.reduce((categories, tag) => {
    // @ts-expect-error TS2339
    tag.parents.forEach((id: number) => {
      // @ts-expect-error TS2339
      categories[id].items.push(tag);
    });
    return categories;
    // @ts-expect-error TS2345
  }, categoriesMap);
}

export function splitTags(tags: ITag[]): [ITag[], ITag[]] {
  return tags.reduce(
    ([tagsCategories, uncategorizedTags], tag) =>
      tag.parents
        ? [tagsCategories, [...uncategorizedTags, { ...tag, selected: false }]]
        : [[...tagsCategories, tag], uncategorizedTags],
    [[], []]
  );
}
