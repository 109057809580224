import { isPlatformBrowser } from '@angular/common';
import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

export const IS_IFRAME = new InjectionToken('IsIframe');

export function isIframe(platformId: Object): boolean {
  try {
    return isPlatformBrowser(platformId) ? window.self !== window.top : false;
    // eslint-disable-next-line id-length
  } catch (e) {
    return true;
  }
}

export const IsIframeProvider: FactoryProvider = {
  provide: IS_IFRAME,
  useFactory: isIframe,
  deps: [PLATFORM_ID]
};
