import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IGuideServiceGroupItem } from '../../types';

const programGroupItemTypeClasses = {
  [GuideServiceTypes.SESSION]: 'guide-program-service-group-item-session',
  [GuideServiceTypes.GROUP_SESSION]: 'guide-program-service-group-item-group-session'
};

@Component({
  selector: 'app-guide-program-service-group-item',
  templateUrl: './guide-program-service-group-item.component.html',
  styleUrls: ['./guide-program-service-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideProgramServiceGroupItemComponent {
  @Input()
  // @ts-expect-error TS2564
  service: IGuideServiceGroupItem;

  @HostBinding('class')
  get classes(): string {
    return [
      'guide-service-group-item',
      'guide-program-service-group-item',
      // @ts-expect-error TS7053
      (this.service && programGroupItemTypeClasses[this.service.type]) || ''
    ].join(' ');
  }

  readonly GuideServiceTypes = GuideServiceTypes;
}
