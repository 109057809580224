<button
  data-qa-id="sidenav-workspace-switch-button"
  pui-icon-button
  #button="cdkOverlayOrigin"
  cdk-overlay-origin
  class="sidenav-workspace-switch-button"
  size="s"
  shape="square"
  appearance="flat"
  (click)="openWorkspaceMenu(parent?.getOrigin() || button.elementRef.nativeElement)">
  <pui-icon size="xs" svgIcon="pui:chevron-right"></pui-icon>
</button>
