<div class="schedule-container">
  <div *ngFor="let dateSlots of visibleTimeSlots" class="date-schedule-time-slots-container">
    <h6 class="schedule-date">{{ dateSlots.label }}</h6>
    <app-time-picker-grid
      [(time)]="time"
      (timeChange)="selectTime($event)"
      [items]="dateSlots.items"
      [isModal]="true"
      class="time-picker-container"></app-time-picker-grid>
  </div>
</div>

<div class="show-more-btn-container" *ngIf="canShowMore">
  <button type="button" (click)="showMoreItems()" class="btn btn-link show-more-btn">
    <ng-container i18n="@@showMoreBtn">Show more</ng-container>
  </button>
</div>

<div class="timezone-container">
  <label for="timezone_picker" i18n="@@timeZoneColon">Time zone:</label>
  <ng-select
    [items]="timezones"
    [(ngModel)]="timezone"
    [searchFn]="searchTimezone"
    [clearable]="timezoneClearable"
    [virtualScroll]="true"
    (change)="timezoneChange.emit(timezone)"
    bindLabel="name"
    bindValue="value"
    class="timezone-picker"
    id="timezone_picker"
    data-qa-id="multi-date-schedule-timezone-select"></ng-select>
</div>
