import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject, timer } from 'rxjs';
import { finalize, map, takeWhile } from 'rxjs/operators';
import { WindowService } from '@app/core/window/window.service';
import { GLOBAL_WINDOW } from '../browser-window/window-provider';

@Injectable()
export class PostPopupWindowService implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _popup: Window;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _postMessageType: string;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _postMessageOrigin: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _renderer: Renderer2;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _windowTitle: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isPopupClosedProgramatically = false;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _state$: Subject<{ error?: any; payload?: any }>;

  constructor(
    private _newWindow: WindowService,
    @Inject(GLOBAL_WINDOW)
    private _browserWindow: Window,
    @Inject(DOCUMENT)
    private _document: Document,
    private _rendererFactory2: RendererFactory2
  ) {
    this._renderer = this._rendererFactory2.createRenderer(null, null);
    this._messageEventHandler = this._messageEventHandler.bind(this);
  }

  ngOnDestroy(): void {
    this._reset();
  }

  openPopup$<T>(
    title: string,
    endpoint: string,
    formValues: T,
    postMessageOrigin: string,
    postMessageType: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    this._reset();
    this._initializeLocals(title, postMessageOrigin, postMessageType);

    const { body } = this._document;
    const form = this._createHiddenForm(endpoint, formValues);
    this._renderer.appendChild(body, form);
    this._openPopupWindow();
    if (this._popup) {
      form.submit();
    }
    this._renderer.removeChild(body, form);

    return this._state$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _createHiddenForm<T extends {}>(url: string, formValues: T): any {
    const form = this._renderer.createElement('form');
    this._renderer.setProperty(form, 'method', 'POST');
    this._renderer.setProperty(form, 'action', url);
    this._renderer.setProperty(form, 'target', this._windowTitle);

    Object.keys(formValues).forEach(paypalFormKey => {
      const tokenHolder = this._renderer.createElement('input');
      this._renderer.setProperty(tokenHolder, 'type', 'hidden');
      this._renderer.setProperty(tokenHolder, 'name', paypalFormKey);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._renderer.setProperty(tokenHolder, 'value', formValues[paypalFormKey]);
      this._renderer.appendChild(form, tokenHolder);
    });

    return form;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initializeLocals(title: string, postMessageOrigin: string, postMessageType: string): void {
    this._windowTitle = title;
    this._postMessageOrigin = postMessageOrigin;
    this._postMessageType = postMessageType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._state$ = new Subject<any>();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _messageEventHandler(event: any): void {
    if (event.origin !== this._postMessageOrigin) {
      return;
    }

    const message = event.data;
    if (message.type && message.type === this._postMessageType) {
      this._state$.next(message);
      this._isPopupClosedProgramatically = true;
      this._reset();
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _openPopupWindow(): void {
    this._browserWindow.addEventListener('message', this._messageEventHandler, false);
    this._popup = this._newWindow.openPopup('', this._windowTitle);

    timer(500, 1000)
      .pipe(
        map(() => (this._popup ? this._popup.closed : true)),
        takeWhile(isClosed => !isClosed),
        finalize(() => this._popupWindowClosed())
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _popupWindowClosed(): void {
    if (this._isPopupClosedProgramatically) {
      return;
    }

    this._browserWindow.removeEventListener('message', this._messageEventHandler);
    // @ts-expect-error TS2322
    this._popup = null;
    this._state$.error({ isAborted: true });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _reset(): void {
    if (this._popup) {
      this._browserWindow.removeEventListener('message', this._messageEventHandler);
      this._popup.close();
      // @ts-expect-error TS2322
      this._popup = null;
      this._state$.complete();
    }
  }
}
