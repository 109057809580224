import { NgModule } from '@angular/core';
import {
  PuiButtonModule,
  PuiIconModule,
  PuiInputModule,
  PuiInputRadioModule,
  PuiLetModule,
  PuiLinkModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { CommonModule } from '@angular/common';
import { GroupServicesModule } from '@app/modules/service-scheduling/components/group-services/group-services.module';
import { NewServiceButtonModule } from '@app/modules/new-service-button/new-service-button.module';
import { SelectedUsersCardModule } from '@libs/components/selected-users-card/selected-users-card.module';
import { ServiceSchedulingFiltersModule } from '@app/modules/service-scheduling/components/service-scheduling-filters/service-scheduling-filters.module';
import { BookingDateModule } from '@app/shared/pipes/booking-date/booking-date.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GuideCancelDrawerComponent } from '@app/screens/guide/guide-sessions/components/drawers/guide-cancel-drawer/guide-cancel-drawer.component';

@NgModule({
  imports: [
    PuiButtonModule,
    CommonModule,
    GroupServicesModule,
    NewServiceButtonModule,
    PuiIconModule,
    PuiLetModule,
    PuiTypographyModule,
    SelectedUsersCardModule,
    ServiceSchedulingFiltersModule,
    BookingDateModule,
    PuiLinkModule,
    PuiInputModule,
    ReactiveFormsModule,
    PuiInputRadioModule
  ],
  declarations: [GuideCancelDrawerComponent],
  exports: [GuideCancelDrawerComponent],
  providers: []
})
export class GuideCancelDrawerModule {}
