import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecoverService, FormService } from '@app/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../auth.scss', './forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]]
  });
  wrongEmail = false;

  constructor(
    private _recoverService: RecoverService,
    private _formService: FormService,
    private _fb: FormBuilder,
    private _router: Router
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onEmailChanged() {
    this.wrongEmail = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  sendInstructions() {
    if (this._formService.markInvalidForm(this.forgotPasswordForm)) {
      return;
    }

    this.wrongEmail = false;
    this._recoverService
      .createHash(this.forgotPasswordForm.value.email)
      .pipe(
        catchError(err => {
          // eslint-disable-next-line no-constant-condition
          if ((err.status = 404)) {
            this.wrongEmail = true;
          }

          return throwError(err);
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => this._router.navigate(['/auth/forgot-password/sent']));
  }
}
