import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';
import { Session } from '@app/shared/interfaces/session';
import { map } from 'rxjs/operators';
import { SessionsService } from '@app/core/session/sessions.service';
import { ClientGuide } from '@app/core/users/types';
import { buildClientGuidesMap } from '@app/screens/client/client-dashboard/utils/transformers';
import { ClientGuidesService } from '@app/core/users/client-guides.service';

@Injectable()
export class AvailableSessionSchedulingService {
  // @ts-expect-error TS2564
  available$: Observable<IClientGuideSession[]>;
  // @ts-expect-error TS2564
  private clientGuidesMap$: Observable<{ [id: number]: ClientGuide }>;

  constructor(
    private readonly sessionsService: SessionsService,
    private readonly clientGuidesService: ClientGuidesService
  ) {
    this.initializeClientGuides();
    this.initializeSessions();
  }

  private initializeSessions(): void {
    this.available$ = this.sessionsService.initializeSessionObservable$(
      // ToDo I don't no why -(
      this.sessionsService.availableSessions$ as unknown as Observable<Session[]>,
      this.clientGuidesMap$
    );
  }

  private initializeClientGuides(): void {
    this.clientGuidesMap$ = this.clientGuidesService.users$.pipe(map(guides => buildClientGuidesMap(guides)));
  }
}
