import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';

// import { locale } from '@env/locale';

@Directive({
  selector: '[appCapturePlatformOgMetaClick]'
})
export class CapturePlatformOgMetaClickDirective {
  private locale: ILocale;

  @Input('appCapturePlatformOgMetaClick')
  // @ts-expect-error TS2564
  className: string;

  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  capturedClick = new EventEmitter<any>();

  @HostListener('click', ['$event'])
  // @ts-expect-error TS7006
  // eslint-disable-next-line id-length
  handleClick(e): void {
    if (this.className && this.doesClickTargetGenealogyContainClass(e.target, this.className)) {
      const anchor = this.findAnchor(e.target);

      if (anchor && anchor.protocol && anchor.host && anchor.protocol + '//' + anchor.host === this.locale.baseUrl) {
        e.preventDefault();
        e.stopPropagation();

        this.capturedClick.emit(e);
      }
    }
  }

  // @ts-expect-error TS7006
  private doesClickTargetGenealogyContainClass(element, className: string): boolean {
    if (element.classList.contains(className)) {
      return true;
    }

    if (!element.parentElement) {
      return false;
    }

    return this.doesClickTargetGenealogyContainClass(element.parentElement, className);
  }

  // @ts-expect-error TS7023
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private findAnchor(element) {
    if (element.nodeName && element.nodeName.toLowerCase() === 'a') {
      return element;
    }

    if (!element.parentElement) {
      return null;
    }

    return this.findAnchor(element.parentElement);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private _localeService: LocaleService) {
    this.locale = this._localeService.getLocale();
  }
}
