import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WorkspacesModule } from '@app/modules/workspaces/workspaces.module';
import { SharedModule } from '@app/shared';
import { HostPermissionPipeModule } from '@app/shared/pipes/host-permission/host-permission.module';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiIconModule,
  PuiLetModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { SelectedHostsComponent } from './selected-hosts.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    WorkspacesModule,
    PuiLetModule,
    PuiIconModule,
    PuiButtonModule,
    PuiAvatarModule,
    PuiTooltipModule,
    PuiTypographyModule,
    HostPermissionPipeModule
  ],
  declarations: [SelectedHostsComponent],
  exports: [SelectedHostsComponent]
})
export class SelectedHostsModule {}
