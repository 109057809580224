import { Guest } from '@app/screens/guide/guide-sessions/services/events/cancel-event.service';
import { GroupSession, SimpleSession } from '@app/shared/interfaces/session';
import { User } from '@app/shared/interfaces/user';

export enum ParticipantTypes {
  CLIENT = 'CLIENT',
  GUEST = 'GUEST'
}

export enum CancelationType {
  FOR_EVENT = 'FOR_EVENT',
  FOR_PARTICIPANT = 'FOR_PARTICIPANT'
}

export interface CancelSessionEventDrawerData {
  cancelationType: CancelationType;
  appearance?: {
    showCancelAllRecurringSwitcher: boolean;
    showMessageField: boolean;
  };
  predefinedData?: {
    cancelAllRecurring: boolean;
    message: string;
  };
  title?: string;
  participant?: User | Guest;
  participantType?: ParticipantTypes;
  session: SimpleSession | GroupSession;
}
