import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IGuideServiceGroup, IGuideServiceGroupItem } from '../../types';

@Component({
  selector: 'app-guide-package-service-group',
  templateUrl: './guide-package-service-group.component.html',
  styleUrls: ['./guide-package-service-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-package-service-group ui-app-guide-package-service-group'
  }
})
export class GuidePackageServiceGroupComponent {
  @Input()
  // @ts-expect-error TS2564
  serviceGroup: IGuideServiceGroup;

  @Output()
  serviceSelect = new EventEmitter<IGuideServiceGroupItem>();

  readonly GuideServiceTypes = GuideServiceTypes;
}
