import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { categorizeTags } from '@app/shared/utils/categorize-tags';
import { ProgramsRouteFilterAdapter } from './programs-route-filter-adapter.service';
import { MarketplaceProgramApiService } from './marketplace-program-api.service';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceProgramFilterService {
  constructor(
    private _filterRouteAdapter: ProgramsRouteFilterAdapter,
    private _marketplaceProgramApiService: MarketplaceProgramApiService
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getPractitioners$() {
    return this._filterRouteAdapter.filterParams$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mergeMap(({ practitioner }: any) => {
        return practitioner ? this._marketplaceProgramApiService.getGuidesShortInfoByIds$(practitioner) : of([]);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getPrices$() {
    return combineLatest([
      this._marketplaceProgramApiService.getProgramsPrices$(),
      this._filterRouteAdapter.filterParams$
    ]).pipe(
      map(([{ min, max }, { priceFrom, priceTo }]) => ({
        min,
        max,
        priceFrom: Number(priceFrom) || min || 0,
        priceTo: Number(priceTo) || max || 100
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getFiltersData$() {
    return combineLatest([
      this._marketplaceProgramApiService
        .loadOptions$()
        .pipe(map(({ languages, tags }) => ({ tags: categorizeTags(tags), languages }))),
      this._filterRouteAdapter.filterParams$
    ]);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getApproaches$() {
    return combineLatest([
      this._marketplaceProgramApiService
        .loadOptions$()
        .pipe(map(({ languages, tags }) => ({ tags: categorizeTags(tags), languages }))),
      this._filterRouteAdapter.filterParams$
    ]).pipe(map(([{ tags }, { approach }]) => ({ tags, approach: approach || [] })));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getIssues$() {
    return this.getFiltersData$().pipe(mergeMap(([{ tags }, { issue }]) => of({ tags, issue: issue || [] })));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getLanguages$() {
    return this.getFiltersData$().pipe(
      map(([{ languages }, { language: selectedLanguages }]) => ({
        languages,
        selectedLanguages: selectedLanguages || []
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getStartDate$() {
    return this._filterRouteAdapter.filterParams$.pipe(
      map(({ startDateFrom, startDateTo, startDateSelfPaced }) => ({
        // @ts-expect-error TS2345
        from: DateTime.fromISO(startDateFrom).isValid ? startDateFrom : '',
        // @ts-expect-error TS2345
        to: DateTime.fromISO(startDateTo).isValid ? startDateTo : '',
        selfPaced: !!startDateSelfPaced
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getSorting$() {
    return this._filterRouteAdapter.filterParams$.pipe(map(({ sorting }) => ({ sorting })));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setIssueFilterAlt(issues) {
    this._filterRouteAdapter.setFilterParams({
      issue: issues.length ? issues : null
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setApproachFilterAlt($approach) {
    this._filterRouteAdapter.setFilterParams({
      approach: $approach.length ? $approach : null
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setLanguageFilterAlt(language) {
    this._filterRouteAdapter.setFilterParams({
      language: language.length ? language : null
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setSortingFilterAlt(sorting) {
    this._filterRouteAdapter.setFilterParams({
      sorting
    });
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setPriceFilterAlter({ from, to }) {
    this._filterRouteAdapter.setFilterParams({
      priceFrom: from,
      priceTo: to
    });
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setStartDateFilterAlt({ from, to, selfPaced }) {
    this._filterRouteAdapter.setFilterParams({
      startDateFrom: from || null,
      startDateTo: to || null,
      startDateSelfPaced: selfPaced || null
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setPractitionerFilterAlt(practitioner) {
    this._filterRouteAdapter.setFilterParams({
      practitioner: practitioner.length ? practitioner : null
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetAllFilters() {
    return this._filterRouteAdapter.setFilterParams({
      practitioner: null,
      startDateFrom: null,
      startDateTo: null,
      startDateSelfPaced: null,
      priceFrom: null,
      priceTo: null,
      sorting: null,
      language: null,
      approach: null,
      issue: null
    });
  }
}
