import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractRangeSliderComponent } from './components/abstract-range-slider/abstract-range-slider.component';
import { MarketplaceRangeSliderComponent } from './components/marketplace-range-slider/marketplace-range-slider.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AbstractRangeSliderComponent, MarketplaceRangeSliderComponent],
  exports: [AbstractRangeSliderComponent, MarketplaceRangeSliderComponent]
})
export class RangeSliderModule {}
