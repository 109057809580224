import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogAuthorGuide, IArticleSelection, RecommendedArticle } from '../../types';

@Component({
  selector: 'app-blog-article-related-articles',
  templateUrl: './blog-article-related-articles.component.html',
  styleUrls: ['./blog-article-related-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleRelatedArticlesComponent {
  @Input()
  // @ts-expect-error TS2564
  articles: RecommendedArticle[];

  @Output()
  select = new EventEmitter<IArticleSelection>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitSelect(authorUrl: string, articleId: number) {
    this.select.emit({ authorUrl, articleId });
  }

  isArticleAuthorGuide(article: RecommendedArticle): boolean {
    return !!article && !!article.author && article.author instanceof BlogAuthorGuide;
  }
}
