export enum SubscriptionRecurrency {
  Month = 'month',
  Year = 'year',
  Week = 'week'
}

export enum SubscriptionType {
  Course = 'course',
  Package = 'package',
  Membership = 'membership'
}

export enum SubscriptionStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  CANCELED_BY_OWNER = 'canceled_by_owner',
  PAST_DUE = 'past_due',
  FAILED = 'failed',
  FINISHED = 'finished'
}

export enum DeactivationType {
  Now = 'now',
  OnSubscriptionPeriodEnd = 'onSubscriptionPeriodEnd',
  OnDate = 'onDate'
}

export enum SubscriptionLength {
  Infinite = 'Infinite',
  Finite = 'Finite'
}
