import { Inject, Pipe, PipeTransform } from '@angular/core';
import { GuideServiceParent } from '@app/modules/book-service';
import { SESSION_PROPERTY_FACTORY, SessionAdapter, SessionPropertyFactory } from '@libs/business/cdk/session';
import { ClientSession, ClientSessionAttribute } from '@libs/business/client/client-session';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';

@Pipe({
  name: 'normalizeSessions'
})
export class NormalizeSessionsPipe implements PipeTransform {
  constructor(
    @Inject(SESSION_PROPERTY_FACTORY)
    private factory: SessionPropertyFactory<IClientGuideSession, GuideServiceParent, ClientSessionAttribute>
  ) {}

  transform(sessions: IClientGuideSession[]): ClientSession<GuideServiceParent>[] {
    if (!sessions) {
      return [];
    }

    return sessions.map(session => new SessionAdapter(session, this.factory) as ClientSession<GuideServiceParent>);
  }
}
