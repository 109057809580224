import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticCopyTypes,
  AnalyticSourceTypes,
  AnalyticServiceTypes,
  InternalEvents
} from '@app/core/analytics/types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { SessionTemplateEditorService } from '../../services/session-template-editor.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-session-template-sharing',
  templateUrl: './session-template-sharing.component.html'
})
export class SessionTemplateSharingComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  // @ts-expect-error TS2564
  type: GuideServiceTypes;

  // @ts-expect-error TS7008
  link;

  private static resolveServiceType(serviceType: GuideServiceTypes): AnalyticServiceTypes {
    return serviceType === GuideServiceTypes.GROUP_SESSION
      ? AnalyticServiceTypes.GROUP_SESSION
      : AnalyticServiceTypes.INDIVIDUAL_SESSION;
  }

  constructor(
    private readonly editor: SessionTemplateEditorService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.editor.templateLink$.pipe(takeUntil(this.destroy$)).subscribe(link => (this.link = link));
    this.editor.template$.pipe(takeUntil(this.destroy$)).subscribe(template => (this.type = template.serviceType));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  linkCopied(copyType: AnalyticCopyTypes): void {
    this.editor.template$
      .pipe(
        take(1),
        map(template => ({
          price: template.price,
          type: SessionTemplateSharingComponent.resolveServiceType(template.serviceType)
        })),
        takeUntil(this.destroy$)
      )
      .subscribe(({ price, type }) =>
        this.analyticsService.event(InternalEvents.LINK_COPY, {
          copy_type: copyType,
          link_type: AnalyticSourceTypes.SERVICE,
          service_price: price,
          service_type: type
        })
      );
  }
}
