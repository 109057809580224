import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

import { ServiceSchedulingStubComponent } from './service-scheduling-stub.component';

@NgModule({
  imports: [CommonModule, PuiIconModule, PuiTypographyModule],
  declarations: [ServiceSchedulingStubComponent],
  exports: [ServiceSchedulingStubComponent]
})
export class ServiceSchedulingStubModule {}
