import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrencyService } from '@app/core/currency/currency.service';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { isSimpleSession, Session } from '@app/shared/interfaces/session';

enum SessionPricingTypes {
  SESSION_OFFER = 'sessionOffer',
  PAID_SESSION = 'paidSession',
  FREE_SESSION = 'freeSession'
}

@Component({
  selector: 'app-session-payment-formatter',
  templateUrl: './session-payment-formatter.component.html',
  styleUrls: ['./session-payment-formatter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionPaymentFormatterComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _session: Session;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessionType: SessionPricingTypes;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _wage: number | null;

  @Input()
  set session(value: Session) {
    this._session = value;

    if (value) {
      this.setSessionType();
      this.setWage();
    }
  }

  @Input()
  priceOnly = false;

  @Input()
  // Ignore HidePrice if price > 0
  ignoreHidePrice = false;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get session(): Session {
    return this._session;
  }

  get sessionType(): SessionPricingTypes {
    return this._sessionType;
  }

  get showPrice(): boolean {
    const price = this.session.payRate || 0;

    return this.ignoreHidePrice && price > 0;
  }

  readonly SessionPricingTypes = SessionPricingTypes;

  constructor(public currency: CurrencyService) {}

  get wage(): number | null {
    return this._wage;
  }

  private setSessionType(): void {
    switch (this.session.status) {
      case SessionStatuses.GUIDE_OFFER:
        this._sessionType = SessionPricingTypes.SESSION_OFFER;
        break;
      default:
        this._sessionType = this._session.isSessionFree
          ? SessionPricingTypes.FREE_SESSION
          : SessionPricingTypes.PAID_SESSION;
    }
  }

  private setWage(): void {
    if (!this._session || !this._session.payRate) {
      this._wage = null;
    }

    // TODO: refactor, this info should come static from server, which is the only source of truth
    let fullCost: number;
    if (
      (this.session.status === SessionStatuses.PENDING_APPROVEMENT ||
        this._sessionType === SessionPricingTypes.SESSION_OFFER) &&
      this._session.recurringSessionsDetails?.length
    ) {
      fullCost = this._session.recurringSessionsDetails?.length
        ? this._session.payRate! * this._session.recurringSessionsDetails.length
        : this._session.payRate!;
    } else if (isSimpleSession(this._session) && !this._session.templateId) {
      // @ts-expect-error TS2533
      fullCost = (this._session.duration / 60) * this._session.payRate;
    } else {
      fullCost =
        // @ts-expect-error TS2533
        this._session.payRate +
        // @ts-expect-error TS2533
        (this._session.overtimeCharge || 0) * (this._session.duration - this._session.originalDuration);
    }

    // WARNING: service fee for guides only, clients formatting should not be affected by service fee
    const serviceFeeFraction = (this.session.serviceFee || 0) / 100;
    const serviceFee = Math.round(fullCost * serviceFeeFraction * 100) / 100;

    this._wage = fullCost - serviceFee;
  }
}
