import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { AutosizeModule } from 'ngx-autosize';

import { LocalStorageService } from '@app/core';
import { ClientWalletService } from '@app/screens/client/client-wallet/services/client-wallet.service';
import { RouterModule } from '@angular/router';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';

import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { GiftCouponSelectorComponent } from './components/gift-coupon-selector/gift-coupon-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    AutosizeModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    PlatformStyleModule,
    CurrentPaymentModule
  ],
  declarations: [InfoModalComponent, GiftCouponSelectorComponent],
  providers: [LocalStorageService, ClientWalletService]
})
export class GiftCouponsModule {}
