import { DateTime } from 'luxon';

import { AnyDate } from '../model/interfaces';

export default function convertToDateTime(value: AnyDate): DateTime {
  let dateTime;

  if (value instanceof DateTime) {
    dateTime = value;
  } else if (value instanceof Date) {
    dateTime = DateTime.fromJSDate(value).setZone('UTC', {
      keepLocalTime: false
    });
  } else if (typeof value === 'string') {
    dateTime = DateTime.fromISO(value).setZone('UTC', {
      keepLocalTime: false
    });
  } else if (typeof value === 'number') {
    dateTime = DateTime.fromMillis(value).setZone('UTC', {
      keepLocalTime: false
    });
  }

  // @ts-expect-error TS2322
  return dateTime;
}
