<div [formGroup]="form" class="questions-form">
  <ng-container formArrayName="questions">
    <div
      class="block q-block"
      *ngFor="let question of questionsControlAccess.controls; let questionIndex = index"
      [formGroupName]="questionIndex">
      <div class="form-group">
        <div class="order-buttons">
          <button
            pui-icon-button
            appearance="secondary"
            shape="square"
            aria-label="Move Up"
            type="button"
            title="Move Up"
            (click)="moveItem(questionIndex, questionIndex - 1)"
            [disabled]="questionIndex === 0">
            <pui-icon svgIcon="pui:arrow-up"></pui-icon>
          </button>
          <button
            pui-icon-button
            appearance="secondary"
            shape="square"
            aria-label="Move Down"
            type="button"
            title="Move Down"
            (click)="moveItem(questionIndex, questionIndex + 1)"
            [disabled]="questionIndex === questionsControlAccess.length - 1">
            <pui-icon svgIcon="pui:arrow-down"></pui-icon>
          </button>
        </div>
        <div class="top-bar">
          <button
            (click)="removeQuestion(questionIndex)"
            class="remove"
            placement="top"
            ngbTooltip="Delete question"
            i18n-ngbTooltip="@@deleteQuestion"></button>
          <button
            (click)="duplicateQuestion(questionIndex)"
            class="copy"
            placement="top"
            ngbTooltip="Duplicate question"
            i18n-ngbTooltip="@@duplicateQuestion"></button>
          <label class="label top-label">
            <ng-container i18n="@@question">Question</ng-container>
            {{ questionIndex + 1 }}
          </label>

          <ng-select formControlName="type" [searchable]="false" [clearable]="false" [readonly]="question.value.id">
            <ng-option [value]="QuizQuestionType.SHORT_ANSWER" i18n="@@shortAnswer">Short answer</ng-option>
            <ng-option [value]="QuizQuestionType.LONG_ANSWER" i18n="@@longAnswer">Long answer</ng-option>
            <ng-option [value]="QuizQuestionType.TEXT" i18n="@@textQuestion">Text</ng-option>
            <ng-option [value]="QuizQuestionType.SINGLE_CHOICE" i18n="@@singleChoice">Single choice</ng-option>
            <ng-option [value]="QuizQuestionType.MULTIPLE_CHOICE" i18n="@@multipleChoice">Multiple choice</ng-option>
            <ng-option [value]="QuizQuestionType.QUIZ" i18n="@@quizQuestionsQuizOption">Quiz</ng-option>
            <ng-option [value]="QuizQuestionType.FILE_UPLOAD" i18n="@@fileUpload">File upload</ng-option>
            <ng-option [value]="QuizQuestionType.SIGNATURE" i18n="@@signature">Signature</ng-option>
          </ng-select>
        </div>

        <div class="question-editor-wrapper">
          <app-text-editor-image
            class="question-content-editor"
            formControlName="question"
            placeholder="Type your question here..."
            i18n-placeholder="@@quizQuestionsQuillEditorQuestion"
            [extendedVideoUploader]="true"></app-text-editor-image>
        </div>

        <div
          class="form-group question-options-group"
          *ngIf="
            question.get('type').value === QuizQuestionType.SINGLE_CHOICE ||
            question.get('type').value === QuizQuestionType.MULTIPLE_CHOICE
          "
          formArrayName="options">
          <div
            class="question-option-group"
            *ngFor="let option of getQuizQuestionOptions(questionIndex).controls as options; let optionIndex = index"
            [formGroupName]="optionIndex">
            <div class="question-option-row">
              <div class="question-option">
                <input
                  type="text"
                  class="form-control"
                  formControlName="text"
                  (keyup)="handleQuestionOptionKeyUp($event, question, questionIndex, optionIndex)"
                  placeholder="Answer choice"
                  i18n-placeholder="@@answerChoice" />
              </div>
              <app-button
                *ngIf="options.length !== 1"
                className="btn close"
                ngbTooltip="Remove answer"
                i18n-ngbTooltip="@@removeAnswer"
                (click)="removeQuestionOptionAtIndex(questionIndex, optionIndex)"></app-button>
            </div>
          </div>
        </div>

        <div
          class="form-group has-option-explanation-group"
          *ngIf="
            question.get('type').value === QuizQuestionType.SINGLE_CHOICE ||
            question.get('type').value === QuizQuestionType.MULTIPLE_CHOICE
          ">
          <app-checkbox formControlName="hasOtherOption" i18n="@@addOtherOption">
            Add an "Other" answer option with comment field
          </app-checkbox>

          <div
            class="question-option-row other-option"
            *ngIf="question.get('hasOtherOption').value"
            [formGroup]="question.get('other')">
            <div class="question-option">
              <input
                type="text"
                class="form-control"
                formControlName="text"
                placeholder="Custom option answer"
                i18n-placeholder="@@quizQuestionsCustomTextPlaceholder" />
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.SHORT_ANSWER">
          <input
            type="text"
            class="form-control"
            readonly
            placeholder="Answer goes here..."
            i18n-placeholder="@@answerGoesHere" />
        </div>

        <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.LONG_ANSWER">
          <textarea
            type="text"
            class="form-control"
            readonly
            placeholder="Answer goes here..."
            i18n-placeholder="@@answerGoesHere"></textarea>
        </div>

        <ng-template [ngIf]="question.get('type').value === QuizQuestionType.QUIZ">
          <div class="form-group has-option-group">
            <app-checkbox formControlName="hasOptions" i18n="@@addAnswerOptions">Add answer options</app-checkbox>
          </div>

          <div
            class="form-group question-options-group"
            *ngIf="question.get('hasOptions')?.value"
            formArrayName="options">
            <div
              class="question-option-group"
              *ngFor="
                let option of getQuizQuestionOptions(questionIndex).controls;
                let optionIndex = index;
                let optionsCount = count
              "
              [formGroupName]="optionIndex">
              <div class="question-option-row">
                <div class="question-option">
                  <div
                    class="is-question-option-correct-wrapper"
                    ngbTooltip="Correct answer"
                    i18n-ngbTooltip="@@correctAnswer"
                    placement="bottom">
                    <app-checkbox formControlName="isCorrect"></app-checkbox>
                  </div>

                  <input
                    type="text"
                    class="form-control"
                    formControlName="text"
                    (keyup)="handleQuestionOptionKeyUp($event, question, questionIndex, optionIndex)" />
                </div>

                <a
                  (click)="removeQuestionOptionAtIndex(questionIndex, optionIndex)"
                  class="remove-option-btn"
                  *ngIf="optionsCount > 1"></a>
              </div>

              <div class="question-option-explanation-group">
                <textarea
                  *ngIf="question.get('hasOptionExplanation').value"
                  class="form-control"
                  formControlName="explanation"></textarea>
              </div>
            </div>
          </div>

          <div
            class="form-group has-option-explanation-group"
            *ngIf="question.get('hasOptions')?.value && explanationsEnabled">
            <app-checkbox formControlName="hasOptionExplanation" i18n="@@addExplanationForCorrectAnswers">
              Add explanation of correct / incorrect answers
            </app-checkbox>
          </div>
        </ng-template>

        <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.FILE_UPLOAD">
          <div class="image-container">
            <div class="image-wrapper">
              <label for="file" class="image-label">
                <span i18n="@@uploadFile">Upload file</span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.SIGNATURE">
          <div class="signature-wrap">
            <div class="canvas"></div>
            <div class="bar">
              <div class="btns-wrap">
                <app-button className="btn-link" i18n="@@quizQuestionsSignatureClear">Clear</app-button>
                <app-button className="btn-link" i18n="@@quizQuestionsSignatureUndo">Undo</app-button>
              </div>
              <span i18n="@@signAbove">Sign above</span>
            </div>
          </div>
          <p i18n="@@signingPolicy" class="signing-policy">
            In signing this document with an electronic signature, I agree and understand that this signature is
            considered as legally valid as a handwritten signature.
          </p>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="form-group">
        <div class="add-question" (click)="toggleQuestionTypes()">
          <app-button className="btn-add btn-lite">
            <ng-container i18n="@@addQuestion">Add question</ng-container>
          </app-button>
          <span class="add-question-arrow" [ngClass]="{ 'menu-open': showQuestionTypes }"></span>
        </div>
        <div class="question-types-wrap" [ngClass]="{ hidden: !showQuestionTypes }">
          <div class="question-types">
            <div class="question-type multiple-choice" (click)="addQuestion(QuizQuestionType.QUIZ)">
              <ng-container i18n="@@quizQuestionsQuizOption">Quiz</ng-container>
            </div>
            <div class="question-type short-answer" (click)="addQuestion(QuizQuestionType.SHORT_ANSWER)">
              <ng-container i18n="@@shortAnswer">Short answer</ng-container>
            </div>
            <div class="question-type single-choice" (click)="addQuestion(QuizQuestionType.SINGLE_CHOICE)">
              <ng-container i18n="@@singleChoice">Single choice</ng-container>
            </div>
            <div class="question-type file-upload" (click)="addQuestion(QuizQuestionType.FILE_UPLOAD)">
              <ng-container i18n="@@fileUpload">File upload</ng-container>
            </div>
            <div class="question-type long-answer" (click)="addQuestion(QuizQuestionType.LONG_ANSWER)">
              <ng-container i18n="@@longAnswer">Long answer</ng-container>
            </div>
            <div class="question-type multiple-choice" (click)="addQuestion(QuizQuestionType.MULTIPLE_CHOICE)">
              <ng-container i18n="@@multipleChoice">Multiple choice</ng-container>
            </div>
            <div class="question-type signature" (click)="addQuestion(QuizQuestionType.SIGNATURE)">
              <ng-container i18n="@@signature">Signature</ng-container>
            </div>
            <div class="question-type text" (click)="addQuestion(QuizQuestionType.TEXT)">
              <ng-container i18n="@@textQuestion">Text</ng-container>
            </div>
            <div class="question-type empty"></div>
          </div>
        </div>
        <div class="questions-required" *ngIf="questionsControlAccess.length === 0">
          <app-form-control-error [show]="true" i18n="@@minOneQuestionRequired">
            Minimum one question required
          </app-form-control-error>
        </div>
      </div>
    </div>
  </ng-container>
</div>
