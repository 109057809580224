export const TimezonesAbbr = {
  'Africa/Addis_Ababa': 'EAT',
  'Africa/Algiers': 'CET',
  'Africa/Asmara': 'EAT',
  'Africa/Asmera': 'EAT',
  'Africa/Bangui': 'WAT',
  'Africa/Blantyre': 'CAT',
  'Africa/Brazzaville': 'WAT',
  'Africa/Bujumbura': 'CAT',
  'Africa/Cairo': 'EET',
  'Africa/Ceuta': 'CET',
  'Africa/Dar_es_Salaam': 'EAT',
  'Africa/Djibouti': 'EAT',
  'Africa/Douala': 'WAT',
  'Africa/Gaborone': 'CAT',
  'Africa/Harare': 'CAT',
  'Africa/Johannesburg': 'SAST',
  'Africa/Juba': 'CAT',
  'Africa/Kampala': 'EAT',
  'Africa/Khartoum': 'CAT',
  'Africa/Kigali': 'CAT',
  'Africa/Kinshasa': 'WAT',
  'Africa/Lagos': 'WAT',
  'Africa/Libreville': 'WAT',
  'Africa/Luanda': 'WAT',
  'Africa/Lubumbashi': 'CAT',
  'Africa/Lusaka': 'CAT',
  'Africa/Malabo': 'WAT',
  'Africa/Maputo': 'CAT',
  'Africa/Maseru': 'SAST',
  'Africa/Mbabane': 'SAST',
  'Africa/Mogadishu': 'EAT',
  'Africa/Nairobi': 'EAT',
  'Africa/Ndjamena': 'WAT',
  'Africa/Niamey': 'WAT',
  'Africa/Porto-Novo': 'WAT',
  'Africa/Tripoli': 'EET',
  'Africa/Tunis': 'CET',
  'Africa/Windhoek': 'CAT',
  'America/Adak': 'HST',
  'America/Anchorage': 'AKST',
  'America/Anguilla': 'AST',
  'America/Antigua': 'AST',
  'America/Aruba': 'AST',
  'America/Atikokan': 'EST',
  'America/Atka': 'HST',
  'America/Bahia_Banderas': 'CST',
  'America/Barbados': 'AST',
  'America/Belize': 'CST',
  'America/Blanc-Sablon': 'AST',
  'America/Boise': 'MST',
  'America/Cambridge_Bay': 'MST',
  'America/Cancun': 'EST',
  'America/Cayman': 'EST',
  'America/Chicago': 'CST',
  'America/Chihuahua': 'MST',
  'America/Coral_Harbour': 'EST',
  'America/Costa_Rica': 'CST',
  'America/Creston': 'MST',
  'America/Curacao': 'AST',
  'America/Dawson': 'MST',
  'America/Dawson_Creek': 'MST',
  'America/Denver': 'MST',
  'America/Detroit': 'EST',
  'America/Dominica': 'AST',
  'America/Edmonton': 'MST',
  'America/El_Salvador': 'CST',
  'America/Ensenada': 'PST',
  'America/Fort_Nelson': 'MST',
  'America/Fort_Wayne': 'EST',
  'America/Glace_Bay': 'AST',
  'America/Goose_Bay': 'AST',
  'America/Grand_Turk': 'EST',
  'America/Grenada': 'AST',
  'America/Guadeloupe': 'AST',
  'America/Guatemala': 'CST',
  'America/Halifax': 'AST',
  'America/Havana': 'CST',
  'America/Hermosillo': 'MST',
  'America/Indiana/Indianapolis': 'EST',
  'America/Indiana/Knox': 'CST',
  'America/Indiana/Marengo': 'EST',
  'America/Indiana/Petersburg': 'EST',
  'America/Indiana/Tell_City': 'CST',
  'America/Indiana/Vevay': 'EST',
  'America/Indiana/Vincennes': 'EST',
  'America/Indiana/Winamac': 'EST',
  'America/Indianapolis': 'EST',
  'America/Inuvik': 'MST',
  'America/Iqaluit': 'EST',
  'America/Jamaica': 'EST',
  'America/Juneau': 'AKST',
  'America/Kentucky/Louisville': 'EST',
  'America/Kentucky/Monticello': 'EST',
  'America/Knox_IN': 'CST',
  'America/Kralendijk': 'AST',
  'America/Los_Angeles': 'PST',
  'America/Louisville': 'EST',
  'America/Lower_Princes': 'AST',
  'America/Managua': 'CST',
  'America/Marigot': 'AST',
  'America/Martinique': 'AST',
  'America/Matamoros': 'CST',
  'America/Mazatlan': 'MST',
  'America/Menominee': 'CST',
  'America/Merida': 'CST',
  'America/Metlakatla': 'AKST',
  'America/Mexico_City': 'CST',
  'America/Moncton': 'AST',
  'America/Monterrey': 'CST',
  'America/Montreal': 'EST',
  'America/Montserrat': 'AST',
  'America/Nassau': 'EST',
  'America/New_York': 'EST',
  'America/Nipigon': 'EST',
  'America/Nome': 'AKST',
  'America/North_Dakota/Beulah': 'CST',
  'America/North_Dakota/Center': 'CST',
  'America/North_Dakota/New_Salem': 'CST',
  'America/Ojinaga': 'MST',
  'America/Panama': 'EST',
  'America/Pangnirtung': 'EST',
  'America/Phoenix': 'MST',
  'America/Port-au-Prince': 'EST',
  'America/Port_of_Spain': 'AST',
  'America/Puerto_Rico': 'AST',
  'America/Rainy_River': 'CST',
  'America/Rankin_Inlet': 'CST',
  'America/Regina': 'CST',
  'America/Resolute': 'CST',
  'America/Santa_Isabel': 'PST',
  'America/Santo_Domingo': 'AST',
  'America/Shiprock': 'MST',
  'America/Sitka': 'AKST',
  'America/St_Barthelemy': 'AST',
  'America/St_Johns': 'NST',
  'America/St_Kitts': 'AST',
  'America/St_Lucia': 'AST',
  'America/St_Thomas': 'AST',
  'America/St_Vincent': 'AST',
  'America/Swift_Current': 'CST',
  'America/Tegucigalpa': 'CST',
  'America/Thule': 'AST',
  'America/Thunder_Bay': 'EST',
  'America/Tijuana': 'PST',
  'America/Toronto': 'EST',
  'America/Tortola': 'AST',
  'America/Vancouver': 'PST',
  'America/Virgin': 'AST',
  'America/Whitehorse': 'MST',
  'America/Winnipeg': 'CST',
  'America/Yakutat': 'AKST',
  'America/Yellowknife': 'MST',
  'Antarctica/Macquarie': 'AEDT',
  'Antarctica/McMurdo': 'NZDT',
  'Antarctica/South_Pole': 'NZDT',
  'Arctic/Longyearbyen': 'CET',
  'Asia/Amman': 'EET',
  'Asia/Beirut': 'EET',
  'Asia/Calcutta': 'IST',
  'Asia/Chongqing': 'CST',
  'Asia/Chungking': 'CST',
  'Asia/Damascus': 'EET',
  'Asia/Famagusta': 'EET',
  'Asia/Gaza': 'EET',
  'Asia/Harbin': 'CST',
  'Asia/Hebron': 'EET',
  'Asia/Hong_Kong': 'HKT',
  'Asia/Jakarta': 'WIB',
  'Asia/Jayapura': 'WIT',
  'Asia/Jerusalem': 'IST',
  'Asia/Karachi': 'PKT',
  'Asia/Kolkata': 'IST',
  'Asia/Macao': 'CST',
  'Asia/Macau': 'CST',
  'Asia/Makassar': 'WITA',
  'Asia/Manila': 'PHT',
  'Asia/Nicosia': 'EET',
  'Asia/Pontianak': 'WIB',
  'Asia/Pyongyang': 'KST',
  'Asia/Seoul': 'KST',
  'Asia/Shanghai': 'CST',
  'Asia/Taipei': 'CST',
  'Asia/Tel_Aviv': 'IST',
  'Asia/Tokyo': 'JST',
  'Asia/Ujung_Pandang': 'WITA',
  'Atlantic/Bermuda': 'AST',
  'Atlantic/Canary': 'WET',
  'Atlantic/Faeroe': 'WET',
  'Atlantic/Faroe': 'WET',
  'Atlantic/Jan_Mayen': 'CET',
  'Atlantic/Madeira': 'WET',
  'Australia/ACT': 'AEDT',
  'Australia/Adelaide': 'ACDT',
  'Australia/Brisbane': 'AEST',
  'Australia/Broken_Hill': 'ACDT',
  'Australia/Canberra': 'AEDT',
  'Australia/Currie': 'AEDT',
  'Australia/Darwin': 'ACST',
  'Australia/Hobart': 'AEDT',
  'Australia/Lindeman': 'AEST',
  'Australia/Melbourne': 'AEDT',
  'Australia/NSW': 'AEDT',
  'Australia/North': 'ACST',
  'Australia/Perth': 'AWST',
  'Australia/Queensland': 'AEST',
  'Australia/South': 'ACDT',
  'Australia/Sydney': 'AEDT',
  'Australia/Tasmania': 'AEDT',
  'Australia/Victoria': 'AEDT',
  'Australia/West': 'AWST',
  'Australia/Yancowinna': 'ACDT',
  CET: 'CET',
  CST6CDT: 'CST',
  'Canada/Atlantic': 'AST',
  'Canada/Central': 'CST',
  'Canada/Eastern': 'EST',
  'Canada/Mountain': 'MST',
  'Canada/Newfoundland': 'NST',
  'Canada/Pacific': 'PST',
  'Canada/Saskatchewan': 'CST',
  'Canada/Yukon': 'MST',
  Cuba: 'CST',
  EET: 'EET',
  EST: 'EST',
  EST5EDT: 'EST',
  Egypt: 'EET',
  'Etc/UCT': 'UTC',
  'Etc/UTC': 'UTC',
  'Etc/Universal': 'UTC',
  'Etc/Zulu': 'UTC',
  'Europe/Amsterdam': 'CET',
  'Europe/Andorra': 'CET',
  'Europe/Athens': 'EET',
  'Europe/Belgrade': 'CET',
  'Europe/Berlin': 'CET',
  'Europe/Bratislava': 'CET',
  'Europe/Brussels': 'CET',
  'Europe/Bucharest': 'EET',
  'Europe/Budapest': 'CET',
  'Europe/Busingen': 'CET',
  'Europe/Chisinau': 'EET',
  'Europe/Copenhagen': 'CET',
  'Europe/Gibraltar': 'CET',
  'Europe/Helsinki': 'EET',
  'Europe/Kaliningrad': 'EET',
  'Europe/Kiev': 'EET',
  'Europe/Lisbon': 'WET',
  'Europe/Ljubljana': 'CET',
  'Europe/Luxembourg': 'CET',
  'Europe/Madrid': 'CET',
  'Europe/Malta': 'CET',
  'Europe/Mariehamn': 'EET',
  'Europe/Monaco': 'CET',
  'Europe/Moscow': 'MSK',
  'Europe/Nicosia': 'EET',
  'Europe/Oslo': 'CET',
  'Europe/Paris': 'CET',
  'Europe/Podgorica': 'CET',
  'Europe/Prague': 'CET',
  'Europe/Riga': 'EET',
  'Europe/Rome': 'CET',
  'Europe/San_Marino': 'CET',
  'Europe/Sarajevo': 'CET',
  'Europe/Simferopol': 'MSK',
  'Europe/Skopje': 'CET',
  'Europe/Sofia': 'EET',
  'Europe/Stockholm': 'CET',
  'Europe/Tallinn': 'EET',
  'Europe/Tirane': 'CET',
  'Europe/Tiraspol': 'EET',
  'Europe/Uzhgorod': 'EET',
  'Europe/Vaduz': 'CET',
  'Europe/Vatican': 'CET',
  'Europe/Vienna': 'CET',
  'Europe/Vilnius': 'EET',
  'Europe/Warsaw': 'CET',
  'Europe/Zagreb': 'CET',
  'Europe/Zaporozhye': 'EET',
  'Europe/Zurich': 'CET',
  HST: 'HST',
  Hongkong: 'HKT',
  'Indian/Antananarivo': 'EAT',
  'Indian/Comoro': 'EAT',
  'Indian/Mayotte': 'EAT',
  Israel: 'IST',
  Jamaica: 'EST',
  Japan: 'JST',
  Libya: 'EET',
  MET: 'MET',
  MST: 'MST',
  MST7MDT: 'MST',
  'Mexico/BajaNorte': 'PST',
  'Mexico/BajaSur': 'MST',
  'Mexico/General': 'CST',
  NZ: 'NZDT',
  Navajo: 'MST',
  PRC: 'CST',
  PST8PDT: 'PST',
  'Pacific/Auckland': 'NZDT',
  'Pacific/Guam': 'ChST',
  'Pacific/Honolulu': 'HST',
  'Pacific/Johnston': 'HST',
  'Pacific/Midway': 'SST',
  'Pacific/Pago_Pago': 'SST',
  'Pacific/Saipan': 'ChST',
  'Pacific/Samoa': 'SST',
  Poland: 'CET',
  Portugal: 'WET',
  ROC: 'CST',
  ROK: 'KST',
  UCT: 'UTC',
  'US/Alaska': 'AKST',
  'US/Aleutian': 'HST',
  'US/Arizona': 'MST',
  'US/Central': 'CST',
  'US/East-Indiana': 'EST',
  'US/Eastern': 'EST',
  'US/Hawaii': 'HST',
  'US/Indiana-Starke': 'CST',
  'US/Michigan': 'EST',
  'US/Mountain': 'MST',
  'US/Pacific': 'PST',
  'US/Samoa': 'SST',
  UTC: 'UTC',
  Universal: 'UTC',
  'W-SU': 'MSK',
  WET: 'WET',
  Zulu: 'UTC'
};
