import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GuideContact } from '@app/core/users/types';
import { ISessionRescheduleMessage } from '@app/modules/rescheduling-service';

@Component({
  selector: 'app-rescheduling-message',
  templateUrl: './rescheduling-message.component.html',
  styleUrls: ['./rescheduling-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReschedulingMessageComponent {
  @Input()
  // @ts-expect-error TS2564
  user: Pick<GuideContact, 'name'>;

  @Output()
  attachMessage: EventEmitter<ISessionRescheduleMessage> = new EventEmitter<ISessionRescheduleMessage>();

  message: string | undefined = undefined;

  handleAction(withMessage: boolean = false): void {
    this.attachMessage.emit(
      withMessage ? { skipped: false, message: this.message } : { skipped: true, message: undefined }
    );
  }
}
