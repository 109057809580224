import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { config } from '@app/core';
import { AuthService } from '@app/core/auth/services';
import { AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { mergeMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

@Injectable()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class TeamMemberActionResolver implements Resolve<any> {
  constructor(private _http: HttpClient, private _router: Router, private _auth: AuthService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(`${config.apiPath}/public/workspace-member/${route.params.hash}`)
        .pipe(
          mergeMap(({ invitation }) => {
            return this._auth.isAuthorized && this._auth.user.email === invitation.email
              ? combineLatest([of(invitation), this._auth.onAuth()])
              : of([invitation]);
          })
        )
        .subscribe(
          ([invitation]) => {
            this._auth.workspaceMemberSignUpInfo = {
              email: invitation.email,
              authState: invitation.userExist ? AuthStates.INIT : AuthStates.SIGN_UP,
              invitationCode: invitation.invitationCode,
              hasAlternativeProvider: invitation.hasAlternativeProvider
            };

            if (invitation.userExist) {
              this._router.navigate(['/auth/sign-in-team'], { replaceUrl: true });
            } else {
              this._auth.signout(false);
              this._router.navigate(['/auth/sign-up'], { replaceUrl: true });
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          error => {
            this._router.navigate(['/not-found'], { replaceUrl: true });
          }
        )
    );
  }
}
