<div class="date-picker-container">
  <label for="schedule_date" class="label date-label" i18n="@@scheduleDateTimePickerDate">Date</label>
  <div class="datepicker-wrapper" (clickOutside)="datePicker.close()">
    <input
      id="schedule_date"
      data-qa-id="schedule-date-picker"
      class="date-control form-control"
      type="text"
      ngbDatepicker
      #datePicker="ngbDatepicker"
      [(ngModel)]="date"
      (ngModelChange)="onScheduleOptionsChange()"
      [navigation]="datePickerNavigation"
      [markDisabled]="markDateDisabled"
      [firstDayOfWeek]="firstDayOfWeek"
      (click)="datePicker.toggle()"
      [autoClose]="datePickerAutoClose"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [readonly]="!dateInputAllowed"
      [disabled]="isDisabled || !isSlotsLoaded"
      [placement]="datePickerPlacement"
      [placeholder]="dateInputPlaceholder" />
  </div>
</div>

<div data-qa-id="schedule-time-picker" class="time-picker-container">
  <ng-template
    *ngTemplateOutlet="
      timePicker;
      context: { timeSlots: scheduleTimeSlots, formControl: timeControl, timezone: timezone }
    "></ng-template>
</div>

<div class="timezone-container">
  <label for="timezone_picker" class="label timezone-label" i18n="@@timeZoneColon">Time zone:</label>
  <ng-select
    [items]="timezones"
    [(ngModel)]="timezone"
    [searchFn]="searchTimezone"
    [clearable]="timezoneClearable"
    [virtualScroll]="true"
    [disabled]="isDisabled"
    (change)="onScheduleOptionsChange()"
    bindLabel="name"
    bindValue="value"
    class="timezone-picker"
    id="timezone_picker"
    data-qa-id="schedule-timezone-select"></ng-select>
</div>
