import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavTabComponent {
  @Input() icon!: string;
}
