import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { EditProfileNavComponent } from './components/edit-profile-nav/edit-profile-nav.component';
import { GuideSaveChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-save-changes-dialog/guide-save-changes-dialog.component';
import { GuideDiscardChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-discard-changes-dialog/guide-discard-changes-dialog.component';
import { PuiButtonModule, PuiModalModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';

@NgModule({
  imports: [RouterModule, CommonModule, PuiModalModule, PuiTypographyModule, PuiButtonModule],
  declarations: [
    EditProfileNavComponent,
    GuideSaveChangesDialogComponent,
    GuideDiscardChangesDialogComponent,
    GuideChangesForNewUsersDialogComponent
  ],
  exports: [
    EditProfileNavComponent,
    GuideSaveChangesDialogComponent,
    GuideDiscardChangesDialogComponent,
    GuideChangesForNewUsersDialogComponent
  ]
})
export class GuideSharedModule {}
