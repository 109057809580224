import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';

import type { GuideServices } from '@app/modules/service-scheduling/types';

type GetGuideServicesResult = { services: GuideServices.RootObject[] };
type GetGuideServiceResult = { template: Partial<GuideServices.RootObject> };

@Injectable({ providedIn: 'root' })
export class GuideServicesApiService {
  constructor(private readonly http: HttpClient) {}

  getGuideServices(): Observable<GetGuideServicesResult> {
    return this.http.get<GetGuideServicesResult>(`${config.apiPath}/user/guide/services`);
  }

  getGuideService(id: GuideServices.RootObject['guideId']): Observable<GetGuideServiceResult> {
    return this.http.get<GetGuideServiceResult>(`${config.apiPath}/user/guide/services/templates/sessions/${id}`);
  }
}
