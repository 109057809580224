export const convertQueryParamsToString = (url: string, queryParams: { [key: string]: string }): string => {
  if (Object.keys(queryParams).length === 0) {
    return url;
  }

  let resultString = url + '?';

  Object.keys(queryParams).forEach((key: string, index: number) => {
    resultString += `${key}=${queryParams[key]}`;

    if (index !== Object.keys(queryParams).length - 1) {
      resultString += '&';
    }
  });

  return resultString;
};
