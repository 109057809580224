import { Injector, Provider } from '@angular/core';
import { NAVIGATION_ENTRIES } from '@app/modules/navigation/tokens';
import { navigationEntriesFactory } from '@app/modules/navigation/providers/entries/factory';
import { AuthService } from '@app/core/auth/services';

export const NAVIGATION_ENTRIES_PROVIDER: Provider = {
  provide: NAVIGATION_ENTRIES,
  useFactory: navigationEntriesFactory,
  deps: [AuthService, Injector]
};
