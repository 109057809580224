export enum EmbeddableCodeSource {
  MEMBER,
  TEAM
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookLinks {
  personal: string;
  workspace?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookWidgetInfo {
  stylesheet: string;
  script: string;
  endpoints: IBookLinks;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookButtonCustomizationOptions {
  text: string;
  style: {
    backgroundColor: string;
    color: string;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IEmbeddableCodeParts {
  startComment: string;
  endComment: string;
  widgetStylesheetSource: string;
  widgetScriptSource: string;
  widgetFrameUrl: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPopupWidgetCodeParts extends IEmbeddableCodeParts {
  buttonText: string;
  buttonStyle: { [styleProp: string]: string };
}

export type EmbeddableWidget = IPopupWidgetCodeParts;
