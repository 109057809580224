<article class="row">
  <div class="wiki-term-content col-md-8">
    <h5 class="wiki-term-name">{{ term?.name }}</h5>
    <div class="wiki-term-description" [innerHTML]="term?.description | safeHtml">
      {{ term?.description }}
    </div>
    <div class="links">
      <a class="wiki-link" [routerLink]="['/blog', { tags: termTagNames }]">Articles about {{ term?.name }}</a>
    </div>
  </div>
  <div class="wiki-term-additional-content col-md-4">
    <h6 class="wiki-guides-title" *ngIf="term.guides && term.guides.length">Coaches working with practice</h6>
    <div class="wiki-guides-container">
      <app-user-info
        class="wiki-guide"
        *ngFor="let guide of term.guides"
        [user]="guide"
        [uiPayload]="guideSpecialization"
        [routerLink]="['/', guide.namedUrl]">
        <ng-template #guideSpecialization>
          <div class="wiki-guide-specialization">{{ guide.specialization }}</div>
        </ng-template>
      </app-user-info>
    </div>
    <div class="links">
      <a class="wiki-link" [routerLink]="['/', guidesRoute, termTagNames ? { tags: termTagNames } : {}]">
        More coaches
      </a>
    </div>
  </div>
</article>
