import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BookingCancellationRoutingModule } from '@appWidget/modules/booking-cancellation/booking-cancellation-routing.module';

import { BookingCancellationComponent } from './booking-cancellation.component';

@NgModule({
  imports: [CommonModule, BookingCancellationRoutingModule],
  declarations: [BookingCancellationComponent]
})
export class BookingCancellationModule {}
