<div class="blog-author-panel row" *ngIf="blogAuthorName">
  <div class="col blog-author-title" *ngIf="blogAuthorName">{{ blogAuthorName }}</div>
</div>

<div class="blog-header" *ngIf="!blogAuthorName">
  <h1 class="main-blog-title">{{ config.blogPageTitle }}</h1>
  <h4 class="main-blog-sub-title" *ngIf="currentRoute.includes('business-blog')">
    {{ config.blogBusinessSubTitle }}
  </h4>
</div>

<div class="management-panel row no-gutters">
  <div class="col-lg-3 col-md-4 order-2 order-md-1 filter-by-type">
    <a
      class="filter-by-type-item"
      i18n="@@blogTabAll"
      [ngClass]="{ active: currentFilter && !currentFilter.type }"
      (click)="changeBlogItemFilterType()">
      All
    </a>
    <a
      class="filter-by-type-item"
      i18n="@@blogTabArticles"
      [ngClass]="{
        active: currentFilter && currentFilter.type && currentFilter.type === BlogItemFilterTypes.ARTICLE
      }"
      (click)="changeBlogItemFilterType(BlogItemFilterTypes.ARTICLE)">
      Articles
    </a>
    <a
      class="filter-by-type-item"
      i18n="@@blogTabWiki"
      [ngClass]="{
        active: currentFilter && currentFilter.type && currentFilter.type === BlogItemFilterTypes.WIKI
      }"
      (click)="changeBlogItemFilterType(BlogItemFilterTypes.WIKI)"
      *ngIf="showWikiFilter">
      Wiki
    </a>
    <a
      class="filter-by-type-item"
      i18n="@@blogTabPodcast"
      [ngClass]="{
        active: currentFilter && currentFilter.type && currentFilter.type === BlogItemFilterTypes.PODCAST
      }"
      (click)="changeBlogItemFilterType(BlogItemFilterTypes.PODCAST)">
      Podcasts
    </a>
  </div>
  <div class="col-lg-3 col-md-4 offset-lg-6 offset-md-4 order-1 order-md-2 search-and-tags">
    <input
      type="text"
      class="form-control search-input order-2 order-md-1"
      [formControl]="searchControl"
      i18n-placeholder="@@blogSearchInput"
      placeholder="Search in blog" />

    <div ngbDropdown class="d-inline-block button order-1 order-md-2">
      <app-button className="btn-add" (click)="addTags()" i18n="@@tags">Tags</app-button>
      <div ngbDropdownMenu class="tag-list">
        <ng-container *ngFor="let tag of tags">
          <div *ngIf="!tag.parents && tag.id > 1" class="dropdown-divider"></div>
          <h6 *ngIf="!tag.parents" class="dropdown-header">{{ tag.name }}</h6>
          <button type="button" *ngIf="tag.parents" (click)="addTag(tag.name)" class="dropdown-item">
            {{ tag.name }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="active-filter-tags" *ngIf="activeTagsNames && activeTagsNames.length">
  <span class="active-filter-tag" *ngFor="let tag of activeTagsNames">
    {{ tag }}
    <button class="close-btn" (click)="removeActiveTag(tag)"></button>
  </span>
</div>

<div class="tiles-panel row" [ngClass]="{ 'no-active-tags': !activeTagsNames }">
  <ng-container *ngFor="let item of items; first as isFirst" [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="itemTypes.ARTICLE">
      <ng-template [ngIf]="isFirst" [ngIfElse]="regularArticleItem">
        <div class="col-md-9">
          <app-blog-item-wide-article
            [article]="item"
            (select)="openArticle($event)"
            class="blog-item wide-article-item"></app-blog-item-wide-article>
        </div>

        <div class="col-md-3">
          <app-blog-item-platform-ads class="blog-item platform-ads-item"></app-blog-item-platform-ads>
        </div>
      </ng-template>

      <ng-template #regularArticleItem>
        <div class="col-md-4">
          <app-blog-item-article
            [article]="item"
            (select)="openArticle($event)"
            (selectAuthor)="openAuthorBlog($event)"
            class="blog-item article-item"></app-blog-item-article>
        </div>
      </ng-template>
    </ng-container>

    <div *ngSwitchCase="itemTypes.PODCAST" class="col-md-4">
      <app-blog-item-podcast
        [podcast]="item"
        (select)="openArticle($event)"
        (selectAuthor)="openAuthorBlog($event)"
        class="blog-item podcast-item"></app-blog-item-podcast>
    </div>

    <div *ngSwitchCase="itemTypes.PODCASTS_PACK" class="col-md-4">
      <app-blog-item-podcasts-pack
        [podcastsPack]="item"
        (select)="openArticle($event)"
        (selectAuthor)="openAuthorBlog($event)"
        class="blog-item podcasts-pack-item borderless-item"></app-blog-item-podcasts-pack>
    </div>

    <div *ngSwitchCase="itemTypes.VIDEO" class="col-md-8">
      <app-blog-item-video
        [video]="item"
        (selectAuthor)="openVideoAuthorPage($event)"
        class="blog-item video-item"></app-blog-item-video>
    </div>

    <div *ngSwitchCase="itemTypes.WIKI_TERM" class="col-md-4">
      <app-blog-item-wiki
        class="blog-item wiki-item borderless-item"
        [term]="item"
        [ngClass]="item?.category?.name + '-category'"></app-blog-item-wiki>
    </div>
  </ng-container>
</div>
