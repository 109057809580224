import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import config from '@app/core/config/config';
import { PaymentMethods } from '@app/shared/enums/payment-methods';
import { GuideServiceTypes, IBookingResult } from '@app/shared/interfaces/services';
import { IGiftCardPaymentDetails } from '@app/shared/interfaces/gift-coupons';
import { SessionsTypes } from '@app/shared/enums/sessions-types';
import { StripeJsService } from '@app/core/stripe/stripe-js.service';
import { Params } from '@angular/router';
import { IUserMembership } from '@app/core/membership/types';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { Payment, PaymentType, PayService } from '../types';

const APPROVE_SESSION_OFFER_ENDPOINT = `${config.apiPath}/user/client/sessions/offers/approve`;

const OFFER_RESCHEDULE_ENDPOINT = `${config.apiPath}/user/client/sessions/reschedule`;

const GIFT_CARD_ENDPOINT = `${config.apiPath}/auth/stripe/gift-coupon`;

const PROGRAMS_ENDPOINT = `${config.apiPath}/user/client/programs`;

// const PACKAGES_ENDPOINT = `${config.apiPath}/auth/stripe/packages`;

const PACKAGES_ENDPOINT = `${config.apiPath}/user/client/packages`;

const SERVICE_ENDPOINT = `${config.apiPath}/user/client/services/books`;

// const PLATFORM_PLAN_ENDPOINT = `${config.apiPath}/auth/stripe/platform-plans`;

const PLATFORM_PLAN_ENDPOINT = `${config.apiPath}/membership`;

const REPAY_OVERDRAFT_ENDPOINT = `${config.apiPath}/auth/stripe/overdraft/repay`;

const SUBSCRIPTIONS = `${config.apiPath}/user/common/subscriptions`;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookingDetails {
  serviceId?: number;
  templateId?: number;
  type?: GuideServiceTypes;
  serviceHost?: number;
  date?: string;
  duration?: number;
  timezone?: string;
  paymentInfo?: {
    id?: PaymentType | string;
    save?: boolean;
    new?: boolean;
    oneTimePayment?: boolean;
    clientEmail: string;
  };
  serviceParent?: {
    id: number;
    type: GuideServiceTypes.PACKAGE | GuideServiceTypes.PROGRAM;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PayWithStripeService implements PayService {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _payment: Payment;

  constructor(
    private _http: HttpClient,
    private _analytics: AnalyticsService,
    private _stripeJsService: StripeJsService,
    private _notifyService: NotificationsService
  ) {}

  init(payment: Payment): void {
    this._payment = payment;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentInfo() {
    if (!this._payment) {
      return null;
    }

    const { paymentData, saveCard, isNewPaymentMethod, useCardId } = this._payment;

    return {
      id: paymentData,
      save: saveCard,
      new: isNewPaymentMethod,
      savedCardId: useCardId
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionOffer$(id: number): Observable<any> {
    return this._buildPayload$({ id }).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string; session: { id: number; free: boolean } }>(
          APPROVE_SESSION_OFFER_ENDPOINT,
          {
            type: params.type,
            serviceParent: params.serviceParent,
            date: params.date,
            serviceHost: params.serviceHost,
            duration: params.duration,
            timezone: params.timezone,
            id: params.id,
            paymentInfo: {
              // @ts-expect-error TS2531
              id: this.paymentInfo.savedCardId ?? '',
              // @ts-expect-error TS2531
              new: this.paymentInfo.new,
              // @ts-expect-error TS2531
              save: this.paymentInfo.save,
              oneTimePayment: params.oneTimePayment,
              clientEmail: params.clientEmail,
              source: params.source
            }
          } as IBookingDetails
        );
      }),
      switchMap(({ clientSecret, session }) => this.finishPayment$(clientSecret).pipe(map(() => session))),
      tap((session: { id: number; free: boolean }) => {
        if (session) {
          this._analytics.event(InternalEvents.ACCEPT_SESSION_OFFER, {
            session,
            paymentMethod: PaymentMethods.PAYPAL
          });
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service$(bookingDetails: any): Observable<IBookingResult> {
    return this._buildPayload$(bookingDetails).pipe(
      switchMap(params => {
        return this._http.post<{ bookingResult: IBookingResult; clientSecret: string }>(SERVICE_ENDPOINT, {
          type: params.type,
          serviceParent: params.serviceParent,
          date: params.date,
          serviceHost: params.serviceHost,
          duration: params.duration,
          timezone: params.timezone,
          templateId: params.serviceId,
          serviceId: params.serviceId,
          paymentInfo: {
            // @ts-expect-error TS2531
            id: this.paymentInfo.savedCardId ?? '',
            // @ts-expect-error TS2531
            new: this.paymentInfo.new,
            // @ts-expect-error TS2531
            save: this.paymentInfo.save,
            oneTimePayment: params.oneTimePayment,
            clientEmail: params.clientEmail,
            source: params.source
          },
          guests: params.guests,
          bookings: params.bookings,
          note: params.note
        } as IBookingDetails);
      }),
      switchMap(({ clientSecret }) => this.finishPayment$(clientSecret)),
      map(() => ({
        id: bookingDetails.id,
        serviceId: bookingDetails.serviceId,
        serviceType: bookingDetails.type
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session$(bookingDetails: any): Observable<IBookingResult> {
    return this.service$(bookingDetails);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reschedule$(id: number, date: string, sessionType: SessionsTypes, message?: string): Observable<any> {
    return this._buildPayload$({ id, date, message }).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string }>(OFFER_RESCHEDULE_ENDPOINT, {
          id: params.id,
          date: params.date,
          reason: params.message,
          timezone: params.timezone
        } as IBookingDetails);
      }),
      switchMap(({ clientSecret }) => this.finishPayment$(clientSecret))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coupon$(giftPayment: IGiftCardPaymentDetails): Observable<any> {
    return this._buildPayload$(giftPayment).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string }>(GIFT_CARD_ENDPOINT, params);
      }),
      switchMap(({ clientSecret }) => this.finishPayment$(clientSecret))
    );
  }

  // @ts-expect-error TS7006
  program$(programId: number, payload): Observable<IBookingResult> {
    return this._buildPayload$({ programId, paymentOption: payload?.paymentOption }).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string }>(`${PROGRAMS_ENDPOINT}/${programId}/orders`, {
          serviceId: programId,
          type: params.type,
          date: params.date,
          serviceHost: params.serviceHost,
          paymentInfo: {
            // @ts-expect-error TS2531
            id: this.paymentInfo.savedCardId ?? '',
            // @ts-expect-error TS2531
            new: this.paymentInfo.new,
            // @ts-expect-error TS2531
            save: this.paymentInfo.save,
            oneTimePayment: params.oneTimePayment,
            clientEmail: params.clientEmail,
            source: params.source
          }
        } as IBookingDetails);
      }),
      switchMap(({ clientSecret }) => this.finishPayment$(clientSecret)),
      mapTo({ id: programId, serviceId: programId, serviceType: GuideServiceTypes.PROGRAM })
    );
  }

  // @ts-expect-error TS7006
  package$(packageId: number, payload): Observable<IBookingResult> {
    return this._buildPayload$({ packageId, paymentOption: payload?.paymentOption }).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string }>(`${PACKAGES_ENDPOINT}/${packageId}/orders`, {
          serviceId: packageId,
          type: params.type,
          date: params.date,
          serviceHost: params.serviceHost,
          paymentInfo: {
            // @ts-expect-error TS2531
            id: this.paymentInfo.savedCardId ?? '',
            // @ts-expect-error TS2531
            new: this.paymentInfo.new,
            // @ts-expect-error TS2531
            save: this.paymentInfo.save,
            oneTimePayment: params.oneTimePayment,
            recurrency: params.recurrency,
            clientEmail: params.clientEmail,
            source: params.source
          }
        } as IBookingDetails);
      }),
      switchMap(({ clientSecret }) => this.finishPayment$(clientSecret)),
      mapTo({ id: packageId, serviceId: packageId, serviceType: GuideServiceTypes.PACKAGE })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  platformPlan$(planType: string, recurrency: string): Observable<any> {
    let _membership: IUserMembership;

    return this._buildPayload$({ planType, recurrency }).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string } | { membership: IUserMembership }>(
          `${PLATFORM_PLAN_ENDPOINT}/${encodeURIComponent(planType)}/switch`,
          {
            ...params,
            paymentInfo: {
              // @ts-expect-error TS2531
              id: this.paymentInfo.savedCardId ?? '',
              // @ts-expect-error TS2531
              new: this.paymentInfo.new,
              // @ts-expect-error TS2531
              save: this.paymentInfo.save || !!recurrency,
              oneTimePayment: params.oneTimePayment,
              selectedRecurrency: recurrency,
              source: params.source
            }
          }
        );
      }),
      tap(response => {
        if ('membership' in response) {
          _membership = response.membership;
        }
      }),
      switchMap(response => {
        if ('clientSecret' in response) {
          return this.finishPayment$(response.clientSecret);
        }

        return of(undefined);
      }),
      // @ts-expect-error TS2454
      mapTo({ membership: _membership })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overdraft$(): Observable<any> {
    return this._buildPayload$({}).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string }>(REPAY_OVERDRAFT_ENDPOINT, params);
      }),
      switchMap(({ clientSecret }) => this.finishPayment$(clientSecret))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptionReactivate$(id: number): Observable<any> {
    return this._buildPayload$({ recurrency: true }).pipe(
      switchMap(params => {
        return this._http.post<{ clientSecret: string }>(`${SUBSCRIPTIONS}/${id}/reactivate`, {
          ...params,
          paymentInfo: {
            // @ts-expect-error TS2531
            id: this.paymentInfo.savedCardId ?? '',
            // @ts-expect-error TS2531
            new: this.paymentInfo.new,
            // @ts-expect-error TS2531
            save: this.paymentInfo.save,
            oneTimePayment: params.oneTimePayment,
            source: params.source
          }
        });
      }),
      switchMap(response => {
        if (response && 'clientSecret' in response) {
          return this.finishPayment$(response.clientSecret);
        } else {
          return of(true);
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finishPayment$(clientSecret: string): Observable<any> {
    return iif(
      () => !!clientSecret,
      this._stripeJsService.stripe$.pipe(
        take(1),
        switchMap(async stripeApi => {
          /*
           * payment_method
           * RECOMMENDED
           * string | object
           * Either the id of an existing PaymentMethod(https://stripe.com/docs/api/payment_methods), or an object containing data to create a PaymentMethod with.
           * See the use case sections below for details.
           */
          const paymentOptions = {
            // @ts-expect-error TS2531
            payment_method: this.paymentInfo.new
              ? // @ts-expect-error TS2531
                { card: this.paymentInfo.id.cardNumber }
              : // @ts-expect-error TS2531
                this.paymentInfo.savedCardId
          };
          return stripeApi
            ? stripeApi.stripe.confirmCardPayment(clientSecret, paymentOptions)
            : throwError({ error: { msg: 'No stripe api' } });
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        switchMap((response: any) => {
          const { error } = response;

          if (error) {
            return throwError({ error: { msg: error.message } });
          }

          return of(response);
        }),
        catchError(err => {
          this._notifyService.create('Server error', err.error.msg, NotificationType.Error, {
            timeOut: 10000
          });
          return throwError(err);
        })
      ),
      of(true)
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _buildPayload$(params: any = {}): Observable<Params> {
    if (!this.paymentInfo?.new) {
      // @ts-expect-error TS2531
      params['cardId'] = this.paymentInfo.savedCardId;
    }

    switch (params['paymentOption']) {
      case PaymentOptions.FULL_PRICE:
        params['oneTimePayment'] = true;
        break;
      case PaymentOptions.INSTALLMENTS:
        params['recurrency'] = true;
        break;
    }

    delete params['paymentOption'];

    if (this.paymentInfo?.save || (this.paymentInfo?.new && !!params['recurrency'])) {
      return this._stripeJsService.stripe$.pipe(
        take(1),
        switchMap(stripeJs => {
          // @ts-expect-error TS2531
          return stripeJs.stripe.createToken(this.paymentInfo.id.cardNumber);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((stripeJs: any) => {
          const { token, error } = stripeJs;

          if (error) {
            return throwError({ error: { msg: error.message } });
          }

          params['source'] = token.id;
          return params;
        })
      );
    }

    return of(params);
  }
}
