import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HostSelectModule } from '@app/modules/propose-service/components/host-select/host-select.module';
import { CheckboxModule } from '@app/modules/ui-kit/checkbox/checkbox.module';
import {
  PuiDropdownModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiLetModule,
  PuiSelectModule,
  PuiTagModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { ServiceSchedulingFiltersComponent } from './service-scheduling-filters.component';
import { UserSelectModule } from '@app/modules/service-scheduling/components/user-select/user-select.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PuiLetModule,
    PuiDropdownModule,
    PuiTagModule,
    CheckboxModule,
    PuiTypographyModule,
    PuiInputCheckboxModule,
    ReactiveFormsModule,
    PuiInputModule,
    PuiSelectModule,
    PuiIconModule,
    HostSelectModule,
    UserSelectModule
  ],
  declarations: [ServiceSchedulingFiltersComponent],
  exports: [ServiceSchedulingFiltersComponent]
})
export class ServiceSchedulingFiltersModule {}
