import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { map } from 'rxjs/operators';

@Injectable()
export class SchedulableGuideServicesService {
  readonly ENDPOINT = `${config.apiPath}/user/guide/services/schedulable`;

  constructor(private _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOwnServices$(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(this.ENDPOINT).pipe(map(({ services }) => services));
  }
}
