<div class="phone-verification-modal">
  <header>
    <h2 i18n="@@enterSmsCode">Enter sms code:</h2>
    <label i18n="@@SmsHasBeenSent">SMS code has been sent to your phone number.</label>
  </header>
  <section>
    <input
      type="text"
      [(ngModel)]="code"
      #codeNgModel="ngModel"
      required
      minlength="6"
      pattern="[0-9]{6,6}"
      class="form-control"
      (keyup.enter)="onSubmit(codeNgModel.valid)"
      [disabled]="isLoading" />
    <div class="repeat-wrapper">
      <label *ngIf="seconds | async as sec; else repeatBtn" i18n="@@youCanRepeatRequestIn">
        You can repeat code request in {{ sec | secondsToTime }}
      </label>
      <ng-template #repeatBtn>
        <div class="repeat-btn-wrapper">
          <app-button className="btn-link" (click)="onRepeat()" i18n="@@repeat">Repeat</app-button>
        </div>
      </ng-template>
    </div>
  </section>
  <footer>
    <app-button
      (click)="onSubmit(codeNgModel.valid)"
      [disabled]="codeNgModel.invalid"
      className="btn-lg btn-primary"
      i18n="@@submit">
      Submit
    </app-button>
  </footer>
</div>
