import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { IGuideService } from '@app/modules/book-service';
import { SchedulableGuideServicesService } from '@app/screens/guide/guide-sessions/services/events';
import { Observable, ReplaySubject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceProvider<TService, TRefreshOptions> {
  readonly services$: Observable<TService[]>;
  refresh?: (options: TRefreshOptions) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GUIDE_SERVICES_PROVIDER = new InjectionToken<IServiceProvider<any, any>>('GuideServicesProvider');

@Injectable()
export class GuideServicesProviderService implements IServiceProvider<IGuideService, void>, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _services$ = new ReplaySubject<IGuideService[]>(1);

  get services$(): Observable<IGuideService[]> {
    return this._services$.asObservable();
  }

  constructor(private readonly _api: SchedulableGuideServicesService) {}

  ngOnDestroy(): void {
    this._services$.complete();
  }

  refresh(): void {
    this._api.getOwnServices$().subscribe(services => {
      this._services$.next(services);
    });
  }
}

export const GUIDE_SERVICES_PROVIDERS = [
  {
    provide: GUIDE_SERVICES_PROVIDER,
    deps: [SchedulableGuideServicesService],
    useClass: GuideServicesProviderService
  }
];
