import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import config from '../../../../core/config/config';

@Injectable()
export class GuideProgramAnswersService {
  private readonly ENDPOINT = `${config.apiPath}/user/guide/chat-bot/export/answers/programs`;

  constructor(private readonly _http: HttpClient, private readonly _notifications: NotificationsService) {}

  downloadCSV$(programId: number): Observable<BlobPart> {
    return this._http.get(`${this.ENDPOINT}/${programId}`, { responseType: 'blob' }).pipe(
      catchError(error => {
        const message = `Failed to load program answers`;

        this._notifications.error(message);
        return throwError(error);
      })
    );
  }

  downloadClientModuleCSV$(clientId: number, programId: number, moduleId: number): Observable<Blob> {
    return this._http
      .get(`${this.ENDPOINT}/${programId}/clients/${clientId}/modules/${moduleId}`, {
        responseType: 'blob'
      })
      .pipe(
        catchError(error => {
          const message = `Failed to load customer's module answers`;

          this._notifications.error(message);
          return throwError(error);
        })
      );
  }
}
