import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import {
  addContactSuccess,
  archiveContactSuccess,
  bulkUpdate,
  fetchContact,
  fetchContactSuccess,
  resetState,
  tagClientSuccess,
  unArchiveContactSuccess,
  updateTagsToClient
} from '@app/screens/guide/guide-clients/guide-relations-table/store/relations/contacts.actions';
import { ContactViewModel } from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';

const initialState = {
  status: 'initial',
  data: []
};

export const contactsReducer = createReducer(
  initialState,
  on(resetState, () => initialState),
  on(fetchContact, state =>
    produce(state, draft => {
      draft.status = 'loading';
      draft.data = [];
    })
  ),
  on(fetchContactSuccess, (state, { clients }) =>
    produce(state, draft => {
      draft.status = 'idle';
      // @ts-expect-error TS2322
      draft.data = clients;
    })
  ),
  on(addContactSuccess, (state, { clients }) =>
    produce(state, draft => {
      // @ts-expect-error TS2322
      draft.data = [...draft.data, ...clients];
    })
  ),
  on(unArchiveContactSuccess, (state, { client }) =>
    produce(state, draft => {
      const index = draft.data.findIndex(({ id }) => id === client.id);
      draft.data[index] = {
        // @ts-expect-error TS2698
        ...state.data[index],
        // @ts-expect-error TS2322
        active: true,
        // @ts-expect-error TS2322
        archived: false
      };
    })
  ),
  on(archiveContactSuccess, (state, { client }) =>
    produce(state, draft => {
      const index = draft.data.findIndex(({ id }) => id === client.id);
      draft.data[index] = {
        // @ts-expect-error TS2698
        ...state.data[index],
        // @ts-expect-error TS2322
        active: false,
        // @ts-expect-error TS2322
        archived: true
      };
    })
  ),
  on(tagClientSuccess, (state, { client }) =>
    produce(state, draft => {
      const index = draft.data.findIndex(({ id }) => id === client.id);

      // @ts-expect-error TS2322
      draft.data[index] = client;
    })
  ),

  // Tags
  on(updateTagsToClient, (state, { client, tags }) =>
    produce(state, draft => {
      const index = draft.data.findIndex(({ id }) => id === client.id);

      // @ts-expect-error TS2339
      const contact = draft.data[index].data;

      // @ts-expect-error TS2322
      draft.data[index] = new ContactViewModel({
        ...contact,
        customFields: {
          ...contact.customFields,
          client_tags: tags
        }
      });
    })
  ),
  on(bulkUpdate, (state, { update }) =>
    produce(state, draft => {
      Object.entries(update).forEach(([clientId, updates]) => {
        let index = draft.data.findIndex(({ id }) => id === clientId);

        if (draft.data[index] === undefined) {
          index = draft.data.findIndex(({ relationId }) => relationId === clientId);
        }

        // @ts-expect-error TS2339
        const contact = draft.data[index]?.data;

        if (!contact) {
          return;
        }

        // @ts-expect-error TS2322
        draft.data[index] = new ContactViewModel({
          ...contact,
          ...updates
        });
      });
    })
  )
);
