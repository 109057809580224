import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import {
  Session,
  SessionActionType,
  SessionAttribute,
  SessionIcon,
  SessionPropertyFactory,
  SessionStatus
} from '@libs/business/cdk/session';

export class SessionAdapter<Meta, Parent, Attribute extends SessionAttribute<string>>
  implements Session<Meta, Parent, Attribute>
{
  actionTypes: SessionActionType[];
  attributes: Attribute[];
  available: number;
  icon?: SessionIcon;
  id: number;
  parent: Parent | undefined;
  status?: SessionStatus;
  title: string;
  connectionType: SessionConnectionTypes;
  total: number;
  showJoinButton: boolean;

  // ToDo rework & remove
  templateId: number;

  // ToDo temporary
  type: GuideServiceTypes;

  constructor(readonly meta: Meta, private readonly factory: SessionPropertyFactory<Meta, Parent, Attribute>) {
    this.icon = this.factory.getIcon(this.meta);
    this.status = this.factory.getStatus(this.meta);
    this.title = this.factory.getSessionName(this.meta);
    this.attributes = this.factory.getAttributes(this.meta);
    this.actionTypes = this.factory.getActions(this.meta);
    this.id = this.factory.getId(this.meta);
    this.total = this.factory.getTotal(this.meta);
    this.available = this.factory.getAvailable(this.meta);
    this.connectionType = this.factory.getConnectionType(this.meta);
    this.parent = this.factory.getParent(this.meta);
    this.showJoinButton = this.factory.getShowJoinButton(this.meta);

    // ToDo temporary
    this.type = this.factory.getSessionType(this.meta);

    // ToDo rework & remove
    this.templateId = this.factory.getTemplateId(this.meta);
  }
}
