import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  private loadings = 0;
  debug = false;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get loading() {
    return this.loadings > 0;
  }

  constructor(private _zone: NgZone) {}

  // @ts-expect-error TS2322
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadingStart(context: string = null) {
    this._zone.run(() => {
      this.loadings += 1;
      if (this.debug) {
        console.log('loadingStart', context, this.loadings);
      }
    });
  }

  // @ts-expect-error TS2322
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadingEnd(context: string = null) {
    this._zone.run(() => {
      this.loadings -= 1;
      if (this.debug) {
        console.log('loadingEnd', context, this.loadings);
      }
    });
  }
}
