import { Inject, Injectable, Injector } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { WIDGET_SERVICE } from '@app/cdk/widgets';
import { ConditionsService } from '@app/modules/sidenav/services/view-conditions.service';
import { ViewCandidateService } from '@app/modules/sidenav/services/view-candidate.service';
import { ViewHostService } from './view-host.service';
import { SidenavSlots, ViewCandidate, ViewCandidateMap, ViewHostCandidateMap, ViewHostType } from '../types';

import { SIDENAV_CANDIDATES, SIDENAV_SLOT_PARENT } from '../tokens';

@Injectable()
export class ViewWidgetService<T extends SidenavSlots> {
  constructor(
    private readonly _hosts$: ViewHostService<T>,
    private readonly _candidates$: ViewCandidateService,
    private readonly _conditions: ConditionsService,
    private readonly _injector: Injector,
    @Inject(SIDENAV_CANDIDATES) private readonly _candidatesList: ViewHostCandidateMap
  ) {
    this.registerCandidates(_candidatesList);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  observeWidgets$(host: ViewHostType<T>): Observable<any> {
    return this._candidates$.pipe(
      // @ts-expect-error TS7053
      map(candidates => candidates[host.name]),
      mergeMap(this._resolveConditions),
      tap(candidates => this._assignHostWithCandidates(host, candidates))
    );
  }

  registerCandidates(candidates: ViewHostCandidateMap): void {
    this._candidates$.registerCandidates(candidates);
  }

  attachHost(host: ViewHostType<T>): void {
    this._hosts$.addHost(host);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _resolveConditions = (candidates: ViewCandidateMap<T>): Observable<Partial<ViewHostCandidateMap>> => {
    if (!candidates) {
      // @ts-expect-error TS2322
      return of(null);
    }
    return combineLatest(
      Object.entries<ViewCandidate>(candidates).map(([slot, candidate]) => {
        return this._conditions.get$(candidate?.condition).pipe(map(status => (status ? [slot, candidate] : null)));
      })
    ).pipe(
      map(result => result.filter(Boolean)),
      map(result => result.reduce((acc, [key, value]: [string, ViewCandidate]) => ({ ...acc, [key]: value }), {}))
    );
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _assignHostWithCandidates(host: ViewHostType<T>, candidates: ViewCandidateMap<T>): void {
    if (!candidates) {
      return;
    }

    host.slots.forEach(slot => {
      const outlet = host[slot];
      const candidate = candidates[slot];
      if (outlet && outlet.hasAttached() && !candidate) {
        outlet.detach();
      }

      if (outlet && candidate) {
        const { component } = candidate;
        if (component && !outlet.hasAttached()) {
          // @ts-expect-error TS2322
          let injector: Injector = null;
          if (candidate.context) {
            injector = Injector.create({
              parent: this._injector,
              providers: [
                { provide: WIDGET_SERVICE, useExisting: candidate.context },
                { provide: SIDENAV_SLOT_PARENT, useValue: host }
              ]
            });
          }

          const portal = new ComponentPortal(component, null, injector);
          const ref = outlet.attachComponentPortal(portal);
          ref.changeDetectorRef.detectChanges();
        }
      }
    });
  }
}
