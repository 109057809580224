import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterChatList'
})
export class FilterChatListPipe implements PipeTransform {
  transform<T extends { name: string }>(chats: T[], searchText: string): T[] {
    // @ts-expect-error TS7034
    let filteredChats;
    const searchTextLowerCase = searchText ? searchText.toLowerCase() : searchText;

    if (!chats || !chats.length) {
      filteredChats = [];
    } else if (!searchText) {
      filteredChats = chats;
    } else {
      filteredChats = chats.filter(item => item.name.toLowerCase().includes(searchTextLowerCase));
    }

    // @ts-expect-error TS7005
    return filteredChats;
  }
}
