import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuideServiceLocationIconModule } from '@app/modules/guide-service/components/guide-service-location-icon/guide-service-location-icon.module';
import { PuiButtonModule } from '@awarenow/profi-ui-core';

import { ClientSessionJoinButtonComponent } from './client-session-join-button.component';

@NgModule({
  imports: [CommonModule, PuiButtonModule, GuideServiceLocationIconModule],
  declarations: [ClientSessionJoinButtonComponent],
  exports: [ClientSessionJoinButtonComponent]
})
export class ClientSessionJoinButtonModule {}
