<div class="assign-member">
  <div class="assign-member--title" i18n="@@shareFormTitle">Share form</div>
  <div class="assign-member--subtitle" i18n="@@shareFormSubtitleCaptionText">
    Share a copy of the form and its automation rules with your teammate by entering their name or email below.
  </div>

  <div class="assign-member-content">
    <app-form-field #field>
      <app-input-label i18n="@@inviteTeamMember">Name or email</app-input-label>
      <app-team-members-select
        appInput
        [formControl]="control"
        [idsToExclude]="idsToExclude"
        [autocompleteConnectedTo]="field.origin"></app-team-members-select>
    </app-form-field>
  </div>

  <div class="assign-member-footer">
    <app-button
      className="btn-primary"
      i18n="@@shareButtonText"
      (click)="handleSubmit()"
      [disabled]="!control.value.length">
      Share
    </app-button>
  </div>
</div>
