import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@app/shared';

import { AlternativeAuthProvidersComponent } from './components/alternative-auth-providers/alternative-auth-providers.component';
import { AuthModalComponent } from './components/auth-modal/auth-modal.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpAlternativeComponent } from './components/sign-up-alternative/sign-up-alternative.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

import { AlternativeAuthProvidersService } from './services/alternative-auth-providers.service';
import { PuiFieldsCoreModule, PuiOtpInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { TfaBackupCodeComponent } from './components/tfa-backup-code/tfa-backup-code.component';
import { TfaSmsAuthComponent } from './components/tfa-sms-auth/tfa-sms-auth.component';
import { SmsOtpInputModule } from '../sms-otp-input/sms-otp-input.module';
import { TFA_SMS_OTP_SERVICE } from '../sms-otp-input/tokens';
import { AuthService } from '@app/core/auth/services';
import { ArrowLoaderModule } from '@app/shared/components/arrow-loader/arrow-loader.module';
import { TfaAppAuthModule } from '@app/modules/auth/components/tfa-app-auth/tfa-app-auth.module';
import { CaptchaModule } from '@libs/captcha/captcha.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    CaptchaModule.forFeature(),
    CommonModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    PuiOtpInputModule,
    PuiFieldsCoreModule,
    PuiTypographyModule,
    SmsOtpInputModule,
    ArrowLoaderModule,
    TfaAppAuthModule,
    TranslocoRootModule
  ],
  declarations: [
    AlternativeAuthProvidersComponent,
    AuthModalComponent,
    ConfirmEmailComponent,
    SignInComponent,
    SignUpAlternativeComponent,
    SignUpComponent,
    WelcomeComponent,
    TfaBackupCodeComponent,
    TfaSmsAuthComponent
  ],
  exports: [AlternativeAuthProvidersComponent, AuthModalComponent, SignInComponent, SignUpComponent],
  providers: [
    AlternativeAuthProvidersService,
    {
      provide: TFA_SMS_OTP_SERVICE,
      useExisting: AuthService
    }
  ]
})
export class AuthModule {}
