import { Component, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { ClientNote } from '@app/modules/client-notes/client-notes.type';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-note-event-meta',
  templateUrl: './note-event-meta.component.html',
  styleUrls: ['./note-event-meta.component.scss']
})
export class NoteEventMetaComponent {
  readonly GuideServiceTypes = GuideServiceTypes;

  @Input()
  // @ts-expect-error TS2564
  note: GuideNote | ClientNote;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get eventInterval() {
    const { start, end } = this.note.metaEvent;
    return { dateStart: start, dateEnd: end };
  }
}
