import { Injectable } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PhoneVerificationModalComponent } from './../components/phone-verification-modal/phone-verification-modal.component';

@Injectable()
export class PhoneVerificationFacadeService {
  constructor(private ngbModal: NgbModal) {}

  openModal(): NgbModalRef {
    return this.ngbModal.open(PhoneVerificationModalComponent, {
      size: 'sm',
      centered: true,
      backdropClass: 'backdrop-low-z-index',
      windowClass: `session-feedback-modal`
    });
  }
}
