import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { IBillingInfo, ISupportedCountry, ValidationMeta } from '@app/modules/billing/interfaces';
import { SupportedCountries } from '@app/modules/billing/types';

@Component({
  selector: 'app-billing-form-wrapper',
  templateUrl: './billing-form-wrapper.component.html',
  styleUrls: ['./billing-form-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingFormWrapperComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentCustomValidations: (keyof IBillingInfo)[] = [];

  // @ts-expect-error TS2564
  @Input() isModal: boolean;

  @Input() btnText = 'Save Changes';

  @Input() i18nBtnText = '@@billingFormSaveChanges';

  // @ts-expect-error TS2564
  @Input() billingForm: FormGroup;

  // @ts-expect-error TS2564
  @Input() countries: SupportedCountries;

  @Output() submitted: EventEmitter<IBillingInfo> = new EventEmitter<IBillingInfo>();

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handleCountryChange(countryId: number) {
    if (!this.countries) {
      return;
    }

    // eslint-disable-next-line id-length
    const country = this.countries.find((c: ISupportedCountry) => c.id === countryId);
    if (country) {
      this.clearValidations();

      const { validation } = country;

      if (validation && validation.meta) {
        this.setValidations(validation.meta);
      }
    }
  }

  handleSubmit(): void {
    this.submitted.emit(this.billingForm.value);
  }

  // @ts-expect-error TS7006
  trimValue(formControl): void {
    formControl.setValue(formControl.value.trim());
  }

  isRequired(key: keyof IBillingInfo): boolean {
    return this._currentCustomValidations.includes(key);
  }

  private clearValidations(): void {
    this._currentCustomValidations.forEach((key: keyof IBillingInfo) => {
      this.billingForm.controls[key].clearValidators();
      this.billingForm.controls[key].updateValueAndValidity();
      this._currentCustomValidations = [];
      this._cdr.detectChanges();
    });
  }

  private setValidations(validation: ValidationMeta): void {
    Object.keys(validation).forEach((key: keyof IBillingInfo) => {
      if (validation[key].required) {
        this.billingForm.controls[key].setValidators([Validators.required]);
        this.billingForm.controls[key].updateValueAndValidity();
        this._currentCustomValidations.push(key);
        this._cdr.detectChanges();
      }
    });
  }
}
