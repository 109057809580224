import { Pipe, PipeTransform } from '@angular/core';
import { ICommunityTag } from '@app/screens/our-community/interfaces';

@Pipe({
  name: 'stringTags'
})
export class StringTagsPipe implements PipeTransform {
  transform(value: string): ICommunityTag[] {
    return value ? this.extractTagsFromString(value) : [];
  }

  private extractTagsFromString = (value: string): ICommunityTag[] =>
    value
      .split(',')
      .map(tag => ({ name: tag.trim() }))
      .filter(tag => tag.name !== '');
}
