import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { map } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticCopyTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { BookWidgetCodeService } from '../../services/book-widget-code.service';
import { EmbeddableCodeSource } from '@app/modules/embeddable-book-button/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-embeddable-book-button-modal',
  templateUrl: './embeddable-book-button-modal.component.html',
  styleUrls: ['./embeddable-book-button-modal.component.scss']
})
export class EmbeddableBookButtonModalComponent implements OnInit {
  analyticCopyTypes = AnalyticCopyTypes;

  buttonOptionsControl = new FormControl();

  embeddableCode = '';

  @Input()
  enableNotifications = true;

  @Input()
  // @ts-expect-error TS2564
  source: EmbeddableCodeSource;

  constructor(
    public modal: NgbActiveModal,
    private _notifications: NotificationsService,
    private _analyticsService: AnalyticsService,
    private _bookWidget: BookWidgetCodeService
  ) {}

  ngOnInit(): void {
    this.buttonOptionsControl.valueChanges
      .pipe(map(({ text, ...style }) => this._bookWidget.generatePopupWidgetCode({ text, style, source: this.source })))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(code => (this.embeddableCode = code));

    const text = `Book a session`;
    this.buttonOptionsControl.setValue({
      text,
      backgroundColor: '#ff0000',
      textColor: '#ffffff'
    });
  }

  onEmbeddableCodeCopied(copyType: AnalyticCopyTypes): void {
    this._analyticsService.event(InternalEvents.LINK_COPY, {
      copy_type: copyType,
      link_type: AnalyticSourceTypes.EMBEDDABLE_WIDGET
    });
    if (this.enableNotifications) {
      const title = `Embeddable booking code copied`;
      this._notifications.success(title);
    }
  }

  onEmbeddableCodeCopyFailed(): void {
    if (this.enableNotifications) {
      const title = `Embeddable booking code copy failed`;
      this._notifications.error(title);
    }
  }
}
