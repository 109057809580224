import { of, Subject, Subscription } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { DRAWER_CONFIG } from '@app/modules/guide-client/services';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { isChoiceQuestion } from '@app/screens/client/client-programs/utils';
import { LoadModuleFormComponent } from '@app/screens/guide/guide-programs/components/load-module-form/load-module-form.component';
import { RestrictedModule } from '@app/screens/guide/guide-programs/types/program-module/restricted-module';
import { GuideDiscardChangesDialogComponent } from '@app/screens/guide/guide-shared/components/guide-discard-changes-dialog/guide-discard-changes-dialog.component';
import { defaultRequiredValidator } from '@app/shared/form-validators/default-required.validator';
import { activationValidator } from '@app/shared/form-validators/module-activation.validator';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { PuiDialogService, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';

import { GuideProgramOptionsService } from '../../services/guide-program-options.service';
import {
  cleanContentModule,
  cleanQuestionDetails,
  cleanQuestionOptionDetails,
  cleanQuiz,
  cleanQuizModule,
  ContentModule,
  EditableProgramModule,
  GroupSessionModule,
  IProgramOptions,
  IQuestionDetails,
  IQuizDetails,
  isContentModule,
  isGroupSessionModule,
  isQuizModule,
  isRestrictedModule,
  isSessionModule,
  ModuleAccessTypes,
  patchProgramModule,
  ProgramModule,
  Question,
  QuestionOption,
  Quiz,
  QuizModule,
  SessionModule
} from '../../types';
import { createModuleActivationOption, parseModuleActivationOption } from '../../types/helpers';
import { randomVal } from './utils';

export class ModulesFormManager {
  private readonly destroy$: Subject<void> = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _modulesForm: FormGroup;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programOptionsValues: IProgramOptions;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _modulesInstructorsSubscriptions = new Map<number, Subscription>();

  get modulesForm(): FormGroup {
    return this._modulesForm;
  }

  constructor(
    readonly programOptions: GuideProgramOptionsService,
    protected readonly _formBuilder: FormBuilder,
    private readonly _workspaceService: WorkspacesService,
    private readonly _drawer: PuiDrawerService,
    private readonly dialog: PuiDialogService
  ) {
    this.programOptions.options$
      .pipe(takeUntil(this.destroy$))
      .subscribe(optionsValues => (this._programOptionsValues = optionsValues));
  }

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._resetModulesInstructorsSubscriptions();
  }

  addModule(moduleType: ModuleTypes, data?: ProgramSessionTemplateInterface, showRecurringAlert = false): void {
    const modules = this._modulesForm.get('modules') as FormArray;

    const newModuleFormGroup = this._formBuilder.group({
      id: [null],
      localId: [randomVal()],
      moduleType: [moduleType],
      title: [null],
      titleDeltaFormat: [null],
      description: [null],
      descriptionDeltaFormat: [null],
      order: [modules.length + 1],
      activation: [this._programOptionsValues.moduleActivations[0].value, activationValidator],
      instructor: [null],
      completionType: [null]
    });

    switch (moduleType) {
      case ModuleTypes.CONTENT:
        newModuleFormGroup.addControl('content', this._formBuilder.control(null));
        break;
      case ModuleTypes.QUIZ:
        newModuleFormGroup.addControl(
          'quiz',
          this._formBuilder.group({
            id: [null],
            localId: [randomVal()],
            questions: this._formBuilder.array([this._createEmptyQuestionFormGroup()])
          })
        );
        break;
      case ModuleTypes.SESSION:
      case ModuleTypes.GROUP_SESSION: {
        newModuleFormGroup.addControl('serviceId', this._formBuilder.control(null));
        newModuleFormGroup.addControl('showRecurringAlert', this._formBuilder.control(showRecurringAlert));
        newModuleFormGroup.addControl('sessionTemplateDraft', this._formBuilder.control(data));
        break;
      }
      default:
        break;
    }

    modules.push(newModuleFormGroup);
    this._modulesForm.markAsDirty();

    this._addModuleInstructorSubscription(newModuleFormGroup);
  }

  addQuestion(event: { moduleId: number; type: QuizQuestionType }): void {
    const modules = this._modulesForm.get('modules') as FormArray;
    const moduleGroup = modules.controls.find(control => control.value.localId === event.moduleId) as FormGroup;
    const moduleQuizGroup = moduleGroup.get('quiz');
    // @ts-expect-error TS2531
    const quizQuestions = moduleQuizGroup.get('questions') as FormArray;

    quizQuestions.push(this._createEmptyQuestionFormGroup(event.type));
  }

  addQuestionOption(event: { moduleId: number; questionIndex: number }): void {
    const { moduleId, questionIndex } = event;
    const modules = this._modulesForm.get('modules') as FormArray;

    const moduleGroup = modules.controls.find(control => control.value.localId === moduleId) as FormGroup;
    // @ts-expect-error TS2531
    const quizQuestionOptionGroups = (moduleGroup.get('quiz').get('questions') as FormArray)
      .at(questionIndex)
      .get('options') as FormArray;

    quizQuestionOptionGroups.push(this._createEmptyQuestionOption());
  }

  cloneModule(moduleIndex: number): void {
    const modules = this._modulesForm.get('modules') as FormArray;
    const module = modules.value[moduleIndex];

    const newModuleFormGroup = this._formBuilder.group({
      localId: [randomVal()],
      order: [modules.length + 1],
      title: [module.title],
      titleDeltaFormat: [module.titleDeltaFormat],
      description: [module.description],
      descriptionDeltaFormat: [module.descriptionDeltaFormat],
      activation: [module.activation],
      moduleType: [module.moduleType],
      completionType: [module.completionType],
      instructor: [module.instructor]
    });

    switch (module.moduleType) {
      case ModuleTypes.CONTENT:
        newModuleFormGroup.addControl('content', this._formBuilder.control(module.content));
        break;
      case ModuleTypes.QUIZ:
        newModuleFormGroup.addControl(
          'quiz',
          this._formBuilder.group({
            id: [null],
            localId: [randomVal()],
            questions: this._formBuilder.array(
              // @ts-expect-error TS7006
              module.quiz.questions.map(question =>
                this._formBuilder.group({
                  localId: [randomVal()],
                  question: [question.question],
                  type: [question.type],
                  hasOptions: [question.hasOptions],
                  hasOptionExplanation: [question.hasOptionExplanation],
                  options: this._formBuilder.array(
                    // @ts-expect-error TS7006
                    question.options.map(option =>
                      this._formBuilder.group({
                        localId: [randomVal()],
                        text: [option.text],
                        isCorrect: [option.isCorrect],
                        explanation: [option.explanation]
                      })
                    )
                  )
                })
              )
            )
          })
        );
        break;
      case ModuleTypes.GROUP_SESSION:
      case ModuleTypes.SESSION:
        newModuleFormGroup.addControl('serviceId', this._formBuilder.control(module.serviceId));
        newModuleFormGroup.addControl('service', this._formBuilder.control(module.service));
        newModuleFormGroup.addControl('sessionTemplateDraft', this._formBuilder.control(module.sessionTemplateDraft));
        newModuleFormGroup.addControl('showRecurringAlert', this._formBuilder.control(module.showRecurringAlert));
        break;
      default:
        break;
    }

    modules.push(newModuleFormGroup);
    this._addModuleInstructorSubscription(newModuleFormGroup);
  }

  removeModule(moduleIndex: number): void {
    const { modules } = this._modulesForm.value;

    if (modules.length <= 1) {
      return;
    }

    const [filteredModules, removedModule] = modules.reduce(
      // @ts-expect-error TS7006
      (result, formModule, index) => {
        if (moduleIndex !== index) {
          result[0].push(formModule);
        } else {
          result[1] = formModule;
        }

        return result;
      },
      [[]]
    );

    if (removedModule) {
      this._removeModuleInstructorSubscription(removedModule.localId);
    }

    // @ts-expect-error TS7006
    const newModules = filteredModules.map((formModule, index) =>
      this._toProgramModule({
        ...formModule,
        order: index + 1,
        activation: index === 0 ? modules[index].activation : formModule.activation
      })
    );

    this._modulesForm.setControl('modules', this._formBuilder.array(this._toModulesFormGroups(newModules)));
  }

  removeQuestionAtIndex(event: { moduleId: number; questionIndex: number }): void {
    const { moduleId, questionIndex } = event;

    const quizQuestions = this._getModuleQuizQuestions(moduleId);
    if (quizQuestions) {
      if (quizQuestions.length) {
        quizQuestions.removeAt(questionIndex);
      }

      // NOTIFICATION: if there is not quiz questions after previous action, then remove quiz itself
      if (!quizQuestions.length) {
        this._getModule(moduleId).removeControl('quiz');
      }
    }
  }

  removeQuestionOptionAtIndex(event: { moduleId: number; questionIndex: number; optionIndex: number }): void {
    const { moduleId, questionIndex, optionIndex } = event;

    const quizQuestions = this._getModuleQuizQuestions(moduleId);
    if (quizQuestions) {
      const questionOptions = quizQuestions.at(questionIndex).get('options') as FormArray;
      if (questionOptions && questionOptions.length > 1) {
        questionOptions.removeAt(optionIndex);
      }
    }
  }

  reorderModules(event: { previousIndex: number; currentIndex: number }): void {
    const { previousIndex, currentIndex } = event;

    if (previousIndex === currentIndex) {
      return;
    }

    const modules = this.toProgramModules(this._modulesForm.value.modules) as EditableProgramModule[];

    moveItemInArray(modules, previousIndex, currentIndex);

    const newOrderedModules = modules.map((module, index) =>
      patchProgramModule(module, {
        order: index + 1
      })
    );

    this._resetModulesInstructorsSubscriptions();

    this._modulesForm.setControl('modules', this._formBuilder.array(this._toModulesFormGroups(newOrderedModules)));

    this._addModulesInstructorsSubscriptions();
  }

  setModulesForm(originalModules: ProgramModule[]): void {
    const modules = originalModules.length
      ? originalModules
      : [
          new ContentModule({
            localId: randomVal(),
            order: 1,
            activationType: 'deferral',
            activationValue: 'P0D',
            title: null,
            titleText: null,
            accessType: ModuleAccessTypes.EDITABLE,
            description: null,
            content: null
          })
        ];

    if (!this._modulesForm) {
      this._initializeModulesForm(modules);
    } else {
      this._updateModulesForm(modules);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toProgramModules(modules?: any): ProgramModule[] {
    // @ts-expect-error TS7006
    return (modules || this.modulesForm.getRawValue().modules).map(formModule => this._toProgramModule(formModule));
  }

  updateModuleTitle(event: { moduleId: number; title: string }): void {
    const { moduleId, title } = event;

    const modules = this._modulesForm.get('modules') as FormArray;

    const moduleGroup = modules.controls.find(control => control.value.localId === moduleId) as FormGroup;
    const moduleTitleControl = moduleGroup.get('title');
    // @ts-expect-error TS2531
    moduleTitleControl.setValue(title, { emitEvent: false });
  }

  updateModuleDescription(event: { moduleId: number; description: string | null }): void {
    const { moduleId, description } = event;

    const modules = this._modulesForm.get('modules') as FormArray;

    const moduleGroup = modules.controls.find(control => control.value.localId === moduleId) as FormGroup;
    const moduleDescriptionControl = moduleGroup.get('description');
    // @ts-expect-error TS2531
    moduleDescriptionControl.setValue(description, { emitEvent: false });
  }

  loadModuleForm(moduleId: number): void {
    const moduleQuizQuestions = this._getModuleQuizQuestions(moduleId);
    const drawerRef: PuiDrawerRef<IQuizDetails> = this._drawer.open(LoadModuleFormComponent, DRAWER_CONFIG);
    drawerRef.afterClosed$
      .pipe(
        take(1),
        filter(quiz => !!quiz),
        switchMap(quiz =>
          this._modulesForm.dirty
            ? this.dialog.open(GuideDiscardChangesDialogComponent, { data: quiz }).afterClosed$
            : of(quiz)
        ),
        filter(quiz => !!quiz),
        takeUntil(this.destroy$)
      )
      .subscribe(quiz => {
        // @ts-expect-error TS2531
        moduleQuizQuestions.clear();
        // @ts-expect-error TS7006
        quiz.questions.forEach(question => {
          // @ts-expect-error TS2531
          moduleQuizQuestions.push(this.convertQuizQuestion(question));
        });
        this._modulesForm.markAsPristine();
      });
  }

  private convertQuizQuestion(question: Question): FormGroup {
    const controlConfig = this.defaultValuesEmptyQuestion(question.type);
    // @ts-expect-error TS2322
    controlConfig.question = [question.question];
    switch (question.type) {
      case QuizQuestionType.QUIZ:
      case QuizQuestionType.SINGLE_CHOICE:
      case QuizQuestionType.MULTIPLE_CHOICE: {
        const { hasOptionExplanation, hasOtherOption } = this.detectQuestionConditions(question);
        controlConfig.hasOptionExplanation = [hasOptionExplanation];
        controlConfig.hasOtherOption = [hasOtherOption, []];
        if (hasOtherOption) {
          const otherOption = this.getOtherOption(question);
          controlConfig.other = this.buildQuestionOptionFormGroup(otherOption);
        }
        // @ts-expect-error TS2345
        controlConfig.options = this._formBuilder.array(this._toQuestionOptionFormGroups(question.options));
        break;
      }
      case QuizQuestionType.SHORT_ANSWER:
      case QuizQuestionType.TEXT:
      case QuizQuestionType.SIGNATURE:
      case QuizQuestionType.FILE_UPLOAD:
      case QuizQuestionType.LONG_ANSWER:
        break;
    }
    return this._formBuilder.group(controlConfig);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private defaultValuesEmptyQuestion(type: QuizQuestionType) {
    return {
      localId: [randomVal()],
      question: [null],
      type: [type, []],
      hasOptions: [isChoiceQuestion(type)],
      hasOptionExplanation: [false],
      hasOtherOption: [false, []],
      other: this._createEmptyQuestionOption(),
      options: this._formBuilder.array([
        this._createEmptyQuestionOption(),
        this._createEmptyQuestionOption(),
        this._createEmptyQuestionOption()
      ])
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createEmptyQuestionFormGroup(type = QuizQuestionType.QUIZ): FormGroup {
    const questionGroup = this._formBuilder.group(this.defaultValuesEmptyQuestion(type));

    // @ts-expect-error TS2531
    questionGroup
      .get('hasOtherOption')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const textControl = questionGroup.get('other.text');

        if (value) {
          // @ts-expect-error TS2531
          textControl.setValidators(defaultRequiredValidator);
        } else {
          // @ts-expect-error TS2531
          textControl.clearValidators();
          // @ts-expect-error TS2531
          textControl.setErrors(null);
        }

        // @ts-expect-error TS2531
        textControl.updateValueAndValidity();
      });

    return questionGroup;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createEmptyQuestionOption(): FormGroup {
    return this._formBuilder.group({
      localId: [randomVal()],
      text: [null],
      isCorrect: [false],
      explanation: [null],
      other: [false, []]
    });
  }

  private buildQuestionOptionFormGroup(questionOption: QuestionOption): FormGroup {
    return this._formBuilder.group({
      id: [questionOption.id],
      localId: [questionOption.localId || randomVal()],
      text: [questionOption.text],
      isCorrect: [questionOption.isCorrect],
      explanation: [questionOption.explanation],
      other: [questionOption.other, []]
    });
  }

  private getOtherOption(question: Question): QuestionOption {
    // @ts-expect-error TS2322
    return question.options.find(option => option.other);
  }

  private detectQuestionConditions(question: IQuestionDetails): {
    hasOptions: boolean;
    hasOptionExplanation: boolean;
    hasOtherOption: boolean;
  } {
    let hasOptionExplanation = false;
    let hasOtherOption = false;
    const hasOptions = isChoiceQuestion(question.type) && !!(question.options && question.options.length > 0);

    if (hasOptions) {
      // @ts-expect-error TS2533
      hasOptionExplanation = question.options.some(op => !!op.explanation);
      // @ts-expect-error TS2533
      hasOtherOption = question.options.some(op => op.other);
    }

    return { hasOptions, hasOptionExplanation, hasOtherOption };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getModule(moduleId: number): FormGroup {
    return (this._modulesForm.get('modules') as FormArray).controls.find(
      control => control.value.localId === moduleId
    ) as FormGroup;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getModuleQuizQuestions(moduleId: number): FormArray | null {
    const modules = this._modulesForm.get('modules') as FormArray;

    const moduleGroup = modules.controls.find(control => control.value.localId === moduleId) as FormGroup;
    const moduleQuizGroup = moduleGroup.get('quiz');

    return moduleQuizGroup ? (moduleQuizGroup.get('questions') as FormArray) : null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initializeModulesForm(modules: ProgramModule[]): void {
    this._modulesForm = this._formBuilder.group({
      modules: this._formBuilder.array(this._toModulesFormGroups(modules))
    });
    this._resetModulesInstructorsSubscriptions();
    this._addModulesInstructorsSubscriptions();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toModuleQuiz(quiz): Quiz {
    if (quiz == null) {
      return quiz;
    }

    const { questions, ...moduleQuiz } = quiz;
    if (questions && questions.length) {
      moduleQuiz.questions = this._toModuleQuizQuestions(questions);
    }

    return new Quiz(cleanQuiz(moduleQuiz));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toModuleQuizQuestions(questions): Question[] {
    // @ts-expect-error TS7031
    return questions.map(({ hasOptions, hasOptionExplanation, options, type, ...question }) => {
      question.type = type || QuizQuestionType.QUIZ;
      question.localId = question.localId || question.id;

      if (hasOptions && isChoiceQuestion(question.type)) {
        const otherOption = question.other;
        if (question.other) {
          otherOption.other = true;
          options.push(otherOption);
        }
        const questionOptions = this._toModuleQuizQuestionOptions(options, hasOptionExplanation);

        if (questionOptions.length) {
          question.options = questionOptions;
          question.serviceType = question.type;
        }
      }

      return new Question(cleanQuestionDetails(question));
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toModuleQuizQuestionOptions(options, hasOptionExplanation: boolean): QuestionOption[] {
    if (!options || !options.length) {
      // @ts-expect-error TS2322
      return null;
    }

    return (
      options // @ts-expect-error TS7006
        .filter(option => !!(option.text && option.text.trim()))
        // @ts-expect-error TS7031
        .map(({ explanation, other, ...option }) => {
          option.explanation = hasOptionExplanation ? explanation : null;
          option.other = other || false;

          return new QuestionOption(cleanQuestionOptionDetails(option));
        })
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toModuleQuizQuestionFormGroups(quiz: Quiz): FormGroup[] {
    return quiz.questions && quiz.questions.length
      ? quiz.questions.map(question => {
          const { hasOptions, hasOptionExplanation, hasOtherOption } = this.detectQuestionConditions(question);

          const questionFormGroup = this._formBuilder.group({
            id: [question.id],
            localId: [question.localId],
            question: [question.question],
            type: [question.type],
            hasOptions: [hasOptions],
            hasOptionExplanation: [hasOptionExplanation],
            hasOtherOption: [hasOtherOption, []]
          });

          if (hasOtherOption) {
            const otherOption = this.getOtherOption(question);
            questionFormGroup.setControl('other', this.buildQuestionOptionFormGroup(otherOption));
          }

          const questionOptions =
            question.options && question.options.length
              ? question.options
              : [
                  new QuestionOption({
                    localId: randomVal(),
                    // @ts-expect-error TS2322
                    text: null,
                    isCorrect: false,
                    // @ts-expect-error TS2322
                    explanation: null
                  })
                ];
          questionFormGroup.setControl(
            'options',
            this._formBuilder.array(this._toQuestionOptionFormGroups(questionOptions))
          );

          return questionFormGroup;
        })
      : [];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toBaseModuleFormGroup(module: ProgramModule): FormGroup {
    return this._formBuilder.group({
      id: [module.id],
      localId: [module.localId],
      title: [module.title],
      titleText: [module.titleText],
      titleDeltaFormat: [module.titleDeltaFormat ? JSON.parse(module.titleDeltaFormat) : null],
      description: [module.description],
      descriptionDeltaFormat: [module.descriptionDeltaFormat ? JSON.parse(module.descriptionDeltaFormat) : null],
      order: [module.order],
      activation: [
        // @ts-expect-error TS2345
        createModuleActivationOption('', module.activationType, module.activationValue).value,
        activationValidator
      ],
      moduleType: [module.moduleType],
      hosts: [module.hosts],
      accessType: [module.accessType],
      instructor: [module.instructor],
      completionType: [module.completionType]
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toEditableModuleFormGroup(module: EditableProgramModule): FormGroup {
    const moduleFormGroup = this._toBaseModuleFormGroup(module);

    if (isContentModule(module)) {
      moduleFormGroup.addControl('content', this._formBuilder.control(module.content));
    }

    if (isQuizModule(module)) {
      moduleFormGroup.addControl(
        'quiz',
        this._formBuilder.group({
          // @ts-expect-error TS2531
          id: [module.quiz.id],
          // @ts-expect-error TS2531
          localId: [module.quiz.localId],
          // @ts-expect-error TS2345
          questions: this._formBuilder.array(this._toModuleQuizQuestionFormGroups(module.quiz))
        })
      );
    }

    if (isGroupSessionModule(module) || isSessionModule(module)) {
      moduleFormGroup.addControl('showRecurringAlert', this._formBuilder.control(false));
      moduleFormGroup.addControl('serviceId', this._formBuilder.control(module.serviceId));
      moduleFormGroup.addControl('service', this._formBuilder.control(module.service));
      moduleFormGroup.addControl('sessionTemplateDraft', this._formBuilder.control(module.sessionTemplateDraft));
    }

    return moduleFormGroup;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toRestrictedModuleFormGroup(module: RestrictedModule): FormGroup {
    const moduleFormGroup = this._toBaseModuleFormGroup(module);

    if (isContentModule(module, ModuleAccessTypes.RESTRICTED)) {
      moduleFormGroup.addControl('content', this._formBuilder.control(module.content));
    }

    if (isQuizModule(module, ModuleAccessTypes.RESTRICTED)) {
      moduleFormGroup.addControl(
        'quiz',
        this._formBuilder.group({
          // @ts-expect-error TS2531
          id: [module.quiz.id],
          // @ts-expect-error TS2531
          localId: [module.quiz.localId],
          // @ts-expect-error TS2345
          questions: this._formBuilder.array(this._toModuleQuizQuestionFormGroups(module.quiz))
        })
      );
    }

    if (
      isGroupSessionModule(module, ModuleAccessTypes.RESTRICTED) ||
      isSessionModule(module, ModuleAccessTypes.RESTRICTED)
    ) {
      moduleFormGroup.addControl('serviceId', this._formBuilder.control(module.serviceId));
      moduleFormGroup.addControl(
        'service',
        this._formBuilder.control({
          ...module.service,
          hosts: module.hosts
        })
      );
      moduleFormGroup.addControl('sessionTemplateDraft', this._formBuilder.control(module.sessionTemplateDraft));
    }

    moduleFormGroup.disable();

    return moduleFormGroup;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toModulesFormGroups(modules: ProgramModule[]): FormGroup[] {
    return modules.length
      ? modules.map(module =>
          isRestrictedModule(module)
            ? this._toRestrictedModuleFormGroup(module)
            : this._toEditableModuleFormGroup(module)
        )
      : [];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _toProgramModule(moduleFormValue: any): ProgramModule {
    const { isTeam, isAdmin } = this._workspaceService;

    const {
      moduleType,
      accessType,
      titleDeltaFormat,
      descriptionDeltaFormat,
      activation,
      content,
      quiz,
      serviceId,
      sessionTemplateDraft,
      ...otherFormProps
    } = moduleFormValue;

    const baseModuleProps = {
      accessType,
      ...otherFormProps,
      ...parseModuleActivationOption(activation),
      titleDeltaFormat: titleDeltaFormat != null ? JSON.stringify(titleDeltaFormat) : null,
      descriptionDeltaFormat: descriptionDeltaFormat != null ? JSON.stringify(descriptionDeltaFormat) : null
    };

    switch (moduleType) {
      case ModuleTypes.CONTENT:
        return new ContentModule(
          cleanContentModule({
            ...baseModuleProps,
            content
          })
        );
      case ModuleTypes.QUIZ:
        return new QuizModule(
          cleanQuizModule({
            ...baseModuleProps,
            quiz: this._toModuleQuiz(quiz)
          })
        );
      case ModuleTypes.GROUP_SESSION:
        return new GroupSessionModule({
          ...baseModuleProps,
          serviceId,
          sessionTemplateDraft,
          ignoreSessionValidation: isTeam && isAdmin,
          title: sessionTemplateDraft?.name || otherFormProps.title
        });
      case ModuleTypes.SESSION:
        return new SessionModule({
          ...baseModuleProps,
          serviceId,
          ignoreSessionValidation: isTeam && isAdmin
        });
    }

    // @ts-expect-error TS2322
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toQuestionOptionFormGroups(options: QuestionOption[]): FormGroup[] {
    return options && options.length
      ? options.filter(option => option.other === false).map(option => this.buildQuestionOptionFormGroup(option))
      : [];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateModulesForm(modules: ProgramModule[]): void {
    this._modulesForm.setControl('modules', this._formBuilder.array(this._toModulesFormGroups(modules)));
    this._resetModulesInstructorsSubscriptions();
    this._addModulesInstructorsSubscriptions();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _addModuleInstructorSubscription(moduleControl: AbstractControl): void {
    const { localId } = moduleControl.value;

    this._removeModuleInstructorSubscription(localId);

    this._modulesInstructorsSubscriptions.set(
      localId,
      // @ts-expect-error TS2531
      moduleControl
        .get('instructor')
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if ([ModuleTypes.GROUP_SESSION, ModuleTypes.SESSION].includes(moduleControl.value.moduleType)) {
            moduleControl.patchValue({ serviceId: null }, { emitEvent: false });
          }
        })
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _addModulesInstructorsSubscriptions(): void {
    const modules = this._modulesForm.get('modules') as FormArray;

    modules.controls.forEach(moduleControl => this._addModuleInstructorSubscription(moduleControl));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _removeModuleInstructorSubscription(moduleLocalId: number): void {
    if (this._modulesInstructorsSubscriptions.has(moduleLocalId)) {
      // @ts-expect-error TS2532
      this._modulesInstructorsSubscriptions.get(moduleLocalId).unsubscribe();
      this._modulesInstructorsSubscriptions.delete(moduleLocalId);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _resetModulesInstructorsSubscriptions(): void {
    this._modulesInstructorsSubscriptions.forEach(subscription => subscription && subscription.unsubscribe());
    this._modulesInstructorsSubscriptions.clear();
  }
}
