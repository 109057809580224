import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InitServiceSchedulingResolver } from '../../resolvers/init-service-scheduling.resolver';
import { ServiceFormComponent } from './service-form.component';

const routes: Routes = [
  {
    path: '',
    component: ServiceFormComponent,
    resolve: {
      _: InitServiceSchedulingResolver
    }
  },
  {
    path: `fail`,
    loadChildren: () => import('../failed-schedule/failed-schedule.module').then(module => module.FailedScheduleModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceFormRoutingModule {}
