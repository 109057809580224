import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChatsBoardComponent } from './components/chats-board/chats-board.component';
import { ActiveChatComponent } from './components/active-chat/active-chat.component';
import { NoActiveChatComponent } from './components/no-active-chat/no-active-chat.component';

import { ActiveChatUserResolver } from './resolvers/active-chat-user.resolver';
import { ChatAvailableGuard } from './guards/chat-available.guard';
import { LastActiveChatRedirectionGuard } from './guards/last-active-chat-redirection.guard';
import { NoChatIfIncompleteProfileGuard } from './guards/no-chat-if-incomplete-profile.guard';
import { NoSelfChatGuard } from './guards/no-self-chat.guard';

const routes: Routes = [
  {
    path: 'chat',
    component: ChatsBoardComponent,
    canActivate: [NoChatIfIncompleteProfileGuard, ChatAvailableGuard],
    children: [
      {
        path: 'select',
        component: NoActiveChatComponent
      },
      {
        path: ':id',
        component: ActiveChatComponent,
        canActivate: [NoSelfChatGuard],
        resolve: {
          user: ActiveChatUserResolver
        }
      },
      {
        path: 'bot/:id',
        component: ActiveChatComponent,
        resolve: {
          user: ActiveChatUserResolver
        },
        data: {
          bot: true
        }
      },
      {
        path: '',
        canActivate: [LastActiveChatRedirectionGuard],
        component: NoActiveChatComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule {}
