<div class="hosts" *ngIf="assignees?.length > 0">
  <p pui-typography="h3" class="hosts__title">
    {{ getTitle() }}
  </p>

  <ul class="hosts__list">
    <li class="hosts__list-item" *ngFor="let host of getAssignees()">
      <app-service-host
        [showBookButton]="sessionType !== 'COLLECTIVE' && showBookButton"
        [showLocation]="sessionType !== 'COLLECTIVE'"
        [host]="host"
        chatLink="/client/chat/{{ getChatLink(host.guideId || host.id) }}"
        [bookingLink]="getBookingLink(host.id, templateId, workspaceId)"></app-service-host>
    </li>
  </ul>

  <div class="hosts__show-more" *ngIf="assignees?.length > MIN_HOSTS_COUNT">
    <button (click)="toggleShowAllHosts()" pui-link pui-typography="body-s" type="button">
      <span>{{ showAllHosts ? 'Show less' : 'Show more' }}</span>
      <pui-icon class="pui-space_left-1" [svgIcon]="showAllHosts ? 'pui:chevron-up' : 'pui:chevron-down'"></pui-icon>
    </button>
  </div>
</div>
