import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { LogType, LogUserInfo } from '@app/core/log/types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { LocalStorageKeys } from '@app/cdk/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getWindow(): any {
  return window;
}

@Injectable({ providedIn: 'root' })
export class LogService {
  private readonly LOG_ID_KEY: LocalStorageKeys = LocalStorageKeys.LOG_ID_KEY;

  private readonly baseUserInfo: LogUserInfo = {
    // @ts-expect-error TS2322
    userEm: null,
    // @ts-expect-error TS2322
    userId: null,
    // @ts-expect-error TS2322
    userNm: null,
    // @ts-expect-error TS2322
    userRl: null
  };

  private readonly isBrowser: boolean;

  // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
  private readonly w: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private console: { [val in LogType]?: any } = {};

  private consoleRelay = true;

  private consoleWrap = true;

  private logId = `${Math.floor(Math.random() * 1e12)}`;

  // @ts-expect-error TS2564
  private userInfo: LogUserInfo;

  constructor(
    private http: HttpClient,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.w = getWindow();
      this.updateUserInfo(null);
      this.wrapConsoleLog();

      const logId = localStorage.getItem(this.LOG_ID_KEY);
      if (logId) {
        this.logId = logId;
      } else {
        localStorage.setItem(this.LOG_ID_KEY, this.logId);
      }
    }
  }

  private wrapConsoleLog(): void {
    if (!this.w.console) {
      this.w.console = {};
    }
    const { console } = this.w;
    try {
      Object.keys(LogType).forEach((key: string) => {
        // @ts-expect-error TS7053
        const logType = LogType[key];
        const origLog = typeof console[logType] === 'function' ? console[logType].bind(console) : function () {};
        if (this.consoleWrap) {
          // @ts-expect-error TS7019
          console[logType] = function (...data) {
            origLog(...data);
          };
        }
        // @ts-expect-error TS7053
        this.console[logType] = origLog;
      });
    } catch (error) {}
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUserInfo(user?: any): void {
    if (user) {
      this.userInfo = {
        userId: String(user.id),
        userNm: `${String(user.firstName).toLowerCase()}_${String(user.lastName).toLowerCase()}`,
        userEm: String(user.email).replace(/\.|@/gi, '_'),
        userRl: String(user.RoleId)
      };
    } else {
      this.userInfo = { ...this.baseUserInfo };
    }
  }

  sendLog(logType: LogType, details: unknown, message?: string): void {
    if (this.isBrowser && this.consoleRelay && this.console[logType]) {
      this.console[logType](details, message);
    }
  }

  log(data: unknown, message?: string): void {
    this.sendLog(LogType.LOG, data, message);
  }

  warn(data: unknown, message?: string): void {
    this.sendLog(LogType.WARN, data, message);
  }

  error(data: unknown, message?: string): void {
    this.sendLog(LogType.ERROR, data, message);
  }
}
