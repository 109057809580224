import { ClientGuide } from '@app/core/users/types';
import { ProgramModule } from './program-module';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientProgramDetails {
  readonly id: number;
  readonly name: string;
  readonly modules: ProgramModule[];
  readonly author: ClientGuide;
  readonly notStarted: boolean;
  readonly workspaceType?: WorkspacesTypes;
}

export class ClientProgram {
  readonly id: number;
  readonly name: string;
  readonly modules: ProgramModule[];
  readonly author: ClientGuide;
  readonly notStarted: boolean;
  readonly workspaceType: WorkspacesTypes | undefined;

  constructor(programDetails: IClientProgramDetails) {
    this.id = programDetails.id;
    this.name = programDetails.name;
    this.modules = programDetails.modules;
    this.author = programDetails.author;
    this.notStarted = programDetails.notStarted;
    this.workspaceType = programDetails.workspaceType;
  }
}
