import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterByNameOrEmailModule } from '@app/shared/pipes/filter-by-name-or-email/filter-by-name-or-email.module';
import { UiKitHelpersModule } from '../../_base/_common/helpers';
import { UserInfoModule } from '../../user-info/user-info.module';
import { UiClientSelectorSingle } from './client-selector';
import { UiSelectorSingleModule } from '../../_base/ui-selector-single';
import { UiOptionModule } from '../../_base/ui-option';
import { UiIconModule } from '../../_base/ui-icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [UiClientSelectorSingle],
  imports: [
    CommonModule,
    UiSelectorSingleModule,
    FormsModule,
    UiOptionModule,
    FilterByNameOrEmailModule,
    UiKitHelpersModule,
    UiIconModule,
    UserInfoModule,
    MatTooltipModule
  ],
  exports: [UiClientSelectorSingle]
})
export class UiClientSelectorSingleModule {}
