import { combineLatest, from, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { GuideProgramClientsService } from '@app/screens/guide/guide-programs/services/guide-program-clients.service';
import {
  GroupSessionModuleProgress,
  IProgramClientProgressDetails,
  isGroupSessionModuleProgress,
  isSessionModuleProgress,
  ProgramClientProgress,
  SessionModuleProgress
} from '@app/screens/guide/guide-programs/types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { assertNever } from '@app/shared/utils/assertions';

import { ServiceSchedulingFiltersService } from './service-scheduling-filters.service';
import { ScheduleSessionConfig, ServiceSchedulingService } from './service-scheduling.service';

@Injectable()
export class SelectedParticipantsNoteMessageService {
  constructor(
    private readonly guideProgramClientsService: GuideProgramClientsService,
    private readonly routeService: ServiceSchedulingService,
    private readonly serviceSchedulingFiltersService: ServiceSchedulingFiltersService
  ) {}

  showNoteParticipantsMessage$(selectedClients$: Observable<CRMClient[]>): Observable<true> {
    const serviceSchedulingProcess = combineLatest([
      this.routeService.config$,
      this.serviceSchedulingFiltersService.servicesStore$,
      selectedClients$.pipe(filter(clients => !!clients?.length))
    ]).pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)));

    return serviceSchedulingProcess.pipe(
      map(this.prepareSelectedService),
      filter(this.skipServicesWithoutParentProgram),
      map(this.prepareSelectedServiceParent),
      map(this.prepareSelectedServiceParentModule),
      switchMap(this.processEachClientSeparately),
      concatMap(this.getClientProgramProgress.bind(this)),
      map(this.prepareShowNoteState),
      filter<true>(Boolean),
      take(1)
    );
  }

  private prepareSelectedService([config, services, clients]: [
    ScheduleSessionConfig,
    GuideServices.RootObject[],
    CRMClient[]
  ]): [ScheduleSessionConfig['service'], GuideServices.RootObject[], CRMClient[]] {
    const { service: serviceTemplate, serviceParent } = config;
    const [serviceItem] = services.filter(service => service.id === config.service?.id);
    const service = {
      ...(serviceTemplate || serviceItem),
      parentService: serviceParent
    } as ScheduleSessionConfig['service'];

    return [service, services, clients];
  }

  private skipServicesWithoutParentProgram([service]: [
    ScheduleSessionConfig['service'],
    GuideServices.RootObject[],
    CRMClient[]
  ]): boolean {
    const parent = service?.parentService;
    return !!parent && parent.type === GuideServiceTypes.PROGRAM;
  }

  private prepareSelectedServiceParent([service, services, clients]: [
    GuideServices.RootObject,
    GuideServices.RootObject[],
    CRMClient[]
  ]): [ScheduleSessionConfig['service'], GuideServices.RootObject, CRMClient[]] {
    const parent = services.find(item => service.parentService!.id === item.id);
    if (!parent) {
      return assertNever(service, "can't find parent for service:");
    }

    return [service, parent, clients];
  }

  private prepareSelectedServiceParentModule([service, parent, clients]: [
    ScheduleSessionConfig['service'],
    GuideServices.RootObject,
    CRMClient[]
  ]): [ScheduleSessionConfig['service'], GuideServices.RootObject, CRMClient[]] {
    const module = parent.modules.find(item => service?.id === item.serviceId);

    if (!module) {
      return assertNever(parent, `can't find module with service id ${service?.id} in Program service:`);
    }

    return [service, parent, clients];
  }

  private processEachClientSeparately([service, parent, clients]: [
    ScheduleSessionConfig['service'],
    GuideServices.RootObject,
    CRMClient[]
  ]): Observable<[CRMClient, ScheduleSessionConfig['service'], GuideServices.RootObject]> {
    return from(clients).pipe(map(client => [client, service, parent]));
  }

  private getClientProgramProgress([client, service, parent]: [
    CRMClient,
    Partial<GuideServices.RootObject>,
    GuideServices.RootObject
  ]): Observable<[IProgramClientProgressDetails, Partial<GuideServices.RootObject>]> {
    return this.guideProgramClientsService
      .getClientProgress$(parent.id, client.clientId)
      .pipe(map(progress => [progress, service]));
  }

  private prepareShowNoteState([progress, service]: [
    ProgramClientProgress,
    Partial<GuideServices.RootObject>
  ]): boolean {
    if (progress.modules.length === 0) {
      return false;
    }

    const module = progress.modules
      .filter(module => isSessionModuleProgress(module) || isGroupSessionModuleProgress(module))
      .find((module: SessionModuleProgress | GroupSessionModuleProgress) => {
        return module.serviceId === service.id;
      });

    if (!module) {
      return assertNever(progress, `Progress for service with id ${service.id} not found in: `);
    }

    return module.status === 'restricted';
  }
}
