import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ISubscription } from '@app/shared/interfaces/subscription';
import { SUBSCRIPTIONS_ENDPOINT } from '@app/shared/constants/endpoints';
import { SubscriptionType } from '@app/shared/enums/subscription';
import { InternalEvents } from '@app/core/analytics/types';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { SocketService } from '@app/core/socket/socket.service';

@Injectable()
export class SubscriptionsService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _subscriptions$ = new ReplaySubject<ISubscription[]>(1);

  private readonly destroy$ = new Subject();

  constructor(
    private readonly _http: HttpClient,
    private readonly _analyticsService: AnalyticsService,
    private readonly _currentPayment: CurrentPaymentService,
    private readonly _socketService: SocketService
  ) {
    merge(
      this._socketService.onMembershipChanged$(),
      this._socketService.onPackageEnrolled(),
      this._socketService.onProgramsChange()
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadSubscriptions());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get subscriptions$() {
    return this._subscriptions$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadSubscriptions() {
    return this._http.get<ISubscription[]>(SUBSCRIPTIONS_ENDPOINT).subscribe(subscriptions => {
      this._subscriptions$.next(subscriptions);
    });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancelSubscription$(id): Observable<any> {
    return this._http
      .post(`${SUBSCRIPTIONS_ENDPOINT}/${id}/cancel`, {})
      .pipe(tap(() => this._analyticsService.event(InternalEvents.PLATFORM_SUBSCRIPTION_CANCELED, {})));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactivateSubscription$(id): Observable<any> {
    return this._currentPayment.pay().subscriptionReactivate$(id);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restartSubscription$(productId, type: SubscriptionType): Observable<any> {
    switch (type) {
      case SubscriptionType.Course:
        return this._currentPayment
          .pay()
          .program$(productId, { paymentOption: PaymentOptions.INSTALLMENTS, serviceType: GuideServiceTypes.PROGRAM });
      case SubscriptionType.Package:
        return this._currentPayment
          .pay()
          .package$(productId, { paymentOption: PaymentOptions.INSTALLMENTS, serviceType: GuideServiceTypes.PACKAGE });
      case SubscriptionType.Membership:
        return this._currentPayment.pay().platformPlan$(productId);
      default:
        return of();
    }
  }
}
