import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributesEnum } from '@libs/business/client/client-session/modules';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';

import { ClientSessionAttributeOptions, ClientSessionTimeAttribute } from '../types';

export const clientSessionTimeAttribute = ({
  locale,
  dateStart,
  duration,
  timezone
}: ClientSessionAttributeOptions): ClientSessionTimeAttribute | null => {
  if (!dateStart) {
    return null;
  }

  return {
    type: ClientSessionAttributesEnum.TIME,
    leftIcon: SessionAttributeIconEnum.CALENDAR,
    leftIconAppearance: 'default',
    label: platformDateTimeRange('DATE, TIME', {
      value: [dateStart],
      templateOptions: {
        durationInMinutes: duration,
        timezone,
        localeParams: locale,
        showTimezone: true
      }
    })
  };
};
