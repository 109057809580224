import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INotificationDetails, IRedirectEvent, ISystemNotification } from '../../../types';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent {
  @Input()
  notifications: ISystemNotification[] = [];

  @Output()
  notificationClick = new EventEmitter<INotificationDetails>();

  @Output()
  redirectEvent = new EventEmitter<IRedirectEvent>();

  constructor(readonly systemNotificationService: SystemNotificationsService) {}

  emitNotificationClick(notificationData: INotificationDetails = {}): void {
    this.notificationClick.emit(notificationData);
  }
}
