import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-question-option',
  templateUrl: './question-option.component.html',
  styleUrls: ['./question-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionOptionComponent),
      multi: true
    }
  ]
})
export class QuestionOptionComponent implements ControlValueAccessor {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _id: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isSelected = false;

  readonly isDisabled$ = new BehaviorSubject(false);

  @Input()
  // @ts-expect-error TS2564
  key: string;

  @Input()
  // @ts-expect-error TS2564
  text: string;

  @Input()
  // @ts-expect-error TS2564
  isCorrect: boolean | null;

  @Input()
  // @ts-expect-error TS2564
  multi: boolean;

  @HostBinding('attr.disabled')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get hasAttributeDisabled() {
    return this.isDisabled;
  }

  @HostBinding('class.correct')
  get hasClassCorrect(): boolean {
    return this.isCorrect != null && this.isCorrect;
  }

  @HostBinding('class.disabled')
  get hasClassDisabled(): boolean {
    return this.isDisabled;
  }

  @HostBinding('class.incorrect')
  get hasClassIncorrect(): boolean {
    return this.isCorrect != null && !this.isCorrect;
  }

  @HostBinding('class.selected')
  get hasClassSelected(): boolean {
    return this.isSelected;
  }

  get isDisabled(): boolean {
    return this.isDisabled$.getValue();
  }

  get isSelected(): boolean {
    return this._isSelected;
  }

  changeSelection(isSelected: boolean): void {
    if (!this.isDisabled) {
      this._isSelected = isSelected;
      this.onChange({ id: this._id, isSelected });
      this.onTouched();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }

  writeValue({ id, isSelected }: { id: number; isSelected: boolean }): void {
    this._id = id;
    this._isSelected = isSelected;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onTouched: any = () => {};
}
