import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SidenavModeService } from '@app/modules/sidenav/services/mode.service';
import { PuiNavigationListMode } from '@awarenow/profi-ui-core';

enum ToggleButtonIconState {
  LEFT = 'pui:expand-left',
  RIGHT = 'pui:expand-right'
}

@Component({
  selector: 'app-sidenav-toggle-button',
  templateUrl: 'toggle-button.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavToggleButtonComponent {
  readonly defaultIcon: ToggleButtonIconState = ToggleButtonIconState.LEFT;

  // @ts-expect-error TS2344
  readonly icon: Record<PuiNavigationListMode, ToggleButtonIconState> = {
    short: ToggleButtonIconState.RIGHT,
    full: ToggleButtonIconState.LEFT
  };

  @Output() toggle: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(public mode$: SidenavModeService) {}
}
