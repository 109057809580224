import { InjectionToken } from '@angular/core';
import { NavigationExtras, PRIMARY_OUTLET } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IModalSettings } from '@libs/utils/modal-with-outlet-routing';

export const BOOKING_NAVIGATION_PREFIX = new InjectionToken<string>('BOOKING_NAVIGATION_PREFIX');
export const BOOKING_OUTLET_REF = new InjectionToken<string>('BOOKING_OUTLET_REF');
export const BOOKING_NAVIGATION_EXTRAS = new InjectionToken<BehaviorSubject<Partial<NavigationExtras>>>(
  'BOOKING_NAVIGATION_EXTRAS'
);
export const BOOKING_MODAL_SETTINGS = new InjectionToken<IModalSettings>('BOOKING_MODAL_SETTINGS');
