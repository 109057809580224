import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { Quiz } from '../../types/quiz';
import { QuizService } from '../../services/quiz.service';
import { Responses } from '../../types/responses';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-show-stats',
  templateUrl: './show-stats.component.html',
  styleUrls: ['./show-stats.component.scss']
})
export class ShowStatsComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  responses: Responses;

  // @ts-expect-error TS2564
  quiz: Quiz;

  // @ts-expect-error TS2564
  id: number;

  selectedUserId;

  constructor(
    private readonly chatBotService: QuizService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    // @ts-expect-error TS2531
    this.route.parent.params
      .pipe(
        tap(({ id }) => (this.id = id)),
        switchMap(({ id }) => chatBotService.getResponses$(+id)),
        takeUntil(this.destroy$)
      )
      .subscribe(quizResponses => {
        this.responses = quizResponses;

        this.cdr.markForCheck();
      });

    // @ts-expect-error TS2531
    this.selectedUserId = this.router.getCurrentNavigation().extras.state?.selectedUserId;
    // @ts-expect-error TS2531
    this.route.parent.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.quiz) {
        this.quiz = data.quiz;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadResponses(): void {
    this.chatBotService
      .getResponses$(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(quizResponses => {
        this.responses = quizResponses;

        this.cdr.markForCheck();
      });
  }
}
