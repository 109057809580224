import { Inject, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfiguratorFormComponent } from '@app/modules/ui-kit/_theming/components';
import { DOCUMENT } from '@angular/common';
import { combineLatest, fromEvent, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, share } from 'rxjs/operators';
import { ALT, CONTROL, SHIFT, ZERO } from '@angular/cdk/keycodes';

@Injectable()
export class ThemeConfiguratorService {
  shortcut$ = this.shortcut([CONTROL, ALT, SHIFT, ZERO]);

  isOpen = false;

  constructor(private _bottomSheet: MatBottomSheet, @Inject(DOCUMENT) private document: Document) {}

  init(): void {
    this.shortcut$.subscribe(_ => {
      this.toggle();
    });
  }

  open(): void {
    this._bottomSheet.open(ConfiguratorFormComponent);
  }

  close(): void {
    this._bottomSheet.dismiss();
  }

  toggle(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isOpen ? this.close() : this.open();
    this.isOpen = !this.isOpen;
  }

  shortcut(shortcut: number[]): Observable<KeyboardEvent[]> {
    // Observables for all keydown and keyup events
    const keyDown$ = fromEvent<KeyboardEvent>(this.document, 'keydown');
    const keyUp$ = fromEvent<KeyboardEvent>(this.document, 'keyup');

    // All KeyboardEvents - emitted only when KeyboardEvent changes (key or type)
    const keyEvents$ = merge(keyDown$, keyUp$).pipe(
      // eslint-disable-next-line id-length
      distinctUntilChanged((a, b) => a.code === b.code && a.type === b.type),
      share()
    );

    // Create KeyboardEvent Observable for specified KeyCode
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const createKeyPressStream = (charCode: number) =>
      keyEvents$.pipe(filter(event => event.keyCode === charCode.valueOf()));

    // Create Event Stream for every KeyCode in shortcut
    // eslint-disable-next-line id-length
    const keyCodeEvents$ = shortcut.map(s => createKeyPressStream(s));

    // Emit when specified keys are pressed (keydown).
    // Emit only when all specified keys are pressed at the same time.
    // More on combineLatest below
    // eslint-disable-next-line id-length
    return combineLatest(keyCodeEvents$).pipe(filter<KeyboardEvent[]>(arr => arr.every(a => a.type === 'keydown')));
  }
}
