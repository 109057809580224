import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { CurrencyService } from '@app/core/currency/currency.service';
import { IGuideService, IServiceItemComponent } from '@app/modules/guide-service/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';

@Component({
  selector: 'app-guide-service',
  templateUrl: './guide-service.component.html',
  styleUrls: ['./guide-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-service ui-app-guide-service'
  }
})
export class GuideServiceComponent<T extends IGuideService> implements IServiceItemComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _context: { service: T };

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _service: T;

  @Input()
  editorLink: unknown;

  @Input()
  // @ts-expect-error TS2564
  publicLink: string;

  @Input()
  // @ts-expect-error TS2564
  copyText: string;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  badges: TemplateRef<any>[];

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  team: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showMore: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  price: TemplateRef<any>;

  @Input()
  set service(service: T) {
    this._service = service;
    this._context = { service };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get context() {
    return this._context;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get service(): T {
    return this._service;
  }

  constructor(public currency: CurrencyService, public workspace: WorkspacesService) {}
}
