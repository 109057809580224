import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ITimeSlot } from '@app/modules/schedule-boards';
import {
  IGuideServiceScheduleOptions,
  IPrivateGuideScheduleOptions,
  IPublicGuideScheduleOptions,
  ScheduleTypes
} from '../../services';
import { IReschedulingSessionDate, ReschedulingSessionDate, ReschedulingSessionOptions } from '../../types';
import { prepareScheduleOptions } from '../../helpers';

@Component({
  selector: 'app-session-time-picker',
  templateUrl: './session-time-picker.component.html',
  styleUrls: ['./session-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'session-time-picker'
  }
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class SessionTimePickerComponent<TId> {
  // @ts-expect-error TS2564
  readonly ScheduleTypes: ScheduleTypes;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guidePrivateScheduleOptions: IPrivateGuideScheduleOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guidePublicScheduleOptions: IPublicGuideScheduleOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideServiceScheduleOptions: IGuideServiceScheduleOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _name: string;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleType: ScheduleTypes;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _time: ITimeSlot;

  @Input()
  set options(value: ReschedulingSessionOptions) {
    this._name = value.name;

    const { type, options } = prepareScheduleOptions(value);

    this._scheduleType = type;

    switch (type) {
      case 'guidePrivateSchedule':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this._guidePrivateScheduleOptions = <IPrivateGuideScheduleOptions>options;
        break;
      case 'guidePublicSchedule':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this._guidePublicScheduleOptions = <IPublicGuideScheduleOptions>options;
        break;
      case 'guideServiceSchedule':
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this._guideServiceScheduleOptions = <IGuideServiceScheduleOptions>options;
        break;
      default:
        break;
    }
  }

  @Output()
  timeSelect = new EventEmitter<IReschedulingSessionDate>();

  get guidePrivateScheduleOptions(): IPrivateGuideScheduleOptions {
    return this._guidePrivateScheduleOptions;
  }

  get guidePublicScheduleOptions(): IPublicGuideScheduleOptions {
    return this._guidePublicScheduleOptions;
  }

  get guideServiceScheduleOptions(): IGuideServiceScheduleOptions {
    return this._guideServiceScheduleOptions;
  }

  get name(): string {
    return this._name;
  }

  get scheduleType(): ScheduleTypes {
    return this._scheduleType;
  }

  get timeSelected(): boolean {
    return !!this._time;
  }

  selectTime(time: ITimeSlot): void {
    this._time = time;
  }

  confirmTime(): void {
    if (this.timeSelected) {
      this.timeSelect.emit(new ReschedulingSessionDate(this._time.value, this._time.timezone));
    }
  }
}
