<div class="modal-body">
  <div class="loading-place" *ngIf="isLoading">
    <div class="loading"></div>
  </div>
  <div class="video-selector" [ngClass]="{ 'has-videos': wistiaVideos.length && !wistiaVideoUploaded }">
    <app-video-gallery
      class="app-video-gallery"
      [videos]="wistiaVideos"
      *ngIf="wistiaVideos.length && !wistiaVideoUploaded"
      (selectVideo)="selectVideo($event)"></app-video-gallery>
    <div id="wistia_uploader" class="wistia-uploader"></div>
  </div>
  <form [formGroup]="uploadVideoForm" class="upload-video-form" [ngClass]="{ 'd-none': wistiaVideoUploaded }">
    <div class="form-group">
      <label for="videoUrl" i18n="@@videoUploaderVideoUrl">Or add link to youtube | vimeo video</label>
      <input type="text" class="form-control" formControlName="videoUrl" id="videoUrl" />
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button className="btn-primary" (click)="save()" [disabled]="!uploadVideoForm.valid" i18n="@@videoUploaderInsert">
    Insert
  </app-button>
  <app-button className="btn-lite" (click)="cancel()" i18n="@@videoUploaderCancel">Cancel</app-button>
</div>
