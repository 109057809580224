import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { ClientSessionStatusLabelsMap } from '@libs/business/client/client-session';

export const ClientSessionStatusLabel: ClientSessionStatusLabelsMap = {
  [SessionStatuses.MISSED]: `Missed`,
  [SessionStatuses.PENDING]: `Pending confirmation`,
  [SessionStatuses.CONFIRMED]: `Confirmed`,
  [SessionStatuses.CONFIRMED_AND_PAID]: `Confirmed and paid`,
  [SessionStatuses.CANCELLED]: `Canceled by client`,
  [SessionStatuses.DECLINED]: `Canceled`,
  [SessionStatuses.DONE]: `Completed`,
  [SessionStatuses.IN_PROGRESS_OLD]: `In progress`,
  [SessionStatuses.GUIDE_OFFER_DECLINED]: `Canceled by client`,
  [SessionStatuses.GUIDE_OFFER_CANCELLED]: `Canceled`,
  [SessionStatuses.EXPIRED]: `Expired`
};
