import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';

import { AuthorisedGuideInfoComponent } from './components/authorised-guide-info/authorised-guide-info.component';
import { ProfileTypeService } from './components/authorised-guide-info/profile-type.service';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, RouterModule, ClipboardModule],
  declarations: [AuthorisedGuideInfoComponent],
  exports: [AuthorisedGuideInfoComponent],
  providers: [ProfileTypeService]
})
export class GuideProfilePrivacyModule {}
