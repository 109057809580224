import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Injectable()
export class RedirectBlogOwnerToBlogDashboardGuard implements CanActivate {
  constructor(protected _auth: AuthService, protected _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.isOwner(route.params)) {
      this._router.navigate([`/${this.getUserRoleName()}/blog`]);
      return false;
    }

    return true;
  }

  private getUserRoleName(): string {
    if (this._auth.isAuthorized) {
      if (this._auth.user.RoleId === UserRoles.ADMIN) {
        return 'admin';
      }

      if (this._auth.user.RoleId === UserRoles.GUIDE) {
        return 'guide';
      }
    }

    // @ts-expect-error TS2322
    return null;
  }

  private isOwner(routeParams: Params): boolean {
    return this._auth.isAuthorized && routeParams.guide && routeParams.guide === this._auth.user.namedUrl;
  }
}
