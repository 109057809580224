import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { BookingRootModule } from '@appWidget/modules/booking-root/booking-root.module';

const routes: Route[] = [
  {
    path: 'booking/v2',
    outlet: 'modal',
    // TODO: was changed in PR-8791 because decline session from external calendars didn't work
    loadChildren: () => BookingRootModule
  },
  {
    path: 'booking',
    outlet: 'modal',
    loadChildren: () => import('@appWidget/modules/booking/booking.module').then(module => module.BookingModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingModalRoutingModule {}
