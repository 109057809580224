import { ILabelledProgramOption } from '../program-options';
import { ModuleActivationTypes } from '../program-module';
import { IIdentifiable, IDiff, IFullPatch, IModuleActivation } from '../util-types';

// eslint-disable-next-line id-length
export function areNullableArraysEqual<T>(a: T[] | null, b: T[] | null): boolean {
  // @ts-expect-error TS2322
  return (!a && !b) || (a && b && a.length === b.length && a.every(i => b.includes(i)));
}

export function getConstantOrderArraysPatch<T extends IIdentifiable, U extends T & IDiff<T>>(
  a1: readonly U[],
  a2: readonly T[]
): IFullPatch<T> {
  let i = 0;
  let j = 0;

  const len1 = a1.length;
  const len2 = a2.length;

  const created: T[] = [];
  const deleted: number[] = [];
  const updated: (Pick<T, 'id' | 'localId'> & Partial<Omit<T, 'id' | 'localId'>>)[] = [];

  while (i < len1 || j < len2) {
    if (i === len1) {
      created.push(a2[j]);
      j += 1;
      continue;
    }

    if (j === len2) {
      // @ts-expect-error TS2345
      deleted.push(a1[i].id);
      i += 1;
      continue;
    }

    if (a1[i].id !== a2[j].id) {
      // @ts-expect-error TS2345
      deleted.push(a1[i].id);
      i += 1;

      if (!a2[j].id) {
        created.push(a2[j]);
        j += 1;
      }
    } else {
      const diff = a1[i].getDiffFrom(a2[j]);

      if (diff) {
        updated.push(diff);
      }

      i += 1;
      j += 1;
    }
  }

  return { created, deleted, updated };
}

export function getArbitraryOrderArraysPatch<T extends IIdentifiable, U extends T & IDiff<T>>(
  newItems: T[],
  oldItems: U[]
): IFullPatch<T> {
  const newItemsDict = newItems.reduce((dict, newItem) => {
    // @ts-expect-error TS7053
    dict[newItem.localId] = newItem;
    return dict;
  }, {});

  // @ts-expect-error TS7034
  const deleted = [];
  // @ts-expect-error TS7034
  const updated = [];

  oldItems.forEach(oldItem => {
    // @ts-expect-error TS7053
    if (!newItemsDict[oldItem.localId]) {
      deleted.push(oldItem.id);

      if (!oldItem.id) {
        throw new Error();
      }
    } else {
      // @ts-expect-error TS7053
      const diff = oldItem.getDiffFrom(newItemsDict[oldItem.localId]);
      if (diff) {
        updated.push(diff);
      }
      // @ts-expect-error TS7053
      delete newItemsDict[oldItem.localId];
    }
  });

  const created = Object.values(newItemsDict) as T[];

  // @ts-expect-error TS7005
  return { created, deleted, updated };
}

export const DEFAULT_ACTIVATION_SPLITTER = '@';

export function createModuleActivationOption(
  label: string,
  type: ModuleActivationTypes,
  value?: string | null
): ILabelledProgramOption<string> {
  return { label, value: `${type}${DEFAULT_ACTIVATION_SPLITTER}${value || ''}` };
}

export function parseModuleActivationOption(optionValue: string): IModuleActivation {
  const [activationType, activationValue] = optionValue.split(DEFAULT_ACTIVATION_SPLITTER);
  return { activationType, activationValue: activationValue || null } as IModuleActivation;
}
