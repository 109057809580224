import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { exhaustMap, filter, tap } from 'rxjs/operators';
import { findRouteKey } from '@libs/utils/route/find-route-key';
import { StepperItem } from '@appWidget/modules/booking/consts';

interface StepsState {
  currentStep: StepperItem | undefined;
  steps: StepperItem[];
}

const initialState: StepsState = {
  currentStep: undefined,
  steps: []
};
@Injectable()
export class BookingStepsStore extends ComponentStore<StepsState> {
  // Selectors
  readonly steps$ = this.select(state => state.steps);
  readonly currentStep$ = this.select(state => state.currentStep);
  readonly currentStepIndex$ = this.select(this.steps$, this.currentStep$, (steps, currentStep) =>
    currentStep === undefined ? 0 : steps.findIndex(step => step.id === currentStep.id)
  );
  readonly nextStep$ = this.select(
    this.steps$,
    this.currentStepIndex$,
    (steps, currentStepIndex) => steps[Math.min(currentStepIndex + 1, steps.length - 1)]
  );
  readonly previousStepIndex$ = this.select(
    this.steps$,
    this.currentStepIndex$,
    (steps, currentStepIndex) => steps[Math.max(currentStepIndex - 1, 0)]
  );

  // Effects
  readonly handleActivatedRouteChange = this.effect<void>(triggger$ =>
    triggger$.pipe(
      exhaustMap(() =>
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
          tap(() => {
            this.updateState();
          })
        )
      )
    )
  );

  constructor(private router: Router, private route: ActivatedRoute) {
    super(initialState);

    this.updateState();
  }

  private updateState(route: ActivatedRouteSnapshot = this.route.snapshot) {
    const {
      steps,
      step: currentStep
    }: {
      steps?: StepperItem[];
      step?: StepperItem;
    } = findRouteKey(route, 'data');

    if (!steps) {
      return;
    } else {
      this.setState({
        currentStep,
        steps
      });
    }
  }
}
