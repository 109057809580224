import { InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicSessionTemplate } from '@app/shared/interfaces/services';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { PublicSessionsTemplatesApi } from './public-sessions-templates-api.service';

export const PUBLIC_SESSION_TEMPLATE_INFO = new InjectionToken<Observable<PublicSessionTemplate>>(
  'PublicSessionTemplateInfo'
);

export function publicSessionTemplateFactory(
  activatedRoute: ActivatedRoute,
  _api: PublicSessionsTemplatesApi
): Observable<PublicSessionTemplate> {
  // @ts-expect-error TS2531
  return combineLatest([activatedRoute.paramMap, activatedRoute.parent.paramMap]).pipe(
    take(1),
    switchMap(([params, parentParams]) => {
      const hasIdParam = !!params.get('id');

      // @ts-expect-error TS2531
      const templateId = hasIdParam ? +params.get('id').split('-')[0] : +parentParams.get('id');
      return _api.getTemplateById$(templateId);
    })
  );
}

export const PUBLIC_SESSION_TEMPLATE_PROVIDERS: Provider[] = [
  {
    provide: PUBLIC_SESSION_TEMPLATE_INFO,
    deps: [ActivatedRoute, PublicSessionsTemplatesApi],
    useFactory: publicSessionTemplateFactory
  }
];
