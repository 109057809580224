import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionDetailsComponent } from './session-details.component';
import {
  PuiIconModule,
  PuiLinkModule,
  PuiPaperModule,
  PuiTextOverflowModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { SessionRangeFormatterModule } from '@app/shared/pipes/session-range-formatter/session-range-formatter.module';
import { SharedModule } from '@libs/shared/shared.module';
import { BookingPipesModule } from '@appWidget/modules/booking/common/pipes/booking-pipes.module';

@NgModule({
  declarations: [SessionDetailsComponent],
  imports: [
    CommonModule,
    PuiIconModule,
    PuiTypographyModule,
    PuiLinkModule,
    PuiPaperModule,
    PlatformStyleModule,
    SessionRangeFormatterModule,
    SharedModule,
    PuiTextOverflowModule,
    BookingPipesModule
  ],
  exports: [SessionDetailsComponent]
})
export class SessionDetailsModule {}
