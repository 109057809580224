import { Pipe, PipeTransform } from '@angular/core';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';
import { ILocale } from '@env/locale.interface';
import { LocaleService } from '@app/core/locale/locale.service';

export interface ServiceRangeWithEnd {
  dateStart: string;
  dateEnd: string;
}

export interface ServiceRangeWithDuration {
  dateStart: string;
  duration: number;
}

export type ServiceRange = ServiceRangeWithEnd | ServiceRangeWithDuration;

@Pipe({
  name: 'serviceRangeFormatter'
})
export class ServiceRangeFormatterPipe implements PipeTransform {
  private locale: ILocale;

  constructor(private _localeService: LocaleService) {
    this.locale = this._localeService.getLocale();
  }

  transform(
    serviceRange: ServiceRange,
    rangeTimezone?: string,
    rangeLocale?: string,
    timeFormat?: string,
    showTimezone = true
  ): string | null {
    if (serviceRange == null || !serviceRange.dateStart || !('dateEnd' in serviceRange || 'duration' in serviceRange)) {
      return null;
    }

    const timezone = rangeTimezone;

    const start = serviceRange['dateStart'];
    // @ts-expect-error TS7053
    const end = serviceRange['dateEnd'];
    // @ts-expect-error TS7053
    const duration = serviceRange['duration'];

    return platformDateTimeRange('TIME', {
      value: [start, end],
      templateOptions: {
        durationInMinutes: duration,
        localeParams: this.locale,
        timezone,
        showTimezone
      }
    });
  }
}
