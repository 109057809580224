import { Pipe, PipeTransform } from '@angular/core';
import { IServiceWorkspaceAssignee, ServiceTeamAssigneeRole } from '@app/screens/guide/guide-sessions-templates/types';

const ASSIGNEE_ROLE_TOOLTIP: Record<ServiceTeamAssigneeRole, string> = {
  [ServiceTeamAssigneeRole.OWNER]: `An owner is the creator of a team’s service and can edit the service. If the owner is also hosting the service, you must add the owner as a host.`,
  [ServiceTeamAssigneeRole.HOST]: `A Host can provide and manage services they are assigned within a team, including offering sessions, accepting requests, messaging with clients, and scheduling and managing participants. Hosts are assigned by admins and cannot edit services.`,
  [ServiceTeamAssigneeRole.INSTRUCTOR]: `An Instructor can provide and manage the modules they are assigned within a program. Only one instructor can be assigned per module. `
};

@Pipe({
  name: 'memberRoleTooltip'
})
export class MemberRoleTooltipPipe implements PipeTransform {
  transform(value: Pick<IServiceWorkspaceAssignee, 'role'>): string {
    return ASSIGNEE_ROLE_TOOLTIP[value.role];
  }
}
