import { NgModule } from '@angular/core';
import { BookingRoutingModule } from '@appWidget/modules/booking-root/booking-root-routing.module';
import { BookingStepperModule } from '@appWidget/modules/booking-root/modules/booking-stepper/booking-stepper.module';

import { BookingRootComponent } from './booking-root.component';

@NgModule({
  declarations: [BookingRootComponent],
  imports: [BookingRoutingModule, BookingStepperModule]
})
export class BookingRootModule {}
