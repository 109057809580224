import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ADMIN_PROFILE_ENDPOINT } from '@app/screens/admin/services/endpoints';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserPasswordsService {
  constructor(private readonly httpClient: HttpClient) {}

  updatePassword$({
    oldPassword,
    newPassword
  }: {
    oldPassword: string;
    newPassword: string;
  }): Observable<{ success: true }> {
    return this.httpClient.patch<{ success: true }>(`${ADMIN_PROFILE_ENDPOINT}/personal`, { oldPassword, newPassword });
  }
}
