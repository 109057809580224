import { AnalyticEvent } from './analytic.event';
import { InternalEvents } from '@app/core/analytics/types';

export class SessionTemplateCreatedEvent extends AnalyticEvent {
  constructor() {
    super(InternalEvents.SESSION_TEMPLATE_CREATED, {
      is_paid: 'undefined',
      session_name: 'undefined',
      session_id: 'undefined',
      availability: 'undefined',
      type: 'undefined',
      guest: 'undefined',
      seats: 'undefined',
      seats_number: 'undefined',
      auto_confirmed_guide: 'undefined',
      limited_future_booking: 'undefined',
      limited_booking_frequency: 'undefined',
      location: 'undefined',
      session_service: 'undefined',
      on_bw: 'undefined',
      experation_date: 'undefined',
      restricted_clients_booking: 'undefined'
    });
  }
}
