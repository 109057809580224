import { Component, Input } from '@angular/core';
import { SessionTypes } from '@app/shared/enums/session-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideContact } from '@app/core/users/types';

export type CancellationTypes = 'cancel' | 'decline';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-cancel-session-modal',
  templateUrl: './cancel-session-modal.component.html',
  styleUrls: ['./cancel-session-modal.component.scss']
})
export class CancelSessionModalComponent {
  readonly SessionTypes = SessionTypes;

  @Input()
  cancellationType: CancellationTypes = 'cancel';

  @Input()
  // @ts-expect-error TS2564
  user: Pick<GuideContact, 'name'>;

  @Input()
  // @ts-expect-error TS2564
  serviceType: SessionTypes;

  @Input()
  // @ts-expect-error TS2564
  numOfParticipants: number;

  @Input()
  // @ts-expect-error TS2564
  sessionName: string;

  @Input()
  // @ts-expect-error TS2564
  sessionDate: string | null;

  // @ts-expect-error TS7008
  message;

  constructor(public modal: NgbActiveModal) {}
}
