import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import {
  AnalyticCreateSourceTypes,
  AnalyticPricingTypes,
  AnalyticServiceTypes,
  AnalyticSubscriptionFrequency,
  InternalEvents
} from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';

import { GuideProgramStateService } from './guide-program-state.service';
import {
  IProgramContent,
  IProgramContentPersistenceAttributes,
  IProgramStore,
  ProgramContent,
  ProgramModule
} from '../types';
import { PROGRAMS_STORE } from './guide-program-server-store.service';

@Injectable()
export class NewGuideProgramStateService implements OnDestroy {
  private readonly destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _stateModifiers: {
    updateContent: (content: ProgramContent) => void;
    updateModules: (modules: ProgramModule[]) => Observable<void>;
  };

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programId: number;

  get content$(): Observable<ProgramContent> {
    return this._programStateHolder.content$;
  }

  get modules$(): Observable<ProgramModule[]> {
    return this._programStateHolder.modules$;
  }

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private _programStateHolder: GuideProgramStateService,
    @Inject(PROGRAMS_STORE) private _externalStore: IProgramStore,
    private _notifications: NotificationsService
  ) {
    this._stateModifiers = {
      updateContent: content => {
        this.createContent(content); // TODO need rework to createContentObs
      },
      updateModules: modules => this.createModules(modules)
    };
  }

  updateContent(content: ProgramContent): void {
    this._stateModifiers.updateContent(content);
  }

  updateModules(modules: ProgramModule[]): Observable<void> {
    return this._stateModifiers.updateModules(modules);
  }

  uploadCover$(images: { coverImage: File; coverImageThumb: File }): Observable<{
    coverImage: string | null;
    coverImageThumb: string | null;
  }> {
    return this._programStateHolder.uploadCover$(images);
  }

  removeCover$(): Observable<null> {
    if (this._programId) {
      // TODO: PR-2565
      return this._externalStore.removeCover$(this._programId);
    }

    return of(null);
  }

  private createContent(content: ProgramContent): void {
    // @ts-expect-error TS2345
    this._externalStore.createContent$(content).subscribe(ids => {
      this._programId = ids.id;
      this._programStateHolder.programId = this._programId;
      this._programStateHolder.applyProgramContentPersistenceAttributes(ids);

      this._stateModifiers.updateContent = this._programStateHolder.updateContent.bind(this._programStateHolder);
    });

    this._programStateHolder.updateContent(content, true);
  }

  createContentObs(content: ProgramContent): Observable<IProgramContentPersistenceAttributes> {
    return this._externalStore.createContent$(content as IProgramContent).pipe(
      tap(ids => {
        if (!ids) {
          return;
        }

        this._programId = ids.id;
        this._programStateHolder.programId = this._programId;
        this._programStateHolder.applyProgramContentPersistenceAttributes(ids);
        this._stateModifiers.updateContent = this._programStateHolder.updateContent.bind(this._programStateHolder);
        this._programStateHolder.updateContent(content, true);
      })
    );
  }

  private createModules(modules: ProgramModule[]): Observable<void> {
    let result$ = of(void 0);
    if (this._programId) {
      this.content$.pipe(take(1)).subscribe(programContent => this.sendClientInviteAnalytics(programContent));

      result$ = this._externalStore.createModules$(this._programId, modules).pipe(
        tap(({ modules: ids }) => {
          this._programStateHolder.applyProgramModulesPersistenceAttributes(ids);
          this._stateModifiers.updateModules = this._programStateHolder.updateModules.bind(this._programStateHolder);
        }),
        map(() => void 0),
        takeUntil(this.destroy$)
      );
    } else {
      const title = `Create program before updating modules`;
      this._notifications.error(title);
    }

    this._programStateHolder.updateModules(modules, true);
    return result$;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private sendClientInviteAnalytics(programContent) {
    const analyticsData: {
      createSource?: AnalyticCreateSourceTypes;
      installmentAllowed?: boolean;
      pricingType?: AnalyticPricingTypes;
      servicePrice?: number;
      serviceType?: AnalyticServiceTypes;
      subscriptionFrequency?: AnalyticSubscriptionFrequency;
      subscriptionPrice?: number;
      totalCountOfPayments?: number;
    } = {
      createSource: AnalyticCreateSourceTypes.SERVICE_LIST,
      servicePrice: Number(programContent.price),
      serviceType: AnalyticServiceTypes.PROGRAM
    };

    switch (programContent.subscriptionRecurrency) {
      case AnalyticSubscriptionFrequency.WEEK:
        analyticsData.subscriptionFrequency = AnalyticSubscriptionFrequency.WEEK;
        break;
      case AnalyticSubscriptionFrequency.MONTH:
        analyticsData.subscriptionFrequency = AnalyticSubscriptionFrequency.MONTH;
        break;
      case AnalyticSubscriptionFrequency.YEAR:
        analyticsData.subscriptionFrequency = AnalyticSubscriptionFrequency.YEAR;
        break;
      default:
        analyticsData.subscriptionFrequency = AnalyticSubscriptionFrequency.NONE;
    }

    if (programContent.isFree) {
      analyticsData.pricingType = AnalyticPricingTypes.FREE;
    }

    if (programContent.price && programContent.subscriptionRecurrency === null) {
      analyticsData.pricingType = AnalyticPricingTypes.ONE_TIME;
      analyticsData.installmentAllowed = false;
    }

    if (programContent.price && programContent.subscriptionPrice && programContent.subscriptionRecurrency !== null) {
      analyticsData.installmentAllowed = true;
      analyticsData.pricingType = AnalyticPricingTypes.ONE_TIME;
      analyticsData.subscriptionPrice = programContent.subscriptionPrice;
      analyticsData.totalCountOfPayments = programContent.totalPayments;
    }

    if (
      programContent.price === 0 &&
      programContent.subscriptionPrice &&
      programContent.subscriptionRecurrency !== null
    ) {
      analyticsData.pricingType = AnalyticPricingTypes.SUBSCRIPTION;
      analyticsData.subscriptionPrice = programContent.subscriptionPrice;
    }

    this._analyticsService.event(InternalEvents.SERVICE_CREATE, analyticsData);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
