import { IUser } from '@app/shared/interfaces/user';
import { GuideContact } from '@app/core/users/types';
import { buildUsersGuideMapKey } from '@app/core/users/utils';
import {
  ChatSummary,
  IServerChatDetails,
  chatSummaryFactory,
  isDirectChatSummary,
  ChatTypes,
  isContactChatSummary
} from './types';

export function convertSocketChatSummary(chat: IServerChatDetails, users: { [id: number]: IUser }): ChatSummary {
  const chatSummary = chatSummaryFactory(chat);

  if (isDirectChatSummary(chatSummary)) {
    const [user] = chat.users;
    if (user) {
      const shouldTakeId = !!users[user.id];
      chatSummary.user =
        // @ts-expect-error TS2322
        users[shouldTakeId ? user.id : buildUsersGuideMapKey({ id: user.id, workspaceId: chat.workspaceId })];
    }
  }

  return chatSummary;
}

export function convertSocketChatSummaries(chats: IServerChatDetails[], users: { [id: number]: IUser }): ChatSummary[] {
  return chats
    .map(chat => convertSocketChatSummary(chat, users))
    .filter(chat => !isDirectChatSummary(chat) || !!chat.user);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createContactChatSummaries(users: GuideContact[]) {
  return users.map(user => {
    const chat = {
      id: `${user.id}contact`,
      type: ChatTypes.CONTACT,
      createdAt: user.createdAt
    };
    const chatSummary = chatSummaryFactory(chat);

    if (isContactChatSummary(chatSummary)) {
      chatSummary.user = user;
    }
    return chatSummary;
  });
}
