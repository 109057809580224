import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PuiDrawerConfig, PuiDrawerService } from '@awarenow/profi-ui-core';

import { ProposeFormsComponent } from './propose-forms.component';
import { ROOT_PATH, ROUTER_OUTLET } from './routes';

const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable()
export class ProposeFormsService {
  constructor(
    private readonly drawer: PuiDrawerService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  open(clients: unknown): void {
    const drawerRef = this.drawer.open(ProposeFormsComponent, DRAWER_CONFIG, {
      clients
    });

    this.router.navigate([{ outlets: { [ROUTER_OUTLET as string]: [ROOT_PATH] } }], {
      relativeTo: this.activatedRoute
    });

    drawerRef.afterClosed$.subscribe(() => {
      drawerRef.close();

      this.router.navigate([{ outlets: { [ROUTER_OUTLET as string]: null } }]);
    });
  }
}
