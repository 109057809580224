import { Pipe, PipeTransform } from '@angular/core';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';

const HOST_PERMISSION: Record<ServiceAssigneePermission, string> = {
  [ServiceAssigneePermission.OWNER]: `Owner`,
  [ServiceAssigneePermission.OWNER_X_PROVIDER]: `Owner`,
  [ServiceAssigneePermission.PROVIDER]: `Host`
};

@Pipe({
  name: 'hostPermission'
})
export class HostPermissionPipe implements PipeTransform {
  transform(permission: ServiceAssigneePermission): string {
    return HOST_PERMISSION[permission];
  }
}
