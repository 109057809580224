import { NgModule } from '@angular/core';
import { BookingModalComponent } from '@app/modules/book-service/components/booking-modal/booking-modal.component';
import { BookingModalRoutingModule } from '@app/modules/book-service/components/booking-modal/booking-modal-routing.module';
import { BOOKING_MODAL_SETTINGS } from '@appWidget/modules/booking/injections';
import { BookingModalService } from '@app/modules/book-service/components/booking-modal/booking-modal.service';
import { PuiIconModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [BookingModalComponent],
  providers: [
    BookingModalService,
    {
      provide: BOOKING_MODAL_SETTINGS,
      useValue: {
        prefix: 'booking',
        outlet: 'modal',
        navigationOptions: {
          skipLocationChange: true,
          replaceUrl: false
        }
      }
    }
  ],
  imports: [BookingModalRoutingModule, PuiIconModule],
  exports: [BookingModalComponent]
})
export class BookingModalModule {}
