import { Injectable } from '@angular/core';
import { GuideClientCardTab } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/services/guide-client-card-tab';

@Injectable()
export class GuideClientCardTabService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _tabIndex: GuideClientCardTab = GuideClientCardTab.DETAILS;

  get tabIndex(): GuideClientCardTab {
    return this._tabIndex;
  }

  set tabIndex(tab: GuideClientCardTab) {
    if (tab === undefined) {
      return;
    }

    this._tabIndex = tab;
  }
}
