import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { MemberRoleEnum } from '@app/modules/workspaces/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionType } from '@app/shared/enums/session-type';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

import { GroupServicesAppearance, SessionViewModel } from './types';
import { mapPackageSessionToVM, mapProgramModuleToVM, mapServiceToVM } from './utils';

const appearance: GroupServicesAppearance = {
  header: false,
  badge: false,
  hostsAvatars: false
};

export type GroupServicesItem = Pick<
  GuideServices.RootObject,
  | 'connectionType'
  | 'id'
  | 'name'
  | 'team'
  | 'type'
  | 'price'
  | 'duration'
  | 'sessionType'
  | 'serviceType'
  | 'seatsPerTimeSlot'
  | 'sessions'
  | 'startDate'
  | 'address'
  | 'subscriptionPrice'
  | 'totalPayments'
  | 'subscriptionRecurrency'
  | 'recurring'
>;

@Component({
  selector: 'app-group-services',
  templateUrl: './group-services.component.html',
  styleUrls: ['./group-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupServicesComponent {
  readonly MAX_GUIDE_AVATARS = 2;
  readonly GUIDE_SERVICE_TYPE = GuideServiceTypes;
  readonly GUIDE_SERVICE_LOCATION_TYPE = SessionConnectionTypes;
  readonly SESSION_TYPE = SessionType;

  readonly platformName = this.runtimeConfigService.get('platformName');
  private services$ = new BehaviorSubject<SessionViewModel[]>([]);
  private searchText$ = new BehaviorSubject<string>('');

  filteredServices$ = combineLatest([this.services$.asObservable(), this.searchText$]).pipe(
    map(([services, searchText]) => {
      return services.filter(
        service => !searchText?.length || service?.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      );
    })
  );

  @Input() appearance: GroupServicesAppearance = appearance;

  @Input() headerText = '';

  @Input() set searchText(value: string) {
    this.searchText$.next(value);
  }

  @Input() set services(data: GuideServices.RootObject[] | GuideServices.RootObject) {
    let sessions: SessionViewModel[] = [];

    // Can be array of session, packges or programs
    if (Array.isArray(data) && data?.length > 0) {
      sessions = data?.map(mapServiceToVM);
    }
    // One PACKAGE
    if (!Array.isArray(data) && data?.type === GuideServiceTypes.PACKAGE) {
      sessions = data.sessions?.map(session => mapPackageSessionToVM(session, data)) || [];
    }
    // One PROGRAM
    if (!Array.isArray(data) && data?.type === GuideServiceTypes.PROGRAM) {
      sessions =
        data.modules
          ?.filter(module => module.moduleType === ModuleTypes.GROUP_SESSION)
          // If a Role Team Member needs to filter modules where he is in the team
          ?.filter(
            module =>
              this.workspacesService.workspace.role === MemberRoleEnum.ADMIN ||
              module.service?.team.find(teamMember => teamMember?.userId === this.workspacesService.workspace.guideId)
          )
          ?.map(session => mapProgramModuleToVM(session, data)) || [];
    }

    this.services$.next(sessions);
  }

  @Output() selectService = new EventEmitter<GroupServicesItem>();

  @Output() selectServiceByIndex = new EventEmitter<number>();

  @Output() deleteService = new EventEmitter<GroupServicesItem>();

  @Output() deleteServiceByIndex = new EventEmitter<number>();

  expanded$ = new BehaviorSubject(true);

  constructor(
    private readonly runtimeConfigService: RuntimeConfigService,
    readonly workspacesService: WorkspacesService
  ) {}

  trackServiceById(index: number, service: GroupServicesItem): number {
    return service?.id || index;
  }
}
