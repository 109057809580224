import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectedPrivateSessionStore } from '@appWidget/modules/booking-cancellation/store/selected-private-session-store';
import { registerProviders } from '@appWidget/modules/booking-root/common/register-providers';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-booking-cancellation',
  templateUrl: './booking-cancellation.component.html',
  styleUrls: ['./booking-cancellation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [registerProviders(), SelectedPrivateSessionStore, PuiDestroyService]
})
export class BookingCancellationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private privateSessionStore: SelectedPrivateSessionStore,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    const { sessionId } = this.route.snapshot.params;

    if (!sessionId) {
      throw Error('Parameter {sessionId} is required!');
    }

    this.privateSessionStore.getPrivateSession(sessionId);
  }
}
