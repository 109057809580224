import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IBlogTag } from '../../types';

@Component({
  selector: 'app-blog-article-related-tags',
  templateUrl: './blog-article-related-tags.component.html',
  styleUrls: ['./blog-article-related-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleRelatedTagsComponent {
  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: any[];

  @Output()
  select = new EventEmitter<IBlogTag>();

  emitSelect(tag: IBlogTag): void {
    this.select.emit(tag);
  }
}
