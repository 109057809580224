<h2 data-qa-id="external-calendars-accounts-calendar-sync-title" class="title" i18n="@@calendarSync">Calendar sync</h2>

<div class="description" i18n="@@externalCalendarsAccountsDescription">
  Sync your availability and sessions with your Google Calendar or Outlook account. Whenever there is a confirmed
  session on the platform, it will get automatically added to your calendar.
</div>

<div class="calendars-sources">
  <app-calendar-source-card
    *ngFor="let source of calendarsSources$ | async"
    [source]="source"
    (connect)="connect(source.source)"
    (disconnect)="disconnect(source.source, $event)"></app-calendar-source-card>
</div>
