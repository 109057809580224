<div class="event-meta">
  <div class="event-header">
    <!--    <div class="event-badge" [ngSwitch]="note?.metaEvent?.service.serviceType">-->
    <!--      <app-guide-service-badge-->
    <!--        *ngSwitchCase="GuideServiceTypes.GROUP_SESSION"-->
    <!--        text="Group session"-->
    <!--        i18n-text="@@noteEventMetaItemGroupSessionBadge"-->
    <!--        [attr.data-qa-id]="'note-event-meta-group-session-badge-' + note?.contentText?.trim()"-->
    <!--        class="group-session-badge"></app-guide-service-badge>-->
    <!--      <app-guide-service-badge-->
    <!--        *ngSwitchCase="GuideServiceTypes.SESSION"-->
    <!--        text="Individual session"-->
    <!--        i18n-text="@@noteEventMetaIndividualSessionBadge"-->
    <!--        [attr.data-qa-id]="'note-event-meta-individual-session-badge-' + note?.contentText?.trim()"-->
    <!--        class="individual-session-badge"></app-guide-service-badge>-->
    <!--    </div>-->
    <div [attr.data-qa-id]="'note-event-meta-event-name-title-' + note?.contentText?.trim()" class="event-name">
      {{ note?.metaEvent?.service.name }}
    </div>
  </div>
  <div [attr.data-qa-id]="'note-event-meta-event-date-label-' + note?.contentText?.trim()" class="event-date">
    {{ eventInterval | sessionRangeFormatter }}
  </div>
</div>
