<div class="billing">
  <div class="billing--title" i18n="@@serviceBillingTitle">
    Please enter your billing information to complete your booking
  </div>
  <app-element-loading [loadingState]="!billingForm">
    <app-billing-form-wrapper
      *ngIf="billingForm"
      btnText="Submit"
      i18nBtnText="@@billingFormSubmit"
      [isModal]="true"
      [billingForm]="billingForm"
      [countries]="countries$ | async"
      (submitted)="billingDataChange.emit($event)"></app-billing-form-wrapper>
  </app-element-loading>
</div>
