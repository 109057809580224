import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabeledToggleModule } from '@app/modules/labeled-toggle/labeled-toggle.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PuiButtonModule, PuiDropdownModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { SwitchToAlternativeAccountComponent } from '@app/modules/sidenav/components/sidenav-switch-to-alternative-account/switch-to-alternative-account/switch-to-alternative-account.component';
import { SwitchToAlternativeAccountService } from '@app/modules/sidenav/components/sidenav-switch-to-alternative-account/switch-to-alternative-account.service';

@NgModule({
  declarations: [SwitchToAlternativeAccountComponent],
  exports: [SwitchToAlternativeAccountComponent],
  imports: [CommonModule, LabeledToggleModule, OverlayModule, PuiButtonModule, PuiIconModule, PuiDropdownModule],
  providers: [SwitchToAlternativeAccountService]
})
export class SwitchToAlternativeAccountModule {}
