import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PuiButtonModule, PuiIconModule, PuiLinkModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

import { DateOverridesFormModule } from '../../forms/date-overrides-form/date-overrides-form.module';
import { DateOverridesDrawerComponent } from './date-overrides-drawer.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DateOverridesFormModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiIconModule,
    PuiLinkModule
  ],
  declarations: [DateOverridesDrawerComponent],
  exports: [DateOverridesDrawerComponent]
})
export class DateOverridesDrawerModule {}
