import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';
import { RedirectAfterSaveActions } from '@app/screens/guide/guide-shared/can-redirect-after-save';

@Component({
  selector: 'app-program-editor',
  templateUrl: './guide-save-changes-dialog.component.html',
  styleUrls: ['./guide-save-changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideSaveChangesDialogComponent {
  isSimpleDialogText = false;

  redirectAfterSaveTypes = RedirectAfterSaveActions;
  // @ts-expect-error TS7006
  constructor(private readonly dialogRef: PuiDialogRef, @Inject(PUI_DIALOG_DATA) private readonly data) {
    this.isSimpleDialogText = this.data?.isSimpleDialogText || false;
  }

  close(action: RedirectAfterSaveActions): void {
    this.dialogRef.close(action);
  }
}
