import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { PuiFieldsCoreModule, PuiOtpInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { SmsOtpInputComponent } from './sms-otp-input.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PuiOtpInputModule,
    PuiFieldsCoreModule,
    ReactiveFormsModule,
    PuiTypographyModule
  ],
  declarations: [SmsOtpInputComponent],
  exports: [SmsOtpInputComponent]
})
export class SmsOtpInputModule {}
