<ng-container *ngIf="mode$ | async as value">
  <button
    pui-icon-button
    class="sidenav-toggle-button"
    size="s"
    shape="square"
    appearance="flat"
    (click)="mode$.toggle()">
    <pui-icon size="xs" [svgIcon]="icon[value] || defaultIcon"></pui-icon>
  </button>
</ng-container>
