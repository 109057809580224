<header *ngIf="client">
  <div class="client-photo-and-links row no-gutters">
    <div class="col-12 col-sm-6">
      <pui-card>
        <pui-card-header>
          <div pui-card-header-icon>
            <pui-avatar class="user-photo" [avatarUrl]="client?.photo" [text]="client?.name" [size]="40"></pui-avatar>
          </div>
          <div pui-card-header-title>
            <span pui-typography="h3">{{ client?.name }}</span>
          </div>
        </pui-card-header>
      </pui-card>
    </div>
    <div class="col-12 col-sm-6 buttons">
      <app-button className="btn-lite" [routerLink]="['/', guideRoute, 'chat', client?.id]">
        <ng-container i18n="@@sendMsgInChat">Send message in chat</ng-container>
      </app-button>
    </div>
  </div>
</header>

<div class="client-progress pui-space_top-5">
  <div class="info-boxes">
    <div class="info-box">
      <div class="value">
        <strong pui-typography="h3">{{
          modules && modules.length ? openModulesCount + '/' + modules.length : '0'
        }}</strong>
      </div>
      <div class="legend" i18n="@@modulesComplete">modules complete</div>
    </div>

    <div class="info-box">
      <div class="value" *ngIf="!quizzesSummary"><strong pui-typography="h3">0</strong></div>
      <div class="value" *ngIf="quizzesSummary">
        <strong pui-typography="h3">{{ quizzesSummary.finished }}/{{ quizzesSummary.total }}</strong>
      </div>
      <div class="legend" i18n="@@clientProgressFollowUpQuestions">follow-up questions</div>
    </div>

    <div *ngIf="enrolled$(client.id) | async as enrolledDate" class="info-box">
      <div class="value">
        <strong pui-typography="h3">{{ enrolledDate | platformDate: { hideWeekDay: true } }}</strong>
      </div>
      <div class="legend" i18n="@@enrolledInProgram">Enrolled in program</div>
    </div>

    <div *ngIf="nextPayment$(client.id) | async as nextPaymentDate" class="info-box">
      <div class="value">
        <strong pui-typography="h3">{{ nextPaymentDate | platformDate: { hideWeekDay: true } }}</strong>

        <ng-container *ngIf="!client.isInstallments">
          <ng-container *hasRuntimeFeature="'manageSubscriptionDates'">
            <button
              pui-icon-button
              appearance="flat-secondary"
              aria-label="set next payment date"
              setPaymentDate
              class="pui-space_left-1"
              [targetId]="programId"
              targetType="program"
              [customerId]="client.id"
              [nextPaymentDate]="nextPaymentDate"
              (paymentDateChange)="setCurrentPeriodEndState(client.id, $event)"
              size="xs">
              <pui-icon svgIcon="pui:edit-2" [size]="20"></pui-icon>
            </button>
          </ng-container>
        </ng-container>
      </div>
      <div class="legend" i18n="@@nextPayment">Next payment</div>
    </div>
  </div>

  <section class="modules-progress pui-space_top-8">
    <div class="pui-space_bottom-2">
      <h4 pui-typography="h4">Progress:</h4>
    </div>
    <div
      class="module-progress"
      [class.inactive]="module.status === 'restricted'"
      *ngFor="let module of modules; index as i">
      <div class="module-header">
        <div class="module-title-container">
          <strong>{{ i + 1 }}.</strong>
          <div class="module-title" *ngIf="module.moduleType === ModuleTypes.CONTENT">
            {{ module.title | clearHtml }}
          </div>
          <div *ngIf="module.moduleType === ModuleTypes.QUIZ">
            <div class="quiz-title">
              <div class="module-title">
                {{ module.title | clearHtml }}
              </div>

              <!--              'Follow-up questions'-->
              <button
                *ngIf="modulesAnswers[module.id]"
                class="download"
                pui-icon-button
                pui-tooltip="Export Forms responses"
                i18n-pui-tooltip="@@clientProgressQuizModuleDownload"
                size="xs"
                [ghost]="true"
                (click)="downloadClientModuleAnswers.emit({ moduleId: module.id })">
                <pui-icon svgIcon="pui:download"></pui-icon>
              </button>
            </div>
            <div *ngIf="modulesAnswers[module.id]">
              <button class="show-answers" pui-link (click)="toggleQuiz(module.id)">
                <span i18n="@@clientProgressShowAnswers">Show answers</span>
                <pui-icon svgIcon="pui:chevron-down" *ngIf="!openQuizzes[module.id]" size="xs"></pui-icon>
                <pui-icon svgIcon="pui:chevron-up" *ngIf="openQuizzes[module.id]" size="xs"></pui-icon>
              </button>
            </div>
          </div>

          <div
            class="session-module"
            *ngIf="module.moduleType === ModuleTypes.SESSION || module.moduleType === ModuleTypes.GROUP_SESSION">
            <ng-template [ngIf]="module.serviceId" [ngIfElse]="noService">
              {{ module.title | clearHtml }}
            </ng-template>
            <div class="session-module__actions">
              <app-button
                *ngIf="module.status !== 'seen' && canScheduleSession(module)"
                className="btn-link"
                (click)="scheduleSession(module)"
                i18n="@@scheduleSession">
                Schedule session
              </app-button>

              <app-button
                *ngIf="module.status !== 'seen' && canRescheduleSession(module)"
                className="btn-link"
                (click)="rescheduleSession(module)"
                i18n="@@rescheduleProgramSession">
                Reschedule
              </app-button>

              <app-button
                *ngIf="module.status !== 'seen' && canCancelSession(module)"
                className="btn-link"
                (click)="cancelSession(module, client)"
                i18n="@@cancel"
                >Cancel
              </app-button>
            </div>
          </div>
        </div>

        <div class="module-status">
          <ng-container
            *ngIf="module.status === 'seen' || isSessionDone(module.session?.status)"
            i18n="@@clientProgressModuleStatusCompleted">
            Completed
            <div
              class="module-quiz-date"
              pui-typography="body-s"
              *ngIf="isQuizModule(module) && module.quiz && module.quiz.date">
              {{ module.quiz.date | sessionDate }}
            </div>
          </ng-container>

          <ng-container *ngIf="module.status !== 'seen' && module.completionType === ModuleCompletionTypes.GUIDE">
            <app-button
              className="btn-link"
              i18n="@@clientProgressCompleteModuleBtn"
              (click)="completeModule.emit({ clientId: client.id, moduleId: module.id })">
              Mark as complete
            </app-button>
          </ng-container>
        </div>
      </div>

      <div class="module-quiz" *ngIf="isQuizModule(module) && module.quiz && openQuizzes[module.id]">
        <div class="quiz-progress ql-editor">
          <app-module-quiz
            class="no-response-option"
            [value]="module.quiz.questions"
            [editableAnswers]="false"
            [isCorrectDisplay]="true"
            [showNoResponseMessage]="false"></app-module-quiz>
        </div>
      </div>

      <div
        *ngIf="module.session && canShowSessionDate(module.session.status)"
        class="module-quiz-date"
        pui-typography="body-s">
        {{ module.session | sessionRangeFormatter }}
      </div>
    </div>
  </section>
</div>

<ng-template #noService>
  <ng-container i18n="@@clientProgressIndividualSession">Session</ng-container>
</ng-template>
