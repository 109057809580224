<h4 data-qa-id="sign-up-alternative-title" class="title" i18n="@@letsSetupYourAccount">Let's set up your account</h4>
<div class="auth-form">
  <form [formGroup]="signUpForm" novalidate (ngSubmit)="signUp()">
    <ng-container *ngIf="!onlyClient && !onlyGuide">
      <div *ngIf="showRoleSelectContainer" class="form-group">
        <label for="isGuide" i18n="@@signUpAsA" class="title-sign-up">Sign up as a</label>
        <app-checkbox id="isGuide" formControlName="isGuide" class="auth-checkbox sign-up-as">
          <ng-container transloco="Practitioner">Practitioner</ng-container>
        </app-checkbox>
        <app-checkbox
          id="isClient"
          formControlName="isClient"
          class="auth-checkbox sign-up-as"
          [disabled]="isClientDisabled">
          <ng-container transloco="Client">Client</ng-container>
        </app-checkbox>
        <app-form-control-error
          [show]="
            signUpForm.errors &&
            signUpForm.errors.roleError &&
            ((signUpForm.controls['isGuide'].dirty && signUpForm.controls['isGuide'].touched) ||
              (signUpForm.controls['isClient'].dirty && signUpForm.controls['isClient'].touched))
          "
          i18n="@@invalidRole">
          Choose at least one role.
        </app-form-control-error>
      </div>

      <p
        *ngIf="signUpForm.controls['isClient'].value && signUpForm.controls['isGuide'].value"
        class="info-text"
        transloco="bothRolesInfoText">
        Great! We’ll set up your practitioner dashboard first.
      </p>
    </ng-container>

    <div class="form-group checkboxes">
      <app-checkbox
        *ngIf="config.showNewsletter"
        id="agreeEmail"
        formControlName="subscribeNews"
        class="auth-checkbox"
        i18n="@@agreeToReceiveEmails">
        I agree to receive {{ platformName }} email newsletter
      </app-checkbox>
      <app-checkbox
        data-qa-id="sign-up-alternative-agree-with-terms-checkbox"
        id="agreeWithTerms"
        formControlName="agreeWithTerms"
        class="auth-checkbox required"
        i18n="@@signUpConfirmTermsAndPrivacyPolicy">
        I authorize the processing of personal data and agree with
        <a href="{{ config.urlTerms }}" target="_blank" rel="noopener">Terms&Conditions</a>
        and
        <a href="{{ config.urlPrivacy }}" target="_blank" rel="noopener">Privacy Policy</a>.
      </app-checkbox>
      <app-form-control-error
        [control]="signUpForm.controls['agreeWithTerms']"
        i18n="@@signUpMustAcceptPolicies"
        class="policies-error">
        You must accept the policies
      </app-form-control-error>
    </div>

    <div class="auth-buttons centered">
      <app-button
        data-qa-id="sign-up-alternative-create-account-button"
        className="btn-primary btn-lg"
        buttonType="submit"
        i18n="@@createAccount">
        Create account
      </app-button>
    </div>
  </form>
</div>
