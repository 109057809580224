import { Injectable } from '@angular/core';
import { EventTypes } from '@app/core/shared-event-actions/types';
import { GuideServicesApiService } from '@app/modules/service-scheduling/services';
import { UniversalSelectorGroup } from '@app/shared/components/universal-selector/types';
import { GuideServiceTypes, PackageService } from '@app/shared/interfaces/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services';
import { AllGuideServicesApiService } from '../../guide-services/services/all-guide-services-api.service';
import { PackageIncludesWithTemplate } from '@app/modules/package/types/package-session-template';

@Injectable()
export class QuizSessionsService {
  private readonly loadingSubject$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject$.asObservable();

  constructor(
    private readonly guideServicesApiService: GuideServicesApiService,
    private readonly auth: AuthService,
    private readonly allGuideServicesApi: AllGuideServicesApiService
  ) {}

  getGuideServices$(): Observable<UniversalSelectorGroup[]> {
    this.loadingSubject$.next(true);

    if (this.auth.isPlatformAdmin()) {
      return this.getDefaultServices$();
    }

    return this.guideServicesApiService.getGuideServices().pipe(
      map(response => {
        return [
          {
            name: '',
            children: response.services
              .map(service => {
                if (service.type === GuideServiceTypes.PACKAGE && Array.isArray(service.sessions)) {
                  const packageSessions = service.sessions.map(session => ({
                    id: session.sessionTemplateId,
                    name: session.template.name,
                    subtitle: service.name
                  }));

                  return packageSessions;
                }

                if (Array.isArray(service.modules)) {
                  const moduleSessions = service.modules
                    .filter(module => module.moduleType === EventTypes.GROUP_SESSION && module.service)
                    .map(module => ({
                      id: module!.service!.id,
                      name: module!.service!.name,
                      subtitle: service.name
                    }));

                  return moduleSessions;
                }

                return {
                  id: service.id,
                  name: service.name,
                  description: service.description
                };
              })
              .flat()
          }
        ];
      }),
      tap(() => this.loadingSubject$.next(false)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private getDefaultServices$(): Observable<UniversalSelectorGroup[]> {
    return this.allGuideServicesApi.getServices$().pipe(
      map(services => {
        return [
          {
            name: '',
            children: services
              .map(service => {
                if (service.type === GuideServiceTypes.PACKAGE && Array.isArray((service as PackageService).sessions)) {
                  const packageSessions = (service as PackageService).sessions.map(
                    (session: PackageIncludesWithTemplate) => ({
                      id: session.sessionTemplateId!,
                      name: session.template!.name || ''
                    })
                  ) as {
                    id: number;
                    name: string;
                  }[];

                  return packageSessions;
                }

                if (Array.isArray(service.modules)) {
                  const moduleSessions = service.modules
                    .filter(module => module.moduleType === EventTypes.GROUP_SESSION)
                    .map(module => ({
                      id: module.serviceId,
                      name: module.title
                    }));

                  return moduleSessions;
                }

                return {
                  id: service.id,
                  name: service.name
                };
              })
              .flat()
          }
        ];
      }),
      tap(() => this.loadingSubject$.next(false)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
