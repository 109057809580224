import { Input, Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@app/core/currency/currency.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-payment-options-modal',
  templateUrl: './payment-options-modal.component.html',
  styleUrls: ['./payment-options-modal.component.scss', '../../../../../scss/_radio.scss']
})
export class PaymentOptionsModalComponent {
  // @ts-expect-error TS2564
  @Input() fullPrice: number;

  @Input() installmentsPrices: {
    paymentDate: string;
    amount: number;
  }[] = [];

  // @ts-expect-error TS7008
  @ViewChild('paymentOptionSelector', { static: false }) paymentOptionSelector;

  constructor(public modal: NgbActiveModal, public currency: CurrencyService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  submit() {
    this.modal.close(this.paymentOptionSelector.paymentOption);
  }
}
