import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITimezoneOption } from '@app/shared/utils/generate-timezones';
import { MultiDateScheduleTimePickerConfig } from '../../multi-date-schedule-time-picker.config';
import { IMultiDateScheduleGroup } from '../../types';

export const MULTI_DATE_SCHEDULE_TIME_PICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiDateScheduleTimePickerComponent),
  multi: true
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-multi-date-schedule-time-picker',
  templateUrl: './multi-date-schedule-time-picker.component.html',
  styleUrls: ['../../../common-styles/timezone-picker.scss', './multi-date-schedule-time-picker.component.scss'],
  providers: [MULTI_DATE_SCHEDULE_TIME_PICKER_VALUE_ACCESSOR],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'multi-date-schedule-time-picker',
    '[class.disabled]': 'isDisabled || null',
    '[attr.disabled]': 'isDisabled || null'
  }
})
export class MultiDateScheduleTimePickerComponent<TScheduleTimeSlot extends { label: string }, TSelectedTime>
  implements ControlValueAccessor
{
  private readonly numberOfVisibleItemsToAdd: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleTimeSlots: IMultiDateScheduleGroup<TScheduleTimeSlot>[] = [];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _time: TSelectedTime;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _visibleTimeSlots: IMultiDateScheduleGroup<TScheduleTimeSlot>[] = [];

  @Input()
  // @ts-expect-error TS2564
  locale: string;

  @Input()
  set scheduleTimeSlots(value: IMultiDateScheduleGroup<TScheduleTimeSlot>[]) {
    this._scheduleTimeSlots = value || [];
    this.showMoreItems();
  }

  @Input()
  searchTimezone: (term: string, timezone: ITimezoneOption) => boolean;

  @Input()
  set time(value: TSelectedTime) {
    this.writeValue(value);
  }

  @Input()
  // @ts-expect-error TS2564
  timezone: string;

  @Input()
  timezoneClearable: boolean;

  @Input()
  timezones: ITimezoneOption[] = [];

  @Output()
  readonly timeChange = new EventEmitter<TSelectedTime>();

  @Output()
  readonly timezoneChange = new EventEmitter<string>();

  isDisabled = false;

  get canShowMore(): boolean {
    return this._scheduleTimeSlots.length > this._visibleTimeSlots.length;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get time(): TSelectedTime {
    return this._time;
  }

  get visibleTimeSlots(): IMultiDateScheduleGroup<TScheduleTimeSlot>[] {
    return this._visibleTimeSlots;
  }

  constructor(readonly config: MultiDateScheduleTimePickerConfig) {
    this.numberOfVisibleItemsToAdd = config.numberOfVisibleItemsToAdd;
    // @ts-expect-error TS2322
    this.searchTimezone = config.searchTimezone;
    this.timezoneClearable = config.timezoneClearable;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectTime(time: TSelectedTime): void {
    if (this.isDisabled) {
      return;
    }

    this.onChange(time);
    this.timeChange.emit(time);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  showMoreItems(): void {
    if (this._visibleTimeSlots.length >= this._scheduleTimeSlots.length) {
      return;
    }

    this._visibleTimeSlots = this._scheduleTimeSlots.slice(
      0,
      this._visibleTimeSlots.length + this.numberOfVisibleItemsToAdd
    );
  }

  writeValue(value: TSelectedTime): void {
    this._time = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};
}
