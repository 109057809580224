import { Pipe, PipeTransform } from '@angular/core';
import { IWorkspace } from '@app/modules/workspaces/types';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';

@Pipe({
  name: 'workspaceAvatar'
})
export class WorkspaceAvatarPipe implements PipeTransform {
  transform(workspace: Pick<IWorkspace, 'type' | 'branding'>): string {
    const isTeam = WorkspaceUtility.isTeam(workspace);

    // @ts-expect-error TS2322
    return isTeam ? workspace.branding.icon : workspace.branding.photo;
  }
}
