import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-labeled-toggle',
  templateUrl: 'labeled-toggle.template.html',
  styleUrls: ['labeled-toggle.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabeledToggleComponent {
  @Input()
  labels: [string, string] = ['Off', 'On'];

  @Output()
  change = new EventEmitter<0 | 1>();

  @Input()
  // @ts-expect-error TS2564
  labelIndex: 0 | 1;

  constructor(@Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitChange({ checked }: MatSlideToggleChange) {
    this.labelIndex = checked ? 1 : 0;
    this.change.emit(this.labelIndex);
  }
}
