import { Component, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-flat-list',
  templateUrl: './flat-list.component.html',
  styleUrls: ['./flat-list.component.scss']
})
export class FlatListComponent {
  form = this.fb.group({
    items: this.fb.array([])
  });

  @Output()
  selectItem = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get itemsFormArray() {
    return this.form.controls.items as FormArray;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this.form;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm(items, selectedItemsIds) {
    this.itemsFormArray.clear();
    // @ts-expect-error TS7031
    items.forEach(({ name, id }) =>
      this.itemsFormArray.push(
        this.fb.group({
          id: [id],
          name: [name],
          selected: [selectedItemsIds.includes(id)]
        })
      )
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  onChange(itemControl) {
    this.selectItem.emit(this.getValues());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getValues() {
    return this.itemsFormArray.value.reduce(
      // @ts-expect-error TS7006
      (selectedItemsIds, { id, selected }) => (selected ? [...selectedItemsIds, id] : selectedItemsIds),
      []
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetValues() {
    this.itemsFormArray.controls.forEach(control => control.patchValue({ selected: false }, { emitEvent: false }));
    this.form.markAsPristine();
  }
}
