// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromCamelToKebabCaseKeys(source: { [propName: string]: any }): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [newPropName: string]: any;
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const target = {} as { [newPropName: string]: any };

  for (const key of Object.keys(source)) {
    const snakeCaseKey = key
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase();
    target[snakeCaseKey] = source[key];
  }

  return target;
}
