<ng-select
  data-qa-id="module-completion-select"
  [formControl]="control"
  [items]="completionTypes"
  [searchable]="false"
  [clearable]="true">
  <ng-template ng-label-tmp let-activationType="item">
    <ng-container [ngSwitch]="activationType">
      <ng-container *ngSwitchCase="ModuleCompletionTypes.AUTO">
        <ng-container [ngSwitch]="moduleType">
          <ng-container *ngSwitchCase="ModuleTypes.SESSION">
            <ng-container *ngTemplateOutlet="sessionCompleteTemplate"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="ModuleTypes.GROUP_SESSION">
            <ng-container *ngTemplateOutlet="sessionCompleteTemplate"></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span i18n="@@moduleCompletionSelectLabelAuto"> Complete when viewed </span>
          </ng-container>

          <ng-template #sessionCompleteTemplate>
            <span i18n="@@moduleCompletionSessionIsCompletedLabel"> Session is Completed </span>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="ModuleCompletionTypes.CLIENT">
        <ng-container i18n="@@moduleCompletionSelectLabelClient">Participant marks as complete</ng-container>
      </ng-container>

      <ng-container *ngIf="isQuizModule">
        <ng-container *ngSwitchCase="ModuleCompletionTypes.AFTER_SUBMIT_QUIZ">
          <ng-container i18n="@@moduleCompletionSelectLabelAfterQuizSubmit">Complete when form submitted</ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="ModuleCompletionTypes.GUIDE">
        <ng-container transloco="moduleCompletionSelectLabelPractitioner">Practitioner marks as complete</ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template ng-option-tmp let-activationType="item">
    <div class="completion-type-option">
      <ng-container [ngSwitch]="activationType">
        <ng-container *ngSwitchCase="ModuleCompletionTypes.AUTO">
          <ng-container [ngSwitch]="moduleType">
            <ng-container *ngSwitchCase="ModuleTypes.SESSION">
              <ng-container *ngTemplateOutlet="sessionCompleteTemplate"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="ModuleTypes.GROUP_SESSION">
              <ng-container *ngTemplateOutlet="sessionCompleteTemplate"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span
                data-qa-id="module-completion-select-complete-when-viewed-select-option"
                i18n="@@moduleCompletionSelectLabelAuto"
                class="completion-option-name">
                Complete when viewed
              </span>
              <span i18n="@@moduleCompletionSelectNoteAuto" class="completion-option-description">
                Participants must visit the selected module to complete it.
              </span>
            </ng-container>

            <ng-template #sessionCompleteTemplate>
              <span
                data-qa-id="module-completion-select-complete-when-viewed-select-option"
                i18n="@@moduleCompletionSessionIsCompletedLabel"
                class="completion-option-name">
                Session is Completed
              </span>
              <span i18n="@@moduleCompletionSessionIsCompletedNote" class="completion-option-description">
                Session must be scheduled and completed
              </span>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ModuleCompletionTypes.CLIENT">
          <span
            data-qa-id="module-completion-select-participant-marks-as-complete-select-option"
            i18n="@@moduleCompletionSelectLabelClient"
            class="completion-option-name">
            Participant marks as complete
          </span>
          <span i18n="@@moduleCompletionSelectNoteClient" class="completion-option-description">
            Participants must click a "Mark as complete" button that will appear at the bottom of the selected module to
            complete it.
          </span>
        </ng-container>

        <ng-container *ngIf="isQuizModule">
          <ng-container *ngSwitchCase="ModuleCompletionTypes.AFTER_SUBMIT_QUIZ">
            <span class="completion-option-name">
              <ng-container i18n="@@moduleCompletionSelectLabelAfterSubmitQuiz">
                Complete when form submitted
              </ng-container>
            </span>
            <span i18n="@@moduleCompletionSelectNoteAfterSubmitQuiz" class="completion-option-description">
              Participants must submit a form to complete the module.
            </span>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ModuleCompletionTypes.GUIDE">
          <span
            class="completion-option-name"
            data-qa-id="module-completion-select-instructor-marks-as-complete-select-option">
            <ng-container transloco="moduleCompletionSelectLabelPractitioner"
              >Practitioner marks as complete</ng-container
            >
          </span>
          <span transloco="moduleCompletionSelectNotePractitioner" class="completion-option-description">
            You or a module practitioner must mark the module as completed manually.
          </span>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-select>
