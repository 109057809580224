import { Inject, Pipe, PipeTransform } from '@angular/core';
import { GuideServiceParent } from '@app/modules/book-service';
import { SESSION_PROPERTY_FACTORY, SessionAdapter, SessionPropertyFactory } from '@libs/business/cdk/session';
import { ClientSession, ClientSessionAttribute } from '@libs/business/client/client-session';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';

@Pipe({
  name: 'normalizeSession'
})
export class NormalizeSessionPipe implements PipeTransform {
  constructor(
    @Inject(SESSION_PROPERTY_FACTORY)
    private getters: SessionPropertyFactory<IClientGuideSession, GuideServiceParent, ClientSessionAttribute>
  ) {}

  transform(session: IClientGuideSession): ClientSession<GuideServiceParent> {
    return new SessionAdapter(session, this.getters) as ClientSession<GuideServiceParent>;
  }
}
