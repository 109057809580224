import { createSelector } from '@ngrx/store';
import { PuiOption } from '@awarenow/profi-ui-core';
import { CLIENTS_TAGS_STORE_KEY } from './commons/consts';
import { CustomFieldValue } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';

export function fieldToOption({ id, name, color }: CustomFieldValue): PuiOption {
  return {
    value: name,
    data: {
      id,
      color
    }
  };
}

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getState = state => state[CLIENTS_TAGS_STORE_KEY];

export const clientsSelector = createSelector(getState, state => state.clients);
export const tagsSelector = createSelector(getState, state => state.tags);

export const clientTagsAsOptionSelector = createSelector(clientsSelector, clients =>
  Object.entries(clients).reduce(
    (acc, [clientId, tags]: [string, CustomFieldValue[]]) => ({ ...acc, [clientId]: tags.map(fieldToOption) }),
    {}
  )
);
export const tagsAsOptionSelector = createSelector(tagsSelector, tags => Object.values(tags || {}).map(fieldToOption));

export const sortedTagsAsOptionSelector = createSelector(tagsAsOptionSelector, (options: PuiOption[]) => {
  const sortFn = sortByDirection('asc');

  return options.sort((option1, option2) => sortFn(option1.value, option2.value));
});

// Values
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fieldValuesSelector = (clientId: number) =>
  // @ts-expect-error TS7031
  createSelector(clientsSelector, clients => clients[clientId].map(({ id }) => id));

export function sortByDirection(direction: 'asc' | 'desc') {
  return (valueA: string | number, valueB: string | number) => {
    if (typeof valueA !== typeof valueB) {
      if (typeof valueA === 'number') {
        valueA = `${valueA}`;
      }
      if (typeof valueB === 'number') {
        valueB = `${valueB}`;
      }
    }

    if (typeof valueA === 'string') {
      valueA = (valueA as string).toLowerCase();
    }

    if (typeof valueB === 'string') {
      valueB = (valueB as string).toLowerCase();
    }

    // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
    // one value exists while the other doesn't. In this case, existing value should come last.
    // This avoids inconsistent results when comparing values to undefined/null.
    // If neither value exists, return 0 (equal).
    let comparatorResult = 0;
    if (valueA != null && valueB != null) {
      // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
      if (valueA > valueB) {
        comparatorResult = 1;
      } else if (valueA < valueB) {
        comparatorResult = -1;
      }
    } else if (valueA != null) {
      comparatorResult = 1;
    } else if (valueB != null) {
      comparatorResult = -1;
    }

    return comparatorResult * (direction === 'asc' ? 1 : -1);
  };
}
