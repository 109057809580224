import { Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScriptOptions {
  id: string;
  src: string;
  async?: boolean;
  defer?: boolean;
}

export abstract class ScriptInjector {
  protected constructor(private _renderer: Renderer2, private _document: Document) {}

  protected appendScript$(options: IScriptOptions): Observable<void> {
    return new Observable<void>(observer => {
      if (!options.id || !options.src) {
        observer.error('Incorrect arguments');
      }

      if (this.doesScriptAlreadyEmbedded(options.id)) {
        observer.next();
        observer.complete();
      }

      const script = this._renderer.createElement('script');

      this._renderer.setProperty(script, 'type', 'text/javascript');
      this._renderer.setProperty(script, 'onload', () => {
        observer.next();
        observer.complete();
      });
      this._renderer.setProperty(script, 'id', options.id);
      this._renderer.setProperty(script, 'src', options.src);

      if (options.async == null || options.async) {
        script.async = true;
      }

      if (options.defer) {
        script.defer = true;
      }

      this._renderer.appendChild(this._document.body, script);
    });
  }

  private doesScriptAlreadyEmbedded(scriptId: string): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error TS7006
    return [].slice.call(this._document.getElementsByTagName('script')).some(script => script.id === scriptId);
  }
}
