<span
  [ngbPopover]="language"
  class="lang-selector"
  data-qa-id="language-selector-select"
  popoverClass="lang-popover"
  placement="top">
  {{ languages[currentLanguage] }}
</span>

<ng-template #language>
  <ul class="languages-list">
    <li
      *ngFor="let lang of availableLanguages"
      (click)="selectLanguage(lang)"
      class="languages-list-item"
      [attr.data-qa-id]="'language-selector-' + lang + '-select-option'">
      {{ languages[lang] }}
    </li>
  </ul>
</ng-template>
