import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { Observable } from 'rxjs';
import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { IBillingInfo } from '@app/modules/billing/interfaces';
import {
  WithDuration,
  WithHostSelectionRequirement,
  WithID,
  WithServiceHost,
  WithServiceID,
  WithWorkspaceID
} from '@app/base';
import { IScheduleGuide } from '@app/modules/schedule-boards';
import { IServiceWorkspaceAssignee } from '@app/screens/guide/guide-sessions-templates/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { Bookings } from '@appWidget/modules/booking/interfaces';
import { SessionsTypes } from '@app/shared/enums/sessions-types';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideProgramServiceParent extends WithID {
  readonly type: GuideServiceTypes.PROGRAM;
  readonly moduleId: number;
  readonly serviceParent?: GuideServiceParent;
  readonly name?: string;
  readonly createdAt?: string;
  readonly expiresAt?: string;
  readonly packagePurchaseId?: number;
  readonly packagePurchaseDate?: string;
  readonly childrenCount?: number;
  readonly childrenAvailableCount?: number;
}

export enum BookingSteps {
  SERVICE_DETAILS = 'SERVICE_DETAILS',
  HOST_SELECTION = 'HOST_SELECTION',
  PAYMENT = 'PAYMENT',
  BILLING_DATA = 'BILLING_DATA',
  PAYMENT_OPTIONS = 'PAYMENT_OPTIONS'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePackageServiceParent extends WithID {
  readonly type: GuideServiceTypes.PACKAGE;
  readonly serviceParent?: GuideServiceParent;
  readonly name?: string;
  readonly createdAt?: string;
  readonly expiresAt?: string;
  readonly packagePurchaseId?: number;
  readonly packagePurchaseDate?: string;
  readonly childrenCount?: number;
  readonly childrenAvailableCount?: number;
}

export type GuideServiceParent = IGuideProgramServiceParent | IGuidePackageServiceParent;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBasicGuideService extends WithID {
  readonly type: GuideServiceTypes;
  readonly serviceParent?: GuideServiceParent;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideService
  extends IBasicGuideService,
    Readonly<Partial<WithHostSelectionRequirement>>,
    Readonly<Partial<WithWorkspaceID>> {
  readonly guideId?: number;
  readonly name: string;
  readonly connectionType?: SessionConnectionTypes;
  readonly description?: string | null;
  readonly duration?: number;
  readonly overtimeCharge?: number;
  readonly price?: number | null;
  readonly subscriptionPrice?: number | null;
  readonly totalPayments?: number | null;
  readonly subscriptionRecurrency?: SubscriptionRecurrency | null;
  readonly participantsLimit?: number | null;
  readonly billingData?: IBillingInfo;
  readonly team?: IServiceWorkspaceAssignee[];
  readonly workspace?: { id: number; type: WorkspacesTypes };
  readonly address?: string | null;
  readonly sessionType?: SessionsTypes;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceOptions<TService> {
  readonly guide: IScheduleGuide;
  readonly service?: TService;
  readonly serviceHost?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceBillingInfo extends IBillingInfo {
  required?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceBookingOptions<TService>
  extends IServiceOptions<TService>,
    Partial<WithHostSelectionRequirement> {
  readonly analyticSourceType?: AnalyticSourceTypes;
  readonly date?: string;
  readonly dateEnd?: string;
  readonly timezone?: string;
  readonly paymentOption?: PaymentOptions;
  readonly billingData?: IServiceBillingInfo;
  splitByWorkspaces?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceDetails<TService> extends Partial<WithDuration>, Partial<WithHostSelectionRequirement> {
  readonly service: TService;
  readonly date?: string;
  readonly dateEnd?: string;
  readonly timezone?: string;
  readonly timeFormat?: string;
  readonly billingData?: IServiceBillingInfo;
  serviceHost?: IScheduleGuide['id'];
  paymentOption?: PaymentOptions;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceBookingDetails<TService> extends IServiceDetails<TService> {
  readonly paymentOption?: PaymentOptions;
  readonly availableHosts?: number[];
  readonly isFree?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceBookingRequest
  extends Readonly<WithServiceID>,
    Readonly<Partial<WithDuration>>,
    Readonly<Partial<WithServiceHost>> {
  readonly type: GuideServiceTypes;
  readonly date?: string;
  readonly timezone?: string;
  readonly serviceParent?: GuideServiceParent;
  readonly billingData?: IBillingInfo;
  readonly bookings: Bookings[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceBookingResult extends WithID, Readonly<WithServiceID> {
  readonly serviceType: GuideServiceTypes;
  readonly templateId?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceBookingResponse extends IServiceBookingResult {
  readonly payRate?: number;
  readonly billingData?: IBillingInfo;
}

// NOTE: currently it's not possible to type observable error response
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookValidationStrategy<TValidationArgs, TValidationSuccess> {
  validate$: (args: TValidationArgs) => Observable<TValidationSuccess>;
}

export type IBookingStepValidationStrategy = {
  [key in BookingSteps]: (options: IServiceBookingOptions<IGuideService>) => boolean;
};
