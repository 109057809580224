import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientNote } from '@app/modules/client-notes/client-notes.type';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { INoteCardUpdateContent, INoteEditorContent, MetaShared } from '@app/shared/interfaces/notes';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss']
})
export class NoteCardComponent {
  editing = false;

  readonly maxSharedUsersAvatars = 3;

  readonly noteEditorMinLines = 1;

  @Input()
  set note(val: ClientNote | GuideNote) {
    this._note = val;
  }

  get note(): ClientNote | GuideNote {
    return this._note;
  }

  @Input()
  // @ts-expect-error TS2564
  noteIndex: number;

  @Input()
  hideDelete = false;

  @Input()
  hideShare = false;

  @Output()
  deleteNote = new EventEmitter<number>();

  @Output()
  openSharingSettings? = new EventEmitter<number>();

  @Output()
  shareNote = new EventEmitter<number>();

  @Output()
  grantViewerAccess = new EventEmitter<number>();

  @Output()
  revokeViewerAccess = new EventEmitter<number>();

  @Output()
  manageAccess = new EventEmitter<number>();

  @Output()
  pinNote = new EventEmitter<number>();

  @Output()
  unpinNote = new EventEmitter<number>();

  @Output()
  updateNote = new EventEmitter<INoteCardUpdateContent>();

  @Output()
  endEditing = new EventEmitter<INoteCardUpdateContent>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _note: ClientNote | GuideNote;

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onNoteChange(noteContent: INoteEditorContent) {
    this.updateNote.emit({
      index: this.noteIndex,
      ...noteContent
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onEndEditing(noteContent: INoteEditorContent) {
    this.endEditing.emit({
      index: this.noteIndex,
      ...noteContent
    });
  }

  isSharedBy(): boolean {
    return !!(this.note.metaSharedBy || {}).user;
  }

  isSharedWith(): boolean {
    return !!(this.note.metaSharedWith || []).length;
  }

  isMetaRecording(): boolean {
    return !!this.note.metaRecording;
  }

  // @ts-expect-error TS7006
  lockControls(editing): void {
    if (!this.isSharedBy()) {
      this.editing = editing;
    }
  }

  startEditingNote(): void {
    this.editing = true;
    this._cdr.detectChanges();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  viewersTrackBy(index, viewer: MetaShared) {
    // @ts-expect-error TS2531
    return viewer.user.id;
  }
}
