import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostSelectComponent } from './host-select.component';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiCardModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiSharedModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HostSelectComponent],
  imports: [
    CommonModule,
    PuiAvatarModule,
    PuiLetModule,
    PuiCardModule,
    PuiInputModule,
    PuiLinkModule,
    PuiTypographyModule,
    PuiDropdownModule,
    PuiIconModule,
    ScrollingModule,
    PuiAvatarGroupModule,
    PuiInputCheckboxModule,
    PuiSharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [HostSelectComponent]
})
export class HostSelectModule {}
