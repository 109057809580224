import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ClientSessionAttribute,
  ClientSessionConnectionTypeAttribute,
  ClientSessionGuestsAttribute,
  ClientSessionGuideAttribute,
  ClientSessionRecurringAttribute,
  ClientSessionTimeAttribute
} from '../../types';
import { puiDefaultProp } from '@awarenow/profi-ui-core';
import { ClientSessionAttributesEnum } from '../../enums';

@Component({
  selector: 'app-session-attributes',
  templateUrl: './session-attributes.component.html',
  styleUrls: ['./session-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionAttributesComponent {
  readonly attributeTypes = ClientSessionAttributesEnum;

  @Input()
  @puiDefaultProp()
  attributes: ClientSessionAttribute[] = [];

  @Input()
  showJoinSessionLink = false;

  trackByType(_: number, attribute: ClientSessionAttribute): ClientSessionAttributesEnum {
    return attribute.type;
  }

  asGuideAttribute(attribute: ClientSessionAttribute): ClientSessionGuideAttribute {
    return attribute as ClientSessionGuideAttribute;
  }

  asTimeAttribute(attribute: ClientSessionAttribute): ClientSessionTimeAttribute {
    return attribute as ClientSessionTimeAttribute;
  }

  asConnectionTypeAttribute(attribute: ClientSessionAttribute): ClientSessionConnectionTypeAttribute {
    return attribute as ClientSessionConnectionTypeAttribute;
  }

  asRecurringAttribute(attribute: ClientSessionAttribute): ClientSessionRecurringAttribute {
    return attribute as ClientSessionRecurringAttribute;
  }

  asGuestsAttribute(attribute: ClientSessionAttribute): ClientSessionGuestsAttribute {
    return attribute as ClientSessionGuestsAttribute;
  }
}
