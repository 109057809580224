import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';

@Component({
  selector: 'app-upcoming-client-sessions',
  templateUrl: './upcoming-client-sessions.component.html',
  styles: ['.upcoming-sessions-list { display: flex; flex-direction: column }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcomingClientSessionsComponent {
  @Input()
  sessions: IClientGuideSession[] | undefined;

  @Input()
  hideHeader = false;
}
