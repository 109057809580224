export type AlertClassName = 'warning' | 'error' | 'success' | 'review' | 'trial-membership' | 'clients-limit';

export const AlertKey = {
  CLIENT_LIMIT_REACHED: 'clients-limit-reached',
  COUPON_APPLIED: 'coupon-applied',
  DECREASE_BALANCE: 'decrease-balance',
  EMAIL_SUCCESS: 'email-success',
  EMAIL_WARNING: 'email-warning',
  INCOMPLETE_GUIDE_PROFILE: 'incomplete-guide-profile',
  INCREASE_BALANCE: 'increase-balance',
  REVIEW: 'review',
  TEAM_MEMBERSHIP: 'team-membership',
  TRIAL_LTD_MEMBERSHIP: 'trial-ltd-membership',
  TRIAL_SUBSCRIPTION_MEMBERSHIP: 'trial-subscription-membership',
  TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM: 'trial-subscription-membership-team',
  TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED: 'trial-subscription-membership-team-finished'
} as const;
export type AlertKey = typeof AlertKey[keyof typeof AlertKey];

export interface Alert {
  id?: number;
  className: AlertClassName;
  title: string;
  description: string;
  template: AlertKey;
  isClosing?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AlertCreaters = { [key in AlertKey]: (arg1: any) => Alert };
