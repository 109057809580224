<div class="video-gallery">
  <ng-scrollbar>
    <div
      class="video"
      *ngFor="let video of videos; let i = index"
      (click)="changeVideo(i)"
      [class.active]="i === selectedVideo"
      [ngStyle]="{ 'background-image': 'url(' + video.thumbnail + ')' }"></div>
  </ng-scrollbar>
</div>
