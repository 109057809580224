import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { config } from '@app/core';
import { IProgramAssignee } from '@app/screens/guide/guide-programs/types';

@Injectable()
export class ProgramAssigneesService {
  private readonly API_ENDPOINT: string = `${config.apiPath}/user/guide/programs`;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _assigneesBeh$: BehaviorSubject<IProgramAssignee[]> = new BehaviorSubject<IProgramAssignee[]>([]);

  assignees$: Observable<IProgramAssignee[]> = this._assigneesBeh$.asObservable();

  constructor(private _http: HttpClient) {}

  getAssignees(programId: number): void {
    this._http
      .get<IProgramAssignee[]>(`${this.API_ENDPOINT}/${programId}/hosts`)
      .subscribe(assignees => this._assigneesBeh$.next(assignees));
  }
}
