<div class="input-group">
  <input
    [attr.data-qa-id]="qaId"
    type="text"
    class="form-control"
    [formControl]="priceControl"
    (input)="onInput()"
    (blur)="onBlur()" />
  <div class="input-group-append currency-sign">
    <span class="input-group-text">{{ currencySign }}</span>
  </div>
</div>
