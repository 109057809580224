<h4 data-qa-id="session-action-result-work-with-subtitle" class="sub-title">
  <ng-container i18n="@@namedSessionWithUser">
    {{ session?.name }}
    {{ session?.user?.name ? 'with ' + session?.user?.name : '' }}
  </ng-container>
</h4>
<div class="session-details-item">
  <pui-icon svgIcon="pui:calendar" size="s" aria-hidden="true"></pui-icon>
  <ng-container *ngIf="session.session?.recurringSessionsDetails as recurring; else single">
    <div class="d-flex flex-column">
      <ng-container *ngFor="let row of recurring">
        <p>
          <span class="current-date">
            {{ row | sessionDateTime: 'dateStart' }}
          </span>
        </p>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #single>
    <span [class.strong]="!!session.oldDate" class="current-date">
      {{ session | sessionDateTime: 'date' }}
    </span>
    <span *ngIf="session.oldDate" class="old-date">{{ session | sessionDateTime: 'oldDate' }}</span>
  </ng-template>
</div>
<div class="session-details-item">
  <pui-icon svgIcon="pui:clock" size="s" aria-hidden="true"></pui-icon>
  <span>
    {{ session.duration }}
    <ng-container [ngPlural]="session.duration">
      <ng-template i18n="@@minute:one" ngPluralCase="=1">minute</ng-template>
      <ng-template i18n="@@minute:many" ngPluralCase="other">minutes</ng-template>
    </ng-container>
  </span>
</div>
<div class="session-details-item">
  <pui-icon svgIcon="pui:tag" size="s" aria-hidden="true"></pui-icon>
  <span>
    <ng-container i18n="@@totalCost">Total cost</ng-container>:
    {{ session.session | price }}
  </span>
</div>

<div class="session-details-item session-location" *ngIf="sessionLink">
  <pui-icon svgIcon="pui:place" size="s" aria-hidden="true"></pui-icon>
  <span>
    <ng-container i18n="@@joinSession">Join session</ng-container>:
    <span>
      <a [href]="sessionLink" target="_blank">{{ sessionLink }}</a>
    </span>
  </span>
</div>
