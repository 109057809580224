import { CookieService } from 'ngx-cookie-service';
import { DateTime } from 'luxon';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { fromEvent, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AUTH_API, SIGN_UP_ALTERNATIVE_PROVIDER } from '@app/shared/constants/endpoints';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { GLOBAL_SESSION_STORAGE } from '@app/core/session-storage/session-storage-provider';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { GoogleAnalyticsCategories, InternalEvents } from '@app/core/analytics/types';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';
import { ILocale } from '@env/locale.interface';
import { IUserMembership } from '@app/core/membership/types';
import { LocaleService } from '@app/core/locale/locale.service';
import { LogService } from '@app/core/log/log.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { environment } from '@env/environment';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { LocalStorageKeys } from '@app/cdk/enums';
import { EmailAlertsService } from '../../email/email-alerts.service';
import { SsrCookieService } from '../../ssr-cookie/ssr-cookie.service';
import { WindowService } from '@app/core/window';
import { TfaChallengeInfo } from '@app/modules/auth/interfaces/tfa-challendge-info.interface';
import { IUser } from '@app/shared/interfaces/user';
import { UserService } from '@app/core/users';
import { isIframe } from '@appWidget/services/is-iframe.service';
import { ApplicationEventService } from '@libs/services/application-events/applications-events.service';
import { AuthEvent } from '@libs/services/application-events/events/autorized.event';

export interface User {
  email: string;
  password: string;
  promoCode?: string;
}

export interface PublicUserInfo {
  RoleId?: UserRoles;
  firstName: string;
  withoutPassword?: boolean;
  emailVerified?: boolean;
  isSsoAuth?: boolean;
}

export interface UserGuide extends User {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  subscribeNews?: boolean;
  specialization?: string;
}

enum AuthProviders {
  FACEBOOK = 'facebook',
  GOOGLE = 'google'
}

interface LoginAnalyticsData {
  authProvider: AuthProviders;
  isSignUp: boolean;
}

@Injectable()
export class AuthService {
  // @ts-expect-error TS2564
  private readonly isBrowser: boolean;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _redirectUrl: string;

  // ToDo Deprecated. Use UserService from core (src/app/core/users/user.service.ts)
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private authSubject$: ReplaySubject<any>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _incompleteProfileNotification$: Subject<any>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _serverTime$: Subject<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _invitationCode: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _sharedEventActionCode: any;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _giftCouponActivationHash: string;

  // @ts-expect-error TS2564
  private locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userMembership$ = new ReplaySubject<IUserMembership | null>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _logout = new Subject<void>();

  private readonly openTfaAuthStepSource$ = new Subject<TfaChallengeInfo>();
  private readonly openConfirmEmailStepSource$ = new Subject<{ email: string }>();

  private tfaChallengeAuthToken = '';

  private isInIframe = false;

  /**
   * Turn on redirection when user change state on other page
   */
  shouldRedirectWhenUserChange = true;

  isAuthorized = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any = {};

  // @ts-expect-error TS2322
  signupByToken: { email: string; hash: string } = null;

  // @ts-expect-error TS2322
  workspaceMemberSignUpInfo: {
    email: string;
    invitationCode: string;
    authState: AuthStates;
    hasAlternativeProvider?: boolean;
  } = null;

  presetEmail = '';

  hasClientProgramInvitation = false;

  get redirectUrl(): string {
    return this._redirectUrl;
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get incompleteProfileNotification$(): Observable<any> {
    return this._incompleteProfileNotification$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get serverTime$(): Observable<any> {
    return this._serverTime$.asObservable();
  }

  get openTfaAuthStep$(): Observable<TfaChallengeInfo> {
    return this.openTfaAuthStepSource$.asObservable();
  }

  get openConfirmEmailStep$(): Observable<{ email: string }> {
    return this.openConfirmEmailStepSource$.asObservable();
  }

  get hashedId(): string {
    return this.isBrowser ? this.user.hashedId : this.ssrCookieService.get('hashedid');
  }

  get userHasActiveMembership(): boolean {
    return (
      this.user.free ||
      (this.user.membership &&
        (this.user.membership.free ||
          this.user.membership.endless ||
          (this.user.membership.currentPeriodEnd &&
            DateTime.fromISO(this.user.membership.currentPeriodEnd).toMillis() > DateTime.local().toMillis())))
    );
  }

  get userHasNotTrialActiveMembership(): boolean {
    return (
      this.user.free ||
      (this.user.membership &&
        !this.user.membership.isTrial &&
        (this.user.membership.free ||
          this.user.membership.endless ||
          (this.user.membership.currentPeriodEnd &&
            DateTime.fromISO(this.user.membership.currentPeriodEnd).toMillis() > DateTime.local().toMillis())))
    );
  }

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private cookieService: CookieService,
    private ssrCookieService: SsrCookieService,
    private http: HttpClient,
    private windowService: WindowService,
    private emailAlerts: EmailAlertsService,
    private logService: LogService,
    private _analyticsService: AnalyticsService,
    private brandingService: BrandingService,
    private _notifications: NotificationsService,
    private localeService: LocaleService,
    private readonly user$: UserService,
    private _runtimeConfigService: RuntimeConfigService,
    private applicationEventService: ApplicationEventService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platform: any,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    @Inject(GLOBAL_SESSION_STORAGE) private _sessionStorage: Storage,
    @Optional() @SkipSelf() parent?: AuthService
  ) {
    if (parent) {
      return parent;
    }

    this.authSubject$ = new ReplaySubject(1);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._serverTime$ = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._incompleteProfileNotification$ = new Subject<any>();
    this.isBrowser = isPlatformBrowser(this.platform);
    this.isInIframe = isIframe(this.platform);

    if (this.isBrowser) {
      fromEvent<StorageEvent>(window, 'storage')
        .pipe(
          filter(() => this.shouldRedirectWhenUserChange),
          filter(event => event.key === 'user'),
          map(() => this.localStorage.getItem(LocalStorageKeys.USER) as string),
          map(storageData => {
            /**
             * Prevent invalid data for JSON
             */
            try {
              return storageData && JSON.parse(storageData);
            } catch {
              return null;
            }
          })
        )
        .subscribe(storageUser => {
          if (!storageUser && ((this.user && this.user.authToken) || this.isAuthorized)) {
            console.log('***** storage signout');

            this.signout();
          }

          if (storageUser && storageUser.RoleId !== this.user.RoleId) {
            console.log('***** storage redirect');

            if (storageUser.RoleId === UserRoles.CLIENT) {
              this._browserWindow.location.href = '/client/dashboard';
            } else if (storageUser.RoleId === UserRoles.GUIDE) {
              this._browserWindow.location.href = `/${environment.guideRoute}/dashboard`;
            } else if (storageUser.RoleId === UserRoles.ADMIN) {
              this._browserWindow.location.href = '/admin/dashboard';
            } else if (storageUser.RoleId === UserRoles.SUPER_ADMIN) {
              this._browserWindow.location.href = '/super-admin';
            }
          }
        });

      this._serverTime$.subscribe(time =>
        this.logService.log(
          {
            serverTime: time,
            diffMinutes: Math.floor(DateTime.local().diff(DateTime.fromISO(time)).as('minutes'))
          },
          'difference between server and client time'
        )
      );
    }

    this.locale = this.localeService.getLocale();
  }

  setUserMembership(membership?: IUserMembership | null): void {
    this.user.membership = membership;
    this.localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(this.user));
    this._userMembership$.next(membership);
  }

  getTokenFromCookie(): string {
    return this.isBrowser ? this.cookieService.get('token') : this.ssrCookieService.get('token');
  }

  setToken(token: string, remember?: boolean): void {
    if (this.isBrowser) {
      this.cookieService.set(
        'token',
        token,
        // @ts-expect-error TS2345
        remember ? 9999 : null,
        '/',
        null,
        this.isInIframe ? true : null,
        this.isInIframe ? 'None' : 'Lax'
      );
    }
  }

  removeToken(): void {
    if (this.isBrowser) {
      this.cookieService.delete('token', '/');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setRoleSwitching() {
    if (this.isBrowser) {
      this.cookieService.set('roleSwitching', 'true');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  removeRoleSwitching() {
    if (this.isBrowser) {
      this.cookieService.delete('roleSwitching');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getRoleSwitchingFromCookie() {
    if (this.isBrowser) {
      return this.cookieService.get('roleSwitching');
    }

    return undefined;
  }

  // @ts-expect-error TS7006
  addToUser(params): void {
    for (const key of Object.keys(params)) {
      this.user[key] = params[key];
    }
    this.remember(this.user);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAuth(): Observable<any> {
    return this.authSubject$.asObservable();
  }

  onLogout(): Observable<void> {
    return this._logout.asObservable();
  }

  onMembershipChanged$(): Observable<IUserMembership | null> {
    return this._userMembership$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkAlternativeAccount(): Observable<any> {
    return this.http.post(`${AUTH_API}/alternativeAccount/check`, {
      timezone: DateTime.local().zoneName
    });
  }

  // @ts-expect-error TS7006
  remember(user, remember?: boolean): void {
    const { authToken } = user;
    this.setToken(authToken, remember);
    user.authToken = null;
    user.remember = remember;
    this.localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(user));
    this.cookieService.set(
      'hashedid',
      user.hashedId,
      9999,
      '/',
      // @ts-expect-error TS2345
      null,
      this.isInIframe ? true : null,
      this.isInIframe ? 'None' : 'Lax'
    );
    user.authToken = authToken;
  }

  checkUserExist(email: string): Observable<PublicUserInfo | null> {
    return this.http.post<PublicUserInfo | null>(`${AUTH_API}/checkUserExist`, { email });
  }

  verifyTfaOtpToken(
    tfaChallengeAuthToken: string,
    otpToken: string,
    onlyClient = true,
    onlyGuide = false
  ): Observable<{ user: IUser }> {
    return this.http
      .post<{ user: IUser }>(
        `${AUTH_API}/tfa/app`,
        {
          otpToken
        },
        {
          headers: {
            token: tfaChallengeAuthToken
          }
        }
      )
      .pipe(
        tap(data => {
          if (
            (onlyClient && data.user.RoleId === UserRoles.GUIDE) ||
            (onlyGuide && data.user.RoleId === UserRoles.CLIENT)
          ) {
            this.storeUserIfRequired(data.user, true);
            this.setUser(data.user);
            this.brandingService.loadBranding();
            return;
          }

          this.authorize(data, true);
        })
      );
  }

  verifyTfaBackupCode(
    tfaChallengeAuthToken: string,
    backupCode: string,
    onlyClient = true,
    onlyGuide = false
  ): Observable<{ user: IUser }> {
    return this.http
      .post<{ user: IUser }>(
        `${AUTH_API}/tfa/backup`,
        {
          backupCode
        },
        {
          headers: {
            token: tfaChallengeAuthToken
          }
        }
      )
      .pipe(
        tap(data => {
          if (
            (onlyClient && data.user.RoleId === UserRoles.GUIDE) ||
            (onlyGuide && data.user.RoleId === UserRoles.CLIENT)
          ) {
            this.storeUserIfRequired(data.user, true);
            this.setUser(data.user);
            this.brandingService.loadBranding();
            return;
          }

          this.authorize(data, true);
        })
      );
  }

  sendTfaSmsToken(): Observable<boolean> {
    return this.http
      .post<{ result: boolean }>(
        `${AUTH_API}/tfa/sms/send`,
        {},
        {
          headers: {
            token: this.tfaChallengeAuthToken
          }
        }
      )
      .pipe(map(res => res.result));
  }

  verifyTfaSmsToken(otpToken: string): Observable<IUser | boolean> {
    return this.http
      .post<{ user: IUser }>(
        `${AUTH_API}/tfa/sms/validate`,
        { otpToken },
        {
          headers: {
            token: this.tfaChallengeAuthToken
          }
        }
      )
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            return of({ user: false });
          }
          return throwError(error);
        }),
        tap(data => {
          if (data.user) {
            this.authorize(data, true);
          }
        }),
        map(res => res.user)
      );
  }

  setTfaAuthToken(token: string): void {
    this.tfaChallengeAuthToken = token;
  }

  signin(
    email: string,
    password: string,
    remember = true,
    onlyClient = true,
    onlyGuide = false,
    teamInvitationCode = ''
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http
      .post(`${AUTH_API}/signin`, {
        email,
        password,
        timezone: DateTime.local().zoneName,
        teamInvitationCode,
        createTeam: !!localStorage.getItem(LocalStorageKeys.CREATE_TEAM)
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((res: any) => {
          const data = res._body ? res.json() : res;

          if (data.user && data.user.time) {
            this._serverTime$.next(data.user.time);
          }

          if (data.user.tfaChallenge) {
            return;
          }

          if (
            (onlyClient && data.user.RoleId === UserRoles.GUIDE) ||
            (onlyGuide && data.user.RoleId === UserRoles.CLIENT)
          ) {
            this.storeUserIfRequired(data.user, remember);
            this.setUser(data.user);
            this.brandingService.loadBranding();
            return;
          }

          this.authorize(res, remember);
        }),
        finalize(() => {
          if (Object.keys(this.user).length) {
            this._analyticsService.event(InternalEvents.LOGIN, {
              method: 'Email',
              id: this.user.id,
              email: this.user.email,
              role: this.user.RoleId === UserRoles.CLIENT ? 'Client' : 'Guide'
            });
          }
        })
      );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signinByToken(token, remember = true): Observable<any> {
    return this.http.post(`${AUTH_API}/signin/token`, { token, timezone: DateTime.local().zoneName }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => data.user && data.user.time && this._serverTime$.next(data.user.time)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((res: any) => {
        if (!this.isAuthorized) {
          this.authorize(res, remember);
        }
      }),
      finalize(() => {
        if (Object.keys(this.user).length) {
          this._analyticsService.event(InternalEvents.LOGIN, { method: 'Email' });
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signinAlternativeAccount(notRedirect = false): Observable<any> {
    const remember = this.user.remember !== undefined ? this.user.remember : true;
    // @ts-expect-error TS7034
    let userRoleId;
    this.setRoleSwitching();
    return this.http
      .post(`${AUTH_API}/alternativeAccount/signin`, {
        timezone: DateTime.local().zoneName,
        createTeam: !!localStorage.getItem(LocalStorageKeys.CREATE_TEAM)
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((data: any) => {
          userRoleId = data.user.RoleId;
          return data.user && data.user.time && this._serverTime$.next(data.user.time);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((data: any) => this.authorizeWithAlternativeProfile(data, remember, notRedirect)),
        finalize(() => {
          this.removeRoleSwitching();
          this._analyticsService.event(InternalEvents.LOGIN, {
            method: 'Switch',
            id: this.user.id,
            email: this.user.email,
            // @ts-expect-error TS7005
            role: userRoleId === UserRoles.CLIENT ? 'Client' : 'Guide'
          });
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAndSwitchWorkspace(saveRole = true): Observable<any> {
    if (!saveRole) {
      this.setRoleSwitching();
    }
    return this.http
      .post(`${AUTH_API}/alternativeAccount/signin`, {
        timezone: DateTime.local().zoneName,
        createTeam: !!localStorage.getItem(LocalStorageKeys.CREATE_TEAM),
        saveRole
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((data: any) => {
          return data.user && data.user.time && this._serverTime$.next(data.user.time);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((data: any) => {
          if (data.user) {
            if (!saveRole) {
              this.reset();
            }
            this.storeUserIfRequired(data.user, !!this.remember);
            this.setUser(data.user);
            this.notifyAuthorized();
            this.brandingService.loadBranding();
          }
          if (!saveRole) {
            this.removeRoleSwitching();
          }
        })
      );
  }

  signinAlternativeProvider(
    // @ts-expect-error TS7006
    user,
    remember = true,
    analyticsData: LoginAnalyticsData,
    onlyGuide = false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (!user) {
      return of();
    }
    this.user = user;
    this.storeUserIfRequired(user);

    return this.http.post(`${AUTH_API}/check`, { timezone: DateTime.local().zoneName }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.signout();
        return throwError(err);
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => data.user && data.user.time && this._serverTime$.next(data.user.time)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => {
        if (data.user && !data.user.authToken) {
          data.user.authToken = user.authToken;
        }
        if (onlyGuide && data.user.RoleId === UserRoles.CLIENT) {
          this.brandingService.loadBranding();

          return;
        }

        this.authorize(data, remember);
      }),
      finalize(() => {
        if (Object.keys(this.user).length) {
          this._analyticsService.event(InternalEvents.LOGIN, { method: analyticsData.authProvider });
        }
      })
    );
  }

  changeToken(token: string): void {
    this.removeToken();
    this.setToken(token);
    this.user.authToken = token;
    this.authSubject$.next(this.user);
    this.user$.next(this.user);
  }

  signout(reloadPage = true): void {
    if (this.isBrowser) {
      this.isAuthorized = false;
      this.reset();
      this.authSubject$.next(null);
      this.user$.next(undefined);
      this._userMembership$.next(null);
      this.logService.updateUserInfo(null);
      this._logout.next();

      if (reloadPage) {
        if (!(!this.router.url.includes('gift-certificates') && !/^\/[^/]+$/.test(this.router.url))) {
          this._browserWindow.location.replace(`${this.locale.baseUrl}${this.router.url.split('?').shift()}`);
        } else {
          this._browserWindow.location.replace(`${this.locale.baseUrl}/auth/sign-in`);
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signup(user: User, remember?: boolean): Observable<any> {
    return this.http.post(`${AUTH_API}/signup`, this.mutateSignUpPayload(user, remember)).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => {
        if (data.user && data.user.promoCodeError) {
          const message = `Invalid invite code`;
          this._notifications.warn(message);
        }
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => data.user && data.user.time && this._serverTime$.next(data.user.time)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => this.authorize(data, remember)),
      finalize(() => {
        if (Object.keys(this.user).length) {
          const { membership } = this.user;

          this.sendSignUpEvents(this.user);

          if (membership && membership.free) {
            this._analyticsService.event(InternalEvents.FREE_PLAN_STARTED, {});
          }

          if (membership && membership.isTrial) {
            this._analyticsService.event(InternalEvents.TRIAL_STARTED, {});
          }
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signupAlternativeProvider(user: User, remember = true, analyticsStats: LoginAnalyticsData): Observable<any> {
    // Todo: remove "provider" property from body of post query after auth analytics will be moved to front
    return this.http
      .post(SIGN_UP_ALTERNATIVE_PROVIDER, {
        ...user,
        provider: analyticsStats.authProvider,
        timezone: DateTime.local().zoneName
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((data: any) => data.user && data.user.time && this._serverTime$.next(data.user.time)),
        tap(() => {
          this.localStorage.removeItem(LocalStorageKeys.CLIENT_INVITATION_CODE);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tap((data: any) => this.authorize(data, remember)),
        finalize(() => {
          if (Object.keys(this.user).length) {
            const { membership } = this.user;

            this.sendSignUpEvents(this.user, analyticsStats.authProvider);

            if (membership && membership.free) {
              this._analyticsService.event(InternalEvents.FREE_PLAN_STARTED, {});
            }

            if (membership && membership.isTrial) {
              this._analyticsService.event(InternalEvents.TRIAL_STARTED, {});
            }
          }
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signupAlternativeAccount(accountData?: any, remember?: boolean, createTeam?: boolean): Observable<any> {
    if (remember === undefined) {
      remember = this.user.remember !== undefined ? this.user.remember : true;
    }
    let formData = {};
    let headersData = {};

    // ToDo: Probably not in use already
    if (accountData) {
      formData = this.buildGuideFormData(accountData, false);
      headersData = { headers: this.buildFormDataHeaders() };
    }

    this.setRoleSwitching();

    return this.http.post(`${AUTH_API}/alternativeAccount/signup`, { ...formData, createTeam }, headersData).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => data.user && data.user.time && this._serverTime$.next(data.user.time)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => this.authorizeWithAlternativeProfile(data, remember)),
      finalize(() => {
        this.removeRoleSwitching();

        if (Object.keys(this.user).length) {
          const analyticsData = {
            category: GoogleAnalyticsCategories.SIGNUP,
            method: 'switch',
            signUpDate: this.user.createdAt
          };

          if (this.user.RoleId === UserRoles.CLIENT) {
            this._analyticsService.event(InternalEvents.SIGN_UP_CLIENT, analyticsData);
          } else {
            this._analyticsService.event(InternalEvents.SIGN_UP_PRACTITIONER, analyticsData);
          }

          this._analyticsService.event(InternalEvents.SIGN_UP, {
            method: 'switch',
            id: this.user.id,
            email: this.user.email,
            role: this.user.RoleId === UserRoles.CLIENT ? 'Client' : 'Guide',
            'SignUp date': DateTime.fromISO(this.user.createdAt).toFormat('yyyy/MM/dd')
          });
        }
      })
    );
  }

  // ToDo: Probably not in use already
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signupGuide(user: UserGuide): Observable<any> {
    const formData = this.buildGuideFormData(user);
    const headers = this.buildFormDataHeaders();
    return this.http.post(`${AUTH_API}/signup-guide`, formData, { headers }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => data.user && data.user.time && this._serverTime$.next(data.user.time)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => {
        this.redirectUrl = `/${environment.guideRoute}/settings/edit-profile`;
        this.authorize(data, true);
      })
    );
  }

  // @ts-expect-error TS7006
  tryToSignIn(invitationCode): void {
    const storageData = this.localStorage.getItem(LocalStorageKeys.USER);
    const storageUser = storageData ? JSON.parse(storageData) : false;

    if (!storageUser) {
      this.removeToken();
      return;
    }

    // TODO: better fix Auth/Client/Guide Guards, see below
    // WARNING: this is a hack mainly for Client/Guide Guard,
    // because actually we know nothing about user until server's response received
    this.user = storageUser;
    this.user.authToken = this.getTokenFromCookie();
    // WARNING: this is a hack for Auth Guard,
    // because actually we don't know if user is authorized until server's response received

    if (!this.user.authToken) {
      this.signout();
      return;
    }

    this.isAuthorized = true;

    this.http
      .post(`${AUTH_API}/check`, { timezone: DateTime.local().zoneName })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.signout();
          return throwError(err);
        }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mergeMap((res: any) => {
          if (invitationCode) {
            return this.http.post(`${AUTH_API}/signin-by-ws-invitation`, { invitationCode });
          }
          return of(res);
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: any) => {
        if (data.user) {
          data.user.authToken = data.user.authToken || storageUser.authToken;

          this.storeUserIfRequired(data.user);
          this.setUser(data.user);

          if (data.user.time) {
            this._serverTime$.next(data.user.time);
          }

          this.notifyAuthorized();
          this.showPostAuthNotifications();
          this.redirectUserAfterReAuthentication();
        } else {
          this.signout();
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  getUserRole(user?: any) {
    let userRole = null;
    const RoleId = user?.RoleId || this.user.RoleId;
    if (RoleId) {
      userRole = `${UserRoles[RoleId]}`.toLowerCase();
    }
    return userRole;
  }

  getUserRoleId(): UserRoles {
    return this.user.RoleId;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authorize(res: any, remember?: boolean): void {
    this._invitationCode = this.localStorage.getItem(LocalStorageKeys.CLIENT_INVITATION_CODE);
    this._sharedEventActionCode = this.localStorage.getItem(LocalStorageKeys.SHARED_EVENT_ACTION_CODE);
    // @ts-expect-error TS2322
    this._giftCouponActivationHash = this.localStorage.getItem(LocalStorageKeys.GIFT_COUPON_ACTIVATION_HASH);

    this.reset();

    const data = res._body ? res.json() : res;

    if (data.user && data.user.activatedInvites && data.user.activatedInvites.includes(this._invitationCode)) {
      this._invitationCode = null;
    }

    if (data.user) {
      this.storeUserIfRequired(data.user, remember);
      this.setUser(data.user);
      this.notifyAuthorized();
      this.showPostAuthNotifications();
      this.brandingService.loadBranding();
      this.redirectUserAfterAuthentication();
      this.applicationEventService.next(new AuthEvent(data.user));
    }

    this._analyticsService.setUserRole(this.user.RoleId);
  }

  isPlatformAdmin(): boolean {
    return this.getUserRoleId() === UserRoles.ADMIN;
  }

  openTfaAuthStep(tfaInfo: TfaChallengeInfo): void {
    this.openTfaAuthStepSource$.next(tfaInfo);
  }

  openConfirmEmailStep(userInfo: { email: string }): void {
    this.openConfirmEmailStepSource$.next(userInfo);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private authorizeWithAlternativeProfile(res: any, remember?: boolean, notRedirect = false): void {
    this._sharedEventActionCode = this.localStorage.getItem(LocalStorageKeys.SHARED_EVENT_ACTION_CODE);

    if (notRedirect) {
      this.redirectUrl = window.location.href;
      this.authorize(res, remember);
      return;
    }

    this.reset();
    const data = res._body ? res.json() : res;
    if (data.user) {
      const { user } = data;
      this.storeUserIfRequired(user, remember);

      const lang = this.locale.language;
      let redirectUrl = lang === this._runtimeConfigService.get('defaultLanguage') ? '/' : `/${lang}/`;

      if (this._sharedEventActionCode) {
        redirectUrl =
          lang === this._runtimeConfigService.get('defaultLanguage')
            ? `/user/event-actions/${this._sharedEventActionCode}`
            : `/${lang}/user/event-actions/${this._sharedEventActionCode}`;
        this._sharedEventActionCode = null;
      }

      if (this.redirectUrl) {
        redirectUrl = this.redirectUrl;
      }

      this.windowService.redirect(redirectUrl);
    }
  }

  private buildGuideFormData(user: UserGuide, withAuthData = true): FormData {
    const formData: FormData = new FormData();

    if (withAuthData) {
      formData.append('email', user.email);
      formData.append('password', user.password);
    }

    formData.append('firstName', user.firstName);
    formData.append('lastName', user.lastName);
    // @ts-expect-error TS2532
    formData.append('subscribeNews', user.subscribeNews.toString());
    formData.append('timezone', DateTime.local().zoneName);

    return formData;
  }

  private buildFormDataHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return headers;
  }

  private notifyAuthorized(): void {
    this.logService.updateUserInfo(this.user);
    this.authSubject$.next(this.user);
    this.user$.next(this.user);
    this._userMembership$.next(this.user.membership);
  }

  // TODO: refactor redirection implementation
  private redirectUserAfterAuthentication(): void {
    if (this.user.RoleId === UserRoles.ADMIN) {
      this.router.navigate(['/admin/dashboard']).then();
      return;
    }

    if (this.user.RoleId === UserRoles.SUPER_ADMIN) {
      this.router.navigate(['/super-admin']).then();
      return;
    }

    const sharedEventActionUrl = this._sharedEventActionCode
      ? `/user/event-actions/${this._sharedEventActionCode}`
      : null;

    if (this.user.RoleId === UserRoles.CLIENT) {
      if (this._invitationCode) {
        this.router.navigate(['/invitations/activation/', this._invitationCode]).then();
        this.redirectUrl = sharedEventActionUrl || this.redirectUrl;
        return;
      }

      if (this._giftCouponActivationHash) {
        this.router.navigate(['/gift-coupons/activation/', this._giftCouponActivationHash]).then();
        return;
      }

      if (sharedEventActionUrl) {
        this.windowService.redirect(sharedEventActionUrl);
        this.localStorage.setItem(LocalStorageKeys.SHARED_EVENT_ACTION_CODE, null);
        return;
      }

      if (this.redirectUrl) {
        this.windowService.redirect(this.redirectUrl);
        // @ts-expect-error TS2322
        this.redirectUrl = null;
        return;
      }

      if (this.router.url.startsWith('/auth') || this.router.url.startsWith('/email-verification')) {
        this.router.navigate(['/client/dashboard']).then();
      }

      return;
    }

    if (this.user.RoleId === UserRoles.GUIDE) {
      this.redirectUrl = sharedEventActionUrl || this.redirectUrl;

      // TODO: temp solution until, should actually act for all roles
      if (this.redirectUrl) {
        this.windowService.redirect(this.redirectUrl);
        // @ts-expect-error TS2322
        this.redirectUrl = null;
        return;
      }

      if (this.router.url.startsWith('/auth') || this.router.url.startsWith('/email-verification')) {
        this.router.navigate([`/${environment.guideRoute}/dashboard`]);
      }
    }
  }

  private redirectUserAfterReAuthentication(): void {
    if (!this.redirectUrl) {
      let path = window.location.pathname;

      // remove redundant language path from url string
      if (this._runtimeConfigService.get('defaultLanguage') !== this.locale.language) {
        // @ts-expect-error TS2532
        const languagesToRemove = `/${this._runtimeConfigService.get('availableLanguages').join('|/')}`;
        const regExp = new RegExp(languagesToRemove);
        path = path.replace(regExp, '');
      }

      this.redirectUrl = path;
    }
  }

  reset(): void {
    this.isAuthorized = false;
    this.user = {};
    this.localStorage.setItem(LocalStorageKeys.USER, undefined);
    this.localStorage.clear();
    this._sessionStorage.clear();
    this.removeToken();
    this.cookieService.delete('hashedid', '/');
    this.removeRoleSwitching();
  }

  // @ts-expect-error TS7006
  setUser(userData): void {
    this.user = userData;
    this.isAuthorized = true;
    this._analyticsService.setUserProperties(userData);
  }

  private showPostAuthNotifications(): void {
    if (!this.user.emailVerified && this.user.RoleId !== UserRoles.ADMIN) {
      this.emailAlerts.showNotVerified();
    }

    if (
      this.user.RoleId === UserRoles.GUIDE &&
      this.user.profileType === GuideProfileTypes.PUBLIC &&
      this.user.incompleteSettings
    ) {
      this._incompleteProfileNotification$.next(this.user.incompleteSettings);
    }
  }

  // @ts-expect-error TS7006
  private storeUserIfRequired(user, remember?: boolean): void {
    this.remember(user, remember);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private sendSignUpEvents(user, method = 'email') {
    const analyticsData = {
      category: GoogleAnalyticsCategories.SIGNUP,
      method,
      // Todo: consider fromIso(user.createdAt).toFormat('yyyy/MM/dd') to correct Amplitude date displaying
      signUpDate: user.createdAt
    };

    this._analyticsService.event(InternalEvents.SIGN_UP, {
      method,
      id: user.id,
      email: user.email,
      role: user.RoleId === UserRoles.CLIENT ? 'Client' : 'Guide',
      'SignUp date': DateTime.fromISO(user.createdAt).toFormat('yyyy/MM/dd')
    });

    if (user.RoleId === UserRoles.GUIDE) {
      this._analyticsService.event(InternalEvents.SIGN_UP_PRACTITIONER, analyticsData);
      this._analyticsService.event(InternalEvents.SIGN_UP_CLIENT, analyticsData);
    }

    if (user.RoleId === UserRoles.CLIENT) {
      this._analyticsService.event(InternalEvents.SIGN_UP_CLIENT, analyticsData);
    }
  }

  /**
   * Mutates sign-up payload with user's {string} timeZone.
   * Additionally, if we navigated from specific url (as example 'createTeam') appends {boolean} createTeam
   * to request payload
   * @param {User} user - User Payload
   * @param {boolean} [rememberMe] - keeps session
   * @return {User & { timezone: string, createTeam: boolean }}
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private mutateSignUpPayload(user: User, rememberMe?: boolean): User & { timezone: string; createTeam: boolean } {
    const createTeam = !!localStorage.getItem(LocalStorageKeys.CREATE_TEAM);
    return { ...user, timezone: DateTime.local().zoneName, createTeam };
  }
}
