<div class="modal-header">
  <h4 class="modal-title" i18n="@@eventActionRangeCancelModalTitle" *ngIf="modalType === 'cancel'">
    Cancel recurring event
  </h4>

  <h4 class="modal-title" i18n="@@eventActionRangeRescheduleModalTitle" *ngIf="modalType === 'reschedule'">
    Edit recurring event
  </h4>

  <app-button class="close-btn" className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <div appRadioGroup [(ngModel)]="eventActionRangeType" class="event-action-range-type-selector">
    <div *ngIf="!hideCurrent">
      <input appRadioInput type="radio" [value]="'current'" id="event_action_range_type_current" />

      <label class="radio-label event-action-range-type-radio-label" for="event_action_range_type_current">
        <span class="event-action-range-type-name" i18n="@@eventActionRangeTypeCurrent">This event</span>
      </label>
    </div>

    <div *ngIf="!showCurrentOnly">
      <input appRadioInput type="radio" [value]="'following'" id="event_action_range_type_following" />

      <label class="radio-label event-action-range-type-radio-label" for="event_action_range_type_following">
        <span class="event-action-range-type-name" i18n="@@eventActionRangeTypeFollowing">
          This and following events
        </span>
      </label>
    </div>

    <div *ngIf="!showCurrentOnly">
      <input appRadioInput type="radio" [value]="'all'" id="event_action_range_type_all" />

      <label class="radio-label event-action-range-type-radio-label" for="event_action_range_type_all">
        <span class="event-action-range-type-name" i18n="@@eventActionRangeTypeAll">All events</span>
      </label>
    </div>
  </div>
</div>

<div class="modal-footer">
  <app-button className="btn-primary" (click)="modal.close(eventActionRangeType)" i18n="@@eventActionRangeModalBtn">
    Ok
  </app-button>
</div>
