import { Question, QuestionOption, Quiz } from '../quiz';
import { GroupSessionModule, IGroupSessionModuleDetails } from './group-session-module';
import { ContentModule, IContentModuleDetails } from './content-module';
import { IProgramModulePersistenceAttributes } from './module-persistence-attributes';
import {
  isContentModule,
  isGroupSessionModule,
  ProgramModule,
  isQuizModule,
  isRestrictedModule
} from './program-module';
import { IQuizModuleDetails, QuizModule } from './quiz-module';
import { ISessionModuleDetails, SessionModule } from './session-module';

export function applyModulePersistenceAttributes(
  module: ProgramModule,
  attributes: IProgramModulePersistenceAttributes
): ProgramModule {
  if (isRestrictedModule(module)) {
    return module;
  }

  if (isContentModule(module)) {
    return new ContentModule({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<IContentModuleDetails>module),
      id: attributes.id,
      localId: attributes.id,
      titleText: attributes.titleText
    });
  }

  if (isGroupSessionModule(module)) {
    return new GroupSessionModule({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<IGroupSessionModuleDetails>module),
      id: attributes.id,
      localId: attributes.id,
      serviceId: module.serviceId || attributes.serviceId
    });
  }

  if (isQuizModule(module)) {
    return new QuizModule({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<IQuizModuleDetails>module),
      id: attributes.id,
      localId: attributes.id,
      quiz: attributes.quiz
        ? new Quiz({
            ...module.quiz,
            id: attributes.quiz.id,
            localId: attributes.quiz.id,
            questions:
              attributes.quiz.questions && attributes.quiz.questions.length
                ? // @ts-expect-error TS2531
                  module.quiz.questions.map((question, index) =>
                    // @ts-expect-error TS2532
                    attributes.quiz.questions[index]
                      ? // @ts-expect-error TS2532
                        applyQuizQuestionPersistenceAttributes(question, attributes.quiz.questions[index])
                      : question
                  )
                : // @ts-expect-error TS2531
                  module.quiz.questions
          })
        : module.quiz
    });
  }

  return new SessionModule({
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ...(<ISessionModuleDetails>module),
    id: attributes.id,
    localId: attributes.id
  });
}

export function applyQuizQuestionPersistenceAttributes(
  quizQuestion: Readonly<Question>,
  attributes: { id: number; options?: { id: number }[] }
): Question {
  return new Question({
    ...quizQuestion,
    id: attributes.id,
    localId: attributes.id,
    options:
      attributes.options && attributes.options.length
        ? // @ts-expect-error TS2533
          quizQuestion.options.map((option, index) =>
            // @ts-expect-error TS2532
            attributes.options[index]
              ? new QuestionOption({
                  ...option,
                  // @ts-expect-error TS2532
                  id: attributes.options[index].id,
                  // @ts-expect-error TS2532
                  localId: attributes.options[index].id
                })
              : option
          )
        : quizQuestion.options
  });
}
