<ng-container *ngIf="guideAvailableSessions$ | async as sessions">
  <ng-container *ngIf="sessions?.length > 0">
    <h4 pui-typography="h2" i18n="@bookingModalScheduleYourSessions">Schedule your sessions</h4>

    <app-available-client-sessions
      class="d-flex flex-column"
      [sessions]="sessions"
      [appearance]="availableClientSessionsAppearance"></app-available-client-sessions>
  </ng-container>
</ng-container>
