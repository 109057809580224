import { ClipboardModule } from 'ngx-clipboard';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FloatingPopoverModule } from '@app/modules/floating-popover/floating-popover.module';
import { AbsComponentServiceItem } from '@app/modules/guide-service/components/abs/abs-component-service-item';
import { SessionPlatformModule } from '@app/modules/guide-service/pipes/session-platform.module';
import { AvatarGroupModule } from '@app/modules/ui-kit/_base/avatar-group/avatar-group.module';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import { WorkspacesModule } from '@app/modules/workspaces/workspaces.module';
import { SharedModule } from '@app/shared/shared.module';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiButtonModule,
  PuiIconModule,
  PuiMenuModule,
  PuiTooltipModule
} from '@awarenow/profi-ui-core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { GuideGroupSessionServiceComponent } from './components/guide-group-session-service/guide-group-session-service.component';
import { GuidePackageServiceGroupItemComponent } from './components/guide-package-service-group-item/guide-package-service-group-item.component';
import { GuidePackageServiceGroupComponent } from './components/guide-package-service-group/guide-package-service-group.component';
import { GuidePackageServiceComponent } from './components/guide-package-service/guide-package-service.component';
import { GuideProgramServiceGroupItemComponent } from './components/guide-program-service-group-item/guide-program-service-group-item.component';
import { GuideProgramServiceGroupComponent } from './components/guide-program-service-group/guide-program-service-group.component';
import { GuideProgramServiceComponent } from './components/guide-program-service/guide-program-service.component';
import { GuideServiceBadgeComponent } from './components/guide-service-badge/guide-service-badge.component';
import { GuideServiceTeamComponent } from './components/guide-service-team/guide-service-team.component';
import { GuideServiceComponent } from './components/guide-service/guide-service.component';
import { GuideSessionServiceComponent } from './components/guide-session-service/guide-session-service.component';
import { GuideWebinarServiceComponent } from './components/guide-webinar-service/guide-webinar-service.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PlatformStyleModule,
    PuiAvatarGroupModule,
    PuiAvatarModule,
    PuiMenuModule,
    ClipboardModule,
    NgbTooltipModule,
    RouterModule,
    UiIconModule,
    PuiIconModule,
    PuiTooltipModule,
    PuiButtonModule,
    FloatingPopoverModule,
    WorkspacesModule,
    AvatarGroupModule,
    SessionPlatformModule
  ],
  declarations: [
    GuideGroupSessionServiceComponent,
    GuidePackageServiceComponent,
    GuideProgramServiceComponent,
    GuideProgramServiceGroupComponent,
    GuidePackageServiceGroupComponent,
    GuideProgramServiceGroupItemComponent,
    GuidePackageServiceGroupItemComponent,
    GuideServiceBadgeComponent,
    GuideServiceComponent,
    GuideSessionServiceComponent,
    GuideWebinarServiceComponent,
    GuideServiceTeamComponent,
    AbsComponentServiceItem
  ],
  exports: [
    GuideGroupSessionServiceComponent,
    GuidePackageServiceComponent,
    GuideProgramServiceComponent,
    GuideProgramServiceGroupComponent,
    GuidePackageServiceGroupComponent,
    GuideServiceBadgeComponent,
    GuideServiceComponent,
    GuideSessionServiceComponent,
    GuideWebinarServiceComponent,
    SessionPlatformModule
  ]
})
export class GuideServiceModule {}
