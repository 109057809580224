import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISystemNotification } from '@app/modules/notifications/types';

@Component({
  selector: 'app-community-notification',
  templateUrl: './community-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './community-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunityNotificationComponent {
  get userName(): string {
    return `${this.notification?.details?.user.firstName} ${this.notification?.details?.user.lastName}`;
  }

  @Input()
  notification!: ISystemNotification;
}
