import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { IGuideService, IServiceBookingOptions } from '@app/modules/book-service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IUser } from '@app/shared/interfaces/user';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ServiceProvidersService } from '@appWidget/screens/book-session/services/service-providers';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { IServiceWorkspaceAssignee } from '@app/screens/guide/guide-sessions-templates/types';

@Component({
  selector: 'app-service-host-selection',
  templateUrl: './service-host-selection.component.html',
  styleUrls: ['./service-host-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UiDestroyService]
})
export class ServiceHostSelectionComponent implements OnChanges {
  @Input()
  // @ts-expect-error TS2564
  bookingDetails: IServiceBookingOptions<IGuideService>;

  @Output()
  updatedDetails$: EventEmitter<IServiceBookingOptions<IGuideService>> = new EventEmitter<
    IServiceBookingOptions<IGuideService>
  >();

  // @ts-expect-error TS2564
  serviceHosts: IServiceWorkspaceAssignee['guide'][];

  constructor(
    private readonly _providers: ServiceProvidersService,
    private readonly _destroy: UiDestroyService,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this._providers
        .getServiceProviders(
          // @ts-expect-error TS2532
          { id: this.bookingDetails.service.id, type: this.bookingDetails.service.type },
          {
            date: this.bookingDetails.date,
            timezone: this.bookingDetails.timezone,
            workspaceId: this.bookingDetails.guide?.workspaceId,
            duration: this.bookingDetails.service?.duration
          }
        )
        .pipe(takeUntil(this._destroy))
        .subscribe(response => {
          this.serviceHosts = response;
          this._cdr.detectChanges();
        });
    }
  }

  selectHost(host: IServiceWorkspaceAssignee['guide']): void {
    this.updatedDetails$.emit({
      ...this.bookingDetails,
      serviceHost: host.id
    });
  }

  trackByHost(index: number): number {
    return index;
  }
}
