import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedUsersCardComponent } from './selected-users-card.component';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiCardModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiList2Module,
  PuiPaperModule,
  PuiSharedModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [SelectedUsersCardComponent],
  imports: [
    CommonModule,
    PuiAvatarModule,
    PuiAvatarGroupModule,
    PuiCardModule,
    PuiDropdownModule,
    ScrollingModule,
    PuiTypographyModule,
    PuiList2Module,
    PuiPaperModule,
    PuiLinkModule,
    PuiSharedModule,
    PuiInputModule,
    FormsModule,
    PuiIconModule,
    PuiLetModule,
    SharedModule
  ],
  exports: [SelectedUsersCardComponent]
})
export class SelectedUsersCardModule {}
