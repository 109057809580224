import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INote } from '@app/shared/interfaces/notes';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';

@Component({
  selector: 'app-note-history-meta',
  templateUrl: './note-history-meta.component.html',
  styleUrls: ['./note-history-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteHistoryMetaComponent {
  @Input()
  // @ts-expect-error TS2564
  note: INote;

  @Input()
  showCreatedAt = true;

  @Input()
  showUpdatedAt = true;

  timezoneOffset$ = this.userTimezone.timezone$;

  datePipeFormat = "EEEE, MMM d, y, h:mmaaaaa'm' (O)";

  constructor(private userTimezone: UserTimezoneStore) {}
}
