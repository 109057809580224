import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-gift-coupons-header',
  templateUrl: './gift-coupons-header.component.html',
  styleUrls: ['./gift-coupons-header.component.scss']
})
export class GiftCouponsHeaderComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  mainLogo = '';

  constructor(public authService: AuthService, private _brandingService: BrandingService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => {
      this.mainLogo = logo;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
