import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { map } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';

export interface BlockedInterval {
  start: string;
  end: string;
  bookingId?: string;
  userId: number;
}

@Injectable({ providedIn: 'root' })
export class GuideScheduleBlocksService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleBlocks$ = new BehaviorSubject<BlockedInterval[]>([]);

  scheduleBlocks$ = this._scheduleBlocks$.asObservable();

  constructor(private _http: HttpClient) {}

  getOverlappedIntervals(rangeStart: Date, rangeEnd: Date): BlockedInterval[] {
    const rangeStartDateTime = DateTime.fromJSDate(rangeStart);
    const rangeEndDateTime = DateTime.fromJSDate(rangeEnd);

    return this._scheduleBlocks$.getValue().filter(interval => {
      const intervalStartDateTime = DateTime.fromISO(interval.start);
      const intervalEndDateTime = DateTime.fromISO(interval.end);

      return (
        (rangeStartDateTime > intervalStartDateTime && rangeStartDateTime < intervalEndDateTime) ||
        (rangeEndDateTime > intervalStartDateTime && rangeEndDateTime < intervalEndDateTime) ||
        (rangeStartDateTime <= intervalStartDateTime && rangeEndDateTime >= intervalEndDateTime)
      );
    });
  }

  refresh(): void {
    this.loadScheduleBlocks();
  }

  private loadScheduleBlocks(): void {
    const localDateTime = DateTime.local().startOf('day');
    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: {
        timezone: localDateTime.zoneName,
        start: localDateTime.toISO(),
        end: localDateTime.plus({ months: 3 }).toISO()
      }
    });

    this._http
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .get<any>(`${config.apiPath}/user/guide/unavailability`, { params })
      .pipe(map(({ intervals }) => intervals))
      .subscribe(blockedIntervals => {
        this._scheduleBlocks$.next(blockedIntervals);
      });
  }
}
