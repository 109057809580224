export enum LogType {
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error'
}

export interface ErrorEvent {
  message: string;
  filename: string;
  lineno: string;
  colno: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

export interface LogUserInfo {
  userId: string;
  userNm: string;
  userEm: string;
  userRl: string;
}
