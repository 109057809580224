export enum ExternalCalendarSources {
  GOOGLE = 'google',
  OUTLOOK = 'outlook',
  OTHER_WORKSPACE = 'otherworkspace'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IExternalCalendars {
  readonly [source: string]: readonly IExternalAccount[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IExternalAccount {
  readonly id: number;
  readonly email: string;
  readonly calendars: readonly IExternalCalendar[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IExternalCalendar {
  readonly id: number;
  readonly name: string;
  readonly description?: string | null;
  readonly isDefault: boolean;
  readonly isSynced: boolean;
  isSyncInProcess?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ICalendarsRequestOptions {
  readonly source?: ExternalCalendarSources;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ICalendarConnectionOptions {
  readonly source: ExternalCalendarSources;
}
