import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { extractUrlFromRouterNavigation } from '@app/core/auth/utils';
import { UserRoles } from '@app/shared/enums/user-roles';
import { NotificationsService } from 'angular2-notifications';
import { noop } from 'rxjs';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// [TODO] Rewrite. This guard implements to much logic.
//  Consider to refactor all guards after migrating on Angular 11, in order to use UrlTree
@Injectable()
export class PublicProgramsGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _notifications: NotificationsService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // For additional information please refer to the following link:
    // https://www.notion.so/profiio/Programs-3310dfe94a3e43ddb2c09cfd9743061c
    return this.checkLoginAndRole(state.url);
  }

  canLoad(): boolean {
    // @ts-expect-error TS2345
    const url: string = extractUrlFromRouterNavigation(this._router.getCurrentNavigation());
    return this.checkLoginAndRole(url);
  }

  private checkLoginAndRole(url: string): boolean {
    const { isAuthorized, user } = this._auth;
    const { RoleId } = user;

    const isClient: boolean = RoleId === UserRoles.CLIENT;
    const isGuide: boolean = RoleId === UserRoles.GUIDE;

    if (isAuthorized) {
      if (isGuide) {
        this.wrongRoleFlow();
      }
    } else {
      this.unauthorizedFlow(url);
    }

    return isClient;
  }

  private wrongRoleFlow(): void {
    const title = `Only for Clients`;
    const message = `Sign In as a Client, please`;
    this._notifications.error(title, message);
    this._router.navigate(['/']).then(() => noop());
  }

  private unauthorizedFlow(url: string): void {
    if (this._runtimeConfigService.get('doesAllPublicProgramAccessibleForClients')) {
      this.navigateToMarketplace();
    } else {
      this._auth.redirectUrl = url;
      this._router.navigate(['/auth']).then(() => noop());
    }
  }

  private navigateToMarketplace(): void {
    this._router.navigate(['/marketplace/programs']).then(() => noop());
  }
}
