import { environment } from '@env/environment';
import { Routes } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';
import { HideAuthBodyComponent } from './hide-auth-body/hide-auth-body.component';

import { AllowedAuthQueryParamsGuard } from './guards/allowed-auth-query-params.guard';
import { AuthModalGuard } from './guards/auth-modal.guard';

export const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AllowedAuthQueryParamsGuard, AuthModalGuard],
    component: HideAuthBodyComponent
  },
  {
    path: 'sign-in',
    runGuardsAndResolvers: 'always',
    redirectTo: '/auth?signIn=true'
  },
  {
    path: 'sign-in-team',
    runGuardsAndResolvers: 'always',
    redirectTo: '/auth?signInTeam=true'
  },
  {
    path: 'sign-up',
    runGuardsAndResolvers: 'always',
    redirectTo: '/auth?signUp=true'
  },
  {
    path: `become-a-${environment.guideRoute}`,
    runGuardsAndResolvers: 'always',
    redirectTo: '/auth?signUp=true'
  },
  {
    path: 'forgot-password',
    children: [
      {
        path: '',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset',
        component: ForgotPasswordResetComponent
      },
      {
        path: 'sent',
        component: ForgotPasswordSentComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sign-in'
  }
];
