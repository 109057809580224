import { NgModule } from '@angular/core';
import { CommonHeaderComponent } from '@app/modules/common-header/common-header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { UserMenuModule } from '@app/modules/user-menu/user-menu.module';
import { ParseMarkdownLinkPipe } from '@app/modules/common-header/parse-markdown-link';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WidgetModalComponent } from '@app/shared/components/widget-modal/widget-modal.component';

@NgModule({
  declarations: [CommonHeaderComponent, ParseMarkdownLinkPipe],
  imports: [CommonModule, RouterModule, SharedModule, NotificationsModule, UserMenuModule],
  exports: [CommonHeaderComponent, ParseMarkdownLinkPipe]
})
export class CommonHeaderModule {}
