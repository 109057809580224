<app-editor-section-wrapper
  [title]="'Testimonials'"
  note="Share your reputation with potential clients."
  i18nTitle="@@testimonials"
  i18nNote="@@shareYourReputation">
  <div configuration-content [formGroup]="formTestimonials">
    <div
      class="testimonial form-group"
      *ngFor="let testimonial of formTestimonials.controls; let testimonialIndex = index"
      [formGroupName]="testimonialIndex">
      <div class="form-group">
        <div class="composite-label">
          <label class="label top-label" i18n="@@testimonialTitleLabel">
            Client {{ testimonialIndex + 1 }} testimonial
          </label>
          <a
            *ngIf="!disabled"
            (click)="removeTestimonial(testimonialIndex)"
            class="btn btn-link remove-btn"
            [attr.data-qa-id]="'guide-service-testimonials-editor-remove-button-' + testimonialIndex"
            i18n="@@remove">
            Remove
          </a>
        </div>
        <textarea
          [attr.data-qa-id]="'guide-service-testimonials-editor-testimonial-textarea-' + testimonialIndex"
          formControlName="text"
          type="text"
          class="form-control"></textarea>
      </div>

      <div class="form-group">
        <label class="label top-label" i18n="@@testimonialClientInfoLabel">
          Client {{ testimonialIndex + 1 }} name, profession
        </label>
        <input
          [attr.data-qa-id]="'guide-service-testimonials-editor-client-name-input-' + testimonialIndex"
          formControlName="clientInfo"
          type="text"
          class="form-control" />
      </div>
    </div>

    <a
      data-qa-id="guide-service-testimonials-editor-add-testimonial-button"
      *ngIf="!disabled"
      (click)="addTestimonial()"
      class="btn btn-link btn-add-simple"
      i18n="@@addTestimonial">
      Add testimonial
    </a>
  </div>
</app-editor-section-wrapper>
