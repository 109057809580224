<div class="header">
  <div class="close" routerLink="/coach/clients">&times;</div>
</div>

<div class="questions" *ngIf="clientQuiz">
  <div class="block">
    <app-quiz-info [clientQuiz]="quizTemplate"></app-quiz-info>
    <app-quiz-session-info *ngIf="clientQuiz?.session" [session]="clientQuiz.session"></app-quiz-session-info>
  </div>
  <ng-container *ngIf="clientQuiz">
    <div class="questions-form" [formGroup]="form">
      <app-module-quiz
        formControlName="answers"
        [editableAnswers]="editMode"
        [isCorrectDisplay]="false"
        [displayKey]="false"></app-module-quiz>
      <div class="controls">
        <app-button
          *ngIf="canSubmit && canEdit && editMode"
          [disabled]="answerIsSaving || submitDisabled || (submitAvailable$ | async) === false"
          (click)="submit()"
          className="btn-primary btn-lg">
          <ng-container i18n="@@submit">Submit</ng-container>
        </app-button>
        <app-button
          *ngIf="!editMode && canEdit && canSubmit"
          [disabled]="submitDisabled"
          (click)="editMode = true"
          className="btn-outline-primary btn-lg">
          <ng-container i18n="@@edit">Edit</ng-container>
        </app-button>
      </div>
    </div>
  </ng-container>
</div>

<div class="loading" *ngIf="!ready"></div>
