import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { IMultiDateScheduleGroup } from '@app/modules/ui-kit/schedule';
import { ISchedule, ITimeSlot } from '../../types';
import {
  SCHEDULE_DATE_FORMAT_STRATEGY,
  SCHEDULE_TIME_FORMAT_STRATEGY,
  ScheduleDateTimeFormatStrategy
} from '../schedule-date-time-format-strategies';
import { ScheduleTimeSlotsBuildStrategy } from './schedule-time-slots-build-strategy';
import { IScheduleItem } from '@app/shared/interfaces/schedule';

@Injectable()
export class DefaultMultiDateScheduleTimeSlotsBuildStrategy extends ScheduleTimeSlotsBuildStrategy<
  ISchedule,
  IMultiDateScheduleGroup<ITimeSlot>
> {
  constructor(
    @Inject(SCHEDULE_DATE_FORMAT_STRATEGY)
    protected readonly _dateLabelFormatter: ScheduleDateTimeFormatStrategy<string | number | Date>,
    @Inject(SCHEDULE_TIME_FORMAT_STRATEGY)
    protected readonly _timeLabelFormatter: ScheduleDateTimeFormatStrategy<string | number | Date>
  ) {
    super();
  }

  generate(
    schedule: ISchedule,
    slotDuration: number,
    timezone: string,
    timeSlotFromat: string
  ): IMultiDateScheduleGroup<ITimeSlot>[] {
    const days = new Map<number, ITimeSlot[]>();

    schedule.ranges.forEach((scheduleItem: IScheduleItem) => {
      const startDateTime = DateTime.fromISO(scheduleItem.dateStart).setZone(timezone);
      const endDateTime = DateTime.fromISO(scheduleItem.dateEnd).setZone(timezone);

      const startOfDateTime = startDateTime.startOf('day').toMillis();
      const timeSlot: ITimeSlot = {
        // @ts-expect-error TS2322
        label: this._timeLabelFormatter.format(startDateTime.toISO(), {
          timezone,
          format: timeSlotFromat
        }),
        guide: scheduleItem.guide,
        value: startDateTime.toISO(),
        timeFormat: timeSlotFromat,
        dateEnd: endDateTime.toISO(),
        timezone
      };

      if (days.has(startOfDateTime)) {
        // @ts-expect-error TS2532
        days.get(startOfDateTime).push(timeSlot);
      } else {
        days.set(startOfDateTime, [timeSlot]);
      }
    });

    // @ts-expect-error TS7034
    const scheduleSlots = [];
    days.forEach((items: ITimeSlot[], date: number) =>
      scheduleSlots.push({
        label: this._dateLabelFormatter.format(date, { timezone }),
        items
      })
    );

    // @ts-expect-error TS7005
    return scheduleSlots;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  canGenerateTimeSlots(schedule: ISchedule, slotDuration: number, timezone: string) {
    return true;
  }
}
