<div class="session-range">{{ clientSession | clientGuideSessionRangeFormatter }}</div>

<div
  class="session-status"
  [ngClass]="!clientSession.session.isMissedSession ? sessionClasses[clientSession.session.status] : 'missed-session'"
  [class.has-comment]="shouldShowDeclineSection"
  [ngbTooltip]="shouldShowDeclineSection ? declineReasonTooltip : null"
  tooltipClass="session-tooltip"
  placement="bottom-left"
  triggers="click:blur">
  <span class="session-status-text">
    <app-event-status
      [attr.data-qa-id]="'session-details-status-label-' + clientSession?.session?.name"
      [status]="clientSession.session?.status"
      [isSessionPaid]="clientSession.session?.payRate > 0"
      [isGuide]="true"
      [onlyIcon]="false"></app-event-status>
  </span>

  <ng-template #declineReasonTooltip>
    <div class="tooltip-title" i18n="@@hereIsWhy">Here's why:</div>
    {{ clientSession?.session?.declineReason?.reason }}
  </ng-template>
</div>

<span *ngIf="showConnectionType" class="session-details">
  <pui-icon
    [svgIcon]="session?.connectionType | sessionConnectionTypeIcon"
    size="xs"
    aria-hidden="true"
    class="start-icon default-icon"></pui-icon>

  <ng-container *ngIf="session?.connectionType === SessionConnectionTypes.IN_PERSON">
    {{ session?.connectionType | sessionPlatform: session?.address }}
  </ng-container>

  <ng-container *ngIf="session?.connectionType === SessionConnectionTypes.PHONE">
    <div *ngIf="groupSessionOrInstance && session?.guide">
      <ng-container *ngIf="session?.guide.phoneForSessions">
        <ng-container *ngTemplateOutlet="phoneForSessions"></ng-container>
      </ng-container>

      <ng-container *ngIf="!session?.guide.phoneForSessions">
        <ng-container *ngTemplateOutlet="askHostToProvidePhoneForSessions"></ng-container>
      </ng-container>
    </div>

    <div *ngIf="session?.serviceType === SessionTypes.SESSION && session?.guide">
      <ng-container *ngIf="session.guide.phoneForSessions">
        <ng-container *ngTemplateOutlet="phoneForSessions"></ng-container>

        <ng-container *ngIf="session.callInitiator === CallInitiators.GUIDE">
          <ng-container *ngTemplateOutlet="guideWillCall"></ng-container>
        </ng-container>
        <ng-container *ngIf="session.callInitiator === CallInitiators.CLIENT">
          <ng-container *ngTemplateOutlet="willBeWaitingForYouCall"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!session.guide.phoneForSessions && session.callInitiator === CallInitiators.GUIDE">
        <ng-container *ngTemplateOutlet="guideWillCall"></ng-container>
      </ng-container>

      <ng-container *ngIf="!session.guide.phoneForSessions && session.callInitiator === CallInitiators.CLIENT">
        <ng-container *ngTemplateOutlet="askHostToProvidePhoneForSessions"></ng-container>
        <ng-container *ngTemplateOutlet="willBeWaitingForYouCall"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</span>

<ng-template #guideWillCall>
  <div i18n="@@guideWillCallYou">{{ host | userName }} will call you.</div>
</ng-template>

<ng-template #willBeWaitingForYouCall>
  <div i18n="@@willBeWaitingForYou">{{ host | userName }} will be waiting for your call.</div>
</ng-template>

<ng-template #phoneForSessions>
  <div>
    {{ session.guide.phoneForSessions }}
  </div>
</ng-template>

<ng-template #askHostToProvidePhoneForSessions>
  <div i18n="@@askHostToProvidePhoneForSessionsNumber">Please ask {{ host | userName }} to provide their number.</div>
</ng-template>
