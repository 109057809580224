import { IUser } from '@app/shared/interfaces/user';
import { GuideClient } from '@app/core/users/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUserWithDeletionStatus extends IUser {
  deleted?: boolean;
}

export class GuideClientProgram extends GuideClient {
  status: 'active' | 'inactive' | 'invited' | 'restrict_future_modules' | 'expelled' | 'subscription_canceled';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events?: any;

  enrolledAt?: string;

  isInstallments: boolean;

  // @ts-expect-error TS7006
  constructor(guideClient) {
    super(guideClient);

    this.status = guideClient.status;
    this.events = guideClient.events;
    this.enrolledAt = guideClient.enrolledAt;
    this.isInstallments = guideClient.isInstallments;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clone() {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const user = <GuideClientProgram>super.clone();
    user.status = this.status;

    return user;
  }
}
