<div class="container pui-container h-100">
  <div class="title" data-qa-id="decline-offer-drawer-title">
    <h3 pui-typography="h2">
      <span>{{ getTitle() }}</span>
    </h3>
  </div>

  <div class="body">
    <label pui-input-label i18n="@@GuideSessionCancellationLabel" data-qa-id="decline-offer-drawer-reason-title"
      >Reason for declining the request (optional)</label
    >
    <pui-input-field>
      <textarea
        [style.resize]="'none'"
        data-qa-id="decline-offer-drawer-textarea-reason"
        [formControl]="textArea"
        i18n-placeholder="@@GuideSessionCancellationReasonPlaceholderMessage"
        placeholder="Let your client know why you can't make it"
        pui-input-text></textarea>
    </pui-input-field>
  </div>

  <div class="buttons">
    <button (click)="decline()" pui-button i18n="@@DeclineSession" data-qa-id="decline-offer-drawer-decline-button">
      Decline session
    </button>
  </div>
</div>
