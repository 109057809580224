import { Directive, Input, TemplateRef } from '@angular/core';
import { StepState } from '@angular/cdk/stepper';

/** Template context available to an attached `matStepperIcon`. */
export interface MatStepperIconContext {
  /** Index of the step. */
  index: number;
  /** Whether the step is currently active. */
  active: boolean;
  /** Whether the step is optional. */
  optional: boolean;
}

/**
 * Template to be used to override the icons inside the step header.
 */
@Directive({
  selector: 'ng-template[matStepperIcon]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MatStepperIcon {
  /** Name of the icon to be overridden. */
  // @ts-expect-error TS2564
  @Input('matStepperIcon') name: StepState;

  constructor(public templateRef: TemplateRef<MatStepperIconContext>) {}
}
