<footer class="p-footer">
  <div class="p-footer__top">
    <div class="inner">
      <div class="p-footer-i">
        <a
          *ngIf="logoUrl?.type === URL_TYPE.RELATIVE; else absolute"
          routerLink="{{ '/' + logoUrl?.value }}"
          class="logo">
          <img [src]="mainLogo" alt="logo" />
        </a>

        <div class="f-menu">
          <nav *ngFor="let link of menuConfig.footerLinks" class="menu">
            <div class="wrap-item">
              <h4 class="title">{{ link.label }}</h4>
            </div>
            <div *ngFor="let subLink of link.children" class="wrap-item">
              <a
                *ngIf="subLink.urlType === URL_TYPE.RELATIVE"
                class="item"
                [routerLink]="[subLink.url]"
                target="{{ subLink.type === LINK_TYPE.EXTERNAL ? '_blank' : '_self' }}"
                [queryParams]="subLink.queryParams">
                {{ subLink.label }}
              </a>

              <ng-container *ngIf="subLink.urlType === URL_TYPE.ABSOLUTE">
                <ng-container [ngSwitch]="subLink.type">
                  <a *ngSwitchCase="LINK_TYPE.INTERNAL" class="item" [href]="[subLink.url]">
                    {{ subLink.label }}
                  </a>
                  <a
                    *ngSwitchCase="LINK_TYPE.EXTERNAL"
                    class="item"
                    [href]="[subLink.url]"
                    target="_blank"
                    rel="noopener">
                    {{ subLink.label }}
                  </a>
                  <a
                    *ngSwitchCase="LINK_TYPE.WIDGET"
                    class="item"
                    href="#"
                    (click)="openWidgetModal($event, subLink.url)">
                    {{ subLink.label }}
                  </a>
                </ng-container>
              </ng-container>
            </div>
          </nav>

          <nav class="menu">
            <div class="wrap-item">
              <div class="soc-links">
                <a
                  *ngIf="menuConfig.facebook"
                  appStyle
                  [textColor]="'#7c8f9e'"
                  [hoverColor]="menuConfig.socialLinksColor"
                  class="soc-btn icon-fb"
                  [href]="menuConfig.facebook"
                  target="_blank"></a>

                <a
                  *ngIf="menuConfig.twitter"
                  appStyle
                  [textColor]="'#7c8f9e'"
                  [hoverColor]="menuConfig.socialLinksColor"
                  class="soc-btn icon-tw"
                  [href]="menuConfig.twitter"
                  target="_blank"></a>

                <a
                  *ngIf="menuConfig.instagram"
                  appStyle
                  [textColor]="'#7c8f9e'"
                  [hoverColor]="menuConfig.socialLinksColor"
                  class="soc-btn icon-insta"
                  [href]="menuConfig.instagram"
                  target="_blank"></a>

                <a
                  *ngIf="menuConfig.linkedIn"
                  appStyle
                  [textColor]="'#7c8f9e'"
                  [hoverColor]="menuConfig.socialLinksColor"
                  class="soc-btn icon-linkedin2"
                  [href]="menuConfig.linkedIn"
                  target="_blank"></a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="p-footer__btm">
    <div class="inner">
      <div class="p-footer__btm-left">
        <div class="lang">
          <app-language-selector *ngIf="canShowLanguageSelector"></app-language-selector>
        </div>
        <div *ngIf="menuConfig.copyRights" class="copyright">
          <span>{{ menuConfig.copyRights }}</span>
        </div>
        <div class="email">
          <span>{{ emailHelp }}</span>
        </div>
      </div>
      <div class="p-footer__btm-right">
        <p *ngIf="menuConfig.enableMadeWithLove" class="address" i18n="@@madeWithLoveLabel">
          Made with
          <span class="icon-heart2"></span>
          love and care for you in Los&nbsp;Angeles and Europe
        </p>
      </div>
    </div>
  </div>

  <app-powered-by-link *ngIf="displayPoweredBy || showPoweredBy" utmCampaign="footer"></app-powered-by-link>
</footer>

<ng-template #absolute>
  <a target="_blank" href="{{ logoUrl?.value }}" class="logo" rel="noopener">
    <img [src]="mainLogo" alt="logo" />
  </a>
</ng-template>
