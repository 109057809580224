<app-guide-notes-board
  #notesBoard
  [notes]="notes"
  [reset]="reset"
  [isAssigned]="isAssigned"
  [clientId]="guideRelation.id"
  [hideSharing]="isContactRelation()"
  [isLoading]="isLoading$ | async"
  (noteCreate)="onNoteCreate($event)"
  (noteDelete)="onNoteDelete($event)"
  (noteEndEditing)="onNoteEndEditing($event)"
  (noteUpdate)="onNoteUpdate($event)"
  (loadMoreNotes)="onLoadMoreNotes()"
  (grantViewerAccess)="onGrantViewerAccess($event)"
  (revokeViewerAccess)="onRevokeViewerAccess($event)"
  (manageAccess)="onManageAccess($event)"
  (pinNote)="onNotePin($event)"
  (unpinNote)="onNoteUnpin($event)"></app-guide-notes-board>
