import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatStepLabel } from './step-label';
import { MatStepperIntl } from './stepper-intl';
import { MatStepperIconContext } from './stepper-icon';
import { CdkStepHeader, StepState } from '@angular/cdk/stepper';
import { CanColor, mixinColor } from '@angular/material/core';

// Boilerplate for applying mixins to MatStepHeader.
const _MatStepHeaderBase = mixinColor(
  class MatStepHeaderBase extends CdkStepHeader {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(elementRef: ElementRef) {
      super(elementRef);
    }
  },
  'primary'
);

@Component({
  selector: 'mat-step-header',
  templateUrl: 'step-header.html',
  styleUrls: ['step-header.scss'],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['color'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mat-step-header',
    role: 'tab'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MatStepHeader extends _MatStepHeaderBase implements AfterViewInit, OnDestroy, CanColor {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _intlSubscription: Subscription;

  /** State of the given step. */
  // @ts-expect-error TS2564
  @Input() state: StepState;

  /** Label of the given step. */
  // @ts-expect-error TS2564
  @Input() label: MatStepLabel | string;

  /** Error message to display when there's an error. */
  // @ts-expect-error TS2564
  @Input() errorMessage: string;

  /** Overrides for the header icons, passed in via the stepper. */
  // @ts-expect-error TS2564
  @Input() iconOverrides: { [key: string]: TemplateRef<MatStepperIconContext> };

  /** Index of the given step. */
  // @ts-expect-error TS2564
  @Input() index: number;

  /** Whether the given step is selected. */
  // @ts-expect-error TS2564
  @Input() selected: boolean;

  /** Whether the given step label is active. */
  // @ts-expect-error TS2564
  @Input() active: boolean;

  /** Whether the given step is optional. */
  // @ts-expect-error TS2564
  @Input() optional: boolean;

  /** Whether the ripple should be disabled. */
  // @ts-expect-error TS2564
  @Input() disableRipple: boolean;

  constructor(
    public _intl: MatStepperIntl,
    private _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(_elementRef);
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._intlSubscription = _intl.changes.subscribe(() => changeDetectorRef.markForCheck());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngAfterViewInit() {
    this._focusMonitor.monitor(this._elementRef, true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this._intlSubscription.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  /** Focuses the step header. */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  focus(origin?: FocusOrigin, options?: FocusOptions) {
    if (origin) {
      this._focusMonitor.focusVia(this._elementRef, origin, options);
    } else {
      this._elementRef.nativeElement.focus(options);
    }
  }

  /** Returns string label of given step if it is a text label. */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _stringLabel(): string | null {
    return this.label instanceof MatStepLabel ? null : this.label;
  }

  /** Returns MatStepLabel if the label of given step is a template label. */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _templateLabel(): MatStepLabel | null {
    return this.label instanceof MatStepLabel ? this.label : null;
  }

  /** Returns the host HTML element. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  _getHostElement() {
    return this._elementRef.nativeElement;
  }

  /** Template context variables that are exposed to the `matStepperIcon` instances. */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _getIconContext(): MatStepperIconContext {
    return {
      index: this.index,
      active: this.active,
      optional: this.optional
    };
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _getDefaultTextForState(state: StepState): string {
    // eslint-disable-next-line eqeqeq
    if (state == 'number') {
      return `${this.index + 1}`;
    }
    // eslint-disable-next-line eqeqeq
    if (state == 'edit') {
      return 'create';
    }
    // eslint-disable-next-line eqeqeq
    if (state == 'error') {
      return 'warning';
    }
    return state;
  }
}
