import { Pipe, PipeTransform } from '@angular/core';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';

@Pipe({
  name: 'sortOnlySessionDate'
})
export class SortSessionByPipe implements PipeTransform {
  transform(array: IClientGuideSession[], field: string, direction: 'asc' | 'desc' = 'asc'): IClientGuideSession[] {
    return array.sort((x, y) => {
      // @ts-expect-error TS7053
      const xObjKey = x.session ? x.session[field] : x[field];
      // @ts-expect-error TS7053
      const yObjKey = y.session ? y.session[field] : y[field];
      if (direction === 'asc') {
        return +new Date(xObjKey) - +new Date(yObjKey);
      }
      return +new Date(yObjKey) - +new Date(xObjKey);
    });
  }
}
