import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingLoaderComponent } from './booking-loader.component';
import { PuiIconModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [BookingLoaderComponent],
  imports: [CommonModule, PuiIconModule],
  exports: [BookingLoaderComponent]
})
export class BookingLoaderModule {}
