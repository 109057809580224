import { IScheduleItem } from '../../../schedule';
import { GuideServiceTypes } from '../../../services';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IModuleServiceSchedule {
  dates: IScheduleItem[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IModuleServiceDetails {
  readonly id: number;
  readonly type: GuideServiceTypes;
  readonly connectionType: SessionConnectionTypes;
  readonly description?: string | null;
  readonly duration: number;
  readonly guideId: number;
  readonly name: string;
  readonly schedule?: IModuleServiceSchedule;
}

export class ModuleService implements IModuleServiceDetails {
  // @ts-expect-error TS2564
  readonly id: number;
  // @ts-expect-error TS2564
  readonly type: GuideServiceTypes;
  // @ts-expect-error TS2564
  readonly connectionType: SessionConnectionTypes;
  readonly description?: string | null;
  // @ts-expect-error TS2564
  readonly duration: number;
  // @ts-expect-error TS2564
  readonly guideId: number;
  // @ts-expect-error TS2564
  readonly name: string;
  readonly schedule?: IModuleServiceSchedule;
  readonly restrictClientBooking?: boolean;

  constructor(moduleServiceDetails: Readonly<IModuleServiceDetails>) {
    if (!moduleServiceDetails) {
      throw new Error('Invalid argument');
    }

    Object.entries(moduleServiceDetails).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
  }
}
