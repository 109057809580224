import { environment as env } from '@env/environment.local';

export const DAY = env.platformStyle.DAY;
export const WEEK_DAY = env.platformStyle.WEEK_DAY;
export const MONTH = env.platformStyle.MONTH;
export const YEAR = env.platformStyle.YEAR;
export const TIME = env.platformStyle.TIME;
export const HUMANIZE_MILLISECONDS = env.platformStyle.HUMANIZE_MILLISECONDS;
export const DEFAULT = env.platformStyle.DEFAULT;

// custom for 'en-US'
export const TIME_WITH_MINUTES = env.platformStyle.TIME_WITH_MINUTES;
export const TIME_WITHOUT_MINUTES = env.platformStyle.TIME_WITHOUT_MINUTES;
