import { switchMap, take, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { SessionTemplateServerStoreService } from '@app/screens/guide/guide-sessions-templates/services/session-template-server-store.service';
import { SwitchToAlternativeAccountConfirmModalComponent } from '@app/shared/components/switch-to-alternative-account-confirm-modal/switch-to-alternative-account-confirm-modal.component';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideServiceTypes, ServiceScheduleItem } from '@app/shared/interfaces/services';
import {
  IBookSessionServiceEvent,
  IPublicSessionTemplate
} from '@app/shared/interfaces/services/public-session-template';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ServiceProvidersService } from '@appWidget/screens/book-session/services/service-providers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { PublicAssignee } from '@app/modules/services/interfaces';
import { Router } from '@angular/router';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { getPathWithBaseUrl, soloSessionPath, teamSessionPath } from '@app/modules/services/utils/get-booking-path';
import { SessionType } from '@app/shared/enums/session-type';
import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';

@Component({
  selector: 'app-public-session-template',
  templateUrl: './public-session-template.component.html',
  styleUrls: ['./public-session-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SessionTemplateServerStoreService, PuiDestroyService]
})
export class PublicSessionTemplateComponent implements OnChanges {
  @Input() readonly = false;

  // @ts-expect-error TS2564
  @Input() template: IPublicSessionTemplate;

  @Output() book: EventEmitter<IBookSessionServiceEvent> = new EventEmitter<IBookSessionServiceEvent>();

  readonly GuideServiceTypes = GuideServiceTypes;

  readonly dateTimeLocale: string;

  readonly minVisibleScheduleItemsCount = 3;

  currentVisibleScheduleItemsCount = this.minVisibleScheduleItemsCount;

  assignees: PublicAssignee[] = [];

  SessionConnectionTypes = SessionConnectionTypes;

  canBeBooked = false;

  frequency = {
    0: `year`,
    1: `month`,
    2: `week`
  };

  constructor(
    private readonly _localeService: LocaleService,
    private readonly _auth: AuthService,
    private readonly _modal: NgbModal,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _providers: ServiceProvidersService,
    private readonly router: Router,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    @Inject(GLOBAL_WINDOW) private browserWindow: Window
  ) {
    this.dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template?.currentValue?.id) {
      this._providers
        .getServiceProviders({ id: changes.template.currentValue.id, type: GuideServiceTypes.SESSION })
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(assignees => {
          this.assignees = assignees as unknown as PublicAssignee[];
          this.canBeBooked =
            !this.template.restrictClientBooking &&
            (this.assignees.length > 0 || WorkspaceUtility.isSolo(this.template.workspace));
          this._cdr.detectChanges();
        });
    }
  }

  canShowMore(scheduleSlot: ServiceScheduleItem): boolean {
    const { address, connectionType } = scheduleSlot;
    if (!address || connectionType !== SessionConnectionTypes.IN_PERSON) {
      return false;
    }

    return address.length > 35;
  }

  toggleShowMore(scheduleItem: ServiceScheduleItem): void {
    scheduleItem.showMore = !scheduleItem.showMore;
  }

  // @ts-expect-error TS7006
  trackByEventId(_, scheduleSlot: ServiceScheduleItem): number {
    return scheduleSlot.eventId;
  }

  hideScheduleDates(): void {
    this.currentVisibleScheduleItemsCount = this.minVisibleScheduleItemsCount;
  }

  showMoreScheduleDates(): void {
    this.currentVisibleScheduleItemsCount += this.minVisibleScheduleItemsCount;
  }

  emitBook(): void {
    if (this._auth.user.RoleId === UserRoles.GUIDE) {
      const { componentInstance, result } = this._modal.open(SwitchToAlternativeAccountConfirmModalComponent, {
        windowClass: 'switch-modal',
        centered: true
      });
      componentInstance.role = UserRoles.CLIENT;

      modalResultToObservable$(result)
        .pipe(
          switchMap(() => this._auth.signinAlternativeAccount(true)),
          takeUntil(this.destroy$)
        )
        .subscribe();
      return;
    }

    if (this.template.workspace.type === WorkspacesTypes.SOLO) {
      const path = `${soloSessionPath(this.template.guide.id, this.template.id)}?type=${
        BookingTypes.SESSION_WITH_AUTHORIZATION
      }`;
      this.browserWindow.location.href = getPathWithBaseUrl(path);
      return;
    }

    if (this.template.sessionType === SessionType.PERSONAL || this.template.sessionType === SessionType.ROUND_ROBIN) {
      const path = `${teamSessionPath(this.template.workspace.id, this.template.id)}?type=${
        BookingTypes.CHOOSE_TEAM_HOST_WITH_AUTHORIZATION
      }`;

      this.browserWindow.location.href = getPathWithBaseUrl(path);
      return;
    }

    if (this.template.sessionType === SessionType.COLLECTIVE) {
      // TODO 5909 add collective path
    }
  }
}
