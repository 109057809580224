import { DateTime } from 'luxon';

import { formatDate } from '@angular/common';
import { getDefaultLocale } from '@platformStyle/utils/get-defaults';

import { DateTimeOptions } from '../model/interfaces';

// @ts-expect-error TS7006
export default function datetime(date, format, options: DateTimeOptions): string {
  const locale =
    (options && options.localeParams && options.localeParams.dateTimeLocale) || getDefaultLocale().dateTimeLocale;
  const timezone = options && options.timezone;
  let offset: string | undefined;

  if (timezone) {
    offset = DateTime.fromMillis(date).setZone(timezone).offsetNameShort;
  }

  return formatDate(date, format, locale, offset);
}
