import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingPipe } from './pricing.pipe';

@NgModule({
  declarations: [PricingPipe],
  imports: [CommonModule],
  exports: [PricingPipe]
})
export class PricingModule {}
