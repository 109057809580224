<header class="container">
  <div class="row flex-nowrap no-gutters">
    <div class="col col-logo">
      <a class="awarenow-logo" routerLink="/">
        <img [src]="mainLogo" />
      </a>
    </div>

    <div class="col flex-grow-0 col-auth" *ngIf="!isAuthorised">
      <a [routerLink]="['/auth/sign-in']" i18n="@@login">Login</a>
    </div>
  </div>
</header>
