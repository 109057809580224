import { ProgramModule } from '../program-module';
import { IBaseProgram, IProgramDetails, ProgramSubscription } from './base-program';
import { EditableProgramSettings, IEditableProgram } from './editable-program';
import { ProgramAccessRoles } from './program-access-roles';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IInstructorProgramDetails extends IProgramDetails {
  readonly settings: EditableProgramSettings;
  readonly subscriptions: ProgramSubscription[];
}

export class InstructorProgram implements IBaseProgram {
  readonly id: number;

  readonly modules: ProgramModule[];

  readonly settings: EditableProgramSettings;

  readonly subscriptions: ProgramSubscription[];

  get programAccessRole(): ProgramAccessRoles {
    return ProgramAccessRoles.INSTRUCTOR;
  }

  constructor(programDetails: Readonly<IInstructorProgramDetails>) {
    if (!programDetails) {
      throw new Error('Invalid argument');
    }

    this.id = programDetails.id;
    this.modules = programDetails.modules;
    this.settings = programDetails.settings;
    this.subscriptions = programDetails.subscriptions;
  }

  toEditable(): IEditableProgram {
    return {
      id: this.id,
      accessRole: ProgramAccessRoles.INSTRUCTOR,
      modules: this.modules,
      settings: this.settings,
      subscriptions: this.subscriptions
    };
  }
}
