import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-list-type',
  template: `
    <div class="flex-column">
      <div class="list-container">
        <div class="list-title">{{ title }}</div>
        <div class="list-wrapper d-flex flex-nowrap" [style.flex-direction]="flexDirection">
          <ng-container *ngFor="let field of field.fieldGroup; let i = index">
            <div class="list-item-wrapper flex-column">
              <div class="d-flex">
                <div class="col">
                  <formly-field class="list-item" [field]="field"></formly-field>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .list-container {
        padding: 15px 10px;
        border: 1px solid #f5f2f2;
      }

      .list-wrapper {
        max-height: 300px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FormlyListType extends FieldArrayType implements OnInit {
  // @ts-expect-error TS2564
  title: string;
  flexDirection: 'column' | 'row' = 'row';

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.title = this.to.label ?? 'List';
    this.flexDirection = this.to.flexDirection;
  }
}
