import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionDropdownComponent } from './components/action-dropdown/action-dropdown.component';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [ActionDropdownComponent],
  exports: [ActionDropdownComponent]
})
export class ActionDropdownModule {}
