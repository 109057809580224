import { Injectable } from '@angular/core';
import { PuiDialog, PuiDialogService } from '@awarenow/profi-ui-core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const enum ConfirmSessionCancelResult {
  CANCEL = 'CANCEL',
  CLOSE = 'CLOSE'
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmSessionCancelService {
  constructor(private readonly dialogService: PuiDialogService) {}

  open(): Observable<ConfirmSessionCancelResult> {
    const dialog = new PuiDialog();

    dialog.title = 'Cancel session without refund?';
    dialog.description = `We get it, plans change. This session you’re willing to cancel is less than in 12 hours, which is due to cancellation fee.\nIf you feel that this session should be refunded, please contact your coach to cancel this session on their behalf.`;

    dialog.buttons = [
      {
        label: 'Cancel anyway',
        appearance: 'error',
        click: ref => {
          ref.close(ConfirmSessionCancelResult.CANCEL);
        }
      },
      {
        label: 'Close',
        appearance: 'secondary',
        click: ref => {
          ref.close(ConfirmSessionCancelResult.CLOSE);
        }
      }
    ];

    const ref = this.dialogService.open(dialog, {
      size: 's',
      hasCloseButton: true
    });

    return ref.afterClosed$.pipe(take(1));
  }
}
