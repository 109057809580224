import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RECORDINGS_ARCHIVE_ENDPOINT } from '@app/shared/constants/endpoints';

@Injectable()
export class RecordingsService {
  constructor(private _http: HttpClient) {}

  getRecording$(archiveId: string): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(`${RECORDINGS_ARCHIVE_ENDPOINT}/${archiveId}?t=${this.noCacheCode()}`);
  }

  private noCacheCode(): string {
    return DateTime.local().toMillis().toString(36);
  }
}
