/**
 * @internal only form GuideClintHistory
 */
import { ClientTimelineEventUtils } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/modules/guide-client-history/client-timeline-event.utils';
import { ClientTimelineEvent } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/modules/guide-client-history/types';

export class ClientHistoryViewModel {
  readonly description = ClientTimelineEventUtils.getDescriptionWithGuide(
    this._event,
    this.config.hasHostedByDisplayed
  );
  readonly author = ClientTimelineEventUtils.getAuthor(this._event);
  readonly comment = this._event.contentText;
  readonly timestamp = this._event.timestamp;

  constructor(
    readonly _event: ClientTimelineEvent,
    private readonly config: {
      // Should show hosted by
      hasHostedByDisplayed: boolean;
    }
  ) {}
}
