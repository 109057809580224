<div (click)="navigate()" class="service-option">
  <div class="service-option__image pui-space_right-3">
    <img [src]="imageSrc" />
  </div>

  <div>
    <h4 pui-typography="body-s-bold" class="service-option__title">{{ name }}</h4>

    <span pui-typography="body-s" class="service-option__description">
      {{ description }}
    </span>
  </div>
</div>
