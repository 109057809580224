import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiCardModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiSharedModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { UserSelectComponent } from './user-select.component';

@NgModule({
  declarations: [UserSelectComponent],
  imports: [
    CommonModule,
    PuiAvatarModule,
    PuiLetModule,
    PuiCardModule,
    PuiInputModule,
    PuiLinkModule,
    PuiTypographyModule,
    PuiDropdownModule,
    PuiIconModule,
    ScrollingModule,
    PuiAvatarGroupModule,
    PuiInputCheckboxModule,
    PuiSharedModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [UserSelectComponent]
})
export class UserSelectModule {}
