import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-widget-modal',
  templateUrl: './widget-modal.component.html',
  styleUrls: ['./widget-modal.component.scss']
})
export class WidgetModalComponent {
  // @ts-expect-error TS2564
  @Input() link: string;

  constructor(private _modal: NgbActiveModal) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  close() {
    this._modal.dismiss();
  }
}
