import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsSelectorModalComponent } from '@app/shared/components/tags-selector/components/tags-selector-modal/tags-selector-modal.component';

@Component({
  selector: 'app-tags-selector',
  templateUrl: './tags-selector.component.html',
  styleUrls: ['./tags-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsSelectorComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsSelectorComponent implements ControlValueAccessor {
  @Input()
  title = 'Add tags';

  @Input()
  // @ts-expect-error TS2564
  disabled: boolean;

  @Input()
  tagsLimit = 10;

  @Input()
  set allTags(value) {
    if (value.length) {
      this._allTags = value;

      for (let i = 0; i < value.length; i += 1) {
        this.namedTags[value[i].id] = {
          name: value[i].name,
          description: value[i].description
        };
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get allTags() {
    return this._allTags;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._value;
  }

  // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  namedTags: any = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  _allTags: any;
  tags = [];

  constructor(private _modalService: NgbModal, private _cdr: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  writeValue(value: any) {
    if (typeof value === 'object' && value.length) {
      this.tags = value;
    } else {
      this.tags = [];
    }
    this.onChange(value);

    this._cdr.detectChanges();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  onChange = value => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  openTagsModal(): void {
    const { result, componentInstance } = this._modalService.open(TagsSelectorModalComponent, {
      size: 'lg',
      centered: true
    });

    componentInstance.allTags = this.allTags;
    componentInstance.selectedTags = this.tags;
    componentInstance.tagsLimit = this.tagsLimit;

    result.then(({ selected }) => this.writeValue(selected)).catch(() => {});
  }

  // @ts-expect-error TS7006
  deleteTag(index): void {
    // @ts-expect-error TS7034
    const tags = [];
    for (let i = 0; i < this.tags.length; i += 1) {
      if (i !== index) {
        tags.push(this.tags[i]);
      }
    }
    // @ts-expect-error TS7005
    this.writeValue(tags);
  }
}
