<div class="service-group-details-container program-service-group-details-container">
  <div class="service-group-details program-service-group-details">
    <!-- <div
      class="program-image-container"
      *ngIf="imageUrl">
      <div
        [style.backgroundImage]="imageUrl"
        class="program-image">
      </div>
    </div> -->
    <app-service-label [type]="serviceGroup.type"></app-service-label>
    <div class="service-group-name program-name">{{ serviceGroup.name }}</div>
  </div>
</div>

<div class="service-group-items-container">
  <app-guide-program-service-group-item
    *ngFor="let service of services"
    [service]="service"
    (click)="serviceSelect.emit(service)"></app-guide-program-service-group-item>
</div>
