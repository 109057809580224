import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';

import { ScheduleOptions } from '../../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IScheduleOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly template: any;
  readonly bufferTime: {
    readonly before: number;
    readonly after: number;
  };
}

@Injectable()
export class GuideScheduleOptionsApiService {
  readonly PATH = `${config.apiPath}/user/guide/availability`;
  readonly ENDPOINT = `${this.PATH}/schedule-options`;

  constructor(private readonly _http: HttpClient) {}

  /**
   * @deprecated
   */
  getScheduleOptions$(): Observable<ScheduleOptions> {
    return this._http
      .get<{ scheduleOptions: IScheduleOptions }>(this.ENDPOINT)
      .pipe(map(response => new ScheduleOptions(response.scheduleOptions)));
  }

  getScheduleOptionsForGuide$(guideId: number, workspaceId: number): Observable<ScheduleOptions> {
    return this._http
      .get<{ scheduleOptions: IScheduleOptions }>(`${this.PATH}/workspace/${workspaceId}/guide/${guideId}`)
      .pipe(map(response => new ScheduleOptions(response.scheduleOptions)));
  }
}
