import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { ISidenavViewHost } from '../../interfaces';
import { SidenavHostEnum, SidenavSlotEnum } from '../../enums';
import { SidenavSlotType } from '../../types';
import { SidenavModeService, ViewWidgetService } from '../../services';
import { WINDOW } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UiDestroyService]
})
export class SidenavComponent implements ISidenavViewHost, AfterViewInit {
  readonly name = SidenavHostEnum.Sidenav;

  readonly slots: SidenavSlotType[] = [SidenavSlotEnum.Header, SidenavSlotEnum.Body, SidenavSlotEnum.Footer];

  @ViewChild('header')
  // @ts-expect-error TS2564
  header: CdkPortalOutlet;

  @ViewChild('body')
  // @ts-expect-error TS2564
  body: CdkPortalOutlet;

  @ViewChild('footer')
  // @ts-expect-error TS2564
  footer: CdkPortalOutlet;

  @HostBinding('style.--puivh')
  vh = `${this.window?.innerHeight / 100}px`;

  constructor(
    readonly service: ViewWidgetService<SidenavSlotType>,
    readonly mode$: SidenavModeService,
    @Inject(UiDestroyService) private readonly _destroy$: UiDestroyService,
    @Inject(WINDOW) private window: Window
  ) {}

  ngAfterViewInit(): void {
    this.service.attachHost(this);
    this.service.observeWidgets$(this).pipe(takeUntil(this._destroy$)).subscribe();
  }
}
