<div class="row-m0 chat-message-container" [ngClass]="message?.status">
  <dl>
    <!--    <app-user-photo class="user-photo" [photoUrl]="sender?.photo"></app-user-photo>-->
    <pui-avatar class="user-photo" [avatarUrl]="sender?.photo" [text]="sender?.name"></pui-avatar>
    <dt>
      <span>{{ sender?.name }}</span>
      <span class="date-time">{{ message.date | platformTime }}</span>
    </dt>
    <dd *ngIf="message.text" [innerHTML]="message.text | safeHtml" class="text"></dd>
    <dd>
      <div class="container-fluid fs-exclude">
        <ng-template [ngIf]="isVideo && message.file?.url">
          <div class="row">
            <div class="col-auto show-download">
              <iframe
                [src]="sanitize(message.file?.url)"
                class="embed-video"
                allowfullscreen="true"
                width="302px"
                height="154px"></iframe>
              <ng-template *ngTemplateOutlet="download"></ng-template>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="isImage">
          <div class="row">
            <div class="col-auto show-download">
              <img [src]="message.file.url" [alt]="message.file.name" class="img-preview" height="320px" />
              <ng-template *ngTemplateOutlet="download"></ng-template>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="!isImage && !isVideo">
          <div class="file-container d-flex mt-2 p-2 show-download">
            <div class="row no-gutters w-100">
              <div class="col">
                <div class="file-icon icon"></div>
              </div>
              <div class="col-9 pr-1 pl-1 flex-column mb-sm-2 mb-md-0">
                <div class="row no-gutters pl-1 pr-1">
                  <div class="file-name">{{ message.file.name }}</div>
                </div>
                <div class="row no-gutters pl-1 pr-1">
                  <div class="file-size" *ngIf="message.file.size">
                    {{ message.file.size | fileSize }}
                  </div>
                </div>
              </div>
            </div>
            <ng-template *ngTemplateOutlet="download"></ng-template>
          </div>
        </ng-template>
      </div>
    </dd>
  </dl>
</div>

<ng-template #download>
  <a
    [attr.href]="message.file.url"
    [download]="message.file.name"
    class="download-icon"
    target="_blank"
    ngbTooltip="Download"
    i18n-ngbTooltip="@@chatFileDownload"
    placement="top">
    <ui-icon svgIcon="download"></ui-icon>
  </a>
</ng-template>
