<div appRadioGroup [(ngModel)]="paymentOption" class="app-radio-group">
  <div class="option flexed">
    <div>
      <input appRadioInput type="radio" [value]="PaymentOptions.FULL_PRICE" id="pay_full" />
      <label class="radio-label" for="pay_full">
        <p data-qa-id="payment-options-selector-pay-in-full-title" i18n="@@payInFull">Pay in full</p>
        <p data-qa-id="payment-options-selector-pay-in-full-description-text" i18n="@@payInFullText" class="text">
          Make a one-time payment
        </p>
      </label>
    </div>
    <div data-qa-id="payment-options-selector-pay-in-full-price-title" class="price">
      {{ fullPrice | platformCurrency }}
    </div>
  </div>
  <div class="option">
    <div>
      <input appRadioInput type="radio" [value]="PaymentOptions.INSTALLMENTS" id="pay_installments" />
      <label class="radio-label" for="pay_installments">
        <p data-qa-id="payment-options-selector-pay-with-payment-plan-title" i18n="@@payInstallments">
          Pay with payment plan
        </p>
        <p
          data-qa-id="payment-options-selector-pay-with-payment-plan-description-text"
          i18n="@@payInstallmentsText"
          class="text">
          Buy now, pay later. By using a payment plan, you can pay for your order across multiple payments. We will
          automatically charge your payment method based on the following schedule.
        </p>
      </label>
    </div>
    <div *ngFor="let installmentsPrice of installmentsPrices; let i = index">
      <div class="flexed payment-item">
        <div>
          <div class="price" [attr.data-qa-id]="'payment-options-selector-pay-with-payment-plan-number-title-' + i">
            {{ i + 1 }}{{ getDayPostfix(i + 1) }}
            <ng-container i18n="@@paymentLabel">payment</ng-container>
          </div>
          <div [attr.data-qa-id]="'payment-options-selector-pay-with-payment-plan-payment-date-title-' + i">
            <ng-template [ngIf]="isTodayDay(installmentsPrice.paymentDate)" [ngIfElse]="payOnDate">
              <ng-container i18n="@@payToday">Pay today</ng-container>
            </ng-template>
            <ng-template #payOnDate>
              <ng-container i18n="@@payOn">Pay on</ng-container>
              {{ installmentsPrice.paymentDate | date: 'MMMM dd, yyyy' }}
            </ng-template>
          </div>
        </div>
        <div class="price" [attr.data-qa-id]="'payment-options-selector-pay-with-payment-plan-price-title-' + i">
          {{ installmentsPrice.amount | platformCurrency }}
        </div>
      </div>
    </div>
  </div>
</div>
