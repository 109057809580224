import { Pipe, PipeTransform } from '@angular/core';
import { IWorkspace } from '@app/modules/workspaces/types';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';

@Pipe({
  name: 'workspaceIsSolo'
})
export class WorkspaceIsSoloPipe implements PipeTransform {
  transform(workspace: Pick<IWorkspace, 'type'>): boolean {
    return WorkspaceUtility.isSolo(workspace);
  }
}
