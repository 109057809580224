import { NgModule } from '@angular/core';
import { TfaAppAuthComponent } from '@app/modules/auth/components/tfa-app-auth/tfa-app-auth.component';
import { CommonModule } from '@angular/common';
import { PuiFieldsCoreModule, PuiLinkModule, PuiOtpInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { ReactiveFormsModule } from '@angular/forms';
import { SmsOtpInputModule } from '@app/modules/sms-otp-input/sms-otp-input.module';

@NgModule({
  declarations: [TfaAppAuthComponent],
  imports: [
    CommonModule,
    PuiOtpInputModule,
    SmsOtpInputModule,
    PuiTypographyModule,
    ReactiveFormsModule,
    PuiFieldsCoreModule,
    PuiLinkModule
  ],
  exports: [TfaAppAuthComponent]
})
export class TfaAppAuthModule {}
