import { ChangeDetectionStrategy, Component, ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PuiDialogService, PuiDialog, PuiDialogRef, PUI_DIALOG_DATA, PuiDestroyService } from '@awarenow/profi-ui-core';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ChatUser } from '@app/core/chat/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { GuideRelationTypes } from '@app/core/users/types';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { ChatsService } from '@app/core/chat/chats.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ActiveChat, IChatBoardMessage } from '../../types';
import { ActiveChatService } from '@app/core/chat/active-chat.service';
import { Observable } from 'rxjs';

enum MyDialogResult {
  CLOSE,
  REMOVE
}

@Component({
  selector: 'app-chat-participants-modal',
  templateUrl: './chat-participants-modal.component.html',
  styleUrls: ['./chat-participants-modal.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-chat-participants-modal' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatParticipantsModalComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chat!: ActiveChat<IChatBoardMessage, ChatUser>;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get chat() {
    return this._chat;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get participants() {
    if (!this.chat) {
      return [];
    }

    return Object.values(this.chat.users).filter(
      user => user.id !== this._auth.user.id && !user.isBlocked && user.role !== UserRoles.GUIDE
    );
  }

  constructor(
    public modal: NgbActiveModal,
    private _router: Router,
    private _dialog: PuiDialogService,
    private _auth: AuthService,
    private _guideClientsService: GuideClientsService,
    private _chatService: ChatsService,
    private _changeDetector: ChangeDetectorRef,
    readonly dialogRef: PuiDialogRef,
    private readonly activeChatService: ActiveChatService,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    this.activeChatService.activeChat$.pipe(takeUntil(this.destroy$)).subscribe(chat => {
      this._chat = chat;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  goToUser(id: number) {
    this.dialogRef.close();
    this._guideClientsService.setSelectedRelationId(id, GuideRelationTypes.GUIDE_CLIENT);
    this._router.navigate([`${environment.guideRoute}/clients`]);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openRemoveUserModal(user) {
    const dialog = new PuiDialog();

    dialog.title = `Remove ${user.name} from the chat?`;
    dialog.description = `This cannot be undone.`;

    dialog.buttons = [
      {
        label: 'Cancel',
        appearance: 'secondary',
        click: ref => {
          ref.close(MyDialogResult.CLOSE);
        }
      },
      {
        label: 'Remove',
        appearance: 'error',
        click: ref => {
          ref.close(MyDialogResult.REMOVE);
        }
      }
    ];

    const ref = this._dialog.open(dialog, {
      hasCloseButton: false,
      size: 's'
    });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    ref.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result === MyDialogResult.REMOVE) {
        return this.removeUser(user.id);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  removeUser(id: number) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._chatService.deleteParticipantFromChat(this._chat.id, id).subscribe(() => {
      this._chat.users[id].isBlocked = true;
      this._changeDetector.detectChanges();
    });
  }
}
