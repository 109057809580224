<div class="main-info-block">
  <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
    <div class="row-m0 title-row">
      <div class="col-md col-left">
        <a class="platform-logo" routerLink="/">
          <img [src]="platformLogo" alt="logo" />
        </a>
      </div>
      <div class="col-md col-main">
        <h2 i18n="@@signUpForInterview">Sign up for an interview</h2>
        <em i18n="@@signUpForInterviewNote">
          We have internal standards and guidelines to make sure our users get quality care. To ensure the baseline, we
          interview our service providers. Please apply to be a coach on
          {{ platformName }}, and one of our admins will get in touch with you about the next steps. Thank you!
        </em>
      </div>
    </div>
    <ng-container *ngIf="!createAlternativeAccount">
      <div class="row-m0">
        <div class="col-md col-left">
          <label i18n="@@registration">Registration</label>
          <em i18n="@@registrationNote">Please, fill in your email and password</em>
        </div>
        <div class="col-md col-main">
          <div class="form-row">
            <div class="col col-12 col-sm">
              <label class="required" i18n="@@email">Email</label>
              <input
                (change)="onEmailChanged()"
                type="text"
                formControlName="email"
                class="form-control"
                placeholder="Your email"
                i18n-placeholder="@@yourEmail" />
              <app-form-control-error [control]="signUpForm.controls['email']" [show]="emailAlreadyExists">
                <ng-template [ngIf]="emailAlreadyExists" [ngIfElse]="invalidEmail" i18n="@@emailAlreadyExist">
                  This email is already registered.
                </ng-template>
                <ng-template #invalidEmail>
                  <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
                </ng-template>
              </app-form-control-error>
            </div>
            <div class="col col-12 col-sm">
              <label class="required" i18n="@@password">Password</label>
              <app-input-password formControlName="password"></app-input-password>
              <app-form-control-error [control]="signUpForm.controls['password']" i18n="@@passwordTooShort">
                Password must be at least 6 chars long.
              </app-form-control-error>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row-m0">
      <div class="col-md col-left">
        <label i18n="@@personalInfo">Personal info</label>
        <em i18n="@@fillPersonalInfo">Please fill your personal information</em>
      </div>
      <div class="col-md col-main">
        <div class="form-row">
          <div class="col col-12 col-sm">
            <label class="required" i18n="@@firstName">First name</label>
            <input type="text" formControlName="firstName" class="form-control" />
          </div>
          <div class="col col-12 col-sm">
            <label class="required" i18n="@@signUpGuideComponentLastName">Last name</label>
            <input type="text" formControlName="lastName" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div class="row-m0 contacts-row">
      <div class="col-md col-left">
        <label i18n="@@yourContacts">You contacts</label>
        <em i18n="@@enterSkypeIdOrPhone">Enter your Skype ID or phone number. Only admins will see this info.</em>
      </div>
      <div class="col-md col-main">
        <div class="form-row phoneNumberOrSkypeId" formGroupName="phoneNumberOrSkypeId">
          <div class="col phone-col col-12 col-sm">
            <label class="required" i18n="@@phoneNumber">Phone number</label>
            <pui-phone-number [required]="true" formControlName="phoneNumber"></pui-phone-number>
            <app-form-control-error
              [control]="signUpForm.controls.phoneNumberOrSkypeId.get('phoneNumber')"
              i18n="@@phoneNumberError">
              Did you choose your country? Check the flags on the left.
            </app-form-control-error>
            <label class="or" i18n="@@or">or</label>
          </div>
          <div class="col col-12 col-sm">
            <label i18n="@@skype">Skype</label>
            <input type="text" formControlName="skypeId" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div class="row-m0">
      <div class="col-md col-left"></div>
      <div class="col-md col-main">
        <div class="form-row">
          <div class="col">
            <label i18n="@@yourLinkedinProfile">What's your LinkedIn profile?</label>
            <input type="text" formControlName="linkedIn" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div class="row-m0">
      <div class="col-md col-left"></div>
      <div class="col-md col-main">
        <div class="form-row">
          <div class="col">
            <label i18n="@@writeAboutYourself">Write briefly about yourself</label>
            <textarea
              formControlName="about"
              class="form-control"
              rows="4"
              placeholder="Give us more information about yourself before the interview"
              i18n-placeholder="@@writeAboutYourselfPlaceholder"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row-m0">
      <div class="col-md col-left"></div>
      <div class="col-md col-main">
        <app-checkbox id="agree" formControlName="agree" class="auth-checkbox" i18n="@@agreeToCompanyTermsAndPP">
          I agree to the {{ platformName | titlecase }}
          <a href="{{ config.urlTerms }}" target="_blank" rel="noopener">Terms & Conditions</a>
          and
          <a href="{{ config.urlPrivacy }}" target="_blank" rel="noopener">Privacy Policy</a>
          .
        </app-checkbox>

        <app-checkbox
          *ngIf="config.showNewsletter"
          id="agreeEmail"
          formControlName="subscribeNews"
          class="auth-checkbox"
          i18n="@@agreeToReceiveEmails">
          I agree to receive {{ platformName }} email newsletter
        </app-checkbox>

        <div class="form-buttons">
          <app-button className="btn-primary btn-md" buttonType="submit" i18n="@@submit">Submit</app-button>
          <label class="required" i18n="@@requiredField">required field</label>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #notification let-close="close">
  <div class="mdl-header">
    <app-button className="close" (click)="close()"></app-button>
  </div>
  <div class="mdl-body">
    <div class="confirmation-icon"></div>
    <h6 transloco="thankYouForRequestText">
      Thank you, we've got your request. Our admin will get in touch with you at the specified time.
    </h6>
    <p transloco="thankYouForRequestTextPart2">
      After the interview, you'll be able to create your coaching profile and start accepting customers.
    </p>
  </div>
</ng-template>
