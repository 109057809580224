import { ScheduleTypeDetails } from '@app/modules/rescheduling-service/services';
import {
  ClientReschedulingSessionOptions,
  GuideReschedulingSessionOptions,
  AuthorisedReschedulingApplicant,
  ReschedulingSessionOptions,
  isUnAuthorisedReschedulingApplicant
} from '@app/modules/rescheduling-service/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { DateTime } from 'luxon';

export function prepareScheduleOptions(options: ReschedulingSessionOptions): ScheduleTypeDetails {
  if ([GuideServiceTypes.GROUP_SESSION].includes(options.serviceType)) {
    return {
      type: 'guideServiceSchedule',
      options: {
        // @ts-expect-error TS2322
        serviceId: options.serviceId,
        guideId:
          options.applicant.role === UserRoles.GUIDE
            ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (<AuthorisedReschedulingApplicant>options.applicant).id
            : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (<ClientReschedulingSessionOptions>options).guideId
      }
    };
  }

  if (options.applicant.role === UserRoles.GUIDE) {
    return {
      type: 'guidePrivateSchedule',
      options: {
        date: DateTime.local().toISO(),
        duration: options.duration,
        timezone: DateTime.local().zoneName,
        guideId: options.guideId,
        // @ts-expect-error TS2532
        userId: options.user.id,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        clientId: (<GuideReschedulingSessionOptions>options).clientId,
        exEvent: options.exEvent,
        // @ts-expect-error TS2322
        token: isUnAuthorisedReschedulingApplicant(options.applicant) ? options.applicant.token : null,
        currentDate: options.currentDate
      }
    };
  }

  return {
    type: 'guidePublicSchedule',
    options: {
      // @ts-expect-error TS2532
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      guideId: (<ClientReschedulingSessionOptions>options).guideId || options.user.id,
      duration: options.duration,
      exEvent: options.exEvent,
      currentDate: options.currentDate,
      workspaceId: options.user?.workspaceId
    }
  };
}
