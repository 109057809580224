// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';

import { IBase, WithID } from '@app/base';
import { Testimonial } from '@app/modules/guide-service-editor/types/testimonial';
import { IGuideSessionService } from '@app/modules/guide-service/types';
import { BookingLimitsType, DayType, PeriodType } from '@app/modules/session-forms/forms/restrictions-form/types';
import { getConstantOrderArraysPatch } from '@app/screens/guide/guide-programs/types/helpers';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionType } from '@app/shared/enums/session-type';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IUser } from '@app/shared/interfaces/user';

export enum CallInitiator {
  CLIENT = 'client',
  GUIDE = 'guide'
}

export interface SessionTemplateLocation {
  connectionType: SessionConnectionTypes;
  address?: string;
  hideAddress?: boolean;
  callInitiator?: CallInitiator;
}

export enum ServiceTeamAssigneeRole {
  OWNER = 'Owner',
  HOST = 'Host',
  INSTRUCTOR = 'Instructor'
}

export enum ServiceAssigneeStatus {
  ACTIVE = 'active',
  PENDING_REMOVAL = 'pending_removal'
}

export enum ServiceAssigneePermission {
  OWNER = 'owner',
  PROVIDER = 'provider',
  OWNER_X_PROVIDER = 'owner_x_provider'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceWorkspaceAssignee extends IBase {
  id: number;
  guideId: number;
  guide: IUser & {
    email: string;
    salesText?: string;
    phoneForSessions?: string | null;
  } & Partial<{
      configuration: Pick<IGuideSessionService, 'connectionType'>;
    }>;
  role: ServiceTeamAssigneeRole;
  status: ServiceAssigneeStatus;
  permission: ServiceAssigneePermission;
  hostConnectionType: {
    connectionType: SessionConnectionTypes;
    phone?: string;
    address?: string;
  };
}

export enum Frequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  HOURLY = 4,
  MINUTELY = 5,
  SECONDLY = 6,
  WEEKDAY = 7
}

export interface RecurringEvent {
  dtstart?: Date;
  interval: number;
  count: number;
  freq: Frequency;
  until?: Date;
  tzid?: string;
}

export interface Schedule {
  id: number;
  name: string;
  template: AvailabilityTemplates;
  overrides: SessionTemplateAvailabilityOverrides;
  timeZone: string;
  defaultSchedule: boolean;
}

export type SessionTemplateCustomAvailability = Partial<Pick<Schedule, 'template' | 'overrides' | 'timeZone'>>;

export interface SessionTemplateSchedule extends Schedule {
  firstName: string;
  lastName: string;
}

export interface SessionTemplateAvailabilityOverride {
  date: string;
  startTime: string;
  endTime: string;
}

export type SessionTemplateAvailabilityOverrides = SessionTemplateAvailabilityOverride[];

export interface AvailabilityTemplate {
  start: string;
  end: string;
  timezone: string;
}

export type AvailabilityTemplates = Record<AvailabilityDateNumbers, AvailabilityTemplate[]>;
export type AvailabilityDateNumbers = '1' | '2' | '3' | '4' | '5' | '6' | '7';

export type LimitBookingFrequency = {
  [BookingLimitsType.PER_YEAR]?: number;
  [BookingLimitsType.PER_MONTH]?: number;
  [BookingLimitsType.PER_WEEK]?: number;
  [BookingLimitsType.PER_DAY]?: number;
};

export type LimitFutureBookings = {
  [PeriodType.ROLLING]?: {
    count: number;
    type: DayType;
  };
  [PeriodType.RANGE]?: [string, string];
};

export interface SessionTemplateInterface extends Partial<WithID> {
  afterEventBuffer: number;
  availabilities?: SessionTemplateSchedule[][];
  beforeEventBuffer: number;
  bookingLimitsFrequency: LimitBookingFrequency | null;
  coverImage: string | null;
  coverImageThumb: string | null;
  customAvailability: SessionTemplateCustomAvailability | null;
  descriptionMarkup: string;
  descriptionRepresentation: string;
  descriptionText: string;
  disableGuests: boolean;
  duration: number;
  hasAutoConfirmation: boolean;
  hasChat: boolean;
  hidePrice?: boolean | null;
  hosts: SessionTemplateHost[];
  limitFutureBookings: LimitFutureBookings | null;
  location: SessionTemplateLocation;
  minimumBookingNotice: number;
  name: string;
  permission: ServiceAssigneePermission;
  price?: number | null;
  recurrence: Pick<RecurringEvent, 'count'> | null;
  removeFromChatOnSessionEnd: boolean;
  scheduleId: number | null;
  seatsPerTimeSlot: number | null;
  seatsShowAttendees: boolean;
  serviceType: GuideServiceTypes;
  sessionType: SessionType;
  slotInterval: number;
  status: GuideProfileTypes;
  testimonials: Testimonial[] | null;
  viewChatHistory: boolean;
  workspaceId: number;
  // enabled and communityName both related to peerboards
  enabled?: boolean | undefined;
  communityName?: string | null | undefined;
  restrictClientBooking: boolean;
  serviceParent?: {
    id?: number;
    type?: GuideServiceTypes.PACKAGE | GuideServiceTypes.PROGRAM;
    name?: string;
  };
}

export interface SessionTemplatePersistenceAttributes {
  id: number;
  testimonials?: { [localId: number]: number };
}

export type SessionTemplatePatchInterface = Partial<Omit<SessionTemplateInterface, 'testimonials' | 'location'>> & {
  location?: Partial<SessionTemplateLocation> | null;
} & {
  testimonials?: {
    created?: Testimonial[];
    deleted?: number[];
    updated?: Testimonial[];
  };
};

export interface SessionTemplateHost {
  firstName: string;
  isFixed?: boolean;
  lastName: string;
  email?: string;
  location: {
    address?: string;
    callInitiator?: CallInitiator;
    hideAddress?: boolean;
    connectionType: SessionConnectionTypes;
  };
  role?: number;
  permission?: ServiceAssigneePermission;
  photo: string | null;
  userId: number;
}

export class SessionTemplate implements SessionTemplateInterface {
  afterEventBuffer: number;
  availabilities?: SessionTemplateSchedule[][];
  beforeEventBuffer: number;
  bookingLimitsFrequency: LimitBookingFrequency | null;
  coverImage: string | null;
  coverImageThumb: string | null;
  descriptionMarkup: string;
  descriptionRepresentation: string;
  descriptionText: string;
  disableGuests: boolean;
  duration: number;
  hasAutoConfirmation: boolean;
  hasChat: boolean;
  hidePrice?: boolean | null;
  hosts: SessionTemplateHost[];
  id?: number;
  limitFutureBookings: LimitFutureBookings | null;
  location: SessionTemplateLocation;
  minimumBookingNotice: number;
  name: string;
  permission: ServiceAssigneePermission;
  price?: number | null;
  recurrence: Pick<RecurringEvent, 'count'> | null;
  removeFromChatOnSessionEnd: boolean;
  scheduleId: number | null;
  seatsPerTimeSlot: number | null;
  seatsShowAttendees: boolean;
  serviceType: GuideServiceTypes;
  sessionType: SessionType;
  slotInterval: number;
  status: GuideProfileTypes;
  testimonials: Testimonial[];
  viewChatHistory: boolean;
  workspaceId: number;
  customAvailability: SessionTemplateCustomAvailability | null;
  // enabled and communityName both related to peerboards
  enabled?: boolean;
  communityName?: string | null;
  restrictClientBooking: boolean;
  serviceParent?: {
    id?: number;
    type?: GuideServiceTypes.PACKAGE | GuideServiceTypes.PROGRAM;
    name?: string;
  };

  static clean(dirtyTemplate: SessionTemplateInterface): SessionTemplateInterface {
    return {
      afterEventBuffer: dirtyTemplate.afterEventBuffer,
      availabilities: dirtyTemplate.availabilities,
      beforeEventBuffer: dirtyTemplate.beforeEventBuffer,
      bookingLimitsFrequency: dirtyTemplate.bookingLimitsFrequency,
      coverImage: dirtyTemplate.coverImage,
      coverImageThumb: dirtyTemplate.coverImageThumb,
      descriptionMarkup: (dirtyTemplate.descriptionMarkup && dirtyTemplate.descriptionMarkup.trim()) || '',
      descriptionRepresentation:
        (dirtyTemplate?.descriptionRepresentation && dirtyTemplate?.descriptionRepresentation?.trim()) || '',
      descriptionText: (dirtyTemplate.descriptionText && dirtyTemplate.descriptionText.trim()) || '',
      disableGuests: dirtyTemplate.disableGuests,
      duration: dirtyTemplate.duration,
      hasAutoConfirmation: dirtyTemplate.hasAutoConfirmation,
      hasChat: dirtyTemplate.hasChat,
      hidePrice: dirtyTemplate.hidePrice,
      hosts: dirtyTemplate.hosts,
      limitFutureBookings: dirtyTemplate.limitFutureBookings,
      location: {
        connectionType:
          ((dirtyTemplate.location.connectionType &&
            dirtyTemplate.location.connectionType.trim()) as SessionConnectionTypes) ||
          SessionConnectionTypes.IN_PLATFORM,
        address: dirtyTemplate.location.address,
        hideAddress: dirtyTemplate.location.hideAddress,
        callInitiator: dirtyTemplate.location.callInitiator
      },
      minimumBookingNotice: dirtyTemplate.minimumBookingNotice,
      name: (dirtyTemplate.name && dirtyTemplate.name.trim()) || '',
      permission: dirtyTemplate.permission,
      price: dirtyTemplate.price,
      recurrence: dirtyTemplate.recurrence,
      removeFromChatOnSessionEnd: dirtyTemplate.removeFromChatOnSessionEnd,
      scheduleId: dirtyTemplate.scheduleId,
      seatsPerTimeSlot: dirtyTemplate.seatsPerTimeSlot,
      seatsShowAttendees: dirtyTemplate.seatsShowAttendees,
      serviceType: dirtyTemplate.serviceType,
      sessionType: dirtyTemplate.sessionType,
      slotInterval: dirtyTemplate.slotInterval,
      status: dirtyTemplate.status,
      testimonials:
        (dirtyTemplate.testimonials &&
          dirtyTemplate.testimonials.filter(
            testimonial =>
              (testimonial.text && testimonial.text.trim()) || (testimonial.clientInfo && testimonial.clientInfo.trim())
          )) ||
        null,
      viewChatHistory: dirtyTemplate.viewChatHistory,
      workspaceId: dirtyTemplate.workspaceId,
      customAvailability: dirtyTemplate.customAvailability,
      restrictClientBooking: dirtyTemplate.restrictClientBooking
    };
  }

  constructor(sessionTemplate: SessionTemplateInterface) {
    if (sessionTemplate.id) {
      this.id = sessionTemplate.id;
    }
    this.afterEventBuffer = sessionTemplate.afterEventBuffer;
    this.availabilities = sessionTemplate.availabilities;
    this.beforeEventBuffer = sessionTemplate.beforeEventBuffer;
    this.bookingLimitsFrequency = sessionTemplate.bookingLimitsFrequency;
    this.coverImage = sessionTemplate.coverImage;
    this.coverImageThumb = sessionTemplate.coverImageThumb;
    this.descriptionMarkup = sessionTemplate.descriptionMarkup;
    this.descriptionRepresentation = sessionTemplate.descriptionRepresentation;
    this.descriptionText = sessionTemplate.descriptionText;
    this.disableGuests = sessionTemplate.disableGuests;
    this.duration = sessionTemplate.duration;
    this.hasAutoConfirmation = sessionTemplate.hasAutoConfirmation;
    this.hasChat = sessionTemplate.hasChat;
    this.hidePrice = sessionTemplate.hidePrice;
    this.hosts = sessionTemplate.hosts;
    this.limitFutureBookings = sessionTemplate.limitFutureBookings;
    this.location = sessionTemplate.location;
    this.minimumBookingNotice = sessionTemplate.minimumBookingNotice;
    this.name = sessionTemplate.name;
    this.permission = sessionTemplate.permission;
    this.price = sessionTemplate.price;
    this.recurrence = Object.keys(sessionTemplate.recurrence || {}).length > 0 ? sessionTemplate.recurrence : null;
    this.removeFromChatOnSessionEnd = sessionTemplate.removeFromChatOnSessionEnd;
    this.scheduleId = sessionTemplate.scheduleId;
    this.seatsPerTimeSlot = sessionTemplate.seatsPerTimeSlot;
    this.seatsShowAttendees = sessionTemplate.seatsShowAttendees;
    this.serviceType = sessionTemplate.serviceType;
    this.sessionType = sessionTemplate.sessionType;
    this.slotInterval = sessionTemplate.slotInterval;
    this.status = sessionTemplate.status;
    this.testimonials = sessionTemplate.testimonials
      ? sessionTemplate.testimonials.map(testimonial => new Testimonial().setValues(testimonial))
      : [];
    this.viewChatHistory = sessionTemplate.viewChatHistory;
    this.workspaceId = sessionTemplate.workspaceId;
    this.customAvailability = sessionTemplate.customAvailability;
    this.enabled = sessionTemplate.enabled;
    this.communityName = sessionTemplate.communityName;
    this.restrictClientBooking = sessionTemplate.restrictClientBooking;
    this.serviceParent = sessionTemplate.serviceParent;
  }

  getDiffFrom(newTemplate: SessionTemplateInterface): { id: number; patch: SessionTemplatePatchInterface } | null {
    const diff: SessionTemplatePatchInterface = {};

    const properties = Object.getOwnPropertyNames(this).filter(prop => {
      const property = this[prop as keyof typeof this];
      return typeof property !== 'function';
    });

    properties.forEach((prop: keyof SessionTemplateInterface) => {
      if (
        [
          'id',
          'testimonials',
          'location',
          'availabilities',
          'customAvailability',
          'recurrence',
          'limitFutureBookings',
          'bookingLimitsFrequency'
        ].includes(prop)
      ) {
        return;
      }

      if (this[prop] !== newTemplate[prop]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        diff[prop] = newTemplate[prop];
      }
    });

    const testimonialsDiff = this.getItemsDiff(this.testimonials, newTemplate.testimonials || []);

    const locationDiff = this.getLocationDiff(this.location, newTemplate.location);

    const limitFutureBookingsDiff = this.getLimitFutureBookingsDiff(
      this.limitFutureBookings,
      newTemplate.limitFutureBookings
    );

    const bookingLimitsFrequencyDiff = this.getBookingLimitsFrequencyDiff(
      this.bookingLimitsFrequency,
      newTemplate.bookingLimitsFrequency
    );

    const customAvailabilityDiff = this.getCustomAvailabilityDiff(
      this.customAvailability,
      newTemplate.customAvailability
    );

    const recurrenceDiff = this.getRecurrenceDiff(this.recurrence, newTemplate.recurrence);

    if (Object.keys(testimonialsDiff).length) {
      diff.testimonials = testimonialsDiff;
    }

    if (Object.keys(locationDiff).length) {
      diff.location = locationDiff;
    }

    if (limitFutureBookingsDiff === null) {
      diff.limitFutureBookings = limitFutureBookingsDiff;
    } else if (Object.keys(limitFutureBookingsDiff || {}).length > 0) {
      diff.limitFutureBookings = limitFutureBookingsDiff;
    }

    if (customAvailabilityDiff === null) {
      diff.customAvailability = customAvailabilityDiff;
    } else if (Object.keys(customAvailabilityDiff || {}).length > 0) {
      diff.customAvailability = customAvailabilityDiff;
    }

    if (recurrenceDiff === null) {
      diff.recurrence = recurrenceDiff;
    } else if (Object.keys(recurrenceDiff || {}).length > 0) {
      diff.recurrence = recurrenceDiff as RecurringEvent;
    }

    if (bookingLimitsFrequencyDiff === null) {
      diff.bookingLimitsFrequency = bookingLimitsFrequencyDiff;
    } else if (Object.keys(bookingLimitsFrequencyDiff || {}).length > 0) {
      diff.bookingLimitsFrequency = bookingLimitsFrequencyDiff as LimitBookingFrequency;
    }

    return Object.keys(diff).length > 0 ? { id: this.id!, patch: diff } : null;
  }

  setPersistenceAttributes(attributes: SessionTemplatePersistenceAttributes): SessionTemplate {
    this.id = attributes.id;

    if (attributes.testimonials) {
      this.testimonials.forEach(testimonial => {
        const persistentTestimonialId = attributes.testimonials![testimonial.localId];

        if (persistentTestimonialId) {
          testimonial.setId(persistentTestimonialId);
        }
      });
    }

    return this;
  }

  private getItemsDiff(
    currentItems: Testimonial[],
    newContentItems: Testimonial[]
  ): {
    created?: Testimonial[];
    deleted?: number[];
    updated?: Testimonial[];
  } {
    const diff: {
      created?: Testimonial[];
      deleted?: number[];
      updated?: Testimonial[];
    } = {};
    const { created, deleted, updated } = getConstantOrderArraysPatch<Testimonial, Testimonial>(
      currentItems,
      newContentItems
    );

    if (created.length) {
      diff.created = created;
    }

    if (deleted.length) {
      diff.deleted = deleted;
    }

    if (updated.length) {
      diff.updated = updated;
    }

    return diff;
  }

  private getLocationDiff(
    location: SessionTemplateLocation,
    newLocation: SessionTemplateLocation
  ): Partial<SessionTemplateLocation> {
    let locationDiff: Partial<SessionTemplateLocation> = {};

    if (location.connectionType === newLocation.connectionType) {
      const properties = Object.getOwnPropertyNames(location);

      properties.forEach((prop: keyof SessionTemplateLocation) => {
        if (location[prop] !== newLocation[prop]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          locationDiff[prop] = newLocation[prop];
        }
      });
    } else {
      locationDiff = newLocation;
    }

    return locationDiff;
  }

  private getLimitFutureBookingsDiff(
    limitFutureBookings: LimitFutureBookings | null,
    newLimitFutureBookings: LimitFutureBookings | null
  ): LimitFutureBookings | null | undefined {
    if (limitFutureBookings === null && newLimitFutureBookings === null) {
      return undefined;
    }
    const changed = JSON.stringify(limitFutureBookings) !== JSON.stringify(newLimitFutureBookings);

    return changed ? newLimitFutureBookings : {};
  }

  private getBookingLimitsFrequencyDiff(
    bookingLimitsFrequency: LimitBookingFrequency | null,
    newBookingLimitsFrequency: LimitBookingFrequency | null
  ): LimitBookingFrequency | Record<string, never> | null | undefined {
    if (bookingLimitsFrequency === null && newBookingLimitsFrequency === null) {
      return undefined;
    }
    const changed = !isEqual(bookingLimitsFrequency, newBookingLimitsFrequency);

    return changed ? newBookingLimitsFrequency : {};
  }

  private getCustomAvailabilityDiff(
    customAvailability: SessionTemplateCustomAvailability | null | undefined,
    newCustomAvailability: SessionTemplateCustomAvailability | null | undefined
  ): SessionTemplateCustomAvailability | null | undefined {
    if (customAvailability === null && newCustomAvailability === null) {
      return undefined;
    }
    const changed = JSON.stringify(customAvailability) !== JSON.stringify(newCustomAvailability);

    return changed ? newCustomAvailability : {};
  }

  private getRecurrenceDiff(
    recurrence: Pick<RecurringEvent, 'count'> | null,
    newRecurrence: Pick<RecurringEvent, 'count'> | null
  ): Pick<RecurringEvent, 'count'> | Record<string, never> | null | undefined {
    if (recurrence === null && newRecurrence === null) {
      return undefined;
    }

    const changed = !isEqual(recurrence, newRecurrence);

    return changed ? newRecurrence : {};
  }
}
