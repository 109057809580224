import { Pipe, PipeTransform } from '@angular/core';

const size = 1024;

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const toMb = (value, postfix) => Number((value / size / size).toFixed(2)) + ` ${postfix}`;

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const toKb = (value, postfix) => Number((value / size).toFixed()) + ` ${postfix}`;

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const toBytes = (value, postfix) => `${value} ${postfix}`;

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: number, postfix?: 'Mb' | 'Kb' | 'bytes'): any {
    if (!value) {
      return null;
    }

    const valueLen = value.toString().length;

    switch (postfix) {
      case 'Mb':
        return toMb(value, postfix);
      case 'Kb':
        return toKb(value, postfix);
      case 'bytes':
        return toBytes(value, postfix);
      default:
        if (valueLen < 4) {
          return toBytes(value, 'bytes');
        }
        if (valueLen < 6) {
          return toKb(value, 'Kb');
        }
        return toMb(value, 'Mb');
    }
  }
}
