import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuiButtonModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { SidenavAdminLogoutComponent } from '@app/modules/sidenav/components/sidenav-admin-logout/sidenav-admin-logout.component';

@NgModule({
  declarations: [SidenavAdminLogoutComponent],
  imports: [CommonModule, PuiButtonModule, PuiIconModule]
})
export class SidenavAdminLogoutModule {}
