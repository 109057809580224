import { AlertKey, Alert, AlertCreaters } from '../../types/alert';

export class AlertTemplates {
  static alerts: AlertCreaters = {
    'coupon-applied': (): Alert => ({
      title: "You're given the gift of wellbeing",
      description: 'Your wallet balance was successfully updated.',
      className: 'success',
      template: AlertKey.COUPON_APPLIED
    }),
    'email-warning': (): Alert => ({
      title: 'Please confirm your e-mail',
      description: "We have sent you an e-mail with a link to confirm. It's easy and quick!",
      className: 'warning',
      template: AlertKey.EMAIL_WARNING
    }),
    'email-success': (): Alert => ({
      title: 'Your email has been successfully confirmed',
      description: 'Thank you, and enjoy the full functionality of the platform',
      className: 'success',
      template: AlertKey.EMAIL_SUCCESS
    }),
    'incomplete-guide-profile': (incompleteSettingsString): Alert => ({
      title: 'Your profile is incomplete',
      description: `To complete profile please set next properties: ${incompleteSettingsString}!`,
      className: 'error',
      template: AlertKey.INCOMPLETE_GUIDE_PROFILE
    }),
    review: ({ guideName }): Alert => ({
      title: `Write a review for your last session with <b>${guideName}</b>`,
      description: `
        It will help others understand if this coach is the right fit for them.
        Also ${guideName} will be grateful to see what worked, and how they can improve.
      `,
      className: 'review',
      template: AlertKey.REVIEW
    }),
    'increase-balance': (): Alert => ({
      title: "You're given the funds",
      description: 'Your wallet balance was successfully updated.',
      className: 'success',
      template: AlertKey.INCREASE_BALANCE
    }),
    'decrease-balance': (): Alert => ({
      title: 'Paid from balance',
      description: 'An amount was debited from the balance as payment for the service',
      className: 'success',
      template: AlertKey.DECREASE_BALANCE
    }),
    'trial-subscription-membership': ({ days, platformName }): Alert => ({
      title: `Your free trial expires in ${days} days. You can select a plan and subscribe to ${platformName} at any time.`,
      description: '',
      className: 'trial-membership',
      template: AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP
    }),
    'trial-ltd-membership': ({ days, platformName }): Alert => ({
      title: `Your free trial expires in ${days} days. You can select a plan and subscribe to ${platformName} at any time.`,
      description: '',
      className: 'trial-membership',
      template: AlertKey.TRIAL_LTD_MEMBERSHIP
    }),
    'trial-subscription-membership-team': ({ days, platformName }): Alert => ({
      title: `Your free trial expires in ${days} days. To continue using ${platformName} with your team, please get in touch.`,
      description: '',
      className: 'trial-membership',
      template: AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM
    }),
    'trial-subscription-membership-team-finished': ({ platformName }): Alert => ({
      title: `Your free trial has expired. To continue using ${platformName} with your team, please get in touch`,
      description: '',
      className: 'trial-membership',
      template: AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED
    }),
    'team-membership': (): Alert => ({
      title: `If you have a team of coaches, you can grab our LTD Team plan by reaching out to support.`,
      description: '',
      className: 'trial-membership',
      template: AlertKey.TEAM_MEMBERSHIP
    }),
    'clients-limit-reached': (number): Alert => ({
      title: `You've hit your ${number} client limit. To add new clients, please increase your limits `,
      description: '',
      className: 'clients-limit',
      template: AlertKey.CLIENT_LIMIT_REACHED
    })
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  static get(key: AlertKey, data?: any) {
    return this.alerts[key] ? this.alerts[key](data || null) : null;
  }
}
