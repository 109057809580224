// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRecurrenceRule {
  freq: string;
  byday?: number[];
  bysetpos?: number;
  until?: string; // iso datetime
  count?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRecurrenceSetRule {
  type: 'rrule';
  value: IRecurrenceRule;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRecurrenceSetDate {
  type: 'rdate';
  value: string | Date;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRecurrenceSetExDate {
  type: 'exdate';
  value: string | Date;
}

export type RecurrenceSetItem = IRecurrenceSetRule | IRecurrenceSetDate | IRecurrenceSetExDate;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleBlockBase {
  start: string; // iso datetime
  end: string; // iso datetime
  recurrence?: IRecurrenceRule;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleBlockSimple extends IScheduleBlockBase {
  timezone: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleBlockFull extends IScheduleBlockBase {
  startTimezone: string;
  endTimezone: string;
}

export function isRecurrenceSetRule(item: RecurrenceSetItem): item is IRecurrenceSetRule {
  return item.type === 'rrule';
}
