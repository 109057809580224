import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { HttpClient, HttpParams } from '@angular/common/http';
import config from '@app/core/config/config';
import { Observable } from 'rxjs';
import { ITeamMembersSharingSettings } from '@app/modules/guide-client/types/team-members';
import { TeamMemberAccessType } from '@app/modules/guide-client/services/guide-relation-share-notes.service';

@Injectable()
export class GuideRelationShareNotesApiService {
  private readonly ENDPOINT = `${config.apiPath}/user/guide/notes/client/`;

  constructor(private readonly _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _generateUrl(clientId: number, noteId?: number) {
    return `${this.ENDPOINT}${clientId}/access/${noteId || ''}`;
  }

  getPossibleTeamMembers$(clientId: number, noteId?: number): Observable<ITeamMembersSharingSettings[]> {
    return this._http.get<ITeamMembersSharingSettings[]>(this._generateUrl(clientId, noteId));
  }

  setAccess$(clientId: number, userId: number, accessType: TeamMemberAccessType, noteId?: number): Observable<unknown> {
    return this._http.post(this._generateUrl(clientId, noteId), { userId, accessType });
  }
}
