import { ModuleTypes } from '../../program-module';
import { ModuleInstructor } from './module-instructor';
import { INote } from '../../../../interfaces/notes';

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientProgramModuleNote extends INote {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseModuleDetails {
  readonly id: number;
  readonly order: number;
  readonly title: string | null;
  readonly description: string | null;
  note: IClientProgramModuleNote | null;
  readonly instructor: ModuleInstructor | undefined;
}

export abstract class BaseModule {
  readonly id: number;
  readonly order: number;
  readonly title: string | null;
  readonly description: string | null;
  note: IClientProgramModuleNote | null;
  instructor: ModuleInstructor | undefined;

  abstract get type(): ModuleTypes;

  protected constructor(moduleDetails: Readonly<IBaseModuleDetails>) {
    if (!moduleDetails) {
      throw new Error('Invalid argument');
    }

    this.id = moduleDetails.id;
    this.order = moduleDetails.order;
    this.title = moduleDetails.title;
    this.description = moduleDetails.description;
    this.note = moduleDetails.note;
    this.instructor = moduleDetails.instructor;
  }
}
