import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { ShareFormComponent } from '@app/screens/guide/guide-surveys/components/share-form/share-form.component';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { QuizService } from '@app/screens/guide/guide-surveys/services/quiz.service';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';
import { SurveyFormService } from '../../services/survey-form.service';
import { FormService } from '@app/core';
import { RedirectAfterSaveActions } from '@app/screens/guide/guide-shared/can-redirect-after-save';

@Component({
  selector: 'app-quiz-automation',
  templateUrl: './quiz-automation.component.html',
  styleUrls: ['./quiz-automation.component.scss', '../../common-styles/chat-bot.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizAutomationComponent implements OnDestroy, SaveOnRouteChanged, AfterViewInit {
  readonly saveButtonDisabled$ = this.surveyFormService.loading$;

  isPlatformAdmin = false;

  get quizId(): number | null {
    return this.surveyFormService.quizId;
  }

  get isTeam$(): Observable<boolean> {
    return this.workspacesService.isTeam$;
  }

  get tabIsEdited(): boolean {
    return this.quizForm.dirty;
  }

  get tabIsValid(): boolean {
    return this.quizForm.valid;
  }

  get isFormValid(): boolean {
    return !this.formService.markInvalidForm(this.quizForm);
  }

  private readonly quizForm = this.surveyFormService.quizForm;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly quizService: QuizService,
    private readonly authService: AuthService,
    private readonly workspacesService: WorkspacesService,
    private readonly dialog: PuiDialogService,
    private readonly surveyFormService: SurveyFormService,
    private readonly formService: FormService
  ) {
    this.isPlatformAdmin = this.authService.isPlatformAdmin();
  }

  ngAfterViewInit(): void {
    this.markFormPristine();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveChanges(saveWithoutConfirmation = false): void {
    this.validateForm();

    if (!this.isFormValid) {
      return;
    }

    if (this.isPlatformAdmin && !saveWithoutConfirmation) {
      const ref = this.dialog.open(GuideChangesForNewUsersDialogComponent);
      ref.afterClosed$.pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
        if (result === RedirectAfterSaveActions.SAVE) {
          this.saveChanges(true);
        }
      });
      return;
    } else {
      this.surveyFormService
        .updateSurvey$()
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => {
          this.markFormPristine();
        });
    }
  }

  shareForm(): void {
    const ref = this.dialog.open(ShareFormComponent, {
      data: {
        idsToExclude: [this.authService.user.id]
      }
    });

    ref.afterClosed$
      .pipe(
        take(1),
        filter(members => !!members && members.length),
        switchMap((members: WorkspaceUser[]) =>
          this.quizService.shareQuiz$(
            this.surveyFormService.quizId as number,
            members.map((member: WorkspaceUser) => member.UserId)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  saveOnRouteChange(confirmation = false): void {
    this.saveChanges(confirmation);
  }

  revertChanges(): void {
    this.surveyFormService.loadSavedQuizForm();
    this.markFormPristine();
  }

  validateForm(): void {
    this.formService.markFormDirtyAndTouched(this.quizForm);
  }

  private markFormPristine(): void {
    this.quizForm.markAsUntouched();
    this.quizForm.markAsPristine();
  }
}
