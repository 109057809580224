import { DateTime } from 'luxon';

import { Pipe, PipeTransform } from '@angular/core';
import { BookingAvailability } from '@appWidget/modules/booking/interfaces';
import { PuiDateService } from '@awarenow/profi-ui-core';

@Pipe({
  name: 'availableDateFn',
  pure: true
})
export class AvailableDateFnPipe implements PipeTransform {
  constructor(private puiDateService: PuiDateService) {}

  transform(events: BookingAvailability, timezone?: string): (date: Date) => boolean {
    const availableDates: Date[] = [];

    if (events && Object.keys(events).length >= 0) {
      for (const i in events) {
        const datetime = timezone ? DateTime.fromISO(i).setZone(timezone!) : DateTime.fromISO(i);

        availableDates.push(datetime.toJSDate());
      }

      return (date: Date): boolean => {
        return availableDates.findIndex(availableDate => this.puiDateService.isSameDay(availableDate, date)) > -1;
      };
    }

    return (): boolean => false;
  }
}
