<form [formGroup]="form">
  <div *ngIf="showTitle" class="pui-space_bottom-4">
    <h3 pui-typography="h3" i18n="@@sessionTemplateSessionAvailabilityTitle">Session availability</h3>
  </div>

  <div class="d-flex flex-row">
    <pui-toggle
      class="availability-form__toggle"
      formControlName="active"
      data-qa-id="guide-service-editor-availability-form">
    </pui-toggle>

    <div>
      <div class="availability-form__body">
        <span pui-typography="body-s-bold" i18n="@@sessionSpecificAvailabilityToggleTitle"
          >Define session specific availability</span
        >
        <span class="availability-form__description" pui-typography="body-s">
          <ng-container
            *ngIf="workspacesService.isTeamAdmin; else teamMemberDescription"
            i18n="@@sessionSpecificAdminAvailabilityToggleDescription">
            Enable this if you want to override the hosts' default availabilities for the session. When disabled, each
            host will be booked based on their default schedule.
          </ng-container>

          <ng-template #teamMemberDescription>
            <ng-container i18n="@@sessionSpecificAvailabilityToggleDescription">
              Enable this if you want to override your default availabilities for the session. When disabled, you will
              be booked based on your default schedule.
            </ng-container>
          </ng-template>
        </span>
      </div>
      <div *ngIf="user$ | async as user">
        <div *ngIf="form.get('active')?.value" class="pui-space_bottom-4">
          <div class="pui-space_bottom-2">
            <h5 pui-typography="h5" i18n="@@Availability">Availability</h5>
          </div>
          <button
            type="button"
            role="button"
            class="availability-form__select-button"
            [disabled]="form.disabled"
            pui-dropdown
            [pui-dropdown-width]="290"
            [pui-dropdown-content]="availabilitiesTemplate">
            <div [ngSwitch]="form.get('availabilityType')?.value">
              <span *ngSwitchCase="AvailabilityType.WORKING_HOURS">{{
                form.get('selectedAvailability')?.value?.name
              }}</span>
              <span *ngSwitchCase="AvailabilityType.CUSTOM" i18n="@@Custom">Custom</span>
              <span *ngSwitchCase="AvailabilityType.NONE"></span>
            </div>
            <pui-icon svgIcon="pui:select" size="xs"></pui-icon>
          </button>

          <ng-template #availabilitiesTemplate let-close="close">
            <div class="availability-form__dropdown-content">
              <ng-container *ngFor="let items of sortWithPrior(form.get('availabilities')?.value, user.id)">
                <ng-container *puiLet="getMember(items) as member">
                  <div *ngIf="items?.length">
                    <div class="availability-form__group-items" *ngIf="workspacesService.isTeamAdmin">
                      <pui-avatar
                        *ngIf="!isTeamAdmin(member.role)"
                        class="pui-space_right-2"
                        size="xs"
                        [text]="member.username"
                        [avatarUrl]="member.photo"></pui-avatar>
                      <h4 pui-typography="h4">
                        {{ isTeamAdmin(member.role) ? adminGroupName : member.username }}
                      </h4>
                    </div>

                    <div (click)="selectAvailability(availability); close()" *ngFor="let availability of items">
                      <app-availability-view
                        class="availability-form__item"
                        [showNamePostfix]="true"
                        [isOwnAvailability]="member.userId === user.id"
                        [availability]="{ availability, timezone: user.timezone}"></app-availability-view>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div (click)="selectAvailability(); close()" class="availability-form__custom-item">
                <span i18n="@@Custom">Custom</span>
              </div>
            </div>
          </ng-template>

          <ng-container *ngIf="form.get('availabilityType')?.value === AvailabilityType.CUSTOM">
            <app-custom-availability-form formControlName="customAvailability"></app-custom-availability-form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #optionContentTemplate let-option="option">
  <span pui-typography="body-s">{{ option.data.label }}</span>
</ng-template>
