import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ExternalCalendarSources, IExternalAccount } from '@app/core/calendars/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ICalendarSource {
  readonly source: ExternalCalendarSources;
  readonly name: string;
  readonly description?: string | null;
  readonly accounts?: readonly IExternalAccount[] | null;
}

const calendarSourcesClasses = {
  [ExternalCalendarSources.GOOGLE]: 'google',
  [ExternalCalendarSources.OUTLOOK]: 'outlook'
};

@Component({
  selector: 'app-calendar-source-card',
  templateUrl: './calendar-source-card.component.html',
  styleUrls: ['./calendar-source-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarSourceCardComponent {
  readonly ExternalCalendarSources = ExternalCalendarSources;

  @Input()
  // @ts-expect-error TS2564
  source: ICalendarSource;

  @Output()
  connect = new EventEmitter<void>();

  @Output()
  disconnect = new EventEmitter<number>();

  @HostBinding('class')
  get classes(): string {
    // @ts-expect-error TS7053
    return ['calendar-source-card', this.source != null ? calendarSourcesClasses[this.source.source] : ''].join(' ');
  }

  get connected(): boolean {
    return this.source != null && this.source.accounts != null && this.source.accounts.length > 0;
  }
}
