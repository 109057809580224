import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgramModulesNoteMeta } from '@app/modules/client-notes/client-notes.type';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';

@Component({
  selector: 'app-note-program-module-meta',
  templateUrl: './note-program-module-meta.component.html',
  styleUrls: ['./note-program-module-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteProgramModuleMetaComponent {
  @Input()
  // @ts-expect-error TS2564
  metaProgramModules: ProgramModulesNoteMeta;

  moduleContainWithIndividualSession(): boolean {
    return (
      this.metaProgramModules.moduleType === ModuleTypes.SESSION && !this.metaProgramModules.moduleMeta.moduleTitle
    );
  }
}
