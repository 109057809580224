import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModuleStatuses, ProgramModule } from '@app/shared/interfaces/programs/client-programs';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.scss']
})
export class ModuleSelectorComponent {
  iconsMap = {
    quiz: 'pui:clipboard',
    content: 'pui:book-open',
    group_session: 'pui:monitor'
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentModuleIndex = -1;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programModules: ProgramModule[];

  readonly unavailableModuleTooltip = `This module is currently unavailable`;

  @Input()
  set currentModuleIndex(value: number) {
    this._currentModuleIndex = value;
    this.updateSelectedModule();
  }

  get currentModuleIndex(): number {
    return this._currentModuleIndex;
  }

  @Input()
  set programModules(value: ProgramModule[]) {
    this._programModules = value;
    this.updateSelectedModule();
  }

  get programModules(): ProgramModule[] {
    return this._programModules;
  }

  @Output()
  select = new EventEmitter<ProgramModule>();

  @ViewChild('moduleSelect', { static: true })
  // @ts-expect-error TS7008
  moduleSelect;

  readonly ModuleTypes = ModuleTypes;
  // @ts-expect-error TS2564
  selectedModule: ProgramModule;

  // hack for ng-select
  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitSelect(module) {
    this.select.emit(module as ProgramModule);
  }

  selectModule(module: ProgramModule): void {
    if (this.moduleSelect) {
      this.moduleSelect.select(module);
    }
  }

  private updateSelectedModule(): void {
    if (this._currentModuleIndex !== -1 && this._programModules && this._programModules.length) {
      this.selectedModule = this._programModules[this._currentModuleIndex];
    }
  }

  isModuleAvailable(module: ProgramModule): boolean {
    return module.status === ModuleStatuses.ALLOWED || module.status === ModuleStatuses.SEEN;
  }
}
