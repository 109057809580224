import { Injectable } from '@angular/core';
import { PuiDrawerConfig, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { ComponentType } from '@angular/cdk/portal';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideClient } from '@app/core/users/types';
import { IGuideContactDetails } from '@app/modules/guide-client/types';
import { ContactViewModel } from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';
import { GuideClientCardTab } from '@app/modules/guide-client/components';

enum Component {
  Add = 'ClientAddComponent',
  Details = 'ClientDetailsComponent'
}

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Injectable({ providedIn: 'root' })
export class GuideRelationPanelService {
  [Component.Add]: Promise<unknown> = import('../components/guide-relation-add/client-add.component');

  [Component.Details]: Promise<unknown> = import('../components/guide-relation-details/client-details.component');

  // @ts-expect-error TS2564
  drawer: PuiDrawerRef;

  constructor(private _drawerService: PuiDrawerService, private _guideClientsService: GuideClientsService) {}

  showAddClientPanel(): Observable<void | IGuideContactDetails[]> {
    return this._lazyLoadAddComponent(Component.Add).pipe(
      switchMap(component => {
        this.drawer = this._drawerService.open(component, DRAWER_CONFIG);

        return this.drawer.afterClosed$;
      })
    );
  }

  showDetailsClientPanel(client: Pick<ContactViewModel, 'id' | 'type'>, tab?: GuideClientCardTab): Observable<void> {
    this._selectRelation(client);

    return this._lazyLoadAddComponent(Component.Details).pipe(
      switchMap(component => {
        this.drawer = this._drawerService.open(component, DRAWER_CONFIG, { tab });

        return this.drawer.afterClosed$;
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _lazyLoadAddComponent(component: Component): Observable<ComponentType<unknown>> {
    // @ts-expect-error TS2571
    return from(this[component]).pipe(map(imported => imported[component]));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _selectRelation({ type, id }: Pick<ContactViewModel, 'id' | 'type'>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const localId = GuideClient.createLocalId({ type, id } as any);

    this._guideClientsService.selectedRelationId.next(localId);
  }
}
