import { Pipe, PipeTransform } from '@angular/core';
import { PlatformStyle } from '../services/platform-style.service';
import { AnyDate, DateTimeOptions } from '../model/interfaces';

@Pipe({
  name: 'platformDate'
})
export class PlatformDatePipe implements PipeTransform {
  constructor(private platformStyle: PlatformStyle) {}

  transform(value: AnyDate, options?: DateTimeOptions): string {
    return this.platformStyle.datetime(value, 'DATE', options);
  }
}
