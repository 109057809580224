import { IMenuLink, MenuLinkTypes } from '@app/screens/admin/types';
import { PuiNavigationEntityEvents } from '@awarenow/profi-ui-core';
import { WindowProvider } from '@app/core/browser-window/window-provider';

export const createEventFromLink = (
  link: IMenuLink,
  windowService: WindowProvider
): PuiNavigationEntityEvents | undefined => {
  if (!link.url) {
    return undefined;
  }

  return {
    // eslint-disable-next-line id-length
    click: e => {
      e.preventDefault();
      e.stopPropagation();

      if (link.url) {
        if ('open' in windowService.window) {
          if (link.type === MenuLinkTypes.EXTERNAL) {
            windowService.window.open(link.url, '_blank', 'noopener,noreferrer');
          } else if (link.type === MenuLinkTypes.INTERNAL) {
            windowService.window.open(link.url, '_self', 'noopener,noreferrer');
          }
        }
      }
    }
  };
};
