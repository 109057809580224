import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-wiki-card',
  templateUrl: './wiki-card.component.html',
  styleUrls: ['./wiki-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WikiCardComponent {
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wiki: any;
  platformName: string;

  constructor(private readonly _runtimeConfigService: RuntimeConfigService) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }
}
