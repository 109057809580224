import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';

import { BaseSessionModuleProgress, IBaseSessionModuleProgressDetails } from './base-session-module-progress';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionModuleProgressDetails extends IBaseSessionModuleProgressDetails {
  readonly serviceId: number;
}

export class SessionModuleProgress extends BaseSessionModuleProgress {
  serviceId: number;
  get moduleType(): ModuleTypes {
    return ModuleTypes.SESSION;
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(moduleDetails: Readonly<ISessionModuleProgressDetails>) {
    super(moduleDetails);

    this.serviceId = moduleDetails.serviceId;
  }
}
