import { DateTime } from 'luxon';

import { Pipe, PipeTransform } from '@angular/core';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';

import { AnyDate, DateTimeOptions, Fragment, SingleTemplate } from '../model/interfaces';
import { PlatformStyle } from '../services/platform-style.service';

@Pipe({
  name: 'platformDateTime'
})
export class PlatformDateTimePipe implements PipeTransform {
  constructor(private platformStyle: PlatformStyle, private timezoneStore: UserTimezoneStore) {}

  transform(
    value: AnyDate,
    template: SingleTemplate | Fragment[],
    options: DateTimeOptions = { timezone: this.timezoneStore.timezone || DateTime.local().zoneName }
  ): string {
    return this.platformStyle.datetime(value, template, {
      timezone: this.timezoneStore.timezone || DateTime.local().zoneName,
      ...options
    });
  }
}
