import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Injectable()
export class BlogOwnerGuard implements CanActivate {
  constructor(protected _auth: AuthService, protected _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const canActivate = this.isAdminOrGuide() && this.isOwner(route.params);

    if (!canActivate) {
      this._router.navigate(['/blog']);
    }

    return canActivate;
  }

  private isAdminOrGuide(): boolean {
    return this._auth.isAuthorized && [UserRoles.ADMIN, UserRoles.GUIDE].includes(this._auth.user.RoleId);
  }

  private isOwner(routeParams: Params): boolean {
    return (
      routeParams.guide &&
      ((this._auth.user.namedUrl && routeParams.guide === this._auth.user.namedUrl) ||
        +routeParams.guide === this._auth.user.id)
    );
  }
}
