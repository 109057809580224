<label class="question-option-label">
  <app-checkbox
    [ngModel]="isSelected"
    (ngModelChange)="changeSelection($event)"
    [ngClass]="{ radio: !multi }"
    [disabled]="isDisabled$ | async">
    <span class="key" *ngIf="key">{{ key }}</span>
    <span class="text">{{ text }}</span>
  </app-checkbox>
</label>
