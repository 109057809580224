<div class="question-text">
  <div class="question-html ql-editor" [appSetMetaToFirstChild]="key" [innerHTML]="question | safeHtml"></div>
</div>

<ng-container [formGroup]="form">
  <div class="question-options fs-exclude" formArrayName="answerOptions">
    <ng-container
      *ngFor="
        let option of formOptions.controls | orderBy: ['value.option.id'];
        let optionIndex = index;
        trackBy: trackBy
      "
      [formGroupName]="optionIndex">
      <!-- eslint-disable -->

      <app-question-option
        [key]="optionKeys[optionIndex]"
        [text]="option.value.text"
        [isCorrect]="isCorrectDisplay ? answeredOptions.get(option.value.option.id) : null"
        [multi]="multi"
        formControlName="option"
        class="question-option"
        [class.explained]="answeredOptions.get(option.value.option.id) != null && explanation"></app-question-option>
    </ng-container>

    <!-- eslint-enable -->
  </div>
  <div class="question-answer fs-exclude" *ngIf="isOtherSelected">
    <div class="answer ql-editor" *ngIf="isDisabled" [innerHTML]="form.get('answer').value | safeHtml"></div>

    <app-quill-editor-image formControlName="answer" *ngIf="!isDisabled"></app-quill-editor-image>
    <app-form-control-error [control]="form.get('answer')" i18n="@@answerRequired">
      Answer required
    </app-form-control-error>
  </div>
</ng-container>

<section
  class="question-answer-explanation-section"
  *ngIf="explanation"
  [ngClass]="{ correct: explanation?.isCorrect, incorrect: !explanation?.isCorrect }">
  <h6 class="question-answer-explanation-title">
    {{ explanation.isCorrect ? 'Correct' : 'Incorrect' }}
  </h6>

  <div class="question-answer-explanation">{{ explanation.text }}</div>
</section>
