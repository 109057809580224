import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { LogService } from '@app/core/log/log.service';
import { WISTIA_VIDEOS_ENDPOINT } from '@app/shared/constants/endpoints';
import { WistiaProject } from './types';

@Injectable()
export class WistiaService {
  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private logService: LogService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getWistiaProjectWithVideos$(force = false): Observable<WistiaProject> {
    return this.http.get<WistiaProject>(WISTIA_VIDEOS_ENDPOINT).pipe(
      catchError((err: HttpErrorResponse) => {
        this.logService.error(err, 'Failed to load wistia project with videos');
        this.notificationsService.error(`Failed to load videos`);
        return throwError(err);
      })
    );
  }
}
