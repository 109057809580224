import { Injectable } from '@angular/core';
import { INavigationDependenciesService } from '@app/modules/navigation';
import { GuideSessionsService } from '@app/screens/guide/guide-sessions/services/events';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';
import { ChatsService } from '@app/core/chat/chats.service';
import { WindowProvider } from '@app/core/browser-window/window-provider';
import { FirstpromoterService } from '@app/core/firstpromoter/firstpromoter.service';
import { AuthService } from '@app/core/auth/services';
import { LoopedinService } from '@app/core/integrations/modules/loopedin';
import { BrandingService } from '@app/core/branding/branding.service';

@Injectable()
export class NavigationDependenciesGuideService implements INavigationDependenciesService {
  constructor(
    public guideSessions: GuideSessionsService,
    public notifier: SystemNotificationsService,
    public chatService: ChatsService,
    public window: WindowProvider,
    public firstPromoterService: FirstpromoterService,
    public authService: AuthService,
    public loopedin: LoopedinService,
    public branding: BrandingService
  ) {}
}
