import { MatListOption, MatSelectionList } from '@angular/material/list';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  QueryList,
  ViewEncapsulation
} from '@angular/core';

/**
 * Component for list-options of selection-list. Each list-option can automatically
 * generate a checkbox and can put current item into the selectionModel of selection-list
 * if the current item is selected.
 */
@Component({
  selector: 'ui-list-option',
  exportAs: 'uiListOption',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-list-option'
  },
  templateUrl: 'list-option.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiListOption extends MatListOption {}

/**
 * Ui-kit list component where each item is a selectable option. Behaves as a listbox.
 */
@Component({
  selector: 'ui-selection-list',
  exportAs: 'uiSelectionList',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-selection-list'
  },
  template: '<ng-content></ng-content>',
  styleUrls: ['list.scss'],
  providers: [{ provide: MatSelectionList, useExisting: forwardRef(() => UiSelectionList) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiSelectionList extends MatSelectionList {
  /** The option components contained within this selection-list. */
  // @ts-expect-error TS2564
  @ContentChildren(UiListOption, { descendants: true }) options: QueryList<UiListOption>;
}
