<div class="row-m0 chat-message-container" [ngClass]="message?.status" data-qa-id="chat-history-message">
  <dl>
    <pui-avatar
      class="user-photo"
      [attr.data-qa-id]="'chat-message-sender-avatar-' + message.text"
      [ngClass]="{ clickable: doesSenderHaveNamedUrl }"
      [routerLink]="doesSenderHaveNamedUrl ? ['/', $any(sender).namedUrl] : null"
      [avatarUrl]="sender?.photo"
      [text]="sender?.name"></pui-avatar>
    <dt
      [ngClass]="{ clickable: doesSenderHaveNamedUrl }"
      [routerLink]="doesSenderHaveNamedUrl ? ['/', $any(sender).namedUrl] : null">
      <span [attr.data-qa-id]="'chat-message-sender-name-title-' + message.text">{{ sender?.name }}</span>
      <span class="date-time" [attr.data-qa-id]="'chat-message-time-title-' + message.text">
        <ng-container *ngIf="userRole === UserRoles.CLIENT">
          {{ message.date | platformTime }}
        </ng-container>
        <ng-container *ngIf="userRole === UserRoles.GUIDE">
          {{ { dateStart: message.date, dateEnd: message.date } | dateRange: "h:mmaaaaa'm'":'' | async }}
        </ng-container>
      </span>
    </dt>
    <div #container (click)="messageClicksHandler($event)" [class.no-pre-wrap]="containText">
      <ng-container *ngIf="isSenderABot()">
        <ng-container *ngIf="expiredTag">
          <dd>
            {{ expiredMessageParts[0] }}
            <a [routerLink]="'/client/programs/quiz/' + -sender.id" i18n="@@completeSurvey">complete survey</a>
            {{ expiredMessageParts[1] }}
          </dd>
        </ng-container>
        <ng-container *ngIf="!expiredTag">
          <ng-template [ngIf]="!message.type || message.type === MessageTypes.MESSAGE" [ngIfElse]="typedMessage">
            <dd [innerHTML]="message.text | safeHtml"></dd>
          </ng-template>
          <ng-template #typedMessage>
            <div [formGroup]="form">
              <app-module-quiz
                formControlName="questions"
                [isCorrectDisplay]="false"
                [editableAnswers]="true"
                [displayKey]="false"></app-module-quiz>
              <button class="btn btn-primary" data-submit="true">
                <ng-template i18n="@@submit" [ngIf]="!editMode" [ngIfElse]="edit">Submit</ng-template>
                <ng-template i18n="@@edit" #edit>Edit</ng-template>
              </button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isSenderABot()">
        <dd
          [innerHTML]="message.text | safeHtml"
          class="fs-exclude"
          [attr.data-qa-id]="'chat-message-content-text-' + message.text"></dd>
      </ng-container>
    </div>
  </dl>
</div>
