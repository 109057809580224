import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '@app/core/currency/currency.service';
import { LocaleService } from '@app/core/locale/locale.service';

@Pipe({ name: 'numberAbbreviation' })
export class NumberAbbreviationPipe implements PipeTransform {
  constructor(private readonly _currencyService: CurrencyService, private readonly _localeService: LocaleService) {}

  transform(value: number, isCurrency: boolean = false): string {
    const options: Intl.NumberFormatOptions = {
      notation: 'compact',
      maximumFractionDigits: 2 // Safari gives an error if maximumFractionDigits is lesser than 2
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    if (isCurrency) {
      options.style = 'currency';
      options.currency = this._currencyService.defaultCurrency;
    }

    const formatter = new Intl.NumberFormat(this._localeService.getLocale().language, options);

    return formatter.format(value);
  }
}
