import { inject, Optional, Provider, SkipSelf } from '@angular/core';
// import { locale } from '@env/locale';
import { environment } from '@env/environment';
import { DATE_TIME_LOCALE, SCHEDULE_DATE_FORMAT, SCHEDULE_TIME_FORMAT } from './tokens';
import { LocaleService } from '@app/core/locale/locale.service';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function DATE_TIME_LOCALE_FACTORY(parent: string) {
  return parent || inject(LocaleService).getLocale().dateTimeLocale;
}

export const LOCALE_PROVIDERS: Provider[] = [
  {
    provide: DATE_TIME_LOCALE,
    deps: [[new Optional(), new SkipSelf(), DATE_TIME_LOCALE]],
    useFactory: DATE_TIME_LOCALE_FACTORY
  }
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function SCHEDULE_DATE_FORMAT_FACTORY(parent: string) {
  return parent || 'longDate';
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function SCHEDULE_TIME_FORMAT_FACTORY(parent: string) {
  return parent || environment.timeFormat || 'h:mma';
}

export const SCHEDULE_FORMATS_PROVIDERS: Provider[] = [
  {
    provide: SCHEDULE_DATE_FORMAT,
    deps: [[new Optional(), new SkipSelf(), SCHEDULE_DATE_FORMAT]],
    useFactory: SCHEDULE_DATE_FORMAT_FACTORY
  },
  {
    provide: SCHEDULE_TIME_FORMAT,
    deps: [[new Optional(), new SkipSelf(), SCHEDULE_TIME_FORMAT]],
    useFactory: SCHEDULE_TIME_FORMAT_FACTORY
  }
];
