import { ModuleTypes } from '../../program-module';
import { BaseAccessibleModule, IBaseAccessibleModuleDetails } from './base-accessible-module';
import { OpenedModuleStatus } from './module-statuses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IContentModuleDetails extends IBaseAccessibleModuleDetails {
  readonly content: string | null;
  readonly status: OpenedModuleStatus;
}

export class ContentModule extends BaseAccessibleModule {
  readonly content: string | null;
  readonly status: OpenedModuleStatus;

  get type(): ModuleTypes {
    return ModuleTypes.CONTENT;
  }

  constructor(moduleDetails: Readonly<IContentModuleDetails>) {
    super(moduleDetails);

    this.content = moduleDetails.content;
    this.status = moduleDetails.status;
  }
}
