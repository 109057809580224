import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IConditionService } from '../interface';
import { ConditionsMap } from '../type';

@Injectable()
export abstract class AbstractConditionService<T extends string> implements IConditionService<T> {
  abstract readonly conditions: Partial<ConditionsMap<T>>;

  get$(name: T): Observable<boolean> {
    return this.conditions[name] || of(false);
  }
}
