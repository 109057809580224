import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ClientGuide } from '@app/core/users/types';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';

import { ClientSessionsCommunicationsService } from '../../services/client-sessions-communications.service';
import { IClientGuideAvailableSession } from '../../types/types';

@Component({
  selector: 'app-available-client-session',
  templateUrl: './available-client-session.component.html',
  styleUrls: ['../../styles/session.scss', './available-client-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableClientSessionComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientSession: IClientGuideAvailableSession;

  @Input()
  set clientSession(value: IClientGuideAvailableSession) {
    this._clientSession = value;
  }

  constructor(
    private _emitter: ClientSessionsCommunicationsService,
    private readonly _analyticsService: AnalyticsService
  ) {}

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get clientSession(): IClientGuideAvailableSession {
    return this._clientSession;
  }

  book(): void {
    this._emitter.bookAvailableSession(this.clientSession, AnalyticSourceTypes.PACKAGE);
  }

  buildDirectChatId(user: ClientGuide): string {
    return buildDirectChatLinkId(user);
  }
}
