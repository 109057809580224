import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { ChatsService } from '../../../core/chat/chats.service';

@Injectable()
export class ChatAvailableGuard implements CanActivate {
  constructor(private _chats: ChatsService, private _notifier: NotificationsService) {}

  canActivate(): Observable<boolean> {
    return this._chats.isAlive$.pipe(
      tap(isChatServiceAlive => {
        if (!isChatServiceAlive) {
          this._notifier.warn('Chat service cannot connect to server');
        }
      })
    );
  }
}
