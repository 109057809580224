<form class="create-team-form" [formGroup]="newWorkspaceForm">
  <h2 class="title" i18n="@@createWorkspaceHeading">Create your team workspace</h2>
  <div class="form-group">
    <app-form-field>
      <app-input-label>
        <ng-container i18n="@@workspaceName">Name your team to create your workspace.</ng-container>
      </app-input-label>
      <ui-input>
        <input
          data-qa-id="create-team-step-workspace-name-input"
          type="text"
          formControlName="name"
          class="form-control" />
      </ui-input>
    </app-form-field>
    <ng-container
      *ngIf="
        newWorkspaceForm.get('name')?.invalid &&
        (newWorkspaceForm.get('name')?.dirty || newWorkspaceForm.get('workspaceName')?.touched)
      ">
      <app-form-control-error
        [control]="newWorkspaceForm.get('name')"
        [show]="newWorkspaceForm.get('name')?.hasError('onlyNumbers')"
        *ngIf="newWorkspaceForm.get('name')?.hasError('onlyNumbers')"
        i18n="@@workspaceNameOnlyNumbers">
        Team name cannot be only numbers.
      </app-form-control-error>
      <app-form-control-error
        [control]="newWorkspaceForm.get('name')"
        [show]="newWorkspaceForm.get('name')?.hasError('maxlength')"
        *ngIf="newWorkspaceForm.get('name')?.hasError('maxlength')"
        i18n="@@workspaceNameMaxLength">
        Team name is too long.
      </app-form-control-error>
      <app-form-control-error
        [control]="newWorkspaceForm.get('name')"
        [show]="newWorkspaceForm.get('name')?.hasError('empty')"
        *ngIf="newWorkspaceForm.get('name')?.hasError('empty')"
        i18n="@@workspaceNameEmpty">
        Team name is required.
      </app-form-control-error>
      <app-form-control-error
        [control]="newWorkspaceForm.get('name')"
        [show]="newWorkspaceForm.get('name')?.hasError('unique')"
        *ngIf="newWorkspaceForm.get('name')?.hasError('unique')"
        i18n="@@workspaceNameUnique">
        Team with this name already exists.
      </app-form-control-error>
    </ng-container>
  </div>
  <app-button
    data-qa-id="create-team-step-create-workspace-button"
    className="btn-primary"
    [disabled]="creatingWorkspace || newWorkspaceForm?.invalid"
    (click)="createWorkspace()"
    i18n="@@createWorkspace">
    Create workspace
  </app-button>
</form>
