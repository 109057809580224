import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PuiIconModule } from '@awarenow/profi-ui-core';
import { UiChipListInput } from './chip-list-input';
import { UiChip } from './chip';
import { UiAutocompleteModule } from '../ui-autocomplete';
import { UiChipList } from './chip-list';

@NgModule({
  declarations: [UiChipList, UiChip, UiChipListInput],
  imports: [
    CommonModule,
    MatChipsModule,
    FormsModule,
    UiAutocompleteModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    PuiIconModule
  ],
  exports: [UiChipList, UiChip, UiChipListInput]
})
export class UiChipListModule {}
