import { INote, MetaShared, INotesMetaRecording, IMetaEvent } from '@app/shared/interfaces/notes';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { User } from '@app/shared/interfaces/user';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IModuleMeta {
  moduleTitle: string | null;
  serviceId?: number | null;
  serviceName?: string;
  guide?: {
    guideId: number;
    firstName: string;
    lastName: string;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INotesMetaProgramModules {
  programId: number;
  programName: string;
  authorId: number;
  moduleType: ModuleTypes;
  moduleId: number;
  moduleMeta: IModuleMeta;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientNote extends INote {
  metaProgramModules?: INotesMetaProgramModules;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGetAdjacentNotesParams {
  delta?: number;
  id: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientAdjacentNotesResponse {
  notes: IClientNote[];
  leftCursor: string;
  rightCursor: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGetClientNotesParams {
  direct?: boolean;
  cursor?: string | null;
  limit?: number;
  pinned?: boolean;
  search?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientNotesResponse {
  notes: IClientNote[];
  cursor: string | null;
}

export class ModuleMeta {
  moduleTitle: string | null;

  serviceId?: number | null;

  serviceName?: string;

  guide?: User;

  constructor({ moduleTitle, serviceId, serviceName, guide }: IModuleMeta) {
    this.moduleTitle = moduleTitle;
    if (serviceId) {
      this.serviceId = serviceId;
    }
    if (serviceName) {
      this.serviceName = serviceName;
    }
    if (guide) {
      const { guideId, firstName, lastName } = guide;
      this.guide = new User(guideId, firstName, lastName);
    }
  }
}

export class ProgramModulesNoteMeta {
  programId: number;

  programName: string;

  authorId: number;

  moduleType: ModuleTypes;

  moduleId: number;

  moduleMeta: ModuleMeta;

  constructor({ programId, programName, authorId, moduleType, moduleId, moduleMeta }: INotesMetaProgramModules) {
    this.programId = programId;
    this.programName = programName;
    this.authorId = authorId;
    this.moduleType = moduleType;
    this.moduleId = moduleId;
    this.moduleMeta = new ModuleMeta(moduleMeta);
  }
}

export class ClientNote {
  readonly id: number;

  createdAt: string;

  updatedAt: string;

  pinned: boolean;

  pinnedAt: string | null;

  content: string;

  contentDeltaFormat: string;

  contentText: string;

  metaSharedWith: MetaShared[];

  metaSharedBy: MetaShared;

  metaProgramModules?: ProgramModulesNoteMeta;

  metaRecording?: INotesMetaRecording;

  metaEvent: IMetaEvent;

  constructor({
    id,
    content,
    contentDeltaFormat,
    contentText,
    createdAt,
    updatedAt,
    pinned,
    pinnedAt,
    metaSharedWith,
    metaSharedBy,
    metaProgramModules,
    metaRecording,
    metaEvent
  }: IClientNote) {
    this.id = id;
    this.content = content;
    this.contentDeltaFormat = contentDeltaFormat;
    this.contentText = contentText;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.pinned = pinned;
    this.pinnedAt = pinnedAt;
    this.metaSharedWith = metaSharedWith.map(meta => new MetaShared(meta));
    this.metaSharedBy = new MetaShared(metaSharedBy);
    if (metaProgramModules) {
      this.metaProgramModules = new ProgramModulesNoteMeta(metaProgramModules);
    }
    this.metaRecording = metaRecording;
    // @ts-expect-error TS2322
    this.metaEvent = metaEvent;
  }
}
