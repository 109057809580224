import { FormlyFieldConfig } from '@ngx-formly/core';
import { Color, Palette } from '@app/modules/ui-kit/kit-branding/interfaces';
import { LIGHT_THEME_BACKGROUND_PALETTE, LIGHT_THEME_FOREGROUND_PALETTE } from '@app/modules/ui-kit/kit-branding/const';

export interface ThemeConfiguration {
  palettes: Palette[];
  defaults: { [key: string]: string };
}

export const preparePalettes = (palettes: { [key: string]: string }[]): Palette[] => {
  return palettes.map((colors, index) => ({
    name: index ? 'foreground' : 'background',
    colors: prepareColors(colors)
  }));
};

export const prepareColors = (palette: { [key: string]: string }): Color[] =>
  Object.entries(palette).map(([name, hex]) => ({
    hex,
    name
  }));

export const CONFIGURATOR_SCHEMA_CONFIG: FormlyFieldConfig[] = [
  {
    key: 'palettes',
    type: 'list',
    templateOptions: {
      label: 'Available palettes',
      flexDirection: 'row'
    },
    fieldArray: {
      fieldGroupClassName: 'column',
      fieldGroup: [
        {
          key: 'paletteName',
          type: 'input',
          templateOptions: {
            disabled: true
          }
        },
        {
          key: 'color',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              key: 'colorHex',
              type: 'ngx-color-picker',
              className: 'col'
            },
            {
              key: 'colorName',
              type: 'ui-select',
              className: 'col',
              wrappers: ['form-field'],
              templateOptions: {
                label: 'Color tags',
                options: prepareColors(LIGHT_THEME_BACKGROUND_PALETTE),
                labelProp: 'name',
                valueProp: 'name',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                change: (field: FormlyFieldConfig, event?: any) => {
                  // @ts-expect-error TS2532
                  const conf = field.options.formState.configuration;
                  const paletteName = field?.parent?.parent?.model?.paletteName;

                  const pInx = paletteName === 'background' ? 0 : 1;
                  // @ts-expect-error TS7006
                  const color = conf.palettes[pInx].colors.find(color => color.name === event.value);
                  // @ts-expect-error TS2532
                  field.parent.formControl.get('colorHex').setValue(color.hex);
                }
              },
              expressionProperties: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                'templateOptions.options': (model: any, formState: any, field?: FormlyFieldConfig) => {
                  const paletteName = field?.parent?.parent?.model?.paletteName;
                  return paletteName === 'background'
                    ? prepareColors(LIGHT_THEME_BACKGROUND_PALETTE)
                    : prepareColors(LIGHT_THEME_FOREGROUND_PALETTE);
                }
              }
            }
          ]
        }
      ]
    }
  },
  {
    key: 'defaults',
    fieldGroupClassName: 'd-flex m-3',
    fieldGroup: [
      {
        key: 'primary',
        type: 'ngx-color-picker',
        templateOptions: {
          label: 'Default Primary'
        },
        className: 'col'
      },
      {
        key: 'warn',
        type: 'ngx-color-picker',
        templateOptions: {
          label: 'Default Warning'
        },
        className: 'col'
      }
    ]
  }
];
