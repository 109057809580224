import { Injectable } from '@angular/core';
import { IGuideScheduleRange } from './types';

@Injectable()
export abstract class ScheduleDatesStore {
  abstract add(scheduleRanges: IGuideScheduleRange[], tz?: string): void;

  abstract buildDateKey(year: number, month: number, day: number, zoneName?: string): string;

  abstract get(dateKey: string): IGuideScheduleRange[];

  abstract has(dateKey: string): boolean;

  abstract reset(): void;
}
