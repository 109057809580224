import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PATH } from '@app/shared/constants/endpoints';
import { AuthService } from '@app/core/auth/services';
import { HttpClient } from '@angular/common/http';
import { PackageIncludes } from '@app/modules/package/types/package-session-template';

/**
 * [Includes] - [Session template]'s inside any [Service]
 */
@Injectable()
export class GuideServiceIncludesService {
  get endpoint(): string {
    return `${API_PATH}/user/${this.auth.getUserRole()}/packages`;
  }

  constructor(private readonly auth: AuthService, private readonly http: HttpClient) {}

  removeIncludes$(packageId: number, includesId: number): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/${packageId}/includes/${includesId}`);
  }

  updateIncludes$(packageId: number, sessionInfo: PackageIncludes): Observable<void> {
    return this.http.put<void>(`${this.endpoint}/${packageId}/includes/${sessionInfo.sessionTemplateId}`, {
      includes: sessionInfo
    });
  }

  appendIncludes$(packageId: number, sessionInfo: PackageIncludes): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/${packageId}/includes`, { includes: sessionInfo });
  }
}
