<div [hidden]="isLoading" class="notes-board">
  <div *ngIf="notes.length; else emptyNotes" class="notes-container">
    <ng-scrollbar class="notes-container-scrollbar" appBottomScroll (bottomScrolled)="onBottomScrolled()">
      <div class="notes-list" *ngFor="let note of notes; index as i; trackBy: notesTrackBy">
        <app-note-card
          [noteIndex]="i"
          [note]="note"
          [clientId]="clientId"
          [hideShare]="hideSharing"
          (deleteNote)="noteDelete.emit($event)"
          (shareNote)="grantViewerAccess.emit($event)"
          (updateNote)="noteUpdate.emit($event)"
          (endEditing)="noteEndEditing.emit($event)"
          (revokeViewerAccess)="revokeViewerAccess.emit($event)"
          (manageAccess)="manageAccess.emit($event)"
          (pinNote)="pinNote.emit($event)"
          (openSharingSettings)="openSharingSettings($event)"
          (unpinNote)="unpinNote.emit($event)">
          <app-guide-note-card-meta class="meta" [note]="note"></app-guide-note-card-meta>
        </app-note-card>
      </div>
    </ng-scrollbar>
  </div>
  <pui-alert *ngIf="!isAssigned" type="warning" class="assign-error">
    <pui-icon size="s" svgIcon="pui:pin"></pui-icon>
    <span i18n="@@guideNotesBoardWriteNoteForClient">To write a note for this client,</span>
    <span i18n="@@guideNotesBoardAddYourselfLink" class="link" (click)="toDetailsTab()">add yourself</span>
    <span i18n="@@guideNotesBoardToAssignTeamMembers">to the assigned team members.</span>
  </pui-alert>
  <div class="add-new-note">
    <app-text-editor-notes
      #addNoteEditor
      data-qa-id="guide-notes-board-note-editor"
      [disabled]="!isAssigned"
      [focusToTextField$]="focusToTextField$"
      (endEditing)="createNote($event)"
      [emptyNotePreview]="emptyNotePreview"
      [clamped]="true"></app-text-editor-notes>

    <ng-template #emptyNotePreview>
      <div
        data-qa-id="guide-notes-board-empty-note-editor"
        pui-typography="body-s"
        class="empty-note"
        [class.disabled]="!isAssigned">
        <span i18n="@@guideNotesBoardTakeNote">Take a note...</span>
      </div>
    </ng-template>
  </div>
</div>

<!-- EMPTY NOTES BLOCK -->
<ng-template #emptyNotes>
  <div class="empty-notes">
    <pui-icon data-qa-id="guide-notes-board-no-notes-file-icon" svgIcon="pui:file" size="xl" class="icon"></pui-icon>
    <div data-qa-id="guide-notes-board-no-notes-title" i18n="@@guideNotesBoardNoNotes" class="no-notes">
      No notes added yet
    </div>
    <div
      *ngIf="isAssigned"
      data-qa-id="guide-notes-board-create-a-new-one-button"
      i18n="@@guideNotesBoardCreateNewNote"
      class="create-new"
      (click)="createNew()">
      Create a new one
    </div>
  </div>
</ng-template>

<!-- LOADING BLOCK -->
<div *ngIf="isLoading">...Loading</div>
