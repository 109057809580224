import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { IClientGuideSession } from '../../types/types';

@Component({
  selector: 'app-client-session-invitations',
  templateUrl: './client-session-invitations.component.html',
  styleUrls: ['../../styles/sessions.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionInvitationsComponent {
  @Input()
  // @ts-expect-error TS2564
  invitations: IClientGuideSession[];

  @Input()
  hideHeader = false;

  @HostBinding('class.new')
  get hasRequests(): boolean {
    return !!this.invitations && this.invitations.length > 0;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get sessionStatuses() {
    return SessionStatuses;
  }
}
