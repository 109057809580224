<div>
  <ng-container *ngIf="all && value">
    <div *ngFor="let id of value; index as i" class="element" [ngbTooltip]="getTooltipText(named[id])">
      <span class="element-name"
        >{{ named[id].name || ' ' }}
        <span *ngIf="named[id]?.subtitle" class="element-subtitle">({{ named[id].subtitle }})</span>
      </span>
      <span (click)="delete(i)" class="element-delete"></span>
    </div>
  </ng-container>

  <div *ngIf="value.length < limit" class="d-inline-block button">
    <app-button *ngIf="!buttonTemplate" className="btn btn-add" (click)="openModal()">{{ caption }}</app-button>

    <ng-container
      *ngIf="buttonTemplate"
      [ngTemplateOutlet]="buttonTemplate"
      [ngTemplateOutletContext]="{ action: openModal.bind(this) }"></ng-container>
  </div>
</div>
