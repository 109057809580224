import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type SERVICE_VALUES =
  | 'email'
  | 'footer'
  | 'group_session_landing_page'
  | 'individual_session_landing_page'
  | 'package_landing_page'
  | 'program_landing_page'
  | 'widget';

@Component({
  selector: 'app-powered-by-link',
  templateUrl: './powered-by-link.component.html',
  styleUrls: ['./powered-by-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoweredByLinkComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _utmCampaign$ = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _utmHost$ = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _utmMedium$ = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _utmSource$ = new BehaviorSubject<string>('powered_by');

  domain = 'https://www.profi.io';

  // @ts-expect-error TS2564
  link: string;

  @Input()
  set utmCampaign(value: SERVICE_VALUES) {
    this._utmCampaign$.next(`powered_by_link_in_${value}`);
  }

  @Input()
  set utmHost(value: string) {
    this._utmHost$.next(`practitionerId-${value}`);
  }

  @Input()
  set utmMedium(value: string | number) {
    this._utmMedium$.next(value?.toString());
  }

  @Input()
  set utmSource(value: string) {
    this._utmSource$.next(value);
  }

  @Input() background = true;
  @HostBinding('style.background') private get backgroundColor(): string {
    return this.background ? '#fff' : 'none';
  }

  constructor(private readonly _router: Router, private readonly _serializer: UrlSerializer) {}

  ngOnInit(): void {
    combineLatest([this._utmCampaign$, this._utmHost$, this._utmMedium$, this._utmSource$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.generateLink());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateLink(): void {
    const tree = this._router.createUrlTree(['/'], {
      queryParams: {
        ...(this._utmCampaign$.getValue() && { utm_campaign: this._utmCampaign$.getValue() }),
        ...(this._utmHost$.getValue() && { utm_host: this._utmHost$.getValue() }),
        ...(this._utmMedium$.getValue() && { utm_medium: this._utmMedium$.getValue() }),
        ...(this._utmSource$.getValue() && { utm_source: this._utmSource$.getValue() })
      }
    });

    this.link = `${this.domain}${this._serializer.serialize(tree)}`;
  }
}
