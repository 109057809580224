import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Component({
  selector: 'app-service-type',
  templateUrl: './service-type.component.html',
  styles: [
    `
      .package-service-type,
      .group-session-service-type,
      .session-service-type,
      .program-service-type {
        /*!NOTICE used in different wrap components*/
        font-size: inherit;
      }

      .capitalized {
        display: inline-block;
        text-align: center;
      }

      .capitalized:first-letter {
        text-transform: uppercase;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'service-type'
  }
})
export class ServiceTypeComponent {
  readonly GuideServiceTypes = GuideServiceTypes;

  @Input()
  type!: GuideServiceTypes;

  @Input()
  capitalized!: boolean;
}
