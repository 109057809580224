import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

@Injectable()
export class IdGuard implements CanActivate {
  constructor(private _router: Router) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (route.params.id && !isNaN(parseInt(route.params.id.split('-')[0], 10))) {
      return true;
    }

    return this._router.parseUrl('/dashboard');
  }
}
