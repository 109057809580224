import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-share-note-confirm-modal',
  templateUrl: './share-note-confirm-modal.component.html',
  styleUrls: ['./share-note-confirm-modal.component.scss', '../../../../../scss/_event-modal.scss']
})
export class ShareNoteConfirmModalComponent {
  // @ts-expect-error TS2564
  @Input() name: string;
  // @ts-expect-error TS2564
  @Input() photo: string;
  // @ts-expect-error TS2564
  @Input() email: string;

  constructor(public modal: NgbActiveModal) {}

  save(): void {
    const response = true;
    this.modal.close(response);
  }

  cancel(): void {
    const response = false;
    this.modal.close(response);
  }
}
