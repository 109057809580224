import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() className = '';
  @Input() disabled = false;
  @Input() buttonType = 'button';
  // @ts-expect-error TS2564
  @Input() qaId: string;
  @Input() formName = '';
}
