import { NgScrollbar } from 'ngx-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { BlogItemTypes, IArticleSelection, IPersonalBlog } from '@app/screens/blog/types';
import { PublicProfile } from '@app/screens/guide-public-profile/guide-public-profile.component';
import { GlobalConfig } from '@cnf/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private reviewsBlockRef!: ElementRef;
  private shouldScrollToReviewsBlock!: boolean;
  itemTypes = BlogItemTypes;

  config = {
    hideProfileTags: true,
    hideProfileSalesText: true,
    hideProfileLocationLanguage: true,
    hideProfileQualifications: true,
    hideCoachReviews: true
  };

  @Input()
  profile: PublicProfile | {} = {};

  @Input()
  mobile = false;

  @Input()
  blog!: IPersonalBlog;

  @Input()
  reviewReadySessionId!: number;

  @Input()
  set shouldScrollToReviews(shouldScrollToReviewsBlock: boolean) {
    this.shouldScrollToReviewsBlock = shouldScrollToReviewsBlock;

    if (!!this.reviewsBlockRef && this.shouldScrollToReviewsBlock) {
      this.scrollToReviewsBlock();
    }
  }

  @Output()
  leaveReview = new EventEmitter<number>();

  @Output()
  loadMoreReviews = new EventEmitter<void>();

  @Output()
  openBlogItem = new EventEmitter<IArticleSelection>();

  @ViewChild('reviewsBlock', { static: false })
  set reviewsBlock(reviewsBlockRef: ElementRef) {
    this.reviewsBlockRef = reviewsBlockRef;

    if (this.shouldScrollToReviewsBlock) {
      this.scrollToReviewsBlock();
    }
  }

  @ViewChild(NgScrollbar, { static: true })
  private scrollbarRef!: NgScrollbar;

  get hasBlogTypeArticle(): boolean {
    return this.blog.items.some(item => item.type === this.itemTypes.ARTICLE);
  }

  constructor(private _brandingService: BrandingService, private _location: Location) {}

  ngOnInit(): void {
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  emitLeaveReview(): void {
    this.leaveReview.emit(this.reviewReadySessionId);
  }

  scrollToReviewsBlock(): void {
    this._location.replaceState(`${this._location.path().split('#').shift()}#reviews`);
    this.reviewsBlockRef?.nativeElement?.scrollIntoView();
  }

  private onScrollUpdate(event: Event): void {
    if (event) {
      const target = event.target as HTMLElement;
      if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
        this.loadMoreReviews.emit();
      }
    }
  }

  private setConfig({
    hideProfileTags,
    hideProfileSalesText,
    hideProfileLocationLanguage,
    hideProfileQualifications,
    hideCoachReviews
  }: GlobalConfig): void {
    this.config.hideProfileTags = hideProfileTags;
    this.config.hideProfileSalesText = hideProfileSalesText;
    this.config.hideProfileLocationLanguage = hideProfileLocationLanguage;
    this.config.hideProfileQualifications = hideProfileQualifications;
    this.config.hideCoachReviews = hideCoachReviews;
  }
}
