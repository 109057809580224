// eslint-disable-next-line max-classes-per-file
import { ChangeDetectionStrategy, Component, Directive, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

@Directive({
  selector: 'ui-card-content, [ui-card-content], [uiCardContent]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-content' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardContent {}

@Directive({
  selector: `ui-card-title, [ui-card-title], [uiCardTitle]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-card-title'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardTitle {}

@Directive({
  selector: `ui-card-time, [ui-card-time], [uiCardTime]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-card-time',
    '[class.ui-time-align-end]': 'align === "end"'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardTime {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
}

@Directive({
  selector: `ui-card-subtitle, [ui-card-subtitle], [uiCardSubtitle]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-card-subtitle'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardSubtitle {}

@Directive({
  selector: 'ui-card-actions',
  exportAs: 'uiCardActions',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-card-actions',
    '[class.ui-card-actions-align-end]': 'align === "end"'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardActions {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
}

@Directive({
  selector: 'ui-card-footer',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-footer' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardFooter {}

@Directive({
  selector: '[ui-card-image], [uiCardImage]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-image' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardImage {}

@Directive({
  selector: '[ui-card-sm-image], [uiCardImageSmall]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-sm-image' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardSmImage {}

@Directive({
  selector: '[ui-card-md-image], [uiCardImageMedium]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-md-image' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardMdImage {}

@Directive({
  selector: '[ui-card-lg-image], [uiCardImageLarge]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-lg-image' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardLgImage {}

@Directive({
  selector: '[ui-card-xl-image], [uiCardImageXLarge]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-xl-image' }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardXlImage {}

@Directive({
  selector: 'ui-card-avatar, [ui-card-avatar], [uiCardAvatar]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-card-avatar',
    '[class.square]': 'shape === "square" '
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiCardAvatar {
  @Input() shape: 'square' | 'circle' = 'circle';
}

@Component({
  selector: 'ui-card-header',
  templateUrl: 'card-header.html',
  styleUrls: ['card-header.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-header' }
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiCardHeader {
  @Input() mode: 'full' | 'short' | undefined;
}

@Component({
  selector: 'ui-card-title-group',
  templateUrl: 'card-title-group.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-card-title-group' }
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiCardTitleGroup {}

@Component({
  selector: 'ui-card',
  exportAs: 'uiCard',
  templateUrl: 'card.html',
  styleUrls: ['card.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-card ui-focus-indicator',
    '[class._mat-animation-noopable]': '_animationMode === "NoopAnimations"'
  }
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiCard {
  constructor(@Inject(ANIMATION_MODULE_TYPE) public _animationMode: string) {}
}
