import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PuiAvatarSizes } from '@awarenow/profi-ui-core';
import { Avatar } from '@awarenow/profi-ui-core/lib/components/avatar/pui-avatar.component';

@Component({
  selector: 'avatar-group',
  exportAs: 'avatarGroup',
  templateUrl: './avatar-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarGroupComponent {
  @Input()
  max = 3;

  @Input()
  size: PuiAvatarSizes = 'l';

  @Input()
  items: Avatar[] = [];

  open = false;

  trackByAvatarUrl(index: number, avatar: Avatar): string {
    // @ts-expect-error TS2322
    return avatar.avatarUrl;
  }

  setOpen(state: boolean): void {
    this.open = state && this.open;
  }
}
