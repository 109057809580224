import { IClientGuideAvailableSession } from '@app/screens/client/client-dashboard/types/types';
import { SessionsStack } from './stack-sessions-by.pipe';
import { GuideServiceParent } from '@app/modules/book-service';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';
import { Session, SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributesEnum, ClientSessionTimeAttribute } from '@libs/business/client/client-session/modules';
import { DateTime } from 'luxon';

const EXPIRACY_TIME = 72;

export const stackSessionsByType = (
  sessions: Session<IClientGuideAvailableSession, GuideServiceParent>[],
  platformStyle: PlatformStyle
): SessionsStack[] => {
  return Object.values(
    sessions.reduce((stacks, session) => {
      const stackId = session.templateId;
      if (!stackId) {
        return stacks;
      }

      let attribute: ClientSessionTimeAttribute | null = null;
      const expiresAt = session.parent?.expiresAt;

      if (expiresAt) {
        const expiresAtDate = DateTime.fromISO(expiresAt);
        const today = DateTime.now();
        const attentionInterval = EXPIRACY_TIME * 3600000;

        const expiresAtLabel =
          `Use before` + ' ' + platformStyle.datetime(expiresAt, 'DATE', { hideDay: false, hideLastYear: true });

        if (expiresAtDate.toMillis() - today.toMillis() < attentionInterval) {
          attribute = {
            type: ClientSessionAttributesEnum.TIME,
            leftIcon: SessionAttributeIconEnum.WARNING,
            leftIconAppearance: 'warning',
            label: expiresAtLabel
          };
        } else {
          attribute = {
            type: ClientSessionAttributesEnum.TIME,
            leftIcon: SessionAttributeIconEnum.CALENDAR,
            leftIconAppearance: 'default',
            label: expiresAtLabel
          };
        }
      }

      const stack: SessionsStack = stacks[stackId] || {
        id: stackId,
        title: session.title,
        count: 0,
        attribute,
        firstSession: session
      };

      return {
        ...stacks,
        [stack.id]: {
          ...stack,
          count: session.available
        } as SessionsStack
      };
    }, {} as Record<number, SessionsStack>)
  );
};
