<div class="row-m0">
  <div class="col-md-8 content-col">
    <div class="links">
      <a [routerLink]="['/wiki']" class="wiki-link" i18n="@@companyWiki">{{ platformName }} wiki</a>
    </div>
    <h4 class="wiki-name">{{ wiki?.name }}</h4>
    <div class="wiki-description" appLineClamp [buttonRef]="button" #c="appLineClamp" [lines]="3">
      <div [innerHTML]="wiki?.description"></div>
      <ng-template #button>
        <button (click)="c.open()" class="open">...</button>
      </ng-template>
    </div>
    <h6 class="wiki-tags-title" i18n="@@canBeHelpfulWith">Can be helpful with</h6>
    <div class="wiki-tags" *ngIf="wiki?.tags && wiki.tags.length">
      <a *ngFor="let tag of wiki.tags" [routerLink]="['./', { tags: tag.name }]" class="wiki-tag">{{ tag.name }}</a>
    </div>
  </div>
  <div class="col-md-4 wiki-blog-container">
    <div class="links">
      <a [routerLink]="['/blog']" class="wiki-link" i18n="@@companyBlog">{{ platformName }} blog</a>
    </div>
    <div class="wiki-blogs">
      <a
        class="wiki-blog"
        *ngFor="let blog of wiki?.blogs"
        [routerLink]="['/blog', blog.author.namedUrl, 'posts', blog.id]">
        <div class="wiki-blog-cover" [style.backgroundImage]="blog.coverImage | safe: 'styleUrl'"></div>
        <div class="wiki-blog-title">{{ blog.title }}</div>
      </a>
    </div>
  </div>
</div>
