import { Pipe, PipeTransform } from '@angular/core';
import { PaymentTypes } from '@app/shared/enums/payment-types';

const paymentTypesNames: { [key in PaymentTypes]: string } = {
  [PaymentTypes.PAYMENT]: `Payment`,
  [PaymentTypes.PAYPAL_PAYMENT]: 'Paypal',
  [PaymentTypes.PREPAYMENT]: `Prepayment`,
  [PaymentTypes.REFUNDED_PAYMENT]: `Refunded payment`,
  [PaymentTypes.REFUNDED_PAYPAL_PAYMENT]: `Refunded paypal`,
  [PaymentTypes.REFUNDED_STRIPE_PAYMENT]: `Refunded stripe`,
  [PaymentTypes.REFUNDED_PREPAYMENT]: `Refunded prepayment`,
  [PaymentTypes.REFUNDED_WALLET_PAYMENT]: `Refunded wallet payment`,
  [PaymentTypes.REFUNDED_WALLET_PREPAYMENT]: `Refunded wallet prepayment`,
  [PaymentTypes.WALLET_PAYMENT]: `Wallet payment`,
  [PaymentTypes.WALLET_PREPAYMENT]: `Wallet prepayment`,
  [PaymentTypes.WITHDRAWAL]: `Withdrawal`,
  [PaymentTypes.STRIPE_PAYMENT]: 'Stripe'
};

@Pipe({
  name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {
  transform(paymentType: string): string {
    // @ts-expect-error TS7053
    return paymentTypesNames[paymentType] || paymentType;
  }
}
