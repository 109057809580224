// https://github.com/angular/components/tree/master/src/material/icon

import { NgModule } from '@angular/core';
import { UiIcon } from './icon';
import { MatCommonModule } from '@angular/material/core';

@NgModule({
  declarations: [UiIcon],
  exports: [UiIcon, MatCommonModule],
  imports: [MatCommonModule]
})
export class UiIconModule {}
