<div class="input-group book-link-wrapper">
  <input
    type="text"
    readonly
    class="form-control book-link"
    [value]="bookLink"
    (copy)="onBookLinkCopied(analyticCopyTypes.STANDARD)" />
  <div class="input-group-append" ngbDropdown>
    <button ngbDropdownToggle class="dropdown-toggle"></button>
    <div ngbDropdownMenu class="dropdown-menu">
      <button
        type="button"
        class="copy-btn"
        ngxClipboard
        [cbContent]="bookLink"
        (cbOnSuccess)="onBookLinkCopied(analyticCopyTypes.BUTTON)"
        (cbOnError)="onBookLinkCopyFailed()"
        i18n="@@copyLink">
        Copy link
      </button>

      <button class="embed-btn" (click)="openBookWidget()" i18n="@@addToWebsite">Add to website</button>
    </div>
  </div>
</div>
