import { Component, forwardRef, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import { ChatsFilesUploaderService } from '@app/core/chat/chats-files-uploader.service';
import { IStorageFileInfo } from '@app/core/files/types';
import { allowedMimeTypes, imageFileExtensions, restrictedFileExtensions } from '@app/core/uploader/types';
import { findExtensionByFilename } from '@app/modules/program/utils';

export enum UploadFileTypes {
  IMG = 'img',
  VIDEO = 'video',
  OTHER = 'other'
}

const MAX_FILE_SIZE = 20971520;

/**
 *  According functional related with client's files download and displaying validation errors
 *  Todo:
 *   - implement reactive form validation;
 *   - create file-upload.component;
 *   - stop using reactive forms to display static result (We now use forms not only to enter responses,
 *   but also to display responses that cannot be changed);
 *   - stop uploading files to AWS in file input 'change' callback (Use base64 form of file data to temporary
 *   displaying images instead of using aws bucket url. And research how can we display video preview);
 *   - move validation errors view into separate component (probably in file-upload.component)
 */

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-file-upload-answer',
  templateUrl: './quiz-file-upload-answer.component.html',
  styleUrls: ['./quiz-file-upload-answer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizFileUploadAnswerComponent),
      multi: true
    },
    ChatsFilesUploaderService
  ]
})
export class QuizFileUploadAnswerComponent implements ControlValueAccessor, OnDestroy, OnInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isDisabled: boolean;

  private destroy$ = new Subject<void>();

  readonly UploadFileTypes = UploadFileTypes;

  readonly allowedMimeTypes = allowedMimeTypes;

  form = this._formBuilder.group({
    id: [null],
    question: [null],
    type: [null],
    answer: this._formBuilder.group({ text: [null], questionId: [null], fileInfo: [null] })
  });

  isValidSize = true;

  isValidType = true;

  @Input()
  // @ts-expect-error TS2564
  key: number | null;

  get answerText(): string | null {
    return this.form.value.answer ? this.form.getRawValue().answer.text : '';
  }

  get answerFile(): IStorageFileInfo {
    const fileInfo = this.form.getRawValue().answer.fileInfo;
    return this.form.value.answer && fileInfo
      ? {
          ...fileInfo,
          location: fileInfo?.location || this.form.value.answer.text
        }
      : null;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get question(): string {
    return this.form.getRawValue().question || '';
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _uploader: ChatsFilesUploaderService,
    private readonly _notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // @ts-expect-error TS7006
  writeValue(question): void {
    this.form.patchValue(question, { emitEvent: false });
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line id-length
  onFileInputChange(e): void {
    const inputEl = (e as InputEvent).target;
    // @ts-expect-error TS2531
    const file: File | null = (inputEl as HTMLInputElement).files[0];

    if (!file) {
      return;
    }

    const { name, size, type } = file;

    if (type === '' && restrictedFileExtensions.includes(findExtensionByFilename(name))) {
      this.isValidType = false;
      // @ts-expect-error TS2531
      this.form.get('answer').reset();
      return;
    }

    if (type && !allowedMimeTypes.includes(type)) {
      this.isValidType = false;
      // @ts-expect-error TS2531
      this.form.get('answer').reset();
      return;
    }

    if (size > MAX_FILE_SIZE) {
      this.isValidSize = false;
      // @ts-expect-error TS2531
      this.form.get('answer').reset();
      return;
    }

    this._uploader
      .uploadFile$(file)
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.type === HttpEventType.Response) {
          // @ts-expect-error TS2339
          const { key, url, bucket, etag } = event.body;

          if (!url) {
            return;
          }

          this.isValidType = true;
          this.isValidSize = true;
          // @ts-expect-error TS2531
          this.form.controls.answer.get('text').setValue(url);
          // @ts-expect-error TS2531
          this.form.controls.answer.get('fileInfo').setValue({ bucket, etag, key, url, name, size, type });
          this._cdr.detectChanges();
        }
      });
  }

  getUploadFileType(src: string): UploadFileTypes | null {
    const extension = findExtensionByFilename(src);

    if (!extension) {
      return null;
    }

    if (this.isVideoFile(extension)) {
      return UploadFileTypes.VIDEO;
    }

    if (this.isImgFile(extension)) {
      return UploadFileTypes.IMG;
    }

    return UploadFileTypes.OTHER;
  }

  private isVideoFile(ext: string): boolean {
    return ext === 'mp4' || ext === 'webm' || ext === 'ogg' || ext === 'mov';
  }

  private isImgFile(ext: string): boolean {
    return imageFileExtensions.includes(ext);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};
}
