import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject } from 'rxjs';
import { finalize, map, mapTo, switchMap } from 'rxjs/operators';
import { GuideOfferApiService } from '@app/core/session/guide-offer-api.service';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { IGuideOfferDetails, IGuideOfferOptions, IGuideOfferService } from '../types';
import { GuideOfferModalComponent } from '../components/guide-offer-modal/guide-offer-modal.component';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

// @ts-expect-error TS2430
// eslint-disable-next-line @typescript-eslint/naming-convention
interface IOfferOptions extends IGuideOfferOptions {
  disableService?: boolean;
  hidePrice?: boolean;
  serviceParent?: { id: number | null; type: GuideServiceTypes | null };
}

@Injectable({ providedIn: 'root' })
export class GuideOfferService implements IGuideOfferService<IGuideOfferOptions, void> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _isOffering = false;
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _modalRef;

  constructor(protected readonly _offerApi: GuideOfferApiService, protected readonly _modal: NgbModal) {}

  // @ts-expect-error TS2416
  offer$({ serviceParent, ...offerOptions }: IOfferOptions): Observable<void> {
    if (this._isOffering) {
      return EMPTY;
    }

    this._isOffering = true;

    // @ts-expect-error TS2322
    return this.openOfferDetailsModal$(offerOptions).pipe(
      // NOTE: hack, later use serviceId instead of templateId
      map(({ serviceId, ...offerDetails }) => ({
        ...offerDetails,
        templateId: serviceId,
        clients: [offerDetails.client]
      })),
      switchMap(offerDetails => this._offerApi.makeOffer$({ ...offerDetails, serviceParent })),
      mapTo(null),
      finalize(() => this.cleanUp())
    );
  }

  protected openOfferDetailsModal$({
    disableService,
    hidePrice,
    ...offerOptions
  }: IOfferOptions): Observable<IGuideOfferDetails> {
    if (!this._modalRef) {
      this._modalRef = this._modal.open(GuideOfferModalComponent);
    }

    const onOfferDetailsChange$ = new Subject<IGuideOfferDetails>();

    const { componentInstance, result } = this._modalRef;

    componentInstance.offerOptions = offerOptions;
    componentInstance.disableService = disableService;
    componentInstance.hidePrice = hidePrice;

    componentInstance.onOfferDetailsChange = (offerDetails: IGuideOfferDetails) => {
      onOfferDetailsChange$.next(offerDetails);
      onOfferDetailsChange$.complete();
    };

    modalResultToObservable$(result)
      .pipe(finalize(() => onOfferDetailsChange$.complete()))
      .subscribe();

    return onOfferDetailsChange$;
  }

  protected cleanUp(): void {
    this._isOffering = false;

    if (this._modalRef) {
      this._modalRef.close();
      this._modalRef = null;
    }
  }
}
