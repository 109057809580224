<div class="mdl-header">
  <app-button className="close" (click)="cancel()"></app-button>
  <div class="user-photo-decoration">
    <!--    <app-user-photo [photoUrl]="session.user?.photo" size="113"></app-user-photo>-->
    <!--    TODO Avatar size 113x113 not exist!-->
    <pui-avatar
      class="app-user-photo"
      [avatarUrl]="session.user?.photo"
      [text]="session.user?.name"
      [style.width]="113"
      [style.widths]="113"></pui-avatar>
  </div>
  <p class="user-name">{{ session.user?.name }}</p>
</div>

<div class="mdl-body">
  <form [formGroup]="sessionFeedbackForm">
    <fieldset class="mark-group">
      <label class="mark-label" transloco="evaluateTheGuide">Please, evaluate the coach</label>
      <star-rating-control formControlName="mark"></star-rating-control>
      <p class="mark-description">
        <ng-container *ngIf="sessionFeedbackForm.controls['mark'].value === 1" i18n="@@review1star">
          well, it didn't work out at all
        </ng-container>
        <ng-container *ngIf="sessionFeedbackForm.controls['mark'].value === 2" i18n="@@review2star">
          I'm not satisfied
        </ng-container>
        <ng-container *ngIf="sessionFeedbackForm.controls['mark'].value === 3" i18n="@@review3star">
          it was okay
        </ng-container>
        <ng-container *ngIf="sessionFeedbackForm.controls['mark'].value === 4" i18n="@@review4star">
          I liked the session!
        </ng-container>
        <ng-container *ngIf="sessionFeedbackForm.controls['mark'].value === 5" i18n="@@review5star">
          I loved it, excellent session!
        </ng-container>
      </p>
      <app-form-control-error [control]="sessionFeedbackForm.controls['mark']" transloco="gradeYourGuide">
        You should grade your coach
      </app-form-control-error>
    </fieldset>

    <ng-container *ngIf="!privateOnly">
      <hr class="divider" />
      <fieldset class="review-group">
        <label for="feedback_form_comment" class="review-label" transloco="reviewWillHelp">
          This review will help others to see if this coach is the right fit for them
        </label>
        <textarea id="feedback_form_comment" formControlName="comment"></textarea>
        <app-form-control-error [control]="sessionFeedbackForm.controls['comment']" i18n="@@reviewTooLong">
          Your review is too long. Please, confine yourself with
          {{ MAX_COMMENT_LENGTH }} characters.
        </app-form-control-error>
      </fieldset>
    </ng-container>

    <hr class="divider" />
    <fieldset class="review-group">
      <label for="feedback_form_private_comment" class="review-label" transloco="privateFeedback">
        Private feedback for the {{ platformName }} team. Anything you'd like to share with the admin? Was the coach a
        good fit for you and provide quality service? How was your connection? Any way we can improve?
      </label>
      <textarea
        id="feedback_form_private_comment"
        class="private-comment"
        [ngClass]="{ 'bad-review': isBadReview() }"
        formControlName="privateComment"></textarea>
      <app-form-control-error [control]="sessionFeedbackForm.controls['privateComment']" i18n="@@privateReviewTooLong">
        Your private review is too long. Please, confine yourself with
        {{ MAX_COMMENT_LENGTH }} characters.
      </app-form-control-error>
      <div class="report-an-issue" *ngIf="isBadReview()" transloco="reportIssueWithGuide">
        Report an issue with this coach
      </div>
    </fieldset>
  </form>
</div>
<div class="mdl-footer">
  <app-button className="btn-lg btn-lite" (click)="sendFeedback()" i18n="@@submitYourReview">
    Submit your review
  </app-button>
</div>
