import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';

@Injectable()
export class EmbedDisqusCountScriptService {
  private readonly DISQUS_COUNT_SCRIPT_ID = 'dsq-count-scr';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly DISQUS_COUNT_SCRIPT_URl = `https://${environment.disqusId}.disqus.com/count.js`;

  constructor(
    private _renderer: Renderer2,
    // @ts-expect-error TS7006
    @Inject(DOCUMENT) private _document,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private _platformId
  ) {
    if (isPlatformBrowser(_platformId) && !this.doesScriptAlreadyEmbedded()) {
      this.appendScript();
    }
  }

  private appendScript(): void {
    const script = this._renderer.createElement('script');

    this._renderer.setProperty(script, 'id', this.DISQUS_COUNT_SCRIPT_ID);
    this._renderer.setProperty(script, 'src', this.DISQUS_COUNT_SCRIPT_URl);
    this._renderer.setProperty(script, 'type', 'text/javascript');
    script.async = true;

    this._renderer.appendChild(this._document.body, script);
  }

  private doesScriptAlreadyEmbedded(): boolean {
    return [].slice
      .call(this._document.getElementsByTagName('script'))
      .some(scriptElement => scriptElement['src'] === this.DISQUS_COUNT_SCRIPT_URl);
  }
}
