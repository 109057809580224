import { ChatUpdateTypes, Chat } from '@app/core/chat/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ActiveChat<T, U> extends Chat<any, U> {
  messages: T[];

  updateType: ChatUpdateTypes | null;

  // @ts-expect-error TS7006
  constructor(chat, messages: T[], updateType?: ChatUpdateTypes | null) {
    super(chat, chat.users, chat.draft);
    this.messages = messages;
    this.updateType = updateType || null;
  }
}
