import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverImageComponent } from './components/cover-image/cover-image.component';
import { SharedModule } from '@app/shared';
import { PuiButtonModule, PuiIconModule, PuiInputFileModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { CoverImageWrapperComponent } from './components/cover-image-wrapper/cover-image-wrapper.component';

@NgModule({
  declarations: [CoverImageComponent, CoverImageWrapperComponent],
  exports: [CoverImageComponent, CoverImageWrapperComponent],
  imports: [CommonModule, SharedModule, PuiTypographyModule, PuiIconModule, PuiInputFileModule, PuiButtonModule]
})
export class CoverImageModule {}
