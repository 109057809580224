import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import config from '@app/core/config/config';
import { sortTags } from '@app/shared/utils/sort-tags';
import { ProgramEventType, ProgramStartType } from '@app/shared/interfaces/programs';
import { ILabelledProgramOption, IProgramOptions, IProgramGeneralOptions, ProgramLevel } from '../types';
import { createModuleActivationOption } from '../types/helpers';

@Injectable()
export class GuideProgramOptionsService implements OnDestroy {
  private readonly GENERAL_API_ENDPOINT = `${config.apiPath}/user/guide/profile/inserting-data`;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _options$: BehaviorSubject<IProgramOptions>;

  get options$(): Observable<IProgramOptions> {
    return this._options$.asObservable();
  }

  constructor(private _http: HttpClient) {
    this._options$ = new BehaviorSubject<IProgramOptions>({
      eventTypes: this.generateEventTypes(),
      languages: [],
      levels: this.generateLevels(),
      moduleActivations: this.generateModuleActivations(),
      startTypes: this.generateStartTypes(),
      tags: []
    });

    this.loadOptions$();
  }

  ngOnDestroy(): void {
    this._options$.complete();
  }

  private generateEventTypes(): ILabelledProgramOption<ProgramEventType>[] {
    return [
      { label: '30-min session', value: 'session30' },
      { label: '60-min session', value: 'session60' }
    ];
  }

  private generateModuleActivationValues(): ILabelledProgramOption<string>[] {
    const values = [
      createModuleActivationOption('Right after program start', 'deferral', 'P0D'),
      createModuleActivationOption('Immediately after enrollment', 'after_enrollment', null),
      createModuleActivationOption('On a specific date', 'specific_date', null)
    ];

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
    const createLabel = (v, vt) => `In ${v} ${vt + (v > 1 ? 's' : '')} after program start`;

    for (let day = 1; day < 7; day++) {
      values.push(createModuleActivationOption(createLabel(day, 'day'), 'deferral', `P${day}D`));
    }

    for (let week = 1; week < 13; week++) {
      values.push(createModuleActivationOption(createLabel(week, 'week'), 'deferral', `P${week}W`));
    }

    for (let month = 1; month < 13; month++) {
      values.push(createModuleActivationOption(createLabel(month, 'month'), 'deferral', `P${month}M`));
    }

    return values;
  }

  private generateLevels(): ILabelledProgramOption<ProgramLevel>[] {
    return [
      { value: 'beginner', label: 'beginner' },
      { value: 'intermediate', label: 'intermediate' },
      { value: 'advanced', label: 'advanced' }
    ];
  }

  private generateModuleActivations(): ILabelledProgramOption<string>[] {
    return [createModuleActivationOption('After completion of the previous module', 'sequence')].concat(
      this.generateModuleActivationValues()
    );
  }

  private generateStartTypes(): ILabelledProgramOption<ProgramStartType>[] {
    return [
      {
        value: 'user_enrollment',
        label: `immediately upon sign up`
      },
      {
        value: 'program_release',
        label: `on a specific date`
      }
    ];
  }

  private loadOptions$(): void {
    this._http.get<IProgramGeneralOptions>(this.GENERAL_API_ENDPOINT).subscribe(({ tags, ...generalOptions }) =>
      this._options$.next({
        ...this._options$.getValue(),
        ...generalOptions,
        tags: sortTags(tags)
      })
    );
  }
}
