import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ArrowLoaderComponent } from './arrow-loader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ArrowLoaderComponent],
  exports: [ArrowLoaderComponent]
})
export class ArrowLoaderModule {}
