import { DiffDetails, IDiff } from '../util-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuestionOptionDetails {
  id?: number;
  localId: number;
  text: string;
  isCorrect: boolean;
  explanation?: string;
  other?: boolean;
}

export class QuestionOption implements IDiff<IQuestionOptionDetails> {
  readonly id?: number;
  // @ts-expect-error TS2564
  readonly localId: number;
  // @ts-expect-error TS2564
  readonly text: string;
  // @ts-expect-error TS2564
  readonly isCorrect: boolean;
  readonly explanation?: string;
  readonly other?: boolean;

  constructor(optionDetails: Readonly<IQuestionOptionDetails>) {
    if (!optionDetails) {
      throw new Error('Invalid argument');
    }

    Object.entries(optionDetails).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
  }

  getDiffFrom(newOptionValue: Readonly<IQuestionOptionDetails>): DiffDetails<IQuestionOptionDetails> | null {
    const diff: Partial<Omit<IQuestionOptionDetails, 'localId' | 'id'>> = ['text', 'isCorrect', 'explanation'].reduce(
      // @ts-expect-error TS7053
      (patch, prop) => (this[prop] !== newOptionValue[prop] ? { ...patch, [prop]: newOptionValue[prop] } : patch),
      {}
    );

    if (!Object.keys(diff).length) {
      return null;
    }

    return {
      ...diff,
      id: this.id,
      localId: this.localId
    };
  }
}

export function cleanQuestionOptionDetails(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dirtyOption: Readonly<IQuestionOptionDetails> | Readonly<any>
): IQuestionOptionDetails {
  return {
    id: dirtyOption.id,
    localId: dirtyOption.localId,
    text: (dirtyOption.text && dirtyOption.text.trim()) || '',
    isCorrect: dirtyOption.isCorrect,
    explanation: (dirtyOption.explanation && dirtyOption.explanation.trim()) || null,
    other: dirtyOption.other
  };
}
