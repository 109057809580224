<div class="modal-header" [ngClass]="{ 'no-border': (isBilling$() | async) || !title }">
  <app-button
    data-qa-id="pay-with-modal-close-button"
    class="closeBtn"
    (click)="modal.dismiss()"
    className="close"></app-button>
  <h3 class="modal-title modal-title--{{ step$ | async }}">{{ title$() | async }}</h3>
</div>
<div class="modal-body">
  <ng-container [ngSwitch]="step$ | async">
    <ng-container *ngSwitchCase="steps.Billing">
      <app-billing-form-wrapper
        *ngIf="billingForm"
        [billingForm]="billingForm"
        [isModal]="true"
        [countries]="countries$ | async"
        (submitted)="handleBillingSubmit($event)"
        btnText="Submit"
        i18nBtnText="@@billingFormSubmit"></app-billing-form-wrapper>
    </ng-container>
    <ng-container *ngSwitchCase="steps.Payment">
      <app-pay-with-selector
        (paymentSelected)="handlePaymentSelected()"
        [submitButtonText]="submitButtonText"
        [disableNewCardSave]="disableNewCardSave"
        [disableSavedCards]="disableSavedCards"
        [isSubscription]="isSubscription"
        [hideWallet]="hideWallet"
        [hidePaymentLink]="hidePaymentLink"
        [isPlatformSubscription]="isPlatformSubscription"
        [disablePayPal]="disablePayPal"
        [amount]="amount"></app-pay-with-selector>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer" *ngIf="isPayment$() | async">
  <app-button data-qa-id="pay-with-modal-cancel-button" className="btn-link" (click)="modal.dismiss()" i18n="@@cancel">
    Cancel
  </app-button>
</div>
