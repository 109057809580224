import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

@Component({
  selector: 'app-sessions-blocked-modal',
  templateUrl: './sessions-blocked-modal.component.html',
  styleUrls: ['./sessions-blocked-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionsBlockedModalComponent {
  sessions = [];
  timezone: string;

  constructor(
    readonly dialogRef: PuiDialogRef,
    // @ts-expect-error TS7006
    @Inject(PUI_DIALOG_DATA) private readonly data,
    private platformStyle: PlatformStyle
  ) {
    this.timezone = this.data.timezone;

    // @ts-expect-error TS7006
    this.sessions = this.data.sessions.reduce((sessions, session) => {
      sessions = [...sessions, ...session];

      return sessions;
    }, []);

    this.sessions.forEach(session => {
      // @ts-expect-error TS2339
      session.range = this.platformStyle.datetimeRange([session.start, session.end], 'DATE, TIME', {
        timezone: this.timezone,
        showTimezone: true,
        hideWeekDay: true
      });
    });
  }
}
