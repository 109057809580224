import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { ClientGuide } from '@app/core/users/types';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';

import { ClientSessionsCommunicationsService } from '../../services/client-sessions-communications.service';
import { IClientGuideSession } from '../../types/types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

enum SessionActions {
  Hide = 'hide'
}

@Component({
  selector: 'app-past-client-session',
  templateUrl: './past-client-session.component.html',
  styleUrls: ['../../styles/session.scss', '../available-client-session/available-client-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastClientSessionComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _actions = [
    {
      id: SessionActions.Hide,
      label: `Hide`,
      class: 'remove'
    }
  ];

  @Input()
  // @ts-expect-error TS2564
  clientSession: IClientGuideSession;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get actions() {
    return this._actions;
  }

  get canBookAgain(): boolean {
    return !!this.clientSession && !!this.clientSession.guide && this.clientSession.guide.pricePerHour != null;
  }

  get isPackage(): boolean {
    // @ts-expect-error TS2532
    return this.clientSession.session.serviceParent.type === GuideServiceTypes.PACKAGE;
  }

  constructor(
    private _emitter: ClientSessionsCommunicationsService,
    private readonly _analyticsService: AnalyticsService
  ) {}

  emitSessionAction(actionId: string): void {
    switch (actionId) {
      case SessionActions.Hide:
        this._emitter.archiveSession(this.clientSession);
        break;
      default:
        break;
    }
  }

  reorder(): void {
    this._emitter.reorderSession(this.clientSession, AnalyticSourceTypes.PRACTITIONER);
  }

  buildDirectChatId(user: ClientGuide): string {
    return buildDirectChatLinkId(user);
  }
}
