<div class="modal-header">
  <h3 class="modal-title" i18n="@@billingFormModalTitle">
    To add paid service, please, enter your billing information
  </h3>
  <app-button className="close" (click)="cancel()"></app-button>
</div>
<div class="modal-body">
  <app-element-loading [loadingState]="!billingForm">
    <app-billing-form-wrapper
      *ngIf="billingForm"
      [billingForm]="billingForm"
      [countries]="countries$ | async"
      [isModal]="true"
      (submitted)="handleSubmit($event)"
      btnText="Submit"
      i18nBtnText="@@billingFormSubmit"></app-billing-form-wrapper>
  </app-element-loading>
</div>
