import { Pipe, PipeTransform } from '@angular/core';
import { GroupSessionModule } from '@app/screens/guide/guide-programs/types';

@Pipe({
  name: 'draftSessionTemplateTitle'
})
export class ModuleSessionDraftSessionTemplateTitlePipe implements PipeTransform {
  transform(programModule: GroupSessionModule): string {
    return programModule.sessionTemplateDraft?.name || 'Group session';
  }
}
