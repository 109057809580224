import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ViewHostCandidateMap } from '../types';

@Injectable()
export class ViewCandidateService extends BehaviorSubject<ViewHostCandidateMap> {
  constructor() {
    // @ts-expect-error TS2345
    super(null);
  }

  registerCandidates(candidates: ViewHostCandidateMap): void {
    const value = this.getValue();
    this.next({
      ...value,
      ...candidates
    });
  }
}
