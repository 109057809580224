<ng-container [ngSwitch]="type">
  <pui-badge
    *ngSwitchCase="GUIDE_SERVICE_TYPE.SESSION"
    class="pui-badge"
    shape="square"
    background="#3949AC"
    color="#fff"
    size="m"
    value="Individual session"></pui-badge>

  <pui-badge
    *ngSwitchCase="GUIDE_SERVICE_TYPE.GROUP_SESSION"
    class="pui-badge"
    shape="square"
    background="#10B393"
    color="#fff"
    size="m"
    value="Group session"></pui-badge>

  <pui-badge
    *ngSwitchCase="GUIDE_SERVICE_TYPE.PACKAGE"
    class="pui-badge"
    shape="square"
    background="#0299E2"
    color="#fff"
    size="m"
    value="Package"></pui-badge>

  <pui-badge
    *ngSwitchCase="GUIDE_SERVICE_TYPE.PROGRAM"
    class="pui-badge"
    shape="square"
    background="#ED7002"
    color="#fff"
    size="m"
    value="Program"></pui-badge>

  <pui-badge
    *ngSwitchDefault
    class="pui-badge"
    shape="square"
    background="#ED7002"
    color="#fff"
    size="m"
    value="Unknown Type"></pui-badge>
</ng-container>
