import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freeSessionOfferFormatter',
  pure: true
})
export class FreeSessionOfferFormatterPipe implements PipeTransform {
  transform(str: string, isFree: boolean): string {
    return isFree ? 'Free ' + str.toLowerCase() : str;
  }
}
