/**
 * @deprecated
 * NOTE: should be consistent with GuideServiceTypes until better solution found
 */
export enum SessionTypes {
  GROUP_SESSION = 'group_session',
  PACKAGE = 'package',
  PROGRAM = 'program',
  SESSION = 'session',
  GROUP_INSTANCE = 'group_instance'
}
