import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ISessionTimeFrame } from '@app/modules/book-session/services/types';
import { ITimezoneOption } from '@app/shared/utils/generate-timezones';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil, startWith, filter } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ngbCustomDateFormatterFactory } from '@app/shared/ng-bootstrap/ngb-custom-date-formatter-factory';
import { CUSTOM_NGB_DATE_PARSER_CONFIG } from '@app/shared/ng-bootstrap/ngb-custom-date-parser-formatter';
import { NgbDateTimeStringAdapter } from '@app/shared/ng-bootstrap/ngb-date-time-string-adapter';
import { IRecurrenceRule, isRecurrenceSetRule, RecurrenceSetItem } from '../../types';
import { areRecurrenceRulesEqual } from '../../utils/comparers';
import resolve from '@platformStyle/utils/resolve';
import { LocaleService } from '@app/core/locale/locale.service';
import { formatDate } from '@angular/common';
import { ILocale } from '@env/locale.interface';
import { ScheduleOptions } from '@app/screens/guide/guide-sessions/types';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IEventDate {
  date: string | null;
  timezone: string | null;
  recurrence?: IRecurrenceRule | null;
}

export enum RecurrenceTypes {
  DAILY,
  WEEKLY_ON_DAY,
  EVERY_WEEKDAY,
  MONTHLY,
  MONTHLY_ON_WEEKDAY
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const sortDateTimes = (firstDate: string, secondDate: string) => {
  const firsDateMillis = DateTime.fromISO(firstDate).toMillis();
  const seconDateMillis = DateTime.fromISO(secondDate).toMillis();

  return firsDateMillis > seconDateMillis ? 1 : firsDateMillis < seconDateMillis ? -1 : 0;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const convertTimeslotLabel = (date: DateTime, dateTimeLocale: string) => {
  return formatDate(date.toISO(), 'h:mma', dateTimeLocale, date.toFormat('ZZZ')).replace(/:00/g, '').toLowerCase();
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-event-date-selector',
  templateUrl: './event-date-selector.component.html',
  styleUrls: ['../../../../../scss/guide-services/radio.scss', './event-date-selector.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateTimeStringAdapter },
    { provide: CUSTOM_NGB_DATE_PARSER_CONFIG, useValue: resolve('DATE', 'toFormat') },
    {
      provide: NgbDateParserFormatter,
      useFactory: ngbCustomDateFormatterFactory,
      deps: [CUSTOM_NGB_DATE_PARSER_CONFIG, LocaleService]
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventDateSelectorComponent),
      multi: true
    }
  ]
})
export class EventDateSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  readonly RECURRENCE_TYPES = RecurrenceTypes;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _canRepeat = true;
  private destroy$ = new Subject();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isDisabled = false;
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _recurrences: { type: RecurrenceTypes; value }[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timeslots: ISessionTimeFrame[] = [];

  @Input()
  set canRepeat(value: boolean | null) {
    this._canRepeat = typeof value === 'boolean' ? value : true;

    if (this._canRepeat) {
      // @ts-expect-error TS2531
      this.form.get('recurrence').enable({ emitEvent: false });
    } else {
      // @ts-expect-error TS2531
      this.form.get('recurrence').disable({ emitEvent: false });
    }
  }

  @Input()
  set timeslots(value: ISessionTimeFrame[]) {
    this._timeslots = value || [];
    // @ts-expect-error TS2531
    const date = this.form.get('date').value;

    if (this._timeslots.length && !date) {
      // @ts-expect-error TS2531
      this.form.get('date').setValue(this._timeslots[0].value);
    }
  }

  @Input()
  template: ScheduleOptions['template'] | null = null;

  @Input()
  timezones: ITimezoneOption[] = [];

  @Output()
  dateChange = new EventEmitter<{ date: string; timezone?: string }>();

  form = this._formBuilder.group({
    isRecurring: [false],
    date: [{ value: null, disabled: true }],
    recurrence: [{ value: null, disabled: true }],
    recurrenceAfterCount: [{ value: 10, disabled: true }, [Validators.min(1), Validators.max(1000)]],
    recurrenceEndType: [{ value: 'until', disabled: true }],
    recurrenceUntilDate: [{ value: null, disabled: true }],
    timezone: [{ value: DateTime.local().zoneName, disabled: true }]
  });

  // @ts-expect-error TS2564
  date: string;
  doesRecurrenceEnd = false;

  // @ts-expect-error TS2531
  maxDate = this.form.get('date').valueChanges.pipe(
    // @ts-expect-error TS2531
    startWith(this.form.get('date').value as Date),
    // @ts-expect-error TS2531
    map(date => date || this.form.get('date').value),
    filter(i => i !== null),
    distinctUntilChanged(),
    map(
      date =>
        DateTime.fromISO(date)
          .plus({ months: this.runtimeConfig.get('availabilityMaxBoundaryInMonths') })
          .toObject() as NgbDateStruct
    )
  );

  minDate = DateTime.local().toObject() as NgbDateStruct;
  minUntilDate = DateTime.local().toObject() as NgbDateStruct;
  // @ts-expect-error TS2322
  locale: ILocale = null;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get canRepeat(): boolean {
    return this._canRepeat;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get recurrences() {
    return this._recurrences;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get timeslots(): ISessionTimeFrame[] {
    return this._timeslots;
  }

  get isRecurring(): boolean {
    // @ts-expect-error TS2531
    return this.form.get('isRecurring').value;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _localeService: LocaleService,
    private readonly runtimeConfig: RuntimeConfigService
  ) {
    this.locale = this._localeService.getLocale();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        take(1),
        distinctUntilChanged(),
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.destroy$),
        map(value => this.prepareControlValue(value))
      )
      .subscribe(this.writeValue.bind(this));

    this.form.valueChanges
      .pipe(
        map(value => this.prepareControlValue(value)),
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        this.onChange(value);
        this.onTouched();
      });

    // @ts-expect-error TS2531
    this.form
      .get('recurrenceEndType')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(recurrenceEndType => {
        if (recurrenceEndType === 'until') {
          // @ts-expect-error TS2531
          this.form.get('recurrenceAfterCount').disable({ emitEvent: false });
          // @ts-expect-error TS2531
          this.form.get('recurrenceUntilDate').enable({ emitEvent: false });
        } else {
          // @ts-expect-error TS2531
          this.form.get('recurrenceUntilDate').disable({ emitEvent: false });
          // @ts-expect-error TS2531
          this.form.get('recurrenceAfterCount').enable({ emitEvent: false });
        }
      });

    // @ts-expect-error TS2531
    this.form
      .get('timezone')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onDateChange(this.date));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDateChange(date: string | null): void {
    if (!date) {
      // @ts-expect-error TS2531
      this.form.get('recurrenceUntilDate').setValue(null, { emitEvent: false });
      this.minUntilDate = DateTime.local().toObject() as NgbDateStruct;
      return;
    }

    // @ts-expect-error TS2531
    const timezone = this.form.get('timezone').value;
    const dateWithZone = DateTime.fromISO(date).startOf('day').setZone(timezone, { keepLocalTime: true }).toISO();

    this.dateChange.emit({ date: dateWithZone, timezone });
    this.regenerateRecurrenceValuesForDate(dateWithZone);
    this.updateMinUntilDate(dateWithZone);
    // @ts-expect-error TS2531
    this.form.get('date').setValue(null);

    if (this.form.disabled) {
      this.form.enable();
    }
  }

  onDoesRecurrenceEndChange(doesRecurrenceEnd: boolean): void {
    if (doesRecurrenceEnd) {
      // @ts-expect-error TS2531
      this.form.get('recurrenceEndType').enable({ emitEvent: false });
      // @ts-expect-error TS2531
      this.form.get('recurrenceAfterCount').enable({ emitEvent: false });
      // @ts-expect-error TS2531
      this.form.get('recurrenceUntilDate').enable({ emitEvent: false });
    } else {
      // @ts-expect-error TS2531
      this.form.get('recurrenceEndType').disable({ emitEvent: false });
      // @ts-expect-error TS2531
      this.form.get('recurrenceAfterCount').disable({ emitEvent: false });
      // @ts-expect-error TS2531
      this.form.get('recurrenceUntilDate').disable({ emitEvent: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isNotWorkingDay = (date: NgbDate): boolean => {
    if (this.template === null) {
      return true;
    }
    const dateNumber = DateTime.fromObject(date).weekday;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !Object.keys(this.template).includes(`${dateNumber}`);
  };

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;

    // @ts-expect-error TS2531
    if (!this._isDisabled && this.form.get('date').value) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  writeValue(eventDate: IEventDate | null): void {
    // @ts-expect-error TS2322
    this.date = (eventDate && eventDate.date) || null;

    // NOTE: order matters, calculate inner recurrence values before parse recurrence from args
    if (this.date) {
      this.regenerateRecurrenceValuesForDate(this.date);
      this.updateMinUntilDate(this.date);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let formValue: any = { date: null, timezone: DateTime.local().zoneName };
    let recurrence = null;

    if (eventDate) {
      ({ recurrence, ...formValue } = eventDate);
    }

    // @ts-expect-error TS2531
    const isRecurring = this.form.get('isRecurring').value || recurrence?.length > 0;

    // @ts-expect-error TS2345
    formValue = { ...formValue, ...this.parseRecurrence(recurrence, isRecurring) };

    this.form.patchValue(formValue, { emitEvent: false });

    if (!this.doesRecurrenceEnd) {
      this.doesRecurrenceEnd =
        isRecurring && (formValue.recurrenceUntilDate != null || formValue.recurrenceEndType === 'after');
    }

    if (this.date) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private parseRecurrence(recurrence: RecurrenceSetItem[], isRecurring: boolean) {
    const defaultRecurrenceValues = {
      isRecurring,
      recurrence: null,
      recurrenceEndType: 'until',
      recurrenceAfterCount: 10,
      recurrenceUntilDate: null
    };

    const recurrenceRule = (recurrence && recurrence.find(it => isRecurrenceSetRule(it))) || null;

    if (!recurrenceRule || !isRecurrenceSetRule(recurrenceRule)) {
      return defaultRecurrenceValues;
    }

    const { until, count, ...rrule } = recurrenceRule.value;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formRecurrenceValues: any = {
      ...defaultRecurrenceValues,
      isRecurring: true,
      recurrence: this._recurrences.find(rec => areRecurrenceRulesEqual(rec.value, rrule)) || null
    };

    if (until) {
      formRecurrenceValues.recurrenceEndType = 'until';
      formRecurrenceValues.recurrenceUntilDate = until;
    } else if (count) {
      formRecurrenceValues.recurrenceEndType = 'after';
      formRecurrenceValues.recurrenceAfterCount = count;
    }

    return formRecurrenceValues;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private prepareControlValue(formValue) {
    const { recurrenceEndType, recurrenceAfterCount, recurrenceUntilDate, recurrence, isRecurring, ...controlValue } =
      formValue;

    if (!recurrence) {
      return controlValue;
    }

    if (!isRecurring) {
      return controlValue;
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value: { count, until, ...recurrenceValue }
    } = recurrence;

    if (this.doesRecurrenceEnd) {
      if (recurrenceEndType === 'until' && recurrenceUntilDate) {
        recurrenceValue.until = DateTime.fromISO(recurrenceUntilDate).endOf('day').toISO();
      } else if (recurrenceEndType === 'after' && recurrenceAfterCount) {
        recurrenceValue.count = recurrenceAfterCount;
      }
    }

    controlValue.recurrence = [{ type: 'rrule', value: recurrenceValue }];
    controlValue.isRecurring = true;

    return controlValue;
  }

  private regenerateRecurrenceValuesForDate(date: string | null): void {
    if (!date) {
      this._recurrences = [];
      return;
    }

    const dateTime = DateTime.fromISO(date);

    this._recurrences = [
      { type: RecurrenceTypes.DAILY, value: { freq: 'daily' } },
      {
        type: RecurrenceTypes.WEEKLY_ON_DAY,
        value: { freq: 'weekly', byday: [dateTime.weekday] }
      },
      { type: RecurrenceTypes.EVERY_WEEKDAY, value: { freq: 'weekly', byday: [1, 2, 3, 4, 5] } },
      { type: RecurrenceTypes.MONTHLY, value: { freq: 'monthly' } },
      {
        type: RecurrenceTypes.MONTHLY_ON_WEEKDAY,
        value: {
          freq: 'monthly',
          byday: [dateTime.weekday],
          bysetpos: [Math.ceil(dateTime.day / 7)]
        }
      }
    ];
  }

  private updateMinUntilDate(date: string): void {
    const dateTime = DateTime.fromISO(date);
    const minUntilJsDate = DateTime.fromObject(this.minUntilDate);

    if (minUntilJsDate.toString() !== dateTime.toString()) {
      this.minUntilDate = dateTime.toObject() as NgbDateStruct;
    }

    const recurrenceUntilDateControl = this.form.get('recurrenceUntilDate');
    const recurrenceUntilDateTime =
      recurrenceUntilDateControl && recurrenceUntilDateControl.value
        ? DateTime.fromISO(recurrenceUntilDateControl.value)
        : null;

    if (recurrenceUntilDateTime && recurrenceUntilDateTime < dateTime) {
      // @ts-expect-error TS2531
      recurrenceUntilDateControl.setValue(dateTime.plus({ days: 1 }).toISO(), { emitEvent: false });
    }
  }
}
