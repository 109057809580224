import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyService } from '@app/core/currency/currency.service';
import { makeUriFromString } from '@app/screens/blog/utils';
import { PublicPackage } from '@app/screens/packages/interfaces';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

import { PublicPackagesService } from '../../services/public-package.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-public-package',
  templateUrl: './public-package.component.html'
})
export class PublicPackageComponent implements OnInit, OnDestroy {
  readonly GuideServiceTypes = GuideServiceTypes;
  private package!: PublicPackage;
  private destroy$ = new Subject();
  isLoading = false;
  invitation: string | null = '';

  constructor(
    public currency: CurrencyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private packageService: PublicPackagesService
  ) {}

  ngOnInit(): void {
    const packageId$: Observable<number> = this.route.paramMap.pipe(
      // @ts-expect-error TS2531
      map(params => +params.get('id').split('-').shift()),
      takeUntil(this.destroy$)
    );

    packageId$
      .pipe(
        filter(packageId => !!packageId),
        mergeMap(packageId => this.loadPackage$(packageId)),
        takeUntil(this.destroy$)
      )
      .subscribe(publicPackage => {
        this.location.replaceState(`/packages/${makeUriFromString(publicPackage.title, publicPackage.id)}`);
        this.package = publicPackage;
      });

    packageId$
      .pipe(
        switchMap(packageId =>
          this.route.queryParamMap.pipe(
            map(params => params.get('invitation')),
            filter(invitation => !!invitation),
            map(invitation => ({ packageId, invitation }))
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ packageId, invitation }) => {
        if (this.package && this.package.id === packageId && this.package.isAlreadyEnrolled) {
          return;
        }

        this.invitation = invitation;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadPackage$(packageId: number): Observable<PublicPackage> {
    this.isLoading = true;

    return this.packageService.getPackage$(packageId).pipe(
      catchError(({ notFound }) => {
        if (notFound) {
          this.router.navigate(['/not-found'], { replaceUrl: true }).then();
        }

        return EMPTY;
      }),
      takeUntil(this.destroy$),
      finalize(() => (this.isLoading = false))
    );
  }
}
