// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IGuideServiceGroupItem } from '../../types';

@Component({
  selector: 'app-guide-package-service-group-item',
  templateUrl: './guide-package-service-group-item.component.html',
  styleUrls: ['./guide-package-service-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-program-service-group-item'
  }
})
export class GuidePackageServiceGroupItemComponent {
  @Input()
  // @ts-expect-error TS2564
  service: IGuideServiceGroupItem;

  readonly GuideServiceTypes = GuideServiceTypes;
}
