import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { Observable, race, timer } from 'rxjs';
import { take, mapTo, tap } from 'rxjs/operators';
import { LocalStorageKeys } from '@app/cdk/enums';

@Injectable()
export class GiftCouponActivationGuard {
  constructor(private _router: Router, private _authService: AuthService, private _localStorage: LocalStorageService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return race(this._authService.onAuth(), timer(500)).pipe(
      mapTo(this._authService.isAuthorized),
      tap(isAuthorized => {
        if (!isAuthorized) {
          this._localStorage.setItem(LocalStorageKeys.GIFT_COUPON_ACTIVATION_HASH, route.paramMap.get('hash'));
          this._router.navigate(['/auth/sign-in']).then();
        }
      }),
      take(1)
    );
  }
}
