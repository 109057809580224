import { Pipe, PipeTransform } from '@angular/core';
import { GuideServiceParent } from '@app/modules/book-service';
import { ClientSession } from '@libs/business/client/client-session';

@Pipe({
  name: 'sessionNameWithParentGroupName'
})
export class SessionWithParentGroupNamePipe<T extends ClientSession<GuideServiceParent>> implements PipeTransform {
  transform(session: T, groupName: string | undefined): T {
    if (!groupName) {
      return session;
    }

    session.title = `${groupName}. ${session.title}`;

    return session;
  }
}
