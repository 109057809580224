import { Pipe, PipeTransform } from '@angular/core';
import { GuideNotesAccessType } from '@app/modules/guide-notes/guide-notes.types';

const NoteAccessType: Record<GuideNotesAccessType, string> = {
  [GuideNotesAccessType.VIEWER]: `Can view`,
  [GuideNotesAccessType.OWNER]: `Owner`
};

@Pipe({
  name: 'userNotesAccessType'
})
export class UserNotesAccessTypePipe implements PipeTransform {
  transform(accessType: GuideNotesAccessType): string {
    return NoteAccessType[accessType];
  }
}
