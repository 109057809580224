import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { humanizeDateOnly } from '@app/shared/utils/humanize';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-time-to-session-start',
  templateUrl: './time-to-session-start.component.html'
})
export class TimeToSessionStartComponent {
  // @ts-expect-error TS2564
  @Input() sessionDateStart: string;

  get startDate(): { start: string; numberOfTime: string; durationOfTime: string } {
    const dateStart = this.sessionDateStart;

    const sessionStartMoment = DateTime.fromISO(dateStart);
    const now = DateTime.local();
    const momentDifference = sessionStartMoment.toMillis() - now.toMillis();
    const timeToSessionStart = humanizeDateOnly(momentDifference);

    let start;
    let durationOfTime;
    if (momentDifference >= 0) {
      start = 'Starts';
    }
    const numberOfTime = timeToSessionStart.split(' ')[0];

    switch (true) {
      case timeToSessionStart.includes('second'):
        durationOfTime = 'second';
        break;
      case timeToSessionStart.includes('minute'):
        durationOfTime = 'minute';
        break;
      case timeToSessionStart.includes('hour'):
        durationOfTime = 'hour';
        break;
      case timeToSessionStart.includes('day'):
        durationOfTime = 'day';
        break;
      case timeToSessionStart.includes('week'):
        durationOfTime = 'week';
        break;
      case timeToSessionStart.includes('mon'):
        durationOfTime = 'mon';
        break;
      case timeToSessionStart.includes('year'):
        durationOfTime = 'year';
        break;
      default:
        durationOfTime = '';
    }

    // @ts-expect-error TS2322
    return { start, numberOfTime, durationOfTime };
  }
}
