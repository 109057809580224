<div class="session-stacker">
  <div class="session-stacker__description">
    <ng-container *ngIf="appearance === 'client-booking-modal'">
      <div class="pui-space_bottom-3">
        <app-service-label [type]="stack?.firstSession?.type"></app-service-label>
      </div>
    </ng-container>

    <div [pui-typography]="appearance === 'client-dashboard' ? 'h5' : 'h4'" class="session-stacker__stack-name">
      {{ stack?.count }} x {{ stack?.title }}
    </div>

    <div *ngIf="stack?.attribute as attribute">
      <app-session-attributes [attributes]="[attribute]"></app-session-attributes>
    </div>
  </div>

  <div class="session-stacker__actions">
    <div class="session-stacker__actions" *ngIf="canBook">
      <ng-container *ngIf="appearance === 'client-dashboard'; else modal">
        <button pui-button appearance="flat" size="s" (click)="schedule()">{{ actionLabelText }}</button>
      </ng-container>
      <ng-template #modal>
        <button pui-button iconPosition="right" appearance="flat" size="l" (click)="schedule()">
          {{ actionLabelText }}
          <pui-icon svgIcon="pui:chevron-right"></pui-icon>
        </button>
      </ng-template>
    </div>
  </div>
</div>
