import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRedirectEvent, ISystemNotification, SystemNotificationTypes } from '@app/modules/notifications/types';
import { WorkspaceNotifications } from '@app/modules/notifications/components/header-notifications/workspace-notification/types';
import { GuideClientCardService } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-workspace-notification',
  templateUrl: './workspace-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './workspace-notification.component.scss']
})
export class WorkspaceNotificationComponent implements OnInit {
  notificationType: typeof WorkspaceNotifications = WorkspaceNotifications;
  systemNotificationTypes = SystemNotificationTypes;
  clientId: number | undefined;

  get serviceLandingLinkParts(): string[] {
    // @ts-expect-error TS2532
    const { type, id } = this.notification.details.service;
    return ['/', `${type}s`, `${id}`];
  }

  get memberName(): string {
    // @ts-expect-error TS2532
    return `${this.notification.details.user.firstName} ${this.notification.details.user.lastName}`;
  }

  get hostName(): string {
    // @ts-expect-error TS2532
    return `${this.notification.details.host.firstName} ${this.notification.details.host.lastName}`;
  }

  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;

  @Output()
  changeWorkspaceEvent = new EventEmitter<IRedirectEvent>();

  statusClasses = {
    [SystemNotificationTypes.WORKSPACE_JOINED_MEMBER]: 'confirmed',
    [SystemNotificationTypes.WORKSPACE_SERVICE_CREATED]: 'created',
    [SystemNotificationTypes.WORKSPACE_SERVICE_BOOKED]: 'booked',
    [SystemNotificationTypes.WORKSPACE_INVITED]: 'invited',
    [SystemNotificationTypes.WORKSPACE_CLIENT_ASSIGNED]: 'invited',
    [SystemNotificationTypes.WORKSPACE_SERVICE_ASSIGNED]: 'invited',
    [SystemNotificationTypes.WORKSPACE_ADMIN_OFFER_DECLINED_BY_CLIENT]: 'declined',
    [SystemNotificationTypes.WORKSPACE_OFFER_CANCELED_BY_ADMIN]: 'cancelled',
    [SystemNotificationTypes.WORKSPACE_SESSION_RESCHEDULED_BY_ADMIN]: 'pending',
    [SystemNotificationTypes.WORKSPACE_SESSION_RESCHEDULED_BY_GUIDE]: 'pending',
    [SystemNotificationTypes.WORKSPACE_GUIDE_SESSION_OFFER_CREATED]: 'invited',
    [SystemNotificationTypes.WORKSPACE_ADMIN_SCHEDULED_SESSION_CANCELED_BY_CLIENT]: 'cancelled',
    [SystemNotificationTypes.WORKSPACE_ADMIN_SCHEDULED_SESSION_ACCEPTED_BY_CLIENT]: 'booked',
    [SystemNotificationTypes.WORKSPACE_SESSION_CANCELED_BY_HOST]: 'cancelled',
    [SystemNotificationTypes.SESSION_REQUEST]: '',
    [SystemNotificationTypes.SESSION_REQUEST_APPROVEMENT]: '',
    [SystemNotificationTypes.SESSION_DECLINE]: '',
    [SystemNotificationTypes.SESSION_CANCEL]: '',
    [SystemNotificationTypes.SESSION_GUIDE_OFFER]: '',
    [SystemNotificationTypes.SESSION_GUIDE_OFFER_APPROVEMENT]: '',
    [SystemNotificationTypes.SESSION_GUIDE_OFFER_CANCEL]: '',
    [SystemNotificationTypes.SESSION_GUIDE_OFFER_DECLINE]: '',
    [SystemNotificationTypes.SESSION_RESCHEDULE_APPROVEMENT]: '',
    [SystemNotificationTypes.SESSION_RESCHEDULE_BY_CLIENT]: '',
    [SystemNotificationTypes.SESSION_RESCHEDULE_BY_GUIDE]: '',
    [SystemNotificationTypes.PACKAGE_INVITE]: '',
    [SystemNotificationTypes.PACKAGE_ENROLLED]: '',
    [SystemNotificationTypes.PACKAGE_PAID]: '',
    [SystemNotificationTypes.NOTE_SHARED_WITH_TEAM_MEMBER]: '',
    [SystemNotificationTypes.PROGRAM_INVITE_INFORMATIVE]: '',
    [SystemNotificationTypes.PROGRAM_INVITE_PREPAID]: '',
    [SystemNotificationTypes.PROGRAM_MODULE_AVAILABLE]: '',
    [SystemNotificationTypes.PROGRAM_COMPLETE]: '',
    [SystemNotificationTypes.PROGRAM_CLIENT_COMPLETE_MODULE]: 'pending',
    [SystemNotificationTypes.CLIENT_REGISTERED]: '',
    [SystemNotificationTypes.WORKSPACE_SESSION_CANCELED_BY_ADMIN]: 'cancelled',
    [SystemNotificationTypes.COMMUNITY_NEW_POST]: 'pending'
  };

  constructor(private readonly clientCardService: GuideClientCardService) {}

  ngOnInit(): void {
    if (
      this.notification.description === this.notificationType.WORKSPACE_ADMIN_OFFER_DECLINED_BY_CLIENT ||
      this.notification.description === this.notificationType.WORKSPACE_ADMIN_SCHEDULED_SESSION_CANCELED_BY_CLIENT ||
      this.notification.description === this.notificationType.CLIENT_ASSIGNED ||
      this.notification.type === this.systemNotificationTypes.WORKSPACE_SERVICE_BOOKED
    ) {
      this.clientId = this.notification.details?.clientId || this.notification.details?.user?.id;
    }
  }

  changeWorkspace(workspaceId: number): void {
    this.changeWorkspaceEvent.emit({
      handlerName: 'switchWorkspace',
      // @ts-expect-error TS2532
      data: { workspaceId, invitationCode: this.notification.details.invitationCode }
    });
  }

  showClientDetails(clientId: number): void {
    this.clientCardService.openDetails(0, clientId);
  }
}
