import { Injectable } from '@angular/core';
import {
  BookingSteps,
  IBookingStepValidationStrategy,
  IGuideService,
  IServiceBookingOptions
} from '@app/modules/book-service/types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Injectable({
  providedIn: 'root'
})
export class BookingStepsService implements IBookingStepValidationStrategy {
  calculate(options: IServiceBookingOptions<IGuideService>): BookingSteps {
    if (!options) {
      return BookingSteps.SERVICE_DETAILS;
    }

    switch (true) {
      case this[BookingSteps.HOST_SELECTION](options):
        return BookingSteps.HOST_SELECTION;
      case this[BookingSteps.SERVICE_DETAILS](options):
        return BookingSteps.SERVICE_DETAILS;
      case this[BookingSteps.PAYMENT_OPTIONS](options):
        return BookingSteps.PAYMENT_OPTIONS;
      case this[BookingSteps.BILLING_DATA](options):
        return BookingSteps.BILLING_DATA;
      case this[BookingSteps.PAYMENT](options):
        return BookingSteps.PAYMENT;
      default:
        return BookingSteps.PAYMENT;
    }
  }

  [BookingSteps.HOST_SELECTION](options: IServiceBookingOptions<IGuideService>): boolean {
    // @ts-expect-error TS2322
    return (
      // @ts-expect-error TS2532
      options.requiresHostSelection && !options.serviceHost && options.service.type !== GuideServiceTypes.GROUP_SESSION
    );
  }

  [BookingSteps.SERVICE_DETAILS](options: IServiceBookingOptions<IGuideService>): boolean {
    return (
      !options ||
      !options.service ||
      ((options.service.type === GuideServiceTypes.SESSION ||
        options.service.type === GuideServiceTypes.GROUP_SESSION) &&
        !options.date)
    );
  }

  [BookingSteps.BILLING_DATA](options: IServiceBookingOptions<IGuideService>): boolean {
    const { billingData } = options;
    // @ts-expect-error TS2322
    return billingData && billingData.required;
  }

  [BookingSteps.PAYMENT_OPTIONS](options: IServiceBookingOptions<IGuideService>): boolean {
    // @ts-expect-error TS2322
    return (
      // @ts-expect-error TS2532
      options.service.price &&
      // @ts-expect-error TS2532
      options.service.subscriptionPrice &&
      // @ts-expect-error TS2532
      options.service.subscriptionRecurrency &&
      // @ts-expect-error TS2532
      options.service.totalPayments &&
      !options.paymentOption
    );
  }

  [BookingSteps.PAYMENT](options: IServiceBookingOptions<IGuideService>): boolean {
    // @ts-expect-error TS2322
    return (
      // @ts-expect-error TS2532
      options.service.price &&
      // @ts-expect-error TS2532
      options.service.subscriptionPrice &&
      // @ts-expect-error TS2532
      options.service.subscriptionRecurrency &&
      // @ts-expect-error TS2532
      options.service.totalPayments &&
      !!options.paymentOption
    );
  }
}
