import { DateTime } from 'luxon';

import { EventStatuses } from '@app/shared/enums/event-statuses';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { IEventSession, ISessionEvent } from '@app/shared/interfaces/event';
import { IServerUser } from '@app/shared/interfaces/user';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';

export class VideoSession {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatId: string | null;

  eventId: number;
  startDateTime: DateTime;
  endDateTime: DateTime;
  originalStartDateTime: DateTime;
  serviceType: SessionTypes;
  instance: IEventSession;
  status: SessionStatuses;
  user: IServerUser;
  workspaceId?: number;
  service = this.sessionEvent.service;
  readonly isGroupSession!: boolean;

  get chatId(): string | null {
    return this._chatId;
  }

  constructor(private sessionEvent: ISessionEvent) {
    this.eventId = sessionEvent.id;
    this.startDateTime = DateTime.fromISO(sessionEvent.startDateTime);
    this.endDateTime = DateTime.fromISO(sessionEvent.endDateTime);
    this.originalStartDateTime = DateTime.fromISO(sessionEvent.originalStartDateTime);
    this.serviceType = sessionEvent.service.serviceType;
    this.workspaceId = sessionEvent.service.workspaceId;
    // @ts-expect-error TS2322
    this.instance = sessionEvent.sessions && sessionEvent.sessions.length ? sessionEvent.sessions[0] : null;
    // fast hack, video session actually requires only event statuses
    this.status = this.convertEventStatusToSessionStatus(sessionEvent.status);
    // TODO: check this.user logic
    this.user = this.instance?.user;

    this.isGroupSession = !!sessionEvent.service.participantsLimit;

    this._chatId = this.isGroupSession
      ? sessionEvent.service.chatId
      : /**
         * Get chatId from service or from client
         */
        sessionEvent.service.chatId ||
        buildDirectChatLinkId({ id: this.user.id, workspaceId: sessionEvent.service.workspaceId });
  }

  private convertEventStatusToSessionStatus(eventStatus: EventStatuses): SessionStatuses {
    switch (eventStatus) {
      case EventStatuses.PENDING:
        return SessionStatuses.PENDING_APPROVEMENT;
      case EventStatuses.CANCELLED:
        return SessionStatuses.CANCELLED;
      case EventStatuses.APPROVED:
        return SessionStatuses.APPROVED;
      case EventStatuses.IN_PROGRESS_OLD:
        return SessionStatuses.IN_PROGRESS_OLD;
      case EventStatuses.DONE:
        return SessionStatuses.DONE;
    }
  }
}
