import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { SortOnlySessionDateModule } from '@app/shared/pipes/sort-only-session-date/sort-only-session-date.module';
import {
  PuiButtonModule,
  PuiDividerModule,
  PuiIconModule,
  PuiLetModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { CLIENT_SESSION_ADAPTOR } from '@libs/business/client/client-session/tokens';

import { SessionComponent } from './components';
import {
  ClientSessionActionModule,
  ClientSessionAttributeModule,
  ClientSessionIconModule,
  ClientSessionJoinButtonModule,
  ClientSessionStatusModule
} from './modules';
import { ClientSessionAdaptorService } from './services';

// ToDo Singleton
@NgModule({
  declarations: [SessionComponent],
  imports: [
    CommonModule,
    PuiLetModule,
    ClientSessionStatusModule,
    ClientSessionAttributeModule,
    ClientSessionJoinButtonModule,
    ClientSessionIconModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiDividerModule,
    SortOnlySessionDateModule,
    ClientSessionActionModule,
    PuiIconModule
  ],
  exports: [ClientSessionStatusModule, ClientSessionAttributeModule, ClientSessionIconModule, SessionComponent]
})
export class ClientSessionModule {
  constructor(@Inject(CLIENT_SESSION_ADAPTOR) adaptor: ClientSessionAdaptorService) {
    adaptor.initializeSessionActions();
  }
}
