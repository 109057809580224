import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IChatMessagesBuffer<T> {
  [id: number]: T[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IChatsNotReadyIds<U> {
  [id: number]: U;
}

export function bufferMessagesWhileChatNotReady<T, U>(chatsNotReady$: Observable<IChatsNotReadyIds<U>>) {
  // @ts-expect-error TS7006
  return function (messageSource$): Observable<T> {
    return new Observable<T>(observer => {
      let messageBuffers: IChatMessagesBuffer<T> = {};

      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const tryEmitFromMessageBuffers = (chatIds: IChatsNotReadyIds<U> = {}) => {
        Object.keys(messageBuffers).forEach(messageBufferChatId => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!chatIds[messageBufferChatId]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            messageBuffers[messageBufferChatId].forEach(message => observer.next(message));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete messageBuffers[messageBufferChatId];
          }
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.keys(chatIds).forEach(chatId => (messageBuffers[chatId] = messageBuffers[chatId] || []));
      };

      const chatsNotReadySubscription = chatsNotReady$.subscribe(
        chatIds => tryEmitFromMessageBuffers(chatIds),
        () => tryEmitFromMessageBuffers(),
        () => tryEmitFromMessageBuffers()
      );

      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const cleanUp = () => {
        // @ts-expect-error TS2322
        messageBuffers = null;
        chatsNotReadySubscription.unsubscribe();
      };

      return messageSource$.subscribe(
        /* next */
        // @ts-expect-error TS7006
        message => {
          if (messageBuffers[message.chatId]) {
            messageBuffers[message.chatId].push(message);
          } else {
            observer.next(message);
          }
        },
        /* error */
        // @ts-expect-error TS7006
        error => {
          cleanUp();
          observer.error(error);
        },
        /* complete */
        () => {
          cleanUp();
          observer.complete();
        }
      );
    });
  };
}
