// import { locale } from '@env/locale';
//
// const dateTimeLocale = locale.dateTimeLocale;

export function getDayPostfix(day: number, dateTimeLocale: string): string {
  if (!dateTimeLocale.includes('en')) {
    return '';
  }

  switch (day) {
    case 1:
    case 21:
    case 31:
      return 'st';
    case 2:
    case 22:
      return 'nd';
    case 3:
    case 23:
      return 'rd';
    default:
      return 'th';
  }
}
