<div class="wrapper" *ngIf="timezoneOffset$ | async as timeZoneOffset">
  <div
    [attr.data-qa-id]="'note-history-meta-created-date-label-' + note?.contentText?.trim()"
    class="created-date"
    pui-typography="label"
    *ngIf="showCreatedAt">
    {{ note.createdAt | date: datePipeFormat:timeZoneOffset }}
  </div>
  <span class="divider" pui-typography="label" *ngIf="showUpdatedAt && note.createdAt !== note.updatedAt">
    &nbsp;/&nbsp;
  </span>
  <div
    [attr.data-qa-id]="'note-history-meta-updated-date-label-' + note?.contentText?.trim()"
    class="updated-date"
    pui-typography="label"
    i18n="@@editedOn"
    *ngIf="showUpdatedAt && note.createdAt !== note.updatedAt">
    edited on {{ note.updatedAt | date: datePipeFormat:timeZoneOffset }}
  </div>
</div>
