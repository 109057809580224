import { createAction, props } from '@ngrx/store';
import { CLIENT_TAGS_STORE } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/commons/consts';
import { ClientTag } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/commons/types';

// Create
export const createClientTag = createAction(
  `[${CLIENT_TAGS_STORE}] Create tag`,
  props<{ tag: Partial<Omit<ClientTag, 'id'>> }>()
);
export const createClientTagSuccess = createAction(
  `[${CLIENT_TAGS_STORE}] Create tag success`,
  props<{ tag: ClientTag }>()
);
export const createClientTagError = createAction(
  `[${CLIENT_TAGS_STORE}] Create tag success`,
  props<{ id: ClientTag['id'] }>()
);
// Read
export const fetchClientTags = createAction(`[${CLIENT_TAGS_STORE}] Fetch tags`);
export const fetchClientTagsSuccess = createAction(
  `[${CLIENT_TAGS_STORE}] Fetch tags success`,
  props<{ tags: ClientTag[] }>()
);
export const fetchClientTagsError = createAction(`[${CLIENT_TAGS_STORE}] Fetch tags error`);
// Update
export const updateClientTag = createAction(
  `[${CLIENT_TAGS_STORE}] Update tag`,
  props<{ id: ClientTag['id']; update: Partial<Omit<ClientTag, 'id'>> }>()
);
export const updateClientTagSuccess = createAction(`[${CLIENT_TAGS_STORE}] Update tag success`);
export const updateClientTagError = createAction(`[${CLIENT_TAGS_STORE}] Update tag error`);
// Delete
export const deleteClientTag = createAction(`[${CLIENT_TAGS_STORE}] Delete tag`, props<{ id: ClientTag['id'] }>());
export const deleteClientTagSuccess = createAction(
  `[${CLIENT_TAGS_STORE}] Delete tag success`,
  props<{ id: ClientTag['id'] }>()
);
export const deleteClientTagError = createAction(
  `[${CLIENT_TAGS_STORE}] Delete tag error`,
  props<{ id: ClientTag['id'] }>()
);
// Others
export const resetClientsTags = createAction(`[${CLIENT_TAGS_STORE}] Reset state`);
