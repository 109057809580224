import { Inject, Injectable } from '@angular/core';
import { PuiNavigationEntity } from '@awarenow/profi-ui-core';
import { combineLatest, EMPTY, iif, Observable, of } from 'rxjs';
import { UserRoles } from '@app/shared/enums/user-roles';
import { filter, map } from 'rxjs/operators';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import { NavigationApiService } from '@app/modules/navigation/api';
import { INavigationDependenciesService, INavigationEntriesService, INavigationEntry } from '../interfaces';
import { NAVIGATION_CONDITIONS, NAVIGATION_DEPENDENCIES } from '../tokens';
import { NavigationWidgetName } from '../types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { NavigationEntryFactory } from '@app/modules/navigation';

@Injectable()
export abstract class AbstractNavigationEntriesService implements INavigationEntriesService {
  abstract role: UserRoles;

  entries: Observable<INavigationEntry>[] = [];

  protected navLinks$: Observable<INavigationEntry[]> = EMPTY;

  constructor(
    @Inject(NAVIGATION_CONDITIONS) readonly conditions: ConditionsMap<VisibilityCondition>,
    @Inject(NAVIGATION_DEPENDENCIES) readonly dependencies: INavigationDependenciesService,
    navigationApiService: NavigationApiService,
    readonly runtimeConfigService: RuntimeConfigService,
    readonly navigationEntryFactory: NavigationEntryFactory
  ) {
    this.navLinks$ = navigationApiService.sidenav$;
  }

  get$(widget?: NavigationWidgetName): Observable<Observable<PuiNavigationEntity>[]> {
    return combineLatest([
      of(
        this.entries.map(entry$ =>
          entry$.pipe(
            filter(entry => (widget ? entry.widget.includes(widget) : true)),
            map(entry => this._entryToEntity(entry))
          )
        )
      ),
      // ToDo(lihih) remove from this
      iif(
        () => widget === 'primary',
        this.navLinks$.pipe(map(entries => entries.map(entry => of(this._entryToEntity(entry))))),
        of([])
      )
    ]).pipe(map(([local, navigation]) => [...local, ...navigation]));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _entryToEntity(entry: INavigationEntry): PuiNavigationEntity {
    return {
      item: entry?.item,
      route: entry?.route,
      visibility$: this.conditions[entry.condition] || of(false),
      // @ts-expect-error TS2322
      events: entry?.events
    };
  }
}
