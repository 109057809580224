import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { InputDirective } from './directives/input.directive';
import { InputLabelComponent } from './components/input-label/input-label.component';

@NgModule({
  declarations: [FormFieldComponent, InputDirective, InputLabelComponent],
  exports: [FormFieldComponent, InputLabelComponent, InputDirective],
  imports: [CommonModule]
})
export class FormModule {}
