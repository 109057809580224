<div class="row">
  <div class="col-md-9">
    <app-blog-article-content
      [article]="article"
      (bookSession)="bookSession($event)"
      (openAuthor)="openAuthor($event)"
      (selectTag)="selectTag($event)"
      class="blog-article-content"></app-blog-article-content>

    <ng-template [ngIf]="disqusIdentifier">
      <disqus [identifier]="disqusIdentifier" [title]="disqusTitle"></disqus>
    </ng-template>

    <ng-container *ngIf="canEditBlogArticle">
      <div class="row" style="margin-top: 40px">
        <div class="col">
          <app-button
            class="edit-btn"
            className="btn-main"
            [routerLink]="['/blog', article.author.namedUrl, 'posts', article.id, 'edit']"
            i18n="@@BlogArticleComponentEditYourStory">
            Edit your story
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-md-3 info-section">
    <app-blog-article-related-articles
      [articles]="relatedArticles"
      (select)="selectRelatedArticle($event)"
      class="related-articles"></app-blog-article-related-articles>
    <ng-container *ngIf="relatedTags && relatedTags.length">
      <app-blog-article-related-tags
        [tags]="relatedTags"
        (select)="selectTag($event)"
        class="related-tags"></app-blog-article-related-tags>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="latestArticles && latestArticles.length">
  <app-blog-article-latest-articles
    [articles]="latestArticles"
    (selectArticle)="selectRelatedArticle($event)"
    (selectAuthor)="openAuthor($event)"
    class="latest-articles"></app-blog-article-latest-articles>
</ng-container>
