import { DateTime } from 'luxon';
import { GuideServiceParent } from '@app/modules/book-service';
import { SessionGroup } from './group-sessions-by.pipe';
import { ClientSession } from '@libs/business/client/client-session';

export const groupSessionsByParent = (
  sessions: ClientSession<GuideServiceParent>[]
): (SessionGroup | ClientSession<GuideServiceParent>)[] => {
  if (!sessions) {
    return [];
  }

  const groupedByParentAndPurchase = sessions.reduce((groups, session) => {
    const parent = session.parent;

    if (!parent) {
      return groups;
    }

    if (!parent.id) {
      const groupKey = session.id;

      return {
        ...groups,
        [groupKey]: session
      };
    }

    const groupKey = parent.id;

    const group: SessionGroup = groups[groupKey] || {
      id: parent.id,
      name: parent.name,
      type: parent.type,
      total: parent.childrenCount,
      available: parent.childrenAvailableCount,
      children: [],
      order: DateTime.fromISO(parent.packagePurchaseDate ?? DateTime.now().toISO()).toMillis()
    };

    const childrens = group.children;

    return {
      ...groups,
      [groupKey]: {
        ...group,
        children: [...childrens, session]
      } as SessionGroup
    };
  }, {} as Record<number, SessionGroup>);

  return Object.values(groupedByParentAndPurchase);
};
