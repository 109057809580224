import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DateTime } from 'luxon';
import config from '@app/core/config/config';
import { HttpClient } from '@angular/common/http';
import { TimezonesService } from '@app/core/timezones/timezones.service';
import {
  ITimeSlot,
  LOCALE_PROVIDERS,
  SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER,
  SCHEDULE_FORMATS_PROVIDERS,
  SCHEDULE_PROVIDER,
  SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
  SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER
} from '@app/modules/schedule-boards';
import { IScheduleOptions } from '@app/modules/ui-kit/schedule';
import {
  IPrivateGuideScheduleOptions,
  IPrivateGuideScheduleTimeSlotsFactory,
  PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDER,
  PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDERS,
  PrivateGuideScheduleProviderService
} from '../../services';

import { ScheduleOptions, IScheduleOptions as IScheduleOptionsWorkDaye } from '@app/screens/guide/guide-sessions/types';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filterEmptyDaysFromScheduleTemplate } from '@app/screens/guide/guide-sessions/services/schedule/converters';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-private-guide-schedule',
  templateUrl: './private-guide-schedule.component.html',
  styleUrls: ['./private-guide-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: SCHEDULE_PROVIDER, useClass: PrivateGuideScheduleProviderService },
    PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDERS,
    SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER,
    SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER,
    SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
    LOCALE_PROVIDERS,
    SCHEDULE_FORMATS_PROVIDERS
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'private-guide-schedule'
  }
})
export class PrivateGuideScheduleComponent<TSelectedTime> implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _privateGuideScheduleOptions: IPrivateGuideScheduleOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleOptions: IScheduleOptions;

  // TODO: make option
  readonly maxDate: { readonly year: number; readonly month: number; readonly day: number } | null;

  private scheduleOptionsTemplate: ScheduleOptions | null = null;
  private destroy$ = new Subject();

  // TODO: make option
  minDate: { readonly year: number; readonly month: number; readonly day: number } | null;

  @Input()
  set options(value: IPrivateGuideScheduleOptions) {
    this._privateGuideScheduleOptions = value;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { clientId, ...scheduleOptions } = value;
    this._scheduleOptions = scheduleOptions;

    this.onScheduleOptionsChange(value);
  }

  @Output()
  timeSelect = new EventEmitter<TSelectedTime>();

  get scheduleOptions(): IScheduleOptions {
    return this._scheduleOptions;
  }

  constructor(
    @Inject(PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDER)
    readonly scheduleSlotsProvider: IPrivateGuideScheduleTimeSlotsFactory<unknown, void, ITimeSlot>,
    public timezones$: TimezonesService,
    readonly httpClient: HttpClient,
    private readonly runtimeConfig: RuntimeConfigService
  ) {
    const now = DateTime.local();

    const { year: minDateYear, month: minDateMonth, day: minDateDay } = now.toObject();
    // @ts-expect-error TS2322
    this.minDate = { year: minDateYear, month: minDateMonth, day: minDateDay };

    const {
      year: maxDateYear,
      month: maxDateMonth,
      day: maxDateDay
    } = now.plus({ months: runtimeConfig.get('availabilityMaxBoundaryInMonths') }).toObject();
    // @ts-expect-error TS2322
    this.maxDate = { year: maxDateYear, month: maxDateMonth, day: maxDateDay };
  }

  onScheduleOptionsChange(scheduleOptions: IScheduleOptions): void {
    const { date, duration, timezone } = scheduleOptions;

    const guideScheduleOptions: IPrivateGuideScheduleOptions = {
      // @ts-expect-error TS2322
      date: scheduleOptions?.currentDate?.date ? DateTime.fromISO(scheduleOptions.currentDate.date).toString() : date,
      // @ts-expect-error TS2322
      duration,
      timezone,
      clientId: this._privateGuideScheduleOptions.clientId,
      exEvent: this._privateGuideScheduleOptions.exEvent,
      token: this._privateGuideScheduleOptions.token,
      currentDate: this._privateGuideScheduleOptions.currentDate
    };

    this.scheduleSlotsProvider.refresh(guideScheduleOptions);
  }

  markDateDisabled = (dateObject: { day: number; month: number; year: number }): boolean => {
    const dateNumber = DateTime.fromObject(dateObject).weekday;

    // @ts-expect-error TS2531
    return !Object.keys(this.scheduleOptionsTemplate.template).includes(`${dateNumber}`);
  };

  ngOnInit(): void {
    const ENDPOINT = `${config.apiPath}/user/guide/availability/schedule-options`;
    const ENDPOINT_MEMBERS = `${config.apiPath}/user/guide/workspaces/members`;
    const forCurrent$ = this.httpClient.get<{ scheduleOptions: IScheduleOptionsWorkDaye }>(ENDPOINT);
    const forGuide$ = this.httpClient.get(ENDPOINT_MEMBERS).pipe(
      switchMap((result: { userId: number; workspaceId: number }[]) => {
        // @ts-expect-error TS2339
        const { workspaceId } = result.filter(i => i.userId === this.scheduleOptions.guideId).shift();
        const END_POINT_TEMPLATE = `${config.apiPath}/user/guide/availability/workspace/${workspaceId}/guide/${this.scheduleOptions.guideId}`;
        return this.httpClient.get<{ scheduleOptions: IScheduleOptionsWorkDaye }>(END_POINT_TEMPLATE);
      })
    );
    (this.scheduleOptions.guideId === this.scheduleOptions.userId ? forCurrent$ : forGuide$)
      .pipe(
        map(response => new ScheduleOptions(response.scheduleOptions)),
        map(filterEmptyDaysFromScheduleTemplate),
        takeUntil(this.destroy$)
      )
      .subscribe(scheduleOptions => {
        this.scheduleOptionsTemplate = scheduleOptions;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
