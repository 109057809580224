import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { AnalyticCopyTypes } from '@app/core/analytics/types';

@Component({
  selector: 'app-guide-service-sharing-editor',
  templateUrl: './guide-service-sharing-editor.component.html',
  styleUrls: ['../../../../scss/guide-services/forms.scss', './guide-service-sharing-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceSharingEditorComponent {
  @Input()
  // @ts-expect-error TS2564
  link: string;

  @Input()
  // @ts-expect-error TS2564
  type: GuideServiceTypes;

  @Output()
  linkCopied = new EventEmitter<AnalyticCopyTypes>();

  constructor(private readonly _notifications: NotificationsService) {}

  onBtnCopied(): void {
    this.linkCopied.emit(AnalyticCopyTypes.BUTTON);
    this._notifications.success(`Copied`);
  }

  onCopyFailed(): void {
    this._notifications.error(`Copy failed`);
  }

  onStandardCopied(): void {
    this.linkCopied.emit(AnalyticCopyTypes.STANDARD);
    this._notifications.success(`Copied`);
  }
}
