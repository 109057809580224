<form [formGroup]="form">
  <div>
    <label
      pui-input-label
      for="template_platform"
      i18n="@@location"
      [class.d-inline-block]="form.get('connectionType')?.disabled">
      Location
    </label>

    <pui-select
      id="template_platform"
      class="pui-space_bottom-2"
      [optionContent]="optionContentTemplate"
      [valueTemplate]="optionContentTemplate"
      formControlName="connectionType"
      [maxHeight]="300"
      ngbTooltip="You can't change the platform for confirmed sessions."
      i18n-ngbTooltip="@@sessionTemplateSettingsPlatformDisabledTooltip"
      [disableTooltip]="disableConnectionTypeTooltip"
      placement="top">
      <pui-option
        [label]="item.name"
        [value]="item.connectionType"
        [data]="item"
        *ngFor="let item of connectionTypes"></pui-option>
    </pui-select>

    <ng-container *ngIf="zoomNotification$ | async as notificationType">
      <ng-template [ngIf]="notificationType.warning">
        <div class="location-form__no-zoom-account pui-space_bottom-2 warning">
          <pui-icon svgIcon="pui:status-awaiting"></pui-icon>
          <span class="pui-space_left-1">
            Make sure that all session hosts below have a Zoom account; otherwise, the sessions of these hosts will be
            created with {{ platformName }} location.
          </span>
        </div>
      </ng-template>
      <ng-template [ngIf]="notificationType.error">
        <div class="location-form__no-zoom-account pui-space_bottom-2 alert">
          <pui-icon svgIcon="pui:status-awaiting"></pui-icon>
          <span class="pui-space_left-1" i18n="@@toUseZoom">To use Zoom,</span>
          <button (click)="connectZoom()" pui-link class="pui-space_left-1">
            <span i18n="@@linkYourAccount">link your account</span>
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div *ngIf="showAddress">
    <div class="pui-space_bottom-4">
      <label
        for="meeting_address"
        i18n="@@address"
        [class.d-inline-block]="form.get('connectionType').disabled"
        pui-input-label>
        Address
      </label>
      <input id="meeting_address" type="text" formControlName="address" class="form-control" />
    </div>

    <pui-input-checkbox formControlName="hideAddress" i18n="@@sessionTemplateSettingsDontShowAddress">
      Don’t show the address on the landing page. Clients will receive the address only after booking.
    </pui-input-checkbox>
  </div>

  <div *ngIf="showPhoneOptions">
    <label formControlName="callInitiator" pui-input-radio [value]="callInitiator.CLIENT">
      <strong i18n="@@sessionTemplatePhoneCallClientInitiator"> Client will call me </strong>
      <span i18n="@@sessionTemplatePhoneCallClientInitiatorNote">
        Please check the phone number in "My account" host's settings.
      </span>
    </label>
    <label formControlName="callInitiator" pui-input-radio [value]="callInitiator.GUIDE">
      <strong i18n="@@sessionTemplatePhoneCallGuideInitiator"> I will initiate the call </strong>
      <span i18n="@@sessionTemplatePhoneCallGuideInitiatorNote">
        Please check the client's phone number in client’s card.
      </span>
    </label>
  </div>
</form>

<ng-template #optionContentTemplate let-option="option">
  <div class="location-form__select">
    <div class="location-form__select-img" *ngIf="option.data.connectionType === SessionConnectionTypes.IN_PLATFORM">
      <img [src]="platformIconURL" alt="" />
    </div>
    <pui-icon
      *ngIf="option.data.connectionType !== SessionConnectionTypes.IN_PLATFORM"
      [svgIcon]="option.data.icon"
      size="s"></pui-icon>
    <span>{{ option.data.connectionType | connectionType }}</span>
  </div>
</ng-template>
