<ng-container *ngIf="label">
  <div class="attribute">
    <pui-icon class="attribute__icon" [svgIcon]="leftIcon" size="s"></pui-icon>
    <div class="attribute__label">{{ label }}</div>
    <ng-template [ngIf]="rightIconRouterLink">
      <a pui-link class="d-flex" [routerLink]="[rightIconRouterLink]">
        <pui-icon class="attribute__icon pui-space_left-1 right-icon" [svgIcon]="rightIcon" size="s"></pui-icon>
      </a>
    </ng-template>
  </div>
</ng-container>
