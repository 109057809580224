// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function removeUndefined(obj: Object) {
  for (const key in obj) {
    // @ts-expect-error TS7053
    if (obj[key] === undefined) {
      // @ts-expect-error TS7053
      delete obj[key];
    }
  }

  return obj;
}
