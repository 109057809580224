import { Component, Input, TemplateRef } from '@angular/core';
import { IServiceItemComponent } from '@app/modules/guide-service/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({ selector: '', template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AbsComponentServiceItem<T> implements IServiceItemComponent {
  @Input()
  editorLink: unknown;

  @Input()
  // @ts-expect-error TS2564
  publicLink: string;

  @Input()
  // @ts-expect-error TS2564
  copyText: string;

  @Input()
  // @ts-expect-error TS2564
  service: T;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  badges: TemplateRef<any>[];

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  price: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showMore: TemplateRef<any>;
}
