import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideRelation } from '@app/core/users/types';

@Component({
  selector: 'app-clients-selector-modal',
  templateUrl: './clients-selector-modal.component.html',
  styleUrls: ['./clients-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsSelectorModalComponent {
  @Input()
  selectedClients: GuideRelation[] = [];

  // ToDo clients group
  @Input()
  // @ts-expect-error TS2564
  groups: { name: string; entries: number[] }[];

  @Input()
  clients: GuideRelation[] = [];

  constructor(private _modal: NgbActiveModal) {}

  cancel(): void {
    this._modal.dismiss();
  }

  save(): void {
    // eslint-disable-next-line id-length
    this._modal.close({ selected: [...this.selectedClients.map(c => c.id)] });
  }
}
