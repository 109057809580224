import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScreenModule } from '@app/modules/ui-kit/_base/_common/screen/screen.module';
import { PortalModule } from '@angular/cdk/portal';
import { PuiButtonModule, PuiDividerModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { NavigationModule } from '@app/modules/navigation';
import { SIDENAV_SCHEMA_PROVIDER } from '@app/modules/sidenav/providers';
import {
  SidenavAdminSchema,
  SidenavClientSchema,
  SidenavGuideSchema,
  SidenavSuperAdminSchema
} from '@app/modules/sidenav/schemas';
import { SidenavBodyComponent, SidenavComponent, SidenavFooterComponent, SidenavHeaderComponent } from './hosts';
import {
  SidenavAdminLogoutModule,
  SidenavCurrentWidgetModule,
  SidenavNavigationPrimaryWidgetModule,
  SidenavNavigationSecondaryWidgetModule,
  SidenavToggleButtonComponent,
  SidenavWorkspaceSwitchWidgetModule,
  SwitchToAlternativeAccountModule
} from './components';
import {
  ConditionsService,
  SidenavModeService,
  ViewCandidateService,
  ViewHostService,
  ViewWidgetService
} from './services';

@NgModule({
  declarations: [
    SidenavComponent,
    SidenavToggleButtonComponent,
    SidenavHeaderComponent,
    SidenavBodyComponent,
    SidenavFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ScreenModule,
    PortalModule,
    PuiDividerModule,
    NavigationModule,
    SidenavCurrentWidgetModule,
    SidenavAdminLogoutModule,
    SidenavNavigationPrimaryWidgetModule,
    SidenavNavigationSecondaryWidgetModule,
    SwitchToAlternativeAccountModule,
    SidenavWorkspaceSwitchWidgetModule,
    PuiButtonModule,
    PuiIconModule
  ],
  exports: [SidenavComponent],
  providers: [
    ViewHostService,
    ConditionsService,
    ViewCandidateService,
    ViewWidgetService,
    SidenavModeService,
    SidenavGuideSchema,
    SidenavClientSchema,
    SidenavAdminSchema,
    SidenavSuperAdminSchema,
    SIDENAV_SCHEMA_PROVIDER
  ]
})
export class SidenavModule {}
