import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionType } from '@app/shared/enums/session-type';

@Component({
  selector: 'app-session-type',
  templateUrl: './session-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTypeComponent {
  readonly SessionType = SessionType;

  @Input()
  type!: SessionType;

  @Input()
  withIcon = true;

  @Input()
  iconSize = 's';
}
