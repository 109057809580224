import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { ParseMarkdownLinkPipe } from '@app/modules/common-header/parse-markdown-link';

@Component({
  selector: 'app-blog-item-platform-ads',
  templateUrl: './blog-item-platform-ads.component.html',
  styleUrls: ['./blog-item-platform-ads.component.scss'],
  providers: [ParseMarkdownLinkPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemPlatformAdsComponent implements OnInit {
  content = {
    bannerTitle: '',
    bannerText: '',
    bannerButtonText: '',
    bannerButtonUrl: '',
    bannerBackgroundUrl: ''
  };

  constructor(private _brandingService: BrandingService, private _pmlPipe: ParseMarkdownLinkPipe) {}

  ngOnInit(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._brandingService.globalConfig$.subscribe(
      ({ blogBannerTitle, blogBannerText, blogBannerLink, blogBannerBackgroundImage }) => {
        this.content.bannerTitle = blogBannerTitle;
        this.content.bannerText = blogBannerText;
        this.content.bannerButtonText = this._pmlPipe.transform(blogBannerLink).text;
        this.content.bannerButtonUrl = this._pmlPipe.transform(blogBannerLink).url;
        this.content.bannerBackgroundUrl = `url(${blogBannerBackgroundImage})`;
      }
    );
  }
}
