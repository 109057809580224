import { Pipe, PipeTransform } from '@angular/core';
import { GuideServiceParent } from '@app/modules/book-service';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';
import { IClientGuideAvailableSession } from '@app/screens/client/client-dashboard/types/types';
import { ClientSessionTimeAttribute } from '@libs/business/client/client-session/modules';
import { Session } from '@libs/business/cdk/session';

export interface SessionsStack {
  id: number;
  title: string;
  count: number;
  attribute: ClientSessionTimeAttribute;
  firstSession: Session<IClientGuideAvailableSession, GuideServiceParent>;
}

@Pipe({
  name: 'stackSessionsBy'
})
export class StackSessionsByPipe implements PipeTransform {
  constructor(private readonly platformStyle: PlatformStyle) {}

  transform(
    sessions: Session<unknown, GuideServiceParent>[],
    stacker: (sessions: Session<unknown, GuideServiceParent>[], platformStyle: PlatformStyle) => SessionsStack[]
  ): SessionsStack[] {
    if (!sessions) {
      return [];
    }

    return stacker(sessions, this.platformStyle);
  }
}
