<ng-template [ngIf]="activeStep === ServiceDetailsFormSteps.SERVICE_PICKER">
  <app-available-session-scheduling
    *ngIf="guide"
    class="d-block pui-space_bottom-6"
    [guide]="guide"></app-available-session-scheduling>

  <h3 data-qa-id="service-details-form-work-with-title" class="step-title" *ngIf="guide">
    <ng-container i18n="@@bookService">Book a service</ng-container>
  </h3>

  <ng-template [ngIf]="!!(provider.services$ | async)" [ngIfElse]="loader">
    <ng-template [ngIf]="(provider.services$ | async).length" [ngIfElse]="noAvailableSessions">
      <app-service-picker
        [services]="provider.services$ | async"
        (serviceSelect)="onServiceSelected($event)"></app-service-picker>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template [ngIf]="activeStep === ServiceDetailsFormSteps.TIME_PICKER">
  <div class="navigation" *ngIf="canManuallySelectService">
    <app-button
      data-qa-id="service-details-form-back-button"
      class="back-btn"
      className="btn-link"
      i18n="@@backButton"
      (click)="backToServices()">
      Back
    </app-button>
  </div>

  <h3 data-qa-id="service-details-form-choose-time-title" class="step-title" i18n="@@chooseTime">Choose time</h3>
  <h4 data-qa-id="service-details-form-service-name-title" class="step-sub-title">{{ selectedService?.name }}</h4>

  <app-service-schedule
    [scheduleOptions]="scheduleOptions"
    (requestMoreOptions)="requestMoreOptions.emit()"
    (timeSelect)="onTimeSelected($event)"></app-service-schedule>
</ng-template>

<ng-template #loader>
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</ng-template>

<ng-template #noAvailableSessions>
  <div class="no-available">
    <span i18n="@@noAvailableServices">No available services</span>
  </div>
</ng-template>
