<header class="container">
  <div class="row flex-nowrap no-gutters">
    <div class="col col-logo" [ngClass]="{ 'custom-grow': enableMenu }">
      <a
        class="awarenow-logo"
        *ngIf="logoUrl?.type === URL_TYPE.RELATIVE; else absolute"
        [routerLink]="'/' + logoUrl?.value">
        <img [src]="showWhiteLogoOnCoachesPage ? whiteLogo : mainLogo" alt="logo" />
      </a>
    </div>

    <div class="col">
      <nav class="menu">
        <app-link-menu *ngIf="enableMenu && canShowLinkMenu" [darkText]="true" [countOfMenuItems]="3"></app-link-menu>
      </nav>
    </div>
    <div class="col search-block d-none d-xl-block" *ngIf="canShowGuidesSearch">
      <div class="search-control">
        <button class="btn search-btn" (click)="searchQueryEntered()"></button>
        <input
          id="search"
          type="text"
          class="form-control search-input"
          [placeholder]="'searchCoaches' | transloco"
          [formControl]="searchControl"
          (keyup.enter)="searchQueryEntered()" />
      </div>
    </div>
    <div
      class="col flex-grow-0 guide-booking-link d-none d-lg-flex justify-content-end align-items-center"
      *ngIf="canShowBookLink$ | async">
      <label i18n="@@myBookingLink">My booking link</label>
      <app-book-link-provider class="book-link-provider"></app-book-link-provider>
    </div>

    <div class="col flex-grow-0 user-block">
      <app-user-menu></app-user-menu>
    </div>

    <div class="col flex-grow-0 col-hamburger d-block d-sm-none">
      <button
        (click)="burgerToggle()"
        (clickOutside)="burgerClickOutside()"
        class="hamburger hamburger--squeeze"
        [ngClass]="{ 'is-active': burgerDisplay }"
        type="button">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <div class="col navigation-wrapper" *ngIf="burgerDisplay">
        <app-sidebar-navigation></app-sidebar-navigation>
      </div>
    </div>
  </div>
</header>

<ng-template #absolute>
  <a class="awarenow-logo" target="_blank" href="{{ logoUrl?.value }}" rel="noopener">
    <img [src]="showWhiteLogoOnCoachesPage ? whiteLogo : mainLogo" alt="logo" />
  </a>
</ng-template>
