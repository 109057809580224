import {
  AfterViewInit,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Inject,
  ViewContainerRef
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { IWidget, IWidgetData, IWidgetService } from '../interface';
import { WIDGET_SERVICE } from '../tokens';

@Directive()
export abstract class WidgetDirective<T> implements IWidget<T>, AfterViewInit {
  // @ts-expect-error TS2416
  componentInstance: ComponentRef<T> | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  abstract readonly _viewContainerRef: ViewContainerRef;

  constructor(
    @Inject(WIDGET_SERVICE) readonly service: IWidgetService<T>,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _destroy$: UiDestroyService
  ) {}

  ngAfterViewInit(): void {
    this.service.view$
      .pipe(
        // eslint-disable-next-line id-length
        filter(v => !!v.component),
        takeUntil(this._destroy$)
      )
      .subscribe(model => {
        this.render(model);
      });
  }

  render(model: IWidgetData<T>): void {
    if (this.componentInstance) {
      this.componentInstance.destroy();
    }

    const componentFactory: ComponentFactory<T> = this._componentFactoryResolver.resolveComponentFactory(
      model.component
    );

    this.componentInstance = this._viewContainerRef.createComponent(componentFactory);

    // @ts-expect-error TS2769
    Object.entries(model.context).forEach(([param, value]) => {
      // @ts-expect-error TS2531
      this.componentInstance.instance[param] = value;
    });

    this.componentInstance.changeDetectorRef.detectChanges();
  }
}
