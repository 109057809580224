import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, PLATFORM_ID } from '@angular/core';
import { WindowProvider, GLOBAL_WINDOW } from './window-provider';
import { BrowserWindow } from './browser-window';

export function getBrowserWindow(browserWindow: BrowserWindow, platformId: Object): Window | Object {
  if (isPlatformBrowser(platformId)) {
    return browserWindow.window;
  }

  return {};
}

const browserWindowProvider: ClassProvider = { provide: WindowProvider, useClass: BrowserWindow };

const windowProvider: FactoryProvider = {
  provide: GLOBAL_WINDOW,
  useFactory: getBrowserWindow,
  deps: [WindowProvider, PLATFORM_ID]
};

export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
