import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import { IGuidePackageService } from '../../types';
import { AbsComponentServiceItem } from '@app/modules/guide-service/components/abs/abs-component-service-item';

@Component({
  selector: 'app-guide-package-service',
  templateUrl: './guide-package-service.component.html',
  styleUrls: ['../../styles/common.scss', './guide-package-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-program-service'
  }
})
export class GuidePackageServiceComponent<T extends IGuidePackageService> extends AbsComponentServiceItem<T> {
  locale: ILocale;

  constructor(private _localeService: LocaleService) {
    super();
    this.locale = this._localeService.getLocale();
  }

  get packageLink(): string | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.service ? `${this.locale.baseUrl}/packages/${(this.service as any).id}` : null;
  }
}
