<div class="guide-program-service-group-item-container">
  <div class="guide-service-group-item-details guide-program-service-group-item-details">
    <div class="group-item-badges">
      <ng-container [ngSwitch]="service.type">
        <app-guide-service-badge
          *ngSwitchCase="GuideServiceTypes.GROUP_SESSION"
          text="Group session"
          i18n-text="@@guideProgramServiceGroupItemGroupSessionBadge"
          class="group-session-badge"></app-guide-service-badge>

        <app-guide-service-badge
          *ngSwitchCase="GuideServiceTypes.SESSION"
          text="Individual session"
          i18n-text="@@guideProgramServiceGroupItemIndividualSessionBadge"
          class="individual-session-badge"></app-guide-service-badge>
      </ng-container>
    </div>
    <div class="program-module-name">
      <div class="program-module-service-name">
        {{ service.name }}
        <ng-container *ngIf="service.availableCount">({{ service.availableCount }})</ng-container>
      </div>
    </div>
  </div>
</div>
