import { ChangeDetectionStrategy, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { WidgetDirective } from '@app/cdk/widgets';
import { ISidenavCurrentView } from '@app/modules/sidenav';

@Component({
  selector: 'app-sidenav-current-widget',
  template: `<ng-container #element></ng-container>`,
  providers: [UiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavCurrentWidgetComponent extends WidgetDirective<ISidenavCurrentView> {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @ViewChild('element', { read: ViewContainerRef }) _viewContainerRef: ViewContainerRef;
}
