import { Pipe, PipeTransform } from '@angular/core';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ILocale } from '@env/locale.interface';
import { DateTime } from 'luxon';
import { SingleTemplate } from '@platformStyle/model/interfaces';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

@Pipe({
  name: 'sessionDuration'
})
export class SessionDurationPipe implements PipeTransform {
  constructor(private readonly _style: PlatformStyle) {}

  // @ts-expect-error TS7006
  transform(date, duration?: number, template: SingleTemplate = 'DATE, TIME'): string {
    let formattedDate;

    if (duration) {
      formattedDate = platformDateTimeRange(template, {
        value: [date],
        templateOptions: {
          durationInMinutes: duration,
          localeParams: this._style.locale
        }
      });
    } else {
      formattedDate = this._style.datetime(date, template, { timezone: DateTime.local().zoneName });
    }

    return formattedDate;
  }
}
