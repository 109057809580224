import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { IUser } from '@app/shared/interfaces/user';
import { GuideContact } from '@app/core/users/types';
import { SessionClient } from '@app/screens/guide/guide-sessions/types/session-client';
import { PuiAvatarSizes } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent {
  @Input()
  archived = false;

  @Input()
  disableOpacity = false;

  @Input()
  disableNamedUrl = false;

  @Input()
  photoSize = 44;

  @Input()
  avatarSize: PuiAvatarSizes = 'm';

  @Input()
  // @ts-expect-error TS2564
  user: IUser & GuideContact & SessionClient;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uiPayload: TemplateRef<any>;

  @Input()
  showEmailAndGuides = false;

  @Input()
  textLength = 44;

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get displayWith() {
    return this._displayWith;
  }

  readonly maxDisplayingAvatars = 3;

  readonly ellipsis = '...';

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set displayWith(fn: (user: IUser & GuideContact & SessionClient) => string) {
    if (!fn) {
      return;
    }
    this._displayWith = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  private _displayWith = (user: IUser & GuideContact & SessionClient) =>
    user.name?.trim() || user.email || user.contacts?.email || '';

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get userName() {
    const name = this.displayWith(this.user);
    return this.crop(name);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get userEmail() {
    return this.crop(this.user.email || this.user.contacts?.email || '');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  crop(line: string) {
    return line.length > this.textLength
      ? line.substring(0, this.textLength - this.ellipsis.length).concat(this.ellipsis)
      : line;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get isOpacity() {
    return this.archived && !this.disableOpacity;
  }
}
