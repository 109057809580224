<div class="d-none seo-tags" itemscope itemtype="https://schema.org/Blog">
  <span itemprop="dateCreated">{{ article?.isoDate }}</span>
  <span itemprop="dateModified">{{ article?.updatedAt }}</span>
</div>
<div class="cover-image" [style.backgroundImage]="article?.coverImage | safe: 'styleUrl'"></div>
<div class="addthis_inline_share_toolbox" *ngIf="isAddThisEnabled"></div>
<div class="content-container">
  <div class="date">{{ article?.date }}</div>

  <h1 class="title">{{ article?.title }}</h1>

  <div class="tags">
    <a href="javascript:;" *ngFor="let tag of article?.tags" class="tag" (click)="emitSelectTag(tag)">{{ tag.name }}</a>
  </div>

  <!-- class .ql-editor fixed missing styles for ql-* classes -->
  <div
    class="content ql-editor"
    appCapturePlatformOgMetaClick="embed-og-container"
    [innerHTML]="article?.content | safeHtml"
    (capturedClick)="handleOGClick($event)"></div>

  <div class="share"></div>
</div>

<ng-container *ngIf="isArticleAuthorGuide">
  <app-blog-article-author
    [user]="article?.author"
    (openBiography)="emitOpenAuthor($event)"
    (bookSession)="emitBookSession($event)"></app-blog-article-author>
</ng-container>
