import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertsService } from '../../modules/alerts/services/alerts.service';
import { AuthService } from '../auth/services/auth.service';
import { SocketService } from '../socket/socket.service';
import { AlertKey } from '@app/modules/alerts/types/alert';

@Injectable()
export class GuideProfileNotificationsService implements OnDestroy {
  private readonly INCOMPLETE_GUIDE_PROFILE = 'incomplete-guide-profile';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _alertsSubscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authSubscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _socketSubscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _doesAlertExist: boolean;

  constructor(private _alerts: AlertsService, private _auth: AuthService, private _socket: SocketService) {
    this._doesAlertExist = false;

    this._alertsSubscription = this._alerts.alerts$.subscribe(alerts => this.handleAlertsChange(alerts));
    this._socketSubscription = this._socket
      .onGuideProfileNotification()
      .subscribe(data => this.handleGuideProfileNotification(data));
    this._authSubscription = this._auth.incompleteProfileNotification$.subscribe(incompleteSettings =>
      this.showIncompleteGuideProfileNotification(incompleteSettings)
    );
  }

  ngOnDestroy(): void {
    if (this._alertsSubscription) {
      this._alertsSubscription.unsubscribe();
    }

    if (this._authSubscription) {
      this._authSubscription.unsubscribe();
    }

    if (this._socketSubscription) {
      this._socketSubscription.unsubscribe();
    }
  }

  // ATTENTION: this empty method is a hack to make angular injector create instance of this class, init logic left in constructor
  init(): void {}

  hideIncompleteGuideProfileNotification(): void {
    this._alerts.closeByTemplate(AlertKey.INCOMPLETE_GUIDE_PROFILE);
  }

  showIncompleteGuideProfileNotification(incompleteSettings: string[]): void {
    this._alerts.template(AlertKey.INCOMPLETE_GUIDE_PROFILE, this.createIncompleteSettingsMessage(incompleteSettings));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleAlertsChange(alerts: any[]): void {
    this._doesAlertExist = alerts.some(alert => alert.template === this.INCOMPLETE_GUIDE_PROFILE);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleGuideProfileNotification(data: any): void {
    const { isComplete, incompleteSettings } = data;

    this._auth.addToUser({ incompleteSettings });

    if (isComplete && !this._doesAlertExist) {
      return;
    }

    if (isComplete && this._doesAlertExist) {
      this.hideIncompleteGuideProfileNotification();
      this._auth.addToUser({ incompleteSettings: undefined });

      return;
    }

    if (!isComplete && this._doesAlertExist) {
      this.hideIncompleteGuideProfileNotification();
    }

    this.showIncompleteGuideProfileNotification(incompleteSettings);
  }

  private createIncompleteSettingsMessage(incompleteSettings: string[]): string {
    const incompleteSettingsAliases = {
      language: 'Primary Language',
      licenseNoAndState: 'License No. and State',
      namedUrl: 'Personal Link',
      photo: 'Profile Picture',
      pricePerHour: 'Your Fee',
      salesText: 'Your Story',
      shortQuote: 'Short Description',
      specialization: 'Specialty',
      tags: 'Tags',
      timezone: 'Location'
    };

    // @ts-expect-error TS7053
    // eslint-disable-next-line id-length
    return incompleteSettings.map(s => incompleteSettingsAliases[s]).join(', ');
  }
}
