import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { PublicClientReview } from '@app/screens/guide-public-profile/guide-public-profile.component';

@Component({
  selector: 'app-public-profile-reviews',
  templateUrl: './public-profile-reviews.component.html',
  styleUrls: ['./public-profile-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicProfileReviewsComponent implements AfterViewInit {
  readonly MINIMIZED_REVIEW_LENGTH = 400;
  carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  @Input()
  reviews: PublicClientReview[] = [];

  @Input()
  mobile = false;

  @Input()
  hasMoreReviews!: boolean;

  @Input()
  reviewReadySessionId!: number;

  @Output()
  leaveReview = new EventEmitter<number>();

  @Output()
  loadMoreReviews = new EventEmitter();

  openReviews: { [key: number]: number } = {};

  constructor(private _cdr: ChangeDetectorRef) {}

  emitLeaveReview(): void {
    this.leaveReview.emit(this.reviewReadySessionId);
  }

  toggleReviewLength(reviewId: number): void {
    if (this.openReviews[reviewId]) {
      delete this.openReviews[reviewId];
    } else {
      this.openReviews[reviewId] = 1;
    }
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  checkIfNeedMore(event: { activePoint: number }): void {
    if (this.hasMoreReviews && event.activePoint === this.reviews.length - 2) {
      this.loadMoreReviews.emit();
    }
  }
}
