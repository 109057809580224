import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { bookingFrequencyOptions } from '@app/modules/session-forms/forms/restrictions-form/data';
import {
  BookingLimit,
  BookingLimitsType,
  RestrictionsFormOption
} from '@app/modules/session-forms/forms/restrictions-form/types';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { LimitBookingFrequency } from '@app/screens/guide/guide-sessions-templates/types';
import { integerValidator } from '@app/shared/form-validators/integer.validator';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

function createBookingLimitFormGroup(
  fb: FormBuilder,
  bookingLimitsNum: number,
  bookingLimitsType: BookingLimitsType | undefined
): FormGroup {
  return fb.group({
    bookingLimitsNum: [
      bookingLimitsNum,
      [
        customValidatorWrapper(Validators.required, `Value must be greater than or equal to 1.`),
        customValidatorWrapper(Validators.min(1), `Value must be greater than or equal to 1.`),
        integerValidator(`Value must be greater than or equal to 1.`)
      ]
    ],
    bookingLimitsType: [bookingLimitsType]
  });
}

@Component({
  selector: 'app-limit-booking-frequency',
  templateUrl: './limit-booking-frequency.component.html',
  styleUrls: ['./limit-booking-frequency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LimitBookingFrequencyComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LimitBookingFrequencyComponent),
      multi: true
    }
  ]
})
export class LimitBookingFrequencyComponent implements ControlValueAccessor, Validator {
  form: FormArray = this.fb.array([]);

  bookingFrequencyOptions = bookingFrequencyOptions;

  enabled = this.fb.control(false);

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly fb: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  onTouched: () => void = () => {};

  writeValue(limitBookingFrequency: LimitBookingFrequency): void {
    const limitBookingFrequencyArray = Object.entries(limitBookingFrequency || {});

    this.form.clear();
    this.enabled.setValue(!!limitBookingFrequencyArray.length);
    limitBookingFrequencyArray.forEach(([bookingLimitsType, bookingLimitsNum]) => {
      this.form.push(createBookingLimitFormGroup(this.fb, bookingLimitsNum, bookingLimitsType as BookingLimitsType));
    });

    this.cdRef.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      fn(value);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    const bookingLimitsNumErrors = this.form.controls
      .map(control => control.get('bookingLimitsNum')?.errors)
      .reduce((acc, cur) => ({ ...acc, ...cur }), {}) as ValidationErrors | null;

    return bookingLimitsNumErrors;
  }

  limitBookingToggle(checked: boolean): void {
    this.enabled.setValue(checked);

    if (checked) {
      this.addFrequencyControl();
    } else {
      this.form.clear();
    }
  }

  addFrequencyControl(bookingLimitsNum: number = 1, bookingLimitsType?: BookingLimitsType): void {
    const [firstNotSelectedOption] = this.notSelectedOptions();
    if (!firstNotSelectedOption) {
      return;
    }

    this.form.push(
      createBookingLimitFormGroup(
        this.fb,
        bookingLimitsNum,
        bookingLimitsType || (firstNotSelectedOption.value as BookingLimitsType)
      )
    );
  }

  removeFrequencyControl(idx: number): void {
    this.form.removeAt(idx);

    if (!this.form.controls.length) {
      this.enabled.setValue(false);
    }
  }

  notSelectedOptions(choosedType?: BookingLimitsType): RestrictionsFormOption[] {
    const bookingLimitsTypes = this.form.value.map((el: BookingLimit) => el.bookingLimitsType);

    return this.bookingFrequencyOptions.filter(
      item => item.value === choosedType || !bookingLimitsTypes.includes(item.value)
    );
  }
}
