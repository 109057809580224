import { Directive, TemplateRef } from '@angular/core';

/**
 * Content for a `mat-step` that will be rendered lazily.
 */
@Directive({
  selector: 'ng-template[matStepContent]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MatStepContent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public _template: TemplateRef<any>) {}
}
