export enum BookingTypes {
  INDIVIDUAL,
  INDIVIDUAL_WITH_LOGIN,
  TEAM,
  TEAM_HOSTS,
  SESSION,
  SESSION_WITH_AUTHORIZATION,
  SESSION_CANCEL,
  SESSION_RESCHEDULE,
  SESSION_ACCEPT,
  SESSION_ACCEPTED,
  SESSION_PAY_ACCEPTED,
  CHOOSE_HOST_FROM_DASHBOARD,
  CHOOSE_TEAM_WITH_HOST_WITH_AUTHORIZATION,
  CHOOSE_TEAM_HOST_WITH_AUTHORIZATION,
  CANCELLATION = 'CANCELLATION',
  RESCHEDULE = 'RESCHEDULE',
  ENROLL_PACKAGE = 'enrollPackage',
  PURCHASE_PROGRAM = 'purchaseProgram'
}
