import { Component } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss']
})
export class LandingLayoutComponent {
  shownMenu = false;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggledMenu(status: boolean) {
    this.shownMenu = status;
  }
}
