import {
  IMetaEvent,
  IMetaGuideClient,
  IMetaGuideContact,
  INote,
  INotesMetaRecording,
  MetaShared
} from '@app/shared/interfaces/notes';

// tslint:disable-next-line:no-empty-interface
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideNote extends INote {
  metaGuideClients: IMetaGuideClient[] | null;
  metaGuideContacts: IMetaGuideContact | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideNotesResponse {
  notes: IGuideNote[];
  cursor: string | null;
}

export enum GuideNotesAccessType {
  OWNER = 1,
  VIEWER = 2
}

export class GuideNote {
  readonly id: number;

  createdAt: string;

  updatedAt: string;

  pinned: boolean;

  pinnedAt: string | null;

  content: string;

  contentDeltaFormat: string;

  contentText: string;

  metaGuideClients: IMetaGuideClient[] | null;

  metaGuideContacts: IMetaGuideContact | null;

  metaSharedWith: MetaShared[];

  metaSharedBy: MetaShared;

  metaRecording?: INotesMetaRecording;

  metaEvent: IMetaEvent;

  constructor({
    id,
    content,
    contentDeltaFormat,
    contentText,
    createdAt,
    updatedAt,
    pinned,
    pinnedAt,
    metaSharedWith,
    metaSharedBy,
    metaGuideClients,
    metaGuideContacts,
    metaRecording,
    metaEvent
  }: IGuideNote) {
    this.id = id;
    this.content = content;
    this.contentDeltaFormat = contentDeltaFormat;
    this.contentText = contentText;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.pinned = pinned;
    this.pinnedAt = pinnedAt;
    this.metaGuideClients = metaGuideClients;
    this.metaGuideContacts = metaGuideContacts;
    this.metaSharedWith = metaSharedWith.map(meta => new MetaShared(meta));
    this.metaSharedBy = new MetaShared(metaSharedBy);
    this.metaRecording = metaRecording;
    // @ts-expect-error TS2322
    this.metaEvent = metaEvent;
  }
}
