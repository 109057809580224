import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IProgramDetails } from '@app/screens/guide/guide-programs/types/program/base-program';
import { PackageService } from '@app/shared/interfaces/services';
import { environment } from '@env/environment';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';
import { ADMIN_ROUTE_PATH } from '@app/routes-consts';

@Component({
  selector: 'app-remove-service-blockers-modal',
  templateUrl: './remove-service-blockers-modal.component.html',
  styleUrls: ['./remove-service-blockers-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveServiceBlockersModalComponent {
  readonly route: string;
  readonly servicesPath: string;

  programs: IProgramDetails[] = [];
  packages: PackageService[] = [];

  // @ts-expect-error TS7006
  constructor(readonly dialogRef: PuiDialogRef, @Inject(PUI_DIALOG_DATA) private readonly _data) {
    this.programs = _data.programs;
    this.packages = _data.packages;
    this.route = _data.isPlatformAdmin ? ADMIN_ROUTE_PATH : environment.guideRoute;
    this.servicesPath = _data.isPlatformAdmin ? 'default-service' : 'services';
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  trackById(entity) {
    return entity.id;
  }
}
