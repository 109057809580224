import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Component({
  selector: 'app-service-label',
  templateUrl: './service-label.component.html',
  styleUrls: ['./service-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceLabelComponent {
  @Input()
  // @ts-expect-error TS2564
  type: GuideServiceTypes;
}
