<div class="wrapper">
  <a
    class="actions-menu-button"
    [ngClass]="{ disabled: !actions || !actions.length }"
    href="javascript:;"
    tabindex="0"></a>
  <ul class="actions">
    <li class="action" *ngFor="let action of actions" (click)="emit(action)" [ngClass]="action.class">
      {{ action.label }}
    </li>
  </ul>
</div>
