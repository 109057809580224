import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingCancellationDetailsComponent } from './booking-cancellation-details.component';
import { PuiButtonModule, PuiInputModule, PuiInputRadioModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { SessionDetailsModule } from '@appWidget/modules/booking/common/components/session-details/session-details.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BookingLoaderModule } from '@appWidget/modules/booking/components/booking-loader/booking-loader.module';

@NgModule({
  declarations: [BookingCancellationDetailsComponent],
  imports: [
    CommonModule,
    PuiTypographyModule,
    PuiButtonModule,
    SessionDetailsModule,
    PuiInputModule,
    ReactiveFormsModule,
    PuiInputRadioModule,
    BookingLoaderModule
  ],
  exports: [BookingCancellationDetailsComponent]
})
export class BookingCancellationDetailsModule {}
