import { createAction, props } from '@ngrx/store';
import { CLIENTS_ASSIGNED_GUIDES_STORE } from './commons/consts';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import type { IWorkspaceMember } from '@app/modules/workspaces/types';

export const resetAll = createAction(`[${CLIENTS_ASSIGNED_GUIDES_STORE}] Reset`);
export const fetchAssignedGuidesByClientId = createAction(
  `[${CLIENTS_ASSIGNED_GUIDES_STORE}] fetch assigned guides by client id`,
  props<{
    id: CRMClient['id'];
  }>()
);
export const fetchAssignedGuidesByClientIdSuccess = createAction(
  `[${CLIENTS_ASSIGNED_GUIDES_STORE}] Success load assigned guides by client id`,
  props<{
    id: CRMClient['id'];
    guides: IWorkspaceMember[];
  }>()
);
export const fetchAssignedGuidesByClientIdError = createAction(
  `[${CLIENTS_ASSIGNED_GUIDES_STORE}] Error while fetching assigned guides by client id`
);

export const assignGuidesForClient = createAction(
  `[${CLIENTS_ASSIGNED_GUIDES_STORE}] Assign guides for client`,
  props<{ id: CRMClient['id']; guides: IWorkspaceMember['userId'][] }>()
);
export const assignGuidesForClientSuccess = createAction(
  `[${CLIENTS_ASSIGNED_GUIDES_STORE}] Guides assigned for client`,
  props<{ id: CRMClient['id']; guides: IWorkspaceMember[] }>()
);
export const assignGuidesForClientError = createAction(
  `[${CLIENTS_ASSIGNED_GUIDES_STORE}] Error while assigning guides for client`
);
