<section class="wiki-container">
  <div class="row no-gutters">
    <h2 class="wiki-title col-md-10 offset-md-2">{{ platformName | titlecase }} wiki</h2>
  </div>
  <div class="row no-gutters wiki-management-section">
    <div class="tabs col-md-10 offset-md-2">
      <button
        type="button"
        class="wiki-tab-btn"
        [ngClass]="{ active: activeTab === Tabs.ALPHABET }"
        (click)="activeTab = Tabs.ALPHABET">
        alphabetically
      </button>
      <button
        type="button"
        class="wiki-tab-btn"
        [ngClass]="{ active: activeTab === Tabs.HOLISTIC_PRACTICE }"
        (click)="activeTab = Tabs.HOLISTIC_PRACTICE">
        by holistic practice
      </button>
    </div>
  </div>
  <section class="wiki-terms-section">
    <div *ngFor="let termsGroup of activeTabTerms[activeTab]" class="row no-gutters wiki-terms-group">
      <div class="wiki-category col-md-2">{{ termsGroup.name }}</div>
      <div class="wiki-group-terms col-md-10">
        <app-wiki-term *ngFor="let term of termsGroup.values" [term]="term"></app-wiki-term>
      </div>
    </div>
  </section>
</section>
