<div>
  <h2 data-qa-id="pay-with-selector-pay-with-title" class="header" i18n="@@payWith">Pay with</h2>

  <div class="loading-place" *ngIf="isLoading">
    <div class="loading"></div>
  </div>

  <div *ngIf="!!savedPaymentMethods.length && isCardEnabled" class="saved-payment-container">
    <p
      data-qa-id="pay-with-selector-saved-payment-options-title"
      class="sub-header"
      i18n="@@payWithSelectorSavedPaymentOptions">
      Saved payment options
    </p>
    <div
      *ngFor="let paymentMethod of savedPaymentMethods; let index = index"
      class="payment saved-payment"
      [ngClass]="{ disabled: isLoading }"
      [attr.data-qa-id]="'pay-with-selector-saved-payment-method-list-item-' + index"
      (click)="selectCard(paymentMethod.id)">
      <div
        class="logo"
        [attr.data-qa-id]="'pay-with-selector-saved-payment-method-' + paymentMethod.type + '-icon-' + index"
        [ngClass]="{
          visa: paymentMethod.type === 'visa',
          mastercard: paymentMethod.type === 'mastercard',
          paypal: paymentMethod.type === 'paypal'
        }"></div>
      {{ paymentMethod.label }}
      <i class="arrow"></i>
    </div>
  </div>

  <div appRadioGroup [(ngModel)]="selectedPaymentMethod">
    <p
      class="sub-header"
      *ngIf="savedPaymentMethods.length && isCardEnabled"
      i18n="@@payWithSelectorOtherPaymentMethods">
      Other payment methods
    </p>

    <div class="payment new-payment" *ngIf="showWallet">
      <label class="radio-label" data-qa-id="pay-with-selector-balance-list-item">
        <input appRadioInput type="radio" [value]="PaymentMethods.WALLET" />
        <span class="checkmark"></span>
        <ng-container i18n="@@balance">Balance</ng-container>
        : {{ balance | platformCurrency }}
      </label>
      <app-pay-with-wallet
        *ngIf="selectedPaymentMethod === PaymentMethods.WALLET"
        class="add-card-form"
        (submitEvent)="paymentSelected.emit()"></app-pay-with-wallet>
    </div>
    <div
      class="payment new-payment"
      [ngClass]="{ selected: selectedPaymentMethod === PaymentMethods.CREDIT_CARD }"
      *ngIf="isCardEnabled">
      <label class="radio-label" data-qa-id="pay-with-selector-credit-card-list-item">
        <input appRadioInput type="radio" [value]="PaymentMethods.CREDIT_CARD" />
        <span class="checkmark"></span>
        <ng-container i18n="@@creditCard">Credit Card</ng-container>
      </label>

      <div *ngIf="selectedPaymentMethod === PaymentMethods.CREDIT_CARD" class="add-card-form">
        <app-pay-with-stripe-new
          *ngIf="paymentGateway === PaymentGateways.STRIPE"
          [submitText]="submitButtonText"
          [isFetching]="isLoading"
          [disableSavedCards]="disableSavedCards || disableNewCardSave"
          (submitEvent)="paymentSelected.emit()"></app-pay-with-stripe-new>
      </div>
    </div>

    <div class="payment new-payment" *ngIf="isOtherPaymentEnabled">
      <label class="radio-label">
        <input
          appRadioInput
          type="radio"
          [value]="PaymentMethods.OTHER_PAYMENT"
          (click)="handleOtherRadioInputClick($event)" />
        <span class="checkmark"></span>
        <ng-container i18n="@@paySelectorWithBankTransfer">Bank transfer</ng-container>
      </label>
      <div *ngIf="selectedPaymentMethod === PaymentMethods.OTHER_PAYMENT" class="add-card-form">
        <app-pay-with-link
          *ngIf="isPaymentLinkEnabled"
          [paymentLink]="paymentOptions.paymentLink"
          (submitEvent)="paymentSelected.emit()"></app-pay-with-link>

        <app-pay-with-requisites
          *ngIf="isPaymentRequisitesEnabled"
          [paymentRequisites]="paymentOptions.paymentRequisites"
          [purchaseButtonText]="purchaseButtonText"
          (submitEvent)="paymentSelected.emit()"></app-pay-with-requisites>
      </div>
    </div>
  </div>

  <p class="confirm-text" data-qa-id="pay-with-selector-confirm-text">
    <ng-container i18n="@@confirmTermsAndPrivacyForPayment">
      By continuing you confirm that you accept the {{ platformName }}
      <a href="{{ config.urlTerms }}" target="_blank" rel="noopener">Terms & Conditions</a>
      and
      <a href="{{ config.urlPrivacy }}" target="_blank" rel="noopener">Privacy Policy</a>
      .
    </ng-container>
    <ng-container *ngIf="config.urlCancellationPolicy" i18n="@@cancellationPolicyNote">
      And here you can view
      <a href="{{ config.urlCancellationPolicy }}" target="_blank" rel="noopener">Cancellation Policy</a>
    </ng-container>
  </p>

  <ng-container *ngIf="isSubscription && isPlatformSubscription && hasTrial">
    <div class="alert-warn" data-qa-id="pay-with-selector-alert-warning-text">
      <app-alert-message color="warn" i18n="@@payWithSelectorSubscriptionPaymentNote">
        Please note that your payment will be processed immediately, your free trial will end and you'll subscribe to
        the plan right away
      </app-alert-message>
    </div>
  </ng-container>
</div>
