import { Injectable, OnDestroy } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ServiceScheduleApiService } from '@app/core/schedule/service-schedule-api.service';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { ISchedule, IScheduleOptions } from '@app/modules/schedule-boards';
import { IServerGuideSchedulesResponse, IServiceScheduleOptions } from '@app/core/schedule/types';
import { ServiceScheduleFacadeService } from '@app/core/schedule/service-schedule-facade.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

export type ScheduleTypes = 'guideSchedule' | 'serviceSchedule';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const toWorkspaceKey = (guide: Readonly<{ id: number; workspaceId: number }>) => `workspace_${guide.workspaceId}`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const toGuideWorkspaceKey = (guide: Readonly<{ id: number; workspaceId: number }>) =>
  `${guide.id},${guide.workspaceId}`;

@Injectable()
export class ScheduleProxyService implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeGuideWorkspaceKey: string;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeServiceId: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideSchedules: { [guideWorkspaceKey: string]: ReplaySubject<ISchedule> } = {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _serviceSchedules: { [serviceId: number]: ReplaySubject<ISchedule> } = {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleType$ = new ReplaySubject<ScheduleTypes>(1);

  get schedule$(): Observable<IScheduleItem[] | { ranges: IScheduleItem[] }> {
    return this._scheduleType$.pipe(
      switchMap(scheduleType =>
        scheduleType === 'guideSchedule'
          ? this._guideSchedules[this._activeGuideWorkspaceKey]
          : this._serviceSchedules[this._activeServiceId]
      )
    );
  }

  constructor(
    private readonly _scheduleFacade: ServiceScheduleFacadeService,
    private readonly _serviceScheduleApi: ServiceScheduleApiService,
    private readonly runtimeConfig: RuntimeConfigService
  ) {}

  ngOnDestroy(): void {
    Object.values(this._guideSchedules).forEach((schedule$: ReplaySubject<ISchedule>) => schedule$.complete());
    Object.values(this._serviceSchedules).forEach((schedule$: ReplaySubject<ISchedule>) => schedule$.complete());
  }

  activateGuideSchedule(options: Readonly<IScheduleOptions>): void {
    this._activeGuideWorkspaceKey = options.requiresHostSelection
      ? toWorkspaceKey(options.guide)
      : toGuideWorkspaceKey(options.guide);

    if (!this._guideSchedules[this._activeGuideWorkspaceKey]) {
      // eslint-disable-next-line rxjs/no-ignored-replay-buffer
      this._guideSchedules[this._activeGuideWorkspaceKey] = new ReplaySubject<{ ranges: IScheduleItem[] }>();
    }

    this.refreshGuideSchedule(options, this._activeGuideWorkspaceKey);

    this._scheduleType$.next('guideSchedule');
  }

  activateGuideServiceSchedule(options: Readonly<IScheduleOptions>): void {
    this._activeGuideWorkspaceKey = toGuideWorkspaceKey(options.guide);
    // @ts-expect-error TS2322
    this._activeServiceId = options.serviceId;

    // @ts-expect-error TS2538
    if (!this._serviceSchedules[options.serviceId]) {
      // @ts-expect-error TS2538
      // eslint-disable-next-line rxjs/no-ignored-replay-buffer
      this._serviceSchedules[options.serviceId] = new ReplaySubject<ISchedule>();

      if (options.requiresHostSelection) {
        this._serviceScheduleApi
          .getWorkspaceServiceSchedule$({
            // @ts-expect-error TS2322
            serviceId: options.serviceId,
            workspaceId: options.guide.workspaceId
          })
          .pipe(map((ranges: IScheduleItem[]) => ({ ranges })))
          // @ts-expect-error TS2538
          .subscribe(schedule => this._serviceSchedules[options.serviceId].next(schedule));
      } else {
        this._serviceScheduleApi
          // @ts-expect-error TS2345
          .getGuideServiceSchedule$(options.guide.id, options.serviceId)
          .pipe(map((ranges: IScheduleItem[]) => ({ ranges })))
          // @ts-expect-error TS2538
          .subscribe(schedule => this._serviceSchedules[options.serviceId].next(schedule));
      }
    }

    this._scheduleType$.next('serviceSchedule');
  }

  private refreshGuideSchedule(options: Readonly<IScheduleOptions>, key: string): void {
    const now = DateTime.local();

    const scheduleOptions: IServiceScheduleOptions = {
      ...options,
      range: {
        start: now.toISO(),
        end: now.plus({ months: this.runtimeConfig.get('availabilityMaxBoundaryInMonths') }).toISO()
      },
      options: { duration: options.duration }
    };

    this._scheduleFacade
      .loadSchedule$(scheduleOptions)
      .pipe(map((data: IServerGuideSchedulesResponse) => ({ ranges: data.events })))
      .subscribe((schedule: { ranges: IScheduleItem[] }) => this._guideSchedules[key].next(schedule));
  }
}
