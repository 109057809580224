<form [formGroup]="settingsForm">
  <section class="section form-section row" *ngIf="!isAdmin">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 class="section-title" i18n="@@sharingSettings">Sharing settings</h3>
    </div>

    <div class="col-md-5 padding-right-md-05">
      <app-program-show-settings-control
        [label]="settingsFormControlsTexts.showBook.label"
        [description]="settingsFormControlsTexts.showBook.description"
        formControlName="isHiddenForBook"
        [calculateValueFn]="calculateIsHiddenForBookToggleState"></app-program-show-settings-control>

      <app-program-show-settings-control
        [label]="settingsFormControlsTexts.showMarketplace.label"
        [description]="settingsFormControlsTexts.showMarketplace.description"
        formControlName="isPublic"></app-program-show-settings-control>
    </div>
  </section>

  <section class="section form-section row">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 class="section-title" i18n="@@startAndDuration">Start and duration</h3>

      <div class="note" i18n="@@startAndDurationNote">Start date and duration of your program.</div>
    </div>

    <div class="col-md-5 padding-right-md-05">
      <div class="form-group start-type-group">
        <label class="label top-label">
          <ng-container i18n="@@theProgramStarts">The program starts</ng-container>:
        </label>

        <div appRadioGroup formControlName="startType">
          <div
            *ngFor="let type of options.startTypes; index as i"
            class="start-type-wrapper"
            [class.with-datepicker]="type.value === 'program_release'">
            <input appRadioInput type="radio" [value]="type.value" [id]="'program_start_type' + i" />
            <label
              [attr.data-qa-id]="'program-settings-start-type-radio-button-' + type.value"
              class="radio-label"
              [for]="'program_start_type' + i">
              {{ type.label }}
            </label>

            <ng-container *ngIf="type.value === 'program_release'">
              <div class="datepicker-wrapper" (clickOutside)="datePicker.close()">
                <input
                  formControlName="startDate"
                  id="program_start_date"
                  type="text"
                  ngbDatepicker
                  #datePicker="ngbDatepicker"
                  navigation="arrows"
                  [firstDayOfWeek]="7"
                  (click)="datePicker.toggle()"
                  [autoClose]="'inside'"
                  [minDate]="minDate"
                  [readonly]="true"
                  [disabled]="isFormDisabled"
                  class="date-control form-control" />
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="program_length" class="label top-label">
          <ng-container i18n="@@programDuration">Program duration</ng-container>
          :
        </label>
        <input
          formControlName="length"
          type="text"
          id="program_length"
          class="form-control"
          data-qa-id="program-settings-program-duration-input" />
      </div>
    </div>
  </section>

  <section class="section row">
    <app-guide-service-pricing-editor
      class="col-12"
      [form]="settingsForm.get('pricingFormGroup')"
      type="program"
      [subscriptionInfo]="subscriptionInfo"
      [disablePaymentTypeChange]="!!allCustomersCount || !author.permissions.canEditSettings"
      (deactivateOffer)="deactivateProgram.emit($event)"
      (pricingChanged)="trackPricingChange($event)"></app-guide-service-pricing-editor>
  </section>

  <section class="section form-section row">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 class="section-title" i18n="@@programSettingsModuleCompletionTitle">Completion options</h3>
    </div>

    <div class="col-md-5 padding-right-md-05">
      <div class="form-group">
        <div appRadioGroup formControlName="moduleCompletionType">
          <div class="module-completion-type-wrapper">
            <input appRadioInput type="radio" [value]="ModuleCompletionTypes.AUTO" id="module_completion_type_auto" />

            <label
              class="radio-label"
              for="module_completion_type_auto"
              i18n="@@programSettingsModuleCompletionTypeAutoLabel">
              Complete when viewed
            </label>

            <div class="note switch-note" i18n="@@programSettingsModuleCompletionTypeAutoNote">
              Participants must visit the selected module to complete it.
            </div>
          </div>

          <div class="module-completion-type-wrapper">
            <input
              appRadioInput
              type="radio"
              [value]="ModuleCompletionTypes.CLIENT"
              id="module_completion_type_client" />

            <label
              class="radio-label"
              for="module_completion_type_client"
              i18n="@@programSettingsModuleCompletionTypeButtonLabel">
              Participant marks as complete
            </label>

            <div class="note switch-note" i18n="@@programSettingsModuleCompletionTypeClientNote">
              Participants must click a "Mark as complete" button that will appear at the bottom of the selected module
              to complete it.
            </div>
          </div>

          <div class="module-completion-type-wrapper">
            <input appRadioInput type="radio" [value]="ModuleCompletionTypes.GUIDE" id="module_completion_type_guide" />

            <label
              class="radio-label"
              for="module_completion_type_guide"
              transloco="programSettingsModuleCompletionTypePractitionerManualLabel">
              Practitioner marks as complete
            </label>

            <div class="note switch-note" transloco="programSettingsModuleCompletionTypePractitionerNote">
              You or an practitioner of a module must mark the module as completed manually.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section form-section row" *ngIf="false">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 class="section-title">Content policy</h3>
    </div>

    <div class="col-md-5 padding-right-md-05">
      <div class="form-group">
        <app-checkbox formControlName="disableContentAfterComplete" i18n="@@removeAccessOnComplete">
          Remove access to content after clients complete the program
        </app-checkbox>
        <div class="note switch-note" i18n="@@removeAccessOnCompleteNote">
          After clients complete the program, they will no longer have access to the curriculum.
        </div>
      </div>

      <div class="form-group">
        <app-checkbox formControlName="disableContentAfterRemoval" i18n="@@removeAccessForExpelledClients">
          Remove access to content for expelled clients.
        </app-checkbox>
        <div class="note switch-note" i18n="@@removeAccessForExpelledClientsNote">
          If you expel a client, they will no longer have access to the curriculum.
        </div>
      </div>
    </div>
  </section>
</form>
