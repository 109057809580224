import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { PreloaderService } from '@app/core/preloader/preloader.service';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { NotificationsService } from 'angular2-notifications';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable()
export class GuideAvailabilityService {
  private readonly ENDPOINT = `${config.apiPath}/user/guide/schedules/free-time`;

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _preloader: PreloaderService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFreeTimeRanges$(date: string, templateId?: number | null): Observable<any[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const paramsObject: any = { date };

    if (templateId) {
      paramsObject.templateId = templateId;
    }

    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: paramsObject
    });

    return this.execute$(
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<{ freeTimeRanges: any[] }>(this.ENDPOINT, { params })
        .pipe(map(({ freeTimeRanges }) => freeTimeRanges)),
      'Failed to load availability slots'
    );
  }

  private execute$<T>(httpRequest$: Observable<T>, errorMessage?: string): Observable<T> {
    this._preloader.loadingStart();
    return httpRequest$.pipe(
      catchError(error => {
        if (errorMessage) {
          this._notifications.error(errorMessage);
        }
        return throwError(error);
      }),
      finalize(() => this._preloader.loadingEnd())
    );
  }
}
