import { Routes } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordSentComponent } from './forgot-password-sent/forgot-password-sent.component';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';

export const routes: Routes = [
  {
    path: '',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset',
    component: ForgotPasswordResetComponent
  },
  {
    path: 'sent',
    component: ForgotPasswordSentComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];
