import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { SchedulingStoreService } from '@app/modules/service-scheduling/services/scheduling-store.service';
import {
  selectChosenClients,
  selectServiceSchedulingAllServices
} from '@app/modules/service-scheduling/store/service-scheduling/service-scheduling.selectors';
import { GuideServices } from '@app/modules/service-scheduling/types';
import {
  filterByEnrolledClients,
  filterByHost,
  filterByNotCompleted,
  filterByNotUsed,
  filterByServiceType,
  filterByText,
  filterSessionWithSeats,
  isProposeServiceFlow,
  primaryFilterByAnyEnrolledClients,
  primaryFilterByFreeOfferSeats,
  primaryFilterById,
  primaryFilterByServiceType,
  sortServicesByName
} from '@app/modules/service-scheduling/utils';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { Store } from '@ngrx/store';

import { ServiceSchedulingLSService } from './scheduling-local-storage.service';
import { ScheduleSessionPrimaryFilters } from './service-scheduling.service';

@Injectable({ providedIn: 'root' })
export class ServiceSchedulingFiltersService {
  readonly searchFilter$ = new BehaviorSubject(null);
  readonly hostsFilter$ = new BehaviorSubject<{ [key: string | number]: boolean } | null>(null);
  readonly serviceTypeFilter$ = new BehaviorSubject<{ [key: string]: boolean } | null>(null);

  readonly primaryFilters$ = new BehaviorSubject<ScheduleSessionPrimaryFilters>(
    this.schedulingLocalStorageService.getSeriviceSchedulingFilters() || {
      serviceIds: [],
      serviceTypes: []
    }
  );
  readonly servicesStore$ = this.store$.select(selectServiceSchedulingAllServices);

  readonly chosenClients$ = this.store$.select(selectChosenClients).pipe(distinctUntilChanged());

  readonly allServices$ = combineLatest([
    this.store$.select(selectServiceSchedulingAllServices),
    this.primaryFilters$.asObservable().pipe(
      distinctUntilChanged(),
      tap(value => this.schedulingLocalStorageService.setServiceSchedulingFilters(value))
    )
  ]).pipe(
    map(([services, { serviceIds, serviceTypes }]) =>
      services
        .filter(service => primaryFilterByServiceType(service, serviceTypes))
        .filter(service => primaryFilterById(service, serviceIds))
    )
  );

  readonly resetTrigger$ = new Subject();

  readonly filteredServices$ = combineLatest([
    this.searchFilter$,
    this.hostsFilter$,
    this.serviceTypeFilter$,
    this.chosenClients$,
    this.allServices$,
    this.schedulingStoreService.stepsHistory$
  ]).pipe(
    map(([searchText, hostsById, serviceTypes, chosenClients, services, stepsHistory]) => {
      const filteredByText = filterByText(services, searchText);
      const filteredByHost = filterByHost(filteredByText, hostsById);
      const filteredByServiceType = filterByServiceType(filteredByHost, serviceTypes);

      if (isProposeServiceFlow(stepsHistory)) {
        return this.proposeServicesList(filteredByServiceType, chosenClients);
      }

      return this.serviceSchedulingList(filteredByServiceType, chosenClients);
    })
  );

  constructor(
    private readonly store$: Store,
    private readonly schedulingLocalStorageService: ServiceSchedulingLSService,
    private readonly schedulingStoreService: SchedulingStoreService
  ) {}

  selectClients(clients: CRMClient[]): void {
    const selectedClientsIds = clients.map(({ id }) => +id);

    this.schedulingStoreService.setChosenClientsIds(selectedClientsIds);
  }

  resetFiltersData(): void {
    this.searchFilter$.next(null);
    this.hostsFilter$.next(null);
    this.serviceTypeFilter$.next(null);
  }

  proposeServicesList(services: GuideServices.RootObject[], chosenClients: CRMClient[]): GuideServices.RootObject[] {
    const groupServices = services.filter(service => service.type === GuideServiceTypes.GROUP_SESSION);
    const packages = services.filter(service => service.type === GuideServiceTypes.PACKAGE);
    const programs = services.filter(service => service.type === GuideServiceTypes.PROGRAM);

    return [
      ...sortServicesByName(groupServices.filter(service => filterSessionWithSeats(service, chosenClients.length))),
      ...sortServicesByName(packages),
      ...sortServicesByName(programs)
    ];
  }

  serviceSchedulingList(services: GuideServices.RootObject[], chosenClients: CRMClient[]): GuideServices.RootObject[] {
    const servicesWithAnyEnrolledClients = services
      .map((service: GuideServices.RootObject) => primaryFilterByFreeOfferSeats(service, chosenClients.length))
      .filter(service => !!service)
      .map((service: GuideServices.RootObject) => primaryFilterByAnyEnrolledClients(service))
      .filter(service => !!service) as GuideServices.RootObject[];

    const filteredByEnrolledClients = filterByEnrolledClients(servicesWithAnyEnrolledClients, chosenClients);

    const groupServices = filteredByEnrolledClients.filter(service => service.type === GuideServiceTypes.GROUP_SESSION);
    const packages = filteredByEnrolledClients.filter(service => service.type === GuideServiceTypes.PACKAGE);
    const programs = filteredByEnrolledClients.filter(service => service.type === GuideServiceTypes.PROGRAM);

    const filteredByNotCompletedPrograms = filterByNotCompleted(programs, chosenClients);

    const filteredByNotUsedPackages = filterByNotUsed(packages, chosenClients);

    return [
      ...sortServicesByName(groupServices.filter(service => filterSessionWithSeats(service, chosenClients.length))),
      ...sortServicesByName(filteredByNotUsedPackages),
      ...sortServicesByName(filteredByNotCompletedPrograms)
    ];
  }
}
