import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ClientSessionsStackBy, ClientSessionsStackingFunctions } from './grouping';
import { AvailableClientSessionsAppearanceType } from '@app/modules/client-sessions/types';

@Component({
  selector: 'app-available-client-sessions',
  templateUrl: './available-client-sessions.component.html',
  styleUrls: ['available-client-sessions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableClientSessionsComponent<T> {
  @Input()
  @HostBinding('class')
  appearance: AvailableClientSessionsAppearanceType | undefined;

  @Input()
  sessions: T[] | undefined;

  @Input()
  hideHeader = false;

  @Input()
  actionLabelText: string | undefined;

  @Output()
  selected = new EventEmitter<never>();

  groupBy = true;

  stackBy = ClientSessionsStackBy.TYPE;

  stackers = ClientSessionsStackingFunctions;
}
