import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { GlobalConfig } from '@cnf/types';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';

// import { locale } from '@env/locale';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-gift-layout',
  templateUrl: './gift-coupons-layout.component.html',
  styleUrls: ['./gift-coupons-layout.component.scss'],
  providers: [MetaTagService]
})
export class GiftCouponsLayoutComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  locale: ILocale;

  constructor(
    private router: Router,
    private title: Title,
    private brandingService: BrandingService,
    private localeService: LocaleService,
    private metaTagService: MetaTagService
  ) {
    this.locale = this.localeService.getLocale();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this.setConfig(config));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setConfig({
    metaKeywordsGiftCouponsPage,
    metaTitleGiftCouponsPage,
    metaDescriptionGiftCouponsPage,
    metaImageGiftCouponsPage
  }: GlobalConfig): void {
    this.title.setTitle(metaTitleGiftCouponsPage);

    this.metaTagService.upsertMetaTags({
      title: metaTitleGiftCouponsPage || '',
      keywords: metaKeywordsGiftCouponsPage || '',
      description: metaDescriptionGiftCouponsPage || '',
      image: metaImageGiftCouponsPage || '',
      url: `${this.locale.baseUrl}${this.router.url}`
    });
  }
}
