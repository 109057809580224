import { Component, Input } from '@angular/core';
import { IClientQuiz } from '@app/core/quizzes/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-info',
  templateUrl: './quiz-info.component.html',
  styleUrls: ['./quiz-info.component.scss']
})
export class QuizInfoComponent {
  @Input()
  // @ts-expect-error TS2564
  clientQuiz: IClientQuiz;
}
