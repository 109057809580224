<div class="hosts-list">
  <div class="hosts-list__item hosts-list__item--with-margin" *ngFor="let host of hosts">
    <div class="hosts-list__info" *puiLet="host?.username ? host?.username : (host | userName) as username">
      <div class="avatar">
        <pui-avatar [avatarUrl]="host?.photo" [rounded]="true" [text]="username" size="l"></pui-avatar>
      </div>
      <div class="hosts-list__details">
        <p class="hosts-list__details__name" pui-typography="body-m-bold">
          {{ username }}
        </p>
      </div>

      <span class="hosts-list__details__permission">{{ host?.permission | hostPermission }}</span>
    </div>

    <div class="hosts-list__actions" [ngClass]="{ inactive: !canRemove }">
      <app-button className="btn-link btn-lg" *ngIf="canRemove" i18n="@@removeHost" (click)="remove.emit(host)">
        Remove
      </app-button>
    </div>
  </div>
</div>
