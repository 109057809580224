<app-authorised-guide-info *ngIf="isGuide" class="authorised-guide-info-container"></app-authorised-guide-info>

<app-public-profile
  [class.pt-0]="isGuide"
  [profile]="profile"
  [reviewReadySessionId]="reviewReadySessionId"
  [shouldScrollToReviews]="shouldScrollToReviews"
  [mobile]="mobile"
  [blog]="blog"
  (openBlogItem)="openBlogItem($event)"
  (loadMoreReviews)="loadMoreReviews()"
  (leaveReview)="leaveReview($event)">
  <app-session-book-options
    *ngIf="isBrowser && profile && !profile.hidePricePerHour"
    [priceStartsFrom]="profile && +profile.priceStartsFrom"
    [discount]="profile?.discount"
    [isSessionFree]="(profile?.freeFirstSession && !hadSessionBefore) || profile?.freeAllSessions"
    [isPromoting]="isPromoting"
    [freeAllSessions]="profile?.freeAllSessions"
    [freeSessionCount]="profile?.bookForFreeCount"></app-session-book-options>

  <div class="buttons">
    <app-button
      *ngIf="canBook"
      [ngStyle]="{
        visibility: bookVisible ? '' : 'hidden',
        position: bookVisible ? '' : 'absolute'
      }"
      className="btn-main btn-book-session"
      (click)="book()"
      #bookButton
      i18n="@@bookASession"
      data-qa-id="guide-public-profile-book-a-session-button">
      Book a session
    </app-button>
    <app-button
      *ngIf="canSendMessage && profile"
      (click)="callSendMessage(profile.id, profile.workspaceId)"
      className="btn-send-message"
      class="send-message"
      data-qa-id="guide-public-profile-send-message-button">
      <ng-container i18n="@@sendMsg">Send message</ng-container>
    </app-button>
  </div>

  <app-guide-widget [widget]="profile?.widget"></app-guide-widget>

  <div class="book-down" [ngClass]="{ visible: !bookVisible }" #bookDown>
    <div class="wrapper">
      <div>
        <div class="free-session" *ngIf="(profile?.freeFirstSession && !hadSessionBefore) || profile?.freeAllSessions">
          <dt i18n="@@firstFreeSession" *ngIf="!profile?.freeAllSessions">First free session</dt>
          <dt i18n="@@freeSessions" *ngIf="profile?.freeAllSessions">Free sessions</dt>
          <dd *ngIf="!profile?.freeAllSessions">
            <ng-container i18n="@@firstFreeSessionNote">This coach provides free discovery session</ng-container>
          </dd>
          <dd transloco="freeSessionsNote" *ngIf="profile?.freeAllSessions">This coach provides free sessions</dd>
        </div>
      </div>
      <app-button *ngIf="canBook" className="btn brand-button btn-lg" (click)="book()" i18n="@@bookASession">
        Book a session
      </app-button>
    </div>
  </div>
</app-public-profile>
