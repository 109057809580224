import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientSessionStatusIconComponent, ClientSessionStatusLabelComponent } from './components';
import { ClientSessionStatusComponent } from './client-session-status.component';
import { PuiIconModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [ClientSessionStatusIconComponent, ClientSessionStatusLabelComponent, ClientSessionStatusComponent],
  imports: [CommonModule, PuiIconModule],
  exports: [ClientSessionStatusIconComponent, ClientSessionStatusLabelComponent, ClientSessionStatusComponent]
})
export class ClientSessionStatusModule {}
