import {
  ChatSummary,
  ChatUser,
  IChatMessage,
  IChatUpdate,
  isContactChatSummary,
  isDirectChatSummary,
  isGroupChatSummary,
  MessageTypes
} from '@app/core/chat/types';
import { DateTime } from 'luxon';
import {
  ActiveChat,
  BoardChatSummary,
  BoardContactChatSummary,
  BoardDirectChatSummary,
  BoardGroupChatSummary,
  ChatBoardMessageTypes,
  IChatBoardMessage
} from '../types';

export function convertChatUpdateToBoardActiveChat(chatUpdate: IChatUpdate): ActiveChat<IChatBoardMessage, ChatUser> {
  if (!chatUpdate) {
    // @ts-expect-error TS2322
    return null;
  }

  const { chat, type: updateType, displayFirstServiceSeparator } = chatUpdate;

  // @ts-expect-error TS2532
  return new ActiveChat(chat, convertToChatBoardMessage(chat.messages, null, displayFirstServiceSeparator), updateType);
}

export function convertFromUserChatToChatListItem(chat: ChatSummary): BoardChatSummary {
  if (!chat) {
    // @ts-expect-error TS2322
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {
    id: chat.id,
    notificationsCount: chat.notificationsCount,
    messages: chat.lastMessage ? [{ text: chat.lastMessage, date: chat.lastMessageDate }] : [],
    createdAt: chat.createdAt
  };

  if (isDirectChatSummary(chat)) {
    params.user = chat.user;
    params.bot = chat.bot;
    params.draft = chat.draft;
    params.workspaceId = chat.workspaceId;

    return new BoardDirectChatSummary(params);
  }

  if (isGroupChatSummary(chat)) {
    params.name = chat.name;
    params.description = chat.description;
    params.photo = chat.photo;
    return new BoardGroupChatSummary(params);
  }

  if (isContactChatSummary(chat)) {
    params.user = chat.user;
    params.draft = chat.draft;
    params.type = chat.type;

    return new BoardContactChatSummary(params);
  }

  // @ts-expect-error TS2322
  return null;
}

export function convertChatMessageToChatBoardMessage(message: IChatMessage): IChatBoardMessage {
  let type = ChatBoardMessageTypes.MESSAGE;

  if (message.file) {
    type = ChatBoardMessageTypes.FILE;
  } else if (message.type === MessageTypes.JOINED_CHAT) {
    type = ChatBoardMessageTypes.JOINED_CHAT;
  }

  return {
    type,
    payload: message
  };
}

export function addChatMessageDateSeparator(date: string): IChatBoardMessage {
  return {
    type: ChatBoardMessageTypes.DATE_SEPARATOR,
    payload: date
  };
}

// @ts-expect-error TS7006
function addServiceSeparator(meta): IChatBoardMessage {
  return {
    type: ChatBoardMessageTypes.SERVICE_SEPARATOR,
    payload: meta
  };
}

export function convertToChatBoardMessage(
  storedMessages: IChatMessage[],
  timezone?: string,
  displayFirstServiceSeparator?: boolean
): IChatBoardMessage[] {
  // @ts-expect-error TS7034
  const result = [];

  if (!storedMessages || !storedMessages.length) {
    // @ts-expect-error TS7005
    return result;
  }

  timezone = timezone || DateTime.local().zoneName;

  const storedMessagesEnd = storedMessages.length;
  let previousMessageMoment = DateTime.fromISO(storedMessages[0].date).setZone(timezone);
  let previousEventId = storedMessages[0].meta?.eventId;

  result.push(addChatMessageDateSeparator(previousMessageMoment.startOf('day').toISO()));

  if (previousEventId && displayFirstServiceSeparator) {
    result.push(addServiceSeparator(storedMessages[0].meta));
  }

  result.push(convertChatMessageToChatBoardMessage(storedMessages[0]));

  let currentMessageMoment;
  let currentEventId;
  let i = 1;

  while (i < storedMessagesEnd) {
    currentMessageMoment = DateTime.fromISO(storedMessages[i].date).setZone(timezone);
    currentEventId = storedMessages[i].meta?.eventId;

    if (!currentMessageMoment.hasSame(previousMessageMoment, 'day')) {
      result.push(addChatMessageDateSeparator(currentMessageMoment.startOf('day').toISO()));
    }

    if (currentEventId && previousEventId !== currentEventId) {
      result.push(addServiceSeparator(storedMessages[i].meta));
    }

    if (currentEventId && storedMessages[i + 1] && storedMessages[i + 1].meta?.eventId !== currentEventId) {
      storedMessages[i].meta.lastSessionMessage = true;
    }

    result.push(convertChatMessageToChatBoardMessage(storedMessages[i]));

    i++;
    previousMessageMoment = currentMessageMoment;
    previousEventId = currentEventId;
  }

  return result;
}
