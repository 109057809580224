import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[backgroundVideo]'
})
export class BackgroundVideoDirective implements OnChanges {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _url: string;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _videoContainer: HTMLDivElement;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _videoElement: HTMLVideoElement;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _videoSource: HTMLSourceElement;

  @Input() set backgroundVideo(value: string) {
    this._url = value;
  }

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.backgroundVideo) {
      this.init(this.el, this._url);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private init(el: ElementRef, url: string) {
    if (!el || !url) {
      return;
    }

    this._videoContainer = this.renderer.createElement('div');
    this._videoElement = this.renderer.createElement('video');
    this._videoSource = this.renderer.createElement('source');

    this._videoSource.src = url;
    this._videoElement.autoplay = true;
    this._videoElement.loop = true;
    this._videoElement.muted = true;

    this.renderer.addClass(this._videoContainer, 'video-container');
    this.renderer.addClass(this._videoElement, 'video-bg');

    this.renderer.appendChild(this._videoContainer, this._videoElement);
    this.renderer.appendChild(this._videoElement, this._videoSource);
    this.renderer.insertBefore(el.nativeElement, this._videoContainer, this.el.nativeElement.firstChild);
  }
}
