import { HttpEventType, HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { GroupChatUsersService } from '@app/core/chat/group-chat-users.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { IUser } from '@app/shared/interfaces/user';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mapTo,
  skip,
  skipUntil,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { GuideContact, isGuideClient, GuideClient } from '@app/core/users/types';
import { GUIDE_SESSIONS_TEMPLATE_ENDPOINT, CLIENT_CHAT_ENDPOINT } from '@app/shared/constants/endpoints';
import { AuthService } from '@app/core/auth/services';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { buildUsersGuideMapKey, getUserKey } from '@app/core/users/utils';
import { generate as generatePseudoRandomId } from '../simple-id/simple-id-generator';
import { SoundService } from '../sound/sound.service';
import { SoundType } from '../sound/types';
import { bufferMessagesWhileChatNotReady } from './buffer-messages-while-chat-not-ready.observable';
import { bufferOnDemand } from './buffer-on-demand.observable';
import { ChatsBotsService } from './chats-bots.service';
import { ChatsFilesUploaderService } from './chats-files-uploader.service';
import { ChatsNotificationsReducer } from './chats-notifications-reducer';
import { ChatsSocketService } from './chats-socket.service';
import { ChatsStoreService } from './chats-store.service';
import { ChatsUsersMapService } from './chats-users-map.service';
import { ChatsUsersService } from './chats-users.service';
import { convertSocketChatSummaries, createContactChatSummaries } from './converters';
import { sortChatSummaries } from './sorters';
import {
  Chat,
  ChatMessageStatuses,
  ChatSummary,
  ChatTypes,
  ChatUpdateTypes,
  ChatUser,
  ChatUserDetailed,
  DirectChatSummary,
  DirectChatUserId,
  GroupChatSummary,
  IAttachment,
  IFileUploading,
  IChatMessage,
  IChatUpdate,
  isContactChatSummary,
  isDirectChatSummary,
  IServerChat,
  IServerChatDetails
} from './types';
import { checkChatIdType, getOppositeRole } from './utils';

// TODO: refactor
const CHAT_BOT_ROLE = 3;

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const isEmptyObject = obj => {
  let isEmpty = true;
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      isEmpty = false;
      break;
    }
  }
  return isEmpty;
};

// TODO: This service should be refactored because RXJS subscriptions working not obvious
@Injectable()
export class ChatsService implements OnDestroy {
  private readonly HISTORY_REQUEST_LIMIT = 10;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _user: {
    id: number;
    firstName: string;
    lastName: string;
    photo: string;
    isOnline: boolean;
    role: number;

    readonly name: string;
    readonly idWithRole: { id: number; role: number };
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _tempFileIdUrlMap: { [url: string]: number } = {};

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _lastActiveChat: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _usersAndBotsMap$ = new ReplaySubject<{ [id: number]: IUser }>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _contacts$ = new ReplaySubject<GuideContact[]>(1);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatUpdate$: Subject<IChatUpdate>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatsSummaries$: BehaviorSubject<ChatSummary[]>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatsNotReady$: BehaviorSubject<{ [chatId: string]: string }>;

  private destroy$ = new Subject<void>();

  isNotificationsPresents$ = new BehaviorSubject<boolean>(false);

  get chatUpdate$(): Observable<IChatUpdate> {
    return this._chatUpdate$.asObservable();
  }

  get chatsSummaries$(): Observable<ChatSummary[]> {
    return this._chatsSummaries$.asObservable();
  }

  get isAlive$(): Observable<boolean> {
    return this._socket.isSocketAlive$;
  }

  get lastActiveChat(): string {
    return this._lastActiveChat;
  }

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
    private _socket: ChatsSocketService,
    private _uploader: ChatsFilesUploaderService,
    private _store: ChatsStoreService,
    private _users: ChatsUsersService,
    private _bots: ChatsBotsService,
    private _soundService: SoundService,
    private _directUsersChatsMap: ChatsUsersMapService,
    private _groupChatsUsers: GroupChatUsersService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.reset();

    _auth.onAuth().subscribe(user => {
      this.onSignOut();

      if (user) {
        this.onSignIn(user);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._usersAndBotsMap$.complete();
  }

  // ATTENTION: this empty method is a hack to make angular injector create instance of this class,
  // init logic left in constructor
  initialize(): void {}

  loadHistory(chatId: string, reversed = false): void {
    const cursor = this._store.getChatHistoryCursor(chatId, reversed);

    if (cursor) {
      this._socket.loadChatHistory(chatId, cursor, this.HISTORY_REQUEST_LIMIT, false, reversed);
    }
  }

  markMessagesRead(chatId: string): void {
    if (chatId && this._store.has(chatId) && this._store.isActivated(chatId)) {
      const messageIds = this._store.updateOthersChatMessagesStatus(chatId, this._user.id, ChatMessageStatuses.READ);
      if (messageIds.length) {
        this._socket.markMessagesRead(chatId, messageIds);
        this.updateUserChatsNotifications(chatId, -messageIds.length);
      }
    }
  }

  resolveChatId(id: string): string {
    // @ts-expect-error TS2345
    const { chatId, type } = checkChatIdType(id, this._runtimeConfigService.get('chatPrefix'));

    let resolvedId = chatId;
    switch (type) {
      case 'user':
        // @ts-expect-error TS2322
        resolvedId = this._directUsersChatsMap.getChatId(new DirectChatUserId(chatId));
        break;
      case 'chat':
        break;
      default:
        break;
    }

    return resolvedId;
  }

  // @ts-expect-error TS7006
  sendMessage(chatId: string, text?: string, attachment?: { name: string; url: string; size?: number }, meta?): void {
    if (!chatId || (!text && !attachment) || !this._store.has(chatId)) {
      return;
    }

    const chatMessage = {} as
      | { text: string }
      | { file: { name: string; url: string; size?: number }; text?: string | null };

    if (text) {
      chatMessage.text = text;
    }

    if (attachment) {
      (chatMessage as { file: { name: string; url: string; size?: number }; text?: string | null }).file = attachment;
    }

    if (!this._store.isTemporary(chatId)) {
      // @ts-expect-error TS2322
      this._socket.sendMessage(chatId, { text, file: attachment, meta });
      return;
    }

    // @ts-expect-error TS2531
    const userId = Number(Object.keys(this._store.get(chatId).users).find(id => Number(id) !== this._user.id));
    if (Number.isNaN(userId)) {
      return;
    }
    // NOTE: based on assumption that one can create chats only with users having opposite role
    const participant = { id: userId, role: getOppositeRole(this._user.idWithRole.role) };

    let preChatCreation$ = of(null);
    // @ts-expect-error TS7034
    let workspace = null;

    if (this._user.idWithRole.role === UserRoles.CLIENT) {
      // @ts-expect-error TS2339
      const { workspaceId } = this._directUsersChatsMap.getUserId(chatId);

      workspace = { id: workspaceId };
      // @ts-expect-error TS2322
      preChatCreation$ = this._users.addGuideClient$({ id: userId, workspaceId });
    }

    // @ts-expect-error TS7005
    preChatCreation$.subscribe(() => this._socket.createChat(participant, workspace, { text, meta }));
  }

  startChat$(id: string | number, eventId?: number | null): Observable<Chat<IChatMessage, ChatUser>> {
    // @ts-expect-error TS2345
    const { chatId, type } = checkChatIdType(id, this._runtimeConfigService.get('chatPrefix'));

    let chat$: Observable<Chat<IChatMessage, ChatUser>>;
    switch (type) {
      case 'chat':
        chat$ = this.startChatById$(chatId, eventId);
        break;
      case 'user':
        chat$ = this.startDirectChat$(new DirectChatUserId(chatId), eventId);
        break;
      case 'contact':
        chat$ = this.startChatById$(chatId);
        break;
      default:
        // @ts-expect-error TS2322
        chat$ = of(null);
    }

    return chat$.pipe(
      tap(chat => {
        if (!chat) {
          return;
        }
        // @ts-expect-error TS2322
        this._lastActiveChat = chat.draft
          ? Object.keys(chat.users).find(userId => Number(userId) !== this._user.id)
          : chat.id;
      })
    );
  }

  uploadFile(chatId: string, file: File): Observable<IFileUploading | null> {
    if (!chatId || !this._store.has(chatId)) {
      // @ts-expect-error TS2322
      return;
    }

    // @ts-expect-error TS2322
    return this._uploader.uploadFile$(file).pipe(
      debounceTime(200),
      map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          // @ts-expect-error TS2532
          const loadedPercent = (event.loaded / event.total) * 100;
          return { loaded: false, loadedPercent };
        }

        if (event.type === HttpEventType.Response) {
          // @ts-expect-error TS2339
          const { url } = event.body;
          if (!url) {
            return null;
          }

          return { file: { name: file.name, url, size: file.size }, loaded: true, loadedPercent: 100 };
        }
      })
    );
  }

  deleteParticipantFromChat(chatId: string, clientId: number): Observable<void> {
    return this._http.delete<void>(`${GUIDE_SESSIONS_TEMPLATE_ENDPOINT}/chat/${chatId}/participants/${clientId}`, {});
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  leaveChat(chatId: string) {
    return this._http.delete<void>(`${CLIENT_CHAT_ENDPOINT}/${chatId}`, {});
  }

  private findChat$(chatId: string, eventId?: number | null): Observable<Chat<IChatMessage, ChatUser>> {
    let chatResolver$;
    if (chatId.includes('contact')) {
      chatResolver$ = this.chatsSummaries$.pipe(
        map(chats => {
          return chats.filter(chat => chat.id === chatId)[0];
        }),
        filter(contactChat => !!contactChat),
        map(contactChat => {
          if (isContactChatSummary(contactChat)) {
            const contactFakeChat = {
              description: null,
              id: contactChat.id,
              name: null,
              photo: null,
              type: contactChat.type,
              users: [new ChatUserDetailed(contactChat.user)],
              workspaceId: null
            };
            // @ts-expect-error TS2345
            this._store.addChat(contactFakeChat, contactFakeChat.users, eventId);
            return this._store.get(contactFakeChat.id);
          }

          return contactChat;
        })
      );
    } else {
      chatResolver$ = this._socket.chatFound$.pipe(
        take(1),
        filter(chat => !!chat),
        switchMap(chat => {
          if (chat.type === ChatTypes.GROUP) {
            const groupChatUsers = chat.users.reduce((chatUsers, user) => {
              if (this._user.id !== user.id) {
                // @ts-expect-error TS2322
                chatUsers.push({ id: user.id, role: user.role });
              }

              return chatUsers;
            }, []);

            return this._groupChatsUsers.add$(groupChatUsers).pipe(mapTo(chat));
          }

          return of(chat);
        }),
        withLatestFrom(this._usersAndBotsMap$),
        map(([chat, usersAndBots]) => {
          const allUsersMap = { ...usersAndBots, [getUserKey(this._user)]: this._user };
          const chatUsers = chat.users.reduce((dict, user) => {
            let userInfo: IUser & { isBlocked?: boolean } =
              // @ts-expect-error TS2322
              allUsersMap[buildUsersGuideMapKey({ id: user.id, workspaceId: chat.workspaceId })] ||
              // @ts-expect-error TS7053
              allUsersMap[user.id];
            // @ts-expect-error TS7053
            if (user.role === UserRoles.CLIENT && isGuideClient(allUsersMap[getUserKey(user)])) {
              // @ts-expect-error TS2322
              userInfo = new GuideClient({ ...allUsersMap[getUserKey(user)] });
            }
            userInfo.isBlocked = user.isBlocked;
            return { ...dict, [getUserKey(user)]: userInfo };
          }, {});
          // @ts-expect-error TS2345
          this._store.addChat(chat, chatUsers, eventId);
          this.loadChatMessages(chatId, eventId);
          return this._store.get(chat.id);
        })
      );

      this._socket.findChat(chatId);
    }

    // @ts-expect-error TS2322
    return chatResolver$;
  }

  private startDirectChat$(
    directChatUserId: DirectChatUserId,
    eventId?: number | null
  ): Observable<Chat<IChatMessage, ChatUser>> {
    return combineLatest([
      this.findDirectChatId$(directChatUserId),
      // @ts-expect-error TS2322
      this.getUser$({ id: directChatUserId.id, workspaceId: directChatUserId.workspaceId })
    ]).pipe(
      map(([chatId, user]) =>
        chatId ? this.activateDirectChat(chatId, user, eventId) : this.createTemporaryDirectChat(user, directChatUserId)
      )
    );
  }

  private findDirectChatId$(directChatUserId: DirectChatUserId): Observable<string> {
    const chatId = this._directUsersChatsMap.getChatId(directChatUserId);

    if (chatId) {
      return of(chatId);
    }

    const resolvedChatId$ = this._socket.directChatFound$.pipe(
      take(1),
      map(chat => chat && chat.id)
    );

    // WARNING: based on assumption that one can create chats only with users having opposite role
    const participant = {
      id: directChatUserId.id,
      role: directChatUserId.id < 0 ? CHAT_BOT_ROLE : getOppositeRole(this._user.idWithRole.role)
    };
    const workspace = this._user.idWithRole.role === UserRoles.CLIENT ? { id: directChatUserId.workspaceId } : null;

    // @ts-expect-error TS2345
    this._socket.findDirectChat(participant, workspace);

    return resolvedChatId$;
  }

  private startChatById$(chatId: string, eventId?: number | null): Observable<Chat<IChatMessage, ChatUser>> {
    if (!this._store.has(chatId)) {
      return this.findChat$(chatId, eventId);
    }

    if (!eventId && this._store.hasEvent(chatId)) {
      this._store.resetMessages(chatId);
      this.loadChatMessages(chatId);
    }

    if (!this._store.isTemporary(chatId) && !this._store.isActivated(chatId)) {
      this._store.activate(chatId);
      this.loadChatMessages(chatId, eventId);
    }

    // @ts-expect-error TS2322
    return of(this._store.get(chatId));
  }

  private activateDirectChat(chatId: string, user: ChatUser, eventId?: number | null): Chat<IChatMessage, ChatUser> {
    if (!this._store.has(chatId)) {
      // @ts-expect-error TS2322
      this._store.createDirectChat(chatId, [user, this._user], true, eventId);
      this.loadChatMessages(chatId, eventId);
    } else if (!this._store.isTemporary(chatId) && !this._store.isActivated(chatId)) {
      this._store.activate(chatId);
      this.loadChatMessages(chatId, eventId);
    }

    if (eventId || this._store.hasEvent(chatId)) {
      this._store.resetMessages(chatId);
      this.loadChatMessages(chatId, eventId);
    }

    if (eventId) {
      this._store.setEvent(chatId, eventId);
    }

    // @ts-expect-error TS2322
    return this._store.get(chatId);
  }

  private createTemporaryDirectChat(user: ChatUser, directChatUserId: DirectChatUserId): Chat<IChatMessage, ChatUser> {
    const tempChatId = `_${generatePseudoRandomId(8)}`;

    this._directUsersChatsMap.set(tempChatId, directChatUserId);
    // @ts-expect-error TS2322
    this._store.createTemporaryDirectChat(tempChatId, [user, this._user]);
    // @ts-expect-error TS2345
    this.addDirectChatToChatsList$(tempChatId, user.id, directChatUserId.workspaceId, true);

    // @ts-expect-error TS2322
    return this._store.get(tempChatId);
  }

  private getUser$({ id, workspaceId }: { id: number | string; workspaceId?: number }): Observable<ChatUser> {
    // @ts-expect-error TS2322
    return id < 0
      ? this._bots.getUser$(id as number).pipe(take(1))
      : this._users.getUser$({ id, workspaceId }).pipe(take(1));
  }

  private addDirectChatToChatsList$(chatId: string, userId: number, workspaceId: number, isTempChat?: boolean): void {
    this._chatsNotReady$.next({ ...this._chatsNotReady$.getValue(), [chatId]: chatId });

    this.getUser$({ id: userId, workspaceId })
      .pipe(
        map(
          user =>
            new DirectChatSummary(
              { id: chatId, notificationsCount: 0, createdAt: new Date().toISOString(), workspaceId },
              user,
              isTempChat
            )
        )
      )
      .subscribe(directChat => {
        this.updateChatSummaries([
          directChat,
          ...this._chatsSummaries$.getValue().filter(chat => directChat.id !== chat.id)
        ]);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [chatId]: readyChatId, ...chatsNotReady } = this._chatsNotReady$.getValue();
        this._chatsNotReady$.next(chatsNotReady);
      });
  }

  private addGroupChatToChatsList(groupChatDetails: IServerChatDetails): void {
    const { id: chatId } = groupChatDetails;
    this._chatsNotReady$.next({ ...this._chatsNotReady$.getValue(), [chatId]: chatId });

    const groupChat = new GroupChatSummary(groupChatDetails);
    this.updateChatSummaries([groupChat, ...this._chatsSummaries$.getValue().filter(chat => chatId !== chat.id)]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [chatId]: readyChatId, ...chatsNotReady } = this._chatsNotReady$.getValue();
    this._chatsNotReady$.next(chatsNotReady);
  }

  private messageReceived$(): Observable<Omit<IChatUpdate, 'chat'>> {
    return this._socket.messageReceived$.pipe(
      bufferMessagesWhileChatNotReady<IChatMessage, string>(this._chatsNotReady$),
      tap(message => this.updateChatSummaryWithReceivedMessage(message.chatId, message)),
      filter(message => this._store.canAddNewMessage(message.chatId)),
      tap(message => this.notifyMessagesWereDelivered(message.chatId, [message])),
      tap(message => {
        if (![ChatMessageStatuses.DELIVERED, ChatMessageStatuses.READ].includes(message.status)) {
          message.status = ChatMessageStatuses.DELIVERED;
        }

        this._store.addNewMessage(message);
      }),
      switchMap(message =>
        this.preLoadGroupChatUserIfNotExists(message.chatId, message.sender as { id: number; role: UserRoles }).pipe(
          // @ts-expect-error TS2554
          mapTo(message)
        )
      ),
      // @ts-expect-error TS2531
      map(message => ({ chatId: message.chatId, type: ChatUpdateTypes.NEW_MESSAGE }))
    );
  }

  private messageSent$(): Observable<Omit<IChatUpdate, 'chat'>> {
    return this._socket.messageSent$.pipe(
      tap(chatMessage => {
        if (chatMessage.file) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const tempId = this._tempFileIdUrlMap[(<IAttachment>chatMessage.file).url];
          if (tempId) {
            this._store.persistMessage(tempId, chatMessage);
            return;
          }
        }

        this._store.addNewMessage(chatMessage);
      }),
      tap(chatMessage => this.updateUserChatsNotifications(chatMessage.chatId, 0, chatMessage.date, chatMessage.text)),
      map(chatMessage => ({ chatId: chatMessage.chatId, type: ChatUpdateTypes.NEW_MESSAGE }))
    );
  }

  private messagesLoaded$(): Observable<Omit<IChatUpdate, 'chat'>> {
    return this._socket.messagesLoaded$.pipe(
      tap(chatHistory => this.notifyMessagesWereDelivered(chatHistory.id, chatHistory.messages)),
      tap(chatHistory => {
        const { id, messages, reversed } = chatHistory;
        messages.forEach(message => {
          if (![ChatMessageStatuses.DELIVERED, ChatMessageStatuses.READ].includes(message.status)) {
            message.status = ChatMessageStatuses.DELIVERED;
          }
        });
        this._store.addHistory(id, messages, reversed);
      }),
      map(chatHistory => ({
        chatId: chatHistory.id,
        type: chatHistory.reversed || chatHistory.eventId ? ChatUpdateTypes.HISTORY_RESERVED : ChatUpdateTypes.HISTORY
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private loadChatMessages(chatId: string, eventId?: number | null) {
    if (eventId) {
      return this.loadChatMessagesFromEvent(chatId, eventId);
    }

    this.loadLatestChatMessages(chatId);
  }

  private loadLatestChatMessages(chatId: string): void {
    const cursor = null;
    const shouldLoadLatest = true;
    // @ts-expect-error TS2345
    this._socket.loadChatHistory(chatId, cursor, this.HISTORY_REQUEST_LIMIT, shouldLoadLatest);
  }

  private loadChatMessagesFromEvent(chatId: string, eventId?: number | null): void {
    const cursor = null;
    const shouldLoadLatest = false;
    // @ts-expect-error TS2345
    this._socket.loadChatHistory(chatId, cursor, this.HISTORY_REQUEST_LIMIT, shouldLoadLatest, false, eventId);
  }

  private notifyMessagesWereDelivered(chatId: string, messages: IChatMessage[]): void {
    const validStatuses = [ChatMessageStatuses.DELIVERED, ChatMessageStatuses.READ];
    // eslint-disable-next-line id-length
    const delivered = messages.filter(m => !validStatuses.includes(m.status) && m.sender.id !== this._user.id);

    if (delivered.length) {
      this._socket.markMessagesDelivered(
        chatId,
        delivered.map(message => message.id)
      );
    }
  }

  private onChatCreated(newChat: IServerChat): void {
    if (newChat.type === ChatTypes.DIRECT) {
      this.onDirectChatCreated(newChat);
    }

    if (newChat.type === ChatTypes.GROUP) {
      this.onGroupChatCreated(newChat);
    }
  }

  private onChatOpened(chat: IServerChatDetails): void {
    this.addGroupChatToChatsList(chat);
  }

  private onChatRemoved(chat: { id: string }): void {
    const storedChat = this._store.get(chat.id);

    if (storedChat && storedChat.type === ChatTypes.GROUP) {
      this._groupChatsUsers.remove(Object.keys(storedChat.users).map(id => Number(id)));
    }

    this._store.remove(chat.id);
    this.removeChatFromChatsList(chat.id);
    this._directUsersChatsMap.removeChatId(chat.id);

    if (this._lastActiveChat === chat.id) {
      // @ts-expect-error TS2322
      this._lastActiveChat = null;
    }

    this._chatUpdate$.next({ chatId: chat.id, type: ChatUpdateTypes.CHAT_REMOVED });
  }

  private onChatParticipantRemoved(chat: { id: string; blockedUsers: { id: number }[] }): void {
    chat.blockedUsers.forEach(user => {
      this._store.blockChatUser(chat.id, user.id);
    });

    this._chatUpdate$.next({
      // @ts-expect-error TS2322
      chat: this._store.get(chat.id),
      chatId: chat.id,
      type: ChatUpdateTypes.CHAT_PARTICIPANT_REMOVED
    });
  }

  private onChatParticipantAdded(chat: { id: string; newUsers: { id: number; role: UserRoles }[] }): void {
    const storedChat = this._store.get(chat.id);

    if (!storedChat) {
      return;
    }

    const { users } = storedChat;

    chat.newUsers.forEach(user => {
      if (users[user.id] && users[user.id].isBlocked) {
        this._store.unblockChatUser(chat.id, user.id);
      } else {
        // @ts-expect-error TS2554
        this.preLoadGroupChatUserIfNotExists(chat.id, user).subscribe();
      }
    });
  }

  private onDirectChatCreated(newChat: IServerChat): void {
    const { id: chatId, workspaceId, users } = newChat;
    // @ts-expect-error TS2532
    const participantId = users.find(user => user.id !== this._user.id).id;
    // @ts-expect-error TS2345
    const directChatParticipantId: DirectChatUserId = this.convertToDirectChatParticipantId(participantId, workspaceId);
    const tempChatId = this._directUsersChatsMap.getChatId(directChatParticipantId);

    if (tempChatId) {
      this._store.persistDirectChat(tempChatId, chatId);
      this._directUsersChatsMap.updateChatIdForUser(directChatParticipantId, tempChatId, chatId);
      this._chatUpdate$.next({
        chatId: tempChatId,
        type: ChatUpdateTypes.TEMP_CHAT_PERSISTED,
        // @ts-expect-error TS2322
        chat: this._store.get(chatId)
      });
      this.updateIdOfChatInChatsList(tempChatId, chatId);
    } else {
      this._directUsersChatsMap.set(chatId, directChatParticipantId);
      // @ts-expect-error TS2345
      this.addDirectChatToChatsList$(chatId, participantId, workspaceId);
      // @ts-expect-error TS2322
      this.getUser$({ id: participantId, workspaceId })
        .pipe(take(1))
        // @ts-expect-error TS2322
        .subscribe(user => this._store.createDirectChat(chatId, [user, this._user]));
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onGroupChatCreated(createdChat: IServerChat) {
    this.addGroupChatToChatsList({ ...createdChat, notificationsCount: 0 });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSignIn(user: any): void {
    if (![UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(user.RoleId)) {
      this._user = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        photo: user.photo,
        isOnline: true,
        role: user.RoleId,

        get name() {
          return `${this.firstName} ${this.lastName}`;
        },
        get idWithRole() {
          return { id: this.id, role: this.role };
        }
      };

      this.setSubscriptions();

      this._socket.loadUserChats();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private onSignOut() {
    this.destroy$.next();
    this.reset();
    this._store.reset();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private preLoadGroupChatUserIfNotExists(chatId, user: { id: number; role: UserRoles }) {
    // @ts-expect-error TS2339
    const { users, type } = this._store.get(chatId);

    if (type !== ChatTypes.GROUP || users[user.id]) {
      return of(null);
    }

    return this._groupChatsUsers.add$([user]).pipe(
      switchMap(() => this._usersAndBotsMap$.pipe(take(1))),
      tap(usersAndBots => {
        if (usersAndBots[user.id]) {
          this._store.addChatUser(chatId, usersAndBots[user.id]);
        }
      })
    );
  }

  private refreshChat(chatId: string, type: ChatUpdateTypes): void {
    // @ts-expect-error TS2322
    this._chatUpdate$.next({ chatId, type, chat: this._store.get(chatId) });
  }

  private removeChatFromChatsList(chatId: string): void {
    const userChats = this._chatsSummaries$.getValue().slice();

    const userChatIndex = userChats.findIndex(chat => chat.id === chatId);
    if (userChatIndex > -1) {
      userChats.splice(userChatIndex, 1);
      this.updateChatSummaries(userChats);
    }
  }

  private reset(): void {
    // @ts-expect-error TS2322
    this._user = null;
    // @ts-expect-error TS2322
    this._lastActiveChat = null;

    if (this._chatsSummaries$) {
      this._chatsSummaries$.complete();
    }

    if (this._chatUpdate$) {
      this._chatUpdate$.complete();
    }

    if (this._chatsNotReady$) {
      this._chatsNotReady$.complete();
    }

    this._chatsSummaries$ = new BehaviorSubject<ChatSummary[]>([]);
    this._chatUpdate$ = new Subject<IChatUpdate>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._chatsNotReady$ = new BehaviorSubject<{ [id: number]: any }>({});
    this.isNotificationsPresents$.next(false);

    this.audioSubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private audioSubscribe() {
    // @ts-expect-error TS2322
    let unreadMessages: number = null;
    this._chatsSummaries$
      .pipe(
        skip(1),
        map(users =>
          users.reduce((sum, val) => {
            // @ts-expect-error TS2533
            sum += val.notificationsCount;
            return sum;
          }, 0)
        )
      )
      .subscribe(unreadNew => {
        if (unreadMessages !== null && unreadNew > unreadMessages) {
          this._soundService.playSound(SoundType.MESSAGE);
        }
        unreadMessages = unreadNew;
      });
  }

  private setSubscriptions(): void {
    this._socket.chatCreated$
      .pipe(
        filter(chat => !!chat),
        takeUntil(this.destroy$)
      )
      .subscribe(newChat => this.onChatCreated(newChat));

    this._socket.chatOpened$.pipe(takeUntil(this.destroy$)).subscribe(chat => this.onChatOpened(chat));

    this._socket.chatRemoved$.pipe(takeUntil(this.destroy$)).subscribe(chat => this.onChatRemoved(chat));

    this._socket.chatParticipantRemoved$
      .pipe(takeUntil(this.destroy$))
      .subscribe(chat => this.onChatParticipantRemoved(chat));

    this._socket.chatParticipantAdded$
      .pipe(takeUntil(this.destroy$))
      .subscribe(chat => this.onChatParticipantAdded(chat));

    combineLatest([
      this._users.getUsersAsDictionary$(),
      this._bots.getUsersAsDictionary$(),
      this._user.role === UserRoles.GUIDE ? this._users.getContacts$() : of([]),
      this._groupChatsUsers.usersMap$
    ])
      .pipe(
        map(([users, bots, contacts, groupChatUsers]) => ({
          usersAndBots: { ...users, ...bots, ...groupChatUsers },
          contacts
        })),
        takeUntil(this.destroy$)
      )
      .subscribe(({ usersAndBots, contacts }) => {
        this._contacts$.next(contacts);
        // @ts-expect-error TS2345
        this._usersAndBotsMap$.next(usersAndBots);
      });

    merge(this.messageReceived$(), this.messageSent$(), this.messagesLoaded$())
      .pipe(takeUntil(this.destroy$))
      // @ts-expect-error TS2345
      .subscribe(({ chatId, type }) => this.refreshChat(chatId, type));

    merge(
      this._socket.messagesDelivered$,
      this._socket.messagesRead$.pipe(
        tap(update => {
          const {
            id: chatId,
            user: { id: userId },
            messageIds
          } = update;
          if (userId === this._user.id) {
            this.updateUserChatsNotifications(chatId, -messageIds.length);
          }
        })
      )
    )
      .pipe(
        bufferOnDemand(2000),
        map(updates => new ChatsNotificationsReducer(updates).run()),
        takeUntil(this.destroy$)
      )
      .subscribe(updates => updates.forEach(update => this.updateSentMessagesStatuses(update)));

    const chatsUsersCombination$ = combineLatest([
      this._socket.chatsLoaded$.pipe(
        tap(chats => this.updateDirectChatsUsersMap(this.splitChatsByType(chats).directChats))
      ),
      this._usersAndBotsMap$
    ]).pipe(
      map(([chats, usersAndBots]) => {
        const socketChatSummaries = convertSocketChatSummaries(chats, usersAndBots);
        return [...socketChatSummaries];
      })
    );

    combineLatest([chatsUsersCombination$, this._contacts$])
      .pipe(
        tap(([newChatSummaries, contacts]) => {
          this.updateChatSummaries(newChatSummaries);
          const currentChats = this._chatsSummaries$.getValue();
          let isUpdated = false;
          let newChats = [...currentChats];
          const newContacts = contacts.filter(({ id }) => !currentChats.some(chat => chat.id === `${id}contact`));
          const removedContacts = currentChats
            .filter(
              currentChat =>
                isContactChatSummary(currentChat) && !contacts.some(chat => `${chat.id}contact` === currentChat.id)
            )
            .map(removedContact => removedContact.id);

          if (newContacts.length) {
            isUpdated = true;
            const contactsChatsSummaries = createContactChatSummaries(newContacts);
            newChats.push(...contactsChatsSummaries);
          }

          if (removedContacts.length) {
            isUpdated = true;
            newChats = newChats.filter(chat => !removedContacts.includes(chat.id));
          }

          if (isUpdated) {
            this.updateChatSummaries(newChats);
          }
        }),
        filter(chats => chats && !!chats.length),
        map(([chats]) => sortChatSummaries(chats)[0]?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(chatId => (this._lastActiveChat = this._lastActiveChat || chatId));

    this._usersAndBotsMap$
      .pipe(skipUntil(chatsUsersCombination$), takeUntil(this.destroy$))
      .subscribe(usersAndBots => this.updateUsersInChatSummaries(usersAndBots));
  }

  private splitChatsByType<T extends { type: ChatTypes }>(chats: T[]): { [type: string]: T[] } {
    return chats.reduce(
      (dict, chat) => {
        if (chat.type === ChatTypes.DIRECT) {
          // @ts-expect-error TS2345
          dict.directChats.push(chat);
        }

        if (chat.type === ChatTypes.GROUP) {
          // @ts-expect-error TS2345
          dict.groupChats.push(chat);
        }
        return dict;
      },
      { directChats: [], groupChats: [] }
    );
  }

  private updateIdOfChatInChatsList(oldChatId: string, newChatId: string): void {
    const userChats = this._chatsSummaries$.getValue();
    const userChat = userChats.find(chat => chat.id === oldChatId);

    if (userChat) {
      userChat.id = newChatId;

      if (isDirectChatSummary(userChat) && userChat.draft) {
        userChat.activate();
      }

      this.updateChatSummaries(userChats.slice());
    }
  }

  private updateDirectChatsUsersMap(directChats: IServerChatDetails[]): void {
    const directChatsUsers = directChats.map(({ id: chatId, workspaceId, users }) => ({
      chatId,
      // @ts-expect-error TS2532
      userId: this.convertToDirectChatParticipantId(users.find(user => user.id !== this._user.id).id, workspaceId)
    }));
    this._directUsersChatsMap.update(directChatsUsers);
  }

  private updateChatSummaryWithReceivedMessage(chatId: string, message: IChatMessage): void {
    const validStatuses = [ChatMessageStatuses.DELIVERED, ChatMessageStatuses.READ];
    const number = !validStatuses.includes(message.status) && message.sender.id !== this._user.id ? 1 : 0;
    this.updateUserChatsNotifications(chatId, number, message.date, message.text);
  }

  private updateUsersInChatSummaries(users: { [id: number]: IUser }): void {
    const chatSummaries = this._chatsSummaries$.getValue().map(chat => {
      if (isDirectChatSummary(chat)) {
        // @ts-expect-error TS7015
        chat.user = users[getUserKey(chat.user)] || chat.user;
      }

      return chat;
    });
    this.updateChatSummaries(chatSummaries);
  }

  // @ts-expect-error TS7006
  private updateSentMessagesStatuses(update): void {
    const { chatId, messageIds } = update;

    if (messageIds && !isEmptyObject(messageIds)) {
      const updatedMessageIds = this._store.updateOwnChatMessagesStatuses(chatId, this._user.id, messageIds);

      if (updatedMessageIds.length) {
        this.refreshChat(chatId, ChatUpdateTypes.MESSAGE_STATUS);
      }
    }
  }

  private updateUserChatsNotifications(
    chatId: string,
    notificationsCount: number,
    lastMessageDate?: string,
    lastMessage?: string
  ): void {
    const userChats = this._chatsSummaries$.getValue();
    const userChat = userChats.find(chat => chat.id === chatId);

    if (!userChat) {
      return;
    }

    if (userChat.notificationsCount !== 0 || notificationsCount >= 0) {
      // @ts-expect-error TS2533
      userChat.notificationsCount += notificationsCount;
    }

    // @ts-expect-error TS2533
    if (userChat.notificationsCount < 0) {
      userChat.notificationsCount = 0;
    }

    if (lastMessageDate) {
      userChat.lastMessageDate = lastMessageDate;
    }

    if (lastMessage) {
      userChat.lastMessage = lastMessage;
    }

    this.updateChatSummaries(userChats.slice());
  }

  private updateChatSummaries(chatSummaries: ChatSummary[]): void {
    const currentSummaries = this._chatsSummaries$.value;

    if (currentSummaries.length > chatSummaries.length) {
      return;
    }

    const filtered = chatSummaries.filter(
      chat =>
        !(
          isContactChatSummary(chat) &&
          chatSummaries.some(chatItem => isDirectChatSummary(chatItem) && chatItem.user.id === chat.user.id)
        )
    );
    this._chatsSummaries$.next(filtered);
    // @ts-expect-error TS2533
    const hasNotifications = chatSummaries.some(({ notificationsCount }) => notificationsCount > 0);
    this.isNotificationsPresents$.next(hasNotifications);
  }

  private convertToDirectChatParticipantId(participantId: number, workspaceId: number): DirectChatUserId {
    return new DirectChatUserId(participantId, this._user.role === UserRoles.CLIENT ? workspaceId : null);
  }
}
