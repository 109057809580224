import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  deleteField,
  deleteFieldError,
  deleteFieldSuccess,
  fetchFields,
  fetchFieldsError,
  fetchFieldsSuccess,
  putFieldError,
  putField,
  putFieldSuccess,
  patchField,
  patchFieldSuccess,
  patchFieldError,
  resetState
} from '@app/screens/guide/guide-clients/guide-relations-table/store/custom-fields/custom-fields.actions';
import { catchError, map, mergeMap, tap, skip, distinctUntilChanged } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';
import { CustomFieldsApiService } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';

@Injectable()
export class CustomFieldsEffects {
  readonly workspaceChange$ = createEffect(() =>
    this.workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: resetState.type
      }))
    )
  );

  // Fetch
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFields.type),
      mergeMap(() =>
        /**
         * Fetch custom field
         */
        this.fieldsApiService.get().pipe(
          map(data => ({
            type: fetchFieldsSuccess.type,
            payload: data
          })),
          catchError(() =>
            of({
              type: fetchFieldsError.type
            }).pipe(
              tap(() => {
                this.notificationsService.error(`Error while fetching field!`);
              })
            )
          )
        )
      )
    )
  );

  // Create
  put$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putField),
      distinctUntilChanged(isEqual),
      mergeMap(({ payload }) =>
        this.fieldsApiService.put(payload).pipe(
          map(data => ({
            type: putFieldSuccess.type,
            payload: data
          })),
          catchError(() =>
            of({
              type: putFieldError.type
            }).pipe(
              tap(() => {
                this.notificationsService.error(`Error while adding field!`);
              })
            )
          )
        )
      )
    )
  );

  // Patch
  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchField),
      mergeMap(({ payload }) =>
        this.fieldsApiService.patch(payload).pipe(
          map(data => ({
            type: patchFieldSuccess.type,
            payload: data
          })),
          catchError(() =>
            of({
              type: patchFieldError.type
            }).pipe(
              tap(() => {
                this.notificationsService.error(`Error while updating field!`);
              })
            )
          )
        )
      )
    )
  );

  // Delete
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteField),
      mergeMap(({ payload }) =>
        this.fieldsApiService.delete(payload).pipe(
          map(data => ({
            type: deleteFieldSuccess.type,
            payload: data
          })),
          catchError(() =>
            of({
              type: deleteFieldError.type
            }).pipe(
              tap(() => {
                this.notificationsService.error(`Error while deleting field!`);
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly fieldsApiService: CustomFieldsApiService,
    private readonly notificationsService: NotificationsService,
    private readonly workspacesService: WorkspacesService
  ) {}
}
