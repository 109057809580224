<div class="icon-container">
  <pui-icon class="action-completed-icon pui-space_bottom-3" svgIcon="pui:check-circle" [size]="40"></pui-icon>
</div>

<h3 [attr.data-qa-id]="'session-action-result-status-' + title + '-title'" class="title" *ngIf="title">
  <ng-container [ngSwitch]="title">
    <ng-container *ngSwitchCase="'Accepted'" i18n="@@accepted">Accepted</ng-container>
    <ng-container *ngSwitchCase="'Reschedule requested'" i18n="@@rescheduleRequested">
      Reschedule requested
    </ng-container>
    <ng-container *ngSwitchCase="'Canceled'" i18n="@@canceled">Canceled</ng-container>
    <ng-container *ngSwitchCase="'Feedback added'" i18n="@@feedbackAdded">Feedback added</ng-container>
  </ng-container>
</h3>

<ng-container *ngIf="actionResult?.session">
  <app-session-result-details class="session-details" [session]="actionResult?.session"></app-session-result-details>
</ng-container>
