import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuiAvatarGroupModule, PuiAvatarModule, PuiDropdownModule, PuiList2Module } from '@awarenow/profi-ui-core';
import { AvatarGroupComponent } from './avatar-group.component';
import { ToAvatarGroupPipe } from './to-avatar-group.pipe';

@NgModule({
  declarations: [AvatarGroupComponent, ToAvatarGroupPipe],
  exports: [AvatarGroupComponent, ToAvatarGroupPipe],
  imports: [CommonModule, PuiAvatarGroupModule, PuiDropdownModule, PuiAvatarModule, PuiList2Module]
})
export class AvatarGroupModule {}
