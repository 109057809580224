// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-guide-leave-profile-editor-modal',
  template: `
    <div class="modal-header">
      <app-button className="close" (click)="cancel()"></app-button>
    </div>
    <div class="modal-body">
      <div class="modal-title">
        <ng-container i18n="@@leaveWithoutSaving">Leave without saving</ng-container>
        ?
      </div>
      <p class="note" i18n="@@youWillLoasUnsavedChanges">
        You're going to leave answers editor and lost unsaved changes.
      </p>
    </div>
    <div class="modal-footer">
      <app-button className="btn btn-lite" (click)="leave()">
        <ng-container i18n="@@leaveWithoutSaving">Leave without saving</ng-container>
      </app-button>
      <app-button className="btn btn-main" (click)="saveAction()" i18n="@@saveAndContinue">
        Save and continue
      </app-button>
    </div>
  `,
  styles: [
    `
      .modal-header,
      .modal-footer {
        border: 0;
      }
      .modal-header {
        justify-content: flex-end;
        padding: 22px 22px 0 22px;
      }
      .modal-body {
        padding: 0 40px 20px 40px;
        text-align: center;
      }
      .modal-footer {
        justify-content: center;
        padding: 0 40px 40px 40px;
      }
      .modal-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        margin-bottom: 10px;
      }
      .note {
        color: #7c8f9e;
        line-height: 24px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientLeaveEditorModalComponent {
  constructor(private _modal: NgbActiveModal) {}

  cancel(): void {
    this._modal.dismiss();
  }

  leave(): void {
    this._modal.close({ save: false });
  }

  saveAction(): void {
    this._modal.close({ save: true });
  }
}
