import { Inject, Injectable } from '@angular/core';
import { SessionStorageProvider } from './session-storage-provider';
import { GLOBAL_WINDOW } from '../browser-window/window-provider';

@Injectable()
export class BrowserSessionStorage extends SessionStorageProvider {
  constructor(@Inject(GLOBAL_WINDOW) private _browserWindow: Window) {
    super();
  }

  get sessionStorage(): Storage | Object {
    return this._browserWindow.sessionStorage;
  }
}
