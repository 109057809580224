import { GUIDE_CLIENTS_STORE_KEY } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/commons/consts';
import { createSelector } from '@ngrx/store';
import { GuideClientsStoreState } from './commons/types';
import { puiSortFunction } from '@awarenow/profi-ui-core';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
// eslint-disable-next-line no-restricted-imports
import { keyBy } from 'lodash';
import { SALES_CLIENT_TAGS } from '@app/screens/guide/guide-clients/consts/stages';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getFullName = ({ firstName, lastName }: CRMClient) => `${firstName} ${lastName}`.trim();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const sortGuideClients = (clients: CRMClient[]) =>
  clients.sort((clientA, clientB) => puiSortFunction('asc')(getFullName(clientA), getFullName(clientB)));

// TODO Types needed
const fromGuideClients = (clients: CRMClient[], key: keyof CRMClient) =>
  Array.from(
    clients.reduce((acc, client) => {
      const value = client[key];

      if (value === undefined || value === null) {
        return acc;
      }

      acc.add(value);

      return acc;
    }, new Set())
  );

export const guideClientsState = (state: {
  [GUIDE_CLIENTS_STORE_KEY]: GuideClientsStoreState;
}): GuideClientsStoreState => state[GUIDE_CLIENTS_STORE_KEY];
// Statuses
export const guideClientsStateStatus = createSelector(guideClientsState, state => state.status);
export const guideClientsIsStatusIdle = createSelector(guideClientsStateStatus, status => status === 'idle');
export const guideClientsIsStatusIdleOrSaving = createSelector(
  guideClientsStateStatus,
  status => status === 'idle' || status === 'saving'
);
// Data
export const guideClientsStateAllClients = createSelector(guideClientsState, state => state.data);
export const guideClientsStateAllClientsArray = createSelector(guideClientsStateAllClients, clients =>
  Object.values(clients)
);
export const clientsByClientId = createSelector(guideClientsStateAllClients, clients => keyBy(clients, 'id'));
export const clientsByRelationId = createSelector(guideClientsStateAllClients, clients => keyBy(clients, 'relationId'));
export const getClientsByClientId = (id: CRMClient['id']) => createSelector(clientsByClientId, clients => clients[id]);
// Filtered
export const guideClientsStateActiveClients = createSelector(guideClientsStateAllClientsArray, clients =>
  clients.filter(client => !client.archived)
);
export const guideClientsStateActiveClientsCount = createSelector(
  guideClientsStateActiveClients,
  clients => clients.length
);
// Sorted and filtered
export const guideClientsStateActiveClientsSorted = createSelector(guideClientsStateActiveClients, clients =>
  sortGuideClients(clients)
);

export const guideClientsStateArchivedClients = createSelector(guideClientsStateAllClientsArray, clients =>
  clients.filter(client => !!client.archived)
);
// Sorted
export const guideClientsStateArchivedClientsCountSorted = createSelector(guideClientsStateArchivedClients, clients =>
  sortGuideClients(clients)
);
// Count
export const guideClientsStateArchivedClientsCount = createSelector(
  guideClientsStateArchivedClients,
  clients => clients.length
);

export const selectGuideClientsOrganizations = createSelector(guideClientsStateAllClientsArray, clients =>
  fromGuideClients(clients, 'organization')
);

export const selectGuideClientsStages = createSelector(guideClientsStateAllClientsArray, clients =>
  fromGuideClients(clients, 'tag')
);

/**
 * Returns a stages map with a value equal to the count of clients in this stage
 * @example
 * {
 *   Suspect: 4
 *   Prospect: 12
 *   Discovery: 3
 *   Proposal: 0
 * }
 */
export const selectGuideClientsStagesWithCount = createSelector(guideClientsStateAllClientsArray, clients => {
  const stagesWithCount = SALES_CLIENT_TAGS.reduce<Record<string, number>>((acc, { name }) => {
    return { ...acc, [name]: 0 };
  }, {});

  for (const client of clients) {
    if (client.tag) {
      stagesWithCount[client.tag] += 1;
    }
  }

  return stagesWithCount;
});

export const clientsCountGroupedByOrganization = createSelector(guideClientsStateAllClientsArray, clients => {
  let organizationWithClientsCount: Record<string, number> = {};

  for (const client of clients) {
    if (client.organization) {
      organizationWithClientsCount = {
        ...organizationWithClientsCount,
        [client.organization]: (organizationWithClientsCount[client.organization] || 0) + 1
      };
    }
  }

  return organizationWithClientsCount;
});

export const clientsCountGroupedByTag = createSelector(guideClientsStateAllClientsArray, clients => {
  let tagsWithCount: Record<string, number> = {};

  for (const client of clients) {
    const tags = client.customFields?.client_tags;

    if (tags) {
      for (const tag of tags) {
        tagsWithCount = {
          ...tagsWithCount,
          [tag as string]: (tagsWithCount[tag as string] || 0) + 1
        };
      }
    }
  }

  return tagsWithCount;
});
