<a class="wide-article" [routerLink]="['/blog/post', link]">
  <span class="image-col order-2 order-md-1">
    <span class="cover-image" [style.backgroundImage]="article?.coverImage | safe: 'styleUrl'"></span>
  </span>
  <span class="info-col order-1 order-md-2">
    <span class="text-adjust">
      <span class="title">{{ article?.title }}</span>

      <span class="description">{{ article?.description | cutString: 200 }}</span>
    </span>

    <span class="tags">
      <ng-container *ngFor="let tag of article.tags">
        <span class="tag">{{ tag.name }}</span>
      </ng-container>
    </span>
  </span>
</a>
