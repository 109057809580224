<h2>
  <ng-container i18n="@@youCompleteProgram">Nice work! You completed this program</ng-container>
  &nbsp;
  {{ completionDate }}
</h2>

<p>
  <ng-container i18n="@@congratsMsg">Congratulations. We hope it serves you well!</ng-container>
  &nbsp;
  <a routerLink="/programs" i18n="@@seeMorePrograms">See more programs</a>
</p>
