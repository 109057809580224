import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EmptyLayoutComponent } from './empty-layout.component';

@NgModule({
  imports: [RouterModule],
  declarations: [EmptyLayoutComponent]
})
export class EmptyLayoutModule {}
