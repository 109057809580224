import { createAction, props } from '@ngrx/store';
import {
  CustomField,
  UpdateCustomField,
  UpdateCustomFieldResult
} from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';
// fetch
export const fetchFields = createAction('[Custom Fields] fetch client tag');
export const fetchFieldsSuccess = createAction(
  '[ ✅ Custom Fields] fetch client tag',
  props<{ payload: CustomField[] }>()
);
export const fetchFieldsError = createAction('[ ⛔️ Custom Fields] fetch client tag');
// create
export const putField = createAction('[Custom Fields] create client tag', props<{ payload: UpdateCustomField }>());
export const putFieldSuccess = createAction(
  '[ ✅ Custom Fields] Put field',
  props<{ payload: UpdateCustomFieldResult[] }>()
);
export const putFieldError = createAction('[ ⛔️ Custom Fields] create client tag');
// update
export const patchField = createAction('[Custom Fields] update client tag', props<{ payload: UpdateCustomField }>());
export const patchFieldSuccess = createAction(
  '[ ✅ Custom Fields] update client tag',
  props<{ payload: UpdateCustomFieldResult[] }>()
);
export const patchFieldError = createAction('[ ⛔️ Custom Fields] update client tag');
// delete
export const deleteField = createAction('[Custom Fields] delete client tag', props<{ payload: UpdateCustomField }>());
export const deleteFieldSuccess = createAction(
  '[ ✅ Custom Fields] delete client tag',
  props<{ payload: UpdateCustomFieldResult[] }>()
);
export const deleteFieldError = createAction('[ ⛔️ Custom Fields] delete client tag');
// reset
export const resetState = createAction('[Custom Fields] Reset state');
