<div class="details" pui-paper shape="rounded" *ngIf="service">
  <div class="pui-space_bottom-3">
    <h4 pui-typography="h4">{{ service.name }}</h4>
  </div>

  <ng-container *ngIf="{ bookings: bookings$ | async, visibleBookings: visibleBookings$ | async } as tableData">
    <table>
      <tbody>
        <ng-container *ngIf="service.description | clearHtml as description">
          <tr *ngIf="description.length > 1">
            <td>
              <div class="d-flex align-items-center">
                <pui-icon size="s" svgIcon="pui:info"></pui-icon>
              </div>
            </td>
            <td>
              <p pui-typography="body-m" pui-text-overflow lineClamp="3">
                {{ description }}
              </p>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <pui-icon class="table__icon" size="s" svgIcon="pui:calendar"></pui-icon>
            </div>
          </td>
          <td>
            <ng-container *ngIf="tableData.bookings && tableData.bookings.length > 0">
              <ng-container *ngIf="tableData.bookings.length > 1">
                <span pui-typography="body-m-bold">{{ tableData.bookings.length }} recurring sessions</span>
              </ng-container>
              <ul class="sessions-date-list" [class.line-through]="cancellation">
                <ng-container *ngFor="let booking of tableData.visibleBookings">
                  <li class="sessions-date-list__item" pui-typography="body-m">
                    <time [attr.datetime]="booking.date">
                      {{ { dateStart: booking.date, duration: service.duration } | sessionRangeFormatter }}
                    </time>
                  </li>
                </ng-container>
              </ul>
              <ng-container *ngIf="tableData.visibleBookings?.length < tableData.bookings.length">
                <button pui-link type="button" pui-typography="body-m" (click)="showMoreBookings()">
                  +{{ tableData.bookings.length - visibleBookingsCount }} more
                </button>
              </ng-container>
            </ng-container>
          </td>
        </tr>
        <tr
          *ngIf="service.location | sessionDetailsConnectionLink: [service.guide, service.guideEventUid] as connection">
          <td>
            <div class="d-flex align-items-center">
              <pui-icon class="table__icon" size="s" [svgIcon]="connection.icon"></pui-icon>
            </div>
          </td>
          <td pui-typography="body-m">
            <ng-container *ngIf="!connection.url; else link">
              {{ connection.label }}
            </ng-container>

            <ng-template #link>
              <a [href]="connection.url" target="_blank">
                {{ connection.label }}
              </a>
            </ng-template>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <pui-icon class="table__icon" size="s" svgIcon="pui:clock"></pui-icon>
            </div>
          </td>
          <td pui-typography="body-m">{{ service.duration }} minutes</td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <pui-icon class="table__icon" size="s" svgIcon="pui:tag"></pui-icon>
            </div>
          </td>
          <td pui-typography="body-m" [ngPlural]="service.price || 0">
            <ng-template ngPluralCase="=0">Free</ng-template>
            <!-- TODO Wrong calculating choose -->
            <ng-template ngPluralCase="other">Total cost: {{ service.price * tableData.bookings.length }}$</ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
