import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Directive({ selector: '[hasRuntimeFeature]' })
export class HasRuntimeFeatureDirective {
  @Input('hasRuntimeFeature') set hasRuntimeFeature(featureName: string) {
    const keys = featureName.split(/\./);

    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO It should work
      this.runtimeConfig.get(...keys)
    ) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }

  constructor(
    private runtimeConfig: RuntimeConfigService,
    private readonly _templateRef: TemplateRef<unknown>,
    private readonly _viewContainer: ViewContainerRef
  ) {}
}
