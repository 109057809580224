// eslint-disable-next-line no-restricted-imports
import { orderBy } from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

export type OrderType = 'asc' | 'desc';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform<T = any>(array: T[], field: keyof T | string[], type: OrderType | OrderType[] = 'asc'): T[] {
    return orderBy(array, Array.isArray(field) ? field : [field], Array.isArray(type) ? type : [type]);
  }
}
