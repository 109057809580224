import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRoles } from '@app/shared/enums/user-roles';
import config from '../config/config';
import { eventFactory, IEventActionResult } from './types';

@Injectable({
  providedIn: 'root'
})
export class SharedEventActionService {
  // @ts-expect-error TS2322
  signupByToken: {
    email: string;
    actionCode: string;
    withoutPassword: boolean;
    firstName?: string;
    role: UserRoles;
  } = null;

  constructor(private _http: HttpClient) {}

  execute$(actionCode: string, actionDetails = {}): Observable<IEventActionResult> {
    return this._http
      .post<IEventActionResult>(`${config.apiPath}/shared-event-actions/encoded/${actionCode}`, actionDetails)
      .pipe(
        map(actionResult => {
          const result: IEventActionResult = {
            complete: actionResult.complete,
            actionType: actionResult.actionType
          };

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.signupByToken = {} as any;
          if (actionResult.actionCode) {
            result.actionCode = actionResult.actionCode;
            this.signupByToken.actionCode = actionResult.actionCode;
          }

          if (actionResult.actionUser) {
            result.actionUser = actionResult.actionUser;
            this.signupByToken.email = actionResult.actionUser.email;
            this.signupByToken.withoutPassword = actionResult.actionUser.withoutPassword;
            this.signupByToken.firstName = actionResult.actionUser.firstName;
            this.signupByToken.role = actionResult.actionUser.role;
          }

          if (actionResult.eventDetails) {
            result.eventDetails = eventFactory(actionResult.eventDetails);
          }

          return result;
        })
      );
  }
}
