import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DesktopDirective,
  DesktopOrMediumTabletDirective,
  MobileDirective,
  MobileOrSmallTabletDirective,
  NotDesktopDirective,
  NotMobileDirective
} from './entries';

@NgModule({
  declarations: [
    NotDesktopDirective,
    DesktopDirective,
    NotMobileDirective,
    MobileDirective,
    MobileOrSmallTabletDirective,
    DesktopOrMediumTabletDirective
  ],
  exports: [
    NotDesktopDirective,
    DesktopDirective,
    NotMobileDirective,
    MobileDirective,
    MobileOrSmallTabletDirective,
    DesktopOrMediumTabletDirective
  ],
  imports: [CommonModule]
})
export class ScreenModule {}
