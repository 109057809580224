import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { customFieldsReducer } from './custom-field.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomFieldsEffects } from '@app/screens/guide/guide-clients/guide-relations-table/store/custom-fields/custom-fields-effects.service';
import { CUSTOM_FIELDS_KEY } from './commons/consts';
import { CustomFieldsApiService } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';

@NgModule({
  imports: [
    StoreModule.forFeature(CUSTOM_FIELDS_KEY, customFieldsReducer),
    EffectsModule.forFeature([CustomFieldsEffects])
  ],
  providers: [CustomFieldsApiService]
})
export class CustomFieldsStoreModule {}
