import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AbstractNavigationEntriesService } from '@app/modules/navigation/abstracts';
import { INavigationEntry } from '@app/modules/navigation/interfaces';

@Injectable()
export class NavigationEntriesClientService extends AbstractNavigationEntriesService {
  role = UserRoles.CLIENT;

  entries: Observable<INavigationEntry>[] = [
    // sidenav - primary
    this.navigationEntryFactory.clientSessions$(this.dependencies),
    this.navigationEntryFactory.clientInbox$(this.dependencies),
    this.navigationEntryFactory.clientSecureMessaging$(this.dependencies),
    this.navigationEntryFactory.clientMarketplace$(),
    this.navigationEntryFactory.clientPrograms$(this.dependencies),
    this.navigationEntryFactory.publicEvents$(),
    this.navigationEntryFactory.clientForms$(),
    this.navigationEntryFactory.clientCommunity$(),

    // widget - secondary
    this.navigationEntryFactory.clientSettings$(),
    this.navigationEntryFactory.whatsNew$(this.dependencies),

    // widget - client
    this.navigationEntryFactory.clientSettingsMyAccount$(),
    this.navigationEntryFactory.clientSettingsWallet$(),
    this.navigationEntryFactory.clientSettingsSubscription$(),

    // widget - links
    this.navigationEntryFactory.urlCancellationPolicy$(this.dependencies),
    this.navigationEntryFactory.urlPrivacy$(this.dependencies),
    this.navigationEntryFactory.urlTerms$(this.dependencies),
    this.navigationEntryFactory.emailHelp$(this.dependencies),
    this.navigationEntryFactory.clientSettingsSignupAsCoach$(this.dependencies)
  ];
}
