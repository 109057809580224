import { BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { PuiTimeZone, WINDOW } from '@awarenow/profi-ui-core';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * This service contains temporary setting of client timezone.
 *
 * WARNING: Do not confuse service with guide-timezone
 */
@Injectable({ providedIn: 'root' })
export class TimezoneProvider extends BehaviorSubject<PuiTimeZone> {
  static readonly STORAGE_KEY = '_timezone';

  constructor(@Inject(WINDOW) window: Window) {
    super(
      (window.sessionStorage.getItem(TimezoneProvider.STORAGE_KEY) as PuiTimeZone | undefined) ||
        (Intl.DateTimeFormat().resolvedOptions().timeZone as PuiTimeZone)
    );

    this.pipe(distinctUntilChanged()).subscribe(value => {
      window.sessionStorage.setItem(TimezoneProvider.STORAGE_KEY, value);
    });
  }
}
