import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

import { EventStatusComponent } from './event-status.component';

@NgModule({
  imports: [CommonModule, PuiIconModule, PuiTypographyModule],
  declarations: [EventStatusComponent],
  exports: [EventStatusComponent]
})
export class EventStatusComponentModule {}
