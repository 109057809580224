import { ApplicationEvent } from '../applications-events.service';

export class AuthEvent extends ApplicationEvent {
  constructor(readonly user: { [key: string]: unknown }) {
    console.log('AuthEvent', user);

    super();
  }
}

/**
 * {
 *     "workspaceId": 186466,
 *     "firstName": "Belg",
 *     "lastName": "Fae",
 *     "timezone": "Europe/Belgrade",
 *     "namedUrl": "belgfae",
 *     "photo": "https://static-test.awarenow.io/avatars/thumbnail-Az3fz_e8u1UZRn5c2v9JLDCA.jpeg",
 *     "confirmed": false,
 *     "profileState": "draft",
 *     "profileType": "public",
 *     "User": {
 *         "id": 193510,
 *         "email": "belaf22746@miqlab.com",
 *         "username": "belaf22746@miqlab.com",
 *         "RoleId": 2,
 *         "lastActive": "2023-07-14T15:33:48.174Z",
 *         "online": false,
 *         "status": true,
 *         "emailVerified": false,
 *         "free": false,
 *         "phone": null,
 *         "phoneVerified": false,
 *         "smsNotificationsEnabled": true,
 *         "lastTimezone": "Europe/Belgrade",
 *         "platformPromoter": true,
 *         "additionalInfo": "",
 *         "preferredLanguage": null,
 *         "organizationId": 1,
 *         "isSsoAuth": false,
 *         "tfaAppEnabled": false,
 *         "tfaSmsEnabled": false,
 *         "createdAt": "2023-07-19T11:35:48.946Z",
 *         "updatedAt": "2023-11-23T15:30:33.585Z"
 *     },
 *     "isSsoAuth": false,
 *     "tfaAppEnabled": false,
 *     "tfaSmsEnabled": false,
 *     "membership": null,
 *     "id": 193510,
 *     "email": "belaf22746@miqlab.com",
 *     "hashedEmail": "e4414631967e993fb1a711c0783a8701f0a1b96d73f1c45bdc7199134727cea7",
 *     "hashedId": "a5788bae8276d70624b2d1206b790b1a:f06499ad17cc313ca0ad8bc8ac609c60",
 *     "emailVerified": false,
 *     "RoleId": 2,
 *     "free": false,
 *     "hasAlternativeProfile": false,
 *     "time": "2023-12-11T09:55:11.736Z",
 *     "isPlatformPromoter": true,
 *     "createdAt": "2023-07-19T11:35:48.946Z",
 *     "analyticStats": {
 *         "referrer": "http%3A%2F%2Flocalhost%3A4200%2F",
 *         "landing": "https%3A%2F%2Ftest.awarenow.io%2Fauth%2Fsign-in",
 *         "firstPlatformSubscriptionDate": null
 *     },
 *     "preferredLanguage": null,
 *     "authToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTkzNTEwLCJlbWFpbCI6ImJlbGFmMjI3NDZAbWlxbGFiLmNvbSIsInJvbGUiOjIsIndvcmtzcGFjZUlkIjoxODY0NjYsImlhdCI6MTcwMjI4ODUxMSwiZXhwIjoxNzA5NDg4NTExfQ.nU1dfzOPU-r_fmEERBnximI5pjPKOLdqOqRdTvj0I9w",
 *     "lastTimezone": "Europe/Belgrade",
 *     "incompleteSettings": [
 *         "language",
 *         "salesText",
 *         "tags"
 *     ],
 *     "remember": true
 * }
 */
