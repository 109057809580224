import { QuizQuestionType } from '@app/core/quizzes/types';
import { convertServerSession } from '@app/core/session/converters';
import { ClientGuide } from '@app/core/users/types';
import {
  ChoiceQuestionAnswer,
  ClientProgram,
  ContentModule,
  FileUploadQuestion,
  GroupSessionModule,
  IClientQuestionAnswer,
  isContentModule,
  isFileUploadQuestion,
  isGroupSessionModule,
  isLongAnswerQuestion,
  isMultipleChoiceQuestion,
  isQuizChoiceQuestion,
  isQuizModule,
  isRestrictedModule,
  isSessionModule,
  isShortAnswerQuestion,
  isSignatureQuestion,
  isSingleChoiceQuestion,
  isTextQuestion,
  LongAnswerQuestion,
  ModuleInstructor,
  ModuleService,
  ModuleStatuses,
  MultipleChoiceQuestion,
  OpenedModuleStatus,
  ProgramModule,
  QuestionAnswer,
  QuestionOption,
  Quiz,
  QuizChoiceQuestion,
  QuizModule,
  QuizQuestion,
  QuizStatuses,
  RestrictedModule,
  SessionModule,
  ShortAnswerQuestion,
  SignatureQuestion,
  SingleChoiceQuestion,
  TextQuestion
} from '@app/shared/interfaces/programs/client-programs';
import { ModuleCompletionTypes, ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { SimpleSession } from '@app/shared/interfaces/session';
import { assertNever } from '@app/shared/utils/assertions';

import { IClientProgramDetails, IClientProgramModuleDetails, IClientQuizQuestion } from './types';

export function buildClientQuizQuestions(clientQuizQuestionDetails: IClientQuizQuestion): QuizQuestion {
  if (Array.isArray(clientQuizQuestionDetails.options) && clientQuizQuestionDetails.options.length > 0) {
    const questionDetails = {
      id: clientQuizQuestionDetails.id,
      question: clientQuizQuestionDetails.question,
      createdAt: clientQuizQuestionDetails.createdAt,
      options: clientQuizQuestionDetails.options.map(
        clientQuestionOption =>
          new QuestionOption({
            id: clientQuestionOption.id,
            text: clientQuestionOption.text,
            isCorrect: clientQuestionOption.isCorrect,
            explanation: clientQuestionOption.explanation,
            other: clientQuestionOption.other
          })
      ),
      answer:
        clientQuizQuestionDetails.answer != null
          ? new ChoiceQuestionAnswer({
              explanation: clientQuizQuestionDetails.answer?.explanation,
              // @ts-expect-error TS2322
              options: clientQuizQuestionDetails.answer.options,
              text: clientQuizQuestionDetails.answer.text
            })
          : null
    };

    switch (clientQuizQuestionDetails.type) {
      case QuizQuestionType.QUIZ:
        // @ts-expect-error TS2345
        return new QuizChoiceQuestion(questionDetails);
      case QuizQuestionType.MULTIPLE_CHOICE:
        // @ts-expect-error TS2345
        return new MultipleChoiceQuestion(questionDetails);
      case QuizQuestionType.SINGLE_CHOICE:
        // @ts-expect-error TS2345
        return new SingleChoiceQuestion(questionDetails);
    }
  }

  if (clientQuizQuestionDetails.type === QuizQuestionType.FILE_UPLOAD) {
    return new FileUploadQuestion({
      id: clientQuizQuestionDetails.id,
      question: clientQuizQuestionDetails.question,
      // @ts-expect-error TS2322
      createdAt: clientQuizQuestionDetails.createdAt,
      // @ts-expect-error TS2322
      answer:
        clientQuizQuestionDetails.answer != null
          ? new QuestionAnswer({
              // @ts-expect-error TS2322
              text: clientQuizQuestionDetails.answer.text,
              fileInfo: clientQuizQuestionDetails.answer.fileInfo
            })
          : null
    });
  }

  if (clientQuizQuestionDetails.type === QuizQuestionType.TEXT) {
    return new TextQuestion({
      id: clientQuizQuestionDetails.id,
      question: clientQuizQuestionDetails.question,
      // @ts-expect-error TS2322
      createdAt: clientQuizQuestionDetails.createdAt,
      // @ts-expect-error TS2322
      answer: null
    });
  }

  if (clientQuizQuestionDetails.type === QuizQuestionType.SIGNATURE) {
    return new SignatureQuestion({
      id: clientQuizQuestionDetails.id,
      question: clientQuizQuestionDetails.question,
      // @ts-expect-error TS2322
      createdAt: clientQuizQuestionDetails.createdAt,
      // @ts-expect-error TS2322
      answer: clientQuizQuestionDetails.answer ? { text: clientQuizQuestionDetails.answer.text } : null
    });
  }

  if (clientQuizQuestionDetails.type === QuizQuestionType.SHORT_ANSWER) {
    return new ShortAnswerQuestion({
      id: clientQuizQuestionDetails.id,
      question: clientQuizQuestionDetails.question,
      // @ts-expect-error TS2322
      createdAt: clientQuizQuestionDetails.createdAt,
      // @ts-expect-error TS2322
      answer:
        clientQuizQuestionDetails.answer != null
          ? // @ts-expect-error TS2322
            new QuestionAnswer({ text: clientQuizQuestionDetails.answer.text })
          : null
    });
  }

  return new LongAnswerQuestion({
    id: clientQuizQuestionDetails.id,
    question: clientQuizQuestionDetails.question,
    // @ts-expect-error TS2322
    createdAt: clientQuizQuestionDetails.createdAt,
    // @ts-expect-error TS2322
    answer:
      clientQuizQuestionDetails.answer != null
        ? // @ts-expect-error TS2322
          new QuestionAnswer({ text: clientQuizQuestionDetails.answer.text })
        : null
  });
}

export function buildClientModule(clientModuleDetails: IClientProgramModuleDetails): ProgramModule {
  const moduleInstructor =
    clientModuleDetails.instructor &&
    new ModuleInstructor({
      id: clientModuleDetails.instructor.id,
      workspaceId: clientModuleDetails.instructor.workspaceId,
      namedUrl: clientModuleDetails.instructor.namedUrl,
      firstName: clientModuleDetails.instructor.firstName,
      lastName: clientModuleDetails.instructor.lastName,
      photo: clientModuleDetails.instructor.photo
    });

  if (clientModuleDetails.status === 'restricted') {
    return new RestrictedModule({
      id: clientModuleDetails.id,
      order: clientModuleDetails.order,
      title: clientModuleDetails.title,
      description: clientModuleDetails.description,
      type: clientModuleDetails.moduleType,
      note: clientModuleDetails.note || null,
      instructor: moduleInstructor
    });
  }

  switch (clientModuleDetails.moduleType) {
    case ModuleTypes.CONTENT:
      return new ContentModule({
        id: clientModuleDetails.id,
        order: clientModuleDetails.order,
        title: clientModuleDetails.title,
        description: clientModuleDetails.description,
        status: clientModuleDetails.status,
        completionType: clientModuleDetails.completionType,
        // @ts-expect-error TS2322
        content: clientModuleDetails.content,
        note: clientModuleDetails.note || null,
        instructor: moduleInstructor
      });
    case ModuleTypes.QUIZ:
      return new QuizModule({
        id: clientModuleDetails.id,
        order: clientModuleDetails.order,
        title: clientModuleDetails.title,
        description: clientModuleDetails.description,
        status: clientModuleDetails.status,
        completionType: clientModuleDetails.completionType,
        quiz: new Quiz({
          status: clientModuleDetails.quiz?.status || QuizStatuses.ACTIVE,
          // @ts-expect-error TS2322
          questions: clientModuleDetails.quiz?.questions?.map(buildClientQuizQuestions)
        }),
        note: clientModuleDetails.note || null,
        instructor: moduleInstructor
      });
    case ModuleTypes.GROUP_SESSION:
      return new GroupSessionModule({
        id: clientModuleDetails.id,
        order: clientModuleDetails.order,
        title: clientModuleDetails.title,
        description: clientModuleDetails.description,
        status: clientModuleDetails.status,
        completionType: clientModuleDetails.completionType,
        service: new ModuleService({
          // @ts-expect-error TS2532
          id: clientModuleDetails.service?.id,
          // @ts-expect-error TS2532
          type: clientModuleDetails.service?.type,
          // @ts-expect-error TS2532
          connectionType: clientModuleDetails.service?.connectionType,
          // @ts-expect-error TS2532
          name: clientModuleDetails.service?.name,
          // @ts-expect-error TS2532
          duration: clientModuleDetails.service?.duration,
          description: clientModuleDetails.service?.description,
          // @ts-expect-error TS2532
          guideId: clientModuleDetails.service?.guideId,
          restrictClientBooking: clientModuleDetails.service?.restrictClientBooking
        }),
        session: clientModuleDetails.session
          ? // @ts-expect-error TS2345
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            <SimpleSession>convertServerSession(clientModuleDetails.session, null)
          : undefined,
        sessionId: clientModuleDetails.sessionId || undefined,
        note: clientModuleDetails.note || null,
        instructor: moduleInstructor
      });
    case ModuleTypes.SESSION:
      return new SessionModule({
        id: clientModuleDetails.id,
        order: clientModuleDetails.order,
        title: clientModuleDetails.title,
        description: clientModuleDetails.description,
        status: clientModuleDetails.status,
        completionType: clientModuleDetails.completionType,
        service: clientModuleDetails.service
          ? new ModuleService({
              id: clientModuleDetails.service.id,
              type: clientModuleDetails.service.type,
              connectionType: clientModuleDetails.service.connectionType,
              name: clientModuleDetails.service.name,
              duration: clientModuleDetails.service.duration,
              description: clientModuleDetails.service.description,
              guideId: clientModuleDetails.service.guideId
            })
          : null,
        session: clientModuleDetails.session
          ? // @ts-expect-error TS2345
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            <SimpleSession>convertServerSession(clientModuleDetails.session, null)
          : undefined,
        sessionId: clientModuleDetails.sessionId || undefined,
        note: clientModuleDetails.note || null,
        instructor: moduleInstructor,
        hosts: clientModuleDetails.hosts
      });
    default:
      assertNever(clientModuleDetails);
  }
}

export function buildClientProgram(clientProgramDetails: IClientProgramDetails): ClientProgram {
  return new ClientProgram({
    id: clientProgramDetails.programId,
    name: clientProgramDetails.programName,
    notStarted: clientProgramDetails.notStarted,
    author: new ClientGuide({
      id: clientProgramDetails.author.id,
      firstName: clientProgramDetails.author.firstName,
      lastName: clientProgramDetails.author.lastName,
      photo: clientProgramDetails.author.photo,
      namedUrl: clientProgramDetails.author.namedUrl,
      about: clientProgramDetails.author.about,
      specialization: clientProgramDetails.author.specialization
    }),
    modules: clientProgramDetails.modules.map(buildClientModule),
    workspaceType: clientProgramDetails.workspaceType
  });
}

export function retryProgramQuiz(clientProgram: ClientProgram, moduleId: number): ClientProgram {
  return new ClientProgram({
    ...clientProgram,
    modules: clientProgram.modules.map(module =>
      module.id !== moduleId || !isQuizModule(module)
        ? module
        : new QuizModule({
            ...module,
            quiz: new Quiz({
              ...module.quiz,
              status: QuizStatuses.RETRY,
              questions: module.quiz?.questions.map(question => {
                if (isMultipleChoiceQuestion(question)) {
                  // @ts-expect-error TS2322
                  return new MultipleChoiceQuestion({ ...question, answer: null });
                }

                if (isSingleChoiceQuestion(question)) {
                  // @ts-expect-error TS2322
                  return new SingleChoiceQuestion({ ...question, answer: null });
                }

                if (isQuizChoiceQuestion(question)) {
                  // @ts-expect-error TS2322
                  return new QuizChoiceQuestion({ ...question, answer: null });
                }

                if (isSignatureQuestion(question)) {
                  // @ts-expect-error TS2322
                  return new SignatureQuestion({ ...question, answer: null });
                }

                if (isFileUploadQuestion(question)) {
                  // @ts-expect-error TS2322
                  return new FileUploadQuestion({ ...question, answer: null });
                }

                if (isTextQuestion(question)) {
                  // @ts-expect-error TS2322
                  return new TextQuestion({ ...question, answer: null });
                }

                // @ts-expect-error TS2322
                return new LongAnswerQuestion({ ...question, answer: null });
              })
            })
          })
    )
  });
}

export function finishProgramQuiz(
  clientProgram: ClientProgram,
  moduleId: number,
  finishDetails: { isFinished: boolean; successor?: IClientProgramModuleDetails }
): ClientProgram {
  const { isFinished, successor } = finishDetails;

  if (!isFinished && !successor) {
    return clientProgram;
  }

  return new ClientProgram({
    ...clientProgram,
    modules: clientProgram.modules.map(module => {
      if (successor && module.id === successor.id) {
        return buildClientModule(successor);
      }

      if (isFinished && module.id === moduleId && isQuizModule(module)) {
        return new QuizModule({
          ...module,
          status:
            module.completionType === ModuleCompletionTypes.AFTER_SUBMIT_QUIZ ? ModuleStatuses.SEEN : module.status,
          quiz: new Quiz({
            ...module.quiz,
            status: QuizStatuses.COMPLETED
          })
        });
      }

      return module;
    })
  });
}

export function updateAnsweredQuiz(
  clientProgram: ClientProgram,
  moduleId: number,
  answers: IClientQuestionAnswer[],
  answeredQuizVerification: {
    successor?: IClientProgramModuleDetails;
    quizStatus: QuizStatuses;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    verifiedAnswers: any;
  }
): ClientProgram {
  return new ClientProgram({
    ...clientProgram,
    modules: clientProgram.modules.map(module => {
      const { successor, quizStatus, verifiedAnswers } = answeredQuizVerification;

      if (successor && successor.id === module.id) {
        return buildClientModule(successor);
      }

      if (module.id === moduleId && isQuizModule(module)) {
        return new QuizModule({
          ...module,
          status:
            module.completionType === ModuleCompletionTypes.AFTER_SUBMIT_QUIZ && quizStatus === QuizStatuses.COMPLETED
              ? ModuleStatuses.SEEN
              : module.status,
          quiz: new Quiz({
            ...module.quiz,
            status: quizStatus,
            questions: module.quiz.questions.map(question => {
              if (question.answer) {
                return question;
              }

              const questionAnswer = answers.find(answer => +question.id === +answer.questionId);
              if (!questionAnswer) {
                return question;
              }

              if (isSingleChoiceQuestion(question)) {
                return new SingleChoiceQuestion({
                  // @ts-expect-error TS2783
                  options: [],
                  ...question,
                  answer: new ChoiceQuestionAnswer({
                    // @ts-expect-error TS2532
                    options: questionAnswer.options?.map(id => ({
                      id,
                      isCorrect: verifiedAnswers[question.id].options[id]
                    })),
                    explanation: verifiedAnswers[question.id]?.explanation,
                    // @ts-expect-error TS2322
                    text: questionAnswer.text
                  })
                });
              }

              if (isQuizChoiceQuestion(question)) {
                return new QuizChoiceQuestion({
                  // @ts-expect-error TS2783
                  options: [],
                  ...question,
                  answer: new ChoiceQuestionAnswer({
                    // @ts-expect-error TS2532
                    options: questionAnswer.options?.map(id => ({
                      id,
                      isCorrect: verifiedAnswers[question.id].options[id]
                    })),
                    explanation: verifiedAnswers[question.id]?.explanation
                  })
                });
              }

              if (isSignatureQuestion(question)) {
                return new SignatureQuestion({
                  ...question,
                  // @ts-expect-error TS2322
                  answer: new QuestionAnswer({ text: questionAnswer.text })
                });
              }

              if (isLongAnswerQuestion(question)) {
                return new LongAnswerQuestion({
                  ...question,
                  // @ts-expect-error TS2322
                  answer: new QuestionAnswer({ text: questionAnswer.text })
                });
              }

              if (isShortAnswerQuestion(question)) {
                return new ShortAnswerQuestion({
                  ...question,
                  // @ts-expect-error TS2322
                  answer: new QuestionAnswer({ text: questionAnswer.text })
                });
              }

              if (isFileUploadQuestion(question)) {
                return new FileUploadQuestion({
                  ...question,
                  // @ts-expect-error TS2322
                  answer: new QuestionAnswer({ text: questionAnswer.text, fileInfo: questionAnswer.fileInfo })
                });
              }

              if (isTextQuestion(question)) {
                return new TextQuestion({
                  ...question,
                  // @ts-expect-error TS2322
                  answer: null
                });
              }

              return new MultipleChoiceQuestion({
                ...question,
                answer: new ChoiceQuestionAnswer({
                  // @ts-expect-error TS2532
                  options: questionAnswer.options?.map(id => ({
                    id,
                    isCorrect: verifiedAnswers[question.id]?.options[id]
                  })),
                  explanation: verifiedAnswers[question.id]?.explanation,
                  // @ts-expect-error TS2322
                  text: questionAnswer.text
                })
              });
            })
          })
        });
      }

      return module;
    })
  });
}

export function updateProgramModuleStatus(
  clientProgram: ClientProgram,
  moduleId: number,
  status: OpenedModuleStatus,
  successor?: IClientProgramModuleDetails
): ClientProgram {
  return new ClientProgram({
    ...clientProgram,
    // @ts-expect-error TS2322
    modules: clientProgram.modules.map(module => {
      if (successor && module.id === successor.id) {
        return buildClientModule(successor);
      }

      if (module.id !== moduleId || isRestrictedModule(module)) {
        return module;
      }

      if (isContentModule(module)) {
        return new ContentModule({ ...module, status });
      }

      if (isQuizModule(module)) {
        return new QuizModule({ ...module, status });
      }

      if (isGroupSessionModule(module)) {
        return new GroupSessionModule({ ...module, status });
      }

      if (isSessionModule(module)) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return new SessionModule({ ...(<SessionModule>module), status });
      }
    })
  });
}

export function updateProgramSessions(clientProgram: ClientProgram, programSessions: SimpleSession[]): ClientProgram {
  return new ClientProgram({
    ...clientProgram,
    modules: clientProgram.modules.map(module => {
      // https://profi-io.atlassian.net/browse/PR-3891
      if (
        (!isSessionModule(module) && !isGroupSessionModule(module)) ||
        (module.session == null && module.sessionId == null)
      ) {
        return module;
      }

      const moduleSessionId = module.session ? module.session.id : module.sessionId;

      // https://profi-io.atlassian.net/browse/PR-3891

      if (isSessionModule(module)) {
        return new SessionModule({
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ...(<SessionModule>module),
          session: programSessions.find(programSession => programSession.id === moduleSessionId)
        });
      }

      return new GroupSessionModule({
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(<GroupSessionModule>module),
        session: programSessions.find(programSession => programSession.id === moduleSessionId)
      });
    })
  });
}

export function addProgramModuleSessionId(
  clientProgram: ClientProgram,
  moduleId: number,
  sessionId: number
): ClientProgram {
  return new ClientProgram({
    ...clientProgram,
    modules: clientProgram.modules.map(module => {
      if (module.id !== moduleId || (!isSessionModule(module) && !isGroupSessionModule(module))) {
        return module;
      }

      if (isSessionModule(module)) {
        return new SessionModule({
          ...module,
          sessionId,
          // @ts-expect-error TS2322
          session: null
        });
      }

      return new GroupSessionModule({
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(<GroupSessionModule>module),
        sessionId,
        // @ts-expect-error TS2322
        session: null
      });
    })
  });
}

export function isChoiceQuestion(questionType: QuizQuestionType): boolean {
  return [QuizQuestionType.QUIZ, QuizQuestionType.SINGLE_CHOICE, QuizQuestionType.MULTIPLE_CHOICE].includes(
    questionType
  );
}
