import { AppFormFieldControl } from '@app/modules/ui-kit/form/components/form-field/form-field.component';
import { Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';

@Component({
  selector: 'ui-input',
  templateUrl: 'input.html',
  styleUrls: ['./input.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-input'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => UiInput) }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiInput implements AppFormFieldControl {
  stateChanges = new Subject<void>();
  // @ts-expect-error TS2564
  focused: boolean;
}
