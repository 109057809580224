import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { merge, Observable, of, Subject, throwError } from 'rxjs';

import { IBookSessionServiceEvent, PublicSessionTemplate } from '@app/shared/interfaces/services';
import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { LocaleService } from '@app/core/locale/locale.service';
import { makeServiceLinkBuilder } from '@app/shared/utils/humanize-id-url';
import { BrandingService } from '@app/core/branding/branding.service';
import { SocketService } from '@app/core/socket/socket.service';
import {
  PUBLIC_SESSION_TEMPLATE_INFO,
  PUBLIC_SESSION_TEMPLATE_PROVIDERS
} from '../../services/public-session-template.providers';
import { PublicSessionTemplateMetaService } from '../../services/public-session-template-meta.service';
import { PublicSessionsTemplatesApi } from '../../services/public-sessions-templates-api.service';

@Component({
  selector: 'app-session-template-landing',
  templateUrl: './session-template-landing.component.html',
  styleUrls: ['./session-template-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PUBLIC_SESSION_TEMPLATE_PROVIDERS, PublicSessionTemplateMetaService, PublicSessionsTemplatesApi]
})
export class SessionTemplateLandingComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  template$: Observable<PublicSessionTemplate>;

  constructor(
    private readonly _brandingService: BrandingService,
    private readonly _localeService: LocaleService,
    private readonly _meta: PublicSessionTemplateMetaService,
    private readonly _notifications: NotificationsService,
    private readonly _router: Router,
    private readonly _serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>,
    @Inject(PUBLIC_SESSION_TEMPLATE_INFO)
    private readonly _templateProvider$: Observable<PublicSessionTemplate>,
    private readonly _socketService: SocketService
  ) {
    const passOrBuildServiceLink = makeServiceLinkBuilder<PublicSessionTemplate>(
      `${this._localeService.getLocale().baseUrl}/sessions`
    );

    this.template$ = merge(_socketService.onSessionChanged(), of(null)).pipe(
      switchMap(() => this._templateProvider$),
      map(passOrBuildServiceLink),
      catchError(error => {
        this._router.navigate(['/not-found'], { replaceUrl: true }).then();
        return throwError(error);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  book(bookServiceEvent: IBookSessionServiceEvent): void {
    const bookingOptions: IServiceBookingOptions<IGuideService> =
      this._serviceBooking.extractBookingOptions(bookServiceEvent);

    this._serviceBooking
      .book$(bookingOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          const title = `Thank you!`;
          const content = `Once your guide confirmed the session we will send you a confirmation email and you will see the session on your dashboard.`;
          this._notifications.success(title, content);
          this._router.navigate(['/client/dashboard']).then();
        },
        error => {
          if (error && error.error && error.error.msg) {
            this._notifications.error(error.error.msg);
          } else {
            this._notifications.error(`Failed to book`);
          }
        }
      );
  }
}
