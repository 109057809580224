import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-hide-auth-body',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HideAuthBodyComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isBrowser: boolean;

  constructor(
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID)
    private _platformId,
    @Inject(DOCUMENT)
    private _document: Document,
    private _renderer: Renderer2
  ) {
    this._isBrowser = isPlatformBrowser(_platformId);
  }

  ngOnInit(): void {
    if (!this._isBrowser) {
      return;
    }

    this.toggleAuthLayoutBody('hide');
  }

  ngOnDestroy(): void {
    if (!this._isBrowser) {
      return;
    }

    this.toggleAuthLayoutBody('show');
  }

  private toggleAuthLayoutBody(action?: 'hide' | 'show'): void {
    const authLayoutBody = this._document.querySelector('app-auth-body');

    if (!authLayoutBody) {
      return;
    }

    if (action && action === 'show') {
      this._renderer.removeClass(authLayoutBody, 'hidden');
    } else {
      this._renderer.addClass(authLayoutBody, 'hidden');
    }
  }
}
