import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IGuideService, IServiceBookingOptions } from '@app/modules/book-service';
import { GuideService, GuideServiceTypes } from '@app/shared/interfaces/services';
import { Observable } from 'rxjs';
// eslint-disable-next-line no-restricted-imports, @nrwl/nx/enforce-module-boundaries
import config from '../../../../../../../../src/app/core/config/config';
import { IServiceWorkspaceAssignee } from '@app/screens/guide/guide-sessions-templates/types';
import { map } from 'rxjs/operators';
import { LocaleService } from '@app/core/locale/locale.service';
import { normalizeFullName } from '@app/shared/utils/full-name';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { WithDuration, WithWorkspaceID } from '@app/base';

@Injectable({
  providedIn: 'root'
})
export class ServiceProvidersService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _API_ENDPOINT = `${config.apiPath}/public/services`;

  constructor(private readonly _http: HttpClient, private _localeService: LocaleService) {}

  getServiceProviders<ResponseItem extends IServiceWorkspaceAssignee['guide']>(
    { id: serviceId, type: serviceType }: Pick<IGuideService, 'id' | 'type'>,
    options?: Pick<IServiceBookingOptions<GuideService>, 'date' | 'timezone'> &
      Partial<WithWorkspaceID> &
      Partial<WithDuration> &
      Partial<{ availableHosts: number[] }> &
      Partial<{ requestConfiguration: boolean }> &
      Partial<{ onlyHosts: boolean }>
  ): Observable<ResponseItem[]> {
    let params: HttpParams;
    const host = this._localeService.getLocale().baseUrl;

    if (options) {
      const queryParams = {};

      Object.keys(options).forEach(prop => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (options[prop] !== null && options[prop] !== undefined) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          queryParams[prop] = options[prop].toString();
        }
      });

      params = new HttpParams({
        encoder: new CustomUrlQueryEncoder(),
        fromObject: queryParams
      });
    }

    // @ts-expect-error TS2454
    return this._http.get<{ team: ResponseItem[] }>(this._getFullUrl(serviceType, serviceId), { params }).pipe(
      map(({ team }) =>
        team
          .map((guide: ResponseItem) => ({
            ...guide,
            name: normalizeFullName(guide),
            namedUrl: `${host}/${guide.namedUrl}`
          }))
          .filter((guide: ResponseItem) => {
            if (!options || !options?.availableHosts) {
              return true;
            }

            // If we have options passed with availableHosts from timeSlot, here we filter guides that have free time
            return options.availableHosts.includes(guide.id);
          })
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getFullUrl(serviceType: GuideServiceTypes, serviceId: number): string {
    switch (serviceType) {
      case GuideServiceTypes.GROUP_SESSION:
        return `${this._API_ENDPOINT}/${GuideServiceTypes.SESSION}s/${serviceId}/assignees`;
      default:
        return `${this._API_ENDPOINT}/${serviceType}s/${serviceId}/assignees`;
    }
  }
}
