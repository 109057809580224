import { DateTime, Duration } from 'luxon';
// @ts-expect-error TS7016
import humanizeDuration from 'humanize-duration';

export function humanize(duration: number | Duration /* in ms */): string {
  const innerDuration = duration instanceof Duration ? duration.as('milliseconds') : duration;

  if (innerDuration > 0) {
    return `in ${humanizeDuration(innerDuration, { round: true, largest: 1 })}`;
  } else {
    return `${humanizeDuration(innerDuration, { round: true, largest: 1 })} ago`;
  }
}

export function humanizeDateOnly(duration: number | Duration /* in ms */): string {
  const innerDuration = duration instanceof Duration ? duration.as('milliseconds') : duration;
  return humanizeDuration(innerDuration, { round: true, largest: 1 });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function humanizeTimezone(timezone?: string) {
  let dt = DateTime.local();
  if (timezone) {
    dt = dt.setZone(timezone);
  }
  // @ts-expect-error TS2532
  const zoneName = dt.zoneName.split('/').pop().replace(new RegExp('_', 'g'), ' ');
  const offsetStr = dt.offset !== 0 ? dt.toFormat('Z') : '';

  return `${zoneName} UTC${offsetStr}`;
}
