import { FactoryProvider, Optional, SkipSelf } from '@angular/core';
import {
  ScheduleDateTimeFormatStrategy,
  SCHEDULE_DATE_FORMAT_STRATEGY,
  SCHEDULE_TIME_FORMAT_STRATEGY
} from '../schedule-date-time-format-strategies';
import { ScheduleTimeSlotsBuildStrategy } from './schedule-time-slots-build-strategy';
import { DefaultMultiDateScheduleTimeSlotsBuildStrategy } from './default-multi-date-schedule-time-slots-build-strategy';
import { DefaultScheduleTimeSlotsBuildStrategy } from './default-schedule-time-slots-build-strategy';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_FACTORY(
  parent: ScheduleTimeSlotsBuildStrategy<unknown, unknown>,
  timeLabelFormatter: ScheduleDateTimeFormatStrategy<unknown>
) {
  return parent || new DefaultScheduleTimeSlotsBuildStrategy(timeLabelFormatter);
}

export const SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER: FactoryProvider = {
  provide: ScheduleTimeSlotsBuildStrategy,
  deps: [[new Optional(), new SkipSelf(), ScheduleTimeSlotsBuildStrategy], SCHEDULE_TIME_FORMAT_STRATEGY],
  useFactory: SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_FACTORY
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export function MULTI_DATE_SCHEDULE_SLOTS_BUILD_STRATEGY_FACTORY(
  parent: ScheduleTimeSlotsBuildStrategy<unknown, unknown>,
  dateLabelFormatter: ScheduleDateTimeFormatStrategy<unknown>,
  timeLabelFormatter: ScheduleDateTimeFormatStrategy<unknown>
) {
  return parent || new DefaultMultiDateScheduleTimeSlotsBuildStrategy(dateLabelFormatter, timeLabelFormatter);
}

export const MULTI_DATE_SCHEDULE_SLOTS_BUILD_STRATEGY_PROVIDER: FactoryProvider = {
  provide: ScheduleTimeSlotsBuildStrategy,
  deps: [
    [new Optional(), new SkipSelf(), ScheduleTimeSlotsBuildStrategy],
    SCHEDULE_DATE_FORMAT_STRATEGY,
    SCHEDULE_TIME_FORMAT_STRATEGY
  ],
  useFactory: MULTI_DATE_SCHEDULE_SLOTS_BUILD_STRATEGY_FACTORY
};
