<header class="header" *ngIf="!isAdmin">
  <h1 class="title" i18n="@@guideServicesBoardTitle">Services</h1>

  <div class="services-list-manager">
    <app-search-input
      [(ngModel)]="searchText"
      placeholder="Search"
      i18n-placeholder="@@searchInputPlaceholder"></app-search-input>

    <app-new-service-button *ngIf="canCreateService$ | async">
      <pui-icon class="pui-space_right-2" svgIcon="pui:plus-circle"></pui-icon>
      <span class="pui-space_right-2" i18n="@@guideServicesBoardServiceButton">Add service</span>
      <pui-icon svgIcon="pui:chevron-down"></pui-icon>
    </app-new-service-button>
  </div>
</header>

<section class="services" data-qa-id="app-guide-services-board__services-list">
  <ng-container
    *ngFor="let service of guideServices.services$ | async | filterByName: searchText; trackBy: trackByIndex"
    [ngSwitch]="service.type">
    <app-guide-service-item
      class="guide-service-item"
      *ngSwitchCase="GuideServiceTypes.GROUP_SESSION"
      [editorLink]="getEditorLink(service)"
      [publicLink]="getPublicLink(service)"
      [service]="service"
      [actionsMenu]="serviceActions"
      [serviceContainer]="groupSessionContainer"></app-guide-service-item>

    <app-guide-service-item
      class="guide-service-item"
      *ngSwitchCase="GuideServiceTypes.PACKAGE"
      [editorLink]="getEditorLink(service)"
      [publicLink]="getPublicLink(service)"
      [service]="service"
      [actionsMenu]="serviceActions"
      [serviceContainer]="packageContainer"></app-guide-service-item>

    <app-guide-service-item
      class="guide-service-item"
      *ngSwitchCase="GuideServiceTypes.PROGRAM"
      [editorLink]="getEditorLink(service)"
      [publicLink]="getPublicLink(service)"
      [service]="service"
      [actionsMenu]="serviceActions"
      [serviceContainer]="programContainer"></app-guide-service-item>

    <app-guide-service-item
      class="guide-service-item"
      *ngSwitchCase="GuideServiceTypes.SESSION"
      [editorLink]="getEditorLink(service)"
      [publicLink]="getPublicLink(service)"
      [service]="service"
      [actionsMenu]="serviceActions"
      [serviceContainer]="sessionContainer"></app-guide-service-item>

    <app-guide-service-item
      class="guide-service-item"
      *ngSwitchCase="GuideServiceTypes.WEBINAR"
      [service]="service"
      [serviceContainer]="webinarContainer"
      copyText="Copy link to session page"
      i18n-copyText="@@linkToSessionPage"></app-guide-service-item>
  </ng-container>
</section>

<ng-template #groupSessionContainer let-service="service" let-editorLink="editorLink" let-publicLink="publicLink">
  <app-guide-group-session-service
    class="guide-group-session-service guide-service-container"
    [service]="service"
    [editorLink]="editorLink"
    [publicLink]="publicLink"
    copyText="Copy link to session page"
    i18n-copyText="@@linkToSessionPage"></app-guide-group-session-service>
</ng-template>

<ng-template #packageContainer let-service="service" let-editorLink="editorLink" let-publicLink="publicLink">
  <app-guide-package-service
    class="guide-package-service guide-service-container"
    [service]="service"
    [editorLink]="editorLink"
    [publicLink]="publicLink"
    copyText="Copy link to package page"
    i18n-copyText="@@linkToPackagePage"></app-guide-package-service>
</ng-template>

<ng-template #programContainer let-service="service" let-editorLink="editorLink" let-publicLink="publicLink">
  <app-guide-program-service
    class="guide-program-service guide-service-container"
    [service]="service"
    [editorLink]="editorLink"
    [publicLink]="publicLink"
    copyText="Copy link to program page"
    i18n-copyText="@@linkToProgramPage"></app-guide-program-service>
</ng-template>

<ng-template #sessionContainer let-service="service" let-editorLink="editorLink" let-publicLink="publicLink">
  <app-guide-session-service
    class="guide-session-service guide-service-container"
    [service]="service"
    [editorLink]="editorLink"
    [publicLink]="publicLink"
    copyText="Copy link to session page"
    i18n-copyText="@@linkToSessionPage"></app-guide-session-service>
</ng-template>

<ng-template #webinarContainer let-service="service">
  <app-guide-webinar-service
    class="guide-webinar-service guide-service-container"
    [service]="service"></app-guide-webinar-service>
</ng-template>

<ng-template #serviceActions let-service="service">
  <div class="session-service-actions guide-service-actions">
    <button
      aria-label="Copy link to session page"
      class="guide-service-actions__button guide-service-actions__button_copy"
      ngxClipboard
      pui-icon-button
      size="l"
      shape="square"
      appearance="flat"
      [cbContent]="getPublicLink(service)">
      <pui-icon class="guide-service-actions__button-icon" svgIcon="pui:link"></pui-icon>
    </button>

    <ng-container
      *ngIf="
        service
          | guideServicesActionMenu
          | toDropdownMenuOptions: 'title':'className':'clickEvent':'svgIcon':'appearance' as menuItems
      ">
      <button
        pui-dropdown
        [pui-dropdown-content]="dropdownContent"
        pui-icon-button
        size="m"
        type="button"
        appearance="flat"
        shape="square">
        <pui-icon svgIcon="pui:more-vertical"></pui-icon>
      </button>

      <ng-template #dropdownContent let-close="close">
        <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
          <button
            *ngFor="let item of menuItems"
            pui-list-item="button"
            pui-a11y-keyboard-widget-item
            [pui-list-item-appearance]="item.appearance"
            (click)="close(); item.event(service)">
            <pui-list-item-icon>
              <pui-icon [svgIcon]="item.svgIcon" size="s"></pui-icon>
            </pui-list-item-icon>
            {{ item.title }}
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
