import { DisqusModule } from 'ngx-disqus';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { SharedModule } from '@app/shared/shared.module';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiIconModule,
  PuiInputFileModule,
  PuiInputModule,
  PuiPaperModule,
  PuiTabsModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BlogArticleAuthorComponent } from './components/blog-article-author/blog-article-author.component';
import { BlogArticleContentComponent } from './components/blog-article-content/blog-article-content.component';
import { BlogArticleCreatorComponent } from './components/blog-article-creator/blog-article-creator.component';
import { BlogArticleEditorComponent } from './components/blog-article-editor/blog-article-editor.component';
import { BlogArticleFormComponent } from './components/blog-article-form/blog-article-form.component';
import { BlogArticleLatestArticlesComponent } from './components/blog-article-latest-articles/blog-article-latest-articles.component';
import { BlogArticleRelatedArticlesComponent } from './components/blog-article-related-articles/blog-article-related-articles.component';
import { BlogArticleRelatedGuidesComponent } from './components/blog-article-related-guides/blog-article-related-guides.component';
import { BlogArticleRelatedTagsComponent } from './components/blog-article-related-tags/blog-article-related-tags.component';
import { BlogArticleComponent } from './components/blog-article/blog-article.component';
import { BlogAuthorDashboardComponent } from './components/blog-author-dashboard/blog-author-dashboard.component';
import { BlogItemArticleComponent } from './components/blog-item-article/blog-item-article.component';
import { BlogItemPlatformAdsComponent } from './components/blog-item-platform-ads/blog-item-platform-ads.component';
import { BlogItemPodcastComponent } from './components/blog-item-podcast/blog-item-podcast.component';
import { BlogItemPodcastsPackComponent } from './components/blog-item-podcasts-pack/blog-item-podcasts-pack.component';
import { BlogItemVideoComponent } from './components/blog-item-video/blog-item-video.component';
import { BlogItemWideArticleComponent } from './components/blog-item-wide-article/blog-item-wide-article.component';
import { BlogItemWikiComponent } from './components/blog-item-wiki/blog-item-wiki.component';
import { BlogTagsComponent } from './components/blog-tags/blog-tags.component';
import { BlogComponent } from './components/blog/blog.component';
import { BusinessBlogArticleComponent } from './components/business-blog-article/business-blog-article.component';
import { BusinessBlogComponent } from './components/business-blog/business-blog.component';
import { CapturePlatformOgMetaClickDirective } from './directives/capture-platform-og-meta-click.directive';
import { AcceptableArticleIdentifierGuard } from './guards/acceptable-article-identifier.guard';
import { AdminOrBlogOwnerGuard } from './guards/admin-or-blog-owner-guard.service';
import { BlogOwnerGuard } from './guards/blog-owner.guard';
import { RedirectBlogOwnerToBlogDashboardGuard } from './guards/redirect-blog-owner-to-blog-dashboard.guard';
import { ArticleResolver } from './resolver/article.resolver';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    SharedModule,
    UiSwitchModule,
    // @ts-expect-error TS2345
    DisqusModule.forRoot(environment.disqusId),
    NgScrollbarModule,
    PuiInputFileModule,
    PuiIconModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiTabsModule,
    PuiPaperModule,
    PuiAvatarModule,
    TextEditorModule,
    PuiInputModule
  ],
  declarations: [
    BlogComponent,
    BlogArticleAuthorComponent,
    BlogArticleComponent,
    BlogArticleContentComponent,
    BlogArticleCreatorComponent,
    BlogArticleEditorComponent,
    BlogArticleFormComponent,
    BlogArticleLatestArticlesComponent,
    BlogArticleRelatedArticlesComponent,
    BlogArticleRelatedGuidesComponent,
    BlogArticleRelatedTagsComponent,
    BlogAuthorDashboardComponent,
    BlogItemArticleComponent,
    BlogItemPlatformAdsComponent,
    BlogItemPodcastComponent,
    BlogItemPodcastsPackComponent,
    BlogItemVideoComponent,
    BlogItemWideArticleComponent,
    BlogItemWikiComponent,
    BlogTagsComponent,
    BusinessBlogComponent,
    BusinessBlogArticleComponent,
    CapturePlatformOgMetaClickDirective
  ],
  exports: [BlogAuthorDashboardComponent, BlogItemArticleComponent, BlogItemPodcastComponent, BlogItemVideoComponent],
  providers: [
    AcceptableArticleIdentifierGuard,
    AdminOrBlogOwnerGuard,
    BlogOwnerGuard,
    ArticleResolver,
    RedirectBlogOwnerToBlogDashboardGuard
  ]
})
export class BlogModule {}
