import { PuiDialogRef } from '@awarenow/profi-ui-core';
import { BehaviorSubject, EMPTY, iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticServiceTypes, InternalEvents } from '@app/core/analytics/types';
import { BookQuizService } from '@app/core/book-quiz/book-quiz.service';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { SocketService } from '@app/core/socket/socket.service';
import { IBillingInfo } from '@app/modules/billing/interfaces';
import { BillingService } from '@app/modules/billing/services';
import {
  IDefaultPrepaymentValidationOptions,
  PreBookValidationStrategyService,
  PrepaymentValidationStrategyService
} from '@app/modules/book-service';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { PayWithModalComponent } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { GuideServiceTypes, IBookSessionServiceEvent } from '@app/shared/interfaces/services';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  IGuideService,
  IServiceBillingInfo,
  IServiceBookingDetails,
  IServiceBookingOptions,
  IServiceBookingRequest,
  IServiceBookingResponse,
  IServiceBookingResult,
  IServiceDetails
} from '../types';
import { ServiceBookingApiService } from './service-booking-api.service';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';

const buildBookingBillingData = (billingData: IServiceBillingInfo): IBillingInfo => {
  return billingData;
};

const buildBookingRequest = (bookingDetails: IServiceBookingDetails<IGuideService>): IServiceBookingRequest => {
  const bookingRequest: IServiceBookingRequest = {
    serviceId: bookingDetails.service.id,
    type: bookingDetails.service.type,
    serviceParent: bookingDetails.service.serviceParent,
    // @ts-expect-error TS2345
    billingData: buildBookingBillingData(bookingDetails.billingData),
    serviceHost: bookingDetails.serviceHost,
    bookings: [] // ToDo[7154]
  };

  ['date', 'duration', 'timezone', 'serviceHost', 'paymentOption'].forEach(prop => {
    // @ts-expect-error TS7053
    if (bookingDetails[prop] != null) {
      // @ts-expect-error TS7053
      bookingRequest[prop] = bookingDetails[prop];
    }
  });

  return bookingRequest;
};

@Injectable({
  providedIn: 'root',
  deps: [
    PreBookValidationStrategyService,
    PrepaymentValidationStrategyService,
    ServiceBookingApiService,
    NgbModal,
    Router,
    BookQuizService,
    AnalyticsService,
    PlatformConfigurationService,
    BillingService,
    BookingService,
    [new Optional(), ClientProgramsService],
    [new Optional(), SocketService]
  ],
  useFactory: (
    preBookValidator: PreBookValidationStrategyService<void, IServiceBookingResult>,
    prePaymentValidator: PrepaymentValidationStrategyService<IDefaultPrepaymentValidationOptions>,
    bookApi: ServiceBookingApiService<IServiceBookingRequest, IServiceBookingResponse>,
    ngbModal: NgbModal,
    router: Router,
    bookQuizService: BookQuizService,
    analyticsService: AnalyticsService,
    platformConfigurationService: PlatformConfigurationService,
    billingService: BillingService,
    bookingService: BookingService,
    clientProgramsService: ClientProgramsService,
    socketService: SocketService
  ) =>
    new DefaultServiceBookingService(
      preBookValidator,
      prePaymentValidator,
      bookApi,
      ngbModal,
      router,
      bookQuizService,
      analyticsService,
      platformConfigurationService,
      billingService,
      bookingService,
      clientProgramsService,
      socketService
    )
})
export abstract class ServiceBookingService<TBookArgs, TBookResponse> {
  abstract book$(args: TBookArgs, waitBooking?: boolean): Observable<TBookResponse>;

  abstract extractBookingOptions(payload: IBookSessionServiceEvent): IServiceBookingOptions<IGuideService>;

  abstract selectPaymentType$<TModalOptions extends object = {}>(modalOptions: TModalOptions): Observable<void>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  abstract _modalRef: NgbModalRef;

  abstract cleanUp(): void;

  abstract bookingInProcess$: Observable<boolean>;
}

@Injectable()
export class DefaultServiceBookingService extends ServiceBookingService<
  IServiceBookingOptions<IGuideService>,
  IServiceBookingResult
> {
  readonly bookingInProcessSource$ = new BehaviorSubject(false);

  get bookingInProcess$(): Observable<boolean> {
    return this.bookingInProcessSource$.asObservable();
  }

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _modalRef: NgbModalRef;

  dialogRef: PuiDialogRef<unknown> | null = null;

  constructor(
    protected readonly _preBookValidator: PreBookValidationStrategyService<void, IServiceBookingResult>,
    protected readonly _prePaymentValidator: PrepaymentValidationStrategyService<IDefaultPrepaymentValidationOptions>,
    protected readonly _bookApi: ServiceBookingApiService<IServiceBookingRequest, IServiceBookingResponse>,
    protected readonly _modal: NgbModal,
    protected readonly _router: Router,
    protected readonly _bookQuizService: BookQuizService,
    protected readonly _analyticsService: AnalyticsService,
    private readonly _platformConfigurationService: PlatformConfigurationService,
    private readonly _billingService: BillingService,
    private bookingService: BookingService,
    private readonly _clientProgramsService: ClientProgramsService,
    private readonly socketService: SocketService
  ) {
    super();

    this._platformConfigurationService.billingDataRequired$
      .pipe(
        switchMap((billingDataRequired: boolean) =>
          billingDataRequired ? _billingService.requestBillingInfo() : EMPTY
        )
      )
      .subscribe();
  }

  book$(bookingOptions: IServiceBookingOptions<IGuideService>, waitBooking = true): Observable<IServiceBookingResult> {
    if (this.bookingInProcessSource$.value) {
      return EMPTY;
    }

    this.bookingInProcessSource$.next(true);

    return this._preBookValidator
      .validate$()
      .pipe(
        switchMap(() => {
          return this.prepareHostSelection$(bookingOptions);
        }),
        switchMap((serviceDetails: IServiceDetails<IGuideService>) => {
          return this.prepareServiceDetails$(serviceDetails, bookingOptions.guide);
        }),
        switchMap((serviceDetails: IServiceDetails<IGuideService>) => {
          return this.makePrepaymentValidation$(serviceDetails);
        }),
        switchMap((serviceDetails: IServiceDetails<IGuideService>) => {
          return this.prepareBillingData$(serviceDetails, bookingOptions.guide);
        }),
        switchMap((serviceDetails: IServiceDetails<IGuideService>) => {
          return this.selectPaymentOption$(serviceDetails, bookingOptions.guide);
        }),
        switchMap((serviceDetails: IServiceBookingDetails<IGuideService>) => {
          return this.addPaymentInfo$(serviceDetails, bookingOptions.guide);
        })
      )
      .pipe(
        switchMap((bookingDetails: IServiceBookingDetails<IGuideService>) => {
          bookingDetails.serviceHost = bookingDetails.serviceHost || bookingOptions.guide.id;
          const { isFree, ...bookingData } = bookingDetails;
          return this._bookApi
            .book$(buildBookingRequest(bookingData), isFree)
            .pipe(map(bookingResponse => [bookingResponse, bookingDetails, isFree]));
        }),
        switchMap(
          ([bookingResponse, bookingDetails, isFree]: [
            IServiceBookingResponse,
            IServiceBookingDetails<IGuideService>,
            boolean
          ]) => {
            const isPackage = bookingDetails.service?.type === GuideServiceTypes.PACKAGE;
            const isProgram = bookingDetails.service?.type === GuideServiceTypes.PROGRAM;
            if (isFree || isPackage || isProgram || !waitBooking) {
              return of([bookingResponse, bookingDetails]);
            } else {
              return this.socketService.onSessionChanged().pipe(
                map(service => service.templateId),
                filter(serviceId => serviceId === bookingDetails.service.id),
                map(() => [bookingResponse, bookingDetails])
              );
            }
          }
        ),
        switchMap(([bookingResult, bookingDetails]: [IServiceBookingResult, IServiceBookingDetails<IGuideService>]) => {
          const formsAuthor = bookingDetails?.serviceHost || bookingOptions.guide.id;
          return this.checkFormsAndOpenIfExist$(bookingResult, formsAuthor).pipe(
            map((bookingSuccess: IServiceBookingResult) => [bookingSuccess, bookingDetails])
          );
        }),
        switchMap(
          ([bookingSuccess, bookingDetails]: [IServiceBookingResult, IServiceBookingDetails<IGuideService>]) => {
            return iif(
              () =>
                bookingSuccess.serviceType === GuideServiceTypes.PROGRAM &&
                !!this._clientProgramsService &&
                !bookingDetails.isFree,
              this._clientProgramsService?.wasEnrolledProgram$.pipe(
                filter(({ programId }) => programId === bookingSuccess.id),
                mapTo(bookingSuccess)
              ),
              of(bookingSuccess)
            );
          }
        ),
        take(1),
        tap((bookingResult: IServiceBookingResponse) => this._postBookAnalytics(bookingResult, bookingOptions)),
        catchError(err => throwError(err)),
        finalize(() => this.cleanUp())
      );
  }

  protected prepareHostSelection$(
    bookingOptions: IServiceBookingOptions<IGuideService>
  ): Observable<IServiceDetails<IGuideService>> {
    const { service, requiresHostSelection, serviceHost } = bookingOptions;

    const skipHostSelection: boolean = !requiresHostSelection || (requiresHostSelection && !!serviceHost);

    if (skipHostSelection) {
      // @ts-expect-error TS2322
      return of({
        ...bookingOptions,
        service,
        requiresHostSelection
      });
    }

    return this.openBookingDetailsModal$({
      ...bookingOptions,
      serviceHost,
      service
    });
  }

  protected prepareServiceDetails$(
    details: IServiceDetails<IGuideService>,
    guide: { readonly id: number; readonly workspaceId: number; readonly name: string }
  ): Observable<IServiceDetails<IGuideService>> {
    const { service, date, serviceHost } = details;

    if (
      ((service?.type === GuideServiceTypes.SESSION || service?.type === GuideServiceTypes.GROUP_SESSION) && date) ||
      service?.type === GuideServiceTypes.PACKAGE ||
      service?.type === GuideServiceTypes.PROGRAM
    ) {
      return of({
        service,
        serviceHost,
        date
      });
    }

    return this.openBookingDetailsModal$({
      ...details,
      guide
    });
  }

  protected prepareBillingData$(
    serviceDetails: IServiceDetails<IGuideService>,
    guide: { readonly id: number; readonly workspaceId: number; readonly name: string }
  ): Observable<IServiceDetails<IGuideService>> {
    if (!serviceDetails.service.price && !serviceDetails.service.subscriptionPrice) {
      return of(serviceDetails);
    }

    if (serviceDetails.billingData) {
      return of(serviceDetails);
    }

    const { billingDataRequired } = this._platformConfigurationService;
    const billingInfo = this._billingService.getBillingValue();

    if (!billingInfo && billingDataRequired) {
      return this.openBookingDetailsModal$({
        ...serviceDetails,
        // @ts-expect-error TS2698
        billingData: { ...billingInfo, required: !billingInfo },
        guide
      });
    }

    return of(serviceDetails);
  }

  protected openBookingDetailsModal$(
    bookingOptions: IServiceBookingOptions<IGuideService>
  ): Observable<IServiceDetails<IGuideService>> {
    const serviceId = bookingOptions.service?.id;
    const workspaceId = bookingOptions.guide.workspaceId;

    if (!this.dialogRef && serviceId && bookingOptions.guide.workspaceType) {
      if (bookingOptions.service?.serviceParent?.type === GuideServiceTypes.PACKAGE) {
        if (bookingOptions.guide.workspaceType === WorkspacesTypes.SOLO) {
          // open solo package session
          this.bookingService.openSoloGuideAvailablePackageSession(
            bookingOptions.guide.id,
            bookingOptions.service.serviceParent.id,
            serviceId,
            bookingOptions.guide.workspaceType,
            bookingOptions.service.serviceParent
          );
        } else {
          // open team package session
          this.bookingService.openTeamAvailablePackageSession(
            workspaceId,
            bookingOptions.service.serviceParent.id,
            serviceId,
            bookingOptions.guide.workspaceType,
            bookingOptions.service.serviceParent
          );
        }
      }

      if (bookingOptions.service?.serviceParent?.type === GuideServiceTypes.PROGRAM) {
        if (bookingOptions.guide.workspaceType === WorkspacesTypes.SOLO) {
          // open solo program session
          this.bookingService.openSoloGuideAvailableProgramSession(
            bookingOptions.guide.id,
            bookingOptions.service.serviceParent.id,
            serviceId,
            bookingOptions.guide.workspaceType
          );
        } else {
          // open team program session
          this.bookingService.openTeamAvailableProgramSession(
            workspaceId,
            bookingOptions.service.serviceParent.id,
            serviceId,
            bookingOptions.guide.workspaceType
          );
        }
      }
    }

    const onBookingDetailsChange$ = new Subject<IServiceBookingDetails<IGuideService>>();

    this.dialogRef?.afterClosed$
      .pipe(
        finalize(() => {
          onBookingDetailsChange$.complete();
          this.dialogRef = null;
        })
      )
      .subscribe();

    return onBookingDetailsChange$;
  }

  selectPaymentType$(modalProps = {}): Observable<void> {
    if (this._modalRef) {
      this._modalRef.close();
      // @ts-expect-error TS2322
      this._modalRef = null;
    }

    const onPaymentSelected$ = new Subject<void>();

    // HACK: we need time to close opened modal, otherwise, the modal window will not be opened,
    // since the service will first replace the window, and then close it
    setTimeout(() => {
      this._modalRef = this._modal.open(PayWithModalComponent);

      const { componentInstance, result } = this._modalRef;

      for (const [key, value] of Object.entries(modalProps)) {
        componentInstance[key] = value;
      }

      componentInstance.paymentTypeSelected = () => {
        onPaymentSelected$.next();
        onPaymentSelected$.complete();
      };

      modalResultToObservable$(result)
        .pipe(finalize(() => onPaymentSelected$.complete()))
        .subscribe();
    }, 0);

    return onPaymentSelected$;
  }

  protected makePrepaymentValidation$(
    serviceDetails: IServiceDetails<IGuideService>
  ): Observable<IServiceDetails<IGuideService>> {
    return this._prePaymentValidator
      .validate$({ canTryFix: true, onBeforeFixHook: () => this.onPaymentFixStarted() })
      .pipe(mapTo(serviceDetails));
  }

  protected onPaymentFixStarted(): void {
    if (this._modalRef) {
      this._modalRef.close();
      // @ts-expect-error TS2322
      this._modalRef = null;
    }
  }

  protected selectPaymentOption$(
    serviceDetails: IServiceDetails<IGuideService>,
    guide: { readonly id: number; readonly workspaceId: number; readonly name: string }
  ): Observable<IServiceBookingDetails<IGuideService>> {
    if (
      serviceDetails.service.price &&
      serviceDetails.service.subscriptionPrice &&
      serviceDetails.service.subscriptionRecurrency &&
      serviceDetails.service.totalPayments
    ) {
      return this.openBookingDetailsModal$({ ...serviceDetails, guide });
    }

    if (serviceDetails.service.price) {
      serviceDetails.paymentOption = PaymentOptions.FULL_PRICE;
    }

    if (serviceDetails.service.subscriptionPrice) {
      serviceDetails.paymentOption = PaymentOptions.INSTALLMENTS;
    }

    return of(serviceDetails);
  }

  protected addPaymentInfo$(
    serviceDetails: IServiceDetails<IGuideService>,
    // @ts-expect-error TS7008
    guide: { readonly id: number; readonly workspaceId; readonly name: string }
  ): Observable<IServiceBookingDetails<IGuideService>> {
    if (!serviceDetails.service.price && !serviceDetails.service.subscriptionPrice) {
      return of({ ...serviceDetails, isFree: true });
    }

    return this.openBookingDetailsModal$({ ...serviceDetails, guide });
  }

  protected checkFormsAndOpenIfExist$(
    bookingResult: IServiceBookingResult,
    guideId: number
  ): Observable<IServiceBookingResult> {
    const { serviceType } = bookingResult;

    if (serviceType !== GuideServiceTypes.SESSION && serviceType !== GuideServiceTypes.GROUP_SESSION) {
      return of(bookingResult);
    }

    return this._bookQuizService.checkIfFormsExist$(guideId, bookingResult.templateId || bookingResult.serviceId).pipe(
      switchMap(({ quizzes }) => {
        if (!quizzes.length) {
          return of(null);
        }

        if (this._modalRef) {
          this._modalRef.close();
          // @ts-expect-error TS2322
          this._modalRef = null;
        }

        return this._bookQuizService.openFormQuizzesModal(quizzes, guideId);
      }),
      mapTo(bookingResult)
    );
  }

  cleanUp(): void {
    this.bookingInProcessSource$.next(false);

    // TODO: Refactor this!
    this._billingService.requestBillingInfo().subscribe();

    if (this._modalRef) {
      this._modalRef.close();
      // @ts-expect-error TS2322
      this._modalRef = null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _postBookAnalytics(
    bookResult: IServiceBookingResponse,
    bookingDetails: IServiceBookingOptions<IGuideService>
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const analyticsData: any = {
      servicePrice: bookResult.payRate,
      ...(bookingDetails.analyticSourceType && { source: bookingDetails.analyticSourceType })
    };

    switch (bookResult.serviceType) {
      case GuideServiceTypes.SESSION:
        analyticsData.serviceType = AnalyticServiceTypes.INDIVIDUAL_SESSION;
        break;
      case GuideServiceTypes.GROUP_SESSION:
        analyticsData.serviceType = AnalyticServiceTypes.GROUP_SESSION;
        break;
      case GuideServiceTypes.PACKAGE:
        analyticsData.serviceType = AnalyticServiceTypes.PACKAGE;
        break;
      default:
        analyticsData.serviceType = 'unknown';
    }

    this._analyticsService.event(InternalEvents.SERVICE_BOOK, analyticsData);
  }

  extractBookingOptions(payload: IBookSessionServiceEvent): IServiceBookingOptions<IGuideService> {
    const { guide, time, analyticSourceType, ...service } = payload;
    return {
      analyticSourceType,
      service,
      guide: { id: guide.id, workspaceId: guide.workspaceId, name: `${guide.firstName} ${guide.lastName}` },
      // @ts-expect-error TS2322
      date: time ? time.dateStart : null,
      // @ts-expect-error TS2322
      timezone: time ? time.timezone : null,
      // @ts-expect-error TS2322
      serviceHost: time ? time.serviceHost : null,
      requiresHostSelection: payload.requiresHostSelection
    };
  }
}
