import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoles } from '@app/shared/enums/user-roles';
import { Subscription, combineLatest, of } from 'rxjs';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SystemNotificationsService } from '@app/core/notifications/system-notifications.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { finalize, switchMap } from 'rxjs/operators';
import { IRedirectEvent, ISystemNotification } from '../../../types';

@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-header-notifications'
  }
})
export class HeaderNotificationsComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _subscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _unreadNotifications: ISystemNotification[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _readNotifications: ISystemNotification[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _unreadNotificationsCount = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isWorkspaceSwitching = false;

  expand = false;

  get readNotifications(): ISystemNotification[] {
    return this._readNotifications;
  }

  get unreadNotifications(): ISystemNotification[] {
    return this._unreadNotifications;
  }

  get isGuideRole(): boolean {
    const roleId = this._auth.getUserRoleId();
    return UserRoles.GUIDE === +roleId;
  }

  constructor(
    private _auth: AuthService,
    private _notifier: SystemNotificationsService,
    private _router: Router,
    private _changeDetection: ChangeDetectorRef,
    private _workspacesService: WorkspacesService,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window
  ) {}

  ngOnInit(): void {
    this._subscription = combineLatest([
      this._notifier.sessionNotifications$,
      this._notifier.packageNotifications$,
      this._notifier.formNotifications$,
      this._notifier.programNotifications$,
      this._notifier.commonNotifications$,
      this._notifier.workspaceNotifications$,
      this._notifier.noteNotifications$,
      this._notifier.communityNotifications$
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    ]).subscribe(
      ([
        sessionNotifications,
        packageNotifications,
        formNotifications,
        programNotifications,
        commonNotifications,
        workspaceNotifications,
        noteNotifications,
        communityNotifications
      ]) => {
        const allNotificationsSortedByDate = [
          ...sessionNotifications,
          ...packageNotifications,
          ...formNotifications,
          ...programNotifications,
          ...commonNotifications,
          ...workspaceNotifications,
          ...noteNotifications,
          ...communityNotifications
          // eslint-disable-next-line id-length
        ].sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());

        const [unreadNotifications, readNotifications] = this.splitNotifications(allNotificationsSortedByDate);

        this._unreadNotifications = unreadNotifications;
        this._readNotifications = readNotifications;

        if (!this.expand) {
          this._unreadNotificationsCount = unreadNotifications.length;
        }
        this._changeDetection.detectChanges();
      }
    );

    this._browserWindow.addEventListener('beforeunload', () => {
      this._notifier.markHeaderNotificationsRead();
    });
  }

  ngOnDestroy(): void {
    this._notifier.markHeaderNotificationsRead();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private splitNotifications(notifications: ISystemNotification[]): [ISystemNotification[], ISystemNotification[]] {
    // @ts-expect-error TS7034
    const unreadNotifications = [];
    // @ts-expect-error TS7034
    const readNotifications = [];

    if (notifications && notifications.length) {
      for (let i = 0, len = notifications.length; i < len; i++) {
        if (notifications[i]) {
          // @ts-expect-error TS7005
          const arr = notifications[i].isRead ? readNotifications : unreadNotifications;
          arr.push({ ...notifications[i], details: notifications[i].details ? { ...notifications[i].details } : null });
        }
      }
    }

    // @ts-expect-error TS7005
    return [unreadNotifications, readNotifications];
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  switchWorkspace({ workspaceId, invitationCode }) {
    if (this._isWorkspaceSwitching || !workspaceId) {
      return;
    }

    this._isWorkspaceSwitching = true;
    this._workspacesService
      .switchWorkspace$(workspaceId, invitationCode)
      .pipe(
        switchMap(({ done }) => (done ? this._workspacesService.setNextWorkspace$(workspaceId) : of(null))),
        finalize(() => {
          this._isWorkspaceSwitching = false;
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(res => res && this._router.navigate(['/dashboard'], { replaceUrl: true }));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onRedirectDispatcher(event: IRedirectEvent) {
    // @ts-expect-error TS7053
    this[event.handlerName](event.data);
  }
}
