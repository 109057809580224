<div class="modal-body">
  <app-session-action-result [actionResult]="actionResult"></app-session-action-result>

  <app-button
    data-qa-id="session-action-result-modal-close-button"
    class="close-btn"
    className="close"
    (click)="modal.close()"></app-button>
</div>

<div class="modal-footer" *ngIf="showFooter">
  <ng-container *ngIf="!!authorise">
    <app-button className="btn-link" (click)="emitAuthorise()" i18n="@@loginForDetails">Log in for details</app-button>
  </ng-container>

  <ng-container *ngIf="canConfirm">
    <app-button
      data-qa-id="session-action-result-modal-ok-button"
      className="btn-primary"
      (click)="modal.close()"
      i18n="@@ok">
      Ok
    </app-button>
  </ng-container>
</div>
