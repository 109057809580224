import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { BlogItemWiki } from '../../types';

@Component({
  selector: 'app-blog-item-wiki',
  templateUrl: './blog-item-wiki.component.html',
  styleUrls: ['../styles/blog-item.scss', './blog-item-wiki.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemWikiComponent {
  platformName: string;

  @Input()
  // @ts-expect-error TS2564
  term: BlogItemWiki;

  constructor(private readonly _runtimeConfigService: RuntimeConfigService) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }
}
