<div class="input-group">
  <input
    [attr.data-qa-id]="'copy-value-input-' + value"
    type="text"
    readonly
    class="form-control"
    [value]="value"
    (copy)="copied.emit(analyticCopyTypes.STANDARD)" />

  <button
    type="text"
    class="input-group-append"
    ngxClipboard
    [attr.data-qa-id]="'copy-value-input-button-' + value"
    [cbContent]="value"
    (cbOnSuccess)="copied.emit(analyticCopyTypes.BUTTON)"
    (cbOnError)="copyFailed.emit()">
    {{ buttonText }}
  </button>
</div>
