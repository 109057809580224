<div
  class="wrapper"
  [ngClass]="{
    'individual-session-request': isIndividualSessionRequest,
    'group-session-request': isGroupSessionRequest
  }"
  data-qa-id="chat-page-header">
  <a class="d-block to-contacts-btn d-lg-none" [routerLink]="'../select'"></a>
  <div class="main-info col-5 col-lg-4" *ngIf="participant">
    <ui-card
      class="ui-card-flat"
      [ngClass]="{
        'individual-session-request': isIndividualSessionRequest,
        'group-session-request': isGroupSessionRequest
      }">
      <ui-card-header>
        <pui-avatar
          ui-card-avatar
          uiIconBadge="online-status"
          uiBadgeIconColor="primary"
          [avatarUrl]="participant.photo"
          [text]="participant.name"
          [uiIconBadgeHidden]="!participant.isOnline"
          style="background-size: contain; background-position: center; background-repeat: no-repeat"></pui-avatar>
        <ui-card-title>
          <span
            [ngClass]="{
              'session-request-card-title': isIndividualSessionRequest || isGroupSessionRequest,
              clickable: !bot
            }"
            (click)="goToParticipant()"
            i18n="@@inChatSessionManagerChatName"
            data-qa-id="in-chat-session-manager-username-title">
            {{ participant.firstName }} {{ participant.lastName }}{{ participant.archived ? ' (archived)' : '' }}
          </span>
        </ui-card-title>
        <ui-card-subtitle
          *ngIf="participant.contacts && isEmail(participant.contacts.email)"
          [ngClass]="{ 'session-request-card-subtitle': isIndividualSessionRequest || isGroupSessionRequest }"
          data-qa-id="in-chat-session-manager-email-subtitle">
          {{ participant.contacts.email }}
        </ui-card-subtitle>
      </ui-card-header>
    </ui-card>
  </div>

  <div class="extended-info col-7 col-lg-8" [class.active]="extendedInfo" *ngIf="!bot">
    <app-in-chat-guide-session-details
      [session]="session"
      (accept)="acceptRequest()"
      (approveReschedulement)="approveReschedulement()"
      (decline)="declineRequest()"
      (cancel)="guideCancelSession($event)"
      (offer)="createOffer()"
      (reschedule)="reschedule()"
      *ngIf="isCurrentUserGuide"></app-in-chat-guide-session-details>

    <app-in-chat-client-session-details
      [session]="session"
      [doesBookAllowed]="true"
      (accept)="acceptOffer()"
      (approveReschedulement)="approveReschedulement()"
      (book)="bookSession()"
      (cancel)="declineOffer()"
      (decline)="clientCancelSession()"
      (reschedule)="reschedule()"
      *ngIf="!isCurrentUserGuide"></app-in-chat-client-session-details>
  </div>

  <div class="extended-info col-8" [class.active]="extendedInfo" *ngIf="bot">
    <div class="quiz-button-wrapper">
      <app-button
        className="btn-primary"
        [routerLink]="'/client/programs/quiz/' + -participant.id"
        i18n="@@inChatSessionManagerCompleteForm">
        Complete form
      </app-button>
    </div>
  </div>
</div>
