import { Pipe, PipeTransform } from '@angular/core';
import { SessionActionType } from '@libs/business/cdk/session';
import { ButtonAppearanceType } from '@awarenow/profi-ui-core';
import { ClientSession } from '@libs/business/client/client-session';

@Pipe({
  name: 'normalizeSessionAction'
})
export class NormalizeSessionActionPipe implements PipeTransform {
  transform(
    actionType: SessionActionType,
    session: ClientSession<unknown>
  ): { iconName?: string; labelText: string; ghost?: boolean; appearance?: ButtonAppearanceType } | null {
    if (!actionType || !session) {
      return null;
    }

    switch (actionType) {
      case 'ask-to-reschedule':
        return {
          iconName: 'pui:rotate-right',
          labelText: `Ask to reschedule`
        };
      case 'cancel':
        return {
          iconName: 'pui:x-circle',
          labelText: `Cancel`
        };
      case 'book-again':
        return {
          iconName: 'pui:grid',
          labelText: `Book again`
        };
      case 'hide':
        return {
          iconName: 'pui:trash',
          labelText: `Hide`
        };
      case 'accept':
      case 'approve-reschedule':
        return {
          appearance: 'secondary',
          labelText: `Accept`
        };
      case 'reschedule':
        return {
          iconName: 'pui:rotate-right',
          appearance: 'flat',
          labelText: `Reschedule`,
          ghost: true
        };
      case 'decline':
        return {
          iconName: 'pui:x-circle',
          appearance: 'flat',
          labelText: `Decline`,
          ghost: true
        };
      default:
        return null;
    }
  }
}
