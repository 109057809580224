<div class="editor">
  <!--  <app-user-photo [photoUrl]="user?.photo" *ngIf="user"></app-user-photo>-->
  <pui-avatar *ngIf="user" [avatarUrl]="user?.photo" [text]="user?.name" class="app-user-photo"></pui-avatar>
  <div class="editor-wrapper">
    <div class="actions-left">
      <ng-container *ngTemplateOutlet="upload"></ng-container>
    </div>
    <quill-editor
      [(ngModel)]="message"
      [format]="'text'"
      [modules]="quillModules"
      placeholder="Write something..."
      i18n-placeholder="@@chatMessageBoxWriteSomething"
      #qlEditor
      (onEditorCreated)="setQuill($event)"
      data-qa-id="chat-message-box-textarea"></quill-editor>
    <div class="actions-right">
      <div
        class="emoji-picker-container"
        [ngClass]="{ visible: isEmojiPickerVisible }"
        (clickOutside)="onClickedOutsideEmoji()"
        [exclude]="'.emoji-picker-btn'">
        <emoji-mart
          [emoji]=""
          [isNative]="true"
          [showPreview]="false"
          (emojiSelect)="onEmojiSelected($event)"></emoji-mart>
      </div>

      <button
        class="emoji-picker-btn"
        pui-icon-button
        size="s"
        shape="square"
        appearance="flat"
        (click)="toggleEmojiPicker()"
        data-qa-id="chat-message-box-emoji-button">
        <pui-icon svgIcon="pui:smile"></pui-icon>
      </button>

      <button
        class="send-btn"
        pui-icon-button
        aria-label="Send message"
        pui-tooltip="Send now"
        i18n-pui-tooltip="@@chatMessageBoxSendNow"
        size="s"
        shape="square"
        appearance="flat"
        [disabled]="sendMessageDisabled"
        (click)="emitSendMessage()"
        data-qa-id="chat-message-box-send-message-button">
        <pui-icon svgIcon="pui:send"></pui-icon>
      </button>
    </div>
    <ng-template [ngIf]="attachment">
      <div class="files">
        <ng-template [ngIf]="attachment.loaded && attachment.file && attachment.file.url">
          <div class="file">
            <ng-template [ngIf]="fileType === FileTypes.Video">
              <iframe [src]="sanitize(attachment.file.url)" class="embed-video" allowfullscreen="false"></iframe>
            </ng-template>

            <ng-template [ngIf]="fileType === FileTypes.Image">
              <img [src]="attachment.file.url" [alt]="attachment.file.name" class="img-preview" />
            </ng-template>

            <ng-template [ngIf]="fileType === FileTypes.Other">
              <div class="file-container d-flex mt-2 p-2">
                <div class="row no-gutters w-100">
                  <div class="col">
                    <div class="file-icon icon"></div>
                  </div>
                  <div class="col-9 pr-1 pl-1 flex-column mb-sm-2 mb-md-0">
                    <div class="row no-gutters pl-1 pr-1">
                      <div class="file-name">{{ attachment.file.name }}</div>
                    </div>
                    <div class="row no-gutters pl-1 pr-1">
                      <div class="file-size" *ngIf="attachment.file.size">
                        {{ attachment.file.size | fileSize }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <button
              class="delete-btn hidden-delete"
              aria-label="Remove file"
              pui-tooltip="Remove file"
              i18n-pui-tooltip="@@chatMessageBoxRemoveFile"
              pui-icon-button
              size="s"
              [ghost]="true"
              (click)="deleteFile()">
              <pui-icon size="s" svgIcon="pui:status-cancelled"></pui-icon>
            </button>
          </div>
        </ng-template>
        <ng-template [ngIf]="!attachment.loaded">
          <div class="file-upload-progress">
            <div class="progress">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ width: attachment.loadedPercent + '%' }"></div>
            </div>
            <button
              *ngIf="attachment.loadedPercent !== 100"
              class="delete-btn"
              aria-label="Remove file"
              pui-tooltip="Remove file"
              i18n-pui-tooltip="@@chatMessageBoxRemoveFile"
              pui-icon-button
              size="s"
              [ghost]="true"
              (click)="deleteFile()">
              <pui-icon size="s" svgIcon="pui:status-cancelled"></pui-icon>
            </button>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #upload>
  <div *ngIf="!bot" class="upload-wrap">
    <div class="file-upload-container">
      <button
        class="attach-btn open-attach-modal"
        aria-label="Attach file"
        pui-icon-button
        pui-tooltip="Attach file"
        i18n-pui-tooltip="@@chatMessageBoxAttachFile"
        size="s"
        shape="square"
        appearance="flat"
        (click)="toggleAttachmentExpand()"
        data-qa-id="chat-message-box-attach-file-button">
        <pui-icon svgIcon="pui:paperclip"></pui-icon>
      </button>
    </div>
    <div
      *ngIf="isAttachmentExpand"
      (clickOutside)="hideAttachmentPopup()"
      [exclude]="'.open-attach-modal'"
      class="attachment-popup">
      <p>Attach file from</p>
      <ul>
        <li>
          <input type="file" (change)="emitUploadFile($event)" />
          <span class="item-icon"></span>
          <span i18n="@@ChatMessageBoxFileMyDevice">My device</span>
        </li>
        <li (click)="handleVideoEmbedding()">
          <span class="item-icon"></span>
          <span i18n="@@ChatMessageBoxFileVideo">Video</span>
        </li>
        <li (click)="handleFileStackEmbedding()">
          <span class="item-icon"></span>
          <span i18n="@@ChatMessageBoxFileOther">Other</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="image-upload-container" *ngIf="bot">
    <button
      class="attach-btn"
      aria-label="Attach file"
      pui-icon-button
      pui-tooltip="Attach file"
      i18n-pui-tooltip="@@chatMessageBoxAttachFile"
      size="s"
      shape="square"
      appearance="flat"
      (click)="openUploadImageModal()">
      <pui-icon svgIcon="pui:paperclip"></pui-icon>
    </button>
  </div>
</ng-template>
