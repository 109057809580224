import { NgModule } from '@angular/core';
import { SessionWidgetService } from './session-widget.service';
import { SessionWidgetComponent } from '@app/modules/session-widget/session-widget.component';
import { ChatModule } from '@app/screens/chat/chat.module';
import { GuideNotesModule } from '@app/modules/guide-notes/guide-notes.module';
import { CommonModule } from '@angular/common';
import { PuiLetModule, PuiTabsModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { GuideServiceBadgeModule } from '@app/modules/propose-service/components/guide-service-badge/guide-service-badge.module';
import { GuideClientModule } from '@app/modules/guide-client/guide-client.module';

@NgModule({
  declarations: [SessionWidgetComponent],
  imports: [
    CommonModule,
    ChatModule,
    GuideNotesModule,
    PuiTabsModule,
    PuiTypographyModule,
    GuideServiceBadgeModule,
    PuiLetModule,
    GuideClientModule
  ],
  providers: [SessionWidgetService]
})
export class SessionWidgetModule {}
