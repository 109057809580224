import { AuthorProgram } from './author-program';
import { InstructorProgram } from './instructor-program';
import { ProgramAccessRoles } from './program-access-roles';

export type Program = AuthorProgram | InstructorProgram;

export function isAuthorProgram(program: Program): program is AuthorProgram {
  return program.programAccessRole === ProgramAccessRoles.AUTHOR;
}

export function isInstructorProgram(program: Program): program is InstructorProgram {
  return program.programAccessRole === ProgramAccessRoles.INSTRUCTOR;
}
