import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { BookQuizService } from '@app/core/book-quiz/book-quiz.service';
import { IClientQuiz } from '@app/core/quizzes/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IQuizAnswers } from '@appWidget/screens/book-session/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-form-quiz',
  templateUrl: './form-quiz.component.html',
  styleUrls: ['./form-quiz.component.scss']
})
export class FormQuizComponent {
  // @ts-expect-error TS2564
  clientQuiz: IClientQuiz;

  editMode = true;

  form: FormGroup = this._formBuilder.group({ answers: [[], []] });

  submitting = false;

  @Input()
  set quiz(quiz: IClientQuiz) {
    this.clientQuiz = quiz;
    this.loadClientQuiz(quiz);
  }

  @Output()
  nextQuiz = new EventEmitter<void>();

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _http: HttpClient,
    private readonly _bookQuizService: BookQuizService
  ) {}

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private static transformAnswers(answers) {
    // @ts-expect-error TS7031
    return answers.map(({ id: questionId, answer: { text, options } }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const quizAnswer = { questionId, text } as any;

      if (options && options.length) {
        // @ts-expect-error TS7031
        quizAnswer.options = options.map(({ id }) => id);
      }

      return quizAnswer;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private loadClientQuiz(clientQuiz: IClientQuiz) {
    const questionsWithAnswers = clientQuiz.questions.map(question => ({
      ...question,
      answer: { text: null }
    }));
    this.form.setValue({ answers: questionsWithAnswers });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  saveAndGoToNextQuiz(skip = false) {
    const userAnswers = skip ? [] : FormQuizComponent.transformAnswers(this.form.value.answers);

    const quizInfoWithAnswers: IQuizAnswers = {
      quizId: this.clientQuiz.id,
      answers: userAnswers,
      skipped: skip
    };

    this._bookQuizService.collectQuizAnswers(quizInfoWithAnswers);
    this.nextQuiz.emit();
  }
}
