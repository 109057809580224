import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserRoles } from '@app/shared/enums/user-roles';

@Component({
  selector: 'app-no-chat-messages',
  templateUrl: './no-chat-messages.component.html',
  styleUrls: ['./no-chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoChatMessagesComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _opponent: string;

  @Input()
  set userRole(role: UserRoles) {
    let opponent = '';

    if (role === UserRoles.CLIENT) {
      opponent = 'coach';
    } else if (role === UserRoles.GUIDE) {
      opponent = 'client';
    }

    this._opponent = opponent;
  }

  get opponent(): string {
    return this._opponent;
  }
}
