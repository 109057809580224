import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { delay, tap } from 'rxjs/operators';
import { PrimaryLayoutService } from '@app/layouts/primary-layout/primary-layout.service';

export type PrimaryLayoutSidenavOptions = Pick<MatSidenav, 'mode' | 'opened'>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPrimaryLayoutView {
  isMobile: boolean;
  sidenav: PrimaryLayoutSidenavOptions;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-primary-layout',
  templateUrl: './primary-layout.component.html',
  styleUrls: ['primary-layout.component.scss']
})
export class PrimaryLayoutComponent {
  view$: Observable<IPrimaryLayoutView>;

  @ViewChild(MatSidenavContainer) container: MatSidenavContainer | undefined;

  constructor(private _service: PrimaryLayoutService) {
    this.view$ = _service.getView$().pipe(
      delay(0), // to update content margins after the menu is collapsed
      tap(() => this.container?.updateContentMargins())
    );
  }

  toggle(): void {
    this._service.toggle();
  }
}
