import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin, Observable, of } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { map, switchMap } from 'rxjs/operators';
import { IProfileInfo, User } from '@app/shared/interfaces/user';
import { AuthService } from '@app/core/auth/services';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IBookingParams } from '@appWidget/screens/book-session/types';
import { AbsWorkspaceService } from '@app/modules/workspaces/services/abs-workspace.service';
import { IWorkspace, WorkspaceRoute } from '@app/modules/workspaces/types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import config from '../config/config';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ServiceGuide } from '@app/shared/interfaces/services';

enum PreferableSource {
  Guide,
  Workspace,
  WorkspaceWithGuide
}

@Injectable()
export class ProfileService extends AbsWorkspaceService {
  private readonly PROFILE_API_ENDPOINT = `${config.apiPath}/public/profile/`;

  constructor(_http: HttpClient, private _authService: AuthService, private _workspacesService: WorkspacesService) {
    super(_http, WorkspaceRoute.PUBLIC);
  }

  getByIdOrNamedUrl(params: IBookingParams): Observable<IProfileInfo> {
    const hashedId: string = this._authService.hashedId ? `?hashedid=${this._authService.hashedId}` : '';
    return this.resolveInformationSource(params, hashedId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getReviews(namedUrl: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(`${this.PROFILE_API_ENDPOINT}${namedUrl}/reviews`).pipe(
      map(serverResponse => {
        if (serverResponse && serverResponse.reviews && serverResponse.reviews.length) {
          // @ts-expect-error TS7006
          serverResponse.reviews.forEach(review => {
            if (review.client) {
              const { id, firstName, lastName, photo } = review.client;
              review.client = new User(id, firstName, lastName, photo);
            }
          });
        }

        return serverResponse;
      })
    );
  }

  private resolveInformationSource(params: IBookingParams, hashedId: string): Observable<IProfileInfo> {
    let source: PreferableSource = PreferableSource.Guide;

    if (params.guideId && params.workspaceId) {
      source = PreferableSource.WorkspaceWithGuide;
    } else if (params.guideId && !params.workspaceId) {
      source = PreferableSource.Guide;
    } else if (params.workspaceId && !params.guideId) {
      source = PreferableSource.Workspace;
    }

    switch (source) {
      case PreferableSource.WorkspaceWithGuide:
        // @ts-expect-error TS2345
        return this.getWorkspaceWithGuideProfile(params.workspaceId, params.guideId, hashedId);
      case PreferableSource.Guide:
        return this.getProfile(params.guideId as string, hashedId);
      case PreferableSource.Workspace:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.workspaceById$({ id: params.workspaceId as any }).pipe(
          switchMap((workspace): Observable<[IWorkspace, ServiceGuide[]]> => {
            return this._workspacesService
              .getGuidesByWorkspaceId$(workspace.id)
              .pipe(map(guides => [workspace, guides]));
          }),
          map(
            ([workspace, guides]): IProfileInfo => ({
              workspace,
              profile: null,
              workspaceGuides: guides
            })
          )
        );
      default:
        // @ts-expect-error TS2322
        return of({ profile: null, workspace: null, workspaceGuides: null });
    }
  }

  private getWorkspaceWithGuideProfile(
    workspaceLink: string,
    guideLink: string,
    hashedId: string
  ): Observable<IProfileInfo> {
    return forkJoin({
      workspace: this.workspaceById$({ id: workspaceLink as unknown as number }),
      profileResponse: this.getProfile(guideLink, hashedId)
    }).pipe(
      map(({ workspace, profileResponse }) => {
        const profile = Object.assign(profileResponse.profile, { workspaceId: workspace.id });
        return {
          workspace,
          profile
        };
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getProfile(guideLink: string, hashedId: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(`${this.PROFILE_API_ENDPOINT}${guideLink}${hashedId}`).pipe(
      map(serverResponse => {
        if (serverResponse && serverResponse.result) {
          Object.defineProperty(serverResponse.profile, 'name', {
            get() {
              return `${this.firstName} ${this.lastName}`;
            },
            configurable: true
          });

          if (serverResponse.profile.reviews && serverResponse.profile.reviews.length) {
            // @ts-expect-error TS7006
            serverResponse.profile.reviews.forEach(review => {
              if (review.client) {
                const { id, firstName, lastName, photo } = review.client;
                review.client = new User(id, firstName, lastName, photo);
              }
            });
          }
        }

        return serverResponse;
      })
    );
  }
}
