import { Component, Output, Input, EventEmitter } from '@angular/core';

import { CurrentPaymentComponent } from '@app/modules/current-payment/components/current-payment/current-payment.component';

import { CurrentPaymentService } from '../../services/current-payment.service';
import { PaymentType } from '../../types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-pay-with-requisites',
  templateUrl: './pay-with-requisites.component.html',
  styleUrls: ['./pay-with-requisites.component.scss']
})
export class PayWithRequisitesComponent extends CurrentPaymentComponent {
  // @ts-expect-error TS2564
  @Input() paymentRequisites: string;

  // @ts-expect-error TS2564
  @Input() purchaseButtonText: string;

  @Output()
  submitEvent = new EventEmitter<{ id: string }>();

  submittingPurchaseBtn = false;

  constructor(private _currentPayment: CurrentPaymentService) {
    super(PaymentType.PAYMENT_REQUISITES, _currentPayment);
  }

  handleRequisitesPaymentClick(): void {
    this.submittingPurchaseBtn = true;
    this.submitEvent.emit();
  }
}
