import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { ScriptInjector } from './script-injector';

/**
 * @deprecated
 */
@Injectable()
export class TwitterService extends ScriptInjector {
  readonly TWTR_SCRIPT_ID = 'twtr_scr';
  readonly TWTR_SCRIPT_SRC = 'https://platform.twitter.com/widgets.js';

  // @ts-expect-error TS7006
  constructor(public renderer: Renderer2, @Inject(DOCUMENT) public document, @Inject(PLATFORM_ID) public platformId) {
    super(renderer, document);

    if (isPlatformBrowser(platformId)) {
      this.initialize$().subscribe();
    }
  }

  protected initialize$(): Observable<void> {
    const scriptOptions = { id: this.TWTR_SCRIPT_ID, src: this.TWTR_SCRIPT_SRC };

    return this.appendScript$(scriptOptions);
  }

  /**
   * @deprecated
   */
  generateShareLink(url: string, quote?: string, hashtags?: string[]): string {
    let shareLink = 'https://twitter.com/intent/tweet';

    if (!url) {
      return shareLink;
    }

    shareLink = `${shareLink}?url=${encodeURIComponent(url)}`;

    if (quote) {
      shareLink = `${shareLink}&text=${encodeURIComponent(quote)}`;
    }

    const filteredHashtags =
      hashtags && hashtags.length ? hashtags.map(this.cleanTwitterHashtag).filter(tag => tag) : [];

    if (filteredHashtags.length) {
      const hashtagsString = filteredHashtags.join(',');
      shareLink = `${shareLink}&hashtags=${encodeURIComponent(hashtagsString)}`;
    }

    return shareLink;
  }

  private cleanTwitterHashtag(initialTag: string): string {
    let tag = initialTag.trim();

    if (tag && tag.charCodeAt(0) === 35) {
      tag = tag.slice(1).trim();
    }

    return tag;
  }
}
