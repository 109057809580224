export default function clearDateTimeString(value: string): string {
  return (
    value
      // eslint-disable-next-line no-useless-escape
      .replace(/(\, \, )/g, ', ')
      // eslint-disable-next-line no-useless-escape
      .replace(/(\,\s\s\,\s)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/(\s\,)$/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/^(\,\s)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/(\,\s)$/g, '')
  );
}
