import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { IScheduleOptions } from '@app/modules/ui-kit/schedule/schedule-date-time-picker';
import { TimezonesService } from '@app/core/timezones/timezones.service';

import {
  LOCALE_PROVIDERS,
  SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER,
  SCHEDULE_FORMATS_PROVIDERS,
  SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER,
  SCHEDULE_DATE_TIME_SLOTS_PROVIDER,
  SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
  ScheduleDateTimeSlotsFactory
} from '../../services';
import { ISchedule, ITimeSlot } from '../../types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-single-date-schedule-board',
  templateUrl: './single-date-schedule-board.component.html',
  styleUrls: ['./single-date-schedule-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LOCALE_PROVIDERS,
    SCHEDULE_DATE_TIME_SLOTS_PROVIDER,
    SCHEDULE_DATES_PARTITION_STRATEGY_PROVIDER,
    SCHEDULE_FORMATS_PROVIDERS,
    SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
    SCHEDULE_TIME_SLOTS_BUILD_STRATEGY_PROVIDER
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'single-date-schedule-board'
  }
})
export class SingleDateScheduleBoardComponent {
  // TODO: make option
  readonly maxDate: { readonly year: number; readonly month: number; readonly day: number } | null;

  // TODO: make option
  minDate: { readonly year: number; readonly month: number; readonly day: number } | null;

  @Input()
  set scheduleOptions(value: IScheduleOptions) {
    this.onScheduleOptionsChange(value);
  }

  @Output()
  readonly timeSelect = new EventEmitter<ITimeSlot>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get scheduleOptions(): IScheduleOptions {
    return {
      date: this.timeSlotsProvider.date,
      duration: this.timeSlotsProvider.duration,
      timezone: this.timeSlotsProvider.timezone,
      excludeDateTime: this.timeSlotsProvider.excludeDateTime
    };
  }

  constructor(
    readonly timeSlotsProvider: ScheduleDateTimeSlotsFactory<ISchedule, string, number, string, ITimeSlot>,
    readonly timezones$: TimezonesService,
    runtimeConfig: RuntimeConfigService
  ) {
    const now = DateTime.local();

    const { year: minDateYear, month: minDateMonth, day: minDateDay } = now.toObject();
    // @ts-expect-error TS2322
    this.minDate = { year: minDateYear, month: minDateMonth, day: minDateDay };

    const {
      year: maxDateYear,
      month: maxDateMonth,
      day: maxDateDay
    } = now.plus({ months: runtimeConfig.get('availabilityMaxBoundaryInMonths') }).toObject();
    // @ts-expect-error TS2322
    this.maxDate = { year: maxDateYear, month: maxDateMonth, day: maxDateDay };

    this.markDateDisabled = this.markDateDisabled.bind(this);
  }

  markDateDisabled(dateObject: { day: number; month: number; year: number }): boolean {
    return !this.timeSlotsProvider.doesDateHaveSlots(DateTime.fromObject(dateObject).toISO());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onScheduleOptionsChange(scheduleOptions: IScheduleOptions) {
    const { date, duration, timezone, excludeDateTime } = scheduleOptions;

    if (timezone) {
      const { year: minDateYear, month: minDateMonth, day: minDateDay } = DateTime.local().setZone(timezone).toObject();
      // @ts-expect-error TS2322
      this.minDate = { year: minDateYear, month: minDateMonth, day: minDateDay };

      this.timeSlotsProvider.changeTimezone(timezone, { refreshTimeSlots: !date });
    }

    if (duration) {
      this.timeSlotsProvider.changeDuration(duration, { refreshTimeSlots: !date });
    }

    if (date) {
      this.timeSlotsProvider.changeDate(date);
    }

    if (excludeDateTime) {
      this.timeSlotsProvider.changeExcludeDateTime(excludeDateTime);
    }
  }
}
