import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
import { IUser } from '@app/shared/interfaces/user';
import { IServiceWorkspaceAssignee } from '@app/screens/guide/guide-sessions-templates/types';
import { User } from '@app/core/auth/services';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPackageSettings {
  title: string;
  description: string;
  isFixedPrice: boolean;
  isFree: boolean;
  price: number;
  subscriptionRecurrency?: SubscriptionRecurrency;
  sessions: {
    sessionTemplateId: number;
    count?: number;
    expirationPeriod?: string;
  }[];
}

export type IPackageAssignee = IUser & Pick<User, 'email'> & Pick<IServiceWorkspaceAssignee, 'role' | 'status'>;

export enum PackageAutoRenewalType {
  MONTHLY = 'monthly',
  ANNUAL = 'annual'
}
