<div class="w-100">
  <label pui-input-label i18n="@@timezone">Time zone</label>
  <pui-select
    [maxHeight]="300"
    [formControl]="timezoneControl"
    [optionContent]="optionContentTemplate"
    [puiDropdownBeforeContent]="dropdownBeforeContent">
    <ng-template #dropdownBeforeContent>
      <div>
        <div class="timezone__search-input">
          <pui-icon svgIcon="pui:search" size="s" class="pui-space_left-2"></pui-icon>
          <input
            class="pui-space_left-2"
            pui-input-text
            type="text"
            [formControl]="searchControl"
            placeholder="Search..."
            i18n-placeholder="@@TimeZoneSearch" />
        </div>
      </div>
    </ng-template>

    <pui-option
      class="timezone__selected-option"
      [label]="item.name"
      [value]="item.value"
      [data]="item"
      *ngFor="let item of timezones | filter: filterTimezonesFn(searchControl?.value)"></pui-option>
  </pui-select>
</div>

<ng-template #optionContentTemplate let-option="option">
  <div class="timezone__option" [class.timezone__selected-option]="timezoneControl.value === option.data.value">
    <span>{{ option.data.name }}</span>
  </div>
</ng-template>
