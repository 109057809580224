import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class UploaderService {
  constructor(private _http: HttpClient, private _notification: NotificationsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadFile(endpoint: string, file: File, key: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append(key, file, file.name);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this._http.post(endpoint, formData, { headers, reportProgress: true, observe: 'events' }).pipe(
      catchError(error => {
        // typescript 3.5 does not understand the "?." operator
        const msg = key ? ((((error || {}).error || {}).errors || {})[key] || {}).msg : '';
        const title = `File upload error`;
        this._notification.error(msg || title);
        return throwError(error);
      })
    );
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteFile(endpoint: string, id): Observable<any> {
    return this._http.delete<void>(`${endpoint}/${id}`).pipe(
      catchError(error => {
        const msg = `Cannot delete file`;
        this._notification.error(msg);
        return throwError(error);
      })
    );
  }
}
