import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, take } from 'rxjs/operators';

import { AUTH_API } from '@app/shared/constants/endpoints';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { MembershipService } from '@app/core/membership/membership.service';
import { SubscriptionPage } from '@app/core/membership/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';

import { AuthService } from '@app/core/auth/services';
import { OUR_HTTP_ENDPOINT } from './our-http-endpoint';
import { PreloaderService } from '../preloader/preloader.service';

const roleSwitchingUrls = [`${AUTH_API}/alternativeAccount/signin`, `${AUTH_API}/alternativeAccount/signup`];

@Injectable()
export class OurHttpInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isBrowser: boolean;

  constructor(
    private readonly _injector: Injector,
    @Optional() @Inject(OUR_HTTP_ENDPOINT) private endPoint: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platform: any,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    private preloader: PreloaderService,
    private readonly _membershipService: MembershipService
  ) {
    this._isBrowser = isPlatformBrowser(platform);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.preloader.loadingStart(req.url);

    const authService = this._injector.get(AuthService);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reqReplaces: any = {
      url: (this.endPoint ? this.endPoint : '') + req.url
    };

    const reqToken = this._getToken(authService);

    if (reqToken) {
      reqReplaces.headers = req.headers.set('token', reqToken);
    }

    if (authService.getRoleSwitchingFromCookie() && !roleSwitchingUrls.includes(req.url)) {
      return EMPTY;
    }

    return next.handle(req.clone(reqReplaces)).pipe(
      // @ts-expect-error TS2345
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.error?.payload === 'membership') {
            this._membershipService.membershipSettings$.pipe(take(1)).subscribe(settings => {
              if (authService.user.RoleId === UserRoles.CLIENT) {
                if (settings.subscriptionPage === SubscriptionPage.MembershipWall) {
                  return this._injector
                    .get(Router)
                    .navigate([`/${environment.membershipRoute}/${encodeURIComponent(settings.defaultClientPlan)}`]);
                }

                return;
              }

              if (authService.user.RoleId === UserRoles.GUIDE) {
                if (settings.subscriptionPage === SubscriptionPage.MembershipWall) {
                  return this._injector
                    .get(Router)
                    .navigate([`/${environment.membershipRoute}/${encodeURIComponent(settings.defaultCoachPlan)}`]);
                }

                return;
              }
            });
          }

          if (
            err.error?.error === '$RoleError$' ||
            (typeof err.error === 'string' && err.error.includes('$RoleError$'))
          ) {
            const currentReqToken = this._getToken(authService);

            if (currentReqToken && reqToken !== currentReqToken) {
              this._browserWindow.location?.reload();
            } else {
              authService.signout();
            }

            return throwError(null);
          }
          return throwError(err);
        }
      }),
      finalize(() => {
        this.preloader.loadingEnd(req.url);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _getToken(authService: AuthService) {
    return this._isBrowser ? authService.getTokenFromCookie() : authService.user?.authToken;
  }
}
