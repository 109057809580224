<ng-container [formGroup]="form">
  <div class="question-text">
    <div class="question-html ql-editor" [appSetMetaToFirstChild]="key" [innerHTML]="question | safeHtml"></div>
  </div>

  <div class="question-answer fs-exclude" formGroupName="answer">
    <div class="answer ql-editor" *ngIf="isDisabled && answerText" [innerHTML]="answerText | safeHtml"></div>
    <div class="answer empty" *ngIf="isDisabled && !answerText" i18n="@@noAnswer">No answer</div>
    <app-text-editor-image
      formControlName="text"
      *ngIf="!isDisabled && questionType === QuizQuestionType.LONG_ANSWER"
      i18n-placeholder="@@typeYourAnswerHere"
      placeholder="Type your answer here..."
      [extendedVideoUploader]="true"></app-text-editor-image>
    <input
      class="form-control"
      type="text"
      formControlName="text"
      *ngIf="!isDisabled && questionType === QuizQuestionType.SHORT_ANSWER"
      placeholder="Type your answer here..."
      i18n-placeholder="@@typeYourAnswerHere" />
  </div>
</ng-container>
