<div class="filter-panel">
  <app-filter-dropdown
    (apply)="setPriceFilter()"
    (reset)="resetPriceFilter()"
    [filterDirty]="priceGroup.dirty"
    class="filter-dropdown">
    <span class="filter-title" i18n="@@price">Price</span>

    <div class="filter-form price-filter-wrapper">
      <!-- <ng-scrollbar> -->
      <div class="price-selected">
        <ng-container i18n="@@priceRangeSelected1">Price selected:</ng-container>
        <span class="price-value">
          {{ priceGroup.value.from | platformCurrency }}
          - {{ priceGroup.value.to | platformCurrency }}
        </span>
      </div>
      <div class="">
        <app-marketplace-range-slider
          #priceFilter
          [fromIndicatorValue]="priceGroup.value.from | platformCurrency"
          [toIndicatorValue]="priceGroup.value.to | platformCurrency"></app-marketplace-range-slider>
      </div>

      <!-- </ng-scrollbar> -->
    </div>
  </app-filter-dropdown>

  <app-filter-dropdown
    (apply)="setStartDateFilter()"
    (reset)="resetStartDateFilter()"
    [filterDirty]="startDateGroup.dirty"
    class="filter-dropdown start-date-filter-wrapper">
    <span class="filter-title" i18n="@@startDate">Start date</span>
    <div class="filter-form">
      <app-range-datepicker #startDateFilter></app-range-datepicker>
    </div>
  </app-filter-dropdown>

  <app-filter-dropdown
    (apply)="setIssueFilter()"
    (reset)="resetIssueFilter()"
    [filterDirty]="issuesGroup.dirty"
    class="filter-dropdown">
    <span class="filter-title" i18n="@@issue">Issue</span>
    <div class="filter-form issue-filter-wrapper">
      <ng-scrollbar>
        <div class="issue-filter-list">
          <app-category-list #issueFilter></app-category-list>
        </div>
      </ng-scrollbar>
    </div>
  </app-filter-dropdown>

  <app-filter-dropdown
    (apply)="setApproachFilter()"
    (reset)="resetApproachFilter()"
    [filterDirty]="approachesGroup.dirty"
    class="filter-dropdown">
    <span class="filter-title" i18n="@@approach">Approach</span>
    <div class="filter-form approach-filter-wrapper">
      <ng-scrollbar>
        <div class="approach-filter-list">
          <app-category-list #approachFilter></app-category-list>
        </div>
      </ng-scrollbar>
    </div>
  </app-filter-dropdown>

  <app-filter-dropdown
    (apply)="setPractitionerFilter()"
    (reset)="resetPractitionerFilter()"
    [filterDirty]="practitionerGroup.dirty"
    class="filter-dropdown">
    <span class="filter-title" transloco="practitioner">Practitioner</span>
    <div class="filter-form">
      <div class="practitioner-filter-wrapper">
        <app-marketplace-user-typeahead #practitionerFilter></app-marketplace-user-typeahead>
      </div>
    </div>
  </app-filter-dropdown>

  <!--    [TODO] Uncomment when programs will have languages again-->
  <!--  <app-filter-dropdown-->
  <!--      (apply)="setLanguageFilter()"-->
  <!--      (reset)="resetLanguageFilter()"-->
  <!--      [filterDirty]="languagesGroup.dirty"-->
  <!--      class="filter-dropdown"-->
  <!--  >-->
  <!--    <span class="filter-title" i18n="@@language">Language</span>-->
  <!--    <div class="filter-form">-->
  <!--      <ng-scrollbar>-->
  <!--        <div class="language-filter-wrapper">-->
  <!--          <app-flat-list-->
  <!--            #languageFilter>-->
  <!--          </app-flat-list>-->
  <!--        </div>-->
  <!--      </ng-scrollbar>-->
  <!--    </div>-->
  <!--  </app-filter-dropdown>-->

  <div ngbDropdown class="filter-dropdown" autoClose="outside" (openChange)="!$event && setSortingFilter()">
    <div ngbDropdownToggle [class.filter-active]="sortingGroup.dirty">
      <span class="filter-title" i18n="@@sorting">Sorting</span>
    </div>
    <div ngbDropdownMenu>
      <div class="filter-content">
        <app-marketplace-sorting-filter #sortingFilter></app-marketplace-sorting-filter>
      </div>
    </div>
  </div>
</div>
