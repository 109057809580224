import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceTypeComponent } from './service-type.component';

@NgModule({
  declarations: [ServiceTypeComponent],
  imports: [CommonModule],
  exports: [ServiceTypeComponent]
})
export class ServiceTypeModule {}
