import { DateTime } from 'luxon';
import { GuideServiceParent } from '@app/modules/book-service';
import { SessionGroup } from './group-sessions-by.pipe';
import { ClientSession } from '@libs/business/client/client-session';

export const groupSessionsByParentAndPurchase = (sessions: ClientSession<GuideServiceParent>[]): SessionGroup[] => {
  if (!sessions) {
    return [];
  }

  const groupedByParentAndPurchase = sessions.reduce((groups, session) => {
    const parent = session.parent;
    if (!parent) {
      return groups;
    }

    // We need a unique key with which we will separate sessions from one package with different expiration dates.
    // This is necessary in order to visually separate sessions from one package into different blocks
    // (if the package is purchased again)
    const purchaseId = parent.packagePurchaseId;
    const groupKey = `${parent.id}${purchaseId}` as string;

    const group: SessionGroup = groups[groupKey] || {
      id: parent.id,
      name: parent.name,
      type: parent.type,
      total: parent.childrenCount,
      available: parent.childrenAvailableCount,
      children: [],
      order: DateTime.fromISO(parent.expiresAt ?? DateTime.now().toISO()).toMillis()
    };

    const childrens = group.children;

    return {
      ...groups,
      [groupKey]: {
        ...group,
        children: [...childrens, session]
      } as SessionGroup
    };
  }, {} as Record<string, SessionGroup>);

  return Object.values(groupedByParentAndPurchase).sort((current, next) => {
    if (isNaN(current.order - next.order)) {
      return -1;
    }

    return current.order - next.order;
  });
};
