import { GuideRelationTypes, isGuideRelation } from '@app/core/users/types';
import { NewGuideClient, isNewGuideClient } from '../interfaces/offers';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideRelationBasicDetails {
  readonly id: number;
  readonly type: GuideRelationTypes;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INewGuideClientBasicDetails {
  readonly id?: number;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
}

export type OfferClientDetails = IGuideRelationBasicDetails | INewGuideClientBasicDetails;

export type OfferReceiver =
  | { readonly id: number; readonly type: GuideRelationTypes; readonly name?: string }
  | NewGuideClient;

export function toClientOffer<T = {}>(offer: T, receiver: OfferReceiver): T & { client: OfferClientDetails } {
  if (isGuideRelation(receiver)) {
    const { id, type } = receiver;

    return {
      ...offer,
      client: { id, type }
    };
  }

  if (isNewGuideClient(receiver)) {
    const { id, email, firstName, lastName } = receiver;

    // @ts-expect-error TS2322
    return {
      ...offer,
      client: { email, firstName, lastName, id: id || null }
    };
  }

  throw new Error('Invalid type of offer receiver');
}
