import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AnalyticCopyTypes } from '@app/core/analytics/types';

@Component({
  selector: 'app-copy-value-input',
  templateUrl: './copy-value-input.component.html',
  styleUrls: ['./copy-value-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyValueInputComponent {
  analyticCopyTypes = AnalyticCopyTypes;

  @Input()
  buttonText = `Copy`;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;

  @Output()
  copied = new EventEmitter<AnalyticCopyTypes>();

  @Output()
  copyFailed = new EventEmitter();
}
