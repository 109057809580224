import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { PUI_DIALOG_DATA, PuiDialogRef } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-guide-discard-changes-dialog',
  templateUrl: './guide-discard-changes-dialog.component.html',
  styleUrls: ['./guide-discard-changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideDiscardChangesDialogComponent {
  // @ts-expect-error TS7006
  constructor(private readonly dialogRef: PuiDialogRef, @Inject(PUI_DIALOG_DATA) private readonly data) {}

  discard(): void {
    this.dialogRef.close(null);
  }

  cancel(): void {
    this.dialogRef.close(this.data);
  }
}
