import { ClipboardModule } from 'ngx-clipboard';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GuideServiceIncludesService } from '@app/modules/guide-service-editor/services/guide-service-includes.service';
import { GuideSessionModule } from '@app/modules/guide-session/guide-session.module';
import { ProgramModule } from '@app/modules/program/program.module';
import { ScrollbarModule } from '@app/modules/scrollbar/scrollbar.module';
import { GroupServicesModule } from '@app/modules/service-scheduling/components/group-services/group-services.module';
import { PriceInputModule } from '@app/modules/ui-kit/price-input/price-input.module';
import { GuideServicesModule } from '@app/screens/guide/modules/widgets/guide-services/guide-services.module';
import { SharedModule } from '@app/shared/shared.module';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiIconModule,
  PuiLetModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { GuideServiceClientComponent } from './guide-service-client/guide-service-client.component';
import { GuideServiceClientsEditorComponent } from './guide-service-clients-editor/guide-service-clients-editor.component';
import { GuideServiceClientsListComponent } from './guide-service-clients-list/guide-service-clients-list.component';
import { GuideServiceEditorHeaderComponent } from './guide-service-editor-header/guide-service-editor-header.component';
import { GuideServiceEditorLandingComponent } from './guide-service-editor-landing.component';
import { GuideServiceFAQEditorComponent } from './guide-service-faq-editor/guide-service-faq-editor.component';
import { GuideServiceIncludesEditorComponent } from './guide-service-includes-editor/guide-service-includes-editor.component';
import { GuideServiceIncludesListComponent } from './guide-service-includes-list/guide-service-includes-list.component';
import { GuideServicePricingEditorComponent } from './guide-service-pricing-editor/guide-service-pricing-editor.component';
import { GuideServiceQuestionnairesEditorComponent } from './guide-service-questionnaires-editor/guide-service-questionnaires-editor.component';
import { GuideServiceSessionEditorComponent } from './guide-service-session-editor/guide-service-session-editor.component';
import { GuideServiceSharingEditorComponent } from './guide-service-sharing-editor/guide-service-sharing-editor.component';
import { GuideServiceSharingSettingsEditorComponent } from './guide-service-sharing-settings-editor/guide-service-sharing-settings-editor.component';
import { GuideServiceSocialLinksComponent } from './guide-service-social-links/guide-service-social-links.component';
import { GuideServiceTestimonialsEditorComponent } from './guide-service-testimonials-editor/guide-service-testimonials-editor.component';
import { GuideAvailabilityService } from './services/guide-availability.service';
import { GuideFAQEditorService } from './services/guide-faq-editor.service';
import { GuidePriceEditorService } from './services/guide-price-editor.service';
import { GuideSharingEditorService } from './services/guide-sharing-editor.service';
import { GuideTestimonialsEditorService } from './services/guide-testimonials-editor.service';
import { MapToServiceItemPipe } from './guide-service-includes-list/map-to-service-item.pipe';
import { RuntimeConfigModule } from '@app/core/runtime-config/runtime-config.module';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    FormsModule,
    GuideServicesModule,
    GuideSessionModule,
    NgScrollbarModule,
    NgbModule,
    PuiLetModule,
    PlatformStyleModule,
    ProgramModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollbarModule,
    SharedModule,
    PriceInputModule,
    PuiIconModule,
    PuiButtonModule,
    GroupServicesModule,
    PuiAvatarModule,
    PuiToggleModule,
    PuiTypographyModule,
    PuiSelectModule,
    RuntimeConfigModule
  ],
  declarations: [
    GuideServiceClientComponent,
    GuideServiceClientsEditorComponent,
    GuideServiceClientsListComponent,
    GuideServiceEditorHeaderComponent,
    GuideServiceEditorLandingComponent,
    GuideServiceFAQEditorComponent,
    GuideServicePricingEditorComponent,
    GuideServiceQuestionnairesEditorComponent,
    GuideServiceSessionEditorComponent,
    GuideServiceSharingEditorComponent,
    GuideServiceSharingSettingsEditorComponent,
    GuideServiceSocialLinksComponent,
    GuideServiceTestimonialsEditorComponent,
    GuideServiceIncludesListComponent,
    GuideServiceIncludesEditorComponent,
    MapToServiceItemPipe
  ],
  exports: [
    GuideServiceEditorLandingComponent,
    GuideServicePricingEditorComponent,
    GuideServiceSharingSettingsEditorComponent,
    GuideServiceTestimonialsEditorComponent,
    GuideServiceClientsEditorComponent,
    GuideServiceQuestionnairesEditorComponent,
    GuideServiceSharingEditorComponent,
    GuideServiceSocialLinksComponent,
    GuideServiceFAQEditorComponent,
    GuideServiceIncludesListComponent
  ],
  providers: [
    GuideAvailabilityService,
    GuideFAQEditorService,
    GuidePriceEditorService,
    GuideSharingEditorService,
    GuideTestimonialsEditorService,
    GuideServiceIncludesService
  ]
})
export class GuideServiceEditorModule {}
