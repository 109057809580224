import { Injectable } from '@angular/core';
import { GuideClientCardComponent } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.component';
import { PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { GuideClientCardTab } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/services/guide-client-card-tab';
import { GuideClientData } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/type';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  clientsByClientId,
  clientsByRelationId
} from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';

@Injectable({
  providedIn: 'root'
})
export class GuideClientCardService {
  constructor(
    private readonly drawer: PuiDrawerService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly store$: Store
  ) {}

  handleRouterChanges() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const { clientId, relationId, tab } = this.route.snapshot.queryParams;

      if (!relationId && !clientId) {
        return;
      }

      const selectFn = relationId ? clientsByRelationId : clientsByClientId;
      const id = relationId ? Number(relationId) : Number(clientId);

      this.store$
        .select(selectFn)
        .pipe(
          map(clients => clients[Number(id)]),
          filter(client => !!client),
          take(1)
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe(client => {
          this.open(client.id, Number(tab) || GuideClientCardTab.DETAILS);
        });
    });
  }

  openDetails(id: CRMClient['id'], clientId?: CRMClient['clientId']): PuiDrawerRef<void, GuideClientData> {
    return this.open(id, GuideClientCardTab.DETAILS, clientId);
  }

  openNotes(id: CRMClient['id'], clientId?: CRMClient['clientId']): PuiDrawerRef<void, GuideClientData> {
    return this.open(id, GuideClientCardTab.NOTES, clientId);
  }

  openHistory(id: CRMClient['id']): PuiDrawerRef<void, GuideClientData> {
    return this.open(id, GuideClientCardTab.HISTORY);
  }

  private open(
    id: CRMClient['id'],
    tab: GuideClientCardTab = GuideClientCardTab.DETAILS,
    clientId?: CRMClient['clientId']
  ): PuiDrawerRef<void, GuideClientData> {
    if (id === undefined) {
      console.warn('Parameter {clientId} is required!');

      // @ts-expect-error TS2322
      return;
    }

    return this.drawer.open(
      GuideClientCardComponent,
      {
        position: 'right',
        maxWidth: '600px',
        hideCloseButton: true
      },
      { id, tab, clientId }
    );
  }
}
