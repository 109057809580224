import { ExternalCalendarEventTypes } from '@app/screens/guide/guide-sessions/types/external-calendar-types';
import { FullCalendarUnavailableTimeEventTypes } from '@app/screens/guide/guide-sessions/types/full-calendar-blocked-time-events';

export enum FullCalendarSessionEventTypes {
  SESSION_REQUEST = 'session_request',
  SESSION_OFFER = 'session_offer',
  FUTURE_SESSION = 'future_session',
  PAST_SESSION = 'past_session',
  GROUP_SESSION = 'group_session'
}

export type FullCalendarEventType =
  // FullCalendarSessionEventTypes
  | FullCalendarSessionEventTypes.SESSION_REQUEST
  | FullCalendarSessionEventTypes.SESSION_OFFER
  | FullCalendarSessionEventTypes.FUTURE_SESSION
  | FullCalendarSessionEventTypes.PAST_SESSION
  | FullCalendarSessionEventTypes.GROUP_SESSION
  // ExternalCalendarEventTypes
  | ExternalCalendarEventTypes.GOOGLE
  | ExternalCalendarEventTypes.OUTLOOK
  // FullCalendarUnavailableTimeEventTypes
  | FullCalendarUnavailableTimeEventTypes.OTHER_WORKSPACE_EVENT
  | FullCalendarUnavailableTimeEventTypes.MY_TIME_BLOCK
  | FullCalendarUnavailableTimeEventTypes.OTHERS_TIME_BLOCK
  | FullCalendarUnavailableTimeEventTypes.SCHEDULE_UNAVAILABILITY_INTERVAL
  | FullCalendarUnavailableTimeEventTypes.BUFFER_TIME;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface FullCalendarEvent {
  id?: string;
  eventId?: string;
  title?: string;
  start: string;
  end: string;
  type: FullCalendarEventType;
  classNames: FullCalendarCustomEventCssClasses[];
  display?: 'auto' | 'block' | 'list-item' | 'background' | 'inverse-background' | 'none';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalData?: any;
  allDay?: boolean;
}

export enum FullCalendarCustomEventCssClasses {
  PENDING_EVENT = 'pending-event',
  // Not used in scss
  PAST_SESSION = 'past-session',
  FULL_STORY_MASKED = 'fs-mask',
  PENDING_CONFIRMATION = 'pending-confirmation',
  CALENDAR_EVENT = 'calendar-event',
  GROUP_SESSION = 'group-session',
  PROGRAM_EVENT = 'program-event',
  PACKAGE_EVENT = 'package-event',
  OTHER_WORKSPACE_EVENT = 'other_workspace_event',
  BG_BLOCK = 'bg-block',
  BUFFER_TIME = 'buffer-time',
  BG_SCHEDULE = 'bg-schedule',
  OUTLOOK = 'outlook_calendar_event',
  GOOGLE = 'google_calendar_event',
  SESSION_MISSED = 'session-missed',
  SESSION_EXPIRED = 'session-expired',
  SESSION_DONE = 'session-done'
}

export type FullCalendarEventTypesClassNames = {
  [key in FullCalendarEventType]: FullCalendarCustomEventCssClasses[];
};
