import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributeOptions, ClientSessionGuideAttribute } from '../types';
import { ClientSessionAttributesEnum } from '@libs/business/client/client-session/modules';

export const clientSessionGuideAttribute = ({
  name,
  chatLinkId
}: ClientSessionAttributeOptions): ClientSessionGuideAttribute | null => {
  if (!name) {
    return null;
  }

  return {
    type: ClientSessionAttributesEnum.GUIDE,
    leftIcon: SessionAttributeIconEnum.USER,
    rightIcon: SessionAttributeIconEnum.MESSAGE,
    rightIconRouterLink: `../../client/chat/${chatLinkId}`,
    label: name
  };
};
