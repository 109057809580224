export function humanizeIdUrl(source: string, id: number | string): string {
  if (!source) {
    return '';
  }

  const uri = source.replace(/\s+/g, '-');
  return `${id}-${encodeURI(uri.toLowerCase())}`;
}

export function makeServiceLinkBuilder<T extends { id: number | string; name: string; link?: string }>(
  baseUrl: string
): (service: T) => T {
  return function (service: T): T {
    if (service.link) {
      return service;
    }

    service.link = `${baseUrl}/${humanizeIdUrl(service.name, service.id)}`;

    return service;
  };
}
