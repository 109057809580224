import { HttpParameterCodec } from '@angular/common/http';

export class CustomUrlQueryEncoder implements HttpParameterCodec {
  // eslint-disable-next-line id-length
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }

  // eslint-disable-next-line id-length
  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }

  // eslint-disable-next-line id-length
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  // eslint-disable-next-line id-length
  decodeValue(v: string): string {
    return decodeURIComponent(v);
  }
}
