import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiDateScheduleTimePickerModule } from '@app/modules/ui-kit/schedule/multi-date-schedule-time-picker/multi-date-schedule-time-picker.module';
import { SingleDateScheduleTimePickerModule } from '@app/modules/ui-kit/schedule/single-date-schedule-time-picker/single-date-schedule-time-picker.module';
import { SingleDateScheduleBoardComponent } from './components/single-date-schedule-board/single-date-schedule-board.component';
import { MultiDateScheduleBoardComponent } from './components/multi-date-schedule-board/multi-date-schedule-board.component';

@NgModule({
  imports: [CommonModule, SingleDateScheduleTimePickerModule, MultiDateScheduleTimePickerModule],
  declarations: [SingleDateScheduleBoardComponent, MultiDateScheduleBoardComponent],
  exports: [SingleDateScheduleBoardComponent, MultiDateScheduleBoardComponent]
})
export class ScheduleBoardsModule {}
