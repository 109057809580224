<div class="modal-header">
  <app-button className="close" (click)="dismiss()"></app-button>
</div>

<div class="modal-body" [formGroup]="form">
  <div class="preview">
    <app-module-quiz
      [editableAnswers]="true"
      [isCorrectDisplay]="false"
      formControlName="questions"
      [displayKey]="false"></app-module-quiz>
  </div>
</div>
