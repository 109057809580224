import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGuideOfferDetails, IGuideOfferOptions } from '@app/modules/guide-offer/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-guide-offer-modal',
  templateUrl: './guide-offer-modal.component.html',
  styleUrls: ['./guide-offer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-offer-modal'
  }
})
export class GuideOfferModalComponent {
  @Input() disableService = false;
  @Input() hidePrice = false;

  @Input()
  // @ts-expect-error TS2564
  offerOptions: IGuideOfferOptions;

  @Input()
  // @ts-expect-error TS2564
  onOfferDetailsChange: ((details: IGuideOfferDetails) => void) | null;

  constructor(readonly modal: NgbActiveModal) {}

  updateOfferDetails(details: IGuideOfferDetails): void {
    if (this.onOfferDetailsChange) {
      this.onOfferDetailsChange(details);
    }
  }
}
