import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AlternativeAuthProvidersService } from '../../services/alternative-auth-providers.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { NotificationsService } from 'angular2-notifications';
import { AuthProviders } from '@app/shared/enums/auth-providers';
import { AuthService } from '@app/core/auth/services';

@Component({
  selector: 'app-alternative-auth-providers',
  templateUrl: './alternative-auth-providers.component.html',
  styleUrls: ['./alternative-auth-providers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlternativeAuthProvidersComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input()
  remember = true;

  @Input()
  showSSOWelcome = false;

  @Input() onlyClient = false;

  @Input() onlyGuide = false;

  @Input() hideSso = false;

  @Input()
  // @ts-expect-error TS2564
  teamInvitationCode: string;

  @Output()
  signUp = new EventEmitter();

  @Output()
  afterSignIn = new EventEmitter();

  @Output()
  signInCanNot = new EventEmitter();

  private isGuideSSOEnabled = this.runtimeConfigService.get('isGuideSSOEnabled') || false;
  private isClientSSOEnabled = this.runtimeConfigService.get('isClientSSOEnabled') || false;

  socialButtonConfig$!: Observable<{
    isFacebookAuthEnabled: boolean;
    isGoogleAuthEnabled: boolean;
  }>;

  get showSocialAuth(): boolean {
    // open link with param guide=true or invite link
    if (this.onlyGuide && !this.onlyClient && this.isGuideSSOEnabled) {
      return false;
    }

    // SSO enabled for Client and has program invitation link
    if (this.auth.hasClientProgramInvitation && this.isClientSSOEnabled) {
      return false;
    }

    return !(this.isGuideSSOEnabled && this.isClientSSOEnabled);
  }

  get showSSOAuth(): boolean {
    if (this.hideSso) {
      // if invite link - hide sso
      return false;
    }

    if (this.onlyGuide && !this.onlyClient && !this.isGuideSSOEnabled) {
      return false;
    }

    // SSO enabled for one of role
    if (this.isGuideSSOEnabled || this.isClientSSOEnabled) {
      return true;
    }

    // hide SSO button
    return false;
  }

  constructor(
    private readonly auth: AuthService,
    private readonly authProvider: AlternativeAuthProvidersService,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly brandingService: BrandingService,
    private readonly notificationsService: NotificationsService
  ) {}

  openFacebookAuthPopup(): void {
    this.authProvider.openFacebookAuthPopup(this.remember, this.onlyClient, this.onlyGuide, this.teamInvitationCode);
  }

  openGoogleAuthPopup(): void {
    this.authProvider.openGoogleAuthPopup(this.remember, this.onlyClient, this.onlyGuide, this.teamInvitationCode);
  }

  openSSOAuthPopup(): void {
    this.authProvider.openSSOAuthPopup(this.remember, this.onlyClient, this.onlyGuide, this.teamInvitationCode);
  }

  ngOnInit(): void {
    this.socialButtonConfig$ = this.brandingService.globalConfig$.pipe(
      map(config => ({
        isFacebookAuthEnabled: config?.isFacebookAuthEnabled,
        isGoogleAuthEnabled: config?.isGoogleAuthEnabled
      }))
    );

    this.authProvider
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe((loggedIn: boolean) => {
        if (loggedIn) {
          if (this.authProvider.signInCanNot) {
            this.signInCanNot.emit();
          } else {
            this.afterSignIn.emit();
          }
        }

        if (!loggedIn && this.authProvider.signUpUser && this.checkSocialAuth()) {
          this.signUp.emit();
        }
      });

    if (this.showSSOAuth) {
      this.authProvider.insertSSOAuthFrame(this.remember, this.onlyClient, this.onlyGuide, this.teamInvitationCode);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkSocialAuth(): boolean {
    if (this.authProvider.signUpProvider === AuthProviders.SSO) {
      return true;
    }

    if ((this.onlyGuide && this.isGuideSSOEnabled) || (this.onlyClient && this.isClientSSOEnabled)) {
      this.notificationsService.error(`Authentication requires SSO: Enter your email to proceed securely`);

      return false;
    }

    return true;
  }
}
