<div class="modal-header">
  <h2 class="modal-title" i18n="@@chatParticipantsModalPartiicipants">Participants ({{ participants.length }})</h2>
</div>
<div class="modal-body">
  <ui-selection-list [multiple]="false" style="background-color: white" disableRipple>
    <ui-list-option *ngFor="let participant of participants" class="participant-option">
      <ui-card class="ui-card-flat">
        <ui-card-header>
          <pui-avatar
            ui-card-avatar
            uiIconBadge="online-status"
            uiBadgeIconColor="primary"
            [avatarUrl]="participant.photo"
            [text]="participant.name"
            [uiIconBadgeHidden]="!participant.isOnline"></pui-avatar>
          <ui-card-title>
            <app-button class="name" className="btn-link" (click)="goToUser(participant.id)">
              {{ participant.name }}
            </app-button>
          </ui-card-title>
          <ui-card-subtitle *ngIf="participant.email || participant.contacts?.email">
            {{ participant.email || participant.contacts?.email }}
          </ui-card-subtitle>
          <div class="actions" align="end">
            <button
              pui-dropdown
              [pui-dropdown-content]="dropdownContent"
              pui-icon-button
              size="m"
              type="button"
              appearance="flat"
              shape="rounded">
              <pui-icon svgIcon="pui:more-vertical"></pui-icon>
            </button>

            <ng-template #dropdownContent let-close="close">
              <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
                <button
                  class="remove-btn"
                  pui-list-item="button"
                  pui-a11y-keyboard-widget-item
                  (click)="close(); openRemoveUserModal(participant)">
                  <pui-list-item-icon>
                    <pui-icon svgIcon="pui:x-circle" size="s" appearance="warning"></pui-icon>
                  </pui-list-item-icon>
                  <span i18n="@@chatParticipantsModalRemoveFromChat">Remove from the chat</span>
                </button>
              </div>
            </ng-template>
          </div>
        </ui-card-header>
      </ui-card>
    </ui-list-option>
  </ui-selection-list>
</div>
