import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthGuideLayoutComponent } from './auth-guide-layout.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AuthGuideLayoutComponent]
})
export class AuthGuideLayoutModule {}
