<div [formGroup]="form">
  <input
    type="radio"
    formControlName="sorting"
    name="sorting"
    value="default"
    id="{{ prefix }}default"
    (change)="onChange()" />
  <label ngbDropdownItem for="{{ prefix }}default" i18n="@@default">Default</label>

  <input
    type="radio"
    formControlName="sorting"
    name="sorting"
    value="startDateAsc"
    id="{{ prefix }}startDateAsc"
    (change)="onChange()" />
  <label ngbDropdownItem for="{{ prefix }}startDateAsc" i18n="@@startDate">Start date</label>

  <input
    type="radio"
    formControlName="sorting"
    name="sorting"
    value="priceAsc"
    id="{{ prefix }}priceAsc"
    (change)="onChange()" />
  <label ngbDropdownItem for="{{ prefix }}priceAsc" i18n="@@priceLowToHigh">Price low to high</label>

  <input
    type="radio"
    formControlName="sorting"
    name="sorting"
    value="priceDesc"
    id="{{ prefix }}priceDesc"
    (change)="onChange()" />
  <label ngbDropdownItem for="{{ prefix }}priceDesc" i18n="@@priceHighToLow">Price high to low</label>
</div>
