<div class="note-card" id="note-card-{{ note.id }}">
  <div class="card-meta">
    <div class="notes-meta-wrapper">
      <app-note-pinned-badge class="note-pin" *ngIf="note.pinned"></app-note-pinned-badge>
      <div class="note-actions">
        <ng-content select=".meta"></ng-content>
        <div class="card-controls" *ngIf="!editing">
          <button
            pui-icon-button
            type="button"
            shape="square"
            appearance="flat"
            size="s"
            direction="bottom-left"
            pui-dropdown
            [attr.data-qa-id]="'note-card-menu-' + noteIndex"
            [pui-dropdown-content]="actions">
            <pui-icon svgIcon="pui:more-vertical"></pui-icon>
          </button>
          <ng-template #actions let-close="close">
            <ul pui-list>
              <li
                [attr.data-qa-id]="'note-card-edit-note-menu-item-' + noteIndex"
                pui-list-item="button"
                *ngIf="!isSharedBy() && !isMetaRecording()"
                (click)="startEditingNote(); close()">
                <pui-list-item-icon>
                  <pui-icon svgIcon="pui:edit"></pui-icon>
                </pui-list-item-icon>
                <span i18n="@@noteCardEditNote">Edit note</span>
              </li>
              <li
                [attr.data-qa-id]="'note-card-pin-note-menu-item-' + noteIndex"
                pui-list-item="button"
                *ngIf="!note.pinned"
                (click)="pinNote.emit(noteIndex); close()">
                <pui-list-item-icon>
                  <pui-icon svgIcon="pui:pin"></pui-icon>
                </pui-list-item-icon>
                <span i18n="@@noteCardPinNote">Pin note</span>
              </li>
              <li
                [attr.data-qa-id]="'note-card-unpin-note-menu-item-' + noteIndex"
                pui-list-item="button"
                *ngIf="note.pinned"
                (click)="unpinNote.emit(noteIndex); close()">
                <pui-list-item-icon>
                  <pui-icon svgIcon="pui:paperclip_off"></pui-icon>
                </pui-list-item-icon>
                <span i18n="@@noteCardUnpinNote">Unpin note</span>
              </li>
              <li
                [attr.data-qa-id]="'note-card-manage-access-menu-item-' + noteIndex"
                pui-list-item="button"
                *ngIf="!isSharedBy() && !hideShare"
                (click)="manageAccess.emit(noteIndex); close()">
                <pui-list-item-icon>
                  <pui-icon svgIcon="pui:users"></pui-icon>
                </pui-list-item-icon>
                <span i18n="@@noteCardManageAccessNote">Manage access</span>
              </li>
              <li
                [attr.data-qa-id]="'note-card-delete-note-menu-item-' + noteIndex"
                pui-list-item="button"
                pui-list-item-appearance="error"
                (click)="deleteNote.emit(noteIndex); close()"
                *ngIf="!isSharedBy() && !hideDelete">
                <pui-list-item-icon>
                  <pui-icon svgIcon="pui:trash"></pui-icon>
                </pui-list-item-icon>
                <span i18n="@@noteCardDeleteNote">Delete note</span>
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="note-card-body" [class.shared]="isSharedBy()">
  <app-text-editor-notes
    #notesEditorComponent
    [noteIndex]="noteIndex"
    [readonly]="isSharedBy()"
    [noteDelta]="note.contentDeltaFormat"
    [noteHtml]="note.content"
    [noteText]="note.contentText"
    (noteChange)="onNoteChange($event)"
    (endEditing)="onEndEditing($event)"
    (isEditorOpened)="lockControls($event)"
    [emptyNotePreview]="emptyNotePreview"
    [clamped]="true"
    [minLines]="noteEditorMinLines"
    [startEdit]="editing"></app-text-editor-notes>
  <div class="shared-by" *ngIf="isSharedBy()">
    <pui-avatar [text]="note.metaSharedBy.user.name" [avatarUrl]="note.metaSharedBy.user.photo" size="s"></pui-avatar>
    <span class="shared-by__text" i18n="@@sharedBy">Shared by</span>
    <span class="name">{{ note.metaSharedBy.user.name }}</span>
  </div>
  <app-note-recording
    *ngIf="note.metaRecording?.recordingId"
    [recordingId]="note.metaRecording?.recordingId"
    [recordingDuration]="note.metaRecording?.recordingDuration"
    [noteText]="note.contentText"
    [noteRecording]="note.metaRecording.tokboxArchive || note.metaRecording"></app-note-recording>
  <div class="sharedWith" *ngIf="isSharedWith()">
    <pui-avatar-group (click)="openSharingSettings.emit()" tabindex="0">
      <ng-container
        *ngFor="let viewer of note.metaSharedWith.slice(0, maxSharedUsersAvatars); trackBy: viewersTrackBy"
        ngProjectAs="pui-avatar">
        <pui-avatar
          [avatarUrl]="viewer.user.photo"
          [pui-tooltip]="viewer.accessType | userNotesAccessType"
          [text]="viewer.user.name"
          [attr.data-qa-id]="
            'note-card-user-access-avatar-' + viewer.accessType + '-' + noteIndex + '-' + viewer.user.id
          "
          role="button"
          size="s"></pui-avatar>
      </ng-container>
      <ng-container *ngIf="note.metaSharedWith.length - maxSharedUsersAvatars > 0" ngProjectAs="pui-avatar">
        <pui-avatar
          role="button"
          aria-pressed="false"
          [autoColor]="false"
          [text]="'+' + (note.metaSharedWith.length - maxSharedUsersAvatars)"></pui-avatar>
      </ng-container>
    </pui-avatar-group>
  </div>
</div>

<ng-template #emptyNotePreview>
  <div class="empty-note">
    <span i18n="@@noteCardStartTyping">Start typing...</span>
  </div>
</ng-template>
