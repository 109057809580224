import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ControlValueAccessor, DefaultValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { HttpEventType } from '@angular/common/http';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';

import { dataURIToBlob } from '@app/shared/utils/data-uri-to-blob';

import { FileStackService } from '@app/core/filestack/filestack.service';
import { FileStackFile } from '@app/shared/interfaces/filestack';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

import { HtmlUploaderComponent, IframeUploaderComponent, VideoUploaderComponent } from '../uploaders';
import { TextEditorImageDownloaderService } from '../../services';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { clipboardPasteMatcher, extendedToolbarContainer } from '../../modules';
import { TextEditorImageUploadDriver } from '../../models';
import { generateRandomFileName, registerHtmlBlot, registerModules, registerVideoBlot } from '../../utils';
import { cleanHTML } from '@app/shared/utils/quill-utils';
import { QuillEntityTypes } from '@app/shared/enums/quill-entity-types';

// Because quill uses `document` directly, we cannot `import` during SSR
// instead, we load dynamically via `require('quill')` in `ngOnInit()`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let require: any;

// tslint:disable-next-line:variable-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let Quill: any = null;

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-text-editor-image',
  templateUrl: './text-editor-image.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditorImageComponent),
      multi: true
    },
    TextEditorImageDownloaderService
  ]
})
export class TextEditorImageComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // text editor instance
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _textEditor;

  // file input element in text editor toolbar
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _toolbarDomElement: HTMLElement;

  // current platform
  private readonly isBrowser: boolean;

  // uniq id for current text editor
  // @ts-expect-error TS2564
  private id: string;

  // driver for image upload
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _uploadDriver: TextEditorImageUploadDriver;

  // subscription for current upload driver
  // @ts-expect-error TS2564
  private fileUrlsSubscription: Subscription;

  // subscription for current upload driver
  // @ts-expect-error TS2564
  private defaultDriverSubscription: Subscription;

  private readonly fileExtentionsRegExp = /(\.jpg|\.jpeg|\.png)$/i;

  private onDestroy$ = new Subject();

  // settigs for text editor
  // toolbar - buttons and events handlers
  modulesDefault = {
    toolbar: {
      container: extendedToolbarContainer,
      handlers: {
        fs: () => this.handleFileStackEmbedding(),
        html: () => this.handleHtmlEmbedding()
        // iframe: () => this.handleIframeEmbedding(),
      }
    }
  };

  // text editor format
  @Input()
  format: 'html' | 'object' | 'text' | 'json' = 'html';

  // text editor settings
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules: any;

  @Input()
  // @ts-expect-error TS2564
  placeholder: string;

  // custom uploader service
  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploaderService: (file: File) => Observable<any>;

  @Input()
  set uploadDriver(driver: TextEditorImageUploadDriver) {
    this._uploadDriver = driver;

    if (this.fileUrlsSubscription) {
      this.fileUrlsSubscription.unsubscribe();
    }

    if (this.defaultDriverSubscription) {
      this.defaultDriverSubscription.unsubscribe();
    }

    this.fileUrlsSubscription = driver.fileUrls$
      .pipe(
        filter(({ id }) => id === this.id),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ url }) => this.setQuillEntity(QuillEntityTypes.IMAGE, url));
  }

  @Input()
  extendedVideoUploader = false;

  // e2e id for text editor
  @Input()
  // @ts-expect-error TS2564
  qaId: string;

  @Input()
  // @ts-expect-error TS2564
  bounds: HTMLElement | string;

  @Output()
  // @ts-expect-error TS7008
  contentChanged = new EventEmitter<{ html: string; text: string; delta; content }>();

  @Output()
  editorCreated = new EventEmitter();

  @ViewChild('textEditorComponent', { static: true })
  // @ts-expect-error TS2564
  private defaultValueAccessor: DefaultValueAccessor;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/explicit-function-return-type
  get uploadDriver() {
    return this._uploadDriver;
  }

  // calculated settings for text editor
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get calculatedModules() {
    const calculatedModules = Object.assign(this.modulesDefault, {});

    if (this.modules) {
      Object.entries(this.modules).forEach(([key, value]) => {
        // @ts-expect-error TS7053
        calculatedModules[key] = Object.assign(calculatedModules[key], value);
      });
    }

    return calculatedModules;
  }

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    private readonly _zone: NgZone,
    private readonly _textEditorImageDownloaderService: TextEditorImageDownloaderService,
    private readonly _modal: NgbModal,
    private readonly _fileStackService: FileStackService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private readonly _platformId
  ) {
    this.isBrowser = isPlatformBrowser(this._platformId);
  }

  ngOnInit(): void {
    this.id = this.generateRandomId();
    this.initQuillExtentions();
    this.initUploaderDriver();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  writeValue(obj: any) {
    this.defaultValueAccessor.writeValue(obj);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnChange(fn: any) {
    this.defaultValueAccessor.registerOnChange(fn);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this.defaultValueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.defaultValueAccessor.setDisabledState(isDisabled);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customizeEditor(editor: any): void {
    this.editorCreated.emit(editor);

    if (editor.editor.scroll.domNode) {
      this._renderer.setAttribute(editor.editor.scroll.domNode, 'data-qa-id', this.qaId);
    }

    this._toolbarDomElement = this._elementRef.nativeElement.querySelector('.ql-toolbar');
    this._textEditor = editor;

    const toolbar = this._textEditor.getModule('toolbar');
    toolbar.addHandler('image', () => this.handleImageEmbedding());

    // save only text and styles
    // @ts-expect-error TS7006
    this._textEditor.clipboard.addMatcher(1, (node, delta) => {
      // @ts-expect-error TS7034
      const ops = [];
      // @ts-expect-error TS7006
      delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert,
            attributes: op.attributes
          });
        }
      });
      // @ts-expect-error TS7005
      delta.ops = ops;
      return delta;
    });

    // add link to pasted text
    // @ts-expect-error TS7006
    this._textEditor.clipboard.addMatcher(3, (node, delta) => {
      const regex = /https?:\/\/[^\s]+/g;

      if (regex.exec(node.data) != null) {
        delta.ops = [{ insert: node.data, attributes: { link: node.data } }];
      }
      return delta;
    });

    toolbar.addHandler('image', () => this.handleImageEmbedding());

    if (this.extendedVideoUploader) {
      toolbar.addHandler('video', () => this.handleVideoEmbedding());
    }
  }

  getContentMarkup(): string {
    return this._textEditor ? this._textEditor.container.querySelector('.ql-editor').innerHTML : null;
  }

  getContentText(): string {
    return this._textEditor ? this._textEditor.getText() : null;
  }

  private initUploaderDriver(): void {
    this.uploadDriver = new TextEditorImageUploadDriver();
    const uploader = this.uploaderService || this._textEditorImageDownloaderService.uploadFileWithContext;

    this.defaultDriverSubscription = this._uploadDriver.uploadRequests$
      .pipe(
        switchMap(({ id, file }) => combineLatest([of(id), uploader(file).pipe(debounceTime(200))])),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([id, event]) => {
        if (event.type === HttpEventType.Response) {
          const { url } = event.body;
          if (!url) {
            return;
          }
          this.uploadDriver.passUrl(id, url);
        }
      });
  }

  private generateRandomId(): string {
    return Math.floor(Math.random() * 1e12).toString();
  }

  private initQuillExtentions(): void {
    if (this.isBrowser) {
      if (!Quill) {
        Quill = require('quill');
        registerModules(Quill);
        registerVideoBlot(Quill);
        registerHtmlBlot(Quill);
      }
    }
  }

  private createQuillFileInput(): HTMLElement {
    const fileInput = this._renderer.createElement('input');

    this._renderer.setProperty(fileInput, 'type', 'file');
    this._renderer.setProperty(fileInput, 'accept', 'image/*');
    this._renderer.setProperty(fileInput, 'capture', 'camera');
    this._renderer.addClass(fileInput, 'ql-image');

    this._zone.runOutsideAngular(() =>
      // eslint-disable-next-line id-length
      this._renderer.listen(fileInput, 'change', e => {
        this.onQuillImageInputChange(e);
        fileInput.value = '';
      })
    );

    this._renderer.appendChild(this._toolbarDomElement, fileInput);

    return fileInput;
  }

  private handleImageEmbedding(): void {
    // @ts-expect-error TS2322
    let fileInput: HTMLElement = this._toolbarDomElement.querySelector('input.ql-image[type=file]');

    if (fileInput == null) {
      fileInput = this.createQuillFileInput();
    }

    fileInput.click();
  }

  private handleVideoEmbedding(): void {
    const modalRef: NgbModalRef = this._modal.open(VideoUploaderComponent, { size: 'lg' });

    modalRef.result.then(data => this.setQuillEntity(QuillEntityTypes.VIDEO, data)).catch(() => {});
  }

  private handleFileStackEmbedding(): void {
    this._fileStackService.openUploadWindow((file: FileStackFile) => {
      const url = this._runtimeConfigService.get('filestackS3Url') + file.key;
      if (this.fileExtentionsRegExp.test(file.filename)) {
        this.setQuillEntity(QuillEntityTypes.IMAGE, url);
      } else {
        this.setQuillDownloadLink(file.filename, url);
      }
    });
  }

  // temporary unused
  private handleIframeEmbedding(): void {
    const modalRef: NgbModalRef = this._modal.open(IframeUploaderComponent, { size: 'sm' });

    modalRef.result.then(data => this.setQuillEntity(QuillEntityTypes.IFRAME, data)).catch(() => {});
  }

  private handleHtmlEmbedding(): void {
    const modalRef: NgbModalRef = this._modal.open(HtmlUploaderComponent, { size: 'sm' });

    modalRef.result.then(data => this.setQuillEntity(QuillEntityTypes.HTML, data)).catch(() => {});
  }

  // @ts-expect-error TS7006
  private handleImageDroppedOrPasted(imageDataUri, type: string): void {
    if (!type) {
      type = 'image/png';
    }

    const blob = dataURIToBlob(imageDataUri);
    const fileName = generateRandomFileName(type);
    const file = new File([blob], fileName);

    this._uploadDriver.uploadFile(this.id, file);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line id-length
  private onQuillImageInputChange(e): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file = (e as any).target.files[0];
    if (!file || !this.fileExtentionsRegExp.test(file.name)) {
      return;
    }

    this._uploadDriver.uploadFile(this.id, file);
  }

  private setQuillDownloadLink(fileName: string, url: string): void {
    if (this.isBrowser) {
      const range = this._textEditor.getSelection(true);
      this._textEditor.insertText(range.index + 1, fileName, 'link', url, Quill.sources.USER);
      this._textEditor.setSelection(range.index + 12, Quill.sources.SILENT);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setQuillEntity(type: QuillEntityTypes, value: string | any): void {
    if (type === QuillEntityTypes.HTML) {
      value.html = cleanHTML(value.html);
    }

    if (this.isBrowser) {
      const range = this._textEditor.getSelection(true);
      this._textEditor.insertEmbed(range.index, type, value, Quill.sources.USER);
      this._textEditor.setSelection(range.index + 1, Quill.sources.SILENT);
    }
  }
}
