<pui-card>
  <pui-card-header>
    <div
      *ngIf="mode === 'full'"
      class="current-title"
      data-qa-id="user-name"
      pui-card-header-title
      pui-tooltip-direction="top-right"
      [pui-tooltip]="title">
      {{ title }}
    </div>
    <pui-avatar
      pui-card-header-icon
      size="l"
      color="white"
      [avatarUrl]="avatar?.avatarUrl"
      [background]="avatar?.background"
      [rounded]="avatar.rounded"
      [text]="avatar.text[0]"></pui-avatar>
  </pui-card-header>
</pui-card>
