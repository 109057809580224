import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { UsersTypeaheadComponent } from './components/users-typeahead/users-typeahead.component';

import { UsersTypeaheadLabelTemplateDirective } from './directives/users-typeahead-label-template.directive';
import { UsersTypeaheadOptionTemplateDirective } from './directives/users-typeahead-option-template.directive';

@NgModule({
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
  declarations: [UsersTypeaheadComponent, UsersTypeaheadLabelTemplateDirective, UsersTypeaheadOptionTemplateDirective],
  exports: [UsersTypeaheadComponent, UsersTypeaheadLabelTemplateDirective, UsersTypeaheadOptionTemplateDirective]
})
export class UsersTypeaheadModule {}
