/**
 * Here is a function that return a new Date from given Date object to the end of the day
 * @param date {Date}
 * @return Date
 */
export const dayEnd = (date: Date): Date => {
  const dayEnd: Date = new Date(date);

  dayEnd.setHours(23, 59, 59, 999);

  return dayEnd;
};
