import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFooterComponent } from './common-footer.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WidgetModalComponent } from '@app/shared/components/widget-modal/widget-modal.component';
import { PoweredByLinkModule } from '@app/modules/powered-by-link/powered-by-link.module';

@NgModule({
  declarations: [CommonFooterComponent],
  exports: [CommonFooterComponent],
  imports: [CommonModule, RouterModule, SharedModule, ReactiveFormsModule, PoweredByLinkModule]
})
export class CommonFooterModule {}
