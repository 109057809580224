import { NotificationsService } from 'angular2-notifications';
import { DateTime } from 'luxon';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { JsonPipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import config from '@app/core/config/config';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { ISchedule } from '@app/modules/schedule-boards';
import { GUIDE_OFFERS_ENDPOINT, GUIDE_SCHEDULES_ENDPOINT } from '@app/shared/constants/endpoints';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { IServiceScheduleItemWithEnd } from '@app/shared/interfaces/services';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';

import { LogService } from '../log/log.service';
import { LogType } from '../log/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ITimeRangesServerResponse {
  freeTimeRanges: IServiceScheduleItemWithEnd[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITimeRangesRequestOptions {
  clientId?: number | null;
  exEvent?: number | null;
  duration?: number | null;
  exTemplateId?: number | null;
  exDate?: string | null;
  exBlockTime?: string | null;
  timezone?: string | null;
  workspaceId?: number;
  guideId?: number;
  templateId?: number;
}

@Injectable()
export class GuideOfferApiService {
  private message = `Error occurred while trying to find free time for this day`;

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _logger: LogService,
    private _analyticService: AnalyticsService,
    private _guideClientsService: GuideClientsService,
    private _toJson: JsonPipe
  ) {}

  getFreeTimeBase(params: HttpParams) {
    return this._http.get<ITimeRangesServerResponse>(`${GUIDE_SCHEDULES_ENDPOINT}/free-time`, { params }).pipe(
      catchError(error => {
        this._notifications.error(this.message);
        const errorLog = { error, location: 'GuideOfferService.getFreeTimeRanges$' };
        this._logger.sendLog(LogType.ERROR, this._toJson.transform(errorLog));
        return throwError(error);
      }),
      map(response => response.freeTimeRanges)
    );
  }

  getFreeTimeRanges$(date: string, options: ITimeRangesRequestOptions = {}): Observable<IServiceScheduleItemWithEnd[]> {
    const freeTimeRangesParams = {
      startTime: DateTime.fromJSDate(new Date(date)).toISO(),
      endTime: DateTime.fromJSDate(new Date(date)).plus({ days: 1 }).toISO(),
      eventTypeId: options.templateId as number,
      timeZone: options.timezone as string
    };

    if (options) {
      Object.keys(options).forEach((prop: keyof ITimeRangesRequestOptions) => {
        if (options[prop] != null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          freeTimeRangesParams[prop] = options[prop].toString();
        }
      });
    }

    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: { ...freeTimeRangesParams }
    });

    return this.getFreeTimeBase(params);
  }

  getFreeTimeRangesByActionCode$(actionCode: string, date: string): Observable<ISchedule> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const freeTimeRangesParams = { date } as any;

    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: freeTimeRangesParams
    });

    return this._http
      .get<{ freeTimeRanges: IScheduleItem[] }>(
        `${config.apiPath}/shared-event-actions/encoded/${actionCode}/guide/free-time`,
        { params }
      )
      .pipe(
        catchError(error => {
          this._notifications.error(this.message);
          return throwError(error);
        }),
        map(response => ({ ranges: response.freeTimeRanges }))
      );
  }

  // TODO: Use OfferGuideApiService
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  makeOffer$(offer: any): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(GUIDE_OFFERS_ENDPOINT, offer).pipe(
      catchError(error => {
        const title = `Offer cannot be made.`;
        this._notifications.error(title, error.error && error.error.msg ? error.error.msg : null);
        const errorLog = { error, location: 'GuideOfferService.makeOffer' };
        this._logger.sendLog(LogType.ERROR, this._toJson.transform(errorLog));
        return throwError(error);
      }),
      tap(({ sessions }) => {
        const title = `Your session offer was sent to your client.`;
        sessions.forEach(() => this._notifications.info(title));
      }),
      tap(({ sessions }) => {
        // @ts-expect-error TS7006
        sessions.forEach(session => {
          this._guideClientsService.users$.pipe(take(1)).subscribe(relations => {
            const guideClient = relations.filter(relation => relation.id === session.clientId)[0];
            if (guideClient) {
              this._analyticService.event(InternalEvents.SESSION_ADDED, {
                isNew: guideClient.isNew
              });
            }
          });
        });
      })
    );
  }
}
