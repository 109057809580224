// eslint-disable-next-line max-classes-per-file
import { QuizQuestionType } from '@app/core/quizzes/types';
import { ClientQuestionAnswer, ClientChoiceQuestionAnswer } from './client-question-answer';
import { QuestionAnswer, QuestionOption } from '@app/shared/interfaces/programs/client-programs';

export enum QuizQuestionsTypes {
  UNANSWERED = 'unanswered',
  LONG_ANSWER = 'long_answer',
  SHORT_ANSWER = 'short_answer',
  MULTIPLE_CHOICE = 'multiple_choice',
  SINGLE_CHOICE = 'single_choice',
  QUIZ = 'quiz',
  SIGNATURE = 'signature',
  FILE_UPLOAD = 'file_upload'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseQuizQuestionProgressDetails {
  readonly id: number;
  readonly question: string;
  readonly questionType: QuizQuestionType;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizProgressQuestionDetails extends IBaseQuizQuestionProgressDetails {
  readonly answers?: ClientQuestionAnswer[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizProgressChoiceQuestionDetails extends IBaseQuizQuestionProgressDetails {
  readonly options?: QuestionOption[];
  readonly answers?: ClientChoiceQuestionAnswer[];
}

export abstract class BaseQuizQuestionProgress {
  readonly id: number;

  readonly question: string;

  readonly questionType: QuizQuestionType;

  protected constructor(questionDetails: IBaseQuizQuestionProgressDetails) {
    if (!questionDetails) {
      throw new Error('Invalid argument');
    }

    this.id = questionDetails.id;
    this.question = questionDetails.question;
    this.questionType = questionDetails.questionType;
  }
}

export class LongAnswerQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.LONG_ANSWER;

  readonly answers?: ClientQuestionAnswer[];

  constructor(questionDetails: IQuizProgressQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }
  }
}

export class ShortAnswerQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.SHORT_ANSWER;

  readonly answers?: ClientQuestionAnswer[];

  constructor(questionDetails: IQuizProgressQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }
  }
}

export class MultipleChoiceQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.MULTIPLE_CHOICE;

  readonly options: QuestionOption[];
  readonly answers?: ClientChoiceQuestionAnswer[];

  constructor(questionDetails: IQuizProgressChoiceQuestionDetails) {
    super(questionDetails);

    // @ts-expect-error TS2322
    this.options = questionDetails.options;

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }
  }
}

export class SingleChoiceQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.SINGLE_CHOICE;

  readonly options: QuestionOption[];
  readonly answers?: ClientChoiceQuestionAnswer[];

  constructor(questionDetails: IQuizProgressChoiceQuestionDetails) {
    super(questionDetails);

    // @ts-expect-error TS2322
    this.options = questionDetails.options;

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }
  }
}

export class QuizChoiceQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.QUIZ;

  readonly options: QuestionOption[];
  readonly answers?: ClientChoiceQuestionAnswer[];

  constructor(questionDetails: IQuizProgressChoiceQuestionDetails) {
    super(questionDetails);

    // @ts-expect-error TS2322
    this.options = questionDetails.options;

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }
  }
}

export class SignatureQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.SIGNATURE;

  // @ts-expect-error TS2564
  readonly options: QuestionOption[];
  readonly answers?: QuestionAnswer[];
  readonly questionType: QuizQuestionType;

  constructor(questionDetails: IQuizProgressQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }

    this.questionType = questionDetails.questionType;
  }
}

export class FileUploadQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.FILE_UPLOAD;

  // @ts-expect-error TS2564
  readonly options: QuestionOption[];
  readonly answers?: QuestionAnswer[];
  readonly questionType: QuizQuestionType;

  constructor(questionDetails: IQuizProgressQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answers) {
      this.answers = questionDetails.answers;
    }

    this.questionType = questionDetails.questionType;
  }
}

export class UnansweredQuestionProgress extends BaseQuizQuestionProgress {
  readonly type = QuizQuestionsTypes.UNANSWERED;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(questionDetails: IBaseQuizQuestionProgressDetails) {
    super(questionDetails);
  }
}

export type QuizProgressQuestion =
  | UnansweredQuestionProgress
  | LongAnswerQuestionProgress
  | ShortAnswerQuestionProgress
  | MultipleChoiceQuestionProgress
  | SingleChoiceQuestionProgress
  | QuizChoiceQuestionProgress
  | SignatureQuestionProgress
  | FileUploadQuestionProgress;

export function isUnansweredQuestionProgress(
  quizQuestion: QuizProgressQuestion
): quizQuestion is UnansweredQuestionProgress {
  return quizQuestion.type === QuizQuestionsTypes.UNANSWERED;
}

export function isLongAnswerQuestionProgress(
  quizQuestion: QuizProgressQuestion
): quizQuestion is LongAnswerQuestionProgress {
  return quizQuestion.type === QuizQuestionsTypes.LONG_ANSWER;
}

export function isMultipleChoiceQuestionProgress(
  quizQuestion: QuizProgressQuestion
): quizQuestion is MultipleChoiceQuestionProgress {
  return quizQuestion.type === QuizQuestionsTypes.MULTIPLE_CHOICE;
}
