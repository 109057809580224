import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { formatDate, FormStyle, getLocaleDayNames, TranslationWidth } from '@angular/common';

import { IQuizBot, QuizPattern } from '@app/core/quizzes/types';
import { QuizService } from '@app/core/quizzes/quiz.service';
import { environment } from '@env/environment';
// import { locale } from '@env/locale';
import { timeAt } from '@app/shared/utils/time-at-localization';
import { LocaleService } from '@app/core/locale/locale.service';

// const dateTimeLocale = locale.dateTimeLocale;

@Component({
  selector: 'app-quiz-item',
  templateUrl: './quiz-item.component.html',
  styleUrls: ['./quiz-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizItemComponent implements OnInit {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _quiz: IQuizBot;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _dateTimeLocale: string;

  dailyPatterns = [];
  monthlyPatterns = [];
  weeklyPatterns = [];

  @Input()
  set quiz(quiz: IQuizBot) {
    this._quiz = quiz;
  }

  get isQuizReadyToFill(): boolean {
    if (!this.quiz.lastQuestionDate) {
      return false;
    }
    const now = DateTime.local();
    return DateTime.fromISO(this.quiz.lastQuestionDate).plus({ hour: 8 }).toSeconds() > now.toSeconds();
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get quiz(): IQuizBot {
    return this._quiz;
  }

  private getLocaleWeekDays(): { [key: number]: string } {
    const localeWeekDayObject = {};
    const localeWeekDaysArray = getLocaleDayNames(this._dateTimeLocale, FormStyle.Format, TranslationWidth.Wide);

    // @ts-expect-error TS7053
    localeWeekDayObject[7] = localeWeekDaysArray[0];
    // @ts-expect-error TS7053
    localeWeekDaysArray.forEach((day, index, array) => (localeWeekDayObject[index] = array[index]));
    return localeWeekDayObject; // { 1: 'Monday', 2: 'Tuesday', ..., 7: 'Sunday' } - locale based (i.e. en-US)
  }

  private getWeekDaysString(days: string): string {
    const localeWeekDays = this.getLocaleWeekDays();

    return (
      days // '123'
        .split('') // ['1', '2', '3']
        // @ts-expect-error TS7015
        .map(dayNumber => localeWeekDays[dayNumber]) // ['Monday', 'Tuesday', 'Wednesday'] - locale based (i.e. en-US)
        .join(', ') // 'Monday, Tuesday, Wednesday'
        .replace(/,(?=[^,]*$)/, ' and')
    ); // 'Monday, Tuesday and Wednesday'
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private readonly _router: Router,
    private readonly _quizService: QuizService,
    private readonly _localeService: LocaleService
  ) {
    this._dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  ngOnInit(): void {
    this.checkQuizPattern();
  }

  checkQuizPattern(): void {
    if (this.quiz.schedules && this.quiz.schedules.length) {
      // @ts-expect-error TS7006
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const postfix = schedule =>
        timeAt(this._dateTimeLocale) +
        formatDate(schedule.date, environment.timeFormat, this._dateTimeLocale).toLocaleLowerCase();

      this.quiz.schedules.forEach(schedule => {
        if (schedule.pattern === QuizPattern.Daily) {
          // @ts-expect-error TS2345
          this.dailyPatterns.push(`Every day ${postfix(schedule)}`);
        }

        if (schedule.pattern === QuizPattern.Weekly) {
          // @ts-expect-error TS2345
          this.weeklyPatterns.push(`Every ${this.getWeekDaysString(schedule.weekdays)} ${postfix(schedule)}`);
        }

        if (schedule.pattern === QuizPattern.Monthly) {
          // ToDo: localize monthday with plural
          // @ts-expect-error TS2345
          this.monthlyPatterns.push(`Every ${schedule.monthday} day of month ${postfix(schedule)}`);
        }
      });
    }
  }

  handleRedirect(): void {
    if (this.isQuizReadyToFill) {
      this.startQuiz();
    } else {
      this._router.navigate(['/client/programs/quiz/' + this.quiz.id]);
    }
  }

  startQuiz(): void {
    // @ts-expect-error TS2345
    this._quizService.startQuiz(this.quiz.lastScheduleId);
    this._router.navigate(['/client/chat/bot/' + this.quiz.chatId]);
  }
}
