import { IWorkspace, WorkspaceUser, MemberRoleEnum } from '@app/modules/workspaces/types';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { IGuideService } from '@app/modules/book-service';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';

class WorkspaceUtility {
  private static readonly EDITOR_PERMISSIONS: ServiceAssigneePermission[] = [
    ServiceAssigneePermission.OWNER,
    ServiceAssigneePermission.OWNER_X_PROVIDER
  ];

  private static readonly ACCEPTABLE_PERMISSIONS: ServiceAssigneePermission[] = [
    ServiceAssigneePermission.PROVIDER,
    ServiceAssigneePermission.OWNER_X_PROVIDER
  ];

  static get hostPermissions(): ServiceAssigneePermission[] {
    return this.ACCEPTABLE_PERMISSIONS;
  }

  static get sessionPlatformChangePermission(): ServiceAssigneePermission[] {
    return [
      ServiceAssigneePermission.OWNER,
      ServiceAssigneePermission.OWNER_X_PROVIDER,
      ServiceAssigneePermission.PROVIDER
    ];
  }

  static get editorPermissions(): ServiceAssigneePermission[] {
    return this.EDITOR_PERMISSIONS;
  }

  static isAdmin(workspace: Partial<IWorkspace>): boolean {
    return workspace?.role === MemberRoleEnum.ADMIN;
  }

  static isMember(workspace: Partial<IWorkspace>): boolean {
    return workspace?.role === MemberRoleEnum.MEMBER;
  }

  static isSolo(workspace: Partial<IWorkspace>): boolean {
    return workspace?.type === WorkspacesTypes.SOLO;
  }

  static isTeam(workspace: Partial<IWorkspace>): boolean {
    return workspace?.type === WorkspacesTypes.TEAM;
  }

  static serviceCanBeProvided<T extends Pick<IGuideService, 'team'>>({ team }: T): boolean {
    // @ts-expect-error TS2322
    return team?.some(({ permission }) => this.ACCEPTABLE_PERMISSIONS.includes(permission));
  }

  static filterHosts<T extends Pick<WorkspaceUser, 'permission'>>(team: T[]): T[] {
    return team.filter((member: T) => !member.permission || this.hostPermissions.includes(member.permission));
  }
}

export { WorkspaceUtility };
