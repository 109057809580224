import { DateTime } from 'luxon';

import { ILocale } from '@env/locale.interface';
import { TIME } from '@platformStyle/model/const';
import { dayStyle, monthStyle, weekDayStyle, yearStyle } from '@platformStyle/utils/resolvers/default';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Resolver<R> = (...args: any) => R;
export type FragmentsRecord = Partial<Record<Fragment, string | Resolver<string>>>;
export type TemplatesRecord = Record<string, Fragment[] | Resolver<Fragment[]>>;

export interface PlatformStyleSchema {
  [key: string]: {
    datetime: {
      fragments?: FragmentsRecord;
      templates?: TemplatesRecord;
    };
  };
}

export const DEFAULT_FRAGMENTS: FragmentsRecord = {
  time: TIME,
  day: dayStyle,
  'week day': weekDayStyle,
  month: monthStyle,
  year: yearStyle,
  '-': '-',
  ',': ',',
  ' ': ' '
};

export const DEFAULT_TEMPLATES: TemplatesRecord = {
  DATE: ['week day', ',', ' ', 'month', ' ', 'day', ',', ' ', 'year'],
  TIME: ['time'],
  'DATE, TIME': ['week day', ',', ' ', 'month', ' ', 'day', ',', ' ', 'year', ',', ' ', 'time']
};

export type AnyDate = DateTime | Date | string | number;

export enum Fragments {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'time',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'day',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'week day',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'month',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'year',
  '-',
  ',',
  ' '
}

export type Fragment = keyof typeof Fragments;
export type SingleTemplate = 'DATE' | 'TIME' | 'DATE, TIME';
export type Template = SingleTemplate;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ResolverOptions<V = any, Options = any> {
  value?: V;
  templateOptions?: Options;
  templates?: TemplatesRecord;
  fragments?: FragmentsRecord;
}

export interface DateOptions {
  timezone?: string;
}

export interface TemplateOptions {
  localeParams?: ILocale;
  hideAmPm?: boolean;
  showTimezone?: boolean;
  hideWeekDay?: boolean;
  hideDay?: boolean;
  hideMonth?: boolean;
  hideLastYear?: boolean;
  hideTime?: boolean;
  durationInMinutes?: number;
  humanize?: (lastDay: number) => string;
}

export type ResolverTypes = 'toDateTime' | 'toFormat';
export type DateTimeOptions = DateOptions & TemplateOptions;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DateTimeResolver = (date: DateTime, locale: string, tz: string, options?: Record<string, any>) => string;
export type DateTimeResolverOptions = ResolverOptions<AnyDate, DateTimeOptions>;
export type DateTimeRangeResolverOptions = ResolverOptions<AnyDate[], DateTimeOptions>;
