import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { ModuleService } from '@app/shared/interfaces/programs/client-programs';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-module-service',
  templateUrl: './module-service.component.html',
  styleUrls: ['./module-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-service'
  }
})
export class ModuleServiceComponent {
  @Input()
  // @ts-expect-error TS2564
  moduleService: ModuleService;

  readonly ConnectionTypes = SessionConnectionTypes;
  readonly platformName: string;

  constructor(private readonly _runtimeConfigService: RuntimeConfigService) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
  }
}
