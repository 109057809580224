import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayViaPipe } from './display-via.pipe';

@NgModule({
  declarations: [DisplayViaPipe],
  exports: [DisplayViaPipe],
  imports: [CommonModule]
})
export class UiKitHelpersModule {}
