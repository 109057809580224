import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { AlternativeAuthProvidersService } from '@app/modules/auth/services/alternative-auth-providers.service';

@Injectable()
export class AllowedAuthQueryParamsGuard implements CanActivate {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly alternativeAuthProvidersService: AlternativeAuthProvidersService,
    @Inject(PLATFORM_ID) private readonly platformId: string
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { queryParams } = route;

    if (queryParams['applyAuthResponse']) {
      if (!isPlatformBrowser(this.platformId)) {
        return true;
      }

      try {
        const authResponseParams = queryParams['applyAuthResponse'];
        const decodedAuthResponse = decodeURIComponent(authResponseParams);
        const parsedAuthResponse = JSON.parse(decodedAuthResponse);

        this.alternativeAuthProvidersService.applyAuthResponse(parsedAuthResponse);

        return true;
      } catch (error) {
        console.error(error);
      }
    }

    if (!queryParams['signUp'] && !queryParams['signIn'] && !queryParams['plan'] && !queryParams['signInTeam']) {
      return this.router.createUrlTree(
        [this.auth.isAuthorized ? '/dashboard' : '/auth/sign-in'],
        /* Removed unsupported properties by Angular migration: replaceUrl. */ {}
      );
    }

    return true;
  }
}
