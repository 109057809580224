<pui-avatar class="user-photo" [avatarUrl]="user.photo" [text]="user | userName"></pui-avatar>

<div class="details">
  <div class="user-name-container">
    <span class="user-name-prefix" i18n="@@moduleServiceHostUserNamePrefix">Host:</span>
    <a class="user-name" [routerLink]="['/', user.namedUrl]">{{ user | userName }}</a>

    <div class="details-list">
      <ng-container *ngIf="dateTime">
        <div class="details-item">
          <div class="details-item__icon">
            <pui-icon svgIcon="pui:calendar" size="s"></pui-icon>
          </div>

          <div class="details-item__label">
            <span pui-typography="body-s">{{ dateTime }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="session && user">
        <div class="details-item" *ngIf="getConnectionType(session, user) as connection">
          <div class="details-item__icon">
            <pui-icon [svgIcon]="connection.icon" size="s"></pui-icon>
          </div>

          <div class="details-item__label">
            <span pui-typography="body-s">
              <ng-container *ngIf="!connection.url; else link">
                {{ connection.label }}
              </ng-container>

              <ng-template #link>
                <a class="details-item__link" [href]="connection.url" target="_blank">
                  {{ connection.label }}
                </a>
              </ng-template>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-content></ng-content>
</div>
