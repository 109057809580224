<div [formGroup]="form">
  <div formArrayName="categories" *ngFor="let category of categoriesFormArray.controls; let i = index">
    <div [formGroupName]="i" class="category">
      <app-collapse-group *ngIf="category.controls.items.controls.length">
        <div class="collapse-group-title">
          <strong>{{ category.value.name }}</strong>
        </div>
        <div class="collapse-group-content">
          <div formArrayName="items" *ngFor="let item of category.controls.items.controls; let j = index">
            <div [formGroupName]="j" class="item">
              <app-checkbox-reactive>
                <input class="checkbox-input" type="checkbox" formControlName="selected" (change)="onChange(item)" />
                <span class="checkbox-label-text">{{ item.value.name }}</span>
              </app-checkbox-reactive>
            </div>
          </div>
        </div>
      </app-collapse-group>
    </div>
  </div>
</div>
