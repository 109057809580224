import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { checkChatIdType } from '@app/core/chat/utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ChatsUsersService } from '@app/core/chat/chats-users.service';
import { ChatsBotsService } from '@app/core/chat/chats-bots.service';
import { IUser } from '@app/shared/interfaces/user';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { buildUsersGuideMapKey } from '@app/core/users/utils';

// NOTE: main purpose: link guide and client => add to related users
// TODO: better exclude "redirect" logic into activation / loading guard
@Injectable()
export class ActiveChatUserResolver implements Resolve<IUser> {
  constructor(
    private _chatsUsers: ChatsUsersService,
    private _chatsBots: ChatsBotsService,
    private _router: Router,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUser> {
    const idRouteParam = route.paramMap.get('id');

    // @ts-expect-error TS2345
    const chatIdType = checkChatIdType(idRouteParam, this._runtimeConfigService.get('chatPrefix'));

    if (chatIdType.type !== 'user') {
      // @ts-expect-error TS2322
      return of(null);
    }

    // @ts-expect-error TS2531
    const [userId, workspaceId] = idRouteParam.split('W').map(idString => +idString);

    const absUserId = Math.abs(userId);
    const parentUrl = this.getParentUrl(state.url, route.url);

    if (userId < 0) {
      this._router.navigate([`${parentUrl}bot/${absUserId}W${workspaceId}`]);
      // @ts-expect-error TS2322
      return of(null);
    }

    const isBot = typeof route.data === typeof {} && route.data.bot === true;
    const user$ = isBot
      ? this._chatsBots.getUser$(-absUserId)
      : this._chatsUsers.getUser$({ id: absUserId, workspaceId });

    // @ts-expect-error TS2322
    return user$.pipe(
      take(1),
      map(user => {
        if (!user) {
          this._router.navigate([parentUrl]);
          return null;
        }

        return user;
      }),
      catchError(err => {
        this._router.navigate(['/not-found'], { replaceUrl: true });
        return throwError(err);
      })
    );
  }

  private getParentUrl(stateUrl: string, routeUrl: { path: string }[]): string {
    const routeUrlStr = routeUrl.map(seg => seg.path).join('/');
    return stateUrl.substring(0, stateUrl.indexOf(routeUrlStr));
  }
}
