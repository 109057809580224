<ng-container [ngSwitch]="provider.scheduleType$ | async">
  <app-single-date-schedule-board
    *ngSwitchCase="'guideSchedule'"
    [scheduleOptions]="guideScheduleOptions"
    (timeSelect)="selectTime($event)"
    class="schedule-board"></app-single-date-schedule-board>

  <app-multi-date-schedule-board
    *ngSwitchCase="'serviceSchedule'"
    [timezone]="localTimezone"
    (timeSelect)="selectTime($event)"
    class="schedule-board"></app-multi-date-schedule-board>

  <div class="management-panel">
    <app-button
      data-qa-id="service-schedule-confirm-time-button"
      className="btn-main"
      [disabled]="!timeSelected"
      (click)="confirmTime()"
      i18n="@@confirmationBtnText">
      Confirm time
    </app-button>

    <app-button
      data-qa-id="service-schedule-request-more-options-button"
      className="btn-lite btn-need-chat"
      (click)="requestMoreOptions.emit()"
      i18n="@@requestMoreOptions">
      Request more options
    </app-button>
  </div>
</ng-container>
