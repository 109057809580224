import { createSelector } from '@ngrx/store';

import { SERVICE_SCHEDULING_STORE } from './commons/consts';
import { GuideServicesState } from './commons/types';

export const selectServiceSchedulingState = (state: { [SERVICE_SCHEDULING_STORE]: GuideServicesState }) =>
  state[SERVICE_SCHEDULING_STORE];

export const selectServiceSchedulingStatus = createSelector(selectServiceSchedulingState, state => state.status);

export const selectServiceSchedulingIsLoading = createSelector(
  selectServiceSchedulingStatus,
  status => status === 'loading'
);
export const selectServiceSchedulingIsIdle = createSelector(selectServiceSchedulingStatus, status => status === 'idle');
export const selectServiceSchedulingAllServices = createSelector(selectServiceSchedulingState, state => state.services);
export const selectAllClients = createSelector(selectServiceSchedulingState, state => state.allClients);
export const selectChosenClients = createSelector(selectServiceSchedulingState, state => state.chosenClients);
export const selectCurrentStep = createSelector(selectServiceSchedulingState, state => state.currentStep);
export const selectStepsHistory = createSelector(selectServiceSchedulingState, state => state.stepsHistory);
