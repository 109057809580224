import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagService } from '@libs/feature-flags';

export const HAS_FEATURE_KEY = 'hasFeature';
export const REDIRECT_URL = 'redirectUrl';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard implements CanLoad {
  constructor(private readonly featureFlagService: FeatureFlagService, private readonly router: Router) {}

  canLoad(
    route: Route,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // @ts-expect-error TS2532
    const featureName: string = route.data[HAS_FEATURE_KEY];
    // @ts-expect-error TS2532
    const redirectUrl: string = route.data[REDIRECT_URL];

    if (featureName === undefined) {
      console.warn('Flag HAS_FEATURE_KEY not defined for route!');

      return false;
    }

    const hasFeature = this.featureFlagService.hasFeature(featureName);

    if (!hasFeature && redirectUrl) {
      return this.router.navigate([redirectUrl]);
    }

    // @ts-expect-error TS2322
    return hasFeature;
  }
}
