import { NgModule } from '@angular/core';
import {
  NAVIGATION_CONDITIONS_PROVIDER,
  NAVIGATION_DEPENDENCIES_PROVIDER,
  NAVIGATION_ENTRIES_PROVIDER
} from '@app/modules/navigation/providers';
import {
  NavigationConditionsAdminService,
  NavigationConditionsClientService,
  NavigationConditionsGuideService,
  NavigationConditionsSuperAdminService,
  NavigationDependenciesAdminService,
  NavigationDependenciesClientService,
  NavigationDependenciesGuideService,
  NavigationDependenciesSuperAdminService,
  NavigationEntriesAdminService,
  NavigationEntriesClientService,
  NavigationEntriesGuideService,
  NavigationEntriesSuperAdminService
} from '@app/modules/navigation/services';
import { GuideSessionsService } from '@app/screens/guide/guide-sessions/services/events';
import { NavigationApiService } from '@app/modules/navigation/api/navigation-api.service';

@NgModule({
  providers: [
    NavigationApiService,
    NavigationConditionsAdminService,
    NavigationConditionsClientService,
    NavigationConditionsGuideService,
    NavigationConditionsSuperAdminService,
    NavigationEntriesAdminService,
    NavigationEntriesClientService,
    NavigationEntriesGuideService,
    NavigationEntriesSuperAdminService,
    NavigationDependenciesClientService,
    NavigationDependenciesGuideService,
    NavigationDependenciesAdminService,
    NavigationDependenciesSuperAdminService,
    NAVIGATION_CONDITIONS_PROVIDER,
    NAVIGATION_ENTRIES_PROVIDER,
    NAVIGATION_DEPENDENCIES_PROVIDER,
    GuideSessionsService
  ]
})
export class NavigationModule {}
