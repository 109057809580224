import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabeledToggleComponent } from '@app/modules/labeled-toggle/labeled-toggle.component';
import { PuiTooltipModule, PuiToggleModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [LabeledToggleComponent],
  imports: [CommonModule, PuiToggleModule, PuiTooltipModule],
  exports: [LabeledToggleComponent]
})
export class LabeledToggleModule {}
