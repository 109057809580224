import { UserRoles } from '@app/shared/enums/user-roles';
import { SubscriptionRecurrency, SubscriptionStatus } from '@app/shared/enums/subscription';
import { getRecurrencyWeight } from '@app/core/subscriptions/utils';

export const CUSTOM_PLAN = 'custom';

export enum MembershipSettings {
  CLIENTS = 'clients',
  BRANDING = 'branding'
}

export enum ClientsSettingOptions {
  UNLIMITED = 'unlimited'
}

export enum BrandingSettingOptions {
  AWARENOW = 'awarenow',
  CUSTOM = 'custom'
}

export enum SubscriptionPage {
  MembershipWall = 'membership_wall',
  Subscription = 'subscription'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMembershipPlan {
  id: number;
  type: string;
  active: boolean;
  displayOnSubscriptionsPage: boolean;
  pricing: {
    amount: number;
    recurrency: SubscriptionRecurrency | null;
  }[];
  name: string;
  description: string[] | null;
  descriptionTooltipText: string[] | null;
  roles: UserRoles[];
  trialPeriod: number | null;
  isFree: boolean;
  defaultRole: UserRoles;
  marketplaceServiceFee: number;
  coachClientsServiceFee: number;
  settings: {
    id: number;
    value: string;
    optionId: number | null;
    settingId: number;
    setting: { name: MembershipSettings };
  }[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUserMembership {
  id: number;
  amount: number;
  recurrency: SubscriptionRecurrency | null;
  name: string;
  roles: UserRoles[];
  platformPlanType: string;
  status: SubscriptionStatus;
  trialPeriod: number | null;
  free: boolean;
  endless: boolean;
  isTrial: boolean;
  currentPeriodEnd: string | null;
  paymentInfo: { cardNumber: string } | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMembershipSettings {
  enabledForCoaches: boolean;
  enabledForClients: boolean;
  defaultCoachPlan: string;
  defaultClientPlan: string;
  hidePublicRoutes: boolean;
  trialCoachPlan: string;
  trialCoachPlanPeriod: number;
  trialClientPlan: string;
  trialClientPlanPeriod: number;
  subscriptionPage: SubscriptionPage;
}

export class Plan {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isBiggerRecurrencySelected = true;

  // @ts-expect-error TS2564
  id: number;
  // @ts-expect-error TS2564
  type: string;
  // @ts-expect-error TS2564
  active: boolean;
  // @ts-expect-error TS2564
  displayOnSubscriptionsPage: boolean;
  // @ts-expect-error TS2564
  pricing: {
    amount: number;
    recurrency: SubscriptionRecurrency | null;
  }[];
  // @ts-expect-error TS2564
  name: string;
  // @ts-expect-error TS2564
  description: string[] | null;
  // @ts-expect-error TS2564
  descriptionTooltipText: string[] | null;
  // @ts-expect-error TS2564
  roles: UserRoles[];
  // @ts-expect-error TS2564
  trialPeriod: number | null;
  // @ts-expect-error TS2564
  isFree: boolean;
  // @ts-expect-error TS2564
  defaultRole: UserRoles;
  // @ts-expect-error TS2564
  marketplaceServiceFee: number;
  // @ts-expect-error TS2564
  coachClientsServiceFee: number;
  // @ts-expect-error TS2564
  settings: {
    id: number;
    value: string;
    optionId: number | null;
    settingId: number;
    setting: { name: MembershipSettings };
  }[];

  availableRecurrencies = [];

  get isBiggerRecurrencySelected(): boolean {
    return this._isBiggerRecurrencySelected;
  }

  set isBiggerRecurrencySelected(val: boolean) {
    this._isBiggerRecurrencySelected = val;
  }

  constructor(plan: IMembershipPlan) {
    Object.entries(plan).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });

    // @ts-expect-error TS2322
    this.availableRecurrencies = [this.getPricing(false), this.getPricing(true)]
      .map(item => (item ? item.recurrency : null))
      .filter((item, index, self) => item && self.indexOf(item) === index);
  }

  get amount(): number {
    const pricing = this.getPricing();

    return pricing ? pricing.amount : 0;
  }

  get recurrency(): SubscriptionRecurrency | null {
    const pricing = this.getPricing();

    return pricing ? pricing.recurrency : null;
  }

  get isLifetimeDeal(): boolean {
    return this.amount > 0 && !this.recurrency;
  }

  get recurrencyWeight(): number {
    return getRecurrencyWeight(this.recurrency);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onIsBiggerRecurrencySelectedChanged(value) {
    this.isBiggerRecurrencySelected = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private getPricing(isBiggerRecurrencySelected = this.isBiggerRecurrencySelected) {
    if (!this.id) {
      return null;
    }

    if (this.isFree) {
      return null;
    }

    if (this.pricing.length === 1) {
      return this.pricing[0];
    }

    const weekly = this.pricing.find(price => price.recurrency === SubscriptionRecurrency.Week);
    const monthly = this.pricing.find(price => price.recurrency === SubscriptionRecurrency.Month);
    const annual = this.pricing.find(price => price.recurrency === SubscriptionRecurrency.Year);

    if (isBiggerRecurrencySelected) {
      if (annual) {
        return annual;
      }
      return monthly;
    }

    if (weekly) {
      return weekly;
    }

    return monthly;
  }
}
