<app-payment-options-selector
  [fullPrice]="options?.fullPrice"
  [installmentsPrices]="options?.installmentsPrices"
  #paymentOptionSelector></app-payment-options-selector>

<app-button
  data-qa-id="service-payment-options-form-continue-button"
  className="btn btn-primary"
  i18n="@@continue"
  (click)="submit()"
  >Continue</app-button
>
