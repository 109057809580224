import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Component({
  selector: 'app-guide-service-badge',
  templateUrl: './guide-service-badge.component.html',
  styleUrls: ['./guide-service-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceBadgeComponent {
  @Input() type: GuideServiceTypes | undefined;

  readonly GUIDE_SERVICE_TYPE = GuideServiceTypes;
}
