import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, CanLoad, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { isPlatformBrowser } from '@angular/common';
import { UserRoles } from '@app/shared/enums/user-roles';

import { AuthService } from './services/auth.service';
import { extractUrlFromRouterNavigation } from './utils';

@Injectable()
export class ClientGuard implements CanActivate, CanLoad {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isBrowser: boolean;

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _notifications: NotificationsService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLoginAndRole(state.url);
  }

  canLoad(): boolean {
    // @ts-expect-error TS2345
    const url = extractUrlFromRouterNavigation(this._router.getCurrentNavigation());
    return this.checkLoginAndRole(url);
  }

  // @ts-expect-error TS7006
  private checkLoginAndRole(url): boolean {
    if (!this._isBrowser) {
      return false;
    }

    const isClient = this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.CLIENT;

    if (!isClient) {
      if (this._auth.isAuthorized) {
        const title = `Only for Clients`;
        const message = `Sign In as a Client, please`;
        this._notifications.error(title, message);
        this._router.navigate(['/']);
      } else {
        this._auth.redirectUrl = url;
        this._router.navigate(['/auth']);
      }
    }

    return isClient;
  }
}
