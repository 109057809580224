<div class="file-container d-flex flex-wrap py-2 px-3">
  <div class="row no-gutters w-100">
    <div class="col-auto">
      <div class="file-icon icon"></div>
    </div>

    <div class="d-flex col-9 pr-2 pl-2 flex-column justify-content-center mb-sm-2 mb-md-0">
      <div class="row no-gutters">
        <div class="file-name">{{ file.name }}</div>
      </div>
      <div class="row no-gutters" *ngIf="prettyFileSize">
        <div class="file-size">{{ prettyFileSize }}</div>
      </div>
    </div>

    <div class="col-auto ml-auto" *ngIf="!disableDownload">
      <a
        target="_blank"
        class="download-icon icon"
        download="{{ file.name }}"
        href="{{ file.location }}"
        rel="noopener"></a>
    </div>
  </div>

  <ng-container [ngSwitch]="true">
    <div
      class="row no-gutters w-100 mt-2"
      *ngSwitchCase="fileType === MimeTypes.IMAGE && imageExt.includes(fileExtension)">
      <img class="image-file-preview" [src]="file.location" alt="file-preview" />
    </div>

    <div
      class="row no-gutters w-100 mt-2"
      *ngSwitchCase="fileType === MimeTypes.VIDEO && videoExt.includes(fileExtension)">
      <div class="video-container">
        <div class="video-wrapper">
          <video controls>
            <source [src]="file.location" [type]="file.type" />
          </video>
        </div>
      </div>
    </div>
  </ng-container>
</div>
