import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { ITimeSlot } from '../../types';
import { SCHEDULE_TIME_FORMAT_STRATEGY, ScheduleDateTimeFormatStrategy } from '../schedule-date-time-format-strategies';
import { ScheduleTimeSlotsBuildStrategy } from './schedule-time-slots-build-strategy';

@Injectable()
export class DefaultScheduleTimeSlotsBuildStrategy extends ScheduleTimeSlotsBuildStrategy<IScheduleItem[], ITimeSlot> {
  protected readonly TIME_UNIT = 'minutes';

  protected readonly DEFAULT_BUILD_STEP = 30;

  constructor(
    @Inject(SCHEDULE_TIME_FORMAT_STRATEGY)
    private readonly _timeLabelFormatter: ScheduleDateTimeFormatStrategy<string | number | Date>
  ) {
    super();
  }

  generate(
    schedule: IScheduleItem[],
    slotDuration: number,
    timezone: string,
    timeSlotFormat: string,
    locale: string,
    excludeDateTime?: string
  ): ITimeSlot[] {
    // We build map of unique label, and array of available guides for this label
    // @ts-expect-error TS2339
    const scheduleGuideMap: Map<ITimeSlot['label'], IScheduleItem['guide']['id'][]> = new Map();
    const slots = [].concat(
      // @ts-expect-error TS2769
      ...schedule.map((range: IScheduleItem) =>
        this.generateRangeTimeSlots(range, slotDuration, timezone, timeSlotFormat, locale, excludeDateTime)
      )
    );

    const uniqueSlots: ITimeSlot[] = [
      ...slots
        .reduce((map, slot) => {
          // Setting Initial Value
          // @ts-expect-error TS2339
          if (!scheduleGuideMap.has(slot.label) && slot.guide) {
            // @ts-expect-error TS2339
            scheduleGuideMap.set(slot.label, [slot.guide.id]);
          }

          // @ts-expect-error TS2339
          if (map.has(slot.label) && slot.guide) {
            // If we already have this slot.label in map, it means,
            // we should just add new guide identifier to array of available hosts
            // @ts-expect-error TS2322
            const existingHosts: number[] = scheduleGuideMap.get(slot.label);
            // @ts-expect-error TS2339
            existingHosts.push(slot.guide.id);
            // @ts-expect-error TS2339
            scheduleGuideMap.set(slot.label, existingHosts);
          }
          // Adding list of available guides for this slot into return value
          // @ts-expect-error TS2339
          return map.set(slot.label, { ...slot, guides: scheduleGuideMap.get(slot.label) });
        }, new Map())
        .values()
    ];

    // Sorting by date, since we processed a lot of duplicates
    return uniqueSlots.sort((current: ITimeSlot, next: ITimeSlot) => (current.value > next.value ? 1 : -1));
  }

  canGenerateTimeSlots(
    schedule: IScheduleItem[],
    duration: number,
    timezone: string,
    excludeDateTime?: string
  ): boolean {
    return schedule.some((range: IScheduleItem) => {
      const rangeStart = DateTime.fromISO(range.dateStart).setZone(timezone);
      const rangeEnd = DateTime.fromISO(range.dateEnd).setZone(timezone);
      const excludeDateTimeRow = excludeDateTime && DateTime.fromISO(excludeDateTime).setZone(timezone);

      const subRanges = [];
      if (
        excludeDateTimeRow &&
        rangeStart.hasSame(excludeDateTimeRow, 'day') &&
        rangeStart <= excludeDateTimeRow &&
        rangeEnd >= excludeDateTimeRow
      ) {
        subRanges.push({ start: rangeStart, end: excludeDateTimeRow });
        if (rangeEnd.diff(excludeDateTimeRow, 'minutes').minutes >= this.DEFAULT_BUILD_STEP) {
          subRanges.push({ start: excludeDateTimeRow.plus({ minutes: this.DEFAULT_BUILD_STEP }), end: rangeEnd });
        }
      } else {
        subRanges.push({ start: rangeStart, end: rangeEnd });
      }

      return subRanges.some(({ start, end }) => {
        const slotStart = this.roundSlotStart(start);
        // @ts-expect-error TS2531
        const slotEnd = slotStart.plus({ [this.TIME_UNIT]: duration });

        // @ts-expect-error TS2339
        return start.hasSame(slotStart, 'day') && slotEnd <= end;
      });
    });
  }

  protected generateRangeTimeSlots(
    range: IScheduleItem,
    duration: number,
    timezone: string,
    timeSlotFormat: string,
    locale: string,
    excludeDateTime?: string
  ): ITimeSlot[] {
    const timeSlots: ITimeSlot[] = [];

    let slotStart = this.roundSlotStart(DateTime.fromISO(range.dateStart).setZone(timezone));
    // @ts-expect-error TS2531
    let slotEnd = slotStart.plus({ [this.TIME_UNIT]: duration });

    const rangeStart = DateTime.fromISO(range.dateStart).setZone(timezone);
    const rangeEnd = DateTime.fromISO(range.dateEnd).setZone(timezone);

    // @ts-expect-error TS2345
    while (rangeStart.hasSame(slotStart, 'day') && slotEnd <= rangeEnd) {
      timeSlots.push({
        duration,
        timezone,
        timeFormat: timeSlotFormat,
        // @ts-expect-error TS2531
        value: slotStart.toISO(),
        // @ts-expect-error TS2322
        label: this._timeLabelFormatter.format(slotStart.toISO(), {
          timezone,
          format: timeSlotFormat
        }),
        guide: range.guide
      });

      // @ts-expect-error TS2531
      slotStart = slotStart.plus({ [this.TIME_UNIT]: this.DEFAULT_BUILD_STEP });
      slotEnd = slotStart.plus({ [this.TIME_UNIT]: duration });
    }

    if (excludeDateTime) {
      return timeSlots.filter(
        timeSlot =>
          DateTime.fromISO(timeSlot.value).set({ second: 0, millisecond: 0 }).toMillis() !==
          DateTime.fromISO(excludeDateTime).toMillis()
      );
    }

    return timeSlots;
  }

  protected roundSlotStart(dateTime: DateTime): DateTime | null {
    const dateTimeRounded = dateTime.set({ second: 0, millisecond: 0 });
    if (dateTimeRounded.minute === 0 || dateTimeRounded.minute === 30) {
      return dateTimeRounded;
    }

    if (dateTimeRounded.minute < 30) {
      return dateTimeRounded.set({ minute: 30 });
    }

    return dateTimeRounded.set({ minute: 0 }).plus({ hour: 1 });
  }
}
