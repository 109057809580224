import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { memo$, MemoizeStorageStrategy } from '@libs/utils/memoize';
import { catchError } from 'rxjs/operators';

export const I18N_CONFIG = new InjectionToken('I18N_CONFIG');

export interface I18nConfig {
  i18nProjectName: string;
  appName: string;
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, @Inject(I18N_CONFIG) private i18nConfig: I18nConfig) {}

  @memo$(MemoizeStorageStrategy(sessionStorage))
  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/project/${this.i18nConfig.i18nProjectName}/${lang}.json`).pipe(
      catchError(() =>
        // Fallback i18n file
        this.http.get<Translation>(`/assets/i18n/project/production/${lang}.json`)
      )
    );
  }
}
