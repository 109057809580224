<div class="pui-input-file-container pui-space_top-1">
  <!-- IDLE -->
  <pui-input-file
    (change)="onCoverImageInputChange($event)"
    accept="image/jpeg, image/jpg, image/png"
    [disabled]="disabled"
    class="uploaded-files-input"
    data-qa-id="cover-image-file-input">
    <div class="d-flex flex-row pui-space_bottom-2">
      <ng-content></ng-content>
    </div>

    <!-- DEFAULT -->
    <div class="pui-input-file__cover-layer" *ngIf="backgroundUrl && state === 'ready'">
      <pui-icon size="xl" svgIcon="pui:upload-image" class="pui-input-file__icon"></pui-icon>
      <div class="d-flex flex-column align-items-center" pui-typography="body-s">
        <div class="pui-input-file__title" i18n="@@uploadImage">Upload image</div>
        <div class="pui-input-file__info pui-space_top-1">
          <span i18n="@@fileRecommendedDimensions">Recommended dimensions</span>
          <span>&nbsp;</span>
          <span>{{ recommendedDimensionsText }}</span>
        </div>
      </div>
    </div>

    <!-- DROP -->
    <div ngProjectAs="pui-input-file-drop-here" class="pui-input-file__content">
      <pui-icon svgIcon="pui:upload-arrow" class="pui-input-file__icon"></pui-icon>
      <p pui-typography="body-s">
        <span class="pui-input-file__title" i18n="@@dropfilehere">Drop file here!</span>
      </p>
    </div>

    <!-- UPLOADED IMAGE -->
    <div class="pui-input-file__image" *ngIf="backgroundUrl && state === 'ready'">
      <img
        [src]="backgroundUrl"
        data-qa-id="cover-image-uploaded-image"
        alt="Your uploaded cover image"
        i18n-alt="@@yourUploadedCoverImage" />
    </div>
  </pui-input-file>

  <!-- LOADING -->
  <div class="pui-input-file_loading" *ngIf="state === 'loading'" data-qa-id="cover-image-loading-spinner">
    <div class="pui-input-file__content">
      <svg
        class="pui-input-file__icon pui-input-file__icon_spin"
        width="38"
        height="38"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 1.651C9.42 1.651 1.652 9.42 1.652 19S9.419 36.349 19 36.349c9.582 0 17.349-7.768 17.349-17.349a.754.754 0 1 1 1.508 0c0 10.415-8.442 18.857-18.857 18.857C8.586 37.857.143 29.415.143 19S8.586.143 19 .143A18.81 18.81 0 0 1 32.912 6.27.754.754 0 1 1 31.8 7.29 17.301 17.301 0 0 0 19 1.65Z" />
      </svg>
      <p pui-typography="body-s">
        <span class="pui-input-file__title" i18n="@@uploadingInProcess">Uploading...</span>
      </p>
    </div>
  </div>

  <!-- CLEAR -->
  <button
    *ngIf="!disableRemoveButton && !disabled && state === 'ready' && backgroundUrl"
    size="m"
    pui-icon-button
    appearance="flat"
    class="pui-input-file__clear"
    (click)="reset()">
    <pui-icon svgIcon="pui:trash" size="l"></pui-icon>
    Remove cover image
  </button>
</div>

<ng-template #customTemplate>
  <ng-conent></ng-conent>
</ng-template>
