// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IVariantSettings {
  aspectRatio?: number;
  quality?: number;
  resizeToWidth?: number;
  roundCropper?: boolean;
}

export class VariantSettings {
  aspectRatio = 16 / 9;
  quality = 100;
  resizeToWidth = 1200;
  roundCropper = false;

  constructor(settings: IVariantSettings) {
    if (settings.aspectRatio != null) {
      this.aspectRatio = settings.aspectRatio;
    }

    if (settings.quality != null) {
      this.quality = settings.quality;
    }

    if (settings.resizeToWidth != null) {
      this.resizeToWidth = settings.resizeToWidth;
    }

    if (settings.roundCropper != null) {
      this.roundCropper = settings.roundCropper;
    }
  }
}
