import { LimitBookingFrequency, LimitFutureBookings } from '@app/screens/guide/guide-sessions-templates/types';

export interface RestrictionsFormOption {
  value: string | number;
  label: string;
}

export enum PeriodType {
  ROLLING = 'ROLLING',
  RANGE = 'RANGE'
}

export enum BookingLimitsType {
  PER_DAY = 'PER_DAY',
  PER_WEEK = 'PER_WEEK',
  PER_MONTH = 'PER_MONTH',
  PER_YEAR = 'PER_YEAR'
}

export interface BookingLimit {
  bookingLimitsType: BookingLimitsType;
  bookingLimitsNum: number;
}

export enum DayType {
  BUSINESS = 'business',
  CALENDAR = 'calendar'
}

export enum TimeMeasureType {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days'
}

export interface LimitFutureBookingsForm {
  periodDays: number;
  periodType: PeriodType;
  periodDaysType: DayType | boolean;
  periodStartDate: Date | null;
  periodEndDate: Date | null;
}

export interface RestrictionsForm {
  limitBookingFrequency: LimitBookingFrequency | null;
  limitFutureBookings: LimitFutureBookings | null;
  beforeEventBuffer: number;
  afterEventBuffer: number;
  minimumBookingNotice: number;
  slotInterval: number;
  expirationPeriod?: string;
}

export enum RestrictionsFormFields {
  FORM_HEADER_TITLE = 'FORM_HEADER_TITLE',
  BEFORE_EVENT_BUFFER = 'BEFORE_EVENT_BUFFER',
  AFTER_EVENT_BUFFER = 'AFTER_EVENT_BUFFER',
  SLOT_INTERVAL = 'SLOT_INTERVAL',
  LIMIT_BOOKING_FREQUENCY = 'LIMIT_BOOKING_FREQUENCY',
  LIMIT_FUTURE_BOOKINGS = 'LIMIT_FUTURE_BOOKINGS',
  MINIMUM_BOOKING_NOTICE = 'MINIMUM_BOOKING_NOTICE',
  EXPIRATION_PERIOD = 'EXPIRATION_PERIOD'
}
