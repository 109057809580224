import { formatDate } from '@angular/common';
import { DateTime } from 'luxon';

function toDateTime(value: string | number | Date): DateTime {
  let dateTime = null;

  if (value instanceof Date && !isNaN(value.valueOf())) {
    dateTime = DateTime.fromJSDate(value);
  } else if (typeof value === 'number' && !isNaN(value)) {
    dateTime = DateTime.fromMillis(value);
  } else if (typeof value == 'string') {
    dateTime = DateTime.fromISO(value.trim());
  }

  // @ts-expect-error TS2322
  return dateTime;
}

export function formatDateAdapter(
  value: string | number | Date,
  format: string,
  locale: string,
  timezone?: string
): string {
  const formatterTimezone = toDateTime(value)
    .setZone(timezone || 'local')
    .toFormat('ZZZ');

  return formatDate(value, format, locale, formatterTimezone);
}
