export enum Languages {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  en = 'English',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ru = 'Russian',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  fr = 'French',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ar = 'Arabic',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  it = 'Italian',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  es = 'Spanish',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  id = 'Indonesian'
}
