import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunityLayoutModule } from '@app/layouts/community-layout/community-layout.module';
import { PrimaryLayoutModule } from '@app/layouts/primary-layout/primary-layout.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AuthGuideLayoutModule } from './auth-guide-layout/auth-guide-layout.module';
import { AuthLayoutModule } from './auth-layout/auth-layout.module';
import { EmptyLayoutModule } from './empty-layout/empty-layout.module';
import { GiftCouponsLayoutModule } from './gift-coupons-layout/gift-coupons-layout.module';
import { LandingBusinessLayoutModule } from './landing-business-layout/landing-business-layout.module';
import { LandingLayoutModule } from './landing-layout/landing-layout.module';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { SimpleLayoutModule } from './simple-layout/simple-layout.module';
import { MembershipLayoutModule } from './membership-layout/membership-layout.module';
import { MarketplaceLayoutModule } from './marketplace-layout/marketplace-layout.module';
import { AltPrimaryLayoutModule } from './alt-primary-layout/alt-primary-layout.module';

@NgModule({
  imports: [
    AppRoutingModule,
    AuthGuideLayoutModule,
    AuthLayoutModule,
    CommonModule,
    EmptyLayoutModule,
    GiftCouponsLayoutModule,
    LandingBusinessLayoutModule,
    LandingLayoutModule,
    MainLayoutModule,
    SimpleLayoutModule,
    CommunityLayoutModule,
    MembershipLayoutModule,
    MarketplaceLayoutModule,
    PrimaryLayoutModule,
    AltPrimaryLayoutModule
  ]
})
export class LayoutsModule {}
