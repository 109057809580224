import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CheckboxModule } from '../../checkbox/checkbox.module';
import { UiKitHelpersModule } from '../_common/helpers';
import { UiChipListModule } from '../ui-chips-list';
import { UiAutocompleteModule } from '../ui-autocomplete';
import { UiIconModule } from '../ui-icon';
import { UiSelectorMultiple } from './selector';

@NgModule({
  declarations: [UiSelectorMultiple],
  exports: [UiSelectorMultiple],
  imports: [
    CommonModule,
    UiChipListModule,
    UiAutocompleteModule,
    MatOptionModule,
    MatAutocompleteModule,
    CheckboxModule,
    UiIconModule,
    UiKitHelpersModule
  ]
})
export class UiSelectorMultipleModule {}
