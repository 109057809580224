<div [formGroup]="recurrenceFormControlService" class="recurrence">
  <div class="recurrence__row">
    <div class="d-flex align-items-center pui-space_right-1">
      <label
        class="recurrence__item recurrence__switcher"
        pui-input-checkbox
        [formControl]="recurrenceFormControlService.recurrenceSwitcherControl">
        <span class="pui-space_right-1" pui-typography="body-s">Repeat</span>
      </label>
      <ng-container *ngIf="recurrenceFormControlService.recurrenceSwitcherControl.value === true">
        <span class="pui-space_right-1" *ngIf="isBlockOffTime" pui-typography="body-s">for</span>
        <pui-input-field class="pui-space_right-2">
          <input
            class="recurrence__item recurrence__input recurrence__input_count"
            type="number"
            [class.recurrence__item_invalid]="recurrenceFormControlService.get('count').invalid"
            formControlName="count"
            puiMinMaxNumber
            [maxNumber]="MAX_COUNT"
            [minNumber]="1" />
        </pui-input-field>
        <!--TODO Missed Recurrence property-->
        <span *ngIf="count" pui-typography="body-s" class="recurrence__item"
          >out of {{ count || recurrenceFormControlService.value?.count }} sessions</span
        >
        <span *ngIf="isBlockOffTime" pui-typography="body-s" class="recurrence__item">times every</span>
      </ng-container>
    </div>
    <div *ngIf="recurrenceFormControlService.recurrenceSwitcherControl.value === true">
      <span *ngIf="!isBlockOffTime" pui-typography="body-s" class="recurrence__item pui-space_right-1">every</span>
      <pui-input-field class="pui-space_right-2">
        <input
          class="recurrence__item recurrence__input recurrence__input_interval"
          [class.recurrence__item_invalid]="recurrenceFormControlService.get('interval').invalid"
          formControlName="interval"
          type="number" />
      </pui-input-field>
      <select class="recurrence__item recurrence__input recurrence__input_freq" formControlName="freq">
        <option *ngFor="let option of recurrenceFormControlService.recurrenceFrequencies" [value]="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</div>
