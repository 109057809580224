import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ICroppedImage } from '@app/shared/interfaces/image';
import { dataURIToBlob } from '@app/shared/utils/data-uri-to-blob';
import { UploadAvatarModalComponent } from '@app/shared/components/upload-avatar-modal/upload-avatar-modal.component';
import { ErrorService } from '@app/core';
import { QuizService } from '../../services/quiz.service';
import { SurveyFormService } from '../../services/survey-form.service';

@Component({
  selector: 'app-quiz-basic-info',
  templateUrl: './quiz-basic-info.component.html',
  styleUrls: ['./quiz-basic-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizBasicInfoComponent implements OnInit, OnDestroy {
  readonly form!: FormGroup;
  readonly formUpload: FormGroup = this.formBuilder.group({
    upload: ['', []]
  });

  uploadedAvatar!: string;
  uploadedFile!: File;

  readonly avatars = this.surveyFormService.avatars;
  selectedAvatar = 0;

  private isFetching = false;
  private readonly somethingWentWrongSTR = `Something went wrong`;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly modal: NgbModal,
    private readonly error: ErrorService,
    private readonly chatBotService: QuizService,
    private readonly notificationsService: NotificationsService,
    private readonly formBuilder: FormBuilder,
    private readonly surveyFormService: SurveyFormService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.form = this.surveyFormService.quizForm.get('basic') as FormGroup;
  }

  ngOnInit(): void {
    if (!this.form.controls.avatar?.value) {
      this.selectAvatar(0);
    } else {
      this.selectedAvatar = this.avatars.indexOf(this.form.controls.avatar?.value);
    }

    this.form.statusChanges
      .pipe(
        tap(() => this.changeDetector.markForCheck()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectAvatar(i: number): void {
    this.selectedAvatar = i;

    this.form.controls.avatar.setValue(i === -1 ? this.uploadedAvatar : this.avatars[i]);
  }

  avatarChangeEvent(event: Event): void {
    const inputEl = event.target as HTMLInputElement & { files: FileList };
    const file = inputEl.files[0];

    if (!file || this.isFetching) {
      return;
    }
    if (/(\.jpg|\.jpeg|\.png)$/i.test(file.name)) {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const error = () => {
        const content = `Avatar file format JPG and PNG. 'Minimum width 190 pixels. Photo size not more than 2MB.`;
        this.notificationsService.error(this.somethingWentWrongSTR, content);
      };
      if (file.size / (1024 * 1024) > 2) {
        const content = `Photo size not more than 2MB.`;
        this.notificationsService.error(this.somethingWentWrongSTR, content);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        // @ts-expect-error TS2531
        img.src = reader.result.toString();
        img.onload = () => {
          if (img.width < 190) {
            return error();
          }
          const { componentInstance, result } = this.modal.open(UploadAvatarModalComponent, {
            size: 'lg'
          });
          componentInstance.event = event;
          result
            .then((croppedImage: { avatar: ICroppedImage }) => {
              this.uploadedAvatar = croppedImage.avatar.imageDataUri;
              const croppedFullBlob = dataURIToBlob(croppedImage.avatar.imageDataUri);
              this.uploadedFile = new File([croppedFullBlob], croppedImage.avatar.name);
              this.selectAvatar(-1);
              this.submitAvatar();
            })
            .catch(() => {})
            .finally(() => {
              inputEl.value = '';
            });
        };
        img.onerror = () => {
          error();
        };
      };
      reader.readAsDataURL(file);
    } else {
      const title = `Invalid file format`;
      const content = `Allowed: .jpg, .jpeg, .png`;
      this.notificationsService.create(title, content, NotificationType.Error);
    }
  }

  private submitAvatar(): void {
    this.isFetching = true;
    this.chatBotService
      .saveAvatar(this.uploadedFile)
      .pipe(
        catchError(err => {
          const errors = this.error.parseErrors(err);
          for (const error of errors) {
            const title = `Something wrong`;
            this.notificationsService.create(title, error, NotificationType.Error);
          }
          return throwError(err);
        }),
        finalize(() => (this.isFetching = false))
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(data => {
        if (data.photo) {
          const title = `Saved successfully`;
          const content = `Click to close...`;
          this.notificationsService.create(title, content, NotificationType.Success);
          this.uploadedAvatar = data.photo;
          this.selectAvatar(-1);
        }
      });
  }
}
