<header
  class="header"
  [ngClass]="{ 'show-menu': showMenu }"
  [ngStyle]="{ backgroundColor: scrolled ? headerBackground : '' }">
  <div class="container">
    <app-btn-menu (btnMenuClick)="toggleMenu()"></app-btn-menu>

    <a
      class="header-logo-link"
      *ngIf="logoUrl?.type === URL_TYPE.RELATIVE; else absolute"
      [routerLink]="'/' + logoUrl?.value">
      <span class="header-logo" [ngStyle]="{ 'background-image': 'url(' + whiteLogo + ')' }"></span>
    </a>

    <nav class="menu" [ngStyle]="{ backgroundColor: menuBackgroundColor }">
      <app-link-menu></app-link-menu>

      <ul class="menu-2" *ngIf="!authService.isAuthorized">
        <li><a (click)="openSignIn($event)" i18n="@@signIn">Sign in</a></li>
      </ul>
    </nav>

    <ng-container *ngIf="authService.isAuthorized">
      <div class="user-block">
        <app-user-menu [commonHeader]="true"></app-user-menu>
      </div>
    </ng-container>
  </div>
</header>

<ng-template #absolute>
  <a class="header-logo-link" target="_blank" href="{{ logoUrl?.value }}" rel="noopener">
    <span class="header-logo" [ngStyle]="{ 'background-image': 'url(' + whiteLogo + ')' }"></span>
  </a>
</ng-template>
