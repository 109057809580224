<ng-container>
  <button type="button" (click)="joinSession()" pui-button appearance="secondary" size="xs">
    <div class="d-flex">
      <app-guide-service-location-icon
        class="platform-icon pui-space_right-2"
        [connectionType]="session?.connectionType"></app-guide-service-location-icon>
      <span class="join-session" i18n="@@joinSession">Join session</span>
      <span class="join" i18n="@@join">Join</span>
    </div>
  </button>
</ng-container>
