import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SessionTemplateHost } from '@app/screens/guide/guide-sessions-templates/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';

@Component({
  selector: 'app-selected-hosts',
  templateUrl: './selected-hosts.component.html',
  styleUrls: ['./selected-hosts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SelectedHostsComponent {
  @Input()
  hosts!: number[];

  @Input()
  canRemove!: boolean;

  @Input()
  sessionConnectionType!: SessionConnectionTypes;

  @Output()
  remove: EventEmitter<SessionTemplateHost> = new EventEmitter<SessionTemplateHost>();
}
