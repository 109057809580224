import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IChatMessage, ChatMessageStatuses, IAttachment } from '@app/core/chat/types';
import { IUser } from '@app/shared/interfaces/user';

@Component({
  selector: 'app-chat-file',
  templateUrl: './chat-file.component.html',
  styleUrls: ['../chat-message/chat-message.component.scss', './chat-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFileComponent {
  readonly ChatMessageStatuses = ChatMessageStatuses;

  @Input()
  // @ts-expect-error TS2564
  message: IChatMessage;

  @Input()
  // @ts-expect-error TS2564
  sender: IUser;

  constructor(private sanitizer: DomSanitizer) {}

  get isImage(): boolean {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return !!this.message && /(\.jpg|\.jpeg|\.png)$/i.test((<IAttachment>this.message.file).url);
  }

  get isVideo(): boolean {
    return !!this.message && this.message.file?.name === 'video';
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
