import { DateTime } from 'luxon';

import { getAbbrByTimezone } from '@app/shared/utils/date';

import { DateTimeRangeResolverOptions, DateTimeResolverOptions, Template } from '../model/interfaces';
import { clearDateTimeString, convertToDateTime, resolve } from '../utils';

export default function platformDateTimeRange(template: Template, params: DateTimeRangeResolverOptions): string {
  // @ts-expect-error TS2345
  const start = convertToDateTime(Array.isArray(params.value) && params.value[0]);
  // @ts-expect-error TS2345
  let end = convertToDateTime(Array.isArray(params.value) && params.value[1]);
  const durationInMinutes = (params.templateOptions && params.templateOptions.durationInMinutes) || 0;
  const isTimezoneVisible = params.templateOptions && params.templateOptions.showTimezone;
  const timeZone = (params.templateOptions && params.templateOptions.timezone) || DateTime.local().zoneName;

  const isRtl =
    (params.templateOptions &&
      params.templateOptions.localeParams &&
      params.templateOptions.localeParams.direction === 'rtl') ||
    false;

  if (durationInMinutes) {
    end = start.plus({ minutes: durationInMinutes }).setZone(timeZone);
  }

  const noon = 12;

  if (!start || !end) {
    return '';
  }

  const isSameDay = start.year === end.year && start.month === end.month && start.day === end.day;
  const isSameYear = start.year === end.year;
  const isSameDayPart = (start.hour >= noon && end.hour >= noon) || (start.hour < noon && end.hour < noon);

  const startParams: DateTimeResolverOptions = {
    value: start,
    templateOptions: {
      // @ts-expect-error TS2532
      localeParams: params.templateOptions.localeParams,
      hideAmPm: isSameDay && isSameDayPart,
      hideLastYear: start.year === end.year,
      timezone: timeZone
    }
  };

  const startFormatted = clearDateTimeString(resolve(template, 'toDateTime', startParams));

  const endParams: DateTimeResolverOptions = {
    value: end,
    templateOptions: {
      // @ts-expect-error TS2532
      localeParams: params.templateOptions.localeParams,
      hideWeekDay: isSameDay,
      hideDay: isSameDay,
      hideMonth: isSameDay,
      hideLastYear: isSameYear,
      timezone: timeZone
    }
  };

  const endFormatted = clearDateTimeString(resolve('TIME', 'toDateTime', endParams));

  let timezoneFormatted = '';
  if (isTimezoneVisible) {
    timezoneFormatted = ` (${getAbbrByTimezone(timeZone)})`;
  }

  return !isRtl
    ? `${startFormatted}-${endFormatted}${timezoneFormatted}`
    : `${endFormatted}-${startFormatted}${timezoneFormatted}`;
}
