import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { ProgramModule } from '@app/modules/program/program.module';
import { ActionDropdownModule } from '@app/modules/ui-kit/action-dropdown/action-dropdown.module';
import { GuideServicesAndEventsModule } from '@app/screens/guide/guide-services-and-events/guide-services-and-events.module';
import { MarketplaceFiltersModule } from '@app/modules/marketplace-filters/marketplace-filters.module';
import { PoweredByLinkModule } from '@app/modules/powered-by-link/powered-by-link.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { ServicesModule } from '@app/modules/services/services.module';
import { UiChipListModule } from '@app/modules/ui-kit/_base/ui-chips-list';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import { ProgramLandingTemplateComponent } from '@app/screens/programs/components/program-landing-template/program-landing-template.component';
import { PricingModule } from '@app/modules/pricing/pricing.module';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';
import { NoProgramsFoundComponent } from './components/no-programs-found/no-programs-found.component';
import { ProgramItemComponent } from './components/program-item/program-item.component';
import { ProgramLandingComponent } from './components/program-landing/program-landing.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { PublicProgramKeyPointsPipe } from './pipes/public-program-key-points.pipe';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    ActionDropdownModule,
    CommonModule,
    GuideServicesAndEventsModule,
    MarketplaceFiltersModule,
    NgbModule,
    PoweredByLinkModule,
    ProgramModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    PlatformStyleModule,
    ServicesModule,
    UiChipListModule,
    UiIconModule,
    PricingModule,
    CurrentPaymentModule,
    TranslocoRootModule
  ],
  exports: [ProgramItemComponent, ProgramLandingTemplateComponent, NoProgramsFoundComponent],
  declarations: [
    ProgramItemComponent,
    ProgramLandingComponent,
    ProgramLandingTemplateComponent,
    ProgramsComponent,
    NoProgramsFoundComponent,
    PublicProgramKeyPointsPipe
  ]
})
export class ProgramsModule {}
