import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { SearchStringService } from '@app/core/public/search-string.service';
import { UrlTypes } from '@app/shared/enums/url-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ILogoLink, prepareLogoUrlData } from '@app/shared/utils/url';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspace } from '@app/modules/workspaces/types';
import { UserRoleUtility } from '@app/core/auth/services';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-app-header'
  }
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly GUIDES_LIST_ROUTE = `/${environment.guidesRoute}`;

  private readonly onDestroy$ = new Subject<void>();

  URL_TYPE = UrlTypes;

  burgerDisplay = false;

  enableMenu = environment.enableLinksInMainHeader;

  // @ts-expect-error TS2564
  logoUrl: ILogoLink;

  mainLogo = '';

  whiteLogo = '';

  searchControl = new FormControl('');

  showWhiteLogoOnCoachesPage = false;

  @HostBinding('style.background-color')
  // @ts-expect-error TS2564
  backgroundColor: string;

  get canShowBookLink$(): Observable<boolean> {
    return this._workspacesService.activeWorkspace$.pipe(
      map(
        (workspace: IWorkspace) =>
          !!workspace && this.authService.isAuthorized && UserRoleUtility.isGuide(this.authService.user)
      )
    );
  }

  get canShowGuidesSearch(): boolean {
    return (
      (!this.authService.isAuthorized || this.authService.user.RoleId === UserRoles.CLIENT) &&
      this._router.url.startsWith(this.GUIDES_LIST_ROUTE)
    );
  }

  get canShowUserWallet(): boolean {
    return this.authService.isAuthorized && this.authService.user.RoleId === UserRoles.CLIENT;
  }

  get canShowLinkMenu(): boolean {
    return (
      this.authService.isAuthorized && ![UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(this.authService.user.RoleId)
    );
  }

  constructor(
    readonly authService: AuthService,
    private readonly _router: Router,
    private readonly _searchStringService: SearchStringService,
    private readonly _brandingService: BrandingService,
    private readonly _workspacesService: WorkspacesService
  ) {}

  ngOnInit(): void {
    this._searchStringService.routeSearchValue$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(routeSearchValue => this.searchControl.setValue(routeSearchValue, { emitEvent: false }));

    this.searchControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.onDestroy$))
      .subscribe(searchInputValue => this._searchStringService.updateSearchInputValue(searchInputValue));

    this._brandingService.mainLogo$.pipe(takeUntil(this.onDestroy$)).subscribe(({ logo }) => (this.mainLogo = logo));
    this._brandingService.whiteLogo$.pipe(takeUntil(this.onDestroy$)).subscribe(({ logo }) => (this.whiteLogo = logo));

    this._brandingService.globalConfig$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ urlInLogo, showWhiteLogoOnCoachesPage }) => {
        this.showWhiteLogoOnCoachesPage = showWhiteLogoOnCoachesPage;
        this.logoUrl = prepareLogoUrlData(urlInLogo);
      });

    this._brandingService.menuConfig$
      .pipe(takeUntil(this.onDestroy$))
      // @ts-expect-error TS2322
      .subscribe(({ headerBackground }) => (this.backgroundColor = headerBackground));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  burgerClickOutside(): void {
    this.burgerDisplay = false;
  }

  burgerToggle(): void {
    this.burgerDisplay = !this.burgerDisplay;
  }

  searchQueryEntered(): void {
    this._router.navigate([this.GUIDES_LIST_ROUTE, this.searchControl.value]);
  }
}
