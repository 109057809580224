<h2 class="section-title" i18n="@@myForms">My Forms</h2>

<div class="row">
  <div class="col-xs-12 col-md-6 col-lg-4 quiz-wrapper" *ngFor="let quiz of quizzes">
    <app-quiz-item class="quiz" [class.ready-to-fill]="isQuizReadyToFill(quiz)" [quiz]="quiz"></app-quiz-item>
  </div>
</div>

<section class="no-quizzes" *ngIf="!quizzes || !quizzes.length">
  <div class="no-quizzes-img"></div>
  <div class="main-note" i18n="@@noFormsYet">Forms will be here soon</div>
</section>
