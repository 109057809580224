import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'formly-field-mat-select',
  template: `
    <ng-template #selectAll let-selectOptions="selectOptions">
      <mat-option (click)="toggleSelectAll(selectOptions)">
        <mat-pseudo-checkbox
          class="mat-option-pseudo-checkbox"
          [state]="getSelectAllState(selectOptions)"></mat-pseudo-checkbox>
        {{ to.selectAllOption }}
      </mat-option>
    </ng-template>
    <mat-select
      [id]="id"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [tabIndex]="to.tabindex"
      [required]="to.required"
      [compareWith]="to.compareWith"
      [multiple]="to.multiple"
      (selectionChange)="change($event)"
      [errorStateMatcher]="errorStateMatcher"
      [aria-labelledby]="_getAriaLabelledby()"
      [disableOptionCentering]="to.disableOptionCentering"
      [typeaheadDebounceInterval]="to.typeaheadDebounceInterval">
      <ng-container *ngIf="to.options | formlySelectOptions: field | async as selectOptions">
        <ng-container
          *ngIf="to.multiple && to.selectAllOption"
          [ngTemplateOutlet]="selectAll"
          [ngTemplateOutletContext]="{ selectOptions: selectOptions }"></ng-container>
        <ng-container *ngFor="let item of selectOptions">
          <mat-optgroup *ngIf="item.group" [label]="item.label">
            <mat-option *ngFor="let child of item.group" [value]="child.value" [disabled]="child.disabled">
              {{ child.label }}
            </mat-option>
          </mat-optgroup>
          <mat-option *ngIf="!item.group" [value]="item.value" [disabled]="item.disabled">{{ item.label }}</mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FormlySelectType extends FieldType {
  defaultOptions = {
    templateOptions: {
      options: [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selectAllValue!: { options: any; value: any[] };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  getSelectAllState(options: any[]) {
    if (this.empty || this.value.length === 0) {
      return '';
    }

    return this.value.length !== this.getSelectAllValue(options).length ? 'indeterminate' : 'checked';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  toggleSelectAll(options: any[]) {
    const selectAllValue = this.getSelectAllValue(options);
    this.formControl.setValue(!this.value || this.value.length !== selectAllValue.length ? selectAllValue : []);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  change($event: MatSelectChange) {
    this.to.change?.(this.field, $event);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  _getAriaLabelledby() {
    if (this.to.attributes?.['aria-labelledby']) {
      return this.to.attributes['aria-labelledby'];
    }

    return this.formField?._labelId;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private getSelectAllValue(options: any[]) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const flatOptions: any[] = [];
      // eslint-disable-next-line id-length
      options.forEach(o => (o.group ? flatOptions.push(...o.group) : flatOptions.push(o)));

      this.selectAllValue = {
        options,
        // eslint-disable-next-line id-length
        value: flatOptions.filter(o => !o.disabled).map(o => o.value)
      };
    }

    return this.selectAllValue.value;
  }
}
