import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { puiDefaultProp } from '@awarenow/profi-ui-core';
import { SessionIcon } from '@libs/business/cdk/session';

@Component({
  selector: 'app-client-session-icon',
  templateUrl: './client-session-icon.component.html',
  styleUrls: ['./client-session-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionIconComponent {
  @Input()
  @puiDefaultProp()
  icon: SessionIcon | null = null;
}
