/**
 * Contrast text color palettes.
 */
const CONTRAST_TEXT_COLOR_PALETTE: { [key: string]: string } = {
  'dark-primary-text': '#32495b',
  'dark-secondary-text': '#5b6d7c',
  'dark-disabled-text': '#adb6bd',
  'dark-dividers': '#c2c8ce',
  'dark-focused': '#d6dbde',
  'light-primary-text': '#ffffff',
  'light-secondary-text': '#ffffff',
  'light-disabled-text': '#ffffff',
  'light-dividers': '#ffffff',
  'light-focused': '#ffffff',
  'light-icon': '#8196a7'
};

export { CONTRAST_TEXT_COLOR_PALETTE };
