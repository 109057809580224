import { UserRoles } from '@app/shared/enums/user-roles';
import { IVisibleFor } from '@app/screens/admin/types';

export const filterByRole = <TVisibleFor extends IVisibleFor = IVisibleFor>(
  visibleFor: TVisibleFor,
  role: UserRoles
): UserRoles[] => {
  const roles = {
    [UserRoles.GUIDE]: 'guide',
    [UserRoles.CLIENT]: 'client',
    [UserRoles.ADMIN]: 'admin'
  };

  // @ts-expect-error TS2322
  return [visibleFor[roles[role]] ? role : null].filter(Boolean);
};
