import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PuiButtonModule,
  PuiIconModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { LimitBookingFrequencyComponent } from './limit-booking-frequency.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PuiLetModule,
    PuiInputModule,
    PuiButtonModule,
    PuiIconModule,
    PuiLinkModule,
    PuiSelectModule,
    PuiToggleModule,
    PuiTypographyModule
  ],
  declarations: [LimitBookingFrequencyComponent],
  exports: [LimitBookingFrequencyComponent]
})
export class LimitBookingFrequencyModule {}
