<div class="send-review">
  <app-button className="btn-primary" *ngIf="reviewReadySessionId" (click)="emitLeaveReview()">
    <ng-container i18n="@@submitYourReview">Submit your review</ng-container>
  </app-button>
</div>
<ng-container *ngIf="reviews && reviews.length">
  <ul class="reviews-list" *ngIf="!mobile">
    <li *ngFor="let review of reviews; index as i" class="reviews-list-item">
      <!--      <app-user-photo [photoUrl]="review.client?.photo"></app-user-photo>-->
      <pui-avatar [avatarUrl]="review.client?.photo" [text]="review.client.name" class="app-user-photo"></pui-avatar>
      <div>
        <div class="review-author">
          <p class="review-author-name">{{ review.client.name }}</p>
          <star-rating [rating]="review.clientMark" readOnly="true"></star-rating>
        </div>
        <div class="review-date">{{ review.createdAt | date: 'yyyy, MMM dd' }}</div>
        <div class="review-text-container">
          <div class="review-text">
            {{ review.clientComment | cutString: (openReviews[i] ? null : MINIMIZED_REVIEW_LENGTH) }}
          </div>
          <button
            *ngIf="
              review.clientComment &&
              review.clientComment.length &&
              review.clientComment.length > MINIMIZED_REVIEW_LENGTH
            "
            type="button"
            class="maximize-text-button"
            (click)="toggleReviewLength(i)">
            <ng-template [ngIf]="openReviews[i]" [ngIfElse]="showMore" i18n="@@showLess">Show less</ng-template>
            <ng-template #showMore i18n="@@showMore">Show more</ng-template>
          </button>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="mobile" class="reviews-carousel">
    <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="reviews" (onMove)="checkIfNeedMore($event)">
      <ngu-tile *nguCarouselDef="let review; let i = index">
        <div>
          <div class="review-author-mobile">
            <div>
              <!--              <app-user-photo [photoUrl]="review.client?.photo"></app-user-photo>-->
              <pui-avatar
                [avatarUrl]="review.client?.photo"
                [text]="review.client?.name"
                class="app-user-photo"></pui-avatar>
            </div>
            <div class="review-author-mobile-info">
              <p class="review-author-name">{{ review.client.name }}</p>
              <star-rating [rating]="review.clientMark" readOnly="true"></star-rating>
            </div>
          </div>
          <div class="review-text-container">
            <div class="review-text">
              {{ review.clientComment | cutString: (openReviews[i] ? null : MINIMIZED_REVIEW_LENGTH) }}
            </div>
            <button
              *ngIf="
                review.clientComment &&
                review.clientComment.length &&
                review.clientComment.length > MINIMIZED_REVIEW_LENGTH
              "
              type="button"
              class="maximize-text-button"
              (click)="toggleReviewLength(i)">
              <ng-template [ngIf]="openReviews[i]" [ngIfElse]="showMore" i18n="@@showLess">Show less</ng-template>
              <ng-template #showMore i18n="@@showMore">Show more</ng-template>
            </button>
          </div>
        </div>
      </ngu-tile>
      <!-- eslint-disable -->

      <ul class="reviewsPoints" *ngIf="reviews?.length" NguCarouselPoint>
        <li
          *ngFor="let i of myCarousel.pointNumbers; let j = index"
          [class.active]="i == myCarousel.activePoint"
          [class.pre-min]="
            (i == 0 && myCarousel.activePoint >= myCarousel.pointNumbers.length / 3) ||
            (i == 1 && myCarousel.activePoint >= myCarousel.pointNumbers.length / 2) ||
            (i == myCarousel.pointNumbers.length - 2 && myCarousel.activePoint <= myCarousel.pointNumbers.length / 2) ||
            (i == myCarousel.pointNumbers.length - 1 &&
              myCarousel.activePoint <= (myCarousel.pointNumbers.length / 3) * 2)
          "
          [class.min]="
            (i == 0 && myCarousel.activePoint >= myCarousel.pointNumbers.length / 2) ||
            (i == myCarousel.pointNumbers.length - 1 && myCarousel.activePoint <= myCarousel.pointNumbers.length / 2)
          "
          (click)="myCarousel.moveTo(i)"></li>
      </ul>

      <!-- eslint-enable -->
    </ngu-carousel>
  </div>
</ng-container>
