import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByNameOrEmail'
})
export class FilterByNameOrEmail implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(clients: any, searchText: string): any {
    if (!clients) {
      return [];
    }

    const searchQuery = searchText ? searchText.trim().toLowerCase() : null;

    return searchQuery
      ? // @ts-expect-error TS7006
        clients.filter(client =>
          [
            (client.name || '').toLowerCase(),
            (client.email || '').toLowerCase(),
            (client.contacts?.email || '').toLowerCase()
          ].some(item => item.includes(searchQuery))
        )
      : clients;
  }
}
