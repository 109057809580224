import { Routes } from '@angular/router';

import { SurveysComponent } from './components/surveys/surveys.component';

export const routes: Routes = [
  {
    path: '',
    component: SurveysComponent
  }
];
