<div class="banner-content" [ngStyle]="{ backgroundImage: content.bannerBackgroundUrl }">
  <div class="title">
    {{ content.bannerTitle | cutString: 40:'' }}
  </div>

  <div class="body">
    {{ content.bannerText | cutString: 110 }}
  </div>

  <div class="footer">
    <app-button class="ads-btn">
      <a href="{{ content.bannerButtonUrl }}">{{ content.bannerButtonText | cutString: 30:'' }}</a>
    </app-button>
  </div>
</div>
