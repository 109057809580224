<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.package?.user?.photo"
    [text]="
      (notification.details?.package?.user?.firstName + ' ' + notification.details?.package?.user?.lastName).trim()
    "></pui-avatar>
  <dt class="notification-title">
    <span
      class="notification-name"
      [ngClass]="{ 'client-name': notification.userRole === UserRoles.GUIDE }"
      (click)="notification.userRole === UserRoles.GUIDE && showClientDetails(notification.details?.package?.user?.id)">
      {{ notification.details?.package?.user?.firstName }} {{ notification.details?.package?.user?.lastName }}
    </span>
    <span class="notification-date">
      {{ notification.dateCreated | notificationDateFormatter }}
    </span>
  </dt>
  <dd>
    <span class="description-text">
      {{ notification.description }}

      <app-button className="btn-link" routerLink="/packages/{{ notification.details?.packageId }}">
        {{ notification.details?.package?.title }}
      </app-button>
    </span>
  </dd>
</dl>
