import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import config from '@app/core/config/config';
import { User, IUser } from '@app/shared/interfaces/user';

@Injectable()
export class ProgramCoauthorsService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _coauthors$ = new BehaviorSubject({});
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _runningRequest$: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get coauthors$(): Observable<any> {
    return this._coauthors$;
  }

  constructor(private _http: HttpClient) {}

  ngOnDestroy(): void {
    this._coauthors$.complete();
  }

  refresh(index: number, search: string, exclude: number[] = []): void {
    if (!search) {
      this.reset();
      return;
    }

    this.cancelRequestIfExists();

    const params = new HttpParams({
      fromObject: { search, 'exclude[]': exclude.map(id => '' + id) }
    });
    this._runningRequest$ = this._http
      .get<{ guides: IUser[] }>(`${config.apiPath}/public/profile/short-info`, { params })
      .pipe(map(({ guides }) => guides.map(guide => new User(guide.id, guide.firstName, guide.lastName, guide.photo))))
      .subscribe(guides => this._coauthors$.next({ [index]: guides }));
  }

  reset(): void {
    this.cancelRequestIfExists();
    this._coauthors$.next({});
  }

  private cancelRequestIfExists(): void {
    if (this._runningRequest$) {
      this._runningRequest$.unsubscribe();
    }
  }
}
