import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { combineLatest } from 'rxjs';
import { BrandingService } from '@app/core/branding/branding.service';

@Component({
  selector: 'app-sign-up-as-profi',
  template: `
    <div class="menu-wrapper">
      <ul class="workspace-actions-list">
        <li *ngIf="canSignUpAsPractitioner$ | async" class="workspace-actions-item">
          <button pui-link (click)="createAlternativeAccount()">
            <pui-icon svgIcon="pui:plus-circle" size="s"></pui-icon>
            <span class="pui-space_left-1">
              <ng-container transloco="ignUpAsCoach">Sign up as practitioner</ng-container>
            </span>
          </button>
        </li>
        <li class="workspace-actions-item">
          <button pui-link (click)="signOut()">
            <pui-icon svgIcon="pui:log-out" size="s"></pui-icon>
            <span class="pui-space_left-1">
              <ng-container i18n="@@logoutClient">Log out</ng-container>
            </span>
          </button>
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['./sign-up-as-profi.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'workspace-menu-container py-sm-1 px-sm-1'
  },
  providers: [UiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpAsProfiComponent {
  canSignUpAsPractitioner$ = combineLatest([this.brandingService.globalConfig$, this.authService.onAuth()]).pipe(
    map(([config, user]) => {
      return !config.hideSignUpAs && !user?.hasAlternativeProfile;
    })
  );

  constructor(
    private readonly authService: AuthService,
    private readonly destroy$: UiDestroyService,
    private readonly brandingService: BrandingService
  ) {}

  createAlternativeAccount(): void {
    this.authService.signupAlternativeAccount().pipe(takeUntil(this.destroy$)).subscribe();
  }

  signOut(): void {
    this.authService.signout();
  }
}
