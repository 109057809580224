<ng-container *ngIf="view$ | async as view">
  <mat-sidenav-container [style.height]="'100vh'" autosize (backdropClick)="toggle()" class="container">
    <mat-sidenav [mode]="view.sidenav.mode" [opened]="view.sidenav.opened" disableClose>
      <app-sidenav></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-container *ngIf="!view.sidenav.isForceHideHeader">
        <app-primary-layout-header *MobileAOrSmallTablet (toggle-sidenav)="toggle()"></app-primary-layout-header>
      </ng-container>
      <app-current-session-alert></app-current-session-alert>
      <app-alerts-provider></app-alerts-provider>
      <div class="content" puiScrollable cdkScrollable>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
