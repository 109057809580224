import { IsoDayTime } from '@app/screens/guide/guide-sessions/types';

export const findIndicesPair = <T>(
  arr: T[],
  predicate1: (a: T) => boolean,
  predicate2: (a: T) => boolean
): [number, number] => {
  const indices = [];
  for (let index = 0, len = arr.length; index < len; index++) {
    const item = arr[index];
    if (predicate1(item)) {
      indices[0] = index;
    }

    if (predicate2(item)) {
      indices[1] = index;
    }

    if (indices.length === 2) {
      return indices as [number, number];
    }
  }

  return [-1, -1];
};

export const setEndHours = (startHour: string, endHour: string, fullDayHours: IsoDayTime[]): IsoDayTime[] => {
  const [startHourIndex] = findIndicesPair(
    fullDayHours,
    hour => hour.iso === startHour,
    hour => hour.iso === endHour
  );

  return fullDayHours.slice(startHourIndex + 1);
};

export const convertToNumber = (value: string): number => {
  return value ? parseInt(value.replace(':', '')) : 0;
};
