<div class="service-details">
  <div class="service-details__item">
    <div class="service-details__icon">
      <pui-icon svgIcon="pui:clock" size="s"></pui-icon>
    </div>

    <div class="service-details__label">
      <span class="duration-value">{{ moduleService?.duration }}</span>
      <span i18n="@@sessionDurationUnit">&nbsp;min</span>
    </div>
  </div>
</div>

<div
  class="service-description"
  *ngIf="moduleService.description"
  [innerHTML]="moduleService?.description | safeHtml"></div>
