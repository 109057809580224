import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IGuideService, IServiceBookingDetails, IServiceBookingOptions } from '../../types';

@Component({
  selector: 'app-service-booking-modal',
  templateUrl: './service-booking-modal.component.html',
  styleUrls: ['./service-booking-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'service-booking-modal'
  }
})
export class ServiceBookingModalComponent {
  @Input()
  // @ts-expect-error TS2564
  bookingOptions: IServiceBookingOptions<IGuideService>;

  @Input()
  // @ts-expect-error TS2564
  onBookingDetailsChange: ((details: IServiceBookingDetails<IGuideService>) => void) | null;

  @Input()
  // @ts-expect-error TS2564
  onRequestMoreOptions: (() => void) | null;

  constructor(readonly modal: NgbActiveModal) {}

  requestMoreOptions(): void {
    if (this.onRequestMoreOptions) {
      this.onRequestMoreOptions();
    }
  }

  updateBookingDetails(details: IServiceBookingDetails<IGuideService> | null): void {
    if (!details) {
      this.modal.dismiss();
    }

    if (this.onBookingDetailsChange) {
      // @ts-expect-error TS2345
      this.onBookingDetailsChange(details);
    }
  }
}
