<ng-container [formGroup]="form">
  <div class="question-text">
    <div class="question-html ql-editor" [appSetMetaToFirstChild]="key" [innerHTML]="question | safeHtml"></div>
  </div>

  <div class="question-answer fs-exclude" formGroupName="answer">
    <div
      class="file-container"
      *ngIf="!isDisabled || !answerFile; else fileCard"
      [ngClass]="{ 'has-cover': answerText, disabled: isDisabled }">
      <ng-container *ngIf="answerText" [ngSwitch]="getUploadFileType(answerText)">
        <div *ngSwitchCase="UploadFileTypes.IMG" class="file-container__img-case">
          <img [src]="answerText ? (answerText | safe: 'url') : null" [alt]="" />
        </div>

        <div *ngSwitchCase="UploadFileTypes.VIDEO" class="file-container__video-case">
          <video [src]="answerText | safe: 'url'" [controls]="isDisabled">
            Your browser does not support the video tag.
          </video>
        </div>

        <div *ngSwitchCase="UploadFileTypes.OTHER" class="file-container__other-case">
          <app-file-card [url]="answerText" [disableDownload]="false"></app-file-card>
        </div>

        <div *ngSwitchDefault class="file-container__default-case">
          <h3>Bad format</h3>
        </div>
      </ng-container>
      <div class="file-wrapper">
        <input
          id="file-{{ key || form.get('id').value }}"
          type="file"
          (change)="onFileInputChange($event)"
          class="file-input"
          [disabled]="isDisabled"
          [accept]="allowedMimeTypes" />
        <label for="file-{{ key || form.get('id').value }}" class="file-label">
          <span i18n="@@uploadFile" *ngIf="!isDisabled">Upload file</span>
          <span i18n="@@noFile" *ngIf="isDisabled && !answerText">No file</span>
        </label>
      </div>
    </div>

    <ng-template #fileCard>
      <app-file-card [file]="answerFile" [disableDownload]="true"></app-file-card>
    </ng-template>
  </div>

  <div *ngIf="!isValidType" class="file-upload-error">
    <i class="error-mark"></i>
    <span class="error-message" i18n="@@quizFileUploadAnswerFileFormatError">This file format is not supported.</span>
  </div>

  <div *ngIf="!isValidSize" class="file-upload-error">
    <i class="error-mark"></i>
    <span class="error-message" i18n="@@quizFileUploadAnswerFileSizeError">The file size must not exceed 20 MB.</span>
  </div>
</ng-container>
