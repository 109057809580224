<form [formGroup]="contentForm" class="form">
  <section class="section form-section row">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 i18n="@@basicInfo">Basic info</h3>

      <div class="section-note" i18n="@@basicInfoDescription">
        Give clients a clear idea of what they can learn if they enroll in your program
      </div>
    </div>

    <div class="col-md-5">
      <div class="form-group">
        <label for="program_name" class="label top-label required" i18n="@@programTitle">Program title</label>
        <input
          data-qa-id="program-content-program-title-input"
          formControlName="name"
          type="text"
          id="program_name"
          class="form-control" />
        <app-form-control-error
          i18n="@@programTitleError"
          [control]="contentForm.get('name')"
          *ngIf="contentForm.get('name')?.errors?.required || contentForm.get('name')?.errors?.empty">
          Program title required.
        </app-form-control-error>
        <app-form-control-error
          i18n="@@programTitleMaxLengthError"
          [control]="contentForm.get('name')"
          *ngIf="contentForm.get('name')?.errors?.maxlength">
          Max program title length is 254 chars.
        </app-form-control-error>
      </div>

      <div class="form-group">
        <label for="program_description" class="label top-label" i18n="@@programDetails">Program details</label>
        <textarea
          data-qa-id="program-content-program-details-textarea"
          formControlName="description"
          id="program_description"
          class="form-control"></textarea>
      </div>

      <ng-container *ngIf="!disableSettings">
        <div class="form-group">
          <label for="program_length" class="label top-label" i18n="@@programDuration">Program duration</label>
          <input
            data-qa-id="program-content-program-duration-input"
            formControlName="length"
            type="text"
            id="program_length"
            class="form-control" />
        </div>
      </ng-container>

      <div class="form-group" *ngIf="!isAdmin">
        <div class="author">
          <label for="program_author" class="label top-label guide-label" transloco="practitioner">Practioner</label>
          <input type="text" id="program_author" class="form-control" [value]="author?.name" readonly />
        </div>

        <div class="authors" formArrayName="coauthors">
          <div class="author" *ngFor="let coauthor of formCoauthors.controls; let coauthorIndex = index">
            <div class="composite-label">
              <label class="label top-label guide-label" i18n="@@coauthor">Coauthor</label>
              <a *ngIf="!isFormDisabled" (click)="removeCoauthor.emit(coauthorIndex)" class="btn btn-link remove-btn">
                <ng-container i18n="@@remove">Remove</ng-container>
              </a>
            </div>

            <app-form-field #field>
              <ui-client-selector-single
                appInput
                [value]="coauthor.value"
                [autocomplete-connected-to]="field.origin"
                [clients]="(possibleCoauthors && possibleCoauthors[coauthorIndex]) || []"
                (typed)="findCoauthors(coauthor, $event)"
                (selected)="selectProposedCoauthor(coauthorIndex, $event)"></ui-client-selector-single>
            </app-form-field>
          </div>
          <a
            class="btn btn-link"
            *ngIf="!hideCoAuthor && !isFormDisabled"
            (click)="addCoauthor.emit()"
            i18n="@@addCoauthor">
            Add coauthor
          </a>
        </div>
      </div>

      <app-cover-image
        recommendedDimensionsText="1200 x 440px"
        i18n-label="@@coverImage"
        label="Cover image"
        imagePrefix="program"
        [coverVariantsSettings]="coverVariantsSettings"
        [backgroundUrl]="contentForm.controls.coverImage?.value"
        (uploadImage)="uploadCovers.emit($event)"
        (removeImage)="removeCover.emit()"
        [disabled]="isFormDisabled">
        <input type="hidden" name="coverImageUrl" formControlName="coverImage" />
        <input type="hidden" name="coverImageThumbUrl" formControlName="coverImageThumb" />
      </app-cover-image>

      <ng-container *ngIf="!disableSettings">
        <div class="form-group start-type-group pui-space_top-6">
          <label class="label top-label" i18n="@@theProgramStarts">The program starts</label>

          <div appRadioGroup formControlName="startType">
            <div *ngFor="let type of options.startTypes; index as i" class="start-type-wrapper">
              <input appRadioInput type="radio" [value]="type.value" [id]="'program_start_type' + i" />
              <label
                [attr.data-qa-id]="'program-content-start-type-radio-button-' + type.value"
                class="radio-label"
                [for]="'program_start_type' + i">
                {{ type.label }}
              </label>

              <ng-container *ngIf="type.value === 'program_release'">
                <div class="datepicker-wrapper" (clickOutside)="datePicker.close()">
                  <input
                    formControlName="startDate"
                    id="program_start_date"
                    type="text"
                    ngbDatepicker
                    #datePicker="ngbDatepicker"
                    navigation="arrows"
                    [firstDayOfWeek]="7"
                    (click)="datePicker.toggle()"
                    [autoClose]="'inside'"
                    [minDate]="minDate"
                    [readonly]="true"
                    class="date-control form-control" />
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>

  <section class="section form-section row">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 i18n="@@programDetails">Program details</h3>

      <div class="section-note" i18n="@@programDetailsNote">
        Describe your approach and what you will teach in this program
      </div>
    </div>

    <div class="col-md-5">
      <div class="form-group">
        <label class="label top-label emph" i18n="@@whatYouWillLearn">What's included?</label>

        <ng-container formArrayName="keyPoints">
          <div class="keypoint form-group" *ngFor="let keyPoint of formKeyPoints.controls; let keyPointIndex = index">
            <i [attr.data-qa-id]="'program-content-included-block-icon-' + keyPointIndex" class="confirmation-icon"></i>
            <input
              [attr.data-qa-id]="'program-content-included-block-input-' + keyPointIndex"
              [formControlName]="keyPointIndex"
              type="text"
              class="form-control" />
            <a
              [attr.data-qa-id]="'program-content-included-block-remove-button-' + keyPointIndex"
              class="remove-keypoint"
              *ngIf="!isFormDisabled"
              (click)="removeKeyPoint.emit(keyPointIndex)"></a>
          </div>

          <a
            data-qa-id="program-content-included-add-topic-button"
            *ngIf="!isFormDisabled"
            (click)="addKeyPoint.emit()"
            class="btn btn-link"
            i18n="@@addTopic">
            Add topic
          </a>
        </ng-container>
      </div>

      <div class="form-group">
        <label class="label top-label emph" i18n="@@programHelpsWith">Program helps with</label>
        <app-tags-selector
          formControlName="issues"
          title="Add topic"
          i18n-title="@@addTopicButtonText"
          tagsLimit="100"
          [allTags]="options.tags"
          class="issues-selector"
          [disabled]="isFormDisabled"></app-tags-selector>
      </div>

      <div class="form-group">
        <label class="label top-label emph" i18n="@@approachesIncluded">Approaches included</label>
        <app-tags-selector
          formControlName="approaches"
          title="Add approach"
          i18n-title="@@addApproachButtonText"
          [tagsLimit]="100"
          [allTags]="options.tags"
          class="approaches-selector"
          [disabled]="isFormDisabled"></app-tags-selector>
      </div>

      <div class="form-group">
        <label for="program_additional_note" class="label top-label">
          <ng-container i18n="@@programAdditionalDetails">Additional details</ng-container>
        </label>
        <textarea
          data-qa-id="program-content-additional-details-textarea"
          formControlName="additionalNotes"
          id="program_additional_note"
          class="form-control"></textarea>
      </div>
    </div>
  </section>

  <section class="section row" *ngIf="shouldDisplayTestimonials">
    <app-guide-service-testimonials-editor
      [disabled]="isFormDisabled"
      class="col-12"
      [formTestimonials]="formTestimonials"></app-guide-service-testimonials-editor>
  </section>

  <section class="section form-section row" *ngIf="shouldDisplayFAQ">
    <div class="col-md-3 offset-md-2 padding-right-md-05">
      <h3 i18n="@@FAQForClients">FAQ for clients</h3>

      <div class="section-note"></div>
    </div>

    <div class="col-md-5" formArrayName="faq">
      <div
        class="faquestion form-group"
        *ngFor="let faQuestion of formFAQuestions.controls; let faQuestionIndex = index"
        [formGroupName]="faQuestionIndex">
        <div class="form-group">
          <div class="composite-label">
            <label class="label top-label" [attr.data-qa-id]="'program-content-question-label-' + faQuestionIndex">
              <ng-container i18n="@@question">Question</ng-container>
              {{ faQuestionIndex + 1 }}
            </label>
            <a
              [attr.data-qa-id]="'program-content-remove-question-button-' + faQuestionIndex"
              *ngIf="!isFormDisabled"
              (click)="removeFAQuestion.emit(faQuestionIndex)"
              class="btn btn-link remove-btn">
              <ng-container i18n="@@remove">Remove</ng-container>
            </a>
          </div>
          <input
            [attr.data-qa-id]="'program-content-question-input-' + faQuestionIndex"
            formControlName="question"
            type="text"
            class="form-control" />
        </div>

        <div class="form-group">
          <label
            [attr.data-qa-id]="'program-content-answer-label-' + faQuestionIndex"
            class="label top-label"
            i18n="@@answer">
            Answer {{ faQuestionIndex + 1 }}
          </label>
          <textarea
            [attr.data-qa-id]="'program-content-answer-textarea-' + faQuestionIndex"
            formControlName="answer"
            type="text"
            class="form-control"></textarea>
        </div>
      </div>

      <a
        data-qa-id="program-content-add-question-button"
        *ngIf="!isFormDisabled"
        (click)="addFAQuestion.emit()"
        class="btn btn-link">
        <ng-container i18n="@@addQuestion">Add question</ng-container>
      </a>
    </div>
  </section>

  <section class="section row" *ngIf="!disableSettings">
    <app-guide-service-pricing-editor
      class="col-12"
      [form]="contentForm.get('pricingFormGroup')"
      [disablePaymentTypeChange]="false"
      (pricingChanged)="trackPricingChange($event)"
      type="program"></app-guide-service-pricing-editor>
  </section>
</form>
