import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { IUser } from '@app/shared/interfaces/user';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ChatUserDetailed } from '@app/core/chat/types';
import config from '../config/config';

@Injectable()
export class GroupChatUsersService implements OnDestroy {
  private readonly ENDPOINT = `${config.apiPath}/user/common/chat-users`;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _usersMap$ = new BehaviorSubject<{ [id: number]: ChatUserDetailed }>({});

  get usersMap$(): Observable<{ [id: number]: ChatUserDetailed }> {
    return this._usersMap$.asObservable();
  }

  constructor(private _http: HttpClient) {}

  ngOnDestroy(): void {
    this._usersMap$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add$(users: { id: number; role: UserRoles }[]): Observable<any> {
    if (!users || !users.length) {
      return of(null);
    }

    const currentUsersMap = this._usersMap$.getValue();
    const newUsers = users.filter(({ id }) => !currentUsersMap[id]);

    return this._loadUsers$(newUsers).pipe(mapTo(null));
  }

  remove(ids: number[]): void {
    const idsMap = ids.reduce((dict, id) => ({ ...dict, [id]: id }), {});
    const currentClientsMap = this._usersMap$.getValue();

    const newClientsMap = Object.entries(currentClientsMap).reduce((dict, [id, user]) => {
      // @ts-expect-error TS7053
      if (!idsMap[id]) {
        // @ts-expect-error TS7053
        dict[id] = user;
      }

      return dict;
    }, {});

    this._usersMap$.next(newClientsMap);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _loadUsers$(users: { id: number; role: UserRoles }[]): Observable<{ [id: number]: ChatUserDetailed }> {
    const guides = users.filter(({ role }) => role === UserRoles.GUIDE).map(({ id }) => id.toString());
    const clients = users.filter(({ role }) => role === UserRoles.CLIENT).map(({ id }) => id.toString());

    if (!guides.length && !clients.length) {
      return of({});
    }

    const params: { guides?: string[]; clients?: string[] } = {};

    if (guides.length) {
      params.guides = guides;
    }

    if (clients.length) {
      params.clients = clients;
    }

    const httpParams = new HttpParams({
      fromObject: params
    });

    return this._http.get<{ users: IUser & { role: UserRoles }[] }>(this.ENDPOINT, { params: httpParams }).pipe(
      map(data => data.users.map(item => new ChatUserDetailed(item))),
      map(newUsers =>
        newUsers.reduce((dict, user) => {
          // @ts-expect-error TS7053
          dict[user.id] = user;
          return dict;
        }, {})
      ),
      tap(usersMap => this._usersMap$.next({ ...this._usersMap$.getValue(), ...usersMap }))
    );
  }
}
