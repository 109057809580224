import { Pipe, PipeTransform } from '@angular/core';
import { IProgramServiceDetails } from '@app/screens/guide/guide-programs/types';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import { IUser } from '@app/shared/interfaces/user';

type ProgramServiceDetailsRootObject = Pick<
  GuideServices.RootObject,
  'duration' | 'seatsPerTimeSlot' | 'connectionType' | 'name' | 'sessionType'
> & { team: IUser[] };

@Pipe({
  name: 'moduleServiceDetailsAdaptor'
})
export class ModuleServiceDetailsAdaptorPipe implements PipeTransform {
  transform([serviceDetails, serviceDraft]: [IProgramServiceDetails, ProgramSessionTemplateInterface]): [
    ProgramServiceDetailsRootObject
  ] {
    if (!serviceDraft) {
      return this.detailsMapper(serviceDetails);
    } else {
      return this.draftMapper(serviceDraft);
    }
  }

  private detailsMapper(serviceDetails: IProgramServiceDetails): [ProgramServiceDetailsRootObject] {
    const team = serviceDetails?.team.sort((firstHost, secondHost) => (firstHost.id > secondHost.id ? 1 : -1));

    return [
      {
        name: serviceDetails.name,
        connectionType: serviceDetails.location?.connectionType || serviceDetails.connectionType,
        seatsPerTimeSlot: serviceDetails.seatsPerTimeSlot,
        duration: serviceDetails.duration,
        sessionType: serviceDetails.sessionType,
        team
      }
    ];
  }

  private draftMapper(serviceDraft: ProgramSessionTemplateInterface): [ProgramServiceDetailsRootObject] {
    const team = serviceDraft.hosts
      .sort((firstHost, secondHost) => ((firstHost.userId || 0) > (secondHost.userId || 0) ? 1 : -1))
      .map(guide => {
        return {
          ...guide,
          permission: undefined
        };
      });

    return [
      {
        name: serviceDraft.name,
        connectionType: serviceDraft.location?.connectionType,
        seatsPerTimeSlot: serviceDraft.seatsPerTimeSlot,
        duration: serviceDraft.duration,
        sessionType: serviceDraft.sessionType,
        team: team as unknown as IUser[]
      }
    ];
  }
}
