<pui-select
  [ngModel]="selectedItemsValues"
  (ngModelChange)="selectItem($event)"
  [disabled]="disabled"
  [class.empty]="!selectedItemsIds?.length"
  multiple="true"
  i18n-puiPlaceholder="@@language"
  puiPlaceholder="Language">
  <pui-option *ngFor="let item of filteredItems" [value]="item" [label]="item"></pui-option>
</pui-select>
