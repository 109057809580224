<div
  [formGroup]="form"
  class="text-editor-notes"
  (clickOutside)="onStopEditing($event)"
  [delayClickOutsideInit]="true"
  *ngIf="isEditing && !this.readonly">
  <app-text-editor-image
    class="text-editor-notes__editor fs-exclude"
    #noteEditorInstance
    formControlName="noteEditorContent"
    format="json"
    [attr.data-qa-id]="'text-editor-notes-note-editor-' + noteIndex"
    [placeholder]="''"
    [bounds]="'self'"
    [modules]="modules"
    [extendedVideoUploader]="true"
    (editorCreated)="onEditorInit($event)"
    (contentChanged)="contentChanged($event)"></app-text-editor-image>
</div>

<div class="text-editor-notes__preview">
  <div class="text-editor-notes__preview-container" (click)="onStartEditing()" *ngIf="!isEditing || this.readonly">
    <pui-text-editor-view
      #quillView
      class="text-editor-notes__text-editor fs-exclude ql-container"
      *ngIf="!isEmpty"
      [attr.data-qa-id]="'text-editor-notes-note-text-' + noteIndex"
      [content]="editorContent"
      format="json"></pui-text-editor-view>

    <div class="text-editor-notes__empty-preview" (click)="startEditing()" *ngIf="isEmpty">
      <ng-template *ngTemplateOutlet="emptyNotePreview"></ng-template>
    </div>
  </div>
  <div
    class="text-editor-notes__view-button"
    *ngIf="isOverflown(quillView)"
    i18n="@@noteEditorShowMore"
    (click)="showMore($event)">
    Show more
  </div>
  <div
    class="text-editor-notes__view-button"
    *ngIf="!clamped && shouldClamp"
    i18n="@@noteEditorShowLess"
    (click)="showLess($event)">
    Show less
  </div>
</div>
