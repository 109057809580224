import { environment } from '@env/environment';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProgramConstructorComponent } from './components/program-constructor/program-constructor.component';
import { ProgramEditorComponent } from './components/program-editor/program-editor.component';

import { CanLeaveEditorTabGuard } from './guards/can-leave-editor-tab.guard';
import { CanCreateServicesGuard } from '@app/core/public/can-create-services.guard';

const routes: Routes = [
  {
    path: 'new',
    component: ProgramConstructorComponent,
    canActivate: [CanCreateServicesGuard]
  },
  {
    path: ':id/edit/:tab',
    component: ProgramEditorComponent,
    canDeactivate: [CanLeaveEditorTabGuard]
  },
  {
    path: ':id/edit',
    redirectTo: ':id/edit/clients'
  },
  {
    path: ':id',
    redirectTo: ':id/edit/clients'
  },
  {
    path: '**',
    redirectTo: `/${environment.guideRoute}/services`
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuideProgramsRoutingModule {}
