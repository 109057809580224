// import { locale } from '@env/locale';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { PublicSessionTemplate } from '@app/shared/interfaces/services';
import { GlobalConfig } from '@cnf/types';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';

import { PUBLIC_SESSION_TEMPLATE_INFO } from './public-session-template.providers';

@Injectable()
export class PublicSessionTemplateMetaService implements OnDestroy {
  private destroy$ = new Subject();
  private locale: ILocale;

  constructor(
    private _configProvider: BrandingService,
    private _router: Router,
    @Inject(PUBLIC_SESSION_TEMPLATE_INFO) private _template$: Observable<PublicSessionTemplate>,
    private localeService: LocaleService,
    private metaTagService: MetaTagService
  ) {
    this.locale = this.localeService.getLocale();
    combineLatest([_configProvider.globalConfig$, _template$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([config, template]) => this.setOGMeta(config, template));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setOGMeta(config: GlobalConfig, template: PublicSessionTemplate): void {
    if (!template?.id) {
      return;
    }

    this.metaTagService.upsertMetaTags({
      title: `${config.metaTitleSessionPage || ''} ${template.name}`,
      description: template.description || config.metaDescriptionSessionPage,
      image: template.coverImage || template.guide.fullPhoto || config.metaImageSessionPage,
      url: `${this.locale.baseUrl}${this._router.url}`,
      type: 'website',
      keywords: config.metaKeywordsSessionPage,
      'og:session:author': template.guide.name
    });
  }
}
