import { Component, EventEmitter, Input, Output } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-btn-menu',
  templateUrl: './btn-menu.component.html',
  styleUrls: ['./btn-menu.component.scss']
})
export class BtnMenuComponent {
  isActive = false;

  // @ts-expect-error TS2564
  @Input() lightHeader: boolean;

  @Output() btnMenuClick = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggleMenu() {
    this.isActive = !this.isActive;
    this.btnMenuClick.emit();
  }
}
