import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { IGuideClient } from '@app/core/users/types';
import { IUser } from '@app/shared/interfaces/user';
import { WithID, WithNamedUrl, WithWorkspaceID, WithWorkspaceRole } from '@app/base';
import { IServiceWorkspaceAssignee } from '@app/screens/guide/guide-sessions-templates/types';
import { IMembershipPlan } from '@app/core/membership/types';

export enum WorkspaceRoute {
  GUIDE,
  PUBLIC
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWorkspace extends WithID, WithWorkspaceRole, Partial<WithNamedUrl>, Partial<WithWorkspaceID> {
  name: string;
  guideId: number;
  isActive: boolean;
  type: WorkspacesTypes;
  branding: IWorkspaceSettingsData['branding'];
  membersCount: number;
  admin: Pick<IGuideClient, 'firstName' | 'lastName' | 'email' | 'id'>;
  adminId?: number;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
  membership?: IMembershipPlan;
  team?: SchedulingTeam;
}

export interface SchedulingTeam {
  bio: null;
  createdAt: string;
  hideBookATeamMember: boolean;
  hideBranding: boolean;
  id: number;
  logo: null;
  metadata: null;
  name: string;
  slug: string;
}

export enum MemberRoleEnum {
  ADMIN = 'admin',
  MEMBER = 'member'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWorkspaceMember extends WithWorkspaceRole {
  userId?: number;
  photo: string | null;
  email: string;
  username?: string;
  workspaceId?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWorkspaceClient extends Readonly<WithID> {
  firstName: string;
  lastName: string;
  photo: string;
  members: IWorkspaceMember[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWorkspaceInvitationInfo {
  teamId: number;
  invitations: string[];
  isResend: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface WorkspaceUser
  extends Pick<IUser, 'id' | 'firstName' | 'lastName' | 'namedUrl' | 'photo'>,
    Partial<Pick<IServiceWorkspaceAssignee, 'permission'>> {
  UserId: number;
  email: string;
}

type BaseWorkspaceData = Pick<IWorkspace, 'name' | 'description' | 'namedUrl' | 'type'>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWorkspaceSettingsData extends BaseWorkspaceData {
  branding: {
    color?: string;
    logo?: string;
    icon?: string;
    photo?: string;
  };
  settings: {
    defaultNotesAccess?: NotesAccessTypes;
  };
}

export enum NotesAccessTypes {
  NO_ACCESS = 'NoAccess',
  CLIENTS = 'Clients',
  TEAM_MEMBERS = 'TeamMembers',
  EVERYONE = 'Everyone'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IWorkspaceSettingsForm extends BaseWorkspaceData {
  logo: string;
  icon: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUploadImageResponse {
  logoUrl: string | undefined;
  iconUrl: string | undefined;
}
