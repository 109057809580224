<ng-container *ngIf="session">
  <div class="session" *puiLet="isMobile$ | async as isMobile">
    <div class="content-wrapper">
      <ng-container *ngIf="!isMobile">
        <ng-container *ngTemplateOutlet="icon"></ng-container>
      </ng-container>
      <div class="session__info">
        <div class="session__info-first-line">
          <ng-container *ngIf="isMobile">
            <ng-container *ngTemplateOutlet="icon"></ng-container>
          </ng-container>
          <div class="session__title">
            <span class="session__title-status">
              <app-client-session-status [status]="session.status">
                <span class="separator">:</span>
              </app-client-session-status>
            </span>
            <span class="session__title-label">
              {{ session.title }}
            </span>
          </div>
          <ng-container *ngIf="isMobile">
            <div class="session__actions-wrapper">
              <ng-container *ngIf="session.showJoinButton">
                <app-client-session-join-button [session]="session"></app-client-session-join-button>
              </ng-container>
              <ng-container *ngTemplateOutlet="actions"></ng-container>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="session.attributes as attributes">
          <app-session-attributes
            class="session__attributes"
            *ngIf="attributes.length > 0"
            [attributes]="attributes"
            [showJoinSessionLink]="session.showJoinButton"></app-session-attributes>
        </ng-container>
      </div>

      <ng-container *ngIf="!isMobile">
        <div class="session__actions-wrapper">
          <ng-container *ngIf="session.showJoinButton">
            <app-client-session-join-button [session]="session"></app-client-session-join-button>
          </ng-container>
          <ng-container *ngTemplateOutlet="actions"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #actions>
  <ng-container *ngIf="session.actionTypes as actionTypes">
    <app-session-actions
      class="session__actions"
      *ngIf="actionTypes.length > 0"
      [actionTypes]="actionTypes"
      [noNeedPaid]="true"
      appearance="dropdown"></app-session-actions>
  </ng-container>
</ng-template>

<ng-template #icon>
  <app-client-session-icon class="session__icon" [icon]="session.icon"></app-client-session-icon>
</ng-template>
