import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, debounceTime, takeUntil, filter } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services';
import { IServerPublicEventsResponse } from '../types';
import { PublicEventsApiService } from './public-events.api.service';
import { PublicEventsFeedFilterService } from './public-events-feed-filter.service';

@Injectable({
  providedIn: 'root'
})
export class PublicEventsService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _arePublicEventsAvailableForClient: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private destroy$ = new Subject<void>();

  get arePublicEventsAvailableForClient$(): Observable<boolean> {
    return this._arePublicEventsAvailableForClient.asObservable();
  }

  constructor(
    private readonly _publicEventsFeedFilterService: PublicEventsFeedFilterService,
    private readonly _publicEventsApiService: PublicEventsApiService,
    private readonly _auth: AuthService
  ) {
    _auth
      .onAuth()
      .pipe(
        filter(user => !!user),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.checkPublicEventsAvailability();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkPublicEventsAvailability(): void {
    this._publicEventsApiService.getPublicEventsAvailability().subscribe(
      ({ available }) => this._arePublicEventsAvailableForClient.next(available),
      () => this._arePublicEventsAvailableForClient.next(false)
    );
  }

  getPublicEventsTypes(): Observable<string[]> {
    return this._publicEventsApiService.getPublicEventsTypes();
  }

  getPublicEventsByFilters(): Observable<IServerPublicEventsResponse> {
    return this._publicEventsFeedFilterService.filter$.pipe(
      debounceTime(300),
      switchMap(filterParams => {
        return this._publicEventsApiService.getPublicEvents(filterParams);
      })
    );
  }
}
