import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { assertNever } from '@app/shared/utils/assertions';
import { UserRoles } from '@app/shared/enums/user-roles';
import { IUser, User } from '@app/shared/interfaces/user';
import { SimpleSession } from '@app/shared/interfaces/session';

export enum ClientEventActionTypes {
  ACCEPT_REQUEST = 'accept_request',
  ACCEPT_RESCHEDULE = 'accept_reschedule',
  CANCEL = 'cancel',
  LEAVE_REVIEW = 'leave_review',
  LEAVE_PRIVATE_REVIEW = 'leave_private_review',
  REBOOK = 'rebook',
  RESCHEDULE = 'reschedule',
  JOIN_SESSION = 'join_session'
}

export enum EventTypes {
  SESSION = 'session',
  GROUP_SESSION = 'group_session'
}

export enum GuideEventActionTypes {
  ACCEPT_REQUEST = 'accept_request',
  ACCEPT_RESCHEDULE = 'accept_reschedule',
  CANCEL = 'cancel',
  CANCEL_EVENT = 'cancel_event',
  RESCHEDULE = 'reschedule'
}

export enum LinkTypes {
  ZOOM = 'zoom'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IEventActionResult {
  complete: boolean;
  actionType: string;
  actionCode?: string;
  actionUser?: { role: UserRoles; email: string; withoutPassword: boolean; firstName?: string };
  eventDetails?: Event;
}

export class SessionZoomLink {
  // @ts-expect-error TS2564
  joinUrl: string;

  phoneNumbers?: { country?: string; number: string }[];

  startUrl?: string | null;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return LinkTypes.ZOOM;
  }

  // @ts-expect-error TS7006
  constructor(eventLink) {
    if (eventLink.joinUrl) {
      this.joinUrl = eventLink.joinUrl;
    }

    if (eventLink.phoneNumbers) {
      this.phoneNumbers = eventLink.phoneNumbers;
    }

    if (eventLink.startUrl) {
      this.joinUrl = eventLink.startUrl;
    }
  }
}

export class BaseEvent {
  id?: number | null;

  name: string;

  description?: string;

  date: string;

  duration: number;

  links?: EventLink[] | null;

  oldDate?: string | null;

  paymentFailed?: boolean | null;

  status: SessionStatuses;

  user: IUser | null;

  session?: SimpleSession;

  // @ts-expect-error TS7006
  constructor(sessionEvent) {
    this.name = sessionEvent.name;
    this.description = sessionEvent.description;
    this.date = sessionEvent.date;
    this.duration = sessionEvent.duration;
    this.status = sessionEvent.status;

    const { user } = sessionEvent;

    // @ts-expect-error TS2322
    this.user = user
      ? new User(user.id, user.firstName, user.lastName, user.photo, user.isOnline, user.lastTimezone, user.workspaceId)
      : null;

    if (sessionEvent.session) {
      this.session = sessionEvent.session;
    }

    if (sessionEvent.id) {
      this.id = sessionEvent.id;
    }

    if (sessionEvent.links) {
      // @ts-expect-error TS7006
      this.links = sessionEvent.links.map(link => eventLinkFactory(link));
    }

    if (sessionEvent.oldDate) {
      this.oldDate = sessionEvent.oldDate;
    }

    if (sessionEvent.paymentFailed != null && sessionEvent.paymentFailed) {
      this.paymentFailed = sessionEvent.paymentFailed;
    }
  }
}

export class SessionEvent extends BaseEvent {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return EventTypes.SESSION;
  }
}

export class GroupSessionEvent extends BaseEvent {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return EventTypes.GROUP_SESSION;
  }
}

export type Event = SessionEvent | GroupSessionEvent;

export type EventLink = SessionZoomLink;

export function eventFactory(event: { type: EventTypes }): Event {
  switch (event.type) {
    case EventTypes.SESSION:
      return new SessionEvent(event);
    case EventTypes.GROUP_SESSION:
      return new GroupSessionEvent(event);
    default:
      return assertNever(event, '"type"-property not found in:');
  }
}

export function eventLinkFactory(eventLink: { type: LinkTypes }): EventLink {
  switch (eventLink.type) {
    case LinkTypes.ZOOM:
      return new SessionZoomLink(eventLink);
    default:
      return assertNever(eventLink, '"type"-property not found in:');
  }
}
