import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-past-client-sessions',
  templateUrl: './past-client-sessions.component.html',
  styleUrls: ['past-client-sessions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastClientSessionsComponent<T> {
  @Input()
  sessions: T[] | undefined;

  groupBy = true;

  @Input()
  hideHeader = false;
}
