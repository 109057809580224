import { CommonModule } from '@angular/common';
import {
  PuiAvatarModule,
  PuiCardModule,
  PuiTooltipModule,
  PuiIconModule,
  PuiLinkModule
} from '@awarenow/profi-ui-core';
import { NgModule } from '@angular/core';

import { BookingPopupModule } from '@app/modules/ui-kit/widgets/booking-popup/booking-popup.module';
import { SafePipeModule } from '@app/shared/pipes/safe/safe.module';
import { FloatingPopoverService } from '@app/modules/floating-popover/floating-popover.service';
import { SidenavCurrentWidgetComponent } from '@app/modules/sidenav/components/sidenav-current/sidenav-current-widget.component';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';

import { CURRENT_VIEW_VARIANTS } from './view';
import { CurrentClientService, CurrentGuideService } from './services';

@NgModule({
  declarations: [SidenavCurrentWidgetComponent, ...CURRENT_VIEW_VARIANTS],
  imports: [
    CommonModule,
    PuiAvatarModule,
    BookingPopupModule,
    PuiTooltipModule,
    SafePipeModule,
    PuiCardModule,
    PuiIconModule,
    CurrentPaymentModule,
    PuiLinkModule
  ],
  providers: [FloatingPopoverService, CurrentGuideService, CurrentClientService]
})
export class SidenavCurrentWidgetModule {}
