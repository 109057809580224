<ng-container *ngIf="canCreateService$ | async">
  <button
    pui-button
    data-qa-id="guide-services-board__add-new-service"
    pui-dropdown
    [pui-dropdown-min-width]="300"
    [pui-dropdown-content]="newServiceDropdown">
    <div class="d-flex align-items-center">
      <ng-content></ng-content>
    </div>
  </button>

  <ng-template #newServiceDropdown>
    <div class="guide-service-option">
      <app-service-selector
        [returnAfterSave]="returnAfterSave"
        name="Session"
        imageSrc="/assets/images/guide-services/session.png"
        i18n-name="@@guideSessionServiceName"
        description="Offer one-on-one and group sessions"
        i18n-description="@@guideSessionServiceDescription"
        link="/{{ servicePath(serviceTypes.GROUP_SESSION) }}/sessions/new"
        data-qa-id="guide-service-option__new-session-button"
        (beforeNavigate)="beforeNavigate.emit()"></app-service-selector>

      <ng-container *ngIf="packagesEnabled">
        <app-service-selector
          [returnAfterSave]="returnAfterSave"
          name="Package"
          imageSrc="/assets/images/guide-services/package.png"
          i18n-name="@@guidePackageServiceName"
          description="Allow clients to purchase a set of sessions or a subscription"
          i18n-description="@@guidePackageServiceDescription"
          link="/{{ servicePath(serviceTypes.PACKAGE) }}/packages/new"
          data-qa-id="guide-service-option__new-package-button"
          (beforeNavigate)="beforeNavigate.emit()"></app-service-selector>
      </ng-container>

      <app-service-selector
        [returnAfterSave]="returnAfterSave"
        name="Program"
        imageSrc="/assets/images/guide-services/program.png"
        i18n-name="@@guideProgramServiceName"
        description="Provide a series of recorded videos, slides, and other content"
        i18n-description="@@guideProgramServiceDescription"
        link="/{{ servicePath(serviceTypes.PROGRAM) }}/programs/new"
        data-qa-id="guide-service-option__new-program-button"
        (beforeNavigate)="beforeNavigate.emit()"></app-service-selector>
    </div>
  </ng-template>
</ng-container>
