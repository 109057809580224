import { Pipe, PipeTransform } from '@angular/core';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';

@Pipe({
  name: 'sessionConnectionTypeIcon'
})
export class SessionConnectionTypeIconPipe implements PipeTransform {
  transform(connectionType: SessionConnectionTypes): string {
    switch (connectionType) {
      case SessionConnectionTypes.IN_PLATFORM:
      case SessionConnectionTypes.ZOOM:
        return 'pui:video';
      case SessionConnectionTypes.IN_PERSON:
        return 'pui:place';
      case SessionConnectionTypes.PHONE:
        return 'pui:phone';
      default:
        return 'pui:place';
    }
  }
}
