import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type ScriptLoaderConfig = Partial<Exclude<HTMLScriptElement, 'onload' | 'onerror'>> &
  Pick<HTMLScriptElement, 'src'>;

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  load(config: ScriptLoaderConfig): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = this.document.createElement('script');

      Object.entries(config).forEach(([key, value]) => {
        script.setAttribute(key, `${value}`);
      });

      script.onload = () => resolve();
      script.onerror = () => reject();

      this.document.head.appendChild(script);
    });
  }
}
