<div class="modal-header">
  <h3 class="modal-title" [attr.data-qa-id]="'cancel-session-modal-' + cancellationType + '-title'">
    <ng-template [ngIf]="cancellationType === 'cancel' && sessionDate" i18n="@@sessionCancellationClientsCancelOnDate">
      Cancel {{ sessionName }} on {{ sessionDate | platformDateTime: 'DATE, TIME':{ showTimezone: true } }}?
    </ng-template>
    <ng-template [ngIf]="cancellationType === 'cancel' && !sessionDate" i18n="@@sessionCancellationClientsCancel">
      Cancel {{ sessionName }}?
    </ng-template>
    <ng-template
      [ngIf]="cancellationType === 'decline' && sessionDate"
      i18n="@@sessionCancellationClientsDeclineOnDate">
      Decline {{ sessionName }} on {{ sessionDate | platformDateTime: 'DATE, TIME':{ showTimezone: true } }}?
    </ng-template>
    <ng-template [ngIf]="cancellationType === 'decline' && !sessionDate" i18n="@@sessionCancellationClientsDecline">
      Decline {{ sessionName }}?
    </ng-template>
  </h3>

  <h4
    [attr.data-qa-id]="'cancel-session-modal-why-' + cancellationType + '-subtitle'"
    class="modal-sub-title"
    *ngIf="!serviceType || serviceType === SessionTypes.SESSION">
    <ng-template [ngIf]="cancellationType === 'cancel'" [ngIfElse]="declined" i18n="@@whyYouCancelledIndividual">
      Let {{ user?.name }} know why you've cancelled.
    </ng-template>
    <ng-template #declined i18n="@@whyYouDeclinedIndividual">
      Let {{ user?.name }} know why you've declined.
    </ng-template>
  </h4>

  <h4 class="modal-sub-title" *ngIf="serviceType && serviceType === SessionTypes.GROUP_SESSION">
    <ng-template
      [ngIf]="cancellationType === 'cancel' && numOfParticipants > 1"
      i18n="@@whyYouHaveCancelledClientsGroup">
      All participants will be notified. You can add a message to let them know why you’ve canceled.
    </ng-template>
    <ng-template
      [ngIf]="cancellationType === 'cancel' && numOfParticipants === 1"
      i18n="@@whyYouHaveCancelledClientGroup">
      You can add a message to let the participant know why you’ve canceled.
    </ng-template>
    <ng-template
      [ngIf]="cancellationType === 'decline' && numOfParticipants > 1"
      i18n="@@whyYouHaveDeclinedClientsGroup">
      All participants will be notified. You can add a message to let them know why you’ve declined.
    </ng-template>
    <ng-template
      [ngIf]="cancellationType === 'decline' && numOfParticipants === 1"
      i18n="@@whyYouHaveDeclinedClientGroup">
      You can add a message to let the participant know why you’ve declined.
    </ng-template>
  </h4>

  <app-button
    data-qa-id="cancel-session-modal-close-button"
    class="close-btn"
    className="close"
    (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <label i18n="@@sessionCancellationMessage" class="label">Message</label>
  <textarea data-qa-id="cancel-session-modal-message-textarea" [(ngModel)]="message" class="form-control"></textarea>
</div>

<div class="modal-footer">
  <app-button
    data-qa-id="cancel-session-modal-send-message-button"
    className="btn-primary"
    (click)="modal.close(message)"
    [disabled]="!message"
    i18n="@@sendMsg">
    Send message
  </app-button>
  <app-button
    [attr.data-qa-id]="'cancel-session-modal-' + cancellationType + '-without-message-button'"
    className="btn-lite"
    (click)="modal.close(null)">
    <ng-template [ngIf]="cancellationType === 'cancel'" [ngIfElse]="decline">
      <ng-container i18n="@@cancelWithoutMsg">Cancel without message</ng-container>
    </ng-template>
    <ng-template #decline>
      <ng-container i18n="@@declineWithoutMsg">Decline without message</ng-container>
    </ng-template>
  </app-button>
</div>
