<ng-container *ngIf="!(isMobile$ | async); else mobileTemplate">
  <header class="wrap">
    <button
      data-qa-id="guide-service-editor-header-close-button"
      type="button"
      class="btn close-btn"
      aria-label="close"
      (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 class="service-type">
      <app-service-type [type]="type" [capitalized]="true"></app-service-type>
    </h5>
    <h1 class="title" data-qa-id="app-guide-service-editor-header__title">{{ title }}</h1>
    <app-copy-value-input
      *ngIf="link"
      [value]="link"
      (copied)="onCopied()"
      (copyFailed)="onCopyFailed()"
      class="link"></app-copy-value-input>
  </header>
</ng-container>

<ng-template #mobileTemplate>
  <header class="guide-service-editor__container">
    <div class="guide-service-editor__row">
      <div class="guide-service-editor__type-and-close">
        <small pui-typography="body-s-bold">
          <app-service-type [type]="type" [capitalized]="true"></app-service-type>
        </small>
        <button
          data-qa-id="guide-service-editor-header-close-button"
          type="button"
          aria-label="close"
          pui-icon-button
          appearance="flat"
          (click)="close()">
          <pui-icon svgIcon="pui:x"></pui-icon>
        </button>
      </div>
    </div>

    <div class="guide-service-editor__row">
      <h2 pui-typography data-qa-id="app-guide-service-editor-header__title">{{ title }}</h2>
    </div>
  </header>
</ng-template>

<nav [class.guide-service-editor__navigation]="isMobile$ | async" class="guide-service-editor__navigation">
  <ng-content></ng-content>
  <ng-content select=".tabs"></ng-content>
</nav>
