import { NgModule } from '@angular/core';
import {
  UiCard,
  UiCardActions,
  UiCardAvatar,
  UiCardContent,
  UiCardFooter,
  UiCardHeader,
  UiCardImage,
  UiCardLgImage,
  UiCardMdImage,
  UiCardSmImage,
  UiCardSubtitle,
  UiCardTime,
  UiCardTitle,
  UiCardTitleGroup,
  UiCardXlImage
} from './card';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [
    UiCard,
    UiCardHeader,
    UiCardTitleGroup,
    UiCardContent,
    UiCardTitle,
    UiCardSubtitle,
    UiCardActions,
    UiCardFooter,
    UiCardSmImage,
    UiCardMdImage,
    UiCardLgImage,
    UiCardImage,
    UiCardXlImage,
    UiCardAvatar,
    UiCardTime
  ],
  imports: [CommonModule],
  declarations: [
    UiCard,
    UiCardHeader,
    UiCardTitleGroup,
    UiCardContent,
    UiCardTitle,
    UiCardSubtitle,
    UiCardActions,
    UiCardFooter,
    UiCardSmImage,
    UiCardMdImage,
    UiCardLgImage,
    UiCardImage,
    UiCardXlImage,
    UiCardAvatar,
    UiCardTime
  ]
})
export class UiCardModule {}
