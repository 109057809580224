import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { QuillEditorImageComponent } from './quill-editor-image.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { QuillImageDownloaderService } from '@app/modules/quill-editor-image/quill-image-downloader.service';
import { VideoUploaderComponent } from './video-uploader/video-uploader.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '@app/shared';
import { WistiaService } from './services/wistia.service';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { IframeUploaderComponent } from '@app/modules/quill-editor-image/iframe-uploader/iframe-uploader.component';
import { HtmlUploaderComponent } from '@app/modules/quill-editor-image/html-uploader/html-uploader.component';
import { fontSizes } from '@awarenow/profi-ui-core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    QuillModule.forRoot({
      customOptions: [
        {
          import: 'attributors/style/size',
          whitelist: fontSizes
        }
      ]
    }),
    FormsModule,
    ReactiveFormsModule,
    ScriptLoaderModule,
    NgScrollbarModule,
    SharedModule
  ],
  declarations: [
    QuillEditorImageComponent,
    VideoUploaderComponent,
    VideoGalleryComponent,
    HtmlUploaderComponent,
    IframeUploaderComponent
  ],
  exports: [QuillEditorImageComponent],
  providers: [QuillImageDownloaderService, WistiaService]
})
export class QuillEditorImageModule {}
