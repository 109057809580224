import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BrandingState, ThemeType } from '@app/modules/ui-kit/kit-branding/types';
import { Color } from '@app/modules/ui-kit/kit-branding/interfaces';
import { LOCAL_STORAGE_KEY } from '@app/modules/ui-kit/kit-branding/const';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class KitBrandingStore {
  private readonly isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId) && !!localStorage;
  }

  getColors(theme: ThemeType): Color[] {
    if (this.isBrowser) {
      const branding: BrandingState = this.getLocalStorage();
      // @ts-expect-error TS2538
      return branding[theme];
    }
    return [];
  }

  setColor(theme: ThemeType, colors: Color[]): void {
    if (this.isBrowser) {
      const branding: BrandingState = this.getLocalStorage();
      // @ts-expect-error TS2464
      this.setLocalStorage({ ...branding, [theme]: colors });
    }
  }

  private setLocalStorage(brandingState: BrandingState): void {
    if (this.isBrowser) {
      const stringValue = JSON.stringify(brandingState);
      localStorage.setItem(LOCAL_STORAGE_KEY, stringValue);
    }
  }

  private getLocalStorage(): BrandingState {
    if (this.isBrowser) {
      // @ts-expect-error TS2345
      const branding = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      // @ts-expect-error TS2352
      return branding ?? ({ primary: [], accent: [] } as BrandingState);
    } else {
      return {} as BrandingState;
    }
  }
}
