import { Routes } from '@angular/router';

import { CanBecomeAGuideGuard } from './guards/can-become-a-guide.guard';
import { RedirectGuard } from './guards/redirect.guard';

import { SignUpGuideComponent } from './sign-up-guide/sign-up-guide.component';

export const routes: Routes = [
  {
    path: 'sign-up',
    canLoad: [CanBecomeAGuideGuard],
    canActivate: [CanBecomeAGuideGuard, RedirectGuard],
    component: SignUpGuideComponent
  },
  {
    path: '**',
    redirectTo: `/auth?signUp=true`
  }
];
