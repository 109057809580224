import { AnalyticEvent } from './analytic.event';
import { InternalEvents } from '@app/core/analytics/types';

export class SessionRescheduledEvent extends AnalyticEvent {
  constructor() {
    super(InternalEvents.SESSION_RESCHEDULED, {
      event_id: 'undefined',
      type: 'undefined',
      seats: 'undefined',
      session_service: 'undefined'
    });
  }
}
