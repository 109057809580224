import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingDatePipe } from './booking-date.pipe';

@NgModule({
  declarations: [BookingDatePipe],
  imports: [CommonModule],
  exports: [BookingDatePipe]
})
export class BookingDateModule {}
