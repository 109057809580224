import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class AcceptableArticleIdentifierGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const canActivate = route.params.id && !isNaN(+route.params.id);

    if (!canActivate) {
      this._router.navigate(['/blog']);
    }

    return canActivate;
  }
}
