import { Injectable } from '@angular/core';
import { VisibilityCondition } from '@app/cdk/conditions';
import { SidenavBodyComponent, SidenavHeaderComponent } from '@app/modules/sidenav/hosts';
import {
  SidenavAdminLogoutComponent,
  SidenavNavigationPrimaryWidgetComponent,
  SidenavNavigationPrimaryWidgetService
} from '@app/modules/sidenav/components';
import { ViewHostCandidateUnionMap } from '@app/modules/sidenav';
import { HeaderLogoComponent } from '@app/modules/ui-kit/widgets/header-logo';

@Injectable()
export class SidenavAdminSchema implements ViewHostCandidateUnionMap {
  // @ts-expect-error TS2416
  sidenav = {
    header: {
      component: SidenavHeaderComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    body: {
      component: SidenavBodyComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    footer: null
  };

  // @ts-expect-error TS2416
  header = {
    logo: {
      component: HeaderLogoComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    current: null,
    workspaceSwitch: null
  };

  body = {
    topNavigation: {
      component: SidenavNavigationPrimaryWidgetComponent,
      condition: VisibilityCondition.always,
      context: SidenavNavigationPrimaryWidgetService
    },
    bottomNavigation: {
      component: SidenavAdminLogoutComponent,
      condition: VisibilityCondition.always,
      context: null
    }
  };

  // @ts-expect-error TS2416
  footer = null;
}
