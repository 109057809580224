<div class="side-panel">
  <div class="program-sidebar-wrapper">
    <nav ngbNav [(activeId)]="activeId" #nav="ngbNav" class="nav-tabs">
      <ng-container [ngbNavItem]="'path'">
        <div class="nav-tabs-item" [class.active]="activeId === 'path'">
          <a ngbNavLink i18n="@@programModulesBoard.programPath">Path</a>
        </div>
        <ng-template ngbNavContent>
          <ng-container *ngIf="isDesktop | async; else programSidebar">
            <ng-scrollbar>
              <ng-container *ngTemplateOutlet="programSidebar"></ng-container>
            </ng-scrollbar>
          </ng-container>

          <ng-template #programSidebar>
            <div class="program-progress">
              <div class="name" *ngIf="program">
                {{ program?.name }}
              </div>
              <app-module-selector
                *ngIf="program"
                #moduleSelect
                [currentModuleIndex]="currentModuleIndex"
                [programModules]="programModules"
                (select)="selectModule($event)"></app-module-selector>
            </div>
          </ng-template>
        </ng-template>
      </ng-container>

      <ng-container [ngbNavItem]="'note'" *ngIf="currentModule && !isPreview">
        <div class="nav-tabs-item" [class.active]="activeId === 'note'">
          <a ngbNavLink i18n="@@programModulesBoardPrivateNotes">Private Notes</a>
        </div>
        <ng-template ngbNavContent>
          <div class="note-wrapper">
            <ng-container *ngIf="isDesktop | async; else moduleNotes">
              <ng-scrollbar>
                <ng-container *ngTemplateOutlet="moduleNotes"></ng-container>
              </ng-scrollbar>
            </ng-container>

            <ng-template #moduleNotes>
              <app-text-editor-notes
                [noteDelta]="currentModuleNote?.contentDeltaFormat || ''"
                [noteHtml]="currentModuleNote?.content || ''"
                [noteText]="currentModuleNote?.contentText || ''"
                (noteChange)="onNoteChange($event, false)"
                (endEditing)="onNoteChange($event, true)"
                [emptyNotePreview]="emptyNotePreview"
                [shouldClamp]="false"></app-text-editor-notes>
            </ng-template>

            <ng-template #emptyNotePreview>
              <div class="add-note-wrapper">
                <div class="add-new-note">
                  <span i18n="@@programModulesBoardStartTyping">Start typing...</span>
                </div>
              </div>
            </ng-template>

            <div class="note-meta">
              <div class="note-status" [ngSwitch]="currentModuleNoteStatus">
                <span class="saved" *ngSwitchCase="'saved'" i18n="@@programModulesBoardNoteStatusSaved">saved</span>
                <span class="unsaved" *ngSwitchCase="'unsaved'" i18n="@@programModulesBoardNoteStatusUnsaved">
                  changes unsaved
                </span>
                <span class="unsaved" *ngSwitchCase="'unsaved-new'" i18n="@@programModulesBoardNoteStatusUnsavedNew">
                  changes unsaved
                </span>
                <span class="pending" *ngSwitchCase="'new-pending'" i18n="@@programModulesBoardNoteStatusSaving">
                  saving...
                </span>
                <span class="pending" *ngSwitchCase="'save-pending'" i18n="@@programModulesBoardNoteStatusSaving">
                  saving...
                </span>
                <span *ngSwitchDefault></span>
              </div>
            </div>

            <div class="all-notes" (click)="onAllNotesShow()">
              <span i18n="@@programModulesBoardShowAllNotes">Show all notes</span>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div class="sidebar-content" [ngbNavOutlet]="nav"></div>
  </div>
</div>

<div class="main-panel">
  <ng-container *ngIf="isDesktop | async; else programMainContent">
    <ng-scrollbar #mainPanelScrollBar>
      <ng-container *ngTemplateOutlet="programMainContent"></ng-container>
    </ng-scrollbar>
  </ng-container>

  <ng-template #programMainContent>
    <ng-template [ngIf]="isQuizModule" [ngIfElse]="otherModules">
      <ng-container *ngIf="isProgramStarted">
        <div class="program-content-pane program-content">
          <ng-template *ngTemplateOutlet="moduleHeader; context: { currentModule: currentModule }"></ng-template>
        </div>
        <app-quiz-module
          #quizModule
          *ngIf="isQuizModule"
          [module]="currentModule"
          (finishQuiz)="finishQuiz.emit($event)"
          (retryQuiz)="retryQuizByModule($event)"
          (submitQuiz)="onQuizSubmit($event)"
          (nextModule)="nextModule()"
          class="quiz-module"></app-quiz-module>

        <div class="program-content-pane program-content-pane_sticky">
          <div class="program-content" *ngIf="isProgramUpcoming || isProgramNotStarted">
            <app-upcoming-program *ngIf="isProgramUpcoming"></app-upcoming-program>

            <app-start-program *ngIf="isProgramNotStarted" (startProgram)="startProgram.emit()"></app-start-program>
          </div>

          <app-module-actions-board
            *ngIf="!isLastModule || currentModule?.status !== 'seen'"
            [module]="currentModule"
            [isNextModuleAvailable]="isNextModuleAccessible"
            (finishQuiz)="finishQuiz.emit($event)"
            (retryQuiz)="retryQuizByModule($event)"
            (complete)="moduleComplete.emit(currentModule.id)"
            (nextModule)="nextModule()"></app-module-actions-board>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #otherModules>
      <div class="program-content-pane program-content-pane_sticky">
        <div class="program-content">
          <ng-container *ngIf="isProgramStarted">
            <ng-template *ngTemplateOutlet="moduleHeader; context: { currentModule: currentModule }"></ng-template>

            <app-content-module *ngIf="isContentModule" [module]="currentModule"></app-content-module>

            <app-session-module
              *ngIf="isGroupSessionModule || isSessionModule"
              [module]="currentModule"
              (book)="book()"
              (reschedule)="reschedule()"
              (cancel)="cancel()"></app-session-module>
          </ng-container>

          <app-upcoming-program *ngIf="isProgramUpcoming"></app-upcoming-program>

          <app-start-program *ngIf="isProgramNotStarted" (startProgram)="startProgram.emit()"></app-start-program>
        </div>

        <app-module-actions-board
          *ngIf="!isLastModule || currentModule?.status !== 'seen'"
          [module]="currentModule"
          [isNextModuleAvailable]="isNextModuleAccessible"
          (complete)="moduleComplete.emit(currentModule.id)"
          (nextModule)="nextModule()"></app-module-actions-board>
      </div>
    </ng-template>
    <app-program-done
      *ngIf="!isPreview && !isProgramNotStarted && isProgramFinished"
      completionDate=""></app-program-done>
  </ng-template>
</div>

<ng-template #progress let-class="class">
  <div class="progress-block" [ngClass]="class" *ngIf="program">
    <div class="text">
      <ng-container i18n="@@progress">Progress</ng-container>
      &nbsp;
      <span class="percents d-md-none">{{ progressValue | percent }}</span>
    </div>

    <div class="progress-view">
      <div class="percents">
        {{ progressValue | percent }}
      </div>

      <div class="bar">
        <div class="line" [ngStyle]="{ width: 100 * progressValue + '%' }"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #moduleHeader let-currentModule="currentModule">
  <app-module-instructor
    *ngIf="
      currentModule?.instructor &&
      currentModule.type !== ModuleTypes.SESSION &&
      currentModule.type !== ModuleTypes.GROUP_SESSION &&
      !isAdmin
    "
    [instructor]="currentModule.instructor"></app-module-instructor>

  <div class="module-header">
    <h6
      class="module-index"
      *ngIf="currentModule?.type === ModuleTypes.SESSION || currentModule?.type === ModuleTypes.GROUP_SESSION">
      <ng-template
        [ngIf]="currentModule.type === ModuleTypes.SESSION || currentModule.type === ModuleTypes.GROUP_SESSION">
        <app-guide-service-badge
          text="Session"
          i18n-text="@@serviceModuleSelectorNewSessionBadge"
          class="individual-session-badge"></app-guide-service-badge>
      </ng-template>
      <ng-template [ngIf]="currentModule.type === ModuleTypes.GROUP_SESSION"> </ng-template>
    </h6>
    <div
      *ngIf="currentModule?.title"
      class="module-title-container"
      [innerHTML]="currentModule?.title | safeHtml"></div>
    <div
      class="module-description-container"
      [innerHTML]="currentModule?.description | safeHtml"
      *ngIf="currentModule?.description"></div>
  </div>
</ng-template>
