export function cleanQuillContentMarkup(content: string): string {
  let cleanContent = content.replace(/((<p><br><\/p>)){2,}/gim, '<p><br></p>');

  if (cleanContent.slice(-11).toLowerCase() === '<p><br></p>') {
    cleanContent = cleanContent.slice(0, -11);
  }

  return cleanContent;
}

export function cleanQuillContentText(text: string): string {
  return text.replace(/(\n){2,}/gi, '\n');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cleanQuillDeltaContent(contentRepresentation: { ops: any[] }): any {
  contentRepresentation.ops.forEach(node => {
    if (node.insert && typeof node.insert === 'string') {
      node.insert = node.insert.replace(/(\n){2,}/gi, '\n');
    }
  });

  return contentRepresentation;
}

export function cleanHTML(html: string): string {
  // regexp to detect script tags in html
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  return html.replace(regex, '');
}
