<ng-container *ngIf="canShareNotes">
  <button
    pui-icon-button
    appearance="secondary"
    shape="square"
    data-qa-id="guide-relation-notes-board-toolbar-access-to-all-notes-button"
    pui-tooltip-direction="left"
    pui-tooltip="Access to all notes I created"
    i18n-pui-tooltip="&quot;@@guideShareAllClientNotesHint"
    (click)="openSharingSettings()">
    <pui-icon svgIcon="pui:users"></pui-icon>
  </button>
</ng-container>
