<div class="modal-header">
  <h3 data-qa-id="tags-selector-modal-select-tags-title" transloco="selectTags">Select tags</h3>
</div>
<div class="modal-body">
  <form [formGroup]="tagsForm">
    <div class="search-control">
      <button class="btn search-btn"></button>
      <input
        id="name"
        type="text"
        class="form-control search-input"
        formControlName="search"
        placeholder="tag search"
        i18n-placeholder="@@tagSearch"
        (input)="filter()" />
    </div>
  </form>
  <ng-scrollbar [ngStyle]="{ 'height.px': 348 }" [visibility]="'hover'" [appearance]="'compact'">
    <div class="tags-list">
      <ng-container *ngFor="let group of filteredTags; index as i">
        <ng-container *ngIf="group.children.length > 0">
          <div *ngIf="i > 0" class="dropdown-divider"></div>
          <h6 class="header">{{ group.name }}</h6>
          <div
            *ngFor="let tag of group.children"
            (click)="selectTag(tag.id)"
            class="tag"
            [ngClass]="{ active: selected.includes(tag.id) }">
            <span [attr.data-qa-id]="'tags-selector-modal-tag-name-label-' + tag.name">{{ tag.name }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>
<div class="modal-footer">
  <button
    pui-button
    data-qa-id="tags-selector-modal-submit-button"
    type="button"
    size="s"
    appearance="secondary"
    (click)="save($event)"
    transloco="submit">
    Submit
  </button>
  <button
    pui-button
    data-qa-id="tags-selector-modal-cancel-button"
    type="button"
    size="s"
    appearance="secondary"
    (click)="cancel($event)"
    transloco="cancel">
    Cancel
  </button>
</div>
