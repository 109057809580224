import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { locale } from '@env/locale.main';
import { ILocale } from '@env/locale.interface';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  locale: ILocale;

  constructor(@Inject(LOCALE_ID) public localeId: string) {
    // @ts-expect-error TS7053
    this.locale = locale[this.localeId];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getLocale() {
    return this.locale;
  }
}
