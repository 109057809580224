<nav ngbNav [(activeId)]="activeId" #nav="ngbNav" class="nav-tabs" (activeIdChange)="handleTabChange($event)">
  <ng-container [ngbNavItem]="'my'">
    <a ngbNavLink i18n="@@myPrograms" [class.active]="activeId === 'my'">My programs</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-4 program-wrapper" *ngFor="let program of clientPrograms">
          <app-program-item
            class="program"
            *ngIf="program.enrolled; else invitedProgramTemplate"
            [program]="program"
            (programSelect)="onProgramSelect($event)"
            [programLink]="composeProgramLink(program)"></app-program-item>
          <ng-template #invitedProgramTemplate>
            <app-program-item
              class="program"
              [program]="program"
              (programSelect)="onProgramSelect($event)"
              (declineInvite)="onInviteDeclined($event)"
              [programLink]="composeProgramLink(program)"></app-program-item>
          </ng-template>
        </div>
      </div>
      <app-no-programs-found *ngIf="!clientPrograms || !clientPrograms.length"></app-no-programs-found>
    </ng-template>
  </ng-container>
  <ng-container [ngbNavItem]="'all'" *ngIf="marketplaceProgramsAvailableCount > 0">
    <a ngbNavLink i18n="@@allPrograms" [class.active]="activeId === 'all'">All programs</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col">
          <app-marketplace-filter-panel></app-marketplace-filter-panel>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-4 program-wrapper" *ngFor="let program of marketplacePrograms">
          <app-program-item
            class="program"
            [program]="program"
            (programSelect)="onProgramSelect($event)"
            [programLink]="composeProgramLink(program)"></app-program-item>
        </div>
      </div>

      <div class="show-more" *ngIf="hasCursor()" (click)="showMore()">
        <span i18n="@@showMoreBtn">Show more</span>
      </div>

      <app-no-programs-found *ngIf="!marketplacePrograms || !marketplacePrograms.length"></app-no-programs-found>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
