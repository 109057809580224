<label class="app-checkbox {{ className }}">
  <input
    [disabled]="disabled"
    type="checkbox"
    [(ngModel)]="value"
    [attr.checked]="value"
    [attr.data-qa-id]="qaId && qaId + '-native'" />
  <span class="checkmark" [attr.data-qa-id]="qaId"></span>
  <span class="text-label"><ng-content></ng-content></span>
</label>
