import { Component, Input, Output, EventEmitter, forwardRef, Attribute } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true
    }
  ],
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any = '';

  // @ts-expect-error TS2564
  showPassword: false;

  type: 'password' | 'text' = 'password';

  @Input() autocomplete = true;

  @Input() className = '';

  @Input() componentId?: string;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Output() change = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._value;
  }

  // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-attribute-decorator
  constructor(@Attribute('qaId') public qaId: string) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  onChange = value => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggle() {
    this.type = this.showPassword ? 'text' : 'password';
  }

  // hack to fix Error: ExpressionChangedAfterItHasBeenCheckedError
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length, @typescript-eslint/no-explicit-any
  keyDownEnter(e: any) {
    const domInput = e.target;
    domInput.blur();
    domInput.focus();
  }
}
