import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { PlatformDateTimePipe } from './pipes/platform-date-time.pipe';
import { PlatformDatePipe } from './pipes/platform-date.pipe';
import { PlatformTimePipe } from './pipes/platform-time.pipe';
import { PlatformDateTimeHybridPipe } from './pipes/platform-date-time-hybrid.pipe';
import { PLATFORM_STYLE_CONFIG, PlatformStyle } from './services/platform-style.service';
import { PLATFORM_STYLE_SCHEMA } from './config';
import { PlatformCurrencyPipe } from './pipes/platform-currency.pipe';

@NgModule({
  declarations: [
    PlatformDateTimePipe,
    PlatformDatePipe,
    PlatformTimePipe,
    PlatformCurrencyPipe,
    PlatformDateTimeHybridPipe
  ],
  imports: [CommonModule],
  exports: [PlatformDateTimePipe, PlatformDatePipe, PlatformTimePipe, PlatformCurrencyPipe, PlatformDateTimeHybridPipe],
  providers: [PlatformStyle, { provide: PLATFORM_STYLE_CONFIG, useValue: PLATFORM_STYLE_SCHEMA }, CurrencyPipe]
})
export class PlatformStyleModule {}
