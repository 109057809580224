import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientSessionIconComponent } from './client-session-icon.component';
import { PuiAvatarModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [ClientSessionIconComponent],
  imports: [CommonModule, PuiAvatarModule],
  exports: [ClientSessionIconComponent, ClientSessionIconComponent]
})
export class ClientSessionIconModule {}
