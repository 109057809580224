import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { SocketService } from '@app/core/socket/socket.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from '../auth/services/auth.service';
import { IMembershipPlan, IMembershipSettings, IUserMembership } from './types';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { MembershipCongratulationsDialogComponent } from '@app/screens/membership/components/membership-congratulations-dialog/membership-congratulations-dialog.component';
import { MembershipApiService } from '@app/core/membership/membership-api.service';

@Injectable()
export class MembershipService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly clientPlansSubject$ = new ReplaySubject<IMembershipPlan[]>(1);
  private readonly coachPlansSubject$ = new ReplaySubject<IMembershipPlan[]>(1);
  private readonly membershipSettingsSubject$ = new ReplaySubject<IMembershipSettings>(1);
  private readonly plansSubject$ = new BehaviorSubject<IMembershipPlan[]>([]);
  private readonly userPlanSubject$ = new BehaviorSubject<IMembershipPlan | null>(null);

  readonly membershipSettings$: Observable<IMembershipSettings> = this.membershipSettingsSubject$.asObservable();
  readonly isMembershipEnabledForCoaches$: Observable<boolean> = this.membershipSettings$.pipe(
    map(settings => settings.enabledForCoaches)
  );
  readonly isMembershipEnabledForClients$: Observable<boolean> = this.membershipSettings$.pipe(
    map(settings => settings.enabledForClients)
  );
  readonly plans$: Observable<IMembershipPlan[]> = this.plansSubject$.asObservable();
  readonly userPlan$: Observable<IMembershipPlan | null> = this.userPlanSubject$.asObservable();

  constructor(
    private readonly authService: AuthService,
    private readonly socketService: SocketService,
    private readonly dialog: PuiDialogService,
    private readonly membershipApi: MembershipApiService
  ) {
    const membership$ = this.authService
      .onMembershipChanged$()
      .pipe(
        distinctUntilChanged(
          (membershipA, membershipB) =>
            membershipA?.name === membershipB?.name && membershipA?.status === membershipB?.status
        )
      );
    const activePlans$ = this.plans$.pipe(map(plans => plans.filter(plan => plan.active)));

    const setUserPlan$ = combineLatest([membership$, activePlans$]).pipe(
      tap(([membership, activePlans]) => {
        if (membership) {
          const plan = activePlans.find(_plan => _plan.type === membership.platformPlanType);
          if (plan) {
            return this.userPlanSubject$.next(plan);
          }

          return this.userPlanSubject$.next(null);
        } else {
          this.userPlanSubject$.next(null);
        }
      })
    );

    setUserPlan$.pipe(takeUntil(this.destroy$)).subscribe();

    this.socketService
      .onMembershipChanged$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateMembershipSettings());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get currentUserPlan(): IMembershipPlan | null {
    return this.plansSubject$.value.find(({ type }) => type === this.userPlanSubject$.value?.type) || null;
  }

  cancelSubscription(id: number, data: { reason?: string }): Observable<IUserMembership> {
    return this.membershipApi.cancelSubscription$(id, data).pipe(
      tap(membership => {
        this.authService.setUserMembership(membership);
      })
    );
  }

  updatePlans(): void {
    this.membershipApi
      .getMembershipSettings$()
      .pipe(take(1))
      .subscribe(({ membershipSettings, plans }) => {
        this.membershipSettingsSubject$.next(membershipSettings);
        this.plansSubject$.next(plans);
        this.clientPlansSubject$.next(plans.filter(plan => plan.active && plan.roles.includes(UserRoles.CLIENT)));
        this.coachPlansSubject$.next(plans.filter(plan => plan.active && plan.roles.includes(UserRoles.GUIDE)));
      });
  }

  updateMembershipSettings(): void {
    forkJoin([this.membershipApi.getMembershipSettings$(), this.membershipApi.getCurrentMembership$()])
      .pipe(take(1))
      .subscribe(([{ membershipSettings, plans }, currentMembership]) => {
        this.authService.setUserMembership(currentMembership);
        this.membershipSettingsSubject$.next(membershipSettings);
        this.plansSubject$.next(plans);
        this.clientPlansSubject$.next(plans.filter(plan => plan.active && plan.roles.includes(UserRoles.CLIENT)));
        this.coachPlansSubject$.next(plans.filter(plan => plan.active && plan.roles.includes(UserRoles.GUIDE)));
      });
  }

  getLastMembership$(): Observable<IUserMembership | undefined> {
    return this.membershipApi.getLastMembership$();
  }

  openCongratulationsDialog(): void {
    this.membershipApi
      .getCurrentMembership$()
      .pipe(
        take(1),
        switchMap(
          membership =>
            this.dialog.open(MembershipCongratulationsDialogComponent, {
              hasCloseButton: true,
              size: 's',
              data: {
                membership
              }
            }).afterClosed$
        )
      )
      .subscribe();
  }
}
