import { SessionAttributeFunction } from '@libs/business/cdk/session';
import {
  clientSessionTimeAttribute,
  clientSessionGuideAttribute,
  clientSessionConnectionTypeAttribute,
  clientSessionRecurringAttribute,
  clientSessionGuestsAttribute
} from '../functions';
import { ClientSessionAttributesEnum } from '../enums';
import { ClientSessionAttribute, ClientSessionAttributeOptions } from '../types';

export const CLIENT_SESSION_ATTRIBUTE_FACTORIES: Record<
  ClientSessionAttributesEnum,
  SessionAttributeFunction<ClientSessionAttributeOptions, ClientSessionAttribute>
> = {
  [ClientSessionAttributesEnum.TIME]: clientSessionTimeAttribute,
  [ClientSessionAttributesEnum.GUIDE]: clientSessionGuideAttribute,
  [ClientSessionAttributesEnum.CONNECTION_TYPE]: clientSessionConnectionTypeAttribute,
  [ClientSessionAttributesEnum.RECURRING]: clientSessionRecurringAttribute,
  [ClientSessionAttributesEnum.GUESTS]: clientSessionGuestsAttribute
};
