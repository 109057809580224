<div class="stepper">
  <div
    class="step"
    *ngFor="let step of steps; let i = index"
    [ngClass]="{ active: i === activeStep, ok: i < activeStep }">
    <div class="step-indicator">
      <div class="step-number">
        {{ i + 1 }}
      </div>
      <div class="step-name">
        {{ step }}
      </div>
    </div>
    <div class="line"></div>
  </div>
</div>
