<div
  *ngIf="mode$ | async as mode"
  class="app-sidenav app-sidenav__container"
  [ngClass]="{ 'app-sidenav__collapse': mode === 'short' }">
  <div class="app-sidenav__header">
    <ng-template portalHost #header="cdkPortalHost"></ng-template>
  </div>

  <div class="app-sidenav__body">
    <ng-template portalHost #body="cdkPortalHost"></ng-template>
  </div>

  <div class="app-sidenav__footer">
    <ng-template portalHost #footer="cdkPortalHost"></ng-template>
  </div>
</div>
