import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { Avatar } from '@awarenow/profi-ui-core/lib/components/avatar/pui-avatar.component';
import { normalizeFullName } from '@app/shared/utils/full-name';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';

@Component({
  selector: 'app-guide-service-team',
  templateUrl: './guide-service-team.component.html',
  styleUrls: ['./guide-service-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceTeamComponent {
  @Input()
  // @ts-expect-error TS2564
  team: WorkspaceUser[];

  @Input()
  avatarsLimit = 2;

  get avatars(): Avatar[] {
    // @ts-expect-error TS2322
    return WorkspaceUtility.filterHosts(this.team).map((member: WorkspaceUser) => ({
      avatarUrl: member.photo,
      text: normalizeFullName(member)
    }));
  }
}
