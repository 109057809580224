import { Pipe, PipeTransform } from '@angular/core';
import { IPriceSettings } from '@app/modules/guide-service-editor/services/guide-price-editor.service';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

@Pipe({
  name: 'pricing'
})
export class PricingPipe<
  TService extends Pick<IPriceSettings, 'isFree' | 'price' | 'subscriptionPrice' | 'subscriptionRecurrency'>
> implements PipeTransform
{
  constructor(private readonly platformStyle: PlatformStyle) {}

  /**
   * Accepts service, and returns pricing details
   * If service is free - returns 'Free'
   * If service is paid and payment option is 'one-time-payment' - returns price with platform currency. As example '15$'
   * If service payment is based on subscription - returns billing installment price and period. As example '15$ / month'
   * @param service
   */
  transform(service: TService): string {
    const price = service.price ? service.price : service.subscriptionPrice;
    return service.isFree
      ? 'Free'
      : this.platformStyle.transformCurrency(price!) +
          (!service.price && service.subscriptionRecurrency ? `/${service.subscriptionRecurrency}` : '');
  }
}
