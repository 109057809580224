import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ProgramsRouteFilterAdapter } from './programs-route-filter-adapter.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IAdditionalQueryParams {
  limit?: number;
  cursor?: number;
}

@Injectable({
  providedIn: 'root'
})
export class MarketplaceProgramFilterApiAdapterService {
  constructor(private _routerFilterAdapter: ProgramsRouteFilterAdapter) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getQueryParams$(additionalQueryParams: IAdditionalQueryParams = {}) {
    return this._routerFilterAdapter.filterParams$.pipe(
      map(
        ({
          priceFrom,
          priceTo,
          startDateFrom,
          startDateTo,
          startDateSelfPaced,
          issue,
          approach,
          practitioner,
          language,
          sorting
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          // @ts-expect-error TS7034
          let queryParams = [];

          if (priceFrom) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, `price[from]=${priceFrom}`];
          }

          if (priceTo) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, `price[to]=${priceTo}`];
          }

          if (startDateFrom) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, `startDate[from]=${startDateFrom}`];
          }

          if (startDateTo) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, `startDate[to]=${startDateTo}`];
          }

          if (startDateSelfPaced) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, `startDate[selfPaced]=${startDateSelfPaced}`];
          }

          if (issue) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, ...issue.map(issueId => `issues[]=${issueId}`)];
          }

          if (approach) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, ...approach.map(approachId => `approaches[]=${approachId}`)];
          }

          if (practitioner) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, ...practitioner.map(practitionerId => `practitioner[]=${practitionerId}`)];
          }

          if (language) {
            // @ts-expect-error TS2322
            queryParams = [...queryParams, ...language.map(languageId => `lang[]=${languageId}`)];
          }

          if (sorting) {
            let paramString = '';
            switch (sorting) {
              case 'startDateAsc':
                paramString = `sortby[start]=asc`;
                break;
              case 'priceDesc':
                paramString = `sortby[price]=desc`;
                break;
              case 'priceAsc':
                paramString = `sortby[price]=asc`;
                break;
            }
            // @ts-expect-error TS2322
            queryParams = paramString ? [...queryParams, paramString] : queryParams;
          }

          const { cursor, limit } = additionalQueryParams;

          if (cursor) {
            queryParams = [...queryParams, `cursor=${cursor}`];
          }

          if (limit) {
            queryParams = [...queryParams, `limit=${limit}`];
          }
          // Beat Angular cache
          queryParams = [...queryParams, `t=${Date.now()}`];

          return queryParams.join('&');
        }
      )
    );
  }
}
