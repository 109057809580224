import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientGuide } from '@app/core/users/types';

@Component({
  selector: 'app-book-widget',
  templateUrl: './book-widget.component.html',
  styleUrls: ['./book-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookWidgetComponent {
  readonly sessionTypeToStringMap = {
    session30: 'free 30-min video sessions',
    session60: 'free 60-min video sessions'
  };

  @Input()
  // @ts-expect-error TS2564
  guide: ClientGuide;

  @Input()
  // @ts-expect-error TS7008
  programSessions;

  @Output()
  bookSession = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get programSession() {
    return this.programSessions ? this.programSessions[0] : null;
  }
}
