import { Timezones } from '@app/shared/utils/timezones';
import { DateTime } from 'luxon';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITimezoneOption {
  name: string;
  value: string;
}

export function convertTimezoneToOption(tzName: string): ITimezoneOption {
  const dateTime = DateTime.local().setZone(tzName);

  return {
    name: `${tzName} (UTC${dateTime.offset !== 0 ? dateTime.toFormat('Z') : ''})`,
    value: tzName
  };
}

export function generateCustomTimezoneOptions(timezones: string[], firstTimezone?: string): ITimezoneOption[] {
  return firstTimezone
    ? [].concat(
        // @ts-expect-error TS2769
        [convertTimezoneToOption(firstTimezone)],
        timezones.filter(tzName => tzName !== firstTimezone).map(tzName => convertTimezoneToOption(tzName))
      )
    : timezones.map(tzName => convertTimezoneToOption(tzName));
}

export function generateTimezoneOptions(): ITimezoneOption[] {
  return generateCustomTimezoneOptions(Timezones, DateTime.local().zoneName);
}
