<h2 class="recording-note-header">
  <span i18n="@@noteRecording_Recording">Recording</span>
  <span>{{ noteText }}</span>
  <span>{{ noteRecording.createdAt | sessionDate }}</span>
</h2>
<div class="note-container" *ngIf="isRecordingAvailable">
  <div class="d-flex">
    <div>
      <div class="file-icon"></div>
    </div>
    <div class="flex-grow-1">
      <dl class="video-info">
        <dt>archive.mp4</dt>
        <dd>{{ (noteRecording.size / 1048576 || 0).toFixed(2) }} Mb</dd>
      </dl>
    </div>
    <div>
      <ng-container *ngIf="downloadArchiveUrl">
        <a [href]="downloadArchiveUrl" target="_blank"><app-button className="btn-download"></app-button></a>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-10">
      <div *puiLet="videoUrl$ | async as videoUrl" class="video-container">
        <div class="video-wrapper">
          <video #video controls *ngIf="videoUrl" (play)="onPlay()">
            <source [src]="videoUrl" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="availability-info" *ngIf="isRecordingAvailable && (recordingExpirationDate | archiveExpirationTime)">-->
<!--  <div class="availability-info-icon"></div>-->
<!--  <span i18n="@@noteRecording_archiveExpiresIn">The link will expire in</span>-->
<!--  &nbsp;-->
<!--  <span class="expiration-time">{{ recordingExpirationDate | archiveExpirationTime }}</span>-->
<!--</div>-->

<div class="availability-info" *ngIf="!isRecordingAvailable">
  <div class="availability-info-icon"></div>
  <span i18n="@@noteRecording_unavailabilityInfo">This recording has expired, download unavailable.</span>
</div>
