import { ProgramService } from './program-service';

export class LocalProgramService {
  readonly programService: Readonly<ProgramService>;
  readonly localStamp?: string;

  constructor(programService: ProgramService, localStamp?: string) {
    this.programService = programService;

    if (localStamp) {
      this.localStamp = localStamp;
    }
  }
}
