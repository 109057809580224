import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoweredByLinkComponent } from './powered-by-link/powered-by-link.component';

@NgModule({
  declarations: [PoweredByLinkComponent],
  exports: [PoweredByLinkComponent],
  imports: [CommonModule]
})
export class PoweredByLinkModule {}
