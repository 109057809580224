<pui-drawer-content>
  <!--HEADER-->
  <ng-template #header>
    <h2 pui-typography="h2">Client subscription</h2>
  </ng-template>

  <ng-template #main>
    <div class="main__container">
      <form [formGroup]="form" class="pui-form">
        <div class="pui-form__row">
          <!--NEXT PAYMENT DATE-->
          <div class="pui-form__field pui-form__field_date">
            <label pui-input-label>Next payment</label>
            <pui-input-date formControlName="nextPaymentDate" [puiDatepickerMinDate]="minDate"></pui-input-date>
          </div>
        </div>
        <pui-alert type="info" class="pui-space_top-3">
          <pui-icon class="icon" svgIcon="pui:info" [size]="20"></pui-icon>
          <span pui-typography="body-s">
            Changing the next payment date will also update the recurring schedule for all future payments.
          </span>
        </pui-alert>
      </form>
    </div>
  </ng-template>

  <ng-template #footer>
    <button pui-button [disabled]="form.invalid" (click)="submit()" [isLoading]="isSubmitting$ | async">
      Save changes
    </button>
  </ng-template>
</pui-drawer-content>
