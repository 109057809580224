import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { GuideServicesUtilsService } from '@app/screens/guide/services/guide-services-utils.service';

const SERVICES_ROUTE_SEGMENT = 'services';
const DEFAULT_SERVICES_ROUTE_SEGMENT = 'default-service';

@Component({
  selector: 'app-new-service-button',
  templateUrl: './new-service-button.component.html',
  styleUrls: ['./new-service-button.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewServiceButtonComponent {
  @Input() returnAfterSave!: boolean;
  @Output() beforeNavigate = new EventEmitter();
  readonly packagesEnabled!: boolean;
  userRole: UserRoles | undefined;
  serviceTypes = GuideServiceTypes;

  readonly canCreateService$ = this.guideServicesUtilsService.canCreateService$;

  get rolePath(): string {
    return this.userRole === UserRoles.GUIDE ? `${environment.guideRoute}` : 'admin';
  }

  constructor(
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly authService: AuthService,
    private guideServicesUtilsService: GuideServicesUtilsService
  ) {
    this.userRole = this.authService.getUserRoleId();
    this.packagesEnabled = !!this.runtimeConfigService.get('guidePackagesEnabled');
  }

  servicePath(serviceType: GuideServiceTypes): string {
    const servicesSegment =
      this.userRole === UserRoles.GUIDE
        ? serviceType === this.serviceTypes.PROGRAM
          ? ``
          : `/${SERVICES_ROUTE_SEGMENT}`
        : `/${DEFAULT_SERVICES_ROUTE_SEGMENT}`;

    return `${this.rolePath}${servicesSegment}`;
  }
}
