import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WorkspacesMenuComponent } from '@app/modules/workspaces/components/workspaces-menu/workspaces-menu.component';
import { IWidgetData, IWidgetService } from '@app/cdk/widgets';
import { ISidenavWorkspaceSwitchView } from '@app/modules/sidenav/components/sidenav-workspace-switch/sidenav-workspace-switch-widget.component';
import { SidenavWorkspaceSwitchButtonComponent } from '@app/modules/sidenav/components/sidenav-workspace-switch/view/workspace-switch-button.component';

@Injectable()
export class SidenavWorkspaceSwitchWidgetService implements IWidgetService<ISidenavWorkspaceSwitchView> {
  view$: Observable<IWidgetData<ISidenavWorkspaceSwitchView>>;

  constructor() {
    this.view$ = of({
      component: SidenavWorkspaceSwitchButtonComponent,
      context: {
        popup: WorkspacesMenuComponent
      }
    });
  }
}
