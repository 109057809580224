import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { VariantSettings } from '@app/screens/guide/guide-programs/components/program-cover-crop-modal/variant-settings';
import { ProgramCoverCropModalComponent } from '@app/screens/guide/guide-programs/components/program-cover-crop-modal/program-cover-crop-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-cover-image-wrapper',
  templateUrl: './cover-image-wrapper.component.html',
  styleUrls: ['./cover-image-wrapper.component.scss']
})
export class CoverImageWrapperComponent implements OnChanges {
  @Input() backgroundUrl!: string;
  @Input() imagePrefix!: string;
  @Input() recommendedDimensionsText!: string;
  @Input() disabled!: boolean;
  @Input() disableRemoveButton = false;
  @Input() coverVariantsSettings: VariantSettings[] = [];

  @Output() uploadImage = new EventEmitter<{
    coverImage: File;
    coverImageThumb: File;
    croppedImageString: string;
  }>();

  @Output() removeImage: EventEmitter<null> = new EventEmitter();

  state: 'idle' | 'loading' | 'ready' = 'idle';

  constructor(private _modal: NgbModal) {}

  ngOnChanges({ backgroundUrl }: SimpleChanges): void {
    if (backgroundUrl?.currentValue) {
      this.state = 'ready';
    } else {
      this.state = 'idle';
    }
  }

  /**
   * That method don't reset previous uploaded image. It only allows to upload a new image.
   */
  reset(): void {
    this.state = 'idle';
    // @ts-expect-error TS2322
    this.backgroundUrl = undefined;
    this.removeImage.emit();
  }

  onCoverImageInputChange(event: Event): void {
    const inputEl = event.target as HTMLInputElement;
    // @ts-expect-error TS2531
    const file = inputEl.files[0];

    if (!file || !/(\.jpg|\.jpeg|\.png)$/i.test(file.name)) {
      return;
    }

    this.state = 'loading';

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      // @ts-expect-error TS2531
      img.src = reader.result.toString();
      img.onload = async () => {
        try {
          const { componentInstance, result } = this._modal.open(ProgramCoverCropModalComponent, {
            size: 'lg'
          });
          componentInstance.event = event;
          componentInstance.type = file.type;
          if (this.imagePrefix) {
            componentInstance.imagePrefix = `${this.imagePrefix}-cover`;
          }
          componentInstance.windowTitle = this.imagePrefix;
          componentInstance.coverVariantsSettings = this.coverVariantsSettings;
          const {
            images: [coverImage, coverImageThumb],
            croppedImageString
          } = await result;

          this.uploadImage.emit({ coverImage, coverImageThumb, croppedImageString });
        } catch (err) {
          this.state = 'idle';
        } finally {
          // @ts-expect-error TS2322
          inputEl.value = null;
        }
      };
    };
    reader.readAsDataURL(file);
  }
}
