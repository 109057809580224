import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Component({
  selector: 'app-remove-service-modal',
  templateUrl: './remove-service-modal.component.html',
  styleUrls: ['./remove-service-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveServiceModalComponent {
  readonly GuideServiceTypes = GuideServiceTypes;

  @Input()
  // @ts-expect-error TS2564
  numberOfParticipants: number;

  @Input()
  // @ts-expect-error TS2564
  serviceType: GuideServiceTypes;

  constructor(public modal: NgbActiveModal) {}

  getServiceTitle(serviceType: GuideServiceTypes): string {
    switch (serviceType) {
      case GuideServiceTypes.SESSION:
      case GuideServiceTypes.GROUP_SESSION:
        return `session`;
      case GuideServiceTypes.PACKAGE:
        return `package`;
      case GuideServiceTypes.PROGRAM:
        return `program`;
      case GuideServiceTypes.WEBINAR:
        return `webinar`;
      default:
        return '';
    }
  }
}
