export interface GlobalConfig {
  blogBannerBackgroundImage: string;
  blogBannerLink: string;
  blogBannerText: string;
  blogBannerTitle: string;
  blogBusinessLogoText: string;
  blogBusinessSubTitle: string;
  blogLogoText: string;
  blogPageTitle: string;
  defaultLanguages: string;
  displayCoachesOfClient: boolean;
  displayHIPAACompliant: boolean;
  displayMoneyBackGuarantee: boolean;
  emailHelp: string;
  urlVisitHelpCenter: string;
  enableBlogNav: boolean;
  enableSessionRoomNav: boolean;
  enableECourcesNav: boolean;
  enableHelpMeFindAGuide: boolean;
  enableMarketplace: boolean;
  enablePayoutsOfCoach: boolean;
  enablePaypalPayoutOfCoach: boolean;
  enablePaymentsRequiredTaxIdWithAddresses: boolean;
  enableSecureMessagesInNotifications: boolean;
  enablePrograms: boolean;
  enableStripePayoutOfCoach: boolean;
  enableSurveys: boolean;
  hideAddLanguages: boolean;
  hideCoachReviews: boolean;
  hideGiftCertificate: boolean;
  hideHeadersDashboardSessionsBlocks: boolean;
  hideNavigatorLanguages: boolean;
  hideProfileLocationLanguage: boolean;
  hideProfileQualifications: boolean;
  hideProfileSalesText: boolean;
  hideProfileTags: boolean;
  hideSignInAs: boolean;
  hideSignUpAs: boolean;
  separatedAuth: boolean;
  hideWallet: boolean;
  homePageRoute: string;
  loginScreenBgUrl: string;
  metaDescriptionBlogPage: string;
  metaDescriptionGiftCouponsPage: string;
  metaDescriptionGuideProfilePage: string;
  metaDescriptionGuidesPage: string;
  metaDescriptionMainPage: string;
  metaDescriptionProgramPage: string;
  metaDescriptionSessionPage: string;
  metaDescriptionWikiPage: string;
  metaDescriptionPackagePage: string;
  metaImageBlogPage: string;
  metaImageGiftCouponsPage: string;
  metaImageGuideProfilePage: string;
  metaImageGuidesPage: string;
  metaImageMainPage: string;
  metaImageProgramPage: string;
  metaImageSessionPage: string;
  metaImageWikiPage: string;
  metaImagePackagePage: string;
  metaKeywordsBlogPage: string;
  metaKeywordsGiftCouponsPage: string;
  metaKeywordsGuideProfilePage: string;
  metaKeywordsGuidesPage: string;
  metaKeywordsMainPage: string;
  metaKeywordsProgramPage: string;
  metaKeywordsSessionPage: string;
  metaKeywordsWikiPage: string;
  metaKeywordsPackagePage: string;
  metaTitleBlogPage: string;
  metaTitleBookPage: string;
  metaTitleGiftCouponsPage: string;
  metaTitleGuideProfilePage: string;
  metaTitleGuidesPage: string;
  metaTitleMainPage: string;
  metaTitleProgramPage: string;
  metaTitleSessionPage: string;
  metaTitleWikiPage: string;
  metaTitlePackagePage: string;
  // @ts-expect-error TS7008
  urlCancellationPolicy;
  urlInLogo: string;
  urlPrivacy: string;
  urlTerms: string;
  showNewsletter: boolean;
  inPersonSessionsAvailable: boolean;
  inCallSessionsAvailable: boolean;
  ssoMode: SsoMode;
  isGoogleAuthEnabled: boolean;
  isFacebookAuthEnabled: boolean;
  showPriceStartsFrom: boolean;
  showWhiteLogoOnCoachesPage: boolean;
  enableServiceCreationForTeamMembers: boolean;
}

export enum SsoMode {
  DISABLED = 'disabled',
  ALL_USERS = 'all_users',
  CLIENT = 'client',
  PRACTITIONER = 'practitioner'
}
