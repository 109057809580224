import { QuizSchedule, QuizSendPolicy } from '@app/core/quizzes/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramQuestionnaire {
  id?: number;
  survey: number;
  sendPolicy: QuizSendPolicy;
  schedule: QuizSchedule | null;
}

export class ProgramQuestionnaire implements IProgramQuestionnaire {
  id?: number;
  survey: number;
  sendPolicy: QuizSendPolicy;
  schedule: QuizSchedule | null;

  constructor() {
    // @ts-expect-error TS2322
    this.id = null;
    // @ts-expect-error TS2322
    this.survey = null;
    this.sendPolicy = QuizSendPolicy.AfterBuyProgram;
    this.schedule = null;
  }

  clone(): ProgramQuestionnaire {
    const clone = new ProgramQuestionnaire();

    clone.id = this.id;
    clone.survey = this.survey;
    clone.sendPolicy = this.sendPolicy;
    clone.schedule = this.schedule;

    return clone;
  }

  setValues(questionnaire: ProgramQuestionnaire): ProgramQuestionnaire {
    this.id = questionnaire.id;
    this.survey = questionnaire.survey;
    this.sendPolicy = questionnaire.sendPolicy;
    this.schedule = questionnaire.schedule;

    return this;
  }
}
