<ng-container [ngSwitch]="connectionType">
  <ng-container *ngSwitchCase="SessionConnectionTypes.IN_PLATFORM">
    <img class="platform-icon" [src]="faviconUrl" alt="platform icon" />
  </ng-container>
  <ng-container *ngSwitchCase="SessionConnectionTypes.ZOOM">
    <pui-icon svgIcon="pui:zoom" [size]="iconSize" aria-hidden="true"></pui-icon>
  </ng-container>
  <ng-container *ngSwitchCase="SessionConnectionTypes.PHONE">
    <pui-icon svgIcon="pui:phone" [size]="iconSize" aria-hidden="true"></pui-icon>
  </ng-container>
  <ng-container *ngSwitchCase="SessionConnectionTypes.IN_PERSON">
    <pui-icon svgIcon="pui:place" [size]="iconSize" aria-hidden="true"></pui-icon>
  </ng-container>
</ng-container>
