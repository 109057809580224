import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingFormComponent, BillingFormWrapperComponent } from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { NgbActiveModal, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CountrySelectorSingleModule } from '@app/modules/ui-kit/widgets/country-selector-single';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { BillingFormModal } from './modals/billing-form/billing-form.modal';
import { ElementLoadingModule } from '@app/modules/ui-kit/element-loading/element-loading.module';
import { PuiTooltipModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [BillingFormComponent, BillingFormWrapperComponent, BillingFormModal],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    NgbTooltipModule,
    CountrySelectorSingleModule,
    FormModule,
    NgbModalModule,
    ElementLoadingModule,
    PuiTooltipModule
  ],
  providers: [NgbActiveModal],
  exports: [BillingFormComponent, BillingFormWrapperComponent, BillingFormModal]
})
export class BillingModule {}
