import { Input, Component } from '@angular/core';
import { getDayPostfix } from '@app/shared/utils/get-day-postfix';
import { CurrencyService } from '@app/core/currency/currency.service';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { LocaleService } from '@app/core/locale/locale.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-payment-options-selector',
  templateUrl: './payment-options-selector.component.html',
  styleUrls: ['./payment-options-selector.component.scss', '../../../../../scss/_radio.scss']
})
export class PaymentOptionsSelectorComponent {
  readonly PaymentOptions = PaymentOptions;

  // @ts-expect-error TS2564
  @Input() fullPrice: number;

  @Input() installmentsPrices: {
    paymentDate: string;
    amount: number;
  }[] = [];

  paymentOption = PaymentOptions.FULL_PRICE;

  dateTimeLocale: string;

  constructor(public currency: CurrencyService, private _localeService: LocaleService) {
    this.dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getDayPostfix(day) {
    return getDayPostfix(day, this.dateTimeLocale);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isTodayDay(date) {
    if (!this.dateTimeLocale.includes('en')) {
      return false;
    }

    return date.setLocale(this.dateTimeLocale).toRelativeCalendar() === 'today';
  }
}
