<section class="service-landing">
  <ng-container *puiLet="isMobile$ | async as isMobile">
    <header *ngIf="!isMobile">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <header
              class="service-landing__header"
              [class.service-landing__header--default-bg]="!coverImageUrl"
              [style.backgroundImage]="coverImageUrl | safe: 'styleUrl'">
              <div class="service-landing__header-layer">
                <h1 class="service-landing__header-title" data-qa-id="service-landing-service-title">{{ title }}</h1>
                <ng-content select=".header-inner"></ng-content>
                <ng-container *ngIf="submitButtonTemplate">
                  <ng-container *ngTemplateOutlet="submitButtonTemplate"></ng-container>
                </ng-container>
              </div>
            </header>
          </div>
        </div>
      </div>
    </header>

    <header *ngIf="isMobile" class="container service-landing__header_mobile">
      <div *ngIf="coverImageUrl" class="row pui-space_bottom-4">
        <div class="col-12">
          <div class="block">
            <img [src]="coverImageUrl | safe" width="100%" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="block block_header block_with-content">
            <h1 pui-typography="h2" data-qa-id="service-landing-service-title">{{ title }}</h1>

            <div class="pui-space_top-6" *ngIf="submitButtonTemplate">
              <ng-container *ngTemplateOutlet="submitButtonTemplate"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </header>
  </ng-container>

  <main class="service-landing__main-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <section class="service-landing__tabs-section">
            <div class="tabs">
              <ng-content select="[tabs]"></ng-content>
            </div>
          </section>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <section class="service-landing__main-section">
            <div style="max-width: 670px; margin: auto">
              <ng-container *ngIf="description && description !== '<p><br></p>'">
                <h3 i18n="@@about" class="service-landing__main-section-about">About</h3>

                <!-- class .ql-editor fixed missing styles for ql-* classes -->
                <div
                  class="service-landing__main-section-description ql-editor"
                  [innerHTML]="description | safeHtml"></div>
              </ng-container>

              <!--            <div *ngIf="guideServiceType === GuideServiceTypes.SESSION && hasSingleHost" class="location">-->
              <!--              <h3 data-qa-id="service-landing-location-title" i18n="@@location" class="section-title">Location</h3>-->
              <!--              <div class="description">-->
              <!--                <pui-icon class="start-icon" svgIcon="pui:place" size="s" aria-hidden="true"></pui-icon>-->
              <!--                <div data-qa-id="service-landing-location-platform-title">-->
              <!--                  {{ connectionType | sessionPlatform: address }}-->
              <!--                  <a-->
              <!--                    *ngIf="connectionType === SessionConnectionTypes.IN_PERSON && address"-->
              <!--                    class="show-on-google-maps"-->
              <!--                    i18n="@@showOnGoogleMaps"-->
              <!--                    href="{{ googleMapsLink }}"-->
              <!--                    target="_blank"-->
              <!--                    rel="noopener noreferrer">-->
              <!--                    Show on Google Maps-->
              <!--                  </a>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </div>-->

              <div
                *ngIf="showFaqAndTestimonials && testimonials && testimonials.length"
                class="service-landing__testimonials">
                <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="testimonials">
                  <ngu-tile *nguCarouselDef="let testimonial; let i = index">
                    <h3 pui-typography="h3">
                      <ng-container i18n="@@testimonials">Testimonials</ng-container>
                      ({{ i + 1 }}/{{ testimonials.length }})
                    </h3>
                    <p class="text">{{ testimonial.text }}</p>
                    <p class="name" pui-typography="body-m">{{ testimonial.clientInfo }}</p>
                  </ngu-tile>

                  <button *ngIf="testimonials?.length > 1" NguCarouselPrev class="arrow arrow-left"></button>
                  <button *ngIf="testimonials?.length > 1" NguCarouselNext class="arrow arrow-right"></button>
                </ngu-carousel>
              </div>

              <ng-content select="#program-included"></ng-content>

              <ng-content select=".schedule"></ng-content>

              <div class="fa-questions" *ngIf="showFaqAndTestimonials && faq && faq.length">
                <h3 i18n="@@frequentlyAskedQuestions" class="section-title title-questions">
                  Frequently asked questions
                </h3>

                <ngb-accordion #faqAccordion>
                  <ngb-panel
                    *ngFor="let faQuestion of faq; let questionIndex = index"
                    [id]="'fa-question-' + questionIndex">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div class="d-flex align-items-center justify-content-between">
                        <h5 class="m-0 question">{{ faQuestion.question }}</h5>
                        <app-button
                          [className]="opened ? 'btn-up-round-lite' : 'btn-down-round-lite'"
                          (click)="faqAccordion.toggle('fa-question-' + questionIndex)"></app-button>
                      </div>
                    </ng-template>

                    <ng-template ngbPanelContent>
                      {{ faQuestion.answer }}
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>

              <div *ngIf="assignees.length > 0">
                <app-service-hosts
                  [isPackage]="isPackage"
                  [templateId]="templateId"
                  [workspaceId]="workspaceId"
                  [type]="workspaceInfo?.type"
                  [assignees]="assignees"
                  [sessionType]="sessionType"
                  [showBookButton]="showBookButton"></app-service-hosts>
              </div>

              <ng-content select="#bottom-content"></ng-content>
            </div>
          </section>
        </div>

        <!--        <div class="col-12 col-md-4 service-landing__right-column">-->
        <!--          <section class="service-landing__share-section">-->
        <!--            <div *ngIf="logo" class="logo">-->
        <!--              <img class="logo__img" width="100%" height="100%" [src]="logo" alt="team logo" />-->
        <!--            </div>-->

        <!--            <h3 data-qa-id="service-landing-share-title" i18n="@@share" class="section-title">Share</h3>-->

        <!--            <app-copy-value-input-->
        <!--              [buttonText]="copyLinkText"-->
        <!--              [value]="link"-->
        <!--              (copied)="onCopied()"-->
        <!--              (copyFailed)="onCopyFailed()"-->
        <!--              class="link"></app-copy-value-input>-->
        <!--          </section>-->
        <!--        </div>-->
      </div>
    </div>
  </main>

  <app-powered-by-link
    class="footer"
    *ngIf="displayPoweredBy || showPoweredBy"
    [utmCampaign]="typeOfLink"
    [utmHost]="guide?.id"></app-powered-by-link>
</section>
