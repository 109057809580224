import { NgModule } from '@angular/core';
import { UiInput } from './input';
import { MatCommonModule } from '@angular/material/core';

@NgModule({
  declarations: [UiInput],
  exports: [UiInput, MatCommonModule],
  imports: [MatCommonModule]
})
export class UiInputModule {}
