import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AnalyticCopyTypes } from '@app/core/analytics/types';
import { FacebookService } from '@app/core/social-services/facebook.service';
import { TwitterService } from '@app/core/social-services/twitter.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-guide-service-social-links',
  templateUrl: './guide-service-social-links.component.html',
  styleUrls: ['./guide-service-social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FacebookService, TwitterService]
})
export class GuideServiceSocialLinksComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _serviceLink = '';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _twitterLink = '';

  @Input()
  facebookHashtag: string;

  @Input()
  quote = 'custom quote can be here';

  @Input()
  twitterHashtags: string[];

  @Input()
  set serviceLink(value: string) {
    this._serviceLink = value;

    this.generateTwitterLink();
  }

  @Output()
  linkClicked = new EventEmitter<AnalyticCopyTypes>();

  get twitterLink(): string {
    return this._twitterLink;
  }

  constructor(
    private _facebook: FacebookService,
    private _twitter: TwitterService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    // @ts-expect-error TS2322
    this.facebookHashtag = this._runtimeConfigService.get('platformName');
    // @ts-expect-error TS2322
    this.twitterHashtags = [this._runtimeConfigService.get('platformName')];
  }

  generateTwitterLink(): void {
    this._twitterLink = this._twitter.generateShareLink(this._serviceLink, this.quote || '', this.twitterHashtags);
  }

  shareViaFacebook(): void {
    this.linkClicked.emit(AnalyticCopyTypes.FACEBOOK);
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._facebook.shareLink$(this._serviceLink, this.quote || '', this.facebookHashtag).subscribe();
  }

  shareViaTwitter(): void {
    this.linkClicked.emit(AnalyticCopyTypes.TWITTER);
  }
}
