import { Inject, Injectable } from '@angular/core';
import { BookingModalComponent } from '@app/modules/book-service/components/booking-modal/booking-modal.component';
import { PUI_IS_MOBILE, PuiDialogRef, PuiDialogService } from '@awarenow/profi-ui-core';
import { BookingWidgetOptions } from '@appWidget/modules/booking/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingModalService {
  readonly isMobile$ = new BehaviorSubject<boolean>(false);

  constructor(private dialog: PuiDialogService, @Inject(PUI_IS_MOBILE) isMobile$: Observable<boolean>) {
    isMobile$.subscribe(value => this.isMobile$.next(value));
  }

  openAsync(options: BookingWidgetOptions): PuiDialogRef<unknown> {
    return this.dialog.open(BookingModalComponent, {
      size: this.isMobile$.value ? 'page' : 'auto',
      hasCloseButton: this.isMobile$.value,
      data: {
        options
      }
    });
  }

  openWithDelay(options: BookingWidgetOptions, delay = 1000): void {
    setTimeout(() => {
      this.dialog.open(BookingModalComponent, {
        size: this.isMobile$.value ? 'page' : 'auto',
        hasCloseButton: this.isMobile$.value,
        data: {
          options
        }
      });
    }, delay);
  }
}
