import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { DateTime } from 'luxon';
import { SetPaymentDateTargetType } from '@app/screens/guide/guide-programs/features/update-subscription/types';

export interface UpdateSubscription {
  targetId: number;
  targetType: SetPaymentDateTargetType;
  customerId: number;
  date: Date;
}

export type UpdateSubscriptionResponse = unknown;

@Injectable()
export class SubscriptionsApiService {
  constructor(private httpClient: HttpClient) {}
  updateSubscription({
    targetId,
    customerId,
    date,
    targetType
  }: UpdateSubscription): Observable<UpdateSubscriptionResponse> {
    const [typeDir, clientsDir] = this.getTargetDir(targetType);

    return this.httpClient.post(
      `${config.apiPath}/user/guide/${typeDir}/${targetId}/${clientsDir}/${customerId}/subscriptions`,
      {
        date: DateTime.fromJSDate(date).toISO()
      }
    );
  }

  private getTargetDir(targetType: SetPaymentDateTargetType) {
    switch (targetType) {
      case 'program':
        return ['programs', 'customers'];
      case 'package':
        return ['packages', 'clients'];
      default:
        throw Error('Missed required parameter "targetType"!');
    }
  }
}
