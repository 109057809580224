<div class="booking-content-wrapper">
  <div class="booking-link" *ngIf="teamBookingLink">
    <div class="booking-link-title text-nowrap" i18n="@@teamBookingLink">Team booking link</div>
    <div class="booking-link-anchor">
      <a pui-link title="Team booking link" i18n-title="@@teamBookingLink" [href]="teamBookingLink | safe: 'url'">
        {{ teamBookingLink }}
      </a>
    </div>
    <div class="booking-link-actions">
      <button
        pui-link
        #teamBookingButton="cdkOverlayOrigin"
        cdk-overlay-origin
        ngxClipboard
        [cbContent]="teamBookingLink"
        (cbOnSuccess)="onBookLinkCopySuccess(teamBookingButton.elementRef.nativeElement)"
        (cbOnError)="onBookLinkCopyFailed()">
        <pui-icon svgIcon="pui:copy" size="xxs"></pui-icon>
        <span class="pui-space_left-1">
          <ng-container i18n="@@bookingPopupCopyLink">Copy link</ng-container>
        </span>
      </button>

      <button pui-link (click)="openBookWidget(source.TEAM)">
        <pui-icon svgIcon="pui:code" size="xxs"></pui-icon>
        <span class="pui-space_left-1">
          <ng-container i18n="@@bookingPopupAddToWebsite">Add to website</ng-container>
        </span>
      </button>
    </div>
  </div>

  <mat-divider *ngIf="teamBookingLink && myBookingLink"></mat-divider>

  <div class="booking-link" *ngIf="myBookingLink">
    <div class="booking-link-title text-nowrap" i18n="@@myBookingLink">My booking link</div>
    <div class="booking-link-anchor">
      <a pui-link title="My booking link" i18n-title="@@myBookingLink" [href]="myBookingLink | safe: 'url'">
        {{ myBookingLink }}
      </a>
    </div>
    <div class="booking-link-actions">
      <button
        pui-link
        #myBookingButton="cdkOverlayOrigin"
        cdk-overlay-origin
        ngxClipboard
        [cbContent]="myBookingLink"
        (cbOnSuccess)="onBookLinkCopySuccess(myBookingButton.elementRef.nativeElement)"
        (cbOnError)="onBookLinkCopyFailed()">
        <pui-icon svgIcon="pui:copy" size="xxs"></pui-icon>
        <span class="pui-space_left-1">
          <ng-container i18n="@@bookingPopupCopyLink">Copy link</ng-container>
        </span>
      </button>

      <button pui-link (click)="openBookWidget(source.MEMBER)">
        <pui-icon svgIcon="pui:code" size="xxs"></pui-icon>
        <span class="pui-space_left-1">
          <ng-container i18n="@@bookingPopupAddToWebsite">Add to website</ng-container>
        </span>
      </button>
    </div>
  </div>
</div>
