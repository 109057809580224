import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Quiz } from '../types/quiz';
import { QuizService } from '../services/quiz.service';
import { SurveyFormService } from '../services/survey-form.service';

@Injectable()
export class QuizResolver implements Resolve<Quiz | null> {
  constructor(private readonly quizService: QuizService, private readonly surveyFormService: SurveyFormService) {}

  resolve(next: ActivatedRouteSnapshot): Observable<Quiz | null> {
    // @ts-expect-error TS2531
    const id = +next.paramMap.get('id');

    this.surveyFormService.resetQuizForm();

    if (!id) {
      return of(null);
    }

    return this.quizService.getQuiz$(id).pipe(
      tap(quiz => {
        if (quiz) {
          this.surveyFormService.setQuiz(quiz);
        }
      })
    );
  }
}
