// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';

import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import { ProgramAccessRoles } from '@app/screens/guide/guide-programs/types';
import { ProgramService } from '@app/screens/guide/guide-programs/types/program-service';
import { DiffDetails } from '@app/screens/guide/guide-programs/types/util-types';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { ISessionInfo } from '@app/shared/interfaces/services';

import { BaseModule, IBaseModuleDetails } from './base-module';
import { ModuleAccessTypes } from './module-access-types';

export type ProgramSessionModuleInfo = Pick<
  ISessionInfo<ProgramSessionTemplateInterface>,
  'sessionTemplateDraft' | 'sessionTemplateId' | 'expirationPeriod' | 'count'
> & { accessRole: ProgramAccessRoles };

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGroupSessionModuleDetails extends IBaseModuleDetails {
  serviceId: number;
  sessionTemplateDraft?: ProgramSessionTemplateInterface;
  service?: ProgramService;
  ignoreSessionValidation?: boolean;
}

export class GroupSessionModule extends BaseModule {
  readonly serviceId: number;

  readonly service?: ProgramService;

  readonly sessionTemplateDraft?: ProgramSessionTemplateInterface;

  /**
   * In case Team Workspace, we ignore necessary session validation
   * @type {boolean}
   */
  readonly ignoreSessionValidation?: boolean;

  accessType!: ModuleAccessTypes;

  get moduleType(): ModuleTypes {
    return ModuleTypes.GROUP_SESSION;
  }

  constructor(groupSessionModuleDetails: Readonly<IGroupSessionModuleDetails>) {
    super(groupSessionModuleDetails);

    this.ignoreSessionValidation = groupSessionModuleDetails.ignoreSessionValidation;
    this.serviceId = groupSessionModuleDetails.serviceId;
    this.sessionTemplateDraft = groupSessionModuleDetails.sessionTemplateDraft;

    if (groupSessionModuleDetails.service != null) {
      this.service = groupSessionModuleDetails.service;
    }
  }

  getDiffFrom(groupSessionModuleValue: Readonly<GroupSessionModule>): DiffDetails<IGroupSessionModuleDetails> | null {
    const baseDiff = super.getDiffFrom(groupSessionModuleValue);
    const isModulesEqual = isEqual(this.sessionTemplateDraft, groupSessionModuleValue.sessionTemplateDraft);

    if (!baseDiff && this.serviceId === groupSessionModuleValue.serviceId && isModulesEqual) {
      return null;
    }

    const diff: DiffDetails<IGroupSessionModuleDetails> = baseDiff
      ? { ...baseDiff }
      : {
          id: this.id,
          localId: this.localId,
          order: this.order
        };
    if (!!groupSessionModuleValue.sessionTemplateDraft && !isModulesEqual) {
      diff.sessionTemplateDraft = groupSessionModuleValue.sessionTemplateDraft;

      diff.serviceId = groupSessionModuleValue.serviceId;
    }

    return diff;
  }

  toJSON(): unknown {
    return {
      ...super.toJSON(),
      serviceId: this.serviceId,
      sessionTemplateDraft: this.sessionTemplateDraft,
      moduleType: this.moduleType
    };
  }
}
