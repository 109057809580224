import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  PuiButtonModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputDateModule,
  PuiInputModule,
  PuiLinkModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { DateOverridesFormComponent } from './date-overrides-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiIconModule,
    PuiGroupModule,
    PuiLinkModule,
    PuiInputDateModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule
  ],
  declarations: [DateOverridesFormComponent],
  exports: [DateOverridesFormComponent]
})
export class DateOverridesFormModule {}
