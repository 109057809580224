import { Injectable, OnDestroy } from '@angular/core';
import { GuideNotesService } from '@app/modules/guide-notes/guide-notes.service';
import { GuideNotesApiService } from '@app/modules/guide-notes/guide-notes-api.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { IGuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { INoteEditorContent } from '@app/shared/interfaces/notes';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideRelationInfoModalState {
  note: IGuideNote | null;
  relationId: string | null;
}

@Injectable()
export class GuideRelationInfoModalService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _serviceId: symbol;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentNoteStatus = 'unsaved-new';
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _noteChangeSubscription: Subscription;
  private destroy$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _resetEditorState$: Subject<void> = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initialState: IGuideRelationInfoModalState = { note: null, relationId: null };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _state$: BehaviorSubject<IGuideRelationInfoModalState> = new BehaviorSubject(this._initialState);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get state$() {
    return this._state$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get note$() {
    return this.state$.pipe(
      filter(({ note }) => note !== null),
      map(({ note }) => note)
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get state() {
    return this._state$.getValue();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get resetEditorState$() {
    return this._resetEditorState$.asObservable();
  }

  constructor(private readonly _guideNotes: GuideNotesService, private readonly _guideNotesApi: GuideNotesApiService) {
    this._serviceId = Symbol('GuideRelationInfoModalService');
    _guideNotes.delete$
      .pipe(
        // @ts-expect-error TS2769
        filter(({ serviceId, noteId }) => {
          const { note } = this.state;
          return serviceId !== this._serviceId && note && note.id === noteId;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const { relationId } = this.state;
        this._currentNoteStatus = 'unsaved-new';
        // @ts-expect-error TS2322
        this._noteChangeSubscription = undefined;
        this.setState({
          relationId,
          note: null
        });
        this._resetEditorState$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setState(state: IGuideRelationInfoModalState) {
    this._state$.next(state);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setRelation(id: string) {
    const { note } = this.state;
    this.setState({
      relationId: id,
      note
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  change({ html, content, text }: INoteEditorContent) {
    const { relationId, note: currentNote } = this.state;
    // @ts-expect-error TS2345
    const { id, type } = GuideContact.parseLocalId(relationId);

    const currentNoteHtml = currentNote && currentNote.content;
    if (currentNoteHtml === html || this._currentNoteStatus === 'new-pending') {
      return;
    }
    switch (this._currentNoteStatus) {
      case 'unsaved-new':
      case 'new':
        if (html === '') {
          return;
        }
        this._currentNoteStatus = 'new-pending';
        let createdNote$: Observable<{ id: number }>;
        const note = {
          content: html,
          contentDeltaFormat: content,
          contentText: text
        };

        if (type === GuideRelationTypes.GUIDE_CLIENT) {
          // @ts-expect-error TS2345
          createdNote$ = this._guideNotesApi.createClientNote$(id, note, relationId);
        }

        if (type === GuideRelationTypes.GUIDE_CONTACT) {
          // @ts-expect-error TS2345
          createdNote$ = this._guideNotesApi.createContactNote$(id, note, relationId);
        }

        // @ts-expect-error TS2454
        this._noteChangeSubscription = createdNote$
          .pipe(switchMap(({ id }) => this._guideNotesApi.getNote$(id)))
          .subscribe(
            newNote => {
              this.setState({
                // @ts-expect-error TS2322
                note: newNote,
                relationId
              });
              this._guideNotes.createNote({ noteId: newNote.id, serviceId: this._serviceId });
              this._currentNoteStatus = 'saved';
            },
            () => {
              this._currentNoteStatus = 'unsaved-new';
            }
          );
        break;
      case 'unsaved':
      case 'saved':
        this._currentNoteStatus = 'save-pending';
        this._noteChangeSubscription = this._guideNotesApi
          // @ts-expect-error TS2531
          .updateNote$(currentNote.id, {
            content: html,
            contentDeltaFormat: content,
            contentText: text
          })
          .subscribe(
            ({ content: contentHtml, contentDeltaFormat, contentText, updatedAt }) => {
              const updatedNote = {
                ...currentNote,
                contentDeltaFormat,
                contentText,
                content: contentHtml,
                updatedAt
              };
              this.setState({
                // @ts-expect-error TS2322
                note: updatedNote,
                relationId
              });
              // @ts-expect-error TS2531
              this._guideNotes.updateNote({ noteId: currentNote.id, serviceId: this._serviceId });
              this._currentNoteStatus = 'saved';
            },
            () => {
              this._currentNoteStatus = 'unsaved';
            }
          );
        break;
      case 'save-pending':
        if (this._noteChangeSubscription) {
          this._noteChangeSubscription.unsubscribe();
        }
        this._currentNoteStatus = 'saved';
        this.change({ html, content, text });
        break;
    }
  }
}
