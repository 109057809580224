import { GroupSession, Session, SimpleSession } from '@app/shared/interfaces/session';

export function sessionsMapRecurringSessions(draftSessions: Session[]): GroupSession[] {
  const recurringEventIds = [...new Set(draftSessions.map(session => session.recurringEventId))];
  // TODO add client id for seats per slot sessions
  const result = recurringEventIds.reduce((sessions: Session[], recurringEventId) => {
    if (recurringEventId) {
      const sessionsByRecId = draftSessions.filter(session => session.recurringEventId === recurringEventId);
      if (sessionsByRecId.length) {
        // TODO for by clientIds
        const firstSession = sessionsByRecId[0];

        if (firstSession instanceof GroupSession && firstSession.sessions?.length) {
          firstSession.sessions = firstSession.sessions.map(session => ({ ...session } as SimpleSession));
        }
        const session = { ...firstSession } as Session;
        sessions.push(session);
      }
    } else {
      sessions.push(...draftSessions.filter(session => !session.recurringEventId));
    }

    return sessions;
  }, []);

  return result.map(session => new GroupSession(session));
}
