import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModuleCompletionTypes, ModuleTypes } from '@app/shared/interfaces/programs/program-module';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-module-completion-select',
  templateUrl: './module-completion-select.component.html',
  styleUrls: ['./module-completion-select.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-completion-select'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ModuleCompletionSelectComponent)
    }
  ]
})
export class ModuleCompletionSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _completionType: ModuleCompletionTypes | null;

  readonly control = new FormControl(null);
  readonly ModuleCompletionTypes = ModuleCompletionTypes;
  readonly ModuleTypes = ModuleTypes;
  readonly completionTypes = [ModuleCompletionTypes.AUTO, ModuleCompletionTypes.CLIENT, ModuleCompletionTypes.GUIDE];

  @Input()
  // @ts-expect-error TS2564
  moduleType: ModuleTypes;

  get isQuizModule(): boolean {
    return this.moduleType === ModuleTypes.QUIZ;
  }

  @Input()
  set completionType(value: ModuleCompletionTypes) {
    this.writeValue(value);
  }

  get completionType(): ModuleCompletionTypes {
    // @ts-expect-error TS2322
    return this._completionType;
  }

  @Output()
  completionTypeChange = new EventEmitter<ModuleCompletionTypes>();

  ngOnInit(): void {
    if (this.isQuizModule) {
      this.completionTypes.splice(this.completionTypes.length - 1, 0, ModuleCompletionTypes.AFTER_SUBMIT_QUIZ);
    }
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(completionType => {
      this._onChange(completionType);
      this._onTouched();
      this.completionTypeChange.emit(completionType);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  writeValue(completionType: ModuleCompletionTypes): void {
    this.control.setValue(completionType, { emitEvent: false });
    this._completionType = completionType;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _onChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  private _onTouched = () => {};
}
