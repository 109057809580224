<ng-container *ngIf="sessions | normalizeSessions as normalizedSessions">
  <ng-container *ngIf="!groupBy; else groupedList">
    <ng-container *ngTemplateOutlet="sessionsList; context: { $implicit: normalizedSessions }"></ng-container>
  </ng-container>
  <ng-template #groupedList>
    <ng-container *ngTemplateOutlet="grouping; context: { $implicit: normalizedSessions }"></ng-container>
  </ng-template>
</ng-container>

<ng-template #sessionsList let-sessions>
  <ng-container *ngFor="let session of sessions; let last = last">
    <div class="sessions-list">
      <app-session class="pui-space_bottom-5 pui-space_top-5" [session]="session"></app-session>

      <ng-container *ngIf="!last">
        <pui-divider></pui-divider>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #grouping let-sessions>
  <ng-container *ngFor="let group of sessions | groupSessionsBy; let last = last">
    <pui-paper [border]="appearance === 'client-booking-modal' ? 'default' : null" shape="rounded-2">
      <div class="session-group">
        <div class="session-group__title">
          <ng-container *ngIf="appearance === 'client-booking-modal'">
            <app-service-label [type]="group.type"></app-service-label>
          </ng-container>
          <span [pui-typography]="appearance === 'client-dashboard' ? 'h5' : 'h4'">
            <ng-container *ngIf="appearance === 'client-dashboard'">
              <ng-container i18n="@clientSessionsGroupActiveLable">Active</ng-container>
              {{ group.type }}:
            </ng-container>
            {{ group.name }}
          </span>
        </div>

        <ng-container *ngIf="!stackBy; else stackedList">
          <ng-container *ngTemplateOutlet="sessionsList; context: { $implicit: group.children }"></ng-container>
        </ng-container>
        <ng-template #stackedList>
          <ng-container
            *ngTemplateOutlet="
              stacking;
              context: { $implicit: group.children, stacker: stackers[stackBy] }
            "></ng-container>
        </ng-template>
      </div>
    </pui-paper>

    <pui-divider *ngIf="!last && appearance === 'client-dashboard'" class="session-group__divider"></pui-divider>
  </ng-container>
</ng-template>

<ng-template #stacking let-sessions let-stacker="stacker">
  <ng-container *ngFor="let stack of sessions | stackSessionsBy: stacker">
    <app-session-stacker
      class="sessions-stacks"
      [stack]="stack"
      [appearance]="appearance"
      [actionLabelText]="actionLabelText"
      [attr.data-qa-id]="'available-client-session-item-' + stack.title"
      (selected)="selected.emit()"></app-session-stacker>
  </ng-container>
</ng-template>
