import { formatDate } from '@angular/common';
import { DateTime } from 'luxon';
import { getDayPostfix } from './get-day-postfix';
import { TimezonesAbbr } from './timezones-abbr';

export function convertToDateTime(date: DateTime | Date | string): DateTime | null {
  let dateTime = null;

  if (date instanceof DateTime) {
    dateTime = date;
  } else if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date);
  } else if (typeof date === 'string') {
    dateTime = DateTime.fromISO(date);
  }

  return dateTime;
}

export function formatDateRange(startDateTime: DateTime, endDateTime: DateTime, locale?: string | null): string {
  const startDate = formatStartDate(startDateTime, locale);
  // @ts-expect-error TS2345
  const startTime = formatDate(startDateTime.toISO(), 'shortTime', locale).replace(/\s(am|pm)/gi, '');
  // @ts-expect-error TS2345
  const endTime = formatDate(endDateTime.toISO(), 'shortTime', locale);
  // @ts-expect-error TS2345
  const timeZone = formatDate(endDateTime.toISO(), 'ZZZZ', locale);

  return `${startDate}, ${startTime} - ${endTime} (${timeZone})`.trim();
}

export function formatStartDate(startDateTime: DateTime, locale?: string | null): string {
  // @ts-expect-error TS2345
  const datePostfix = getDayPostfix(startDateTime.day, locale);

  // @ts-expect-error TS2345
  return `${formatDate(startDateTime.toISO(), `EEEE, LLL d${datePostfix ? `'${datePostfix}'` : ''}`, locale)}`;
}

export function prepareDateRange(datesRangeProvider: IHaveDateRange | null): [DateTime, DateTime] | null {
  if (!datesRangeProvider) {
    return null;
  }

  const startDateTime = convertToDateTime(datesRangeProvider.date);

  if (!startDateTime || !startDateTime.isValid) {
    return null;
  }

  const endDateTime = datesRangeProvider.duration
    ? startDateTime.plus({ minutes: +datesRangeProvider.duration })
    : // @ts-expect-error TS2345
      convertToDateTime(datesRangeProvider.dateEnd);

  if (!endDateTime || !endDateTime.isValid) {
    return null;
  }

  return [startDateTime, endDateTime];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IHaveDateRange {
  date: string | Date | DateTime;
  duration?: number | string;
  dateEnd?: string | Date | DateTime;
}

export function getAbbrByTimezone(tz: string): string {
  let abbr = DateTime.local().setZone(tz).toFormat('ZZZZ');
  if (abbr.match(/^GMT(\+|-)/i)) {
    // @ts-expect-error TS7053
    abbr = TimezonesAbbr[tz] || abbr;
  }
  return abbr;
}
