<div
  [attr.data-qa-id]="'guide-service-item-' + service?.name"
  class="main-info-container guide-service-info-container"
  [routerLink]="editorLink">
  <div class="main-info">
    <h4 class="name-container">
      <ng-container *ngFor="let badge of badges">
        <ng-container *ngTemplateOutlet="badge; context: context"></ng-container>
      </ng-container>
      <span [attr.data-qa-id]="'guide-service-name-title-' + service?.name" class="name" [pui-tooltip]="service?.name">
        {{ service?.name }}
      </span>
      <button
        *ngIf="publicLink"
        class="copy-link"
        pui-icon-button
        size="xs"
        appearance="flat"
        shape="square"
        [pui-tooltip]="copyText"
        ngxClipboard
        (click)="$event.stopPropagation()"
        [cbContent]="publicLink">
        <pui-icon svgIcon="pui:menu-booking-link" size="s"></pui-icon>
      </button>
    </h4>
    <ng-container *ngTemplateOutlet="details; context: context"></ng-container>
  </div>

  <div class="meta-container">
    <div
      class="team-container guide-service-info-container"
      *ngIf="service?.team?.length && (workspace.isTeam$ | async)">
      <ng-template *ngTemplateOutlet="team || defaultTeam; context: context"></ng-template>
    </div>

    <div class="price-container guide-service-info-container">
      <ng-container *ngIf="!service?.hidePrice">
        <ng-template *ngTemplateOutlet="price || defaultPrice; context: context"></ng-template>
      </ng-container>
    </div>
  </div>
</div>

<ng-template [ngIf]="!service?.link">
  <ng-container *ngTemplateOutlet="description; context: context"></ng-container>
</ng-template>

<ng-template [ngIf]="showMore">
  <ng-container *ngTemplateOutlet="showMore; context: context"></ng-container>
</ng-template>

<ng-template #defaultTeam let-service="service"></ng-template>

<ng-template #defaultPrice let-service="service">
  <ng-template [ngIf]="service?.price || service?.subscriptionPrice" [ngIfElse]="free">
    <span class="price-wrap">
      <span class="price">{{ service?.price || service?.subscriptionPrice | platformCurrency }}</span>
      <ng-template [ngIf]="!service?.price && service?.subscriptionRecurrency">
        <div class="recurrency">/{{ service?.subscriptionRecurrency }}</div>
      </ng-template>
    </span>
  </ng-template>

  <ng-template #free>
    <span [attr.data-qa-id]="'guide-service-free-label-' + service?.name" class="free" i18n="@@free">Free</span>
  </ng-template>
</ng-template>
