import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationModalComponent {
  // @ts-expect-error TS2564
  @Input() type: 'paymentWithOuterLink';

  constructor(public modal: NgbActiveModal) {}
}
