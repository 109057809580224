import { formatCurrency } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '@app/core/currency/currency.service';

interface IWithSubscriptionPayment {
  subscriptionPrice: number;
  subscriptionRecurrency: string;
}

interface IWithInstallmentPayment {
  price?: number;
  payRate?: number;
  subscriptionPrice: number;
  subscriptionRecurrency: string;
  totalPayments: number;
}

interface IWithOneTimePayment {
  price?: number;
  payRate?: number;
}

interface IWithFreePayment {
  isFree: boolean;
}

type PricePipeValue = IWithFreePayment | IWithOneTimePayment | IWithInstallmentPayment | IWithSubscriptionPayment;

// Create a custom Pipe called 'PricePipe' to format a Service payment options.
@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  // Constructor with injection of the 'currency' service and the 'LOCALE_ID' for locale formatting.
  constructor(private currency: CurrencyService, @Inject(LOCALE_ID) private locale: string) {}

  // PipeTransform interface implementation to transform the input value based on the payment type.
  transform(paymentOption: PricePipeValue): string {
    return getComputedPrice(paymentOption, {
      locale: this.locale,
      currency: this.currency
    });
  }
}

export function getComputedPrice(
  paymentOption: PricePipeValue,
  options: { locale: string; currency: CurrencyService }
): string {
  if (!paymentOption) {
    return ''; // If no input value provided, return an empty string
  }

  if ((paymentOption as IWithFreePayment).isFree) {
    return 'Free';
  }

  if ('subscriptionPrice' in paymentOption && paymentOption.subscriptionPrice > 0) {
    if ('totalPayments' in paymentOption && paymentOption.totalPayments === null) {
      const { subscriptionPrice, subscriptionRecurrency } = paymentOption as IWithSubscriptionPayment;

      // Subscription payment.
      return `${customFormatCurrency(subscriptionPrice, options)} per ${subscriptionRecurrency}`;
    } else {
      const { price, payRate, subscriptionPrice, subscriptionRecurrency, totalPayments } =
        paymentOption as IWithInstallmentPayment;

      const amount = price || payRate!;

      // Installment payment.
      return `${customFormatCurrency(amount, options)} or ${customFormatCurrency(
        subscriptionPrice,
        options
      )} per ${subscriptionRecurrency} (${totalPayments} total payments)`;
    }
  } else {
    const { price, payRate } = paymentOption as IWithOneTimePayment;

    const amount = price || payRate!;

    // One-time payment.
    return amount === 0 || !amount ? 'Free' : customFormatCurrency(amount, options);
  }
}

export function customFormatCurrency(
  value: number,
  { locale, currency }: { locale: string; currency: CurrencyService }
): string {
  return formatCurrency(value, locale, currency.defaultCurrencySign, currency.defaultCurrency, '1.0-2');
}
