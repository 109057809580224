// eslint-disable-next-line max-classes-per-file
import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
// import { locale } from '@env/locale';
import { ILocale } from '@env/locale.interface';
import { WithWorkspaceID } from '@app/base';
import { GuideServiceTypes } from './guide-service';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { IWorkspace } from '@app/modules/workspaces/types';
import { PackageIncludes } from '@app/modules/package/types/package-session-template';

export enum GuidePublicServiceKinds {
  SINGLE = 'single',
  GROUP = 'group',
  PACKAGE = 'package'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePublicSingleService {
  id: number;
  guideId: number;
  name: string;
  description?: string | null;
  price?: number | null; // if price = 0, then service is free
  subscriptionPrice?: number | null;
  subscriptionRecurrency?: SubscriptionRecurrency | null;
  totalPayments?: number | null;
  type: GuideServiceTypes;
  coverImage?: string | null;
  hidePrice?: boolean;
  address?: string;
  hideAddress?: boolean;
  callInitiator?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePublicSingleSession extends IGuidePublicSingleService, Readonly<Partial<WithWorkspaceID>> {
  duration: number;
  connectionType: SessionConnectionTypes;
  overtimeCharge?: number | null;
  workspace: Pick<IWorkspace, 'id' | 'type'>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePackageService extends IGuidePublicSingleService {
  sessions: PackageIncludes[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideProgramService extends IGuidePublicSingleService {
  startDate?: string | null;
  startType?: string | null;
  enrolledAt?: string | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePublicSingleServiceProgramParent {
  id: number;
  type: GuideServiceTypes.PROGRAM;
  moduleId: number;
  moduleOrder: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePublicSingleServicePackageParent {
  id: number;
  type: GuideServiceTypes.PACKAGE;
}

export type GuidePublicServiceGroupItemParent =
  | IGuidePublicSingleServiceProgramParent
  | IGuidePublicSingleServicePackageParent;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePublicServiceGroupItem extends IGuidePublicSingleSession {
  serviceParent: GuidePublicServiceGroupItemParent;
  availableCount?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePublicServiceGroup {
  id?: number;
  guideId: number;
  name: string;
  description?: string | null;
  coverImage?: string | null;
  type?: GuideServiceTypes;

  readonly services: GuidePublicSingleSession;
}

export class GuidePublicSingleService {
  // @ts-expect-error TS2564
  readonly id: number;
  // @ts-expect-error TS2564
  readonly guideId: number;
  // @ts-expect-error TS2564
  readonly name: string;
  readonly description?: string | null;
  readonly price?: number | null; // if price = 0, then service is free
  readonly subscriptionPrice?: number | null;
  readonly subscriptionRecurrency?: SubscriptionRecurrency | null;
  readonly totalPayments?: number | null;
  // @ts-expect-error TS2564
  readonly type: GuideServiceTypes;
  readonly coverImage?: string | null;
  readonly hidePrice?: boolean;

  readonly kind = GuidePublicServiceKinds.SINGLE;

  locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return this.id && this.type === GuideServiceTypes.GROUP_SESSION
      ? `${this.locale.baseUrl}/sessions/${this.id}`
      : null;
  }

  constructor(serviceDetails: IGuidePublicSingleService, localeDetails: ILocale) {
    if (!serviceDetails) {
      throw new Error('Invalid argument');
    }

    Object.entries(serviceDetails).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
    this.locale = localeDetails;
  }
}

export class GuidePublicSingleSession extends GuidePublicSingleService {
  readonly duration: number;
  readonly connectionType: SessionConnectionTypes;
  readonly overtimeCharge?: number | null;
  readonly workspaceId?: number;
  readonly workspace?: Pick<IWorkspace, 'id' | 'type'>;
  readonly address?: string;
  readonly hideAddress?: boolean;
  readonly callInitiator?: string;

  constructor(serviceDetails: IGuidePublicSingleSession, localeDetails: ILocale) {
    super(serviceDetails, localeDetails);

    this.duration = serviceDetails.duration;
    this.connectionType = serviceDetails.connectionType;
    this.overtimeCharge = serviceDetails.overtimeCharge;
    this.workspaceId = serviceDetails.workspaceId;
    this.workspace = serviceDetails.workspace;
    this.address = serviceDetails.address;
    this.hideAddress = serviceDetails.hideAddress;
    this.callInitiator = serviceDetails.callInitiator;
  }
}

export class GuidePublicServiceGroupItem extends GuidePublicSingleSession {
  serviceParent: GuidePublicServiceGroupItemParent;
  availableCount: number;

  constructor(serviceDetails: IGuidePublicServiceGroupItem, localeDetails: ILocale) {
    super(serviceDetails, localeDetails);

    this.serviceParent = serviceDetails.serviceParent;
    // @ts-expect-error TS2322
    this.availableCount = serviceDetails.availableCount;
  }
}

export class GuidePublicServiceGroup {
  readonly id?: number;
  // @ts-expect-error TS2564
  readonly guideId: number;
  // @ts-expect-error TS2564
  readonly name: string;
  readonly description?: string | null;
  readonly coverImage?: string | null;
  readonly type?: GuideServiceTypes;

  // @ts-expect-error TS2564
  readonly services: GuidePublicServiceGroupItem;

  readonly kind = GuidePublicServiceKinds.GROUP;

  constructor(servicesContainerDetails: IGuidePublicServiceGroup) {
    if (!servicesContainerDetails) {
      throw new Error('Invalid argument');
    }

    Object.entries(servicesContainerDetails).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
  }
}

export class GuidePackageService extends GuidePublicSingleService {
  readonly sessions: PackageIncludes[];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return this.id ? `${this.locale.baseUrl}/packages/${this.id}` : null;
  }

  constructor(serviceDetails: IGuidePackageService, localeDetails: ILocale) {
    super(serviceDetails, localeDetails);

    this.sessions = serviceDetails.sessions;
  }
}

export class GuideProgramService extends GuidePublicSingleService {
  readonly startDate: string | null;
  readonly startType: string | null;
  readonly enrolledAt: string | null;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return this.id ? `${this.locale.baseUrl}/programs/${this.id}` : null;
  }

  constructor(serviceDetails: IGuideProgramService, localeDetails: ILocale) {
    super(serviceDetails, localeDetails);

    // @ts-expect-error TS2322
    this.startDate = serviceDetails.startDate;
    // @ts-expect-error TS2322
    this.startType = serviceDetails.startType;
    // @ts-expect-error TS2322
    this.enrolledAt = serviceDetails.enrolledAt;
  }
}

export type GuidePublicService =
  | GuidePublicSingleSession
  | GuidePublicServiceGroup
  | GuidePackageService
  | GuideProgramService;

export function isGuidePublicSingleService(service: GuidePublicService): service is GuidePublicSingleSession {
  return service.kind === GuidePublicServiceKinds.SINGLE;
}

export function isGuidePublicServiceGroup(service: GuidePublicService): service is GuidePublicServiceGroup {
  return service.kind === GuidePublicServiceKinds.GROUP;
}
