import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '@app/core/config/config';
import { Observable } from 'rxjs';

export interface CustomFieldValue {
  id?: number | string;
  name: string;
  color: string;
}

export interface CustomField {
  fieldName: string;
  values: CustomFieldValue[];
}

export interface UpdateCustomField {
  fieldName: string;
  value: Partial<CustomFieldValue>;
}

export interface UpdateCustomFieldResult extends UpdateCustomField {
  id: number;
}

@Injectable({ providedIn: 'root' })
export class CustomFieldsApiService {
  private readonly url = `${config.apiPath}/user/guide/crm/custom-fields`;

  constructor(private readonly http: HttpClient) {}

  get(): Observable<CustomField[]> {
    return this.http.get<CustomField[]>(this.url);
  }

  put(data: UpdateCustomField): Observable<UpdateCustomFieldResult[]> {
    return this.http.put<UpdateCustomFieldResult[]>(this.url, data);
  }

  patch(data: UpdateCustomField): Observable<UpdateCustomFieldResult[]> {
    return this.http.post<UpdateCustomFieldResult[]>(this.url, data);
  }

  delete(data: UpdateCustomField): Observable<UpdateCustomFieldResult[]> {
    return this.http.delete<UpdateCustomFieldResult[]>(this.url, { body: data });
  }
}
