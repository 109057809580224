import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { puiDefaultProp } from '@awarenow/profi-ui-core';
import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionGuestsAttribute } from '../../types';
import { ClientSessionAttributesEnum } from '../../enums';

@Component({
  selector: 'app-client-session-guests-attribute',
  templateUrl: './client-session-guests-attribute.component.html',
  styleUrls: ['../client-session-attribute.scss'],
  styles: ['.text-lowercase {text-transform: lowercase !important;}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionGuestsAttributeComponent implements ClientSessionGuestsAttribute {
  type: ClientSessionAttributesEnum.GUESTS = ClientSessionAttributesEnum.GUESTS;

  @Input()
  @puiDefaultProp()
  leftIcon: SessionAttributeIconEnum.USER_PLUS = SessionAttributeIconEnum.USER_PLUS;

  @Input() label!: string;

  @Input()
  @puiDefaultProp()
  items: { label: string }[] = [];
}
