<app-form-field #field>
  <ui-client-selector-single
    [formControl]="instructorControl"
    appInput
    (typed)="filter($event)"
    [clientOptionTemplateRef]="clientOptionTemplateRef"
    [clients]="users$ | async"
    placeholder="Choose instructor"
    [autocomplete-connected-to]="field.origin"></ui-client-selector-single>

  <ng-template let-option #clientOptionTemplateRef>
    <app-user-info [user]="option" [disableNamedUrl]="true"></app-user-info>
  </ng-template>
</app-form-field>
