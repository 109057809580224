import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-notes-filters',
  templateUrl: './notes-filters.component.html',
  styleUrls: ['./notes-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesFiltersComponent {
  @Input()
  // @ts-expect-error TS2564
  searchControl: FormControl;

  @Input()
  // @ts-expect-error TS2564
  canShareNotes: boolean;

  @Input()
  // @ts-expect-error TS2564
  clientId: number;
}
