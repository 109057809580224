export class RequiredReCaptchaSiteKey extends Error {
  readonly name = 'RequiredReCaptchaSiteKey';

  constructor() {
    super('Missed reCAPTCHA site key!');
  }
}

export class ReCaptchaUndefined extends Error {
  readonly name = 'ReCaptchaUndefined';

  constructor() {
    super('grecaptcha is undefined!');
  }
}

export class ReCaptchaMissedToken extends Error {
  readonly name = 'ReCaptchaMissedToken';

  constructor() {
    super('Missed reCAPTCHA token!');
  }
}
