import { NgModule } from '@angular/core';
import { GuideClientsApiService } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SERVICE_SCHEDULING_STORE } from './commons/consts';
import { ServiceSchedulingEffects } from './service-scheduling.effects';
import { serviceSchedulingReducer } from './service-scheduling.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(SERVICE_SCHEDULING_STORE, serviceSchedulingReducer),
    EffectsModule.forFeature([ServiceSchedulingEffects])
  ],
  providers: [GuideClientsApiService]
})
export class GuideServicesStoreModule {}
