import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEventsRequestOptions, IExternalEventCompositeKey, IExternalEvents } from '../../types';
import config from '../../../../../core/config/config';

function convertRequestOptionsToHttpParams(options: IEventsRequestOptions): HttpParams {
  return new HttpParams({
    fromObject: Object.entries(options).reduce((paramsObject, [optionName, optionValue]) => {
      let paramName = optionName;
      let paramValue;

      if (typeof optionValue === 'string') {
        paramValue = optionValue;
      } else if (Array.isArray(optionValue)) {
        paramName = `${paramName}[]`;
        // eslint-disable-next-line id-length
        paramValue = optionValue.map(v => (typeof v === 'string' ? v : v.toString()));
      } else {
        paramValue = optionValue.toString();
      }

      return {
        ...paramsObject,
        [paramName]: paramValue
      };
    }, {})
  });
}

@Injectable()
export class ExternalEventsApiService {
  readonly ENDPOINT = `${config.apiPath}/user/guide/external-events`;

  constructor(private readonly _http: HttpClient) {}

  hideEvent$(event: IExternalEventCompositeKey): Observable<void> {
    return this._http.post<void>(`${this.ENDPOINT}/hidden`, event);
  }

  loadEvents$(options: IEventsRequestOptions = {}): Observable<IExternalEvents> {
    const params = convertRequestOptionsToHttpParams(options);

    return this._http.get<{ events: IExternalEvents }>(this.ENDPOINT, { params }).pipe(map(({ events }) => events));
  }
}
