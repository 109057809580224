<span [attr.data-qa-id]="'labeled-toggle-right-label-' + !labelIndex" class="label" [class.label_checked]="!labelIndex">
  {{labels[0]}}
</span>
<ng-container *ngIf="(isMobile$ | async)">
  <!-- For some Android devices pui-toggle not working with pui-tooltip -->
  <pui-toggle [checked]="!!labelIndex" (change)="emitChange($event)"></pui-toggle>
</ng-container>
<ng-container *ngIf="!(isMobile$ | async)">
  <!-- Desktop with pui-tooltip -->
  <pui-toggle
    pui-tooltip="Switch to {{ labels[+!labelIndex] }} dashboard"
    [checked]="!!labelIndex"
    (change)="emitChange($event)"></pui-toggle>
</ng-container>
<span [attr.data-qa-id]="'labeled-toggle-left-label-' + !!labelIndex" class="left" [class.label_checked]="!!labelIndex">
  {{labels[1]}}
</span>
