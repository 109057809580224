import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { catchError, first, switchMap } from 'rxjs/operators';
import {
  GuideService,
  GuideServiceTypes,
  isGroupSessionService,
  isPackageService,
  isProgramService,
  isSessionService,
  PackageService,
  SessionService,
  ProgramService
} from '@app/shared/interfaces/services';
import { mapGuideProgramItem } from '@app/screens/guide/guide-services/utils/mappers';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import { GuidePackageService } from '@app/screens/guide/guide-packages/guide-package.service';
import { SessionsService } from '@app/core/session/sessions.service';
import { isSimpleSession, Session } from '@app/shared/interfaces/session';
import {
  AnalyticCopyTypes,
  AnalyticSourceTypes,
  AnalyticServiceTypes,
  InternalEvents
} from '@app/core/analytics/types';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services';
import { AllGuideServicesApiService } from './all-guide-services-api.service';
import { removeUndefined } from '../utils/helpers';
import { RemoveServiceModalComponent } from '../modals/remove-service-modal/remove-service-modal.component';
import { ProgramSharingModalComponent } from '../modals/program-sharing-modal/program-sharing-modal.component';
import { RemoveServiceBlockersModalComponent } from '../modals/remove-service-blockers-modal/remove-service-blockers-modal.component';

@Injectable()
export class GuideServicesService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _services$ = new BehaviorSubject<GuideService[]>([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _locale: ILocale;

  get services$(): Observable<GuideService[]> {
    return this._services$.asObservable();
  }

  readonly isLoading$ = new BehaviorSubject(false);

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _api: AllGuideServicesApiService,
    private readonly _modal: NgbModal,
    private _dialog: PuiDialogService,
    private _localeService: LocaleService,
    private _guidePackageService: GuidePackageService,
    private _sessionsService: SessionsService,
    private _auth: AuthService
  ) {
    this._locale = this._localeService.getLocale();
  }

  duplicateProgram(duplicatedService: ProgramService): void {
    this._api.duplicateProgram(duplicatedService.id).subscribe(service => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const program = mapGuideProgramItem({ ...duplicatedService, ...removeUndefined(service) } as any, this._locale);

      this._services$.next([...this._services$.getValue(), program]);
    });
  }

  refresh(): void {
    this.isLoading$.next(true);
    this._api.getServices$().subscribe(services => {
      this._services$.next(services);
      this.isLoading$.next(false);
    });
  }

  removeService(service: GuideService): void {
    let action$: Observable<void>;

    if (isGroupSessionService(service) || isSessionService(service)) {
      action$ = this.removeSessionService$(service as SessionService);
    } else if (isProgramService(service)) {
      action$ = this.removeProgramService$(service as ProgramService);
    } else if (isPackageService(service)) {
      action$ = this.removePackageService$(service as PackageService);
    } else {
      action$ = EMPTY;
    }

    action$.subscribe(() => this.removeServiceItem(service));
  }

  shareProgram(service: ProgramService): void {
    this.shareProgramService$(service).subscribe();
  }

  private removeProgramService$(service: ProgramService): Observable<void> {
    const { componentInstance, result } = this._modal.open(RemoveServiceModalComponent);
    componentInstance.numberOfParticipants = service.customersCount;
    componentInstance.serviceType = GuideServiceTypes.PROGRAM;

    return modalResultToObservable$(result).pipe(switchMap(() => this._api.removeProgram(service.id)));
  }

  private removePackageService$(service: PackageService): Observable<void> {
    this._guidePackageService.packageId = service.id.toString();
    if (this._guidePackageService.isPlatformAdmin) {
      return this.removePackageServiceHandler(service.id, 0);
    }
    this._guidePackageService.loadClients();
    return this._guidePackageService.activeClientsCount$.pipe(
      first(),
      // @ts-expect-error TS2345
      switchMap(clients => this.removePackageServiceHandler(service.id, clients))
    );
  }

  private removePackageServiceHandler(serviceId: number, activeClients: number): Observable<void> {
    const { componentInstance, result } = this._modal.open(RemoveServiceModalComponent);
    componentInstance.numberOfParticipants = activeClients;
    componentInstance.serviceType = GuideServiceTypes.PACKAGE;
    return modalResultToObservable$(result).pipe(switchMap(() => this._api.removePackage(serviceId)));
  }

  private removeServiceItem(service: GuideService): void {
    this._services$.next(this._services$.getValue().filter(oldService => oldService.id !== service.id));
  }

  private removeSessionService$(service: SessionService): Observable<void> {
    return this._sessionsService.futureSessions$.pipe(
      first(),
      switchMap(futureSessions => this.removeSessionServiceHandler(service, futureSessions)),
      catchError(error => {
        const { status, error: data } = error;

        if (status === 403 && Array.isArray(data.packages) && Array.isArray(data.programs)) {
          this._dialog.open(RemoveServiceBlockersModalComponent, {
            size: 's',
            hasCloseButton: true,
            data: {
              packages: data.packages,
              programs: data.programs,
              isPlatformAdmin: this._auth.isPlatformAdmin()
            }
          });
        }

        return throwError(error);
      })
    );
  }

  private removeSessionServiceHandler(service: SessionService, futureSessions: Session[]): Observable<void> {
    const { componentInstance, result } = this._modal.open(RemoveServiceModalComponent);
    componentInstance.numberOfParticipants = this.hasFutureSession(service.id, futureSessions) ? 1 : 0;
    componentInstance.serviceType = isGroupSessionService(service)
      ? GuideServiceTypes.GROUP_SESSION
      : GuideServiceTypes.SESSION;
    return modalResultToObservable$(result).pipe(switchMap(() => this._api.removeSessionService$(service.id)));
  }

  private shareProgramService$(service: ProgramService): Observable<void> {
    const { componentInstance, result } = this._modal.open(ProgramSharingModalComponent);
    componentInstance.programLink = `${this._locale.baseUrl}/programs/${service.id}`;
    componentInstance.linkCopied.subscribe(() =>
      this._analyticsService.event(InternalEvents.LINK_COPY, {
        copy_type: AnalyticCopyTypes.SERVICE_LIST,
        link_type: AnalyticSourceTypes.SERVICE,
        service_price: service.price,
        service_type: AnalyticServiceTypes.PROGRAM
      })
    );

    return modalResultToObservable$(result);
  }

  private hasFutureSession(serviceId: number, sessions: Session[]): boolean {
    return sessions.some(session =>
      isSimpleSession(session) ? session.templateId === serviceId : session.id === serviceId
    );
  }
}
