<div>
  <!--HEADING-->
  <div class="heading pui-space_bottom-8">
    <h1 i18n="@@signInTfaAppTitle" pui-typography="h1">Two-factor authentication</h1>
  </div>
  <!--BODY-->
  <div class="auth-form">
    <div>
      <div class="pui-space_bottom-5">
        <p i18n="@@signInTfaAppSubTitle" pui-typography="body-s">
          Enter a security code generated by your two factor authentication app to verify it’s you
        </p>
      </div>

      <pui-otp-input
        class="pui-otp-input_center"
        [formControl]="otpCodeControl"
        numOfDigits="6"
        puiPlaceholder="000000"></pui-otp-input>
      <span
        *ngIf="showInvalidCodeError$ | async"
        class="invalid-code-error"
        pui-typography="body-s"
        i18n="@@signInTfaInvalidCode"
        >Invalid code provided. Please try again
      </span>
    </div>
  </div>
  <!--FOOTER-->
  <div class="pui-space_top-8 backup-code">
    <p pui-typography="body-s">
      <span i18n="@@dontHaveYourPhone">Don’t have your phone?</span>
      <br />
      <a pui-link pui-typography="body-s" i18n="@@usebackupcode" (click)="useBackupCode.emit()"
        >Use a backup code to access your account
      </a>
    </p>
  </div>
</div>
