<div class="auth-body">
  <h4 class="title" i18n="@@signInWelcomeBackTitle">Welcome back, {{ firstName }}</h4>
  <div class="auth-form">
    <form [formGroup]="signInForm" (ngSubmit)="signIn()">
      <label for="email" i18n="@@email">Email</label>
      <input
        formControlName="email"
        id="email"
        type="email"
        class="form-control"
        readonly
        data-qa-id="sign-in__textfield-email" />
      <label for="password" i18n="@@password">Password</label>
      <app-input-password
        componentId="password"
        formControlName="password"
        className="lg"
        qaId="sign-in__textfield-password"
        (change)="onPasswordChanged()"></app-input-password>
      <app-form-control-error [control]="signInForm.controls['password']" [show]="wrongPassword">
        <ng-template [ngIf]="wrongPassword" [ngIfElse]="invalidPassword" i18n="@@wrongPassword">
          Passwords are not matching, please check and try again.
        </ng-template>
        <ng-template #invalidPassword i18n="@@invalidPassword">Invalid password.</ng-template>
      </app-form-control-error>

      <div class="auth-buttons">
        <app-button className="btn-primary btn-lg" buttonType="submit" i18n="@@signIn" qaId="sign-in__button-submit">
          Sign in
        </app-button>
        <div class="forgot-pass-btn">
          <app-button className="btn-link btn-lg" routerLink="/auth/forgot-password" i18n="@@forgotPassword">
            Forgot Password?
          </app-button>
        </div>
      </div>

      <!--
        Necessary solution to mention client about privacy. See more:
        https://developers.google.com/recaptcha/docs/faq?hl=ru#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
      -->
      <div class="pui-space_top-1 text-center">
        <small pui-typography="body-s">
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" rel="noopener" href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a target="_blank" rel="noopener" href="https://policies.google.com/terms">Terms of Service</a> apply.
        </small>
      </div>
    </form>
  </div>
</div>
