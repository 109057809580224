import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IFAQuestion, FAQuestion } from '@app/modules/guide-service-editor/types/faq';

@Injectable()
export class GuideFAQEditorService {
  constructor(private _formBuilder: FormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createFAQForm(faq?: IFAQuestion[], isAuthor?: boolean) {
    return this._formBuilder.array(this.FAQToForm(faq, isAuthor));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private FAQToForm(faq?: IFAQuestion[], isAuthor?: boolean) {
    return faq && faq.length
      ? faq.map(faQuestion =>
          this._formBuilder.group({
            id: [faQuestion.id],
            localId: [faQuestion.localId],
            question: [faQuestion.question],
            answer: [faQuestion.answer]
          })
        )
      : isAuthor === null || isAuthor === undefined || isAuthor === true
      ? [this._formBuilder.group(new FAQuestion())]
      : [];
  }
}
