import { Injectable } from '@angular/core';
import { generateTimezoneOptions, ITimezoneOption } from '@app/shared/utils/generate-timezones';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class TimezonesService extends Observable<ITimezoneOption[]> {
  constructor() {
    super(subscriber => subscriber.next(generateTimezoneOptions()));

    return this.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
