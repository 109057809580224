import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import config from '@app/core/config/config';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { IPublicProgramItem, ProgramGuide } from '@app/shared/interfaces/programs';
import { MarketplaceProgramFilterApiAdapterService } from '@app/modules/marketplace-filters/services/marketplace-program-filter-api-adapter.service';
import { User } from '@app/shared/interfaces/user';
import { IPublicProgram } from '@app/modules/program/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMarketplacePublicPrograms {
  programs: IPublicProgramItem[];
  cursor: number | null;
}

@Injectable()
export class PublicProgramsService {
  private readonly ENDPOINT = `${config.apiPath}/public/programs`;

  private readonly CLIENT_PROGRAMS_ENDPOINT = `${config.apiPath}/user/client/programs`;

  private readonly MARKETPLACE_PROGRAMS_ENDPOINT = `${config.apiPath}/public/marketplace/programs`;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _filtersQueryParamsString = '';

  constructor(private _http: HttpClient, private _marketplaceFilterAdapter: MarketplaceProgramFilterApiAdapterService) {
    this._marketplaceFilterAdapter
      .getQueryParams$()
      .subscribe(paramsString => (this._filtersQueryParamsString = paramsString));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activateInvitation$(code: string): Observable<any> {
    return this._http.post(`${this.CLIENT_PROGRAMS_ENDPOINT}/invitations`, { code });
  }

  declineClientInvitation$(programId: number): Observable<{ deleted: boolean }> {
    return this._http.delete<{ deleted: boolean }>(`${this.CLIENT_PROGRAMS_ENDPOINT}/${programId}/invitation`).pipe(
      catchError(error => {
        const notFound = error.status === 404;
        return throwError({ ...error, notFound });
      })
    );
  }

  getProgram$(programId: number): Observable<IPublicProgram> {
    return this._http.get<{ program: IPublicProgram }>(`${this.ENDPOINT}/${programId}`).pipe(
      map(({ program: { guide, coauthors, ...programContent } }) => ({
        ...programContent,
        coauthors: coauthors.map((coauthor: User) => new User(coauthor.id, coauthor.firstName, coauthor.lastName)),
        guide: new ProgramGuide(
          guide.id,
          guide.firstName,
          guide.lastName,
          guide.photo,
          guide.namedUrl,
          guide.specialization,
          guide.shortQuote,
          undefined,
          undefined,
          programContent.workspaceId
        )
      })),
      catchError(error => {
        const notFound = error.status === 404;
        return throwError({ ...error, notFound });
      })
    );
  }

  getMarketplacePrograms$(queryParamsString: string): Observable<IMarketplacePublicPrograms> {
    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(this.MARKETPLACE_PROGRAMS_ENDPOINT, {
          params: new HttpParams({
            encoder: new CustomUrlQueryEncoder(),
            fromString: queryParamsString
          })
        })
        .pipe(
          // eslint-disable-next-line id-length
          catchError(e => {
            console.log(e);
            return throwError(e);
          }),
          map(({ programs, cursor }) => ({
            // @ts-expect-error TS7031
            programs: programs.map(({ guide, ...program }) => ({
              ...program,
              guide: new ProgramGuide(
                guide.id,
                guide.firstName,
                guide.lastName,
                guide.photo,
                guide.namedUrl,
                null,
                null
              )
            })),
            cursor
          }))
        )
    );
  }

  isAlreadyEnrolled$(programId: number): Observable<boolean> {
    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(`${this.CLIENT_PROGRAMS_ENDPOINT}/enrolled/${programId}`)
        .pipe(map(response => response.isCustomerEnrolled))
    );
  }
}
