import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { PuiIconSizeType } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-guide-service-location-icon',
  templateUrl: './guide-service-location-icon.component.html',
  styleUrls: ['./guide-service-location-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceLocationIconComponent {
  SessionConnectionTypes = SessionConnectionTypes;

  @Input() connectionType: SessionConnectionTypes = SessionConnectionTypes.IN_PLATFORM;

  @Input() iconSize: PuiIconSizeType = 's';

  get faviconUrl(): string {
    return this.runtimeConfigService.get('faviconUrl') as string;
  }

  constructor(private readonly runtimeConfigService: RuntimeConfigService) {}
}
