import { ResolverOptions } from '../../model/interfaces';
import { getDefaultFragments, getDefaultLocale } from '../get-defaults';

export default function toFormat(fragment: string, params?: ResolverOptions): string {
  const locale = (params && params.templateOptions && params.templateOptions.locale) || getDefaultLocale();
  const fragments = {
    ...getDefaultFragments(locale),
    ...((params && params.fragments) || {})
  };

  let format = '';

  // @ts-expect-error TS7053
  if (typeof fragments[fragment] === 'function') {
    // @ts-expect-error TS7053
    format = fragments[fragment](params && params.value, params && params.templateOptions);
  } else {
    // @ts-expect-error TS7053
    format = fragments[fragment];
  }

  return format;
}
