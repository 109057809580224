import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { ICroppedImage } from '@app/shared/interfaces/image';
import { dataURIToBlob } from '@app/shared/utils/data-uri-to-blob';
import { VariantSettings } from './variant-settings';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-program-cover-crop-modal',
  templateUrl: './program-cover-crop-modal.component.html',
  styleUrls: ['./program-cover-crop-modal.component.scss']
})
export class ProgramCoverCropModalComponent {
  @Input()
  // @ts-expect-error TS2564
  event: ImageCroppedEvent;

  @Input()
  imageFormat = 'jpeg';

  @Input()
  // @ts-expect-error TS2564
  type: string;

  @Input()
  // @ts-expect-error TS2564
  coverVariantsSettings: VariantSettings[];

  @Input()
  imagePrefix = 'program-cover';

  @Input()
  windowTitle: 'program' | 'session' | 'package' = 'program';

  @ViewChild('imageCropper', { static: false })
  // @ts-expect-error TS2564
  imageCropper: ImageCropperComponent;

  // @ts-expect-error TS2564
  croppedImageString: string;

  isLoading = true;

  images: File[] = [];

  variantIndex = 0;

  constructor(private _modal: NgbActiveModal) {}

  cancel(): void {
    this._modal.close({ cancel: true });
  }

  imageCropped(event: ImageCroppedEvent): void {
    // @ts-expect-error TS2322
    this.croppedImageString = event.base64;
  }

  dismiss(): void {
    this._modal.dismiss({ cancel: true });
  }

  imageLoaded(): void {
    this.isLoading = false;
  }

  next(): void {
    const croppedImage = this.buildCroppedImage();
    this.images.push(new File([dataURIToBlob(croppedImage.imageDataUri)], croppedImage.name, { type: this.type }));
    this.variantIndex += 1;

    if (this.variantIndex >= this.coverVariantsSettings.length) {
      this._modal.close({ images: this.images, croppedImageString: this.croppedImageString });
    } else {
      this.imageCropper.imageBase64 = croppedImage.imageDataUri;
    }
  }

  private buildCroppedImage(): ICroppedImage {
    return {
      imageDataUri: this.croppedImageString,
      name: this.generateImageName(32, this.imageFormat)
    };
  }

  private generateImageName(nameLength = 32, imageFormat = 'jpg'): string {
    return `${this.imagePrefix}-${this.makeid(nameLength)}.${imageFormat}`;
  }

  private makeid(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
