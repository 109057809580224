import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { finalize, map } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { config } from '@app/core';
import { WikiTerm } from '../types';

@Injectable()
export class WikiService {
  private readonly API_PATH = config.apiPath;

  constructor(private _httpClient: HttpClient, private _notifications: NotificationsService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getWiki$(): Observable<any> {
    return this._httpClient
      .get<{ wiki: WikiTerm[] }>(`${this.API_PATH}/public/wiki`)
      .pipe(map(response => response.wiki.map(term => new WikiTerm(term))));
  }
}
