import { isGroupSession, isSimpleSession, Session } from '@app/shared/interfaces/session';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { GuideServiceParent } from '@app/modules/book-service';

export function collectServiceTypesFromSession(session: Session): GuideServiceTypes[] {
  // @ts-expect-error TS7034
  let types = [];

  if (!isSimpleSession(session) && !isGroupSession(session)) {
    // @ts-expect-error TS7005
    return types;
  }

  types.push(session.serviceType);

  if (session.serviceParent) {
    // @ts-expect-error TS2769
    types = types.concat(collectServiceTypeFromSessionParent(session.serviceParent));
  }

  // @ts-expect-error TS2322
  return types;
}

export function collectServiceTypeFromSessionParent(parent: GuideServiceParent): GuideServiceTypes[] {
  let types = [];

  if (parent && parent.type) {
    types.push(parent.type);
  }

  if (parent.serviceParent) {
    types = types.concat(collectServiceTypeFromSessionParent(parent.serviceParent));
  }

  return types;
}
