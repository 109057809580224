import { Injectable } from '@angular/core';
import { concat, interval, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IUser } from '@app/shared/interfaces/user';
import { QuizService } from '@app/core/quizzes/quiz.service';
import { ChatUserDetailed } from './types';

@Injectable()
export class ChatsBotsService {
  constructor(private _quizService: QuizService) {}

  getUser$(id: number, force = false): Observable<ChatUserDetailed> {
    // @ts-expect-error TS2322
    return this._quizService
      .getUser$(-id, force)
      .pipe(
        map(bot => (bot ? new ChatUserDetailed({ ...bot, id: -bot.id, botType: bot.type, parent: bot.creator }) : null))
      );
  }

  getUsersAsDictionary$(): Observable<{ [id: number]: IUser }> {
    return concat([interval(60 * 1000), of(0)]).pipe(
      switchMap(i =>
        this._quizService.getUsersAsDictionary$(!!i).pipe(
          map(dict => {
            const newDict = {};
            for (const [key, bot] of Object.entries(dict)) {
              // @ts-expect-error TS7053
              newDict[-key] = new ChatUserDetailed({
                ...bot,
                id: -bot.id,
                botType: bot.type,
                parent: bot.creator
              });
            }
            return newDict;
          })
        )
      )
    );
  }
}
