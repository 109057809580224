import { Component, Input } from '@angular/core';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-note-card-meta',
  templateUrl: './guide-note-card-meta.component.html',
  styleUrls: ['./guide-note-card-meta.component.scss']
})
export class GuideNoteCardMetaComponent {
  @Input()
  // @ts-expect-error TS2564
  note: GuideNote;
}
