import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AppFormFieldControl } from '../../form/components/form-field/form-field.component';
import { PHRASES } from '../../_base/_common/localize';
import { UiSelectorMultiple } from '../../_base/ui-selector-multiple/selector';

@Component({
  selector: 'ui-client-selector-multiple',
  templateUrl: 'template.html',
  styleUrls: ['./client-selector.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-client-selector'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => UiClientSelectorMultiple) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiClientSelectorMultiple implements ControlValueAccessor, AppFormFieldControl, OnDestroy {
  @Output()
  typed: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  inputTokenEnd: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  stateChanges = new Subject<void>();

  focused = false;

  private destroy$ = new Subject<void>();

  readonly text = PHRASES;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private readonly _clients$: BehaviorSubject<any[]>;

  readonly controlType = 'client-selector';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clients$: Observable<any[]>;

  @Input()
  // @ts-expect-error TS2564
  canAdd: boolean;

  @Input()
  showEmptyListTemplate = true;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any[] {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(newValue: any[]) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
      this._onTouched();
      this.valueChanged.emit(this._value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any[] = [];

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get clients(): any[] {
    return this._clients;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set clients(clients: any[]) {
    if (clients !== this._clients) {
      this._clients = clients;
      this._clients$.next(clients);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _clients: any[] = [];

  // @ts-expect-error TS2564
  @ViewChild(UiSelectorMultiple, { static: true }) selector: UiSelectorMultiple;

  @HostBinding('attr.area-describedby') userAriaDescribedBy = '';

  @Input()
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input()
  required = false;

  @Input()
  placeholder: string = this.text['Choose client'];

  @Input()
  errorStateMatcher: ErrorStateMatcher | undefined;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('autocomplete-connected-to')
  // @ts-expect-error TS2564
  autocompleteConnectedTo: MatAutocompleteOrigin;

  @Input()
  autocompleteDisabled = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('client-count')
  // @ts-expect-error TS2564
  count: number;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('nothing-found-text')
  // @ts-expect-error TS2564
  nothingFoundText: string;

  /* TemplateRef that is displayed when the option list is empty  */
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyListTemplateRef: TemplateRef<any> | undefined;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? !!this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled = false;

  /* get a text label for an option in the options list */
  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getOptionLabel() {
    return this._getOptionLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getOptionLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getOptionLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getOptionLabel = (option: any) => option?.name || option?.email || option?.contacts?.email;

  /* get text label for selected options */
  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getBadgeLabel() {
    return this._getBadgeLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getBadgeLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getBadgeLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getBadgeLabel = (option: any) => option?.name || option?.email || option?.contacts?.email;

  /* match already selected options with the general list of options */
  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get compareWith() {
    return this._compareWith;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set compareWith(fn: (option1: any, option2: any) => boolean) {
    if (!fn) {
      return;
    }
    this._compareWith = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _compareWith = (option1: any, option2: any) =>
    option1?.id && option2?.id ? option1.id === option2.id : option1?.email === option2?.email;

  get inputValue(): string | undefined {
    return this.selector?.typedValue;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private _changeDetectorRef: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this._clients$ = new BehaviorSubject([]);
    this.clients$ = this._clients$.asObservable();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected(value: any): void {
    this._onChange(value);
    this._onTouched();
    this.valueChanged.emit(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
  }

  onPanelOpen(): void {
    this.focused = true;
    this.stateChanges.next();
  }

  onPanelClose(): void {
    this.focused = false;
    this.stateChanges.next();
  }

  addClientByClick(): void {
    // @ts-expect-error TS2532
    this.selector.chipListInput.onInputTokenEnd({
      input: this.selector.chipListInput?.inputElement?.nativeElement,
      // @ts-expect-error TS2322
      value: this.selector.chipListInput?.value
    });

    // Timeout is needed to wait for the focus() event trigger on chip input.
    setTimeout(() => {
      // @ts-expect-error TS2532
      this.selector.chipListInput.inputElement.nativeElement.focus();
      // @ts-expect-error TS2532
      this.selector.chipListInput.trigger.openPanel();
    });
  }
}
