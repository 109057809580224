<h5 class="title" i18n="@@pros">Pros</h5>

<app-search-input
  [(ngModel)]="searchText"
  placeholder="Search"
  i18n-placeholder="@@searchInputPlaceholder"></app-search-input>

<ng-template [ngIf]="guides && guides.length" [ngIfElse]="noUsers">
  <div class="user-info" *ngFor="let guide of guides | filterByName: searchText; trackBy: trackByFn">
    <app-user-info [user]="guide" [uiPayload]="chatAndBookLink"></app-user-info>

    <a
      class="button-with-vertical-dots"
      [ngbPopover]="actions"
      *ngIf="guide.reviewReadySessionId"
      popoverClass="actions-popover"
      placement="bottom-right"
      (click)="$event.preventDefault(); $event.stopPropagation()">
      <span class="point"></span>
    </a>

    <ng-template #actions>
      <button
        type="button"
        class="review-btn"
        i18n="@@clientGuidesListCompLeave"
        (click)="emitLeaveReview(guide.reviewReadySessionId)">
        Leave a review
      </button>
    </ng-template>
  </div>
</ng-template>

<ng-template #noUsers>
  <div class="no-users" transloco="guidesEmpty">Here you'll see the list of your coaches</div>
</ng-template>

<ng-template #chatAndBookLink let-user="$implicit">
  <div class="ui-payload-wrapper">
    <a [routerLink]="['../chat', buildDirectChatId(user)]" class="user-chat-link">
      <span class="user-chat-link-icon"></span>
      <span class="user-chat-link-text" i18n="@@sendMsg">Send message</span>
    </a>
    <a (click)="$event.stopPropagation(); bookSession.emit(user)" class="user-book-link">
      <span class="user-book-link-icon"></span>
      <span class="user-book-link-text" i18n="@@bookSessionWithoutA">Book session</span>
    </a>
  </div>
</ng-template>
