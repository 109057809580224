<div>
  <div class="pui-space_bottom-5">
    <h2 pui-typography="h2" i18n="@@SelectTheDatesForOverride">Select the dates for override</h2>
  </div>

  <button (click)="back()" class="pui-space_bottom-6" pui-link>
    <pui-icon svgIcon="pui:chevron-left" size="xs"></pui-icon>
    <span i18n="@@Back" class="pui-space_left-1">Back</span>
  </button>

  <app-date-overrides-form [excludedDates]="excludedDates" [formControl]="dateOverrides"></app-date-overrides-form>
</div>

<div class="date-overrides-drawer-wrapper__buttons">
  <button *ngIf="dateOverrides.value" (click)="save()" pui-button>
    <span i18n="@@SaveOverride">Save override</span>
  </button>

  <button pui-button (click)="close()" appearance="secondary">
    <span i18n="@@Cancel">Cancel</span>
  </button>
</div>
