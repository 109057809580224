import { NgModule } from '@angular/core';
import { AvailabilityService, GuideAvailabilityApiService } from '@libs/services/http/http-availability.service';

@NgModule({})
export class GuideStoreModule {
  static forFeature() {
    return {
      ngModule: GuideStoreModule,
      providers: [
        {
          provide: AvailabilityService,
          useClass: GuideAvailabilityApiService
        }
      ]
    };
  }
}
