import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '@app/core';

@Injectable()
export class ClientInvitationActivationService {
  constructor(private _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activateClientInvitation$(hash: string): Observable<any> {
    return this._http.post(`${config.apiPath}/user/common/invitations/activation`, { hash });
  }
}
