import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SidenavCurrentBookingPopupView } from '@app/modules/ui-kit/widgets/booking-popup/booking-popup.component';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { ClientCardsService } from '@app/layouts/main-layout/header/header-user-wallet/services/client-cards.service';
import { ICanSidenavMode } from '@app/modules/sidenav';
import { ISidenavCurrentBaseView, ISidenavCurrentWithPopupView, ISidenavCurrentWithSubtitleView } from '../interface';

@Component({
  selector: 'app-sidenav-current-base-view',
  templateUrl: 'templates/current-base-view.component.html',
  styleUrls: ['current-view.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'sidenav-current-view'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavCurrentBaseViewComponent implements ISidenavCurrentBaseView, ICanSidenavMode {
  @Input()
  // @ts-expect-error TS2564
  avatar: {
    text: string;
    avatarUrl?: string;
    color?: string;
    background?: string;
    rounded?: boolean;
  };

  @Input()
  title = '';

  @Input() mode: 'short' | 'full' | undefined;
}

@Component({
  selector: 'app-sidenav-current-subtitle-view',
  templateUrl: 'templates/current-subtitle-view.component.html',
  styleUrls: ['current-view.component.scss'],
  providers: [UiDestroyService, ClientCardsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavCurrentWithSubtitleViewComponent
  extends SidenavCurrentBaseViewComponent
  implements ISidenavCurrentWithSubtitleView, ICanSidenavMode
{
  @Input()
  // @ts-expect-error TS2564
  subtitle: string;
}

@Component({
  selector: 'app-sidenav-current-popup-view',
  templateUrl: 'templates/current-popup-view.component.html',
  styleUrls: ['current-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavCurrentWithPopupViewComponent
  extends SidenavCurrentBaseViewComponent
  implements ISidenavCurrentWithPopupView<SidenavCurrentBookingPopupView>, ICanSidenavMode
{
  @Input()
  // @ts-expect-error TS2564
  subtitle: SidenavCurrentBookingPopupView;
}
