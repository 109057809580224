import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-guide-tags',
  templateUrl: './guide-tags.component.html',
  styleUrls: ['./guide-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideTagsComponent {
  private readonly tagsSubject = new BehaviorSubject<string[]>([]);
  readonly tags$ = this.tagsSubject.asObservable();

  readonly active$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get('tags') as string),
    map(tagsString => (tagsString ? tagsString.split(',') : null)),
    map(tags => tags?.map(tag => tag.charAt(0).toUpperCase() + tag.slice(1))),
    map(tags => (tags ? tags : []))
  );

  @Input()
  set items(value: string[] | null) {
    this.tagsSubject.next(value || []);
  }

  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}

  selectTag(tag: string[]): void {
    this.router.navigate([`/${environment.guidesRoute}`, { tags: tag?.slice(-1) }]);
  }

  filterTag(active: string[] | null, tags: string[] | null): string[] {
    if (active && tags) {
      return active?.filter(item => !tags?.includes(item));
    }
    return [];
  }
}
