import { DateTime } from 'luxon';
import { DateTimeOptions } from '../../../model/interfaces';
import { TIME_WITH_MINUTES, TIME_WITHOUT_MINUTES } from '../../../model/const';

function enTimeStyle(date: DateTime, templateOptions: DateTimeOptions): string {
  const hasMinutes = date && !!date.minute;
  let format = hasMinutes ? TIME_WITH_MINUTES : TIME_WITHOUT_MINUTES;
  const isAmPmHidden = templateOptions && templateOptions.hideAmPm;
  const isTimeHidden = templateOptions && templateOptions.hideTime;

  if (isAmPmHidden) {
    format = format.slice(0, -1);
  }

  if (isTimeHidden) {
    format = '';
  }

  return format;
}

export { enTimeStyle };
