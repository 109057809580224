import { CallInitiators } from '@app/shared/enums/call-initiators';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { environment } from '@env/environment';
import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { LOCATION_ORIGIN } from '@cnst/index';

export interface GetConnectionTypesOptions {
  name: string;
  platformName: string | undefined;
  address: string | null;
  connectionType: SessionConnectionTypes;
  phoneForSessions: string | undefined;
  callInitiator: CallInitiators;
  hideAddress: boolean;
  eventUuid: string;
}

export interface GetConnectionTypesResponse {
  icon: SessionAttributeIconEnum.VIDEO | SessionAttributeIconEnum.PLACE | SessionAttributeIconEnum.PHONE;
  label: string;
  url: string | undefined;
}

export const getConnectionTypes = ({
  platformName,
  connectionType,
  address,
  phoneForSessions,
  callInitiator,
  name,
  hideAddress,
  eventUuid
}: GetConnectionTypesOptions): GetConnectionTypesResponse | null => {
  if (!connectionType) {
    return null;
  }

  let icon: SessionAttributeIconEnum;
  let label: string;
  let url: string | undefined;

  switch (connectionType) {
    case SessionConnectionTypes.IN_PLATFORM:
      icon = SessionAttributeIconEnum.VIDEO;
      label = `${platformName}`;
      url = `/meeting/${eventUuid}`;
      break;
    case SessionConnectionTypes.ZOOM:
      icon = SessionAttributeIconEnum.VIDEO;
      label = `Zoom`;
      url = generateFullZoomUrl(eventUuid);
      break;
    case SessionConnectionTypes.IN_PERSON:
      icon = SessionAttributeIconEnum.PLACE;
      const defaultLabelText = ` In-person`;
      label = !hideAddress ? address || defaultLabelText : defaultLabelText;
      break;
    case SessionConnectionTypes.PHONE:
      const text = phoneLabelText(phoneForSessions, callInitiator, name);
      icon = SessionAttributeIconEnum.PHONE;
      label = ` Phone call` + ': ' + `${text}`;
      break;
    default:
      icon = SessionAttributeIconEnum.PLACE;
      label = `${platformName}`;
  }

  return { icon, label, url };
};

const phoneLabelText = (
  phoneForSessions: string | undefined,
  callInitiator: CallInitiators,
  name: string | undefined
): string => {
  const isGuideCallInitiator = callInitiator === CallInitiators.GUIDE;
  const isClientCallInitiator = callInitiator === CallInitiators.CLIENT;
  let text = '';

  if (name) {
    if (phoneForSessions) {
      text += phoneForSessions + ' ';
    }

    if (isGuideCallInitiator) {
      text += `${name} will call you.`;
    } else if (isClientCallInitiator) {
      text += `${name} will be waiting for your call.`;
    }
  }

  return text;
};

export const generateFullZoomUrl = (eventUuid: string): string => {
  const backendUrl = `${environment.apiHost}/v2/zoom-meeting/${eventUuid}`;
  return `${LOCATION_ORIGIN}/external-link?url=${backendUrl}`;
};
