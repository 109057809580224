import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { FloatingPopoverComponent } from './floating-popover.component';
import { FloatingPopoverService } from './floating-popover.service';

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [FloatingPopoverComponent],
  providers: [FloatingPopoverService]
})
export class FloatingPopoverModule {}
