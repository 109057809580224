// eslint-disable-next-line max-classes-per-file
import { PackageIncludes } from '@app/modules/package/types/package-session-template';
import { IWorkspace, WorkspaceUser } from '@app/modules/workspaces/types';
import { ProgramAccessRoles } from '@app/screens/guide/guide-programs/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionType } from '@app/shared/enums/session-type';
import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
import { assertNever } from '@app/shared/utils/assertions';
import { ILocale } from '@env/locale.interface';

export enum GuideServiceTypes {
  GROUP_SESSION = 'group_session',
  PACKAGE = 'package',
  PROGRAM = 'program',
  SESSION = 'session',
  /**
   * @deprecated
   */
  WEBINAR = 'webinar'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramServiceAuthor {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly namedUrl: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBookingResult {
  readonly id: number;
  readonly serviceId: number;
  readonly serviceType: GuideServiceTypes;
}

export class ProgramServiceAuthor {
  readonly id: number;

  readonly firstName: string;

  readonly lastName: string;

  readonly namedUrl: string;

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(authorDetails: IProgramServiceAuthor) {
    if (!authorDetails) {
      throw new Error('Invalid argument');
    }

    this.id = authorDetails.id;
    this.firstName = authorDetails.firstName;
    this.lastName = authorDetails.lastName;
    this.namedUrl = authorDetails.namedUrl;
  }
}

class GuideServiceBase {
  locale: ILocale;

  id: number;

  name: string;

  description?: string | null;

  price?: number | null;

  subscriptionPrice?: number | null;

  subscriptionRecurrency?: SubscriptionRecurrency | null;

  totalPayments?: number | null;

  status?: string | null;

  hidePrice?: boolean | null;

  guideId: number;

  team: WorkspaceUser[];

  workspace?: Pick<IWorkspace, 'id' | 'type'>;

  modules: {
    id: number;
    moduleType: string;
    serviceId: number;
    title: string;
  }[];

  // @ts-expect-error TS7006
  constructor(props, locale) {
    this.id = props.id;
    this.guideId = props.guideId;
    this.name = props.name;
    this.description = props.description == null ? null : props.description;
    this.price = props.price == null ? null : props.price;
    this.subscriptionPrice = props.subscriptionPrice == null ? null : props.subscriptionPrice;
    this.status = props.status == null ? null : props.status;
    this.subscriptionRecurrency = props.subscriptionRecurrency == null ? null : props.subscriptionRecurrency;
    this.totalPayments = props.totalPayments == null ? null : props.totalPayments;
    this.hidePrice = props.hidePrice == null ? null : props.hidePrice;
    this.locale = locale;
    this.team = props.team;
    this.workspace = props.workspace;
    this.modules = props.modules;
  }
}

export class GroupSessionService extends GuideServiceBase {
  duration: number;
  connectionType: SessionConnectionTypes;
  isProgrammatic?: boolean;
  recurring?: { count: number; freq: number; interval: number };
  sessionType?: SessionType;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return GuideServiceTypes.GROUP_SESSION;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return this.id ? `${this.locale.baseUrl}/sessions/${this.id}` : null;
  }

  // @ts-expect-error TS7006
  constructor(props, locale) {
    super(props, locale);

    this.sessionType = props.sessionType;
    this.recurring = props.recurring;
    this.duration = props.duration;
    this.connectionType = props.connectionType;

    if (props.isProgrammatic != null) {
      this.isProgrammatic = props.isProgrammatic;
    }
  }
}

export class PackageService extends GuideServiceBase {
  sessions: PackageIncludes[];

  customersCount: number;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return GuideServiceTypes.PACKAGE;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return this.id ? `${this.locale.baseUrl}/packages/${this.id}` : null;
  }

  // @ts-expect-error TS7006
  constructor(props, locale) {
    super(props, locale);
    this.sessions = props.sessions;
    this.customersCount = props.customersCount;
    this.hidePrice = props.isHiddenPrice;
    this.locale = locale;
  }
}

export class ProgramService extends GuideServiceBase {
  startDate: string | null;

  customersCount: number;

  author?: ProgramServiceAuthor;

  accessRole?: ProgramAccessRoles;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return GuideServiceTypes.PROGRAM;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return this.id ? `${this.locale.baseUrl}/programs/${this.id}` : null;
  }

  // @ts-expect-error TS7006
  constructor(props, locale) {
    super(props, locale);

    this.startDate = props.startDate;
    this.customersCount = props.customersCount;
    this.accessRole = props.accessRole;

    if (props.author) {
      this.author = new ProgramServiceAuthor(props.author);
    }
  }
}

export class SessionService extends GuideServiceBase {
  duration: number;
  connectionType: SessionConnectionTypes;
  serviceType: GuideServiceTypes;
  isProgrammatic?: boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get type() {
    return GuideServiceTypes.SESSION;
  }

  // @ts-expect-error TS7006
  constructor(props, locale) {
    super(props, locale);

    this.duration = props.duration;
    this.connectionType = props.connectionType;
    this.serviceType = props.sessionType;

    if (props.isProgrammatic != null) {
      this.isProgrammatic = props.isProgrammatic;
    }
  }
}

export type GuideService = GroupSessionService | PackageService | ProgramService | SessionService;

export function guideServiceFactory(item: { type: GuideServiceTypes }, locale: ILocale): GuideService {
  switch (item.type) {
    case GuideServiceTypes.GROUP_SESSION:
      return new GroupSessionService(item, locale);
    case GuideServiceTypes.PACKAGE:
      return new PackageService(item, locale);
    case GuideServiceTypes.PROGRAM:
      return new ProgramService(item, locale);
    case GuideServiceTypes.SESSION:
      return new SessionService(item, locale);
    default:
      assertNever(item, '"type"-property not found in:');
  }
}

export function guideServicesFactory(items: { type: GuideServiceTypes }[], locale: ILocale): GuideService[] {
  return items.map(item => {
    return guideServiceFactory(item, locale);
  });
}

export function isGroupSessionService(service: GuideService): service is GroupSessionService {
  return service.type === GuideServiceTypes.GROUP_SESSION;
}

export function isPackageService(service: GuideService): service is PackageService {
  return service.type === GuideServiceTypes.PACKAGE;
}

export function isProgramService(service: GuideService): service is ProgramService {
  return service.type === GuideServiceTypes.PROGRAM;
}

export function isSessionService(service: GuideService): service is SessionService {
  return service.type === GuideServiceTypes.SESSION;
}

export function getNormalizedServiceName(type: GuideServiceTypes): string {
  switch (type) {
    case GuideServiceTypes.SESSION:
      return `Individual session`;
    case GuideServiceTypes.GROUP_SESSION:
      return `Group session`;
    case GuideServiceTypes.PACKAGE:
      return `Package`;
    case GuideServiceTypes.PROGRAM:
      return `Program`;
    case GuideServiceTypes.WEBINAR:
      return `Webinar`;
    default:
      return '';
  }
}
