import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  ViewChild
} from '@angular/core';
import { InputDirective } from '@app/modules/ui-kit/form/directives/input.directive';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

/** An interface which allows a control to work inside of a `app-form-field`. */
@Directive()
export abstract class AppFormFieldControl {
  /**
   * Stream that emits whenever the state of the control changes such that the parent `AppFormField`
   * needs to run change detection.
   */
  // @ts-expect-error TS2564
  readonly stateChanges: Observable<void>;

  /** Whether the control is focused. */
  abstract focused: boolean;
}

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-form-field',
    '[class.focused]': '_control.focused'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent implements AfterViewInit {
  // @ts-expect-error TS2564
  @ContentChild(InputDirective) inputDirective: InputDirective;
  // @ts-expect-error TS2564
  @ViewChild('main', { read: ElementRef, static: true }) main: ElementRef<HTMLElement>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @ContentChild(AppFormFieldControl) _controlStatic: AppFormFieldControl;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  get _control() {
    return this._explicitFormFieldControl || this._controlStatic;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  set _control(value) {
    this._explicitFormFieldControl = value;
  }

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _explicitFormFieldControl: AppFormFieldControl;

  get origin(): MatAutocompleteOrigin {
    return { elementRef: this.main };
  }

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const control = this._control;

    // Subscribe to changes in the child control state in order to update the form field UI.
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    control.stateChanges.pipe(startWith(null)).subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });
  }
}
