<app-abstract-range-slider #abstractSlider>
  <div class="bar"></div>
  <div class="bar-from"></div>
  <div class="handle-from">
    <div class="price-indicator">{{ fromIndicatorValue }}</div>
  </div>
  <div class="bar-to"></div>
  <div class="handle-to">
    <div class="price-indicator">{{ toIndicatorValue }}</div>
  </div>
</app-abstract-range-slider>
