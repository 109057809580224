import { DateTime } from 'luxon';

import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { isGroupSession, Session } from '@app/shared/interfaces/session';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function countPendingEventsOffRange(
  pendingEvents: { dateStart: string }[],
  range: { currentStart: Date; currentEnd: Date }
) {
  if (!pendingEvents.length || !range) {
    return { pastCounter: 0, futureCounter: 0 };
  }

  const sinceDateTime = DateTime.fromJSDate(range.currentEnd);
  const untilDateTime = DateTime.fromJSDate(range.currentStart);

  return pendingEvents.reduce(
    (counters, event) => {
      const eventStartDateTime = DateTime.fromISO(event.dateStart);

      if (eventStartDateTime > sinceDateTime) {
        counters.futureCounter += 1;
      } else if (eventStartDateTime < untilDateTime) {
        counters.pastCounter += 1;
      }

      return counters;
    },
    { pastCounter: 0, futureCounter: 0 }
  );
}

export function filterPastSessions(sessions: Session[]): Session[] {
  const legalStatuses = [
    SessionStatuses.PENDING_APPROVEMENT,
    SessionStatuses.GUIDE_OFFER,
    SessionStatuses.IN_PROGRESS,
    SessionStatuses.RESCHEDULE_BY_CLIENT,
    SessionStatuses.RESCHEDULE_BY_GUIDE,
    SessionStatuses.DONE,
    SessionStatuses.APPROVED,
    SessionStatuses.PENDING,
    SessionStatuses.MISSED,
    SessionStatuses.EXPIRED
  ];

  return sessions.filter(
    session =>
      legalStatuses.includes(session.status) ||
      (isGroupSession(session) &&
        !session.sessions &&
        DateTime.fromISO(session.dateStart)
          .plus({ minutes: +session.duration })
          .diffNow('milliseconds').milliseconds < 0)
  );
}

export function filterFromGroupRequests(sessions: Session[]): Session[] {
  return sessions.filter(session => session.serviceType !== SessionTypes.GROUP_SESSION);
}

export function filterFromSimpleRequests(sessions: Session[]): Session[] {
  return sessions.filter(session => session.serviceType !== SessionTypes.SESSION);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IDateInterval {
  readonly start: Date | string;
  readonly end: Date | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly additionalData?: any;
}

export function isOverlapping(
  items: IDateInterval[],
  testItem: IDateInterval,
  members: IWorkspaceMember[] = []
): boolean {
  if (!items || !items.length) {
    return false;
  }

  const toDate = (date: Date | string): Date => (typeof date === 'string' ? new Date(date) : date);

  const testItermStart = toDate(testItem.start);
  const testItemEnd = toDate(testItem.end);

  return items
    .filter(item => members.map(member => member?.userId).includes(item?.additionalData?.session?.guideId))
    .some(item => {
      const itemStart = toDate(item.start);
      const itemEnd = toDate(item.end);

      return (
        (testItermStart > itemStart && testItermStart < itemEnd) ||
        (testItemEnd > itemStart && testItemEnd < itemEnd) ||
        (testItermStart <= itemStart && testItemEnd >= itemEnd)
      );
    });
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IFullCalendarDate {
  readonly start: string;
  readonly end: string;
}

export function isEventPassed(event: IFullCalendarDate, offset = 0): boolean {
  return DateTime.fromISO(event.end).plus({ minutes: offset }).diffNow('milliseconds').milliseconds > 0;
}
