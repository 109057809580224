import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { ScheduleBoardsModule } from '@app/modules/schedule-boards/schedule-boards.module';
import { GuideServicesModule } from '@app/screens/guide/guide-services/guide-services.module';
import { SharedModule } from '@app/shared';
import { ElementLoadingModule } from '@app/modules/ui-kit/element-loading/element-loading.module';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ProgramModule } from '@app/modules/program/program.module';
import { BillingModule } from '@app/modules/billing/billing.module';
import {
  PuiAvatarModule,
  PuiBadgeModule,
  PuiButtonModule,
  PuiCardModule,
  PuiDialogModule,
  PuiIconModule,
  PuiPaperModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';

import { ServiceBookingComponent } from './components/service-booking/service-booking.component';
import { ServicePickerComponent } from './components/service-picker/service-picker.component';
import { ServiceScheduleComponent } from './components/service-schedule/service-schedule.component';
import { ServiceBookingModalComponent } from './components/service-booking-modal/service-booking-modal.component';
import { ServiceDetailsFormComponent } from './components/service-details-form/service-details-form.component';
import { ServicePaymentOptionsFormComponent } from './components/service-payment-options-form/service-payment-options-form.component';

import { FormQuizzesComponent } from './components/form-quizzes/form-quizzes.component';
import { FormQuizComponent } from './components/form-quiz/form-quiz.component';
import { ServiceBillingComponent } from './components/service-billing/service-billing.component';
import { ServiceHostSelectionComponent } from './components/service-host-selection/service-host-selection.component';
import { AvailableSessionSchedulingComponent } from './components/available-session-scheduling/available-session-scheduling.component';
import { ClientSessionsModule } from '@app/modules/client-sessions/client-sessions.module';
import { LocaleService } from '@app/core/locale/locale.service';
import { BookingModalModule } from '@app/modules/book-service/components/booking-modal/booking-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GuideServiceModule,
    GuideServicesModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    ScheduleBoardsModule,
    SharedModule,
    NgbCollapseModule,
    ProgramModule,
    BillingModule,
    ElementLoadingModule,
    PuiIconModule,
    PuiButtonModule,
    PuiPaperModule,
    PuiCardModule,
    PuiBadgeModule,
    PuiAvatarModule,
    CurrentPaymentModule,
    PuiTypographyModule,
    ClientSessionsModule,
    PuiDialogModule,
    BookingModalModule
  ],
  declarations: [
    ServiceBookingComponent,
    ServicePickerComponent,
    ServiceScheduleComponent,
    ServiceBookingModalComponent,
    ServiceDetailsFormComponent,
    ServicePaymentOptionsFormComponent,
    FormQuizzesComponent,
    FormQuizComponent,
    ServiceBillingComponent,
    ServiceHostSelectionComponent,
    AvailableSessionSchedulingComponent
  ],
  exports: [ServiceBookingComponent],
  providers: [LocaleService]
})
export class BookServiceModule {}
