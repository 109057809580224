import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PUI_DRAWER_DATA, PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { SetPaymentDate, SetPaymentDateResult } from '../../types';
import {
  SubscriptionsApiService,
  UpdateSubscription
} from '@app/screens/guide/guide-programs/features/update-subscription/services/subscriptions-api.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
// eslint-disable-next-line no-restricted-imports
import { isBefore, isSameDay } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';

const minDateValidator = (minDate: Date) => (control: FormControl) =>
  isSameDay(control.value, minDate) || isBefore(control.value, minDate) ? { invalidDate: true } : null;

@Component({
  selector: 'app-set-payment-date',
  templateUrl: './set-payment-date.component.html',
  styleUrls: ['./set-payment-date.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetPaymentDateComponent {
  readonly minDate = new Date(this.data.paymentDate);
  readonly form = new FormGroup({
    nextPaymentDate: new FormControl(this.minDate, [minDateValidator(this.minDate)])
  });

  readonly isSubmitting$ = new BehaviorSubject(false);

  constructor(
    @Inject(PUI_DRAWER_DATA) readonly data: SetPaymentDate,
    @Inject(PuiDestroyService) private destroy$: Observable<void>,
    private dialogRef: PuiDrawerRef<SetPaymentDateResult>,
    private subscriptionsApiService: SubscriptionsApiService,
    private notificationsService: NotificationsService
  ) {
    if (!data) {
      throw Error('Missed data for SetPaymentDateComponent!');
    }
  }

  submit() {
    if (this.form.invalid || this.isSubmitting$.value) {
      return;
    }

    this.isSubmitting$.next(true);

    const { nextPaymentDate } = this.form.value;
    const { targetId, customerId, targetType } = this.data;

    this.subscriptionsApiService
      .updateSubscription({
        targetId: targetId,
        customerId,
        targetType,
        date: nextPaymentDate
      } as UpdateSubscription)
      .pipe(
        catchError(() => {
          this.notificationsService.error('Server error', 'Failed to update subscription');
          this.isSubmitting$.next(false);

          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.dialogRef.close(nextPaymentDate);
        this.isSubmitting$.next(false);
        this.notificationsService.success('Payment date is changed.');
      });
  }
}
