<div class="selected-clients" pui-paper shape="rounded">
  <pui-card>
    <pui-card-header>
      <pui-avatar-group pui-card-header-icon size="l">
        <pui-avatar
          *ngFor="let client of clients | slice: 0:MAX_DISPLAYED_GUIDES; trackBy: trackByClientId"
          size="l"
          [text]="client | userName"
          [avatarUrl]="client.photo"></pui-avatar>
        <ng-container *ngIf="clients.length - MAX_DISPLAYED_GUIDES > 0" ngProjectAs="pui-avatar">
          <pui-avatar
            size="l"
            [text]="'+' + (clients.length - MAX_DISPLAYED_GUIDES)"
            [autoColor]="false"
            role="button"
            aria-pressed="false"></pui-avatar>
        </ng-container>
      </pui-avatar-group>

      <div pui-card-header-title pui-typography="body-s" data-qa-id="selected-client-name">
        <ng-container>
          <ng-container *ngIf="clients.length < 4; else elseTemplate">
            <b>
              <ng-container *ngFor="let client of clients; let first = first; let last = last">
                <ng-container *ngIf="!last">{{ client | userName }},&nbsp;</ng-container>
                <ng-container *ngIf="last">{{ client | userName }}</ng-container>
              </ng-container>
            </b>
          </ng-container>
          <ng-template #elseTemplate>
            <b>{{ clients.length }}&nbsp;clients</b>
          </ng-template>
        </ng-container>

        <ng-container [ngPlural]="clients.length">
          <ng-template ngPluralCase="one">
            <span>&nbsp;was selected</span>
          </ng-template>
          <ng-template ngPluralCase="other">
            <span>&nbsp;were selected</span>
          </ng-template>
        </ng-container>
      </div>
      <button
        *ngIf="clients.length >= 4"
        pui-link
        pui-card-header-subheader
        type="button"
        role="button"
        pui-dropdown
        i18n="@@previewAll"
        [pui-dropdown-max-height]="240"
        [pui-dropdown-min-width]="242"
        [pui-dropdown-content]="selectedClientsDropdown"
        [items]="clients">
        Preview all
      </button>
    </pui-card-header>
  </pui-card>
</div>

<ng-template #selectedClientsDropdown let-items="items">
  <div class="selected-clients-dropdown">
    <div class="selected-clients-dropdown__row selected-clients-dropdown__row_devider">
      <label class="search-field pui-space_bottom-2">
        <pui-icon svgIcon="pui:search" size="s" class="pui-space_right-2 search-field__icon"></pui-icon>
        <input
          pui-typography="body-s"
          class="search-field__native"
          pui-input-text
          id="search-for-clients"
          type="search"
          placeholder="Search by name or e-mail..."
          i18n-placeholder="@@searchByNameOrEmail"
          [(ngModel)]="search" />
      </label>
    </div>
    <div class="selected-clients-dropdown__row">
      <ng-container *puiLet="items | filter: filterByNameOrEmail(search) as filteredClients">
        <ng-container *ngIf="filteredClients.length > 0; else noItems">
          <cdk-virtual-scroll-viewport itemSize="24" class="guide-services__list" autoHeight>
            <div *cdkVirtualFor="let item of filteredClients" class="guide-services__item">
              <pui-card>
                <pui-card-header>
                  <pui-avatar
                    pui-card-header-icon
                    size="s"
                    [text]="item | userName"
                    [avatarUrl]="item.photo"></pui-avatar>
                  <span pui-card-header-title pui-typography="body-s">
                    {{ item | userName }}
                  </span>
                </pui-card-header>
              </pui-card>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-container>
        <ng-template #noItems>
          <span class="guide-services__item guide-services__empty" pui-typography="body-s" i18n="@@noClientsFound">
            No clients found
          </span>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-template>
