<div class="header">
  <ui-card class="ui-card-flat">
    <ui-card-header>
      <pui-avatar
        ui-card-avatar
        uiIconBadge="online-status"
        uiBadgeIconColor="primary"
        [text]="userName"
        [uiIconBadgeHidden]="true"></pui-avatar>
      <ui-card-title>{{ email }}</ui-card-title>
      <ui-card-subtitle>
        <span i18n="@@notYetRegisteredSubtitle" class="subtitle">Not yet registered</span>
      </ui-card-subtitle>
    </ui-card-header>
  </ui-card>
</div>

<div class="wrap">
  <div class="no-chat-messages-view">
    <div class="no-chat-messages-icon"></div>
    <h6>
      <ng-container i18n="@@notYetRegistered">
        You’ll be able to chat with
        <span class="no-chat-messages-username" (click)="goToContactInfo()">{{ userName }}</span>
        once
        <br />
        they create an account
      </ng-container>
    </h6>
  </div>
</div>
