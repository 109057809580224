import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class SoloWorkspaceGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _notifications: NotificationsService,
    private _workspacesService: WorkspacesService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._workspacesService.isSolo$.pipe(
      map(isSolo => {
        if (isSolo) {
          return true;
        }

        if (this._auth.isAuthorized) {
          const title = `Only for Solo workspace`;
          this._notifications.error(title);
          return this._router.parseUrl('/');
        }

        return this._router.parseUrl('/auth');
      }),
      take(1)
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
