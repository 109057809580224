import { Directive, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appInput]'
})
export class InputDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroy$: Subject<any> = new Subject<any>();

  // @ts-expect-error TS2345
  error$: Subject<ValidationErrors> = new BehaviorSubject<ValidationErrors>(null);

  @HostBinding('class') classes = 'form-control';

  constructor(public el: ElementRef, @Optional() @Self() public control: NgControl) {}

  @HostListener('focusout') focusOut(): void {
    if (this.control && this.control.touched) {
      // @ts-expect-error TS2345
      this.error$.next(this.control.errors);
    }
  }

  ngOnInit(): void {
    if (this.control) {
      // @ts-expect-error TS2531
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      this.control.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
        if (this.control.dirty) {
          // @ts-expect-error TS2345
          this.error$.next(this.control.errors);
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
