import { Routes } from '@angular/router';

import { BlogArticleCreatorComponent } from './components/blog-article-creator/blog-article-creator.component';
import { BlogArticleEditorComponent } from './components/blog-article-editor/blog-article-editor.component';
import { BlogArticleComponent } from './components/blog-article/blog-article.component';
import { BlogComponent } from './components/blog/blog.component';
import { BusinessBlogArticleComponent } from './components/business-blog-article/business-blog-article.component';
import { BusinessBlogComponent } from './components/business-blog/business-blog.component';
import { AcceptableArticleIdentifierGuard } from './guards/acceptable-article-identifier.guard';
import { AdminOrBlogOwnerGuard } from './guards/admin-or-blog-owner-guard.service';
import { BlogOwnerGuard } from './guards/blog-owner.guard';
import { RedirectBlogOwnerToBlogDashboardGuard } from './guards/redirect-blog-owner-to-blog-dashboard.guard';
import { ArticleResolver } from './resolver/article.resolver';

export const blogRoutes: Routes = [
  {
    path: '',
    component: BlogComponent
  },
  {
    path: 'post/:id',
    component: BlogArticleComponent,
    resolve: {
      article: ArticleResolver
    }
  },
  {
    path: ':guide',
    component: BlogComponent,
    canActivate: [RedirectBlogOwnerToBlogDashboardGuard]
  },
  {
    path: ':guide/posts/new',
    component: BlogArticleCreatorComponent,
    canActivate: [BlogOwnerGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: ':guide/posts/:id',
    component: BlogArticleComponent,
    canActivate: [AcceptableArticleIdentifierGuard],
    resolve: {
      article: ArticleResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: ':guide/posts/:id/edit',
    component: BlogArticleEditorComponent,
    canActivate: [AdminOrBlogOwnerGuard, AcceptableArticleIdentifierGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '**',
    redirectTo: ''
  }
];

export const businessBlogRoutes: Routes = [
  {
    path: '',
    component: BusinessBlogComponent
  },
  {
    path: ':guide',
    component: BusinessBlogComponent,
    canActivate: [RedirectBlogOwnerToBlogDashboardGuard]
  },
  {
    path: ':guide/posts/new',
    redirectTo: '/blog/:guide/posts/new'
  },
  {
    path: ':guide/posts/:id',
    component: BusinessBlogArticleComponent,
    canActivate: [AcceptableArticleIdentifierGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: ':guide/posts/:id/edit',
    redirectTo: '/blog/:guide/posts/:id/edit'
  },
  {
    path: '**',
    redirectTo: ''
  }
];
