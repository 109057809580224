import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AbstractNavigationEntriesService } from '@app/modules/navigation/abstracts/';
import { INavigationEntry } from '@app/modules/navigation/interfaces';

@Injectable()
export class NavigationEntriesSuperAdminService extends AbstractNavigationEntriesService {
  role = UserRoles.SUPER_ADMIN;

  entries: Observable<INavigationEntry>[] = [
    // widget - primary
    this.navigationEntryFactory.superAdminOrganizations$(),
    this.navigationEntryFactory.superAdminAdministrators$()
  ];
}
