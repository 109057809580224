import { Bookings } from '@appWidget/modules/booking/interfaces';
import { SessionResponse } from '@appWidget/modules/booking-cancellation/services/private-sessions.service';

export function getBookingsFromSession(session: SessionResponse): Bookings[] {
  const recurring = session?.recurringSessionsDetails;
  if (Array.isArray(recurring) && recurring.length) {
    return (
      recurring.map(session => ({
        id: session.id,
        date: session.dateStart || session.date!,
        duration: session.duration
      })) || []
    );
  }

  if (session) {
    return [
      {
        id: session.id,
        date: session.dateStart || session.date!,
        duration: session.duration
      }
    ];
  }

  return [];
}
