import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { CalendarConnectionService } from '@app/core/calendars/calendar-connection.service';
import { ExternalCalendarSources } from '@app/core/calendars/types';
import { ExternalCalendarsService } from '@app/core/calendars/external-calendars.service';
import { InternalEvents } from '@app/core/analytics/types';
import { calendarTexts } from '@app/core/calendars/texts';

@Component({
  selector: 'app-external-calendars-accounts',
  templateUrl: './external-calendars-accounts.component.html',
  styleUrls: ['./external-calendars-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CalendarConnectionService],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'external-calendars-accounts'
  }
})
export class ExternalCalendarsAccountsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  readonly ExternalCalendarSources = ExternalCalendarSources;

  readonly calendarsSources$ = this._calendars.calendars$.pipe(
    map(calendarsSources =>
      Object.entries(calendarsSources).map(([source, accounts]) => {
        return {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          source: <ExternalCalendarSources>source,
          // @ts-expect-error TS7053
          name: calendarTexts[source].name,
          // @ts-expect-error TS7053
          description: calendarTexts[source].description,
          accounts
        };
      })
    )
  );

  constructor(
    private readonly _calendars: ExternalCalendarsService,
    private readonly _connections: CalendarConnectionService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _auth: AuthService
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this._calendars.refresh();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  connect(source: ExternalCalendarSources): void {
    this._connections
      .connect$({ source })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._calendars.refresh();
        this._analyticsService.event(InternalEvents.CALENDAR_CONNECT, { calendarType: source });
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  disconnect(source: ExternalCalendarSources, accountId: number) {
    this._calendars.disconnect(source, accountId);
  }
}
