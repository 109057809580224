import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DeclineData,
  DeclineResult
} from '@app/screens/guide/guide-sessions/components/drawers/guide-decline-drawer/guide-decline-drawer.types';
import { PUI_DRAWER_DATA, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';

@Component({
  selector: 'app-guide-decline-drawer',
  templateUrl: './guide-decline-drawer.component.html',
  styleUrls: ['./guide-decline-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideDeclineDrawerComponent {
  readonly textArea = new FormControl('');
  constructor(
    @Inject(PUI_DRAWER_DATA) private readonly data: DeclineData,
    @Inject(LOCALE_ID) private locale: string,
    private readonly timezoneStore: UserTimezoneStore,
    @Optional() private readonly ref?: PuiDrawerRef<DeclineResult>
  ) {}

  decline(): void {
    this.ref?.close({ result: true, reason: this.textArea.value });
  }

  getTitle(): string {
    const title = (this.data.count ? this.data.count + ' x ' : '') + this.data.title;

    // Date
    const date = this.data.date
      ? ` on ${formatDate(this.data.date, 'EEEE, MMM d, yyyy hh:mm', this.locale, this.timezoneStore.timezone)}`
      : '';

    // Client Name
    const forClient = this.data.clientName ? ` for ${this.data.clientName}` : '';

    return `Decline ${title} ${date}${forClient}?`;
  }
}
