import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { ClientSessionStatusLabel } from '@libs/business/client/client-session/constants';

export const clientSessionStatusLabelFactory = ({
  status,
  name,
  missed,
  isFreeSession,
  isExpired
}: {
  status: SessionStatuses;
  name: string;
  missed: boolean | undefined;
  isFreeSession: boolean;
  isExpired: boolean;
}): string => {
  if (!status && !name) {
    return 'unknown';
  }

  if (isExpired) {
    return `${ClientSessionStatusLabel[SessionStatuses.EXPIRED]}`;
  }

  if (missed) {
    return `${ClientSessionStatusLabel[SessionStatuses.MISSED]}`;
  }

  if (status === SessionStatuses.RESCHEDULE_BY_CLIENT || status === SessionStatuses.PENDING_APPROVEMENT) {
    return `${ClientSessionStatusLabel[SessionStatuses.PENDING]}`;
  }

  if (status === SessionStatuses.RESCHEDULE_BY_GUIDE || status === SessionStatuses.GUIDE_OFFER) {
    return `${ClientSessionStatusLabel[SessionStatuses.PENDING]}`;
  }

  if (status === SessionStatuses.APPROVED) {
    if (isFreeSession) {
      return `${ClientSessionStatusLabel[SessionStatuses.CONFIRMED]}`;
    } else {
      return `${ClientSessionStatusLabel[SessionStatuses.CONFIRMED_AND_PAID]}`;
    }
  }
  // === ToDo end =====================================================================

  return `${ClientSessionStatusLabel[status]}`;
};
