import { Directive, HostBinding, HostListener, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { SafePipe } from '@app/shared/pipes/safe/safe.pipe';
import { IGradient } from '@app/screens/our-community/interfaces';

@Directive({
  selector: '[appStyle]',
  providers: [SafePipe]
})
export class StyleDirective implements OnChanges {
  safe: SafePipe;

  @Input() lGradient?: IGradient;

  @Input() rGradient?: IGradient;

  @Input() backgroundColor?: string;

  @Input() backgroundImage?: string;

  @Input() hoverColor?: string;

  @Input() textColor?: string;

  // @ts-expect-error TS2564
  @HostBinding('style.background') background: SafeStyle;

  // @ts-expect-error TS7008
  @HostBinding('style.color') tColor;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @HostListener('mouseenter') onEnter() {
    if (this.hoverColor && this.backgroundColor) {
      this.background = this.hoverColor;
    }
    if (this.textColor && this.hoverColor) {
      this.tColor = this.hoverColor;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  @HostListener('mouseleave') onLeave() {
    if (this.hoverColor && this.backgroundColor) {
      this.background = this.backgroundColor;
    }
    if (this.textColor && this.hoverColor) {
      this.tColor = this.textColor;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private injector: Injector) {
    this.safe = injector.get(SafePipe);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (this.lGradient) {
      this.background = `linear-gradient(to right, ${this.lGradient.start} 13%, ${this.lGradient.end} 87%)`;
    }
    if (this.rGradient) {
      this.background = `radial-gradient(circle at 51% 50%, ${this.rGradient.end}, ${this.rGradient.start})`;
    }
    if (this.textColor) {
      this.tColor = this.textColor;
    }
    if (this.backgroundColor) {
      this.background = this.backgroundColor;
    }
    if (this.backgroundImage) {
      // @ts-expect-error TS2322
      this.background = this.safe.transform(this.backgroundImage, 'styleUrl');
    }
  }
}
