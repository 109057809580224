<app-guide-service-editor-landing
  [type]="(editor.template$ | async)?.serviceType"
  [title]="editor.templateName$ | async"
  (closeEditor)="close()">
  <div class="tabs" *ngIf="!isPlatformAdmin">
    <a routerLink="./settings" routerLinkActive="active" i18n="@@basicInfo">Basic info</a>
    <a routerLink="./questionnaire" routerLinkActive="active" i18n="@@questionnaire" *ngIf="false">Questionnaire</a>
    <a routerLink="./sharing" routerLinkActive="active" i18n="@@sharing">Sharing</a>
  </div>

  <main>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </main>
</app-guide-service-editor-landing>
