import { Injectable, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class SsrCookieService {
  private cookieStore = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(PLATFORM_ID) platformId: string, @Optional() @Inject(REQUEST) private req: any) {
    if (isPlatformServer(platformId) && this.req && this.req.cookies && typeof this.req.cookies === 'object') {
      this.cookieStore = this.req.cookies;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get(key: string) {
    // @ts-expect-error TS7053
    return this.cookieStore[key] ? this.cookieStore[key] : null;
  }
}
