<ng-container *puiLet="client$ | async as client">
  <ng-container *puiLet="client | toViewModel as clientViewModel">
    <div class="guide-client-details__container" pui-panel pui-panel-full-height>
      <div pui-panel-content>
        <div class="guide-client-details__client-fields">
          <div class="guide-client-details__field" *ngFor="let field of fields">
            <ng-container
              *ngTemplateOutlet="
                guideClientDetailsField;
                context: {
                  fieldKey: field,
                  clientViewModel: clientViewModel
                }
              "></ng-container>
          </div>

          <!--Assigned team members BEGIN-->
          <div
            class="guide-client-details__field"
            *ngIf="client?.type === 'guideClient' && (isWorkspaceAdmin$ | async) && !(isSoloWorkspace$ | async)">
            <div class="guide-client-details__field-label pui-space_bottom-1">
              <small pui-typography="label-time" i18n="@@assignedTeamMembers">Assigned team members</small>
              <pui-icon
                size="xs"
                class="guide-client-details__client-action-icon pui-space_left-1"
                svgIcon="pui:info"
                pui-tooltip="Assign a team member to share this client."
                i18n-pui-tooltip="@@contactDetailsTemplateAssignClientGuidesTooltip"></pui-icon>
            </div>
            <app-assigned-team-memeber-selector [clientId]="client.relationId"></app-assigned-team-memeber-selector>
          </div>
          <!--Assigned team members END-->
        </div>
      </div>

      <!--ARCHIVE ACTION START-->
      <!--3 Feature. Archive client for the client card component-->
      <!--3.1 This feature allows only for workspace admin or solo guide-->
      <div pui-panel-sticky-row *ngIf="isWorkspaceAdmin$ | async">
        <!--3.2 If client not archived. Display "Move to archive" button-->
        <div *ngIf="!client.archived" class="guide-client-details__client-action">
          <button
            pui-link
            class="guide-client-details__client-action"
            i18n="@@contactDetailsTemplateArchiveClientText:new"
            (click)="features.archiveClient(client.id)">
            Move to archive
          </button>
          <pui-icon
            class="guide-client-details__client-action-icon pui-space_left-1"
            svgIcon="pui:info"
            pui-tooltip="You won't lose any client information and can get it back later. You can't communicate or schedule within archived clients, but they won't count toward your client limit."
            i18n-pui-tooltip="@@contactDetailsTemplateArchiveClientTooltip:new"></pui-icon>
        </div>

        <!--3.3 If client is archived. Display "Move from archive" button-->
        <div *ngIf="client.archived" class="guide-client-details__client-action">
          <button pui-link (click)="features.unArchiveClient(client.id)">
            <span i18n="@@contactDetailsTemplateUnarchiveClientText:new">Unarchive client</span>
          </button>
          <pui-icon
            class="guide-client-details__client-action-icon pui-space_left-1"
            svgIcon="pui:info"
            pui-tooltip="You've reached your active client limit — upgrade your plan or archive other clients."
            i18n-pui-tooltip="@@contactDetailsTemplateUnarchiveClientTooltipClientLimitIsReached:new"></pui-icon>
        </div>
      </div>
      <!--ARCHIVE ACTION END-->
    </div>

    <!--FIELD TEMPLATE START-->
    <ng-template #guideClientDetailsField let-fieldKey="fieldKey" let-clientViewModel="clientViewModel">
      <div class="guide-client-details__field-label pui-space_bottom-1">
        <label [attr.id]="fieldKey">
          <small pui-typography="label-time" [attr.id]="'guide-client-details__label-' + fieldKey">
            {{ clientViewModel[fieldKey].label }}
          </small>
        </label>
      </div>
      <div class="guide-client-details__field-value" [ngSwitch]="fieldKey">
        <!--DEFAULT FIELD BEGIN-->
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="!isCurrentEditableField(fieldKey)" [ngSwitch]="fieldKey">
            <ng-container *ngSwitchCase="'stage'">
              <ng-container *ngIf="!(clientViewModel[fieldKey].value | isEmpty); else emptyField">
                <ng-container *ngTemplateOutlet="stageEditorTemplate; context: { fieldKey: fieldKey }"></ng-container>
              </ng-container>
            </ng-container>

            <!--CLIENT TAG FIELD BEGIN-->
            <ng-container *ngSwitchCase="'tag'">
              <ng-container *ngIf="!(clientViewModel[fieldKey].value | isEmpty); else emptyField">
                <ng-container *ngTemplateOutlet="tagEditorTemplate; context: { fieldKey: fieldKey }"></ng-container>
              </ng-container>
            </ng-container>
            <!--CLIENT TAG FIELD END-->

            <ng-container *ngSwitchDefault>
              <span *ngIf="clientViewModel[fieldKey].value" pui-typography="body-s">
                {{ clientViewModel[fieldKey].value }}
              </span>
            </ng-container>
          </ng-container>
          <!--EDITOR SWITCH BEGIN-->
          <ng-container *ngIf="isCurrentEditableField(fieldKey)" [ngSwitch]="fieldKey">
            <ng-container *ngSwitchCase="'firstName'">
              <ng-container
                *ngTemplateOutlet="
                  firstNameEditorFormTemplate;
                  context: { fieldKey: fieldKey, control: clientViewModel[fieldKey].control }
                "></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'lastName'">
              <ng-container
                *ngTemplateOutlet="
                  lastNameEditorFormTemplate;
                  context: { fieldKey: fieldKey, control: clientViewModel[fieldKey].control }
                "></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'email'">
              <ng-container
                *ngTemplateOutlet="
                  emailEditorFormTemplate;
                  context: { fieldKey: fieldKey, control: clientViewModel[fieldKey].control }
                "></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'phone'">
              <ng-container
                *ngTemplateOutlet="
                  phoneEditorFormTemplate;
                  context: { fieldKey: fieldKey, control: clientViewModel[fieldKey].control }
                "></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'organization'">
              <ng-container
                *ngTemplateOutlet="
                  organizationEditorTemplate;
                  context: { fieldKey: fieldKey, control: clientViewModel[fieldKey].control }
                "></ng-container>
            </ng-container>
            <!--CLIENT STAGE FIELD BEGIN-->
            <ng-container *ngSwitchCase="'stage'">
              <ng-container *ngTemplateOutlet="stageEditorTemplate; context: { fieldKey: fieldKey }"></ng-container>
            </ng-container>
            <!--CLIENT END FIELD BEGIN-->

            <!--CLIENT TAG FIELD BEGIN-->
            <ng-container *ngSwitchCase="'tag'">
              <ng-container *ngTemplateOutlet="tagEditorTemplate; context: { fieldKey: fieldKey }"></ng-container>
            </ng-container>
            <!--CLIENT TAG FIELD END-->
          </ng-container>
          <!--EDITOR SWITCH BEGIN-->

          <!--CREATE OR EDIT START-->
          <ng-container *ngIf="!isCurrentEditableField(fieldKey)">
            <!-- 1.1 If field has value. Display the Edit button. Else show the empty button (1.2) -->
            <ng-container *ngIf="!clientViewModel[fieldKey].selfEditor">
              <ng-container *ngIf="!(clientViewModel[fieldKey].value | isEmpty); else emptyField">
                <div
                  class="guide-client-details__field-edit-button pui-space_left-2"
                  [class.guide-client-details__field-edit-button_hover]="isDesktop$ | async">
                  <ng-container>
                    <div class="guide-client-details__field-edit-content">
                      <!--2.1 If field is editable. Display the edit button. This allow to show a field editor form-->
                      <button
                        *ngIf="clientViewModel[fieldKey].editable"
                        pui-icon-button
                        appearance="flat"
                        size="s"
                        shape="square"
                        (click)="setCurrentEditableField(fieldKey)"
                        aria-label="Edit field"
                        pui-tooltip="Edit"
                        i18n-pui-tooltip="@@Edit:new"
                        i18n-aria-label="@@Edit:new">
                        <pui-icon svgIcon="pui:edit"></pui-icon>
                      </button>

                      <!--2.2 If field is not editable. Display "pui:lock" icon-->
                      <button
                        *ngIf="!clientViewModel[fieldKey].editable"
                        pui-icon-button
                        appearance="flat"
                        size="s"
                        pui-tooltip="User registration data"
                        pui-tooltip-direction="left"
                        i18n-pui-tooltip="@@userRegistrationDataHint:new">
                        <pui-icon aria-hidden="true" svgIcon="pui:lock"></pui-icon>
                      </button>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <!--CREATE OR EDIT END-->
          <!-- 1.2 If field value is empty. Display the button with text "Add a field"  -->
          <ng-template #emptyField>
            <button type="button" pui-link (click)="setCurrentEditableField(fieldKey)">
              {{ clientViewModel[fieldKey].emptyLabel }}
            </button>
          </ng-template>
        </ng-container>
        <!--DEFAULT FIELD END-->
      </div>
    </ng-template>
    <!--FIELD TEMPLATE END-->

    <!-- FIRST NAME EDITOR BEGIN-->
    <ng-template #firstNameEditorFormTemplate let-fieldKey="fieldKey" let-control="control">
      <guide-client-field-editor
        [control]="control"
        (save)="saveEditFieldForm(client.id, fieldKey, $event)"
        (cancel)="closeEditFieldForm()">
        <div class="field-editor-form__row">
          <pui-input-field>
            <input
              pui-input-text
              autofocus
              placeholder="First name"
              i18n-placeholder="@@clientCard:First name"
              type="text"
              [formControl]="control" />
          </pui-input-field>
        </div>
      </guide-client-field-editor>
    </ng-template>
    <!-- FIRST NAME EDITOR END-->

    <!-- LAST NAME EDITOR BEGIN-->
    <ng-template #lastNameEditorFormTemplate let-fieldKey="fieldKey" let-control="control">
      <guide-client-field-editor
        [control]="control"
        (save)="saveEditFieldForm(client.id, fieldKey, $event)"
        (cancel)="closeEditFieldForm()">
        <div class="field-editor-form__row">
          <pui-input-field>
            <input
              pui-input-text
              autofocus
              placeholder="Last name"
              i18n-placeholder="@@clientCard:Last name"
              type="text"
              [formControl]="control" />
          </pui-input-field>
        </div>
      </guide-client-field-editor>
    </ng-template>
    <!-- LAST NAME EDITOR END-->

    <!-- EMAIL EDITOR BEGIN-->
    <ng-template #emailEditorFormTemplate let-fieldKey="fieldKey" let-control="control">
      <guide-client-field-editor
        [control]="control"
        (save)="saveEditFieldForm(client.id, fieldKey, $event)"
        (cancel)="closeEditFieldForm()">
        <div class="field-editor-form__row">
          <pui-input-field>
            <input
              pui-input-text
              autofocus
              placeholder="Email"
              i18n-placeholder="@@clientCard:E-mail"
              type="text"
              [formControl]="control" />
            <pui-input-error role="alert" [formControl]="control"></pui-input-error>
          </pui-input-field>
        </div>
      </guide-client-field-editor>
    </ng-template>
    <!-- EMAIL EDITOR END-->

    <!--PHONE EDITOR BEGIN-->
    <ng-template #phoneEditorFormTemplate let-fieldKey="fieldKey" let-control="control">
      <guide-client-field-editor
        [control]="control"
        (save)="saveEditFieldForm(client.id, fieldKey, $event)"
        (cancel)="closeEditFieldForm()">
        <div class="field-editor-form__row">
          <pui-phone-number [formControl]="control"></pui-phone-number>
        </div>
      </guide-client-field-editor>
    </ng-template>
    <!--PHONE EDITOR END-->

    <!--ORGANIZATION EDITOR BEGIN-->
    <ng-template #organizationEditorTemplate let-fieldKey="fieldKey" let-control="control">
      <guide-client-field-editor
        [control]="control"
        (save)="saveEditFieldForm(client.id, fieldKey, $event)"
        (cancel)="closeEditFieldForm()">
        <div class="field-editor-form__row">
          <pui-input-field>
            <input
              pui-input-text
              autofocus
              placeholder="Enter organization"
              i18n-placeholder="@@newnterPhoneNumber:new"
              type="text"
              [formControl]="control" />
          </pui-input-field>
        </div>
      </guide-client-field-editor>
    </ng-template>
    <!--ORGANIZATION EDITOR END-->

    <!--STAGE EDITOR BEGIN-->
    <ng-template #stageEditorTemplate let-fieldKey="fieldKey">
      <guide-client-stage-selector
        [stage]="clientViewModel[fieldKey].value"
        (stageChange)="features.stageClient(client.id, $event)"></guide-client-stage-selector>
    </ng-template>
    <!--STAGE EDITOR END-->

    <!--TAG EDITOR BEGIN-->
    <ng-template #tagEditorTemplate let-fieldKey="fieldKey">
      <guide-client-tag-selector
        class="tag-selector"
        [clientId]="client.id"
        [isStandalone]="true"
        [options]="clientViewModel[fieldKey].value"
        (optionsChange)="features.tagClient(client.id, $event)"></guide-client-tag-selector>
    </ng-template>
    <!--TAG EDITOR END-->
  </ng-container>
</ng-container>
