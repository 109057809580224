import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuideServiceBadgeModule } from '@app/modules/propose-service/components/guide-service-badge/guide-service-badge.module';
import { SharedModule } from '@app/shared';
import { SessionTypeModule } from '@app/shared/components/session-type/session-type.module';
import { SkipOwnerPipeModule } from '@app/shared/pipes/skip-owner/skip-owner.module';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiBadgeModule,
  PuiButtonModule,
  PuiCardModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiKeyboardWidgetModule,
  PuiLetModule,
  PuiList2Module,
  PuiSharedModule,
  PuiTextHighlightModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { SharedModule as LibSharedModule } from '@libs/shared/shared.module';

import { GroupServicesComponent } from './group-services.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PuiLetModule,
    PuiIconModule,
    PuiSharedModule,
    PuiBadgeModule,
    PuiButtonModule,
    PuiDropdownModule,
    PuiKeyboardWidgetModule,
    PuiList2Module,
    PuiCardModule,
    PuiAvatarModule,
    ScrollingModule,
    LibSharedModule,
    SkipOwnerPipeModule,
    SessionTypeModule,
    PuiAvatarGroupModule,
    PuiTextHighlightModule,
    PuiAvatarGroupModule,
    PuiAvatarModule,
    PuiTypographyModule,
    GuideServiceBadgeModule
  ],
  declarations: [GroupServicesComponent],
  exports: [GroupServicesComponent]
})
export class GroupServicesModule {}
