import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import config from '@app/core/config/config';
import { Observable } from 'rxjs';
import { IRuntimeConfig } from './runtime-config-interface';
import { memo$, MemoizeStorageStrategy } from '@libs/utils/memoize';

@Injectable()
export class RuntimeConfigApiService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _http: HttpClient;

  private readonly ENDPOINT = `${config.apiPath}/config/front-config`;

  constructor(private readonly _handler: HttpBackend) {
    this._http = new HttpClient(_handler);
  }

  @memo$(MemoizeStorageStrategy(sessionStorage), 'runtimeConfig')
  loadConfig$(): Observable<IRuntimeConfig> {
    return this._http.get<IRuntimeConfig>(`${this.ENDPOINT}`);
  }
}
