import { UrlTypes } from '@app/shared/enums/url-types';
import { LOCATION_ORIGIN } from '../../../consts';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ILogoLink {
  type: UrlTypes;
  value: string;
}

export const getUrlType = (url: string): UrlTypes | null => {
  if (!url) {
    return null;
  }

  const expression = /^https?:\/\/.+$/i;
  const regex = new RegExp(expression);

  if (url.match(regex)) {
    return UrlTypes.ABSOLUTE;
  }
  return UrlTypes.RELATIVE;
};

export const prepareLogoUrlData = (url: string): ILogoLink => {
  // @ts-expect-error TS2322
  return { type: getUrlType(url), value: url };
};

export const resolveRedirectUrl = (base: string, path = '', configDefaultLanguage: string): string => {
  return base === configDefaultLanguage ? `${LOCATION_ORIGIN}${path}` : `${LOCATION_ORIGIN}/${base}${path}`;
};
