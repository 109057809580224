import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientSessionRecurringAttribute } from '../../types';
import { puiDefaultProp } from '@awarenow/profi-ui-core';
import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributesEnum } from '../../enums';

@Component({
  selector: 'app-client-session-recurring-attribute',
  templateUrl: './client-session-recurring-attribute.component.html',
  styleUrls: ['../client-session-attribute.scss'],
  styles: ['.text-capitalize {text-transform: capitalize !important;}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionRecurringAttributeComponent implements ClientSessionRecurringAttribute {
  type: ClientSessionAttributesEnum.RECURRING = ClientSessionAttributesEnum.RECURRING;

  @Input()
  @puiDefaultProp()
  leftIcon: SessionAttributeIconEnum.REPEAT = SessionAttributeIconEnum.REPEAT;

  @Input() label!: string;
}
