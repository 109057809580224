import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { ServiceConnectionTypeVM } from '@appWidget/modules/booking/common/pipes/service-connection-type.pipe';
import { BookingLocation } from '@appWidget/modules/booking/interfaces';
import { environment } from '@env/environment';

export interface FullLocationParams {
  location: BookingLocation;
  guideName: string;
  guidePhone?: string;
  platformName?: string;
}

export type FullLocationParamsWithEventId = FullLocationParams & { guideEventUid?: string };

export type ServiceConnectionTypeVMWithLink = ServiceConnectionTypeVM & { url?: string };

export const getConnectionType = (
  connectionType: SessionConnectionTypes,
  platformName?: string
): ServiceConnectionTypeVM => {
  switch (connectionType) {
    case 'in_person':
      return {
        icon: 'pui:place',
        label: ` In person`
      };
    case 'in_platform':
      return {
        icon: 'pui:video',
        label: platformName || 'Platform'
      };
    case 'zoom':
      return {
        icon: 'pui:zoom',
        label: `Zoom`
      };
    case 'phone':
      return {
        icon: 'pui:phone',
        label: `Phone call`
      };
    default:
      return {
        icon: '',
        label: ''
      };
  }
};

export const getConnectionNameWithAddress = (
  location: BookingLocation,
  platformName?: string
): ServiceConnectionTypeVM => {
  const response = getConnectionType(location.connectionType, platformName);

  if (location.connectionType === SessionConnectionTypes.IN_PERSON && !location.hideAddress && location.address) {
    response.label = location.address as string;
  }

  return response;
};

export const getFullConnection = ({
  location,
  guideName,
  guidePhone,
  platformName
}: FullLocationParams): ServiceConnectionTypeVM => {
  const response = getConnectionNameWithAddress(location, platformName);

  if (location.connectionType === SessionConnectionTypes.PHONE) {
    if (location.callInitiator === 'client') {
      const text = `will be waiting for your call`;
      if (guidePhone) {
        response.label = `${guidePhone} ${guideName} ${text}`;
      } else {
        response.label = `${guideName} ${text}`;
      }
    }

    if (location.callInitiator === 'guide') {
      const text = ` will call you`;
      if (guidePhone) {
        response.label = `${guidePhone} ${guideName} ${text}`;
      } else {
        response.label = `${guideName} ${text}`;
      }
    }
  }

  return response;
};

export const getFullConnectionWithLinks = (params: FullLocationParamsWithEventId): ServiceConnectionTypeVMWithLink => {
  const response = getFullConnection(params);

  if (params.location.connectionType === SessionConnectionTypes.ZOOM) {
    return {
      ...response,
      url: `${environment.apiHost}/v2/zoom-meeting/${params.guideEventUid}`
    };
  }

  if (params.location.connectionType === SessionConnectionTypes.IN_PLATFORM) {
    return {
      ...response,
      url: `/meeting/${params.guideEventUid}`
    };
  }

  return response;
};
