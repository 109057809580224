import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appLineClamp]',
  exportAs: 'appLineClamp'
})
export class ClampDirective implements OnInit {
  private readonly isBrowser: boolean;

  private buttonContext = { show: false };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private buttonWrapper: any;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private button: EmbeddedViewRef<any>;

  @Input()
  lines = 3;

  @Input()
  // @ts-expect-error TS2564
  buttonRef: TemplateRef<{ show: boolean }>;

  @Input()
  lineHeight = 22.4;

  constructor(
    private el: ElementRef,
    private _viewContainer: ViewContainerRef,
    private _ngZone: NgZone,
    private renderer: Renderer2,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    @Inject(DOCUMENT) private _document: Document,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.button = this._viewContainer.createEmbeddedView(this.buttonRef, this.buttonContext);
    this.buttonWrapper = this.renderer.createElement('div');
    const styleCss = `
        display: inline-flex;
        visibility: hidden;
        background-color: #fff;
        position: absolute;
        top: 0; left: 0;
      `;
    this.renderer.setAttribute(this.buttonWrapper, 'style', styleCss);
    this.renderer.addClass(this.buttonWrapper, 'open-block');
    // eslint-disable-next-line id-length
    this.button.rootNodes.forEach(r => this.renderer.appendChild(this.buttonWrapper, r));
    this.renderer.appendChild(this.el.nativeElement, this.buttonWrapper);
    this.button.detectChanges();
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.hide());
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onResize() {
    this.hide();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  open() {
    if (!this.isBrowser) {
      return;
    }

    this.renderer.setStyle(this.buttonWrapper, 'visibility', 'hidden');
    this.renderer.setStyle(this.el.nativeElement, 'max-height', 'none');
    this.buttonContext.show = false;
    this.button.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hide() {
    if (!this.isBrowser) {
      return;
    }

    let rects;
    let word;
    const element = this.el.nativeElement;
    this.renderer.setAttribute(
      element,
      'style',
      `
      max-height: ${this.lineHeight * this.lines}px;
      position: relative;
      overflow: hidden;
    `
    );
    const mainRect = element.getClientRects()[0];
    const parents = element.childNodes;
    const stack = Array.from(parents).reverse() as Node[];

    w1: while (stack.length) {
      const parentElt = stack.pop();
      if (parentElt === this.buttonWrapper) {
        continue;
      }
      // @ts-expect-error TS2532
      if (parentElt.nodeName !== '#text' && parentElt.childNodes) {
        // @ts-expect-error TS2532
        stack.push(...Array.from(parentElt.childNodes));
        continue;
      }
      // @ts-expect-error TS2532
      if (parentElt.textContent.trim().length === 0) {
        continue;
      }
      const range = this._document.createRange();
      // @ts-expect-error TS2532
      const words = parentElt.textContent.split(' ');
      let start = 0;
      let end = 0;
      let i = 0;
      for (; i < words.length; i++) {
        word = words[i];
        end = start + word.length;
        // @ts-expect-error TS2345
        range.setStart(parentElt, start);
        // @ts-expect-error TS2345
        range.setEnd(parentElt, end);
        rects = range.getClientRects();
        if (rects[0].bottom > mainRect.bottom) {
          break;
        }
        start = end + 1;
      }
      if (i === words.length) {
        this.open();
        continue;
      }
      let rect,
        j = 1;
      end = start;
      do {
        word = words[i - j];
        if (!word) {
          continue w1;
        }
        start = start - word.length - 1;
        // @ts-expect-error TS2345
        range.setStart(parentElt, start);
        // @ts-expect-error TS2345
        range.setEnd(parentElt, end);
        rects = range.getClientRects();
        rect = rects[0];
        j++;
      } while (this.buttonWrapper.offsetWidth > rect.width && j < 4 && i - j > 0);
      const styleCss = `
        position: absolute;
        visibility: visible;
        background-color: #fff;
        top: ${rect.top - mainRect.top}px;
        width: ${rect.width}px;
        left: ${rect.left - mainRect.left}px;
        height: ${rect.height}px;
        display: flex; align-items: center;
      `;
      this.renderer.setAttribute(this.buttonWrapper, 'style', styleCss);
      this.buttonContext.show = true;
      this.button.detectChanges();

      break;
    }
  }
}
