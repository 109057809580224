import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isServiceDateRequired } from '../helpers';

export function guideOfferDateValidatorBuilder(): ValidatorFn {
  return function (formGroup: FormGroup): ValidationErrors | null {
    const serviceControl = formGroup.get('service');
    const dateControl = formGroup.get('date');

    if (!serviceControl || !dateControl) {
      return null;
    }

    const service = serviceControl.value;
    const date = dateControl.value;

    if (service != null && isServiceDateRequired(service) && date == null) {
      return {
        serviceDateRequired: true
      };
    }

    return null;
  };
}
