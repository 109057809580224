<div class="guide-client-history__container">
  <ng-container [ngSwitch]="status$ | async">
    <ng-container *ngSwitchCase="'loading'">
      <!-- CLIENT EVENTS LOADING -->
      <p>...Loading</p>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="(events$ | async)?.length > 0; else empty">
        <ng-container *puiLet="filtered$ | async as events">
          <!--FILTERS-->
          <div class="filters-form" [formGroup]="filterFormGroup">
            <div class="filters-form__row pui-space_bottom-6">
              <div class="filters-form__field">
                <pui-input-field class="filter-field_search">
                  <pui-icon pui-input-field-left svgIcon="pui:search" size="s"></pui-icon>
                  <input
                    pui-input-text
                    type="search"
                    i18n-placeholder="Search"
                    placeholder="Search"
                    size="s"
                    formControlName="search" />
                </pui-input-field>
              </div>
              <div class="filters-form__field">
                <div class="date-range">
                  <pui-input-date
                    puiPlaceholder="Start day"
                    formControlName="startDate"
                    size="s"
                    [puiDatepickerMaxDate]="
                      (todayDate > endDateControl.value ? endDateControl.value : todayDate) || todayDate
                    "></pui-input-date>
                  <span class="pui-space_right-2 pui-space_left-2">—</span>
                  <pui-input-date
                    puiPlaceholder="End day"
                    formControlName="endDate"
                    size="s"
                    [puiDatepickerMinDate]="startDateControl.value"
                    [puiDatepickerMaxDate]="todayDate"></pui-input-date>
                </div>
              </div>
            </div>
          </div>
          <!--LIST OF EVENTS-->
          <ng-container *ngIf="events?.length > 0; else noRecordsFoundTemplate">
            <pui-timeline pui-overflow-auto-height class="guide-client-history__list">
              <ng-container
                *ngFor="let event of events"
                ngProjectAs="pui-timeline-item"
                [ngTemplateOutlet]="eventItem"
                [ngTemplateOutletContext]="{ $implicit: event }"></ng-container>
            </pui-timeline>
          </ng-container>
          <!--NO RECORDS FOUND-->
          <ng-template #noRecordsFoundTemplate>
            <div class="empty">
              <div class="empty__content">
                <pui-icon svgIcon="pui:inbox" size="xl" class="empty__icon pui-space_bottom-2"></pui-icon>
                <p pui-typography="body-m-bold" i18n="@@clientHistory:noRecordsTitle">No records found</p>
                <p pui-typography="body-s">
                  <small pui-typography="body-s" i18n="@@clientHistory:noRecordsDescription">
                    Try modifying the parameters to see records here.
                  </small>
                  <button pui-link type="button" (click)="resetFilters()" i18n="@@clientHistory:noRecordsReset">
                    Reset query
                  </button>
                </p>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- CLIENT EVENTS EMPTY BLOCK -->
      <ng-template #empty>
        <div class="empty">
          <div class="empty__content">
            <pui-icon svgIcon="pui:file" size="xl" class="empty__icon pui-space_bottom-2"></pui-icon>
            <p pui-typography="body-m-bold" i18n="@@clientHistoryIsEmpty:new">Client history is empty</p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #eventItem let-event>
  <ng-container *ngIf="timezoneOffset$ | async as timezoneOffset">
    <pui-timeline-item [dot]="eventPicture" [dotContext]="{ user: event.author }">
      <pui-card class="event-card">
        <pui-card-header class="event-card__container">
          <div class="pui-space_bottom-1 event-card__title" pui-card-header-title pui-typography="body-s">
            <strong class="event-card__title-name" [pui-text-highlight]="searchControl.value">
              {{ event.author | userName }}
            </strong>
            <small class="event-card__title-time" pui-typography="label">
              <time [attr.datetime]="event.timestamp">
                {{ event.timestamp | date: 'MMM d h:mm':timezoneOffset }}&nbsp;{{
                  event.timestamp | date: 'aa':timezoneOffset | lowercase
                }}&nbsp;({{ event.timestamp | date: 'zzz':timezoneOffset }})
              </time>
            </small>
          </div>
          <div pui-card-header-subheader class="event-card__description">
            <!--TEXT-->
            <!-- TODO add [pui-text-highlight]="searchControl.value" so that it doesn't overlap tag <a> in innerHTML -->
            <p pui-typography="body-s" [innerHTML]="event.description | ngSafe: 'html'"></p>
            <!--COMMENT-->
            <em
              *ngIf="!!event.comment"
              [pui-text-highlight]="searchControl.value"
              role="comment"
              pui-typography="body-s"
              class="event-card__comment">
              {{ event.comment }}
            </em>
          </div>
        </pui-card-header>
      </pui-card>
    </pui-timeline-item>

    <ng-template #eventPicture let-user="user">
      <pui-avatar [text]="user | userName" [avatarUrl]="user?.photoUrl"></pui-avatar>
    </ng-template>
  </ng-container>
</ng-template>
