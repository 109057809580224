import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { INoteEditorContent, NotesTypes, NotesTypesSortProp } from '@app/shared/interfaces/notes';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { GuideNotesBoardService } from '@app/modules/guide-notes/guide-notes-board.service';
import { GuideNotesService } from '@app/modules/guide-notes/guide-notes.service';
import { GuideNotesApiService } from '@app/modules/guide-notes/guide-notes-api.service';
import { PuiDrawerService } from '@awarenow/profi-ui-core';
import { GuideRelationShareNotesComponent } from '@app/modules/guide-client/components/guide-relation-share-notes/guide-relation-share-notes.component';
import { DRAWER_CONFIG } from '@app/modules/guide-client/services';

const GUIDE_RELATION_NOTES_INITIAL_BOARD_STATE = {
  notes: [],
  cursor: null,
  cursorPinned: null,
  notesType: NotesTypes.PINNED,
  relationId: null,
  scrollTo: null
};

@Injectable()
export class GuideRelationNotesBoardService extends GuideNotesBoardService {
  protected relationList: string[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _serviceId: symbol;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isLoadingSubj = new BehaviorSubject<boolean>(true);
  readonly isLoading$ = this._isLoadingSubj.asObservable();

  constructor(
    protected _guideNotes: GuideNotesService,
    protected _guideNotesApi: GuideNotesApiService,
    protected readonly modal: NgbModal,
    private readonly _drawer: PuiDrawerService
  ) {
    super(_guideNotes, _guideNotesApi, modal, GUIDE_RELATION_NOTES_INITIAL_BOARD_STATE);

    this._serviceId = Symbol('GuideRelationNotesBoardService');
  }

  create({ text, content, html }: INoteEditorContent): void {
    if (!html) {
      return;
    }
    // @ts-expect-error TS2339
    const { relationId, ...rest } = this.state;
    const { id, type } = GuideContact.parseLocalId(relationId);
    const note = {
      content: html,
      contentText: text,
      contentDeltaFormat: content
    };

    let createdNote$: Observable<GuideNote>;
    if (type === GuideRelationTypes.GUIDE_CLIENT) {
      // [TODO] Rewrite this method and backend endpoint
      createdNote$ = this._guideNotesApi.createClientNote$(id, note, relationId);
    }

    if (type === GuideRelationTypes.GUIDE_CONTACT) {
      // [TODO] Rewrite this method and backend endpoint
      createdNote$ = this._guideNotesApi.createContactNote$(id, note, relationId);
    }

    // @ts-expect-error TS2454
    if (createdNote$) {
      createdNote$.subscribe(newNote => {
        // @ts-expect-error TS2339
        const { notesType, notes: currentNotes } = this.state;

        if (notesType === NotesTypes.STANDARD) {
          const [pinnedNotes, standardNotes] = this._splitNotesByPinStatus([newNote, ...currentNotes]);
          const sortedStandardNotes = this._sortNotesBy(standardNotes, NotesTypesSortProp.STANDARD);
          const cursor = sortedStandardNotes[sortedStandardNotes.length - 1].updatedAt;

          this.setState({
            ...rest,
            notes: [...pinnedNotes, ...sortedStandardNotes],
            cursor,
            relationId,
            scrollTo: newNote.id
          });
        }

        this._guideNotes.createNote({
          serviceId: this._serviceId,
          noteId: newNote.id
        });
        this._resetEditorState$.next();
      });
    }
  }

  loadNotes(search?: string): void {
    // @ts-expect-error TS2339
    const { relationId: localId } = this.state;
    const { id: relationId, type: relationType } = GuideContact.parseLocalId(localId);

    this._guideNotesApi
      // @ts-expect-error TS2345
      .getRelationNotes$(relationId, relationType, null, this._limit, true, search)
      .pipe(
        map(pinnedNotesResponse => {
          const countOfRestNotesToLoad = this._limit - pinnedNotesResponse.notes.length;

          if (countOfRestNotesToLoad > 0) {
            return {
              ...this.state,
              notes: [...pinnedNotesResponse.notes],
              cursor: null,
              cursorPinned: pinnedNotesResponse.cursor,
              notesType: NotesTypes.STANDARD,
              scrollTo: null,
              countOfRestNotesToLoad
            };
          }

          return {
            ...this.state,
            notes: [...pinnedNotesResponse.notes],
            cursor: null,
            cursorPinned: pinnedNotesResponse.cursor,
            notesType: NotesTypes.PINNED,
            scrollTo: null,
            countOfRestNotesToLoad: 0
          };
        }),
        catchError(() => of({ ...this.state }))
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        // @ts-expect-error TS2345
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad, search);
        } else {
          this._isLoadingSubj.next(false);
        }
      });
  }

  loadMoreNotes(count?: number, search?: string): void {
    if (count === 0) {
      return;
    }

    // @ts-expect-error TS2339
    const { relationId: localId, notesType, cursorPinned, cursor } = this.state;
    const limit = count || this._limit;
    const isPinned = notesType === NotesTypes.PINNED;
    const currentCursor = isPinned ? cursorPinned : cursor;
    const { id: relationId, type: relationType } = GuideContact.parseLocalId(localId);

    this._guideNotesApi
      // @ts-expect-error TS2345
      .getRelationNotes$(relationId, relationType, currentCursor, limit, isPinned, search)
      .pipe(
        map(noteResponse => {
          const notes = this.state?.notes || [];
          const currentNotes = [
            ...notes,
            ...noteResponse.notes.filter(note => !notes.map(item => item.id).includes(note.id))
          ];
          const countOfRestNotesToLoad = limit - noteResponse.notes.length;
          const restState = {
            relationId: localId,
            scrollTo: null
          };

          if (countOfRestNotesToLoad > 0 && isPinned) {
            return {
              ...restState,
              notes: currentNotes,
              cursor: null,
              cursorPinned: noteResponse.cursor,
              notesType: NotesTypes.STANDARD,
              countOfRestNotesToLoad
            };
          }

          return {
            ...restState,
            notes: currentNotes,
            cursor: !isPinned ? noteResponse.cursor : cursor,
            cursorPinned: isPinned ? noteResponse.cursor : cursorPinned,
            notesType,
            countOfRestNotesToLoad: 0
          };
        }),
        finalize(() => this._isLoadingSubj.next(false))
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad, search);
        }
      });
  }

  grantViewerAccess(index: number): void {
    // TODO: insert modal
    // @ts-expect-error TS2339
    const { notes: currentNotes, relationId } = this.state;
    const { id, type } = GuideContact.parseLocalId(relationId);
    if (type === GuideRelationTypes.GUIDE_CONTACT) {
      return;
    }
    const { id: sharedId } = currentNotes[index];
    this._guideNotesApi.shareNotesWithClient$(id, [sharedId]).subscribe(() => {
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this._guideNotesApi.getSharedNotes$([sharedId]).subscribe(notes => this._updateStateAfterNotesAreShared(notes));
      this._guideNotes.grantAccess({
        serviceId: this._serviceId,
        noteIds: [sharedId]
      });
    });
  }

  revokeViewerAccess(index: number): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, relationId } = this.state;
    const { id, type } = GuideContact.parseLocalId(relationId);
    if (type === GuideRelationTypes.GUIDE_CONTACT) {
      return;
    }
    const { id: sharedId } = currentNotes[index];

    this._guideNotesApi.revokeViewerAccessForClient$(id, [sharedId]).subscribe(() => {
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this._guideNotesApi.getSharedNotes$([sharedId]).subscribe(notes => this._updateStateAfterNotesAreShared(notes));
      this._guideNotes.revokeViewerAccess({
        serviceId: this._serviceId,
        noteIds: [sharedId]
      });
    });
  }

  // @ts-expect-error TS7006
  manageAccess(index): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, relationId } = this.state;
    const { id: clientId, type } = GuideContact.parseLocalId(relationId);
    if (type === GuideRelationTypes.GUIDE_CONTACT) {
      return;
    }
    const { id: noteId } = currentNotes[index];

    this._drawer.open(
      GuideRelationShareNotesComponent,
      {
        ...DRAWER_CONFIG,
        hideCloseButton: true
      },
      { clientId, noteId }
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setRelationId(relationId: string) {
    this.relationList = [relationId];
    this.setState({
      ...GUIDE_RELATION_NOTES_INITIAL_BOARD_STATE,
      relationId
    });
    this.loadNotes();
  }
}
