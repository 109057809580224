<app-guide-service
  [service]="service"
  [badges]="badges || [defaultBadge]"
  [details]="details || defaultDetails"
  [showMore]="showMore"
  [team]="team"
  [editorLink]="editorLink"
  [publicLink]="publicLink"
  [copyText]="copyText"></app-guide-service>

<ng-template #defaultBadge>
  <app-guide-service-badge
    [attr.data-qa-id]="'guide-program-service-badge-' + service.name"
    text="Program"
    i18n-text="@@guideServiceItemProgramBadge"
    class="program-badge"></app-guide-service-badge>
</ng-template>

<ng-template #team>
  <app-guide-service-team [team]="service.team"></app-guide-service-team>
</ng-template>

<ng-template #defaultDetails let-service="service">
  <div class="service-details session-service-details">
    <div class="service-date" *ngIf="programStartDate(service)">
      <pui-icon svgIcon="pui:calendar" size="s" aria-hidden="true"></pui-icon>
      <ng-container i18n="@@guideServiceItemProgramStartDateLabel" class="start-date-note">
        {{ service?.startType === 'user_enrollment' ? 'After enrollment' : 'Starts' }}
      </ng-container>
      {{ service?.startDate | localDate: 'MMMM dd, yyyy' }}
    </div>

    <div
      class="service-clients pui-space_left-5"
      *ngIf="service.customersCount !== null && service.customersCount !== undefined && !isAdmin">
      <pui-icon svgIcon="pui:users" size="s" aria-hidden="true"></pui-icon>
      <span>{{ service.customersCount }}</span>
    </div>
  </div>
</ng-template>
