import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutosizeModule } from 'ngx-autosize';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';

import { BookButtonCustomizerComponent } from './components/book-button-customizer/book-button-customizer.component';
import { BookLinkProviderComponent } from './components/book-link-provider/book-link-provider.component';
import { EmbeddableBookButtonModalComponent } from './components/embeddable-book-button-modal/embeddable-book-button-modal.component';

@NgModule({
  imports: [
    AutosizeModule,
    ClipboardModule,
    ColorPickerModule,
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [BookButtonCustomizerComponent, BookLinkProviderComponent, EmbeddableBookButtonModalComponent],
  exports: [BookLinkProviderComponent, EmbeddableBookButtonModalComponent]
})
export class EmbeddableBookButtonModule {}
