import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ClientNotesModule } from '@app/modules/client-notes/client-notes.module';
import { FileCardModule } from '@app/modules/ui-kit/file-card/file-card.module';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { ScrollbarModule } from '@app/modules/scrollbar/scrollbar.module';
import { SharedModule } from '@app/shared';
import { UserNotesModule } from '@app/modules/user-notes/user-notes.module';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { UiChipListModule } from '@app/modules/ui-kit/_base/ui-chips-list';

import { ClientDashboardModule } from '@app/screens/client/client-dashboard/client-dashboard.module';
import { UiIconModule } from '@app/modules/ui-kit/_base/ui-icon';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiDialogModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiKeyboardWidgetModule,
  PuiList2Module,
  PuiMenuModule,
  PuiSelectModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { BookWidgetComponent } from './components/book-widget/book-widget.component';
import { ContentModuleComponent } from './components/content-module/content-module.component';
import { ModuleActionsBoardComponent } from './components/module-actions-board/module-actions-board.component';
import { ModuleGroupSessionComponent } from './components/module-group-session/module-group-session.component';
import { ModuleIndividualSessionComponent } from './components/module-individual-session/module-individual-session.component';
import { ModuleInstructorComponent } from './components/module-instructor/module-instructor.component';
import { ModuleQuizComponent } from './components/module-quiz/module-quiz.component';
import { ModuleSelectorComponent } from './components/module-selector/module-selector.component';
import { ModuleServiceComponent } from './components/module-service/module-service.component';
import { ModuleServiceHostComponent } from './components/module-service-host/module-service-host.component';
import { ProgramDoneComponent } from './components/program-done/program-done.component';
import { ProgramModulesBoardComponent } from './components/program-modules-board/program-modules-board.component';
import { QuestionOptionComponent } from './components/question-option/question-option.component';
import { QuizChoiceAnswerComponent } from './components/quiz-choice-answer/quiz-choice-answer.component';
import { QuizCustomAnswerComponent } from './components/quiz-custom-answer/quiz-custom-answer.component';
import { QuizFileUploadAnswerComponent } from './components/quiz-file-upload-answer/quiz-file-upload-answer.component';
import { QuizInfoComponent } from './components/quiz-info/quiz-info.component';
import { QuizModuleComponent } from './components/quiz-module/quiz-module.component';
import { QuizSchedulingComponent } from './components/quiz-scheduling/quiz-scheduling.component';
import { QuizSignatureAnswerComponent } from './components/quiz-signature-answer/quiz-signature-answer.component';
import { QuizTextQuestionComponent } from './components/quiz-text-question/quiz-text-question.component';
import { SessionModuleComponent } from './components/session-module/session-module.component';
import { StartProgramComponent } from './components/start-program/start-program.component';
import { UpcomingProgramComponent } from './components/upcoming-program/upcoming-program.component';

import { PushNextElementDirective } from './directives/push-next-element.directive';
import { SetMetaToFirstChildDirective } from './directives/set-meta-to-first-child.directive';
import { IsUnansweredQuestionPipe } from '@app/screens/guide/guide-programs/pipes/is-unanswered-question.pipe';
import { QuillEditorImageModule } from '@app/modules/quill-editor-image/quill-editor-image.module';
import { GuideSharedModule } from '@app/screens/guide/guide-shared/guide-shared.module';
import { BookingPipesModule } from '@appWidget/modules/booking/common/pipes/booking-pipes.module';
import { SharedModule as LibSharedModule } from '@libs/shared/shared.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    ClientNotesModule,
    CommonModule,
    FileCardModule,
    FormsModule,
    GuideServiceModule,
    NgScrollbarModule,
    NgScrollbarModule,
    NgbModule,
    PlatformStyleModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollbarModule,
    SharedModule,
    LibSharedModule,
    UserNotesModule,
    UiChipListModule,
    PuiIconModule,
    UiIconModule,
    PuiMenuModule,
    ClientDashboardModule,
    PuiButtonModule,
    PuiKeyboardWidgetModule,
    PuiList2Module,
    PuiDropdownModule,
    PuiAvatarModule,
    TextEditorModule,
    QuillEditorImageModule,
    PuiTooltipModule,
    PuiTypographyModule,
    PuiDialogModule,
    GuideSharedModule,
    BookingPipesModule,
    PuiSelectModule,
    TranslocoRootModule
  ],
  declarations: [
    BookWidgetComponent,
    ContentModuleComponent,
    ModuleActionsBoardComponent,
    ModuleGroupSessionComponent,
    ModuleIndividualSessionComponent,
    ModuleInstructorComponent,
    ModuleQuizComponent,
    ModuleSelectorComponent,
    ModuleServiceComponent,
    ModuleServiceHostComponent,
    ProgramDoneComponent,
    ProgramModulesBoardComponent,
    QuestionOptionComponent,
    QuizChoiceAnswerComponent,
    QuizCustomAnswerComponent,
    QuizFileUploadAnswerComponent,
    QuizInfoComponent,
    QuizModuleComponent,
    QuizSchedulingComponent,
    QuizSignatureAnswerComponent,
    QuizTextQuestionComponent,
    SessionModuleComponent,
    StartProgramComponent,
    UpcomingProgramComponent,
    PushNextElementDirective,
    SetMetaToFirstChildDirective,
    IsUnansweredQuestionPipe
  ],
  exports: [
    ModuleQuizComponent,
    ProgramModulesBoardComponent,
    QuizInfoComponent,
    QuizSchedulingComponent,
    QuizChoiceAnswerComponent,
    QuizTextQuestionComponent
  ]
})
export class ProgramModule {}
