import { Component, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformServer, Location } from '@angular/common';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(RESPONSE) private response: any,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    private _location: Location
  ) {
    if (isPlatformServer(platformId)) {
      response.status(404);
    }
  }

  back(): void {
    this._location.back();
  }
}
