import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sessionsLandingPageLink',
  pure: true
})
export class SessionsLandingPageLinkPipe implements PipeTransform {
  transform(...args: Parameters<typeof sessionsLandingPageLink>): ReturnType<typeof sessionsLandingPageLink> {
    return sessionsLandingPageLink(...args);
  }
}

export function sessionsLandingPageLink(sessionId: number): string[] | null {
  if (sessionId === undefined || sessionId === null) {
    return null;
  }

  return [`/`, `sessions`, sessionId.toString()];
}
