import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { AbsComponentServiceItem } from '@app/modules/guide-service/components/abs/abs-component-service-item';
import { IGuideSessionService } from '../../types';
import { SessionType } from '@app/shared/enums/session-type';

@Component({
  selector: 'app-guide-group-session-service',
  templateUrl: './guide-group-session-service.component.html',
  styleUrls: ['../../styles/common.scss', './guide-group-session-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideGroupSessionServiceComponent<T extends IGuideSessionService> extends AbsComponentServiceItem<T> {
  readonly SessionType = SessionType;
  readonly platformName = this.runtimeConfigService.get('platformName');

  @HostBinding('class') hostClass = 'guide-group-session-service';
  constructor(private readonly runtimeConfigService: RuntimeConfigService) {
    super();
  }
}
