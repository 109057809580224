import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentServiceItem } from '@app/modules/guide-service/components/abs/abs-component-service-item';
import { IGuideProgramService } from '../../types';
import { AuthService } from '@app/core/auth/services';

@Component({
  selector: 'app-guide-program-service',
  templateUrl: './guide-program-service.component.html',
  styleUrls: ['../../styles/common.scss', './guide-program-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-program-service ui-app-guide-program-service'
  }
})
export class GuideProgramServiceComponent<T extends IGuideProgramService> extends AbsComponentServiceItem<T> {
  isAdmin: boolean;

  constructor(private authService: AuthService) {
    super();

    this.isAdmin = this.authService.isPlatformAdmin();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  programStartDate(program) {
    if (program.startType === 'user_enrollment') {
      return program.enrolledAt || this.today;
    }
    return program.startDate;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get today() {
    return new Date();
  }
}
