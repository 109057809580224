import { InjectionToken, Provider } from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const IS_TEAM_WORKSPACE = new InjectionToken<Observable<boolean>>('[Workspace] Is this team workspace?');
export const isTeamWorkspaceProvider: Provider = {
  provide: IS_TEAM_WORKSPACE,
  useFactory: (workspacesService: WorkspacesService): Observable<boolean> => {
    return workspacesService.activeWorkspace$.pipe(map(WorkspaceUtility.isTeam));
  },
  deps: [WorkspacesService]
};

export const GET_IS_TEAM_WORKSPACE_VALUE = new InjectionToken<() => boolean>('IS_TEAM_WORKSPACE_VALUE');
export const isTeamWorkspaceValueProvider: Provider = {
  provide: GET_IS_TEAM_WORKSPACE_VALUE,
  useFactory: (workspacesService: WorkspacesService): (() => boolean) => {
    return () => WorkspaceUtility.isTeam(workspacesService.workspace);
  },
  deps: [WorkspacesService]
};
