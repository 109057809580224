<input
  [attr.id]="componentId"
  [attr.autocomplete]="autocomplete ? 'on' : 'new-password'"
  [attr.data-qa-id]="qaId"
  [disabled]="disabled"
  [placeholder]="placeholder"
  class="form-control {{ className }}"
  [type]="type"
  (blur)="onTouched()"
  (keydown.enter)="keyDownEnter($event)"
  [(ngModel)]="value" />
<label class="switcher" [attr.title]="showPassword ? 'Hide password' : 'Show password'">
  <input type="checkbox" [checked]="showPassword" (change)="toggle()" [(ngModel)]="showPassword" />
  <span class="checkmark"></span>
</label>
