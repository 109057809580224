import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributeOptions, ClientSessionGuestsAttribute } from '../types';
import { ClientSessionAttributesEnum } from '@libs/business/client/client-session/modules';

export const clientSessionGuestsAttribute = ({
  guests
}: ClientSessionAttributeOptions): ClientSessionGuestsAttribute | null => {
  if (!guests?.length) {
    return null;
  }

  return {
    type: ClientSessionAttributesEnum.GUESTS,
    leftIcon: SessionAttributeIconEnum.USER_PLUS,
    label: ` Guests`,
    items: guests.map(guest => ({ label: guest }))
  };
};
