// eslint-disable-next-line max-classes-per-file, @typescript-eslint/no-unused-vars
import { SessionStatuses, isOffer } from '@app/shared/enums/session-statuses';
import { SessionsTypes } from '@app/shared/enums/sessions-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { Observable } from 'rxjs';
import { GuideContact } from '@app/core/users/types';
import { IWorkspace } from '@app/modules/workspaces/types';

// NOTE: currently it's not possible to type observable error response
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IValidationStrategy<TValidationArgs, TValidationSuccess> {
  validate$: (args: TValidationArgs) => Observable<TValidationSuccess>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export interface IServiceReschedulingApi<TReschedulingDetails, TReschedulingResult> {
  reschedule$: (reschedulingDetails: ISessionReschedulingDetails) => TReschedulingResult;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IReschedulingService<TReschedulingOptions, TReschedulingResult> {
  reschedule$(options: TReschedulingOptions): TReschedulingResult;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IReschedulingSessionDate {
  readonly date: string;
  readonly timezone?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionReschedulingOptions {
  readonly id?: number;
  readonly name: string;
  readonly duration: number;
  readonly status: SessionStatuses;
  readonly serviceType: GuideServiceTypes;
  readonly currentDate: IReschedulingSessionDate;
  readonly exEvent: number;

  readonly collectionType?: SessionsTypes; // NOTE: added for backward compatibility
  readonly price?: number;
  readonly serviceId?: number;

  readonly newDate?: IReschedulingSessionDate;
  readonly applicant: ReschedulingApplicant;

  readonly user?: Pick<GuideContact, 'id' | 'name'>;
  readonly message?: string;
  readonly guideId?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionReschedulingDetails {
  readonly id: number;
  readonly date: string;
  readonly timezone?: string;
  readonly collectionType?: SessionsTypes; // NOTE: added for backward compatibility,
  message?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionReschedulingResponse {
  newDate: IReschedulingSessionDate;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionRescheduleMessage {
  skipped: boolean;
  message: string | undefined;
}

export class ReschedulingSessionDate implements IReschedulingSessionDate {
  readonly date: string;

  readonly timezone?: string;

  constructor(date: string, timezone?: string) {
    this.date = date;

    if (timezone) {
      this.timezone = timezone;
    }
  }
}

export class UnAuthorisedReschedulingApplicant {
  readonly token: string;

  readonly role: UserRoles;

  constructor(applicant: { readonly token: string; readonly role: UserRoles }) {
    this.token = applicant.token;
    this.role = applicant.role;
  }
}

export class AuthorisedReschedulingApplicant {
  readonly id: number;

  readonly role: UserRoles;

  constructor(applicant: { readonly id: number; readonly role: UserRoles }) {
    this.id = applicant.id;
    this.role = applicant.role;
  }
}

export type ReschedulingApplicant = UnAuthorisedReschedulingApplicant | AuthorisedReschedulingApplicant;

export function isUnAuthorisedReschedulingApplicant(
  applicant: ReschedulingApplicant
): applicant is UnAuthorisedReschedulingApplicant {
  return applicant instanceof UnAuthorisedReschedulingApplicant;
}

export function isAuthorisedReschedulingApplicant(
  applicant: ReschedulingApplicant
): applicant is AuthorisedReschedulingApplicant {
  return applicant instanceof AuthorisedReschedulingApplicant;
}

export abstract class BaseReschedulingSessionOptions implements ISessionReschedulingOptions {
  readonly id?: number;

  readonly name: string;

  readonly collectionType?: SessionsTypes; // NOTE: added for backward compatibility

  readonly duration: number;

  readonly status: SessionStatuses;

  readonly serviceType: GuideServiceTypes;

  readonly currentDate: IReschedulingSessionDate;

  readonly applicant: ReschedulingApplicant;

  readonly exEvent: number;

  readonly guideId: number;

  readonly price?: number;

  readonly serviceId?: number;

  readonly newDate?: IReschedulingSessionDate;

  readonly message?: string;

  readonly user?: Pick<GuideContact, 'id' | 'name'> & Pick<IWorkspace, 'workspaceId'>;

  readonly isFree?: boolean;

  protected constructor(initialValues: ISessionReschedulingOptions) {
    this.id = initialValues.id;
    this.name = initialValues.name;
    this.duration = initialValues.duration;
    this.status = initialValues.status;
    this.serviceType = initialValues.serviceType;
    this.currentDate = initialValues.currentDate;
    this.applicant = initialValues.applicant;
    this.exEvent = initialValues.exEvent;
    // @ts-expect-error TS2322
    this.guideId = initialValues.guideId;
    if (initialValues.collectionType != null) {
      this.collectionType = initialValues.collectionType; // NOTE: added for backward compatibility
    }

    if (initialValues.serviceId != null) {
      this.serviceId = initialValues.serviceId;
    }

    if (initialValues.price != null) {
      this.price = initialValues.price;
    }

    if (initialValues.newDate != null) {
      this.newDate = initialValues.newDate;
    }

    if (initialValues.user) {
      this.user = initialValues.user;
    }

    this.message = initialValues.message;
  }
}

export class GuideReschedulingSessionOptions extends BaseReschedulingSessionOptions {
  readonly clientId: number;

  constructor(clientId: number, options: ISessionReschedulingOptions) {
    super(options);

    this.clientId = clientId;
  }
}

export class ClientReschedulingSessionOptions extends BaseReschedulingSessionOptions {
  readonly guideId: number;

  constructor(guideId: number, options: ISessionReschedulingOptions) {
    super(options);

    this.guideId = guideId;
  }
}

export type ReschedulingSessionOptions = ClientReschedulingSessionOptions | GuideReschedulingSessionOptions;

export function isClientReschedulingSessionOptions(
  options: ReschedulingSessionOptions
): options is ClientReschedulingSessionOptions {
  return options instanceof ClientReschedulingSessionOptions;
}

export function isGuideReschedulingSessionOptions(
  options: ReschedulingSessionOptions
): options is GuideReschedulingSessionOptions {
  return options instanceof GuideReschedulingSessionOptions;
}
