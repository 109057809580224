<div class="guide-program-service-group-item-container">
  <div class="guide-program-service-group-item-image"></div>

  <div class="guide-service-group-item-details guide-program-service-group-item-details">
    <div class="group-item-badges">
      <ng-container [ngSwitch]="service.type">
        <app-guide-service-badge
          *ngSwitchCase="GuideServiceTypes.GROUP_SESSION"
          text="Session"
          i18n-text="@@guideProgramServiceGroupItemSessionBadge"
          class="individual-session-badge"></app-guide-service-badge>

        <app-guide-service-badge
          *ngSwitchCase="GuideServiceTypes.SESSION"
          text="Session"
          i18n-text="@@guideProgramServiceGroupItemSessionBadge"
          class="individual-session-badge"></app-guide-service-badge>
      </ng-container>
    </div>
    <div class="program-module-name">
      <span class="program-module-index" *ngIf="service.serviceParent.moduleOrder">
        <ng-container i18n="@@guideProgramServiceGroupItemModuleIndex">Module</ng-container>
        {{ service.serviceParent.moduleOrder }}:
      </span>
      <div class="program-module-service-name">
        {{ service.name }}
        <ng-container *ngIf="service.availableCount">({{ service.availableCount }})</ng-container>
      </div>
    </div>
  </div>
</div>
