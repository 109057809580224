import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { humanize } from '@app/shared/utils/humanize';

@Pipe({
  name: 'humanizeSessionStartDate',
  pure: false
})
export class HumanizeSessionStartDatePipe implements PipeTransform {
  transform(dateStr: string): string {
    let result = '';

    if (dateStr) {
      const sessionStartMoment = DateTime.fromISO(dateStr);
      const now = DateTime.local();
      const momentDifference = sessionStartMoment.toMillis() - now.toMillis();
      const timeToSessionStart = humanize(momentDifference);

      if (momentDifference >= 0) {
        result = 'Starts ' + timeToSessionStart;
      } else {
        result = 'Started ' + timeToSessionStart;
      }
    }

    return result;
  }
}
