import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISystemNotification } from '@app/modules/notifications/types';
import { GuideClientCardTab } from '@app/modules/guide-client/components';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideClientCardService } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.service';

@Component({
  selector: 'app-note-notification',
  templateUrl: './note-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './note-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteNotificationComponent {
  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;
  GuideClientCardTab = GuideClientCardTab;

  constructor(private _authService: AuthService, private readonly clientCardService: GuideClientCardService) {}

  get isClient(): boolean {
    return this._authService.getUserRoleId() === UserRoles.CLIENT;
  }

  showClientDetails(clientId: number): void {
    this.clientCardService.openNotes(0, clientId);
  }
}
