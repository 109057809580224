import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { ITimeSlot, SCHEDULE_PROVIDER } from '@app/modules/schedule-boards';
import { IGuideServiceScheduleOptions, GuideServiceScheduleProviderService } from '../../services';

@Component({
  selector: 'app-guide-service-schedule',
  templateUrl: './guide-service-schedule.component.html',
  styleUrls: ['./guide-service-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SCHEDULE_PROVIDER, useClass: GuideServiceScheduleProviderService }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'guide-service-schedule'
  }
})
export class GuideServiceScheduleComponent {
  @Input()
  set options(value: IGuideServiceScheduleOptions) {
    this._scheduleProvider.refresh(value);
  }

  @Output()
  timeSelect = new EventEmitter<ITimeSlot>();

  constructor(private readonly _scheduleProvider: GuideServiceScheduleProviderService) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly localTimezone = DateTime.local().zoneName;
}
