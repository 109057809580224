import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { ActiveChatService } from '@app/core/chat/active-chat.service';
import { ChatTypes, ChatUpdateTypes, ChatUser, IAttachment } from '@app/core/chat/types';
import { SessionsService } from '@app/core/session/sessions.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideRelationTypes } from '@app/core/users/types';
import { BodyClasses, WindowService } from '@app/core/window';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { isGroupSession, isSimpleSession, Session, SimpleSession } from '@app/shared/interfaces/session';
import { IUser, User } from '@app/shared/interfaces/user';
import { environment } from '@env/environment';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

import { ActiveChat, IChatBoardMessage } from '../../types';
import { convertChatUpdateToBoardActiveChat } from '../../utils/converters';

const allowedSessionStatues = [
  SessionStatuses.APPROVED,
  SessionStatuses.IN_PROGRESS_OLD,
  SessionStatuses.PENDING_APPROVEMENT,
  SessionStatuses.RESCHEDULE_BY_CLIENT
];

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-active-chat',
  templateUrl: './active-chat.component.html',
  styleUrls: ['../../../../loading.scss', './active-chat.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-app-active-chat' },
  providers: [ActiveChatService]
})
export class ActiveChatComponent implements OnInit, OnDestroy {
  readonly ChatTypes = ChatTypes;

  private readonly destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _participant: { id: number; role: number; bot?: boolean; workspaceId?: number } | null = null;

  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatId;

  // @ts-expect-error TS2564
  session: Session;

  // @ts-expect-error TS2564
  currentSession: Session;

  // @ts-expect-error TS2564
  chat: ActiveChat<IChatBoardMessage, ChatUser>;

  // @ts-expect-error TS2564
  author: IUser;

  // @ts-expect-error TS2564
  authorRole: UserRoles;

  isHistoryLoading = false;

  // @ts-expect-error TS2564
  isContact: boolean;

  eventId: number | null = null;

  get participant(): { id: number; role: number; bot?: boolean; workspaceId?: number } | null {
    return this._participant;
  }

  constructor(
    readonly route: ActivatedRoute,
    private readonly style: PlatformStyle,
    private readonly _router: Router,
    private readonly _chat: ActiveChatService,
    private readonly _auth: AuthService,
    private readonly _sessions: SessionsService,
    private readonly _windowService: WindowService,
    private readonly _guideClientsService: GuideClientsService
  ) {
    const routeState = this._router.getCurrentNavigation()?.extras?.state;
    this.eventId = (routeState && routeState.eventId) || null;
  }

  ngOnInit(): void {
    const author = this._auth.user;

    combineLatest([this.route.paramMap, this.route.queryParamMap])
      .pipe(
        withLatestFrom(this.route.data),
        map(([[params, queryParams], data]) => ({
          id: params.get('id'),
          isBot: !!data.bot,
          // @ts-expect-error TS2531
          workspaceId: +queryParams.get('workspaceId')
        })),
        tap(params => {
          if (this._chatId && this._chatId !== params.id && this.eventId) {
            this.eventId = null;
          }
        }),
        tap(params => (this._chatId = params.id)),
        takeUntil(this.destroy$)
      )
      .subscribe(chatDetails => {
        // @ts-expect-error TS2345
        this.startChat({ ...chatDetails, eventId: this.eventId, workspaceId: chatDetails.workspaceId });
      });

    this._chat.chatUpdate$
      .pipe(
        filter(update => update && update.type === ChatUpdateTypes.CHAT_REMOVED),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this._router.navigate(['../select'], { replaceUrl: true, relativeTo: this.route }));

    this._chat.chatUpdate$
      .pipe(
        filter(update => !update || update.type !== ChatUpdateTypes.CHAT_REMOVED),
        map(update =>
          convertChatUpdateToBoardActiveChat(
            // @ts-expect-error TS2345
            update ? { ...update, displayFirstServiceSeparator: !!this.eventId } : null
          )
        ),
        tap(chat => {
          this.chat = chat;

          // @ts-expect-error TS2322
          let newParticipant: { id: number; role: number; bot?: boolean } = null;

          if (chat && chat.type === ChatTypes.DIRECT) {
            const newParticipantId = Object.keys(chat.users).find(userId => Number(userId) !== this._auth.user.id);
            // @ts-expect-error TS7015
            newParticipant = newParticipantId ? chat.users[newParticipantId] : null;
          }

          this._participant = newParticipant;
          this.isHistoryLoading =
            this.isHistoryLoading &&
            chat.updateType !== ChatUpdateTypes.HISTORY &&
            chat.updateType !== ChatUpdateTypes.HISTORY_RESERVED;
        }),
        tap(() => {
          if (author.RoleId === UserRoles.CLIENT) {
            // @ts-expect-error TS2345
            this.setClientSessionListener(this._participant);
          } else if (author.RoleId === UserRoles.GUIDE) {
            // @ts-expect-error TS2345
            this.setGuideSessionListener(this._participant);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this._chat.isContact$.pipe(takeUntil(this.destroy$)).subscribe(isContact => {
      this.isContact = isContact;
    });

    if (author) {
      // @ts-expect-error TS2322
      this.author = new User(author.id, author.firstName, author.lastName, author.photo);
      this.authorRole = author.RoleId;
    }

    this._windowService.addBodyClasses([BodyClasses.NO_FOOTER, BodyClasses.NO_OSANO]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this._windowService.removeBodyClasses([BodyClasses.NO_FOOTER, BodyClasses.NO_OSANO]);
  }

  loadHistory(reversed = false): void {
    this.isHistoryLoading = true;
    this._chat.loadHistory(reversed);
  }

  sendMessage({ message, attachment }: { message: string; attachment?: IAttachment }): void {
    let meta = null;

    if (
      this.chat.type === ChatTypes.DIRECT &&
      this.currentSession &&
      this.currentSession.serviceType === SessionTypes.SESSION &&
      (this.currentSession.status === SessionStatuses.IN_PROGRESS_OLD ||
        this.currentSession.status === SessionStatuses.APPROVED) &&
      DateTime.fromISO(this.currentSession.dateStart) <= DateTime.local()
    ) {
      const time = this.style.datetimeRange([this.currentSession.dateStart], 'TIME', {
        timezone: DateTime.local().zoneName,
        localeParams: this.style.locale,
        durationInMinutes: this.currentSession.duration,
        showTimezone: true
      });

      meta = {
        eventId: this.currentSession.eventId,
        title: `${this.currentSession.name}, ${time}`,
        serviceType: this.currentSession.serviceType
      };
    }

    this._chat.sendMessage(message, attachment, meta);

    if (meta && meta.eventId && !(this.currentSession as SimpleSession).hasChat) {
      this._sessions
        .addSessionChat(this.currentSession.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          (this.currentSession as SimpleSession).hasChat = true;
        });
    }
  }

  goToUserHandler(event: { id: number; type: GuideRelationTypes }): void {
    const { id, type } = event;
    this._guideClientsService.setSelectedRelationId(id, type);
    this._router.navigate([`${environment.guideRoute}/clients`]);
  }

  private setClientSessionListener(guide: { id: number }): void {
    if (!guide) {
      return;
    }

    combineLatest([
      this._sessions.sessionOffers$.pipe(
        map(offers => {
          const sortedOffers = offers
            .filter(offer => {
              if (isSimpleSession(offer)) {
                // @ts-expect-error TS2531
                return offer.guideId === guide.id && offer.guide.workspaceId === this.chat.workspaceId;
              }

              return offer.sessions?.some(
                // @ts-expect-error TS2531
                session => session.guideId === guide.id && offer.guide.workspaceId === this.chat.workspaceId
              );
            })
            .sort(this.sortSessions);
          return sortedOffers[0];
        })
      ),
      this._sessions.futureSessions$.pipe(
        map(sessions => {
          // @ts-expect-error TS7034
          let result;
          const sortedUpcomingSessions = sessions
            .map(session => {
              if (isSimpleSession(session)) {
                if (
                  allowedSessionStatues.includes(session.status) &&
                  session.guideId === guide.id &&
                  // @ts-expect-error TS2531
                  session.guide.workspaceId === this.chat.workspaceId
                ) {
                  result = session;
                }
              } else {
                session.sessions?.forEach(simpleSession => {
                  if (
                    allowedSessionStatues.includes(simpleSession.status) &&
                    simpleSession.guideId === guide.id &&
                    // @ts-expect-error TS2531
                    simpleSession.guide.workspaceId === this.chat.workspaceId
                  ) {
                    result = simpleSession;
                  }
                });
              }
              // @ts-expect-error TS7005
              return result;
            })
            .sort(this.sortSessions);
          return sortedUpcomingSessions;
        }),
        tap(sessions => (this.currentSession = sessions[0])),
        // eslint-disable-next-line id-length
        map(sessions => sessions.filter(s => s && s.status !== SessionStatuses.IN_PROGRESS_OLD)[0])
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(latestSessions => this.refreshSession(latestSessions));
  }

  private setGuideSessionListener(client: { id: number }): void {
    if (!client) {
      return;
    }

    combineLatest([
      this._sessions.sessionRequests$.pipe(
        map(requests => {
          const sortedRequests = requests
            .filter(
              request =>
                request.guideId === this._auth.user.id &&
                ((isSimpleSession(request) && request.clientId === client.id) ||
                  // eslint-disable-next-line id-length
                  (isGroupSession(request) && request.sessions && request.sessions.some(s => s.clientId === client.id)))
            )
            // @ts-expect-error TS2532
            // eslint-disable-next-line id-length
            .map(request => (isSimpleSession(request) ? request : request.sessions.find(s => s.clientId === client.id)))
            // @ts-expect-error TS2345
            .sort(this.sortSessions);
          return sortedRequests[0];
        })
      ),
      this._sessions.futureSessions$.pipe(
        map(sessions => {
          // @ts-expect-error TS7034
          let result;
          const sortedSessions = sessions
            .filter(session => session.guideId === this._auth.user.id)
            .map(session => {
              if (isSimpleSession(session)) {
                if (allowedSessionStatues.includes(session.status) && session.clientId === client.id) {
                  result = session;
                }
              } else {
                session.sessions?.forEach(simpleSession => {
                  if (allowedSessionStatues.includes(simpleSession.status) && simpleSession.clientId === client.id) {
                    result = simpleSession;
                  }
                });
              }
              // @ts-expect-error TS7005
              return result;
            })
            .sort(this.sortSessions);
          return sortedSessions;
        }),
        tap(sessions => (this.currentSession = sessions[0])),
        // eslint-disable-next-line id-length
        map(sessions => sessions.filter(s => s && s.status !== SessionStatuses.IN_PROGRESS_OLD)[0])
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(latestSessions => this.refreshSession(latestSessions));
  }

  // @ts-expect-error TS7006
  private refreshSession(latestSessions): void {
    this.session = !latestSessions ? null : latestSessions[0] || latestSessions[1];
  }

  private sortSessions(s1: { dateStart: string }, s2: { dateStart: string }): number {
    return new Date(s1.dateStart).getTime() - new Date(s2.dateStart).getTime();
  }

  private startChat(chatDetails: {
    id: string;
    isBot?: boolean | null;
    eventId?: number | null;
    workspaceId: number;
  }): void {
    if (this.isHistoryLoading) {
      this.isHistoryLoading = false;
    }

    this._chat.restart(chatDetails.id, chatDetails.workspaceId, chatDetails.eventId);
  }
}
