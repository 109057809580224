<div class="modal-header">
  <app-button className="close" (click)="close()"></app-button>
</div>

<div class="modal-body">
  <h4 class="title" i18n="@@unenrollFromProgram">Unenroll from program</h4>

  <p>
    <ng-container i18n="@@selectProgramToEnrolllient">Please select a program to unenroll your client</ng-container>
    &ngsp;
    <strong>{{ client.firstName }} {{ client.lastName }}</strong>
    :
  </p>

  <form [formGroup]="form" (submit)="unEnroll()">
    <div class="form-group">
      <label for="program">
        <ng-container i18n="@@name">Name</ng-container>
        :
      </label>
      <ng-select
        [clearable]="false"
        [searchable]="false"
        placeholder="Select program"
        i18n-placeholder="@@selectProgramPlaceholder"
        formControlName="program"
        id="program">
        <ng-option [value]="0" i18n="@@selectProgram">Select program</ng-option>
        <ng-option [value]="1">{{ programName }}</ng-option>
      </ng-select>
      <app-form-control-error [control]="form.get('program')" i18n="@@programRequired">
        Program required
      </app-form-control-error>
    </div>

    <!-- eslint-disable -->
    <app-checkbox formControlName="blockAccess" *ngIf="isExpulsion == null" i18n="@@dismissAccessToProgram">
      Dismiss access to the content of this program
    </app-checkbox>
    <!-- eslint-enable -->

    <p *ngIf="form.get('blockAccess')?.value" i18n="@@dismissAccessToProgramDescription">
      This operation will permanently delete the client from this program, will block all related resources.
    </p>

    <div class="form-row">
      <div class="col-3">
        <app-button
          className="btn-primary send-btn"
          buttonType="submit"
          [disabled]="form.get('program')?.value !== 1"
          i18n="@@unenroll">
          Unenroll
        </app-button>
      </div>

      <div class="col-3">
        <app-button className="btn-lite send-btn" (click)="close()" i18n="@@cancel">Cancel</app-button>
      </div>
    </div>
  </form>
</div>
