import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';

import { CalendarSourceCardComponent } from './components/calendar-source-card/calendar-source-card.component';
import { ExternalCalendarsAccountsComponent } from './components/external-calendars-accounts/external-calendars-accounts.component';
import { ExternalCalendarsAccountsModalComponent } from './components/external-calendars-accounts-modal/external-calendars-accounts-modal.component';
import { CalendarAccountComponent } from './components/calendar-account/calendar-account.component';
import { SourceConnectionInvitationComponent } from './components/source-connection-invitation/source-connection-invitation.component';
import { SocialGoogleButtonModule } from '@app/modules/external-calendars-accounts/components/social-google-button/social-google-button.module';
import { GoogleButtonModule } from '@libs/components/google-button/google-button.module';

@NgModule({
  imports: [CommonModule, SharedModule, SocialGoogleButtonModule, GoogleButtonModule],
  declarations: [
    CalendarSourceCardComponent,
    ExternalCalendarsAccountsComponent,
    ExternalCalendarsAccountsModalComponent,
    CalendarAccountComponent,
    SourceConnectionInvitationComponent
  ]
})
export class ExternalCalendarsAccountsModule {}
