import { Pipe, PipeTransform } from '@angular/core';
import { normalizeFullName } from '@app/shared/utils/full-name';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {
  transform(user: { firstName: string; lastName: string } | null): string | null {
    return normalizeFullName(user);
  }
}
