import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoModule,
  TranslocoService
} from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { I18N_CONFIG, I18nConfig, TranslocoHttpLoader } from '@libs/transloco/transloco-http-loader';
import { isPlatformBrowser } from '@angular/common';
import { take } from 'rxjs/operators';
import { CustomHandler } from '@libs/transloco/custom-handler';

@NgModule({
  exports: [TranslocoModule]
})
export class TranslocoRootModule {
  static forRoot(i18nConfig: I18nConfig) {
    return {
      ngModule: TranslocoRootModule,
      exports: [TranslocoModule],
      providers: [
        {
          provide: I18N_CONFIG,
          useValue: i18nConfig
        },

        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: ['en'],
            defaultLang: 'en',
            // Remove this option if your application doesn't support changing language in runtime.
            // reRenderOnLangChange: true,
            prodMode: environment.production
          })
        },
        {
          provide: TRANSLOCO_MISSING_HANDLER,
          useClass: CustomHandler
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        {
          provide: APP_INITIALIZER,
          deps: [TranslocoService, PLATFORM_ID],
          multi: true,
          useFactory: (translocoService: TranslocoService, platformId: string) => () => {
            if (!isPlatformBrowser(platformId)) {
              return;
            }

            Object.defineProperty(window, 'translocoService', {
              value: translocoService
            });

            translocoService.load(translocoService.getDefaultLang()).pipe(take(1)).subscribe();
          }
        }
      ]
    };
  }
}
