<div class="dates" [class.injected]="injected">
  <div class="left" [class.active]="datesScrollLeft !== 0" (click)="scrollDatesLeft()"></div>
  <div class="hide-scroll">
    <div
      class="dates-scroll"
      #datesScroll
      (scroll)="datesScrollListener($event)"
      [scrollLeft]="datesScroll.scrollWidth">
      <div class="dates-wrap" [ngStyle]="{ width: datesWidth + 'px' }">
        <div
          class="date"
          *ngFor="let date of mDates; let i = index"
          (click)="selectDate(i)"
          [class.active]="this.selectedSlot === -1 ? date.selected : this.selectedSlot === i"
          [class.today]="date.today">
          <ng-container *ngIf="injected">
            <ng-container *ngIf="date.today">
              <ng-container transloco="today">Today</ng-container>
              ,
            </ng-container>
            {{ date.date.toISO() | date: 'LLL d' }}
          </ng-container>
          <ng-container *ngIf="!injected">
            {{ date.date.toISO() | date: 'd LLL' }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    class="right"
    [class.active]="datesScrollLeft < datesScroll.scrollWidth - datesScroll.offsetWidth"
    (click)="scrollDatesRight()"></div>
</div>

<div class="dates mobile">
  <div class="left" (click)="this.selectedSlot > 0 ? selectDate(this.selectedSlot - 1) : null"></div>

  <div class="dates-select-wrap">
    <ng-select
      #ngSelect
      class="ng-select"
      placeholder=""
      [searchable]="false"
      [clearable]="false"
      [items]="mDates"
      (change)="$event ? selectDate(getIndex($event)) : null">
      <ng-template ng-label-tmp let-item="item" ng-value="item">
        {{ mDates[selectedSlot].date.toISO() | date: 'd LLL' }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{ item.date.toISO() | date: 'd LLL' }}
      </ng-template>
    </ng-select>
  </div>

  <div class="right" (click)="this.selectedSlot < this.mDates.length ? selectDate(this.selectedSlot + 1) : null"></div>
</div>
