import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientProgram } from '@app/shared/interfaces/programs/client-programs';

@Component({
  selector: 'app-program-modules-preview-modal',
  templateUrl: './program-modules-preview-modal.component.html',
  styleUrls: ['./program-modules-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramModulesPreviewModalComponent {
  @Input()
  program: ClientProgram | undefined;

  @Input()
  selectedModuleId: number | undefined;

  constructor(public modal: NgbActiveModal) {}
}
