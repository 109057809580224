import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function servicePricingValidator(): ValidatorFn {
  return function (formGroup: FormGroup): ValidationErrors | null {
    const { isInstallmentsEnabled, paymentType, price, subscriptionPrice, totalPayments } = formGroup.value;

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const isInvalidPriceValue = value => typeof value !== 'number' || value < 1;
    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const buildValidationError = value => ({ invalidPrice: { value } });

    let validationError;
    switch (paymentType) {
      case 'paid': {
        validationError =
          isInvalidPriceValue(price) ||
          (isInstallmentsEnabled && (isInvalidPriceValue(subscriptionPrice) || !totalPayments))
            ? buildValidationError(price)
            : null;
        break;
      }
      case 'subscription': {
        validationError = isInvalidPriceValue(subscriptionPrice) ? buildValidationError(subscriptionPrice) : null;
        break;
      }
      case 'free':
      case 'hidden':
      default:
        validationError = null;
        break;
    }

    return validationError;
  };
}
