<div class="video-section">
  <div class="video-container">
    {{ video?.url }}
    <iframe
      [src]="video?.url | embedVideo: false | safe"
      frameborder="0"
      allow="encrypted-media"
      allowfullscreen
      class="video-frame"></iframe>
  </div>
</div>
<div class="footer">
  <app-user-info
    class="user-info"
    [user]="video?.author"
    [photoSize]="28"
    (click)="emitSelectAuthor($event)"></app-user-info>
</div>
