import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LandingLayoutComponent } from './landing-layout.component';
import { UserMenuModule } from '@app/modules/user-menu/user-menu.module';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { AuthModule } from '@app/modules/auth/auth.module';
import { CommonHeaderModule } from '@app/modules/common-header/common-header.module';
import { CommonFooterModule } from '@app/modules/common-footer/common-footer.module';

@NgModule({
  declarations: [LandingLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    UserMenuModule,
    NotificationsModule,
    AuthModule,
    CommonHeaderModule,
    CommonFooterModule
  ]
})
export class LandingLayoutModule {}
