import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import { RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { RedirectAfterSaveActions } from '@app/screens/guide/guide-shared/can-redirect-after-save';

export function canRedirectAfterSaveProgramsAdmin(
  notificationService: NotificationsService,
  sourceComponent: SaveOnRouteChanged,
  nextRoute: RouterStateSnapshot
): Observable<RedirectAfterSaveActions> {
  const notFoundRouteResponse =
    nextRoute.url !== '/not-found' ? RedirectAfterSaveActions.SAVE : RedirectAfterSaveActions.CANCEL;

  if (sourceComponent.tabIsEdited) {
    if (!sourceComponent.tabIsValid) {
      notificationService.warn('The tab contains invalid changes. Please fix or discard them');

      return of(RedirectAfterSaveActions.CANCEL);
    }

    sourceComponent.saveOnRouteChange(true);
    return of(RedirectAfterSaveActions.CANCEL);
  } else {
    return of(notFoundRouteResponse);
  }
}
