<div [formGroup]="form" class="calendar-block">
  <ngb-datepicker
    [dayTemplate]="t"
    class="calendar-container"
    #dp
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [showWeekdays]="true"
    navigation="none">
    <ng-template ngbDatepickerContent>
      <div *ngFor="let month of dp.state.months">
        <div class="calendar-month-header">
          <div class="calendar-month-controls">
            <span class="calendar-month-control-prev" (click)="navigate(-1)"></span>
            <span class="calendar-month-name">{{ i18n.getMonthFullName(month.month) }} {{ month.year }}</span>
            <span class="calendar-month-control-next" (click)="navigate(1)"></span>
          </div>
          <div class="calendar-today">
            <button class="btn btn-today btn-sm" (click)="today()" i18n="@@today">Today</button>
          </div>
        </div>
        <ngb-datepicker-month class="calendar-month" [month]="month"></ngb-datepicker-month>
      </div>
    </ng-template>
  </ngb-datepicker>
  <!--  -->
  <ng-template #t let-date let-focused="focused">
    <!-- eslint-disable -->

    <div
      class="custom-day-wrapper"
      [class.from]="(isFrom(date) && toDate !== null) || (isFrom(date) && afterFrom(hoveredDate))"
      [class.to]="
        isTo(date) ||
        (fromDate !== null && afterFrom(hoveredDate) && date == hoveredDate && !isInside(hoveredDate) && toDate == null)
      ">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </div>

    <!-- eslint-enable -->
  </ng-template>
  <app-checkbox-reactive>
    <input class="checkbox-input" type="checkbox" formControlName="selfPaced" (change)="onSelfPacedChange()" />
    <span class="checkbox-label-text" i18n="@@selfPaced">Self paced</span>
  </app-checkbox-reactive>
</div>
