import { combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { isPlatformServer } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { FirstpromoterService } from '@app/core/firstpromoter/firstpromoter.service';
import { MembershipService } from '@app/core/membership/membership.service';
import { CUSTOM_PLAN } from '@app/core/membership/types';
import { AuthModalComponent, AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GlobalConfig } from '@cnf/types';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-app-user-menu'
  }
})
export class UserMenuComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authSubscription: Subscription;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _fpGetTokenSubscription: Subscription;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _fpCreatePromoterSubscription: Subscription;

  private destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isPlatformPromoter = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any = {};

  UserRoles = UserRoles;

  // @ts-expect-error TS2564
  userRole: UserRoles;

  readonly clientTooltip: string = `Switch to client dashboard`;

  readonly guideTooltip: string = translocoService.translate(`roleSwitcherGuideTooltip`);

  // @ts-expect-error TS2564
  promoterUrl: string | null;

  expand = false;

  config: Pick<GlobalConfig, 'hideSignInAs' | 'hideSignUpAs' | 'hideWallet'> = {
    hideSignInAs: false,
    hideSignUpAs: false,
    hideWallet: true
  };

  guideRoute = environment.guideRoute;

  timeoutId = null;

  showSubscriptionsForGuide = false;

  showSubscriptionsForClient = false;

  @Input()
  fullMenu = true;

  @Input()
  // @ts-expect-error TS2564
  commonHeader: boolean;

  @ViewChild('whatsNew', { static: false })
  set whatsNew(value: ElementRef) {
    if (value && !isPlatformServer(this.platformId) && this.canActivateWhatsNew) {
      value.nativeElement.appendChild(this.window.productStashBox);
      this.window.productStashBox.style.display = 'block';
    }
  }

  get userHavePromoterDashboard(): boolean {
    return this._isPlatformPromoter && this.firstpromoterService.isAvailable;
  }

  get canActivateWhatsNew(): boolean {
    return !!this.window.productStashBox;
  }

  constructor(
    public authService: AuthService,
    private firstpromoterService: FirstpromoterService,
    private _router: Router,
    private _modal: NgbModal,
    private membershipService: MembershipService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    private brandingService: BrandingService
  ) {
    this.window = _browserWindow;
    if (isPlatformServer(this.platformId)) {
      // TODO: use angular cdk instead
      this.window.innerWidth = 576; // mock for ssr
    }
  }

  ngOnInit(): void {
    this._authSubscription = this.authService
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((user: any) => {
        this.user = user;

        if (user === null) {
          return;
        }
        this._isPlatformPromoter = user.isPlatformPromoter;
        this.userRole = user.RoleId;

        if (this._isPlatformPromoter) {
          this.getPromoterUrl();
        }
      });

    this.brandingService.globalConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: GlobalConfig) => this.setConfig(config));

    combineLatest([this.membershipService.membershipSettings$, this.authService.onMembershipChanged$()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([membershipSettings, userMembership]) => {
        this.showSubscriptionsForGuide =
          membershipSettings.enabledForCoaches && (!userMembership || userMembership.platformPlanType !== CUSTOM_PLAN);
        this.showSubscriptionsForClient =
          membershipSettings.enabledForClients && (!userMembership || userMembership.platformPlanType !== CUSTOM_PLAN);
      });
  }

  ngOnDestroy(): void {
    if (this._authSubscription) {
      this._authSubscription.unsubscribe();
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (this._fpCreatePromoterSubscription) {
      this._fpCreatePromoterSubscription.unsubscribe();
    }

    if (this._fpGetTokenSubscription) {
      this._fpGetTokenSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  createAlternativeAccount(): void {
    if (!this.userRole) {
      return;
    }

    this.authService.signupAlternativeAccount().pipe(takeUntil(this.destroy$)).subscribe();
  }

  hideDropdown(): void {
    this.expand = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openSignIn() {
    const ref = this._modal.open(AuthModalComponent, { windowClass: 'auth-modal' });
    ref.componentInstance.activeState = AuthStates.INIT;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signOut() {
    this.authService.signout();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  signinAlternativeAccount() {
    this.authService.signinAlternativeAccount().pipe(takeUntil(this.destroy$)).subscribe();
  }

  switchToAlternativeAccount(isClient: boolean): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if ((isClient && this.userRole === UserRoles.CLIENT) || (!isClient && this.userRole === UserRoles.GUIDE)) {
      return;
    }

    // @ts-expect-error TS2322
    this.timeoutId = setTimeout(() => {
      if (!this.user.hasAlternativeProfile) {
        return this.createAlternativeAccount();
      }

      this.signinAlternativeAccount();
    }, 1000);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  stopPropagation(event) {
    event.stopPropagation();
  }

  toggleDropdown(): void {
    this.expand = !this.expand;
  }

  private setConfig({ hideSignInAs, hideSignUpAs, hideWallet }: GlobalConfig): void {
    this.config.hideSignInAs = hideSignInAs;
    this.config.hideSignUpAs = hideSignUpAs;
    this.config.hideWallet = hideWallet;
  }

  createPromoter(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._fpCreatePromoterSubscription = this.firstpromoterService.createPromoter().subscribe(url => {
      this.promoterUrl = url;
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    });
  }

  getPromoterUrl(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._fpGetTokenSubscription = this.firstpromoterService.getUrlIfExists().subscribe(url => {
      this.promoterUrl = url;
    });
  }
}
