import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BlogAuthorGuide, BlogItemArticle, IArticleSelection } from '../../types';
import { makeUriFromString } from '@app/screens/blog/utils';
import { environment } from '@env/environment';

@Component({
  selector: 'app-blog-item-article',
  templateUrl: './blog-item-article.component.html',
  styleUrls: ['../styles/blog-item.scss', './blog-item-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemArticleComponent {
  disqusId = environment.disqusId;

  @Input()
  // @ts-expect-error TS2564
  article: BlogItemArticle;

  @Input()
  hideAuthor = false;

  @Output()
  select = new EventEmitter<IArticleSelection>();

  @Output()
  selectAuthor = new EventEmitter<string>();

  @HostListener('click')
  emitSelect(): void {
    this.select.emit({
      articleId: this.article.id,
      // @ts-expect-error TS2322
      authorUrl: this.article.author ? this.article.author.namedUrl : null
    });
  }

  get source(): string {
    return this.article.url || this.article.title;
  }

  get link(): string {
    return makeUriFromString(this.source, this.article.id);
  }

  get isArticleAuthorGuide(): boolean {
    return !!this.article && !!this.article.author && this.article.author instanceof BlogAuthorGuide;
  }

  // eslint-disable-next-line id-length
  emitSelectAuthor(e: Event): void {
    e.stopPropagation();
    // @ts-expect-error TS2532
    this.selectAuthor.emit(this.article.author.namedUrl);
  }
}
