<div class="wrapper">
  <div class="title-wrapper" (click)="toggle()" [attr.aria-expanded]="!isCollapsed">
    <div class="title">
      <ng-content select=".collapse-group-title"></ng-content>
    </div>
    <i class="chevron" [class.collapsed]="isCollapsed"></i>
  </div>
  <div [ngbCollapse]="isCollapsed">
    <ng-content select=".collapse-group-content"></ng-content>
  </div>
</div>
