<div class="content-wrapper">
  <h4 class="modal-title" i18n="@@unableToDeleteService">Unavailable for deletion</h4>
  <div class="modal-body">
    <p class="blockers-description">
      <span i18n="@@deletionWarning">To make a session available for deletion, please, first delete it from</span>
      :
    </p>
    <div class="blockers-list-wrapper">
      <ul class="blockers-list">
        <li *ngFor="let program of programs; trackBy: trackById">
          <pui-alert type="warning">
            <pui-icon class="icon" svgIcon="warning" size="xxs"></pui-icon>
            <a
              class="service-link"
              target="_blank"
              [routerLink]="['/', guideRoute, 'programs', program.id, 'edit', 'content']">
              {{ program.name }},
              <span *ngFor="let module of program.modules; trackBy: trackById; index as index">
                <ng-container i18n="@@module">Module</ng-container>
                {{ module.order }}
                <ng-container *ngIf="index !== program.modules.length - 1">,</ng-container>
              </span>
            </a>
          </pui-alert>
        </li>
        <li *ngFor="let package of packages; trackBy: trackById">
          <pui-alert type="warning">
            <pui-icon class="icon" svgIcon="warning" size="xxs"></pui-icon>
            <a
              class="service-link"
              target="_blank"
              [routerLink]="['/', route, servicesPath, 'packages', package.id, 'settings']">
              {{ package.title }}
            </a>
          </pui-alert>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button pui-button appearance="primary" size="s" (click)="dialogRef.close()">
      <ng-container i18n="@@ok">Ok</ng-container>
    </button>
  </div>
</div>
