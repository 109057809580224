<ng-container *ngIf="moduleForm" [formGroup]="moduleForm">
  <app-module-service-details
    *ngIf="serviceDetails || sessionTemplateDraft"
    [serviceDetails]="serviceDetails"
    [serviceDraft]="sessionTemplateDraft"
    [showRecurringAlert]="showRecurringAlert"
    (detailsClick)="editSessionTemplate()"></app-module-service-details>
  <div class="form-group">
    <app-module-activation-select
      formControlName="activation"
      [options]="moduleActivations"
      [moduleFormTouched]="moduleForm.get('activation')?.touched"></app-module-activation-select>
  </div>
  <div class="form-group">
    <label class="label top-label" i18n="@@sessionModuleCompletionOptions">Completion options</label>
    <app-module-completion-select
      [moduleType]="moduleForm.value.moduleType"
      data-qa-id="session-module-completion-options-select"
      formControlName="completionType"></app-module-completion-select>
  </div>
</ng-container>
