import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleRound'
})
export class SimpleRoundPipe implements PipeTransform {
  transform(value: string | number): number | null {
    if (typeof value === 'string' && !value) {
      return null;
    }

    const number = Number(value);

    if (isNaN(number)) {
      return null;
    }

    return Math.round((number + Number.EPSILON) * 100) / 100;
  }
}
