// import { locale } from '@env/locale';

// const dateTimeLocale = locale.dateTimeLocale;

export function timeAt(dateTimeLocale: string): string {
  if (!dateTimeLocale.includes('en')) {
    return '';
  }

  return 'at ';
}
