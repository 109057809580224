<ng-container [formGroup]="form">
  <div class="question-text">
    <div class="question-html ql-editor" [appSetMetaToFirstChild]="key" [innerHTML]="question | safeHtml"></div>
  </div>

  <div class="question-answer fs-exclude" formGroupName="answer">
    <img [src]="answerText" *ngIf="isDisabled && answerText" />
    <p *ngIf="isDisabled && !answerText" i18n="@@noSignature" class="empty">No signature</p>
    <div [ngClass]="{ hidden: isDisabled }">
      <div class="signature-wrap">
        <canvas class="canvas" #canvas [height]="260" [width]="650"></canvas>
        <div class="bar">
          <div class="btns-wrap">
            <button class="btn-link" (click)="clear()">Clear</button>
            <button class="btn-link" (click)="undo()">Undo</button>
          </div>
          <span i18n="@@signAbove">Sign above</span>
        </div>
      </div>
      <p i18n="@@signingPolicy" class="signing-policy">
        In signing this document with an electronic signature, I agree and understand that this signature is considered
        as legally valid as a handwritten signature.
      </p>
    </div>
  </div>
</ng-container>
