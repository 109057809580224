<pui-card>
  <pui-card-header>
    <div
      *ngIf="mode === 'full'"
      class="current-title"
      pui-card-header-title
      data-qa-id="user-name"
      pui-tooltip-direction="top-right"
      [pui-tooltip]="title">
      {{ title }}
    </div>
    <span *ngIf="mode === 'full'" pui-card-header-subheader class="current-subtitle">
      <a
        *ngIf="subtitle?.openPopup as callback"
        pui-link
        href="#"
        title="Booking link"
        i18n-title="@@currentPopupViewBookingLink"
        (click)="callback($event, subtitle)">
        <pui-icon svgIcon="pui:link" size="xxs"></pui-icon>
        <span class="pui-space_left-1">
          <ng-container i18n="@@currentPopupViewBookingLink">Booking link</ng-container>
        </span>
      </a>
    </span>
    <pui-avatar
      pui-card-header-icon
      size="l"
      color="white"
      [avatarUrl]="avatar?.avatarUrl"
      [background]="avatar?.background"
      [rounded]="avatar.rounded"
      [text]="title"></pui-avatar>
  </pui-card-header>
</pui-card>
