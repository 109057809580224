import { NgScrollbarModule } from 'ngx-scrollbar';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@app/shared';
import { PuiAvatarModule, PuiBadgeModule, PuiDividerModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { SharedModule as LibSharedModule } from '@libs/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CommunityNotificationComponent } from './components/header-notifications/community-notification/community-notification.component';
import { FormNotificationComponent } from './components/header-notifications/form-notification/form-notification.component';
import { HeaderNotificationsComponent } from './components/header-notifications/header-notifications/header-notifications.component';
import { NoteNotificationComponent } from './components/header-notifications/note-notification/note-notification.component';
import { NotificationsListComponent } from './components/header-notifications/notifications-list/notifications-list.component';
import { PackageNotificationComponent } from './components/header-notifications/package-notification/package-notification.component';
import { NotificationDateFormatterPipe } from './components/header-notifications/pipes/notification-date-formatter.pipe';
import { ProgramNotificationComponent } from './components/header-notifications/program-notification/program-notification.component';
import { SessionNotificationComponent } from './components/header-notifications/session-notification/session-notification.component';
import { SimpleNotificationComponent } from './components/header-notifications/simple-notification/simple-notification.component';
import { WorkspaceNotificationComponent } from './components/header-notifications/workspace-notification/workspace-notification.component';

const routes: Routes = [
  {
    path: '',
    component: HeaderNotificationsComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    SharedModule,
    RouterModule.forChild(routes),
    PuiBadgeModule,
    PuiIconModule,
    PuiDividerModule,
    PuiAvatarModule,
    LibSharedModule
  ],
  declarations: [
    HeaderNotificationsComponent,
    NotificationsListComponent,
    SessionNotificationComponent,
    PackageNotificationComponent,
    NotificationDateFormatterPipe,
    FormNotificationComponent,
    ProgramNotificationComponent,
    SimpleNotificationComponent,
    WorkspaceNotificationComponent,
    NoteNotificationComponent,
    CommunityNotificationComponent
  ],
  exports: [
    HeaderNotificationsComponent,
    NotificationsListComponent,
    SessionNotificationComponent,
    PackageNotificationComponent,
    NotificationDateFormatterPipe,
    ProgramNotificationComponent,
    SimpleNotificationComponent,
    CommunityNotificationComponent
  ]
})
export class NotificationsModule {}
