<div class="wrapper" (click)="handleRedirect()">
  <div class="content">
    <div class="image" [ngStyle]="{ 'background-image': 'url(' + quiz.photo + ')' }"></div>
    <div class="name">{{ quiz.name | cutString: 50 }}</div>
    <div class="throws">
      <ng-container *ngIf="dailyPatterns.length">
        <ng-container *ngFor="let dailyPattern of dailyPatterns">
          {{ dailyPattern }}
          <br />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="weeklyPatterns.length">
        <ng-container *ngFor="let weeklyPattern of weeklyPatterns">
          {{ weeklyPattern }}
          <br />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="monthlyPatterns.length">
        <ng-container *ngFor="let monthlyPattern of monthlyPatterns">
          {{ monthlyPattern }}
          <br />
        </ng-container>
      </ng-container>
    </div>
    <div class="controls">
      <app-button className="btn-primary" *ngIf="isQuizReadyToFill" (click)="startQuiz()" i18n="@@fillOutSurvey">
        Fill out survey
      </app-button>
      <app-button className="btn-link" [routerLink]="'/client/programs/quiz/' + quiz.id" i18n="@@seeAnswers">
        See answers
      </app-button>
    </div>
  </div>
</div>
