import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';

@Injectable()
export class NavigationConditionsSuperAdminService implements Partial<ConditionsMap<VisibilityCondition>> {
  always: Observable<boolean> = of(true);

  never: Observable<boolean> = of(false);
}
