import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientSessionGuideAttribute } from '../../types';
import { SessionAttributeIconEnum } from '@libs/business/cdk/session';
import { ClientSessionAttributesEnum } from '../../enums';
import { puiDefaultProp } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-client-session-guide-attribute',
  templateUrl: './client-session-guide-attribute.component.html',
  styleUrls: ['../client-session-attribute.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionGuideAttributeComponent implements ClientSessionGuideAttribute {
  type: ClientSessionAttributesEnum.GUIDE = ClientSessionAttributesEnum.GUIDE;

  @Input()
  @puiDefaultProp()
  leftIcon: SessionAttributeIconEnum.USER = SessionAttributeIconEnum.USER;

  @Input() label!: string;

  @Input()
  @puiDefaultProp()
  rightIcon: SessionAttributeIconEnum.MESSAGE = SessionAttributeIconEnum.MESSAGE;

  @Input() rightIconRouterLink!: string;
}
