<div class="controls update" *ngIf="{ saveButtonDisabled: !!(saveButtonDisabled$ | async) } as options">
  <app-button
    className="btn-preview btn-lg"
    [disabled]="options.saveButtonDisabled"
    (click)="previewAction()"
    i18n="@@preview"
    >Preview</app-button
  >
  <app-button
    className="btn-primary btn-lg"
    [disabled]="options.saveButtonDisabled"
    (click)="updateAction(isNew)"
    i18n="@@saveChanges"
    >Save changes</app-button
  >
</div>

<div class="form-wrapper">
  <div class="box">
    <div class="wide-form">
      <app-quiz-basic-info></app-quiz-basic-info>
    </div>
  </div>
</div>

<div class="form-wrapper">
  <div class="form">
    <app-quiz-questions></app-quiz-questions>
  </div>
</div>
