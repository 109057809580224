import { Injectable } from '@angular/core';
import { IOnlineStatuses } from '@app/shared/interfaces/user';
import { Observable, interval } from 'rxjs';
import { mergeMap, startWith, take } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';
import { SocketService } from '../socket/socket.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Injectable()
export class OnlineStatusService {
  constructor(private _auth: AuthService, private _socket: SocketService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getOnlineStatuses$(userIds: number[], intervalLength = 0) {
    return this._socket.onSuccess$().pipe(
      take(1),
      mergeMap(() => {
        const role = this._auth.user.RoleId === UserRoles.CLIENT ? UserRoles.GUIDE : UserRoles.CLIENT;
        const users = userIds.map(userId => ({ id: userId, role }));

        let interval$: Observable<IOnlineStatuses>;
        if (intervalLength) {
          interval$ = interval(intervalLength).pipe(
            startWith(0),
            mergeMap(() => this._socket.getOnlineStatuses$(users))
          );
        } else {
          interval$ = this._socket.getOnlineStatuses$(users);
        }
        return interval$;
      })
    );
  }
}
