<div #container class="booking__container" data-qa-id="booking-container">
  <div class="booking__main">
    <!--STEPPER-->
    <div class="booking__row booking__stepper">
      <app-booking-stepper></app-booking-stepper>
    </div>

    <!--CONTENT-->
    <div class="booking__row booking__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
