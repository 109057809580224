import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, finalize, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TfaSmsTimerService {
  private REQUEST_WAITING_TIME = 5 * 60; // 5 minutes

  private timerSub?: Subscription;
  private secondsForWaitingSource = new BehaviorSubject(this.REQUEST_WAITING_TIME);

  private isTimerInProgress = false;

  get secondsForWaiting(): Observable<number> {
    return this.secondsForWaitingSource.asObservable();
  }

  get timerInProgress(): boolean {
    return this.isTimerInProgress;
  }

  startTimer(): void {
    this.clearTimer();

    this.isTimerInProgress = true;

    this.timerSub = this.secondsForWaiting
      .pipe(
        delay(1000),
        take(this.REQUEST_WAITING_TIME),
        finalize(() => {
          this.isTimerInProgress = false;
        })
      )
      .subscribe(seconds => this.secondsForWaitingSource.next(seconds - 1));
  }

  clearTimer(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    this.secondsForWaitingSource.next(this.REQUEST_WAITING_TIME);
  }
}
