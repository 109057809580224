import { Subject } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRoles } from '@app/shared/enums/user-roles';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-switch-to-alternative-account-confirm-modal',
  templateUrl: './switch-to-alternative-account-confirm-modal.component.html',
  styleUrls: ['./switch-to-alternative-account-confirm-modal.component.scss']
})
export class SwitchToAlternativeAccountConfirmModalComponent implements OnDestroy {
  private destroy$ = new Subject();

  @Input()
  // @ts-expect-error TS2564
  role: UserRoles;

  @Input()
  withLogoutButton = false;

  constructor(public modal: NgbActiveModal) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  switchAccount() {
    this.modal.close();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  shouldSwitchToGuide() {
    return this.role === UserRoles.GUIDE;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  shouldSwitchToClient() {
    return this.role === UserRoles.CLIENT;
  }

  dismiss(): void {
    this.modal.dismiss();
  }

  logout(): void {
    this.modal.close({ logout: true });
  }
}
