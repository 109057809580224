<div class="modal-header">
  <h3 class="modal-title" i18n="@@notSureWhoFit">Not sure who’s the right fit for you?</h3>
  <app-button className="close" (click)="cancel()"></app-button>
</div>
<div class="modal-body">
  <p class="info-text" i18n="@@notSureWhoFitDescPart">
    Please, describe what kind of experience you’re looking for in this secure form. The information that you share with
    us is private and will help us match you with the right heart-centered wellbeing provider.
    <br />
    Our triage coach will respond within 24 hours via email or faster if you text us.
  </p>
  <p class="info-text" i18n="@@notSureWhoFitContactUs">
    Is this urgent? Are you looking for help right now? Please text us to
    <a href="tel:12133400818">+1(213)-340-0818</a>
    with your request and we will try to find a provider who is available to connect with you ASAP. In case of emergency
    or life-threatening situation please call 911.
  </p>
  <form [formGroup]="helpForm">
    <ng-container *ngIf="showEmail">
      <div class="help-form-group">
        <label for="help_form_email" i18n="@@email">Email</label>
        <div>
          <input id="help_form_email" class="help-form-input" type="email" formControlName="email" />
        </div>
        <app-form-control-error [control]="helpForm.controls['email']">
          <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
        </app-form-control-error>
      </div>
    </ng-container>
    <div class="help-form-group">
      <ng-container *ngIf="showEmail">
        <label for="help_form_text" i18n="@@message">Message</label>
      </ng-container>
      <div>
        <textarea id="help_form_text" class="help-form-input" formControlName="text" rows="5"></textarea>
      </div>
      <app-form-control-error [control]="helpForm.controls['text']">
        <ng-container *ngIf="helpForm.controls.text?.errors?.required">
          <ng-container i18n="@@textRequired">Text required</ng-container>
          .
        </ng-container>
        <ng-container *ngIf="helpForm.controls.text?.errors?.maxlength">
          <ng-container i18n="@@textIsTooLong">Text is too long. Max length is</ng-container>
          {{ MAX_TEXT_LENGTH }}
        </ng-container>
      </app-form-control-error>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button className="btn-lg btn-main" (click)="sendMessage()" i18n="@@getPersonalizedHelpBtn">
    Get personalized help!
  </app-button>
</div>
