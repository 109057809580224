import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import config from '../config/config';

@Injectable()
export class SystemTimeService {
  readonly REFRESH_PERIOD = 1000 * 60 * 30;
  readonly ALLOWED_INCONSISTENCY_EDGE = 5;

  constructor(private _http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrongSystemTime$(): Observable<any> {
    return timer(0, this.REFRESH_PERIOD).pipe(
      switchMap(() => this._http.get(`${config.apiPath}/public/time?t=` + DateTime.local().toSeconds())),
      filter((res: { time: string }) => {
        const serverTime = DateTime.fromISO(res.time);
        const localTime = DateTime.local();
        const allowedDuration = { minutes: this.ALLOWED_INCONSISTENCY_EDGE };
        return serverTime.minus(allowedDuration) > localTime || serverTime.plus(allowedDuration) < localTime;
      })
    );
  }
}
