<div *ngIf="view$ | async as view">
  <ng-template [ngIf]="view.mode === 'short'">
    <button
      pui-icon-button
      class="sidenav-toggle-button"
      size="s"
      shape="square"
      appearance="flat"
      pui-dropdown-align="right"
      pui-dropdown-direction="horizontal"
      [(pui-dropdown)]="view.open"
      [pui-dropdown-content]="footerDropdown"
      (click)="onToggle(view.open)">
      <pui-icon svgIcon="pui:toggle-right"></pui-icon>
    </button>
    <ng-template #footerDropdown>
      <div class="switch-to-alternative-account-panel">
        <ng-container *ngTemplateOutlet="toggle"></ng-container>
      </div>
    </ng-template>
  </ng-template>

  <ng-template [ngIf]="view.mode !== 'short'">
    <ng-container *ngTemplateOutlet="toggle"></ng-container>
  </ng-template>

  <ng-template #toggle>
    <app-labeled-toggle
      data-qa-id="role-switcher__toggle"
      class="alternative-account-toggle"
      [labels]="view.labels"
      [labelIndex]="view.labelIndex"
      (change)="switchToAlternativeAccount()"></app-labeled-toggle>
  </ng-template>
</div>
