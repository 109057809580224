import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PuiButtonModule,
  PuiGroupModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiInputRadioModule,
  PuiLinkModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { LocationFormComponent } from './location-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@libs/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PuiToggleModule,
    PuiInputModule,
    PuiGroupModule,
    PuiSelectModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiLinkModule,

    PuiIconModule,
    PuiInputCheckboxModule,
    PuiInputRadioModule,
    NgbTooltipModule,
    SharedModule
  ],
  declarations: [LocationFormComponent],
  exports: [LocationFormComponent]
})
export class LocationFormModule {}
