<div class="session-invitation">
  <div class="session-info-container">
    <div class="session-client-avatar">
      <pui-avatar
        class="user-photo"
        [text]="clientSession?.guide.name"
        [avatarUrl]="clientSession?.guide.photo"
        size="l"></pui-avatar>
    </div>

    <div class="session-details-container">
      <div class="session-name" pui-typography="body-s-bold">
        <ng-container *ngIf="clientSession?.session?.serviceParent?.id">
          {{ clientSession?.session?.serviceParent.name }} {{ clientSession?.session?.serviceParent.type }}.
        </ng-container>
        {{ clientSession.session?.name }}
      </div>
      <div class="session-details">
        <span class="session-details-item">
          <pui-icon svgIcon="pui:calendar" size="xs"></pui-icon>
          {{ clientSession.session | sessionRangeFormatter }}
        </span>
        <span class="session-details-item">
          <pui-icon svgIcon="pui:user" size="xs"></pui-icon>
          {{ clientSession?.guide | userName }}
          <a
            [routerLink]="['../chat', buildDirectChatId(clientSession?.guide)]"
            class="user-chat-link"
            [ngbTooltip]="chatTooltip">
            <pui-icon class="user-chat-icon" svgIcon="pui:message" size="xs"></pui-icon>
          </a>
        </span>
        <span *ngIf="clientSession.session && !clientSession.session.hidePrice" class="session-details-item">
          <pui-icon svgIcon="pui:tag" size="xs"></pui-icon>
          <app-session-payment-formatter
            [session]="clientSession?.session"
            [priceOnly]="true"></app-session-payment-formatter>
        </span>
        <span class="session-details-item">
          <pui-icon
            [svgIcon]="clientSession?.session?.connectionType | sessionConnectionTypeIcon"
            size="xs"
            aria-hidden="true"></pui-icon>
          {{ clientSession?.session?.connectionType | sessionPlatform: clientSession?.session?.address }}
        </span>
      </div>
    </div>
  </div>

  <div class="session-invitation-actions">
    <button class="action-btn" pui-button appearance="secondary" size="s" (click)="approve()">
      <ng-container i18n="@@accept">Accept</ng-container>
    </button>
    <button
      class="action-btn"
      pui-button
      appearance="flat"
      [ghost]="true"
      size="s"
      (click)="reschedule()"
      *ngIf="clientSession.session.serviceType === SessionTypes.SESSION">
      <pui-icon svgIcon="pui:rotate-right" size="s"></pui-icon>
      <ng-container i18n="@@reschedule">Reschedule</ng-container>
    </button>
    <button class="action-btn" pui-button appearance="flat" [ghost]="true" size="s" (click)="cancel()">
      <pui-icon svgIcon="pui:x-circle"></pui-icon>
      <ng-container i18n="@@decline">Decline</ng-container>
    </button>
  </div>
</div>

<ng-template #chatTooltip>
  <div i18n="@@sendMsg">Send message</div>
</ng-template>
