// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleTimeSlotsBuildStrategy<TSchedule, TTimeSlot> {
  generate: (
    schedule: TSchedule,
    slotDuration: number,
    timezone: string,
    timeSlotFormat: string,
    locale: string,
    excludeDateTime?: string
  ) => TTimeSlot[];
  canGenerateTimeSlots?: (
    schedule: TSchedule,
    slotDuration: number,
    timezone: string,
    excludeDateTime?: string
  ) => boolean;
}

export abstract class ScheduleTimeSlotsBuildStrategy<TSchedule, TTimeSlot>
  implements IScheduleTimeSlotsBuildStrategy<TSchedule, TTimeSlot>
{
  abstract generate(
    schedule: TSchedule,
    slotDuration: number,
    timezone: string,
    timeSlotFormat: string,
    locale: string,
    excludeDateTime?: string
  ): TTimeSlot[];

  abstract canGenerateTimeSlots(
    schedule: TSchedule,
    slotDuration: number,
    timezone: string,
    excludeDateTime?: string
  ): boolean;
}
