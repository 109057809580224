<ng-container *ngIf="sessions | normalizeSessions as normalizedSessions">
  <ng-container *ngIf="!groupBy; else groupedList">
    <ng-container *ngTemplateOutlet="sessionsList; context: { $implicit: normalizedSessions }"></ng-container>
  </ng-container>
  <ng-template #groupedList>
    <ng-container *ngTemplateOutlet="grouping; context: { $implicit: normalizedSessions }"></ng-container>
  </ng-template>
</ng-container>

<ng-template #sessionsList let-sessions let-withoutDivider="withoutDivider" let-groupName="groupName">
  <ng-container *ngFor="let session of sessions; let last = last">
    <div class="sessions-list">
      <app-session
        class="pui-space_bottom-5 pui-space_top-5"
        [session]="session | sessionNameWithParentGroupName: groupName"
        [attr.data-qa-id]="'past-client-session-item-' + session?.title"></app-session>

      <ng-container *ngIf="!last && !withoutDivider">
        <pui-divider></pui-divider>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #grouping let-sessions>
  <ng-container *ngFor="let item of sessions | groupSessionsBy; let last = last">
    <ng-container *ngIf="item.children; else session">
      <div class="session-group">
        <div class="session-group__header">
          <div class="session-group__header-content">
            <div pui-typography="h5" class="session-group__title" i18n="@clientSessionsGroupCompleted:Lable">
              Completed {{ item.type }}: {{ item.name }}
            </div>
          </div>
          <div class="session-group__header-actions">
            <button pui-icon-button appearance="flat" shape="rounded">
              <pui-icon
                svgIcon="{{ collapse.expanded ? 'pui:chevron-up' : 'pui:chevron-down' }}"
                (click)="collapse.expanded ? collapse.close() : collapse.open()"></pui-icon>
            </button>
          </div>
        </div>

        <pui-collapse #collapse>
          <ng-template pui-collapse-content>
            <div class="session-group__childrens">
              <ng-container
                *ngTemplateOutlet="
                  sessionsList;
                  context: { $implicit: item.children, withoutDivider: true, groupName: item.name }
                "></ng-container>
            </div>
          </ng-template>
        </pui-collapse>
      </div>
    </ng-container>
    <ng-template #session>
      <app-session
        class="pui-space_bottom-5 pui-space_top-5"
        [session]="$any(item)"
        [attr.data-qa-id]="'past-client-session-item-' + item?.title"></app-session>
    </ng-template>
    <pui-divider *ngIf="!last" class="session-group__divider"></pui-divider>
  </ng-container>
</ng-template>
