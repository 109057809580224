import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BrandingService } from '@app/core/branding/branding.service';
import { ILogoLink, prepareLogoUrlData } from '@app/shared/utils/url';
import { UrlTypes } from '@app/shared/enums/url-types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  URL_TYPE = UrlTypes;
  mainLogo = '';
  // @ts-expect-error TS2564
  logoUrl: ILogoLink;

  constructor(private readonly _brandingService: BrandingService) {}

  ngOnInit(): void {
    this._brandingService.mainLogo$.pipe(takeUntil(this.destroy$)).subscribe(({ logo }) => (this.mainLogo = logo));

    this._brandingService.globalConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ urlInLogo }) => (this.logoUrl = prepareLogoUrlData(urlInLogo)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
