import { StarRatingModule } from 'angular-star-rating';
import { SimpleNotificationsModule } from 'angular2-notifications';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { switchMap } from 'rxjs/operators';

import { DatePipe, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { APP_ID, APP_INITIALIZER, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@app/core';
import { IntegrationsModule } from '@app/core/integrations/integrations.module';
import { LocaleService } from '@app/core/locale/locale.service';
import { NotFoundModule } from '@app/modules/not-found/not-found.module';
import { ProposeFormsModule } from '@app/modules/propose-forms/propose-forms.module';
// Our modules:
import { ReschedulingServiceModule } from '@app/modules/rescheduling-service';
import { SessionWidgetRootModule } from '@app/modules/session-widget/session-widget-root.module';
import { SystemVersionPopupModule } from '@app/modules/system-version-popup/system-version-popup.module';
import { TokenSignInModule } from '@app/modules/token-sign-in/token-sign-in.module';
import { iconInitialize } from '@app/modules/ui-kit/_base/ui-icon/icon';
import { IconStore } from '@app/modules/ui-kit/_base/ui-icon/icon-store';
import { ThemingModule } from '@app/modules/ui-kit/_theming';
import { KitBrandingModule } from '@app/modules/ui-kit/kit-branding/kit-branding.module';
import { SharedModule } from '@app/shared';
import { NgbDateCustomParserFormatter } from '@app/shared/utils/datepicker-formatter';
import {
  DEVICE_TYPE_PROVIDERS,
  PuiDialogModule,
  puiIconInitialize,
  PuiIconStore,
  PuiRootModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import {
  ClientSessionActionsService,
  ClientSessionAdaptorService,
  ClientSessionCommunicationsService
} from '@libs/business/client/client-session/services';
import { CLIENT_SESSION_ADAPTOR } from '@libs/business/client/client-session/tokens';
import { FeatureFlagService, FeatureFlagsModule } from '@libs/feature-flags';
import { AlvisModule } from '@libs/services/alvis/alvis.module';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
// Bootstrap component:
import { AppComponent } from './app.component';
import { AuthService } from './core/auth/services';
import { GLOBAL_WINDOW } from './core/browser-window/window-provider';
import { LayoutsModule } from './layouts/layouts.module';
import { AlertsModule } from './modules/alerts/alerts.module';
import { BookServiceModule } from './modules/book-service';
import { ClientInvitationActivationModule } from './modules/client-invitation/client-invitation-activation.module';
import { ClientProgramsModule } from './modules/client-programs/client-programs.module';
import { CouponActivationModule } from './modules/coupon-activation/coupon-activation.module';
import { EmailVerificationModule } from './modules/email-verification/email-verification.module';
import { GiftCouponsModule } from './modules/gift-coupons/gift-coupons.module';
import { GuideOfferModule } from './modules/guide-offer/guide-offer.module';
import { PhoneVerificationModule } from './modules/phone-verification/phone-verification.module';
import { ServiceSchedulingModule } from './modules/service-scheduling/service-scheduling.module';
import { SessionTemplateDrawerModule } from './modules/session-forms/drawers/session-template-drawer/session-template-drawer.module';
import { ScreensModule } from './screens/screens.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '@libs/transloco';
import { ExternalLinkModule } from '@app/modules/external-link/external-link.module';
import { RuntimeConfigModule } from '@app/core/runtime-config/runtime-config.module';
import { GraphQLModule } from './graphql.module';

// @ts-expect-error TS7005
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const System;

@NgModule({
  imports: [
    AlertsModule,
    AppRoutingModule,
    RuntimeConfigModule.forRoot(),
    BookServiceModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'aware' }),
    ClientInvitationActivationModule,
    ClientProgramsModule,
    CoreModule,
    CouponActivationModule,
    EmailVerificationModule,
    GiftCouponsModule,
    GuideOfferModule,
    ProposeFormsModule,
    ServiceSchedulingModule,
    SessionTemplateDrawerModule,
    HammerModule,
    LayoutsModule,
    NgbModule,
    NotFoundModule,
    PhoneVerificationModule,
    ReschedulingServiceModule,
    ScreensModule,
    SharedModule,
    KitBrandingModule,
    PuiDialogModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    SimpleNotificationsModule.forRoot({
      position: ['bottom', 'right'],
      timeOut: 4000,
      clickToClose: true,
      preventDuplicates: true,
      showProgressBar: false,
      theClass: 'aware-platform-notifications',
      icons: {
        success: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.7 8.1l-7.1 6.7-2.2-2.2c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l2.5 2.5c.2.2.5.2.7 0l7.5-7c.2-.2.2-.5 0-.7-.2-.1-.5-.2-.7 0z"/>
                    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 23C5.9 23 1 18.1 1 12S5.9 1 12 1s11 4.9 11 11-4.9 11-11 11z"/>
                  </svg>`,
        error: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12.2 0H12C5.3.1-.1 5.6 0 12.2.1 18.8 5.3 24 11.8 24h.2c6.7-.1 12.1-5.6 12-12.2C23.9 5.2 18.7 0 12.2 0zM12 23h-.2C5.9 23 1.2 18.2 1 12.2.9 6.2 5.9 1.1 12 1h.2C18.1 1 22.8 5.8 23 11.8c.1 6-4.9 11-11 11.2z"/>
                  <path d="M12 14.1c.3 0 .5-.2.5-.5V6.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v7.3c0 .3.2.5.5.5z"/>
                  <circle cx="12" cy="17.2" r="1"/>
                </svg>`,
        warn: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12.2 0H12C5.3.1-.1 5.6 0 12.2.1 18.8 5.3 24 11.8 24h.2c6.7-.1 12.1-5.6 12-12.2C23.9 5.2 18.7 0 12.2 0zM12 23h-.2C5.9 23 1.2 18.2 1 12.2.9 6.2 5.9 1.1 12 1h.2C18.1 1 22.8 5.8 23 11.8c.1 6-4.9 11-11 11.2z"/>
                <path d="M12 14.1c.3 0 .5-.2.5-.5V6.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v7.3c0 .3.2.5.5.5z"/>
                <circle cx="12" cy="17.2" r="1"/>
               </svg>`,
        alert: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12.2 0H12C5.3.1-.1 5.6 0 12.2.1 18.8 5.3 24 11.8 24h.2c6.7-.1 12.1-5.6 12-12.2C23.9 5.2 18.7 0 12.2 0zM12 23h-.2C5.9 23 1.2 18.2 1 12.2.9 6.2 5.9 1.1 12 1h.2C18.1 1 22.8 5.8 23 11.8c.1 6-4.9 11-11 11.2z"/>
                  <path d="M12 14.1c.3 0 .5-.2.5-.5V6.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v7.3c0 .3.2.5.5.5z"/>
                  <circle cx="12" cy="17.2" r="1"/>
               </svg>`,
        info: `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                <path d='M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 23C6 23 1 18 1 12S6 1 12 1s11 5 11 11-5 11-11 11z'/>
                <path d='M15.1 18.8h-2.6V9.9c0-.3-.2-.5-.5-.5H9.9c-.3 0-.5.2-.5.5s.2.5.5.5h1.6v8.3H8.9c-.3 0-.5.2-.5.5s.2.5.5.5h6.3c.3 0 .5-.2.5-.5 0-.2-.3-.4-.6-.4z'/>
                <circle cx='11.5' cy='5.7' r='1'/>
               </svg>`
      }
    }),
    StarRatingModule.forRoot(),
    SystemVersionPopupModule,
    TokenSignInModule,
    TransferHttpCacheModule,
    ThemingModule,
    PuiRootModule,
    IntegrationsModule,
    FeatureFlagsModule.forRoot(() => ({ launchdarklyId: environment.featureFlagServiceId })),
    AlvisModule.forRoot(),
    SessionWidgetRootModule,
    HttpClientModule,
    TranslocoRootModule.forRoot({
      appName: 'awarenow',
      i18nProjectName: location.hostname
    }),
    // CommunityLibraryModule.forRoot(),
    PuiTypographyModule,
    ExternalLinkModule,
    GraphQLModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    ...DEVICE_TYPE_PROVIDERS,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: APP_INITIALIZER,
      useFactory: iconInitialize,
      deps: [IconStore],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      /**
       * Util for init library icons
       * @param initService PuiIconStore
       * @param @optional baseHref  Define custom baseHref
       */
      useFactory: puiIconInitialize,
      deps: [PuiIconStore],
      multi: true
    },
    DatePipe,
    {
      provide: CLIENT_SESSION_ADAPTOR,
      useClass: ClientSessionAdaptorService
    },
    ClientSessionCommunicationsService,
    ClientSessionActionsService
  ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    @Inject(GLOBAL_WINDOW) browserWindow: Window,
    private localeService: LocaleService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService
  ) {
    const platform = isPlatformBrowser(this.platformId) ? 'in the browser' : 'on the server';
    const locale = this.localeService.getLocale();
    console.log(`Running ${platform} with appId=${this.appId}`);
    import(
      /* webpackInclude: /(en|ar|es|fr|it|ru|id)\.mjs$/ */
      `node_modules/@angular/common/locales/${locale.language}.mjs`
    ).then(module => {
      registerLocaleData(module.default);
    });

    if (isPlatformBrowser(platformId)) {
      this.featureFlagService.init$(null, { environmentName: environment.NAME }).subscribe();

      // if environment is production,
      (environment.production
        ? // Then collects user identity for feature flags service,
          this.authService.onAuth().pipe(
            switchMap(user => {
              const newUser = user ? { key: user.id, email: user.email } : null;
              return this.featureFlagService.identify$(newUser, { environmentName: environment.NAME });
            })
          )
        : // Else use the anonymous client
          this.featureFlagService.identify$(null, { environmentName: environment.NAME })
      ).subscribe();
    }
  }
}
