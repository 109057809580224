import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICalendarSource } from '@app/modules/external-calendars-accounts/components/calendar-source-card/calendar-source-card.component';

@Component({
  selector: 'app-source-connection-invitation',
  templateUrl: './source-connection-invitation.component.html',
  styleUrls: ['../../styles/source-card-common.scss', './source-connection-invitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'source-connection-invitation'
  }
})
export class SourceConnectionInvitationComponent {
  @Input()
  // @ts-expect-error TS2564
  source: ICalendarSource;

  @Output()
  connect = new EventEmitter<void>();
}
