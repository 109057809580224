import { NotificationsService } from 'angular2-notifications';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { CurrencyService } from '@app/core/currency/currency.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { UserWalletService } from '@app/core/user-wallet/user-wallet.service';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { PaymentType } from '@app/modules/current-payment/types';
import { IOtherPaymentOptions, OtherPaymentMethods } from '@app/screens/admin/types';
import { ICard, IPaymentMethod, IPaypalAccount } from '@app/screens/client/client-wallet/interfaces/card';
import { ClientWalletService } from '@app/screens/client/client-wallet/services/client-wallet.service';
import { PaymentGateways } from '@app/shared/enums/payment-gateways';
import { PaymentMethods } from '@app/shared/enums/payment-methods';
import { UrlTypes } from '@app/shared/enums/url-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { getUrlType } from '@app/shared/utils/url';
import { GlobalConfig } from '@cnf/types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-pay-with-selector',
  templateUrl: './pay-with-selector.component.html',
  styleUrls: ['./pay-with-selector.component.scss']
})
export class PayWithSelectorComponent implements OnInit, OnDestroy, OnChanges {
  private readonly destroy$ = new Subject<void>();

  readonly paymentGateway;

  readonly platformName: string;

  readonly PaymentGateways = PaymentGateways;

  readonly PaymentMethods = PaymentMethods;

  // @ts-expect-error TS2564
  balance: number | null;

  config = {
    urlCancellationPolicy: '',
    urlPrivacy: '#',
    urlTerms: '#'
  };

  // @ts-expect-error TS2564
  paymentOptions: IOtherPaymentOptions;

  savedCards: IPaymentMethod[] = [];

  savedPaypalAccounts: IPaymentMethod[] = [];

  // @ts-expect-error TS2564
  selectedPaymentMethod: PaymentMethods;

  @Input()
  amount?: number;

  @Input()
  disableNewCardSave = false;

  @Input()
  disableSavedCards = false;

  @Input()
  hideWallet?: boolean;

  @Input()
  hidePaymentLink?: boolean;

  @Input()
  isLoading = false;

  @Input()
  isSubscription = false;

  @Input()
  isPlatformSubscription = false;

  @Input()
  purchaseButtonText = 'Enroll';

  @Input()
  submitButtonText = 'Next';

  @Input()
  disablePayPal = false;

  @Output()
  paymentSelected = new EventEmitter();

  get isCardEnabled(): boolean {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('stripeEnabled');
  }

  get isNegativeBalance(): boolean {
    // @ts-expect-error TS2531
    return this.balance < 0;
  }

  get isOtherPaymentEnabled(): boolean {
    return (
      this.paymentOptions && this.paymentOptions.otherPaymentEnabled && !this.isSubscription && !this.hidePaymentLink
    );
  }

  get isPaymentLinkEnabled(): boolean {
    return (
      this.isOtherPaymentEnabled &&
      this.paymentOptions &&
      this.paymentOptions.paymentMethod === OtherPaymentMethods.PAYMENT_LINK
    );
  }

  get isPaymentRequisitesEnabled(): boolean {
    return (
      this.isOtherPaymentEnabled &&
      this.paymentOptions &&
      this.paymentOptions.paymentMethod === OtherPaymentMethods.PAYMENT_REQUISITES
    );
  }

  get isPaypalEnabled(): boolean {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('paypalEnabled') && !this.isSubscription && !this.disablePayPal;
  }

  get savedPaymentMethods(): IPaymentMethod[] {
    return [...this.savedCards, ...this.savedPaypalAccounts];
  }

  get showWallet(): boolean {
    // @ts-expect-error TS2322
    return !this.hideWallet && this.balance && this.amount && this.balance >= this.amount;
  }

  get hasTrial(): boolean {
    return this._authService.user.membership && this._authService.user.membership.isTrial;
  }

  constructor(
    private readonly _auth: AuthService,
    private readonly _brandingService: BrandingService,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _clientWalletService: ClientWalletService,
    private readonly _userWalletService: UserWalletService,
    private readonly _notificationService: NotificationsService,
    private readonly _authService: AuthService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    private readonly _currentPaymentService: CurrentPaymentService,
    readonly currency: CurrencyService
  ) {
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
    this.paymentGateway = this._runtimeConfigService.get('paymentGateway');
  }

  ngOnInit(): void {
    this._loadClientSavedPaymentMethods();
    this._loadClientWallet();

    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this._setConfig(config));

    this._brandingService.otherPaymentOptions$.pipe(takeUntil(this.destroy$)).subscribe(paymentOptions => {
      if (paymentOptions) {
        const paymentLinkType = getUrlType(paymentOptions.paymentLink);

        const link =
          paymentLinkType && paymentLinkType === UrlTypes.RELATIVE
            ? `http://${paymentOptions.paymentLink}`
            : paymentOptions.paymentLink;

        this.paymentOptions = {
          ...paymentOptions,
          ...(link && { paymentLink: link }) // if (link) this.paymentOptions.paymentLink = link
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // @ts-expect-error TS7006
  ngOnChanges(changes): void {
    if (changes.disableSavedCards) {
      if (changes.disableSavedCards.currentValue === false && changes.disableSavedCards.previousValue === true) {
        this._loadClientSavedPaymentMethods();
      }
    }
  }

  handleOtherRadioInputClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.isNegativeBalance) {
      event.preventDefault();
      this._notificationService.alert(`Your balance is negative`);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _loadClientSavedPaymentMethods(): void {
    if (this.disableSavedCards) {
      return;
    }

    this._clientWalletService
      .refresh$(true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {});

    this._clientWalletService
      .cards$()
      .pipe(
        filter(cards => !!cards && !!cards.length),
        takeUntil(this.destroy$)
      )
      .subscribe((cards: ICard[]) => {
        this.savedCards = cards.map(card => ({
          id: card.id,
          label: `XXXX-XXXX-XXXX-${card.number}`,
          isDefault: card.isDefault,
          type: card.type
        }));
        this._changeDetector.detectChanges();
      });

    this._clientWalletService
      .paypalAccounts$()
      .pipe(
        filter(paypalAccounts => !!paypalAccounts && !!paypalAccounts.length),
        takeUntil(this.destroy$)
      )
      .subscribe((paypalAccounts: IPaypalAccount[]) => {
        this.savedPaypalAccounts = paypalAccounts.map(paypalAccount => ({
          id: paypalAccount.id,
          label: paypalAccount.email,
          isDefault: paypalAccount.isDefault,
          type: 'paypal'
        }));
        this._changeDetector.detectChanges();
      });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _loadClientWallet(): void {
    if (this._auth.user.RoleId !== UserRoles.CLIENT) {
      return;
    }

    this._userWalletService.refresh();
    this._userWalletService.wallet$.pipe(takeUntil(this.destroy$)).subscribe(wallet => {
      this.balance = wallet ? wallet.balance : null;
      this._changeDetector.detectChanges();
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _setConfig({ urlPrivacy, urlTerms, urlCancellationPolicy }: GlobalConfig): void {
    this.config.urlTerms = urlTerms;
    this.config.urlPrivacy = urlPrivacy;
    this.config.urlCancellationPolicy = urlCancellationPolicy;
  }

  // @ts-expect-error TS7006
  selectCard(id): void {
    if (this.isLoading) {
      return;
    }
    this._currentPaymentService.createNewPayment(PaymentType.STRIPE);
    // @ts-expect-error TS2531
    this._currentPaymentService.currentPayment.isNewPaymentMethod = false;
    // @ts-expect-error TS2531
    this._currentPaymentService.currentPayment.useCardId = id;
    this.paymentSelected.emit();
  }
}
