import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { convertScheduleItemToIHaveDateRange, convertSessionToIHaveDateRange } from '@app/modules/program/utils';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { IModuleServiceSchedule, ModuleInstructor } from '@app/shared/interfaces/programs/client-programs';
import { SimpleSession } from '@app/shared/interfaces/session';
import { IHaveDateRange } from '@app/shared/utils/date';

// TODO: Check if this is used anywhere, and if not, delete it
@Component({
  selector: 'app-module-group-session',
  templateUrl: './module-group-session.component.html',
  styleUrls: ['./module-group-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-group-session'
  }
})
export class ModuleGroupSessionComponent {
  @Input()
  // @ts-expect-error TS2564
  instructor: ModuleInstructor;

  @Input()
  // @ts-expect-error TS2564
  session: SimpleSession;

  @Input()
  // @ts-expect-error TS2564
  schedule: IModuleServiceSchedule;

  @Output()
  book = new EventEmitter();

  get isBooked(): boolean {
    return (
      this.session != null &&
      [SessionStatuses.APPROVED, SessionStatuses.GUIDE_OFFER, SessionStatuses.PENDING_APPROVEMENT].includes(
        this.session.status
      )
    );
  }

  get scheduleDateTime(): IHaveDateRange | null {
    return this.schedule && this.schedule.dates && this.schedule.dates[0]
      ? convertScheduleItemToIHaveDateRange(this.schedule.dates[0])
      : null;
  }

  get sessionDateTime(): IHaveDateRange | null {
    return this.isBooked ? convertSessionToIHaveDateRange(this.session) : this.scheduleDateTime;
  }
}
