import { ProgramModule } from '../program-module';
import { IBaseProgram, IProgramDetails, ProgramSubscription } from './base-program';
import { EditableProgramSettings, IEditableProgram } from './editable-program';
import { ProgramAccessRoles } from './program-access-roles';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramEditPermissions {
  canEditSettings?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramAuthor {
  name: string;
  id: number;
  permissions: IProgramEditPermissions;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IAuthorProgramDetails extends IProgramDetails {
  readonly settings: EditableProgramSettings;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly questionnaires: any[];
}

export class AuthorProgram implements IBaseProgram {
  readonly id: number;
  readonly modules: ProgramModule[];
  readonly settings: EditableProgramSettings;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly questionnaires: any[];
  readonly subscriptions: ProgramSubscription[];

  get programAccessRole(): ProgramAccessRoles {
    return ProgramAccessRoles.AUTHOR;
  }

  constructor(programDetails: Readonly<IAuthorProgramDetails>) {
    if (!programDetails) {
      throw new Error('Invalid argument');
    }

    this.id = programDetails.id;
    this.modules = programDetails.modules;
    this.settings = programDetails.settings;
    this.questionnaires = programDetails.questionnaires;
    this.subscriptions = programDetails.subscriptions;
  }

  toEditable(): IEditableProgram {
    return {
      id: this.id,
      accessRole: ProgramAccessRoles.AUTHOR,
      modules: this.modules,
      settings: this.settings,
      questionnaires: this.questionnaires,
      subscriptions: this.subscriptions
    };
  }
}
