import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-note-action-modal',
  templateUrl: './note-action-modal.component.html',
  styleUrls: ['./note-action-modal.component.scss', '../../../../../scss/_event-modal.scss']
})
export class NoteActionModalComponent {
  @Input()
  // @ts-expect-error TS2564
  message: string;

  @Input()
  // @ts-expect-error TS2564
  title: string;

  @Input()
  // @ts-expect-error TS2564
  confirmText: string;

  @Input()
  // @ts-expect-error TS2564
  rejectText: string;

  constructor(public modal: NgbActiveModal) {}

  save(): void {
    const response = true;
    this.modal.close(response);
  }

  cancel(): void {
    const response = false;
    this.modal.close(response);
  }
}
