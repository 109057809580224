import { IUser, User } from '@app/shared/interfaces/user';

export enum NotesTypes {
  PINNED = 'pinned',
  STANDARD = 'standard'
}

export enum NotesTypesSortProp {
  PINNED = 'pinnedAt',
  STANDARD = 'updatedAt'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMetaShared {
  user?: IUser;
  userRole?: number;
  accessType?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMetaEventService {
  id: number;
  name?: string;
  serviceType: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMetaEvent {
  id: number;
  service: IMetaEventService;
  start: string;
  end: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMetaGuideClient {
  clientId: number;
  noteId: number;
  createdAt: string;
  updatedAt: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMetaGuideContact {
  contactId: number;
  noteId: number;
  createdAt: string;
  updatedAt: string;
}

export class MetaShared {
  user: User | null;

  userRole: number | null;

  accessType: number | null;

  constructor({ user, userRole, accessType }: IMetaShared) {
    if (user) {
      const { id, firstName, lastName, photo } = user;
      this.user = new User(id, firstName, lastName, photo);
      // @ts-expect-error TS2322
      this.userRole = userRole;
      // @ts-expect-error TS2322
      this.accessType = accessType;
    } else {
      this.user = null;
      this.userRole = null;
      this.accessType = null;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INoteContent {
  content: string;
  contentDeltaFormat: string;
  contentText: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INoteUpdatedContent extends INoteContent {
  updatedAt: string;
  id: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INote extends INoteContent {
  id: number;
  createdAt: string;
  updatedAt: string;
  pinned: boolean;
  pinnedAt: string | null;
  metaSharedWith: IMetaShared[];
  metaSharedBy: IMetaShared;
  metaRecording?: INotesMetaRecording;
  metaEvent?: IMetaEvent;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INoteEditorContent {
  text: string;
  content: string;
  html: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INoteCardUpdateContent extends INoteEditorContent {
  index: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface INotesMetaRecording {
  noteId: number;
  archiveId: number;
  recordingId: string;
  recordingDuration: number;
  createdAt: string;
}
