import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { LocaleService } from '@app/core/locale/locale.service';
import { GuideServiceParent } from '@app/modules/book-service';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { ClientSession } from '@libs/business/client/client-session/types';
import { generateFullZoomUrl } from '@libs/utils/location/location';

@Component({
  selector: 'app-client-session-join-button',
  templateUrl: './client-session-join-button.component.html',
  styleUrls: ['./client-session-join-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionJoinButtonComponent {
  @Input()
  session: ClientSession<GuideServiceParent> | undefined;

  private readonly sessionUrl: string;

  readonly SessionConnectionTypes = SessionConnectionTypes;

  get eventUuid(): string {
    return this.session?.meta?.session?.eventUuid || '';
  }

  get sessionRoomUrl(): string {
    return `${this.sessionUrl}/meeting/${this.eventUuid}`;
  }

  constructor(private readonly localeService: LocaleService, @Inject(GLOBAL_WINDOW) private browserWindow: Window) {
    this.sessionUrl = `${this.localeService.getLocale().baseUrl}`;
  }

  joinSession(): void {
    let url = '';

    switch (this.session?.connectionType) {
      case SessionConnectionTypes.IN_PLATFORM:
        url = this.sessionRoomUrl;
        break;
      case SessionConnectionTypes.ZOOM:
        url = generateFullZoomUrl(this.eventUuid);
        break;
      default:
        url = this.sessionRoomUrl;
        break;
    }

    this.browserWindow.open(url, '_blank');
  }
}
