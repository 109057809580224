import { Component, EventEmitter, Input, Output } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent {
  opened = false;

  @Input()
  // @ts-expect-error TS2564
  filterDirty: boolean;

  @Output()
  apply = new EventEmitter();

  @Output()
  reset = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  applyFilter() {
    this.apply.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetFilter() {
    this.reset.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onStateChange($event: boolean) {
    this.opened = $event;
    if (!$event) {
      this.apply.emit();
    }
  }
}
