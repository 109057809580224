import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StepsHistory } from '@app/modules/service-scheduling/store/service-scheduling/commons/types';
import * as serviceSchedulingActions from '@app/modules/service-scheduling/store/service-scheduling/service-scheduling.actions';
import {
  selectAllClients,
  selectCurrentStep,
  selectStepsHistory
} from '@app/modules/service-scheduling/store/service-scheduling/service-scheduling.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class SchedulingStoreService {
  stepsHistory: StepsHistory = {};
  readonly clients$ = this.store$.select(selectAllClients);
  readonly currentStep$ = this.store$.select(selectCurrentStep);
  readonly stepsHistory$ = this.store$
    .select(selectStepsHistory)
    .pipe(tap(stepsHistory => (this.stepsHistory = stepsHistory)));

  constructor(private readonly store$: Store) {}

  fetchAllServices(): void {
    this.store$.dispatch(serviceSchedulingActions.fetchAllServices());
  }

  setChosenClientsIds(clientsIds: number[]): void {
    this.store$.dispatch(serviceSchedulingActions.setChosenClientsIds({ chosenClientsIds: clientsIds }));
  }
}
