<div class="modal-body">
  <form [formGroup]="form" class="upload-html-form">
    <div class="form-group">
      <label for="html">HTML</label>
      <textarea #htmlArea class="form-control" id="html" formControlName="html" rows="5"></textarea>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="width (i.e. 100% or 500px)" formControlName="width" />
    </div>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="height (i.e. 90px)" formControlName="height" />
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button className="btn-primary" (click)="save()">Insert</app-button>
  <app-button className="btn-lite" (click)="cancel()">Cancel</app-button>
</div>
