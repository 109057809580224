// eslint-disable-next-line max-classes-per-file
import { DateTime } from 'luxon';

import { GuideServiceParent } from '@app/modules/book-service/types';
import { IWorkspace } from '@app/modules/workspaces/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PuiListItemAppearanceType } from '@awarenow/profi-ui-core';

import { CallInitiators } from '../enums/call-initiators';
import { SessionStatuses } from '../enums/session-statuses';
import { SessionTypes } from '../enums/session-types';
import { SessionsTypes } from '../enums/sessions-types';
import { User } from './user';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSession {
  id: number;
  chatId?: string | null;
  client?: IServerSessionUser;
  clientId: number;
  date: string;
  declineData?: ISessionDeclineReason;
  description?: string | null;
  duration: number;
  free?: boolean | null;
  guide?: IServerSessionGuide;
  guideId: number;
  // hash: string;
  isAssessmentAllowed?: boolean | null;
  isRecurring?: boolean | null;
  isZoomPreferable?: boolean | null;
  name?: string;
  note?: string;
  originalDuration?: number | null;
  overtimeCharge?: number | null;
  payRate?: number | null;
  hidePrice?: boolean | null;
  hideAddress?: boolean | null;
  serviceFee?: number | null;
  sessions?: IServerSession[] | null;
  sessionToken: number;
  serviceType: SessionTypes | GuideServiceTypes;
  status: SessionStatuses;
  templateId?: number | null;
  eventId?: number | null;
  eventUuid?: string | null;
  recurringEventId?: string | null;
  zoomMeetings?: {
    startUrl?: string | null;
    joinUrl: string;
    phoneNumbers: { country?: string; number: string }[];
  }[];
  availableSessions?: number;
  template?: { name: string; serviceType: GuideServiceTypes; duration?: number } & { workspace: IWorkspace };
  workspace?: IWorkspace;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerAvailableSession {
  client?: IServerSessionUser;
  clientId: number;
  guide?: IServerSessionGuide;
  guideId: number;
  templateId?: number | null;
  totalSessions: number;
  availableSessions: number;
  template: { name: string; serviceType: GuideServiceTypes; duration?: number } & { workspace: IWorkspace } & {
    sessionType: {
      properties: SessionsTypes;
    };
  } & { restrictClientBooking: boolean };
  serviceParent: GuideServiceParent;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSessionUser {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  lastTimezone?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSessionGuide extends IServerSessionUser {
  workspaceId: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPaymentInfo {
  id: number | string;
  save?: boolean;
  new?: boolean;
  oneTimePayment?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionDeclineReason {
  date: string;
  reason?: string;
}

export class SessionClient extends User {
  readonly archived: boolean;

  readonly contacts?: {
    readonly email?: string;
    readonly phone: string | null;
  };

  // @ts-expect-error TS7006
  constructor(client) {
    // @ts-expect-error TS2345
    super(client.id, client.firstName, client.lastName, client.photo, null, client.lastTimezone);

    if (client.contacts) {
      this.contacts = client.contacts;
    }

    this.archived = client.archived;
  }
}

export class SessionGuide extends User {
  readonly workspaceId: number;

  readonly phoneForSessions: string | null;

  // @ts-expect-error TS7006
  constructor(guide) {
    // @ts-expect-error TS2345
    super(guide.id, guide.firstName, guide.lastName, guide.photo, null);

    this.phoneForSessions = guide.phoneForSessions ?? null;

    this.workspaceId = guide.workspaceId;
    this.workspaceType = guide.workspaceType;
  }
}

export class ZoomMeetingPhoneNumber {
  readonly country?: string | null;

  readonly number: string;

  // @ts-expect-error TS7006
  constructor(phoneNumber) {
    this.number = phoneNumber.number;

    if (phoneNumber.country) {
      this.country = phoneNumber.country;
    }
  }
}

export class ZoomMeeting {
  readonly startUrl?: string | null;

  readonly joinUrl: string;

  // @ts-expect-error TS2564
  readonly phoneNumbers: ZoomMeetingPhoneNumber[];

  // @ts-expect-error TS7006
  constructor(zoomMeeting) {
    this.startUrl = zoomMeeting.startUrl;
    this.joinUrl = zoomMeeting.joinUrl;

    if (zoomMeeting.startUrl) {
      this.startUrl = zoomMeeting.startUrl;
    }

    if (zoomMeeting.phoneNumbers) {
      // @ts-expect-error TS7006
      this.phoneNumbers = zoomMeeting.phoneNumbers.map(phoneNumber => new ZoomMeetingPhoneNumber(phoneNumber));
    }
  }
}

export class BaseSessionDetails {
  readonly id: number;
  readonly afterEventBuffer?: number;
  readonly beforeEventBuffer?: number;
  readonly chatId?: string | null;

  readonly eventId: number;

  readonly eventUuid: string;

  collectionType: SessionsTypes;

  readonly dateStart: string;

  readonly description?: string | null;

  readonly descriptionText?: string | null;

  readonly duration: number;

  readonly guideId: number;

  readonly guide?: IServerSessionGuide;

  readonly guests?: { id: number; email: string }[];

  readonly disableGuests?: boolean;

  readonly isSessionFree: boolean;

  readonly isRecurring?: boolean | null;

  readonly isPastSession?: boolean | null;

  readonly isZoomPreferable?: boolean | null;

  readonly name?: string | null;

  readonly originalDuration?: number | null;

  readonly overtimeCharge?: number | null;

  readonly payRate?: number | null;

  readonly hidePrice?: number;

  readonly hideAddress?: boolean;

  readonly seatsPerTimeSlot?: number | null;

  readonly serviceFee?: number | null; // NOTE: better remove, payRate / price should already be calculated (on server)

  readonly sessionToken: number;

  readonly serviceType: SessionTypes;

  status: SessionStatuses;

  readonly zoomMeetings?: ZoomMeeting[];

  readonly serviceParent?: GuideServiceParent;

  readonly participantsLimit: number | null;

  readonly currentOccupancyRate: number | null;

  readonly archives?: string[];

  readonly isPaymentRequired: boolean;

  readonly scheduleId?: number | null;

  readonly address: string | null;

  readonly connectionType: SessionConnectionTypes;

  readonly callInitiator: CallInitiators;

  readonly phoneForSessions: string | null;

  readonly restrictClientBooking: boolean;

  readonly hasAutoConfirmation?: boolean;

  readonly recurringEventId?: string;
  readonly recurringSessionsDetails?: readonly {
    readonly id: number;
    readonly dateStart: string;
    readonly duration: number;
    readonly status: SessionStatuses;
  }[];

  readonly sessionType: SessionsTypes;

  get isRecurrent(): boolean {
    return (
      !!this.recurringEventId && !!this.recurringSessionsDetails?.length && this.recurringSessionsDetails.length > 1
    );
  }

  get isSeatsPerTimeSlot(): boolean {
    return !!this.seatsPerTimeSlot;
  }

  // @ts-expect-error TS7006
  constructor(baseSessionDetails) {
    this.id = baseSessionDetails.id;
    this.beforeEventBuffer = baseSessionDetails.beforeEventBuffer || 0;
    this.afterEventBuffer = baseSessionDetails.afterEventBuffer || 0;
    this.collectionType = baseSessionDetails.collectionType;
    this.dateStart = baseSessionDetails.dateStart;
    this.duration = baseSessionDetails.duration;
    this.guideId = baseSessionDetails.guideId;
    this.recurringEventId = baseSessionDetails.recurringEventId;
    this.guide = baseSessionDetails.guide;
    this.guests = baseSessionDetails.guests;
    this.disableGuests = baseSessionDetails.disableGuests;
    this.isSessionFree = baseSessionDetails.isSessionFree;
    this.sessionToken = baseSessionDetails.sessionToken;
    this.serviceType = baseSessionDetails.serviceType;
    this.status = baseSessionDetails.status;
    this.seatsPerTimeSlot = baseSessionDetails.seatsPerTimeSlot;
    this.serviceParent = baseSessionDetails.serviceParent;
    this.eventId = baseSessionDetails.eventId;
    this.eventUuid = baseSessionDetails.eventUuid;
    this.participantsLimit = baseSessionDetails.participantsLimit;
    this.currentOccupancyRate = baseSessionDetails.currentOccupancyRate;
    this.connectionType = baseSessionDetails.connectionType;
    this.address = baseSessionDetails.address;
    this.phoneForSessions = baseSessionDetails.phoneForSessions;
    this.callInitiator = baseSessionDetails.callInitiator;
    this.restrictClientBooking = baseSessionDetails.restrictClientBooking;
    this.hasAutoConfirmation = baseSessionDetails.hasAutoConfirmation;
    this.sessionType = baseSessionDetails.sessionType;
    this.isPaymentRequired = baseSessionDetails.isPaymentRequired;

    [
      'chatId',
      'description',
      'isRecurring',
      'isZoomPreferable',
      'name',
      'originalDuration',
      'overtimeCharge',
      'payRate',
      'serviceFee',
      'hidePrice',
      'hideAddress',
      'scheduleId'
    ].forEach(prop => {
      if (baseSessionDetails[prop] !== undefined) {
        // @ts-expect-error TS7053
        this[prop] = baseSessionDetails[prop];
      }
    });

    if (baseSessionDetails.zoomMeetings) {
      // @ts-expect-error TS7006
      this.zoomMeetings = baseSessionDetails.zoomMeetings.map(meeting => new ZoomMeeting(meeting));
    }

    this.isPastSession =
      DateTime.fromISO(this.dateStart).plus({ minutes: this.duration }).diffNow('milliseconds').milliseconds < 0;

    if (baseSessionDetails.archives && baseSessionDetails.archives.length) {
      this.archives = baseSessionDetails.archives;
    }
  }
}

// ToDo Split SimpleSession -> (ClientSession | GuideSession)
export class SimpleSession extends BaseSessionDetails {
  readonly clientId: number;

  declineReason?: ISessionDeclineReason | null;

  disableGuests?: boolean;

  declineData?: ISessionDeclineReason;

  isAssessmentAllowed?: boolean; // NOTE: for clients only

  note?: string | null;

  templateId?: number | null;

  user: SessionClient | SessionGuide;

  seatsPerTimeSlot!: number | null;

  client: SessionClient | null;

  readonly clients!: SessionClient[] | null;

  // @ts-expect-error TS2416
  guide!: (SessionGuide & { namedUrl?: string }) | null;

  missed?: boolean;

  hasChat?: boolean;

  // @ts-expect-error TS2564
  workspace: IWorkspace;

  expiresAt?: string;

  name?: string;

  hasAutoConfirmation?: boolean;

  paymentFailed?: boolean;

  get isMissedSession(): boolean {
    return this.missed === true;
  }

  get isExpired(): boolean {
    return this.expiresAt ? DateTime.fromISO(this.expiresAt).toMillis() - DateTime.local().toMillis() <= 0 : false;
  }

  get isOfflineConnectionType(): boolean {
    return [SessionConnectionTypes.PHONE, SessionConnectionTypes.IN_PERSON].includes(this.connectionType);
  }

  // @ts-expect-error TS7006
  constructor(session) {
    super(session);

    this.clientId = session.clientId;

    this.user = session.user;
    this.client = session.client;
    this.guide = session.guide;
    this.declineData = session.declineData;

    [
      'declineReason',
      'disableGuests',
      'isAssessmentAllowed',
      'note',
      'templateId',
      'missed',
      'hasChat',
      'expiresAt',
      'name',
      'recurringEventId',
      'recurringSessionsDetails',
      'callInitiator'
    ].forEach(prop => {
      if (session[prop] !== undefined) {
        // @ts-expect-error TS7053
        this[prop] = session[prop];
      }
    });
  }
}

export class GroupSession extends BaseSessionDetails {
  sessions?: SimpleSession[];

  expiresAt?: string;

  disableGuests?: boolean;

  name?: string;

  templateId?: number | null;

  seatsPerTimeSlot!: number | null;

  client: SessionClient | null;

  // @ts-expect-error TS2416
  guide: SessionGuide | null;

  get isExpired(): boolean {
    return this.expiresAt ? DateTime.fromISO(this.expiresAt).toMillis() - DateTime.local().toMillis() <= 0 : false;
  }

  // @ts-expect-error TS7006
  constructor(session) {
    super(session);

    if (session.sessions) {
      // @ts-expect-error TS7006
      this.sessions = session.sessions.map(simpleSession => new SimpleSession(simpleSession));
    }

    this.client = session.client;
    this.guide = session.guide;

    ['expiresAt', 'name', 'serviceType', 'recurringEventId', 'recurringSessionsDetails'].forEach(prop => {
      if (session[prop] !== undefined) {
        // @ts-expect-error TS7053
        this[prop] = session[prop];
      }
    });
  }
}

export class AvailableSession {
  // @ts-expect-error TS2564
  id: number;

  // @ts-expect-error TS2564
  user: User;

  // @ts-expect-error TS2564
  clientId: number;

  guide?: IServerSessionGuide;

  // @ts-expect-error TS2564
  guideId: number;

  // @ts-expect-error TS2564
  templateId: number | null;

  duration?: number | null;

  expiresAt?: string;

  // @ts-expect-error TS2564
  serviceParent: GuideServiceParent;

  // @ts-expect-error TS2564
  workspace: IWorkspace;

  // @ts-expect-error TS2564
  name: string;

  // @ts-expect-error TS2564
  serviceType: GuideServiceTypes;

  // @ts-expect-error TS2564
  totalSessions: number;

  // @ts-expect-error TS2564
  availableSessions: number;

  sessionType!: SessionsTypes;

  connectionType!: SessionConnectionTypes;

  // @ts-expect-error TS2564
  session: { id: number };

  restrictClientBooking: boolean | undefined;

  // @ts-expect-error TS7006
  constructor(sessionDetails) {
    [
      'id',
      'user',
      'clientId',
      'guide',
      'guideId',
      'templateId',
      'serviceParent',
      'name',
      'expiresAt',
      'serviceType',
      'workspace',
      'duration',
      'totalSessions',
      'availableSessions',
      'client',
      'session',
      'sessionType',
      'restrictClientBooking'
    ].forEach(prop => {
      if (sessionDetails[prop] !== undefined) {
        // @ts-expect-error TS7053
        this[prop] = sessionDetails[prop];
      }
    });
  }

  get isExpired(): boolean {
    return this.expiresAt ? DateTime.fromISO(this.expiresAt).toMillis() - DateTime.local().toMillis() <= 0 : false;
  }
}

export type Session = SimpleSession | GroupSession;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSimpleSession(session: any): session is SimpleSession {
  return session instanceof SimpleSession;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isGroupSession(session: any): session is GroupSession {
  return session instanceof GroupSession;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAvailableSession(session: any): session is AvailableSession {
  return session instanceof AvailableSession;
}

export type CommunicationPanelType = 'regular' | 'zoom';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientSessionActionsMenuItem {
  title: string;
  className?: string;
  action: string;
  icon: string;
  appearance?: PuiListItemAppearanceType;
}
