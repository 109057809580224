<ng-container *ngIf="profile">
  <div *ngIf="profile.id || profile.preview" class="row no-gutters wrapper-row">
    <div class="col-lg main-col">
      <div class="title-block">
        <pui-avatar class="user-photo" [avatarUrl]="profile.photo"></pui-avatar>
        <div
          class="photo-wrapper"
          [style.backgroundImage]="profile.fullPhoto ? 'url(' + profile.fullPhoto + ')' : null"></div>
        <div class="specialization-wrapper d-flex align-items-center">
          <div class="flex-col">
            <div class="name" pui-typography="h1">{{ profile.firstName }} {{ profile.lastName }}</div>
            <div class="specialization" pui-typography="h4">
              {{ profile.specialization }}
            </div>
          </div>
          <!-- eslint-disable -->

          <div
            class="rating-wrapper d-flex flex-column ml-auto"
            *ngIf="!config.hideCoachReviews && profile.rateByFeedbacks != null"
            (click)="scrollToReviewsBlock()">
            <star-rating
              data-qa-id="reviews-star-rating"
              [starType]="'svg'"
              [rating]="profile.rateByFeedbacks || 0"
              readOnly="true"></star-rating>
            <a data-qa-id="reviews-count-label" class="reviews" href="javascript:;">
              {{ profile.reviewsCount || 0 }}&ngsp;
              <ng-container [ngPlural]="profile.reviewsCount">
                <ng-template i18n="@@review:one" ngPluralCase="=1">review</ng-template>
                <ng-template i18n="@@review:many" ngPluralCase="other">reviews</ng-template>
              </ng-container>
            </a>
          </div>

          <!-- eslint-enable -->
        </div>
        <hr />
        <ng-container *ngIf="profile.tags && profile.tags.length && !config.hideProfileTags">
          <div class="preferences">
            <h5>
              <ng-container i18n="@@publicProfileIHelpWith">I help with</ng-container>
              :
            </h5>
            <ul>
              <li *ngFor="let tag of profile.tags" [ngClass]="{ promo: tag.isPromo }">
                <span [ngbTooltip]="tag.description">{{ tag.name }}</span>
              </li>
            </ul>
          </div>
          <hr />
        </ng-container>
      </div>

      <div class="description-block">
        <h3 *ngIf="profile.videoUrl" i18n="@@moreAboutMe">More about me</h3>
        <div *ngIf="profile.videoUrl" class="video-wrapper">
          <iframe
            title=""
            width="560"
            height="315"
            data-qa-id="public-profile-more-about-me-video"
            [src]="profile.videoUrl | embedVideo: false | safe"
            allow="autoplay; encrypted-media"
            allowfullscreen></iframe>
        </div>
        <div *ngIf="profile.shortQuote" data-qa-id="public-profile-short-quote-title" class="inspiration">
          {{ profile.shortQuote }}
        </div>
        <div *ngIf="profile.salesText && !config.hideProfileSalesText" class="description-section">
          <h3 data-qa-id="public-profile-about-title"><span i18n="@@about">About</span></h3>
          <div data-qa-id="public-profile-about-text">{{ profile.salesText }}</div>
        </div>
        <hr *ngIf="profile.videoUrl || profile.shortQuote || profile.aboutMe" />

        <div
          *ngIf="(profile.occupation || profile.language) && !config.hideProfileLocationLanguage"
          class="description-section location-language-block">
          <h3 data-qa-id="public-profile-location-and-language-title">
            <span i18n="@@locationAndLanguage">Location and language</span>
            :
          </h3>
          <div collapsible-content>
            <div class="row">
              <div *ngIf="profile.timezone" class="col-md">
                <ul>
                  <li>
                    <label data-qa-id="public-profile-time-zone-label" i18n="@@timezone">Time zone</label>
                    <span data-qa-id="public-profile-time-zone-text">{{
                      profile.timezone | replace: '_':' ':'g'
                    }}</span>
                  </li>
                </ul>
              </div>
              <div *ngIf="profile.language" class="col-md">
                <ul>
                  <li>
                    <label data-qa-id="public-profile-language-label" i18n="@@language">Language</label>
                    <span data-qa-id="public-profile-language-text">
                      {{ profile.language + (profile.secondaryLanguage ? ', ' + profile.secondaryLanguage : '') }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="profile.occupation || profile.language" />

        <div *ngIf="profile.ageOfClients || profile.practicesApproach" class="description-section focus-info-block">
          <h3><span>Client Focus info:</span></h3>
          <div collapsible-content>
            <div class="row">
              <div class="col-md">
                <ul>
                  <li *ngIf="profile.ageOfClients">
                    <label i18n="@@clientsAge">Age of clients</label>
                    <span>{{ profile.ageOfClients }}</span>
                  </li>
                  <li>
                    <label i18n="@@specialCategories">Special Categories</label>
                    <span>{{ 'TODO' }}</span>
                  </li>
                </ul>
              </div>
              <div *ngIf="profile.practicesApproach" class="col-md">
                <ul>
                  <li>
                    <label i18n="@@practicesApproach">Practices Approach</label>
                    <span>{{ profile.practicesApproach }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="profile.ageOfClients || profile.practicesApproach" />

        <div
          *ngIf="
            (profile.yearsInPractice ||
              profile.school ||
              profile.yearGraduated ||
              profile.licenseNoAndState ||
              (profile.certificates && profile.certificates.length)) &&
            !config.hideProfileQualifications
          "
          class="description-section qualifications-block">
          <h3>
            <span i18n="@@qualifications">Qualifications</span>
          </h3>
          <div collapsible-content>
            <ul>
              <li *ngIf="profile.yearsInPractice">
                <label>
                  <ng-container i18n="@@yearsInPractice">Years in practice</ng-container>
                  :
                </label>
                <span data-qa-id="public-profile-years-in-practice-text">{{ profile.yearsInPractice }}</span>
              </li>
              <li *ngIf="profile.school">
                <label>
                  <ng-container i18n="@@school">School</ng-container>
                  :
                </label>
                <span data-qa-id="public-profile-school-text">{{ profile.school }}</span>
              </li>
              <li *ngIf="profile.yearGraduated">
                <label>
                  <ng-container i18n="@@yearGraduated">Year graduated</ng-container>
                  :
                </label>
                <span data-qa-id="public-profile-year-graduated-text">{{ profile.yearGraduated }}</span>
              </li>
              <li *ngIf="profile.licenseNoAndState">
                <label>
                  <ng-container i18n="@@licenseNo">License No. and state</ng-container>
                  :
                </label>
                <span data-qa-id="public-profile-license-no-and-state-text">{{ profile.licenseNoAndState }}</span>
              </li>
              <li *ngIf="profile.certificates && profile.certificates.length">
                <label>
                  <ng-container i18n="@@diplomasAndOther">Diplomas, certificates, licenses</ng-container>
                  :
                </label>
                <span *ngFor="let cert of profile.certificates">
                  <br />
                  <a
                    class="document"
                    [href]="cert.url"
                    [title]="cert.name"
                    [attr.data-qa-id]="'public-profile-certificate-name-label-' + cert.name">
                    {{ cert.name }}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <hr *ngIf="blog && blog.items.length && hasBlogTypeArticle" />
      </div>
    </div>

    <div class="col-lg book-col">
      <div
        class="book-block"
        [class.free-session]="profile.freeFirstSession"
        [class.no-price]="profile.hidePricePerHour">
        <ng-content></ng-content>
      </div>

      <ng-container
        *ngIf="!config.hideCoachReviews && ((profile.reviews && profile.reviews.length) || reviewReadySessionId)">
        <hr />
        <div class="reviews-block" #reviewsBlock>
          <h3 i18n="@@reviewsTitle">Reviews</h3>

          <div class="scroll-wrapper" [ngStyle]="{ 'min-height': mobile ? '0' : '' }">
            <ng-scrollbar
              [ngStyle]="{ height: 'auto', position: mobile ? 'static' : '' }"
              [visibility]="'hover'"
              [appearance]="'compact'"
              [disabled]="mobile"
              [autoHeightDisabled]="false">
              <app-public-profile-reviews
                [reviews]="profile.reviews"
                [hasMoreReviews]="profile.hasMoreReviews"
                [reviewReadySessionId]="reviewReadySessionId"
                [mobile]="mobile"
                (loadMoreReviews)="loadMoreReviews.emit()"
                (leaveReview)="emitLeaveReview()"></app-public-profile-reviews>
            </ng-scrollbar>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="blog" *ngIf="blog && blog.items.length && hasBlogTypeArticle">
      <div class="row">
        <div class="col-12">
          <h3>
            <span i18n="@@myBlog">My blog</span>
          </h3>
        </div>

        <ng-container *ngFor="let item of blog.items" [ngSwitch]="item.type">
          <div class="col-md-6 col-lg-4" *ngSwitchCase="itemTypes.ARTICLE">
            <app-blog-item-article
              [article]="item"
              (select)="openBlogItem.emit($event)"
              [hideAuthor]="true"
              class="blog-item article-item"></app-blog-item-article>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
