import { Observable } from 'rxjs';
import { filter, map, mapTo, switchMap, take, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { SocketService } from '@app/core/socket/socket.service';
import { ServiceSchedulingService } from '@app/modules/service-scheduling/services/service-scheduling.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { MemberRoleEnum } from '@app/modules/workspaces/types';
import {
  ClientModuleProgress,
  GroupSessionModuleProgress,
  GuideClientProgram,
  isQuizModuleProgress,
  isUnansweredQuestionProgress,
  LocalProgramService,
  ProgramService,
  SessionModuleProgress
} from '@app/screens/guide/guide-programs/types';
import { GuideCancelDrawerService } from '@app/screens/guide/guide-sessions/components/drawers/guide-cancel-drawer/guide-cancel-drawer.service';
import { GuideSessionsService } from '@app/screens/guide/guide-sessions/services/events';
import { isActiveStatus, isOffer, SessionStatuses } from '@app/shared/enums/session-statuses';
import { ModuleCompletionTypes, ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { SimpleSession } from '@app/shared/interfaces/session';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { SessionsService } from '@app/core/session/sessions.service';
import { GuideProgramStateService } from '@app/screens/guide/guide-programs/services/guide-program-state.service';
import { ProgramSubscription } from '@app/screens/guide/guide-programs/types/program/base-program';
import produce from 'immer';
import { DateTime } from 'luxon';
import { GuideProgramClientsService } from '@app/screens/guide/guide-programs/services/guide-program-clients.service';

@Component({
  selector: 'app-client-progress',
  templateUrl: './client-progress.component.html',
  styleUrls: ['./client-progress.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientProgressComponent implements OnInit {
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _client;
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _clientSessionsSummary;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _modules: ClientModuleProgress[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _modulesAnswers: { [key: string]: boolean } = {};
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _openModulesCount: number | null;
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _quizzesSummary;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _services: LocalProgramService[] = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _servicesMap: { [serviceId: number]: ProgramService } = {};
  private disableButton = false;

  readonly QuizQuestionType = QuizQuestionType;

  guideRoute = environment.guideRoute;

  @Input()
  set client(value) {
    this._client = value;
    this.setClientSessionsSummary();
  }

  @Input()
  programId!: number;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programSessionsInfo!: any[];

  @Input()
  progress!: number | null;

  @Input()
  set modules(value: ClientModuleProgress[]) {
    if (!value) {
      return;
    }

    this._modules = value;
    const modulesAnswers: { [key: string]: boolean } = {};
    value.map(module => {
      if ('quiz' in module) {
        module.quiz?.questions.map(question => {
          if ('answers' in question) {
            modulesAnswers[module.id + ''] = true;
          }
        });
      }
    });
    this._modulesAnswers = modulesAnswers;
    this.setOpenModulesCount();
    this.setQuizzesSummary();
  }

  @Input()
  set services(value: LocalProgramService[]) {
    this._services = value;
    this._servicesMap = (value || []).reduce(
      (dict, { programService: service }) => ({ ...dict, [service.id]: service }),
      {}
    );
  }

  @Output()
  completeModule = new EventEmitter<{ clientId: number; moduleId: number }>();

  @Output()
  downloadClientModuleAnswers = new EventEmitter<{ moduleId: number }>();

  @Output()
  readonly update = new EventEmitter<void>();

  openQuizzes = {};
  quizzesGrades = {};

  readonly ModuleCompletionTypes = ModuleCompletionTypes;
  readonly ModuleTypes = ModuleTypes;

  /**
   * Handle sessions status of session change for client.
   */
  readonly onClientSessionChanged$ = this.socketService.onSessionChanged().pipe(
    switchMap(event => this.sessions.session$(event.id)),
    filter(session => session?.client?.id === this.client?.id)
  );

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/explicit-function-return-type
  get client() {
    return this._client;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get modules(): ClientModuleProgress[] {
    return this._modules;
  }

  get modulesAnswers(): { [key: string]: boolean } {
    return this._modulesAnswers;
  }

  get openModulesCount(): number | null {
    return this._openModulesCount;
  }

  get isTeamMember(): boolean {
    return this.workspacesService.workspace.role === MemberRoleEnum.MEMBER;
  }

  get teamMemberId(): number {
    return this.workspacesService.workspace.guideId;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get quizzesSummary() {
    return this._quizzesSummary;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get services(): LocalProgramService[] {
    return this._services;
  }

  constructor(
    private readonly serviceSchedulingService: ServiceSchedulingService,
    private readonly guideSessionsService: GuideSessionsService,
    private readonly workspacesService: WorkspacesService,
    @Inject(PuiDestroyService) private destroy$: Observable<void>,
    private readonly guideCancelDrawerService: GuideCancelDrawerService,
    private socketService: SocketService,
    private readonly sessions: SessionsService,
    private readonly programState: GuideProgramStateService,
    private readonly clientsService: GuideProgramClientsService
  ) {}

  ngOnInit(): void {
    this.onClientSessionChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateProgress();
    });
  }

  isQuizModule(module: ClientModuleProgress): boolean {
    return isQuizModuleProgress(module);
  }

  toggleQuiz(id: number): void {
    // @ts-expect-error TS7053
    if (this.openQuizzes[id]) {
      // @ts-expect-error TS7053
      delete this.openQuizzes[id];
    } else {
      // @ts-expect-error TS7053
      this.openQuizzes[id] = 1;
    }
  }

  private setClientSessionsSummary(): void {
    if (!this._client.events || !this.programSessionsInfo) {
      this._clientSessionsSummary = null;
      return;
    }

    const totalProgramSessions = this.programSessionsInfo.reduce((sum, sessionType) => sum + sessionType.count, 0);

    if (!totalProgramSessions) {
      this._clientSessionsSummary = null;
      return;
    }

    const clientSessionsLeft = Object.keys(this._client.events).reduce(
      (sum, sessionType) => sum + (this._client.events[sessionType] || 0),
      0
    );

    this._clientSessionsSummary = { total: totalProgramSessions, left: clientSessionsLeft };
  }

  private setOpenModulesCount(): void {
    if (this._modules) {
      this._openModulesCount = this._modules.reduce((acc, module) => acc + (module.status === 'seen' ? 1 : 0), 0);
    } else {
      this._openModulesCount = null;
    }
  }

  private setQuizzesSummary(): void {
    if (!this._modules || !this._modules.length) {
      this._quizzesSummary = null;
      return;
    }

    const quizzesSummary = this._modules.reduce(
      (sum, module) => {
        if (isQuizModuleProgress(module)) {
          sum.total += 1;

          const { id, quiz } = module;

          if (quiz) {
            const { questions, status } = quiz;
            const filteredQuestions = questions.filter(question => question.questionType !== QuizQuestionType.TEXT);

            if (!filteredQuestions.length) {
              // @ts-expect-error TS7053
              this.quizzesGrades[id] = 1;
            } else {
              const answersCount = filteredQuestions.reduce((count, question) => {
                if (isUnansweredQuestionProgress(question)) {
                  return 0;
                }

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { answers } = question as any;

                return count + (answers && answers.length ? 1 : 0);
              }, 0);

              // @ts-expect-error TS7053
              this.quizzesGrades[id] = answersCount / filteredQuestions.length;
            }

            if (status === 'completed' || !filteredQuestions.length) {
              sum.finished += 1;
            }
          }
        }

        return sum;
      },
      {
        total: 0,
        finished: 0
      }
    );

    if (quizzesSummary.total) {
      this._quizzesSummary = quizzesSummary;
    }
  }

  scheduleSession(module: GroupSessionModuleProgress | SessionModuleProgress): void {
    if (this.disableButton) {
      return;
    }

    this.serviceSchedulingService
      .scheduleSessionOfProgram(this.programId, module.serviceId as number, [this.client?.id as number])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.disableButton = false;

        this.serviceSchedulingService.afterClose$
          ?.pipe(take(1))
          // eslint-disable-next-line rxjs-angular/prefer-takeuntil,rxjs/no-nested-subscribe
          .subscribe(() => {
            this.updateProgress();
          });
      });
  }

  cancelSession(module: GroupSessionModuleProgress | SessionModuleProgress, client?: GuideClientProgram): void {
    const session = module?.session;

    if (isOffer(session?.status!)) {
      this.guideSessionsService.decline(
        session!,
        true,
        () => {
          this.updateProgress();
        },
        {
          clientName: client?.name,
          date: session?.dateStart
        }
      );
    } else {
      this.guideCancelDrawerService
        .openCancelConformation$(session!)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => {
          this.updateProgress();
        });
    }
  }

  rescheduleSession(module: GroupSessionModuleProgress | SessionModuleProgress): void {
    this.guideSessionsService.reschedule(
      new SimpleSession({
        ...module?.session!,
        templateId: module.sessionTemplate?.id,
        guideId: module.guideId,
        eventId: module.eventId
      }),
      true,
      false
    );
  }

  canShowSessionDate(status: SessionStatuses | undefined): boolean {
    return status ? isActiveStatus(status) : false;
  }

  canScheduleSession(module: GroupSessionModuleProgress | SessionModuleProgress): boolean {
    if (this.isTeamMember && !module.sessionTemplate?.team?.map(tm => tm.guideId).includes(this.teamMemberId)) {
      return false;
    }

    return module.session?.status
      ? !isActiveStatus(module.session?.status) && !this.isSessionDone(module.session?.status)
      : true;
  }

  canCancelSession(module: GroupSessionModuleProgress | SessionModuleProgress): boolean {
    if (this.isTeamMember && !module.sessionTemplate?.team?.map(tm => tm.guideId).includes(this.teamMemberId)) {
      return false;
    }

    return module.session?.status
      ? isActiveStatus(module.session?.status) && !this.isSessionDone(module.session?.status)
      : false;
  }

  canRescheduleSession(module: GroupSessionModuleProgress | SessionModuleProgress): boolean {
    const isGroupSession = (module?.sessionTemplate?.seatsPerTimeSlot || 0) > 0;

    if (
      (this.isTeamMember && !module.sessionTemplate?.team?.map(tm => tm.guideId).includes(this.teamMemberId)) ||
      isGroupSession
    ) {
      return false;
    }

    return module.session?.status
      ? isActiveStatus(module.session?.status) && !this.isSessionDone(module.session?.status)
      : false;
  }

  isSessionDone(status: SessionStatuses | undefined): boolean {
    return status === SessionStatuses.DONE;
  }

  updateProgress(): void {
    this.update.emit();
  }

  enrolled$(clientId: number): Observable<string | undefined> {
    return this.clientsService.clients$.pipe(
      map(clients => clients.find(client => client.id === clientId)),
      map(client => client?.enrolledAt)
    );
  }

  nextPayment$(clientId: number): Observable<string> {
    return this.subscription$(clientId).pipe(map(({ subscription }) => subscription.currentPeriodEnd));
  }

  setCurrentPeriodEndState(clientId: number, date: Date): void {
    this.programState.updateProgramState(program =>
      produce(program, draft => {
        const index = draft.subscriptions.findIndex(subscription => subscription.customerId === clientId);

        if (draft.subscriptions[index]) {
          draft.subscriptions[index].subscription.currentPeriodEnd = DateTime.fromJSDate(date).toISO();
        }
      })
    );
  }

  private subscription$(clientId: number): Observable<ProgramSubscription> {
    return this.programState.settings$.pipe(
      filter(Boolean),
      mapTo(this.programState.program.subscriptions),
      map(subscriptions => subscriptions.find(subscription => subscription.customerId === clientId)),
      filter<ProgramSubscription>(Boolean)
    );
  }
}
