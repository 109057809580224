import { ResolverOptions } from '../../model/interfaces';
import datetime from '../datetime';
import { getDefaultLocale } from '../get-defaults';
import toFormat from './fragment-to-format';

export default function toDateTime(fragment: string, params: ResolverOptions): string {
  const format = toFormat(fragment, params);
  const locale =
    (params.templateOptions &&
      params.templateOptions.localeParams &&
      params.templateOptions.localeParams.dateTimeLocale) ||
    getDefaultLocale();

  const options = {
    ...(params.templateOptions || {}),
    locale
  };

  let result = datetime(params.value.valueOf(), format, options);
  if (fragment === 'time') {
    result = result.toLowerCase();
  }

  return result;
}
