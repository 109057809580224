// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IFAQuestion {
  id?: number;
  localId: number;
  question: string | null;
  answer: string | null;
}

export class FAQuestion {
  id?: number;
  localId: number;
  question: string | null;
  answer: string | null;

  constructor() {
    this.localId = new Date().getTime();
    this.question = null;
    this.answer = null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  static clean() {
    //
  }

  clone(): FAQuestion {
    const clone = new FAQuestion();

    clone.id = this.id;
    clone.localId = this.localId;
    clone.question = this.question;
    clone.answer = this.answer;

    return clone;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getDiffFrom(newFAQuestion: IFAQuestion) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const diff: any = { id: this.id };

    ['question', 'answer'].forEach(prop => {
      // @ts-expect-error TS7053
      if (this[prop] !== newFAQuestion[prop]) {
        // @ts-expect-error TS7053
        diff[prop] = newFAQuestion[prop];
      }
    });

    return Object.keys(diff).length > 1 ? diff : null;
  }

  setId(id: number): FAQuestion {
    this.id = id;
    this.localId = id;

    return this;
  }

  setValues(faQuestion: IFAQuestion): FAQuestion {
    this.id = faQuestion.id;
    this.question = faQuestion.question;
    this.answer = faQuestion.answer;

    if (faQuestion.localId) {
      this.localId = faQuestion.localId;
    }

    return this;
  }

  update(faQuestion: IFAQuestion): FAQuestion {
    if (faQuestion.id) {
      this.id = faQuestion.id;
    }

    if (faQuestion.localId) {
      this.localId = faQuestion.localId;
    }

    this.question = faQuestion.question;
    this.answer = faQuestion.answer;

    return this;
  }
}
