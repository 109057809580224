import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DisabledReason, SoundType, activeSessionDisabledSounds } from '@app/core/sound/types';
import { LogService } from '@app/core/log/log.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  static DISABLE_NOTIFICATIONS = 'DISABLE_NOTIFICATIONS';
  private isBrowser: boolean;
  private audios: Record<string, HTMLAudioElement> = {};
  private audioFiles: Record<SoundType, string> = {
    [SoundType.CALL]: 'assets/audio/call.mp3',
    [SoundType.CONNECT]: 'assets/audio/connect.mp3',
    [SoundType.DISCONNECT]: 'assets/audio/disconnect.mp3',
    [SoundType.ENDING_SESSION]: 'assets/audio/ending-session.mp3',
    [SoundType.MESSAGE]: 'assets/audio/message.mp3',
    [SoundType.NEW_REQUEST]: 'assets/audio/new-request.mp3',
    [SoundType.NOTIFICATION]: 'assets/audio/notification.mp3'
  };

  constructor(
    private logService: LogService,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    private localStorage: LocalStorageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.initAudios();
    }
  }

  private initAudios(): void {
    Object.keys(this.audioFiles).forEach((soundType: SoundType) => {
      this.setAudio(soundType, this.audioFiles[soundType]);
    });

    this.audios[SoundType.CALL].loop = true;
  }

  private setAudio(soundType: SoundType, file: string): void {
    const audio = new Audio(file);
    audio.preload = 'auto';
    this.audios[soundType] = audio;
  }

  private getAudio(soundType: SoundType): HTMLAudioElement {
    return this.audios[soundType];
  }

  playSound(soundType: SoundType): void {
    const disabledReason = this.localStorage.getItem(SoundService.DISABLE_NOTIFICATIONS) as DisabledReason | null;

    switch (disabledReason) {
      case DisabledReason.ACTIVE_SESSION:
        if (!activeSessionDisabledSounds[soundType]) {
          this.executeSound(soundType);
        }
        break;
      default:
        this.executeSound(soundType);
    }
  }

  stopSound(soundType: SoundType): void {
    // TODO https://arrangeactassert.com/posts/how-to-fix-the-request-is-not-allowed-by-the-user-agent-or-the-platform-in-the-current-context-possibly-because-the-user-denied-permission/
    try {
      setTimeout(() => {
        const audio = this.getAudio(soundType);
        audio.pause();
        audio.currentTime = 0;
      }, 0);
    } catch (err) {
      console.log(err);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  stopAllSounds() {
    Object.keys(SoundType).forEach((key: string) => {
      // @ts-expect-error TS7053
      this.stopSound(SoundType[key]);
    });
  }

  disableNotificationSounds(reason: DisabledReason): void {
    this.localStorage.setItem(SoundService.DISABLE_NOTIFICATIONS, reason);
  }

  enableAllNotificationSounds(): void {
    this.localStorage.removeItem(SoundService.DISABLE_NOTIFICATIONS);
  }

  private executeSound(soundType: SoundType): void {
    // TODO https://arrangeactassert.com/posts/how-to-fix-the-request-is-not-allowed-by-the-user-agent-or-the-platform-in-the-current-context-possibly-because-the-user-denied-permission/
    try {
      setTimeout(() => {
        const playedPromise = this.getAudio(soundType).play();
        if (playedPromise) {
          playedPromise.catch(error => {
            this.logService.error(error, `playSound error [${soundType}]`);
          });
        }
      }, 0);
    } catch (error) {
      console.log(error);
    }
  }
}
