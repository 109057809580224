import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, takeUntil, mapTo, mergeMap } from 'rxjs/operators';
import { EmailVerificationService } from './email-verification.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { SocketService } from '@app/core/socket/socket.service';
import { AuthModalComponent, AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-email-verification',
  template: '',
  providers: [EmailVerificationService]
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  // @ts-expect-error TS2564
  private hash: string | null;
  private destroy$ = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _emailVerifier: EmailVerificationService,
    private _auth: AuthService,
    private _socket: SocketService,
    protected _modal: NgbModal
  ) {}

  ngOnInit(): void {
    this._route.paramMap
      .pipe(
        map(params => params.get('hash')),
        mergeMap(hash => {
          this.hash = hash;
          return this._auth.isAuthorized ? this._socket.onSuccess$().pipe(mapTo(hash)) : of(hash);
        }),
        // @ts-expect-error TS2345
        mergeMap(verificationToken => this._emailVerifier.verify$(verificationToken)),
        takeUntil(this.destroy$)
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: any) => {
        if (data && data.withoutPassword) {
          // @ts-expect-error TS2322
          this._auth.signupByToken = { email: data.email, hash: this.hash };
          const ref = this._modal.open(AuthModalComponent, { windowClass: 'auth-modal' });
          ref.componentInstance.activeState = AuthStates.SIGN_UP;
          ref.componentInstance.disableCloseButton = true;
        } else {
          this._router.navigate(['/dashboard']);
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
