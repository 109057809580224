import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DateTime } from 'luxon';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { locale } from '@env/locale'; // TODO: fix, bad use of locale injection
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { ILocale } from '@env/locale.interface';
import { ISchedule, ISchedulePartitionUnit, IScheduleProvider, ITimeSlot } from '../../types';
import { SCHEDULE_PROVIDER } from '../schedule-provider';
import { ScheduleDatesPartitionStrategy } from '../schedule-dates-partition-strategies';
import { ScheduleTimeSlotsBuildStrategy } from '../schedule-time-slots-build-strategies';

import { ScheduleDateTimeSlotsFactory } from './schedule-date-time-slots-factory';
import { IScheduleDateTimeSlotsUpdateOptions } from './types';

export const APPLY_FIRST_AVAILABLE_DAY = new InjectionToken<boolean>('applyFirstAvailableDay');

@Injectable()
export class DefaultScheduleDateTimeSlotsFactory extends ScheduleDateTimeSlotsFactory<
  ISchedule,
  string,
  number,
  string,
  ITimeSlot
> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _partitionStrategy: ScheduleDatesPartitionStrategy<ISchedule, ISchedulePartitionUnit>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _scheduleDatesRangesStore = new Map<string, IScheduleItem[]>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<IScheduleItem[], ITimeSlot>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _date: string | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _excludeDateTime: string | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _duration: number | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _schedule: ISchedule = { ranges: [] };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _timezone: string = DateTime.local().zoneName;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _timeSlotFormat: string | null = null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _applyFirstAvailableDay: boolean;

  private locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  get date(): string {
    // @ts-expect-error TS2322
    return this._date;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  get excludeDateTime(): string | null {
    return this._excludeDateTime;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  get duration(): number | null {
    return this._duration;
  }

  // @ts-expect-error TS2416
  get timezone(): string | null {
    return this._timezone;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  get timeSlotFormat(): string | null {
    return this._timeSlotFormat;
  }

  constructor(
    @Inject(SCHEDULE_PROVIDER) scheduleProvider: IScheduleProvider<ISchedule>,
    partitionStrategy: ScheduleDatesPartitionStrategy<ISchedule, ISchedulePartitionUnit>,
    timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<IScheduleItem[], ITimeSlot>,
    @Inject(APPLY_FIRST_AVAILABLE_DAY) applyFirstAvailableDay: boolean,
    locale: ILocale
  ) {
    super(scheduleProvider, new ReplaySubject<ITimeSlot[]>(1));

    if (!partitionStrategy) {
      throw new Error('Partition strategy required');
    }

    if (!timeSlotsBuildStrategy) {
      throw new Error('Time slots build strategy required');
    }

    this._applyFirstAvailableDay = applyFirstAvailableDay !== false;
    this._partitionStrategy = partitionStrategy;
    this._timeSlotsBuildStrategy = timeSlotsBuildStrategy;

    scheduleProvider.schedule$.pipe(takeUntil(this.destroy$)).subscribe(schedule => this.setSchedule(schedule));
    this.locale = locale;
  }

  changeDate(date: string | null, options: IScheduleDateTimeSlotsUpdateOptions = {}): void {
    this._date = date;

    if (options.refreshTimeSlots !== false) {
      this.refreshTimeSlots();
    }
  }

  changeExcludeDateTime(excludeDateTime: string | null, options: IScheduleDateTimeSlotsUpdateOptions = {}): void {
    this._excludeDateTime = excludeDateTime;

    if (options.refreshTimeSlots !== false) {
      this.refreshTimeSlots();
    }
  }

  changeDuration(duration: number, options: IScheduleDateTimeSlotsUpdateOptions = {}): void {
    this._duration = duration;

    if (options.refreshTimeSlots !== false) {
      this.refreshTimeSlots();
    }
  }

  changeTimezone(timezone: string | null, options: IScheduleDateTimeSlotsUpdateOptions = {}): void {
    // @ts-expect-error TS2322
    this._timezone = timezone;

    this.refreshStore();

    if (options.refreshTimeSlots !== false) {
      this.refreshTimeSlots();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  changeTimeslotFormat(timeSlotFormat: string | null, options: IScheduleDateTimeSlotsUpdateOptions = {}) {
    this._timeSlotFormat = timeSlotFormat;

    if (options.refreshTimeSlots !== false) {
      this.refreshTimeSlots();
    }
  }

  doesDateHaveSlots(date: string): boolean {
    const range = this._scheduleDatesRangesStore.get(DateTime.fromISO(date).toFormat('yyyy-MM-dd'));
    return (
      !!range &&
      // @ts-expect-error TS2345
      this._timeSlotsBuildStrategy.canGenerateTimeSlots(range, this._duration, this._timezone, this._excludeDateTime)
    );
  }

  getFirstAvailableDate(): string | null {
    if (!this._scheduleDatesRangesStore.size) {
      return null;
    }

    for (const [dateKey, range] of this._scheduleDatesRangesStore) {
      if (
        !!range &&
        // @ts-expect-error TS2345
        this._timeSlotsBuildStrategy.canGenerateTimeSlots(range, this._duration, this._timezone, this._excludeDateTime)
      ) {
        return DateTime.fromFormat(dateKey, 'yyyy-MM-dd', {
          zone: this._timezone || 'local'
        }).toISO();
      }
    }

    return null;
  }

  protected refreshStore(): void {
    this._scheduleDatesRangesStore.clear();

    this._partitionStrategy
      .partition(this._schedule, this._timezone)
      .forEach(partitionUnit => this._scheduleDatesRangesStore.set(partitionUnit.date, partitionUnit.ranges));
  }

  protected refreshTimeSlots(): void {
    if (!this._scheduleDatesRangesStore.size) {
      this._timeSlotsEmitter$.next([]);
      return;
    }

    if (!this._date) {
      this._timeSlotsEmitter$.next([]);
      return;
    }

    if (this._applyFirstAvailableDay && !this.doesDateHaveSlots(this._date)) {
      this._date = this.getFirstAvailableDate();
    }

    // @ts-expect-error TS2345
    const dateRanges = this._scheduleDatesRangesStore.get(DateTime.fromISO(this._date).toFormat('yyyy-MM-dd'));
    const timeSlots = dateRanges
      ? this._timeSlotsBuildStrategy.generate(
          dateRanges,
          // @ts-expect-error TS2345
          this._duration,
          this._timezone,
          this._timeSlotFormat,
          this.locale.dateTimeLocale,
          this.excludeDateTime
        )
      : [];

    this._timeSlotsEmitter$.next(timeSlots);
  }

  protected setSchedule(schedule: ISchedule): void {
    this._schedule = schedule;

    this.refreshStore();

    if (this._applyFirstAvailableDay && !this._date) {
      this._date = this.getFirstAvailableDate();
    }

    this.refreshTimeSlots();
  }
}
