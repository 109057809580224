import { Color } from '../interfaces';

/**
 * Colors const to be replaced to value from cloud
 * @const {Color[]}
 */
const ACCENT: Color[] = [
  { name: '50', hex: '#757ef4', contrast: { name: '50', hex: '#FFFFFF' } },
  { name: '100', hex: '#2e3cef', contrast: { name: '100', hex: '#FFFFFF' } },
  { name: '200', hex: '#111fd4', contrast: { name: '200', hex: '#FFFFFF' } },
  { name: '300', hex: '#0b1592', contrast: { name: '300', hex: '#FFFFFF' } },
  { name: '400', hex: '#091175', contrast: { name: '400', hex: '#FFFFFF' } },
  { name: '500', hex: '#070d59', contrast: { name: '500', hex: '#FFFFFF' } },
  { name: '600', hex: '#05093d', contrast: { name: '600', hex: '#FFFFFF' } },
  { name: '700', hex: '#030520', contrast: { name: '700', hex: '#FFFFFF' } },
  { name: '800', hex: '#000104', contrast: { name: '800', hex: '#FFFFFF' } },
  { name: '900', hex: '#000000', contrast: { name: '900', hex: '#FFFFFF' } },
  { name: 'A100', hex: '#606cff', contrast: { name: 'A100', hex: '#FFFFFF' } },
  { name: 'A200', hex: '#0012f9', contrast: { name: 'A200', hex: '#FFFFFF' } },
  { name: 'A400', hex: '#000b93', contrast: { name: 'A400', hex: '#FFFFFF' } },
  { name: 'A700', hex: '#060e74', contrast: { name: 'A700', hex: '#FFFFFF' } }
];

export { ACCENT };
