import { NgModule } from '@angular/core';
import { GuideOfferApiService } from '@app/core/session/guide-offer-api.service';
import { ScheduleBookDates } from '@app/modules/book-session/services/schedule-book-dates';
import { ScheduleDatesStore } from '@app/modules/book-session/services/schedule-dates-store';
import { ExternalCalendarsAccountsService } from '@app/modules/external-calendars-accounts';
import { GuideSessionsAdaptorService } from '@app/screens/guide/guide-dashboard/services/guide-sessions-adapter.service';
import { GuideSessionsCommunicationsService } from '@app/screens/guide/guide-dashboard/services/guide-sessions-communications.service';
import {
  GuideAvailabilityCalculatorService,
  GuideCalendarEventService,
  GuideEventRecordingsService,
  GuideServiceScheduleApiService,
  GuideSessionsService,
  SchedulableGuideServicesService
} from '@app/screens/guide/guide-sessions/services/events';
import { ExternalEventsApiService } from '@app/screens/guide/guide-sessions/services/external-calendars/external-events-api.service';
import { ExternalEventsService } from '@app/screens/guide/guide-sessions/services/external-calendars/external-events.service';
import {
  GuideScheduleBlocksTemplatesService,
  GuideScheduleEditorService,
  GuideScheduleOptionsApiService
} from '@app/screens/guide/guide-sessions/services/schedule';

import { ScheduleSessionService } from './schedule-session.service';

// TODO Remove useless providers
const INTERNAL_PROVIDERS = [
  GuideScheduleBlocksTemplatesService,
  GuideScheduleEditorService,
  GuideScheduleOptionsApiService,
  GuideSessionsCommunicationsService,
  GuideOfferApiService,
  GuideServiceScheduleApiService,
  GuideAvailabilityCalculatorService,
  GuideSessionsAdaptorService,
  // WARNING: when provide is an abstract class, ngOnDestroy not firing
  // https://github.com/angular/angular/issues/14821
  {
    provide: ScheduleDatesStore,
    useClass: ScheduleBookDates
  },
  GuideSessionsService,
  GuideCalendarEventService,
  SchedulableGuideServicesService,
  ExternalEventsService,
  ExternalEventsApiService,
  ExternalCalendarsAccountsService,
  GuideEventRecordingsService
];

@NgModule({
  providers: [INTERNAL_PROVIDERS, ScheduleSessionService]
})
export class ScheduleSessionModule {}
