<app-in-chat-session-manager
  [participantDetails]="participant"
  [session]="session"
  *ngIf="participant"
  (goToUser)="goToUserHandler($event)"></app-in-chat-session-manager>

<app-in-group-chat-banner [chat]="chat" *ngIf="chat && chat.type === ChatTypes.GROUP"></app-in-group-chat-banner>

<div *ngIf="isHistoryLoading" style="position: relative">
  <div class="loading"></div>
</div>

<ng-template [ngIf]="chat && chat.messages && chat.messages.length && !(chat && chat.type === ChatTypes.CONTACT)">
  <app-chat-scroll [eventId]="eventId" (sendMessage)="sendMessage($event)"></app-chat-scroll>
</ng-template>

<ng-template [ngIf]="(!chat || !chat.messages || !chat.messages.length) && !(chat && chat.type === ChatTypes.CONTACT)">
  <app-no-chat-messages [userRole]="authorRole"></app-no-chat-messages>
</ng-template>

<ng-template [ngIf]="chat && chat.type === ChatTypes.CONTACT">
  <app-not-yet-registered [contact]="chat.users[0]" (goToUser)="goToUserHandler($event)"></app-not-yet-registered>
</ng-template>

<ng-template [ngIf]="chat && !(chat.type === ChatTypes.CONTACT)">
  <app-chat-message-box
    class="fs-exclude"
    (sendMessage)="sendMessage($event)"
    [user]="author"
    [bot]="(this.route.data | async)?.bot"></app-chat-message-box>
</ng-template>
