import { ChatTypes } from '@app/core/chat/types/chat-types';
import { IChatMessage } from './chat-message';
import { ChatUser } from './chat-user';

export enum ChatUpdateTypes {
  NEW_MESSAGE = 1,
  MESSAGE_STATUS = 2,
  HISTORY = 3,
  FILE_UPLOAD_STATUS = 4,
  MESSAGE_REMOVED = 5,
  TEMP_CHAT_PERSISTED = 6,
  CHAT_REMOVED = 7,
  HISTORY_RESERVED = 8,
  CHAT_PARTICIPANT_REMOVED = 9
}

export class Chat<M, U> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _type: ChatTypes;

  id: string;

  messages: M[];

  // @ts-expect-error TS2564
  users: { [id: number]: U };

  draft?: boolean;

  description?: string | null;

  name?: string | null;

  photo?: string | null;

  meta?: { serviceId?: number } | null;

  workspaceId?: number;

  get type(): ChatTypes {
    return this._type;
  }

  // @ts-expect-error TS7006
  constructor(chatDetails, users?: { [id: number]: U }, draft?: boolean) {
    this.id = chatDetails.id;
    this.messages = chatDetails.messages || [];
    this._type = chatDetails.type;

    ['description', 'name', 'photo', 'meta', 'workspaceId'].forEach(prop => {
      if (chatDetails[prop] !== undefined) {
        // @ts-expect-error TS7053
        this[prop] = chatDetails[prop];
      }
    });

    if (users) {
      this.users = users;
    }

    if (draft != null) {
      this.draft = draft;
    }
  }

  activate(): void {
    delete this.draft;
  }

  clone(): Chat<M, U> {
    const chatDetails = {
      id: this.id,
      messages: this.messages
    };
    return new Chat(chatDetails, this.users, this.draft);
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGeneralChatUpdate<M, U> {
  chatId: string;
  chat?: Chat<M, U>;
  type?: ChatUpdateTypes;
  displayFirstServiceSeparator?: boolean;
}

export type IChatUpdate = IGeneralChatUpdate<IChatMessage, ChatUser>;
