import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SessionsStack } from '../stack-sessions-by.pipe';
import { AnalyticSourceTypes } from '@app/core/analytics/types';
import { ClientSessionCommunicationsService } from '@libs/business/client/client-session/services';
import { AvailableClientSessionsAppearanceType } from '@app/modules/client-sessions/types';

@Component({
  selector: 'app-session-stacker',
  templateUrl: './session-stacker.component.html',
  styleUrls: ['./session-stacker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionStackerComponent {
  @Input()
  @HostBinding('class')
  appearance: AvailableClientSessionsAppearanceType | undefined;

  @Output()
  selected = new EventEmitter<never>();

  @Input()
  stack: SessionsStack | undefined;

  @Input()
  actionLabelText: string | undefined;

  constructor(private readonly emitter: ClientSessionCommunicationsService) {}

  schedule(): void {
    const meta = this.stack?.firstSession?.meta;
    if (!meta) {
      console.warn('Cant execute Service action! Session Meta is not defined!');
      return;
    }
    this.emitter.bookAvailableSession(meta, AnalyticSourceTypes.PACKAGE);
    this.selected.emit();
  }

  get canBook(): boolean {
    return !this.stack?.firstSession?.meta?.session.restrictClientBooking;
  }
}
