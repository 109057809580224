import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { BookingNavigationService } from '@appWidget/modules/booking-root/service/booking-navigation.service';
import { SelectedPrivateSessionStore } from '@appWidget/modules/booking-cancellation/store/selected-private-session-store';
import { PuiDialogRef } from '@awarenow/profi-ui-core';
import { Router } from '@angular/router';
import { BOOKING_MODAL_SETTINGS } from '@appWidget/modules/booking/injections';
import { BookingCancellationConfirmationState } from '@appWidget/modules/booking-cancellation/types';
import { CancellationType } from '@appWidget/modules/booking/screens/cancellation/types';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Bookings } from '@appWidget/modules/booking/interfaces';

@Component({
  selector: 'app-booking-cancellation-confirmation',
  templateUrl: './booking-cancellation-confirmation.component.html',
  styleUrls: ['./booking-cancellation-confirmation.component.scss', '../../common/styles/layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingCancellationConfirmationComponent {
  readonly service$ = this.privateSessionStore.service$;
  readonly bookings$: Observable<Bookings[]>;
  readonly isModal = this.modalSettings;

  constructor(
    private readonly bookingNavigationService: BookingNavigationService,
    private readonly privateSessionStore: SelectedPrivateSessionStore,
    private readonly router: Router,
    @Optional() @Inject(BOOKING_MODAL_SETTINGS) readonly modalSettings?: boolean,
    @Optional() private readonly dialogRef?: PuiDialogRef
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state as BookingCancellationConfirmationState;
    this.bookings$ = combineLatest([this.privateSessionStore.sessionEvents$, this.service$]).pipe(
      map(([bookings, session]) => {
        if (state.cancel === CancellationType.RECURRING) {
          return bookings;
        } else {
          return [{ id: session?.id!, date: session?.dateStart!, duration: session?.duration! }];
        }
      })
    );
  }

  complete(): void {
    this.dialogRef?.close();
  }
}
