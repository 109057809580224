import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { AlertsService } from '@app/modules/alerts/services/alerts.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { concatMap, delay, distinctUntilKeyChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import {
  INegativeBalanceData,
  IPostSessionData,
  IUserWallet,
  UserWalletService
} from '@app/core/user-wallet/user-wallet.service';
import { CurrencyService } from '@app/core/currency/currency.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionsService } from '@app/core/session/sessions.service';
import { ClientGuidesService } from '@app/core/users/client-guides.service';
import { IServerSession, Session, SimpleSession } from '@app/shared/interfaces/session';
import { ClientSessionFeedbackComponent } from '@app/shared/components/client-session-feedback/client-session-feedback.component';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { User } from '@app/shared/interfaces/user';
import { AuthService } from '@app/core/auth/services';
import { buildUsersGuideMapKey } from '@app/core/users/utils';

import { IGuideService, IServiceBookingOptions, ServiceBookingService } from '@app/modules/book-service';
import { IPayWithModalOptions } from '@app/modules/current-payment/components/pay-with-modal/pay-with-modal.component';
import { SocketService } from '../socket/socket.service';
import config from '../config/config';
import { AlertKey } from '@app/modules/alerts/types/alert';

enum WalletNotificationType {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'coupon-applied' = 'coupon_applied',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'increase-balance' = 'system_fund_payment',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'decrease-balance' = 'balance_payment'
}

@Injectable()
export class UserWalletNotificationsService implements OnDestroy {
  // @ts-expect-error TS2564
  wallet: IUserWallet;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _negativeModalInstance: any;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isPaymentSelectorModalOpen: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _openForFeedbackSessions: { [id: number]: any } = {};

  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isGiftCouponNotificationExist: boolean;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isPaidNotificationExist: boolean;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isPaymentNotificationExist: boolean;

  constructor(
    private _http: HttpClient,
    private _alerts: AlertsService,
    private _socket: SocketService,
    private _auth: AuthService,
    private _currencyService: CurrencyService,
    private _modalService: NgbModal,
    private _currentPayment: CurrentPaymentService,
    private _sessionsService: SessionsService,
    private clientGuidesService: ClientGuidesService,
    private _userWalletService: UserWalletService,
    private readonly _serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, void>
  ) {
    this._auth
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this._authChanged(user));

    this._alerts.deletedAlert$
      .pipe(
        map(({ template }) => template),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filter(type => Object.keys(WalletNotificationType).includes(type)),
        takeUntil(this.destroy$)
      )
      .subscribe((type: keyof typeof WalletNotificationType) => {
        this.markNotificationsReadByType(WalletNotificationType[type]);
      });

    this._socket
      .onUserWalletNotification()
      .pipe(takeUntil(this.destroy$))
      .subscribe(notification => this._catchNewNotificationByType(notification.type));

    this._userWalletService.negativeBalance$
      .pipe(
        distinctUntilKeyChanged('balance'),
        // eslint-disable-next-line id-length
        concatMap(v => of(v).pipe(delay(2000))),
        takeUntil(this.destroy$)
      )
      .subscribe(data => this._negativeBalanceListener(data));

    this._userWalletService.wallet$
      .pipe(
        filter(wallet => wallet?.balance >= 0),
        distinctUntilKeyChanged('balance'),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this._positiveBalanceListener());

    this._userWalletService.postSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => this._postSessionListener(data));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // ATTENTION: this empty method is a hack to make angular injector create instance of this class,
  // init logic left in constructor
  init(): void {}

  checkoutUserWalletNotifications(): void {
    const url = `${config.apiPath}/user/client/wallets/unread-notifications`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._http.get<{ unreadNotifications: any[] }>(url).subscribe(response => {
      if (response.unreadNotifications && response.unreadNotifications.length) {
        this._addUnreadNotificationsByType(response.unreadNotifications);
      }
    });
  }

  // @ts-expect-error TS7006
  markNotificationsReadByType(type): void {
    this._http.post(`${config.apiPath}/user/client/wallets/mark-notifications-read`, { type }).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authChanged(user: { RoleId: number }): void {
    if (user && user.RoleId === UserRoles.CLIENT) {
      setTimeout(() => this.checkoutUserWalletNotifications(), 3000);
    }
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _catchNewNotificationByType(type) {
    if (type === WalletNotificationType['increase-balance'] && !this._isPaymentNotificationExist) {
      this._alerts.template(AlertKey.INCREASE_BALANCE);
    }
    if (type === WalletNotificationType['coupon-applied'] && !this._isGiftCouponNotificationExist) {
      this._alerts.template(AlertKey.COUPON_APPLIED);
    }
    if (type === WalletNotificationType['decrease-balance']) {
      this._alerts.template(AlertKey.DECREASE_BALANCE);
    }
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _addUnreadNotificationsByType(notifications) {
    this._isGiftCouponNotificationExist = notifications.some(
      // @ts-expect-error TS7006
      item => item.type === WalletNotificationType['coupon-applied']
    );
    this._isPaidNotificationExist = notifications.some(
      // @ts-expect-error TS7006
      item => item.type === WalletNotificationType['decrease-balance']
    );
    this._isPaymentNotificationExist = notifications.some(
      // @ts-expect-error TS7006
      item => item.type === WalletNotificationType['increase-balance']
    );

    if (this._isPaymentNotificationExist) {
      this._alerts.template(AlertKey.INCREASE_BALANCE);
    }
    if (this._isGiftCouponNotificationExist) {
      this._alerts.template(AlertKey.COUPON_APPLIED);
    }
    if (this._isPaidNotificationExist) {
      this._alerts.template(AlertKey.DECREASE_BALANCE);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _negativeBalanceListener(data: INegativeBalanceData): void {
    const { balance } = data;
    const alert = `You have a negative balance. Please, pay`;
    const message = `${alert} ${this._currencyService.defaultCurrencySign}${Math.abs(balance)}`;
    if (this._negativeModalInstance) {
      this._negativeModalInstance.title = message;
    } else {
      this._showPaymentSelectorModal({ message });
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _positiveBalanceListener(): void {
    if (this._serviceBooking._modalRef) {
      this._serviceBooking._modalRef.close();
      // @ts-expect-error TS2790
      delete this._serviceBooking._modalRef;
    }

    if (this._negativeModalInstance) {
      delete this._negativeModalInstance;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _showPaymentSelectorModal(error?: { message: string }, session?: Session): void {
    const modalOptions: IPayWithModalOptions = {
      title: `Pay for session`,
      hideWallet: true,
      hidePaymentLink: true
    };

    if (error) {
      modalOptions.title = error.message;
    }

    const result$ = this._serviceBooking.selectPaymentType$(modalOptions);

    this._negativeModalInstance = this._serviceBooking._modalRef.componentInstance;
    this._isPaymentSelectorModalOpen = true;

    result$.pipe(take(1)).subscribe(
      () => {
        this._isPaymentSelectorModalOpen = false;

        this._currentPayment
          .pay()
          .overdraft$()
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(
            () => {
              this._negativeModalInstance = undefined;
              this._userWalletService.refresh();
              this._showFeedbackForm(session);
            },
            () => this._showFeedbackForm(session)
          );

        return true;
      },
      () => {
        this._isPaymentSelectorModalOpen = false;
        this._showFeedbackForm(session);
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _showFeedbackForm(session?: Session): void {
    if (!session || this._openForFeedbackSessions[session.id]) {
      return;
    }
    const { componentInstance, result } = this._modalService.open(ClientSessionFeedbackComponent);
    componentInstance.session = session;
    this._openForFeedbackSessions[session.id] = true;

    result
      .then(({ sessionId, mark, comment, privateComment }) => {
        this._sessionsService.sendFeedback$(sessionId, mark, comment, privateComment).subscribe();
        delete this._openForFeedbackSessions[session.id];
      })
      .catch(() => delete this._openForFeedbackSessions[session.id]);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _postSessionListener(postSessionData: IPostSessionData): void {
    if (postSessionData.error) {
      if (this._negativeModalInstance) {
        this._negativeModalInstance.title = postSessionData.error;
      } else {
        this._showPaymentSelectorModal({ message: postSessionData.error });
      }
      return;
    }

    // ToDo unsubscribe?
    this._convertServerSession$(postSessionData.session).subscribe(convertedSession => {
      this._showFeedbackForm(convertedSession);
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _convertServerSession$(serverSession: IServerSession): Observable<Session> {
    return (
      this.clientGuidesService
        // @ts-expect-error TS2322
        .getUser$(buildUsersGuideMapKey({ id: serverSession.guideId, workspaceId: serverSession.guide?.workspaceId }))
        .pipe(
          map(guide => {
            const { date, free, ...sessionDetails } = serverSession;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (sessionDetails as any).dateStart = date;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (sessionDetails as any).isSessionFree = !!free;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (sessionDetails as any).user = new User(guide.id, guide.firstName, guide.lastName, guide.photo);

            return new SimpleSession(sessionDetails);
          })
        )
    );
  }
}
