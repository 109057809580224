import { Validators } from '@angular/forms';

import { ICommunityConfig } from './community-config';
import { GlobalConfig } from './global-config';

export { GlobalConfig } from './global-config';

export { ICommunityConfig } from './community-config';

export enum DisableRules {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IConfigItem {
  type: ConfigItemTypes;
  defaultValue?: string | boolean | number;
  title?: string;
  subtitle?: string;
  separatorBefore?: string;
  separatorBeforeDescription?: string;
  separatorAfter?: boolean;
  validators?: Validators[];
  disabled?: DisableRules;
  templateAfter?: string;
  options?: {
    value: string | number;
    label: string;
  }[];
  hidden?: boolean;
}

export type IConfigManifest = {
  [key in keyof GlobalConfig]: IConfigItem;
};

export type ICommunityConfigManifest = {
  [key in keyof ICommunityConfig]: IConfigItem;
};

export enum ConfigItemTypes {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  text = 'text',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  checkbox = 'checkbox',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  number = 'number',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  uploader = 'uploader',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  cropUploader = 'cropUploader',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  videoUploader = 'videoUploader',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  select = 'select'
}
