<app-guide-service
  [service]="service"
  [badges]="badges || [defaultBadge]"
  [description]="description"
  [details]="details || defaultDetails"
  [price]="price"
  [team]="team"
  [editorLink]="editorLink"
  [publicLink]="publicLink"
  [copyText]="copyText"></app-guide-service>

<ng-template #team>
  <app-guide-service-team [team]="service.team"></app-guide-service-team>
</ng-template>

<ng-template #defaultBadge>
  <app-guide-service-badge
    text="Session"
    [attr.data-qa-id]="'guide-individual-session-badge-' + service.name"
    i18n-text="@@guideServiceItemSessionBadge"
    class="individual-session-badge"></app-guide-service-badge>
</ng-template>

<ng-template #defaultDetails let-service="service">
  <div class="service-details">
    <div class="service-details--time service-details__item">
      <div class="session-duration">
        <pui-icon svgIcon="pui:clock" size="s" aria-hidden="true"></pui-icon>
        <span [attr.data-qa-id]="'guide-session-service-duration-title-' + service?.name" class="duration-value">
          {{ service?.duration }}
        </span>
        <span i18n="@@sessionDurationUnit">min</span>
      </div>
    </div>
    <div
      class="service-details--platform session-location service-details__item"
      [ngClass]="{ 'hidden-platform': service.workspace | workspaceIsTeam }"
      [attr.data-qa-id]="'guide-session-service-location-title-' + service?.name">
      <pui-icon svgIcon="pui:place" size="s" aria-hidden="true"></pui-icon>
      {{ service?.connectionType | sessionPlatform: service.address }}
    </div>

    <div class="service-details--platform"></div>
  </div>
</ng-template>
