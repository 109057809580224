import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { isPlatformBrowser } from '@angular/common';
import { ScriptService } from 'ngx-script-loader';
import { FileStackFile } from '@app/shared/interfaces/filestack';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// @ts-expect-error TS7005
declare let filestack;

@Injectable({
  providedIn: 'root'
})
export class FileStackService {
  private readonly isBrowser: boolean;
  readonly filestackJsUrl = 'https://static.filestackapi.com/filestack-js/3.14.0/filestack.min.js';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;

  constructor(
    private scriptService: ScriptService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(GLOBAL_WINDOW) private browserWindow: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser && !this.browserWindow._filestack) {
      this.browserWindow._filestack = this.browserWindow._filestack || [];
      this.scriptService.loadScript(this.filestackJsUrl).subscribe(() => {
        this.client = filestack.init(this._runtimeConfigService.get('filestackApiKey'));
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openUploadWindow(onUploaded: (file: FileStackFile) => void) {
    if (this.isBrowser) {
      this.client
        .picker({
          // @ts-expect-error TS7006
          onUploadDone: file => {
            onUploaded(file.filesUploaded[0]);
          }
        })
        .open();
    }
  }
}
