<ng-container *puiLet="(selectedOptions$ | async)?.length as optionLength">
  <!--EMPTY CELL BEGIN-->

  <ng-container *ngIf="!isStandalone && optionLength === 0 && isHidden">
    <!--EMPTY CELL BEGIN-->
    <div *ngIf="isFocused" class="contacts-tag-cell__empty-container">
      <button pui-link type="button" (click)="isHidden = false" class="contacts-tag-cell__empty-container-button">
        <pui-icon svgIcon="pui:plus-circle" class="pui-space_right-1"></pui-icon>
        <span i18n="@@tableAddTag:new">Add tag</span>
      </button>
    </div>
    <!--EMPTY CELL BEGIN-->

    <!--EMPTY CELL BEGIN-->
    <span *ngIf="!isFocused" class="empty-cell" aria-label="Empty cell"></span>
    <!--EMPTY CELL END-->
  </ng-container>
  <!--EMPTY CELL BEGIN-->

  <ng-container *ngIf="optionLength > 0 || !isHidden || isStandalone">
    <div
      #select
      pui-select
      placeholder="Select a tag or create a new one"
      i18n-placeholder="@@newelectATagOrCreateANewOne:new"
      class="guide-client-tag-input"
      [class.guide-client-tag-input_standalone]="isStandalone"
      [selectedOptions]="selectedOptions$ | async"
      [noOptionContent]="noOptionTemplate"
      [customSelectedTemplate]="selectedOptionTemplate"
      [maxlength]="MAX_VALUE_COUNT"
      [multiple]="true"
      [search]="search.value"
      (searchChange)="search.setValue($event)"
      (selectedOptionsChange)="change($event)">
      <pui-optgroup
        *ngIf="optionLength < MAX_VALUE_COUNT"
        label="Select a tag or create a new one"
        i18n-label="@@newelectATagOrCreateANewOne:new">
        <!-- OPTION BEGIN-->

        <pui-option
          *ngFor="let option of variants$ | async"
          [value]="option.value"
          [data]="option.data"
          [label]="option.label">
          <div class="option-content__decoration">
            <!-- COLOR PICKER BEGIN -->
            <button
              class="option-content__button pui-space_right-3"
              [items]="COLOR_VARIANTS"
              [pui-dropdown-content]="colorSelector"
              [puiDropdownContext]="{
                currentColor: option.data?.color,
                option: option
              }"
              (click)="$event.preventDefault(); $event.stopPropagation()"
              pui-dropdown>
              <span
                class="pui-datalist__option-dot pui-space_right-1"
                [style.background-color]="option.data?.color || '#fff'"
                [style.color]="option.data?.color || '#DDE7EC'"></span>
              <pui-icon class="pui-datalist__option-icon" svgIcon="pui:chevron-down" size="xs"></pui-icon>
            </button>
            <!-- COLOR PICKER END -->
          </div>
          <div class="option-content__text">
            <span>{{ option.label }}</span>
          </div>
          <div class="option-content__action pui-space_left-2">
            <button
              type="button"
              aria-label="Delete option"
              class="option-content__button"
              (click)="$event.preventDefault(); $event.stopPropagation(); deleteOptionConfirm(option)">
              <pui-icon svgIcon="pui:trash" size="xs"></pui-icon>
            </button>
          </div>
        </pui-option>
        <!-- OPTION END-->

        <!--CREATE OPTION BEGIN-->
        <pui-option
          *ngIf="(canShowCreateOption$ | async) && optionLength < MAX_VALUE_COUNT"
          [label]="search.value"
          [value]="search.value"
          [data]="{ creatable: true, color: DEFAULT_TAG_COLOR }">
          <div class="pui-datalist__option pui-datalist__option_create">
            <div class="pui-datalist__option-text">
              <span>Create</span>
              <pui-tag
                class="pui-space_left-2 pui-tag_text-overflow"
                [style.max-width]="'127px'"
                size="s"
                [borderColor]="DEFAULT_TAG_COLOR"
                [background]="DEFAULT_TAG_COLOR">
                <span class="tag-text">{{ search.value }}</span>
              </pui-tag>
            </div>
          </div>
        </pui-option>
        <!--CREATE OPTION END-->
      </pui-optgroup>
    </div>
  </ng-container>

  <ng-template #noOptionTemplate>
    <div pui-list>
      <div class="option-content__text" pui-list-item>
        <strong i18n="@@deleteAnyTagToAddNew:Delete">Delete&nbsp;</strong>
        <span i18n="@@deleteAnyTagToAddNew:AnyTagAnyTagToAddNew">any tag to add new.</span>
      </div>
    </div>
  </ng-template>

  <ng-template #selectedOptionTemplate let-option="option">
    <pui-tag
      class="pui-tag_text-overflow"
      [editable]="selectComponent?.isAttached"
      [style.max-width]="'127px'"
      [attr.title]="option.label || option.value"
      size="s"
      (remove)="deleteSelectedOption(option)"
      [borderColor]="option.data?.color || DEFAULT_TAG_COLOR"
      [background]="option.data?.color || DEFAULT_TAG_COLOR">
      <span class="tag-text">{{ option.label || option.value }}</span>
    </pui-tag>
  </ng-template>

  <ng-template #colorSelector let-colors="items" let-close="close" let-currentColor="currentColor" let-option="option">
    <div class="palette">
      <div class="palette__close">
        <button class="palette__close-button" (click)="close()">
          <pui-icon svgIcon="pui:x" size="s"></pui-icon>
        </button>
      </div>
      <div class="palette__wrapper">
        <div class="pui-space_bottom-4">
          <label pui-typography="body-s-bold">Select a color</label>
        </div>
        <pui-color-selector
          [color]="currentColor"
          [colors]="colors"
          (colorChange)="updateTag(option, { color: $event }); close()"></pui-color-selector>
      </div>
    </div>
  </ng-template>
</ng-container>
