import { DateTime } from 'luxon';
import { from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, pluck, switchMap, take } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublicProfileInfo } from '@app/screens/guide/guide-profile/services/guide-profile.service';
import { GUIDE_PROFILE } from '@app/shared/constants/endpoints';
import { UserTimezoneStore } from '@libs/core/user-timezone.store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ChangeTimezoneModalComponent } from './change-timezone-modal.component';

@Injectable({ providedIn: 'root' })
export class ChangeTimezoneModalService {
  private currentTimeZone: string = DateTime.local().zoneName;

  private subjectFoUpdate: Subject<true> = new Subject<true>();

  changeZoneSubscription$ = this.subjectFoUpdate.asObservable();

  private readonly timezone$ = this.userTimezoneStore.timezone$;

  constructor(
    private readonly http: HttpClient,
    private readonly modal: NgbModal,
    private userTimezoneStore: UserTimezoneStore
  ) {}

  checkTimezone(): void {
    this.timezone$
      .pipe(
        take(1),
        filter(timezone => {
          const currentTimeZoneOffset = DateTime.local().offset;
          const userTimezoneOffset = DateTime.local().setZone(timezone).offset;

          return !!timezone && currentTimeZoneOffset !== userTimezoneOffset;
        }),
        switchMap(() => {
          const { componentInstance, result } = this.modal.open(ChangeTimezoneModalComponent, { centered: true });
          componentInstance.currentTimezone = this.currentTimeZone;
          return from(result);
        }),
        distinctUntilChanged(),
        filter(timeZone => !!timeZone)
      )
      .subscribe(timezone => {
        this.userTimezoneStore.setTimezone(timezone);
        this.subjectFoUpdate.next(true);
      });
  }

  changeTimezone$(timezone: string): Observable<void> {
    return this.http.post<void>(`${GUIDE_PROFILE}/public`, {
      timezone
    });
  }

  getPublicProfile(): Observable<PublicProfileInfo> {
    return this.http.get<{ profile: PublicProfileInfo }>(`${GUIDE_PROFILE}/public`).pipe(pluck('profile'));
  }
}
