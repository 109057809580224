<ng-container *ngIf="startDate.start === 'Starts'; else startedAgo">
  <div class="time-to-session-start">
    <ng-container [ngSwitch]="startDate.durationOfTime">
      <ng-container *ngSwitchCase="'second'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="second"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'minute'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="minute"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'hour'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="hour"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'day'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="day"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'week'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="week"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'mon'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="month"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'year'">
        <ng-template [ngTemplateOutlet]="startsIn"></ng-template>
        <ng-template [ngTemplateOutlet]="year"></ng-template>
      </ng-container>
      <ng-template *ngSwitchDefault>{{ sessionDateStart | humanizeSessionStartDate }}</ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-template #startedAgo>
  <div class="time-to-session-start">
    <ng-container [ngSwitch]="startDate.durationOfTime">
      <ng-container *ngSwitchCase="'second'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="second"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'minute'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="minute"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'hour'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="hour"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'day'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="day"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'week'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="week"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'mon'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="month"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'year'">
        <ng-template [ngTemplateOutlet]="started"></ng-template>
        <ng-template [ngTemplateOutlet]="year"></ng-template>
        <ng-template [ngTemplateOutlet]="ago"></ng-template>
      </ng-container>
      <ng-template *ngSwitchDefault>{{ sessionDateStart | humanizeSessionStartDate }}</ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #startsIn>
  <ng-container i18n="@@timeToSessionStartStartsIn">Starts in {{ startDate.numberOfTime }}</ng-container>
</ng-template>

<ng-template #started>
  <ng-container transloco="timeToSessionStartStarted">Started</ng-container>
</ng-template>

<ng-template #ago>
  <ng-container transloco="timeToSessionStartAgo">ago</ng-container>
</ng-template>

<ng-template #second>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template transloco="zero:seconds" ngPluralCase="=0">zero</ng-template>
    <ng-template transloco="second:one" ngPluralCase="=1">second</ng-template>
    <ng-template transloco="second:many" ngPluralCase="other">seconds</ng-template>
  </ng-container>
</ng-template>

<ng-template #minute>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template transloco="minute:one" ngPluralCase="=1">minute</ng-template>
    <ng-template transloco="minute:many" ngPluralCase="other">minutes</ng-template>
  </ng-container>
</ng-template>

<ng-template #hour>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template ngPluralCase="=1">hour</ng-template>
    <ng-template ngPluralCase="other">hours</ng-template>
  </ng-container>
</ng-template>

<ng-template #day>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template ngPluralCase="=1">day</ng-template>
    <ng-template ngPluralCase="other">days</ng-template>
  </ng-container>
</ng-template>

<ng-template #week>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template i18n="@@week:one" ngPluralCase="=1">week</ng-template>
    <ng-template i18n="@@week:many" ngPluralCase="other">weeks</ng-template>
  </ng-container>
</ng-template>

<ng-template #month>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template i18n="@@month:one" ngPluralCase="=1">month</ng-template>
    <ng-template i18n="@@month:many" ngPluralCase="other">months</ng-template>
  </ng-container>
</ng-template>

<ng-template #year>
  <ng-container [ngPlural]="+startDate.numberOfTime">
    <ng-template i18n="@@year:one" ngPluralCase="=1">year</ng-template>
    <ng-template i18n="@@year:many" ngPluralCase="other">years</ng-template>
  </ng-container>
</ng-template>
