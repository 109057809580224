import { IAvailabilityParams, IGuideScheduleRequestOptions, IServiceScheduleOptions } from '@app/core/schedule/types';
import { HttpParams } from '@angular/common/http';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';

class ScheduleUtility {
  static buildParams(scheduleOptions: IServiceScheduleOptions): HttpParams {
    const { options } = scheduleOptions;

    const paramsObject: IAvailabilityParams = {
      // @ts-expect-error TS2532
      guideId: scheduleOptions.serviceHost ? scheduleOptions.serviceHost : scheduleOptions.guide.id,
      // @ts-expect-error TS2322
      workspaceId: scheduleOptions.workspaceId || scheduleOptions.guide.workspaceId || null,
      rangeStart: scheduleOptions.range.start,
      rangeEnd: scheduleOptions.range.end,
      serviceId: scheduleOptions.serviceId,
      serviceParentId: scheduleOptions.serviceParent?.id,
      serviceParentType: scheduleOptions.serviceParent?.type
    };

    if (options) {
      Object.keys(options).forEach((prop: keyof IGuideScheduleRequestOptions) => {
        if (options[prop] !== null && options[prop] !== undefined) {
          // @ts-expect-error TS7053
          paramsObject[prop] = options[prop].toString();
        }
      });
    }

    const queryParams = {};

    Object.keys(paramsObject).forEach((prop: keyof IAvailabilityParams) => {
      if (paramsObject[prop] !== null && paramsObject[prop] !== undefined) {
        // @ts-expect-error TS7053
        queryParams[prop] = paramsObject[prop].toString();
      }
    });

    return new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: queryParams
    });
  }
}

export { ScheduleUtility };
