import { DateTime } from 'luxon';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';

import { Override, OverridesTime } from '../../forms/date-overrides-form/date-overrides-form.component';

interface DrawerData {
  override: {
    date: string;
    values: Override[];
  };
  excludedDates: string[];
}

@Component({
  selector: 'app-date-overrides-drawer',
  templateUrl: './date-overrides-drawer.component.html',
  styleUrls: [`./date-overrides-drawer.component.scss`],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateOverridesDrawerComponent implements OnInit {
  dateOverrides = new FormControl(null);
  excludedDates: string[] = [];

  constructor(private readonly drawerRef: PuiDrawerRef<null, DrawerData>) {}

  ngOnInit(): void {
    if (this.drawerRef.data?.override) {
      this.setControlData();
    }
  }

  setControlData(): void {
    const { override, excludedDates } = this.drawerRef.data;

    this.excludedDates = excludedDates;

    this.dateOverrides.patchValue({
      date: DateTime.fromISO(override.date).toJSDate(),
      overrides: override.values,
      unavailable: this.isUnavailable(override.values)
    });
  }

  save(): void {
    this.drawerRef.close(this.dateOverrides.value);
  }

  close(): void {
    this.drawerRef.close();
  }

  back(): void {
    this.drawerRef.close();
  }

  private isUnavailable(overrides: Override[]): boolean {
    const firstOverride = overrides[0];

    return (
      overrides.length === 1 &&
      firstOverride.startTime === OverridesTime.DEFAULT_TIME &&
      firstOverride.endTime === OverridesTime.DEFAULT_TIME
    );
  }
}
