import { SubscriptionRecurrency } from '@app/shared/enums/subscription';

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getRecurrencyWeight(recurrency) {
  switch (recurrency) {
    case SubscriptionRecurrency.Week:
      return 1;
    case SubscriptionRecurrency.Month:
      return 2;
    case SubscriptionRecurrency.Year:
      return 3;
    default:
      return 0;
  }
}
