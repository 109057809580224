<ng-container [formGroup]="form">
  <div class="d-flex">
    <div class="pui-form__field">
      <label pui-input-label [required]="true" i18n="@@newEventDateLabel">Date</label>
      <pui-input-date
        class="choose-date"
        formControlName="date"
        i18n-puiPlaceholder="@@chooseDate"
        puiPlaceholder="Choose date"
        puiDatepickerHandler
        (monthChange)="calendarDateChanges$.next($event)"
        [puiAvailableDate]="availability$ | async | availableDateFn: timezone"
        [puiDatepickerMinDate]="minDate.toJSDate()"
        [puiPartialAvailableDate]="isPartialAvailableDate"
        puiPartialAvailableDateText="Outside of availability"></pui-input-date>
      <ng-container *puiLet="form?.get('date') as control">
        <div *ngIf="control?.touched && control?.hasError('required')" class="pui-space_top-2">
          <span pui-typography="body-s" class="pui-error" i18n="@@datetIsRequred">Date is required</span>
        </div>
      </ng-container>
    </div>

    <div class="pui-form__field time-field">
      <label pui-input-label [required]="true" i18n="@@time">Time</label>

      <pui-select
        formControlName="time"
        [disabled]="timeControlDisabled"
        puiPlaceholder="Choose time"
        i18n-puiPlaceholder="@@chooseTimePlaceholder"
        [puiDropdownAfterContent]="bottomTemplate"
        [virtualScroll]="false"
        [optionContent]="optionContentTemplate"
        [maxHeight]="238">
        <ng-template #bottomTemplate>
          <ng-container *ngIf="canBeExtended">
            <div class="override-toggler">
              <label formControlName="extended" pui-toggle>
                <strong i18n="@@overrideAvailableTimes">Override available times</strong>
              </label>
            </div>
          </ng-container>
        </ng-template>

        <pui-option
          *ngFor="let slot of slots"
          [value]="slot?.time?.toString()"
          [data]="slot"
          [label]="slot.time | date: 'shortTime':(timezone | timezoneOffset: slot.time)"></pui-option>
      </pui-select>
      <ng-template #optionContentTemplate let-option="option">
        <div class="d-flex flex-column time-option">
          <span>{{ option.data.time | date: 'shortTime':(timezone | timezoneOffset: option.data.time) }}</span>
          <div *ngIf="option.data.seats && !hideTimeslotsWithAttendee && !extended">
            <small pui-typography="body-s">{{ option.data.seats | availableSeats: option.data.attendees }} </small>
          </div>
          <div *ngIf="extended && option.data.reasons?.length > 0">
            <ng-container *ngFor="let reason of option.data.reasons; let last = last">
              <small
                pui-typography="body-s"
                [pui-tooltip]="reason"
                class="time-option__description-text"
                pui-tooltip-direction="top-left"
                >{{ reason }}</small
              >
              <br *ngIf="!last" />
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-container *puiLet="form?.get('time') as control">
        <div *ngIf="control?.touched && control?.hasError('required')" class="pui-space_top-2">
          <span pui-typography="body-s" class="pui-error pui-space_top-2" i18n="@@timetIsRequred"
            >Time is required</span
          >
        </div>
      </ng-container>
    </div>
  </div>

  <div>
    <div class="pui-space_top-2">
      <span i18n="@@timeZoneColo">Time zone:&nbsp;</span>
      <pui-input-time-zone formControlName="timezone"></pui-input-time-zone>
    </div>
  </div>
</ng-container>
