import { IChatMessage } from '@app/core/chat/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChatBoardMessage {
  type: ChatBoardMessageTypes;
  payload: IChatMessage | string;
}

export enum ChatBoardMessageTypes {
  MESSAGE = 'MESSAGE',
  FILE = 'FILE',
  DATE_SEPARATOR = 'DATE_SEPARATOR',
  SERVICE_SEPARATOR = 'SERVICE_SEPARATOR',
  JOINED_CHAT = 'JOINED_CHAT'
}
