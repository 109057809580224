import { environment } from '@env/environment';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MiniQuiz } from '@app/screens/guide/guide-surveys/types/quiz';

@Component({
  selector: 'app-quiz-card',
  templateUrl: './quiz-card.component.html',
  styleUrls: ['./quiz-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizCardComponent {
  @Input()
  // @ts-expect-error TS2564
  quiz: MiniQuiz;
  @Output()
  removeQuiz = new EventEmitter<number>();
  @Output()
  duplicate = new EventEmitter();
  @Output()
  sendToClient = new EventEmitter();

  guideRoute = environment.guideRoute;
}
