import { FormControl, ValidatorFn } from '@angular/forms';
import { DEFAULT_ACTIVATION_SPLITTER } from '@app/screens/guide/guide-programs/types/helpers';
import { QuizEnds } from '@app/core/quizzes/types';

export const activationValidator: ValidatorFn = (control: FormControl): { [key: string]: boolean } | null => {
  if (!control.value) {
    return null;
  }

  const [type, date] = control.value.split(DEFAULT_ACTIVATION_SPLITTER);

  if (type === QuizEnds.SpecificDate && !date) {
    return {
      required: true
    };
  }

  return null;
};
