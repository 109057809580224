import { ChatMessageStatuses } from '@app/core/chat/types';

export class ChatsNotificationsReducer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _updates: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _cache: any;

  // @ts-expect-error TS7006
  constructor(updates) {
    this._updates = updates;
    this._cache = {};
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  run() {
    const updatesCount = this._updates.length;
    let index = updatesCount - 1;

    while (index >= 0) {
      const refreshCache = this._cache[this._updates[index].chatId] ? this.updateCache : this.addToCache;
      refreshCache.call(this, this._updates[index]);
      index--;
    }

    return this.reduceCache();
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private addToCache({ chatId, userId, status, messageIds }) {
    this._cache[chatId] = {
      chatId,
      userId,
      // @ts-expect-error TS7006
      messageIds: messageIds.reduce((dict, messageId) => {
        dict[messageId] = status;
        return dict;
      }, {})
    };
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private updateCache(currentUpdate) {
    if (!currentUpdate.messageIds) {
      return;
    }

    const { chatId, status, messageIds } = currentUpdate;

    const cachedMessageIds = this._cache[chatId].messageIds || {};

    for (let i = 0, len = messageIds.length; i < len; i++) {
      const messageId = messageIds[i];
      if (!cachedMessageIds[messageId] || cachedMessageIds[messageId] !== ChatMessageStatuses.READ) {
        cachedMessageIds[messageId] = status;
      }
    }

    if (!this._cache[chatId].messageIds) {
      this._cache[chatId].messageIds = cachedMessageIds;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private reduceCache(): any[] {
    const chatIds = Object.keys(this._cache);
    const result = [];

    for (let index = 0, len = chatIds.length; index < len; index++) {
      result.push(this._cache[chatIds[index]]);
    }

    return result;
  }
}
