import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionResponse } from '@appWidget/modules/booking-cancellation/services/private-sessions.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bookings } from '@appWidget/modules/booking/interfaces';

const VISIBLE_BOOKINGS_COUNT = 4;

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionDetailsComponent {
  private readonly bookingsSubject$ = new BehaviorSubject<Bookings[]>([]);
  private readonly showMoreSubject$ = new BehaviorSubject<boolean>(false);
  @Input() set bookings(bookings: Bookings[]) {
    this.bookingsSubject$.next(this.sortByDate(bookings ?? []));
  }

  @Input() service!: SessionResponse;

  @Input() cancellation = false;

  readonly visibleBookingsCount = VISIBLE_BOOKINGS_COUNT;
  readonly bookings$: Observable<Bookings[]> = this.bookingsSubject$.asObservable();
  readonly visibleBookings$: Observable<Bookings[]> = combineLatest([
    this.bookingsSubject$,
    this.showMoreSubject$
  ]).pipe(
    map(([bookings, showMore]) => {
      return showMore ? bookings : bookings.slice(0, VISIBLE_BOOKINGS_COUNT);
    })
  );

  trackBy = (index: number): number => index;

  showMoreBookings(): void {
    this.showMoreSubject$.next(true);
  }

  private sortByDate(bookings: Bookings[]): Bookings[] {
    return bookings?.sort((itemA, itemB) => new Date(itemA.date).getTime() - new Date(itemB.date).getTime());
  }
}
