import { Inject, Injectable, OnDestroy, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { IntegrationService } from '../../interfaces/integration-service';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '@app/core/auth/services';
import { merge, Subject } from 'rxjs';
import { CanduConfig } from './candu.config';
import { startWith, takeUntil } from 'rxjs/operators';

declare const Candu: {
  init: (params: { userId?: string; clientToken: string }) => void;
};

const appendCanduScript = (
  window: Window,
  renderer: Renderer2,
  options: CanduConfig,
  userId: string | undefined
): void => {
  if (!window || !renderer) {
    return;
  }

  const params = { clientToken: options.clientToken };
  if (userId) {
    // @ts-expect-error TS7053
    params['userId'] = userId;
  }

  // @ts-expect-error TS7015
  if (window['Candu']?.init) {
    Candu.init(params);
    return;
  }

  const script: HTMLScriptElement = renderer.createElement('script');
  script.setAttribute('src', 'https://cdn.candu.ai/sdk/latest/candu.umd.js?token=' + options.clientToken);
  script.async = true;
  script.onload = function () {
    Candu.init(params);
  };

  renderer.appendChild(window.document.head, script);
};

@Injectable()
export class CanduService implements IntegrationService, OnDestroy {
  // @ts-expect-error TS2564
  private readonly renderer: Renderer2;

  // @ts-expect-error TS2564
  private readonly window: Window;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(AuthService) private readonly auth: AuthService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly rendererFactory: RendererFactory2
  ) {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      this.renderer = this.rendererFactory.createRenderer(window.document, null);
      this.window = window;
    }
  }

  init(options: CanduConfig): void {
    const logInOrLogOut = merge(this.auth.onAuth(), this.auth.onLogout());

    logInOrLogOut.pipe(startWith(undefined), takeUntil(this.destroy$)).subscribe(() => {
      appendCanduScript(this.window, this.renderer, options, this.auth.user.id);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
