import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUserSuggestion<TId = number> {
  readonly id: TId;
  readonly name: string;
  readonly photo?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUsersSearchService<TUser> {
  readonly search: (searchString: string) => void;
  readonly users$: Observable<TUser[]>;
  readonly loading: boolean;
}

export const USERS_SEARCH_SERVICE_PROVIDER = new InjectionToken<IUsersSearchService<IUserSuggestion>>(
  'UsersSearchServiceProvider'
);
