import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { TfaChallengeInfo } from '../../interfaces/tfa-challendge-info.interface';
import { OtpCodeService } from '../../services/otp-code.service';

@Component({
  selector: 'tfa-app-auth',
  templateUrl: './tfa-app-auth.component.html',
  styleUrls: ['../../../../screens/auth/auth.scss', '../../styles/auth.scss', './tfa-app-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PuiDestroyService, OtpCodeService]
})
export class TfaAppAuthComponent implements OnInit {
  // @ts-expect-error TS2564
  @Input() tfaInfo: TfaChallengeInfo;

  @Input() onlyClient = true;
  @Input() onlyGuide = false;

  @Output()
  afterSignIn = new EventEmitter();

  @Output()
  useBackupCode = new EventEmitter();

  otpCodeControl: FormControl;
  showInvalidCodeError$: Subject<boolean>;

  constructor(
    private readonly authService: AuthService,
    private readonly otpCodeService: OtpCodeService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {
    this.otpCodeControl = this.otpCodeService.control;
    this.showInvalidCodeError$ = this.otpCodeService.showError$;
  }

  ngOnInit(): void {
    this.otpCodeService.subscribeOnOtpType(this.verifyTfaOtp.bind(this));
  }

  private verifyTfaOtp(otpToken: string): void {
    this.authService
      .verifyTfaOtpToken(this.tfaInfo.tfaChallengeAuthToken, otpToken, this.onlyClient, this.onlyGuide)
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            this.showInvalidCodeError$.next(true);
          }
          return throwError(error);
        }),
        mergeMap(result => {
          if (
            (this.onlyClient && this.authService.user.RoleId === UserRoles.GUIDE) ||
            (this.onlyGuide && this.authService.user.RoleId === UserRoles.CLIENT)
          ) {
            return combineLatest([of(result), this.authService.checkAlternativeAccount()]);
          }

          return of([result, null]);
        }),
        mergeMap(([result, checkRes]) => {
          if (checkRes) {
            if (checkRes.hasAlternativeProfile) {
              return this.authService.signinAlternativeAccount(true);
            }
            return throwError({ result, alternativeFailed: true });
          }

          return of(result);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        isValid => {
          if (isValid) {
            return this.afterSignIn.emit();
          }

          this.otpCodeControl.reset();
          this.otpCodeControl.enable({ emitEvent: false });
          this.showInvalidCodeError$.next(true);
        },
        error => {
          if (error.alternativeFailed) {
            this.authService.authorize(error.result);
          }
        }
      );
  }
}
