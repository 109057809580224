import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  PuiButtonModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiKeyboardWidgetModule,
  PuiLetModule,
  PuiList2Module
} from '@awarenow/profi-ui-core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import {
  ClientSessionConnectionTypeAttributeComponent,
  ClientSessionGuestsAttributeComponent,
  ClientSessionGuideAttributeComponent,
  ClientSessionRecurringAttributeComponent,
  ClientSessionTimeAttributeComponent,
  SessionAttributesComponent
} from './components';

@NgModule({
  declarations: [
    SessionAttributesComponent,
    ClientSessionGuideAttributeComponent,
    ClientSessionRecurringAttributeComponent,
    ClientSessionTimeAttributeComponent,
    ClientSessionConnectionTypeAttributeComponent,
    ClientSessionGuestsAttributeComponent
  ],
  imports: [
    CommonModule,
    PuiIconModule,
    RouterModule,
    PuiLetModule,
    PuiButtonModule,
    PuiButtonModule,
    NgbTooltipModule,
    PuiDropdownModule,
    PuiList2Module,
    PuiKeyboardWidgetModule
  ],
  exports: [SessionAttributesComponent]
})
export class ClientSessionAttributeModule {}
