import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthBodyComponent } from './auth-body/auth-body.component';
import { AuthContainerComponent } from './auth-container/auth-container.component';
import { AuthHeaderComponent } from './auth-header/auth-header.component';
import { AuthLayoutComponent } from './auth-layout.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AuthBodyComponent, AuthContainerComponent, AuthHeaderComponent, AuthLayoutComponent]
})
export class AuthLayoutModule {}
