<span [ngSwitch]="type" [ngClass]="{ capitalized: capitalized }" [attr.data-qa-id]="type + '-service-type-title'">
  <span *ngSwitchCase="GuideServiceTypes.PROGRAM" i18n="@@serviceTypeProgramLower" class="program-service-type">
    program
  </span>
  <span *ngSwitchCase="GuideServiceTypes.PACKAGE" i18n="@@serviceTypePackageLower" class="package-service-type">
    package
  </span>
  <span
    *ngSwitchCase="GuideServiceTypes.SESSION"
    i18n="@@serviceTypeIndividualSessionLower"
    class="session-service-type">
    individual session
  </span>
  <span
    *ngSwitchCase="GuideServiceTypes.GROUP_SESSION"
    i18n="@@serviceTypeSessionLower"
    class="group-session-service-type">
    session
  </span>
  <span *ngSwitchCase="GuideServiceTypes.WEBINAR" i18n="@@serviceTypeWebinarLower" class="webinar-service-type">
    webinar
  </span>
</span>
