import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ProgramAccessRoles } from '@app/screens/guide/guide-programs/types';
import { GuideServicesService } from '@app/screens/guide/guide-services/services/guide-services.service';
import {
  GuideService,
  GuideServiceTypes,
  isGroupSessionService,
  isPackageService,
  isProgramService
} from '@app/shared/interfaces/services';
import { PuiListItemAppearanceType } from '@awarenow/profi-ui-core';

export interface SessionActionsMenuItem {
  title: string;
  className?: string;
  clickEvent: (service: GuideService) => void;
  svgIcon: string;
  appearance?: PuiListItemAppearanceType;
}

@Pipe({
  name: 'guideServicesActionMenu'
})
export class GuideServicesActionMenuPipe implements PipeTransform {
  buttons: Record<'delete' | 'share' | 'duplicate', SessionActionsMenuItem> = {
    delete: {
      title: `Delete`,
      className: 'delete-btn',
      clickEvent: this.guideServices.removeService.bind(this.guideServices),
      svgIcon: 'delete-small',
      appearance: 'error'
    },
    share: {
      title: `Share`,
      clickEvent: this.guideServices.shareProgram.bind(this.guideServices),
      svgIcon: 'share'
    },
    duplicate: {
      title: `Duplicate`,
      clickEvent: this.guideServices.duplicateProgram.bind(this.guideServices),
      svgIcon: 'duplicate'
    }
  };

  get isTeamAdmin(): boolean {
    return this.workspaceService.isAdmin;
  }

  constructor(
    private readonly guideServices: GuideServicesService,
    private readonly auth: AuthService,
    private readonly workspaceService: WorkspacesService
  ) {}

  transform(service: GuideService): SessionActionsMenuItem[] | null {
    if (!service) {
      return null;
    }

    const items: SessionActionsMenuItem[] = [];

    switch (service.type) {
      case GuideServiceTypes.SESSION:
        if (this.canRemoveService(service)) {
          items.push(this.buttons['delete']);
        }
        break;

      case GuideServiceTypes.GROUP_SESSION:
        if (this.canRemoveService(service)) {
          items.push(this.buttons['delete']);
        }
        break;

      case GuideServiceTypes.PROGRAM:
        if (!this.auth.isPlatformAdmin()) {
          items.push(this.buttons['share']);
        }
        if (this.canDuplicateProgram(service)) {
          items.push(this.buttons['duplicate']);
        }
        if (this.canRemoveProgram(service)) {
          items.push(this.buttons['delete']);
        }
        break;

      case GuideServiceTypes.PACKAGE:
        if (this.canRemovePackage(service)) {
          items.push(this.buttons['delete']);
        }
        break;
    }

    return items.length ? items : null;
  }

  private canRemoveService(service: GuideService): boolean {
    return isGroupSessionService(service) && (this.isAuthorOfService(service) || this.auth.isPlatformAdmin());
  }

  private canRemovePackage(service: GuideService): boolean {
    return isPackageService(service) && (this.isAuthorOfService(service) || this.auth.isPlatformAdmin());
  }

  private canRemoveProgram(service: GuideService): boolean {
    return isProgramService(service) && service.accessRole === ProgramAccessRoles.AUTHOR;
  }

  private canDuplicateProgram(service: GuideService): boolean {
    return isProgramService(service) && (service.accessRole === ProgramAccessRoles.AUTHOR || this.isTeamAdmin);
  }

  private isAuthorOfService(service: GuideService): boolean {
    const loggedInUserId = this.auth.user.id;

    return loggedInUserId === service.guideId;
  }
}
