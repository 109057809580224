import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PriceInputComponent } from './components/price-input/price-input.component';

@NgModule({
  declarations: [PriceInputComponent],
  exports: [PriceInputComponent],
  imports: [CommonModule, ReactiveFormsModule]
})
export class PriceInputModule {}
