import { Injectable } from '@angular/core';
import { VisibilityCondition } from '@app/cdk/conditions';
import { ViewHostCandidateUnionMap } from '@app/modules/sidenav/types/view-candidate';
import { SidenavBodyComponent } from '@app/modules/sidenav/hosts/body/body.component';
import {
  SidenavAdminLogoutComponent,
  SidenavNavigationPrimaryWidgetComponent,
  SidenavNavigationPrimaryWidgetService
} from '@app/modules/sidenav/components';

@Injectable()
export class SidenavSuperAdminSchema implements ViewHostCandidateUnionMap {
  // @ts-expect-error TS2416
  sidenav = {
    header: null,
    body: {
      component: SidenavBodyComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    footer: null
  };

  // @ts-expect-error TS2416
  header = null;

  body = {
    topNavigation: {
      component: SidenavNavigationPrimaryWidgetComponent,
      condition: VisibilityCondition.always,
      context: SidenavNavigationPrimaryWidgetService
    },
    bottomNavigation: {
      component: SidenavAdminLogoutComponent,
      condition: VisibilityCondition.always,
      context: null
    }
  };

  // @ts-expect-error TS2416
  footer = null;
}
