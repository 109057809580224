import { User } from '../user';

export class ProgramGuide extends User {
  specialization: string;
  shortQuote: string | null;
  namedUrl: string;

  constructor(
    id: number,
    firstName: string | null,
    lastName: string | null,
    photo: string | null,
    namedUrl: string | null,
    specialization: string | null,
    shortQuote: string | null,
    isOnline?: boolean,
    lastTimezone?: string,
    workspaceId?: number
  ) {
    // @ts-expect-error TS2345
    super(id, firstName, lastName, photo, isOnline, lastTimezone, workspaceId);

    this.namedUrl = namedUrl || '';
    this.specialization = specialization || '';
    this.shortQuote = shortQuote;
  }
}
