import { Component, Inject } from '@angular/core';
import { PUI_IS_DESKTOP } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-alt-primary-layout',
  templateUrl: './alt-primary-layout.component.html',
  styleUrls: ['./alt-primary-layout.component.scss']
})
export class AltPrimaryLayoutComponent {
  constructor(@Inject(PUI_IS_DESKTOP) readonly isDesktop$: Observable<boolean>) {}
}
