import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  transform(seconds: number) {
    const dur = Duration.fromMillis(seconds * 1000);

    if (seconds / 60 / 60 > 24) {
      return 'more than 24 hours';
    }
    let format = `s's'`;
    if (seconds > 59) {
      format = `m'm' ${format}`;
    }
    if (seconds / 60 > 59) {
      format = `h'h' ${format}`;
    }
    return dur.toFormat(format);
  }
}
