import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModuleInstructor } from '@app/shared/interfaces/programs/client-programs';

@Component({
  selector: 'app-module-instructor',
  templateUrl: './module-instructor.component.html',
  styleUrls: ['./module-instructor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-instructor'
  }
})
export class ModuleInstructorComponent {
  @Input()
  // @ts-expect-error TS2564
  instructor: ModuleInstructor;
}
