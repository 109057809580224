import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { isPlatformBrowser } from '@angular/common';
import { ScriptService } from 'ngx-script-loader';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FileStackFile } from '@app/shared/interfaces/filestack';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  private readonly isBrowser: boolean;
  readonly signatureJsUrl = 'https://cdn.jsdelivr.net/npm/signature_pad@2.3.2/dist/signature_pad.min.js';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;

  constructor(
    private scriptService: ScriptService,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(GLOBAL_WINDOW) private browserWindow: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser && !this.browserWindow.SignaturePad) {
      this.scriptService.loadScript(this.signatureJsUrl).subscribe();
    }
  }

  // ATTENTION: this empty method is a hack to make angular injector create instance of this class, init logic left in constructor
  initialize(): void {}
}
