import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalCalendarsAccountsModalComponent } from '../components/external-calendars-accounts-modal/external-calendars-accounts-modal.component';

@Injectable()
export class ExternalCalendarsAccountsService {
  constructor(private readonly _modal: NgbModal) {}

  open(): void {
    this._modal.open(ExternalCalendarsAccountsModalComponent, {
      windowClass: 'external-calendars-accounts-modal'
    });
  }
}
