import { WidgetDirective } from '@app/cdk/widgets';
import { INavigationWidget } from '@app/modules/navigation/interfaces/navigation-widget';
import { Directive, HostListener } from '@angular/core';

@Directive()
export abstract class NavigationWidgetDirective extends WidgetDirective<INavigationWidget> {
  @HostListener('click')
  onClick(): void {
    // @ts-expect-error TS2532
    this.service.mode$.set('short');
  }
}
