import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { IHaveDateRange, prepareDateRange, formatDateRange } from '../utils/date';

@Pipe({
  name: 'sessionDateTime'
})
export class SessionDateTimePipe implements PipeTransform {
  private dateTimeLocale: string;

  constructor(private _localeService: LocaleService) {
    this.dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  transform(datesRangeProvider: IHaveDateRange | null): string | null {
    const dateRange = prepareDateRange(datesRangeProvider);

    if (!dateRange) {
      return null;
    }

    const [startDateTime, endDateTime] = dateRange;

    return formatDateRange(startDateTime, endDateTime, this.dateTimeLocale);
  }
}
