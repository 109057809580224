import { ModuleCompletionTypes } from '@app/shared/interfaces/programs/program-module';
import { BaseModule, IBaseModuleDetails } from './base-module';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseAccessibleModuleDetails extends IBaseModuleDetails {
  readonly completionType: ModuleCompletionTypes;
}

export abstract class BaseAccessibleModule extends BaseModule {
  readonly completionType: ModuleCompletionTypes;

  protected constructor(moduleDetails: Readonly<IBaseAccessibleModuleDetails>) {
    super(moduleDetails);

    this.completionType = moduleDetails.completionType;
  }
}
