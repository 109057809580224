/**
 * @deprecated
 * @param source
 * @param id
 */
export const makeUriFromString = (source: string, id: number | string): string => {
  if (source) {
    const uri = source
      .replace(/\s+|\(|\)|\\|\/|\|/g, '-')
      .replace(/--+/g, '-')
      .replace(/(^-+)|(-+$)/g, '');
    return `${id}-${encodeURI(uri.toLowerCase())}`;
  }
  return '';
};
