<ng-template [ngIf]="label && items.length > 0">
  <ng-template [ngIf]="items.length > 1" [ngIfThen]="multipleItems" [ngIfElse]="singleItem"></ng-template>
</ng-template>

<ng-template #multipleItems>
  <div class="attribute">
    <pui-icon class="attribute__icon" [svgIcon]="leftIcon" size="s"></pui-icon>
    <div class="attribute__label text-lowercase">
      <span
        pui-link
        role="button"
        class="text-lowercase"
        [pui-dropdown]="false"
        [pui-dropdown-content]="dropdownContent">
        {{ items.length }}{{ label }}
      </span>
    </div>
  </div>

  <ng-template #dropdownContent let-close="close">
    <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
      <button pui-list-item="button" pui-a11y-keyboard-widget-item *ngFor="let item of items" (click)="close()">
        <span>{{ item.label }}</span>
      </button>
    </div>
  </ng-template>
</ng-template>

<ng-template #singleItem>
  <div class="attribute">
    <pui-icon class="attribute__icon" [svgIcon]="leftIcon" size="s"></pui-icon>
    <div class="attribute__label">{{ items[0].label }}</div>
  </div>
</ng-template>
