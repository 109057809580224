import config from '@app/core/config/config';

export const API_PATH = config.apiPath;

export const AUTH_API = `${API_PATH}/auth`;
export const ALTERNATIVE_AUTH_PROVIDERS_ENDPOINT = `${AUTH_API}/alternative-providers`;
export const FACEBOOK_AUTH_ENDPOINT = `${ALTERNATIVE_AUTH_PROVIDERS_ENDPOINT}/facebook`;
export const GOOGLE_AUTH_ENDPOINT = `${ALTERNATIVE_AUTH_PROVIDERS_ENDPOINT}/google`;
export const SSO_AUTH_ENDPOINT = `${ALTERNATIVE_AUTH_PROVIDERS_ENDPOINT}/sso`;
export const SIGN_UP_ALTERNATIVE_PROVIDER = `${ALTERNATIVE_AUTH_PROVIDERS_ENDPOINT}/signup`;
export const GOOGLE_CALENDAR_AUTH_ENDPOINT = `${AUTH_API}/calendars/google`;
export const OUTLOOK_CALENDAR_AUTH_ENDPOINT = `${AUTH_API}/calendars/outlook`;

export const TFA_SETUP_API = `${API_PATH}/user/common/tfa`;

export const CLIENT_API = `${API_PATH}/user/client`;
export const CLIENT_PROFILE_ENDPOINT = `${CLIENT_API}/profile`;
export const CLIENT_SESSIONS_ENDPOINT = `${CLIENT_API}/sessions`;
export const CLIENT_CHAT_ENDPOINT = `${CLIENT_API}/chat`;
export const CLIENT_SESSIONS_FEEDBACKS_ENDPOINT = `${CLIENT_SESSIONS_ENDPOINT}/feedbacks`;
export const CLIENT_PAYMENTS_ENDPOINT = `${CLIENT_API}/payments`;
export const CLIENT_STRIPE_CREDIT_CARDS_ENDPOINT = `${CLIENT_API}/payments/stripe/cards`;
export const CLIENT_COUPONS_ENDPOINT = `${CLIENT_API}/coupons`;
export const CLIENT_WALLETS_ENDPOINT = `${CLIENT_API}/wallets`;

export const GUIDE_API = `${API_PATH}/user/guide`;
export const GUIDE_SCHEDULES_ENDPOINT = `${GUIDE_API}/schedules`;
export const GUIDE_SESSIONS_ENDPOINT = `${GUIDE_API}/sessions`;
export const GUIDE_EVENTS_ENDPOINT = `${GUIDE_API}/events`;
export const GUIDE_SESSIONS_TEMPLATE_ENDPOINT = `${GUIDE_API}/services/templates/sessions`;
export const GUIDE_PACKAGES_ENDPOINT = `${GUIDE_API}/packages`;
export const GUIDE_OFFERS_ENDPOINT = `${GUIDE_SESSIONS_ENDPOINT}/offers`;
export const GUIDE_GUESTS_ENDPOINT = `${GUIDE_SESSIONS_ENDPOINT}/guests`;
export const GUIDE_RECORDINGS_ENDPOINT = `${GUIDE_API}/recordings`;
export const GUIDE_AVAILABILITY = `${GUIDE_API}/availability`;
export const WORKSPACE_AVAILABILITY = `${GUIDE_AVAILABILITY}/workspace`;
export const GUIDE_PROFILE = `${GUIDE_API}/profile`;
export const GUIDE_CUSTOM_TAGS = `${GUIDE_API}/tags`;
export const GUIDE_UPLOADS = `${GUIDE_API}/uploads`;

export const PUBLIC_PROFILE_ENDPOINT = `${API_PATH}/public/profile`;

export const PUBLIC_PACKAGES_ENDPOINT = `${API_PATH}/public/packages`;

export const PUBLIC_WORKSPACE_ENDPOINT = `${API_PATH}/public/workspaces`;

export const USER_COMMON_API = `${API_PATH}/user/common`;
export const CHAT_FILES_UPLOADER_ENDPOINT = `${USER_COMMON_API}/chat-files`;
export const CALENDARS_ENDPOINT = `${USER_COMMON_API}/calendars`;
export const GOOGLE_CALENDAR_ACCOUNT_ENDPOINT = `${CALENDARS_ENDPOINT}/google`;
export const SUBSCRIPTIONS_ENDPOINT = `${USER_COMMON_API}/subscriptions`;

export const MEDIA_API = `${API_PATH}/media`;

export const WISTIA_VIDEOS_ENDPOINT = `${USER_COMMON_API}/videos`;
export const WISTIA_PROJECT_ENDPOINT = `${WISTIA_VIDEOS_ENDPOINT}/project`;

export const BRANDING_ENDPOINT = `${API_PATH}/public/branding`;
export const GUIDE_BRANDING_ENDPOINT = `${API_PATH}/public/guide-branding`;

export const MEMBERSHIP_ENDPOINT = `${API_PATH}/membership`;

export const RECORDINGS_ARCHIVE_ENDPOINT = `${API_PATH}/public/recordings/daily`;

export const NOTIFICATION_CONFIG_ENDPOINT = `${API_PATH}/user/admin/notifications-management`;
