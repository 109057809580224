import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { QuizProgress } from './quiz-progress';
import { BaseModuleProgress, IBaseModuleProgressDetails } from './base-module-progress';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizModuleProgressDetails extends IBaseModuleProgressDetails {
  readonly quiz?: QuizProgress;
}

export class QuizModuleProgress extends BaseModuleProgress {
  readonly quiz?: QuizProgress;

  get moduleType(): ModuleTypes {
    return ModuleTypes.QUIZ;
  }

  constructor(moduleDetails: Readonly<IQuizModuleProgressDetails>) {
    super(moduleDetails);

    if (moduleDetails.quiz) {
      this.quiz = moduleDetails.quiz;
    }
  }
}
