import { Color } from '../interfaces';

/**
 * Colors const to be replaced to value from cloud
 * @const {Color[]}
 */
const PRIMARY: Color[] = [
  { name: '50', hex: '#ffffff', contrast: { name: '50', hex: '#32495b' } },
  { name: '100', hex: '#edf7f9', contrast: { name: '100', hex: '#32495b' } },
  { name: '200', hex: '#e6f3f6', contrast: { name: '200', hex: '#32495b' } },
  { name: '300', hex: '#cde7ed', contrast: { name: '300', hex: '#32495b' } },
  { name: '400', hex: '#9ad0da', contrast: { name: '400', hex: '#32495b' } },
  { name: '500', hex: '#68b8c8', contrast: { name: '500', hex: '#32495b' } },
  { name: '600', hex: '#4facbf', contrast: { name: '600', hex: '#5b6d7c' } },
  { name: '700', hex: '#36a1b6', contrast: { name: '700', hex: '#5b6d7c' } },
  { name: '800', hex: '#1d95ad', contrast: { name: '700', hex: '#ffffff' } },
  { name: '900', hex: '#0389a4', contrast: { name: '900', hex: '#ffffff' } },
  { name: 'A100', hex: '#d1d1d1', contrast: { name: 'A100', hex: '#5b6d7c' } },
  { name: 'A200', hex: '#dde7ec', contrast: { name: 'A200', hex: '#5b6d7c' } },
  { name: 'A400', hex: '#dfe8ed', contrast: { name: 'A400', hex: '#5b6d7c' } },
  { name: 'A700', hex: '#FFFFFF', contrast: { name: 'A700', hex: '#ffffff' } }
];

export { PRIMARY };
