// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleDatesPartitionStrategy<TSchedule, TPartitionUnit> {
  partition(schedule: TSchedule, timezone: string): TPartitionUnit[];
}

export abstract class ScheduleDatesPartitionStrategy<TSchedule, TPartitionUnit>
  implements IScheduleDatesPartitionStrategy<TSchedule, TPartitionUnit>
{
  abstract partition(schedule: TSchedule, timezone: string): TPartitionUnit[];
}
