import { KitBrandingService } from '@app/modules/ui-kit/kit-branding/services';

/**
 * Used for inheritance, where we need to load variables
 * For example watch AppComponent
 * @export
 * @class AbsComponentWithBranding
 */
export class AbsComponentWithBranding {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected constructor(kitBrandingService: KitBrandingService) {
    // Temporary disabled before we implementing Theme Configurator
    // kitBrandingService.loadVariables();
  }
}
