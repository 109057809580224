import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { GuideScheduleEndpointService } from '@app/core/schedule/guide-schedule-endpoint.service';
import { ISchedule } from '@app/modules/schedule-boards';
import { ScheduleProvider } from './schedule-provider.service';
import { IPublicGuideScheduleOptions } from './types';
import { IServiceScheduleOptions } from '@app/core/schedule/types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Injectable()
export class PublicGuideScheduleProviderService extends ScheduleProvider<ISchedule, IPublicGuideScheduleOptions> {
  constructor(
    protected readonly _publicGuideScheduleApi: GuideScheduleEndpointService,
    private readonly runtimeConfig: RuntimeConfigService
  ) {
    super();
  }

  refresh(options: IPublicGuideScheduleOptions): void {
    const now = DateTime.local();

    const scheduleOptions: IServiceScheduleOptions = {
      guide: {
        id: options.guideId,
        // @ts-expect-error TS2322
        workspaceId: options.workspaceId
      },
      range: {
        start: now.toISO(),
        end: now.plus({ months: this.runtimeConfig.get('availabilityMaxBoundaryInMonths') }).toISO()
      },
      options: {
        duration: options.duration,
        exEvent: options.exEvent
      }
    };

    this._publicGuideScheduleApi
      .loadGuideSchedule$(scheduleOptions)
      .pipe(map(data => data.events))
      .subscribe(ranges => this._schedule$.next({ ranges }));
  }
}
