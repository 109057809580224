import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { PublicAssignee } from '@app/modules/services/interfaces';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-service-host',
  templateUrl: './service-host.component.html',
  styleUrls: ['./service-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceHostComponent {
  @Input() showBookButton = true;
  @Input() showLocation = true;
  @Input() host!: PublicAssignee;
  @Input() chatLink = '';
  @Input() bookingLink = '';

  constructor(
    @Inject(GLOBAL_WINDOW) private browserWindow: Window,
    @Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>
  ) {}

  redirect(): void {
    this.browserWindow.open(this.bookingLink, '_blank');
  }
}
