<ng-container *ngIf="showSocialAuth && !showSSOWelcome">
  <button
    *ngIf="(socialButtonConfig$ | async).isFacebookAuthEnabled"
    data-qa-id="facebook-auth-button"
    class="auth-provider-btn facebook-auth"
    (click)="openFacebookAuthPopup()">
    <span class="provider-icon"></span>
    <span class="text" i18n="@@signInWithFacebook">Sign in with Facebook</span>
  </button>

  <button
    *ngIf="(socialButtonConfig$ | async).isGoogleAuthEnabled"
    data-qa-id="google-auth-button"
    class="auth-provider-btn google-auth"
    (click)="openGoogleAuthPopup()">
    <span class="provider-icon"></span>
    <span class="text" i18n="@@signInWithGoogle">Sign in with Google</span>
  </button>
</ng-container>

<ng-container *ngIf="showSSOAuth">
  <span *ngIf="showSSOWelcome" class="sso-use-text" i18n="@@useSSOToSignIn">Please, use SSO to sign in.</span>
  <button
    data-qa-id="sso-auth-button"
    class="auth-provider-btn sso-auth"
    (click)="openSSOAuthPopup()"
    [class.sso-auth_padded]="showSSOWelcome">
    <span class="provider-icon"></span>
    <span class="text" i18n="@@signInWithSSO">Sign in with SSO</span>
  </button>
</ng-container>
