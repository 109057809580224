import { IMenuLink } from '@app/screens/admin/types';
import { getUrlType } from '@app/shared/utils/url';
import { UrlTypes } from '@app/shared/enums/url-types';

export const parseLink = (links: IMenuLink[]): IMenuLink[] => {
  return links.map(link => {
    if (link.children && link.children.length) {
      link.children = parseLink(link.children);
    }

    // @ts-expect-error TS2322
    link.urlType = getUrlType(link.url);

    const separator = `/`;

    const isMailTo = link.url?.startsWith('mailto:');

    if (link.urlType === UrlTypes.RELATIVE && link.url.charAt(0) !== separator && !isMailTo) {
      link.url = separator + link.url;
    }

    if (link.url.indexOf('?') !== -1 && link.urlType === UrlTypes.RELATIVE) {
      const [url, search] = link.url.split('?');
      const urlParams = new URLSearchParams(search);
      const queryParams = Object.fromEntries(urlParams);

      return {
        ...link,
        url,
        queryParams
      };
    }

    return link;
  });
};
