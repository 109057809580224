import { BillingService } from '@app/modules/billing/services';
import { BrandingService } from '@app/core/branding/branding.service';
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { SupportedCountries } from '@app/modules/billing/types';
import { takeUntil } from 'rxjs/operators';

@Directive()
abstract class AbsComponentWithBilling implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _enabled = false;

  // @ts-expect-error TS2564
  countries$: Observable<SupportedCountries>;

  // @ts-expect-error TS2564
  billingForm: FormGroup;

  protected constructor(
    protected readonly _billingService: BillingService,
    protected readonly _configProvider: BrandingService,
    protected readonly _cdr: ChangeDetectorRef
  ) {
    this._configProvider.globalConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ enablePaymentsRequiredTaxIdWithAddresses }) => {
        this._enabled = enablePaymentsRequiredTaxIdWithAddresses;
      });
  }

  get enabled(): boolean {
    return this._enabled;
  }

  ngOnInit(): void {
    this.countries$ = this._billingService.countries$;
    this._billingService
      .getBillingForm$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((billingForm: FormGroup) => {
        this.billingForm = billingForm;
        this._cdr?.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export { AbsComponentWithBilling };
