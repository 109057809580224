import { NotificationsService } from 'angular2-notifications';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { formatDate, JsonPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { LocaleService } from '@app/core/locale/locale.service';
import { LogService } from '@app/core/log/log.service';
import { LogType } from '@app/core/log/types';
import { ClientGuidesService } from '@app/core/users/client-guides.service';
import { ClientGuide } from '@app/core/users/types';
import { buildUsersGuideMapKey } from '@app/core/users/utils';
import { IClientGuideSession } from '@app/screens/client/client-dashboard/types/types';
import {
  prepareClientGuideSession,
  splitToKnownAndUnknownGuideSessions
} from '@app/screens/client/client-dashboard/utils/transformers';
import { GuidePackageService } from '@app/screens/guide/guide-packages/guide-package.service';
import { SessionTypes } from '@app/shared/enums/session-types';
import { SessionsTypes } from '@app/shared/enums/sessions-types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ISessionEvent } from '@app/shared/interfaces/event';
import { INotification } from '@app/shared/interfaces/notification';
import { Session } from '@app/shared/interfaces/session';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { ILocale } from '@env/locale.interface';

import { AuthService } from '../auth/services/auth.service';
import { ChatsService } from '../chat/chats.service';
import { SocketService } from '../socket/socket.service';
import {
  ACCEPT_SESSION_ERROR,
  ACCEPT_SESSION_OFFER_ERROR,
  acceptedEventRequestMessage,
  ARCHIVE_SESSION_ERROR,
  DECLINE_SESSION_OFFER_ERROR,
  END_SESSION_ERROR,
  getNotification,
  REFUSE_SESSION_ERROR,
  RESCHEDULE_APPROVEMENT_ERROR,
  RESCHEDULE_ERROR
} from './notifications';
import { SessionsApiService } from './sessions-api.service';
import { SessionsObservables } from './sessions-observables';

export enum SessionsSyncStates {
  OUT_OF_SYNC,
  SYNCING,
  SYNC_ERROR,
  IN_SYNC
}

interface AcceptSessionResult {
  count: number;
}

@Injectable()
export class SessionsService extends SessionsObservables {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessionsSyncState$ = new BehaviorSubject<SessionsSyncStates>(SessionsSyncStates.OUT_OF_SYNC);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _title = `Already cancelled.`;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _userRole: UserRoles | null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _locale: ILocale;

  // TODO: fast, hacky implementation
  get sessionsSyncState$(): Observable<SessionsSyncStates> {
    return this._sessionsSyncState$.asObservable();
  }

  constructor(
    private readonly _notificationsService: NotificationsService,
    private readonly _socketService: SocketService,
    private readonly _authService: AuthService,
    private readonly _logger: LogService,
    private readonly _chatsService: ChatsService,
    private readonly _toJson: JsonPipe,
    private readonly _analyticsService: AnalyticsService,
    private readonly _api: SessionsApiService,
    private _localeService: LocaleService,
    private readonly clientGuidesService: ClientGuidesService,
    private readonly _guidePackageService: GuidePackageService
  ) {
    super();
    this._setSubscriptions();
    this._locale = this._localeService.getLocale();
  }

  // @ts-expect-error TS2366
  getSessionTypeById(id: number): SessionsTypes {
    const sessionTypes: SessionsTypes[] = [
      SessionsTypes.OFFER,
      SessionsTypes.REQUEST,
      SessionsTypes.FUTURE,
      SessionsTypes.PAST,
      SessionsTypes.AVAILABLE
    ];
    for (const sessionType of sessionTypes) {
      if (this.findSession(id, sessionType)) {
        return sessionType;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startAcceptSessionOffer$(id: number): Observable<any> {
    const session = this.removeSession(id, SessionsTypes.OFFER);

    if (!session) {
      return this.acceptSessionOfferFailure$(null, session);
    }

    return of(session);
  }

  acceptSessionOfferFailure$(
    error: HttpErrorResponse | null,
    sessionRequestToRevert?: Session | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (sessionRequestToRevert) {
      this.addSession(sessionRequestToRevert, SessionsTypes.OFFER);
    }

    if (error) {
      this._logError('acceptSessionOfferFailure', error);
      // @ts-expect-error TS2345
      this._showError(getNotification(ACCEPT_SESSION_OFFER_ERROR));
    }

    return throwError((error && error.message) || getNotification(ACCEPT_SESSION_OFFER_ERROR));
  }

  acceptSessionOfferSuccess(session: Session): void {
    this.addSession(session, SessionsTypes.FUTURE);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  acceptSessionRequest$(sessionRequest: Session): Observable<any> {
    const session = this.removeSession(sessionRequest.id, SessionsTypes.REQUEST);

    if (!session) {
      return this._acceptSessionRequestFailure$(null);
    }

    return this._api.acceptSessionRequest$(sessionRequest.id).pipe(
      tap(
        (result: AcceptSessionResult) =>
          session &&
          this._acceptSessionRequestSuccess(session, { count: result.count, name: sessionRequest.name || '' })
      ),
      catchError(error => this._acceptSessionRequestFailure$(error, session))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approveReschedule$(id: number, sessionType: SessionsTypes): Observable<any> {
    const sessionToRevert = this.removeSession(id, sessionType);

    return this._api
      .approveReschedule$(id)
      .pipe(catchError(error => this.approveRescheduleFailure$(error, { sessionToRevert, sessionType })));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  archiveSession$(session: Session): Observable<any> {
    if (this._userRole === UserRoles.GUIDE && session.serviceType !== SessionTypes.SESSION) {
      return this._archiveGuideGroupSessionInstance$(session);
    }

    const sessionToRevert = this.removeSession(session.id, session.collectionType);

    if (!sessionToRevert) {
      return this._archiveSessionFailure$(null);
    }

    return this._api.archiveSession$(session.id).pipe(
      catchError(error =>
        this._archiveSessionFailure$(error, {
          sessionToRevert,
          serviceType: session.collectionType
        })
      )
    );
  }

  markAsMissed$(session: Session, description: string): Observable<void> {
    return this._api.markAsMissed$(session.eventId, description);
  }

  markAsAbsent$(id: number, description: string): Observable<void> {
    return this._api.markAsAbsent$(id, description);
  }

  markAsPresent$(eventId: number): Observable<void> {
    return this._api.markAsPresent$(eventId);
  }

  markAsCompleted$(session: Session): Observable<void> {
    return this._api.markAsCompleted$(session.eventId);
  }

  declineSessionOffer$(
    id: number,
    reason: string,
    sessionType: SessionsTypes,
    userId: number,
    session: Session,
    cancelAllRecurring: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (this._userRole === UserRoles.GUIDE && session.serviceType !== SessionTypes.SESSION) {
      return this._declineGuideGroupSessionInstance$(id, reason, userId, session, cancelAllRecurring);
    }
    const sessionToRevert = this.removeSession(id, sessionType);
    if (!sessionToRevert) {
      // @ts-expect-error TS2345
      this._showError(getNotification(DECLINE_SESSION_OFFER_ERROR));
      return throwError(getNotification(DECLINE_SESSION_OFFER_ERROR));
    }

    return this._api.declineSessionOffer$(id, reason).pipe(
      catchError(error => this._declineSessionOfferFailure$(error, { sessionToRevert, sessionType })),
      tap(() =>
        this._sendSessionCancellationChatMessage(
          buildDirectChatLinkId({ id: userId, workspaceId: session?.guide?.workspaceId }),
          session.dateStart,
          reason
        )
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  endSession$(eventId: number): Observable<any> {
    return this._api.endSession$(eventId).pipe(catchError(error => this._endSessionFailure$(error)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFeedbackReadySession$(profileUrl: string): Observable<any> {
    return this._api
      .getFeedbackReadySession$<{ sessionId: number }>(profileUrl)
      .pipe(map(session => session.sessionId));
  }

  refuseSession$(
    id: number,
    reason: string | null,
    sessionType: SessionsTypes,
    userId: number,
    session: Session,
    cancelAllRecurring = true
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (this._userRole === UserRoles.GUIDE && session.serviceType !== SessionTypes.SESSION) {
      return this._refuseGuideGroupSessionInstance$(id, reason, userId, session, cancelAllRecurring);
    }

    const sessionToRevert = this.removeSession(id, sessionType);

    if (!sessionToRevert) {
      return this._refuseSessionFailure$(null);
    }

    return this._api.refuseSession$(id, reason, cancelAllRecurring).pipe(
      catchError(error => this._refuseSessionFailure$(error, { sessionToRevert, sessionType })),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any = {}) => this._analyticsService.event(InternalEvents.CANCEL_SESSION, { session: data.session })),
      tap(() =>
        this._sendSessionCancellationChatMessage(
          buildDirectChatLinkId({ id: userId, workspaceId: session?.guide?.workspaceId }),
          session.dateStart,
          reason
        )
      )
    );
  }

  refresh(): void {
    this._loadSessions();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startRescheduleSession$(id: number, sessionType: SessionsTypes): Observable<any> {
    const session = this.removeSession(id, sessionType);

    if (!session) {
      return this.rescheduleSessionFailure$(null, { sessionToRevert: session, sessionType });
    }

    return of(session);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reschedule$(id: number, date: string, sessionType: SessionsTypes, message?: string): Observable<any> {
    // @ts-expect-error TS7034
    let sessionToRevert = null;

    return this.startRescheduleSession$(id, sessionType).pipe(
      tap(session => (sessionToRevert = session)),
      switchMap(() => {
        return (
          this._api
            // TODO add real value for timezone if this flow is calling from somewhere. Or remove rid code
            .reschedule$(id, date, 'timeZone', message)
            // @ts-expect-error TS2322
            .pipe(catchError(error => this.rescheduleSessionFailure$(error, { sessionToRevert, sessionType })))
        );
      })
    );
  }

  rescheduleSessionFailure$(
    error?: HttpErrorResponse | null,
    revertDetails?: { sessionToRevert: Session; sessionType: SessionsTypes }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (revertDetails) {
      const { sessionToRevert, sessionType } = revertDetails;
      this.addSession(sessionToRevert, sessionType);
    }

    if (error) {
      this._logError('rescheduleSessionFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(RESCHEDULE_ERROR));

    return throwError(error || getNotification(RESCHEDULE_ERROR));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendFeedback$(sessionId: number, mark: number, comment?: string, privateComment?: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const feedback = { sessionId, mark } as any;

    if (comment) {
      feedback.comment = comment;
    }

    if (privateComment) {
      feedback.privateComment = privateComment;
    }

    return this._api.sendFeedback$(feedback).pipe(
      catchError(error => {
        const title = `Cannot send a session feedback`;
        this._notificationsService.error(title);
        return throwError(error || 'Cannot send a session feedback');
      })
    );
  }

  getSessionsByEventUuid$(eventUuid: string): Observable<ISessionEvent> {
    return this._api.getEventByUuid$(eventUuid);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  addSessionChat(sessionId) {
    return this._api.addSessionChat$(sessionId);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _acceptSessionRequestFailure$(
    error: HttpErrorResponse | null,
    sessionRequestToRevert?: Session | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (sessionRequestToRevert) {
      this.addSession(sessionRequestToRevert, SessionsTypes.REQUEST);
    }

    if (error) {
      this._logError('acceptSessionRequestFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(ACCEPT_SESSION_ERROR));

    return throwError(error || getNotification(ACCEPT_SESSION_ERROR));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _acceptSessionRequestSuccess(session: Session, result: { name: string; count: number }): void {
    const successMsg = acceptedEventRequestMessage(result.name, result.count);
    this._notificationsService.success(successMsg);
    if (session.serviceType === SessionTypes.SESSION) {
      this.addSession(session, SessionsTypes.FUTURE);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private approveRescheduleFailure$(
    error: HttpErrorResponse | null,
    revertDetails?: { sessionToRevert: Session; sessionType: SessionsTypes } | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (revertDetails) {
      const { sessionToRevert, sessionType } = revertDetails;
      this.addSession(sessionToRevert, sessionType);
    }

    if (error) {
      this._logError('rescheduleApprovementFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(RESCHEDULE_APPROVEMENT_ERROR));

    return throwError(error || getNotification(RESCHEDULE_APPROVEMENT_ERROR));
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _archiveGuideGroupSessionInstance$(session): Observable<any> {
    return this._api.archiveSession$(session.id);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _archiveSessionFailure$(
    error: HttpErrorResponse | null,
    revertDetails?: { sessionToRevert: Session; serviceType: SessionsTypes } | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (revertDetails) {
      const { sessionToRevert, serviceType } = revertDetails;
      this.addSession(sessionToRevert, serviceType);
    }

    if (error) {
      this._logError('archiveSessionFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(ARCHIVE_SESSION_ERROR));

    return throwError(error || getNotification(ARCHIVE_SESSION_ERROR));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _declineGuideGroupSessionInstance$(
    id: number,
    reason: string,
    userId: number,
    session: Session,
    cancelAllRecurring: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this._api.declineSessionOffer$(id, reason, cancelAllRecurring).pipe(
      // @ts-expect-error TS2345
      catchError(error => this._declineSessionOfferFailure$(error, null)),
      tap(() =>
        this._sendSessionCancellationChatMessage(
          buildDirectChatLinkId({ id: userId, workspaceId: session?.guide?.workspaceId }),
          session.dateStart,
          reason
        )
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _declineSessionOfferFailure$(
    error: HttpErrorResponse | null,
    details?: { sessionToRevert: Session; sessionType: SessionsTypes }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (error?.error.msg) {
      this._notificationsService.error(getNotification(DECLINE_SESSION_OFFER_ERROR)?.title, error.error.msg);

      return EMPTY;
    }

    if (error && error.error && error.error.payload && error.error.payload.actionIsAlreadyExecuted) {
      this._notificationsService.warn(error.error.msg || this._title);

      return EMPTY;
    }

    if (details) {
      const { sessionToRevert, sessionType } = details;
      this.addSession(sessionToRevert, sessionType);
    }

    if (error) {
      this._logError('declineSessionOfferFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(DECLINE_SESSION_OFFER_ERROR));

    return throwError(error || getNotification(DECLINE_SESSION_OFFER_ERROR));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _endSessionFailure$(error: HttpErrorResponse | null): Observable<any> {
    if (error) {
      this._logError('endSessionFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(END_SESSION_ERROR));

    return throwError(error);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _loadSessions(): void {
    this._sessionsSyncState$.next(SessionsSyncStates.SYNCING);
    this._api
      .loadSessions$()
      .pipe(catchError(error => this._loadSessionsFailure$(error)))
      .subscribe(({ sessions }) => {
        if (sessions != null) {
          this.updateSessions(sessions);
        }
        this._sessionsSyncState$.next(SessionsSyncStates.IN_SYNC);
      });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _loadSessionsFailure$(error: HttpErrorResponse): Observable<any> {
    this._sessionsSyncState$.next(SessionsSyncStates.SYNC_ERROR);
    return throwError(error);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _logError(methodName: string, error: any, details?: any): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorLog = { error, location: `SessionsService.${methodName}` } as any;
    if (details) {
      errorLog.details = details;
    }
    this._logger.sendLog(LogType.ERROR, this._toJson.transform(errorLog));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _refuseGuideGroupSessionInstance$(
    id: number,
    reason: string | null,
    userId: number,
    session: Session,
    cancelAllRecurring: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this._api.refuseSession$(id, reason, cancelAllRecurring).pipe(
      // @ts-expect-error TS2345
      catchError(error => this._refuseSessionFailure$(error, null)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any = {}) => this._analyticsService.event(InternalEvents.CANCEL_SESSION, { session: data.session })),
      tap(() =>
        this._sendSessionCancellationChatMessage(
          buildDirectChatLinkId({ id: userId, workspaceId: session?.guide?.workspaceId }),
          session.dateStart,
          reason
        )
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _refuseSessionFailure$(
    error: HttpErrorResponse | null,
    details?: { sessionToRevert: Session; sessionType: SessionsTypes }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    if (error?.error.msg) {
      this._notificationsService.error(getNotification(DECLINE_SESSION_OFFER_ERROR)?.title, error.error.msg);

      return EMPTY;
    }

    if (error && error.error && error.error.payload && error.error.payload.actionIsAlreadyExecuted) {
      this._notificationsService.warn(error.error.msg || this._title);

      return EMPTY;
    }

    if (details) {
      const { sessionToRevert, sessionType } = details;
      this.addSession(sessionToRevert, sessionType);
    }

    if (error) {
      this._logError('refuseSessionFailure', error);
    }

    // @ts-expect-error TS2345
    this._showError(getNotification(REFUSE_SESSION_ERROR));

    return throwError(error || getNotification(REFUSE_SESSION_ERROR));
  }

  // TODO: better send on server
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sendSessionCancellationChatMessage(userId: string, dateStart: string, reason?: string | null): void {
    this._chatsService.startChat$(userId).subscribe(({ id: chatId }) => {
      const time = formatDate(dateStart, 'MMMM d', this._locale.dateTimeLocale);
      const cancellationReason = reason && reason.trim().length ? ` with this note: ${reason.trim()}` : '';
      const chatMessage = `Sorry to say that your session on ${time} just got cancelled${cancellationReason}. This is an automated message.`;
      this._chatsService.sendMessage(chatId, chatMessage, undefined, { notDuplicateInEmail: true });
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _setSubscriptions(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._authService.onAuth().subscribe((user: any) => {
      if (user && user.RoleId !== UserRoles.ADMIN && user.RoleId !== UserRoles.SUPER_ADMIN) {
        this._userRole = user.RoleId;
        this._loadSessions();
      } else {
        this._userRole = null;
      }
    });
    this._socketService.onSessionChanged().subscribe(() => {
      this.refresh();
      this._guidePackageService.loadClients();
    });
    this._socketService.onPackageEnrolled().subscribe(() => {
      this.refresh();
    });
    this.expire$.subscribe(() => this.refresh());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _showError(error?: INotification) {
    if (error) {
      const { title, message } = error;
      this._notificationsService.error(title, message);
    }
  }

  initializeSessionObservable$(
    source$: Observable<Session[]>,
    clientGuidesMap$: Observable<{ [p: number]: ClientGuide }>
  ): Observable<IClientGuideSession[]> {
    return source$.pipe(
      switchMap(source =>
        clientGuidesMap$.pipe(
          take(1),
          map(guidesMap => [source, guidesMap] as [Session[], { [id: number]: ClientGuide }])
        )
      ),
      map(([sessions, guidesMap]) => prepareClientGuideSession(sessions, DateTime.local().zoneName, guidesMap)),
      map(sessions => splitToKnownAndUnknownGuideSessions(sessions)),
      tap(({ unknownGuideSessions }) => this.checkUnknownGuidesIfRequired(unknownGuideSessions)),
      map(({ knownGuideSessions }) => knownGuideSessions)
    );
  }

  private checkUnknownGuidesIfRequired(unknownGuideSessions: IClientGuideSession[]): void {
    if (!unknownGuideSessions || !unknownGuideSessions.length) {
      return;
    }

    this.clientGuidesService.checkNewUsers(
      unknownGuideSessions.map(({ session }) => {
        return buildUsersGuideMapKey({
          id: session.guideId,
          // @ts-expect-error TS2531
          workspaceId: session.workspace?.id || session.guide.workspaceId
        });
      })
    );
  }
}
