<app-service-badge
  *ngIf="clientSession.session.serviceParent?.id"
  [service]="clientSession.session.serviceParent"></app-service-badge>

<div class="main-content">
  <app-user-info
    class="session-lg-left-col user-info"
    [user]="clientSession.guide"
    [uiPayload]="chatLink"
    [routerLink]="['/', clientSession.guide?.namedUrl]"></app-user-info>

  <ng-template #chatLink let-user="$implicit">
    <a [routerLink]="['/', 'client', 'chat', buildDirectChatId(user)]" class="user-chat-link">
      <span class="user-chat-link-icon"></span>
      <span class="user-chat-link-text" i18n="@@sendMsg">Send message</span>
    </a>
  </ng-template>

  <div class="session-lg-center-col dashboard-session-info with-separator">
    <div class="date">
      <ng-template [ngIf]="!clientSession.session.isExpired" [ngIfElse]="expired">
        <ng-container *ngIf="clientSession.session.expiresAt">
          <ng-container i18n="@@sessionExpires">Expires</ng-container>
          &nbsp;{{ clientSession.session.expiresAt | platformDateTime: 'DATE, TIME' }}
        </ng-container>
      </ng-template>
      <ng-template #expired>
        <ng-container i18n="@@sessionExpired">Expired</ng-container>
      </ng-template>
    </div>
    <div
      [attr.data-qa-id]="'available-client-session-session-name-title-' + clientSession.session.name"
      class="session-name">
      {{ clientSession.session.name }}
    </div>
  </div>

  <div class="session-lg-right-col with-separator">
    <app-button className="btn-link" (click)="!clientSession.session.isExpired && book()">
      <ng-container i18n="@@chooseTime">Choose time</ng-container>
    </app-button>
  </div>
</div>
