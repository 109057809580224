import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '@app/core/auth/services/auth.service';
import { isUnAuthorisedReschedulingApplicant, IValidationStrategy, ReschedulingSessionOptions } from '../types';

@Injectable({
  providedIn: 'root'
})
export class PreReschedulingValidationStrategy implements IValidationStrategy<ReschedulingSessionOptions, void> {
  constructor(private readonly _auth: AuthService) {}

  validate$(reschedulingOptions: ReschedulingSessionOptions): Observable<void> {
    // @ts-expect-error TS2322
    return this.validate(reschedulingOptions) ? of(null) : throwError(null);
  }

  protected validate(reschedulingOptions: ReschedulingSessionOptions): boolean {
    if (isUnAuthorisedReschedulingApplicant(reschedulingOptions.applicant)) {
      return !!reschedulingOptions.applicant.token;
    }

    return this._auth.isAuthorized;
  }
}
