<ng-container *ngIf="selectedRelation$ | async as guideRelation; else loading">
  <app-guide-client-view
    *ngIf="guideRelation"
    [guideRelation]="guideRelation"
    [selectedTabId]="ref?.data?.tab || 0"
    (offer)="createOffer(guideRelation)"
    (back)="back()"></app-guide-client-view>
</ng-container>

<ng-template #loading>... loading</ng-template>
