import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { PHRASES } from '../../_base/_common/localize';
import { AppFormFieldControl } from '../../form/components/form-field/form-field.component';

@Component({
  selector: 'ui-client-selector-single',
  templateUrl: 'template.html',
  styleUrls: ['../ui-client-selector-multiple/client-selector.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-client-selector'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => UiClientSelectorSingle) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiClientSelectorSingle implements ControlValueAccessor, AppFormFieldControl, OnDestroy {
  readonly text = PHRASES;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private readonly _clients$: BehaviorSubject<any[]>;

  readonly controlType = 'client-selector';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clients$: Observable<any[]>;

  @Input()
  // @ts-expect-error TS2564
  canAdd: boolean;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
      this._onTouched();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get clients(): any[] {
    return this._clients;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set clients(clients: any[]) {
    if (clients !== this._clients) {
      this._clients = clients;
      this._clients$.next(clients);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _clients: any[] = [];

  @Input()
  required = false;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientOptionTemplateRef: TemplateRef<any> | undefined;

  @Input()
  placeholder: string = this.text['Choose client'];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('nothing-found-text')
  // @ts-expect-error TS2564
  nothingFoundText: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('autocomplete-connected-to')
  // @ts-expect-error TS2564
  autocompleteConnectedTo: MatAutocompleteOrigin;

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getOptionLabel() {
    return this._getOptionLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getOptionLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getOptionLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getOptionLabel = (option: any) => option?.name || option?.email || option?.contacts?.email;

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get displayWith() {
    return this._displayWith;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set displayWith(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._displayWith = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _displayWith = (option: any) => option?.name || option?.email || option?.contacts?.email;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? !!this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering,@typescript-eslint/naming-convention
  protected _disabled = false;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() typed: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() addClient: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  stateChanges = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/member-ordering
  focused: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this._clients$ = new BehaviorSubject([]);
    this.clients$ = this._clients$.asObservable();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPanelOpen(): void {
    this.focused = true;
    this.stateChanges.next();
  }

  onPanelClose(): void {
    this.focused = false;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddClient(value: string | any): void {
    if (typeof value !== 'string') {
      return;
    }

    this.addClient.emit(value);
  }
}
