import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-primary-layout-header',
  templateUrl: './primary-layout-header.component.html',
  styleUrls: ['./primary-layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryLayoutHeaderComponent {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('toggle-sidenav') toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
}
