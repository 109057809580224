<form
  class="billing-form"
  [ngClass]="{ 'billing-form--modal': isModal }"
  [formGroup]="billingForm"
  (ngSubmit)="handleSubmit()">
  <div class="row-m0">
    <div class="col-lg col-main">
      <div class="form-row">
        <div class="col">
          <label class="required" i18n="@@billingFormCountry">Country</label>
          <app-form-field #field>
            <ui-country-selector
              appInput
              formControlName="country"
              [autocompleteConnectedTo]="field.origin"
              (ngModelChange)="handleCountryChange($event)"
              [countries]="countries"></ui-country-selector>
          </app-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="row-m0">
    <div class="col-lg col-main">
      <div class="form-row align-items-end">
        <div class="col-md">
          <label class="required" i18n="@@billingFormAddressLine1">Address line 1</label>
          <input
            type="text"
            formControlName="addressLine1"
            class="form-control"
            (change)="trimValue(billingForm.controls['addressLine1'])" />
        </div>
      </div>
    </div>
  </div>

  <div class="row-m0">
    <div class="col-lg col-main">
      <div class="form-row align-items-end">
        <div class="col-md" [ngClass]="{ 'col-md-12': isModal }">
          <label i18n="@@billingFormAddressLine2">Address line 2</label>
          <input type="text" formControlName="addressLine2" class="form-control" />
        </div>
        <div class="col-md" [ngClass]="{ 'col-md-12': isModal }">
          <label i18n="@@billingFormAddressLine3">Address line 3</label>
          <input type="text" formControlName="addressLine3" class="form-control" />
        </div>
      </div>
    </div>
  </div>

  <div class="row-m0">
    <div class="col-lg col-main">
      <div class="form-row align-items-end">
        <ng-container *ngIf="isModal; then modalView; else pageView"></ng-container>
        <ng-template #modalView>
          <div class="col-md" [ngClass]="{ 'col-md-12': isModal }">
            <label class="required" i18n="@@billingFormCity">City</label>
            <input
              type="text"
              formControlName="city"
              class="form-control"
              (change)="trimValue(billingForm.controls['city'])" />
          </div>

          <div class="col-md-6 default-margin">
            <label class="required" i18n="@@billingFormPostalCode">Postal code</label>
            <input
              type="text"
              formControlName="postalCode"
              class="form-control"
              (change)="trimValue(billingForm.controls['postalCode'])" />
          </div>

          <div class="col-md-6 default-margin">
            <label i18n="@@billingFormTaxID" [ngClass]="{ required: isRequired('taxId') }">Tax ID</label>
            <input
              type="text"
              formControlName="taxId"
              class="form-control"
              (change)="trimValue(billingForm.controls['taxId'])" />
          </div>
        </ng-template>
        <ng-template #pageView>
          <div class="col-md-6">
            <label class="required" i18n="@@billingFormCity">City</label>
            <input
              type="text"
              formControlName="city"
              class="form-control"
              (change)="trimValue(billingForm.controls['city'])" />
          </div>

          <div class="col-md-6">
            <label class="required" i18n="@@billingFormPostalCode">Postal code</label>
            <input
              type="text"
              formControlName="postalCode"
              class="form-control"
              (change)="trimValue(billingForm.controls['postalCode'])" />
          </div>

          <div class="col-md-12 default-margin">
            <label i18n="@@billingFormTaxID" [ngClass]="{ required: isRequired('taxId') }">Tax ID</label>
            <input
              type="text"
              formControlName="taxId"
              class="form-control"
              (change)="trimValue(billingForm.controls['taxId'])" />
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="row-m0">
    <div class="col-lg col-main">
      <div class="form-buttons margin-top-36">
        <div
          class="tooltip-wrapper"
          [pui-tooltip]="billingForm.invalid ? 'Please complete all required fields' : null"
          i18n-pui-tooltip="{{billingForm.invalid ? '@@billingFormRequiredFields' : null}}"
          placement="top">
          <app-button
            className="btn-primary btn-md"
            buttonType="submit"
            i18n="{{i18nBtnText}}"
            [disabled]="billingForm.invalid">
            {{ btnText }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</form>
