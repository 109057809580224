<div class="service-host__wrapper">
  <div class="service-host__header">
    <pui-avatar
      class="service-host__header-avatar"
      [avatarUrl]="host.photo"
      [text]="host | userName"
      [size]="56"></pui-avatar>
    <div class="service-host__header-data">
      <div class="service-host__header-data-row">
        <span pui-typography="body-m-bold">{{ host | userName }}</span>
      </div>
      <div *ngIf="host.specialization" class="service-host__header-data-row">
        <small pui-typography="body-s">{{ host.specialization }}</small>
      </div>
      <div *ngIf="showLocation && host.connectionType" class="service-host__header-data-row">
        <small pui-typography="body-s">
          <pui-icon svgIcon="pui:place" size="s" style="vertical-align: top"></pui-icon>
          <span>
            {{ host.connectionType | sessionPlatform: host.address }}
          </span>
        </small>
      </div>
    </div>
  </div>

  <!--TEXT-->
  <div class="service-host__quote">
    <p pui-typography="body-s">
      {{ host.shortQuote }}
    </p>
  </div>

  <!--ACTIONS-->
  <div class="service-host__actions">
    <div>
      <button
        pui-button
        role="link"
        *ngIf="showBookButton"
        (click)="redirect()"
        [size]="(isMobile$ | async) ? 's' : 'm'">
        <pui-icon svgIcon="pui:calendar"></pui-icon>
        <span i18n="@@serviceHostBookSession">Book session</span>
      </button>
    </div>
  </div>
</div>
