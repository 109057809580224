import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';

import { ScheduleOptions } from '../../types';
import { filterEmptyDaysFromScheduleTemplate } from './converters';
import { GuideScheduleBlocksTemplatesService } from './guide-schedule-blocks-templates.service';
import { BlockedInterval, GuideScheduleBlocksService } from './guide-schedule-blocks.service';
import { GuideScheduleOptionsApiService } from './guide-schedule-options-api.service';

@Injectable()
export class GuideScheduleEditorService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _schedule$ = new ReplaySubject<ScheduleOptions>(1);

  private readonly destroy$ = new Subject();

  get schedule$(): Observable<ScheduleOptions> {
    return this._schedule$.asObservable();
  }

  constructor(
    private readonly _auth: AuthService,
    private readonly _scheduleBlocks: GuideScheduleBlocksService,
    private readonly _scheduleBlockTemplates: GuideScheduleBlocksTemplatesService,
    private readonly _scheduleOptionsApi: GuideScheduleOptionsApiService
  ) {
    this._auth
      .onAuth()
      .pipe(
        filter(user => user && user.RoleId === UserRoles.GUIDE),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._scheduleBlocks.refresh();
        this.refreshSchedule();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getOverlappedIntervals(rangeStart: Date, rangeEnd: Date): BlockedInterval[] {
    return this._scheduleBlocks.getOverlappedIntervals(rangeStart, rangeEnd);
  }

  refreshSchedule(): void {
    this.getScheduleOptions().subscribe(scheduleTemplate => {
      this._schedule$.next(scheduleTemplate);
      this._scheduleBlocks.refresh();
    });
  }

  removeScheduleBlock(id: string): void {
    this._scheduleBlockTemplates.removeTemplate$(id).subscribe(() => this._scheduleBlocks.refresh());
  }

  private getScheduleOptions(): Observable<ScheduleOptions> {
    return this._scheduleOptionsApi.getScheduleOptions$().pipe(map(filterEmptyDaysFromScheduleTemplate));
  }
}
