import { Inject, Injectable, InjectionToken } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { LocaleService } from '@app/core/locale/locale.service';
// import { locale } from '@env/locale';
//
// const dateTimeLocale = locale.dateTimeLocale;

export const CUSTOM_NGB_DATE_PARSER_CONFIG = new InjectionToken('CustomNgbDateParserConfigToken');

@Injectable()
export class NgbCustomDateParserFormatter extends NgbDateParserFormatter {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _dateStringFormat: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _dateTimeLocale: string;

  constructor(@Inject(CUSTOM_NGB_DATE_PARSER_CONFIG) private _format: string, private _localeService: LocaleService) {
    super();
    this._dateStringFormat = this._format || 'yyyy-MM-dd';
    this._dateTimeLocale = this._localeService.getLocale().dateTimeLocale;
  }

  format(dateStruct: NgbDateStruct): string {
    if (!dateStruct) {
      // @ts-expect-error TS2322
      return null;
    }

    const dateTime = DateTime.fromObject(dateStruct);

    return formatDate(dateTime.toISO(), this._dateStringFormat, this._dateTimeLocale);
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      // @ts-expect-error TS2322
      return null;
    }

    const date = DateTime.fromFormat(value, this._dateStringFormat);
    if (!date.isValid) {
      // @ts-expect-error TS2322
      return null;
    }

    const { year, month, day } = date;
    return { year, month, day };
  }
}
