import { Injectable } from '@angular/core';
import { BookSessionService } from '@app/modules/book-session/services/book-session.service';
import { ScheduleDatesStore } from '@app/modules/book-session/services/schedule-dates-store';
import { IGuideScheduleRange, ISessionTimeFrame } from '@app/modules/book-session/services/types';
import { formatDate } from '@angular/common';
// import { locale } from '@env/locale';
import { DateTime } from 'luxon';
import { LocaleService } from '@app/core/locale/locale.service';

@Injectable()
export class GuideAvailabilityCalculatorService extends BookSessionService {
  SESSION_DURATIONS = [1]; // NOTE: prevents next day crossover, see original updateScheduleTz
  // @ts-expect-error TS2564
  roundRangeStart: (rangeStart: DateTime, duration?: number) => DateTime | null;
  // @ts-expect-error TS2564
  roundTimeFrameStart: (duration: number) => number | null;

  get sessionTimeFrames(): ISessionTimeFrame[] {
    return this._sessionTimeFrames;
  }

  constructor(private _scheduleDates: ScheduleDatesStore, localeService: LocaleService) {
    super(_scheduleDates, localeService);
  }

  protected generateSessionTimeFramesPerScheduleRange(
    scheduleRange: IGuideScheduleRange,
    duration: number
  ): ISessionTimeFrame[] {
    const timeFrames = [];

    const scheduleRangeStart = this.dateTimeFromISO(scheduleRange.dateStart);
    const scheduleRangeEnd = this.dateTimeFromISO(scheduleRange.dateEnd);

    const timeFrameDateTime = this.dateTimeFromISO(scheduleRange.dateStart);

    let timeFrameStart =
      (this.roundRangeStart && this.roundRangeStart(timeFrameDateTime, duration)) || timeFrameDateTime;
    let timeFrameEnd = timeFrameStart.plus({ [this.TIME_UNIT]: duration });

    while (scheduleRangeStart.hasSame(timeFrameStart, 'day') && timeFrameEnd <= scheduleRangeEnd) {
      const label = formatDate(
        timeFrameStart.toISO(),
        'h:mma',
        this.locale.dateTimeLocale,
        timeFrameStart.toFormat('ZZZ')
      )
        .replace(/:00/g, '')
        .toLowerCase();

      timeFrames.push({ label, duration, value: timeFrameStart.toISO() });

      timeFrameStart = timeFrameStart.plus({
        [this.TIME_UNIT]: (this.roundTimeFrameStart && this.roundTimeFrameStart(duration)) || duration
      });

      timeFrameEnd = timeFrameStart.plus({ [this.TIME_UNIT]: duration });
    }

    return timeFrames;
  }
}
