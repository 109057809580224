<h2 class="form-title" i18n="@@proposeAService">Propose a service</h2>

<form [formGroup]="guideOfferForm" class="form">
  <div class="form-group" *ngIf="scheduleSessionService.isTeamAdmin">
    <label for="team-members" class="label top-label" i18n="@@chooseHostLabel">Host</label>
    <ng-select
      id="team-members"
      formControlName="host"
      [searchable]="false"
      [clearable]="false"
      [items]="scheduleSessionService.hosts | async | orderBy: 'username'"
      bindLabel="username"
      placeholder="Choose host"
      i18n-placeholder="@@chooseHostSession"></ng-select>
  </div>

  <div class="form-group">
    <label class="label" for="guide_offer_service" i18n="@@guideOfferServiceLabel">Service</label>

    <ng-select
      id="guide_offer_service"
      formControlName="service"
      [items]="scheduleSessionService.sessions | async"
      bindLabel="name"
      [clearable]="false"
      [searchable]="false"
      [readonly]="disableService"></ng-select>
  </div>

  <ng-container *ngIf="shouldShowRestScheduleOptions">
    <div class="form-group" *ngIf="!hidePrice">
      <div class="price-row">
        <div class="input-group">
          <input formControlName="price" type="number" min="0" inputmode="decimal" class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text">{{ currency.defaultCurrencySign }}</span>
          </div>
        </div>

        <p class="price-note" i18n="@@guideOfferPriceNote">You may change the price of the service</p>
      </div>
    </div>

    <div class="form-group date-form-group" [class.show]="isDateRequired">
      <app-event-date-selector
        formControlName="date"
        [canRepeat]="false"
        [timezones]="scheduleSessionService.timezones"
        [timeslots]="scheduleSessionService.timeslots"
        [template]="template$ | async"
        class="event-date-selector"
        (dateChange)="scheduleSessionService.dateChange$.next($event)"></app-event-date-selector>
    </div>

    <div class="form-group">
      <label class="label" for="guide_offer_message" i18n="@@guideOfferMessageLabel">Message to client</label>
      <textarea
        id="guide_offer_message"
        class="form-control message"
        formControlName="message"
        autosize
        [minRows]="MESSAGE_MIN_ROWS"
        [maxRows]="MESSAGE_MAX_ROWS"></textarea>

      <app-form-control-error [control]="guideOfferForm.get('message')" i18n="@@guideOfferMessageError">
        Max message length is {{ MESSAGE_MAX_LENGTH }}
      </app-form-control-error>
    </div>
  </ng-container>

  <div class="buttons">
    <app-button
      className="btn-main"
      (click)="submitOffer()"
      [disabled]="guideOfferForm.invalid || !guideOfferForm.get('date')?.value?.date"
      i18n="@@sendInvite">
      Send invite
    </app-button>
  </div>
</form>
