import { Injectable, OnDestroy } from '@angular/core';
import { from, Observable, ReplaySubject, throwError } from 'rxjs';
import { IEventActionResult } from '@app/core/shared-event-actions/types';
import { SwitchToAlternativeAccountConfirmModalComponent } from '@app/shared/components/switch-to-alternative-account-confirm-modal/switch-to-alternative-account-confirm-modal.component';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRoles } from '@app/shared/enums/user-roles';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services';
import { LocalStorageKeys } from '@app/cdk/enums';

@Injectable()
export class SharedEventActionsMediatorService implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _newEventActionResult$: ReplaySubject<IEventActionResult>;

  get newEventActionResult$(): Observable<IEventActionResult> {
    return this._newEventActionResult$.asObservable();
  }

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _modal: NgbModal,
    private readonly _authService: AuthService
  ) {
    this.reset();
  }

  ngOnDestroy(): void {
    this._newEventActionResult$.complete();
  }

  setNewEventActionResult(eventActionResult: IEventActionResult): void {
    this._newEventActionResult$.next(eventActionResult);
  }

  reset(): void {
    this._newEventActionResult$ = new ReplaySubject<IEventActionResult>(1);
  }

  showSwitchRoleModal(role: UserRoles, code: string): Observable<void> {
    this._localStorage.setItem(LocalStorageKeys.SHARED_EVENT_ACTION_CODE, code);
    const { componentInstance, result } = this._modal.open(SwitchToAlternativeAccountConfirmModalComponent, {
      windowClass: 'switch-modal',
      centered: true
    });

    componentInstance.role = role;

    return from(result).pipe(
      catchError(err => {
        this._localStorage.removeItem(LocalStorageKeys.SHARED_EVENT_ACTION_CODE);
        return throwError(err);
      }),
      switchMap(() => this._authService.signinAlternativeAccount(false))
    );
  }
}
