<ng-container
  *ngIf="{
    session: session$ | async,
    bookings: bookings$ | async,
    loading: isSending$ | async,
    bookingsSelected: bookingsSelected$ | async
  } as data">
  <div class="booking-cancellation__container" *ngIf="data.session && data.bookings">
    <!--TITLE-->
    <div class="booking-cancellation__row booking-cancellation__header" data-qa-id="booking-cancellation-header">
      <h3 pui-typography="h3">{{ getTitle(data.session) }}</h3>
    </div>

    <form [formGroup]="form" class="booking-cancellation__row delete-participant__content">
      <ng-container *ngIf="isSelectSessionBlock(data.session, data.bookings)">
        <div class="d-flex flex-column pui-space_bottom-5">
          <label pui-input-radio formControlName="cancellationType" [value]="CancellationType.CURRENT">
            <span i18n="@@ThisSession:This session">This session</span>
          </label>
          <label pui-input-radio formControlName="cancellationType" [value]="CancellationType.RECURRING">
            <span i18n="@@ThisAndFollowingSessions:This and following sessions">This and following sessions</span>
          </label>
        </div>
      </ng-container>

      <!--DETAILS-->
      <div class="booking-cancellation__row booking-cancellation__details">
        <app-session-details [service]="data.session" [bookings]="data.bookingsSelected!"></app-session-details>
      </div>

      <!--FORM-->
      <div class="booking-cancellation__row booking-cancellation__form" data-qa-id="booking-cancellation-reason-title">
        <label pui-input-label>{{ reasonLabel$ | async }}</label>
        <pui-input-field>
          <textarea
            [style.resize]="'none'"
            formControlName="message"
            data-qa-id="booking-cancellation-message-reason"
            rows="4"
            pui-typography="body-s"
            pui-input-text
            type="text"
            [placeholder]="reasonPlaceholder$ | async"></textarea>
        </pui-input-field>
      </div>

      <!--ACTIONS-->
      <div class="booking-cancellation__row booking-cancellation__row_small booking-cancellation__actions">
        <button
          pui-button
          [isLoading]="data.loading!"
          i18n="bookingCancellationSubmit"
          (click)="cancel(data.session)"
          data-qa-id="booking-cancellation-cancel-button">
          Cancel
        </button>
        <button
          pui-button
          [disabled]="data.loading!"
          appearance="secondary"
          i18n="bookingCancellationNevermind"
          class="pui-space_left-3"
          (click)="close(data.session, data.bookings)"
          data-qa-id="booking-cancellation-nevermind-button">
          Nevermind
        </button>
      </div>
    </form>
  </div>
</ng-container>
