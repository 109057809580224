import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChangeFunction = (value: boolean) => void;
type OnTouchFunction = () => void;

@Component({
  selector: 'app-program-show-settings-control',
  templateUrl: './program-show-settings-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProgramShowSettingsControlComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramShowSettingsControlComponent implements ControlValueAccessor {
  disabled = false;

  value = false;

  @Input() label: string | undefined;

  @Input() description: string | undefined;

  @Input()
  calculateValueFn?: (value: boolean) => boolean;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onChange: OnChangeFunction = () => {};

  onTouch: OnTouchFunction = () => {};

  onValueChange(checked: boolean): void {
    if (!this.onChange || !this.onTouch) {
      return;
    }
    this.onChange(this.calculateValueFn ? this.calculateValueFn(checked) : checked);
    this.onTouch();
  }

  registerOnChange(fn: OnChangeFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFunction): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean | null): void {
    if (value === null) {
      return;
    }

    this.value = this.calculateValueFn ? this.calculateValueFn(value) : value;

    this.cdr.markForCheck();
  }
}
