import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'formly-ngx-color-picker-type',
  template: `
    <div class="d-flex">
      <ng-container *ngIf="to.label">
        <div class="col">{{ to?.label }}</div>
      </ng-container>
      <ng-container>
        <input
          [(colorPicker)]="color"
          [style.background]="color"
          (colorPickerChange)="changeColor($event)"
          [value]="color" />
      </ng-container>
    </div>
  `,
  styles: [
    `
      .d-flex {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      input {
        display: flex;
        padding: 0;
        margin: 0;
        border: 1px solid #ccc;
        width: 80px;
        height: 30px;
      }
    `
  ]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FormlyNgxColorPickerType extends FieldType implements OnInit, OnDestroy {
  color = '';

  subscription = Subscription.EMPTY;

  ngOnInit(): void {
    // this.color = this.formControl.value ?? this.field.defaultValue ?? this.color;

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.formControl.valueChanges.pipe(startWith(this.formControl.value as string)).subscribe(value => {
      this.color = value;
    });
  }

  // @ts-expect-error TS7006
  changeColor(event): void {
    this.formControl.setValue(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
