import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SessionStatuses } from '@app/shared/enums/session-statuses';

import { ISystemNotification } from '../../../types';
import { GuideClientCardService } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/guide-client-card.service';
import { UserRoles } from '@app/shared/enums/user-roles';

@Component({
  selector: 'app-session-notification',
  templateUrl: './session-notification.component.html',
  styleUrls: ['../styles/notifications.scss', './session-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionNotificationComponent {
  @Input()
  // @ts-expect-error TS2564
  notification: ISystemNotification;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionClasses: any = {
    [SessionStatuses.APPROVED]: 'session-confirmed',
    [SessionStatuses.CANCELLED]: 'session-cancelled',
    [SessionStatuses.DECLINED]: 'session-declined',
    [SessionStatuses.GUIDE_OFFER]: 'session-pending',
    [SessionStatuses.GUIDE_OFFER_CANCELLED]: 'session-cancelled',
    [SessionStatuses.GUIDE_OFFER_DECLINED]: 'session-cancelled',
    [SessionStatuses.PENDING_APPROVEMENT]: 'session-pending',
    [SessionStatuses.RESCHEDULE_BY_CLIENT]: 'session-pending',
    [SessionStatuses.RESCHEDULE_BY_GUIDE]: 'session-pending'
  };

  readonly UserRoles = UserRoles;

  constructor(private readonly clientCardService: GuideClientCardService) {}

  showClientDetails(clientId: number): void {
    this.clientCardService.openDetails(0, clientId);
  }
}
