import { SimpleSession } from '@app/shared/interfaces/session';
import { ModuleInstructor } from '@app/shared/interfaces/programs/client-programs';
import { ModuleService } from './module-service';
import { BaseAccessibleModule, IBaseAccessibleModuleDetails } from './base-accessible-module';
import { OpenedModuleStatus } from './module-statuses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseSessionModuleDetails extends IBaseAccessibleModuleDetails {
  readonly service?: ModuleService | null;
  readonly sessionId?: number;
  readonly session?: SimpleSession;
  readonly status: OpenedModuleStatus;
  readonly hosts?: ModuleInstructor[];
}

export abstract class BaseSessionModule extends BaseAccessibleModule {
  readonly service?: ModuleService | null;
  readonly sessionId?: number;
  readonly session?: SimpleSession;
  readonly status: OpenedModuleStatus;
  hosts?: ModuleInstructor[];

  constructor(moduleDetails: Readonly<IBaseSessionModuleDetails>) {
    super(moduleDetails);

    this.status = moduleDetails.status;

    this.hosts = moduleDetails.hosts;

    if (moduleDetails.service) {
      this.service = moduleDetails.service;
    }

    if (moduleDetails.sessionId) {
      this.sessionId = moduleDetails.sessionId;
    }

    if (moduleDetails.session) {
      this.session = moduleDetails.session;
    }
  }
}
