import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ClientEventActionTypes, GuideEventActionTypes } from '@app/core/shared-event-actions/types';
import { ISessionActionResult } from '../../types';

@Component({
  selector: 'app-session-action-result',
  templateUrl: './session-action-result.component.html',
  styleUrls: ['./session-action-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionActionResultComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _actionResult: ISessionActionResult;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _hostClass: string | null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _title: string | null;

  @Input()
  set actionResult(value: ISessionActionResult) {
    this._actionResult = value;
    this.setHostClass(value.actionType);
    this.setTitle(value.actionType);
  }

  @HostBinding('class')
  get hostClass(): string | null {
    return this._hostClass;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get actionResult(): ISessionActionResult {
    return this._actionResult;
  }

  get title(): string | null {
    return this._title;
  }

  private setHostClass(actionType: string): void {
    switch (actionType) {
      case ClientEventActionTypes.ACCEPT_REQUEST:
      case GuideEventActionTypes.ACCEPT_REQUEST:
        this._hostClass = 'request-accepted';
        break;
      case ClientEventActionTypes.ACCEPT_RESCHEDULE:
      case GuideEventActionTypes.ACCEPT_RESCHEDULE:
        this._hostClass = 'reschedule-accepted';
        break;
      case ClientEventActionTypes.RESCHEDULE:
      case GuideEventActionTypes.RESCHEDULE:
        this._hostClass = 'reschedule';
        break;
      case ClientEventActionTypes.CANCEL:
      case GuideEventActionTypes.CANCEL:
      case GuideEventActionTypes.CANCEL_EVENT:
        this._hostClass = 'cancelled';
        break;
      case ClientEventActionTypes.LEAVE_PRIVATE_REVIEW:
      case ClientEventActionTypes.LEAVE_REVIEW:
        this._title = 'feedback';
        break;
      default:
        this._title = null;
    }
  }

  private setTitle(actionType: string): void {
    switch (actionType) {
      case ClientEventActionTypes.ACCEPT_REQUEST:
      case GuideEventActionTypes.ACCEPT_REQUEST:
      case ClientEventActionTypes.ACCEPT_RESCHEDULE:
      case GuideEventActionTypes.ACCEPT_RESCHEDULE:
        this._title = 'Accepted';
        break;
      case ClientEventActionTypes.RESCHEDULE:
      case GuideEventActionTypes.RESCHEDULE:
        this._title = 'Reschedule requested';
        break;
      case ClientEventActionTypes.CANCEL:
      case GuideEventActionTypes.CANCEL:
      case GuideEventActionTypes.CANCEL_EVENT:
        this._title = 'Canceled';
        break;
      case ClientEventActionTypes.LEAVE_PRIVATE_REVIEW:
      case ClientEventActionTypes.LEAVE_REVIEW:
        this._title = 'Feedback added';
        break;
      default:
        this._title = null;
    }
  }
}
