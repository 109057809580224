<div class="session-status-container" *ngIf="moduleSessionsState === ModuleSessionStates.CAN_BOOK; else booked">
  <app-button
    className="btn-primary"
    i18n="@@moduleIndividualSessionScheduleSessionBtn"
    (click)="emitBook(instructor)"
    [disabled]="(bookDisabled$ | async) || module?.service.restrictClientBooking">
    Schedule session
  </app-button>
</div>

<ng-template #booked>
  <div class="booked">
    <app-module-service-host
      [user]="module.session.guide"
      [session]="module.session"
      [dateTime]="getDateTime() | sessionDateTime">
    </app-module-service-host>

    <div
      class="session-actions"
      *ngIf="
        moduleSessionsState === ModuleSessionStates.OFFER ||
        moduleSessionsState === ModuleSessionStates.REQUEST ||
        moduleSessionsState === ModuleSessionStates.CONFIRMED
      ">
      <div *ngIf="session?.status === SessionStatuses.APPROVED">
        <span class="session-status-icon session-confirmed"></span>
        <span class="session-status-text" i18n="@@sessionStatusApprovedFree">Confirmed</span>
      </div>
      <button
        *ngIf="!isSessionMenuAvailable()"
        pui-dropdown
        [pui-dropdown-content]="dropdownContent"
        pui-icon-button
        size="m"
        type="button"
        shape="square"
        appearance="flat">
        <pui-icon svgIcon="pui:more-vertical"></pui-icon>
      </button>

      <ng-template #dropdownContent let-close="close">
        <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
          <button
            pui-list-item="button"
            pui-a11y-keyboard-widget-item
            *ngFor="let item of menuItems; trackBy: menuItemsTrackBy"
            (click)="close(); item.clickEvent(instructor)">
            <pui-list-item-icon [class]="item.className">
              <pui-icon [svgIcon]="item.svgIcon" size="s"></pui-icon>
            </pui-list-item-icon>
            <span>{{ item.title }}</span>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
