import { ExternalCalendarSources } from './types';

export const calendarTexts = {
  [ExternalCalendarSources.GOOGLE]: {
    name: 'Google Calendar',
    description: 'Gmail, Google Workspace'
  },
  [ExternalCalendarSources.OUTLOOK]: {
    name: 'Outlook Office 365',
    description: 'Microsoft calendar, Hotmail'
  }
};
