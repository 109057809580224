<div [formGroup]="form">
  <div class="form-row align-items-start">
    <div class="form-group form-group-every">
      <p class="bold"><ng-container i18n="@@sendFormEveryText">Send form every</ng-container>:</p>
      <div class="send-every">
        <input type="number" [min]="DAYS" class="form-control" formControlName="days" />

        <pui-select class="schedule-selector" formControlName="repeat" id="every" size="s">
          <pui-option [value]="QuizPattern.Daily" i18n-label="@@day" label="Day"></pui-option>
          <pui-option [value]="QuizPattern.Weekly" i18n-label="@@week" label="Week"></pui-option>
          <pui-option [value]="QuizPattern.Monthly" i18n-label="@@month" label="Month"></pui-option>
        </pui-select>
      </div>
      <app-form-control-error
        [control]="form.controls['days']"
        [show]="form.controls['days'].invalid"
        i18n="@@quizDaysError">
        Please write integer value greater than zero
      </app-form-control-error>
    </div>

    <div class="form-group form-group-timezone">
      <p for="at" class="bold"><ng-container i18n="@@inClientTimezone">in client's timezone</ng-container>:</p>
      <pui-select class="schedule-selector" formControlName="time" id="at" size="s">
        <pui-option *ngFor="let d of dates; let i = index" [value]="d.toISO()" [label]="formatTime(d)"> </pui-option>
      </pui-select>
    </div>
  </div>

  <app-weekdays-selector
    formControlName="weekdays"
    [class.d-none]="form.get('repeat')?.value !== QuizPattern.Weekly"></app-weekdays-selector>
  <div class="monthday" [class.d-none]="form.get('repeat')?.value !== QuizPattern.Monthly">
    <label for="monthday" i18n="@@monthlyOnDay">Monthly on day</label>
    <ng-select [searchable]="false" [clearable]="false" formControlName="monthday" id="monthday">
      <ng-option *ngFor="let i of getArray(31)" [value]="i + 1">{{ i + 1 }}</ng-option>
    </ng-select>
  </div>

  <div appRadioGroup class="app-radio-group ends-radio" formControlName="ends">
    <div class="radio-group-note"><ng-container i18n="@@ends">Ends</ng-container>:</div>
    <div class="radio">
      <label class="radio-label">
        <input appRadioInput type="radio" [value]="QuizEnds.Never" />
        <span class="checkmark"></span>
        <ng-container i18n="@@never">Never</ng-container>
      </label>
    </div>
    <div class="radio">
      <label class="radio-label">
        <input appRadioInput type="radio" [value]="QuizEnds.SpecificDate" />
        <span class="checkmark"></span>
        <ng-container i18n="@@on">On</ng-container>
      </label>
      <ng-container *ngTemplateOutlet="sendUntil"></ng-container>
    </div>
    <div class="radio">
      <label class="radio-label">
        <input appRadioInput type="radio" [value]="QuizEnds.FixedCount" />
        <span class="checkmark"></span>
        <ng-container i18n="@@after">After</ng-container>
      </label>
      <div class="input-wrap">
        <input type="number" class="form-control" formControlName="fireCount" min="1" />
        <span *ngIf="form.controls['fireCount']?.value > 1" i18n="@@occurrences">occurrences</span>
        <span *ngIf="form.controls['fireCount']?.value <= 1" i18n="@@occurrence">occurrence</span>
      </div>
    </div>
    <app-form-control-error
      [control]="form.controls['fireCount']"
      [show]="form.controls['fireCount'].invalid"
      i18n="@@quizfireCountErrorText">
      Please write a whole number value greater than zero
    </app-form-control-error>
  </div>

  <div class="form-group" style="display: none">
    <label for="repeat" i18n="@@quizSchedulingRepeat">Repeat:</label>
    <ng-select [searchable]="false" [clearable]="false" formControlName="repeat" (change)="(null)" id="repeat">
      <ng-option value="once" i18n="@@once">Once</ng-option>
      <ng-option value="daily" i18n="@@daily">Daily</ng-option>
      <ng-option value="weekly" i18n="@@weekly">Weekly</ng-option>
      <ng-option value="monthly" i18n="@@monthly">Monthly</ng-option>
    </ng-select>
  </div>

  <ng-template #sendUntil>
    <div class="datepicker-wrapper" (clickOutside)="dUntil.close()" (click)="dUntil.open()">
      <input
        type="text"
        readonly
        id="until"
        class="form-control"
        name="until"
        formControlName="until"
        placeholder="Choose a date"
        i18n-placeholder="@@chooseADate"
        #dUntil="ngbDatepicker"
        [firstDayOfWeek]="7"
        ngbDatepicker
        navigation="arrows"
        [minDate]="minDate" />
    </div>
  </ng-template>
</div>
