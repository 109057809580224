import { NgModule } from '@angular/core';
import { ContactDetailsTagsSelectorComponent } from '@app/modules/guide-client/components/contact-details/components/contact-details-tags-selector.component';
import {
  PuiButtonModule,
  PuiColorSelectorModule,
  PuiDialogModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiLinkModule,
  PuiOptionModule,
  PuiTableModule,
  PuiTagModule,
  PuiTextOverflowModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { CommonModule } from '@angular/common';
import { ClientsTagsFeatureModule } from '@app/screens/guide/guide-clients/guide-relations-table/services/features/clients-tags/clients-tags-feature.module';

@NgModule({
  declarations: [ContactDetailsTagsSelectorComponent],
  imports: [
    PuiTagModule,
    PuiTableModule,
    CommonModule,
    PuiButtonModule,
    PuiOptionModule,
    PuiTextOverflowModule,
    PuiIconModule,
    PuiDialogModule,
    PuiDropdownModule,
    PuiTypographyModule,
    PuiColorSelectorModule,
    ClientsTagsFeatureModule,
    PuiLinkModule
  ],
  exports: [ContactDetailsTagsSelectorComponent]
})
export class ContactDetailsTagsSelectorModule {}
