import { Injectable } from '@angular/core';
import { IUser } from '@app/shared/interfaces/user';
import { Chat, ChatMessageStatuses, ChatTypes, ChatUser, IChatMessage, IServerChat } from './types';

enum StoreStates {
  TEMPORARY = 1,
  PERSISTENT_INACTIVE = 2,
  PERSISTENT_ACTIVE = 3
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IStoreItem {
  id: string;
  chat: Chat<IChatMessage, ChatUser>;
  state: StoreStates;
  eventId?: number | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IStoreCollection {
  [key: string]: IStoreItem;
}

@Injectable()
export class ChatsStoreService {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _store: IStoreCollection;

  constructor() {
    this.reset();
  }

  activate(id: string): void {
    this._store[id].state = StoreStates.PERSISTENT_ACTIVE;
    this._store[id].chat.activate();
  }

  addChat(chat: IServerChat, users: { [id: number]: ChatUser }, eventId?: number): void {
    if (this.has(chat.id)) {
      return;
    }

    this._store[chat.id] = {
      id: chat.id,
      eventId,
      chat: new Chat<IChatMessage, ChatUser>(chat, users),
      state: StoreStates.PERSISTENT_ACTIVE
    };
  }

  addChatUser(id: string, user: ChatUser): void {
    if (!this.has(id) || !user) {
      return;
    }

    this._store[id].chat.users[user.id] = user;
  }

  addHistory(id: string, messages: IChatMessage[], reversed: boolean): void {
    const storedMessages = this._store[id].chat.messages;
    const sortedDescMessages = messages
      .filter(message => !storedMessages.some(storedMessage => storedMessage.id === message.id))
      .sort((m1, m2) => this.compareMessagesById(m1, m2));

    if (!sortedDescMessages.length) {
      return;
    }

    if (reversed) {
      // @ts-expect-error TS2769
      this._store[id].chat.messages = [].concat(storedMessages, sortedDescMessages);
    } else {
      // @ts-expect-error TS2769
      this._store[id].chat.messages = [].concat(sortedDescMessages, storedMessages);
    }
  }

  addNewMessage(message: IChatMessage): void {
    this._store[message.chatId].chat.messages.push(message);
  }

  // @ts-expect-error TS7006
  resetMessages(chatId): void {
    this._store[chatId].eventId = null;
    this._store[chatId].chat.messages = [];
  }

  canAddNewMessage(id: string): boolean {
    return this._store[id] && this._store[id].state !== StoreStates.PERSISTENT_INACTIVE;
  }

  createDirectChat(id: string, users?: IUser[], activate?: boolean, eventId?: number): void {
    this._createChat(
      id,
      ChatTypes.DIRECT,
      activate ? StoreStates.PERSISTENT_ACTIVE : StoreStates.PERSISTENT_INACTIVE,
      users,
      eventId
    );
  }

  createTemporaryDirectChat(id: string, users?: IUser[]): void {
    this._createChat(id, ChatTypes.DIRECT, StoreStates.TEMPORARY, users);
  }

  get(id: string): Chat<IChatMessage, ChatUser> | null {
    return this._store[id] ? this._store[id].chat : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getChatHistoryCursor(id: string, reversed = false) {
    const { messages } = this._store[id].chat;

    if (!messages.length) {
      return null;
    }

    return reversed ? messages[messages.length - 1].id : messages[0].id;
  }

  has(id: string): boolean {
    return !!this._store[id];
  }

  hasEvent(id: string): boolean {
    return this._store[id] ? !!this._store[id].eventId : false;
  }

  setEvent(id: string, eventId: number): void {
    if (this._store[id]) {
      this._store[id].eventId = eventId;
    }
  }

  isActivated(id: string): boolean {
    return this.has(id) && this._store[id].state === StoreStates.PERSISTENT_ACTIVE;
  }

  isTemporary(id: string): boolean {
    return this._store[id].state === StoreStates.TEMPORARY;
  }

  persistDirectChat(temporaryChatId: string, chatId: string): void {
    const chatStoreItem = this._store[temporaryChatId];

    if (chatStoreItem && chatStoreItem.state === StoreStates.TEMPORARY) {
      const chat = chatStoreItem.chat.clone();
      chat.id = chatId;
      chat.activate();

      this._store[chatId] = {
        id: chatId,
        chat,
        state: StoreStates.PERSISTENT_ACTIVE
      };

      delete this._store[temporaryChatId];
    }
  }

  persistMessage(tempMessageId: number, message: IChatMessage): void {
    const { chatId } = message;

    if (this.has(chatId)) {
      const { messages } = this._store[chatId].chat;

      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].id === tempMessageId) {
          messages[i] = message;
          break;
        }
      }
    }
  }

  remove(id: string): void {
    delete this._store[id];
  }

  removeMessage(id: string, messageId: number): void {
    if (this.has(id)) {
      const { messages } = this._store[id].chat;
      let messageToRemoveIndex = -1;

      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].id === messageId) {
          messageToRemoveIndex = i;
          break;
        }
      }

      if (messageToRemoveIndex > -1) {
        messages.splice(messageToRemoveIndex, 1);
      }
    }
  }

  blockChatUser(id: string, userId: number): void {
    if (this.has(id) && this._store[id].chat.users[userId]) {
      this._store[id].chat.users[userId].isBlocked = true;
    }
  }

  unblockChatUser(id: string, userId: number): void {
    if (this.has(id) && this._store[id].chat.users[userId]) {
      this._store[id].chat.users[userId].isBlocked = false;
    }
  }

  reset(): void {
    this._store = {};
  }

  updateChatFileUploadStatus(id: string, messageId: number, uploadStatus: number): void {
    if (this.has(id)) {
      const { messages } = this._store[id].chat;
      let file = null;

      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].id === messageId) {
          ({ file } = messages[i]);
          break;
        }
      }

      if (file) {
        // @ts-expect-error TS2339
        file.uploadStatus = uploadStatus;
      }
    }
  }

  updateOthersChatMessagesStatus(id: string, userId: number, status: ChatMessageStatuses): number[] {
    const modifiedMessagesIds = [];

    if (this.has(id)) {
      const { messages } = this._store[id].chat;

      for (let i = 0, len = messages.length; i < len; i++) {
        const message = messages[i];

        if (message.sender.id !== userId && message.status !== status) {
          message.status = status;
          modifiedMessagesIds.push(message.id);
        }
      }
    }

    return modifiedMessagesIds;
  }

  updateOwnChatMessagesStatuses(id: string, senderId: number, ids: { [index: number]: ChatMessageStatuses }): number[] {
    const modifiedMessagesIds = [];

    if (this.has(id)) {
      const { messages } = this._store[id].chat;

      for (let i = 0, len = messages.length; i < len; i++) {
        const message = messages[i];
        const status = ids[message.id];

        if (!status) {
          continue;
        }

        if (message.sender.id === senderId && message.status !== status) {
          message.status = status;
          modifiedMessagesIds.push(message.id);
        }
      }
    }

    return modifiedMessagesIds;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _createChat(id: string, type: ChatTypes, state: StoreStates, users?: IUser[], eventId?: number): void {
    if (this._store[id]) {
      return;
    }

    const chatUsers =
      users && users.length ? users.reduce((usersMap, user) => ({ ...usersMap, [user.id]: user }), {}) : null;

    this._store[id] = {
      id,
      eventId,
      state,
      chat: new Chat<IChatMessage, ChatUser>(
        {
          id,
          messages: [],
          type
        },
        // @ts-expect-error TS2345
        chatUsers,
        state === StoreStates.TEMPORARY
      )
    };
  }

  private compareMessagesById<T extends { id: number }>(message1: T, message2: T): number {
    return message1.id - message2.id;
  }
}
