import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { SharedModule } from '@app/shared/shared.module';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { EmbeddableBookButtonModule } from '@app/modules/embeddable-book-button/embeddable-book-button.module';
import { UserMenuModule } from '@app/modules/user-menu/user-menu.module';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { PoweredByLinkModule } from '@app/modules/powered-by-link/powered-by-link.module';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HelpMeFindAGuideModalComponent } from './sidebar-navigation/help-me-find-a-guide-modal/help-me-find-a-guide-modal.component';
import { MainLayoutComponent } from './main-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarNavigationComponent } from './sidebar-navigation/sidebar-navigation.component';
import { PuiTypographyModule } from '@awarenow/profi-ui-core';
import { OurCommunityModule } from '@app/screens/our-community/our-community.module';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgScrollbarModule,
    SharedModule,
    AlertsModule,
    UserMenuModule,
    NotificationsModule,
    EmbeddableBookButtonModule,
    PoweredByLinkModule,
    PlatformStyleModule,
    CurrentPaymentModule,
    PuiTypographyModule,
    OurCommunityModule,
    TranslocoRootModule
  ],
  exports: [HeaderComponent, SidebarNavigationComponent],
  declarations: [
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    HelpMeFindAGuideModalComponent,
    MainLayoutComponent,
    SidebarComponent,
    SidebarNavigationComponent
  ]
})
export class MainLayoutModule {}
