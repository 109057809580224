import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';
import { GuideServicesApiService } from '@app/modules/service-scheduling/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { GroupServicesItem } from '@app/modules/service-scheduling/components/group-services/group-services.component';

const isSessionService = (service: {
  type: GuideServiceTypes;
  parentService?: {
    id: number;
  };
}): boolean => {
  return (
    !service.parentService?.id &&
    (service.type === GuideServiceTypes.GROUP_SESSION || service.type === GuideServiceTypes.SESSION)
  );
};

@Component({
  selector: 'app-session-templates-drawer',
  templateUrl: './session-templates-drawer.component.html',
  styleUrls: ['./session-templates-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionTemplatesDrawerComponent {
  isLoading$ = new BehaviorSubject<boolean>(false);

  filter = '';

  templates$: Observable<GuideServices.RootObject[]>;

  constructor(
    private readonly drawerRef: PuiDrawerRef<{ id: number; recurring: unknown } | undefined, void>,
    guideServicesApiService: GuideServicesApiService
  ) {
    this.isLoading$.next(true);
    this.templates$ = guideServicesApiService.getGuideServices().pipe(
      map(({ services }) => services.filter(isSessionService)),
      filter(value => !!value),
      tap(() => this.isLoading$.next(false))
    );
  }

  selectService({ id, recurring }: GroupServicesItem): void {
    this.drawerRef.close({ id, recurring });
  }
}
