import { Observable } from 'rxjs';

export interface CSVCell {
  value: string;
}

export interface CSVRow {
  cells: CSVCell[];
}

export interface CSV {
  header?: CSVCell[];
  rows: CSVRow[];
}

export class CsvReader {
  static parse(file: File, hasHeader = false, separator = ',', lineSeparator = '\n'): Observable<CSV> {
    // eslint-disable-next-line id-length
    return new Observable(o => {
      const reader = new FileReader();

      // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
      reader.onload = (e: any) => {
        try {
          const rows = e.target.result.split(lineSeparator);
          const csv: CSV = { rows: [] };

          if (hasHeader) {
            const header = rows.shift();
            csv.header = CsvReader.parseRow(header, separator);
          }

          for (const row of rows) {
            if (row.trim().length) {
              csv.rows.push({ cells: CsvReader.parseRow(row, separator) });
            }
          }

          o.next(csv);
          o.complete();
        } catch (err) {
          // @ts-expect-error TS2721
          reader.onerror(err);
        }
      };

      reader.onerror = err => {
        o.error(err);
        o.complete();
      };

      reader.readAsText(file);
    });
  }

  private static parseRow(row: string, separator: string): CSVCell[] {
    const cells = row.split(separator);
    const values = [];

    for (const cell of cells) {
      values.push({ value: cell.trim() });
    }

    return values;
  }
}
