<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="StubViewMode.LOADING">
    <div class="guide-services-empty guide-services-empty_loading">
      <div class="guide-services-empty__container">
        <pui-icon svgIcon="pui:refresh-cw" size="m" class="guide-services-empty__icon"></pui-icon>
        <br />
        <span i18n="@@fetchingData:title" pui-typography="h3" class="guide-services-empty__title">Fetching data</span>
        <br />
        <small i18n="@@fetchingData:description" pui-typography="body-s">
          This will only take a moment. Please wait...
        </small>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="StubViewMode.EMPTY">
    <div class="guide-services-empty">
      <div class="guide-services-empty__container">
        <pui-icon svgIcon="pui:inbox" size="l" class="guide-services-empty__icon"></pui-icon>
        <br />
        <span i18n="@@noServicesFound" pui-typography="body-m-bold" class="guide-services-empty__title">
          No services found
        </span>
        <br />
        <small class="pui-space_right-1" i18n="@@noServicesMatchedYourSearch" pui-typography="body-s">
          No services matched your search.
        </small>
      </div>
    </div>
  </ng-container>
</ng-container>
