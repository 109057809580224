import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
  /**
   * Parse errors from out server.
   * @param e
   * @returns {array} of error messages
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseErrors(res: any): string[] {
    const err = res.error;
    if (typeof err === 'string' && err[0] === '<') {
      return [res.statusText];
    } else if (err.errors === undefined) {
      return [err.msg];
    } else {
      const errors = [];
      for (const key of Object.keys(err.errors)) {
        errors.push(err.errors[key].msg ? err.errors[key].msg : err.status);
      }
      return errors;
    }
  }
}
