import { Inject, Injectable } from '@angular/core';
import { Observable, of, merge } from 'rxjs';
import { defaultIfEmpty, filter, map } from 'rxjs/operators';
import { BrandingService } from '@app/core/branding/branding.service';
import { AuthService } from '@app/core/auth/services';
import { IS_ADMIN_WORKSPACE } from '@app/modules/workspaces/providers/is-admin-workspace';

@Injectable({ providedIn: 'root' })
export class GuideServicesUtilsService {
  private isPlatformAdmin$ = of(this.authService.isPlatformAdmin());
  private enableServiceCreationForTeamMembers$ = this.brandingService.globalConfig$.pipe(
    map(globalConfig => globalConfig.enableServiceCreationForTeamMembers)
  );

  readonly canCreateService$ = merge(
    this.isPlatformAdmin$,
    this.isWorkspaceAdmin$,
    this.enableServiceCreationForTeamMembers$
  ).pipe(
    filter(value => value),
    defaultIfEmpty(false)
  );

  constructor(
    private readonly brandingService: BrandingService,
    private readonly authService: AuthService,
    @Inject(IS_ADMIN_WORKSPACE) private isWorkspaceAdmin$: Observable<boolean>
  ) {}
}
