<app-schedule-date-time-picker
  [time]="time"
  [markDateDisabled]="markDateDisabled"
  [maxDate]="maxDate"
  [minDate]="minDate"
  [scheduleOptions]="scheduleOptions"
  [scheduleTimeSlots]="scheduleTimeSlots"
  [timePicker]="timePicker"
  [timezones]="timezones"
  [disabledWithoutSlots]="true"
  (timeChange)="selectTime($event)"
  (scheduleOptionsChange)="scheduleOptionsChange.emit($event)"></app-schedule-date-time-picker>

<ng-template #timePicker let-items="timeSlots" let-formControl="formControl">
  <app-time-picker-grid [formControl]="formControl" [items]="items"></app-time-picker-grid>
</ng-template>
