<ng-container *ngIf="client$ | async as client">
  <div class="client-card__container">
    <div class="client-card__header">
      <div class="client-card__header-container">
        <ng-container>
          <pui-card>
            <pui-card-header>
              <pui-avatar
                pui-card-header-icon
                [avatarUrl]="client.photo"
                data-qa-id="guide-client-card-client-avatar"
                [text]="client | userName"
                size="xxl"></pui-avatar>
              <div pui-typography="h3" pui-card-header-title>
                <span data-qa-id="guide-client-card-client-username-title">
                  {{ client | userName }}
                </span>
                <ng-container *ngIf="client.archived">
                  (<span i18n="@@newClientCardUserStatusArchived:new">archived</span>)
                </ng-container>
              </div>

              <pui-icon pui-card-header-action class="close-btn" svgIcon="pui:x" (click)="back()"></pui-icon>
            </pui-card-header>
          </pui-card>
          <button
            class="client-card__propose-service-button"
            data-qa-id="guide-client-card-propose-a-service-button"
            pui-button
            size="s"
            (click)="proposeService([client])">
            Propose a Service
          </button>
        </ng-container>
      </div>
    </div>
    <div class="client-card__main">
      <div class="client-card__main-container" pui-overflow-auto-height>
        <pui-tabs
          data-qa-id="guide-client-card-tabs"
          class="pui-tabs_grow-1 pui-tabs_full"
          [(selectedTabIndex)]="tabService.tabIndex">
          <ng-template pui-tab puiTitle="Details" i18n-puiTitle="@@newClientCardDetailsTab:new">
            <app-guide-client-details [id]="client.id"></app-guide-client-details>
          </ng-template>
          <ng-template pui-tab puiTitle="Notes" i18n-puiTitle="@@newClientCardNotesTab:new">
            <app-guide-client-notes [client]="client"></app-guide-client-notes>
          </ng-template>
          <ng-template pui-tab puiTitle="History" i18n-puiTitle="@@newClientCardHistoryTab:new">
            <app-guide-client-history class="client-card__content" [clientId]="client.id"></app-guide-client-history>
          </ng-template>
        </pui-tabs>
      </div>
    </div>
  </div>
</ng-container>
