import { groupSessionsByParentAndPurchase } from './group-sessions-by-parent-and-expires';
import { groupSessionsByParent } from './group-sessions-by-parent';
import { GuideServiceParent } from '@app/modules/book-service';
import { SessionGroup } from '../grouping/group-sessions-by.pipe';
import { SessionsStack } from '@app/modules/client-sessions/components/available-client-sessions/grouping/stack-sessions-by.pipe';
import { stackSessionsByType } from '@app/modules/client-sessions/components/available-client-sessions/grouping/stack-sessions-by-type';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { Session } from '@libs/business/cdk/session';
import { ClientSession } from '@libs/business/client/client-session';

export enum ClientSessionsGroupBy {
  PARENT_AND_PURCHASE = 'parent-and-purchase',
  PARENT = 'parent'
}

export enum ClientSessionsStackBy {
  TYPE = 'type'
}

export const ClientSessionsGroupingFunctions: Record<
  ClientSessionsGroupBy,
  (sessions: ClientSession<GuideServiceParent>[]) => (SessionGroup | ClientSession<GuideServiceParent>)[]
> = {
  [ClientSessionsGroupBy.PARENT_AND_PURCHASE]: groupSessionsByParentAndPurchase,
  [ClientSessionsGroupBy.PARENT]: groupSessionsByParent
};

export const ClientSessionsStackingFunctions: Record<
  ClientSessionsStackBy,
  (sessions: Session<unknown, GuideServiceParent>[], platformStyle: PlatformStyle) => SessionsStack[]
> = {
  [ClientSessionsStackBy.TYPE]: stackSessionsByType
};

export const ClientSessionsParentGrouping: Record<
  GuideServiceTypes.PROGRAM | GuideServiceTypes.PACKAGE,
  (sessions: ClientSession<GuideServiceParent>[]) => (SessionGroup | ClientSession<GuideServiceParent>)[]
> = {
  [GuideServiceTypes.PACKAGE]: ClientSessionsGroupingFunctions[ClientSessionsGroupBy.PARENT_AND_PURCHASE],
  [GuideServiceTypes.PROGRAM]: ClientSessionsGroupingFunctions[ClientSessionsGroupBy.PARENT]
};
