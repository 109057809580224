<div>
  <ng-container *ngIf="_allTags">
    <ng-container *ngFor="let tag of tags; index as i">
      <div *ngIf="namedTags[tag]" class="tag" [ngbTooltip]="namedTags[tag].description">
        <span>{{ namedTags[tag].name }}</span>
        <span *ngIf="!disabled" (click)="deleteTag(i)" class="tag-delete"></span>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="tags.length < tagsLimit" class="d-inline-block button">
    <app-button
      [attr.data-qa-id]="'tags-selector-' + title + '-button'"
      className="btn btn-add"
      [disabled]="disabled"
      (click)="openTagsModal()">
      {{ title }}
    </app-button>
  </div>
</div>
