import { Injector, Provider } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { NAVIGATION_CONDITIONS } from '@app/modules/navigation/tokens';
import { sidenavConditionsFactory } from './factory';

export const NAVIGATION_CONDITIONS_PROVIDER: Provider = {
  provide: NAVIGATION_CONDITIONS,
  useFactory: sidenavConditionsFactory,
  deps: [AuthService, Injector]
};
