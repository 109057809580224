import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum StubViewMode {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY'
}

@Component({
  selector: 'app-service-scheduling-stub',
  templateUrl: './service-scheduling-stub.component.html',
  styleUrls: ['./service-scheduling-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceSchedulingStubComponent {
  StubViewMode = StubViewMode;

  @Input()
  view: StubViewMode = StubViewMode.LOADING;
}
