// eslint-disable-next-line no-restricted-imports
import { includes, map as _map } from 'lodash';

import { GuideServices } from '@app/modules/propose-service/types';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const hasProviderPermission = (guide: Pick<GuideServices.Team, 'permission' | 'role'>) => {
  return (
    (guide.permission === undefined && !!guide.role) ||
    guide.permission === ServiceAssigneePermission.OWNER_X_PROVIDER ||
    guide.permission === ServiceAssigneePermission.PROVIDER
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const onlyProvidableGuides = (team: GuideServices.Team[]) => {
  const permissions = _map(team, 'permission');

  if (!permissions.filter(item => !!item).length) {
    return true;
  }

  return (
    includes(permissions, ServiceAssigneePermission.OWNER_X_PROVIDER) ||
    includes(permissions, ServiceAssigneePermission.PROVIDER)
  );
};

export const onlyProvidableGuideService = (service: GuideServices.RootObject): boolean =>
  onlyProvidableGuides(service.team);
