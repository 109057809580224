import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { ServiceTeamSchedulePayload } from '@app/modules/schedule-boards';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSchedule {
  schedule: {
    dates: IScheduleItem[];
  };
}

@Injectable()
export class ServiceScheduleApiService {
  private readonly ENDPOINT = `${config.apiPath}/public`;

  constructor(private _http: HttpClient) {}

  getGuideServiceSchedule$(guideId: number, serviceId: number): Observable<IScheduleItem[]> {
    return this._http
      .get<IServerSchedule>(`${this.ENDPOINT}/profile/${guideId}/services/${serviceId}/schedule`)
      .pipe(map(({ schedule: { dates } }) => dates));
  }

  getWorkspaceServiceSchedule$({ serviceId, workspaceId }: ServiceTeamSchedulePayload): Observable<IScheduleItem[]> {
    return this._http
      .get<IServerSchedule>(`${this.ENDPOINT}/workspaces/${workspaceId}/services/${serviceId}/schedule`)
      .pipe(map(({ schedule: { dates } }) => dates));
  }
}
