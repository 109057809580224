import { Injectable } from '@angular/core';
import { VisibilityCondition } from '@app/cdk/conditions';
import { HeaderLogoComponent } from '@app/modules/ui-kit/widgets/header-logo';
import {
  CurrentGuideService,
  SidenavCurrentWidgetComponent,
  SidenavNavigationPrimaryWidgetComponent,
  SidenavNavigationPrimaryWidgetService,
  SidenavNavigationSecondaryWidgetComponent,
  SidenavNavigationSecondaryWidgetService,
  SidenavWorkspaceSwitchWidgetComponent,
  SidenavWorkspaceSwitchWidgetService,
  SwitchToAlternativeAccountComponent
} from '@app/modules/sidenav/components';
import { SidenavBodyComponent, SidenavFooterComponent, SidenavHeaderComponent } from '@app/modules/sidenav/hosts';
import { ViewHostCandidateUnionMap } from '@app/modules/sidenav';

@Injectable()
export class SidenavGuideSchema implements ViewHostCandidateUnionMap {
  sidenav = {
    header: {
      component: SidenavHeaderComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    body: {
      component: SidenavBodyComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    footer: {
      component: SidenavFooterComponent,
      condition: VisibilityCondition.always,
      context: null
    }
  };

  header = {
    logo: {
      component: HeaderLogoComponent,
      condition: VisibilityCondition.always,
      context: null
    },
    current: {
      component: SidenavCurrentWidgetComponent,
      condition: VisibilityCondition.always,
      context: CurrentGuideService
    },
    workspaceSwitch: {
      component: SidenavWorkspaceSwitchWidgetComponent,
      condition: VisibilityCondition.always,
      context: SidenavWorkspaceSwitchWidgetService
    }
  };

  body = {
    topNavigation: {
      component: SidenavNavigationPrimaryWidgetComponent,
      condition: VisibilityCondition.always,
      context: SidenavNavigationPrimaryWidgetService
    },
    bottomNavigation: {
      component: SidenavNavigationSecondaryWidgetComponent,
      condition: VisibilityCondition.always,
      context: SidenavNavigationSecondaryWidgetService
    }
  };

  footer = {
    switchAccount: {
      component: SwitchToAlternativeAccountComponent,
      condition: VisibilityCondition.account_switcher,
      context: null
    }
  };
}
