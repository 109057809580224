import { Routes } from '@angular/router';

import { SessionTemplateLandingComponent } from './components/session-template-landing/session-template-landing.component';

import { IdGuard } from './guards/id.guard';

export const sessionTemplatesRoutes: Routes = [
  {
    path: ':id',
    canActivate: [IdGuard],
    component: SessionTemplateLandingComponent
  }
];
