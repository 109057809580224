<div class="modal-header" [class.no-close]="disableCloseButton">
  <app-button *ngIf="!disableCloseButton" className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="logo">
  <a *ngIf="logoUrl?.type === URL_TYPE.RELATIVE; else absolute" [routerLink]="'/' + logoUrl?.value">
    <img [src]="mainLogo" alt="" />
  </a>
</div>

<div *ngIf="loaded$ | async; else loader" class="modal-body">
  <ng-container [ngSwitch]="activeState">
    <ng-template [ngSwitchCase]="AuthStates.INIT">
      <app-welcome
        [showAuthMethodsSeparator]="showAuthMethodsSeparator$ | async"
        [onlyClient]="onlyClient || signUpOnlyClients"
        [onlyGuide]="onlyGuide || signUpOnlyCoaches"
        (afterUserChecked)="onUserChecked($event)"
        (signUpWithAlternativeProvider)="onSignUpWithAlternativeProvider()"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        (signInCanNot)="signInCanNot.emit()"></app-welcome>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.SIGN_IN">
      <app-sign-in
        [email]="email"
        [remember]="remember"
        [firstName]="user.firstName"
        [onlyClient]="onlyClient"
        [onlyGuide]="onlyGuide"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        (signInCanNot)="signInCanNot.emit()"
        (openTfaStep)="onOpenTfaStep($event)"></app-sign-in>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.SIGN_UP">
      <app-sign-up
        [email]="email"
        [onlyClient]="onlyClient || signUpOnlyClients"
        [onlyGuide]="onlyGuide || signUpOnlyCoaches"
        [actionCode]="actionCode"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        class="auth-body"></app-sign-up>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.SIGN_UP_ALTERNATIVE">
      <app-sign-up-alternative
        [separatedAuth]="separatedAuth$ | async"
        [onlyClient]="onlyClient || signUpOnlyClients"
        [onlyGuide]="onlyGuide || signUpOnlyCoaches"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        class="auth-body"></app-sign-up-alternative>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.SIGN_IN_ALTERNATIVE">
      <app-welcome
        [onlyClient]="onlyClient || signUpOnlyClients"
        [onlyGuide]="onlyGuide || signUpOnlyCoaches"
        showSSOWelcome="true"
        (afterUserChecked)="onUserChecked($event)"
        (signUpWithAlternativeProvider)="onSignUpWithAlternativeProvider()"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        (signInCanNot)="signInCanNot.emit()"></app-welcome>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.CONFIRM_EMAIL">
      <app-confirm-email [email]="email" [emailVerified]="user && user.emailVerified"></app-confirm-email>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.TFA_APP_CHALLENGE">
      <tfa-app-auth
        [onlyClient]="onlyClient"
        [onlyGuide]="onlyGuide"
        [tfaInfo]="tfaInfo"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        (useBackupCode)="openTfaBackupStep()"></tfa-app-auth>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.TFA_SMS_CHALLENGE">
      <tfa-sms-auth
        [onlyClient]="onlyClient"
        [onlyGuide]="onlyGuide"
        [tfaInfo]="tfaInfo"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"
        (useBackupCode)="openTfaBackupStep()"></tfa-sms-auth>
    </ng-template>
    <ng-template [ngSwitchCase]="AuthStates.TFA_USE_BACK_UP_CODE">
      <tfa-backup-code
        [onlyClient]="onlyClient"
        [onlyGuide]="onlyGuide"
        [tfaInfo]="tfaInfo"
        (afterSignIn)="afterSignIn.emit(); modal.dismiss()"></tfa-backup-code>
    </ng-template>
  </ng-container>
</div>

<ng-template #loader>
  <arrow-loader></arrow-loader>
</ng-template>

<ng-template #absolute>
  <a target="_blank" href="{{ logoUrl?.value }}" rel="noopener">
    <img [src]="mainLogo" alt="" />
  </a>
</ng-template>
