import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { AuthModalComponent, AuthStates } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { AuthService } from '@app/core/auth/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AuthModalGuard implements CanActivate {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isBrowser: boolean;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _modal: NgbModal,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID)
    private platformId
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { queryParams } = route;

    if (!this._auth.isAuthorized) {
      if (queryParams.signUp) {
        if (queryParams.presetEmail) {
          this._auth.presetEmail = decodeURIComponent(queryParams.presetEmail);
        }
        this.openAuthModal(AuthStates.SIGN_UP);
      } else {
        this.openAuthModal(AuthStates.INIT);
      }
      return true;
    }

    return this._router.createUrlTree(
      ['/dashboard'],
      /* Removed unsupported properties by Angular migration: replaceUrl. */ {}
    );
  }

  private async openAuthModal(activeState: AuthStates): Promise<void> {
    if (!this._isBrowser) {
      return;
    }

    try {
      const { componentInstance, result } = this._modal.open(AuthModalComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        windowClass: 'auth-modal'
      });

      componentInstance.activeState = activeState;
      componentInstance.disableCloseButton = true;

      await result;
    } catch (error) {
      console.log(error);
    }
  }
}
