export enum SessionAttributeIconEnum {
  CALENDAR = 'pui:calendar',
  WARNING = 'pui:warning',
  USER = 'pui:user',
  REPEAT = 'pui:repeat',
  PLACE = 'pui:place',
  VIDEO = 'pui:video',
  PHONE = 'pui:phone',
  MESSAGE = 'pui:message',
  USER_PLUS = 'pui:user-plus'
}
