import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, PLATFORM_ID } from '@angular/core';
import { SessionStorageProvider, GLOBAL_SESSION_STORAGE } from './session-storage-provider';
import { BrowserSessionStorage } from './browser-session-storage';

export function getBrowserSessionStorage(
  browserSessionStorage: BrowserSessionStorage,
  platformId: Object
): Storage | Object {
  if (isPlatformBrowser(platformId)) {
    return browserSessionStorage.sessionStorage;
  }

  return new Object();
}

const browserSessionStorageProvider: ClassProvider = {
  provide: SessionStorageProvider,
  useClass: BrowserSessionStorage
};

const sessionStorageProvider: FactoryProvider = {
  provide: GLOBAL_SESSION_STORAGE,
  useFactory: getBrowserSessionStorage,
  deps: [SessionStorageProvider, PLATFORM_ID]
};

export const SESSION_STORAGE_PROVIDERS = [browserSessionStorageProvider, sessionStorageProvider];
