import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { BasicInfoFormFields } from './types';

export enum BasicInfoFormAppearanceName {
  FULL = 'full',
  SOLO_WORKSPACE = 'solo_workspace',
  TEAM_WORKSPACE_OWNER = 'team_workspace_owner',
  TEAM_WORKSPACE_PROVIDER = 'team_workspace_provider',
  PACKAGE = 'package',
  PACKAGE_WORKSPACE_ADMIN = 'package_workspace_admin',
  PROGRAM = 'program',
  PROGRAM_WORKSPACE_ADMIN = 'program_workspace_admin',
  DRAWER_PACKAGE = 'drawer_package',
  DRAWER_PACKAGE_WORKSPACE_ADMIN = 'drawer_package_workspace_admin',
  DRAWER_PROGRAM = 'drawer_program',
  DRAWER_PROGRAM_WORKSPACE_ADMIN = 'drawer_program_workspace_admin'
}

type BasicInfoFormAppearanceMap = Record<BasicInfoFormFields, boolean>;

export const BasicInfoFormAppearanceFull: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: true,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: true,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: true,
  [BasicInfoFormFields.STATUS]: true,
  [BasicInfoFormFields.COUNT]: true,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: false
};

export const BasicInfoFormAppearanceSoloWorkspace: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: true,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: true,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: true,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: true,
  [BasicInfoFormFields.STATUS]: true,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: false
};

export const BasicInfoFormAppearanceTeamWorkspaceOwner: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: true,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: true,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: true,
  [BasicInfoFormFields.LOCATION]: false,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: true,
  [BasicInfoFormFields.STATUS]: true,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: false
};

export const BasicInfoFormAppearanceTeamWorkspaceProvider: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: true,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: true,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: true,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: true,
  [BasicInfoFormFields.STATUS]: true,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: false
};

export const BasicInfoFormAppearancePackage: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: true,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: false
};

export const BasicInfoFormAppearanceDrawerPackage: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: true,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: true
};

export const BasicInfoFormAppearanceWAPackage: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: true,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: false,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: true,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: false
};

export const BasicInfoFormAppearanceDrawerWAPackage: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: false,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: true,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: true
};

export const BasicInfoFormAppearanceProgram: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: true
};

export const BasicInfoFormAppearanceDrawerProgram: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: true,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: true
};

export const BasicInfoFormAppearanceWAProgram: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: false,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: true
};

export const BasicInfoFormAppearanceDrawerWAProgram: BasicInfoFormAppearanceMap = {
  [BasicInfoFormFields.FORM_HEADER_TITLE]: false,
  [BasicInfoFormFields.SESSION_TITLE]: true,
  [BasicInfoFormFields.SESSION_DESCRIPTION]: false,
  [BasicInfoFormFields.UPLOAD_COVER_IMAGE]: false,
  [BasicInfoFormFields.LOCATION]: false,
  [BasicInfoFormFields.DURATION]: true,
  [BasicInfoFormFields.RECURRENCE]: false,
  [BasicInfoFormFields.STATUS]: false,
  [BasicInfoFormFields.COUNT]: false,
  [BasicInfoFormFields.RESTRICT_CLIENT_BOOKING]: true
};

const BasicInfoFormAppearancesMap: Record<BasicInfoFormAppearanceName, BasicInfoFormAppearanceMap> = {
  [BasicInfoFormAppearanceName.FULL]: BasicInfoFormAppearanceFull,
  [BasicInfoFormAppearanceName.SOLO_WORKSPACE]: BasicInfoFormAppearanceSoloWorkspace,
  [BasicInfoFormAppearanceName.TEAM_WORKSPACE_OWNER]: BasicInfoFormAppearanceTeamWorkspaceOwner,
  [BasicInfoFormAppearanceName.TEAM_WORKSPACE_PROVIDER]: BasicInfoFormAppearanceTeamWorkspaceProvider,
  [BasicInfoFormAppearanceName.PACKAGE]: BasicInfoFormAppearancePackage,
  [BasicInfoFormAppearanceName.PACKAGE_WORKSPACE_ADMIN]: BasicInfoFormAppearanceWAPackage,
  [BasicInfoFormAppearanceName.PROGRAM]: BasicInfoFormAppearanceProgram,
  [BasicInfoFormAppearanceName.PROGRAM_WORKSPACE_ADMIN]: BasicInfoFormAppearanceWAProgram,
  [BasicInfoFormAppearanceName.DRAWER_PACKAGE]: BasicInfoFormAppearanceDrawerPackage,
  [BasicInfoFormAppearanceName.DRAWER_PACKAGE_WORKSPACE_ADMIN]: BasicInfoFormAppearanceDrawerWAPackage,
  [BasicInfoFormAppearanceName.DRAWER_PROGRAM]: BasicInfoFormAppearanceDrawerProgram,
  [BasicInfoFormAppearanceName.DRAWER_PROGRAM_WORKSPACE_ADMIN]: BasicInfoFormAppearanceDrawerWAProgram
};

@Directive({
  selector: '[basicInfoFormAppearance]'
})
export class BasicInfoFormAppearanceDirective {
  private appearance: BasicInfoFormAppearanceMap | undefined;
  private fieldName: BasicInfoFormFields | undefined;

  constructor(private readonly templateRef: TemplateRef<unknown>, private readonly viewContainer: ViewContainerRef) {}

  @Input()
  set basicInfoFormAppearance(appearanceName: BasicInfoFormAppearanceName | undefined) {
    if (!appearanceName) {
      console.warn(`Basic info form appearance name not provided.`);
      return;
    }
    this.appearance = BasicInfoFormAppearancesMap[appearanceName];

    this.updateView();
  }

  @Input()
  set basicInfoFormAppearanceFieldName(fieldName: BasicInfoFormFields | undefined) {
    if (!fieldName) {
      console.warn(`Basic info form field name not provided.`);
      return;
    }
    this.fieldName = fieldName;
    this.updateView();
  }

  private updateView(): void {
    if (!this.appearance || !this.fieldName) {
      return;
    }
    this.viewContainer.clear();

    if (this.appearance[this.fieldName]) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
