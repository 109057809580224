import { Component, Input, forwardRef, Attribute } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value = false;

  @Input()
  className = '';

  @Input()
  disabled = false;

  @Input()
  set value(val) {
    this._value = val;
    this._onChange(val);
    this._onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get value() {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onTouched: any = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering, @angular-eslint/no-attribute-decorator
  constructor(@Attribute('qaId') public qaId: string) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  writeValue(value) {
    this._value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
