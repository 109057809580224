import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GiftCouponsBodyComponent } from '@app/layouts/gift-coupons-layout/gift-coupons-body/gift-coupons-body.component';
import { GiftCouponsHeaderComponent } from '@app/layouts/gift-coupons-layout/gift-coupons-header/gift-coupons-header.component';
import { GiftCouponsContainerComponent } from '@app/layouts/gift-coupons-layout/gift-coupons-container/gift-coupons-container.component';
import { GiftCouponsLayoutComponent } from '@app/layouts/gift-coupons-layout/gift-coupons-layout.component';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { UserMenuModule } from '@app/modules/user-menu/user-menu.module';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [CommonModule, RouterModule, NotificationsModule, SharedModule, UserMenuModule],
  declarations: [
    GiftCouponsBodyComponent,
    GiftCouponsHeaderComponent,
    GiftCouponsContainerComponent,
    GiftCouponsLayoutComponent
  ]
})
export class GiftCouponsLayoutModule {}
