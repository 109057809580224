import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';

/*
  units as "style.height"
 */
const MAIN_LOGO_HEIGHT = '32px';

@Component({
  selector: 'app-header-logo',
  templateUrl: 'header-logo.template.html',
  styleUrls: ['header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderLogoComponent {
  mainLogo$: Observable<{
    url: string;
    src: string;
  }>;

  height = MAIN_LOGO_HEIGHT;

  constructor(brandingService: BrandingService, workspacesService: WorkspacesService) {
    this.mainLogo$ = combineLatest([
      brandingService.globalConfig$.pipe(map(({ urlInLogo }) => urlInLogo)),
      brandingService.mainLogo$.pipe(map(({ logo }) => logo)),
      workspacesService.activeWorkspace$.pipe(map(ws => ws))
    ]).pipe(
      map(([url, logo, ws]) => {
        return {
          url,
          src: ws?.branding?.logo || logo
        };
      })
    );
  }
}
