import { ClientModuleProgress } from './client-module-progress';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProgramClientProgressDetails {
  readonly modules: ClientModuleProgress[];
}

export class ProgramClientProgress {
  readonly modules: ClientModuleProgress[];

  constructor(progressDetails: IProgramClientProgressDetails) {
    if (!progressDetails) {
      throw new Error('Invalid argument');
    }

    this.modules = progressDetails.modules;
  }
}
