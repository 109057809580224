import { IScheduleTemplate } from '@app/modules/guide-session/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleOptions {
  readonly template: IScheduleTemplate;
  readonly bufferTime: BufferTime;
  readonly areExternalEventsBuffered?: boolean | null;
}

export class BufferTime {
  readonly before: number;
  readonly after: number;

  constructor(before?: Readonly<number>, after?: Readonly<number>) {
    this.before = before || 0;
    this.after = after || 0;
  }
}

export class ScheduleOptions {
  readonly template: IScheduleTemplate;
  readonly bufferTime: BufferTime;
  readonly areExternalEventsBuffered?: boolean | null;

  constructor(scheduleOptionsDetails: IScheduleOptions) {
    if (!scheduleOptionsDetails) {
      throw new Error('Invalid schedule options');
    }

    this.template = scheduleOptionsDetails.template;
    this.bufferTime = scheduleOptionsDetails.bufferTime;
    this.areExternalEventsBuffered = scheduleOptionsDetails.areExternalEventsBuffered;
  }
}
