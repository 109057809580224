import { SimpleSession } from '@app/shared/interfaces/session';

import { ProgramModuleClientProgressSessionTemplate } from '../program-client-progress-response';
import { BaseModuleProgress, IBaseModuleProgressDetails } from './base-module-progress';
import { ModuleProgressService } from './module-progress-service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseSessionModuleProgressDetails extends IBaseModuleProgressDetails {
  readonly serviceId?: number;
  readonly service?: ModuleProgressService;
  readonly sessionId?: number;
  readonly session?: SimpleSession;
  readonly sessionTemplate?: ProgramModuleClientProgressSessionTemplate;
  readonly guideId?: number;
  readonly eventId?: number;
}

export abstract class BaseSessionModuleProgress extends BaseModuleProgress {
  readonly serviceId?: number;
  readonly service?: ModuleProgressService;
  readonly sessionId?: number;
  readonly session?: SimpleSession;
  readonly sessionTemplate?: ProgramModuleClientProgressSessionTemplate;
  readonly guideId?: number;
  readonly eventId?: number;

  constructor(moduleDetails: Readonly<IBaseSessionModuleProgressDetails>) {
    super(moduleDetails);

    ['serviceId', 'service', 'sessionId', 'session', 'sessionTemplate', 'guideId', 'eventId'].forEach(prop => {
      // @ts-expect-error TS7053
      if (moduleDetails[prop] != null) {
        // @ts-expect-error TS7053
        this[prop] = moduleDetails[prop];
      }
    });
  }
}
