import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-upcoming-program',
  templateUrl: './upcoming-program.component.html',
  styleUrls: ['./upcoming-program.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'upcoming-program'
  }
})
export class UpcomingProgramComponent {}
