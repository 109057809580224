import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { map } from 'rxjs/operators';
import { Recording } from '@app/screens/guide/guide-sessions/types/recording';
import { DateTime } from 'luxon';

@Injectable()
export class GuideEventRecordingsService {
  readonly ENDPOINT = `${config.apiPath}/user/guide/events`;

  constructor(private _http: HttpClient) {}

  getRecordings$(eventId: number): Observable<Recording[]> {
    return this._http.get<{ recordings: Recording[] }>(`${this.ENDPOINT}/${eventId}/recordings`).pipe(
      map(({ recordings }) =>
        recordings.map(recording => {
          const startTime = (recording.startTime as unknown as number) * 1000;
          return {
            ...recording,
            startTime: DateTime.fromMillis(startTime).toISO()
          };
        })
      )
    );
  }
}
