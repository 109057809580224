import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideNote } from '@app/modules/guide-notes/guide-notes.types';
import {
  INoteCardUpdateContent,
  INoteEditorContent,
  NotesTypes,
  NotesTypesSortProp
} from '@app/shared/interfaces/notes';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { NoteActionModalComponent } from '@app/modules/user-notes/components/note-action-modal/note-action-modal.component';
import { GuideNotesService } from './guide-notes.service';
import { GuideNotesApiService } from './guide-notes-api.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuideNotesBoardState {
  notes: GuideNote[];
  cursor: string | null;
  notesType: NotesTypes;
  cursorPinned: string | null;
  scrollTo: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any;
}

@Injectable()
export abstract class GuideNotesBoardService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected abstract readonly _serviceId: symbol;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _limit = 15;

  protected abstract relationList: string[];

  protected destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _defaultInitialState: IGuideNotesBoardState = {
    notes: [],
    cursor: null,
    cursorPinned: null,
    notesType: NotesTypes.PINNED,
    scrollTo: null
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _state$: BehaviorSubject<IGuideNotesBoardState | null>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _resetEditorState$: Subject<void> = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _notesStatuses: Map<number, string> = new Map([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _noteChangeSubscription: Map<number, Subscription> = new Map([]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get state$() {
    return this._state$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get resetEditorState$() {
    return this._resetEditorState$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get state() {
    return this._state$.getValue();
  }

  protected constructor(
    protected readonly _guideNotes: GuideNotesService,
    protected readonly _guideNotesApi: GuideNotesApiService,
    protected readonly modal: NgbModal,
    protected _initialState: IGuideNotesBoardState
  ) {
    this._state$ = new BehaviorSubject(this._initialState || this._defaultInitialState);

    _guideNotes.create$
      .pipe(
        filter(({ serviceId }) => serviceId !== this._serviceId),
        switchMap(({ noteId }) => this._guideNotesApi.getNote$(noteId)),
        filter(note => this._filterNotesByRelationPredicate(note)),
        takeUntil(this.destroy$)
      )
      .subscribe(note => {
        // @ts-expect-error TS2339
        const { notes: currentNotes, notesType, cursor, ...rest } = this.state;

        if (notesType === NotesTypes.STANDARD && cursor && cursor < note.updatedAt) {
          const [pinnedNotes, restNotes] = this._splitNotesByPinStatus([note, ...currentNotes]);
          const sortedStandardNotes = this._sortNotesBy(restNotes, NotesTypesSortProp.STANDARD);
          this.setState({
            ...rest,
            notesType,
            cursor,
            notes: [...pinnedNotes, ...sortedStandardNotes]
          });
        }
      });

    _guideNotes.update$
      .pipe(
        filter(({ serviceId }) => serviceId !== this._serviceId),
        switchMap(({ noteId }) => this._guideNotesApi.getNote$(noteId)),
        filter(note => this._filterNotesByRelationPredicate(note)),
        filter(({ id, pinned }) => {
          // if note not in the list and unpinned skip it
          // @ts-expect-error TS2339
          const { notes } = this.state;
          // @ts-expect-error TS7031
          const noteToUpdate = notes.find(({ id: currentId }) => currentId === id);
          return !!noteToUpdate || pinned;
        }),
        map(note => {
          // @ts-expect-error TS2339
          const { notes: currentNotes, cursor, cursorPinned, notesType, ...restState } = this.state;
          const { contentDeltaFormat, contentText, content, updatedAt, pinned: pinnedStatus, pinnedAt, id } = note;
          // @ts-expect-error TS7031
          const noteToUpdate = currentNotes.find(({ id: currentId }) => currentId === id);

          const shouldAddPinnedNote =
            (!noteToUpdate && notesType === NotesTypes.STANDARD) ||
            // @ts-expect-error TS2531
            (notesType === NotesTypes.PINNED && cursorPinned && cursorPinned < pinnedAt);

          if (shouldAddPinnedNote) {
            // note not in the list and pinned
            const [unsortedPinnedNotes, standardNotes] = this._splitNotesByPinStatus([note, ...currentNotes]);
            const pinnedNotes = this._sortNotesBy(unsortedPinnedNotes, NotesTypesSortProp.PINNED);

            const updatedNotes = [...pinnedNotes, ...standardNotes];

            return {
              ...restState,
              notes: updatedNotes,
              cursor,
              cursorPinned,
              notesType,
              scrollTo: null,
              countOfRestNotesToLoad: 0
            };
          }

          const noteChangedPinStatus = noteToUpdate && noteToUpdate.pinned !== pinnedStatus;

          noteToUpdate.contentDeltaFormat = contentDeltaFormat;
          noteToUpdate.contentText = contentText;
          noteToUpdate.content = content;
          noteToUpdate.updatedAt = updatedAt;
          noteToUpdate.pinned = pinnedStatus;
          noteToUpdate.pinnedAt = pinnedAt;

          let notes = [...currentNotes];

          if (noteChangedPinStatus && pinnedStatus === true) {
            const [unsortedPinnedNotes, standardNotes] = this._splitNotesByPinStatus(currentNotes);
            const pinnedNotes = this._sortNotesBy(unsortedPinnedNotes, NotesTypesSortProp.PINNED);
            notes = [...pinnedNotes, ...standardNotes];
          }

          if (noteChangedPinStatus && pinnedStatus === false) {
            const shouldRemoveNoteFromList =
              (notesType === NotesTypes.PINNED && cursorPinned) ||
              (notesType === NotesTypes.STANDARD && cursor && cursor > updatedAt);

            if (shouldRemoveNoteFromList) {
              // @ts-expect-error TS7031
              const updatedNotes = currentNotes.filter(({ id: currentId }) => currentId !== id);
              return {
                ...restState,
                notes: updatedNotes,
                cursor,
                notesType,
                cursorPinned,
                scrollTo: null,
                countOfRestNotesToLoad: 1
              };
            }

            const [pinnedNotes, unsortedStandardNotes] = this._splitNotesByPinStatus(currentNotes);
            const standardNotes = this._sortNotesBy(unsortedStandardNotes, NotesTypesSortProp.STANDARD);
            notes = [...pinnedNotes, ...standardNotes];
          }

          return {
            ...restState,
            cursor,
            notesType,
            cursorPinned,
            notes,
            scrollTo: null,
            countOfRestNotesToLoad: 0
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad);
        }
      });

    _guideNotes.delete$
      .pipe(
        filter(({ serviceId }) => serviceId !== this._serviceId),
        takeUntil(this.destroy$)
      )
      .subscribe(({ noteId }) => {
        // @ts-expect-error TS2339
        const { notes: currentNotes, ...rest } = this.state;
        this.setState({
          // @ts-expect-error TS7031
          notes: currentNotes.filter(({ id }) => noteId !== id),
          ...rest
        });
      });

    _guideNotes.share$
      .pipe(
        filter(({ serviceId }) => serviceId !== this._serviceId),
        switchMap(({ noteIds }) => this._guideNotesApi.getSharedNotes$(noteIds)),
        filter(sharedNotes => this._filterNotesByRelationPredicate(sharedNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe(sharedNotes => this._updateStateAfterNotesAreShared(sharedNotes));

    _guideNotes.revokeAccess$
      .pipe(
        filter(({ serviceId }) => serviceId !== this._serviceId),
        switchMap(({ noteIds }) => this._guideNotesApi.getSharedNotes$(noteIds)),
        filter(sharedNotes => this._filterNotesByRelationPredicate(sharedNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe(sharedNotes => this._updateStateAfterNotesAreShared(sharedNotes));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setState(state: IGuideNotesBoardState) {
    this._state$.next(state);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetState() {
    this._state$.next(this._initialState);
  }

  abstract create({ text, content, html }: INoteEditorContent): void;

  abstract loadNotes(search?: string): void;

  abstract loadMoreNotes(count?: number, search?: string): void;

  // @ts-expect-error TS7006
  abstract manageAccess(event): void;

  update({ index, text, content, html }: INoteCardUpdateContent, stopEditing: boolean): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, ...rest } = this.state;
    const currentNote = currentNotes[index];
    const currentNoteId = currentNote.id;
    const noteHtmlBeforeChange = currentNote.content;
    if (text && text.trim() === '' && stopEditing) {
      this.delete(index);
      return;
    }

    if (noteHtmlBeforeChange === html) {
      return;
    }
    const currentNoteStatus = this._notesStatuses.get(currentNoteId) || 'saved';
    switch (currentNoteStatus) {
      case 'unsaved':
      case 'saved':
        this._notesStatuses.set(currentNoteId, 'save-pending');
        // eslint-disable-next-line no-case-declarations
        const subscription = this._guideNotesApi
          .updateNote$(currentNoteId, {
            content: html,
            contentText: text,
            contentDeltaFormat: content
          })
          .subscribe(
            ({ content: contentHtml, contentDeltaFormat, contentText, updatedAt }) => {
              if (stopEditing) {
                this.setState({
                  // @ts-expect-error TS7006
                  notes: currentNotes.map(note =>
                    note.id === currentNoteId
                      ? {
                          ...note,
                          contentDeltaFormat,
                          contentText,
                          content: contentHtml,
                          updatedAt
                        }
                      : note
                  ),
                  ...rest,
                  scrollTo: null
                });
              }

              this._guideNotes.updateNote({
                serviceId: this._serviceId,
                noteId: currentNoteId
              });

              this._notesStatuses.set(currentNoteId, 'saved');
            },
            () => {
              this._notesStatuses.set(currentNoteId, 'unsaved');
            }
          );
        this._noteChangeSubscription.set(currentNoteId, subscription);
        break;
      case 'save-pending':
        if (this._noteChangeSubscription.has(currentNoteId)) {
          // @ts-expect-error TS2532
          this._noteChangeSubscription.get(currentNoteId).unsubscribe();
          this._noteChangeSubscription.delete(currentNoteId);
        }
        this._notesStatuses.delete(currentNoteId);
        this.update({ index, text, content, html }, stopEditing);
        break;
    }
  }

  delete(index: number): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, ...rest } = this.state;
    const { id: deleteId } = currentNotes[index];
    this._guideNotesApi.deleteNote$(deleteId).subscribe(() => {
      this.setState({
        // @ts-expect-error TS7031
        notes: currentNotes.filter(({ id }) => deleteId !== id),
        ...rest,
        scrollTo: null
      });
      this._guideNotes.deleteNote({
        serviceId: this._serviceId,
        noteId: deleteId
      });
    });
  }

  abstract grantViewerAccess(index: number): void;

  abstract revokeViewerAccess(index: number): void;

  pinNote(index: number): void {
    // @ts-expect-error TS2531
    const { id: pinnedId } = this.state.notes[index];

    this._guideNotesApi
      .pinNote$(pinnedId)
      .pipe(
        map(({ pinnedAt }) => {
          // @ts-expect-error TS2339
          const { notes: currentNotes, notesType, cursorPinned, ...restState } = this.state;

          if (notesType === NotesTypes.PINNED && cursorPinned && cursorPinned < pinnedAt) {
            // @ts-expect-error TS7031
            const updatedNotes = currentNotes.filter(({ id: currentId }) => currentId !== pinnedId);

            return {
              ...restState,
              notes: updatedNotes,
              notesType,
              cursorPinned,
              scrollTo: null,
              countOfRestNotesToLoad: 1
            };
          }

          // @ts-expect-error TS7006
          const updatedNotes = currentNotes.map(note => {
            if (note.id === pinnedId) {
              note.pinned = true;
              note.pinnedAt = pinnedAt;
            }
            return note;
          });

          const [pinnedNotes, restNotes] = this._splitNotesByPinStatus(updatedNotes);
          const sortedPinnedNotes = this._sortNotesBy(pinnedNotes, NotesTypesSortProp.PINNED);

          return {
            ...restState,
            notes: [...sortedPinnedNotes, ...restNotes],
            notesType,
            cursorPinned,
            scrollTo: pinnedId,
            countOfRestNotesToLoad: 0
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad);
        }

        this._guideNotes.updateNote({
          serviceId: this._serviceId,
          noteId: pinnedId
        });
      });
  }

  unpinNote(index: number): void {
    // @ts-expect-error TS2531
    const { id: pinnedId, updatedAt } = this.state.notes[index];

    const { componentInstance, result } = this.modal.open(NoteActionModalComponent, { centered: true });

    componentInstance.message = `Are you sure you want to remove this pinned note?`;
    componentInstance.title = `Un-pin note?`;
    componentInstance.rejectText = `Cancel`;
    componentInstance.confirmText = `Un-pin note`;

    modalResultToObservable$(result)
      .pipe(
        filter(confirm => confirm),
        switchMap(() => this._guideNotesApi.unpinNote$(pinnedId)),
        map(() => {
          // @ts-expect-error TS2339
          const { notes: currentNotes, cursor, cursorPinned, notesType, ...restState } = this.state;

          if (
            (notesType === NotesTypes.PINNED && cursorPinned) ||
            (notesType === NotesTypes.STANDARD && cursor && cursor > updatedAt)
          ) {
            // @ts-expect-error TS7031
            const updatedNotes = currentNotes.filter(({ id: currentId }) => currentId !== pinnedId);
            return {
              ...restState,
              notes: updatedNotes,
              notesType,
              cursor,
              cursorPinned,
              scrollTo: null,
              countOfRestNotesToLoad: 1
            };
          }

          // @ts-expect-error TS7006
          const updatedNotes = currentNotes.map(note => {
            if (note.id === pinnedId) {
              note.pinned = false;
            }
            return note;
          });

          const [pinnedNotes, restNotes] = this._splitNotesByPinStatus(updatedNotes);
          const sortedRestNotes = this._sortNotesBy(restNotes, NotesTypesSortProp.STANDARD);

          return {
            ...restState,
            notes: [...pinnedNotes, ...sortedRestNotes],
            notesType,
            cursor,
            cursorPinned,
            scrollTo: null,
            countOfRestNotesToLoad: 1
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad);
        }

        this._guideNotes.updateNote({
          serviceId: this._serviceId,
          noteId: pinnedId
        });
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  protected _updateStateAfterNotesAreShared(sharedNotes: GuideNote[]) {
    // @ts-expect-error TS2339
    const { notes: currentNotes, ...rest } = this.state;
    const sharedNotesMap = new Map(sharedNotes.map(note => [note.id, note]));
    this.setState({
      // @ts-expect-error TS7006
      notes: currentNotes.map(note => {
        const sharedNote = sharedNotesMap.get(note.id);
        return sharedNote || note;
      }),
      ...rest,
      scrollTo: null
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  protected _splitNotesByPinStatus(currentNotes: GuideNote[]) {
    return currentNotes.reduce(
      ([pinned, rest], note) => {
        return note.pinned ? [[...pinned, note], rest] : [pinned, [...rest, note]];
      },
      [[], []]
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _sortNotesBy(notes: GuideNote[], prop: keyof GuideNote): GuideNote[] {
    return notes.sort(({ [prop]: propA }, { [prop]: propB }) => {
      // @ts-expect-error TS2533
      if (propA < propB) {
        return 1;
      }
      // @ts-expect-error TS2533
      if (propA > propB) {
        return -1;
      }
      return 0;
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _filterNotesByRelationPredicate(notes: GuideNote[] | GuideNote): boolean {
    const incomingNotesRelationSet: Set<string> = new Set(
      // @ts-expect-error TS2769
      [].concat(notes).reduce((relations, { metaGuideContacts, metaGuideClients }) => {
        // @ts-expect-error TS2488
        const [metaGuideClient] = metaGuideClients || [];
        const { clientId } = metaGuideClient || {};
        const { contactRelationId } = metaGuideContacts || {};

        if (!clientId && !contactRelationId) {
          return relations;
        }

        const relationProps = clientId
          ? { id: clientId, type: GuideRelationTypes.GUIDE_CLIENT }
          : { id: contactRelationId, type: GuideRelationTypes.GUIDE_CONTACT };
        const relationId = GuideContact.createLocalId(relationProps);

        return [...relations, relationId];
      }, [])
    );

    if (incomingNotesRelationSet.size === 0) {
      return false;
    }

    return this.relationList.some(relation => incomingNotesRelationSet.has(relation));
  }
}
