import { createSelector } from '@ngrx/store';
import { WORKSPACE_GUIDES_STORE } from './commons/consts';
import { WorkspaceGuidesState } from './commons/types';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { puiSortFunction } from '@awarenow/profi-ui-core';

const sortWorkspaceGuides = (guides: IWorkspaceMember[]): IWorkspaceMember[] =>
  Array.from(guides).sort((guideA, guideB) => puiSortFunction('asc')(guideA.username, guideB.username));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectWorkspaceGuidesStore = (state: { [WORKSPACE_GUIDES_STORE]: WorkspaceGuidesState }) =>
  state[WORKSPACE_GUIDES_STORE];

export const selectWorkspaceGuidesStatus = createSelector(selectWorkspaceGuidesStore, state => state.status);
export const selectWorkspaceGuidesData = createSelector(selectWorkspaceGuidesStore, state => state.data);
export const selectSortedWorkspaceGuidesData = createSelector(selectWorkspaceGuidesData, guides =>
  sortWorkspaceGuides(guides)
);
