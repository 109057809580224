<span *ngIf="!session.hidePrice || showPrice">
  <ng-container *ngIf="!priceOnly">
    <span class="session-payment-type">
      <ng-container [ngSwitch]="sessionType">
        <ng-container *ngSwitchCase="SessionPricingTypes.SESSION_OFFER" i18n="@@sessionOffer"
          >Session offer</ng-container
        >
        <ng-container *ngSwitchCase="SessionPricingTypes.FREE_SESSION" i18n="@@freeSession">Free session</ng-container>
        <ng-container *ngSwitchCase="SessionPricingTypes.PAID_SESSION" i18n="@@paidSession">Paid session</ng-container>
      </ng-container>
      :
    </span>
    {{ session?.duration }}
    <ng-container i18n="@@min">min</ng-container>
    <ng-container *ngIf="session?.payRate">
      -
      <span class="session-wage">
        {{ wage | platformCurrency }}
        <ng-container i18n="@@totalLC">total</ng-container>
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="priceOnly">
    <ng-container *ngIf="session?.payRate > 0">
      <span class="session-wage" [attr.data-qa-id]="'session-payment-formatter-paid-title-' + session?.name">
        {{ wage | platformCurrency }}
      </span>
    </ng-container>
    <ng-container *ngIf="session?.payRate === 0">
      <span [attr.data-qa-id]="'session-payment-formatter-free-title-' + session?.name" i18n="@@free">Free</span>
    </ng-container>
  </ng-container>
</span>
