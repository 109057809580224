import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { SafePipeModule } from '@app/shared/pipes/safe/safe.module';
import { ClipboardModule } from 'ngx-clipboard';
import { BookingPopupComponent } from '@app/modules/ui-kit/widgets/booking-popup/booking-popup.component';
import { PuiButtonModule, PuiIconModule, PuiLinkModule } from '@awarenow/profi-ui-core';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  declarations: [NotificationComponent, BookingPopupComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    SafePipeModule,
    ClipboardModule,
    PuiIconModule,
    PuiLinkModule,
    OverlayModule,
    PuiButtonModule
  ]
})
export class BookingPopupModule {}
