<ng-container *puiLet="relation$ | async as client">
  <ng-container *ngIf="!!client">
    <ng-container>
      <app-notes-filters
        [searchControl]="searchControl"
        [canShareNotes]="canShareNotes(client)"
        [clientId]="client?.id"></app-notes-filters>
      <app-guide-relation-notes-board
        class="client-card-notes__main"
        [searchControl]="searchControl"
        [isAssigned]="isAssigned(client)"
        [guideRelation]="client"></app-guide-relation-notes-board>
    </ng-container>
  </ng-container>
</ng-container>
