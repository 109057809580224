import { NotificationsService } from 'angular2-notifications';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticServiceTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services';
import { acceptedEventRequestMessage } from '@app/core/session/notifications';
import { SessionsService } from '@app/core/session/sessions.service';
import { ClientEventActionTypes, IEventActionResult } from '@app/core/shared-event-actions/types';
import { ClientGuidesService } from '@app/core/users/client-guides.service';
import { ClientGuide } from '@app/core/users/types';
import {
  IGuideService,
  IServiceBookingOptions,
  IServiceBookingResult,
  ServiceBookingService
} from '@app/modules/book-service';
import { BookingModalService } from '@app/modules/book-service/components/booking-modal/booking-modal.service';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { ConfirmSessionCancelService } from '@app/modules/confirm-session-cancel/confirm-session-cancel.service';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { ServiceReschedulingService } from '@app/modules/rescheduling-service';
import { SessionActionResultModalComponent } from '@app/modules/session-action-result/components/session-action-result-modal/session-action-result-modal.component';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import {
  Guide,
  IClientGuideAvailableSession,
  IClientGuideSession
} from '@app/screens/client/client-dashboard/types/types';
import { ClientSessionFeedbackComponent } from '@app/shared/components/client-session-feedback/client-session-feedback.component';
import { SessionTypes } from '@app/shared/enums/session-types';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { Session, SimpleSession } from '@app/shared/interfaces/session';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';
import { BookingWidgetOptions } from '@appWidget/modules/booking/interfaces';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// BookingModalService

// ToDo refactor
@Injectable()
export class ClientSessionActionsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    readonly clientGuidesService: ClientGuidesService,
    readonly sessions: SessionsService,
    private readonly modal: NgbModal,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, any>,
    private readonly rescheduler: ServiceReschedulingService,
    private readonly currentPayments: CurrentPaymentService,
    private readonly auth: AuthService,
    private readonly notificationsService: NotificationsService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly confirmSessionCancelService: ConfirmSessionCancelService,
    private readonly bookingService: BookingService,
    private readonly dialog: PuiDialogService,
    private readonly bookingModalService: BookingModalService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  acceptOffer(clientSession: IClientGuideSession): void {
    const { guide, session, paymentFailed } = clientSession;
    const sessionIsFree = !paymentFailed && session.isSessionFree && !session.payRate;
    const options: BookingWidgetOptions = {
      type: sessionIsFree ? BookingTypes.SESSION_ACCEPTED : BookingTypes.SESSION_PAY_ACCEPTED,
      guideId: guide.id,
      sessionTemplateId: session.templateId ?? undefined,
      hideStepper: sessionIsFree,
      session
    };

    const isPay$ = sessionIsFree ? this.currentPayments.pay(true).sessionOffer$(session.id) : of(null);
    isPay$
      .pipe(
        switchMap(() => this.bookingModalService.openAsync(options).afterClosed$),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const { payRate, serviceType } = session;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const analyticsData: any = {
          servicePrice: payRate,
          source: AnalyticSourceTypes.INVITATION
        };

        if (serviceType === SessionTypes.GROUP_INSTANCE) {
          analyticsData.serviceType = AnalyticServiceTypes.GROUP_SESSION;
        }

        if (serviceType === SessionTypes.SESSION) {
          analyticsData.serviceType = AnalyticServiceTypes.INDIVIDUAL_SESSION;
        }

        this.analyticsService.event(InternalEvents.SERVICE_BOOK, analyticsData);
      });
  }

  approveReschedulement(clientSession: IClientGuideSession, withoutPaid = false): void {
    const { guide, session } = clientSession;
    const sessionIsFree = (session.isSessionFree && !session.payRate) || withoutPaid;
    const options: BookingWidgetOptions = {
      type: sessionIsFree ? BookingTypes.SESSION_ACCEPTED : BookingTypes.SESSION_PAY_ACCEPTED,
      guideId: guide.id,
      sessionTemplateId: session.templateId ?? undefined,
      hideStepper: sessionIsFree,
      session,
      withoutPaid,
      workspaceId: guide.workspaceId
    };

    this.sessions
      .approveReschedule$(session.id, session.collectionType)
      .pipe(
        switchMap(() => this.bookingModalService.openAsync(options).afterClosed$),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const successMsg = acceptedEventRequestMessage(session.name || '', session.recurringSessionsDetails?.length);

        this.notificationsService.success(successMsg);
      });
  }

  archiveSession(clientSession: IClientGuideSession): void {
    this.sessions.archiveSession$(clientSession.session).subscribe();
  }

  cancelSession(clientSession: IClientGuideSession): void {
    const { guide, session } = clientSession;
    const options: BookingWidgetOptions = {
      type: BookingTypes.CANCELLATION,
      guideId: guide.id,
      sessionTemplateId: session.templateId || undefined,
      session
    };

    this.bookingModalService.openWithDelay(options);
  }

  declineOffer(clientSession: IClientGuideSession): void {
    const { guide, session } = clientSession;
    const options: BookingWidgetOptions = {
      type: BookingTypes.CANCELLATION,
      guideId: guide.id,
      sessionTemplateId: session.templateId || undefined,
      session
    };

    this.bookingModalService.openWithDelay(options);
  }

  findSessionAndLeaveReview(sessionDetails: { sessionId: number; privateOnly: boolean }): void {
    this.sessions.pastSessions$
      .pipe(
        skip(1), // skip default from behavior subject
        take(1),
        map(pastSessions => {
          const { sessionId } = sessionDetails;
          return pastSessions.find(pastSession => pastSession.id === sessionId);
        }),
        filter(session => !!session),
        // @ts-expect-error TS2345
        switchMap(session => this.leaveReview$(session, sessionDetails.privateOnly)),
        switchMap(review => {
          const { sessionId, mark, comment, privateComment } = review;
          return this.sessions.sendFeedback$(sessionId, mark, comment, privateComment);
        }),
        tap(() => this.clientGuidesService.refresh())
      )
      .subscribe();
  }

  openGuide(guideId: number, workspaceId: number): void {
    this.bookingService.guideIndividualPage(guideId, workspaceId);
  }

  reorderSession(params: {
    guide?: ClientGuide | Guide;
    clientSession?: IClientGuideSession;
    analyticSourceType?: AnalyticSourceTypes;
    bookingType: BookingTypes;
  }): void {
    const sessionTemplateId = params.clientSession?.session.templateId;
    const guideId = params.clientSession?.guide.id;
    const workspaceId = params.clientSession?.guide.workspaceId;

    // TODO: PR-5909 refactor this, same code in program-modules-board.component.ts
    const serviceParent = params.clientSession?.session.serviceParent;
    if (serviceParent?.type === GuideServiceTypes.PROGRAM) {
      if (params.clientSession?.guide.workspaceType === WorkspacesTypes.SOLO) {
        this.bookingService.openSoloGuideAvailableProgramSession(
          guideId!,
          serviceParent.id,
          sessionTemplateId!,
          params.clientSession?.guide.workspaceType!
        );
      } else {
        this.bookingService.openTeamAvailableProgramSession(
          workspaceId!,
          serviceParent.id,
          sessionTemplateId!,
          params.clientSession?.guide.workspaceType!
        );
      }
    } else if (serviceParent?.type === GuideServiceTypes.PACKAGE) {
      if (params.clientSession?.guide.workspaceType === WorkspacesTypes.SOLO) {
        this.bookingService.openSoloGuideAvailablePackageSession(
          guideId!,
          serviceParent.id,
          sessionTemplateId!,
          params.clientSession?.guide.workspaceType!,
          serviceParent
        );
      } else {
        this.bookingService.openTeamAvailablePackageSession(
          workspaceId!,
          serviceParent.id,
          sessionTemplateId!,
          params.clientSession?.guide.workspaceType!,
          serviceParent
        );
      }
    } else if (sessionTemplateId && guideId && workspaceId) {
      const ref = this.bookingService.openService(sessionTemplateId, guideId, workspaceId);

      ref.afterClosed$.subscribe(
        (bookingSuccess: IServiceBookingResult) => {
          if (bookingSuccess.serviceType === GuideServiceTypes.PROGRAM) {
            this.router.navigate(['/client/programs', bookingSuccess.id, 'modules']);
          }
        },
        error => {
          if (error && error.error && error.error.msg) {
            this.notificationsService.error(error.error.msg);
          } else {
            this.notificationsService.error('Failed to book');
          }
        }
      );
    }
  }

  private mapToSimpleSessionFromDifferentFormats(
    session: SimpleSession & {
      template?: { description: string; regularSessionTemplate: { connectionType: ConnectionType }[] };
    } & { date?: string }
  ): SimpleSession {
    return {
      id: session.id,
      guideId: session.guideId,
      clientId: session.clientId,
      dateStart: session.date || session.dateStart,
      status: session.status,
      name: session.name,
      templateId: session.templateId,
      payRate: session.payRate,
      duration: session.duration,
      description: session.description || session.template?.description,
      connectionType: session.connectionType || session.template?.regularSessionTemplate[0].connectionType,
      collectionType: session.collectionType
    } as SimpleSession;
  }

  rescheduleSession(clientSession: IClientGuideSession, withoutPaid = false): void {
    const { session, guide } = clientSession;
    const options: BookingWidgetOptions = {
      type: BookingTypes.RESCHEDULE,
      sessionTemplateId: session.templateId || undefined,
      session: this.mapToSimpleSessionFromDifferentFormats(session),
      withoutPaid: !session.paymentFailed || withoutPaid,
      guideId: guide.id,
      workspaceId: guide.workspaceId,
      workspaceType: guide.workspaceType
    };
    if (session.serviceParent) {
      if (session.serviceParent?.type === GuideServiceTypes.PROGRAM) {
        options.programId = session.serviceParent?.id;
      } else if (session.serviceParent?.type === GuideServiceTypes.PACKAGE) {
        options.packageId = session.serviceParent?.id;
      }
    }
    console.log('booking modal options:', options, clientSession);

    this.bookingModalService.openWithDelay(options);
  }

  showSessionActionResult(eventActionResult: IEventActionResult): void {
    this.showSessionActionResultModal$(eventActionResult).subscribe(() => {
      // ToDo https://profi-io.atlassian.net/browse/PR-3525
      this.serviceBooking._modalRef?.close();
    });
  }

  bookAvailableSession(clientSession: IClientGuideAvailableSession, analyticSourceType?: AnalyticSourceTypes): void {
    const requiresHostSelection = WorkspaceUtility.isTeam(clientSession.session.workspace);

    this.serviceBooking.cleanUp();

    // setTimeout for correct sequence
    setTimeout(() => {
      this.serviceBooking
        .book$({
          guide: clientSession.guide,
          service: {
            // @ts-expect-error TS2322
            id: clientSession.session.templateId,
            guideId: clientSession.guide.id,
            name: clientSession.session.name,
            type: clientSession.session.serviceType,
            // @ts-expect-error TS2322
            duration: clientSession.session.duration,
            serviceParent: clientSession.session.serviceParent,
            sessionType: clientSession.session.sessionType,
            availableSessions: clientSession.session.availableSessions,
            requiresHostSelection
          },
          requiresHostSelection,
          analyticSourceType
        })
        .subscribe(
          () => {},
          error => {
            if (error && error.error && error.error.msg) {
              this.notificationsService.error(error.error.msg);
            } else {
              this.notificationsService.error('Failed to book');
            }
          }
        );
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private leaveReview$(session: Session, privateOnly = false): Observable<any> {
    const { componentInstance, result } = this.modal.open(ClientSessionFeedbackComponent, {
      keyboard: false,
      centered: true,
      backdrop: 'static',
      windowClass: `session-feedback-modal`
    });

    componentInstance.session = session;
    componentInstance.privateOnly = privateOnly;

    return modalResultToObservable$(result);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private showSessionActionResultModal$(eventActionResult: IEventActionResult): Observable<any> {
    const actionTypesToShow: string[] = [
      ClientEventActionTypes.ACCEPT_REQUEST,
      ClientEventActionTypes.ACCEPT_RESCHEDULE,
      ClientEventActionTypes.RESCHEDULE,
      ClientEventActionTypes.CANCEL
    ];

    if (!actionTypesToShow.includes(eventActionResult.actionType)) {
      return EMPTY;
    }

    const { actionType, eventDetails } = eventActionResult;
    const { componentInstance, result } = this.modal.open(SessionActionResultModalComponent);

    componentInstance.actionResult = { actionType, session: eventDetails };
    componentInstance.canConfirm = true;

    return modalResultToObservable$(result);
  }
}
