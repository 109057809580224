// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { ICroppedImage } from '../../interfaces/image';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-avatar-modal.component.html',
  styleUrls: ['./upload-avatar-modal.component.scss']
})
export class UploadAvatarModalComponent {
  @Input()
  // @ts-expect-error TS2564
  event: ImageCroppedEvent;

  @Input()
  imageFormat: OutputFormat = 'jpeg';

  @Input()
  imageQuality = 92;

  @Input()
  resizeToWidth = 128;

  @ViewChild('imageCropper', { static: false })
  // @ts-expect-error TS2564
  imageCropper: ImageCropperComponent;

  // @ts-expect-error TS2564
  croppedImageString: string;

  aspectRatio = 1;

  // @ts-expect-error TS2564
  fullImage: ICroppedImage;

  roundCropper = true;

  isLoading = true;

  constructor(private activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  imageCropped(event: ImageCroppedEvent): void {
    // @ts-expect-error TS2322
    this.croppedImageString = event.base64;
  }

  imageLoaded(): void {
    this.isLoading = false;
  }

  save(): void {
    this.activeModal.close({
      avatar: this.buildCroppedImage()
    });
  }

  private buildCroppedImage(): ICroppedImage {
    return {
      imageDataUri: this.croppedImageString,
      name: this.generateImageName(32, this.imageFormat)
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private generateImageName(nameLength = 32, imageFormat: OutputFormat = 'jpeg'): string {
    return `img-${this.makeid(32)}.${imageFormat}`;
  }

  private makeid(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
