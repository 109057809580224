import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PuiDialogRef } from '@awarenow/profi-ui-core';
import { RedirectAfterSaveActions } from '../../can-redirect-after-save';

@Component({
  selector: 'app-guide-changes-for-new-users-dialog',
  templateUrl: './guide-changes-for-new-users-dialog.component.html',
  styleUrls: ['./guide-changes-for-new-users-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideChangesForNewUsersDialogComponent {
  constructor(private readonly dialogRef: PuiDialogRef) {}

  save(): void {
    this.dialogRef.close(RedirectAfterSaveActions.SAVE);
  }

  cancel(): void {
    this.dialogRef.close(RedirectAfterSaveActions.CANCEL);
  }
}
