<div class="auth-body">
  <h4 class="title" i18n="@@signInTfaAppTitle">Two-factor authentication</h4>
  <div class="auth-form">
    <div class="pui-space_top-8 pui-space_bottom-2">
      <sms-otp-input [phoneNumber]="tfaInfo.phoneNumber" (validCodeTyped)="onValidCodeTyped($event)"></sms-otp-input>
    </div>

    <div class="pui-space_top-8 backup-code">
      <span i18n="@@signInTfaDontHavePhone">Don’t have your phone?</span>
      <span i18n="@@signInTfaUseBackupCodes" class="btn-link" (click)="useBackupCode.emit()"
        >Use a backup code to access your account</span
      >
    </div>
  </div>
</div>
