import { getConstantOrderArraysPatch } from '@app/screens/guide/guide-programs/types/helpers';
import { IDiff, DiffDetails } from '../util-types';
import { Question } from './question';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizDetails {
  id?: number;
  localId: number;
  questions: Question[];
}

export interface GuideQuiz {
  id: number;
  title: string;
  questions: Question[];
}

export class Quiz implements IDiff<IQuizDetails> {
  readonly id?: number;
  // @ts-expect-error TS2564
  readonly localId: number;
  // @ts-expect-error TS2564
  readonly questions: Question[];

  constructor(quizDetails: Readonly<IQuizDetails>) {
    if (!quizDetails) {
      throw new Error('Invalid argument');
    }

    Object.entries(quizDetails).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
  }

  getDiffFrom(newQuizValue: Readonly<IQuizDetails>): DiffDetails<IQuizDetails> | null {
    const diff: DiffDetails<IQuizDetails> = {
      id: this.id,
      localId: this.localId
    };

    const questionsDiff = Object.entries(getConstantOrderArraysPatch(this.questions, newQuizValue.questions)).reduce(
      (innerDiff, [key, value]) => (value.length ? { ...innerDiff, [key]: value } : innerDiff),
      {}
    );

    const didQuestionsDiff = Object.keys(questionsDiff).length;

    if (!didQuestionsDiff) {
      return null;
    }

    diff.questions = questionsDiff;

    return diff;
  }
}

export function cleanQuiz(dirtyQuiz: Readonly<IQuizDetails>): IQuizDetails {
  return {
    id: dirtyQuiz.id,
    localId: dirtyQuiz.localId,
    questions: dirtyQuiz.questions
  };
}
