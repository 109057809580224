import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BrandingService } from '@app/core/branding/branding.service';
import { IMenuConfig, IMenuLink, MenuLinkTypes } from '@app/screens/admin/types';
import { SlideInOutAnimation } from './animations';
import { UrlTypes } from '@app/shared/enums/url-types';
import { WidgetModalComponent } from '@app/shared/components/widget-modal/widget-modal.component';
import { getUrlType } from '@app/shared/utils/url';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-link-menu',
  templateUrl: './link-menu.component.html',
  styleUrls: ['./link-menu.component.scss'],
  animations: [SlideInOutAnimation],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-app-link-menu'
  }
})
export class LinkMenuComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  animationState = 'in';

  LINK_TYPE = MenuLinkTypes;
  URL_TYPE = UrlTypes;

  menuConfig: IMenuConfig = {
    mobileMenuBackgroundColor: '',
    navLinks: []
  };

  // @ts-expect-error TS2564
  @Input() darkText: boolean;

  @Input() countOfMenuItems?: number;

  constructor(
    private readonly _modal: NgbModal,
    private readonly _router: Router,
    private readonly _brandingService: BrandingService
  ) {}

  ngOnInit(): void {
    this._brandingService.menuConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      // @ts-expect-error TS2345
      const menuItems = this.parseLink(config.navLinks).map(item => {
        const isDropdown = !!(item.children && item.children.length);

        return {
          ...item,
          isDropdown,
          isOpen: false,
          children: isDropdown ? item.children : null
        };
      });
      if (this.countOfMenuItems) {
        // @ts-expect-error TS2322
        this.menuConfig.navLinks = menuItems.filter((item, index) => index < this.countOfMenuItems);
      } else {
        // @ts-expect-error TS2322
        this.menuConfig.navLinks = menuItems;
      }
      this.menuConfig.mobileMenuBackgroundColor = config.mobileMenuBackgroundColor;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private parseLink(links: IMenuLink[]) {
    return links.map(link => {
      if (link.children && link.children.length) {
        link.children = this.parseLink(link.children);
      }

      // @ts-expect-error TS2322
      link.urlType = getUrlType(link.url);

      if (link.urlType === UrlTypes.RELATIVE && link.url.charAt(0) !== '/') {
        link.url = '/' + link.url;
      }

      if (link.url.indexOf('?') !== -1 && link.urlType === UrlTypes.RELATIVE) {
        return {
          ...link,
          url: link.url.split('?')[0],
          queryParams: this._router.parseUrl(link.url).queryParams
        };
      }

      return link;
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  toggleDropDownMenu(menuItem: any) {
    menuItem.isOpen = !menuItem.isOpen;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  hideDropDownMenu(menuItem: any) {
    menuItem.isOpen = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  openWidgetModal(event: Event, url: string) {
    event.preventDefault();
    const modalRef = this._modal.open(WidgetModalComponent, {
      scrollable: false,
      centered: true,
      windowClass: 'widget-modal'
    });
    modalRef.componentInstance.link = url;
    return false;
  }
}
