import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogAuthorGuide, IBookEvent } from '../../types';

@Component({
  selector: 'app-blog-article-author',
  templateUrl: './blog-article-author.component.html',
  styleUrls: ['./blog-article-author.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleAuthorComponent {
  @Input()
  // @ts-expect-error TS2564
  user: BlogAuthorGuide;

  @Output()
  bookSession = new EventEmitter<IBookEvent>();

  @Output()
  openBiography = new EventEmitter<string>();

  emitBookSession(): void {
    this.bookSession.emit({ guide: this.user });
  }

  emitOpenBiography(): void {
    this.openBiography.emit(this.user.namedUrl);
  }
}
