import { FactoryProvider, Optional, SkipSelf } from '@angular/core';
import { GUIDE_OFFER_CONFIG } from '@app/modules/guide-offer/tokens';
import { IGuideOfferConfig } from '@app/modules/guide-offer/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function guideOfferConfigFactory(parent: IGuideOfferConfig) {
  return (
    parent || {
      messageMaxLength: 1000,
      messageMinRows: 4,
      messageMaxRow: 18
    }
  );
}

export const GUIDE_OFFER_CONFIG_PROVIDER: FactoryProvider = {
  provide: GUIDE_OFFER_CONFIG,
  deps: [[new Optional(), new SkipSelf(), GUIDE_OFFER_CONFIG]],
  useFactory: guideOfferConfigFactory
};
