import { Pipe, PipeTransform } from '@angular/core';
import { IPublicProgram } from '@app/modules/program/types';

@Pipe({
  name: 'publicProgramKeyPoints'
})
export class PublicProgramKeyPointsPipe implements PipeTransform {
  transform(keyPoints: IPublicProgram['keyPoints'] | undefined): string[] {
    if (!keyPoints || keyPoints.length === 0) {
      return [];
    }

    return keyPoints.split('\n').filter(item => item.trim().length > 0);
  }
}
