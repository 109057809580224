<div class="programs">
  <app-editor-section-wrapper
    [title]="'Hosts'"
    note="Hosts are automatically added to programs that contain modules where they are assigned."
    i18nTitle="@@programHosts"
    i18nNote="@@programHostsNote">
    <div configuration-content>
      <div class="team-list">
        <div class="team-list-element" *ngFor="let member of members$ | async; trackBy: trackByMember">
          <div class="guide-info">
            <div class="avatar">
              <pui-avatar [avatarUrl]="member.photo" [rounded]="true" [text]="member | userName" size="l"></pui-avatar>
            </div>
            <div class="details">
              <p class="name" [pui-tooltip]="member | userName">{{ member | userName }}</p>
              <p class="email" [pui-tooltip]="member.email">{{ member.email }}</p>
            </div>
          </div>
          <div class="guide-actions">
            <p pui-tooltip="{{ member | memberRoleTooltip }}" i18n="@@{{ member.role }}Name">
              {{ member.role }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-editor-section-wrapper>
</div>
