import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { PuiNavigationListMode } from '@awarenow/profi-ui-core';
import { SwitchToAlternativeAccountService } from '@app/modules/sidenav/components/sidenav-switch-to-alternative-account/switch-to-alternative-account.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISwitchToAlternativeAccountView {
  mode: PuiNavigationListMode;
  labels: [practitioner: string, client: string];
  labelIndex: 0 | 1;
  open: boolean;
}

@Component({
  selector: 'app-switch-to-alternative-account',
  templateUrl: './switch-to-alternative-account.component.html',
  styleUrls: ['./switch-to-alternative-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-switch-to-alternative-account'
  },
  providers: [UiDestroyService]
})
export class SwitchToAlternativeAccountComponent {
  view$: Observable<ISwitchToAlternativeAccountView>;

  constructor(private _service: SwitchToAlternativeAccountService, private _destroy$: UiDestroyService) {
    this.view$ = _service.getView$();
  }

  switchToAlternativeAccount(): void {
    this._service.switchToAlternativeAccount().pipe(takeUntil(this._destroy$)).subscribe();
  }

  onToggle(state: boolean): void {
    this._service.setOpen(!state);
  }
}
