import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

export const RETURN_AFTER_SAVE_PARAM = 'returnAfterSave';

@Component({
  selector: 'app-service-selector',
  templateUrl: './service-selector.component.html',
  styleUrls: ['./service-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceOptionComponent {
  @Input() returnAfterSave = false;
  @Input() description!: string;
  @Input() link!: string;
  @Input() imageSrc!: string;
  @Input() name!: string;
  @Input() qaId!: string;

  @Output() beforeNavigate = new EventEmitter();

  constructor(private readonly router: Router) {}

  navigate(): void {
    this.beforeNavigate.emit();

    const url = `${this.link}${this.returnAfterSave ? `?${RETURN_AFTER_SAVE_PARAM}=true` : ''}`;

    this.router.navigateByUrl(url);
  }
}
