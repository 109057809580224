import { inject, Inject, Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  DefaultScheduleDateTimeSlotsFactory,
  ISchedule,
  ISchedulePartitionUnit,
  IScheduleProvider,
  ITimeSlot,
  SCHEDULE_PROVIDER,
  ScheduleDatesPartitionStrategy,
  ScheduleDateTimeSlotsFactory,
  ScheduleTimeSlotsBuildStrategy,
  ScheduleTimeSlotsFactory
} from '@app/modules/schedule-boards';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { IServiceScheduleItemWithEnd } from '@app/shared/interfaces/services';
import { LocaleService } from '@app/core/locale/locale.service';
import { IPrivateGuideScheduleTimeSlotsFactory, IPrivateGuideScheduleSlotsRefreshOptions } from './types';

export const PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDER = new InjectionToken<
  IPrivateGuideScheduleTimeSlotsFactory<IPrivateGuideScheduleSlotsRefreshOptions, void, ITimeSlot>
>('PrivateGuideScheduleProvider');

@Injectable()
export class PrivateGuideScheduleSlotsProvider
  extends ScheduleTimeSlotsFactory<ISchedule, ITimeSlot>
  implements IPrivateGuideScheduleTimeSlotsFactory<IPrivateGuideScheduleSlotsRefreshOptions, void, ITimeSlot>
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _schedule: IServiceScheduleItemWithEnd[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _scheduleTimeSlotsFactory: ScheduleDateTimeSlotsFactory<
    ISchedule,
    string,
    number,
    string,
    ITimeSlot
  >;

  constructor(
    @Inject(SCHEDULE_PROVIDER) scheduleProvider: IScheduleProvider<ISchedule>,
    partitionStrategy: ScheduleDatesPartitionStrategy<ISchedule, ISchedulePartitionUnit>,
    timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<IScheduleItem[], ITimeSlot>
  ) {
    super(scheduleProvider, new ReplaySubject<ITimeSlot[]>(1));

    // @ts-expect-error TS2322
    this._scheduleTimeSlotsFactory = new DefaultScheduleDateTimeSlotsFactory(
      scheduleProvider,
      partitionStrategy,
      timeSlotsBuildStrategy,
      false,
      inject(LocaleService).getLocale()
    );

    this._scheduleTimeSlotsFactory.timeSlots$
      .pipe(takeUntil(this.destroy$))
      .subscribe(timeSlots => this._timeSlotsEmitter$.next(timeSlots));
  }

  refresh(options: IPrivateGuideScheduleSlotsRefreshOptions): void {
    if (options.timezone) {
      this._scheduleTimeSlotsFactory.changeTimezone(options.timezone, {
        refreshTimeSlots: Object.keys(options).length === 1
      });
    }

    if (options.date) {
      this._scheduleTimeSlotsFactory.changeDate(options.date, { refreshTimeSlots: false });
    }

    if (options.duration) {
      this._scheduleTimeSlotsFactory.changeDuration(options.duration, { refreshTimeSlots: false });
    }

    if (options.currentDate) {
      this._scheduleTimeSlotsFactory.changeExcludeDateTime(options.currentDate.date, { refreshTimeSlots: false });
    }

    if (Object.keys(options).length > 1) {
      // @ts-expect-error TS2722
      this._scheduleProvider.refresh(options);
    }
  }
}

export const PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDERS = [
  {
    provide: PRIVATE_GUIDE_SCHEDULE_SLOTS_PROVIDER,
    deps: [SCHEDULE_PROVIDER, ScheduleDatesPartitionStrategy, ScheduleTimeSlotsBuildStrategy],
    useClass: PrivateGuideScheduleSlotsProvider
  }
];
