<div [@hiding]="isSessionFree && !isAuth ? 'show' : 'hide'" class="row no-gutters free-session-row">
  <div class="col free-session-col">
    <dl>
      <dt transloco="firstFreeSession" *ngIf="!freeAllSessions">First free session</dt>
      <dt transloco="freeSessions" *ngIf="freeAllSessions">Free sessions</dt>
      <dd transloco="firstFreeSessionNote" *ngIf="!freeAllSessions">This coach provides free discovery session</dd>
      <dd transloco="freeSessionsNote" *ngIf="freeAllSessions">This coach provides free sessions</dd>
    </dl>
  </div>
</div>

<div class="row no-gutters price-block" *ngIf="!disablePrice && !freeAllSessions">
  <div *ngIf="priceStartsFrom && config.showPriceStartsFrom" class="col price-from">
    <span class="price-from__label" i18n="@@from">From</span>
    <span class="price-from__value">{{ priceStartsFrom | platformCurrency }}</span>
  </div>
  <div
    class="col money-back"
    [ngbTooltip]="moneyBackTooltip"
    tooltipClass="money-back-tooltip"
    placement="bottom"
    *ngIf="config.displayMoneyBackGuarantee">
    <div class="icon"></div>
    <div class="money-back-text">
      <div class="percents" i18n="@@moneyBackGuaranteePercent">100%</div>
      <div class="label" i18n="@@moneyBackGuarantee">Money back guarantee</div>
    </div>
  </div>
</div>

<div class="price-book-divider"></div>

<ng-template #moneyBackTooltip>
  <ng-container i18n="@@moneyBackTooltip">
    If you are not satisfied with our services and are still within 10 days of your original session purchase date, your
    request for a refund will be honored. You can request it by contacting our support
  </ng-container>
  <a href="mailto:{{ config.emailHelp }}" class="help-email">{{ config.emailHelp }}</a>
  .
</ng-template>
