import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoHeightScrollbarDirective } from './auto-height-scrollbar.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AutoHeightScrollbarDirective],
  exports: [AutoHeightScrollbarDirective]
})
export class ScrollbarModule {}
