import { Injectable } from '@angular/core';
import { PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { SessionWidgetComponent } from '@app/modules/session-widget/session-widget.component';
import { SessionsService } from '@app/core/session/sessions.service';
import type { VideoSession } from '@app/screens/session/types';

@Injectable()
export class SessionWidgetService {
  drawerRef?: PuiDrawerRef;

  constructor(private _sessionsService: SessionsService, private drawer: PuiDrawerService) {}

  open(videoSession: VideoSession) {
    this.drawerRef = this.drawer.open(
      SessionWidgetComponent,
      {
        position: 'right',
        maxWidth: '600px'
      },
      {
        // TODO Need refactor
        session: videoSession
      }
    );

    this.drawerRef?.afterClosed$.subscribe(() => {
      this.drawerRef?.close();
      this.drawerRef = undefined;
    });
  }
}
