import produce from 'immer';
import { createReducer, on } from '@ngrx/store';
import {
  deleteFieldSuccess,
  fetchFields,
  fetchFieldsSuccess,
  patchFieldSuccess,
  putFieldSuccess,
  resetState
} from './custom-fields.actions';
import { CustomFieldsState } from './commons/type';
import { CustomFieldValue } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';

const initialState: CustomFieldsState = {
  status: 'initial',
  data: {}
};

export const customFieldsReducer = createReducer(
  initialState,
  on(resetState, () => initialState),
  on(fetchFields, state =>
    produce(state, draft => {
      draft.status = 'loading';
    })
  ),
  on(fetchFieldsSuccess, (state, { payload: fields }) =>
    produce(state, draft => {
      draft.status = 'idle';
      draft.data = fields.reduce((acc, field) => {
        return {
          ...acc,
          [field.fieldName]: field
        };
      }, {});
    })
  ),
  on(putFieldSuccess, (state, { payload }) =>
    produce(state, draft => {
      for (const { fieldName, id, value } of payload) {
        draft.data[fieldName]?.values.push({ ...value, id } as CustomFieldValue);
      }
    })
  ),
  on(patchFieldSuccess, (state, { payload }) =>
    produce(state, draft => {
      for (const { fieldName, id, value } of payload) {
        const values = draft.data[fieldName]?.values;
        const index = values.findIndex(value => value.id === id);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        values[index] = { ...value, id };
      }
    })
  ),
  on(deleteFieldSuccess, (state, { payload }) =>
    produce(state, draft => {
      draft.status = 'idle';
      for (const { fieldName, id } of payload) {
        if (!draft.data[fieldName]) {
          return;
        }

        draft.data[fieldName].values = draft.data[fieldName].values.filter(value => value.id !== id);
      }
    })
  )
);
