import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, mergeMap, skip, switchMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import {
  ClientsApiService,
  ContactViewModel
} from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';
import {
  addContacts,
  addContactSuccess,
  archiveContact,
  archiveContactSuccess,
  archiveSelectedContacts,
  bulkUpdate,
  bulkUpdateSuccess,
  editContact,
  fetchContact,
  fetchContactSuccess,
  resetState,
  tagClient,
  tagClientSuccess,
  unArchiveContact,
  unArchiveContactSuccess,
  unarchiveSelectedContacts,
  updateTagsToClient,
  updateTagsToClientSuccess
} from '@app/screens/guide/guide-clients/guide-relations-table/store/relations/contacts.actions';
import { GuideContact } from '@app/core/users/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';

@Injectable()
export class ContactsEffects {
  readonly fetchContacts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchContact.type),
      mergeMap(() =>
        this._clientsApiService.getClients().pipe(
          map(relations => ({ type: fetchContactSuccess.type, clients: relations })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly addContact$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addContacts),
      map(({ clients }) => ({
        type: addContactSuccess.type, // it will update all contacts.
        clients
      }))
    )
  );

  readonly addContactSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addContactSuccess),
      map(() => ({
        type: fetchContact.type // it will update all contacts.
      }))
    )
  );

  readonly editContact$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editContact),
      map(() => ({
        type: fetchContact.type // it will update all contacts.
      }))
    )
  );

  readonly archiveClient$ = createEffect(() =>
    this._actions$.pipe(
      ofType(archiveContact),
      mergeMap(({ client }) =>
        this._clientsApiService.archive(client.id).pipe(
          tap(() => {
            this._notificationsService.success(
              ` The client record “${client.firstName} ${client.lastName}” has been moved to Archive`
            );
          }),
          map(() => ({
            type: archiveContactSuccess.type,
            client
          }))
        )
      )
    )
  );

  readonly archiveClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(archiveSelectedContacts),
      filter(({ contacts }) => !!contacts),
      mergeMap(({ contacts }) =>
        this._clientsApiService.archiveContacts(contacts).pipe(
          tap(() => {
            if (contacts.length === 1) {
              this._notificationsService.success(
                `The client record “${contacts[0].firstName} ${contacts[0].lastName}” has been moved to Archive`
              );
            } else {
              this._notificationsService.success(` ${contacts.length} client records have been moved to Archive`);
            }
          }),
          map(() => ({
            type: archiveSelectedContacts.type
          }))
        )
      )
    )
  );

  readonly unarchiveClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(unarchiveSelectedContacts),
      filter(({ contacts }) => !!contacts),
      mergeMap(({ contacts }) =>
        this._clientsApiService.unarchiveContacts(contacts).pipe(
          tap(() => {
            if (contacts.length === 1) {
              this._notificationsService.success(
                `The client record “${contacts[0].firstName} ${contacts[0].lastName}” has been moved to Active`
              );
            } else {
              this._notificationsService.success(`${contacts.length} client records have been moved to Active`);
            }
          }),
          map(() => ({
            type: archiveSelectedContacts.type
          }))
        )
      )
    )
  );

  readonly unArchiveClient$ = createEffect(() =>
    this._actions$.pipe(
      ofType(unArchiveContact),
      mergeMap(({ client }) =>
        this._clientsApiService.unarchive(client.id).pipe(
          tap(() => {
            this._notificationsService.success(
              `The client record “${client.firstName} ${client.lastName}” has been moved to Active`
            );
          }),
          map(() => ({
            type: unArchiveContactSuccess.type,
            client
          }))
        )
      )
    )
  );

  readonly tagClient$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tagClient),
      mergeMap(({ client, tag }) =>
        // @ts-expect-error TS2345
        this._clientsApiService.stage(client.id, tag).pipe(
          map(() => ({
            type: tagClientSuccess.type,
            client: new ContactViewModel({
              ...client.data,
              tag
            } as GuideContact)
          }))
        )
      )
    )
  );

  readonly updateTagsToClient$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateTagsToClient),
      mergeMap(({ client, tags }) =>
        this._clientsApiService
          .setClientTags(
            client.id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            tags.map(({ id }) => id)
          )
          .pipe(
            map(() => ({
              type: updateTagsToClientSuccess.type
            }))
          )
      )
    )
  );

  /**
   * Reset state after current workspace is switched.
   */
  readonly workspaceChange$ = createEffect(() =>
    this._workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: resetState.type
      }))
    )
  );

  readonly relationChanges$ = createEffect(() =>
    this._guideClients.users$.pipe(
      debounceTime(500),
      map(() => ({
        type: fetchContact.type
      }))
    )
  );

  readonly bulkUpdate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(bulkUpdate),
      debounceTime(500),
      switchMap(({ update }) =>
        this._clientsApiService.bulkUpdate(update).pipe(map(() => ({ type: bulkUpdateSuccess.type })))
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _clientsApiService: ClientsApiService,
    private readonly _notificationsService: NotificationsService,
    private readonly _workspacesService: WorkspacesService,
    private readonly _guideClients: GuideClientsService
  ) {}
}
