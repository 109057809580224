import { IWorkspace } from '@app/modules/workspaces/types';
import { ServiceGuide } from '@app/shared/interfaces/services';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  isOnline?: boolean;
  lastTimezone?: string;
  namedUrl?: string;
  specialization?: string;
  workspaceId?: number;
  RoleId?: number;
  authToken?: string;
  readonly name: string;
  email?: string;
  connectionType?: string;
  invitedBy?: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerUser {
  id: number;
  firstName: string;
  lastName: string;
  online: boolean;
  lastTimezone?: string;
  photo: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IProfileInfo {
  profile: unknown;
  workspace: IWorkspace;
  workspaceGuides?: ServiceGuide[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuide {
  payRate?: number;
  publicUrl?: string;
  timezone: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IOnlineStatuses {
  [key: number]: boolean;
}

export class User implements IUser {
  id: number;

  firstName: string;

  lastName: string;

  // @ts-expect-error TS2564
  photo: string;

  // @ts-expect-error TS2416
  isOnline?: boolean | null;

  lastTimezone?: string;

  workspaceId?: number;

  workspaceType?: WorkspacesTypes;

  get name(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    photo?: string,
    isOnline?: boolean,
    lastTimezone?: string,
    workspaceId?: number,
    workspaceType?: WorkspacesTypes
  ) {
    this.id = id;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.lastTimezone = lastTimezone;
    this.isOnline = isOnline;
    this.workspaceId = workspaceId;
    this.workspaceType = workspaceType;

    if (photo) {
      this.photo = photo;
    }
  }
}
