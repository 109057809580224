import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from '@app/core/ssr-cookie/ssr-cookie.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class CookieCollectionService {
  private readonly COOKIE_NAME = '_cookie-collection-allowed';

  constructor(
    private cookieService: CookieService,
    private ssrCookie: SsrCookieService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  isCookieCollectionAllowed(): boolean {
    if (isPlatformServer(this.platformId)) {
      return this.ssrCookie.get(this.COOKIE_NAME) === 'true';
    } else {
      return this.cookieService.get(this.COOKIE_NAME) === 'true';
    }
  }

  setCookieCollectionAllowed(): void {
    if (isPlatformBrowser(this.platformId)) {
      // https://github.com/stevermeister/ngx-cookie-service/issues/86
      // @ts-expect-error TS2345
      this.cookieService.set(this.COOKIE_NAME, 'true', 9999, '/', null, null, 'Lax');
    }
  }
}
