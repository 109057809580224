export type OpenedModuleStatus = 'allowed' | 'seen';

export type ModuleStatus = OpenedModuleStatus | 'restricted';

export enum ModuleStatuses {
  ALLOWED = 'allowed',
  SEEN = 'seen',
  RESTRICTED = 'restricted',
  HARD_RESTRICTED = 'hard_restricted'
}

export enum OpenedModuleStatuses {
  ALLOWED = 'allowed',
  SEEN = 'seen'
}
