import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ui-option',
  template: `
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['option.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-option'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiOption {
  @Input() value: unknown;

  @Input() disabled: boolean | undefined;

  @Input() id: string | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('content', { static: true, read: TemplateRef }) content!: TemplateRef<any>;

  get classList(): string {
    return this._elm?.nativeElement?.classList?.toString() ?? '';
  }

  constructor(private _elm: ElementRef) {}
}
