import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { SidenavModeService } from '@app/modules/sidenav/services/mode.service';

@Component({
  selector: 'app-admin-logout',
  template: `
    <button pui-button size="x" type="button" appearance="flat" (click)="signOut()" iconPosition="right">
      <pui-icon svgIcon="pui:log-out" size="xs"></pui-icon>
      <span *ngIf="(mode$ | async) === 'full'" i18n="@@signOut">Sign out</span>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavAdminLogoutComponent {
  constructor(private _auth: AuthService, public mode$: SidenavModeService) {}

  signOut(): void {
    this._auth.signout();
  }
}
