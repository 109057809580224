// eslint-disable-next-line max-classes-per-file
import { QuestionAnswer, ChoiceQuestionAnswer } from './question-answer';
import { QuizQuestionType } from '@app/core/quizzes/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuestionOptionDetails {
  readonly id: number;
  readonly text: string;
  readonly isCorrect?: boolean;
  readonly explanation?: string;
  readonly other?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBaseQuestionDetails {
  readonly id: number;
  readonly question: string | null;
  readonly createdAt: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuestionDetails extends IBaseQuestionDetails {
  readonly answer?: QuestionAnswer;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChoiceQuestionDetails extends IBaseQuestionDetails {
  readonly options: QuestionOption[];
  readonly answer?: ChoiceQuestionAnswer;
}

export class QuestionOption {
  readonly id: number;
  readonly text: string;
  readonly isCorrect?: boolean;
  readonly explanation?: string;
  readonly other?: boolean;

  constructor(questionOptionDetails: IQuestionOptionDetails) {
    this.id = questionOptionDetails.id;
    this.text = questionOptionDetails.text;

    if (questionOptionDetails.isCorrect != null) {
      this.isCorrect = questionOptionDetails.isCorrect;
    }

    if (questionOptionDetails.explanation) {
      this.explanation = questionOptionDetails.explanation;
    }

    if (typeof questionOptionDetails.other !== 'undefined') {
      this.other = questionOptionDetails.other;
    }
  }
}

export abstract class BaseQuestion {
  readonly id: number;
  readonly question: string | null;
  readonly createdAt: string;

  protected constructor(questionDetails: IBaseQuestionDetails) {
    this.id = questionDetails.id;
    this.question = questionDetails.question;
    this.createdAt = questionDetails.createdAt;
  }
}

export class LongAnswerQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.LONG_ANSWER;

  readonly answer?: QuestionAnswer;

  constructor(questionDetails: IQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export class MultipleChoiceQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.MULTIPLE_CHOICE;

  readonly options: QuestionOption[];
  readonly answer?: ChoiceQuestionAnswer;

  constructor(questionDetails: IChoiceQuestionDetails) {
    super(questionDetails);

    this.options = questionDetails.options;

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export class SingleChoiceQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.SINGLE_CHOICE;

  readonly options: QuestionOption[];
  readonly answer?: ChoiceQuestionAnswer;

  constructor(questionDetails: IChoiceQuestionDetails) {
    super(questionDetails);

    this.options = questionDetails.options;

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export class QuizChoiceQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.QUIZ;

  readonly options: QuestionOption[];
  readonly answer?: ChoiceQuestionAnswer;

  constructor(questionDetails: IChoiceQuestionDetails) {
    super(questionDetails);

    this.options = questionDetails.options;

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export class FileUploadQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.FILE_UPLOAD;

  readonly answer?: QuestionAnswer;

  constructor(questionDetails: IQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export class TextQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.TEXT;

  readonly answer = null;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(questionDetails: IQuestionDetails) {
    super(questionDetails);
  }
}

export class SignatureQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.SIGNATURE;

  readonly answer?: QuestionAnswer;

  constructor(questionDetails: IQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export class ShortAnswerQuestion extends BaseQuestion {
  readonly type = QuizQuestionType.SHORT_ANSWER;

  readonly answer?: QuestionAnswer;

  constructor(questionDetails: IQuestionDetails) {
    super(questionDetails);

    if (questionDetails.answer) {
      this.answer = questionDetails.answer;
    }
  }
}

export type QuizQuestion =
  | LongAnswerQuestion
  | MultipleChoiceQuestion
  | FileUploadQuestion
  | TextQuestion
  | SignatureQuestion
  | SingleChoiceQuestion
  | ShortAnswerQuestion
  | QuizChoiceQuestion;

export function isLongAnswerQuestion(quizQuestion: QuizQuestion): quizQuestion is LongAnswerQuestion {
  return quizQuestion.type === QuizQuestionType.LONG_ANSWER;
}

export function isMultipleChoiceQuestion(quizQuestion: QuizQuestion): quizQuestion is MultipleChoiceQuestion {
  return quizQuestion.type === QuizQuestionType.MULTIPLE_CHOICE;
}

export function isFileUploadQuestion(quizQuestion: QuizQuestion): quizQuestion is FileUploadQuestion {
  return quizQuestion.type === QuizQuestionType.FILE_UPLOAD;
}

export function isTextQuestion(quizQuestion: QuizQuestion): quizQuestion is TextQuestion {
  return quizQuestion.type === QuizQuestionType.TEXT;
}

export function isSignatureQuestion(quizQuestion: QuizQuestion): quizQuestion is SignatureQuestion {
  return quizQuestion.type === QuizQuestionType.SIGNATURE;
}

export function isSingleChoiceQuestion(quizQuestion: QuizQuestion): quizQuestion is SingleChoiceQuestion {
  return quizQuestion.type === QuizQuestionType.SINGLE_CHOICE;
}

export function isShortAnswerQuestion(quizQuestion: QuizQuestion): quizQuestion is ShortAnswerQuestion {
  return quizQuestion.type === QuizQuestionType.SHORT_ANSWER;
}

export function isQuizChoiceQuestion(quizQuestion: QuizQuestion): quizQuestion is QuizChoiceQuestion {
  return quizQuestion.type === QuizQuestionType.QUIZ;
}
