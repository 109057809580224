import { ChangeDetectionStrategy, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { NavigationWidgetDirective } from '@app/modules/sidenav/abstracts/navigation-widget';

@Component({
  selector: 'app-sidenav-navigation-secondary-widget',
  providers: [UiDestroyService],
  template: `<ng-container #element></ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavNavigationSecondaryWidgetComponent extends NavigationWidgetDirective {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @ViewChild('element', { read: ViewContainerRef }) _viewContainerRef: ViewContainerRef;
}
