import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-landing-tab',
  templateUrl: './service-landing-tab.component.html',
  styleUrls: ['./service-landing-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceLandingTabComponent {
  @Input()
  // @ts-expect-error TS2564
  title: string;

  @Input()
  // @ts-expect-error TS2564
  value: string;

  @Input()
  // @ts-expect-error TS2564
  icon: string;
}
