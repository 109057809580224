<div class="session-invitation">
  <div class="session-info-container">
    <div class="session-client-avatar">
      <pui-avatar
        class="user-photo"
        [text]="invitation?.guide.name"
        [avatarUrl]="invitation?.guide.photo"
        size="l"></pui-avatar>
    </div>

    <div class="session-details-container">
      <div
        [attr.data-qa-id]="'client-session-invitation-session-name-label-' + invitation.session?.name"
        class="session-name"
        pui-typography="body-s-bold">
        <ng-container *ngIf="invitation?.session?.serviceParent?.id">
          {{ invitation?.session?.serviceParent.name }} {{ invitation?.session?.serviceParent.type }}.
        </ng-container>
        {{ invitation.session?.name }}
      </div>
      <div class="session-details">
        <span
          [attr.data-qa-id]="'client-session-invitation-session-range-date-label-' + invitation.session?.name"
          class="session-details-item">
          <pui-icon svgIcon="pui:calendar" size="xs"></pui-icon>
          {{ invitation.session | sessionRangeFormatter }}
        </span>
        <span class="session-details-item">
          <pui-icon svgIcon="pui:user" size="xs"></pui-icon>
          <a
            [attr.data-qa-id]="'client-session-invitation-guide-user-name-link-' + invitation?.guide?.id"
            [routerLink]="['/', invitation.guide.namedUrl]"
            class="user-link">
            {{ invitation?.guide | userName }}
          </a>
          <a
            [attr.data-qa-id]="'client-session-invitation-guide-chat-link-' + invitation?.guide?.id"
            [routerLink]="['../chat', buildDirectChatId(invitation?.guide)]"
            class="user-chat-link"
            [ngbTooltip]="chatTooltip">
            <pui-icon class="user-chat-icon" svgIcon="pui:message" size="xs"></pui-icon>
          </a>
        </span>
        <span *ngIf="invitation.session && !invitation.session.hidePrice" class="session-details-item">
          <pui-icon svgIcon="pui:tag" size="xs"></pui-icon>
          <app-session-payment-formatter
            [session]="invitation?.session"
            [priceOnly]="true"></app-session-payment-formatter>
        </span>
        <span class="session-details-item">
          <pui-icon
            [svgIcon]="invitation?.session?.connectionType | sessionConnectionTypeIcon"
            size="xs"
            aria-hidden="true"></pui-icon>
          {{ invitation?.session?.connectionType | sessionPlatform: invitation?.session?.address }}
        </span>
      </div>
    </div>
  </div>

  <div class="session-invitation-actions">
    <button
      [attr.data-qa-id]="'client-session-invitation-accept-button-' + invitation.session?.name"
      class="action-btn"
      pui-button
      appearance="secondary"
      size="s"
      (click)="accept()">
      <ng-container i18n="@@accept">Accept</ng-container>
    </button>
    <button
      [attr.data-qa-id]="'client-session-invitation-reschedule-button-' + invitation.session?.name"
      class="action-btn"
      pui-button
      appearance="flat"
      [ghost]="true"
      size="s"
      (click)="reschedule()"
      *ngIf="invitation.session.serviceType === SessionTypes.SESSION">
      <pui-icon svgIcon="pui:rotate-right" size="s"></pui-icon>
      <ng-container i18n="@@reschedule">Reschedule</ng-container>
    </button>
    <button
      [attr.data-qa-id]="'client-session-invitation-decline-button-' + invitation.session?.name"
      class="action-btn"
      pui-button
      appearance="flat"
      [ghost]="true"
      size="s"
      (click)="decline()">
      <pui-icon svgIcon="pui:x-circle"></pui-icon>
      <ng-container i18n="@@decline">Decline</ng-container>
    </button>
  </div>
</div>

<ng-template #chatTooltip>
  <div i18n="@@sendMsg">Send message</div>
</ng-template>
