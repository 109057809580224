<header class="header">
  <h1 class="title" i18n="@@forms">Forms</h1>
  <app-button className="btn-add-primary" routerLink="/{{ guideRoute }}/surveys/new">
    <ng-container i18n="@@addForm">Add form</ng-container>
  </app-button>
</header>

<section class="events-section">
  <ng-container *ngIf="items.length > 0; else noForms">
    <div class="events row">
      <div class="event-item-wrapper col-xl-4 col-lg-6 col-12" *ngFor="let item of items">
        <app-quiz-card
          class="quiz event-item"
          [quiz]="item.payload"
          (removeQuiz)="removeQuiz($event)"
          (duplicate)="duplicateQuiz($event)"
          (sendToClient)="sendToClient($event)"></app-quiz-card>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #noForms>
  <div class="no-forms-container">
    <div class="no-forms-image-container">
      <img src="/assets/images/empty-data.svg" alt="You don't have any forms yet" />
    </div>
    <h1 class="no-forms-heading" pui-typography="h2" i18n="@@noForms">You don’t have any forms yet</h1>
    <p class="no-forms-copy" pui-typography="body-m" i18n="@@formsWillAppear">Your forms will appear here.</p>
    <button pui-button appearance="primary" size="s" routerLink="/{{ guideRoute }}/surveys/new">
      <ng-container i18n="@@addForm">Add form</ng-container>
    </button>
  </div>
</ng-template>
