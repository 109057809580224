<div class="modal-header">
  <div class="top-row">
    <app-button className="close" (click)="modal.dismiss()"></app-button>
  </div>
</div>
<div class="content-wrapper">
  <h4 class="modal-title">
    <span i18n="@@deleteCourseModalTitle">Delete</span>
    {{ getServiceTitle(serviceType) }}?
  </h4>
  <div class="modal-body">
    <div class="participants-count" *ngIf="+numberOfParticipants; else warnMessage">
      <ng-container
        *ngIf="serviceType !== GuideServiceTypes.SESSION && serviceType !== GuideServiceTypes.GROUP_SESSION">
        <span [ngPlural]="numberOfParticipants">
          You have {{ numberOfParticipants }}
          <ng-template i18n="@@activeParticipantInThis:one" ngPluralCase="=1">active participant in this</ng-template>
          <ng-template i18n="@@activeParticipantInThis:many" ngPluralCase="other">
            active participants in this
          </ng-template>
        </span>
        <span>{{ getServiceTitle(serviceType) }}.</span>
      </ng-container>
      <div class="note" i18n="@@participantsInTheCourseMsg" *ngIf="serviceType === GuideServiceTypes.PROGRAM">
        All content will be deleted and they will lose access to their program.
      </div>
      <div class="note" i18n="@@participantsInThePackageWarn" *ngIf="serviceType === GuideServiceTypes.PACKAGE">
        They will lose access to their sessions.
      </div>
      <div
        class="note"
        i18n="@@participantsInTheSessionNote"
        *ngIf="serviceType === GuideServiceTypes.SESSION || serviceType === GuideServiceTypes.GROUP_SESSION">
        Are you sure? This is an active session. If you delete it, existing clients will be unenrolled.
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-button class="modal-btn modal-footer__btn" className="btn-lite" (click)="modal.dismiss()">
      <ng-container i18n="@@cancel">Cancel</ng-container>
    </app-button>
    <app-button class="modal-btn" className="btn-outline-danger" (click)="modal.close()" i18n="@@deleteService">
      {{ +numberOfParticipants ? 'Delete anyway' : 'Delete' }}
    </app-button>
  </div>
</div>

<ng-template #warnMessage>
  <div class="note" i18n="@@emptyCourseNote">This can’t be undone.</div>
</ng-template>
