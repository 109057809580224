import { StarRatingModule } from 'angular-star-rating';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GuideProgramsModule } from '@app/screens/guide/guide-programs/guide-programs.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { FindYourGuideHomeComponent } from './components/find-your-guide-home/find-your-guide-home.component';
import { GiftCertificateComponent } from './components/gift-certificate/gift-certificate.component';
import { GuideCardComponent } from './components/guide-card/guide-card.component';
import { WikiCardComponent } from './components/wiki-card/wiki-card.component';
import { FindYourGuideRoutingModule } from './find-your-guide-routing.module';
import { GuideLanguagesStringPipe } from './pipes/guide-languages-string.pipe';
import { GuideTagsComponent } from '@app/screens/find-your-guide/components/find-your-guide-home/components/guide-tags/guide-tags.component';
import {
  PuiButtonModule,
  PuiIconModule,
  PuiSelectModule,
  PuiToggleModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    StarRatingModule,
    FindYourGuideRoutingModule,
    GuideProgramsModule,
    ReactiveFormsModule,
    PlatformStyleModule,
    PuiButtonModule,
    PuiIconModule,
    PuiTypographyModule,
    PuiSelectModule,
    PuiToggleModule,
    UiSwitchModule,
    TranslocoRootModule
  ],
  exports: [GuideCardComponent],
  declarations: [
    FindYourGuideHomeComponent,
    GuideCardComponent,
    GiftCertificateComponent,
    WikiCardComponent,
    GuideTagsComponent,
    GuideLanguagesStringPipe
  ]
})
export class FindYourGuideModule {}
