import { PlatformStyleSchema } from './model/interfaces';
import { enTimeStyle } from './utils/resolvers/en';

export const PLATFORM_STYLE_SCHEMA: PlatformStyleSchema = {
  ['en-US']: {
    datetime: {
      fragments: {
        time: enTimeStyle
      }
    }
  },
  ['ar']: {
    datetime: {
      templates: {
        DATE: ['year', ' ', 'day', ' ', 'week day', ' ', ',', 'month'],
        'DATE, TIME': ['time', ' ', 'year', ' ', 'day', ' ', 'week day', ' ', ',', 'month']
      }
    }
  },
  ['ru']: {
    datetime: {
      templates: {
        DATE: ['week day', ',', ' ', 'day', ' ', 'month', ',', ' ', 'year'],
        'DATE, TIME': ['week day', ',', ' ', 'day', ' ', 'month', ',', ' ', 'year', ',', ' ', 'time']
      }
    }
  }
};
