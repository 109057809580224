import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-start-program',
  templateUrl: './start-program.component.html',
  styleUrls: ['./start-program.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'start-program'
  }
})
export class StartProgramComponent {
  @Output()
  startProgram = new EventEmitter();
}
