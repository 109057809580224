import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { AuthService } from '@app/core/auth/services';
import config from '@app/core/config/config';
import { TextEditorImageComponent } from '@app/modules/text-editor/components';
import { CoverImageService } from '@app/modules/ui-kit/cover-image/services/cover-image.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { VariantSettings } from '@app/screens/guide/guide-programs/components/program-cover-crop-modal/variant-settings';
import { SessionTemplateEditorService } from '@app/screens/guide/guide-sessions-templates/services';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';
import { cleanQuillDeltaContent } from '@app/shared/utils/quill-utils';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

import { BasicInfoFormAppearanceName } from './basic-info-form-appearance.directive';
import { BasicInfoForm, BasicInfoFormFields } from './types';
import { integerValidator } from '@app/shared/form-validators/integer.validator';

const SESSION_TEMPLATE_LOCATION_FIELD_NAME = 'location';

@Component({
  selector: 'app-basic-info-form',
  templateUrl: './basic-info-form.component.html',
  styleUrls: ['./basic-info-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BasicInfoFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BasicInfoFormComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicInfoFormComponent implements ControlValueAccessor, Validator {
  @Input()
  appearance: BasicInfoFormAppearanceName | undefined;

  basicInfoFormFields = BasicInfoFormFields;

  coverVariantsSettings: VariantSettings[] = [
    new VariantSettings({ aspectRatio: 1200 / 440, resizeToWidth: 1200 }),
    new VariantSettings({ aspectRatio: 29 / 18, resizeToWidth: 375 })
  ];

  @ViewChild('descriptionEditor', { static: false })
  descriptionEditor!: TextEditorImageComponent;

  coverImageDisabled = false;

  guideProfileTypes = GuideProfileTypes;

  durationMask = [/\d/, /\d/, /\d/];
  totalCountMask = [/\d/, /\d/];
  minTotalCount = 1;
  maxTotalCount = 99;

  get sessionsTemplateEndpoint(): string {
    return `${config.apiPath}/user/${this.authService.getUserRole()}/services/templates/sessions`;
  }

  form: FormGroup = this.formBuilder.group({
    name: ['', [customValidatorWrapper(Validators.required, `Session template title required.`)]],
    count: [1],
    descriptionRepresentation: [''],
    coverImage: [null],
    coverImageThumb: [null],
    length: [
      60,
      [
        customValidatorWrapper(Validators.required, `Session duration required. Min length: 1 min.`),
        customValidatorWrapper(Validators.min(1), `Session duration required. Min length: 1 min.`),
        integerValidator(`Session duration required. Min length: 1 min.`)
      ]
    ],
    [SESSION_TEMPLATE_LOCATION_FIELD_NAME]: [false],
    recurring: [null],
    status: [true],
    restrictClientBooking: [false]
  });

  constructor(
    private readonly authService: AuthService,
    private readonly coverImageService: CoverImageService,
    private readonly workspacesService: WorkspacesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly editor: SessionTemplateEditorService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  onTouched: () => void = () => {};

  writeValue(value: BasicInfoForm): void {
    if (value) {
      this.form.patchValue({
        ...value,
        descriptionRepresentation: value.descriptionRepresentation ? JSON.parse(value.descriptionRepresentation) : '',
        status: value.status === GuideProfileTypes.PUBLIC
      });
    }
  }

  registerOnChange(fn: (value: BasicInfoForm) => void): void {
    this.form.valueChanges
      .pipe(
        map(value => {
          // true|false => 'private'|'public'
          const status: GuideProfileTypes =
            value.status === false ? GuideProfileTypes.PRIVATE : GuideProfileTypes.PUBLIC;

          // if status is 'public' restrictClientBooking should be false
          const restrictClientBooking = status === GuideProfileTypes.PUBLIC ? false : value.restrictClientBooking;

          return {
            ...value,
            restrictClientBooking,
            status,
            count: Number(value.count),
            length: Number(value.length)
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((value: BasicInfoForm) => {
        let descriptionFields: Partial<
          Pick<BasicInfoForm, 'descriptionRepresentation' | 'descriptionMarkup' | 'descriptionText'>
        > = {};

        if (this.descriptionEditor) {
          const descriptionRepresentation = value.descriptionRepresentation as { ops: unknown[] } | null;
          descriptionFields = {
            descriptionRepresentation: descriptionRepresentation?.ops
              ? JSON.stringify(cleanQuillDeltaContent(descriptionRepresentation))
              : null,
            descriptionMarkup: this.descriptionEditor.getContentMarkup() || '',
            descriptionText: this.descriptionEditor.getContentText() || ''
          };
        }

        fn({
          ...value,
          ...descriptionFields
        });
      });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      Object.entries(this.form.controls).forEach(([controlName, control]) => {
        if (controlName === SESSION_TEMPLATE_LOCATION_FIELD_NAME) {
          return;
        }
        control.disable();
      });
      this.form.disable();
      this.coverImageDisabled = true;
    } else {
      Object.values(this.form.controls).forEach(control => {
        control.enable();
      });
      this.coverImageDisabled = false;
    }
  }

  validate(): ValidationErrors | null {
    return {
      ...this.form.get('name')?.errors,
      ...this.form.get('length')?.errors,
      ...this.form.get('recurring')?.errors
    };
  }

  uploadImage(images: { coverImage: File; coverImageThumb: File; croppedImageString: string }): void {
    this.coverImageService
      .uploadCoverImage(images.coverImage, images.coverImageThumb, 'session', this.sessionsTemplateEndpoint)
      .pipe(takeUntil(this.destroy$))
      .subscribe(urls => {
        this.form.patchValue({
          coverImage: urls.coverImage,
          coverImageThumb: urls.coverImageThumb
        });
        this.cdr.detectChanges();
      });
  }

  removeImage(): void {
    // TODO: PR-2565
    const sessionTemplateId = this.editor.originalTemplate?.id;
    if (sessionTemplateId) {
      this.coverImageService
        .removeCoverImage(this.sessionsTemplateEndpoint, sessionTemplateId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.form.patchValue({
            coverImage: null,
            coverImageThumb: null
          });
        });
    } else {
      this.form.patchValue({
        coverImage: null,
        coverImageThumb: null
      });
    }
  }
}
