import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { AuthService } from '@app/core/auth/services';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class WorkspaceAdminGuard implements CanActivate, CanLoad {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly workspacesService: WorkspacesService,
    private readonly notifications: NotificationsService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    // @ts-expect-error TS2322
    return combineLatest([this.workspacesService.isSolo$, this.workspacesService.isTeamAdmin$]).pipe(
      map(([isSolo, isTeamAdmin]) => {
        if (isSolo || isTeamAdmin) {
          return true;
        }

        if (this.auth.isAuthorized) {
          const title = `Only for workspace Admins`;
          this.notifications.error(title);
          return this.router.parseUrl('/');
        }

        return;
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
