import { QuizQuestion } from './quiz-question';

export enum QuizStatuses {
  ACTIVE = 'active',
  RETRY = 'retry',
  COMPLETED = 'completed'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizDetails {
  readonly questions: QuizQuestion[];
  readonly status: QuizStatuses;
}

export class Quiz {
  readonly questions: QuizQuestion[];
  readonly status: QuizStatuses;

  constructor(quizDetails: IQuizDetails) {
    this.questions = quizDetails.questions;
    this.status = quizDetails.status;
  }
}
