import { hasProviderPermission } from '@app/modules/propose-service/utils/filters/guide-service-filters';
import { SessionViewModel } from '@app/modules/service-scheduling/components/group-services/types';
import {
  mapPackageSessionToVM,
  mapProgramModuleToVM
} from '@app/modules/service-scheduling/components/group-services/utils';
import {
  ServiceSchedulingStep,
  StepsHistory
} from '@app/modules/service-scheduling/store/service-scheduling/commons/types';
import { GuideServices } from '@app/modules/service-scheduling/types';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { puiSortFunction } from '@awarenow/profi-ui-core';

export function filterSessionWithSeats(
  service: GuideServices.RootObject | SessionViewModel,
  clientsCount: number
): GuideServices.RootObject | SessionViewModel | undefined {
  if (clientsCount <= 1) {
    return service;
  }

  if (service?.seatsPerTimeSlot! >= clientsCount) {
    return service;
  }

  return;
}

export function filterByText(
  services: GuideServices.RootObject[],
  searchText: string | null
): GuideServices.RootObject[] {
  return services.filter(
    service => !searchText?.length || service?.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );
}

export function filterByHost(
  services: GuideServices.RootObject[],
  hostsById: {
    [key: string]: boolean;
    [key: number]: boolean;
  } | null
): GuideServices.RootObject[] {
  return services.filter(
    service =>
      !hostsById ||
      !Object.values(hostsById).filter(item => item).length ||
      // TODO: Should used only one of (member.guideId || member.id)
      service.team.filter(
        (member: GuideServices.Team) => hasProviderPermission(member) && hostsById[member?.guideId || member?.id || '']
      ).length
  );
}

export function filterByEnrolledClients(
  services: GuideServices.RootObject[],
  chosenClients?: CRMClient[]
): GuideServices.RootObject[] {
  const chosenClientsIds = chosenClients?.map(({ clientId }) => clientId) || [];

  return services.filter(service => {
    if (service.type === GuideServiceTypes.PROGRAM) {
      return chosenClientsIds.every(id => (service.customerIds || []).includes(id));
    }

    return true;
  });
}

export function filterByServiceType(
  services: GuideServices.RootObject[],
  serviceTypes:
    | {
        [key: string]: boolean;
      }
    | null
    | undefined
): GuideServices.RootObject[] {
  return services.filter(
    service => !serviceTypes || !Object.values(serviceTypes).filter(item => item).length || serviceTypes[service.type]
  );
}

export function filterByNotCompleted(
  services: GuideServices.RootObject[],
  chosenClients?: CRMClient[]
): GuideServices.RootObject[] {
  const chosenClientsIds = chosenClients?.map(({ clientId }) => clientId) || [];

  return services
    .map(service => {
      const modules = service.modules.filter(module => {
        return chosenClientsIds.every(id => (module.customerIds || []).includes(id));
      });

      return {
        ...service,
        modules
      };
    })
    .filter(service => {
      return !!service.modules.length;
    });
}

export function filterByNotUsed(
  services: GuideServices.RootObject[],
  chosenClients?: CRMClient[]
): GuideServices.RootObject[] {
  const chosenClientsIds = chosenClients?.map(({ clientId }) => clientId) || [];

  return services
    .map(service => {
      const sessions =
        service.sessions?.filter(session => {
          return chosenClientsIds.every(id => (session.customersIds || []).includes(id));
        }) || [];

      return {
        ...service,
        sessions
      };
    })
    .filter(service => {
      return !!service.sessions?.length;
    });
}

export const primaryFilterByServiceType = (
  service: GuideServices.RootObject,
  serviceTypes?: GuideServiceTypes[]
): boolean => {
  return serviceTypes?.length ? serviceTypes.includes(service.type) : true;
};

export const primaryFilterById = (service: GuideServices.RootObject, serviceIds?: number[]): boolean => {
  return serviceIds?.length ? serviceIds.includes(service.id) : true;
};

export const primaryFilterByFreeOfferSeats = (
  service: GuideServices.RootObject,
  selectedClientsCount: number
): GuideServices.RootObject | undefined => {
  if (service.type === GuideServiceTypes.PACKAGE) {
    const packageSessions = service.sessions?.filter(session =>
      filterSessionWithSeats(mapPackageSessionToVM(session, service), selectedClientsCount)
    );

    return packageSessions?.length ? { ...service, sessions: packageSessions } : undefined;
  }

  if (service.type === GuideServiceTypes.PROGRAM) {
    const programModules = service.modules.filter(
      module =>
        module.moduleType === GuideServiceTypes.GROUP_SESSION &&
        filterSessionWithSeats(mapProgramModuleToVM(module, service), selectedClientsCount)
    );

    return programModules?.length ? { ...service, modules: programModules } : undefined;
  }

  return filterSessionWithSeats(service, selectedClientsCount) as GuideServices.RootObject;
};

export const primaryFilterByAnyEnrolledClients = (
  service: GuideServices.RootObject
): GuideServices.RootObject | undefined => {
  if (service.type === GuideServiceTypes.PACKAGE) {
    const haveEnrolledClients = service.sessions?.some(session => !!session.customersIds?.length);

    return haveEnrolledClients ? service : undefined;
  }

  if (service.type === GuideServiceTypes.PROGRAM) {
    const haveEnrolledClients = service.modules.some(
      module => module.moduleType === GuideServiceTypes.GROUP_SESSION && !!module.customerIds?.length
    );

    return haveEnrolledClients ? service : undefined;
  }

  return service;
};

export function sortServicesByName(services: GuideServices.RootObject[]): GuideServices.RootObject[] {
  return services.sort((serviceA, serviceB) => puiSortFunction('asc')(serviceA.name, serviceB.name));
}

export function isProposeServiceFlow(stepsHistory: StepsHistory): boolean {
  return Object.keys(stepsHistory).includes(ServiceSchedulingStep.PROPOSE_SERVICE_LIST);
}
