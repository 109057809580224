<ng-container *ngIf="redirectUrl$ | async as redirectUrl; else empty">
  <pui-icon class="icon _success" svgIcon="pui:close-undefined" size="xl"></pui-icon>
  <p pui-typography="h4">If you are not redirected in 5 seconds, <a [href]="redirectUrl">click here</a>.</p>
</ng-container>

<ng-template #empty>
  <ng-container *ngIf="errorMessage; else loading">
    <pui-icon class="icon _error" svgIcon="pui:close-undefined" size="xl"></pui-icon>
    <p pui-typography="h4">You have no access to the given resource</p>
  </ng-container>

  <ng-template #loading>
    <pui-icon class="icon rotate _loading" svgIcon="pui:refresh-cw" size="xl"></pui-icon>

    <p pui-typography="h4">Checking the redirect URL...</p>
  </ng-template>
</ng-template>
