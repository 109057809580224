import { Directive } from '@angular/core';
import { BillingService } from '@app/modules/billing/services';
import { PlatformConfigurationService } from '@app/core/platform-configuration';
import { IBillingInfo } from '@app/modules/billing/interfaces';
import { combineLatest, Observable } from 'rxjs';
import { SupportedCountries } from '@app/modules/billing/types';
import { map } from 'rxjs/operators';

@Directive()
abstract class AbsComponentWithClientValidation {
  countries$: Observable<SupportedCountries> = this._billing.countries$;

  shouldDisplay$: Observable<boolean> = combineLatest([
    this._billing.billingInfo$,
    this._platformConfiguration.billingDataRequired$
  ]).pipe(map(([billingInfo, billingDataRequired]) => (billingDataRequired ? !billingInfo : false)));

  protected constructor(
    protected readonly _billing: BillingService,
    protected readonly _platformConfiguration: PlatformConfigurationService
  ) {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    _billing.requestBillingInfo().subscribe();
    this.countries$ = _billing.getSupportedCountries$();
  }

  get shouldDisplay(): boolean {
    const billingData: IBillingInfo = this._billing.getBillingValue();
    return !billingData && this._platformConfiguration.billingDataRequired;
  }
}

export { AbsComponentWithClientValidation };
