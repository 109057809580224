import { Routes } from '@angular/router';

import { environment } from '@env/environment';

import { AuthGuard } from '@app/core/auth/auth.guard';
import { ClientGuard } from '@app/core/auth/client.guard';
import { GuideGuard } from '@app/core/auth/guide.guard';
import { LandingGuard } from '@app/core/public/landing.guard';
import { PublicEventsGuard } from '@app/core/public/public-events.guard';
import { SuperAdminGuard } from '@app/core/auth/super-admin.guard';

import { routes as guidePublicProfileRoutes } from '@app/screens/guide-public-profile/guide-public-profile-routing.module';
import { PlatformSubscriptionCommonComponent } from './screens/platform-subscription/platform-subscription.component';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { HomepageComponent } from './screens/homepage/homepage.component';

export const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    canActivate: [LandingGuard],
    runGuardsAndResolvers: 'always'
  },

  {
    path: 'dashboard',
    component: DashboardComponent
  },

  {
    path: 'platform-subscription',
    component: PlatformSubscriptionCommonComponent
  },

  {
    path: environment.guideRoute,
    // eslint-disable-next-line id-length
    loadChildren: () => import('@app/screens/guide/guide.module').then(m => m.GuideModule),
    canActivate: [AuthGuard, GuideGuard],
    canLoad: [AuthGuard, GuideGuard],
    runGuardsAndResolvers: 'always'
  },

  {
    path: 'client',
    // eslint-disable-next-line id-length
    loadChildren: () => import('@app/screens/client/client.module').then(m => m.ClientModule),
    canActivate: [AuthGuard, ClientGuard],
    canLoad: [AuthGuard, ClientGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: environment.publicEventsRoute,
    canActivate: [PublicEventsGuard],
    canLoad: [PublicEventsGuard],
    // eslint-disable-next-line id-length
    loadChildren: () => import('@app/modules/public-events/public-events.module').then(m => m.PublicEventsModule)
  },

  {
    path: 'super-admin',
    // eslint-disable-next-line id-length
    loadChildren: () => import('@app/screens/super-admin/super-admin.module').then(m => m.SuperAdminModule),
    canLoad: [AuthGuard, SuperAdminGuard],
    canActivate: [AuthGuard, SuperAdminGuard],
    runGuardsAndResolvers: 'always'
  },

  {
    path: '**',
    // DO NOT USE LAZY loading for this module
    // eslint-disable-next-line max-len
    // loadChildren: () => import('@app/screens/guide-public-profile/guide-public-profile.module').then(m => m.GuidePublicProfileModule)
    children: [{ path: '', children: guidePublicProfileRoutes }],
    runGuardsAndResolvers: 'always'
  }
];
