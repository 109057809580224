import { InjectionToken, Provider } from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const MY_GUIDE_ID = new InjectionToken<Observable<boolean>>('MY_GUIDE_ID');
export const myGuideIdProvider: Provider = {
  provide: MY_GUIDE_ID,
  useFactory: (workspacesService: WorkspacesService): Observable<number> => {
    return workspacesService.activeWorkspace$.pipe(map(workspace => workspace.guideId));
  },
  deps: [WorkspacesService]
};

export const GET_MY_GUIDE_ID_VALUE = new InjectionToken<() => boolean>('MY_GUIDE_ID_VALUE');
export const myGuideIdValueProvider: Provider = {
  provide: GET_MY_GUIDE_ID_VALUE,
  useFactory:
    (workspacesService: WorkspacesService): (() => number) =>
    () =>
      workspacesService.workspace?.guideId,
  deps: [WorkspacesService]
};
