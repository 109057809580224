import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientProgramsService } from './services/client-programs.service';

@NgModule({
  imports: [CommonModule],
  providers: [ClientProgramsService]
})
export class ClientProgramsModule {}
