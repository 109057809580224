<ng-container [ngSwitch]="activeStep">
  <ng-template [ngSwitchCase]="BookingSteps.HOST_SELECTION">
    <app-service-host-selection
      [bookingDetails]="bookingOptions"
      (updatedDetails$)="onServiceHostSelection($event)"></app-service-host-selection>
  </ng-template>

  <ng-template [ngSwitchCase]="BookingSteps.SERVICE_DETAILS">
    <app-service-details-form
      [options]="bookingOptions"
      (requestMoreOptions)="requestMoreOptions.emit()"
      (serviceDetailsChange)="onServiceDetailsChange($event)"></app-service-details-form>
  </ng-template>

  <ng-template [ngSwitchCase]="BookingSteps.BILLING_DATA">
    <app-service-billing (billingDataChange)="onBillingDataChange($event)"></app-service-billing>
  </ng-template>

  <ng-template [ngSwitchCase]="BookingSteps.PAYMENT_OPTIONS">
    <app-service-payment-options-form
      (paymentOptionsInfoChange)="onPaymentOptionsInfoChange($event)"
      [options]="paymentOptionsOptions"></app-service-payment-options-form>
  </ng-template>

  <ng-template [ngSwitchCase]="BookingSteps.PAYMENT">
    <app-pay-with-selector
      [amount]="paymentOptions?.amount"
      [disableNewCardSave]="paymentOptions?.isSubscription"
      [isSubscription]="paymentOptions?.isSubscription"
      [hideWallet]="paymentOptions?.hideWallet"
      (paymentSelected)="onPaymentInfoChange()"></app-pay-with-selector>
  </ng-template>
</ng-container>
