<div
  [@hiding]="hide ? 'hide' : 'show'"
  class="alert"
  [ngClass]="alert.className"
  [attr.data-qa-id]="'alert-' + alert.template">
  <div class="container">
    <div class="row no-gutters">
      <div class="icon"></div>
      <div class="body">
        <ng-template
          [ngIf]="
            alert.template !== AlertKey.INCOMPLETE_GUIDE_PROFILE &&
            alert.template !== AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP &&
            alert.template !== AlertKey.TRIAL_LTD_MEMBERSHIP &&
            alert.template !== AlertKey.CLIENT_LIMIT_REACHED &&
            alert.template !== AlertKey.TEAM_MEMBERSHIP &&
            alert.template !== AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM &&
            alert.template !== AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED
          ">
          <div class="title" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
          <div class="description" [innerHTML]="alert.description" data-qa-id="alert-description"></div>
        </ng-template>
        <ng-template [ngIf]="alert.template === AlertKey.INCOMPLETE_GUIDE_PROFILE">
          <div class="title" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
          <div class="description" data-qa-id="alert-description">
            {{ alert.description }}
            <a routerLink="/{{ guideRoute }}/settings/edit-profile" i18n="@@clickToEditProfile">
              Click to edit profile
            </a>
          </div>
        </ng-template>
        <ng-template
          [ngIf]="
            alert.template === AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM ||
            alert.template === AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP_TEAM_FINISHED
          ">
          <div class="title" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
          <button [id]="alert.template" i18n="@@bookCall" (click)="openIntercom()" class="action">Book a call</button>
        </ng-template>
        <ng-template
          [ngIf]="
            alert.template === AlertKey.TRIAL_LTD_MEMBERSHIP ||
            alert.template === AlertKey.TRIAL_SUBSCRIPTION_MEMBERSHIP
          ">
          <div class="title" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
          <a routerLink="/platform-subscription" i18n="@@seletPlan" data-qa-id="alert-select-plan-button">
            Select plan
          </a>
        </ng-template>
        <ng-template [ngIf]="alert.template === AlertKey.TEAM_MEMBERSHIP">
          <div class="title" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
          <button i18n="@@talkToSupport" (click)="openIntercom()" class="action">Talk to support</button>
        </ng-template>
        <ng-template [ngIf]="alert.template === AlertKey.CLIENT_LIMIT_REACHED">
          <div class="title" [innerHTML]="alert.title" data-qa-id="alert-title"></div>
          <a routerLink="/platform-subscription" i18n="@@increaseLimitByUpgrading">by upgrading.</a>
        </ng-template>
      </div>
      <div class="close-wrapper">
        <button class="close" (click)="close()"></button>
      </div>
    </div>
  </div>
</div>
