import { Injectable } from '@angular/core';
import { ServiceScheduleApiService } from '@app/core/schedule/service-schedule-api.service';
import { ISchedule } from '@app/modules/schedule-boards';
import { ScheduleProvider } from './schedule-provider.service';
import { IGuideServiceScheduleOptions } from './types';

@Injectable()
export class GuideServiceScheduleProviderService extends ScheduleProvider<ISchedule, IGuideServiceScheduleOptions> {
  constructor(protected readonly _guideServiceScheduleApi: ServiceScheduleApiService) {
    super();
  }

  refresh(options: IGuideServiceScheduleOptions): void {
    this._guideServiceScheduleApi
      .getGuideServiceSchedule$(options.guideId, options.serviceId)
      .subscribe(ranges => this._schedule$.next({ ranges }));
  }
}
