import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, Provider } from '@angular/core';
import { GuideServiceParent } from '@app/modules/book-service';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';
import { BaseSessionComponent, ClientSession, SESSION_COMPONENT } from '@libs/business/client/client-session';

const SESSION_COMPONENT_PROVIDER: Provider = {
  provide: SESSION_COMPONENT,
  useExisting: forwardRef(() => SessionComponent)
};

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
  providers: [SESSION_COMPONENT_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionComponent implements BaseSessionComponent<GuideServiceParent> {
  @Input()
  session: ClientSession<GuideServiceParent> | undefined;

  constructor(@Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>) {}
}
