<ng-container [formGroup]="form">
  <div class="form-group">
    <div class="date-time-group">
      <div class="date-wrapper">
        <label for="event_date" class="label top-label required" i18n="@@newEventDateLabel">Date</label>
        <div class="datepicker-wrapper" (clickOutside)="datePicker.close()">
          <input
            [(ngModel)]="date"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="onDateChange($event)"
            id="event_date"
            data-qa-id="event-date-picker"
            type="text"
            ngbDatepicker
            #datePicker="ngbDatepicker"
            navigation="arrows"
            [firstDayOfWeek]="7"
            [markDisabled]="isNotWorkingDay"
            (click)="datePicker.toggle()"
            [autoClose]="'inside'"
            [maxDate]="maxDate | async"
            [minDate]="minDate"
            [readonly]="true"
            [disabled]="isDisabled"
            placement="bottom"
            class="date-control form-control"
            placeholder="Choose date..."
            i18n-placeholder="@@newEventDatePlaceholder" />
        </div>
      </div>

      <div class="time-wrapper">
        <label for="event_time" class="label top-label required" i18n="@@newEventTimeLabel">Time</label>
        <ng-select
          formControlName="date"
          id="event_time"
          data-qa-id="event-time-picker"
          [searchable]="false"
          [clearable]="false"
          [items]="timeslots"
          [virtualScroll]="true"
          bindLabel="label"
          bindValue="value"></ng-select>
      </div>
    </div>

    <div class="recurrence-timezone-group">
      <div class="recurring-check-wrapper" *ngIf="canRepeat">
        <app-checkbox
          data-qa-id="event-date-selector-recurring-checkbox"
          formControlName="isRecurring"
          i18n="@@newEventRecurringCheck">
          Recurring
        </app-checkbox>
      </div>

      <div class="timezone-wrapper">
        <label for="event_timezone" class="label side-label timezone-label">
          <ng-container i18n="@@newEventTimezoneLabel">Time zone</ng-container>
          :
        </label>
        <ng-select
          formControlName="timezone"
          id="event_timezone"
          [searchable]="false"
          [clearable]="false"
          [items]="timezones"
          [virtualScroll]="true"
          bindLabel="name"
          bindValue="value"></ng-select>
      </div>
    </div>
  </div>

  <div class="form-group recurrence-group" *ngIf="canRepeat && isRecurring">
    <label class="label" for="event_recurrence" i18n="@@newEventRepeatLabel">Repeat</label>
    <ng-select
      formControlName="recurrence"
      id="event_recurrence"
      data-qa-id="event-date-selector-repeat-select"
      [searchable]="false"
      [clearable]="false"
      [items]="recurrences">
      <ng-template ng-label-tmp let-recurrence="item">
        <span *ngIf="recurrence.type === RECURRENCE_TYPES.DAILY" i18n="@@recurrenceDaily">Daily</span>

        <span *ngIf="recurrence.type === RECURRENCE_TYPES.WEEKLY_ON_DAY">
          <ng-container i18n="@@recurrenceWeeklyOn">Weekly on</ng-container>
          {{ form.get('date').value | date: 'EEEE' }}
        </span>

        <span *ngIf="recurrence.type === RECURRENCE_TYPES.EVERY_WEEKDAY">
          <ng-container i18n="@@recurrenceEveryWeekDay">Every week day</ng-container>
          ({{ form.get('date').value | weekDay: 1 | date: 'EEEE' }} -
          {{ form.get('date').value | weekDay: 5 | date: 'EEEE' }})
        </span>

        <span *ngIf="recurrence.type === RECURRENCE_TYPES.MONTHLY" i18n="@@recurrenceMonthly">Monthly</span>

        <span *ngIf="recurrence.type === RECURRENCE_TYPES.MONTHLY_ON_WEEKDAY">
          <ng-container i18n="@@recurrenceMonthlyOn">Monthly on</ng-container>
          {{ form.get('date').value | date: 'EEEE' }}
        </span>
      </ng-template>

      <ng-template ng-option-tmp let-recurrence="item">
        <span
          data-qa-id="event-date-selector-daily-select-option"
          *ngIf="recurrence.type === RECURRENCE_TYPES.DAILY"
          i18n="@@recurrenceDaily">
          Daily
        </span>

        <span
          data-qa-id="event-date-selector-weekly-on-day-select-option"
          *ngIf="recurrence.type === RECURRENCE_TYPES.WEEKLY_ON_DAY">
          <ng-container i18n="@@recurrenceWeeklyOn">Weekly on</ng-container>
          {{ form.get('date').value | date: 'EEEE' }}
        </span>

        <span
          data-qa-id="event-date-selector-every-weekday-select-option"
          *ngIf="recurrence.type === RECURRENCE_TYPES.EVERY_WEEKDAY">
          <ng-container i18n="@@recurrenceEveryWeekDay">Every week day</ng-container>
          ({{ form.get('date').value | weekDay: 1 | date: 'EEEE' }} -
          {{ form.get('date').value | weekDay: 5 | date: 'EEEE' }})
        </span>

        <span
          data-qa-id="event-date-selector-monthly-select-option"
          *ngIf="recurrence.type === RECURRENCE_TYPES.MONTHLY"
          i18n="@@recurrenceMonthly">
          Monthly
        </span>

        <span
          data-qa-id="event-date-selector-monthly-on-weekday-select-option"
          *ngIf="recurrence.type === RECURRENCE_TYPES.MONTHLY_ON_WEEKDAY">
          <ng-container i18n="@@recurrenceMonthlyOn">Monthly on</ng-container>
          {{ form.get('date').value | date: 'EEEE' }}
        </span>
      </ng-template>
    </ng-select>
  </div>

  <!-- eslint-disable -->

  <div
    class="form-group recurrence-end-group"
    *ngIf="canRepeat && isRecurring && form.get('recurrence')?.value != null">
    <app-checkbox
      [(ngModel)]="doesRecurrenceEnd"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onDoesRecurrenceEndChange($event)"
      data-qa-id="event-date-selector-this-recurrence-ends-checkbox"
      i18n="@@newEventRecurrenceEnd">
      This recurrence ends
    </app-checkbox>

    <div
      appRadioGroup
      formControlName="recurrenceEndType"
      class="recurrence-end-type-selector"
      *ngIf="canRepeat && doesRecurrenceEnd">
      <div class="radio-item-container">
        <div class="radio-item">
          <input appRadioInput type="radio" [value]="'until'" id="recurrence_end_type_until" />

          <label
            data-qa-id="event-date-selector-recurrence-end-on-radio-button"
            class="radio-label recurrence-end-type-radio-label"
            for="recurrence_end_type_until">
            <span class="recurrence-end-type-name" i18n="@@recurrenceEndTypeUntil">on</span>
          </label>
        </div>

        <div class="recurrence-until-date">
          <div class="datepicker-wrapper" (clickOutside)="untilDatePicker.close()">
            <input
              formControlName="recurrenceUntilDate"
              id="recurrence_end_date"
              type="text"
              ngbDatepicker
              #untilDatePicker="ngbDatepicker"
              navigation="arrows"
              [firstDayOfWeek]="7"
              (click)="untilDatePicker.toggle()"
              [autoClose]="'inside'"
              [maxDate]="maxDate | async"
              [minDate]="minUntilDate"
              [readonly]="true"
              placement="bottom"
              class="recurrent-until-date-control form-control"
              placeholder="Choose date..."
              i18n-placeholder="@@newEventRecurrenceUntilDatePlaceholder" />
          </div>
        </div>
      </div>

      <div class="radio-item-container">
        <div class="radio-item">
          <input appRadioInput type="radio" [value]="'after'" id="recurrence_end_type_after" />

          <label
            data-qa-id="event-date-selector-recurrence-end-after-radio-button"
            class="radio-label recurrence-end-type-radio-label"
            for="recurrence_end_type_after">
            <span class="recurrence-end-type-name" i18n="@@recurrenceEndTypeAfter">after</span>
          </label>
        </div>

        <div>
          <input
            formControlName="recurrenceAfterCount"
            class="form-control"
            type="number"
            min="1"
            step="1"
            max="1000"
            inputmode="numeric"
            data-qa-id="event-date-selector-recurrence-after-count-input"
            [readonly]="form.get('recurrenceEndType').value !== 'after'" />
          <app-form-control-error
            [control]="form.get('recurrenceAfterCount')"
            i18n="@@newEventRecurrenceEndInvalidCount">
            Incorrect count value
          </app-form-control-error>
        </div>
      </div>
    </div>
  </div>

  <!-- eslint-enable -->
</ng-container>
