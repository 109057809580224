import { FormBuilder, FormGroup } from '@angular/forms';
import { Testimonial } from '@app/modules/guide-service-editor/types/testimonial';
import { SessionTemplate, SessionTemplateInterface } from '@app/screens/guide/guide-sessions-templates/types';
import { SessionType } from '@app/shared/enums/session-type';

import { SessionTemplateForm } from './types';

export const createForm = (fb: FormBuilder): FormGroup => {
  return fb.group({
    basicInfo: fb.control(null),
    pricing: fb.control(null),
    location: fb.control(null),
    availability: fb.control(null),
    hosts: fb.control(null),
    participantsAndGuests: fb.control(null),
    restrictions: fb.control(null),
    testimonials: fb.control(null),
    communityConfig: fb.control(null)
  });
};

export function createSessionTemplateFormValue(template: SessionTemplate): SessionTemplateForm {
  return {
    basicInfo: {
      name: template.name,
      length: template.duration,
      descriptionRepresentation: template.descriptionRepresentation,
      coverImage: template.coverImage,
      coverImageThumb: template.coverImageThumb,
      status: template.status,
      location: template.location,
      recurring: template.recurrence,
      restrictClientBooking: template.restrictClientBooking
    },
    testimonials: template.testimonials,
    restrictions: {
      afterEventBuffer: template.afterEventBuffer,
      beforeEventBuffer: template.beforeEventBuffer,
      minimumBookingNotice: template.minimumBookingNotice,
      slotInterval: template.slotInterval,
      limitBookingFrequency: template.bookingLimitsFrequency,
      limitFutureBookings: template.limitFutureBookings
    },
    location: template.location,
    pricing: {
      price: template.price,
      hidePrice: template.hidePrice
    },
    participantsAndGuests: {
      disableGuests: template.disableGuests,
      seatsPerTimeSlot: template.seatsPerTimeSlot,
      hasAutoConfirmation: template.hasAutoConfirmation
    },
    availability: {
      availabilities: template.availabilities, // Why???
      sessionTemplateScheduleId: template.scheduleId,
      customAvailability: template.customAvailability
    },
    hosts: {
      sessionType: template.sessionType || SessionType.PERSONAL,
      hosts: {
        search: '',
        hostsById: {},
        selectedHosts: template.hosts
      }
    },
    communityConfig: {
      hasChat: !!template.seatsPerTimeSlot && template.hasChat,
      viewChatHistory: template.viewChatHistory,
      removeFromChatOnSessionEnd: template.removeFromChatOnSessionEnd,
      enabled: template.enabled,
      communityName: template.communityName
    }
  };
}

export function createSessionTemplateInterfaceFromFromValue(
  sessionTemplateFormValue: SessionTemplateForm,
  originalTemplate: SessionTemplate | null,
  isSoloWorkspace: boolean,
  isWorkspaceMember: boolean
): SessionTemplateInterface {
  const basicInfo = sessionTemplateFormValue.basicInfo;
  const pricing = sessionTemplateFormValue.pricing;
  const { hosts, sessionType } = sessionTemplateFormValue.hosts;
  const participantsAndGuests = sessionTemplateFormValue.participantsAndGuests;
  const restrictions = sessionTemplateFormValue.restrictions;
  const availability = sessionTemplateFormValue.availability;
  const testimonials = sessionTemplateFormValue.testimonials;
  const communityConfig = sessionTemplateFormValue.communityConfig;

  // ToDo Rework location
  const location =
    isSoloWorkspace || isWorkspaceMember
      ? sessionTemplateFormValue.basicInfo.location
      : sessionTemplateFormValue.location;

  return {
    afterEventBuffer: restrictions.afterEventBuffer,
    beforeEventBuffer: restrictions.beforeEventBuffer,
    bookingLimitsFrequency: restrictions.limitBookingFrequency,
    coverImage: basicInfo.coverImage,
    coverImageThumb: basicInfo.coverImageThumb,
    customAvailability: availability.customAvailability || null,
    descriptionMarkup: basicInfo.descriptionMarkup || '',
    descriptionRepresentation: basicInfo.descriptionRepresentation || '',
    descriptionText: basicInfo.descriptionText || '',
    disableGuests: participantsAndGuests.disableGuests,
    duration: basicInfo.length,
    hasAutoConfirmation: participantsAndGuests.hasAutoConfirmation,
    hidePrice: pricing.hidePrice,
    hosts: hosts.selectedHosts,
    limitFutureBookings: restrictions.limitFutureBookings,
    location: location,
    minimumBookingNotice: restrictions.minimumBookingNotice,
    name: basicInfo.name,
    permission: originalTemplate?.permission!,
    price: pricing.price,
    recurrence: basicInfo.recurring,
    scheduleId: availability.sessionTemplateScheduleId || null,
    seatsPerTimeSlot: participantsAndGuests.seatsPerTimeSlot,
    seatsShowAttendees: false, // [ToDo]
    serviceType: originalTemplate?.serviceType!,
    sessionType: sessionType,
    slotInterval: restrictions.slotInterval,
    status: basicInfo.status,
    testimonials: testimonials ? testimonials.map(testimonial => new Testimonial().setValues(testimonial)) : [],
    workspaceId: originalTemplate?.workspaceId!,
    removeFromChatOnSessionEnd: communityConfig.removeFromChatOnSessionEnd,
    viewChatHistory: communityConfig.viewChatHistory,
    hasChat: !!participantsAndGuests.seatsPerTimeSlot && communityConfig.hasChat,
    enabled: communityConfig.enabled,
    communityName: communityConfig.communityName,
    restrictClientBooking: basicInfo.restrictClientBooking
  };
}
