import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthService } from '@app/core/auth/services';
import { filter, map } from 'rxjs/operators';
import { BrandingService } from '@app/core/branding/branding.service';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { MembershipService } from '@app/core/membership/membership.service';
import { PublicEventsService } from '@app/modules/public-events/services/public-events.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { LoopedinService } from '@app/core/integrations/modules';

@Injectable()
export class NavigationConditionsClientService implements Partial<ConditionsMap<VisibilityCondition>> {
  always: Observable<boolean> = of(true);

  never: Observable<boolean> = of(false);

  // only for client who in BrandingService parameter enableSurveys === true
  [VisibilityCondition.forms] = combineLatest([
    this.brandingService.globalConfig$.pipe(map(({ enableSurveys }) => enableSurveys))
  ]).pipe(map(([enableSurveys]) => enableSurveys));

  [VisibilityCondition.community] = this.runtimeConfigService.configuration$.pipe(
    map(({ amity }) => amity.isEnabled && amity.permissions.client)
  );

  // only for client who have access to Programs & in BrandingService parameter enablePrograms === true
  [VisibilityCondition.programs] = combineLatest([
    this.clientProgramsService.doesClientHaveAccessiblePrograms$,
    this.brandingService.globalConfig$.pipe(map(({ enablePrograms }) => enablePrograms))
  ]).pipe(
    map(([doesClientHaveAccessiblePrograms, enablePrograms]) => doesClientHaveAccessiblePrograms && enablePrograms)
  );

  // only for client who in BrandingService parameter hideWallet === false
  [VisibilityCondition.wallet] = combineLatest([
    this.brandingService.globalConfig$.pipe(map(({ hideWallet }) => !hideWallet))
  ]).pipe(map(([hideWallet]) => hideWallet));

  // only if loopedin script installed
  [VisibilityCondition.whats_new] = of(!!this.loopedinService.getLoopedin());

  // only if user can signup as coach
  [VisibilityCondition.can_signup_as_coach] = combineLatest([
    this.brandingService.globalConfig$,
    this.authService.onAuth()
  ]).pipe(
    map(([config, user]) => {
      return !config.hideSignUpAs && !user?.hasAlternativeProfile;
    })
  );

  // only if membership for clients enabled by platform admins
  [VisibilityCondition.subscription] = this.membershipService.isMembershipEnabledForClients$;

  // TODO: Review arePublicEventsAvailableForClient$ request to api is made in component of old side menu
  // @ts-expect-error TS2416
  [VisibilityCondition.public_events] = combineLatest([
    this.publicEventsService.arePublicEventsAvailableForClient$,
    of(this.runtimeConfigService.get('publicEventsEnabledForClients'))
  ]).pipe(map(([publicEventsAvailable, publicEventsEnabled]) => publicEventsAvailable && publicEventsEnabled));

  // only if account_switcher enabled in Global config
  [VisibilityCondition.account_switcher]: Observable<boolean> = combineLatest([
    this.authService.onAuth().pipe(
      filter(user => !!user),
      map(({ hasAlternativeProfile }) => hasAlternativeProfile)
    ),
    this.brandingService.globalConfig$.pipe(
      filter(config => !!config),
      map(({ hideSignInAs }) => !hideSignInAs)
    )
  ]).pipe(map(flags => flags.every(flag => flag === true)));

  [VisibilityCondition.marketplace]: Observable<boolean> = this.brandingService.globalConfig$.pipe(
    filter(config => !!config),
    map(({ enableMarketplace }) => !!enableMarketplace)
  );

  constructor(
    private readonly authService: AuthService,
    private readonly clientProgramsService: ClientProgramsService,
    private readonly brandingService: BrandingService,
    private readonly membershipService: MembershipService,
    private readonly publicEventsService: PublicEventsService,
    private readonly runtimeConfigService: RuntimeConfigService,
    private readonly loopedinService: LoopedinService
  ) {}
}
