import { Injector } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import {
  NavigationConditionsAdminService,
  NavigationConditionsClientService,
  NavigationConditionsGuideService,
  NavigationConditionsSuperAdminService
} from '@app/modules/navigation/services';

export const sidenavConditionsFactory = (
  authService: AuthService,
  injector: Injector
): Partial<ConditionsMap<VisibilityCondition>> => {
  switch (authService.getUserRoleId()) {
    case UserRoles.CLIENT:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationConditionsClientService);
    case UserRoles.GUIDE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationConditionsGuideService);
    case UserRoles.ADMIN:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationConditionsAdminService);
    case UserRoles.SUPER_ADMIN:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationConditionsSuperAdminService);
    default:
      // @ts-expect-error TS2322
      return null;
  }
};
