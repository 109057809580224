import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ServicesDetailsProviderService } from '@app/modules/book-service/services/services-details-provider.service';
import { SCHEDULE_PROVIDER } from '@app/modules/schedule-boards';
import { IScheduleOptions } from '@app/modules/ui-kit/schedule';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-service-schedule',
  templateUrl: './service-schedule.component.html',
  styleUrls: ['./service-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SCHEDULE_PROVIDER, useClass: ServicesDetailsProviderService }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'service-schedule'
  }
})
export class ServiceScheduleComponent<
  TScheduleOptions extends { duration?: number; date?: string; timezone?: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TSelectedTime = any
> {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideScheduleOptions: IScheduleOptions | null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleOptions: TScheduleOptions;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _time: TSelectedTime;

  readonly localTimezone = DateTime.local().zoneName;

  @Input()
  set scheduleOptions(value: TScheduleOptions) {
    this._scheduleOptions = value;
    if (value) {
      this._guideScheduleOptions = {
        duration: value.duration,
        date: DateTime.local().toISO(),
        timezone: DateTime.local().zoneName
      };
    }
  }

  @Output()
  requestMoreOptions = new EventEmitter();

  @Output()
  timeSelect = new EventEmitter<TSelectedTime>();

  get guideScheduleOptions(): IScheduleOptions {
    // @ts-expect-error TS2322
    return this._guideScheduleOptions;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get scheduleOptions(): TScheduleOptions {
    return this._scheduleOptions;
  }

  get timeSelected(): boolean {
    return !!this._time;
  }

  constructor(readonly provider: ServicesDetailsProviderService) {}

  confirmTime(): void {
    if (this.timeSelected) {
      this.timeSelect.emit(this._time);
    }
  }

  selectTime(time: TSelectedTime): void {
    this._time = time;
  }
}
