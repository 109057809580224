import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type EventActionRangeType = 'all' | 'current' | 'following';
export type EventActionRangeModalType = 'cancel' | 'reschedule';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-event-action-range-modal',
  templateUrl: './event-action-range-modal.component.html',
  styleUrls: [
    '../../../../../../../scss/guide-services/radio.scss',
    '../../../../../../../scss/_event-modal.scss',
    './event-action-range-modal.component.scss'
  ]
})
export class EventActionRangeModalComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _hideCurrent = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _showCurrentOnly = false;

  @Input()
  set hideCurrent(value: boolean) {
    this._hideCurrent = value;

    if (this._hideCurrent && this.eventActionRangeType === 'current') {
      this.eventActionRangeType = 'following';
    }
  }

  @Input()
  modalType: EventActionRangeModalType = 'cancel';

  @Input()
  set showCurrentOnly(value: boolean) {
    this._showCurrentOnly = value;

    if (this._showCurrentOnly && this.eventActionRangeType !== 'current') {
      this.eventActionRangeType = 'current';
    }
  }

  eventActionRangeType: EventActionRangeType = 'current';

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get hideCurrent(): boolean {
    return this._hideCurrent;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get showCurrentOnly(): boolean {
    return this._showCurrentOnly;
  }

  constructor(public modal: NgbActiveModal) {}
}
