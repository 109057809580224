import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { Quiz } from '../types/quiz';
import { QuizQuestionType, QuizQuestionOption, QuizEntityPolicy } from '@app/core/quizzes/types';
import { QuizSendPolicy } from '@app/core/quizzes/types';
import { QuizService } from './quiz.service';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';
import { WindowManagerService } from '@libs/services/window-manager/window-manager.service';
import { CHOOSE_FORMS_FEATURE } from '@app/modules/propose-forms/consts';
import { isChoiceQuestion } from '@app/screens/client/client-programs/utils';
import { quizQuestionOptionsValidator } from '../validators/quiz-question-options-validator';

@Injectable({
  providedIn: 'root'
})
export class SurveyFormService implements OnDestroy {
  private readonly loadingSubject$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject$.asObservable();

  readonly avatars: string[] = [
    'https://aware-upload-files.s3.amazonaws.com/bot-avatars/default/avatar_1.png',
    'https://aware-upload-files.s3.amazonaws.com/bot-avatars/default/avatar_2.png',
    'https://aware-upload-files.s3.amazonaws.com/bot-avatars/default/avatar_3.png',
    'https://aware-upload-files.s3.amazonaws.com/bot-avatars/default/avatar_4.png'
  ];

  private quizFormSource!: FormGroup;

  get quizForm(): FormGroup {
    return this.quizFormSource;
  }

  readonly quiz$ = new BehaviorSubject<Quiz | null>(null);

  private readonly destroy$ = new Subject();

  private savedQuiz: Quiz | null = null;

  get quizId(): number | null {
    return this.quiz$.value?.id || null;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationsService,
    private readonly quizService: QuizService,
    private readonly windowManagerService: WindowManagerService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setQuiz(quiz: Quiz): void {
    this.updateQuizForm(quiz);
  }

  loadSavedQuizForm(quiz = this.savedQuiz): void {
    if (!quiz) {
      return;
    }

    this.updateQuizForm(quiz);
  }

  updateQuizForm(quiz: Quiz): void {
    this.resetQuizForm();

    this.savedQuiz = quiz;
    this.quiz$.next(quiz);

    this.quizForm.patchValue(quiz);

    if (quiz.questions.length) {
      const questionsControl = this.quizForm.controls.questions as FormArray;

      quiz.questions.forEach(question => questionsControl.push(this.createQuestion(question)));
    }

    if (quiz.settings.length) {
      const settingsControl = this.quizForm.controls.settings as FormArray;

      quiz.settings.forEach(item => {
        if (item.schedule && item.schedule.pattern) {
          item.schedule.repeat = item.schedule.pattern;
        }

        settingsControl.push(
          this.formBuilder.group({
            id: [item.id, []],
            sendPolicy: [item.sendPolicy, []],
            clientsPolicy: [item.clientsPolicy, []],
            entityPolicy: [item.entityPolicy, []],
            programs: [item.programs, []],
            sessions: [item.sessions, []],
            schedule: [item.schedule, []],
            clients: [item.clients, []]
          })
        );
      });
    }

    this.quizForm.markAsPristine();
  }

  resetQuizForm(): void {
    this.quizFormSource = this.formBuilder.group({
      basic: this.formBuilder.group({
        quizName: ['', [Validators.required, Validators.maxLength(100)]],
        welcomeMessage: ['', [Validators.maxLength(2000)]],
        avatar: [this.avatars[0], []]
      }),
      settings: this.formBuilder.array([]),
      questions: this.formBuilder.array([])
    });

    this.savedQuiz = null;
    this.quiz$.next(null);
  }

  createQuestionOption(
    params: QuizQuestionOption = { text: '', explanation: '', other: false, isCorrect: false } as QuizQuestionOption
  ): FormGroup {
    return this.formBuilder.group({
      isCorrect: [params.isCorrect, []],
      text: [params.text, [quizQuestionOptionsValidator()]],
      explanation: [params.explanation, []],
      other: [params.other, []]
    });
  }

  createQuestion(
    params: {
      id?: number | undefined;
      question: string;
      options: QuizQuestionOption[] | null;
      type: QuizQuestionType;
    } = { question: '', options: null, type: QuizQuestionType.SHORT_ANSWER }
  ): FormGroup {
    const other = (params.options || []).find(item => item.other);
    const options = this.formBuilder.array(
      params.options
        ? params.options.filter(i => !i.other).map(item => this.createQuestionOption(item))
        : [this.createQuestionOption(), this.createQuestionOption(), this.createQuestionOption()]
    );
    const questionGroup = this.formBuilder.group({
      id: [params.id],
      question: [params.question, [Validators.required]],
      type: [params.type, []],
      hasOptions: [isChoiceQuestion(params.type)],
      hasOptionExplanation: [params.options?.some(op => !!op.explanation)],
      hasOtherOption: [!!other, []],
      other: this.createQuestionOption(other),
      options: options
    });

    // @ts-expect-error TS2531
    questionGroup
      .get('hasOtherOption')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const textControl = questionGroup.get('other.text');

        if (value) {
          // @ts-expect-error TS2531
          textControl.setValidators(Validators.required);
        } else {
          // @ts-expect-error TS2531
          textControl.clearValidators();
          // @ts-expect-error TS2531
          textControl.setErrors(null);
        }

        // @ts-expect-error TS2531
        textControl.updateValueAndValidity();
      });

    return questionGroup;
  }

  createSurvey$(): Observable<number | null> {
    this.loadingSubject$.next(true);

    if (this.quizForm.invalid) {
      this.loadingSubject$.next(false);

      return of(null);
    }

    const data = this.processSettings();

    return this.quizService.newQuiz(data).pipe(
      tap(quiz => {
        this.quiz$.next({
          ...this.quiz$.value,
          id: quiz.id
        } as Quiz);

        this.notificationService.success(`Changes are successfully saved`);
        /**
         * That code will send a message for propose-a-form module.
         * If propose-a-form module is listening this message it will close the browser window.
         */
        this.windowManagerService.postMessage(CHOOSE_FORMS_FEATURE, { command: 'update' });
      }),
      map(quiz => quiz?.id),
      finalize(() => this.loadingSubject$.next(false))
    );
  }

  updateSurvey$(): Observable<Quiz | null> {
    this.loadingSubject$.next(true);

    if (this.quizForm.invalid || !this.quizId) {
      this.loadingSubject$.next(false);

      return of(null);
    }

    const setting = this.processSettings();

    return this.quizService.updateQuiz(this.quizId, setting).pipe(
      tap(_quiz => {
        this.notificationService.success(`Changes are successfully saved`);
        this.windowManagerService.postMessage(CHOOSE_FORMS_FEATURE, { command: 'update' });
        this.loadSavedQuizForm(_quiz);
        this.quizForm.markAsPristine();
      }),
      finalize(() => this.loadingSubject$.next(false))
    );
  }

  private processSettings(): Quiz {
    const data: Quiz = this.quizForm.value;

    if (data.settings.length > 0) {
      data.settings = data.settings.filter(item => !!item?.sendPolicy);
    }

    data.settings.forEach(setting => {
      if (setting.sendPolicy !== QuizSendPolicy.Scheduling) {
        setting.schedule = null;
      }

      if (
        setting.sendPolicy !== QuizSendPolicy.AfterBuyProgram &&
        setting.entityPolicy !== QuizEntityPolicy.SelectedPrograms
      ) {
        setting.programs = [];
      }

      if (setting.sendPolicy === QuizSendPolicy.AfterBuyProgram) {
        setting.sessions = [];
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.questions.forEach((question: any) => {
      if (!question.hasOptions) {
        question.options = [];
      }

      if (question.hasOtherOption && question.other.text.trim()) {
        question.other.other = true;
        question.options.push(question.other);
      }

      if (question.hasOptions) {
        question.options = question.options.filter((option: { text?: string }) => option.text);
      }
    });

    return data;
  }
}
