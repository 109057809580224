import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '../config/config';
import { AuthService } from '../auth/services/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FindYourGuideService {
  constructor(private _http: HttpClient, private authService: AuthService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getProfiles(filter?: any): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryParams = { params: {} } as any;

    if (filter && Object.keys(filter).length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { isoLanguages, languages, timezones, ...params }: any = filter;

      if (languages && languages.length) {
        params.languages = languages.join(',');
      }

      if (timezones && timezones.length) {
        params.timezones = timezones.join(',');
      }

      if (isoLanguages && isoLanguages.length) {
        params.isoLanguages = isoLanguages.join(',');
      }

      queryParams.params = params;
    }

    if (this.authService.isAuthorized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryParams.params = queryParams.params || ({} as any);
    }

    if (this.authService.hashedId) {
      queryParams.params.hashedid = this.authService.hashedId;
    }

    queryParams.params.noCache = Math.random();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(`${config.apiPath}/public/profile/all`, queryParams);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPublicGuides(ids?: string): Observable<any> {
    // @ts-expect-error TS2769
    return this._http.get(`${config.apiPath}/public/profile/list`, { params: { ids } });
  }

  getDropdownTags$(): Observable<string[]> {
    return this._http
      .get<{ tags: { id: number; name: string }[] }>(`${config.apiPath}/public/profile/tags`)
      .pipe(map(response => response.tags.map(tag => tag.name)));
  }
}
