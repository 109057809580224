<div class="modal-header">
  <h3 data-qa-id="rescheduling-message-include-message-title" class="modal-title" i18n="@@rescheduleMessageIncludeMsg">
    Include a message
  </h3>

  <h4
    data-qa-id="rescheduling-message-why-reschedule-subtitle"
    *ngIf="user"
    class="modal-sub-title"
    i18n="@@whyYouReschedule">
    Let {{ user?.name }} know why you reschedule
  </h4>
</div>

<div class="modal-body">
  <textarea data-qa-id="rescheduling-message-message-textarea" [(ngModel)]="message" class="form-control"></textarea>
</div>

<div class="modal-footer">
  <app-button
    data-qa-id="rescheduling-message-send-message-button"
    className="btn-primary"
    i18n="@@sendMsg"
    [disabled]="!message"
    (click)="handleAction(true)">
    Send message
  </app-button>
  <app-button
    data-qa-id="rescheduling-message-reschedule-without-message-button"
    className="btn-link"
    i18n="@@rescheduleWithoutMsg"
    (click)="handleAction()">
    Reschedule without message
  </app-button>
</div>
