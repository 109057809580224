import { ScreenBreakpoints } from '@app/modules/ui-kit/_base/_common/screen/services';

export const BREAKPOINTS: ScreenBreakpoints = {
  Mobile: '(max-width: 767.98px)',
  SMobile: '(max-width: 359.58px)',
  MMobile: '(min-width: 360px) and (max-width: 374.98px)',
  LMobile: '(min-width: 375px) and (max-width: 767.98px)',
  Tablet: '(min-width: 768px) and (max-width: 1365.98px)',
  STablet: '(min-width: 768px) and (max-width: 991.98px)',
  MTablet: '(min-width: 992px) and (max-width: 1365.98px)',
  Desktop: '(min-width: 1366px)',
  SDesktop: '(min-width: 1366px) and (max-width: 1439.98px)',
  MDesktop: '(min-width: 1440px) and (max-width: 1919.58px)',
  LDesktop: '(min-width: 1920px)'
};
