<ng-container *ngIf="mode$ | async as mode">
  <div class="line">
    <div [style.display]="mode !== 'short' ? 'inherit' : 'none'">
      <ng-template portalHost #logo="cdkPortalHost"></ng-template>
    </div>
    <app-sidenav-toggle-button *DesktopOrMediumTablet></app-sidenav-toggle-button>
  </div>

  <div class="line">
    <div>
      <ng-template portalHost #current="cdkPortalHost"></ng-template>
    </div>

    <div>
      <div [style.display]="mode !== 'short' ? 'inherit' : 'none'">
        <ng-template portalHost #workspaceSwitch="cdkPortalHost"></ng-template>
      </div>
    </div>
  </div>
</ng-container>
