import { Location } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BlogReaderService } from '../../services/blog-reader.service';
import { BusinessBlogReaderService } from '../../services/business-blog-reader.service';
import { DisqusWidgetsService } from '../../services/disqus-widgets.service';
import { EmbedDisqusCountScriptService } from '../../services/embed-disqus-count-script.service';
import { BlogComponent } from '../blog/blog.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-business-blog',
  templateUrl: '../blog/blog.component.html',
  styleUrls: ['../blog/blog.component.scss'],
  providers: [
    EmbedDisqusCountScriptService,
    DisqusWidgetsService,
    BusinessBlogReaderService,
    {
      provide: BlogReaderService,
      useExisting: BusinessBlogReaderService
    }
  ]
})
export class BusinessBlogComponent extends BlogComponent {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _routerService: Router,
    private _titleService: Title,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private _platformId,
    private _authService: AuthService,
    private _blogService: BusinessBlogReaderService,
    private _embedDisqusCountScriptService: EmbedDisqusCountScriptService,
    private _disqusWidgetsService: DisqusWidgetsService,
    private _modalService: NgbModal,
    private _analyticsService: AnalyticsService,
    private _brandingService: BrandingService,
    private _locationService: Location,
    private _metaTagService: MetaTagService,
    _localeService: LocaleService
  ) {
    super(
      _activatedRoute,
      _routerService,
      _titleService,
      _platformId,
      _authService,
      _blogService,
      _embedDisqusCountScriptService,
      _disqusWidgetsService,
      _modalService,
      _analyticsService,
      _brandingService,
      _locationService,
      _localeService,
      _metaTagService
    );

    this._showWikiFilter = false;
  }
}
