<div class="icon"></div>

<div class="card-content">
  <ng-template [ngIf]="connected" [ngIfElse]="disconnected">
    <app-calendar-account
      *ngFor="let account of source.accounts"
      [account]="account"
      [source]="source.source"
      (disconnect)="disconnect.emit($event)"></app-calendar-account>

    <div class="account-btn-container" *ngIf="source.source === ExternalCalendarSources.OUTLOOK">
      <app-button
        data-qa-id="calendar-source-card-add-account-button"
        className="btn-link"
        (click)="connect.emit()"
        i18n="@@calendarSourceCardAddAccountBtn">
        Add account
      </app-button>
    </div>
    <div class="account-btn-container" *ngIf="source.source === ExternalCalendarSources.GOOGLE">
      <app-button
        data-qa-id="calendar-source-card-add-google-calendar-account-button"
        className="btn-link"
        (click)="connect.emit()"
        i18n="@@calendarSourceCardAddGoogleCalendarAccountBtn">
        Add Google Calendar account
      </app-button>
    </div>
  </ng-template>

  <ng-template #disconnected>
    <app-source-connection-invitation [source]="source" (connect)="connect.emit()"></app-source-connection-invitation>
  </ng-template>
</div>
