import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { environment } from '@env/environment';

import { BodyClasses } from './body-classes';

@Injectable()
export class WindowService {
  sessionUrl: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private popupWindow$: BehaviorSubject<any>;

  constructor(
    private _localeService: LocaleService,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.popupWindow$ = new BehaviorSubject<any>(null);
    this.sessionUrl = `${this._localeService.getLocale().baseUrl}/session`;
  }

  addBodyClasses(names: BodyClasses[] | string[]): void {
    this._document.body.classList.add(...names);
  }

  removeBodyClasses(names: BodyClasses[]): void {
    this._document.body.classList.remove(...names);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  redirect(url) {
    window.location.href = url;
  }

  openPopup(url: string, title: string): Window {
    const popupOptions = `directories=0, location=0, menubar=0, status=0, toolbar=0, personalbar=0, resizable=1`;

    const popupPosition = this.calculatePopupPosition();

    const newWindow = environment.production
      ? window.open(
          url,
          title,
          `${popupOptions}, [width]=${popupPosition.width}, height=${popupPosition.height}, left=${popupPosition.left}, top=${popupPosition.top}`
        )
      : window.open(url, title);

    this.popupWindow$.next(newWindow);

    // Puts focus on the newWindow
    // @ts-expect-error TS2774
    if (window.focus) {
      // @ts-expect-error TS2531
      newWindow.focus();
    }

    // @ts-expect-error TS2322
    return newWindow;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restoreSessionPopupIfExists(): any {
    const currentPopupWinRef = this.popupWindow$.getValue();

    let restoredSessionPopup = currentPopupWinRef && currentPopupWinRef.name === 'session' ? currentPopupWinRef : null;
    if (!restoredSessionPopup) {
      restoredSessionPopup = window.open('', 'session');

      if (restoredSessionPopup) {
        this.popupWindow$.next(restoredSessionPopup);

        if (restoredSessionPopup.focus) {
          restoredSessionPopup.focus();
        }
      }
    }

    return restoredSessionPopup;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  restorePopupDescriptor(d) {
    this.popupWindow$.next(d);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeSessionPopup(): any {
    this.popupWindow$.pipe(take(1)).subscribe(windowRef => {
      if (windowRef && !windowRef.closed) {
        windowRef.close();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  popupWindow() {
    return this.popupWindow$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  calculatePopupPosition(defaultScreenWidth = 1400, defaultScreenHeight = 900) {
    let width, height, left, top;
    // Fixes dual-screen position                         Most browsers      Firefox
    let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    // const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    if (dualScreenLeft < 1024) {
      dualScreenLeft = 0;
    }

    const screenWidth = window.screen.availWidth || window.screen.width;
    const screenHeight = window.screen.availHeight || window.screen.height;

    if (screenWidth > defaultScreenWidth) {
      width = Math.min(Math.round(screenWidth * 0.9), defaultScreenWidth);
      height = Math.min(Math.round(screenHeight * 0.9), defaultScreenHeight);
    } else {
      width = screenWidth;
      height = screenHeight;
    }

    // eslint-disable-next-line prefer-const
    left = screenWidth / 2 - width / 2 + dualScreenLeft;
    // eslint-disable-next-line prefer-const
    top = screenHeight / 2 - height / 2; // + dualScreenTop;

    return {
      left,
      top,
      width,
      height
    };
  }
}
