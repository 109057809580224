import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { ITimeSlot, SCHEDULE_PROVIDER } from '@app/modules/schedule-boards';
import { IScheduleOptions } from '@app/modules/ui-kit/schedule';
import { IPublicGuideScheduleOptions, PublicGuideScheduleProviderService } from '../../services';

@Component({
  selector: 'app-public-guide-schedule',
  templateUrl: './public-guide-schedule.component.html',
  styleUrls: ['./public-guide-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SCHEDULE_PROVIDER, useClass: PublicGuideScheduleProviderService }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'public-guide-schedule'
  }
})
export class PublicGuideScheduleComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scheduleOptions: IScheduleOptions | null;

  @Input()
  set options(value: IPublicGuideScheduleOptions) {
    this._scheduleOptions = {
      duration: value.duration,
      date: value?.currentDate?.date ? value.currentDate.date : DateTime.local().toISO(),
      timezone: DateTime.local().zoneName,
      excludeDateTime: value.currentDate && value.currentDate.date
    };

    this._scheduleProvider.refresh({
      guideId: value.guideId,
      duration: value.duration,
      exEvent: value.exEvent,
      currentDate: value.currentDate,
      workspaceId: value.workspaceId
    });
  }

  @Output()
  timeSelect = new EventEmitter<ITimeSlot>();

  get scheduleOptions(): IScheduleOptions | null {
    return this._scheduleOptions;
  }

  constructor(
    @Inject(SCHEDULE_PROVIDER)
    private readonly _scheduleProvider: PublicGuideScheduleProviderService
  ) {}
}
