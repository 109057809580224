import { Component, forwardRef, Input, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// @ts-expect-error TS7005
declare const SignaturePad;

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-quiz-signature-answer',
  templateUrl: './quiz-signature-answer.component.html',
  styleUrls: ['./quiz-signature-answer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuizSignatureAnswerComponent),
      multi: true
    }
  ]
})
export class QuizSignatureAnswerComponent implements ControlValueAccessor, OnDestroy, OnInit, AfterViewInit {
  private readonly destroy$ = new Subject<void>();

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isDisabled: boolean;

  @Input()
  // @ts-expect-error TS2564
  key: number | null;

  @ViewChild('canvas', { static: false })
  // @ts-expect-error TS2564
  canvas: ElementRef;

  form = this._formBuilder.group({
    id: [null],
    question: [null],
    type: [null],
    answer: this._formBuilder.group({ text: [null] })
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signaturePad: any = {};

  signatureImage = '';

  get answerText(): string | null {
    return (this.form.value.answer && this.form.getRawValue().answer.text) || '';
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  get question(): string {
    return this.form.getRawValue().question || '';
  }

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      minDistance: 0,
      onEnd: () => {
        // @ts-expect-error TS2531
        this.form.controls.answer.get('text').setValue(this.signaturePad.toDataURL());
      }
    });

    if (this.answerText) {
      this.signaturePad.fromDataURL(this.answerText);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  // @ts-expect-error TS7006
  writeValue(question): void {
    this.form.patchValue(question, { emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clear() {
    if (this.isDisabled) {
      return;
    }

    this.signaturePad.clear();
    // @ts-expect-error TS2531
    this.form.controls.answer.get('text').setValue(null);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  undo() {
    if (this.isDisabled) {
      return;
    }

    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private onChange = (_: any) => {};

  private onTouched: () => void = () => {};
}
