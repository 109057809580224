<a class="guide-card" [routerLink]="'/' + guide.namedUrl">
  <div class="header">
    <div
      [attr.data-qa-id]="'guide-card-guide-photo-image-' + guide.id"
      class="photo"
      [style.backgroundImage]="guide.fullPhoto | safe: 'styleUrl'">
      <div class="online-indicator" *ngIf="guide.online === true" [ngClass]="{ online: guide.online === true }"></div>
    </div>
    <div class="photo-profile" [style.backgroundImage]="guide.photo | safe: 'styleUrl'"></div>
  </div>

  <div class="main-info">
    <div class="name-and-rating">
      <div [attr.data-qa-id]="'guide-card-guide-username-title-' + guide.id" class="name">
        {{ guide.firstName }} {{ guide.lastName }}
      </div>
      <a
        *ngIf="!config.hideCoachReviews && guide.reviewsCount"
        class="reviews"
        [routerLink]="['/', guide.namedUrl]"
        fragment="reviews">
        <star-rating
          *ngIf="guide.rateByFeedbacks"
          [starType]="'svg'"
          [rating]="guide.rateByFeedbacks || 0"
          readOnly="true"></star-rating>
        <span>
          {{ guide.reviewsCount || 0 }}&ngsp;
          <ng-container [ngPlural]="guide.reviewsCount">
            <ng-template i18n="@@review:one" ngPluralCase="=1">review</ng-template>
            <ng-template i18n="@@review:many" ngPluralCase="other">reviews</ng-template>
          </ng-container>
        </span>
      </a>
    </div>
    <div *ngIf="guide.priceStartsFrom && config.showPriceStartsFrom" class="price-from">
      <span class="price-from__label" i18n="@@from">From</span>
      <span class="price-from__value">{{ currencySign }}{{ guide.priceStartsFrom }}</span>
    </div>
  </div>

  <div [attr.data-qa-id]="'guide-card-about-text-' + guide.id" class="about">
    {{ guide.shortQuote || guide.salesText }}
  </div>

  <div class="preference">
    <h5 i18n="@@focusAreas">Focus areas</h5>
    <div class="tags">
      <ng-container *ngFor="let tag of visibleTags">
        <a
          class="tag"
          [routerLink]="[GUIDES_LIST_ROUTE, { tags: tag.name }]"
          (click)="stop($event)"
          [attr.data-qa-id]="'guide-card-tag-name-label-' + tag.name + '-' + guide.id"
          [attr.title]="tag.name"
          [ngbTooltip]="tag.description"
          [ngClass]="{ promo: tag.isPromo, selected: tag.isSelected }">
          {{ tag.name }}
        </a>
      </ng-container>
      <span class="and-more" *ngIf="guide.tags && guide.tags.length > 5">
        <ng-container i18n="@@and">and</ng-container>
        {{ guide.tags.length - 5 }}
        <ng-container i18n="@@more">more</ng-container>
      </span>
    </div>
  </div>

  <div class="divider-wrapper">
    <div class="divider"></div>
  </div>

  <div class="specialization">
    <div class="row">
      <div class="col-9">
        <h5 i18n="@@specialization">Specialty</h5>
        <div [attr.data-qa-id]="'guide-card-specialization-title-' + guide.id">{{ guide.specialization }}</div>
      </div>
      <div class="col-3 therapist-badge-wrapper">
        <div class="qualification-badge" *ngIf="guide.qualification">
          <div [ngClass]="{ 'long-text': badge.length > 9 }">{{ badge }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="divider-wrapper">
    <div class="divider"></div>
  </div>

  <div class="footer" *ngIf="!config.hideProfileLocationLanguage">
    <div class="location" [attr.data-qa-id]="'guide-card-timezone-title-' + guide.id">
      {{ guide.timezone.replace('_', ' ') }}
    </div>
    <div class="zone-offset">{{ tzOffset() > 0 ? '+' + tzOffset() : tzOffset() }}h</div>
  </div>
</a>

<ng-template #discountTooltip>
  <ng-container i18n="@@discountReason">You are seeing this discount as you've applied</ng-container>
  &nbsp;"{{ guide?.discount?.name }}"&nbsp;
  <ng-container i18n="@@guideCardInviteCode">invite code</ng-container>
  .
</ng-template>
