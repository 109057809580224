import { ClientSessionAttributesEnum } from '../enums';
import { ClientSessionAttribute, ClientSessionAttributeOptions } from '../types';
import { CLIENT_SESSION_ATTRIBUTE_FACTORIES } from '../constants';

export class ClientSessionAttributesFactory {
  static readonly CLIENT_ATTRIBUTES = [
    ClientSessionAttributesEnum.TIME,
    ClientSessionAttributesEnum.GUIDE,
    ClientSessionAttributesEnum.CONNECTION_TYPE,
    ClientSessionAttributesEnum.RECURRING,
    ClientSessionAttributesEnum.GUESTS
  ];

  static getAttributes(options: ClientSessionAttributeOptions): ClientSessionAttribute[] {
    return ClientSessionAttributesFactory.CLIENT_ATTRIBUTES.map(
      attributeName => CLIENT_SESSION_ATTRIBUTE_FACTORIES[attributeName]
    )
      .filter(attributeFactory => !!attributeFactory)
      .map(attributeFactory => attributeFactory(options))
      .filter(attribute => !!attribute) as ClientSessionAttribute[];
  }
}
