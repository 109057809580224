import { QuizProgressQuestion } from './quiz-progress-question';
import { QuizStatuses } from './quiz-statuses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizProgressDetails {
  readonly date?: string;
  readonly questions: QuizProgressQuestion[];
  readonly status: QuizStatuses;
}

export class QuizProgress {
  readonly date?: string;
  readonly questions: QuizProgressQuestion[];
  readonly status: QuizStatuses;

  constructor(quizDetails: IQuizProgressDetails) {
    if (!quizDetails) {
      throw new Error('Invalid argument');
    }

    this.questions = quizDetails.questions;
    this.status = quizDetails.status;

    if (quizDetails.date) {
      this.date = quizDetails.date;
    }
  }
}
