import { Injectable } from '@angular/core';
import type * as launchdarkly from 'launchdarkly-js-client-sdk';
import { from, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FeatureFlagConfig } from '../consts';
import { FeatureFlagsUser } from '../interfaces';

const LD_ANONYMOUS_USER: launchdarkly.LDUser = {
  key: 'anonymous',
  name: 'anonymous',
  anonymous: true
};

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  client!: launchdarkly.LDClient;

  constructor(private featureFlagConfig: FeatureFlagConfig) {}

  init$(user?: FeatureFlagsUser | null, customKeys?: FeatureFlagsUser['custom']): Observable<void> {
    if (this.client) {
      console.warn('WARNING! This call is prevented FeatureFlagService.client already initialized!');

      return of(undefined);
    }

    const id = this.featureFlagConfig.launchdarklyId;
    const initialUser = user ? user : LD_ANONYMOUS_USER;

    return this.loadLaunchdarklyLib$().pipe(
      tap(launchdarklyLib => {
        this.client = launchdarklyLib.initialize(id, { ...initialUser, ...(customKeys && { custom: customKeys }) });
      }),
      switchMap(() => this.client.waitForInitialization())
    );
  }

  identify$(
    newUser?: FeatureFlagsUser | null,
    customKeys?: FeatureFlagsUser['custom']
  ): Observable<launchdarkly.LDFlagSet | null> {
    if (!this.client) {
      console.warn('WARNING! FeatureFlagService.client not initialized!');

      return of(null);
    }

    const identifiedUser = newUser ? { ...newUser, ...(customKeys && { custom: customKeys }) } : LD_ANONYMOUS_USER;

    // @ts-expect-error TS2345
    return from(this.client.identify(identifiedUser, null));
  }

  // turn into Observable and add waitForInitialization if you have problems with race conditions after identify$
  hasFeature(name: string): boolean | undefined {
    if (!this.client) {
      console.warn('WARNING! FeatureFlagService.client not initialized!');

      return false;
    }

    return this.client.variation(name, false);
  }

  private loadLaunchdarklyLib$(): Observable<typeof launchdarkly> {
    return from(import(/* webpackChunkName: 'launchdarkly' */ 'launchdarkly-js-client-sdk'));
  }
}
