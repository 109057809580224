import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { makeUriFromString } from '@app/screens/blog/utils';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';
import { take, takeUntil } from 'rxjs/operators';
import { ProgramAssigneesService } from '@app/screens/guide/guide-programs/services/program-assignees.service';
import { Subject } from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ServiceProvidersService } from '@appWidget/screens/book-session/services/service-providers';
import { IProgramModule, IPublicProgram } from '@app/modules/program/types';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { ClientProgramsService } from '@app/modules/client-programs/services/client-programs.service';
import { PublicProgramsService } from '../../services/public-programs.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PublicAssignee } from '@app/modules/services/interfaces';
import { LOCATION_ORIGIN } from '../../../../../consts';

@Component({
  selector: 'app-program-landing-template',
  templateUrl: './program-landing-template.component.html',
  styleUrls: ['./program-landing-template.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-program-landing-template'
  },
  providers: [PublicProgramsService, ProgramAssigneesService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramLandingTemplateComponent implements OnInit, OnChanges, OnDestroy {
  @Input('program') program!: IPublicProgram;

  @Input() isPreview = false;

  @Output() enroll: EventEmitter<void> = new EventEmitter();

  assignees: PublicAssignee[] = [];

  get today(): Date {
    return new Date();
  }

  private readonly destroy$ = new Subject<void>();

  readonly GuideServiceTypes = GuideServiceTypes;

  readonly ModuleTypes = ModuleTypes;

  showMoreModules = true;

  lessModulesCount = 5;

  get price(): string {
    const { program } = this;

    return (
      this.platformStyle.transformCurrency(program.price ? program.price : program.subscriptionPrice) +
      (!program.price && program.subscriptionRecurrency ? `/${program.subscriptionRecurrency}` : '')
    );
  }

  get link(): string {
    if (!this.program || !this.program.id) {
      return '';
    }

    return `${LOCATION_ORIGIN}/programs/${makeUriFromString(this.program.name, this.program.id)}`;
  }

  get programEnrolledDateCaption(): string {
    return `After enrollment`;
  }

  get programStartDate(): string | Date {
    if (this.program.startType === 'user_enrollment') {
      return this.program.enrolledAt || this.today;
    }
    return this.program.startDate;
  }

  get modules(): IProgramModule[] {
    if (!this.program?.modules?.length) {
      return [];
    }

    return this.program.modules
      .filter(module => module.title)
      .map(module => {
        return {
          ...module,
          title: this.domSanitizer.bypassSecurityTrustHtml(module.title) as string
        };
      });
  }

  constructor(
    private platformStyle: PlatformStyle,
    private programAssigneesService: ProgramAssigneesService,
    private clientProgramsService: ClientProgramsService,
    private readonly providers: ServiceProvidersService,
    private readonly cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.clientProgramsService.markNotificationRead(this.program.id);
    this.clientProgramsService.programs$.pipe(take(1), takeUntil(this.destroy$)).subscribe(programs => {
      const programInfo = programs.find(program => program.id === this.program.id);

      if (programInfo) {
        this.program.enrolled = programInfo.enrolled || false;
        this.program.enrolledAt = programInfo.enrolledAt || null;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.program) {
      this.providers
        .getServiceProviders({ id: changes.program.currentValue.id, type: GuideServiceTypes.PROGRAM })
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(assignees => {
          this.assignees = assignees as unknown as PublicAssignee[];
          this.cdr.markForCheck();
        });
    }
  }

  handleEnroll(): void {
    if (!this.program.isAlreadyEnrolled && this.program.subscriptionDeactivated) {
      return;
    }

    this.enroll.emit();
  }

  onClickToggleVisibleModules(): void {
    this.showMoreModules = !this.showMoreModules;
  }

  trackModulesByTitle(index: number, module: IProgramModule): string {
    return module.title;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
