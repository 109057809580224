import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { AfterViewChecked, Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { BrandingService } from '@app/core/branding/branding.service';
import config from '@app/core/config/config';
import { LocaleService } from '@app/core/locale/locale.service';
import { FindYourGuideService } from '@app/core/public/find-your-guide.service';
import { GuidesWikiService } from '@app/core/public/guides-wiki.service';
import { HelpMeFindAGuideCommunicatorService } from '@app/core/public/help-me-find-a-guide-communicator.service';
import { SearchStringService } from '@app/core/public/search-string.service';
import { OnlineStatusService } from '@app/core/status/online-status.service';
import { GlobalConfig } from '@cnf/types';
import { environment } from '@env/environment';
import { ILocale } from '@env/locale.interface';
import { MetaTagService } from '@libs/services/meta-tag/meta-tag.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

// import { locale } from '@env/locale';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuidesFilter {
  search?: string;
  sort?: string;
  free?: boolean;
  sortOrder?: string;
  limit?: number;
  offset?: number;
  languages?: string[];
  isoLanguages?: string[];
  timezones?: string[];
  noUpdateLanguages?: boolean;
  availableNow?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IGuidesFilterSort {
  sort: GuideFilterSortOptions;
  sortOrder: string;
}

export enum GuideFilterSortOptions {
  DEFAULT = 'default',
  NAME = 'name',
  COST = 'cost',
  RATE = 'rate'
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-find-your-guide-home',
  templateUrl: './find-your-guide-home.component.html',
  styleUrls: ['./find-your-guide-home.component.scss']
})
export class FindYourGuideHomeComponent implements OnInit, AfterViewChecked, OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentFilter: IGuidesFilter;
  private destroy$ = new Subject();
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _onlineStatusesSubscription: Subscription;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _scrollPosition: [number, number];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _showAvailableNow = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _locale: ILocale;

  readonly isBrowser;

  // @ts-expect-error TS7008
  activeTags;
  currentSort: IGuidesFilterSort;
  // @ts-expect-error TS7008
  guides;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  languages: any[];
  // @ts-expect-error TS2564
  languagesForm: FormGroup;
  sortOptions = GuideFilterSortOptions;
  totalCount: number;
  eventFromMobileSearch = false;
  // @ts-expect-error TS7008
  wiki;
  searchControl: FormControl;

  config: {
    defaultLanguages: string | null;
    hideGiftCertificate: boolean;
    hideNavigatorLanguages: boolean;
    hideAddLanguages: boolean;
    showPriceStartsFrom: boolean;
    hideCoachReviews: boolean;
  } = {
    defaultLanguages: null,
    hideGiftCertificate: false,
    hideNavigatorLanguages: false,
    hideAddLanguages: false,
    showPriceStartsFrom: false,
    hideCoachReviews: true
  };

  get showAvailableNow(): boolean {
    return this._showAvailableNow;
  }

  readonly tags$ = this._findYourGuide.getDropdownTags$();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _viewportScroller: ViewportScroller,
    private _findYourGuide: FindYourGuideService,
    private _onlineStatuses: OnlineStatusService,
    private _searchStringService: SearchStringService,
    private _helpFindAGuide: HelpMeFindAGuideCommunicatorService,
    private _guidesWiki: GuidesWikiService,
    private _analyticsService: AnalyticsService,
    private _formBuilder: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platformId: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(REQUEST) private req: any,
    private title: Title,
    private brandingService: BrandingService,
    private _localeService: LocaleService,
    private metaTagService: MetaTagService
  ) {
    this.totalCount = 0;
    // @ts-expect-error TS2322
    this.currentSort = { sort: GuideFilterSortOptions.DEFAULT, sortOrder: null };
    this.isBrowser = isPlatformBrowser(platformId);
    this.searchControl = this._formBuilder.control([]);
    this._router.events
      .pipe(
        // eslint-disable-next-line id-length
        filter(e => e instanceof Scroll),
        takeUntil(this.destroy$)
      )
      // eslint-disable-next-line id-length
      .subscribe(e => this.onRouterScrollEvent(e));
    this._locale = this._localeService.getLocale();
  }

  ngOnInit(): void {
    this.guides = undefined;
    this.languagesForm = this._formBuilder.group({ languages: [] });
    this._currentFilter = { isoLanguages: [] };

    this.subscribeOnGlobalSearchUpdates();
    this.subscribeOnLanguagesFilterUpdates();

    this._analyticsService.event(InternalEvents.GUIDES_LIST);

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.searchControl.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(() => {
      this.eventFromMobileSearch = true;
      this.updateCurrentFilter({ search: this.searchControl.value });
    });

    this.brandingService.globalConfig$.pipe(take(1), takeUntil(this.destroy$)).subscribe(config => {
      this.setConfig(config);
      this.subscribeOnRouteUpdates();
    });
  }

  ngAfterViewChecked(): void {
    if (this.guides && this._scrollPosition) {
      const scrollPosition = this._scrollPosition;
      // @ts-expect-error TS2322
      this._scrollPosition = null;
      this._viewportScroller.scrollToPosition(scrollPosition);
    }
  }

  ngOnDestroy(): void {
    this._searchStringService.updateRouteSearchValue('');

    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleAvailableNow(): void {
    this._showAvailableNow = !this._showAvailableNow;
    this.updateCurrentFilter({ availableNow: this._showAvailableNow ? true : null });
  }

  updateSort(sort: GuideFilterSortOptions): void {
    if (sort !== this.currentSort.sort) {
      this.currentSort = { sort, sortOrder: 'asc' };
      this.updateCurrentFilter(this.currentSort);
    } else if (this.currentSort.sortOrder === 'asc') {
      this.currentSort.sortOrder = 'desc';
      this.updateCurrentFilter({ sortOrder: 'desc' });
    } else if (this.currentSort.sortOrder === 'desc') {
      // @ts-expect-error TS2322
      this.currentSort = { sort: GuideFilterSortOptions.DEFAULT, sortOrder: null };
      this.updateCurrentFilter({ sort: null, sortOrder: null });
    }
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private arrangeGuideTags(guideTags) {
    const activeGuideTags = [];

    const regularGuideTags = [...guideTags];
    for (let i = 0, activeTagsLen = this.activeTags.length; i < activeTagsLen; i++) {
      for (let j = 0, regularGuideTagsLen = regularGuideTags.length; j < regularGuideTagsLen; j++) {
        if (this.activeTags[i]?.toLowerCase() === regularGuideTags[j].name?.toLowerCase()) {
          regularGuideTags[j].isSelected = 1;
          activeGuideTags.push(regularGuideTags[j]);
          regularGuideTags.splice(j, 1);
          break;
        }
      }
    }

    return activeGuideTags.concat(regularGuideTags);
  }

  private filterGuidesAndSubscribeForOnlineStatuses(guidesFilter: IGuidesFilter, languageChanged = false): void {
    if (this._onlineStatusesSubscription) {
      this._onlineStatusesSubscription.unsubscribe();
    }

    this._onlineStatusesSubscription = this._findYourGuide
      .getProfiles(guidesFilter)
      .pipe(
        tap(({ _, publicProfiles: guides, languages }) => {
          this.setGuides(guides);

          // TODO: refactor
          if (!languageChanged) {
            this.languages = languages;
            // @ts-expect-error TS2531
            this.languagesForm.get('languages').setValue(
              // @ts-expect-error TS7006
              languages.filter(lang => lang.selected).map(lang => lang.id),
              { emitEvent: false }
            );
          }

          this.eventFromMobileSearch = false;
        }),
        // @ts-expect-error TS7006
        map(data => data.publicProfiles.map(profile => profile.id)),
        mergeMap(guides => this.getOnlineStatuses$(guides)),
        takeUntil(this.destroy$)
      )
      .subscribe(statuses => this.updateGuidesIfOnlineChanged(statuses));

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._guidesWiki.getGuideWiki$(guidesFilter).subscribe(wiki => (this.wiki = wiki));
  }

  private getIsoLanguages(): string[] {
    let languages;

    if (this.isBrowser) {
      // @ts-expect-error TS7053
      const userLanguages = navigator.languages || navigator['userLanguage'];
      languages =
        typeof navigator.languages === 'object'
          ? userLanguages.map(lang => lang.split('-').shift())
          : // @ts-expect-error TS2339
            userLanguages.split('-').shift();
    } else {
      const userLanguages = this.req.headers['accept-language'];
      // @ts-expect-error TS7006
      languages = userLanguages.split(',').map(lang => lang.split('-').shift().split(';').shift());
    }

    return Array.from(new Set(languages));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getOnlineStatuses$(guideIds: number[]): Observable<any> {
    return this._onlineStatuses.getOnlineStatuses$(guideIds, config.pingOnlineInterval);
  }

  private mapSelectedLanguagesIdsToLanguages(selectedLanguagesIds: number[]): string[] {
    return this.languages
      .filter(language => selectedLanguagesIds.includes(language.id))
      .map(language => language.iso.toLowerCase());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onRouterScrollEvent(event: any): void {
    const { position } = event;
    if (position) {
      this._scrollPosition = position;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setGuides(guides: any[]): void {
    if (!this.activeTags || !this.activeTags.length) {
      this.guides = guides;
      this.totalCount = this.guides.length;
      return;
    }

    this.guides = guides.map(guide => {
      if (guide.tags && guide.tags.length) {
        guide.tags = this.arrangeGuideTags(guide.tags);
      }

      return guide;
    });

    this.guides = guides.filter(guide => {
      for (let i = 0; i < guide.tags.length; i += 1) {
        // @ts-expect-error TS7006
        if (this.activeTags.some(tag => tag.toLowerCase() === guide.tags[i].name?.toLowerCase())) {
          return true;
        }
      }
    });
    this.totalCount = this.guides.length;
  }

  private subscribeOnGlobalSearchUpdates(): void {
    this._searchStringService.searchInputValue$
      .pipe(takeUntil(this.destroy$))
      .subscribe(search => this._router.navigate([environment.guidesRoute, search]));
  }

  private subscribeOnLanguagesFilterUpdates(): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.languagesForm.valueChanges.pipe(take(1)).subscribe(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      const { isoLanguages, ...newFilterValue }: any = this._currentFilter;
      this._currentFilter = newFilterValue;
    });

    this.languagesForm.valueChanges
      .pipe(
        map(({ languages: selectedLanguages }) => this.mapSelectedLanguagesIdsToLanguages(selectedLanguages)),
        takeUntil(this.destroy$)
      )
      .subscribe(languages => this.updateCurrentFilter({ languages }, true));
  }

  private subscribeOnRouteUpdates(): void {
    combineLatest([this._route.url, this._route.paramMap, this._route.data])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([urlSegments, paramMap, { free }]) => {
        const search = urlSegments[0] && urlSegments[0].path !== 'free' ? urlSegments[0].path : '';
        this.searchControl.setValue(search, { emitEvent: false });

        const tagsString = paramMap.get('tags');
        this.activeTags = tagsString ? tagsString.split(',') : null;

        this.updateCurrentFilter({ search, tags: this.activeTags, free });
        this._searchStringService.updateRouteSearchValue(search);

        if (search !== '') {
          this._analyticsService.event(InternalEvents.SEARCH, { searchTerm: search });
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateCurrentFilter(patch: any, languageChanged = false): void {
    const newFilterValue = { ...this._currentFilter };

    Object.keys(patch).forEach(filterProp => {
      if (!patch[filterProp]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete newFilterValue[filterProp];
      } else {
        // @ts-expect-error TS7053
        newFilterValue[filterProp] = patch[filterProp];
      }
    });

    this._currentFilter = newFilterValue;

    this.filterGuidesAndSubscribeForOnlineStatuses(newFilterValue, languageChanged);
  }

  // @ts-expect-error TS7006
  private updateGuidesIfOnlineChanged(statuses): void {
    // @ts-expect-error TS7006
    this.guides.forEach(guide => {
      if (guide.online !== statuses[guide.id]) {
        guide.online = statuses[guide.id];
      }
    });
  }

  private setConfig(config: GlobalConfig): void {
    this.config.defaultLanguages = config.defaultLanguages;
    this.config.hideGiftCertificate = config.hideGiftCertificate;
    this.config.hideNavigatorLanguages = config.hideNavigatorLanguages;
    this.config.hideAddLanguages = config.hideAddLanguages;
    this.config.showPriceStartsFrom = config.showPriceStartsFrom;
    this.config.hideCoachReviews = config.hideCoachReviews;

    this.setOGMeta(config);

    const defaultLanguagesArray = config.defaultLanguages.split(',').map((lang: string) => lang.toLowerCase().trim());

    this._currentFilter = {
      isoLanguages: config.hideNavigatorLanguages
        ? defaultLanguagesArray
        : this.getIsoLanguages().concat(defaultLanguagesArray)
    };
    this.updateCurrentFilter(this._currentFilter);
  }

  private setOGMeta({
    metaKeywordsGuidesPage,
    metaTitleGuidesPage,
    metaDescriptionGuidesPage,
    metaImageGuidesPage
  }: GlobalConfig): void {
    this.title.setTitle(metaTitleGuidesPage);

    this.metaTagService.upsertMetaTags({
      title: metaTitleGuidesPage,
      description: metaDescriptionGuidesPage,
      image: metaImageGuidesPage,
      url: `${this._locale.baseUrl}${this._router.url}`,
      type: 'website',
      keywords: metaKeywordsGuidesPage
    });
  }
}
