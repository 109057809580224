import { WithNamedUrl } from '@app/base';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { User } from '../user';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServiceGuide extends Partial<WithNamedUrl> {
  id: number;
  firstName: string | null;
  lastName: string | null;
  photo: string | null;
  fullPhoto: string | null;
  specialization: string | null;
  shortQuote: string | null;
  workspaceId: number;
  publicLink: string | null;
}

export class ServiceGuide extends User {
  specialization: string | null;

  shortQuote: string | null;

  namedUrl: string | null;

  fullPhoto: string | null;

  workspaceId: number;

  directChatLink: string;

  publicLink: string | null;

  constructor(serviceGuide: Readonly<IServiceGuide>) {
    const { id, firstName, lastName, photo, fullPhoto, namedUrl, specialization, shortQuote, workspaceId, publicLink } =
      serviceGuide;

    // @ts-expect-error TS2345
    super(id, firstName, lastName, photo);

    // @ts-expect-error TS2322
    this.namedUrl = namedUrl;
    this.specialization = specialization;
    this.shortQuote = shortQuote;
    this.fullPhoto = fullPhoto;
    this.workspaceId = workspaceId;
    this.publicLink = publicLink;

    this.directChatLink = buildDirectChatLinkId({ id, workspaceId });
  }
}
