<div class="modal-header">
  <app-button className="close close-confirm-popup" (click)="dismiss()"></app-button>
</div>

<div class="modal-body">
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="wrapper">
        <ng-container *ngIf="this.shouldSwitchToGuide()">
          <h2 transloco="switchToAlternativeAccountModalClientModeHeader" pui-typography="h2">
            Switch to a practitioner mode?
          </h2>
          <p
            transloco="switchToAlternativeAccountModalClientModeBody"
            pui-typography="body-m"
            class="switch-modal-body-text">
            To work with other clients, you need to switch to Practitioner mode.
            <span class="info" popoverClass="switch-tooltip" [ngbPopover]="popContent" placement="top"></span>
          </p>
        </ng-container>
        <ng-container *ngIf="this.shouldSwitchToClient()">
          <h2 i18n="switchToAlternativeAccountModalPractitionerModeHeader" pui-typography="h2">
            Switch to a client mode?
          </h2>
          <p
            transloco="switchToAlternativeAccountModalPractitionerModeBodyText"
            pui-typography="body-m"
            class="switch-modal-body-text">
            To book a session with other practitioners please switch to a client mode.
            <span
              class="info"
              popoverClass="switch-tooltip"
              [ngbPopover]="popContent"
              triggers="mouseenter:mouseleave"
              placement="top"></span>
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="switch-modal-row">
    <app-button className="btn-primary btn-switch-confirm" (click)="switchAccount()" i18n="switch">Switch</app-button>

    <ng-container *ngIf="withLogoutButton; else cancelButton">
      <app-button className="btn-primary btn-switch-confirm cancel" (click)="logout()" i18n="logout">
        Logout
      </app-button>
    </ng-container>

    <ng-template #cancelButton>
      <app-button className="btn-primary btn-switch-confirm cancel" (click)="dismiss()" i18n="cancel">
        Cancel
      </app-button>
    </ng-template>
  </div>
</div>

<ng-template #popContent>
  <img class="popover-image" src="/assets/images/switch_tooltip.png" alt="User role switcher" />
  <p class="popover-text" transloco="switchToAlternativeAccountModalPopoverTooltip" pui-typography="body-s">
    You can always switch back to the practitioner mode at the bottom of left-hand menu
  </p>
</ng-template>
