import { AnalyticEvent } from './analytic.event';
import { InternalEvents } from '@app/core/analytics/types';

export class SessionBookedEvent extends AnalyticEvent {
  constructor() {
    super(InternalEvents.SESSION_BOOKED, {
      event_id: 'undefined',
      is_recurrent: 'undefined',
      is_paid: 'undefined',
      type: 'undefined',
      guest: 'undefined',
      seats: 'undefined',
      auto_confirmed_guide: 'undefined',
      location: 'undefined',
      session_service: 'undefined',
      ws_type: 'undefined',
      ws_id: 'undefined',
      ws_name: 'undefined',
      source: 'undefined',
      session_template_id: 'undefined'
    });
  }
}
