import { Subject } from 'rxjs';
import { TextEditorImage, TextEditorImageUploadRequest } from '../interfaces';

export class TextEditorImageUploadDriver {
  uploadRequests$ = new Subject<TextEditorImageUploadRequest>();
  fileUrls$ = new Subject<TextEditorImage>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  uploadFile(id: string, file: File) {
    this.uploadRequests$.next({ id, file });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  passUrl(id: string, url: string) {
    this.fileUrls$.next({ id, url });
  }
}
