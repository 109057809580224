import { StepsComponent } from './steps.component';
import { NgModule } from '@angular/core';
import { StepComponent } from './step/step.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [StepsComponent, StepComponent],
  exports: [StepsComponent, StepComponent]
})
export class StepsModule {}
