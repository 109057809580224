import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { puiSortFunction } from '@awarenow/profi-ui-core';

export class ContactViewModel implements ContactViewModel {
  /**
   * All string or integer properties can be filterable
   */
  readonly name: string;
  readonly email: string;
  readonly phone: string;
  readonly organization: string;
  readonly active: boolean;
  readonly archived: boolean;
  readonly isContact: boolean;
  readonly selected: boolean;

  get id(): number {
    return this._data.id;
  }

  get firstName(): string {
    return this._data.firstName;
  }

  get lastName(): string {
    return this._data.lastName;
  }

  get relationId(): number {
    return this._data.relationId;
  }

  get type(): 'guideContact' | 'guideClient' {
    return this._data.type;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get photo() {
    return this._data.photo;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get tag() {
    return this._data.tag || null;
  }

  get tags(): number[] {
    return [];
  }

  get data(): GuideContact {
    return this._data;
  }

  constructor(private _data: GuideContact) {
    this.name = `${this._data.firstName} ${this._data.lastName}`;
    // @ts-expect-error TS2322
    this.phone = this._data.contacts?.phone;
    // @ts-expect-error TS2322
    this.email = this._data.contacts?.email;
    // @ts-expect-error TS2322
    this.organization = this._data.contacts?.organization;
    this.active = !this._data.archived;
    // @ts-expect-error TS2322
    this.archived = this._data.archived;
    this.isContact = this._data.type === GuideRelationTypes.GUIDE_CONTACT;
    this.selected = this._data.selected;
  }
}

@Injectable({ providedIn: 'root' })
export class ClientsApiService {
  constructor(private readonly http: HttpClient, private readonly guideClients: GuideClientsService) {}

  getClients(): Observable<ContactViewModel[]> {
    return this.guideClients.users$.pipe(
      take(1),
      map(data =>
        data
          /**
           * Map to {IContactViewModel}
           */
          .map(data => new ContactViewModel(data))
          /**
           * Sort a list alphabetically order
           */
          // eslint-disable-next-line id-length
          .sort((a, b) => puiSortFunction('asc')(a.name, b.name))
      )
    );
  }

  archive(clientId: ContactViewModel['id']): Observable<void> {
    return this.http.put<void>(`${config.apiPath}/user/guide/relations/contacts/${clientId}`, {
      values: {
        archived: true,
        archivedAt: DateTime.local().toISO()
      }
    });
  }

  archiveContacts(contacts: ContactViewModel[]): Observable<void> {
    return this.http.patch<void>(
      `${config.apiPath}/user/guide/relations/contacts`,
      contacts.map(contact => ({
        id: this.selectId(contact),
        archived: true
      }))
    );
  }

  unarchiveContacts(contacts: ContactViewModel[]): Observable<void> {
    return this.http.patch<void>(
      `${config.apiPath}/user/guide/relations/contacts`,
      contacts.map(contact => ({
        id: this.selectId(contact),
        archived: false
      }))
    );
  }

  unarchive(clientId: ContactViewModel['id']): Observable<void> {
    return this.http.put<void>(`${config.apiPath}/user/guide/relations/contacts/${clientId}`, {
      values: {
        archived: false,
        archivedAt: null
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  stage(clientId: ContactViewModel['id'], tag: string) {
    return this.http.put<void>(`${config.apiPath}/user/guide/relations/contacts/${clientId}`, {
      values: {
        tag
      }
    });
  }

  setClientTags(clientId: ContactViewModel['id'], tags: number[]): Observable<void> {
    return this.http.put<void>(`${config.apiPath}/user/guide/relations/contacts/${clientId}`, {
      values: {
        customFields: {
          client_tags: tags
        }
      }
    });
  }

  bulkUpdate(update: { id: number; [key: string]: unknown }[]): Observable<unknown> {
    return this.http.patch<void>(`${config.apiPath}/user/guide/relations/contacts`, update);
  }

  private selectId(contact: ContactViewModel): number {
    return contact.isContact ? contact.id : contact.relationId;
  }
}
