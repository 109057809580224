import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-html-uploader',
  templateUrl: './html-uploader.component.html',
  styleUrls: ['./html-uploader.component.scss']
})
export class HtmlUploaderComponent implements OnInit {
  // @ts-expect-error TS2564
  form: FormGroup;

  // @ts-expect-error TS2564
  @ViewChild('htmlArea', { static: false }) htmlArea: ElementRef;

  constructor(private _formBuilder: FormBuilder, private _modal: NgbActiveModal, private _renderer: Renderer2) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      html: ['', [Validators.required]],
      width: [''],
      height: ['']
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  save() {
    if (!this.form.valid) {
      // @ts-expect-error TS2531
      if (this.form.get('html').errors.required) {
        this._renderer.setAttribute(this.htmlArea.nativeElement, 'placeholder', 'Content is required');
      }
      return;
    }
    const html = this.form.value;
    this._modal.close(html);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  cancel() {
    this._modal.dismiss();
  }
}
