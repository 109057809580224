<div class="pui-container">
  <div>
    <span pui-typography="h2" i18n="@@programModuleLoadQuizChooseQuiz">Choose form</span>
    <div class="explanation">
      <pui-icon svgIcon="pui:warning"></pui-icon>
      <p class="explanation-text" pui-typography="body-s" i18n="@@programModuleLoadQuizExplanation">
        You can change the chosen form. Changes in form will be saved for this module only and won’t change the original
        form.
      </p>
    </div>
    <div class="form">
      <p class="form-header" pui-typography="h5" i18n="@@programModuleLoadQuizForm">Form</p>
      <!-- TODO Will work after https://profi-io.atlassian.net/browse/PR-5070-->
      <pui-select
        [formControl]="chosenQuizId"
        placeholder="Choose form"
        i18n-placeholder="@@programModuleLoadQuizChooseQuiz">
        <pui-option
          *ngIf="quizzes.length === 0"
          disabled
          label="No forms are available. Start with creating a form."
          i18n-label="@@programModuleLoadQuizNoQuizzes"></pui-option>
        <ng-container *ngIf="quizzes.length > 0">
          <!-- TODO Dirty hack. Fix me pls after https://profi-io.atlassian.net/browse/PR-5069 -->
          <pui-option *ngFor="let quiz of quizzes" [value]="quiz.id + ''" [label]="quiz.title"></pui-option>
        </ng-container>
      </pui-select>
    </div>
  </div>
  <div>
    <button pui-button (click)="uploadQuiz()">Upload</button>
  </div>
</div>
