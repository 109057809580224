import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SessionTemplatesDrawerModule } from '@app/modules/session-forms/drawers/session-templates-drawer/session-templates-drawer.module';
import { BasicInfoFormModule } from '@app/modules/session-forms/forms/basic-info-form/basic-info-form.module';
import { HostsFormModule } from '@app/modules/session-forms/forms/hosts-form/hosts-form.module';
import { LocationFormModule } from '@app/modules/session-forms/forms/location-form/location-form.module';
import { ParticipantsAndGuestsFormModule } from '@app/modules/session-forms/forms/participants-and-guests-form/participants-and-guests-form.module';
import { RestrictionsFormModule } from '@app/modules/session-forms/forms/restrictions-form/restrictions-form.module';
import { SessionAvailabilityFormModule } from '@app/modules/session-forms/forms/session-availability-form/session-availability-form.module';
import { SessionTemplateServerStoreService } from '@app/screens/guide/guide-sessions-templates/services';
import { SharedModule } from '@app/shared';
import { PuiButtonModule, PuiCollapseModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SessionTemplateDrawerRouterModule } from './session-template-drawer-routing.module';
import { SessionTemplateDrawerComponent } from './session-template-drawer.component';

@NgModule({
  imports: [
    CommonModule,
    SessionTemplateDrawerRouterModule,
    ReactiveFormsModule,
    NgbModule,
    BasicInfoFormModule,
    LocationFormModule,
    ParticipantsAndGuestsFormModule,
    RestrictionsFormModule,
    HostsFormModule,
    SessionAvailabilityFormModule,
    SharedModule,
    PuiTypographyModule,
    PuiButtonModule,
    PuiCollapseModule,
    SessionTemplatesDrawerModule
  ],
  declarations: [SessionTemplateDrawerComponent],
  providers: [SessionTemplateServerStoreService],
  exports: [SessionTemplateDrawerComponent]
})
export class SessionTemplateDrawerModule {}
