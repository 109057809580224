import { Injectable } from '@angular/core';
import { PreReschedulingValidationStrategy } from '@app/modules/rescheduling-service/services/pre-rescheduling-validation-strategy';
import { ServiceReschedulingApiService } from '@app/modules/rescheduling-service/services/service-rescheduling-api.service';
import { isOffer, SessionStatuses } from '@app/shared/enums/session-statuses';
import { UserRoles } from '@app/shared/enums/user-roles';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { finalize, mapTo, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';
import { SessionReschedulingModalComponent } from '../components/session-rescheduling-modal/session-rescheduling-modal.component';
import {
  IReschedulingService,
  ISessionReschedulingDetails,
  ISessionReschedulingResponse,
  ReschedulingSessionOptions
} from '../types';

export function isPaymentRequiredBeforeRescheduling(
  sessionStatus: SessionStatuses,
  sessionPrice: number | null
): boolean {
  // @ts-expect-error TS2322
  return sessionPrice && isOffer(sessionStatus);
}

export function buildReschedulingDetails(reschedulingOptions: ReschedulingSessionOptions): ISessionReschedulingDetails {
  return {
    // @ts-expect-error TS2322
    id: reschedulingOptions.id,
    collectionType: reschedulingOptions.collectionType,
    // @ts-expect-error TS2532
    date: reschedulingOptions.newDate.date,
    // @ts-expect-error TS2532
    timezone: reschedulingOptions.newDate.timezone,
    message: reschedulingOptions.message
  };
}

@Injectable({
  providedIn: 'root'
})
export class ServiceReschedulingService
  implements IReschedulingService<ReschedulingSessionOptions, Observable<ISessionReschedulingResponse>>
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _isRescheduling = false;

  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _modalRef;

  constructor(
    protected readonly _preReschedulingValidator: PreReschedulingValidationStrategy,
    protected readonly _api: ServiceReschedulingApiService,
    protected readonly _modal: NgbModal
  ) {}

  reschedule$(options: ReschedulingSessionOptions): Observable<ISessionReschedulingResponse> {
    if (this._isRescheduling) {
      return EMPTY;
    }

    this._isRescheduling = true;

    return this._preReschedulingValidator.validate$(options).pipe(
      switchMap(() => this.setNewDate$(options)),
      switchMap(reschedulingOptions => this.setRescheduleMessage$(reschedulingOptions)),
      switchMap(reschedulingOptions => this.addPaymentInfo$(reschedulingOptions)),
      switchMap(reschedulingOptions => this.makeReschedule$(reschedulingOptions)),
      finalize(() => this.cleanUp())
    );
  }

  protected setNewDate$(reschedulingOptions: ReschedulingSessionOptions): Observable<ReschedulingSessionOptions> {
    if (reschedulingOptions.newDate && reschedulingOptions.newDate.date) {
      return of(reschedulingOptions);
    }
    return this.openRescheduleDetailsModal$(reschedulingOptions);
  }

  protected setRescheduleMessage$(
    reschedulingOptions: ReschedulingSessionOptions
  ): Observable<ReschedulingSessionOptions> {
    if (reschedulingOptions && reschedulingOptions.message) {
      return of(reschedulingOptions);
    }
    return this.openRescheduleDetailsModal$(reschedulingOptions);
  }

  protected openRescheduleDetailsModal$(
    reschedulingOptions: ReschedulingSessionOptions
  ): Observable<ReschedulingSessionOptions> {
    if (!this._modalRef) {
      this._modalRef = this._modal.open(SessionReschedulingModalComponent);
    }

    const onReschedulingOptionsUpdate$: Subject<ReschedulingSessionOptions> = new Subject<ReschedulingSessionOptions>();

    const { componentInstance, result } = this._modalRef;

    componentInstance.reschedulingOptions = reschedulingOptions;
    componentInstance.onReschedulingOptionsUpdate = (reschedulingOptionsUpdate: ReschedulingSessionOptions) => {
      onReschedulingOptionsUpdate$.next(reschedulingOptionsUpdate);
      onReschedulingOptionsUpdate$.complete();
    };

    modalResultToObservable$(result)
      .pipe(finalize(() => onReschedulingOptionsUpdate$.complete()))
      .subscribe();

    return onReschedulingOptionsUpdate$;
  }

  protected addPaymentInfo$(reschedulingOptions: ReschedulingSessionOptions): Observable<ReschedulingSessionOptions> {
    if (
      reschedulingOptions.applicant.role === UserRoles.CLIENT &&
      // @ts-expect-error TS2345
      isPaymentRequiredBeforeRescheduling(reschedulingOptions.status, reschedulingOptions.price)
    ) {
      return this.openRescheduleDetailsModal$(reschedulingOptions);
    }

    return of({ ...reschedulingOptions, isFree: true });
  }

  protected makeReschedule$(reschedulingOptions: ReschedulingSessionOptions): Observable<ISessionReschedulingResponse> {
    const reschedulingDetails = buildReschedulingDetails(reschedulingOptions);

    if (!reschedulingDetails.id) {
      // @ts-expect-error TS2322
      return of({ newDate: reschedulingOptions.newDate });
    }

    const { isFree } = reschedulingOptions;

    // @ts-expect-error TS2322
    return this._api.reschedule$(reschedulingDetails, isFree).pipe(mapTo({ newDate: reschedulingOptions.newDate }));
  }

  protected cleanUp(): void {
    this._isRescheduling = false;

    if (this._modalRef) {
      this._modalRef.close();
      this._modalRef = null;
    }
  }
}
