import { AuthService } from '@app/core/auth/services';
import { Injector } from '@angular/core';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ViewHostCandidateMap } from '@app/modules/sidenav/types';
import { SidenavAdminSchema, SidenavClientSchema, SidenavGuideSchema, SidenavSuperAdminSchema } from '../../schemas';

export const sidenavSCHEMAFactory = (authService: AuthService, injector: Injector): ViewHostCandidateMap => {
  switch (authService.getUserRoleId()) {
    case UserRoles.CLIENT:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(SidenavClientSchema);
    case UserRoles.GUIDE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(SidenavGuideSchema);
    case UserRoles.ADMIN:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(SidenavAdminSchema);
    case UserRoles.SUPER_ADMIN:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(SidenavSuperAdminSchema);
    default:
      // @ts-expect-error TS2322
      return null;
  }
};
