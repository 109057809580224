import { INotification } from '@app/shared/interfaces/notification';

export const ACCEPT_SESSION_ERROR = 'ACCEPT_SESSION_ERROR';
export const ACCEPT_SESSION_OFFER_ERROR = 'ACCEPT_SESSION_OFFER_ERROR';
export const ARCHIVE_SESSION_ERROR = 'ARCHIVE_SESSION_ERROR';
export const DECLINE_SESSION_OFFER_ERROR = 'DECLINE_SESSION_OFFER_ERROR';
export const LOAD_SESSIONS_ERROR = 'LOAD_SESSIONS_ERROR';
export const REFUSE_SESSION_ERROR = 'REFUSE_SESSION_ERROR';
export const START_SESSION_ERROR = 'START_SESSION_ERROR';
export const END_SESSION_ERROR = 'END_SESSION_ERROR';
export const ACCEPT_SESSION_TIME_CHANGE_ERROR = 'ACCEPT_SESSION_TIME_CHANGE_ERROR';
export const RESCHEDULE_APPROVEMENT_ERROR = 'RESCHEDULE_APPROVEMENT_ERROR';
export const RESCHEDULE_ERROR = 'RESCHEDULE_ERROR';

const notificationTitles = {
  [ACCEPT_SESSION_ERROR]: `Accept Session Request Error`,
  [ACCEPT_SESSION_OFFER_ERROR]: `Accept Session Offer Error`,
  [ARCHIVE_SESSION_ERROR]: `Archive Session Error`,
  [DECLINE_SESSION_OFFER_ERROR]: `Decline Session Offer Error`,
  [LOAD_SESSIONS_ERROR]: `Load Sessions Error`,
  [REFUSE_SESSION_ERROR]: `Refuse Session Error`,
  [START_SESSION_ERROR]: `Start Session Error`,
  [END_SESSION_ERROR]: `End Session Error`,
  [ACCEPT_SESSION_TIME_CHANGE_ERROR]: `Accept session date change error`,
  [RESCHEDULE_APPROVEMENT_ERROR]: `Reschedule approvement error`,
  [RESCHEDULE_ERROR]: `Reschedule error`
};

const notificationMessages = {
  [ACCEPT_SESSION_ERROR]: `Error occurred while accepting session`,
  [ACCEPT_SESSION_OFFER_ERROR]: `Error occurred while accepting session offer`,
  [ARCHIVE_SESSION_ERROR]: `Error occurred while archiving session`,
  [DECLINE_SESSION_OFFER_ERROR]: `Error occurred while declining session`,
  [LOAD_SESSIONS_ERROR]: `Error occurred while loading sessions`,
  [REFUSE_SESSION_ERROR]: `Error occurred while refusing session`,
  [START_SESSION_ERROR]: `Error occurred while starting session`,
  [END_SESSION_ERROR]: `Error occurred while ending session`,
  [ACCEPT_SESSION_TIME_CHANGE_ERROR]: `Error occurred while accepting approve date change`,
  [RESCHEDULE_ERROR]: `Error occurred while rescheduling`,
  [RESCHEDULE_APPROVEMENT_ERROR]: `Error occurred while approving reschedulement`
};

export function getNotification(key: string): INotification | null {
  let notification = null;
  // @ts-expect-error TS7053
  if (notificationTitles[key] || notificationMessages[key]) {
    notification = {
      // @ts-expect-error TS7053
      title: notificationTitles[key] || null,
      // @ts-expect-error TS7053
      message: notificationMessages[key] || null
    };
  }
  return notification;
}

export function acceptedEventRequestMessage(name: string, count?: number): string {
  const successMsgFirstPart = `The request for`;
  const countMsg = `${count && count > 1 ? count + ' x ' : ' '}`;
  const nameMsg = `${name}`;
  const successMsgLastPart = `is accepted`;

  const successMsg = `${successMsgFirstPart} ${countMsg}${nameMsg} ${successMsgLastPart}`;
  return successMsg;
}
