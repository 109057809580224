<div class="row">
  <div class="col-md-4">
    <div class="d-none d-md-block section module-selector-section">
      <h2 class="title" data-qa-id="program-modules-title">
        <ng-container i18n="@@programModules">Program modules</ng-container>
        :
      </h2>

      <div
        class="program-modules"
        cdkDropList
        [cdkDropListDisabled]="!editable"
        (cdkDropListDropped)="reorderModules.emit($event)"
        (clickOutside)="hidePopover()">
        <div
          class="module regular-module"
          [class.selected]="activeModule?.localId === module.localId"
          *ngFor="let module of modules.getRawValue(); trackBy: trackById; index as index"
          cdkDrag>
          <a
            [attr.data-qa-id]="'program-modules-module-item-' + module.moduleType + '-' + index"
            class="module-button module-selector"
            (click)="$event.stopPropagation(); selectModule(module)">
            <div class="module-clamp">
              <avatar-group [items]="getAvatarSource(module) | toAvatarGroup: 'name':'photo'" size="m"></avatar-group>
              <strong class="module-index">{{ index + 1 }}.</strong>
              <div
                class="module-title-container"
                *ngIf="module.moduleType === ModuleTypes.CONTENT"
                [innerHTML]="module.title"></div>
              <div
                class="module-title-container"
                *ngIf="module.moduleType === ModuleTypes.QUIZ"
                [innerHTML]="module.title || 'Forms and questions'"
                i18n-[innerHTML]="@@formsModulesModuleTitle"></div>
              <div class="module-title-container" *ngIf="module.moduleType === ModuleTypes.SESSION">
                <ng-template [ngIf]="!module.serviceId" [ngIfElse]="individualSessionModule">
                  <ng-container i18n="@@programModulesIndividualSession">Individual session</ng-container>
                </ng-template>
                <ng-template #individualSessionModule>
                  <ng-container *ngTemplateOutlet="serviceModule; context: { $implicit: module }"></ng-container>
                </ng-template>
              </div>
              <div class="module-title-container" *ngIf="module.moduleType === ModuleTypes.GROUP_SESSION">
                <ng-template [ngIf]="!module.serviceId" [ngIfElse]="groupSessionModule">
                  <ng-container>{{ module | draftSessionTemplateTitle }}</ng-container>
                </ng-template>
                <ng-template #groupSessionModule>
                  <ng-container *ngTemplateOutlet="serviceModule; context: { $implicit: module }"></ng-container>
                </ng-template>
              </div>
            </div>
          </a>

          <button
            class="module-button module-actions"
            (click)="$event.stopPropagation(); selectModule(module, index)"
            *ngIf="editable && modules.length > 1"
            type="button">
            <span class="point"></span>
          </button>

          <div class="module-actions-popover" *ngIf="activePopoverIndex === index && modules.length > 1">
            <a class="action-btn duplicate-btn" (click)="cloneModuleAndSelectIt(index)" i18n="@@duplicate">Duplicate</a>
            <a class="action-btn delete-btn" (click)="removeModuleAndSelectPrevious(index)" i18n="@@delete">Delete</a>
          </div>
        </div>

        <div
          *ngIf="editable"
          (clickOutside)="isModuleTypeSelectorOpen && toggleModuleTypeSelector()"
          class="module new-module btn-group">
          <button
            (click)="toggleModuleTypeSelector()"
            [class.open]="isModuleTypeSelectorOpen"
            data-qa-id="program-modules-add-module-dropdown"
            type="button"
            class="module-button"
            i18n="@@createNewModule">
            Add module
          </button>
          <div class="new-module-menu" *ngIf="isModuleTypeSelectorOpen">
            <button
              data-qa-id="program-modules-add-module-content-dropdown-option"
              class="new-module-type-btn new-content-module-btn"
              (click)="addModuleAndSelectIt(ModuleTypes.CONTENT)">
              <i></i>
              <span i18n="@@newContentModuleBtn">Video or reading materials</span>
            </button>
            <button
              data-qa-id="program-modules-add-module-quiz-dropdown-option"
              class="new-module-type-btn new-quiz-module-btn"
              (click)="addModuleAndSelectIt(ModuleTypes.QUIZ)">
              <i></i>
              <span i18n="@@formsAndQuestionsModuleBtn">Forms and questions</span>
            </button>
            <button
              data-qa-id="program-modules-add-module-session-dropdown-option"
              class="new-module-type-btn new-session-module-btn"
              (click)="addModuleAndSelectIt(ModuleTypes.GROUP_SESSION)">
              <i></i>
              <span i18n="@@newSessionModuleBtn">Session</span>
            </button>
            <button class="new-module-type-btn new-group-session-module-btn disabled"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="modules-mobile-select d-md-none">
      <button type="button" class="module-button module-actions" (click)="toggleMobilePopover()" *ngIf="editable">
        <span class="point"></span>
      </button>

      <div
        class="module-actions-popover"
        *ngIf="activeMobilePopover"
        (clickOutside)="hideMobilePopover()"
        [exclude]="'.modules-mobile-select .module-actions'">
        <a class="action-btn duplicate-btn" (click)="cloneModuleFromMobile()" i18n="@@duplicate">Duplicate</a>
        <a class="action-btn delete-btn" (click)="removeModuleFromMobileView()" i18n="@@delete">Delete</a>
      </div>

      <ng-select
        class="ng-select"
        bindLabel="order"
        [searchable]="false"
        [clearable]="false"
        [items]="modules.value"
        [ngModel]="activeModule"
        (change)="selectModule($event)">
        <ng-template #mobileModuleItemTmpl let-module>
          <strong class="module-index">{{ module.order }}.</strong>
          <div
            class="module-title-label module-title-mobile-selector"
            *ngIf="module.moduleType === ModuleTypes.CONTENT"
            [innerHTML]="module.title || 'Video or reading materials'"
            i18n-[innerHTML]="@@programModulesVideoOrReadingMaterialsTitle"></div>
          <div
            class="module-title-label module-title-mobile-selector"
            *ngIf="module.moduleType === ModuleTypes.QUIZ"
            [innerHTML]="module.title || 'Forms and questions'"
            i18n-[innerHTML]="@@formsAndQuestionsProgramModulesModuleTitle"></div>
          <div
            class="module-title-label module-title-mobile-selector"
            *ngIf="module.moduleType === ModuleTypes.SESSION">
            <ng-template [ngIf]="!module.serviceId" [ngIfElse]="mobileIndividualSessionModule">
              <ng-container i18n="@@programModulesIndividualSession">Individual session</ng-container>
            </ng-template>
            <ng-template #mobileIndividualSessionModule>
              <ng-container *ngTemplateOutlet="serviceModule; context: { $implicit: module }"></ng-container>
            </ng-template>
          </div>
          <div
            class="module-title-label module-title-mobile-selector"
            *ngIf="module.moduleType === ModuleTypes.GROUP_SESSION">
            <ng-template [ngIf]="!module.serviceId" [ngIfElse]="mobileGroupSessionModule">
              <ng-container i18n="@@programModulesGroupSession">Group session</ng-container>
            </ng-template>
            <ng-template #mobileGroupSessionModule>
              <ng-container *ngTemplateOutlet="serviceModule; context: { $implicit: module }"></ng-container>
            </ng-template>
          </div>
        </ng-template>

        <ng-template ng-label-tmp let-module="item">
          <ng-container *ngTemplateOutlet="mobileModuleItemTmpl; context: { $implicit: module }"></ng-container>
        </ng-template>

        <ng-template ng-option-tmp let-module="item">
          <div class="module-option" [class.restricted-module]="module.accessType === ModuleAccessTypes.RESTRICTED">
            <ng-container *ngTemplateOutlet="mobileModuleItemTmpl; context: { $implicit: module }"></ng-container>
          </div>
        </ng-template>

        <ng-template ng-footer-tmp>
          <div class="module new-module" *ngIf="editable">
            <button
              (click)="toggleMobileModuleTypeSelector()"
              (clickOutside)="isMobileModuleTypeSelectorOpen && toggleMobileModuleTypeSelector()"
              [class.open]="isMobileModuleTypeSelectorOpen"
              type="button"
              class="module-button"
              i18n="@@createNewModule">
              Add module
            </button>
            <div class="new-module-menu" *ngIf="isMobileModuleTypeSelectorOpen">
              <button
                class="new-module-type-btn new-content-module-btn"
                (click)="addModuleAndSelectIt(ModuleTypes.CONTENT)">
                <i></i>
                <span i18n="@@newContentModuleBtn">Video or reading materials</span>
              </button>
              <button class="new-module-type-btn new-quiz-module-btn" (click)="addModuleAndSelectIt(ModuleTypes.QUIZ)">
                <i></i>
                <span i18n="@@formsAndQuestionsModuleBtn">Forms and questions</span>
              </button>
              <button
                class="new-module-type-btn new-session-module-btn"
                (click)="addModuleAndSelectIt(ModuleTypes.GROUP_SESSION)">
                <i></i>
                <span i18n="@@newSessionModuleBtn">Session</span>
              </button>
              <button class="new-module-type-btn new-group-session-module-btn disabled"></button>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="col-md-8" [class.d-block]="modules.value.length > 0">
    <section class="section module-section">
      <h2
        [attr.data-qa-id]="'program-modules-module-title-' + activeModule.moduleType"
        class="title"
        *ngIf="activeModule">
        {{ activeModule.order }}.
        <ng-container *ngIf="activeModule.moduleType === ModuleTypes.CONTENT" i18n="@@contentModuleTitle">
          Video or reading material
        </ng-container>
        <ng-container *ngIf="activeModule.moduleType === ModuleTypes.QUIZ" i18n="@@formsAndQuestionsModuleTitle">
          Forms and questions
        </ng-container>
        <ng-container
          *ngIf="
            activeModule.moduleType === ModuleTypes.SESSION || activeModule.moduleType === ModuleTypes.GROUP_SESSION
          "
          i18n="@@publicProgramSession">
          Session
        </ng-container>
      </h2>

      <form [formGroup]="modulesForm" class="form">
        <ng-container formArrayName="modules">
          <ng-container
            *ngFor="let module of modules.controls; let formModuleIndex = index"
            [formGroupName]="formModuleIndex">
            <ng-container *ngIf="module.value.localId === activeModule?.localId">
              <ng-container [ngSwitch]="module.value.moduleType">
                <ng-template [ngSwitchCase]="ModuleTypes.CONTENT">
                  <app-content-module
                    [moduleForm]="module"
                    [moduleActivations]="formModuleIndex === 0 ? firstModuleActivations : regularModuleActivations"
                    [hideInstructor]="isSoloWorkspace || !isTeamAdmin"
                    (titleChange)="onModuleTitleChanged($event)"></app-content-module>
                </ng-template>

                <ng-template [ngSwitchCase]="ModuleTypes.QUIZ">
                  <app-quiz-module
                    [moduleForm]="module"
                    [hideInstructor]="isSoloWorkspace || !isTeamAdmin"
                    [moduleActivations]="formModuleIndex === 0 ? firstModuleActivations : regularModuleActivations"
                    (addQuestion)="addNewQuestion($event)"
                    (addQuestionOption)="addNewQuestionOption($event)"
                    (removeQuestion)="removeModuleQuestion($event)"
                    (removeQuestionOption)="removeModuleQuestionOption($event)"
                    (titleChange)="onModuleTitleChanged($event)"
                    (descriptionChange)="onModuleDescriptionChanged($event)"
                    (loadQuiz)="onLoadQuiz($event)"></app-quiz-module>
                </ng-template>

                <ng-template [ngSwitchCase]="ModuleTypes.GROUP_SESSION">
                  <app-session-module
                    [moduleForm]="module"
                    [moduleActivations]="formModuleIndex === 0 ? firstModuleActivations : regularModuleActivations"
                    [disableServiceChange]="shouldDisableSessionModuleServiceChange(module.value)"
                    [programAccessRole]="programAccessRole"></app-session-module>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </form>
    </section>
  </div>
</div>

<ng-template #serviceModule let-module>{{ module.title }} </ng-template>
