<div class="title d-flex align-items-center justify-content-between">
  <p class="title__text">Clients ({{ clients?.length + allInvited?.length + deletedClients?.length }})</p>
  <ng-content select=".title-action-button"></ng-content>
</div>

<div class="d-flex align-items-center">
  <div class="search-control" [class.search-control--no-margin]="searchControlActions.children?.length">
    <em class="search-icon"></em>
    <input
      type="text"
      class="search-input form-control"
      placeholder="Search for a client"
      data-qa-id="clients-list-search-input"
      i18n-placeholder="@@clientsListSearchPlaceholder"
      [(ngModel)]="searchText" />
  </div>
  <div #searchControlActions>
    <ng-content select=".search-control-action-button"></ng-content>
  </div>
</div>

<div class="tabs" *ngIf="editable">
  <div
    class="enrolled tab"
    data-qa-id="clients-list-enrolled-tab"
    (click)="selectTab(clientsListTabs.Enrolled)"
    [class.active]="activeTab === clientsListTabs.Enrolled">
    <ng-container i18n="@@enrolled">Enrolled</ng-container>
    {{ clients ? ' (' + clients?.length + ')' : '' }}
  </div>
  <div
    class="invited tab"
    data-qa-id="clients-list-pending-tab"
    (click)="selectTab(clientsListTabs.Pending)"
    [class.active]="activeTab === clientsListTabs.Pending">
    <ng-container i18n="@@pending">Pending</ng-container>
    {{ allInvited ? ' (' + allInvited?.length + ')' : '' }}
  </div>
  <div
    class="deleted tab"
    data-qa-id="clients-list-deleted-tab"
    (click)="selectTab(clientsListTabs.Deleted)"
    [class.active]="activeTab === clientsListTabs.Deleted">
    <ng-container i18n="@@deleted">Deleted</ng-container>
    {{ deletedClients ? ' (' + deletedClients?.length + ')' : '' }}
  </div>
</div>

<div class="users-section" *ngIf="clients && clients?.length && activeTab === clientsListTabs.Enrolled">
  <ng-scrollbar puiScrollable cdkScrollable appAutoHeight>
    <div
      *ngFor="let client of clients | filterByName: searchText; let i = index"
      class="user"
      [class.active]="client.id === selectedClientId"
      (click)="select(client.id)">
      <div class="user-all">
        <div class="user-info" [title]="client?.name">
          <ng-container
            *ngTemplateOutlet="avatarTemplate; context: { name: client?.name, url: client?.photo }"></ng-container>
          {{ client?.name }}
        </div>
        <div class="info">
          <span *ngIf="progress[client.id]">{{ progress[client.id] | percent }}</span>
        </div>

        <button
          type="button"
          class="client-actions"
          *ngIf="editable"
          pui-dropdown
          [pui-dropdown-content]="actionsDropdownTemplate"
          [puiDropdownContext]="{client}"
          [pui-dropdown-min-width]="'auto'"
          [pui-dropdown-width]="'auto'">
          <span class="point"></span>
        </button>
      </div>
    </div>
  </ng-scrollbar>

  <ng-template #actionsDropdownTemplate let-client="client">
    <button pui-button appearance="flat-destructive" (click)="unEnrollEmit(client)">
      <pui-icon svgIcon="pui:x-circle" [size]="20"></pui-icon>
      <span pui-typography="body-s"> Unenroll from program </span>
    </button>
  </ng-template>
</div>

<div class="users-section" *ngIf="allInvited && allInvited?.length && activeTab === clientsListTabs.Pending">
  <ng-scrollbar puiScrollable cdkScrollable appAutoHeight>
    <div
      *ngFor="let client of allInvited | filterByName: searchText; let i = index"
      class="user item"
      [class.prepaid]="client.prepaid"
      [class.paid]="client.paid"
      [class.active]="client.inviteId && selectedInvitedClientId === client.inviteId"
      (click)="selectInvitedClient(client)">
      <ng-container *ngIf="client.id" class="item">
        <div class="item__logo">
          <ng-container
            *ngTemplateOutlet="avatarTemplate; context: { name: client?.name, url: client.photo }"></ng-container>
        </div>

        <div class="item__content">
          <span [attr.data-qa-id]="'clients-list-pending-client-user-name-title-' + client.name">
            {{ client.name || client.contacts?.email }}
          </span>
        </div>

        <div class="item__action">
          <button
            type="button"
            class="client-actions"
            pui-dropdown
            [pui-dropdown-content]="invitedClientsActionsDropdownTemplate"
            [puiDropdownContext]="{client}"
            [pui-dropdown-min-width]="'auto'"
            [pui-dropdown-width]="'auto'">
            <span class="point"></span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!client.id" class="item">
        <div class="item__logo">
          <ng-container
            *ngTemplateOutlet="avatarTemplate; context: { name: client?.name, url: client.photo }"></ng-container>
        </div>

        <div class="item__content">
          <span [attr.data-qa-id]="'clients-list-pending-client-user-name-title-' + client.name">
            {{ client.name || client.contacts?.email }}
          </span>
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>

  <ng-template #invitedClientsActionsDropdownTemplate let-client="client">
    <button pui-button appearance="flat-destructive" (click)="deleteInvitedClient(client)">
      <pui-icon svgIcon="pui:trash-2" [size]="20"></pui-icon>
      Delete
    </button>
  </ng-template>
</div>

<div class="users-section" *ngIf="deletedClients && deletedClients?.length && activeTab === clientsListTabs.Deleted">
  <ng-scrollbar puiScrollable cdkScrollable appAutoHeight>
    <div
      *ngFor="let client of deletedClients | filterByName: searchText; let i = index"
      class="user deleted"
      [class.opened]="activePopoverIndex === i">
      <div class="user-info" [title]="client?.name">
        <ng-container
          *ngTemplateOutlet="avatarTemplate; context: { name: client?.name, url: client.photo }"></ng-container>

        {{ client?.name }}
      </div>
      <button
        type="button"
        class="client-actions"
        pui-dropdown
        [pui-dropdown-content]="deletedClientsActionsDropdownTemplate"
        [puiDropdownContext]="{client}"
        [pui-dropdown-min-width]="'auto'"
        [pui-dropdown-width]="'auto'">
        <span class="point"></span>
      </button>
    </div>
  </ng-scrollbar>

  <ng-template #deletedClientsActionsDropdownTemplate let-client="client">
    <button pui-button appearance="flat-destructive" (click)="unEnrollEmit(client)">
      <pui-icon svgIcon="pui:x-circle" [size]="20"></pui-icon>
      Delete completely
    </button>
  </ng-template>
</div>

<ng-template #avatarTemplate let-name="name" let-url="url">
  <pui-avatar size="m" class="user-photo" [avatarUrl]="url" [text]="name"></pui-avatar>
</ng-template>
