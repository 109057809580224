import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { IWorkspace, WorkspaceRoute } from '@app/modules/workspaces/types';
import { PUBLIC_WORKSPACE_ENDPOINT } from '@app/shared/constants/endpoints';

import config from '../../../core/config/config';

abstract class AbsWorkspaceService {
  readonly GUIDE_API_ENDPOINT = `${config.apiPath}/user/guide/workspaces`;

  readonly API_ENDPOINT: string;

  protected constructor(protected _http: HttpClient, protected route: WorkspaceRoute) {
    switch (route) {
      case WorkspaceRoute.PUBLIC:
        this.API_ENDPOINT = PUBLIC_WORKSPACE_ENDPOINT;
        break;
      case WorkspaceRoute.GUIDE:
        this.API_ENDPOINT = this.GUIDE_API_ENDPOINT;
        break;
    }
  }

  workspaceById$({ id: workspaceId }: Pick<IWorkspace, 'id'>): Observable<IWorkspace> {
    return this._http.get<IWorkspace>(`${this.API_ENDPOINT}/${workspaceId}`);
  }
}

export { AbsWorkspaceService };
