import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { DateTime } from 'luxon';
// import { locale } from '@env/locale';
import { LocaleService } from '@app/core/locale/locale.service';

// const dateTimeLocale = locale.dateTimeLocale;

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  private readonly dateTimeLocale: string;

  constructor(private localeService: LocaleService) {
    super();
    this.dateTimeLocale = this.localeService.getLocale().dateTimeLocale;
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const millis = Date.parse(value);
      const date = new Date(millis);
      if (!date) {
        // @ts-expect-error TS2322
        return null;
      }
      return { day: date.getDate(), year: date.getFullYear(), month: date.getMonth() };
    }
    // @ts-expect-error TS2322
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const dateParsed = DateTime.fromObject(date);
    if (!dateParsed.isValid) {
      return '';
    }
    if (this.dateTimeLocale.includes('en')) {
      return formatDate(dateParsed.toISO(), 'yyyy-MM-dd', this.dateTimeLocale);
    }
    return formatDate(dateParsed.toISO(), 'shortDate', this.dateTimeLocale);
  }
}
