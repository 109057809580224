import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'lodash';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {
  transform<T>(arr: T[], args: string): any[] {
    return map(arr, args);
  }
}
