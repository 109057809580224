import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { UiKitHelpersModule } from '../_common/helpers';
import { UiSelectorSingle } from './selector';
import { UiAutocompleteModule } from '../ui-autocomplete';
import { UiIconModule } from '../ui-icon';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY(overlay: Overlay): () => RepositionScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY
};

@NgModule({
  declarations: [UiSelectorSingle],
  exports: [UiSelectorSingle],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    UiAutocompleteModule,
    UiIconModule,
    FormsModule,
    UiKitHelpersModule
  ],
  providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER]
})
export class UiSelectorSingleModule {}
