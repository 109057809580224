import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { io } from 'socket.io-client';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { skip, map } from 'rxjs/operators';
import config from '@app/core/config/config';
import { GroupSession, isGroupSession, isSimpleSession, Session, SimpleSession } from '@app/shared/interfaces/session';
import {
  loadRecentNotificationsEvent,
  markNotificationsReadEvent,
  newNotificationReceived,
  notificationsWereMarkedReadEvent,
  recentNotificationsLoadedEvent
} from '@app/modules/notifications/events';
import { ISystemNotification, SystemNotificationTypes } from '@app/modules/notifications/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { ClientGuidesService } from '@app/core/users/client-guides.service';
import { ClientGuide, GuideClient } from '@app/core/users/types';
import { AuthService } from '@app/core/auth/services';
import { SoundService } from '../sound/sound.service';
import { SoundType } from '../sound/types';
import { SessionsService } from '../session/sessions.service';
import { QuizClientsPolicy } from '@app/core/quizzes/types';
import { UserService } from '@app/core/users/user.service';

const NOTIFICATIONS_LIMIT = 100;
/* supplement is used as fast hack, there can be sessions already removed, and this affects session notification */
const NOTIFICATIONS_SUPPLEMENT = 20;

interface NotificationSessions {
  [key: number]: SimpleSession;
  group: {
    [key: number]: GroupSession;
  };
}

@Injectable()
export class SystemNotificationsService {
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _socket;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _subscriptions: Subscription;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _newNotification$ = new Subject<ISystemNotification>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notificationsLoaded$ = new Subject<{ notifications: ISystemNotification[] }>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notifications$ = new BehaviorSubject<ISystemNotification[]>([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notificationsUpdatedAfterRead$ = new BehaviorSubject<ISystemNotification[]>([]);

  isNotificationsPresents$ = new BehaviorSubject<boolean>(false);

  get sessionNotifications$(): Observable<ISystemNotification[]> {
    // eslint-disable-next-line id-length
    return this._notifications$.pipe(map(notifications => notifications.filter(this.isSessionNotification)));
  }

  get programNotifications$(): Observable<ISystemNotification[]> {
    // eslint-disable-next-line id-length
    return this._notifications$.pipe(map(notifications => notifications.filter(n => n.details?.programId)));
  }

  get packageNotifications$(): Observable<ISystemNotification[]> {
    // eslint-disable-next-line id-length
    return this._notifications$.pipe(map(notifications => notifications.filter(n => n.details?.packageId)));
  }

  get formNotifications$(): Observable<ISystemNotification[]> {
    // eslint-disable-next-line id-length
    return this._notifications$.pipe(map(notifications => notifications.filter(n => n.details?.quizTemplateId)));
  }

  get commonNotifications$(): Observable<ISystemNotification[]> {
    // eslint-disable-next-line id-length
    return this._notifications$.pipe(map(notifications => notifications.filter(n => !n.details)));
  }

  get workspaceNotifications$(): Observable<ISystemNotification[]> {
    // eslint-disable-next-line id-length
    return this._notifications$.pipe(map(notifications => notifications.filter(n => n.details?.workspaceId)));
  }

  get noteNotifications$(): Observable<ISystemNotification[]> {
    return this._notifications$.pipe(
      map(notifications => notifications.filter(notification => notification.details?.notesLink))
    );
  }

  get communityNotifications$(): Observable<ISystemNotification[]> {
    return this._notifications$.pipe(
      map(notifications => notifications.filter(notification => notification.details?.communityId))
    );
  }

  constructor(
    private _auth: AuthService,
    private _sessions: SessionsService,
    private _soundService: SoundService,
    private _guideClients: GuideClientsService,
    private clientGuidesService: ClientGuidesService,
    private readonly user$: UserService
  ) {
    this._auth.onAuth().subscribe(user => {
      this.destroySocket();
      this.removeSubscriptions();

      if (user) {
        if (user.RoleId !== UserRoles.ADMIN) {
          this.setSubscriptions(user.RoleId);
          this.initializeSocket(user.authToken, user.RoleId);
        }
      }
    });

    this.audioSubscribe();
  }

  // @ts-expect-error TS7006
  private static getGroupSession(sessions: NotificationSessions, notification): GroupSession {
    if (notification.details.eventId && sessions.group[notification.details.eventId]) {
      const group = sessions.group[notification.details.eventId];
      // @ts-expect-error TS2322
      return group?.sessions?.find(session => session?.id === notification.details.sessionId);
    }

    // @ts-expect-error TS2322
    return Object.values(sessions.group)
      .map(group => group.sessions)
      .flat()
      .find(session => session?.id === notification.details.sessionId);
  }

  // @ts-expect-error TS7006
  private static addSessionDetailsToNotification(notification, sessions: NotificationSessions): ISystemNotification {
    const groupSession = this.getGroupSession(sessions, notification);
    if (groupSession) {
      return {
        ...notification,
        details: {
          sessionId: notification.details.sessionId,
          status: notification.details.status,
          session: groupSession
        }
      };
    }

    return notification.details.sessionId && sessions[notification.details.sessionId]
      ? {
          ...notification,
          details: {
            sessionId: notification.details.sessionId,
            status: notification.details.status,
            session: sessions[notification.details.sessionId]
          }
        }
      : notification;
  }

  private mergeNotificationsSessionsClients(
    notifications: ISystemNotification[],
    sessions: NotificationSessions,
    clients: GuideClient[]
  ): ISystemNotification[] {
    return notifications.map(notification => {
      if (!notification.details) {
        return notification;
      }

      if (notification.details.quizTemplateId) {
        return clients && clients.length
          ? {
              ...notification,
              details: {
                quizTemplateId: notification.details.quizTemplateId,
                quizId: notification.details.quizId,
                fromClientId: notification.details.fromClientId,
                title: notification.details.title,
                quizClientsPolicy: notification.details.quizClientsPolicy,
                user:
                  notification.details.quizClientsPolicy === QuizClientsPolicy.Author
                    ? this.user$.getValue()
                    : // @ts-expect-error TS2532
                      clients.find(client => client.id === notification.details.fromClientId),
                ...(notification.details.quizClientsPolicy === QuizClientsPolicy.Author
                  ? SystemNotificationsService.addSessionDetailsToNotification(notification, sessions).details
                  : {})
              }
            }
          : notification;
      }

      return SystemNotificationsService.addSessionDetailsToNotification(notification, sessions);
    });
  }

  private mergeNotificationsServicesGuides(
    notifications: ISystemNotification[],
    sessions: NotificationSessions,
    guides: ClientGuide[]
  ): ISystemNotification[] {
    return notifications.map(notification => {
      if (!notification.details) {
        return notification;
      }

      if (notification.details.programId) {
        if (guides && guides.length) {
          // @ts-expect-error TS2532
          const userInfo = guides.find(guide => guide.id === notification.details.authorId);
          return {
            ...notification,
            details: {
              ...notification.details,
              user: userInfo || notification.details.user
            }
          };
        }
        return notification;
      }

      if (notification.details.quizTemplateId) {
        return guides && guides.length
          ? {
              ...notification,
              details: {
                quizTemplateId: notification.details.quizTemplateId,
                fromGuideId: notification.details.fromGuideId,
                title: notification.details.title,
                // @ts-expect-error TS2532
                user: guides.find(guide => guide.id === notification.details.fromGuideId),
                scheduleId: notification.details.scheduleId
              }
            }
          : notification;
      }

      return SystemNotificationsService.addSessionDetailsToNotification(notification, sessions);
    });
  }

  private destroySocket(): void {
    if (this._socket) {
      this._socket.off();
      this._socket.disconnect();
      this._socket = null;
    }
  }

  private getSessionsUpdateObservable$(): Observable<NotificationSessions> {
    return this._sessions.update$.pipe(
      map(sessions => {
        const sessionsMap: NotificationSessions = { group: {} };
        const sessionsLabels = Object.keys(sessions);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sessionsLabels.forEach(label => this.sessionsArrayToDictionary(sessions[label], sessionsMap));
        return sessionsMap;
      })
    );
  }

  private initializeSocket(token: string, role: number): void {
    const { host, path } = config.notificationsSocket;
    this._socket = io(host, {
      path,
      closeOnBeforeunload: false,
      auth: {
        token,
        role
      },
      transports: ['websocket']
    });
    this.setSocketListeners();
    this.loadRecentNotifications();
  }

  private loadRecentNotifications(): void {
    this._socket.emit(loadRecentNotificationsEvent, {
      limit: NOTIFICATIONS_LIMIT + NOTIFICATIONS_SUPPLEMENT
    });
  }

  private onNotificationsWereMarkedRead(response: { ids: number[] }): void {
    const unreadNotifications = this._notificationsUpdatedAfterRead$.getValue();
    unreadNotifications.forEach(notification => {
      if (response.ids.includes(notification.id)) {
        notification.isRead = true;
      }
    });
    unreadNotifications.sort(this.compareNotifications);
    this._notificationsUpdatedAfterRead$.next(unreadNotifications.slice(0, NOTIFICATIONS_LIMIT));
    this.isNotificationsPresents$.next(false);
  }

  private removeSubscriptions(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this.isNotificationsPresents$.next(false);
    }
  }

  private setSocketListeners(): void {
    // @ts-expect-error TS7006
    this._socket.on(newNotificationReceived, response => this._newNotification$.next(response));
    // @ts-expect-error TS7006
    this._socket.on(recentNotificationsLoadedEvent, response => this._notificationsLoaded$.next(response));
    // @ts-expect-error TS7006
    this._socket.on(notificationsWereMarkedReadEvent, response => this.onNotificationsWereMarkedRead(response));
  }

  private setSubscriptions(roleId: number): void {
    this._subscriptions = merge(
      this._notificationsLoaded$.pipe(map(response => response.notifications)),
      this._newNotification$.pipe(
        map(newNotification => ([] as ISystemNotification[]).concat(newNotification, this._notifications$.getValue()))
      )
    ).subscribe(notifications => {
      if (notifications.some(notification => !notification?.isRead)) {
        this.isNotificationsPresents$.next(true);
      }
      this._notificationsUpdatedAfterRead$.next(notifications);
    });

    if (roleId === UserRoles.GUIDE) {
      this._subscriptions.add(
        combineLatest([
          this._notificationsUpdatedAfterRead$,
          this.getSessionsUpdateObservable$(),
          this._guideClients.clients$
        ])
          .pipe(
            map(([notifications, sessions, clients]) =>
              this.mergeNotificationsSessionsClients(notifications, sessions, clients)
            ),
            map(notifications =>
              notifications.filter(
                notification =>
                  notification &&
                  (notification.details || notification.type === SystemNotificationTypes.CLIENT_REGISTERED)
              )
            )
          )
          .subscribe(notifications => {
            this._notifications$.next(notifications);
          })
      );
    }

    if (roleId === UserRoles.CLIENT) {
      this._subscriptions.add(
        combineLatest([
          this._notificationsUpdatedAfterRead$,
          this.getSessionsUpdateObservable$(),
          this.clientGuidesService.users$
        ])
          .pipe(
            map(([notifications, sessions, guides]) =>
              this.mergeNotificationsServicesGuides(notifications, sessions, guides)
            ),
            map(notifications => notifications.filter(notification => notification && notification.details))
          )
          .subscribe(notifications => this._notifications$.next(notifications))
      );
    }
  }

  private sessionsArrayToDictionary(
    sessions: Session[],
    initialObject: NotificationSessions = { group: {} }
  ): NotificationSessions {
    for (let i = 0, len = sessions.length; i < len; i++) {
      const currentSession = sessions[i];

      if (isSimpleSession(currentSession)) {
        initialObject[currentSession.id] = currentSession as SimpleSession;
      }

      if (isGroupSession(currentSession)) {
        initialObject.group[currentSession.eventId] = currentSession;
      }
    }

    return initialObject;
  }

  // @ts-expect-error TS7006
  private compareNotifications(notification1, notification2): number {
    if (!notification1.isRead && notification2.isRead) {
      return -1;
    }
    if (notification1.isRead && !notification2.isRead) {
      return 1;
    }
    if (notification1.priority !== notification2.priority) {
      return notification2.priority - notification1.priority;
    }
    return notification2.id - notification1.id;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private audioSubscribe() {
    // @ts-expect-error TS2322
    let unreadNotifications: number = null;
    this.sessionNotifications$.pipe(skip(1)).subscribe(notifications => {
      const unreadNew = notifications.filter(i => !i.isRead).length;
      if (unreadNotifications !== null && unreadNew > unreadNotifications) {
        this._soundService.playSound(SoundType.NOTIFICATION);
      }

      unreadNotifications = unreadNew;
    });
  }

  markNotificationsRead(ids?: number[]): void {
    if (!ids) {
      ids = this._notifications$
        .getValue()
        .filter(notification => !notification.isRead)
        .map(notification => notification.id);
    }
    this._socket.emit(markNotificationsReadEvent, { ids });
    this.onNotificationsWereMarkedRead({ ids });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  markHeaderNotificationsRead() {
    const ids = this._notifications$
      .getValue()
      .filter(
        notification =>
          ((!notification.details && notification.type === SystemNotificationTypes.CLIENT_REGISTERED) ||
            // @ts-expect-error TS2532
            notification.details.sessionId ||
            // @ts-expect-error TS2532
            notification.details.packageId ||
            // @ts-expect-error TS2532
            notification.details.quizTemplateId ||
            // @ts-expect-error TS2532
            notification.details.workspaceId ||
            // @ts-expect-error TS2532
            notification.details.programId ||
            // @ts-expect-error TS2532
            notification.details.communityId ||
            // @ts-expect-error TS2532
            notification.details.notesLink) &&
          !notification.isRead
      )
      .map(notification => notification.id);

    this._socket.emit(markNotificationsReadEvent, { ids });
    this.onNotificationsWereMarkedRead({ ids });
  }

  isSessionNotification(notification: ISystemNotification): boolean {
    return notification.details?.sessionId && !notification.details?.quizTemplateId;
  }
}
