import { DateTime } from 'luxon';
import { DAY, MONTH, WEEK_DAY, YEAR } from '@platformStyle/model/const';
import convertToDateTime from '../../convert-to-datetime';

// @ts-expect-error TS7006
function weekDayStyle(date: DateTime, templateOptions): string {
  const isHidden = templateOptions && templateOptions.hideWeekDay;

  let format = WEEK_DAY;
  if (isHidden) {
    format = '';
  }
  return format;
}

// @ts-expect-error TS7006
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function dayStyle(date: DateTime, templateOptions) {
  const isHidden = templateOptions && templateOptions.hideDay;

  let format = DAY;
  if (isHidden) {
    format = '';
  }

  return format;
}

// @ts-expect-error TS7006
function monthStyle(date: DateTime, templateOptions): string {
  const isHidden = templateOptions && templateOptions.hideMonth;

  let format = MONTH;
  if (isHidden) {
    format = '';
  }

  return format;
}

// @ts-expect-error TS7006
function yearStyle(date: DateTime, templateOptions): string {
  const now = convertToDateTime(Date.now());
  const isCurrentYear = date ? !(now.year - date.year) : false;
  const isHidden = templateOptions && templateOptions.hideLastYear;
  let format = YEAR;

  if (isHidden && isCurrentYear) {
    format = '';
  }

  return format;
}

export { weekDayStyle, dayStyle, monthStyle, yearStyle };
