import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

export interface ClientsTag {
  name: string;
  color: string;
}

export const SALES_CLIENT_TAGS: ClientsTag[] = [
  {
    name: 'Suspect',
    color: '#7C3AED'
  },
  {
    name: 'Prospect',
    color: '#0F9BE3'
  },
  {
    name: 'Discovery',
    color: '#FBBF24'
  },
  {
    name: 'Proposal',
    color: '#EA580C'
  },
  {
    name: 'Contract signed',
    color: '#4FACBF'
  },
  {
    name: 'Client',
    color: '#059669'
  },
  {
    name: 'On hold',
    color: '#F30055'
  }
];

@Component({
  selector: 'guide-client-stage-selector, [guide-client-stage-selector]',
  templateUrl: './guide-client-stage-selector.component.html',
  styleUrls: ['./guide-client-stage-selector.component.scss'],
  providers: [
    PuiDestroyService,
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GuideClientStageSelectorComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideClientStageSelectorComponent implements ControlValueAccessor {
  @Input()
  stage: string | null = null;

  @Output()
  stageChange = new EventEmitter<string>();

  tags = SALES_CLIENT_TAGS;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value = ''; // this is the updated value that the class accesses

  onChange: (value: string) => void = () => {};
  onTouch: (value: string) => void = () => {};

  set value(val: string) {
    if (val !== undefined && this._value !== val) {
      // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
      this._value = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  // @ts-expect-error TS7006
  readonly excludeCurrentTag = (currentTagName: string) => tag => tag.name !== currentTagName;

  getCurrentStage(tagName: string): ClientsTag | null {
    // @ts-expect-error TS2322
    return !tagName ? null : this.tags.find(tag => tag.name === tagName);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitStageChanged(stage: string) {
    this.stage = stage;
    this.value = stage;
    this.stageChange.emit(stage);
  }

  // this method sets the value programmatically
  writeValue(value: string): void {
    this.value = value;
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: (value: string) => void): void {
    this.onTouch = fn;
  }
}
