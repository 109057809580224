import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'weekDay'
})
export class WeekDayPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(initialDateString: string, weekday = 1): any {
    return DateTime.fromISO(initialDateString).set({ weekday }).toISO();
  }
}
