import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { EmailVerificationService } from '../../../email-verification/email-verification.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['../../../../screens/auth/auth.scss', '../../styles/auth.scss', './confirm-email.component.scss'],
  providers: [EmailVerificationService]
})
export class ConfirmEmailComponent implements OnDestroy {
  private destroy$ = new Subject();

  @Input()
  // @ts-expect-error TS2564
  email: string;

  // Temporary solution
  @Input()
  // @ts-expect-error TS2564
  emailVerified: boolean;

  constructor(
    private _emailVerificationService: EmailVerificationService,
    private _notifications: NotificationsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resendEmail() {
    this._emailVerificationService
      .resendEmailVerification(this.email)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const message = `Email was resent!`;
        this._notifications.success(message);
      });
  }
}
