import { environment } from '@env/environment';
import { LOCATION_ORIGIN } from '../consts';

export const locale = {
  'en-GB': {
    baseUrl: LOCATION_ORIGIN,
    bookWidgetBaseUrl: `http://localhost:4600${environment.bookWidgetBaseHref}`,
    dateTimeLocale: 'en-US',
    language: 'en',
    direction: 'ltr'
  },
  'en-US': {
    baseUrl: LOCATION_ORIGIN,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}`,
    dateTimeLocale: 'en-US',
    language: 'en',
    direction: 'ltr'
  },
  en: {
    baseUrl: LOCATION_ORIGIN,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}`,
    dateTimeLocale: 'en-US',
    language: 'en',
    direction: 'ltr'
  },
  ru: {
    baseUrl: `${LOCATION_ORIGIN}/ru`,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}/ru`,
    dateTimeLocale: 'ru',
    language: 'ru',
    direction: 'ltr'
  },
  it: {
    baseUrl: `${LOCATION_ORIGIN}/it`,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}/it`,
    dateTimeLocale: 'it',
    language: 'it',
    direction: 'ltr'
  },
  fr: {
    baseUrl: `${LOCATION_ORIGIN}/fr`,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}/fr`,
    dateTimeLocale: 'fr',
    language: 'fr',
    direction: 'ltr'
  },
  es: {
    baseUrl: `${LOCATION_ORIGIN}/es`,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}/es`,
    dateTimeLocale: 'es',
    language: 'es',
    direction: 'ltr'
  },
  ar: {
    baseUrl: `${LOCATION_ORIGIN}/ar`,
    bookWidgetBaseUrl: `${LOCATION_ORIGIN}${environment.bookWidgetBaseHref}/ar`,
    dateTimeLocale: 'ar',
    language: 'ar',
    direction: 'rtl'
  }
};
