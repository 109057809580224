import { DateTime } from 'luxon';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-timezone-modal',
  templateUrl: './change-timezone-modal.component.html',
  styleUrls: ['./change-timezone-modal.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeTimezoneModalComponent {
  timezoneName = '';
  private timezone = '';

  @Input()
  set currentTimezone(timezone: string) {
    const dt = DateTime.local().setZone(timezone);

    this.timezoneName = `${timezone} (${dt.toFormat('ZZZZ')})`;
    this.timezone = timezone;
  }

  constructor(readonly modal: NgbActiveModal) {}

  change(): void {
    this.modal.close(this.timezone);
  }

  close(): void {
    this.modal.close(null);
  }
}
