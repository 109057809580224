import { Pipe, PipeTransform } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { BookingGuide, BookingLocation } from '@appWidget/modules/booking/interfaces';
import { ServiceConnectionTypeVM } from '@appWidget/modules/booking/common/pipes/service-connection-type.pipe';
import { getFullConnectionWithLinks } from '@appWidget/modules/booking/utils/get-service-connection-type';

export type ServiceConnectionTypeVMWithLink = ServiceConnectionTypeVM & { url?: string };
export type BookingGuideForLocation = Pick<BookingGuide, 'firstName' | 'lastName' | 'phoneForSessions'>;

@Pipe({
  name: 'sessionDetailsConnectionLink'
})
export class SessionDetailsConnectionLinkPipe implements PipeTransform {
  private readonly platformName =
    this.runtimeConfigService.get('videoLocationCustomName') || this.runtimeConfigService.get('platformName');

  constructor(private readonly runtimeConfigService: RuntimeConfigService) {}

  transform(
    location: BookingLocation,
    [guide, eventId]: [BookingGuideForLocation, string]
  ): ServiceConnectionTypeVMWithLink | null {
    if (!location) {
      return null;
    }

    // for this type we always show address
    return getFullConnectionWithLinks({
      location: {
        ...location,
        hideAddress: false
      },
      guidePhone: guide?.phoneForSessions as string,
      guideName: `${guide?.firstName} ${guide?.lastName}`,
      platformName: this.platformName,
      guideEventUid: eventId
    });
  }
}
