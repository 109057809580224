// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injector, Injectable, LOCALE_ID, InjectionToken, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  GuidePublicService,
  GuidePublicServiceGroup,
  GuidePublicServiceGroupItem,
  GuidePublicSingleSession,
  GuidePackageService,
  GuideProgramService
} from '@app/shared/interfaces/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { GuideServiceTypes } from '@app/shared/interfaces/services/guide-service';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import config from '../config/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function buildGuidePublicServices(servicesDetails: any[], locale: ILocale): GuidePublicService[] {
  return servicesDetails.map(serviceDetails => {
    if (serviceDetails.services) {
      return new GuidePublicServiceGroup({
        id: serviceDetails.id,
        guideId: serviceDetails.guideId,
        name: serviceDetails.name,
        description: serviceDetails.description,
        coverImage: serviceDetails.coverImage,
        type: serviceDetails.type,
        services: serviceDetails.services.map(
          // @ts-expect-error TS7006
          serviceItemDetails =>
            new GuidePublicServiceGroupItem(
              {
                id: serviceItemDetails.id,
                guideId: serviceItemDetails.guideId,
                name: serviceItemDetails.name,
                description: serviceItemDetails.description,
                duration: serviceItemDetails.duration,
                connectionType: serviceItemDetails.connectionType,
                price: serviceItemDetails.price, // if price = 0, then service is free
                overtimeCharge: serviceItemDetails.overtimeCharge,
                type: serviceItemDetails.type,
                serviceParent: serviceItemDetails.serviceParent,
                availableCount: serviceItemDetails.availableCount,
                hidePrice: serviceItemDetails.price === undefined,
                workspace: serviceDetails.workspace,
                address: serviceItemDetails.address,
                hideAddress: serviceItemDetails.hideAddress,
                callInitiator: serviceItemDetails.callInitiator
              },
              locale
            )
        )
      });
    }

    if (serviceDetails.type === GuideServiceTypes.PACKAGE) {
      return new GuidePackageService(
        {
          id: serviceDetails.id,
          guideId: serviceDetails.guideId,
          name: serviceDetails.name,
          description: serviceDetails.description,
          price: !serviceDetails.isHiddenPrice ? serviceDetails.price : undefined,
          subscriptionPrice: !serviceDetails.isHiddenPrice ? serviceDetails.subscriptionPrice : undefined,
          subscriptionRecurrency: serviceDetails.subscriptionRecurrency,
          totalPayments: serviceDetails.totalPayments,
          type: serviceDetails.type,
          sessions: serviceDetails.sessions,
          hidePrice: serviceDetails.isHiddenPrice
        },
        locale
      );
    }

    if (serviceDetails.type === GuideServiceTypes.PROGRAM) {
      return new GuideProgramService(
        {
          id: serviceDetails.id,
          guideId: serviceDetails.guideId,
          name: serviceDetails.name,
          description: serviceDetails.description,
          price: !serviceDetails.hidePrice ? serviceDetails.price : undefined,
          subscriptionPrice: !serviceDetails.hidePrice ? serviceDetails.subscriptionPrice : undefined,
          subscriptionRecurrency: serviceDetails.subscriptionRecurrency,
          totalPayments: serviceDetails.totalPayments,
          type: serviceDetails.type,
          startDate: serviceDetails.startDate,
          coverImage: serviceDetails.coverImageThumb,
          hidePrice: serviceDetails.hidePrice,
          startType: serviceDetails.startType,
          enrolledAt: serviceDetails.enrolledAt
        },
        locale
      );
    }

    return new GuidePublicSingleSession(
      {
        id: serviceDetails.id,
        guideId: serviceDetails.guideId,
        name: serviceDetails.name,
        description: serviceDetails.description,
        duration: serviceDetails.duration,
        connectionType: serviceDetails.connectionType,
        price: serviceDetails.price, // if price = 0, then service is free
        overtimeCharge: serviceDetails.overtimeCharge,
        type: serviceDetails.type,
        hidePrice: serviceDetails.hidePrice,
        workspaceId: serviceDetails.workspaceId,
        workspace: serviceDetails.workspace,
        address: serviceDetails.address,
        hideAddress: serviceDetails.hideAddress,
        callInitiator: serviceDetails.callInitiator
      },
      locale
    );
  });
}

@Injectable()
export class GuidePublicServicesService {
  readonly ENDPOINT = `${config.apiPath}/public/profile`;

  constructor(private _http: HttpClient, private _localeService: LocaleService) {}

  // TODO: PR-5909 add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getService$(guide: { id: number; workspaceId?: number }, serviceId: number): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryParams: any = {};

    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: queryParams
    });

    let requestUrl = `${this.ENDPOINT}/${guide.id}/service/${serviceId}?nocache=${Math.random()}`;

    if (guide.workspaceId) {
      requestUrl = `${requestUrl}&workspaceId=${guide.workspaceId}`;
    }

    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(requestUrl, {
          params
        })
    );
  }

  getServices$(
    guide: { id: number; workspaceId?: number },
    clientId?: number | null
  ): Observable<GuidePublicService[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryParams: any = {};

    if (clientId) {
      queryParams.clientId = clientId;
    }

    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: queryParams
    });

    let requestUrl = `${this.ENDPOINT}/${guide.id}/services?nocache=${Math.random()}`;

    if (guide.workspaceId) {
      requestUrl = `${requestUrl}&workspaceId=${guide.workspaceId}`;
    }

    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<{ services: any[] }>(requestUrl, {
          params
        })
        .pipe(map(({ services }) => buildGuidePublicServices(services, this._localeService.getLocale())))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getServices(workspaceId: number, guideId: number, kind?: 'group' | 'individual') {
    return (
      this._http
        // TODO Rewrite url path
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<{ services: any[] }>(`${config.apiPath}/user/guide/services/workspace/${workspaceId}/guide/${guideId}`, {
          params: new HttpParams({
            encoder: new CustomUrlQueryEncoder(),
            fromObject: {
              // @ts-expect-error TS2322
              kind
            }
          })
        })
        .pipe(map(({ services }) => buildGuidePublicServices(services, this._localeService.getLocale())))
    );
  }
}
