<div class="module-selector">
  <div
    *ngFor="let module of programModules; let moduleIndex = index"
    role="button"
    aria-pressed="false"
    class="module-option"
    [class.active]="moduleIndex === currentModuleIndex"
    [class.complete]="module.status === 'seen'"
    [class.unavailable]="!isModuleAvailable(module)"
    [ngClass]="{
      'quiz-module': module.type === 'quiz',
      'content-module': module.type === 'content',
      'session-module': module.type === 'session',
      'group-session-module': module.type === 'group_session'
    }"
    [pui-tooltip]="isModuleAvailable(module) ? '' : unavailableModuleTooltip"
    [tabIndex]="moduleIndex"
    (click)="select.emit(module)">
    <pui-icon
      class="module-option-unavailable-icon"
      svgIcon="pui:lock-filled"
      size="s"
      *ngIf="!isModuleAvailable(module)"></pui-icon>
    <div class="module-option-icon">
      <pui-icon [svgIcon]="iconsMap[module.type]" [size]="28"></pui-icon>
    </div>
    <div class="module-option-text">
      <ng-template [ngIf]="module.type === ModuleTypes.SESSION || module.type === ModuleTypes.GROUP_SESSION">
        <app-guide-service-badge
          text="Session"
          i18n-text="@@serviceModuleSelectorNewSessionBadge"
          class="individual-session-badge"></app-guide-service-badge>
      </ng-template>
      <div>
        <ng-container
          *ngTemplateOutlet="
            moduleTitleBuilder;
            context: { $implicit: module, cssClass: 'module-title-container' }
          "></ng-container>
      </div>
    </div>
  </div>
</div>

<app-horizontal-slider class="modules-mobile" [contentHeight]="100" [contentWidth]="programModules.length * 115">
  <div class="modules" [ngStyle]="{ width: programModules.length * 115 + 'px' }">
    <div
      *ngFor="let module of programModules; let moduleIndex = index"
      class="module"
      [class.active]="moduleIndex === currentModuleIndex"
      [class.seen]="module.status === 'seen'"
      [class.allowed]="module.status === 'allowed'"
      [class.last-pip]="
        programModules[moduleIndex + 1] &&
        programModules[moduleIndex + 1].status !== 'seen' &&
        programModules[moduleIndex + 1].status !== 'allowed'
      "
      (click)="select.emit(module)">
      <div class="pip"></div>
      <ng-container i18n="@@module">Module</ng-container>
      {{ moduleIndex + 1 }}
    </div>
  </div>
</app-horizontal-slider>

<div class="modules-mobile-select">
  <ng-select
    class="ng-select"
    #moduleSelect
    [ngModel]="selectedModule"
    [searchable]="false"
    [clearable]="false"
    [items]="programModules"
    (change)="emitSelect($event)">
    <ng-template ng-label-tmp let-module="item">
      <ng-container *ngIf="currentModuleIndex >= 0">
        <ng-container
          *ngTemplateOutlet="
            moduleTitleBuilder;
            context: {
              $implicit: module,
              cssClass: 'module-title-option module-title-mobile-selector'
            }
          "></ng-container>
      </ng-container>
    </ng-template>

    <ng-template ng-option-tmp let-module="item">
      <ng-container
        *ngTemplateOutlet="
          moduleTitleBuilder;
          context: {
            $implicit: module,
            cssClass: 'module-title-option module-title-mobile-selector'
          }
        "></ng-container>
    </ng-template>
  </ng-select>
</div>

<ng-template #moduleTitleBuilder let-module let-cssClass="cssClass">
  <ng-container [ngSwitch]="module?.type">
    <div *ngSwitchCase="ModuleTypes.CONTENT" [innerHTML]="module?.title" class="{{ cssClass }}"></div>
    <div
      *ngSwitchCase="ModuleTypes.QUIZ"
      class="{{ cssClass }}"
      [innerHTML]="module?.title || 'Follow-up questions'"
      i18n-[innerHTML]="@@moduleSelectorModuleTitle"></div>
    <div *ngSwitchCase="ModuleTypes.SESSION" class="{{ cssClass }}">{{ module?.title }}</div>
    <div *ngSwitchCase="ModuleTypes.GROUP_SESSION" class="{{ cssClass }}">{{ module?.title }}</div>
  </ng-container>
</ng-template>
