import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { IRuntimeConfig } from './runtime-config-interface';
import { RuntimeConfigApiService } from './runtime-config-api.service';

@Injectable()
export class RuntimeConfigService {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _configuration: IRuntimeConfig;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _configuration$: ReplaySubject<IRuntimeConfig> = new ReplaySubject<IRuntimeConfig>(1);

  configuration$: Observable<IRuntimeConfig> = this._configuration$.asObservable();

  constructor(private readonly _runtimeConfigApiService: RuntimeConfigApiService) {}

  get<P1 extends keyof NonNullable<IRuntimeConfig>>(prop1: P1): NonNullable<IRuntimeConfig>[P1] | undefined;

  get<P1 extends keyof NonNullable<IRuntimeConfig>, P2 extends keyof NonNullable<NonNullable<IRuntimeConfig>[P1]>>(
    prop1: P1,
    prop2: P2
  ): NonNullable<NonNullable<IRuntimeConfig>[P1]>[P2] | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(...props: string[]): any {
    return (
      this._configuration &&
      props.reduce(
        // @ts-expect-error TS7053
        (result: IRuntimeConfig, prop: string) => (result == null ? undefined : result[prop]),
        this._configuration
      )
    );
  }

  load$(): Observable<IRuntimeConfig> {
    // TODO change baseUrl to grab it from window.location.host. It's currently not working on ssr mode
    return this._runtimeConfigApiService.loadConfig$().pipe(
      catchError(error => {
        return throwError(error);
      }),
      tap((configuration: IRuntimeConfig) => (this._configuration = configuration)),
      tap((configuration: IRuntimeConfig) => this._configuration$.next(configuration))
    );
  }
}
