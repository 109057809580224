import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { SharedModule } from '@app/shared';
import { BillingModule } from '@app/modules/billing/billing.module';
import { PuiLinkModule } from '@awarenow/profi-ui-core';
import { PayWithStripeComponent } from './components/pay-with-stripe/pay-with-stripe.component';
import { PayWithStripeNewComponent } from './components/pay-with-stripe-new/pay-with-stripe-new.component';
import { PayWithWalletComponent } from './components/pay-with-wallet/pay-with-wallet.component';
import { PayWithLinkComponent } from './components/pay-with-link/pay-with-link.component';
import { CurrentPaymentComponent } from './components/current-payment/current-payment.component';
import { PayWithSelectorComponent } from './components/pay-with-selector/pay-with-selector.component';
import { PayWithRequisitesComponent } from './components/pay-with-requisites/pay-with-requisites.component';
import { PayWithModalComponent } from './components/pay-with-modal/pay-with-modal.component';
import { PaymentOptionsModalComponent } from './components/payment-options-modal/payment-options-modal.component';
import { PaymentOptionsSelectorComponent } from './components/payment-options-selector/payment-options-selector.component';

@NgModule({
  imports: [
    CommonModule,
    PlatformStyleModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BillingModule,
    PuiLinkModule
  ],
  declarations: [
    PayWithStripeComponent,
    PayWithStripeNewComponent,
    PayWithSelectorComponent,
    PayWithModalComponent,
    PaymentOptionsModalComponent,
    PaymentOptionsSelectorComponent,
    CurrentPaymentComponent,
    PayWithWalletComponent,
    PayWithLinkComponent,
    PayWithRequisitesComponent
  ],
  exports: [
    PayWithStripeComponent,
    PayWithStripeNewComponent,
    PayWithSelectorComponent,
    PayWithModalComponent,
    PaymentOptionsModalComponent,
    PaymentOptionsSelectorComponent,
    CurrentPaymentComponent
  ]
})
export class CurrentPaymentModule {}
