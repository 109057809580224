import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IBookLinks, IBookWidgetInfo } from '@app/modules/embeddable-book-button/types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Injectable({ providedIn: 'root' })
export class BookShareInfoService {
  get bookLink(): string | null {
    if (!this._auth.user) {
      return null;
    }

    const { isSolo } = this._workspace;
    const namedUrl = this._auth.user.namedUrl || this._auth.user.id;

    return isSolo ? `${this._locale.getLocale().bookWidgetBaseUrl}/${namedUrl}` : `${this.teamBookLink}/${namedUrl}`;
  }

  get teamBookLink(): string | null {
    const { workspace, isSolo } = this._workspace;

    if (!workspace) {
      return null;
    }

    // @ts-expect-error TS2322
    return isSolo
      ? undefined
      : `${this._locale.getLocale().bookWidgetBaseUrl}/t/${workspace.namedUrl || workspace.workspaceId}`;
  }

  get teamBookLinkBase(): string {
    return `${this._locale.getLocale().bookWidgetBaseUrl}/t/`;
  }

  get endpoints(): IBookLinks {
    return {
      // @ts-expect-error TS2322
      personal: this.bookLink,
      // @ts-expect-error TS2322
      workspace: this.teamBookLink
    };
  }

  get bookWidgetInfo(): IBookWidgetInfo {
    return {
      stylesheet: `<link href="${this._runtimeConfigService.get('widgetSource')}/widget.css" rel="stylesheet">`,
      script: `<script src="${this._runtimeConfigService.get(
        'widgetSource'
      )}/widget.js" type="text/javascript"></script>`,
      endpoints: this.endpoints
    };
  }

  get profileLink(): string {
    return `${this._locale.getLocale().baseUrl}/${this._auth.user.namedUrl || this._auth.user.id}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get defaultBookButtonOptions(): any {
    return {
      text: 'Book a session',
      style: {
        display: 'inline-block',
        padding: '14px 28px',
        border: '0',
        fontSize: '14px',
        lineHeight: '1',
        borderRadius: '100px',
        textDecoration: 'none',
        backgroundColor: '#0388a4',
        color: '#ffffff'
      }
    };
  }

  constructor(
    private _auth: AuthService,
    private _locale: LocaleService,
    private _workspace: WorkspacesService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}
}
