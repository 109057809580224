import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { MetaTagsKeys, metaTagsMap, metaTagsNamesArray } from './types';

@Injectable()
export class MetaTagService {
  constructor(private meta: Meta) {}

  upsertMetaTags(tags: {
    [key in MetaTagsKeys]?: string;
  }): void {
    const keys = Object.keys(tags);

    metaTagsNamesArray.forEach(name => {
      if (
        keys.includes(name) &&
        (this.meta.getTag(`name="${name}"`) || this.meta.getTag(`property="${metaTagsMap[name]}"`))
      ) {
        const content = tags[name]!;

        this.meta.updateTag({ property: metaTagsMap[name], content }, `property="${metaTagsMap[name]}"`);
        this.meta.updateTag({ name, content });
      } else if (keys.includes(name)) {
        const content = tags[name]!;

        this.meta.addTag({ name, property: metaTagsMap[name], content });
      }
    });
  }

  removeTags(tags: MetaTagsKeys[]): void {
    metaTagsNamesArray.forEach(name => {
      if (
        tags.includes(name) &&
        (this.meta.getTag(`name="${name}"`) || this.meta.getTag(`property="${metaTagsMap[name]}"`))
      ) {
        this.meta.removeTag(`name="${name}"`);
        this.meta.removeTag(`property="${metaTagsMap[name]}"`);
      }
    });
  }
}
