import { NgModule } from '@angular/core';
import { LazyLoadIntegrationsService } from './services/integrations.service';
import {
  TAWK_INTEGRATION_PROVIDER,
  LOOPEDIN_INTEGRATION_PROVIDER,
  GOOGLE_ANALYTICS_INTEGRATION_PROVIDER,
  PROFITWELL_INTEGRATION_PROVIDER,
  CANDU_INTEGRATION_PROVIDER
} from './modules';

@NgModule({
  providers: [
    TAWK_INTEGRATION_PROVIDER,
    LOOPEDIN_INTEGRATION_PROVIDER,
    GOOGLE_ANALYTICS_INTEGRATION_PROVIDER,
    PROFITWELL_INTEGRATION_PROVIDER,
    CANDU_INTEGRATION_PROVIDER,
    LazyLoadIntegrationsService
  ]
})
export class IntegrationsModule {
  constructor(integrationsService: LazyLoadIntegrationsService) {
    integrationsService.load();
  }
}
