import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuizAutomationComponent } from './components/quiz-automation/quiz-automation.component';
import { ShowStatsComponent } from './components/show-stats/show-stats.component';
import { QuizWrapperComponent } from './components/quiz-wrapper/quiz-wrapper.component';
import { QuizResolver } from './resolvers/quiz-resolver';
import { QuizContentEditorComponent } from './components/quiz-content-editor/quiz-content-editor.component';

import { CanSaveQuizAndRedirectGuard, CanLeaveWithValidationGuard, CanLeaveEditorGuard } from './guards';
import { EmptyLayoutComponent } from '@app/layouts/empty-layout/empty-layout.component';
import { CanLeaveAnswersEditorGuard } from '@app/screens/client/client-programs/guards/can-leave-answers-editor.guard';
import { QuizAnswersComponent } from '@app/screens/guide/guide-surveys/components/quiz-answers/quiz-answers.component';
import { GuideClientsResolver } from '../guide-clients/guide-client/guide-clients.resolver';

export const routes: Routes = [
  {
    path: 'answers',
    component: EmptyLayoutComponent,
    children: [
      {
        path: ':id',
        component: QuizAnswersComponent,
        canDeactivate: [CanLeaveAnswersEditorGuard]
      }
    ]
  },
  {
    path: 'new',
    component: QuizWrapperComponent,
    resolve: {
      quiz: QuizResolver
    },
    children: [
      {
        path: 'settings',
        component: QuizAutomationComponent,
        canActivate: [CanSaveQuizAndRedirectGuard],
        canDeactivate: [CanLeaveWithValidationGuard]
      },
      {
        path: 'content',
        component: QuizContentEditorComponent,
        canDeactivate: [CanLeaveEditorGuard]
      },
      {
        path: 'show-stats',
        component: ShowStatsComponent,
        canActivate: [CanSaveQuizAndRedirectGuard]
      },
      {
        path: '**',
        redirectTo: 'content'
      }
    ]
  },
  {
    path: ':id',
    component: QuizWrapperComponent,
    resolve: {
      quiz: QuizResolver,
      clients: GuideClientsResolver
    },
    children: [
      {
        path: 'settings',
        canDeactivate: [CanLeaveWithValidationGuard],
        component: QuizAutomationComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'content',
        component: QuizContentEditorComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [CanLeaveEditorGuard]
      },
      {
        path: 'show-stats',
        component: ShowStatsComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: '**',
        redirectTo: 'content'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'new/settings'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuideSurveysRoutingModule {}
