import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ServiceSharingTypes } from '@app/modules/guide-service-editor/types/sharing';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ISharingSettingsForm {
  sharing: ServiceSharingTypes;
}

export { ServiceSharingTypes } from '@app/modules/guide-service-editor/types/sharing';

@Injectable()
export class GuideSharingEditorService {
  constructor(private _formBuilder: FormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createSharingSettings() {
    return this._formBuilder.group({
      sharing: [ServiceSharingTypes.Private]
    });
  }

  sharingSettingsToForm(data: ServiceSharingTypes): ISharingSettingsForm {
    return { sharing: data };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  prepareSharingData({ sharing }: ISharingSettingsForm) {
    return sharing;
  }
}
