<div class="meta-badges">
  <app-guide-service-badge
    text="Program"
    i18n-text="@@noteProgramModuleMetaBadge"
    class="program-badge"></app-guide-service-badge>
  <app-guide-service-badge
    *ngIf="moduleContainWithIndividualSession()"
    text="Individual session"
    i18n-text="@@noteProgramModuleSessionMetaBadge"
    class="individual-session-badge"></app-guide-service-badge>
</div>
<div *ngIf="metaProgramModules.programName && metaProgramModules.programId">
  <a class="program-name" [routerLink]="['client/programs', metaProgramModules.programId, 'modules']">
    {{ metaProgramModules.programName }}
  </a>
  <span class="module-name">/</span>
</div>
<div class="program-name-archived" *ngIf="metaProgramModules.programName && !metaProgramModules.programId">
  {{ metaProgramModules.programName }}
</div>
<div class="module-name">
  <ng-container *ngIf="metaProgramModules.moduleMeta.moduleTitle">
    {{ metaProgramModules.moduleMeta.moduleTitle | stripHtmlTags }}
  </ng-container>
  <ng-container *ngIf="moduleContainWithIndividualSession()">
    <span>&ldquo;{{ metaProgramModules.moduleMeta.serviceName }}&rdquo;</span>
    <span i18n="@@with">with</span>
    <span class="service-provider">{{ metaProgramModules.moduleMeta.guide.name }}</span>
  </ng-container>
</div>
