<div class="row">
  <h2 class="section-title side-col" i18n="@@myProfile">My profile</h2>

  <div class="info-block center-col">
    <ng-select
      [items]="profileTypeService.profileTypes$ | async"
      [(ngModel)]="userProfileType"
      [searchable]="false"
      [clearable]="false"
      [disabled]="isUpdating"
      (change)="changeProfileType($event)"
      bindLabel="name"
      bindValue="type"
      class="profile-type-selector">
      <ng-template ng-option-tmp let-profileType="item">
        <label class="radio-label profile-type-info">
          <span class="profile-type-name">{{ profileType.name }}</span>
          <span class="profile-type-description">{{ profileType.description }}</span>
        </label>
      </ng-template>
    </ng-select>
  </div>

  <div class="info-block center-col">
    <app-copy-value-input
      class="profile-link-container"
      [buttonText]="'Copy link'"
      i18n-[buttonText]="@@copyLinkButton"
      [value]="profileLink"
      (copied)="onCopied($event)"
      (copyFailed)="onCopyFailed()"></app-copy-value-input>
  </div>

  <div class="buttons side-col" *ngIf="!hideEditAction">
    <app-button
      className="btn-edit-primary"
      [routerLink]="['/', guideRoute, 'settings', 'edit-profile']"
      i18n="@@editPage">
      Edit page
    </app-button>
  </div>
</div>
