import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommunityLayoutComponent } from './community-layout.component';
import { UserMenuModule } from '@app/modules/user-menu/user-menu.module';
import { NotificationsModule } from '@app/modules/notifications/notifications.module';
import { AuthModule } from '@app/modules/auth/auth.module';
import { OurCommunityModule } from '@app/screens/our-community/our-community.module';
import { CommonHeaderModule } from '@app/modules/common-header/common-header.module';
import { SharedModule } from '@app/shared';
import { CommonFooterModule } from '@app/modules/common-footer/common-footer.module';

@NgModule({
  declarations: [CommunityLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    UserMenuModule,
    NotificationsModule,
    AuthModule,
    OurCommunityModule,
    CommonHeaderModule,
    CommonFooterModule,
    SharedModule
  ]
})
export class CommunityLayoutModule {}
