import { BehaviorSubject, Observable } from 'rxjs';
import { Directive, Input } from '@angular/core';

@Directive()
abstract class AbsComponentWithLoader {
  private loadingStateBeh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _loadingState: boolean;

  loadingState$: Observable<boolean> = this.loadingStateBeh$.asObservable();

  get loadingState(): boolean {
    return this._loadingState;
  }

  @Input()
  set loadingState(value: boolean) {
    this._loadingState = value;
  }
}

export { AbsComponentWithLoader };
