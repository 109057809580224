<input
  [formControl]="guideSuggestInput"
  (selectItem)="onCoachSelected($event)"
  [ngbTypeahead]="search"
  [inputFormatter]="formatter"
  [resultFormatter]="formatter"
  [editable]="false"
  [resultTemplate]="coachSuggestResultTemplate"
  type="text"
  class="form-control"
  id="coach"
  [placeholder]="'practitionerFilterPlaceholder' | transloco"
  name="coach" />
<ng-template #coachSuggestResultTemplate let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t" *ngIf="!r.countLeft; else total"></ngb-highlight>
  <ng-template #total>
    <div class="total" i18n="@@resultsMore">{{ r.countLeft }} results more</div>
  </ng-template>
</ng-template>

<ng-scrollbar appAutoHeight>
  <div class="practitioner-list">
    <app-flat-list #flatList (selectItem)="onChange($event)"></app-flat-list>
  </div>
</ng-scrollbar>
