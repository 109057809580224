import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMultiDateScheduleGroup } from '@app/modules/ui-kit/schedule';
import { ILocale } from '@env/locale.interface';
import { SCHEDULE_PROVIDER } from '../schedule-provider';
// import { locale } from '@env/locale'; // TODO: fix, bad use of locale injection
import { ISchedule, IScheduleProvider, ITimeSlot } from '../../types';
import { ScheduleTimeSlotsBuildStrategy } from '../schedule-time-slots-build-strategies';
import { ScheduleTimeSlotsTimezoneFactory } from './schedule-time-slots-timezone-factory';

import { IScheduleDateTimeSlotsUpdateOptions } from './types';

@Injectable()
export class DefaultMultiDateScheduleTimeSlotsFactory extends ScheduleTimeSlotsTimezoneFactory<
  ISchedule,
  string,
  IMultiDateScheduleGroup<ITimeSlot>
> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<
    ISchedule,
    IMultiDateScheduleGroup<ITimeSlot>
  >;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _schedule: ISchedule = { ranges: [] };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _timezone: string = DateTime.local().zoneName;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _timeSlotFormat: string | null = null;

  private locale: ILocale;

  // @ts-expect-error TS2416
  get timezone(): string | null {
    return this._timezone;
  }

  get timeSlotFormat(): string | null {
    return this._timeSlotFormat;
  }

  constructor(
    @Inject(SCHEDULE_PROVIDER) scheduleProvider: IScheduleProvider<ISchedule>,
    timeSlotsBuildStrategy: ScheduleTimeSlotsBuildStrategy<ISchedule, IMultiDateScheduleGroup<ITimeSlot>>,
    locale: ILocale
  ) {
    super(scheduleProvider, new ReplaySubject<IMultiDateScheduleGroup<ITimeSlot>[]>(1));

    if (!timeSlotsBuildStrategy) {
      throw new Error('Time slots build strategy required');
    }

    this._timeSlotsBuildStrategy = timeSlotsBuildStrategy;

    scheduleProvider.schedule$.pipe(takeUntil(this.destroy$)).subscribe(schedule => {
      this.setSchedule(schedule);
    });
    this.locale = locale;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeTimezone(timezone: string, options: IScheduleDateTimeSlotsUpdateOptions): void {
    if (this._timezone === timezone) {
      return;
    }

    this._timezone = timezone;
    this.refreshTimeSlots();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  changeTimeslotFormat(timeSlotFormat: string | null, options: IScheduleDateTimeSlotsUpdateOptions = {}) {
    this._timeSlotFormat = timeSlotFormat;

    this.refreshTimeSlots();
  }

  protected refreshTimeSlots(): void {
    if (!this._schedule || !this._schedule.ranges.length) {
      this._timeSlotsEmitter$.next([]);
    }

    this._timeSlotsEmitter$.next(
      this._timeSlotsBuildStrategy.generate(
        this._schedule,
        // @ts-expect-error TS2345
        null,
        this._timezone,
        this._timeSlotFormat,
        this.locale.dateTimeLocale
      )
    );
  }

  protected setSchedule(schedule: ISchedule): void {
    this._schedule = schedule;
    this.refreshTimeSlots();
  }
}
