import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROOT_PATH, ROUTER_OUTLET } from './routes';
import { SessionTemplateDrawerComponent } from './session-template-drawer.component';

const routes: Routes = [
  {
    path: ROOT_PATH,
    outlet: ROUTER_OUTLET,
    component: SessionTemplateDrawerComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SessionTemplateDrawerRouterModule {}
