<ul [ngClass]="{ menu: true, 'dark-text': darkText }">
  <ng-container *ngFor="let menuItem of menuConfig.navLinks">
    <li [ngClass]="{ 'dropdown-wrapper': menuItem.isDropdown, isOpen: menuItem.isOpen }">
      <a
        *ngIf="menuItem.urlType === URL_TYPE.RELATIVE"
        [routerLink]="[menuItem.url]"
        target="{{ menuItem.type === LINK_TYPE.EXTERNAL ? '_blank' : '_self' }}"
        [queryParams]="menuItem.queryParams">
        {{ menuItem.label }}
      </a>

      <ng-container *ngIf="menuItem.urlType === URL_TYPE.ABSOLUTE">
        <ng-container [ngSwitch]="menuItem.type">
          <a *ngSwitchCase="LINK_TYPE.EXTERNAL" [href]="menuItem.url" target="_blank">{{ menuItem.label }}</a>

          <a *ngSwitchCase="LINK_TYPE.INTERNAL" [href]="menuItem.url">{{ menuItem.label }}</a>

          <a *ngSwitchCase="LINK_TYPE.WIDGET" href="#" (click)="openWidgetModal($event, menuItem.url)">
            {{ menuItem.label }}
          </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="menuItem.isDropdown">
        <span
          class="dropdown-toggle-btn"
          (click)="toggleDropDownMenu(menuItem)"
          (clickOutside)="hideDropDownMenu(menuItem)"></span>
        <div class="dropdown-links" [@slideInOut]="menuItem.isOpen ? 'in' : 'out'">
          <ng-container *ngFor="let subMenuItem of menuItem.children">
            <a
              *ngIf="subMenuItem.urlType === URL_TYPE.RELATIVE"
              [routerLink]="[subMenuItem.url]"
              [queryParams]="subMenuItem.queryParams"
              [ngStyle]="{
                'background-color':
                  menuItem.isOpen && !darkText
                    ? menuConfig.mobileMenuBackgroundColor
                    : darkText
                    ? '#fff'
                    : 'transparent'
              }"
              target="{{ subMenuItem.type === LINK_TYPE.EXTERNAL ? '_blank' : '_self' }}">
              {{ subMenuItem.label }}
            </a>

            <ng-container *ngIf="subMenuItem.urlType === URL_TYPE.ABSOLUTE">
              <ng-container [ngSwitch]="subMenuItem.type">
                <a
                  *ngSwitchCase="LINK_TYPE.EXTERNAL"
                  [href]="subMenuItem.url"
                  target="_blank"
                  [ngStyle]="{
                    'background-color':
                      menuItem.isOpen && !darkText
                        ? menuConfig.mobileMenuBackgroundColor
                        : darkText
                        ? '#fff'
                        : 'transparent'
                  }">
                  {{ subMenuItem.label }}
                </a>
                <a
                  *ngSwitchCase="LINK_TYPE.INTERNAL"
                  [href]="subMenuItem.url"
                  [ngStyle]="{
                    'background-color':
                      menuItem.isOpen && !darkText
                        ? menuConfig.mobileMenuBackgroundColor
                        : darkText
                        ? '#fff'
                        : 'transparent'
                  }">
                  {{ subMenuItem.label }}
                </a>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </li>
  </ng-container>
</ul>
