import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToDropdownMenuOptionsPipe } from './to-dropdown-menu-options.pipe';

@NgModule({
  declarations: [ToDropdownMenuOptionsPipe],
  exports: [ToDropdownMenuOptionsPipe],
  imports: [CommonModule]
})
export class DropdownMenuModule {}
