import { NgModule } from '@angular/core';
import { UploadImageModalComponent } from './upload-image-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PuiButtonModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UploadImageModalComponent],
  imports: [ImageCropperModule, PuiButtonModule, TranslocoModule, CommonModule, PuiIconModule],
  exports: [UploadImageModalComponent]
})
export class UploadImageModalModule {}
