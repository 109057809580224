import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { catchError, tap } from 'rxjs/operators';

import config from '@app/core/config/config';

@Injectable()
export class RecoverService {
  constructor(private http: HttpClient) {}

  /**
   * Recover Password Create Hash.
   *
   * @param {string} email
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createHash(email: string): Observable<any> {
    return this.http.post(`${config.apiPath}/auth/recover/create-hash`, { email }).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Recover Password Check Hash.
   *
   * @param {string} email
   * @param {string} hash
   * @param {string} password
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkHash(email: string, hash: string, password: string): Observable<any> {
    return this.http.post(`${config.apiPath}/auth/recover/check-hash`, { email, hash, password }).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
