import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@app/shared';
import { SharedModule as SharedCoreModule } from '@libs/shared/shared.module';

import { ServiceDateTimeModalComponent } from './components/service-date-time-modal/service-date-time-modal.component';
import { EventScheduleFormComponent } from './components/event-schedule-form/event-schedule-form.component';
import { EventDateSelectorComponent } from './components/event-date-selector/event-date-selector.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, NgSelectModule, ReactiveFormsModule, SharedModule, SharedCoreModule],
  declarations: [EventScheduleFormComponent, ServiceDateTimeModalComponent, EventDateSelectorComponent],
  exports: [EventScheduleFormComponent, ServiceDateTimeModalComponent, EventDateSelectorComponent]
})
export class GuideSessionModule {}
