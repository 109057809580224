<ng-container *ngIf="{ active: active$ | async, tags: tags$ | async } as data">
  <pui-select
    [ngModel]="data.active"
    (ngModelChange)="selectTag($event)"
    [multiple]="true"
    [class.empty]="!data.active?.length"
    i18n-puiPlaceholder="@@focusArea"
    puiPlaceholder="Focus area">
    <pui-option *ngFor="let tag of filterTag(data.active, data.tags)" [value]="tag" [label]="tag"></pui-option>
    <pui-option *ngFor="let tag of data.tags" [value]="tag" [label]="tag"></pui-option>
  </pui-select>
</ng-container>
