// eslint-disable-next-line max-classes-per-file
import { IWorkspace } from '@app/modules/workspaces/types';
import { IGuidePaymentInfo } from '@app/screens/admin/components/guide-wallet/types';
import { Languages } from '@app/shared/enums/languages';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SubscriptionStatus } from '@app/shared/enums/subscription';
import { IQualification } from '@app/shared/interfaces/qualification';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { IUser } from '@app/shared/interfaces/user';
import { GlobalConfig, ICommunityConfig } from '@cnf/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChartItemValue {
  name: string;
  value: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IChart {
  [index: number]: IChartItemValue;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPage {
  size: number;
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  orderBy: string;
  orderDir: string;
  search?: string;
}

export type Pagination = {
  totalElements: number;
  totalPages: number;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPlatformSubscription {
  id: number;
  amount?: string;
  status: SubscriptionStatus;
  cancelationReason?: string;
  name: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
  hasInvoices?: boolean;
  platformSubscriptionsInvoices: IPlatformSubscriptionInvoice[];
  workspace?: Pick<IWorkspace, 'name' | 'type' | 'id'>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPlatformSubscriptionInvoice {
  amount: string | number;
  status: string;
  invoicePaidAt: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoiceUrl: any;
  chargeId: string | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSideClient {
  id: number;
  firstName: string;
  free: boolean;
  lastName: string;
  photo: string;
  role: string;
  email: string;
  dateOfRegistration: string;
  language: { id: number; name: string };
  phone: string;
  isPlatformPromoter: boolean;
  hasGuideAccount: boolean;
  session: {
    approvedSessionsCount: number;
    pendingSessionCount: number;
    doneSessionCount: number;
    totalCost: number;
    totalSessionTime: number;
  };
  token: string;
  isActiveUser?: boolean;
  platformSubscriptions: {
    id: number;
    amount?: number;
    recurrency?: string;
    status: SubscriptionStatus;
    cancelationReason?: string;
    free: boolean;
    isTrial: boolean;
    endless: boolean;
    name: string;
    currentPeriodEnd?: string;
    platformSubscriptionsInvoices: {
      amount: number;
      status: string;
      invoicePaidAt: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      invoiceUrl: any;
      chargeId: string | null;
    }[];
  }[];
  additionalInfo: string;
  referrer: string;
  landing: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmHost: string;
  test: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClient {
  id: number;
  firstName: string;
  lastName: string;
  readonly name: string;
  languageId: number;
  language: string;
  email: string;
  phone: string;
  registrationDate: string;
  photo: string;
  approvedSessionsCount: number;
  doneSessionsCount: number;
  pendingSessionsCount: number;
  totalCost: number;
  totalSessionsTime: number;
  isFree: boolean;
  isBlocked?: boolean;
  isPlatformPromoter: boolean;
  hasGuideAccount: boolean;
  platformSubscriptions: IPlatformSubscription[];
  additionalInfo: string;
  referrer: string;
  landing: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmHost: string;
  test: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSideGuide {
  expert: boolean;
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  role: string;
  email: string;
  emailVerified?: boolean;
  phone: string;
  phoneVerified?: boolean;
  skype: string;
  linkedIn: string;
  dateOfRegistration: string;
  secondStep: boolean;
  confirmed: boolean;
  topRated: boolean;
  language: { id: number; name: string };
  sessions: {
    approvedSessions: number;
    doneSessions: number;
    pendingSessions: number;
  };
  token: string;
  marketplaceServiceFee: number;
  coachClientsServiceFee: number;
  wallet: number;
  qualification: number;
  profileState: string;
  profileType: string;
  freeAllSessions: boolean;
  free: boolean;
  isActiveUser?: boolean;
  isPlatformPromoter: boolean;
  platformSubscriptions: {
    id: number;
    amount?: number;
    recurrency?: string;
    status: SubscriptionStatus;
    cancelationReason?: string;
    free: boolean;
    isTrial: boolean;
    endless: boolean;
    name: string;
    currentPeriodStart?: string;
    currentPeriodEnd?: string;
    platformSubscriptionsInvoices: {
      amount: number;
      status: string;
      invoicePaidAt: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      invoiceUrl: any;
      chargeId: string | null;
    }[];
    workspaceName?: IWorkspace['name'];
    workspaceType?: IWorkspace['type'];
    workspaceId?: IWorkspace['id'];
  }[];
  additionalInfo?: string;
  referrer: string;
  landing: string;
  utmMedium: string;
  utmSource: string;
  utmCampaign: string;
  utmTerm: string;
  utmHost: string;
  test: boolean;
  workspace: IWorkspace & { createdAt: string; wallet: string };
  workspacesCount: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuide {
  id: number;
  firstName: string;
  lastName: string;
  readonly name: string;
  photo: string;
  language: string;
  email: string;
  emailVerified?: boolean;
  phone: string;
  phoneVerified?: boolean;
  skype: string;
  linkedIn: string;
  registrationDate: string;
  isConfirmed: boolean;
  isExpert: boolean;
  isTopRated: boolean;
  totalCost: number;
  marketplaceServiceFee: number;
  coachClientsServiceFee: number;
  approvedSessionsCount: number;
  doneSessionsCount: number;
  pendingSessionsCount: number;
  qualification: number;
  profileState: string;
  profileType: string;
  freeAllSessions: boolean;
  isFree: boolean;
  isBlocked?: boolean;
  isPlatformPromoter: boolean;
  platformSubscriptions: IPlatformSubscription[];
  additionalInfo?: string;
  referrer: string;
  landing: string;
  utmMedium: string;
  utmSource: string;
  utmCampaign: string;
  utmTerm: string;
  utmHost: string;
  test: boolean;
  workspace: IWorkspace & { createdAt: string; actionsVisible: boolean; wallet: string };
  workspacesCount: number;
}

export type User = IClient | IGuide;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSideUsers<T> {
  rows: T[];
  count: number;
  page: IPage;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUsers<T> {
  users: T[];
  count: number;
  page: IPage;
}

export class IOptionalSearch {
  search?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export interface IQueryParams<T = unknown> extends IOptionalSearch {
  sortBy?: string;
  limit?: number;
  offset?: number;
  orderBy?: string;
  sortDir?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideQueryParams extends IQueryParams<GuidesSortOptions> {
  unconfirmedOnly?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionUser {
  id: number;
  firstName: string;
  lastName: string;
  readonly name?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSession {
  client: ISessionUser;
  createdAt: string;
  date: string;
  declineData: { message: string };
  duration: number;
  free: boolean;
  guide: ISessionUser;
  id: number;
  payRate: number;
  payments?: ISessionPayment[];
  status: SessionStatuses;
  template: { name: string };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSessionResponse extends Pick<IServerResponseBase<IServerSession>, 'rows'> {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerResponseBase<T> {
  rows: T[];
  count: number;
  page: IPage;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerSessions extends IServerResponseBase<IServerSession> {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISession {
  client: string; // = client full name
  date: string;
  declineMessage: string;
  guide: string; // = guide full name
  id: number;
  payRate: string;
  payments?: ISessionPayment[];
  status: SessionStatuses;
  template: string;
  time: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessions extends Omit<IServerResponseBase<ISession[]>, 'rows'> {
  sessions: ISession[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ITag {
  id: number;
  name: string;
  description: string;
  status?: boolean;
  parents?: number[];
}

export enum PricingType {
  ONE_TIME = 'One time',
  SUBSCRIPTION = 'Subscription'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPaymentSession {
  id: number;
  date: string;
  duration: number;
  status: string;
  type: GuideServiceTypes;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuidePayment {
  id: number;
  amount: number;
  guideId: number;
  serviceFee: number;
  guideFee: number;
  type: string;
  status: string;
  date: string;
  pricingType?: PricingType;
  client: IUser;
  session: IPaymentSession;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientPayment {
  id: number;
  amount: number;
  serviceFee: number;
  type: string;
  status: string;
  date: string;
  guide: IUser;
  session: IPaymentSession;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideWalletInfo {
  availableBalance: number;
  balance: number;
  payments: IGuidePaymentInfo[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideWallet {
  availableBalance: number;
  balance: number;
  payments: IGuidePayment[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IOtherPaymentOptions {
  otherPaymentEnabled: boolean;
  paymentLink: string;
  paymentMethod: OtherPaymentMethods;
  paymentRequisites: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPaymentsFilter extends IOptionalSearch {
  sort?: string;
  sortDirection?: string;
  guideId?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientWallet {
  balance: number;
  payments: IClientPayment[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISearchSort extends IOptionalSearch {
  sort?: string;
  sortDirection?: string;
  limit?: string;
  offset?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGiftCoupon {
  couponId: number;
  value: number;
  isActivated: boolean;
  receiverEmail: string;
  receiverName: string;
  senderEmail: string;
  senderName: string;
  senderComment: string;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGiftCouponData {
  giftCoupons: IGiftCoupon[];
  page: IPage;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGetProgramsOptions {
  limit: string;
  offset: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionPayment {
  id: number;
  clientId: number;
  guideId: number;
  sessionId: number;
  amount: number;
  guideFee: number;
  serviceFee: number;
  description: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBranding {
  communityConfig: ICommunityConfig;
  favicon?: string;
  globalConfig: GlobalConfig;
  isGuideBranding?: boolean;
  language: Languages;
  mainLogo: string;
  menuConfig: IMenuConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentOptions: any;
  qualifications: IQualification[];
  whiteLogo: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideBranding {
  mainLogo: string;
  favicon?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMenuLink {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  url?: string | any;
  urlType?: 'absolute' | 'relative';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams?: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: string | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: MenuLinkTypes | any;
  children?: IMenuLink[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visibleFor?: IVisibleFor | any;
  availableRoles?: number[];
  isDropdown?: boolean;
  isOpen?: boolean;
  iconName?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IVisibleFor {
  none: boolean;
  client: boolean;
  guide: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMenuConfig {
  headerBackground?: string;
  mobileMenuBackgroundColor?: string;
  navLinks?: IMenuLink[];
  footerLinks?: IMenuLink[];
  facebook?: string;
  twitter?: string;
  instagram?: string;
  linkedIn?: string;
  enableMadeWithLove?: boolean;
  subscribeBtnGradientStart?: string;
  subscribeBtnGradientEnd?: string;
  socialLinksColor?: string;
  copyRights?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IMenuData {
  data: IMenuConfig;
  language: string;
}

export interface AllowedSetting {
  id: number;
  name: string;
  description: string;
  type: PlanSettingTypes;
  options: {
    id: number;
    name: string;
    description: string;
    special: boolean;
  }[];
}

export enum PlanSettingTypes {
  NUMBER = 'number',
  TEXT = 'text',
  SELECT = 'select',
  BOOLEAN = 'boolean'
}

export class WikiCategory {
  // @ts-expect-error TS2564
  id: number;
  // @ts-expect-error TS2564
  name: string;
}

export class WikiTermTag {
  // @ts-expect-error TS2564
  id: number;
  // @ts-expect-error TS2564
  name: string;
}

export class WikiTerm {
  // @ts-expect-error TS2564
  id: number;
  // @ts-expect-error TS2564
  categoryId: number;
  category?: WikiCategory;
  // @ts-expect-error TS2564
  name: string;
  // @ts-expect-error TS2564
  description: string;
  // @ts-expect-error TS2564
  descriptionRepresentation: string;
  // @ts-expect-error TS2564
  tags: WikiTermTag[];
}

export class WikiTermUpdate {
  id?: number;
  // @ts-expect-error TS2564
  categoryId: number;
  // @ts-expect-error TS2564
  name: string;
  // @ts-expect-error TS2564
  description: string;
  // @ts-expect-error TS2564
  descriptionRepresentation: string;
  // @ts-expect-error TS2564
  tags: number[];
}

export enum ClientsSortOptions {
  LANGUAGE = 'language',
  REGISTRATION_DATE = 'dateOfReg',
  TOTAL_COST = 'totalCost',
  NAME = 'name',
  ID = 'id'
}

export enum ProgramsSortOptions {}
export enum GuidesSortOptions {
  ID = 'id',
  LANGUAGE = 'language',
  REGISTRATION_DATE = 'dateOfReg',
  TOTAL_COST = 'totalCost',
  NAME = 'name',
  EXPERT = 'expert',
  CONFIRMED = 'confirmed',
  FREE_ALL_SESSIONS = 'freeAllSessions'
}

export enum PaymentTypes {
  PREPAYMENT = 'prepayment',
  PAYMENT = 'payment',
  REFUNDED_PREPAYMENT = 'refunded_prepayment',
  REFUNDED_PAYMENT = 'refunded_payment',
  REFUNDED_WALLET_PAYMENT = 'refunded_wallet_payment',
  REFUNDED_WALLET_PREPAYMENT = 'refunded_wallet_prepayment',
  REFUNDED_PAYPAL_PAYMENT = 'refunded_paypal_payment',
  WALLET_PAYMENT = 'wallet_payment',
  WALLET_PREPAYMENT = 'wallet_prepayment',
  WITHDRAWAL = 'withdrawal'
}

export enum PaymentStatuses {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed'
}

export enum MenuLinkTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  WIDGET = 'widget'
}

export enum OtherPaymentMethods {
  PAYMENT_LINK = 'payment_link',
  PAYMENT_REQUISITES = 'payment_requisites'
}
