import { Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { puiDefaultProp, PuiDestroyService } from '@awarenow/profi-ui-core';
import { SubscriptionPaymentDateService } from '@app/screens/guide/guide-programs/features/update-subscription/services/subscription-payment-date.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SetPaymentDateTargetType } from '@app/screens/guide/guide-programs/features/update-subscription/types';

@Directive({
  selector: '[setPaymentDate]',
  providers: [PuiDestroyService]
})
export class SetPaymentDateDirective {
  /**
   * The payment date change event.
   */
  @Output()
  paymentDateChange = new EventEmitter<Date>();

  /**
   * The program ID for the subscription.
   */
  @Input()
  @puiDefaultProp()
  targetId!: number;

  @Input()
  targetType!: SetPaymentDateTargetType;

  /**
   * The customer ID for the subscription.
   */
  @Input()
  @puiDefaultProp()
  customerId!: number;

  /**
   * The next payment date for the subscription.
   */
  @Input()
  @puiDefaultProp()
  nextPaymentDate!: Date | string;

  constructor(
    private subscriptionPaymentDateService: SubscriptionPaymentDateService,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  @HostListener('click')
  onClick() {
    this.subscriptionPaymentDateService
      .setPaymentDate({
        customerId: this.customerId,
        targetId: this.targetId,
        targetType: this.targetType,
        paymentDate: this.nextPaymentDate
      })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.paymentDateChange.emit(result);
        }
      });
  }
}
