import { ModuleTypes } from '../../program-module';
import { BaseSessionModule, IBaseSessionModuleDetails } from './base-session-module';
import { OpenedModuleStatus } from './module-statuses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionModuleDetails extends IBaseSessionModuleDetails {}

export class SessionModule extends BaseSessionModule {
  // @ts-expect-error TS2564
  readonly status: OpenedModuleStatus;

  get type(): ModuleTypes {
    return ModuleTypes.SESSION;
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(moduleDetails: Readonly<ISessionModuleDetails>) {
    super(moduleDetails);
  }
}
