import { Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  get isUnconfirmedGuide(): boolean {
    return (
      this._auth.isAuthorized &&
      this._auth.user.RoleId === UserRoles.GUIDE &&
      this._auth.user.profileType === GuideProfileTypes.PUBLIC &&
      !this._auth.user.confirmed
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get isAuth() {
    return this._auth.isAuthorized;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private _auth: AuthService, private renderer: Renderer2, @Inject(DOCUMENT) private _document: any) {}

  hideMenu(): void {
    this.renderer.removeClass(this._document.body, 'menu-expanded');
  }
}
