import { ClientGuide } from '@app/core/users/types';
import {
  AvailableSession,
  Session,
  isAvailableSession,
  isSimpleSession,
  isGroupSession
} from '@app/shared/interfaces/session';
import { IClientGuideSession } from '../types/types';

export type ClientGuidesMap = {
  [id: number]: ClientGuide & { [workspaceId: number]: ClientGuide };
};

export function buildClientGuidesMap(guides: ClientGuide[]): ClientGuidesMap {
  return guides.reduce((dict: { [key: string]: ClientGuide }, guide) => {
    const guideSoloOrWorkspace = dict[guide.id] || guide;
    return { ...dict, [guide.id]: { ...guideSoloOrWorkspace, [guide.workspaceId]: guide } };
  }, {});
}

// @ts-expect-error TS7006
export function prepareClientGuideSession(sessions: Session[], timezone: string, guidesMap): IClientGuideSession[] {
  return sessions.reduce((clientGuideSessions, session) => {
    if (isSimpleSession(session) || isGroupSession(session)) {
      // @ts-expect-error TS2322
      clientGuideSessions.push({ guide: guidesMap?.[session.guideId]?.[session.guide.workspaceId], timezone, session });
    }

    if (isAvailableSession(session)) {
      clientGuideSessions.push({
        // @ts-expect-error TS2322
        guide: guidesMap?.[(session as AvailableSession).guideId]?.[(session as AvailableSession).workspace.id],
        // @ts-expect-error TS2322
        timezone,
        session
      });
    }

    return clientGuideSessions;
  }, []);
}

export function splitToKnownAndUnknownGuideSessions(sessions: IClientGuideSession[]): {
  knownGuideSessions: IClientGuideSession[];
  unknownGuideSessions: IClientGuideSession[];
} {
  return sessions.reduce(
    (
      knownUnknown: { knownGuideSessions: IClientGuideSession[]; unknownGuideSessions: IClientGuideSession[] },
      session
    ) => {
      const arr = session.guide ? knownUnknown.knownGuideSessions : knownUnknown.unknownGuideSessions;
      arr.push(session);

      return knownUnknown;
    },
    { knownGuideSessions: [], unknownGuideSessions: [] }
  );
}
