import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { BlogAuthorGuide, IBlogTag, IBookEvent } from '../../types';
import { isPlatformBrowser } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { environment } from '@env/environment';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-blog-article-content',
  templateUrl: './blog-article-content.component.html',
  styleUrls: ['./blog-article-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogArticleContentComponent implements AfterViewInit {
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  article: any;

  @Output()
  bookSession = new EventEmitter<IBookEvent>();

  @Output()
  openAuthor = new EventEmitter<string>();

  @Output()
  selectTag = new EventEmitter<IBlogTag>();

  private isBrowser = false;

  get isArticleAuthorGuide(): boolean {
    return !!this.article && !!this.article.author && this.article.author instanceof BlogAuthorGuide;
  }

  get isAddThisEnabled(): boolean {
    return !!this._runtimeConfigService.get('ADDTHIS_PROFILE_ID');
  }

  constructor(
    private router: Router,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private platformId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(GLOBAL_WINDOW) private window: Window | any,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngAfterViewInit() {
    try {
      if (
        this.isBrowser &&
        this.window.addthis &&
        this.window.addthis.layers &&
        typeof this.window.addthis.layers.refresh === 'function'
      ) {
        this.window.addthis.layers.refresh();
      }
      // eslint-disable-next-line id-length
    } catch (e) {}
  }

  emitBookSession(event: IBookEvent): void {
    this.bookSession.emit(event);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitOpenAuthor(namedUrl: string) {
    this.openAuthor.emit(namedUrl);
  }

  emitSelectTag(tag: IBlogTag): void {
    this.selectTag.emit(tag);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, id-length
  handleOGClick(e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ogAnchor: any = this.findOGAnchor(e.target);

    if (ogAnchor && ogAnchor.href) {
      this.router.navigateByUrl(`${ogAnchor.pathname}${ogAnchor.search}${ogAnchor.hash}`);
    }
  }

  // @ts-expect-error TS7023
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private findOGAnchor(element) {
    if (element.nodeName && element.nodeName.toLowerCase() === 'a') {
      return element;
    }

    if (!element.parentNode) {
      return null;
    }

    return this.findOGAnchor(element.parentNode);
  }
}
