import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-no-active-chat',
  templateUrl: './no-active-chat.component.html',
  styleUrls: ['./no-active-chat.component.scss']
})
export class NoActiveChatComponent {
  UserRoles = UserRoles;
  userRole: UserRoles;

  constructor(private authService: AuthService, private _router: Router) {
    this.userRole = this.authService.user.RoleId;
  }
}
