<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.user?.photo"
    [text]="notification.details?.user | userName"></pui-avatar>
  <dt class="notification-title">
    <span class="notification-name">
      <ng-container *ngIf="notification.type !== NOTIFICATION_TYPE.PROGRAM_COMPLETE">
        {{ notification.details.user?.firstName }} {{ notification.details?.user?.lastName }}
      </ng-container>
    </span>
    <span class="notification-date">
      {{ notification.dateCreated | notificationDateFormatter }}
    </span>
  </dt>
  <dd>
    <span class="description-text">
      {{ getNotificationMessage(notification.type) }}

      <app-button
        className="btn-link"
        [ngClass]="{ 'notification-link__exclamation-point': notification.type === NOTIFICATION_TYPE.PROGRAM_COMPLETE }"
        routerLink="/programs/{{ notification.details?.programId }}">
        {{ notification.details?.title | clearHtml }}
      </app-button>
    </span>
  </dd>
</dl>
