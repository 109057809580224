import { Injectable } from '@angular/core';
import { refundPaymentTypes } from '@app/shared/constants/refund-payment-types';
import { Transaction } from '@app/core/payments/types';
import { PaymentsUserTypes } from '@app/modules/payment/payments-grid/types';
import { PaymentTypes } from '@app/shared/enums/payment-types';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  readonly PaymentsUserTypes: typeof PaymentsUserTypes = PaymentsUserTypes;

  isPaymentRefund(payment: Transaction['payment']): boolean {
    return refundPaymentTypes.includes(payment.type);
  }

  isPaymentWithdrawal(payment: Transaction['payment']): boolean {
    return PaymentTypes.WITHDRAWAL === payment.type;
  }

  // @ts-expect-error TS7006
  getPaymentAmountSign(payment: Transaction['payment'], userType): string {
    if (payment.amount === 0) {
      return '';
    }

    if (userType === this.PaymentsUserTypes.CLIENT) {
      return this.isPaymentRefund(payment) ? '+' : '-';
    }

    return this.isPaymentRefund(payment) || this.isPaymentWithdrawal(payment) ? '-' : '+';
  }
}
