import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { Session } from '@app/shared/interfaces/session';
import { DateTime } from 'luxon';
import { PlatformStyle } from '@platformStyle/services/platform-style.service';

const rescheduleRequest = `Reschedule request`;
const cancelRequest = `Cancel request`;
const askToReschedule = `Ask to reschedule`;
const cancelSession = `Cancel session`;
const bookAnotherSession = `Book another session`;
const proposeAnotherTime = `Propose another time`;
const declineOffer = `Decline offer`;

const actionTypes = {
  BOOK: 'book',
  CANCEL: 'cancel',
  DECLINE: 'decline',
  RESCHEDULE: 'reschedule',
  ACCEPT: 'accept',
  ACCEPT_RESCHEDULING: 'accept_rescheduling'
};

const icons = {
  RESCHEDULE: 'pui:rotate-right',
  CANCEL: 'pui:x-circle',
  ADD: 'pui:plus-circle'
};

const colors = {
  ACCENT: 'warning'
};

const requestIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: rescheduleRequest,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.CANCEL,
    text: cancelRequest,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const requestGroupActions = [
  {
    icon: icons.CANCEL,
    text: cancelRequest,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const approvedIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: askToReschedule,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.ADD,
    text: bookAnotherSession,
    className: 'icon-add',
    action: actionTypes.BOOK
  },
  {
    icon: icons.CANCEL,
    text: cancelSession,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const approvedGroupActions = [
  {
    icon: icons.ADD,
    text: bookAnotherSession,
    className: 'icon-add',
    action: actionTypes.BOOK
  },
  {
    icon: icons.CANCEL,
    text: cancelSession,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const guideOfferIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: proposeAnotherTime,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.CANCEL,
    text: declineOffer,
    className: 'icon-cancel',
    action: actionTypes.CANCEL,
    color: colors.ACCENT
  }
];

const guideOfferGroupActions = [
  {
    icon: icons.CANCEL,
    text: declineOffer,
    className: 'icon-cancel',
    action: actionTypes.CANCEL,
    color: colors.ACCENT
  }
];

const guideRescheduleIndividualActions = [
  {
    icon: icons.RESCHEDULE,
    text: askToReschedule,
    className: 'icon-reschedule',
    action: actionTypes.RESCHEDULE
  },
  {
    icon: icons.CANCEL,
    text: declineOffer,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

const guideRescheduleGroupActions = [
  {
    icon: icons.CANCEL,
    text: declineOffer,
    className: 'icon-cancel',
    action: actionTypes.DECLINE,
    color: colors.ACCENT
  }
];

@Component({
  selector: 'app-in-chat-client-session-details',
  templateUrl: './in-chat-client-session-details.component.html',
  styleUrls: ['./in-chat-client-session-details.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-in-chat-client-session-details' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InChatClientSessionDetailsComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any[] = [];

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _session: Session;

  actionTypes = actionTypes;

  @Input() set session(session: Session) {
    this._session = session;
    if (this._session) {
      switch (this._session.status) {
        case SessionStatuses.APPROVED:
          if (this.canReschedule()) {
            this.actions = approvedIndividualActions;
            break;
          }
          this.actions = approvedGroupActions;
          break;
        case SessionStatuses.PENDING_APPROVEMENT:
        case SessionStatuses.RESCHEDULE_BY_CLIENT:
          if (this.canReschedule()) {
            this.actions = requestIndividualActions;
            break;
          }
          this.actions = requestGroupActions;
          break;
        case SessionStatuses.GUIDE_OFFER:
          if (this.canReschedule()) {
            this.actions = guideOfferIndividualActions;
            break;
          }
          this.actions = guideOfferGroupActions;
          break;
        case SessionStatuses.RESCHEDULE_BY_GUIDE:
          if (this.canReschedule()) {
            this.actions = guideRescheduleIndividualActions;
            break;
          }
          this.actions = guideRescheduleGroupActions;
          break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get session() {
    return this._session;
  }

  @Input()
  // @ts-expect-error TS2564
  doesBookAllowed: boolean;

  @Output()
  accept = new EventEmitter();

  @Output()
  approveReschedulement = new EventEmitter();

  @Output()
  book = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  @Output()
  decline = new EventEmitter();

  @Output()
  reschedule = new EventEmitter();

  get date(): string {
    if (this._session) {
      const timezone = DateTime.local().zoneName;
      return this.style.datetimeRange([this._session.dateStart], 'DATE, TIME', {
        timezone,
        localeParams: this.style.locale,
        durationInMinutes: this._session.duration
      });
    }
    return '';
  }

  get enableSessionApprovedSection(): boolean {
    return !!this.session && [SessionStatuses.APPROVED].includes(this.session.status);
  }

  get enableSessionInvitationSection(): boolean {
    return (
      !!this.session && [SessionStatuses.GUIDE_OFFER, SessionStatuses.RESCHEDULE_BY_GUIDE].includes(this.session.status)
    );
  }

  get enableSessionRequestSection(): boolean {
    return (
      !!this.session &&
      [SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(this.session.status)
    );
  }

  get isIndividualSessionRequest(): boolean {
    return (
      !!this._session &&
      [SessionStatuses.GUIDE_OFFER, SessionStatuses.RESCHEDULE_BY_GUIDE].includes(this._session.status) &&
      this._session.serviceType === SessionTypes.SESSION
    );
  }

  get isGroupSessionRequest(): boolean {
    return (
      !!this._session &&
      [SessionStatuses.GUIDE_OFFER, SessionStatuses.RESCHEDULE_BY_GUIDE].includes(this._session.status) &&
      this._session.serviceType === SessionTypes.GROUP_INSTANCE
    );
  }

  get isReschedulement(): string {
    return !!this._session && this._session.status === SessionStatuses.RESCHEDULE_BY_GUIDE
      ? actionTypes.ACCEPT_RESCHEDULING
      : actionTypes.ACCEPT;
  }

  constructor(private style: PlatformStyle) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  actionHandler(type: string) {
    switch (type) {
      case actionTypes.BOOK:
        this.book.emit();
        break;
      case actionTypes.ACCEPT:
        this.accept.emit();
        break;
      case actionTypes.CANCEL:
        this.cancel.emit();
        break;
      case actionTypes.DECLINE:
        this.decline.emit();
        break;
      case actionTypes.RESCHEDULE:
        this.reschedule.emit();
        break;
      case actionTypes.ACCEPT_RESCHEDULING:
        this.approveReschedulement.emit();
        break;
    }
  }

  private canReschedule(): boolean {
    return !(!!this._session.payRate || this._session.isRecurrent);
  }
}
