import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { QuizClientsPolicy, QuizEntityPolicy, QuizSendPolicy } from '@app/core/quizzes/types';
import { QuizProgramsService } from '../../services/quiz-programs.service';
import { SurveyFormService } from '../../services/survey-form.service';
import { QuizSessionsService } from '../../services/quiz-sessions.service';
import { minLengthArray } from '@app/shared/form-validators';

@Component({
  selector: 'app-quiz-settings',
  templateUrl: './quiz-settings.component.html',
  styleUrls: ['../quiz-basic-info/quiz-basic-info.component.scss', './quiz-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [QuizSessionsService]
})
export class QuizSettingsComponent implements OnDestroy, OnInit {
  readonly QuizSendPolicy = QuizSendPolicy;
  readonly QuizClientsPolicy = QuizClientsPolicy;
  readonly QuizEntityPolicy = QuizEntityPolicy;

  readonly form = this.surveyFormService.quizForm.controls.settings as FormArray;

  readonly universalServices$ = this.quizSessionsService.getGuideServices$();
  readonly universalServicesLoading$ = this.quizSessionsService.loading$;

  readonly universalPrograms$ = this.quizProgramsService.getGuidePrograms$().pipe(
    map(programs => [
      {
        name: null,
        children: programs
      }
    ])
  );
  readonly universalProgramsLoading$ = this.quizProgramsService.loading$;

  readonly emptySessionListModalTitle = `No sessions found`;
  readonly emptySessionListModalDescription = `Currently, there are no sessions in your services. You can apply this rule to all future sessions by selecting All sessions. If you want to apply this rule to a specific session, first, go to services and create a new session.`;

  readonly emptyProgramsListModalTitle = `No programs found`;
  readonly emptyProgramsListModalDescription = `Currently, there are no programs in your services. You can apply this rule to all future programs by selecting All programs. If you want to apply this rule to a specific program, first, go to services and create a new program.`;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly quizSessionsService: QuizSessionsService,
    private readonly quizProgramsService: QuizProgramsService,
    private readonly formBuilder: FormBuilder,
    private readonly surveyFormService: SurveyFormService
  ) {}

  ngOnInit(): void {
    this.setValidators();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addRow(): void {
    this.form.push(
      this.formBuilder.group({
        id: [null, []],
        // Trigger
        sendPolicy: [null, [Validators.required]],
        // Send to
        clientsPolicy: [{ value: null, disabled: true }, []],
        // What to send
        entityPolicy: [QuizEntityPolicy.All, [Validators.required]],

        programs: [[], []],
        sessions: [[], []],
        schedule: [[], []]
      })
    );
  }

  deleteRow(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  handleSendPolicyChanged(value: QuizSendPolicy, index: number): void {
    if (value !== null) {
      this.form.controls[index].get('clientsPolicy')?.enable();
    }

    this.form.controls[index].get('clientsPolicy')?.setValue(QuizClientsPolicy.All);
    this.form.controls[index].get('entityPolicy')?.setValue(QuizEntityPolicy.All);
    this.handleEntityPolicyChanged(QuizEntityPolicy.All, index);
  }

  handleEntityPolicyChanged(value: QuizEntityPolicy, index: number): void {
    const sessionsControl = this.form.controls[index].get('sessions');
    const programsControl = this.form.controls[index].get('programs');

    if (value === QuizEntityPolicy.All) {
      sessionsControl?.setValue([]);
      programsControl?.setValue([]);

      sessionsControl?.clearValidators();
      programsControl?.clearValidators();

      sessionsControl?.updateValueAndValidity();
      programsControl?.updateValueAndValidity();
    }

    if (value === QuizEntityPolicy.SelectedPrograms) {
      sessionsControl?.setValue([]);
      sessionsControl?.clearValidators();
      programsControl?.setValidators([minLengthArray(1)]);
    }

    if (value === QuizEntityPolicy.SelectedSessions) {
      programsControl?.setValue([]);
      programsControl?.clearValidators();
      sessionsControl?.setValidators([minLengthArray(1)]);
    }
  }

  matchPolicy<T>(policy: T | null, criteria: T[]): boolean {
    if (policy === null) {
      return false;
    }

    return criteria.includes(policy);
  }

  private setValidators(): void {
    this.form.controls.forEach((settingItem, index) => {
      this.handleEntityPolicyChanged(settingItem.get('entityPolicy')?.value, index);
    });
  }
}
