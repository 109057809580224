import { iif, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { QuizQuestionType } from '@app/core/quizzes/types';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import {
  SessionTemplateDrawerService,
  SessionTemplateProgramData
} from '@app/modules/session-forms/drawers/session-template-drawer/services/session-template-drawer.service';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

import { ILabelledProgramOption, IProgramOptions, ModuleAccessTypes, ProgramAccessRoles } from '../../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISelectedModule {
  id: number;
  localId: number;
}

@Component({
  selector: 'app-program-modules',
  templateUrl: './program-modules.component.html',
  styleUrls: [
    '../../../../../../scss/menus/button-with-vertical-dots.scss',
    '../../../../../modules/guide-service-editor/common-styles/layout.scss',
    '../../../../../modules/guide-service-editor/common-styles/popover.scss',
    './program-modules.component.scss'
  ],
  providers: [SessionTemplateDrawerService, PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramModulesComponent implements DoCheck {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isBrowser = isPlatformBrowser(this._platformId);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _formModulesRef: FormArray;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activePopoverIndex: number;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _firstModuleActivations: ILabelledProgramOption<string>[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _regularModuleActivations: ILabelledProgramOption<string>[] = [];

  @Input()
  editable!: boolean;

  @Input()
  editorId!: number;

  @Input()
  modulesForm!: FormGroup;

  @Input()
  set options(value: IProgramOptions) {
    if (!value || !value.moduleActivations) {
      this._regularModuleActivations = [];
      this._firstModuleActivations = [];
    } else {
      this._regularModuleActivations = value.moduleActivations;
      this._firstModuleActivations = value.moduleActivations.slice(1);
    }
  }

  @Input()
  programAccessRole!: ProgramAccessRoles;

  @Input()
  isTeamAdmin!: boolean;

  @Output()
  addModule = new EventEmitter<{
    type: ModuleTypes;
    data?: ProgramSessionTemplateInterface | undefined;
    showRecurringAlert?: boolean;
  }>();

  @Output()
  addQuestion = new EventEmitter<{ moduleId: number; type: QuizQuestionType }>();

  @Output()
  addQuestionOption = new EventEmitter<{ moduleId: number; questionIndex: number }>();

  @Output()
  cloneModule = new EventEmitter<number>();

  @Output()
  moduleSelected = new EventEmitter<ISelectedModule>();

  @Output()
  removeModule = new EventEmitter<number>();

  @Output()
  removeQuestion = new EventEmitter<{ moduleId: number; questionIndex: number }>();

  @Output()
  removeQuestionOption = new EventEmitter<{
    moduleId: number;
    questionIndex: number;
    optionIndex: number;
  }>();

  @Output()
  reorderModules = new EventEmitter<{ previousIndex: number; currentIndex: number }>();

  @Output()
  updateModuleTitle = new EventEmitter<{ moduleId: number; title: string }>();

  @Output()
  updateModuleDescription = new EventEmitter<{ moduleId: number; description: string | null }>();

  @Output()
  loadQuiz = new EventEmitter<{ moduleId: number }>();

  activeMobilePopover = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeModule: any;

  isModuleTypeSelectorOpen = false;

  isMobileModuleTypeSelectorOpen = false;

  readonly ModuleTypes = ModuleTypes;

  readonly ModuleAccessTypes = ModuleAccessTypes;

  readonly ProgramAccessRoles = ProgramAccessRoles;

  get activePopoverIndex(): number {
    return this._activePopoverIndex;
  }

  get firstModuleActivations(): ILabelledProgramOption<string>[] {
    return this._firstModuleActivations;
  }

  get modules(): FormArray {
    return this.modulesForm.get('modules') as FormArray;
  }

  get regularModuleActivations(): ILabelledProgramOption<string>[] {
    return this._regularModuleActivations;
  }

  get isSoloWorkspace(): boolean {
    return this._workspacesService.isSolo;
  }

  constructor(
    @Inject(GLOBAL_WINDOW) private readonly _window: Window,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private readonly _platformId: any,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _workspacesService: WorkspacesService,
    private readonly sessionTemplateDrawerService: SessionTemplateDrawerService,
    private readonly destroy$: PuiDestroyService
  ) {}

  ngDoCheck(): void {
    if (this._formModulesRef !== this.modules) {
      this._formModulesRef = this.modules;

      if (this.modules && this.modules.value.length && this.findModuleIndex(this.activeModule) === -1) {
        const accessibleModule = this.findFirstAccessibleModule(this.modules.value);
        this.selectModule(accessibleModule || this.modules.value[0]);
      }
    }
  }

  addModuleAndSelectIt(moduleType: ModuleTypes): void {
    iif(
      () => moduleType === ModuleTypes.GROUP_SESSION,
      of(moduleType).pipe(
        switchMap(
          () =>
            this.sessionTemplateDrawerService.open$<SessionTemplateProgramData>({
              parentType: GuideServiceTypes.PROGRAM,
              accessRole: this.programAccessRole
            }).afterClosed$
        ),
        filter<SessionTemplateProgramData>(data => !!data),
        map(data => {
          return {
            type: moduleType,
            data: data.sessionInfo?.sessionTemplateDraft,
            showRecurringAlert: data.showRecurringAlert
          };
        })
      ),
      of({ type: moduleType, showRecurringAlert: false })
    )
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(result => {
        this.isModuleTypeSelectorOpen = false;
        this.isMobileModuleTypeSelectorOpen = false;
        this.addModule.emit(result);
        this.selectModule(this.modules.value[this.modules.length - 1]);
        this.hidePopover(true);
        this.toggleModuleTypeSelector();
      });
  }

  addNewQuestion(type: QuizQuestionType): void {
    this.addQuestion.emit({
      moduleId: this.activeModule.localId,
      type
    });
  }

  addNewQuestionOption(event: { questionIndex: number }): void {
    this.addQuestionOption.emit({
      moduleId: this.activeModule.localId,
      questionIndex: event.questionIndex
    });
  }

  cloneModuleAndSelectIt(index: number): void {
    this.cloneModule.emit(index);
    this.selectModule(this.modules.value[this.modules.length - 1]);
    this.hidePopover(true);
  }

  cloneModuleFromMobile(): void {
    // @ts-expect-error TS7006
    const activeModuleIndex = this.modules.value.findIndex(module => module.localId === this.activeModule.localId);
    if (activeModuleIndex > -1) {
      this.cloneModuleAndSelectIt(activeModuleIndex);
    }
    this.toggleMobilePopover();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  findModuleIndex(module): any {
    if (!module) {
      return -1;
    }

    const { modules } = this;
    for (let i = 0, len = modules.length; i < len; i++) {
      if (modules.at(i).value.localId === module.localId) {
        return i;
      }
    }
    return -1;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hideMobilePopover() {
    if (this.activeMobilePopover) {
      this.activeMobilePopover = false;
    }
  }

  hidePopover(skipRefresh?: boolean): void {
    // @ts-expect-error TS2322
    this._activePopoverIndex = null;

    if (!skipRefresh) {
      this._changeDetector.detectChanges();
    }
  }

  onModuleTitleChanged(changedValue: { html: string }): void {
    this.updateModuleTitle.emit({ moduleId: this.activeModule.localId, title: changedValue.html });
    // @ts-expect-error TS7006
    this.activeModule = this.modules.value.find(module => module.localId === this.activeModule.localId);
  }

  onModuleDescriptionChanged(changedValue: { html: string | null }): void {
    this.updateModuleDescription.emit({
      moduleId: this.activeModule.localId,
      description: changedValue.html
    });
  }

  // @ts-expect-error TS7031
  onLoadQuiz({ moduleId }): void {
    this.loadQuiz.emit({ moduleId });
  }

  removeModuleAndSelectPrevious(index: number): void {
    this.removeModule.emit(index);
    if (index > 0) {
      this.selectModule(this.modules.value[index - 1]);
    }
  }

  removeModuleFromMobileView(): void {
    // @ts-expect-error TS7006
    const activeModuleIndex = this.modules.value.findIndex(module => module.localId === this.activeModule.localId);
    if (activeModuleIndex > -1) {
      this.removeModuleAndSelectPrevious(activeModuleIndex);
    }
    this.toggleMobilePopover();
  }

  removeModuleQuestion(event: { questionIndex: number }): void {
    this.removeQuestion.emit({
      moduleId: this.activeModule.localId,
      questionIndex: event.questionIndex
    });
  }

  removeModuleQuestionOption(event: { questionIndex: number; optionIndex: number }): void {
    const { questionIndex, optionIndex } = event;

    this.removeQuestionOption.emit({
      moduleId: this.activeModule.localId,
      questionIndex,
      optionIndex
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectModule(module: any, index?: number): void {
    let willAutoRefresh = false;

    if (module) {
      willAutoRefresh = !this.activeModule || this.activeModule.localId !== module.localId;
      this.activeModule = module;
      this.moduleSelected.emit({ id: this.activeModule.id, localId: this.activeModule.localId });
    }

    if (this._activePopoverIndex === index && index != null) {
      this.hidePopover(willAutoRefresh);
    } else if (this._activePopoverIndex !== index) {
      // @ts-expect-error TS2322
      this._activePopoverIndex = index;

      if (!willAutoRefresh) {
        this._changeDetector.detectChanges();
      }
    }

    if (this._isBrowser) {
      this._window.scrollTo(0, 0);
    }
  }

  // @ts-expect-error TS7006
  shouldDisableSessionModuleServiceChange(serviceModule): boolean {
    switch (this.programAccessRole) {
      case ProgramAccessRoles.AUTHOR:
        return this._workspacesService.isSolo
          ? false
          : serviceModule.instructor !== null && serviceModule.instructor.id !== this.editorId;
      case ProgramAccessRoles.INSTRUCTOR:
        return (
          !serviceModule.hosts.find((host: { id: number }) => host.id === this.editorId) &&
          (serviceModule.instructor == null || serviceModule.instructor.id !== this.editorId)
        );
      default:
        return true;
    }
  }

  toggleMobilePopover(): void {
    this.activeMobilePopover = !this.activeMobilePopover;
  }

  toggleMobileModuleTypeSelector(): void {
    this.isMobileModuleTypeSelectorOpen = !this.isMobileModuleTypeSelectorOpen;
  }

  toggleModuleTypeSelector(): void {
    this.isModuleTypeSelectorOpen = !this.isModuleTypeSelectorOpen;
  }

  trackById(index: number): number {
    return index;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private findFirstAccessibleModule(modules: any[]): any {
    return modules.find(module => module.accessType !== ModuleAccessTypes.RESTRICTED);
  }

  // @ts-expect-error TS7006
  getAvatarSource(module): string {
    // from new module with session
    if (module.sessionTemplateDraft && module.sessionTemplateDraft?.hosts.length > 0) {
      // @ts-expect-error TS7006
      return module.sessionTemplateDraft?.hosts.map(host => {
        return {
          ...host,
          name: host.username ? host.username : host.firstName + ' ' + host.lastName
        };
      });
    }

    // from duplicated module
    if (module.service && module.service?.team?.length > 0) {
      // @ts-expect-error TS7006
      return module.service?.team?.map(host => {
        return {
          ...host,
          name: host.firstName + ' ' + host.lastName
        };
      });
    }

    // from saved modules in backend
    if (module?.hosts && module?.hosts.length > 0) {
      return module?.hosts;
    }
  }
}
