import { Injector } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ConditionsMap, VisibilityCondition } from '@app/cdk/conditions';
import {
  NavigationDependenciesAdminService,
  NavigationDependenciesClientService,
  NavigationDependenciesGuideService,
  NavigationDependenciesSuperAdminService
} from '@app/modules/navigation/services';

export const navigationDependenciesFactory = (
  authService: AuthService,
  injector: Injector
): ConditionsMap<VisibilityCondition> => {
  switch (authService.getUserRoleId()) {
    case UserRoles.CLIENT:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationDependenciesClientService);
    case UserRoles.GUIDE:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationDependenciesGuideService);
    case UserRoles.ADMIN:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationDependenciesAdminService);
    case UserRoles.SUPER_ADMIN:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return injector.get<any>(NavigationDependenciesSuperAdminService);
    default:
      // @ts-expect-error TS2322
      return null;
  }
};
