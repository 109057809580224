import { formatDateAdapter } from '@app/modules/common-behaviors';
import { ScheduleDateTimeFormatStrategy } from './schedule-date-time-format-strategy';

export class DefaultScheduleDateTimeFormatStrategy extends ScheduleDateTimeFormatStrategy<string | number | Date> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(format: string, locale: string, timezone?: string) {
    super(format, locale, timezone);
  }

  format(
    item: string | number | Date,
    options?: { format?: string; locale?: string; timezone?: string } | null
  ): string | null {
    const format = (options && options.format) || this._format;
    const locale = (options && options.locale) || this._locale;
    const timezone = (options && options.timezone) || this._timezone;

    // @ts-expect-error TS2345
    return formatDateAdapter(item, format, locale, timezone);
  }
}
