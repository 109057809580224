<ng-container [formGroup]="form">
  <div class="d-flex">
    <div class="form-container">
      <div>
        <div class="wrapper">
          <!-- COVER IMAGE UPLOADED FRAME -->
          <div
            *ngIf="form.controls.coverImageUrl && form.controls.coverImageUrl.value; else coverImageUploader"
            class="uploaded-files-item__image">
            <img
              [src]="form.controls.coverImageUrl.value"
              alt="Your uploaded cover image"
              i18n-alt="@@yourUploadedCoverImage" />
          </div>

          <ng-template #coverImageUploader [ngSwitch]="coverImageState">
            <!-- COVER IMAGE IDLE FRAME -->
            <pui-input-file
              *ngSwitchCase="'idle'"
              [formControl]="fileControl"
              [maxFileSize]="2 * 1000 * 1000"
              accept="image/jpeg, image/jpg, image/png, image/webp"
              class="uploaded-files-input">
              <div class="uploaded-files-input__content">
                <pui-icon
                  size="xl"
                  svgIcon="pui:upload-image"
                  class="uploaded-files-input__icon pui-space_bottom-3"></pui-icon>
                <div pui-typography="body-s">
                  <div class="uploaded-files-input__title" i18n="@@uploadImage">Upload image</div>
                  <div class="uploaded-files-input__description pui-space_top-1">
                    <span i18n="@@fileRecommendedDimensions">Recommended dimensions</span>
                    <span>&nbsp;892 x 446px</span>
                  </div>
                </div>
              </div>

              <div ngProjectAs="pui-input-file-drop-here" class="uploaded-files-input__content">
                <pui-icon svgIcon="pui:upload-arrow" class="uploaded-files-input__icon"></pui-icon>
                <span class="uploaded-files-input__text" i18n="@@dropfilehere">Drop file here!</span>
              </div>
            </pui-input-file>

            <!-- COVER IMAGE LOADING FRAME -->
            <div *ngSwitchCase="'loading'" class="pui-input-file_loading">
              <div class="uploaded-files-input__content">
                <svg
                  class="pui-input-file__spinner pui-space_bottom-3"
                  width="38"
                  height="38"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19 1.651C9.42 1.651 1.652 9.42 1.652 19S9.419 36.349 19 36.349c9.582 0 17.349-7.768 17.349-17.349a.754.754 0 1 1 1.508 0c0 10.415-8.442 18.857-18.857 18.857C8.586 37.857.143 29.415.143 19S8.586.143 19 .143A18.81 18.81 0 0 1 32.912 6.27.754.754 0 1 1 31.8 7.29 17.301 17.301 0 0 0 19 1.65Z" />
                </svg>
                <p pui-typography="body-s">
                  <span class="uploaded-files-input__title" i18n="@@uploadingInProcess">Uploading...</span>
                </p>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="content-container">
        <div>
          <pui-input-error [formControl]="fileControl"></pui-input-error>
        </div>

        <div class="article-type-switch-container">
          <ui-switch formControlName="isPodcast"></ui-switch>
          <span class="article-type-label" i18n="@@blogArticleTypeSwitcherPodcast">Is podcast</span>
        </div>

        <div class="article-type-switch-container" *ngIf="isBusinessTypeAllowed">
          <ui-switch formControlName="isBusiness"></ui-switch>
          <span class="article-type-label" i18n="@@blogArticleTypeSwitcherBusiness">Is Business</span>
        </div>

        <div class="tags-container">
          <app-tags-selector
            formControlName="articleTags"
            title="Add tags"
            i18n-title="@@tagSelectorButtonText"
            [allTags]="tags"></app-tags-selector>
        </div>

        <div class="title-container">
          <input
            type="text"
            formControlName="title"
            placeholder="Title"
            i18n-placeholder="@@blogArticleTitlePlaceholder"
            class="article-title-input" />
          <input type="text" formControlName="url" placeholder="Url" class="article-title-input" />
        </div>

        <div class="editor-container">
          <div class="editor-wrapper">
            <app-text-editor-image
              #textEditorInstance
              formControlName="contentRepresentation"
              format="object"
              placeholder="Insert text here..."
              i18n-placeholder="@@blogArticleQuillPlaceholder"
              [uploaderService]="uploaderService"
              [extendedVideoUploader]="true"></app-text-editor-image>
          </div>
        </div>
      </div>
    </div>
    <button
      *ngIf="form.controls.coverImageUrl && form.controls.coverImageUrl.value"
      size="m"
      pui-icon-button
      appearance="flat"
      (click)="resetCoverImage()"
      class="pui-input-file__clear"
      aria-label="Remove cover image"
      i18n-aria-label="@@removeCoverImage">
      <pui-icon svgIcon="pui:trash" size="l"></pui-icon>
    </button>
  </div>
  <div class="buttons-container">
    <app-button
      className="btn-primary btn-md publish-btn"
      class="publish-button-wrapper"
      i18n="@@blogArticlePublishBtn"
      [disabled]="isSaveDisabled"
      (click)="emitSave(statuses.PUBLISHED)">
      Publish
    </app-button>

    <app-button
      class="draft-btn-wrapper"
      className="btn-lite btn-md"
      i18n="@@blogArticleDraftBtn"
      [disabled]="isSaveDisabled"
      (click)="emitSave(statuses.DRAFT)">
      Save in drafts
    </app-button>

    <app-button
      *ngIf="canSendOut && published && !emailSent"
      class="send-out-btn-wrapper"
      className="btn-lite btn-md"
      i18n="@@blogArticleSendOutEmailBtn"
      (click)="sendOutEmit()">
      Send out email
    </app-button>
  </div>
</ng-container>
