import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { PuiTypographyModule } from '@awarenow/profi-ui-core';

import { ServiceOptionComponent } from './service-selector.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, PuiTypographyModule],
  exports: [ServiceOptionComponent],
  declarations: [ServiceOptionComponent]
})
export class ServiceSelectorModule {}
