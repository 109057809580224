import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ProgramAssigneesService } from '@app/screens/guide/guide-programs/services/program-assignees.service';
import { Observable } from 'rxjs';
import { IProgramAssignee } from '@app/screens/guide/guide-programs/types';

@Component({
  selector: 'app-program-assignees',
  templateUrl: './program-assignees.component.html',
  styleUrls: ['./program-assignees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramAssigneesComponent implements OnInit {
  @Input()
  // @ts-expect-error TS2564
  programId: number;

  members$: Observable<IProgramAssignee[]> = this._programAssigneesService.assignees$;

  constructor(private readonly _programAssigneesService: ProgramAssigneesService) {}

  ngOnInit(): void {
    this._programAssigneesService.getAssignees(this.programId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByMember(index: number, member: IProgramAssignee): number {
    return index;
  }
}
