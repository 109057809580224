<ng-container [ngSwitch]="status">
  <!-- MISSED -->
  <ng-container *ngSwitchCase="Statuses.MISSED">
    <pui-icon class="status-cancelled" size="s" svgIcon="pui:status-cancelled"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" class="status-cancelled" i18n="@@sessionStatusMissed">Missed</span>
    </ng-container>
  </ng-container>
  <!-- MISSED -->
  <ng-container *ngSwitchCase="Statuses.EXPIRED">
    <pui-icon class="status-cancelled" size="s" svgIcon="pui:status-cancelled"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" class="status-cancelled" i18n="@@sessionStatusExpired">Expired</span>
    </ng-container>
  </ng-container>
  <!-- APPROVED -->
  <ng-container *ngSwitchCase="Statuses.APPROVED">
    <pui-icon class="status-done" size="s" svgIcon="pui:status-done"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s">
        {{ approvedSessionString }}
      </span>
    </ng-container>
  </ng-container>
  <!-- CANCELLED -->
  <ng-container *ngSwitchCase="Statuses.CANCELLED">
    <pui-icon class="status-cancelled" size="s" svgIcon="pui:status-cancelled"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" class="status-cancelled" i18n="@@sessionStatusCancelled">Canceled by client</span>
    </ng-container>
  </ng-container>
  <!-- DECLINED -->
  <ng-container *ngSwitchCase="Statuses.DECLINED">
    <pui-icon class="status-cancelled" size="s" svgIcon="pui:status-cancelled"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" class="status-cancelled" i18n="@@sessionStatusDeclined">Canceled</span>
    </ng-container>
  </ng-container>
  <!-- DONE -->
  <ng-container *ngSwitchCase="Statuses.DONE">
    <pui-icon class="status-done" size="s" svgIcon="pui:status-done"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" i18n="@@sessionStatusDone">Completed</span>
    </ng-container>
  </ng-container>
  <!-- CONFIRMED_AND_PAID -->
  <ng-container *ngSwitchCase="Statuses.CONFIRMED_AND_PAID">
    <pui-icon class="status-done" size="s" svgIcon="pui:status-done"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" i18n="@@sessionStatusApproved">Confirmed and paid</span>
    </ng-container>
  </ng-container>
  <!-- CONFIRMED -->
  <ng-container *ngSwitchCase="Statuses.CONFIRMED">
    <pui-icon class="status-done" size="s" svgIcon="pui:status-done"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" i18n="@@sessionStatusApprovedFree">Confirmed</span>
    </ng-container>
  </ng-container>
  <!-- IN_PROGRESS -->
  <ng-container *ngSwitchCase="Statuses.IN_PROGRESS_OLD">
    <pui-icon class="status-in-progress" size="s" svgIcon="pui:status-in-progress"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" i18n="@@sessionStatusInProgress">In progress</span>
    </ng-container>
  </ng-container>
  <!-- GUIDE_OFFER_DECLINED -->
  <ng-container *ngSwitchCase="Statuses.GUIDE_OFFER_DECLINED">
    <pui-icon class="status-cancelled" size="s" svgIcon="pui:status-cancelled"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span class="status-cancelled" i18n="@@sessionStatusGuideOfferDeclined">Canceled by client</span>
    </ng-container>
  </ng-container>
  <!-- GUIDE_OFFER_CANCELLED -->
  <ng-container *ngSwitchCase="Statuses.GUIDE_OFFER_CANCELLED">
    <pui-icon class="status-cancelled" size="s" svgIcon="pui:status-cancelled"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" class="status-cancelled" i18n="@@sessionStatusGuideOfferCancelled">Canceled</span>
    </ng-container>
  </ng-container>

  <!-- PendingSessionStatus -->
  <ng-container *ngSwitchCase="isPendingSessionStatus(status)">
    <pui-icon class="status-pending" size="s" svgIcon="pui:status-pending"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" i18n="@@sessionStatusRescheduleByGuide">Pending confirmation</span>
    </ng-container>
  </ng-container>
  <!-- AwaitingSessionStatus -->
  <ng-container *ngIf="isAwaitingSessionStatus(status)">
    <pui-icon class="status-awaiting" size="s" svgIcon="pui:status-awaiting"></pui-icon>
    <ng-container *ngIf="!onlyIcon">
      <span pui-typography="body-s" i18n="@@sessionStatusRescheduleByClient">Awaiting confirmation</span>
    </ng-container>
  </ng-container>
</ng-container>
