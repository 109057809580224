export enum VisibilityCondition {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  account_switcher = 'account_switcher',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  always = 'always',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  can_signup_as_client = 'can_signup_as_client',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  can_signup_as_coach = 'can_signup_as_coach',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  client = 'client',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  community = 'community',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  earn_affiliates = 'earn_affiliates',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  forms = 'forms',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  blogs = 'blogs',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  never = 'never',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  payments = 'payments',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  programs = 'programs',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public_events = 'public_events',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  solo_workspace = 'solo_workspace',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  subscription = 'subscription',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  team_workspace_admin = 'team_workspace_admin',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  wallet = 'wallet',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  whats_new = 'whats_new',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  workspace_admin = 'workspace_admin',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  marketplace = 'marketplace',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  email_help = 'email_help',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  url_privacy = 'url_privacy',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  url_terms = 'url_terms',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  url_cancellation_policy = 'url_cancellation_policy',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  url_visit_help_center = 'url_visit_help_center',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reports = 'reports',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  services = 'services'
}
