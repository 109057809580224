export enum ModuleTypes {
  CONTENT = 'content',
  QUIZ = 'quiz',
  SESSION = 'session',
  GROUP_SESSION = 'group_session'
}

export enum ModuleCompletionTypes {
  AUTO = 'auto',
  CLIENT = 'client',
  GUIDE = 'guide',
  AFTER_SUBMIT_QUIZ = 'after_submit_quiz'
}
