import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomUrlQueryEncoder } from '@app/shared/utils/custom-url-query-encoder';
import { Observable, throwError } from 'rxjs';
import { INoteContent, INoteUpdatedContent } from '@app/shared/interfaces/notes';
import { NotificationsService } from 'angular2-notifications';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SIMPLE_ID } from '@app/core/simple-id';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { IGuideNotesResponse, IGuideNote, GuideNote } from './guide-notes.types';
import config from '../../core/config/config';

@Injectable({
  providedIn: 'root'
})
export class GuideNotesApiService {
  readonly ENDPOINT = `${config.apiPath}/user/guide/notes`;

  // [TODO] move token injection in wrapper service
  constructor(
    private readonly _http: HttpClient,
    private readonly _notifications: NotificationsService,
    // @ts-expect-error TS7006
    @Inject(SIMPLE_ID) private _token
  ) {}

  getClientsNotes$(
    id: number,
    cursor: string | null,
    limit: number | null,
    pinned: boolean
  ): Observable<{ notes: GuideNote[]; cursor: string | null }> {
    const params: { [prop: string]: string } = {};
    if (limit) {
      params.limit = limit.toString();
    }

    if (cursor) {
      params.cursor = cursor;
    }

    if (pinned) {
      params.pinned = pinned.toString();
    }

    return this._http
      .get<IGuideNotesResponse>(`${this.ENDPOINT}/client/${id}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: params
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot get client notes`);
          return throwError(error);
        }),
        map(({ notes, ...rest }) => ({
          notes: notes.map(note => new GuideNote(note)),
          ...rest
        }))
      );
  }

  getContactsNotes$(
    id: number,
    cursor: string | null,
    limit: number | null,
    pinned: boolean
  ): Observable<{ notes: GuideNote[]; cursor: string | null }> {
    const params: { [prop: string]: string } = {};

    if (limit) {
      params.limit = limit.toString();
    }

    if (cursor) {
      params.cursor = cursor;
    }

    if (pinned) {
      params.pinned = pinned.toString();
    }

    return this._http
      .get<IGuideNotesResponse>(`${this.ENDPOINT}/contact/${id}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: params
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot get contact notes`);
          return throwError(error);
        }),
        map(({ notes, ...rest }) => ({
          notes: notes.map(note => new GuideNote(note)),
          ...rest
        }))
      );
  }

  getRelationNotes$(
    relationId: number,
    relationType: GuideRelationTypes,
    cursor: string | null,
    limit: number | null,
    pinned: boolean,
    search: string
  ): Observable<{ notes: GuideNote[]; cursor: string | null }> {
    // const { id: relId, type: relType } = GuideContact.parseLocalId(relationId);
    const relPath = relationType === GuideRelationTypes.GUIDE_CLIENT ? 'client' : 'contact';

    const params: { [prop: string]: string } = {};

    if (limit) {
      params.limit = limit.toString();
    }

    if (cursor) {
      params.cursor = cursor;
    }

    if (pinned) {
      params.pinned = pinned.toString();
    }

    if (search) {
      params.search = search;
    }

    return this._http
      .get<IGuideNotesResponse>(`${this.ENDPOINT}/${relPath}/${relationId}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: params
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot get contact notes`);
          return throwError(error);
        }),
        map(({ notes, ...rest }) => ({
          notes: notes.map(note => new GuideNote(note)),
          ...rest
        }))
      );
  }

  getEventsNotes$(
    id: number,
    cursor: string | null,
    limit: number | null
  ): Observable<{ notes: GuideNote[]; cursor: string | null }> {
    const params: { [prop: string]: string } = {};
    if (limit) {
      params.limit = limit.toString();
    }

    if (cursor) {
      params.cursor = cursor;
    }
    return this._http
      .get<IGuideNotesResponse>(`${this.ENDPOINT}/event/${id}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: params
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot get event notes`);
          return throwError(error);
        }),
        map(({ notes, ...rest }) => ({
          notes: notes.map(note => new GuideNote(note)),
          ...rest
        }))
      );
  }

  updateNote$(id: number, note: INoteContent): Observable<INoteUpdatedContent> {
    return this._http.put<INoteUpdatedContent>(`${this.ENDPOINT}/${id}`, { note, token: this._token }).pipe(
      catchError(error => {
        this._notifications.error(`Cannot update note`);
        return throwError(error);
      })
    );
  }

  pinNote$(id: number): Observable<{ pinnedAt: string }> {
    return this._http.post<{ pinnedAt: string }>(`${this.ENDPOINT}/pin/${id}`, { token: this._token }).pipe(
      catchError(error => {
        this._notifications.error(`Cannot pin note`);
        return throwError(error);
      })
    );
  }

  unpinNote$(id: number): Observable<void> {
    return this._http
      .delete<void>(`${this.ENDPOINT}/pin/${id}`, {
        params: new HttpParams({
          encoder: new CustomUrlQueryEncoder(),
          fromObject: { token: this._token }
        })
      })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot unpin note`);
          return throwError(error);
        })
      );
  }

  // [TODO] Delete also should send the token, use query string to transfer token in all methods for consistency
  deleteNote$(id: number): Observable<void> {
    return this._http.delete<void>(`${this.ENDPOINT}/${id}`).pipe(
      catchError(error => {
        this._notifications.error(`Cannot delete note`);
        return throwError(error);
      })
    );
  }

  createClientNote$(id: number, note: INoteContent, relationId: string): Observable<GuideNote> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: clientId } = GuideContact.parseLocalId(relationId);
    return this._http
      .post<{ id: number }>(`${this.ENDPOINT}/client/${id}`, { note, token: this._token, relationId })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot create note`);
          return throwError(error);
        }),
        switchMap(({ id: noteId }) => this.getNote$(noteId))
      );
  }

  createEventNote$(id: number, clientIds: number[], note: INoteContent): Observable<GuideNote> {
    return this._http
      .post<{ id: number }>(`${this.ENDPOINT}/event/${id}`, { note, token: this._token, clientIds })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot create note`);
          return throwError(error);
        }),
        switchMap(({ id: noteId }) => this.getNote$(noteId))
      );
  }

  getClientNote$(id: number, clientId: number): Observable<GuideNote> {
    return this._http.get<IGuideNote>(`${this.ENDPOINT}/${id}/client/${clientId}`).pipe(
      catchError(error => {
        this._notifications.error(`Cannot get client note`);
        return throwError(error);
      }),
      map(note => new GuideNote(note))
    );
  }

  createContactNote$(id: number, note: INoteContent, relationId: string): Observable<GuideNote> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: contactId } = GuideContact.parseLocalId(relationId);
    return this._http
      .post<{ id: number }>(`${this.ENDPOINT}/contact/${id}`, { note, token: this._token, relationId })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot create note`);
          return throwError(error);
        }),
        switchMap(({ id: noteId }) => this.getNote$(noteId))
      );
  }

  getContactNote$(id: number, contactId: number): Observable<GuideNote> {
    return this._http.get<IGuideNote>(`${this.ENDPOINT}/${id}/contact/${contactId}`).pipe(
      catchError(error => {
        this._notifications.error(`Cannot get contact note`);
        return throwError(error);
      }),
      map(note => new GuideNote(note))
    );
  }

  shareNotesWithClient$(clientId: number, notes: number[]): Observable<void> {
    return this._http
      .post<void>(`${this.ENDPOINT}/grant-access/client/${clientId}`, { notes, token: this._token })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot grant access to note`);
          return throwError(error);
        })
      );
  }

  revokeViewerAccessForClient$(clientId: number, notes: number[]): Observable<void> {
    return this._http
      .post<void>(`${this.ENDPOINT}/revoke-access/client/${clientId}`, { notes, token: this._token })
      .pipe(
        catchError(error => {
          this._notifications.error(`Cannot revoke access to note`);
          return throwError(error);
        })
      );
  }

  getNote$(id: number): Observable<GuideNote> {
    return this._http.get<IGuideNote>(`${this.ENDPOINT}/${id}`).pipe(
      catchError(error => {
        this._notifications.error(`Cannot get note`);
        return throwError(error);
      }),
      map(note => new GuideNote(note))
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getSharedNotes$(noteIds: number[]) {
    const params = new HttpParams({
      encoder: new CustomUrlQueryEncoder(),
      fromObject: {
        'n[]': noteIds.map(id => `${id}`),
        t: `${Date.now()}` // Required to beat Angular inner caching
      }
    });
    return this._http.get<{ notes: IGuideNote[] }>(`${config.apiPath}/user/guide/notes/shared`, { params }).pipe(
      catchError(error => {
        return throwError(error);
      }),
      map(({ notes }) => notes.map(note => new GuideNote(note)))
    );
  }
}
