import { NgModule } from '@angular/core';
import { SetPaymentDateDirective } from './directives/set-payment-date.directive';
import { SubscriptionPaymentDateService } from './services/subscription-payment-date.service';
import { SetPaymentDateModule } from './components/set-payment-date/set-payment-date.module';
import { SubscriptionsApiService } from './services/subscriptions-api.service';

@NgModule({
  declarations: [SetPaymentDateDirective],
  imports: [SetPaymentDateModule],
  providers: [SubscriptionPaymentDateService, SubscriptionsApiService],
  exports: [SetPaymentDateDirective]
})
export class ProgramUpdateSubscriptionModule {}
