import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PuiLayoutModule, PuiAppBarModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { AltPrimaryLayoutComponent } from './alt-primary-layout.component';
import { RouterModule } from '@angular/router';
import { SidenavModule } from '@app/modules/sidenav/sidenav.module';
import { AltLayoutPageComponent } from './components/alt-layout-page/alt-layout-page.component';

@NgModule({
  declarations: [AltPrimaryLayoutComponent, AltLayoutPageComponent],
  imports: [
    CommonModule,
    RouterModule,
    PuiLayoutModule,
    PuiAppBarModule,
    PuiLayoutModule,
    PuiTypographyModule,
    SidenavModule
  ],
  exports: [AltPrimaryLayoutComponent, AltLayoutPageComponent]
})
export class AltPrimaryLayoutModule {}
