import { Pipe, PipeTransform } from '@angular/core';

/*
 * WARNING!
 * This pipe will not work on platforms without document(ssr)
 */

@Pipe({
  name: 'stripHtmlTags'
})
export class StripHtmlTagsPipe implements PipeTransform {
  transform(html: string): string {
    const tmpDivElement = document.createElement('div');
    tmpDivElement.innerHTML = html;
    return tmpDivElement.textContent || tmpDivElement.innerText || '';
  }
}
