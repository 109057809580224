<form [formGroup]="addCardForm" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="col">
      <label i18n="@@cardNumber">Card number</label>
      <div data-qa-id="pay-with-stripe-new-card-number-input" id="card-number" #cardNumber></div>
    </div>
  </div>
  <div class="form-row">
    <div class="col col-md-6">
      <label i18n="@@expiration">Expiration</label>
      <div data-qa-id="pay-with-stripe-new-card-expiry-input" id="card-expiry" #cardExpiry></div>
    </div>
    <div class="col col-md-6">
      <label>CVC</label>
      <div data-qa-id="pay-with-stripe-new-card-cvc-input" id="card-cvc" #cardCvc></div>
    </div>
  </div>
  <div class="form-row" *ngIf="!disableSavedCards">
    <div class="col">
      <app-checkbox formControlName="saveCard" i18n="@@saveCard">Save card for future payments</app-checkbox>
    </div>
  </div>

  <div class="form-row">
    <div class="col">
      <app-button
        data-qa-id="pay-with-stripe-new-submit-button"
        className="btn-lg btn-main"
        buttonType="submit"
        [disabled]="isFetching">
        {{ submitText }}
      </app-button>
    </div>
  </div>
</form>
