import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogItemPodcast, BlogItemPodcastsPack, IArticleSelection } from '../../types';

@Component({
  selector: 'app-blog-item-podcasts-pack',
  templateUrl: './blog-item-podcasts-pack.component.html',
  styleUrls: ['./blog-item-podcasts-pack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemPodcastsPackComponent {
  @Input()
  // @ts-expect-error TS2564
  podcastsPack: BlogItemPodcastsPack;

  @Output()
  select = new EventEmitter<IArticleSelection>();

  @Output()
  selectAuthor = new EventEmitter<string>();

  get podcasts(): BlogItemPodcast[] {
    return this.podcastsPack && this.podcastsPack.podcasts ? this.podcastsPack.podcasts : [];
  }

  emitSelect(podcastSelection: IArticleSelection): void {
    this.select.emit(podcastSelection);
  }

  emitSelectAuthor(authorDetails: string): void {
    this.selectAuthor.emit(authorDetails);
  }
}
