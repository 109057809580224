import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { IBillingInfo } from '@app/modules/billing/interfaces';
import { BookingStepsService } from '@app/modules/book-service/services/booking-steps.service';
import {
  BookingSteps,
  IGuideService,
  IServiceBookingDetails,
  IServiceBookingOptions,
  IServiceDetails
} from '../../types';

@Component({
  selector: 'app-service-booking',
  templateUrl: './service-booking.component.html',
  styleUrls: ['./service-booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'service-booking'
  }
})
export class ServiceBookingComponent {
  readonly BookingSteps: typeof BookingSteps = BookingSteps;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeStep: BookingSteps | null = null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _bookingOptions: IServiceBookingOptions<IGuideService>;

  @Input()
  set bookingOptions(value: IServiceBookingOptions<IGuideService>) {
    this._bookingOptions = value;

    this._activeStep = this._stepsService.calculate(value);
  }

  @Output()
  bookingDetailsChange = new EventEmitter<IServiceBookingDetails<IGuideService>>();

  @Output()
  requestMoreOptions: EventEmitter<void> = new EventEmitter<void>();

  get activeStep(): BookingSteps {
    // @ts-expect-error TS2322
    return this._activeStep;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get bookingOptions(): IServiceBookingOptions<IGuideService> {
    return this._bookingOptions;
  }

  get servicePrice(): number | null {
    return this.bookingOptions && this.bookingOptions.service && this.bookingOptions.service.price
      ? this.bookingOptions.service.price
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentOptions() {
    const isSubscription =
      this.bookingOptions &&
      this.bookingOptions.service &&
      this.bookingOptions.service.subscriptionRecurrency &&
      (!this.bookingOptions.service.price || this.bookingOptions.paymentOption === PaymentOptions.INSTALLMENTS);

    return { amount: this.servicePrice || null, isSubscription, hideWallet: isSubscription };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentOptionsOptions() {
    if (
      !this.bookingOptions ||
      !this.bookingOptions.service ||
      !this.bookingOptions.service.subscriptionRecurrency ||
      !this.bookingOptions.service.totalPayments
    ) {
      return null;
    }

    // eslint-disable-next-line prefer-spread
    const installmentsPrices = Array.apply(null, Array(this.bookingOptions.service.totalPayments)).map(
      (val, index) => ({
        // @ts-expect-error TS2532
        amount: this.bookingOptions.service.subscriptionPrice,
        paymentDate: DateTime.local().plus({
          // @ts-expect-error TS2464
          [this.bookingOptions.service.subscriptionRecurrency]: index
        })
      })
    );
    return { fullPrice: this.servicePrice, installmentsPrices };
  }

  constructor(private readonly _stepsService: BookingStepsService) {}

  onPaymentInfoChange(): void {
    const bookingDetails = {
      ...this._prepareServiceDetails(),
      paymentOption: this._bookingOptions.paymentOption
    };

    return this.bookingDetailsChange.emit(bookingDetails);
  }

  onPaymentOptionsInfoChange(paymentOption: PaymentOptions): void {
    const bookingDetails = { ...this._prepareServiceDetails(), paymentOption };

    this.bookingDetailsChange.emit(bookingDetails);
  }

  onServiceDetailsChange(serviceDetails: IServiceDetails<IGuideService>): void {
    const { requiresHostSelection, serviceHost } = this._bookingOptions;
    this.bookingDetailsChange.emit({
      ...serviceDetails,
      requiresHostSelection,
      serviceHost: serviceDetails.serviceHost || serviceHost
    });
  }

  onBillingDataChange(billingData: IBillingInfo): void {
    const bookingDetails = { ...this._prepareServiceDetails(), billingData: { ...billingData, required: false } };
    this.bookingDetailsChange.emit(bookingDetails);
  }

  onServiceHostSelection(options: IServiceBookingOptions<IGuideService>): void {
    if (options.serviceHost) {
      const bookingDetails: IServiceBookingDetails<IGuideService> = {
        ...this._prepareServiceDetails(),
        serviceHost: options.serviceHost
      };

      this.bookingDetailsChange.emit(bookingDetails);
    }
  }

  onBookingDetailsChange(options: IServiceBookingOptions<IGuideService>): void {
    if (options.date) {
      const bookingDetails: IServiceBookingDetails<IGuideService> = {
        ...this._prepareServiceDetails(),
        serviceHost: options.serviceHost
      };

      this.bookingDetailsChange.emit(bookingDetails);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _prepareServiceDetails(): IServiceBookingDetails<IGuideService> {
    const bookingDetails = {
      service: this._bookingOptions.service,
      billingData: this._bookingOptions.billingData,
      requiresHostSelection: this._bookingOptions.requiresHostSelection,
      serviceHost: this._bookingOptions.serviceHost
    };

    ['date', 'duration', 'timezone', 'requiresHostSelection', 'serviceHost'].forEach(prop => {
      // @ts-expect-error TS7053
      if (this._bookingOptions[prop] != null) {
        // @ts-expect-error TS7053
        bookingDetails[prop] = this._bookingOptions[prop];
      }
    });

    // @ts-expect-error TS2322
    return bookingDetails;
  }
}
