import { ClickOutsideModule } from 'ng-click-outside';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { ArchiveExpirationTimePipe } from '@app/modules/user-notes/pipes/archive-expiration-time';
import { SharedModule } from '@app/shared';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiBadgeModule,
  PuiButtonModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiLetModule,
  PuiList2Module,
  PuiMenuModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { NoteActionModalComponent } from './components/note-action-modal/note-action-modal.component';
import { NoteCardComponent } from './components/note-card/note-card.component';
import { NoteEventMetaComponent } from './components/note-event-meta/note-event-meta.component';
import { NoteHistoryMetaComponent } from './components/note-history-meta/note-history-meta.component';
import { NotePinnedBadgeComponent } from './components/note-pinned-badge/note-pinned-badge.component';
import { NoteProgramModuleMetaComponent } from './components/note-program-module-meta/note-program-module-meta.component';
import { NoteRecordingComponent } from './components/note-recording/note-recording.component';
import { UserNotesAccessTypePipe } from './pipes/user-notes-access-type.pipe';
import { NoteRecordingService } from './services/note-recording.service';

@NgModule({
  declarations: [
    NoteCardComponent,
    NoteHistoryMetaComponent,
    NoteRecordingComponent,
    NoteEventMetaComponent,
    NoteProgramModuleMetaComponent,
    NoteActionModalComponent,
    NotePinnedBadgeComponent,
    UserNotesAccessTypePipe,
    ArchiveExpirationTimePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    PlatformStyleModule,
    PuiIconModule,
    PuiAvatarModule,
    PuiBadgeModule,
    SharedModule,
    PuiLetModule,
    GuideServiceModule,
    RouterModule,
    PuiAvatarGroupModule,
    PuiMenuModule,
    PuiTooltipModule,
    PuiButtonModule,
    PuiDropdownModule,
    PuiList2Module,
    PuiTypographyModule,
    TextEditorModule
  ],
  exports: [
    NoteCardComponent,
    NoteHistoryMetaComponent,
    NoteRecordingComponent,
    NoteEventMetaComponent,
    NoteProgramModuleMetaComponent,
    NotePinnedBadgeComponent
  ],
  providers: [NoteRecordingService]
})
export class UserNotesModule {}
