<ng-container
  *ngIf="{
    session: session$ | async,
    recurring: recurring$ | async,
    isOffer: isOffer$ | async
  } as data">
  <form [formGroup]="form" class="delete-participant">
    <div class="delete-participant__header">
      <h2 pui-typography="h2">
        <ng-container i18n="@@CancelSessionRecurring">Cancel</ng-container>
        <ng-container>&nbsp;</ng-container>
        <ng-container *ngIf="data.recurring > 1 && cancelAllRecurring">
          <ng-container *ngIf="!data.isOffer; else cancelCountRecurring">
            <ng-container i18n="@@recurring">recurring</ng-container>
            <ng-container>&nbsp;</ng-container>
          </ng-container>
          <ng-template #cancelCountRecurring>
            <ng-container i18n="@@CancelCountRecurring">{{ data.recurring }}</ng-container>
            <ng-container>&nbsp;</ng-container>
            <ng-container>x</ng-container>
            <ng-container>&nbsp;</ng-container>
          </ng-template>
        </ng-container>
        <ng-container>{{ data.session?.name }}</ng-container>
        <ng-container *ngIf="!data.isOffer && !data.recurring">
          <ng-container>&nbsp;</ng-container>
          <ng-container i18n="@@onLowerCase">on</ng-container>
          <ng-container>&nbsp;</ng-container>
          <ng-container>{{ data.session.dateStart | date: 'EEEE, MMMM d, yyyy h:mma (zzzz)' }}</ng-container>
        </ng-container>
        <ng-container>?</ng-container>
      </h2>
    </div>

    <button (click)="back()" class="pui-space_bottom-4" pui-link>
      <pui-icon svgIcon="pui:chevron-left" size="xs"></pui-icon>
      <span i18n="@@Back" class="pui-space_left-1">Back</span>
    </button>

    <div class="delete-participant__content">
      <div>
        <label pui-input-label i18n="@@ReasonForCancellation">Reason for cancellation (optional)</label>
        <pui-input-field>
          <textarea
            [style.resize]="'none'"
            formControlName="message"
            rows="4"
            pui-typography="body-s"
            pui-input-text
            type="text"
            i18n-placeholder="@@ReasonForGuideCancellationPlaceholder"
            placeholder="Let your client know why you’ve canceled"></textarea>
        </pui-input-field>
      </div>
    </div>

    <button
      (click)="cancel()"
      [disabled]="isLoading"
      pui-button
      class="delete-participant__button"
      type="submit"
      i18n="@@CancelSession">
      Cancel session
    </button>
  </form>
</ng-container>
