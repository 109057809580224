import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceLabelComponent } from './service-label.component';
import { ServiceTypeModule } from '@app/shared/components/service-type/service-type.module';

@NgModule({
  declarations: [ServiceLabelComponent],
  imports: [CommonModule, ServiceTypeModule],
  exports: [ServiceLabelComponent]
})
export class ServiceLabelModule {}
