import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RuntimeConfigApiService } from './runtime-config-api.service';
import { RuntimeConfigService } from './runtime-config.service';
import { HasRuntimeFeatureDirective } from './has-runtime-feature.directive';

@NgModule({
  declarations: [HasRuntimeFeatureDirective],
  exports: [HasRuntimeFeatureDirective]
})
export class RuntimeConfigModule {
  static forRoot() {
    return {
      ngModule: RuntimeConfigModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: (runtimeConfigService: RuntimeConfigService) => () => runtimeConfigService.load$().toPromise(),
          deps: [RuntimeConfigService]
        },
        RuntimeConfigService,
        RuntimeConfigApiService
      ]
    };
  }
}
