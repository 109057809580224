import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionGuide, SimpleSession } from '@app/shared/interfaces/session';
import { getConnectionTypes, GetConnectionTypesResponse } from '@libs/utils/location/location';
import { CallInitiators } from '@app/shared/enums/call-initiators';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Component({
  selector: 'app-module-service-host',
  templateUrl: './module-service-host.component.html',
  styleUrls: ['./module-service-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleServiceHostComponent {
  @Input()
  user!: SessionGuide & { namedUrl: string };

  @Input()
  session!: SimpleSession;

  @Input()
  dateTime: string | null = null;

  readonly SessionConnectionTypes = SessionConnectionTypes;
  platformName: string | undefined = '';

  constructor(private runtimeConfigService: RuntimeConfigService) {
    this.platformName = this.runtimeConfigService.get('platformName');
  }

  getConnectionType(session: SimpleSession, guide: SessionGuide): GetConnectionTypesResponse | null {
    return getConnectionTypes({
      platformName: this.platformName,
      connectionType: session.connectionType as SessionConnectionTypes,
      address: session.address,
      phoneForSessions: guide.phoneForSessions as string,
      callInitiator: session.callInitiator as unknown as CallInitiators,
      name: `${guide.firstName} ${guide.lastName}`,
      hideAddress: false,
      eventUuid: session.eventUuid
    });
  }
}
