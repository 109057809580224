import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Component({
  selector: 'app-service-badge',
  templateUrl: './service-badge.component.html',
  styleUrls: ['./service-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceBadgeComponent {
  @Input()
  // @ts-expect-error TS2564
  service: {
    type: GuideServiceTypes;
    name: string;
  };
}
