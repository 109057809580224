export class WikiCategory {
  // @ts-expect-error TS2564
  id: number;
  // @ts-expect-error TS2564
  name: string;
}

export class WikiTermTag {
  // @ts-expect-error TS2564
  id: number;
  // @ts-expect-error TS2564
  name: string;
}

export class WikiTermGuide {
  firstName: string;
  lastName: string;
  namedUrl: string;
  photo: string;
  specialization: string;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  // @ts-expect-error TS7006
  constructor(guide) {
    this.firstName = guide.firstName;
    this.lastName = guide.lastName;
    this.namedUrl = guide.namedUrl;
    this.photo = guide.photo;
    this.specialization = guide.specialization;
  }
}

export class WikiTerm {
  id: number;
  category?: WikiCategory;
  name: string;
  description: string;
  tags: WikiTermTag[];
  guides?: WikiTermGuide[];

  // @ts-expect-error TS7006
  constructor(term) {
    this.id = term.id;
    this.category = term.category;
    this.name = term.name;
    this.description = term.description;
    this.tags = term.tags;

    if (term.category) {
      this.category = term.category;
    }

    if (term.guides) {
      // @ts-expect-error TS7006
      // eslint-disable-next-line id-length
      this.guides = term.guides.map(g => new WikiTermGuide(g));
    }
  }
}
