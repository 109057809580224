import { ConfigItemTypes, ICommunityConfigManifest } from '@cnf/types';
import { LOCATION_ORIGIN } from '../consts';

export const communityConfigManifest: ICommunityConfigManifest = {
  introSectionTitle: {
    separatorBefore: 'Section Intro',
    type: ConfigItemTypes.text,
    title: 'Title',
    defaultValue: 'Break through using mindfulness-based coaching'
  },
  introSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue:
      'awarenow gives you the transformative guidance you need to gain a competitive advantage, rewire your brain, build healthy habits, and understand yourself better'
  },
  introSectionTags: {
    type: ConfigItemTypes.text,
    title: 'Tags',
    defaultValue:
      'anxiety, panic attacks, sexuality, burnout, transformative leadership, relationships, meditation, holistic health & wellness, purpose, sleep'
  },
  introSectionButton: {
    type: ConfigItemTypes.text,
    title: 'Button text',
    defaultValue: 'Find a coach'
  },
  hideSectionButton: {
    type: ConfigItemTypes.checkbox,
    title: 'Hide button',
    defaultValue: false
  },
  introSectionBackgroundUrl: {
    type: ConfigItemTypes.cropUploader,
    title: 'Background image',
    defaultValue: ''
  },
  introSectionBackgroundVideoUrl: {
    type: ConfigItemTypes.videoUploader,
    title: 'Background video',
    defaultValue: ''
  },
  guideSectionTitle: {
    separatorBefore: 'Section Guides',
    type: ConfigItemTypes.text,
    title: 'Title',
    defaultValue: 'Practitioners'
  },
  guideSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue:
      'Each one of these awesome coaches adds something special to our community. We can’t wait for you to get to know them and fall in love with them!'
  },
  guideSectionButton: {
    type: ConfigItemTypes.text,
    title: 'Button text',
    defaultValue: 'Go to all Practitioners'
  },
  certainGuidesId: {
    type: ConfigItemTypes.text,
    title: 'Six or less ids of Guides you want to display on the community page: 1, 23, 5',
    defaultValue: ''
  },
  productSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue: 'Is a platform to find guides to help you it takes to reach your goals and wellbeing.'
  },
  productSectionCardOneTitle: {
    type: ConfigItemTypes.text,
    title: 'Card 1 Title (max 50 chars)',
    defaultValue: 'Pain relief'
  },
  productSectionCardOneText: {
    type: ConfigItemTypes.text,
    title: 'Card 1 Text',
    defaultValue: 'If you often experience a state of anxiety, depression, and do not want to resort to medication'
  },
  productSectionCardOneTags: {
    type: ConfigItemTypes.text,
    title: 'Card 1 Tags',
    defaultValue: 'anxiety, nervous, stress, depressions, apathy'
  },
  productSectionCardOneImage: {
    type: ConfigItemTypes.uploader,
    title: 'Card 1 Background Image (565px*329px)',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service1.jpg`
  },
  productSectionCardOneImageMobile: {
    type: ConfigItemTypes.uploader,
    title: 'Card 1 Mobile Background Image',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service1.jpg`
  },
  productSectionCardTwoTitle: {
    type: ConfigItemTypes.text,
    title: 'Card 2 Title (max 50 chars)',
    defaultValue: 'Physical problem'
  },
  productSectionCardTwoText: {
    type: ConfigItemTypes.text,
    title: 'Card 2 Text',
    defaultValue:
      'If you often have trouble sleeping, you have trouble sleeping, prevents live weight, psychosomatic medicine'
  },
  productSectionCardTwoTags: {
    type: ConfigItemTypes.text,
    title: 'Card 2 Tags',
    defaultValue: 'insomnia, bad habits, apathy, excess weight, psychosomatic'
  },
  productSectionCardTwoImage: {
    type: ConfigItemTypes.uploader,
    title: 'Card 2 Background Image (565px*329px)',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service2.jpg`
  },
  productSectionCardTwoImageMobile: {
    type: ConfigItemTypes.uploader,
    title: 'Card 2 Mobile Background Image',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service2.jpg`
  },
  productSectionCardThreeTitle: {
    type: ConfigItemTypes.text,
    title: 'Card 3 Title (max 50 chars)',
    defaultValue: 'Help to evolve'
  },
  productSectionCardThreeText: {
    type: ConfigItemTypes.text,
    title: 'Card 3 Text',
    defaultValue:
      'Look for ways to develop yourself, develop intuition, stress resistance, and other mental qualities with our guides'
  },
  productSectionCardThreeTags: {
    type: ConfigItemTypes.text,
    title: 'Card 3 Tags',
    defaultValue: 'anxiety, nervous, stress, depressions, apathy'
  },
  productSectionCardThreeImage: {
    type: ConfigItemTypes.uploader,
    title: 'Card 3 Background Image (565px*329px)',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service3.jpg`
  },
  productSectionCardThreeImageMobile: {
    type: ConfigItemTypes.uploader,
    title: 'Card 3 Mobile Background Image',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service3.jpg`
  },
  productSectionCardFourTitle: {
    type: ConfigItemTypes.text,
    title: 'Card 4 Title (max 50 chars)',
    defaultValue: 'Lifestyle management'
  },
  productSectionCardFourText: {
    type: ConfigItemTypes.text,
    title: 'Card 4 Text',
    defaultValue: 'If you often experience a state of anxiety, depression, and do not want to resort to medication'
  },
  productSectionCardFourTags: {
    type: ConfigItemTypes.text,
    title: 'Card 4 Tags',
    defaultValue: 'lifestyle, money, stress, healthy habits, sport'
  },
  productSectionCardFourImage: {
    type: ConfigItemTypes.uploader,
    title: 'Card 4 Background Image (565px*329px)',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service4.jpg`
  },
  productSectionCardFourImageMobile: {
    type: ConfigItemTypes.uploader,
    title: 'Card 4 Background Mobile Image',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/service4.jpg`
  },
  coursesSectionTitle: {
    separatorBefore: 'Section Programs',
    type: ConfigItemTypes.text,
    title: 'Title',
    defaultValue: 'Programs'
  },
  coursesSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue:
      'Our coaches create these carefully curated online programs to help you stay healthy and advance to a higher level in your life journey'
  },
  coursesSectionButton: {
    type: ConfigItemTypes.text,
    title: 'Button Text',
    defaultValue: 'Go to all Programs'
  },
  blogsSectionTitle: {
    separatorBefore: 'Section Blogs',
    type: ConfigItemTypes.text,
    title: 'Title',
    defaultValue: 'Popular Blogs'
  },
  blogsSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue:
      'If you want to get tips to improve your life or dig deeper into holistic practices, our blog is a great space to find inspiration and be up to date on the latest trends.'
  },
  blogsSectionButton: {
    type: ConfigItemTypes.text,
    title: 'Button Text',
    defaultValue: 'Go to Blog'
  },
  podcastsSectionTitle: {
    separatorBefore: 'Section Podcasts',
    type: ConfigItemTypes.text,
    title: 'Title',
    defaultValue: 'Podcasts'
  },
  podcastsSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue:
      'Learn more about our coaches directly from their voices! In each episode, you can get tips, learn best practices, and discover curiosities about their work and approaches.'
  },
  podcastsSectionButton: {
    type: ConfigItemTypes.text,
    title: 'Button Text',
    defaultValue: 'Go to all Podcasts'
  },
  certificateSectionOffer: {
    separatorBefore: 'Section Certificate',
    type: ConfigItemTypes.text,
    title: 'Main Text',
    defaultValue: 'Share the care. Give the gift of wellbeing to your loved ones!'
  },
  certificateSectionDescription: {
    type: ConfigItemTypes.text,
    title: 'Description',
    defaultValue: 'Peace of mind is not a luxury upgrade, but an essential human need.'
  },
  certificateSectionImage: {
    type: ConfigItemTypes.uploader,
    title: 'Image',
    defaultValue: `${LOCATION_ORIGIN}/assets/images/gift-certificate.png`
  },
  certificateSectionButton: {
    type: ConfigItemTypes.text,
    title: 'Button',
    defaultValue: 'Gift certificate'
  },
  gradientStart: {
    separatorBefore: 'Colors',
    type: ConfigItemTypes.text,
    title: 'Gradient start',
    defaultValue: '#0e8fb2'
  },
  gradientEnd: {
    type: ConfigItemTypes.text,
    title: 'Gradient end',
    defaultValue: '#2ea6c0'
  },
  whiteButtonsBackgroundHoverColor: {
    type: ConfigItemTypes.text,
    title: 'Background on hover for white buttons',
    defaultValue: '#c6e7ee'
  },
  coloredButtonsBackgroundColor: {
    type: ConfigItemTypes.text,
    title: 'Background for colored buttons',
    defaultValue: '#fb0c2e'
  },
  coloredButtonsBackgroundHoverColor: {
    type: ConfigItemTypes.text,
    title: 'Background on hover for colored buttons',
    defaultValue: '#e6003c'
  }
};
