import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserWalletService } from '@app/core/user-wallet/user-wallet.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { Observable, of, throwError } from 'rxjs';
import { IBookValidationStrategy } from '../types';

@Injectable({
  providedIn: 'root',
  deps: [AuthService, UserWalletService, NgbModal],
  useFactory: (auth: AuthService, wallet: UserWalletService, modal: NgbModal) =>
    new DefaultPreBookValidationStrategy(auth, wallet, modal)
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class PreBookValidationStrategyService<TValidationArgs = any, TValidationSuccess = void>
  implements IBookValidationStrategy<TValidationArgs, TValidationSuccess>
{
  abstract validate$(args: TValidationArgs): Observable<TValidationSuccess>;
}

@Injectable()
export class DefaultPreBookValidationStrategy extends PreBookValidationStrategyService {
  constructor(private _auth: AuthService, private _userWallet: UserWalletService, private _modal: NgbModal) {
    super();
  }

  validate$(): Observable<void> {
    // @ts-expect-error TS2322
    return this.validate() ? of(null) : throwError(null);
  }

  protected validate(): boolean {
    return (
      !this._auth.isAuthorized ||
      (this._auth.user.RoleId === UserRoles.CLIENT &&
        !this._userWallet.checkIfBalanceIsNegativeAndFireNotificationIfTrue())
    );
  }
}
