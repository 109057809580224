import { Pipe, PipeTransform } from '@angular/core';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';
import { ILocale } from '@env/locale.interface';
import { LocaleService } from '@app/core/locale/locale.service';
import { UserService } from '@app/core';
import { UserRoles } from '@app/shared/enums/user-roles';

@Pipe({
  name: 'sessionDateTime'
})
export class SessionDateTimePipe implements PipeTransform {
  private locale: ILocale;

  constructor(private readonly user$: UserService, private readonly localeService: LocaleService) {
    this.locale = this.localeService.getLocale();
  }

  // @ts-expect-error TS7006
  transform(session, dateProp = 'date', durationProp = 'duration'): string | null {
    if (!session) {
      return null;
    }

    const start = session[dateProp];
    const duration = +session[durationProp];
    const user = this.user$.value;
    const userTimezone = user?.RoleId === UserRoles.GUIDE && user.lastTimezone ? user.lastTimezone : '';

    return platformDateTimeRange('DATE, TIME', {
      value: [start],
      templateOptions: {
        durationInMinutes: duration,
        showTimezone: true,
        localeParams: this.locale,
        timezone: userTimezone
      }
    });
  }
}
