import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { IScheduleProvider } from '@app/modules/schedule-boards';

@Injectable()
export abstract class ScheduleProvider<TSchedule, TOptions>
  implements IScheduleProvider<TSchedule, TOptions>, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _schedule$ = new ReplaySubject<TSchedule>(1);

  get schedule$(): Observable<TSchedule> {
    return this._schedule$.asObservable();
  }

  ngOnDestroy(): void {
    this._schedule$.complete();
  }

  abstract refresh(options: TOptions): void;
}
