<div class="container pui-container h-100">
  <div class="title">
    <h3 pui-typography="h2">
      <span>{{ title }}</span>
    </h3>
  </div>

  <button *ngIf="hasBackButton" (click)="back()" class="pui-space_bottom-4" pui-link>
    <pui-icon svgIcon="pui:chevron-left" size="xs"></pui-icon>
    <span i18n="@@Back" class="pui-space_left-1">Back</span>
  </button>

  <div class="body">
    <form [formGroup]="form" *puiLet="form.get('service')?.value as service">
      <div class="pui-form__row pui-space_bottom-4">
        <app-full-date
          *ngIf="service"
          [hostControl]="form.get('host')"
          [service]="service"
          [hideTimeslotsWithAttendee]="true"
          formControlName="schedule"></app-full-date>
      </div>

      <div class="pui-form__row" *ngIf="isUnavailableDateTime">
        <pui-alert type="info">
          <label formControlName="doubleBooking" pui-input-checkbox i18n="@@doubleBookingAcknowledge"
            >I confirm that this session should be booked at the time that is already booked or outside the
            availability.
          </label></pui-alert
        >
      </div>

      <div class="pui-space_bottom-2">
        <label class="pui-space_bottom-1" pui-input-label i18n="@@GuideRescheduleReasonsForReschedulingOptional"
          >Reasons for rescheduling (optional)
        </label>

        <pui-input-field>
          <textarea
            [style.resize]="'none'"
            formControlName="note"
            rows="4"
            pui-typography="body-s"
            pui-input-text
            type="text"
            i18n-placeholder="@@GuideRescheduleReasonMessagePlaceholder"
            placeholder="Let your client know why you reschedule"></textarea>
        </pui-input-field>
      </div>
    </form>
  </div>

  <div class="buttons">
    <button [disabled]="invalid" (click)="reschedule()" pui-button i18n="@@Reschedule">Reschedule</button>
  </div>
</div>
