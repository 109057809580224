import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { GuideContactsDetails, IGuideContactDetails } from '@app/modules/guide-client/types/contacts';
import { IContact } from '@app/core/users/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideContactsStore {
  addContacts$(contactDetails: GuideContactsDetails): Observable<IGuideContactDetails[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateContact$(id: number, values: IContact): Observable<any>;
}

export const GUIDE_CONTACTS_STORE = new InjectionToken('GuideContactsStore');
