const loadGuideProfile = () => import('./guide-profile').then(module => module.GuideProfileModule);

const loadBranding = () => import('./branding').then(module => module.BrandingModule);

const loadTheming = () => import('../../../modules/ui-kit/_theming').then(module => module.ThemingModule);

const loadGuideData = () => import('./guide-data').then(module => module.GuideDataModule);

const loadWorkspaceData = () => import('./workspace-data').then(module => module.WorkspaceDataModule);

const loadReports = () => import('../../../modules/report/report.module').then(module => module.ReportModule);

const loadDefaultServices = () => import('./default-services').then(module => module.DefaultServicesModule);

const loadDefaultServiceEditor = () =>
  import('./default-service-editor').then(module => module.DefaultServiceEditorModule);

const loadDefaultForms = () => import('./default-forms').then(module => module.DefaultFormsModule);

const loadDefaultFormEditor = () => import('./default-form-editor').then(module => module.DefaultFormEditorModule);

const loadNotificationsConfig = () => import('./notifications-config').then(module => module.NotificationsConfigModule);

export {
  loadGuideProfile,
  loadBranding,
  loadTheming,
  loadGuideData,
  loadWorkspaceData,
  loadReports,
  loadDefaultServices,
  loadDefaultForms,
  loadDefaultServiceEditor,
  loadDefaultFormEditor,
  loadNotificationsConfig
};
