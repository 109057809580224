import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputLabelComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  ngOnInit(): void {}
}
