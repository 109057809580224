import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StarRatingModule } from 'angular-star-rating';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NguCarouselModule } from '@ngu/carousel';
import { RouterModule } from '@angular/router';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiButtonModule,
  PuiCardModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiLetModule,
  PuiList2Module,
  PuiSelectModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { SharedModule } from '@app/shared';
import { ProgramModule } from '@app/modules/program/program.module';
import { AuthModule } from '@app/modules/auth/auth.module';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { WorkspacesModule } from '@app/modules/workspaces/workspaces.module';
import { TextEditorModule } from '@app/modules/text-editor/text-editor.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { GuideSurveysRoutingModule } from './guide-surveys-routing.module';
import { QuizAutomationComponent } from './components/quiz-automation/quiz-automation.component';
import { ShowStatsComponent } from './components/show-stats/show-stats.component';
import { QuizWrapperComponent } from './components/quiz-wrapper/quiz-wrapper.component';
import { QuizResolver } from './resolvers/quiz-resolver';
import { QuizContentEditorComponent } from './components/quiz-content-editor/quiz-content-editor.component';
import { QuizQuestionsPreviewModalComponent } from './components/quiz-questions-preview-modal/quiz-questions-preview-modal.component';
import { QuizBasicInfoComponent } from './components/quiz-basic-info/quiz-basic-info.component';
import { QuizStatsComponent } from './components/quiz-stats/quiz-stats.component';
import { QuizQuestionsComponent } from './components/quiz-questions/quiz-questions.component';
import { QuizSettingsComponent } from './components/quiz-settings/quiz-settings.component';
import { QuizClientsComponent } from './components/quiz-clients/quiz-clients.component';
import { ShareFormComponent } from './components/share-form/share-form.component';

import { QuizService } from './services/quiz.service';
import { QuizProgramsService } from './services/quiz-programs.service';
import {
  CanLeaveEditorGuard,
  CanLeaveWithValidationGuard,
  CanSaveQuizAndRedirectGuard
} from '@app/screens/guide/guide-surveys/guards';
import { QuizAnswersComponent } from '@app/screens/guide/guide-surveys/components/quiz-answers/quiz-answers.component';
import { CanLeaveAnswersEditorGuard } from '@app/screens/client/client-programs/guards/can-leave-answers-editor.guard';
import { QuizSessionInfoComponent } from '@app/screens/guide/guide-surveys/components/quiz-session-info/quiz-session-info.component';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { GuideSharedModule } from '@app/screens/guide/guide-shared/guide-shared.module';
import { GuideClientsResolver } from '@app/screens/guide/guide-clients/guide-client/guide-clients.resolver';
import { GuideClientsStoreModule } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    StarRatingModule,
    NgScrollbarModule,
    NguCarouselModule,
    GuideSurveysRoutingModule,
    ProgramModule,
    AuthModule,
    PlatformStyleModule,
    FormModule,
    WorkspacesModule,
    PuiAvatarModule,
    TextEditorModule,
    PuiTooltipModule,
    PuiIconModule,
    GuideServiceModule,
    PuiTypographyModule,
    PuiAvatarGroupModule,
    PuiCardModule,
    PuiList2Module,
    PuiDropdownModule,
    GuideSharedModule,
    GuideClientsStoreModule,
    PuiLetModule,
    PuiSelectModule,
    PuiButtonModule
  ],
  declarations: [
    QuizAutomationComponent,
    ShowStatsComponent,
    QuizWrapperComponent,
    QuizContentEditorComponent,
    QuizQuestionsPreviewModalComponent,
    QuizBasicInfoComponent,
    QuizStatsComponent,
    QuizQuestionsComponent,
    QuizSettingsComponent,
    QuizClientsComponent,
    ShareFormComponent,
    QuizAnswersComponent,
    QuizSessionInfoComponent
  ],
  exports: [QuizBasicInfoComponent, QuizSettingsComponent],
  providers: [
    QuizResolver,
    QuizService,
    QuizProgramsService,
    CanLeaveEditorGuard,
    CanSaveQuizAndRedirectGuard,
    CanLeaveAnswersEditorGuard,
    CanLeaveWithValidationGuard,
    GuideClientsResolver
  ]
})
export class GuideSurveysModule {}
