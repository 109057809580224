import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { BlogOwnerGuard } from './blog-owner.guard';

@Injectable()
export class AdminOrBlogOwnerGuard extends BlogOwnerGuard {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(_auth: AuthService, _router: Router) {
    super(_auth, _router);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return (this._auth.isAuthorized && this._auth.user.RoleId === UserRoles.ADMIN) || super.canActivate(route);
  }
}
