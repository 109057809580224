import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';
import { WindowService } from '../window/window.service';
import { GLOBAL_WINDOW } from '../browser-window/window-provider';
import config from '../config/config';
import { ICalendarConnectionOptions } from './types';

@Injectable()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CalendarConnectionService<TResult = any> implements OnDestroy {
  readonly ENDPOINT = `${config.apiPath}/auth/calendars`;
  private readonly WINDOW_TITLE = 'external_calendar_connection_window';
  private readonly MESSAGE_TYPE = 'external_calendar_connection_response';
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _state$: Subject<TResult>;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _authPopup: Window;

  constructor(
    private readonly _auth: AuthService,
    private readonly _newWindow: WindowService,
    private readonly _renderer: Renderer2,
    @Inject(GLOBAL_WINDOW) private readonly _browserWindow: Window,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this.messageEventHandler = this.messageEventHandler.bind(this);
  }

  ngOnDestroy(): void {
    this.reset();
  }

  connect(options: ICalendarConnectionOptions): void {
    this.connect$(options).subscribe();
  }

  connect$(options: ICalendarConnectionOptions): Observable<TResult> {
    this.reset();
    this._state$ = new Subject<TResult>();

    if (this._auth.isAuthorized) {
      const { authToken } = this._auth.user;

      if (authToken) {
        const body = this._document.body;

        const hiddenFormValues = {
          ...options,
          token: authToken
        };
        const hiddenForm = this.createHiddenFormWithValues(this.ENDPOINT, hiddenFormValues);
        this._renderer.appendChild(body, hiddenForm);

        this.openAuthPopup();

        if (this._authPopup) {
          hiddenForm.submit();
        }

        this._renderer.removeChild(body, hiddenForm);
      } else {
        this._state$.error('No auth token.');
      }
    }

    return this._state$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createHiddenFormWithValues(url: string, hiddenFormValues: ICalendarConnectionOptions): any {
    const form = this.createHiddenForm(url);

    for (const [key, value] of Object.entries(hiddenFormValues)) {
      const element = this.createHiddenFormElement(key, value);
      this._renderer.appendChild(form, element);
    }

    return form;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createHiddenForm(url: string): any {
    const form = this._renderer.createElement('form');

    this._renderer.setProperty(form, 'method', 'POST');
    this._renderer.setProperty(form, 'action', url);
    this._renderer.setProperty(form, 'target', this.WINDOW_TITLE);

    return form;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createHiddenFormElement(name: string, value: string): any {
    const element = this._renderer.createElement('input');

    this._renderer.setProperty(element, 'type', 'hidden');
    this._renderer.setProperty(element, 'name', name);
    this._renderer.setProperty(element, 'value', value);

    return element;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private messageEventHandler(event: any): void {
    const message = event.data;

    if (message.type && message.type === this.MESSAGE_TYPE) {
      if (typeof message.error === 'object' && Object.keys(message.error).length > 0) {
        this._state$.error(message.error);
      } else {
        this._state$.next(message.payload);
      }

      this.reset();
    }
  }

  private openAuthPopup(): void {
    this._browserWindow.addEventListener('message', this.messageEventHandler, false);
    this._authPopup = this._newWindow.openPopup('', this.WINDOW_TITLE);
  }

  private reset(): void {
    if (this._authPopup) {
      this._browserWindow.removeEventListener('message', this.messageEventHandler);
      this._authPopup.close();
      // @ts-expect-error TS2322
      this._authPopup = null;
      this._state$.complete();
    }
  }
}
