<ng-container [ngSwitch]="appearance">
  <ng-container *ngSwitchCase="'dropdown'">
    <div class="dropdown">
      <button
        class="dropdown__host-button"
        pui-dropdown="false"
        [pui-dropdown-content]="dropdownContent"
        pui-icon-button
        size="m"
        type="button"
        appearance="flat"
        [ghost]="ghost"
        shape="rounded">
        <pui-icon svgIcon="pui:more-vertical"></pui-icon>
      </button>

      <ng-template #dropdownContent let-close="close">
        <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
          <div
            *ngFor="let actionType of actionTypes"
            pui-list-item="button"
            pui-a11y-keyboard-widget-item
            (puiClick)="close(); execute(actionType)">
            <ng-container *ngIf="actionType | normalizeSessionAction: parent.session as action">
              <div class="dropdown__option">
                <pui-icon class="pui-space_right-2" [svgIcon]="action.iconName" size="s"></pui-icon>
                {{ action.labelText }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'buttons'">
    <div class="buttons">
      <ng-container *ngFor="let actionType of actionTypes">
        <ng-container *puiLet="actionType | normalizeSessionAction: parent.session as action">
          <button
            class="buttons__action-button"
            pui-button
            size="s"
            [appearance]="action.appearance"
            [attr.data-qa-id]="'client-session-invitation-' + actionType + '-button-' + parent.session?.title"
            [ghost]="action.ghost"
            (click)="execute(actionType)">
            <pui-icon *ngIf="action.iconName" [svgIcon]="action.iconName"></pui-icon>
            {{ action.labelText }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
