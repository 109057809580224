import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeatureFlagConfig } from './consts';
import { HasFeatureFlagDirective } from './directives/has-feature-flag.directive';
import { HasNoFeatureFlagDirective } from './directives/has-no-feature-flag.directive';

@NgModule({
  declarations: [HasFeatureFlagDirective, HasNoFeatureFlagDirective],
  exports: [HasFeatureFlagDirective, HasNoFeatureFlagDirective]
})
export class FeatureFlagsModule {
  static forRoot(configFactory: () => FeatureFlagConfig): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [{ provide: FeatureFlagConfig, useFactory: configFactory }]
    };
  }
}
