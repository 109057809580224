<div class="auth-body">
  <h1 data-qa-id="welcome-title" class="title" i18n="@@welcomeTitle">Welcome!</h1>
  <app-alternative-auth-providers
    [remember]="remember"
    [onlyClient]="onlyClient"
    [onlyGuide]="onlyGuide"
    [teamInvitationCode]="teamInvitationCode"
    [showSSOWelcome]="showSSOWelcome"
    (signUp)="signUpWithAlternativeProvider.emit()"
    (afterSignIn)="afterSignIn.emit()"
    (signInCanNot)="signInCanNot.emit()"
    class="alternative-auth-providers"></app-alternative-auth-providers>
  <ng-container *ngIf="!showSSOWelcome; else continueButton">
    <div *ngIf="showAuthMethodsSeparator" class="or" i18n="@@or">or</div>
    <p data-qa-id="welcome-enter-email-address-subtitle" class="subtitle" i18n="@@enterYourEmailText">
      Enter your email address to get started
    </p>
    <div class="auth-form">
      <form [formGroup]="signInForm" (ngSubmit)="checkUserExist()">
        <input
          formControlName="email"
          id="email"
          type="email"
          placeholder="example@mail.com"
          i18n-placeholder="@@emailPlaceholder"
          data-qa-id="welcome-form__textfield-email"
          class="form-control" />
        <app-form-control-error [control]="signInForm.controls['email']">
          <ng-container i18n="@@invalidEmail">Invalid email.</ng-container>
        </app-form-control-error>

        <app-checkbox
          id="remember"
          formControlName="remember"
          class="auth-checkbox"
          data-qa-id="welcome-remember-me-checkbox"
          (ngModelChange)="remember = signInForm.value['remember']"
          i18n="@@rememberMe">
          Remember me on this computer.
        </app-checkbox>

        <div class="auth-buttons">
          <app-button
            className="btn-primary btn-lg"
            buttonType="submit"
            i18n="@@continue"
            qaId="welcome-form__button-submit">
            Continue
          </app-button>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-template #continueButton>
    <div *ngIf="showContinueButton" class="auth-form">
      <div class="auth-buttons">
        <app-button
          className="btn-primary btn-lg"
          buttonType="submit"
          i18n="@@continue"
          qaId="welcome-form__button-submit">
          Continue
        </app-button>
      </div>
    </div>
  </ng-template>
</div>
