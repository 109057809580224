import { IScheduleTemplate } from '@app/modules/guide-session/types';
import { ScheduleOptions } from '@app/screens/guide/guide-sessions/types';

export function extractNonEmptyScheduleDays(scheduleTemplate: IScheduleTemplate): IScheduleTemplate {
  return Object.entries(scheduleTemplate).reduce((nonEmptyDays, [scheduleDay, workingHours]) => {
    // @ts-expect-error TS7006
    const nonEmptyRanges = workingHours.filter(range => range.start !== range.end);
    return nonEmptyRanges.length ? { ...nonEmptyDays, [scheduleDay]: nonEmptyRanges } : nonEmptyDays;
  }, {});
}

export function filterEmptyDaysFromScheduleTemplate(scheduleOptions: ScheduleOptions): ScheduleOptions {
  return new ScheduleOptions({
    template: extractNonEmptyScheduleDays(scheduleOptions.template),
    bufferTime: scheduleOptions.bufferTime,
    areExternalEventsBuffered: scheduleOptions.areExternalEventsBuffered
  });
}
