<app-guide-service
  [service]="service"
  [badges]="badges || [defaultBadge]"
  [description]="description"
  [details]="details || defaultDetails"
  [price]="price"
  [team]="team"
  [showMore]="showMore"
  [editorLink]="editorLink"
  [publicLink]="publicLink"
  [copyText]="copyText"></app-guide-service>

<ng-template #team>
  <app-guide-service-team [team]="service.team"></app-guide-service-team>
</ng-template>

<ng-template #defaultBadge>
  <app-guide-service-badge
    text="Session"
    [attr.data-qa-id]="'guide-individual-session-badge-' + service.name"
    i18n-text="@@guideServiceItemSessionBadge"
    class="individual-session-badge"></app-guide-service-badge>
</ng-template>

<ng-template #defaultDetails let-service="service">
  <div class="service-details">
    <div class="service-details__item session-duration">
      <pui-icon svgIcon="pui:clock" size="s" aria-hidden="true"></pui-icon>
      <span [attr.data-qa-id]="'guide-group-session-service-duration-title-' + service?.name" class="duration-value">
        {{ service?.duration }}
      </span>
      <span i18n="@@sessionDurationUnit">min</span>
    </div>

    <div
      class="service-details__item session-location"
      [ngClass]="{ 'hidden-platform': service.workspace | workspaceIsTeam }"
      [attr.data-qa-id]="'guide-group-session-service-location-title-' + service?.name">
      <pui-icon svgIcon="pui:place" size="s" aria-hidden="true"></pui-icon>
      {{ service?.connectionType | sessionPlatform: service?.address }}
    </div>

    <div
      class="service-details__item"
      [ngSwitch]="service?.sessionType"
      [attr.data-qa-id]="'guide-session-service-type-title-' + service?.name + service?.sessionType">
      <ng-container *ngSwitchCase="SessionType.ROUND_ROBIN">
        <pui-icon svgIcon="pui:cycle" size="s" aria-hidden="true"></pui-icon>
        <ng-container i18n="@@guideSessionRoundrobinAndPersonal">Round-robin and personal</ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="SessionType.COLLECTIVE">
        <pui-icon svgIcon="pui:user-group" size="s" aria-hidden="true"></pui-icon>
        <ng-container i18n="@@guideSessionCollective">Collective</ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <pui-icon svgIcon="pui:select-user" size="s" aria-hidden="true"></pui-icon>
        <ng-container i18n="@@guideSessionPersonal">Personal</ng-container>
      </ng-container>
    </div>

    <div
      class="service-details__item"
      *ngIf="service?.recurring?.count as recurringCount"
      [attr.data-qa-id]="'guide-session-service-recurrence-title-' + service?.name">
      <pui-icon svgIcon="pui:repeat" size="s" aria-hidden="true"></pui-icon>
      <ng-container i18n="@@guideSessionRepeatsUpTo">Repeats up to</ng-container>
      {{ recurringCount }}
      <ng-container i18n="@@guideSessionRepeatsTimes">times</ng-container>
    </div>
  </div>
</ng-template>
