<ng-container *ngIf="package">
  <app-service-landing
    [type]="GuideServiceTypes.PACKAGE"
    [title]="package.title"
    [coverImageUrl]="package.coverImage"
    [description]="package.description"
    [link]="link"
    [logo]="package.workspace?.branding?.logo"
    [workspaceId]="package.workspace?.id || package.guide.workspaceId"
    [guide]="package.guide"
    [assignees]="assignees"
    [testimonials]="package.testimonials"
    [showBookButton]="false"
    [isPackage]="true"
    [faq]="package.faq">
    <ng-template #submitButtonTemplate>
      <app-button
        *ngIf="isShowEnrollButton; else sessionBookButton"
        (click)="enrollOrOpen(true)"
        className="btn btn-primary"
        class="submit-btn"
        [disabled]="isOwnPackage">
        <ng-container
          *ngIf="package.isAlreadyEnrolled && package.subscriptionPrice && !package.totalPayments; else showPrice">
          <span data-qa-id="package-landing-subscribe-now-button" i18n="@@enrolled">Enrolled</span>
        </ng-container>

        <ng-template #showPrice>
          <span data-qa-id="package-landing-subscribe-now-button" i18n="@@purchase">Purchase</span>

          <ng-container *ngIf="!package.isHiddenPrice">
            <br />
            <span data-qa-id="package-landing-free-subtitle" *ngIf="package.isFree" i18n="@@free"> Free </span>
            <span *ngIf="!package.isFree">
              <ng-container *ngIf="package.price">
                {{ package.price | platformCurrency }}
              </ng-container>
              <ng-container *ngIf="!package.price && package.subscriptionPrice">
                {{ package.subscriptionPrice | platformCurrency }}
                <ng-container i18n="@@per">per</ng-container>
                {{ package.subscriptionRecurrency }}
                <span *ngIf="package?.totalPayments" class="total-payments">
                  ({{ package.totalPayments }}
                  <ng-container [ngPlural]="package.totalPayments">
                    <ng-template i18n="@@payment:one" ngPluralCase="=1">payment</ng-template>
                    <ng-template i18n="@@payment:many" ngPluralCase="other">payments</ng-template>
                  </ng-container>
                  )
                </span>
              </ng-container>
            </span>
          </ng-container>
        </ng-template>
      </app-button>

      <ng-template #sessionBookButton>
        <app-button
          *ngIf="isShowBookSessionButton"
          className="btn btn-primary"
          class="submit-btn"
          (click)="toDashboard()">
          <span i18n="@@bookSessionWithoutA">Book session</span>
        </app-button>
      </ng-template>
    </ng-template>

    <ng-container tabs>
      <div class="tab-container" *ngIf="!package.isHiddenPrice">
        <app-nav-tab icon="pui:tag">
          <ng-container name data-qa-id="public-session-template-price-label" i18n="@@priceTabName">
            Price
          </ng-container>

          <ng-container description data-qa-id="public-session-template-price-value-label">
            <ng-template [ngIf]="package?.price || package?.subscriptionPrice" [ngIfElse]="noPriceTmpl">
              <ng-template
                [ngTemplateOutlet]="priceTmpl"
                [ngTemplateOutletContext]="{ showPlatformCurrency: true }"></ng-template>
            </ng-template>
          </ng-container>
        </app-nav-tab>
      </div>

      <div class="tab-container">
        <div ngbDropdown (openChange)="toggleDropdown()" class="dropdown">
          <div
            data-qa-id="package-landing-includes-dropdown"
            id="includesDropdown"
            class="dropdown"
            ngbDropdownToggle
            [ngClass]="{ isOpen: expand }">
            <img src="/assets/images/includes.svg" alt="" />
            <div class="tab-info">
              <span
                class="dropdown__label"
                data-qa-id="package-landing-includes-label"
                i18n="@@includes"
                pui-typography="body-s"
                >Includes</span
              >
              <span
                pui-typography="body-m"
                data-qa-id="package-landing-activities-count-label"
                class="dropdown__value"
                [ngPlural]="activities.length">
                <ng-template i18n="@@item:zero" ngPluralCase="=0">no items</ng-template>
                <ng-template i18n="@@item:one" ngPluralCase="=1">{{ activities.length }} item</ng-template>
                <ng-template i18n="@@item:many" ngPluralCase="other">{{ activities.length }} items</ng-template>
              </span>
            </div>
            <a data-qa-id="package-landing-includes-dropdown-button" href="javascript:;" class="pointer"></a>
          </div>
          <div ngbDropdownMenu aria-labelledby="includesDropdown">
            <ng-scrollbar>
              <span
                [attr.data-qa-id]="'package-landing-includes-dropdown-option-' + text"
                class="dropdown-item"
                *ngFor="let text of activities">
                {{ text }}
              </span>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </ng-container>
  </app-service-landing>
</ng-container>

<ng-template #priceTmpl let-showPlatformCurrency="showPlatformCurrency">
  <ng-container *ngIf="package.price">
    <span *ngIf="showPlatformCurrency">{{ package?.price | platformCurrency }}</span>
    <span *ngIf="!showPlatformCurrency">{{ package?.price }}</span>
  </ng-container>
  <ng-container *ngIf="!package.price && package.subscriptionPrice">
    <span class="pui-space_right-1" *ngIf="showPlatformCurrency">{{
      package?.subscriptionPrice | platformCurrency
    }}</span>
    <span class="pui-space_right-1" *ngIf="!showPlatformCurrency">{{ package?.subscriptionPrice }}</span>
    <span i18n="@@per">per</span>
    {{ package?.subscriptionRecurrency }}
    <span *ngIf="package?.totalPayments" class="total-payments">
      ({{ package?.totalPayments }}
      <ng-container [ngPlural]="package.totalPayments">
        <ng-template i18n="@@payment:one" ngPluralCase="=1">payment</ng-template>
        <ng-template i18n="@@payment:many" ngPluralCase="other">payments</ng-template>
      </ng-container>
      )
    </span>
  </ng-container>
</ng-template>

<ng-template #noPriceTmpl>
  <span i18n="@@templateFree">Free</span>
</ng-template>
