import { NgModule } from '@angular/core';
import { GuideProgramClientsService } from '@app/screens/guide/guide-programs/services/guide-program-clients.service';

import { ServiceFormModule } from './modules/service-form/service-form.module';
import { InitServiceSchedulingResolver } from './resolvers/init-service-scheduling.resolver';
import { ChooseServiceRoutingModule } from './service-scheduling-routing.module';
import { ServiceSchedulingComponent } from './service-scheduling.component';
import {
  SelectedParticipantsNoteMessageService,
  ServiceSchedulingLSService,
  ServiceSchedulingService
} from './services';
import { GuideServicesStoreModule } from './store/service-scheduling/service-scheduling-store.module';
import { GuideStoreModule } from '@libs/stores/guide-store.module';
import { SchedulingStoreService } from '@app/modules/service-scheduling/services/scheduling-store.service';

@NgModule({
  imports: [ServiceFormModule, GuideServicesStoreModule, ChooseServiceRoutingModule, GuideStoreModule.forFeature()],
  declarations: [ServiceSchedulingComponent],
  exports: [ServiceSchedulingComponent],
  providers: [
    ServiceSchedulingService,
    ServiceSchedulingLSService,
    InitServiceSchedulingResolver,
    GuideProgramClientsService,
    SelectedParticipantsNoteMessageService,
    SchedulingStoreService
  ]
})
export class ServiceSchedulingModule {}
