import { IMenuLink, MenuLinkTypes } from '@app/screens/admin/types';
import { UserRoles } from '@app/shared/enums/user-roles';
import { WindowProvider } from '@app/core/browser-window/window-provider';
import { INavigationEntry } from '@app/modules/navigation';
import { PuiNavigationEntity } from '@awarenow/profi-ui-core';
import { UrlTypes } from '@app/shared/enums/url-types';
import { createEventFromLink } from '@app/modules/navigation/utils/create-event-from-link';
import { menuLinkToNavigationEntity } from '@app/modules/navigation/utils/menu-link-to-navigation-entity';
import { visibleForToCondition } from '@app/modules/navigation/utils/visible-for-to-condition';
import { createRouteFromLink } from '@app/modules/navigation/utils/create-route-from-link';

export const menuLinkToNavigationEntry = (
  link: IMenuLink,
  role: UserRoles,
  windowService: WindowProvider
): INavigationEntry | null => {
  const { children } = link;
  let subOptions: PuiNavigationEntity[] = [];

  if (children?.length) {
    // eslint-disable-next-line id-length
    subOptions = children.map(l => menuLinkToNavigationEntity(l, role, windowService));
  }

  if (link.urlType === UrlTypes.ABSOLUTE) {
    return {
      item: {
        // @ts-expect-error TS2322
        icon: link.iconName,
        title: link.label,
        options: subOptions
      },
      events: createEventFromLink(link, windowService),
      widget: [],
      role: [role],
      condition: visibleForToCondition(link, role)
    };
  }

  if (link.urlType === UrlTypes.RELATIVE) {
    if (link.type === MenuLinkTypes.EXTERNAL) {
      return {
        item: {
          // @ts-expect-error TS2322
          icon: link.iconName,
          title: link.label,
          options: subOptions
        },
        events: createEventFromLink(link, windowService),
        widget: [],
        role: [role],
        condition: visibleForToCondition(link, role)
      };
    }

    if (link.type === MenuLinkTypes.INTERNAL) {
      return {
        item: {
          // @ts-expect-error TS2322
          icon: link.iconName,
          title: link.label,
          options: subOptions
        },
        route: createRouteFromLink(link),
        widget: [],
        role: [role],
        condition: visibleForToCondition(link, role)
      };
    }
  }

  return null;
};
