import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UserPasswordsService } from '@app/core/users/user-passwords.service';
import { IUser } from '@app/shared/interfaces/user';
import { ApplicationEventService } from '@libs/services/application-events/applications-events.service';
import { UserEvent } from '@libs/services/application-events/events/user.event';

@Injectable({ providedIn: 'root' })
export class UserService extends BehaviorSubject<IUser | undefined> {
  constructor(readonly password: UserPasswordsService, private applicationEventService: ApplicationEventService) {
    super(undefined);

    this.handleChanges();
  }

  private handleChanges(): void {
    this.asObservable()
      .pipe(filter<IUser>(Boolean))
      .subscribe(user => {
        this.applicationEventService.next(new UserEvent(user));
      });
  }
}
