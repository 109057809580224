import { AbstractControl, ValidatorFn } from '@angular/forms';
import { take } from 'rxjs/operators';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: string } | null => {
    const password = control.value;

    if (!password) {
      return { required: 'Password required.' };
    }

    if (password.length < 6) {
      return { minlength: 'Password must be at least 6 chars long.' };
    }

    if (!control.value.trim().length) {
      return { whitespace: 'Password cannot contain only whitespace' };
    }

    return null;
  };
}

export function matchPasswordValidator(passwordControlName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: string } | null => {
    const passwordControl = control.parent?.get(passwordControlName);
    const repeatPasswordControl = control;

    if (!passwordControl || !repeatPasswordControl) {
      return null;
    }

    if (!repeatPasswordControl.value) {
      return { required: 'Required field' };
    }

    passwordControl.valueChanges.pipe(take(1)).subscribe(() => {
      repeatPasswordControl.updateValueAndValidity();
    });

    if (passwordControl.value !== repeatPasswordControl.value) {
      return { mismatch: 'Passwords do not match' };
    }

    return null;
  };
}
