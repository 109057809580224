<app-service-landing
  [type]="template?.type"
  [title]="template?.name"
  [description]="template?.descriptionMarkup"
  [link]="template?.link"
  [workspaceId]="template?.workspaceId || template?.guide?.workspaceId"
  [guide]="template?.guide"
  [assignees]="assignees"
  [coverImageUrl]="template?.coverImage"
  [testimonials]="template?.testimonials"
  [address]="template?.address"
  [connectionType]="template?.connectionType"
  [hasSingleHost]="template?.hasSingleHost"
  [readonly]="readonly"
  [sessionType]="template?.sessionType"
  [showBookButton]="!template?.restrictClientBooking"
  [logo]="template?.workspace?.branding.logo"
  class="service-landing"
  [class.no-booking]="!canBeBooked"
  [templateId]="template?.id">
  <ng-template #submitButtonTemplate>
    <app-button #submitButton *ngIf="canBeBooked" className="btn-primary" class="submit-btn" (click)="emitBook()">
      <span
        pui-typography="body-m-bold"
        data-qa-id="public-session-template-book-a-session-button"
        i18n="@@publicSessionTemplateSubmitBtn">
        Book session
      </span>
      <br />
      <span i18n="@@templateSubmitBtnSubName" *ngIf="template?.price" pui-typography="body-m-bold">
        <ng-template
          [ngTemplateOutlet]="pricePersonTmpl"
          [ngTemplateOutletContext]="{ showPlatformCurrency: true }"></ng-template>
      </span>
    </app-button>
  </ng-template>

  <ng-container tabs>
    <div class="tab-container" *ngIf="!template?.hidePrice">
      <app-nav-tab icon="pui:tag">
        <ng-container name data-qa-id="public-session-template-price-label" i18n="@@priceTabName"> Price </ng-container>

        <ng-container description data-qa-id="public-session-template-price-value-label">
          <ng-template [ngIf]="template?.price" [ngIfElse]="noPriceTmpl">
            <ng-template
              [ngTemplateOutlet]="priceSessionTmpl"
              [ngTemplateOutletContext]="{ showPlatformCurrency: true }"></ng-template>
          </ng-template>
        </ng-container>
      </app-nav-tab>
    </div>

    <div class="tab-container" *ngIf="template?.duration">
      <app-nav-tab icon="pui:clock">
        <ng-container name data-qa-id="public-session-template-duration-label" i18n="@@durationTabName">
          Duration
        </ng-container>

        <ng-container description data-qa-id="public-session-template-duration-value-label">
          <span>{{ template?.duration }}</span>
          {{ ' ' }}
          <span i18n="@@templateMinutes">min</span>
        </ng-container>
      </app-nav-tab>
    </div>

    <div class="tab-container" *ngIf="template?.seatsPerTimeSlot">
      <app-nav-tab icon="pui:users">
        <ng-container name i18n="@@maxUsersTabParticipants"> Participants </ng-container>

        <ng-container
          description
          data-qa-id="public-session-template-available-date-value-label"
          i18n="@@landingUpToSeats">
          Up to {{ template?.seatsPerTimeSlot }}
        </ng-container>
      </app-nav-tab>
    </div>
    <!-- TODO: PR-8686 -->
    <!-- <div class="tab-container" *ngIf="template?.reccuringEvent.count >= 0">
      <app-nav-tab icon="pui:repeat">
        <ng-container name data-qa-id="public-session-template-recurrence-label" i18n="@@recurrenceTabName">
          Recurrence
        </ng-container>

        <ng-container description data-qa-id="public-session-template-recurrence-value-label">
          <span i18n="@@reccuranceTabRepeatedEvent">Repeated event</span>
        </ng-container>
      </app-nav-tab>
    </div> -->
  </ng-container>

  <div class="schedule" *ngIf="template?.schedule?.length > 0 && assignees?.length > 0">
    <h4 class="schedule-section-title" i18n="@@schedule-section-title">Choose session</h4>

    <div class="schedule-items-container row">
      <div
        class="schedule-item-container col-12 col-lg-4"
        *ngFor="
          let scheduleSlot of template.schedule | slice: 0:currentVisibleScheduleItemsCount;
          trackBy: trackByEventId
        ">
        <div class="schedule-item">
          <div class="schedule-item-content">
            <div class="schedule-item-date">
              {{ scheduleSlot.dateStart | formatDate: 'fullDate':'local':dateTimeLocale }}
            </div>

            <div class="schedule-item-time">
              <pui-icon class="start-icon" svgIcon="pui:clock" size="s" aria-hidden="true"></pui-icon>
              {{ scheduleSlot | serviceRangeFormatter: 'local':dateTimeLocale }}
            </div>

            <div class="schedule-item-price" *ngIf="template?.price">
              <pui-icon class="start-icon" svgIcon="pui:price" size="s" aria-hidden="true"></pui-icon>
              <ng-template [ngTemplateOutlet]="priceTmpl"></ng-template>
            </div>

            <div *ngIf="scheduleSlot.guide" class="schedule-item-host" [routerLink]="'/' + scheduleSlot.guide.namedUrl">
              <div class="start-icon">
                <pui-avatar
                  [avatarUrl]="scheduleSlot.guide.photo"
                  [text]="scheduleSlot.guide.firstName"
                  size="xs"></pui-avatar>
              </div>
              <div>
                <div class="schedule-item-host-name">{{ scheduleSlot.guide | userName }}</div>
              </div>
            </div>

            <div *ngIf="scheduleSlot.connectionType" class="schedule-session-location">
              <pui-icon class="start-icon" svgIcon="pui:place" size="s" aria-hidden="true"></pui-icon>
              <div class="location-info-and-show-more">
                <div
                  class="location-info"
                  [class.can-show-more]="canShowMore(scheduleSlot)"
                  [class.full]="scheduleSlot.showMore">
                  {{ scheduleSlot.connectionType | sessionPlatform: scheduleSlot.address }}
                </div>
                <div class="show-more" (click)="toggleShowMore(scheduleSlot)">
                  <ng-container *ngIf="scheduleSlot.showMore" i18n="@@showLess">Show less</ng-container>
                  <ng-container *ngIf="!scheduleSlot.showMore" i18n="@@showMore">Show more</ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <div
              class="schedule-occupancy-rate"
              i18n="@@publicSessionTemplateOccupancyRate"
              *ngIf="scheduleSlot.participantsLimit > scheduleSlot.currentOccupancyRate">
              {{ scheduleSlot.participantsLimit - scheduleSlot.currentOccupancyRate }}/{{
                scheduleSlot.participantsLimit
              }}
              seats available
            </div>
            <div
              class="schedule-occupancy-rate"
              i18n="@@publicSessionTemplateSessionFull"
              *ngIf="scheduleSlot.currentOccupancyRate >= scheduleSlot.participantsLimit">
              This session is now full
            </div>
            <app-button
              *ngIf="
                scheduleSlot.participantsLimit > scheduleSlot.currentOccupancyRate || !scheduleSlot.participantsLimit
              "
              className="btn-primary"
              i18n="@@templateScheduleBookBtnName"
              (click)="emitBook(scheduleSlot)">
              Book
            </app-button>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn-link more-dates-btn"
      i18n="@@moreScheduleDates"
      *ngIf="template.schedule.length > currentVisibleScheduleItemsCount"
      (click)="showMoreScheduleDates()">
      See more dates
    </button>
    <button
      type="button"
      class="btn-link more-dates-btn"
      *ngIf="currentVisibleScheduleItemsCount > minVisibleScheduleItemsCount"
      (click)="hideScheduleDates()">
      <ng-container i18n="@@hide">Hide</ng-container>
    </button>
  </div>
</app-service-landing>

<ng-template #priceSessionTmpl let-showPlatformCurrency="showPlatformCurrency">
  <span *ngIf="showPlatformCurrency">{{ template?.price | platformCurrency }}</span>
  <span *ngIf="!showPlatformCurrency">{{ template?.price }}</span
  >/<span i18n="@@templateSession">session</span>
</ng-template>

<ng-template #noPriceTmpl>
  <span i18n="@@templateFree">Free</span>
</ng-template>

<ng-template #pricePersonTmpl let-showPlatformCurrency="showPlatformCurrency">
  <span *ngIf="showPlatformCurrency">{{ template?.price | platformCurrency }}</span>
  <span *ngIf="!showPlatformCurrency">{{ template?.price }}</span
  >/<span i18n="@@templatePerson">person</span>
</ng-template>
