import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

import { ROOT_PATH, ROUTER_OUTLET } from './routes';

// TODO @artak.m@profi.io Need to remove service type from router config.
const routes: Routes = [
  {
    outlet: ROUTER_OUTLET,
    path: '',
    children: [
      {
        path: `${ROOT_PATH}/:type/:serviceId/host`,
        loadChildren: () =>
          import('./modules/choose-service-host/choose-service-host.module').then(
            module => module.ChooseServiceHostModule
          )
      },
      {
        path: ROOT_PATH,
        loadChildren: () =>
          import('./modules/choose-service/choose-service.module').then(module => module.ChooseServiceModule)
      },
      {
        path: `${ROOT_PATH}/${GuideServiceTypes.GROUP_SESSION}/:id`,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/service-form/service-form.module').then(module => module.ServiceFormModule)
          }
        ]
      },
      {
        path: `${ROOT_PATH}/${GuideServiceTypes.SESSION}/:id`,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/service-form/service-form.module').then(module => module.ServiceFormModule)
          }
        ]
      },
      {
        path: `${ROOT_PATH}/${GuideServiceTypes.PACKAGE}/:id`,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/program-or-package-form/program-or-package-form.module').then(
                module => module.ProgramOrPackageFormModule
              )
          }
        ]
      },
      {
        path: `${ROOT_PATH}/${GuideServiceTypes.PROGRAM}/:id`,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/program-or-package-form/program-or-package-form.module').then(
                module => module.ProgramOrPackageFormModule
              )
          }
        ]
      },
      {
        path: `${ROOT_PATH}/propose-service`,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/propose-service-list/propose-service-list.module').then(
                module => module.ProposeServiceListModule
              )
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChooseServiceRoutingModule {}
