import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { filter, tap } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AnalyticEvent } from '@app/core/analytics/events/analytic.event';
import { SegmentService } from '@app/core/analytics/segment.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { CurrencyService } from '@app/core/currency/currency.service';
import { LogService } from '@app/core/log/log.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { IServerSession } from '@app/shared/interfaces/session';

import {
  BookTypes,
  FbqEvent,
  FbqEvents,
  GtagConfig,
  GtagEvent,
  GtagEvents,
  IAnalyticParams,
  InternalEvents,
  SegmentEvents
} from './types';

/**
 * Link to check https://awarenow.activehosted.com/admin/main.php?action=list
 */
const ACTIVE_CAMPAIGN_COACHES_LIST_ID = 35;
const ACTIVE_CAMPAIGN_CLIENTS_LIST_ID = 40;

const DAYS_TO_LIVE = 30;

const USER_ROLES_HASH = {
  1: 'Client',
  2: 'Guide',
  3: 'Admin'
};

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private readonly isBrowser: boolean = false;

  // @ts-expect-error TS2322
  private userRole: UserRoles = null;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(GLOBAL_WINDOW) private _window: Window | any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private _platform: any,
    private readonly _cookieService: CookieService,
    private readonly _currencyService: CurrencyService,
    private readonly _http: HttpClient,
    private readonly _logService: LogService,
    private readonly _router: Router,
    private readonly _segmentService: SegmentService,
    private readonly _runtimeConfigService: RuntimeConfigService // private localStorageService: LocalStorageService
  ) {
    this.isBrowser = isPlatformBrowser(this._platform);
    if (this.isBrowser) {
      _router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          tap(() => this.pageview()),
          // eslint-disable-next-line id-length
          tap((e: RouterEvent) => {
            if (_window.Intercom) {
              const intercomOptions = {
                hide_default_launcher: false,
                last_request_at: parseInt(String(new Date().getTime() / 1000), 10)
              };
              if (e.url.match(/^.*\/chat(\/.*)?/i)) {
                intercomOptions.hide_default_launcher = true;
              }
              _window.Intercom('update', intercomOptions);
            }
          })
        )
        .subscribe();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  initSegment(pageTracking = false) {
    this._segmentService.init(pageTracking);
  }

  getAnalyticParamsFromCookie(): IAnalyticParams {
    return {
      referrer: this._cookieService.get('referrer'),
      landing: this._cookieService.get('landing'),
      visitDate: this._cookieService.get('first-visit-date'),
      utmSource: this._cookieService.get('utm_source'),
      utmCampaign: this._cookieService.get('utm_campaign'),
      utmMedium: this._cookieService.get('utm_medium'),
      utmTerm: this._cookieService.get('utm_term'),
      // @ts-expect-error TS2322
      utmHost: this._cookieService.get('utm_host') || null
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  event(eventName: InternalEvents, rawData: { [key: string]: any } = {}) {
    if (!this.isBrowser) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
    const data: { [key: string]: any } = {
      ...rawData,
      ws_type: localStorage.getItem('ws_type') || 'Unknown',
      ws_id: localStorage.getItem('ws_id') || 'Unknown',
      ws_name: localStorage.getItem('ws_name') || 'Unknown'
    };

    console.log(eventName, data);

    switch (eventName) {
      case InternalEvents.ACCEPT_SESSION_OFFER:
        this.gtagEvent(GtagEvents.PURCHASE, {
          book_type: BookTypes.OFFER,
          payment_method: data.paymentMethod,
          ...this.getGtagSessionParams(data.session)
        });
        this.fbqEvent(FbqEvents.PURCHASE, this.getFbqSessionParams(data.session));
        break;
      case InternalEvents.ADD_PAYMENT_INFO:
        this.gtagEvent(GtagEvents.ADD_PAYMENT_INFO);
        this.fbqEvent(FbqEvents.ADD_PAYMENT_INFO);
        break;
      case InternalEvents.BEGIN_BOOK:
        this.gtagEvent(GtagEvents.BEGIN_CHECKOUT);
        this.fbqEvent(FbqEvents.INITIATE_CHECKOUT);
        break;
      case InternalEvents.BLOCK_TIME_SAVED:
        this._segmentService.event(SegmentEvents.BLOCK_TIME_SAVED, data);
        break;
      case InternalEvents.BLOG:
        this.fbqEvent(FbqEvents.VIEW_CONTENT);
        break;
      case InternalEvents.BLOG_ARTICLE:
        this.fbqEvent(FbqEvents.VIEW_CONTENT);
        break;
      case InternalEvents.BLOG_STORY_DRAFT_SAVED:
        this._segmentService.event(SegmentEvents.BLOG_STORY_DRAFT_SAVED, data);
        break;
      case InternalEvents.BLOG_STORY_PUBLISHED:
        this._segmentService.event(SegmentEvents.BLOG_STORY_PUBLISHED, data);
        break;
      case InternalEvents.BOOK_SESSION:
        this.gtagEvent(GtagEvents.PURCHASE, {
          book_type: BookTypes.BOOK,
          payment_method: data.paymentMethod,
          ...this.getGtagSessionParams(data.session)
        });
        this.fbqEvent(FbqEvents.PURCHASE, this.getFbqSessionParams(data.session));
        if (data.session && data.session.free) {
          this.fbqEvent(FbqEvents.START_TRIAL);
        }
        break;
      case InternalEvents.BOOK_STEP_DATE:
        this.gtagEvent(GtagEvents.CHECKOUT_PROGRESS, { checkout_step: 1 });
        break;
      case InternalEvents.BOOK_STEP_TIME:
        this.gtagEvent(GtagEvents.CHECKOUT_PROGRESS, { checkout_step: 2 });
        break;
      case InternalEvents.CALENDAR_CONNECT:
        this._segmentService.event(SegmentEvents.CALENDAR_CONNECT, data);
        break;
      case InternalEvents.CALENDAR_DISCONNECT:
        this._segmentService.event(SegmentEvents.CALENDAR_DISCONNECT, data);
        break;
      case InternalEvents.CANCEL_SESSION:
        this.gtagEvent(GtagEvents.REFUND, this.getGtagSessionParams(data.session));
        break;
      case InternalEvents.CLIENT_INVITE:
        this._segmentService.event(SegmentEvents.CLIENT_INVITE, data);
        break;
      case InternalEvents.EMAIL_CONFIRMED:
        this._segmentService.event(SegmentEvents.EMAIL_CONFIRMED, data);
        break;
      case InternalEvents.FEE_SET:
        this._segmentService.event(SegmentEvents.FEE_SET, data);
        break;
      case InternalEvents.FREE_PLAN_STARTED:
        this._segmentService.event(SegmentEvents.FREE_PLAN_STARTED, data);
        break;
      case InternalEvents.FREE_PROGRAM_CREATED:
        this._segmentService.event(SegmentEvents.FREE_PROGRAM_CREATED, data);
        break;
      case InternalEvents.GIFT_COUPON:
        this.fbqEvent(FbqEvents.VIEW_CONTENT);
        break;
      case InternalEvents.GUIDES_LIST:
        this.gtagEvent(GtagEvents.VIEW_ITEM_LIST);
        break;
      case InternalEvents.GUIDE_PROFILE:
        this.gtagEvent(GtagEvents.VIEW_ITEM, this.getGtagProfileParams(data.profile));
        this.fbqEvent(FbqEvents.VIEW_CONTENT, this.getFbqProfileParams(data.profile));
        break;
      case InternalEvents.HELP_FIND_GUIDE:
        this.gtagEvent(GtagEvents.HELP_FIND_GUIDE);
        break;
      case InternalEvents.JOURNALING_PROMPT_CONNECTED_TO_PROGRAM:
        this._segmentService.event(SegmentEvents.JOURNALING_PROMPT_CONNECTED_TO_PROGRAM, data);
        break;
      case InternalEvents.JOURNALING_PROMPT_CREATED:
        this._segmentService.event(SegmentEvents.JOURNALING_PROMPT_CREATED, data);
        break;
      case InternalEvents.JOURNALING_PROMPT_INVITED_CLIENT:
        this._segmentService.event(SegmentEvents.JOURNALING_PROMPT_INVITED_CLIENT, data);
        break;
      case InternalEvents.LINK_COPY:
        this._segmentService.event(SegmentEvents.LINK_COPY, data);
        break;
      case InternalEvents.LOGIN:
        this._segmentService.event(SegmentEvents.LOGIN, data);
        break;
      case InternalEvents.MESSAGE_SENT_BY_CLIENT:
        this._segmentService.event(SegmentEvents.MESSAGE_SENT_BY_CLIENT, data);
        break;
      case InternalEvents.MESSAGE_SENT_BY_COACH:
        this._segmentService.event(SegmentEvents.MESSAGE_SENT_BY_COACH, data);
        break;
      case InternalEvents.PAID_PROGRAM_CREATED:
        this._segmentService.event(SegmentEvents.PAID_PROGRAM_CREATED, data);
        break;
      case InternalEvents.PLATFORM_SUBSCRIBED:
        this._segmentService.event(SegmentEvents.PLATFORM_SUBSCRIBED, data);
        break;
      case InternalEvents.PLATFORM_SUBSCRIPTION_CANCELED:
        this._segmentService.event(SegmentEvents.PLATFORM_SUBSCRIPTION_CANCELED, data);
        break;
      case InternalEvents.PLATFORM_SUBSCRIPTION_PAID:
        this._segmentService.event(SegmentEvents.PLATFORM_SUBSCRIPTION_PAID, data);
        break;
      case InternalEvents.PROFILE_PRIVACY_CHANGED:
        const flag = data.profileType === 'public' ? 'On' : 'Off';
        this._segmentService.event(SegmentEvents.PROFILE_PRIVACY_CHANGED, { 'Public profile': flag });
        break;
      case InternalEvents.PROFILE_UPDATED:
        this._segmentService.event(SegmentEvents.PROFILE_UPDATED, data);
        break;
      case InternalEvents.PROGRAM_INVITED_CLIENT:
        this._segmentService.event(SegmentEvents.PROGRAM_INVITED_CLIENT, data);
        break;
      case InternalEvents.PROGRAM_MODULE_ADDED:
        this._segmentService.event(SegmentEvents.PROGRAM_MODULE_ADDED, data);
        break;
      case InternalEvents.QUIZ_CREATED:
        this._segmentService.event(SegmentEvents.QUIZ_CREATED, data);
        break;
      case InternalEvents.QUIZ_INVITED_CLIENT:
        this._segmentService.event(SegmentEvents.QUIZ_INVITED_CLIENT, data);
        break;
      case InternalEvents.RUN_TEST_COMPLETED:
        this._segmentService.event(SegmentEvents.RUN_TEST_COMPLETED, data);
        break;
      case InternalEvents.RUN_TEST_INITIATED:
        this._segmentService.event(SegmentEvents.RUN_TEST_INITIATED, data);
        break;
      case InternalEvents.SEARCH:
        this.gtagEvent(GtagEvents.SEARCH, { search_term: data.searchTerm });
        this.gtagEvent(GtagEvents.VIEW_SEARCH_RESULTS, { search_term: data.searchTerm });
        this.fbqEvent(FbqEvents.SEARCH, { search_string: data.searchTerm });
        break;
      case InternalEvents.SERVICE_BOOK:
        this._segmentService.event(SegmentEvents.SERVICE_BOOK, data);
        break;
      case InternalEvents.SERVICE_CREATE:
        this._segmentService.event(SegmentEvents.SERVICE_CREATE, data);
        break;
      case InternalEvents.SIGN_UP:
        this.fbqEvent(FbqEvents.COMPLETE_REGISTRATION);
        this._segmentService.event(SegmentEvents.SIGN_UP, data);
        break;
      case InternalEvents.SIGN_UP_CLIENT:
        this._segmentService.event(SegmentEvents.SIGN_UP_CLIENT, data);
        break;
      case InternalEvents.SIGN_UP_PRACTITIONER:
        this._segmentService.event(SegmentEvents.SIGN_UP_PRACTITIONER, data);
        break;
      case InternalEvents.TRIAL_STARTED:
        this._segmentService.event(SegmentEvents.TRIAL_STARTED, data);
        break;
      case InternalEvents.WIKI:
        this.fbqEvent(FbqEvents.VIEW_CONTENT);
        break;
      case InternalEvents.WITHDRAW_ACCOUNT_CONNECT:
        this._segmentService.event(SegmentEvents.WITHDRAW_ACCOUNT_CONNECT, data);
        break;
      case InternalEvents.WORKING_HOURS_SAVED:
        this._segmentService.event(SegmentEvents.WORKING_HOURS_SAVED, data);
        break;
      case InternalEvents.ZOOM_INTEGRATED:
        this._segmentService.event(SegmentEvents.ZOOM_INTEGRATED, data);
        break;
      default:
        this._segmentService.event(eventName, data);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  setUserProperties(user: any) {
    const userProperties = {
      ...(user.firstName && user.lastName && { name: `${user.firstName} ${user.lastName}` }),
      ...(user.email && { email: user.email }),
      ...(user.createdAt && { signup_date: DateTime.fromISO(user.createdAt).toFormat('yyyy/MM/dd') }),
      ...(user.RoleId && {
        // @ts-expect-error TS7053
        role: USER_ROLES_HASH[user.RoleId],
        ...(user.RoleId !== UserRoles.ADMIN && {
          lists: [
            {
              id: user.RoleId === 2 ? ACTIVE_CAMPAIGN_COACHES_LIST_ID : ACTIVE_CAMPAIGN_CLIENTS_LIST_ID,
              status: 'active'
            }
          ]
        })
      }),
      platform: this._runtimeConfigService.get('platformName')?.toLowerCase(),
      ...(user.analyticStats &&
        user.analyticStats.landing && { landing: decodeURIComponent(user.analyticStats.landing) }),
      ...(user.analyticStats &&
        user.analyticStats.referrer && { referrer: decodeURIComponent(user.analyticStats.referrer) }),
      ...(user.analyticStats && user.analyticStats.test && { test: user.analyticStats.test }),
      ...(user.analyticStats &&
        user.analyticStats.firstPlatformSubscriptionDate && {
          firstPaymentDate: new Date(user.analyticStats.firstPlatformSubscriptionDate).toLocaleDateString()
        }),
      ...(user.membership && user.membership.plan && { plan: user.membership.plan }),
      ...(user.membership && user.membership.status && { subscription_status: user.membership.status })
    };

    this._segmentService.identify(user.id, userProperties);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setUserRole(userRole: UserRoles) {
    this.userRole = userRole;
  }

  storeUserStatistics(): void {
    const landing = encodeURIComponent(this._window.location.href);
    const referrer = encodeURIComponent(this._window.document.referrer) || landing;
    const urlParams = new URLSearchParams(this._window.location.search);

    if (!this._cookieService.check('referrer')) {
      // @ts-expect-error TS2345
      this._cookieService.set('referrer', referrer, DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (!this._cookieService.check('landing')) {
      // @ts-expect-error TS2345
      this._cookieService.set('landing', landing, DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (!this._cookieService.check('first-visit-date')) {
      // @ts-expect-error TS2345
      this._cookieService.set('first-visit-date', DateTime.local().toISO(), DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (urlParams.get('utm_source') && !this._cookieService.check('utm_source')) {
      // @ts-expect-error TS2345
      this._cookieService.set('utm_source', urlParams.get('utm_source'), DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (urlParams.get('utm_medium') && !this._cookieService.check('utm_medium')) {
      // @ts-expect-error TS2345
      this._cookieService.set('utm_medium', urlParams.get('utm_medium'), DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (urlParams.get('utm_campaign') && !this._cookieService.check('utm_campaign')) {
      // @ts-expect-error TS2345
      this._cookieService.set('utm_campaign', urlParams.get('utm_campaign'), DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (urlParams.get('utm_term') && !this._cookieService.check('utm_term')) {
      // @ts-expect-error TS2345
      this._cookieService.set('utm_term', urlParams.get('utm_term'), DAYS_TO_LIVE, '/', null, null, 'Lax');
    }

    if (urlParams.get('utm_host') && !this._cookieService.check('utm_host')) {
      // @ts-expect-error TS2345
      this._cookieService.set('utm_host', urlParams.get('utm_host'), DAYS_TO_LIVE, '/', null, null, 'Lax');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private pageview() {
    const params: GtagConfig = {
      page_path: this._router.url,
      page_title: this._window.document.title,
      page_location: this._window.location.href
    };

    this.gtagConfig(params);

    this.fbqEvent(FbqEvents.PAGE_VIEW);
  }

  // @ts-expect-error TS7019
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private gtag(...args) {
    try {
      if (
        this._window.gtag &&
        this._runtimeConfigService.get('GA_MEASUREMENT_ID') &&
        (!this.userRole || this.userRole === UserRoles.CLIENT)
      ) {
        this._window.gtag(...args);
      }
    } catch (err) {
      this._logService.error(err, 'gtag error');
    }
  }

  // @ts-expect-error TS7019
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private fbq(...args) {
    try {
      if (
        this._window.fbq &&
        this._runtimeConfigService.get('FB_PIXEL_ID') &&
        (!this.userRole || this.userRole === UserRoles.CLIENT)
      ) {
        this._window.fbq(...args);
      }
    } catch (err) {
      this._logService.error(err, 'fbq error');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private gtagConfig(params: GtagConfig) {
    this.gtag('config', this._runtimeConfigService.get('GA_MEASUREMENT_ID'), params);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private gtagEvent(eventName: GtagEvents, params: GtagEvent = {}) {
    this.gtag('event', eventName, params);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private fbqEvent(eventName: FbqEvents, params: FbqEvent = {}) {
    this.fbq('track', eventName, params);
  }

  private getSessionPrice(session: IServerSession): number {
    // @ts-expect-error TS2533
    return session.free ? 0 : Math.round((session.duration / 60) * session.payRate * 100) / 100;
  }

  private getGtagSessionParams(session: IServerSession): GtagEvent {
    let gtagEvent: GtagEvent = {};
    try {
      gtagEvent = {
        transaction_id: `S${session.id}`,
        value: this.getSessionPrice(session),
        currency: this._currencyService.defaultCurrency,
        items: [
          {
            id: `G${session.guideId}`,
            category: 'guides',
            variant: session.free ? 'free' : 'paid'
          }
        ]
      };
      // eslint-disable-next-line id-length
    } catch (e) {
      this._logService.error(e, 'getGtagSessionParams');
    }
    return gtagEvent;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getGtagProfileParams(profile: any): GtagEvent {
    let gtagEvent: GtagEvent = {};
    try {
      gtagEvent = {
        items: [
          {
            id: `G${profile.id}`,
            category: 'guides',
            name: `${profile.firstName} ${profile.lastName}`,
            price: profile.pricePerHour
          }
        ]
      };
      // eslint-disable-next-line id-length
    } catch (e) {
      this._logService.error(e, 'getGtagProfileParams');
    }
    return gtagEvent;
  }

  private getFbqSessionParams(session: IServerSession): FbqEvent {
    let fbqEvent: FbqEvent = {};
    try {
      fbqEvent = {
        value: this.getSessionPrice(session),
        currency: this._currencyService.defaultCurrency,
        content_ids: [`G${session.guideId}`],
        content_type: 'guide'
      };
      // eslint-disable-next-line id-length
    } catch (e) {
      this._logService.error(e, 'getFbqSessionParams');
    }
    return fbqEvent;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getFbqProfileParams(profile: any): FbqEvent {
    let fbqEvent: FbqEvent = {};
    try {
      fbqEvent = {
        value: profile.pricePerHour,
        currency: this._currencyService.defaultCurrency,
        content_name: `${profile.firstName} ${profile.lastName}`,
        content_ids: [`G${profile.id}`],
        content_type: 'guide'
      };
      // eslint-disable-next-line id-length
    } catch (e) {
      this._logService.error(e, 'getFbqProfileParams');
    }
    return fbqEvent;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private capitalizeStr(str: string) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  }

  emitEvent({ eventName, data }: AnalyticEvent) {
    this.event(eventName, data);
  }
}
