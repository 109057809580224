import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { BaseModuleProgress, IBaseModuleProgressDetails } from './base-module-progress';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IContentModuleProgressDetails extends IBaseModuleProgressDetails {
  readonly content: string | null;
}

export class ContentModuleProgress extends BaseModuleProgress {
  readonly content: string | null;

  get moduleType(): ModuleTypes {
    return ModuleTypes.CONTENT;
  }

  constructor(moduleDetails: Readonly<IContentModuleProgressDetails>) {
    super(moduleDetails);

    this.content = moduleDetails.content;
  }
}
