import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionsService } from '@app/core/session/sessions.service';
import { IServiceReschedulingApi, ISessionReschedulingDetails } from '@app/modules/rescheduling-service/types';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';

// NOTE: service works as adapter
@Injectable({
  providedIn: 'root'
})
export class ServiceReschedulingApiService
  implements IServiceReschedulingApi<ISessionReschedulingDetails, Observable<void>>
{
  constructor(
    private readonly _sessions: SessionsService,
    private readonly _currentPayment: CurrentPaymentService,
    private readonly _auth: AuthService
  ) {}

  // @ts-expect-error TS2366
  reschedule$(reschedulingDetails: ISessionReschedulingDetails, isFree = false): Observable<void> {
    const { id: sessionId, date, collectionType, message } = reschedulingDetails;

    if (this._auth.user.RoleId === UserRoles.GUIDE) {
      // @ts-expect-error TS2345
      return this._sessions.reschedule$(sessionId, date, collectionType, message);
    }

    if (this._auth.user.RoleId === UserRoles.CLIENT) {
      // @ts-expect-error TS2345
      return this._currentPayment.pay(isFree).reschedule$(sessionId, date, collectionType, message);
    }
  }
}
