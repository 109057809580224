import { Injectable } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  get defaultCurrency(): string {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('defaultCurrency');
  }

  get defaultCurrencySign(): string {
    // @ts-expect-error TS2322
    return this._runtimeConfigService.get('defaultCurrencySign');
  }

  constructor(private readonly _runtimeConfigService: RuntimeConfigService) {}
}
