const normalizeFullName = (user: { firstName: string; lastName: string } | null): null | string => {
  if (!user) {
    return null;
  }

  const firstName = user.firstName || '';
  const lastName = user.lastName || '';

  return `${firstName} ${lastName}`.trim();
};

export { normalizeFullName };
