<div class="container">
  <div class="row">
    <div class="col-md-8 offset-md-2 breadcrumbs">
      <a [routerLink]="['/']">Home</a>
      <span class="breadcrumb-delimiter">/</span>
      <a [routerLink]="['/blog']">Blog</a>
      <ng-container *ngIf="article?.author.namedUrl">
        <span class="breadcrumb-delimiter">/</span>
        <a [routerLink]="['/blog', article?.author?.namedUrl]">
          {{ article?.author?.name || article?.author?.namedUrl }}
        </a>
        <span class="breadcrumb-delimiter">/</span>
        <a [routerLink]="['../']">{{ article?.title }}</a>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <app-blog-article-form
        [article]="article"
        [canSendOut]="canSendOut"
        [isBusinessTypeAllowed]="canCreateBusinessArticles"
        [isSaveDisabled]="isSaving"
        [tags]="tags"
        (save)="publish($event)"
        (sendOut)="sendOut($event)"
        (uploadCover)="uploadCover($event)"></app-blog-article-form>
    </div>
  </div>
</div>
