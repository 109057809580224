import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { GlobalConfig } from '@cnf/types';

@Injectable({
  providedIn: 'root'
})
export class PlatformConfigurationService {
  // @ts-expect-error TS2345
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _globalConfig$: BehaviorSubject<GlobalConfig> = new BehaviorSubject<GlobalConfig>(null);

  readonly globalConfig$: Observable<GlobalConfig> = this._globalConfig$
    .asObservable()
    .pipe(filter(config => !!config));

  readonly billingDataRequired$: Observable<boolean> = this.globalConfig$.pipe(
    map((config: GlobalConfig) => config?.enablePaymentsRequiredTaxIdWithAddresses ?? false)
  );

  readonly switchConfigurations$: Observable<Pick<GlobalConfig, 'hideSignInAs' | 'hideSignUpAs'>> =
    this.globalConfig$.pipe(map(({ hideSignInAs, hideSignUpAs }: GlobalConfig) => ({ hideSignInAs, hideSignUpAs })));

  constructor(private readonly _brandingService: BrandingService) {
    _brandingService.globalConfig$.subscribe((config: GlobalConfig) => this._globalConfig$.next(config));
  }

  get billingDataRequired(): boolean {
    const globalConfig: GlobalConfig = this._globalConfig$.getValue();
    return globalConfig.enablePaymentsRequiredTaxIdWithAddresses;
  }
}
