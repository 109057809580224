import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorSectionWrapperComponent } from '@app/screens/guide/modules/widgets/guide-services/components';

@NgModule({
  declarations: [EditorSectionWrapperComponent],
  imports: [CommonModule],
  exports: [EditorSectionWrapperComponent]
})
export class GuideServicesModule {}
