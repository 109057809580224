import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { WidgetDirective } from '@app/cdk/widgets';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISidenavWorkspaceSwitchView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popup: ComponentType<any>;
}

@Component({
  selector: 'app-sidenav-workspace-switch-widget',
  template: '<ng-container #element></ng-container>',
  providers: [UiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavWorkspaceSwitchWidgetComponent
  extends WidgetDirective<ISidenavWorkspaceSwitchView>
  implements AfterViewInit
{
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @ViewChild('element', { read: ViewContainerRef }) _viewContainerRef: ViewContainerRef;
}
