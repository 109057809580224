import { PuiDrawerConfig } from '@awarenow/profi-ui-core';
import {
  SessionTemplateCustomAvailability,
  SessionTemplateSchedule
} from '@app/screens/guide/guide-sessions-templates/types';

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

export enum AvailabilityType {
  WORKING_HOURS = 'WORKING_HOURS',
  CUSTOM = 'CUSTOM',
  NONE = 'NONE'
}

export interface SessionTemplateAvailabilityForm {
  active: boolean;
  availabilityType: AvailabilityType;
  sessionTemplateScheduleId?: number | null;
  customAvailability?: SessionTemplateCustomAvailability | null;
  availabilities?: SessionTemplateSchedule[][];
  selectedAvailability: SessionTemplateSchedule;
}
