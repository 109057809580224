import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId) && !!localStorage;
  }

  getItem(key: string): string | null {
    if (this.isBrowser) {
      return localStorage.getItem(key);
    }

    return null;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setItem(key, value) {
    if (this.isBrowser) {
      return localStorage.setItem(key, value);
    }

    return null;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  removeItem(key) {
    if (this.isBrowser) {
      return localStorage.removeItem(key);
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clear() {
    if (this.isBrowser) {
      // TODO This solution is not convenient to expand, but let it be here for now.
      const prevStorage = Object.entries(localStorage).filter(([key]) => /^__/.test(key));

      localStorage.clear();
      prevStorage.forEach(([key, value]) => {
        this.setItem(key, value);
      });
    }
  }
}
