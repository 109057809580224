<h3 data-qa-id="session-time-picker-choose-time-title" class="title" i18n="@@reschedulingDetailsFormTitle">
  Choose a time
</h3>

<h4 data-qa-id="session-time-picker-session-name-subtitle" class="sub-title">{{ name }}</h4>

<ng-container [ngSwitch]="scheduleType">
  <div class="schedule-container">
    <app-public-guide-schedule
      *ngSwitchCase="'guidePublicSchedule'"
      [options]="guidePublicScheduleOptions"
      (timeSelect)="selectTime($event)"></app-public-guide-schedule>

    <app-private-guide-schedule
      *ngSwitchCase="'guidePrivateSchedule'"
      [options]="guidePrivateScheduleOptions"
      (timeSelect)="selectTime($event)"></app-private-guide-schedule>

    <app-guide-service-schedule
      *ngSwitchCase="'guideServiceSchedule'"
      [options]="guideServiceScheduleOptions"
      (timeSelect)="selectTime($event)"></app-guide-service-schedule>
  </div>

  <div class="management-panel">
    <app-button
      data-qa-id="session-time-picker-confirm-time-button"
      className="btn-main"
      [disabled]="!timeSelected"
      (click)="confirmTime()"
      i18n="@@confirmationBtnText">
      Confirm time
    </app-button>
  </div>
</ng-container>
