import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { Quiz } from '../quiz';
import { DiffDetails, IDiff } from '../util-types';
import { BaseModule, cleanBaseModule, IBaseModuleDetails } from './base-module';
import { ModuleAccessTypes } from './module-access-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizModuleDetails extends IBaseModuleDetails {
  quiz: Quiz | null;
}

export class QuizModule extends BaseModule implements IDiff<IQuizModuleDetails> {
  readonly quiz: Quiz | null;

  // @ts-expect-error TS2564
  accessType: ModuleAccessTypes;

  get moduleType(): ModuleTypes {
    return ModuleTypes.QUIZ;
  }

  constructor(quizModuleDetails: Readonly<IQuizModuleDetails>) {
    super(quizModuleDetails);

    this.quiz = quizModuleDetails.quiz;
  }

  getDiffFrom(quizModuleValue: Readonly<QuizModule>): DiffDetails<IQuizModuleDetails> | null {
    const baseDiff = super.getDiffFrom(quizModuleValue);

    // @ts-expect-error TS2531
    const quizDiff = quizModuleValue.quiz.questions ? this.quiz.getDiffFrom(quizModuleValue.quiz) : null;

    if (!baseDiff && !quizDiff) {
      return null;
    }

    const diff: DiffDetails<IQuizModuleDetails> = baseDiff
      ? { ...baseDiff }
      : {
          id: this.id,
          localId: this.localId,
          order: this.order
        };

    if (quizDiff) {
      diff.quiz = quizDiff;
    }

    return diff;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON(): any {
    return {
      ...super.toJSON(),
      quiz: this.quiz,
      moduleType: this.moduleType
    };
  }
}

export function cleanQuizModule(quizModule: Readonly<IQuizModuleDetails>): IQuizModuleDetails {
  return {
    ...cleanBaseModule(quizModule),
    quiz: quizModule.quiz
  };
}
