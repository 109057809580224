import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-program-done',
  templateUrl: './program-done.component.html',
  styleUrls: ['./program-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'program-done'
  }
})
export class ProgramDoneComponent {
  @Input()
  // @ts-expect-error TS2564
  completionDate: string;
}
