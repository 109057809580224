import { PaymentTypes } from '@app/shared/enums/payment-types';

export const refundPaymentTypes: string[] = [
  PaymentTypes.REFUNDED_PREPAYMENT,
  PaymentTypes.REFUNDED_PAYMENT,
  PaymentTypes.REFUNDED_WALLET_PREPAYMENT,
  PaymentTypes.REFUNDED_WALLET_PAYMENT,
  PaymentTypes.REFUNDED_PAYPAL_PAYMENT,
  PaymentTypes.REFUNDED_STRIPE_PAYMENT
];
