<div class="pui-container">
  <h2
    data-qa-id="guide-relation-share-notes-guide-sharing-notes-settings-title"
    class="share-notes-header"
    i18n="@@guideSharingNotesSettingsTitle"
    pui-typography="h2"
    *ngIf="!noteId">
    Access to all my notes on this client
  </h2>
  <h2
    data-qa-id="guide-relation-share-notes-guide-sharing-single-note-settings-title"
    class="share-notes-header"
    i18n="@@guideSharingSingleNoteSettingsTitle"
    pui-typography="h2"
    *ngIf="noteId">
    Access to this note
  </h2>
  <a
    href="javascript:"
    pui-link
    (click)="closeSharingSettings()"
    data-qa-id="guide-relation-share-notes-back-to-notes-list-link">
    <pui-icon svgIcon="pui:chevron-left" size="s"></pui-icon>
    <span class="pui-space_left-1 font-600" i18n="@@guideSharingNotesSettingsBackButtonText">Back to notes list</span>
  </a>
  <div
    data-qa-id="guide-relation-share-notes-guide-sharing-notes-settings-manage-text"
    pui-typography="body-s"
    class="manage-text"
    i18n="@@guideSharingNotesSettingsManageText"
    *ngIf="!noteId">
    Manage access to all your notes related to this client.
  </div>
  <div
    data-qa-id="guide-relation-share-notes-guide-sharing-single-note-settings-manage-text"
    pui-typography="body-s"
    class="manage-text"
    i18n="@@guideSharingSingleNoteSettingsManageText"
    *ngIf="noteId">
    Manage access to this note.
  </div>
  <ul class="list">
    <li class="list-item" *ngFor="let teamMember of teamMembers">
      <pui-paper>
        <pui-card>
          <pui-card-header>
            <div pui-card-header-title>
              <span
                [attr.data-qa-id]="'guide-relation-share-notes-client-username-title-' + teamMember?.userId"
                class="contact-name font-600">
                {{ { firstName: teamMember.firstName, lastName: teamMember.lastName } | userName }}
              </span>
            </div>
            <ng-container ngProjectAs="[pui-card-header-subheader]" [ngSwitch]="teamMember.type">
              <span
                pui-card-header-subheader
                [attr.data-qa-id]="'guide-relation-share-notes-client-role-title-' + teamMember?.userId"
                *ngSwitchCase="teamMemberSharingSettingsUserType.TEAM_MEMBER">
                {{ teamMember.role | workspaceRoleText }}
              </span>
              <span
                pui-card-header-subheader
                [attr.data-qa-id]="'guide-relation-share-notes-client-role-title-' + teamMember?.userId"
                *ngSwitchCase="teamMemberSharingSettingsUserType.CLIENT"
                i18n="@@guideSharingNotesSettingsClient">
                Client
              </span>
            </ng-container>
            <pui-badge-host pui-card-header-icon>
              <pui-avatar
                [attr.data-qa-id]="'guide-relation-share-notes-client-avatar-' + teamMember?.userId"
                [text]="teamMember?.name"
                [avatarUrl]="teamMember.photo"></pui-avatar>
            </pui-badge-host>
            <div pui-card-header-action class="contact-action">
              <ng-select
                [attr.data-qa-id]="'guide-relation-share-notes-access-type-select-' + teamMember?.userId"
                [ngModel]="teamMember.accessType"
                [searchable]="false"
                [clearable]="false"
                [items]="accessOptions"
                bindLabel="label"
                bindValue="value"
                appendTo="body"
                (change)="updateTeamMemberAccess($event, teamMember.userId)"></ng-select>
            </div>
          </pui-card-header>
        </pui-card>
      </pui-paper>
    </li>
  </ul>
  <div pui-typography="body-s" class="contact-text" *ngIf="isTeam">
    <ng-container i18n="@@guideSharingNotesSettingsContactAdmin" *ngIf="!isTeamAdmin">
      Contact your team admin if you need to share the note with another team member.
    </ng-container>
    <ng-container i18n="@@guideSharingNotesSettingsAdminShareNotes" *ngIf="isTeamAdmin && !noteId">
      If you need to share the notes with another team member, assign them to the client.
    </ng-container>
    <ng-container i18n="@@guideSharingNotesSettingsAdminShareNote" *ngIf="isTeamAdmin && noteId">
      If you need to share the note with another team member, assign them to the client.
    </ng-container>
  </div>
</div>
