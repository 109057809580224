import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GuideClientData } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/type';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { GuideClientsFeaturesService } from '@app/screens/guide/guide-clients/guide-client/services/features/guide-clients-features.service';
import { clientsByClientId } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
import { PUI_DRAWER_DATA, PuiDrawerRef } from '@awarenow/profi-ui-core';
import { Store } from '@ngrx/store';

import { GuideClientCardTabService } from './services/guide-client-card-tab.service';
import { guideClientsFetchAll } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';

@Component({
  templateUrl: './guide-client-card.component.html',
  styleUrls: ['./guide-client-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuideClientCardTabService]
})
export class GuideClientCardComponent implements OnInit {
  readonly client$: Observable<CRMClient> = this.getClient().pipe(
    tap(client => {
      if (!client) {
        this.store$.dispatch(guideClientsFetchAll());
      }
    }),
    filter<CRMClient>(Boolean)
  );

  constructor(
    readonly tabService: GuideClientCardTabService,
    readonly featuresService: GuideClientsFeaturesService,
    private readonly store$: Store,
    @Inject(PUI_DRAWER_DATA) private readonly data: GuideClientData,
    private readonly drawerRef: PuiDrawerRef<GuideClientData>
  ) {}

  ngOnInit(): void {
    if (this.tabService.tabIndex === this.data.tab) {
      return;
    }

    this.tabService.tabIndex = this.data.tab;
  }

  proposeService(clients: CRMClient[]): void {
    this.featuresService.proposeAService(clients);
    this.drawerRef.close();
  }

  back(): void {
    this.drawerRef.close();
  }

  private getClient(): Observable<CRMClient | undefined> {
    return this.store$.select(clientsByClientId).pipe(
      map(clients => {
        if (this.data.clientId) {
          return Object.values(clients).find(({ clientId }) => clientId === this.data.clientId)!;
        }

        return clients[this.data.id];
      })
    );
  }
}
