import { Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  expand = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(DOCUMENT) private _document: any, private renderer: Renderer2) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  expandMenu() {
    this.expand = !this.expand;
    if (this.expand) {
      this.renderer.addClass(this._document.body, 'menu-expanded');
    } else {
      this.renderer.removeClass(this._document.body, 'menu-expanded');
    }
  }
}
