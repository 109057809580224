import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingCancellationModule } from '@appWidget/modules/booking-cancellation/booking-cancellation.module';
import { BookingRootComponent } from '@appWidget/modules/booking-root/booking-root.component';
import { BookingStep } from '@appWidget/modules/booking/consts';

const routes: Routes = [
  {
    path: '',
    component: BookingRootComponent,
    children: [
      // Place for booking flows...
      {
        path: BookingStep.CANCELLATION,
        // TODO: was changed in PR-8791 because decline session from external calendars didn't work
        loadChildren: () => BookingCancellationModule
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingRoutingModule {}
