import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { FormService } from '@app/core';

import { QuizQuestionsPreviewModalComponent } from '../quiz-questions-preview-modal/quiz-questions-preview-modal.component';
import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import { GuideChangesForNewUsersDialogComponent } from '@app/screens/guide/guide-shared/components/guide-changes-for-new-users-dialog/guide-changes-for-new-users-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { PuiDialogService } from '@awarenow/profi-ui-core';
import { AuthService } from '@app/core/auth/services';
import { ADMIN_ROUTE_PATH } from '@app/routes-consts';
import { environment } from '@env/environment';
import { SurveyFormService } from '../../services/survey-form.service';
import { RedirectAfterSaveActions } from '@app/screens/guide/guide-shared/can-redirect-after-save';

@Component({
  selector: 'app-quiz-content-editor',
  templateUrl: './quiz-content-editor.component.html',
  styleUrls: ['../quiz-automation/quiz-automation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizContentEditorComponent implements OnDestroy, SaveOnRouteChanged {
  readonly saveButtonDisabled$ = this.surveyFormService.loading$;
  readonly quizForm = this.surveyFormService.quizForm;

  isPlatformAdmin = false;

  private readonly route: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly formService: FormService,
    private readonly modal: NgbModal,
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly dialog: PuiDialogService,
    private readonly router: Router,
    private readonly surveyFormService: SurveyFormService
  ) {
    this.isPlatformAdmin = this.authService.isPlatformAdmin();
    this.route = this.authService.isPlatformAdmin()
      ? `${ADMIN_ROUTE_PATH}/default-form`
      : `${environment.guideRoute}/surveys`;
  }

  get tabIsEdited(): boolean {
    return this.quizForm.dirty;
  }

  get tabIsValid(): boolean {
    return !(this.formService.markInvalidForm(this.quizForm) || this.quizForm.value.questions.length === 0);
  }

  get isNew(): boolean {
    return !this.surveyFormService.quizId;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  validateForm(): void {
    this.formService.markFormDirtyAndTouched(this.quizForm);
  }

  saveOnRouteChange(confirmation = false): void {
    this.updateAction(confirmation, true);
  }

  revertChanges(): void {
    this.surveyFormService.loadSavedQuizForm();
    this.quizForm.markAsPristine();
  }

  updateAction(saveWithoutConfirmation = false, saveFromGuard = false): void {
    this.formService.markFormDirtyAndTouched(this.quizForm);

    if (this.quizForm.invalid || this.quizForm.controls.questions.value.length === 0) {
      return;
    }

    if (this.isNew && saveFromGuard) {
      this.quizForm.markAsPristine();
      return;
    }

    if (this.isNew && saveWithoutConfirmation) {
      return this.createQuiz();
    }

    if (this.isPlatformAdmin && !saveWithoutConfirmation) {
      const ref = this.dialog.open(GuideChangesForNewUsersDialogComponent);
      ref.afterClosed$.pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
        if (result === RedirectAfterSaveActions.SAVE) {
          this.updateAction(true);
        }
      });
      return;
    } else {
      this.quizForm.markAsTouched();

      if (this.formService.markInvalidForm(this.quizForm) || this.quizForm.controls.questions.value.length === 0) {
        return;
      }

      this.surveyFormService.updateSurvey$().pipe(takeUntil(this.destroy$)).subscribe();

      this.quizForm.markAsPristine();
    }
  }

  previewAction(): void {
    const { componentInstance } = this.modal.open(QuizQuestionsPreviewModalComponent, {
      windowClass: 'full-screen-modal'
    });
    componentInstance.form = this.formBuilder.group({
      questions: [this.quizForm.value.questions]
    });
  }

  private createQuiz(): void {
    this.surveyFormService
      .createSurvey$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(id => {
        if (id) {
          this.router.navigate([`/${this.route}/${id}/content`]);
        }

        this.quizForm.markAsPristine();
      });
  }
}
