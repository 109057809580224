<section class="guide-section">
  <ng-container *ngIf="workspaceInfo | workspaceIsSolo; then soloView; else teamView"></ng-container>

  <ng-template #soloView>
    <h3 data-qa-id="service-hosted-hosted-by-title" i18n="@@hostedBy" class="title">Hosted by</h3>

    <ng-container *ngTemplateOutlet="singleHostContainer; context: { user: guide }"></ng-container>
  </ng-template>

  <ng-template #teamView>
    <ng-container *ngIf="members.length > 1; then availableHosts; else hostedBy"></ng-container>
    <ng-template #availableHosts>
      <h3 i18n="@@availableHosts" class="title">Available hosts</h3>

      <div class="multiple-guides">
        <div *ngFor="let member of members; let i = index; trackBy: trackByFn" class="guide-card">
          <div class="row-m0">
            <div class="col-auto">
              <div class="img-container">
                <!--                <app-user-photo class="user-photo" [size]="80" [photoUrl]="member?.photo"></app-user-photo>-->
                <pui-avatar class="user-photo" [avatarUrl]="member?.photo" [text]="member?.name"></pui-avatar>
              </div>
            </div>
            <div class="col">
              <a [routerLink]="'/' + member?.namedUrl" class="name">{{ member | userName }}</a>
              <p class="specialization">{{ member?.specialization }}</p>
              <a [routerLink]="contactReadonly ? [] : ['/client/chat/' + member.directChatLink]" class="chat-link">
                <ng-container i18n="@@contact">Contact</ng-container>
                {{ member | userName }}
              </a>
            </div>
          </div>

          <div *ngIf="member?.shortQuote" class="description-switch">
            <ng-container *ngIf="visibleDescriptionIndex !== i">
              <div class="description-toggle" (click)="onClickToggleDescription(i)">
                <span class="description-toggle-text" i18n="@@showMore">Show more</span>
                <ui-icon color="primary" size="xs" svgIcon="menu:one-arrow-down"></ui-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="visibleDescriptionIndex === i">
              <div class="description">&ldquo;{{ member?.shortQuote }}&rdquo;</div>
              <div class="description-toggle" (click)="onClickToggleDescription(i)">
                <span class="description-toggle-text" i18n="@@showLess">Show less</span>
                <ui-icon color="primary" size="xs" svgIcon="menu:one-arrow-up"></ui-icon>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #hostedBy>
      <h3 data-qa-id="service-hosted-hosted-by-title" i18n="@@hostedBy" class="title">Hosted by</h3>

      <ng-container *ngTemplateOutlet="singleHostContainer; context: { user: members[0] }"></ng-container>
    </ng-template>
  </ng-template>

  <div *ngIf="logo" class="logo-wrap">
    <img class="logo-img" [src]="logo" alt="logo" />
  </div>

  <ng-template #singleHostContainer let-user="user">
    <div class="single-guide">
      <div class="img-container">
        <!--        <app-user-photo class="user-photo" [size]="80" [photoUrl]="user?.photo"></app-user-photo>-->
        <pui-avatar class="user-photo" [avatarUrl]="user?.photo" [text]="user?.name"></pui-avatar>
      </div>

      <div class="centered">
        <a data-qa-id="service-hosted-user-name-title" [routerLink]="'/' + user?.namedUrl" class="name">
          {{ user | userName }}
        </a>
        <p class="specialization">{{ user?.specialization }}</p>
      </div>

      <div *ngIf="guide?.shortQuote" class="description">&ldquo;{{ user?.shortQuote }}&rdquo;</div>

      <a
        data-qa-id="service-hosted-contact-link"
        [routerLink]="contactReadonly ? [] : ['/client/chat/' + directChatId]"
        class="chat-link">
        <ng-container i18n="@@contact">Contact</ng-container>
        {{ user | userName }}
      </a>
    </div>
  </ng-template>
</section>
