import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss']
})
export class FormControlErrorComponent {
  @Input()
  // @ts-expect-error TS2564
  control: AbstractControl;

  @Input()
  show = false;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get errors() {
    return this.control.errors;
  }
}
