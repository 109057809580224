import { Component, Inject, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { AuthModalComponent } from '@app/modules/auth/components/auth-modal/auth-modal.component';
import { PublicGuideWidget } from '@app/screens/guide-public-profile/guide-public-profile.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-guide-widget',
  templateUrl: './guide-widget.component.html',
  styleUrls: ['./guide-widget.component.scss']
})
export class GuideWidgetComponent {
  @Input()
  widget!: PublicGuideWidget;

  constructor(
    private auth: AuthService,
    @Inject(GLOBAL_WINDOW) private browserWindow: Window,
    private modal: NgbModal
  ) {}

  handle(): void {
    let afterAuth$;

    if (this.auth.isAuthorized) {
      afterAuth$ = of(null);
    } else {
      // TODO: whole process should be refactored, too many repetitive code for auth. Perhaps, better use single separate service
      const { componentInstance } = this.modal.open(AuthModalComponent, {
        windowClass: 'auth-modal'
      });
      afterAuth$ = componentInstance.afterSignIn.pipe(take(1));
    }

    afterAuth$.subscribe(() => {
      if (this.widget && this.widget.link && this.widget.link.url) {
        this.browserWindow.open(this.widget.link.url, '_blank');
      }
    });
  }
}
