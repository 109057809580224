import { PuiBadgeModule } from '@awarenow/profi-ui-core';
import { NgModule } from '@angular/core';
import { GuideServiceBadgeComponent } from './guide-service-badge.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [GuideServiceBadgeComponent],
  imports: [CommonModule, PuiBadgeModule],
  exports: [GuideServiceBadgeComponent]
})
export class GuideServiceBadgeModule {}
