import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { map, take } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class WorkspaceGuard implements CanActivate, CanActivateChild, CanLoad {
  canActivate(): Observable<boolean | UrlTree> {
    return this._workspacesService.activeWorkspace$.pipe(
      take(1),
      map(workspace => {
        if (!workspace) {
          const title = `Only for Guides with workspaces`;
          this._notifications.error(title);
          return this._router.parseUrl('/');
        }

        return true;
      })
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private readonly _workspacesService: WorkspacesService,
    private readonly _notifications: NotificationsService,
    private readonly _router: Router
  ) {}
}
