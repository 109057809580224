import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { ISidenavBodyHost } from '../../interfaces';
import { SidenavBodySlotEnum, SidenavHostEnum } from '../../enums';
import { SidenavBodySlotType } from '../../types';
import { ViewWidgetService } from '../../services';

@Component({
  selector: 'app-sidenav-body',
  templateUrl: 'body.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UiDestroyService]
})
export class SidenavBodyComponent implements ISidenavBodyHost, AfterViewInit {
  readonly name = SidenavHostEnum.Body;

  readonly slots: SidenavBodySlotType[] = [SidenavBodySlotEnum.TopNavigation, SidenavBodySlotEnum.BottomNavigation];

  @ViewChild('topNavigation')
  // @ts-expect-error TS2564
  topNavigation: CdkPortalOutlet;

  @ViewChild('bottomNavigation')
  // @ts-expect-error TS2564
  bottomNavigation: CdkPortalOutlet;

  constructor(
    readonly service: ViewWidgetService<SidenavBodySlotType>,
    @Inject(UiDestroyService) private readonly _destroy$: UiDestroyService
  ) {}

  ngAfterViewInit(): void {
    this.service.observeWidgets$(this).pipe(takeUntil(this._destroy$)).subscribe();
  }
}
