import { Subject } from 'rxjs';
import { IScheduleProvider } from '../../types';
import { ScheduleTimeSlotsTimezoneFactory } from './schedule-time-slots-timezone-factory';
import { IScheduleDateTimeSlotsUpdateOptions } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleTimeSlotsFactoryWithVariableDate<TDate, TOptions> {
  readonly date: TDate;
  changeDate: (date: TDate, options?: TOptions) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleTimeSlotsFactoryWithVariableDuration<TDuration, TOptions> {
  readonly duration: TDuration;
  changeDuration: (duration: TDuration, options?: TOptions) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IScheduleSlotsStore<TDate> {
  doesDateHaveSlots: (date: TDate) => boolean;

  getFirstAvailableDate: () => TDate | null;
}

export abstract class ScheduleDateTimeSlotsFactory<TSchedule, TDate, TDuration, TTimezone, TTimeSlot>
  extends ScheduleTimeSlotsTimezoneFactory<TSchedule, TTimezone, TTimeSlot>
  implements
    IScheduleTimeSlotsFactoryWithVariableDate<TDate, IScheduleDateTimeSlotsUpdateOptions>,
    IScheduleTimeSlotsFactoryWithVariableDuration<TDuration, IScheduleDateTimeSlotsUpdateOptions>,
    IScheduleSlotsStore<TDate>
{
  // @ts-expect-error TS2564
  readonly date: TDate;

  // @ts-expect-error TS2564
  readonly duration: TDuration;

  // @ts-expect-error TS2564
  readonly timeSlotFormat: string | null;

  readonly excludeDateTime?: string;

  protected constructor(scheduleProvider: IScheduleProvider<TSchedule>, timeSlotsEmitter$?: Subject<TTimeSlot[]>) {
    super(scheduleProvider, timeSlotsEmitter$);
  }

  abstract changeDate(date: TDate, options?: IScheduleDateTimeSlotsUpdateOptions): void;

  abstract changeExcludeDateTime(excludeDateTime: string, options?: IScheduleDateTimeSlotsUpdateOptions): void;

  abstract changeDuration(duration: TDuration, options?: IScheduleDateTimeSlotsUpdateOptions): void;

  abstract changeTimeslotFormat(timeslotFormat: string | null): void;

  abstract doesDateHaveSlots(date: TDate): boolean;

  abstract getFirstAvailableDate(): TDate | null;
}
