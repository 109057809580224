import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { IClientQuiz, QuizAnswers, QuizLatestAnswers, IQuizBot, GuideQuiz } from '@app/core/quizzes/types';
import { SocketService } from '@app/core/socket/socket.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AuthService } from '../auth/services/auth.service';
import config from '../config/config';

@Injectable()
export class QuizService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _users: IQuizBot[] = [];

  private filled = false;

  private loading = false;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _users$: Subject<IQuizBot[]>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _usersReplay$ = new ReplaySubject<IQuizBot[]>(1);

  constructor(
    private _authService: AuthService,
    private _http: HttpClient,
    private _socketService: SocketService,
    private _notificationsService: NotificationsService
  ) {
    this._socketService.onSurvey().subscribe(({ date, id, updated }) => {
      const user = this._users.find(i => i.id === id);
      if (!user || updated) {
        this.getUsers$(true);
      } else {
        user.lastQuestionDate = date;
        this._usersReplay$.next(this._users);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get usersReplay$() {
    return this._usersReplay$.asObservable();
  }

  getUser$(id: number, force = false): Observable<IQuizBot> {
    if (!this.filled || force) {
      // @ts-expect-error TS2322
      return this.getUsers$(force).pipe(switchMap(() => of(this._users.find(i => i.id === id))));
    }
    const user = this._users.find(i => i.id === id);
    // @ts-expect-error TS2322
    return !user && !force ? this.getUser$(id, true) : of(user);
  }

  getUsers$(force = false): Observable<IQuizBot[]> {
    if (this.loading) {
      return this._users$.asObservable();
    }
    if (force) {
      this.filled = false;
    }
    if (!this.filled && this._authService.user.RoleId === UserRoles.CLIENT) {
      this.loading = true;
      this._users$ = new Subject();
      this._http
        .get(`${config.apiPath}/user/client/chat-bot`)
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((users: any[]) => {
            return users.map(user => ({
              bot: true,
              creator: user.creator,
              date: user.date,
              firstDate: user.firstDate,
              firstName: user.title,
              id: user.id,
              isOnline: user.online,
              lastName: '',
              lastQuestionDate: user.lastQuestionDate,
              lastScheduleId: user.lastScheduleId,
              name: user.title,
              photo: user.avatar,
              schedules: user.schedules,
              type: user.type,
              workspaceId: user.workspaceId
            }));
          }),
          tap(users => {
            this._users = users;
            this.filled = true;
            this.loading = false;
          })
        )
        .subscribe(users => {
          this._users$.next(users);
          this._usersReplay$.next(users);
          this._users$.complete();
        });
      return this._users$.asObservable();
    }
    return of(this._users);
  }

  getUserQuiz(quizId: number): Observable<IClientQuiz> {
    return this._http.get<IClientQuiz>(`${config.apiPath}/user/client/chat-bot/${quizId}`);
  }

  saveUserAnswers(answers: QuizAnswers): Observable<IClientQuiz> {
    return this._http.post<IClientQuiz>(`${config.apiPath}/user/client/chat-bot/answers`, answers);
  }

  getGuideQuiz(quizId: number): Observable<GuideQuiz> {
    return this._http
      .get<{ quiz: GuideQuiz }>(`${config.apiPath}/user/guide/chat-bot/answers/${quizId}`)
      .pipe(map(response => response?.quiz));
  }

  saveGuideAnswers(answers: QuizAnswers): Observable<IClientQuiz> {
    return this._http.post<IClientQuiz>(`${config.apiPath}/user/guide/chat-bot/answers`, answers);
  }

  saveUserLatestAnswers(answers: QuizLatestAnswers): Observable<IClientQuiz> {
    return this._http.post<IClientQuiz>(`${config.apiPath}/user/client/chat-bot/latest-answers`, answers);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  startQuiz(scheduleId: number) {
    if (!scheduleId) {
      this._notificationsService.error(`Error: Invalid schedule identifier`);

      throw Error(
        `The 'scheduleId' parameter is invalid. Please ensure that you provide a valid 'scheduleId' to execute this function successfully`
      );
    }

    this._http
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .post<any>(`${config.apiPath}/user/client/chat-bot/start-bot/${scheduleId}`, {})
      .pipe(
        catchError(err => {
          const message = `Failed to start survey`;
          this._notificationsService.error(message);
          return throwError(err);
        })
      )
      .subscribe(({ started }) => {
        if (!started) {
          const message = `Already started`;
          this._notificationsService.info(message);
        } else {
          const message = `Survey started`;
          this._notificationsService.success(message);
        }
      });
  }

  getUsersAsDictionary$(force = false): Observable<{ [id: number]: IQuizBot }> {
    return this.getUsers$(force).pipe(
      map(users =>
        users.reduce((dict, user) => {
          // @ts-expect-error TS7053
          dict[user.id] = user;
          return dict;
        }, {})
      )
    );
  }
}
