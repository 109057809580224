// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'lodash';

import { ProgramSessionTemplateInterface } from '@app/modules/program/types';
import { ProgramService } from '@app/screens/guide/guide-programs/types/program-service';
import { DiffDetails } from '@app/screens/guide/guide-programs/types/util-types';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';

import { BaseModule, IBaseModuleDetails } from './base-module';
import { ModuleAccessTypes } from './module-access-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISessionModuleDetails extends IBaseModuleDetails {
  serviceId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service?: any;
  /**
   * In case Team Workspace, we ignore necessary session validation
   * @type {boolean}
   */
  readonly ignoreSessionValidation?: boolean;

  sessionTemplateDraft?: ProgramSessionTemplateInterface;
}

export class SessionModule extends BaseModule {
  readonly serviceId: number;

  sessionTemplateDraft?: ProgramSessionTemplateInterface;

  showRecurringAlert?: boolean;

  readonly service?: ProgramService;
  /**
   * In case Team Workspace, we ignore necessary session validation
   * @type {boolean}
   */
  readonly ignoreSessionValidation?: boolean;

  accessType!: ModuleAccessTypes;

  get moduleType(): ModuleTypes {
    return ModuleTypes.SESSION;
  }

  constructor(sessionModuleDetails: Readonly<ISessionModuleDetails>) {
    super(sessionModuleDetails);

    this.ignoreSessionValidation = sessionModuleDetails.ignoreSessionValidation;
    this.serviceId = sessionModuleDetails.serviceId;
    this.sessionTemplateDraft = sessionModuleDetails.sessionTemplateDraft;

    if (sessionModuleDetails.service) {
      this.service = sessionModuleDetails.service;
    }
  }

  getDiffFrom(sessionModuleValue: Readonly<SessionModule>): DiffDetails<ISessionModuleDetails> | null {
    const baseDiff = super.getDiffFrom(sessionModuleValue);

    if (!baseDiff && this.serviceId === sessionModuleValue.serviceId) {
      return null;
    }

    const diff: DiffDetails<ISessionModuleDetails> = baseDiff
      ? { ...baseDiff }
      : {
          id: this.id,
          localId: this.localId,
          order: this.order,
          moduleType: this.moduleType
        };

    if (this.serviceId !== sessionModuleValue.serviceId) {
      diff.serviceId = sessionModuleValue.serviceId;
    }

    if (!isEqual(this.sessionTemplateDraft, sessionModuleValue.sessionTemplateDraft)) {
      diff.sessionTemplateDraft = sessionModuleValue.sessionTemplateDraft;
    }

    return diff;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON(): any {
    return {
      ...super.toJSON(),
      serviceId: this.serviceId,
      moduleType: this.moduleType,
      sessionTemplateDraft: this.sessionTemplateDraft
    };
  }
}
