import { InjectionToken, Provider } from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const IS_SOLO_WORKSPACE = new InjectionToken<Observable<boolean>>('[Workspace] Is this solo workspace');
export const isSoloWorkspaceProvider: Provider = {
  provide: IS_SOLO_WORKSPACE,
  useFactory: (workspacesService: WorkspacesService): Observable<boolean> => {
    return workspacesService.activeWorkspace$.pipe(map(WorkspaceUtility.isSolo));
  },
  deps: [WorkspacesService]
};

export const GET_IS_SOLO_WORKSPACE = new InjectionToken<Observable<() => boolean>>('IS_SOLO_WORKSPACE_VALUE');
export const isSoloWorkspaceValueProvider: Provider = {
  provide: GET_IS_SOLO_WORKSPACE,
  useFactory:
    (workspacesService: WorkspacesService): (() => boolean) =>
    () =>
      WorkspaceUtility.isSolo(workspacesService.workspace),
  deps: [WorkspacesService]
};
