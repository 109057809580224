import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogItemVideo } from '../../types';

@Component({
  selector: 'app-blog-item-video',
  templateUrl: './blog-item-video.component.html',
  styleUrls: ['../styles/blog-item.scss', './blog-item-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemVideoComponent {
  @Input()
  // @ts-expect-error TS2564
  video: BlogItemVideo;

  @Output()
  selectAuthor = new EventEmitter<string>();

  // eslint-disable-next-line id-length
  emitSelectAuthor(e: Event): void {
    e.stopPropagation();
    // @ts-expect-error TS2532
    this.selectAuthor.emit(this.video.author.namedUrl);
  }
}
