import { UserRoles } from '@app/shared/enums/user-roles';

export const menuItems: {
  name: string;
  blockId: string;
  icon: string;
  showFor: UserRoles[];
}[] = [
  {
    name: `Basic info`,
    blockId: 'basicInfo',
    icon: 'pui:feather',
    showFor: [UserRoles.GUIDE, UserRoles.ADMIN]
  },
  {
    name: `Pricing`,
    blockId: 'pricing',
    icon: 'pui:price',
    showFor: [UserRoles.GUIDE, UserRoles.ADMIN]
  },
  {
    name: `Locations and hosts`,
    blockId: 'locationsAndHosts',
    icon: 'pui:place',
    showFor: [UserRoles.ADMIN]
  },
  {
    name: `Session availability`,
    blockId: 'sessionAvailability',
    icon: 'pui:clock',
    showFor: [UserRoles.GUIDE]
  },
  {
    name: `Participants and guests`,
    blockId: 'participantsAndGuests',
    icon: 'pui:smile',
    showFor: [UserRoles.GUIDE, UserRoles.ADMIN]
  },
  {
    name: `Restrictions`,
    blockId: 'restrictions',
    icon: 'pui:maximize',
    showFor: [UserRoles.GUIDE]
  },
  {
    name: `Testimonials`,
    blockId: 'testimonials',
    icon: 'pui:quote',
    showFor: [UserRoles.GUIDE]
  }
];
