import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { IScheduleItem } from '@app/shared/interfaces/schedule';
import { Session, SimpleSession } from '@app/shared/interfaces/session';
import { IHaveDateRange } from '@app/shared/utils/date';

import { ModuleSessionStates } from './types';

export function convertScheduleItemToIHaveDateRange(scheduleItem: IScheduleItem): IHaveDateRange | null {
  return {
    date: scheduleItem.dateStart,
    dateEnd: scheduleItem.dateEnd
  };
}

export function convertSessionToIHaveDateRange(session: Session): IHaveDateRange | null {
  return {
    date: session.dateStart,
    duration: session.duration
  };
}

export function detectModuleIndividualSessionState(session: SimpleSession): ModuleSessionStates {
  if (!session) {
    return ModuleSessionStates.CAN_BOOK;
  }

  if ([SessionStatuses.EXPIRED].includes(session.status)) {
    return ModuleSessionStates.NO_STATE;
  }

  if ([SessionStatuses.DONE, SessionStatuses.IN_PROGRESS_OLD].includes(session.status)) {
    return ModuleSessionStates.DONE;
  }

  if ([SessionStatuses.GUIDE_OFFER, SessionStatuses.RESCHEDULE_BY_GUIDE].includes(session.status)) {
    return ModuleSessionStates.OFFER;
  }

  if ([SessionStatuses.PENDING_APPROVEMENT, SessionStatuses.RESCHEDULE_BY_CLIENT].includes(session.status)) {
    return ModuleSessionStates.REQUEST;
  }

  if (session.status === SessionStatuses.APPROVED) {
    return ModuleSessionStates.CONFIRMED;
  }

  return ModuleSessionStates.CAN_BOOK;
}

export function findExtensionByFilename(filename: string): string {
  // @ts-expect-error TS2322
  return (filename && filename.substring(filename.lastIndexOf('.') + 1, filename.length)) || null;
}
