import { Pipe, PipeTransform } from '@angular/core';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';

export function skipOwner<T extends { permission?: ServiceAssigneePermission | undefined }>(items: T[]): T[] {
  return items.filter(item => item.permission !== ServiceAssigneePermission.OWNER);
}

@Pipe({
  name: 'skipOwner'
})
export class SkipOwnerPipe<T extends { permission?: ServiceAssigneePermission | undefined }> implements PipeTransform {
  transform(items: T[]): T[] {
    return skipOwner(items);
  }
}
