import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MEMBERSHIP_ENDPOINT } from '@app/shared/constants/endpoints';
import { IMembershipPlan, IMembershipSettings, IUserMembership } from './types';

@Injectable({
  providedIn: 'root'
})
export class MembershipApiService {
  constructor(private readonly http: HttpClient) {}

  getPlanByType$(type: string): Observable<IMembershipPlan> {
    return this.http.get<IMembershipPlan>(`${MEMBERSHIP_ENDPOINT}/${encodeURIComponent(type)}`);
  }

  cancelSubscription$(id: number, data: { reason?: string }): Observable<IUserMembership> {
    return this.http.post<IUserMembership>(`${MEMBERSHIP_ENDPOINT}/subscription/${id}/cancel`, data);
  }

  checkPlanCoupon$(type: string, coupon: string): Observable<{ id: number; amount: number }> {
    return this.http.post<{ id: number; amount: number }>(
      `${MEMBERSHIP_ENDPOINT}/${encodeURIComponent(type)}/coupon/check`,
      {
        coupon
      }
    );
  }

  getMembershipSettings$(): Observable<{ membershipSettings: IMembershipSettings; plans: IMembershipPlan[] }> {
    return this.http.get<{ membershipSettings: IMembershipSettings; plans: IMembershipPlan[] }>(
      `${MEMBERSHIP_ENDPOINT}/settings`
    );
  }

  getCurrentMembership$(): Observable<IUserMembership | undefined> {
    return this.http.get<{ membership: IUserMembership }>(`${MEMBERSHIP_ENDPOINT}`).pipe(map(data => data?.membership));
  }

  getLastMembership$(): Observable<IUserMembership | undefined> {
    return this.http
      .get<{ membership: IUserMembership }>(`${MEMBERSHIP_ENDPOINT}/last`)
      .pipe(map(response => response?.membership));
  }
}
