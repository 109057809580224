<div class="tab">
  <div class="tab__icon">
    <pui-icon [svgIcon]="icon" size="l"></pui-icon>
  </div>

  <div class="tab__content">
    <div class="tab__name" pui-typography="body-s">
      <ng-content select="[name]"></ng-content>
    </div>
    <div class="tab__description" pui-typography="body-m">
      <ng-content select="[description]"></ng-content>
    </div>
  </div>
</div>
