<div class="modal-header">
  <app-button data-qa-id="invite-clients-modal-close-button" className="close" (click)="close()"></app-button>
</div>

<div class="modal-body">
  <h4 data-qa-id="invite-clients-modal-invite-clients-title" class="title" i18n="@@inviteClients">Invite clients</h4>

  <div class="note" data-qa-id="invite-clients-modal-note-text">
    <ng-container i18n="@@inviteClientsNotePart1">They will be invited to the</ng-container>
    <app-service-type [type]="type"></app-service-type>
    <ng-container i18n="@@inviteClientsNotePart2">via email.</ng-container>
  </div>

  <div class="d-flex">
    <div>
      <input type="file" class="d-none" #csv (change)="importCSV(csv.files)" accept=".csv,.txt" />
      <button
        data-qa-id="invite-clients-modal-import-csv-button"
        (click)="csv.click()"
        type="button"
        class="btn btn-link invite-btn"
        i18n="@@importCsv">
        Import CSV
      </button>
    </div>
  </div>

  <form [formGroup]="invitationsForm" class="form">
    <app-form-field #field>
      <ui-client-selector-multiple
        appInput
        formControlName="invitations"
        data-qa-id="invite-clients-modal-clients-select"
        [clients]="clients"
        [disabled]="disableAddNewClient"
        [autocomplete-connected-to]="field.origin"
        [canAdd]="canAddByEmail"
        (inputTokenEnd)="addInvitationFromView($event)"></ui-client-selector-multiple>
    </app-form-field>

    <div class="form-row checkbox-row">
      <div class="col-6">
        <app-checkbox formControlName="isPrepaid" data-qa-id="invite-clients-modal-enroll-without-payment-checkbox">
          <ng-container i18n="@@enrollClientsInThe">Enroll client(s) in the</ng-container>
          <app-service-type [type]="type"></app-service-type>
          <ng-container i18n="@@withoutPayment">without payment</ng-container>
        </app-checkbox>
      </div>
      <div class="col-3">
        <app-button
          className="btn-primary send-btn"
          i18n="@@send"
          [disabled]="invitationsForm.invalid"
          (click)="send()"
          data-qa-id="invite-clients-modal-send-button">
          Send
        </app-button>
      </div>
      <div class="col-3">
        <app-button
          data-qa-id="invite-clients-modal-cancel-button"
          className="btn-lite send-btn"
          (click)="close()"
          i18n="@@cancel">
          Cancel
        </app-button>
      </div>
    </div>
  </form>
</div>
