import { FactoryProvider, Injectable } from '@angular/core';
import { SessionTemplateDrawerComponent } from '@app/modules/session-forms/drawers/session-template-drawer/session-template-drawer.component';
import { PuiDrawerConfig, PuiDrawerData, PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import {
  DEFAULT_DURATION,
  DEFAULT_SESSION_TEMPLATE_VALUES,
  SessionTemplatePredefinedFields,
  SessionTemplatePredefinedFieldsApiService
} from '@app/screens/guide/guide-sessions-templates/services';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { ServiceAssigneePermission, SessionTemplateInterface } from '@app/screens/guide/guide-sessions-templates/types';
import { map } from 'rxjs/operators';
import { IWorkspace } from '@app/modules/workspaces/types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionType } from '@app/shared/enums/session-type';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';
import { PackageIncludes } from '@app/modules/package/types/package-session-template';
import { ProgramSessionModuleInfo } from '@app/screens/guide/guide-programs/types';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';

const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

export interface SessionTemplateDrawerDataInterface<T> extends PuiDrawerData {
  parentType: GuideServiceTypes.PROGRAM | GuideServiceTypes.PACKAGE;
  sessionInfo?: T;
}

export interface SessionTemplatePackageData extends SessionTemplateDrawerDataInterface<PackageIncludes> {
  parentType: GuideServiceTypes.PACKAGE;
}

export interface SessionTemplateProgramData extends SessionTemplateDrawerDataInterface<ProgramSessionModuleInfo> {
  parentType: GuideServiceTypes.PROGRAM;
  showRecurringAlert?: boolean;
}

export type SessionTemplateDrawerData = SessionTemplatePackageData | SessionTemplateProgramData;

export const isSessionTemplatePackageData = (data: SessionTemplateDrawerData): data is SessionTemplatePackageData => {
  return data.parentType === GuideServiceTypes.PACKAGE;
};

export const isSessionTemplateProgramData = (data: SessionTemplateDrawerData): data is SessionTemplateProgramData => {
  return data.parentType === GuideServiceTypes.PROGRAM;
};

export function defaultSessionTemplateValuesDrawerFactory(
  predefinedFieldsApi: SessionTemplatePredefinedFieldsApiService,
  workspace: WorkspacesService,
  auth: AuthService
): Observable<SessionTemplateInterface> {
  return combineLatest([
    iif(() => auth.getUserRoleId() === UserRoles.GUIDE, predefinedFieldsApi.getPredefinedFields(), of(null)),
    workspace.activeWorkspace$
  ]).pipe(
    map(([sessionTemplatePredefinedFields, workspace]: [SessionTemplatePredefinedFields, IWorkspace]) => {
      return {
        afterEventBuffer: sessionTemplatePredefinedFields?.afterEventBuffer || 0,
        availabilities: sessionTemplatePredefinedFields?.schedules || [],
        beforeEventBuffer: sessionTemplatePredefinedFields?.beforeEventBuffer || 0,
        bookingLimitsFrequency: null,
        coverImage: null,
        coverImageThumb: null,
        customAvailability: null,
        descriptionMarkup: '',
        descriptionRepresentation: '',
        descriptionText: '',
        disableGuests: true,
        duration: DEFAULT_DURATION,
        hasAutoConfirmation: false,
        hasChat: false,
        hidePrice: false,
        hosts: [],
        limitFutureBookings: null,
        location: {
          connectionType: SessionConnectionTypes.IN_PLATFORM
        },
        minimumBookingNotice: sessionTemplatePredefinedFields?.minimumBookingNotice ?? 1,
        name: '',
        permission: ServiceAssigneePermission.OWNER,
        price: null,
        recurrence: null,
        removeFromChatOnSessionEnd: false,
        scheduleId: null,
        seatsPerTimeSlot: null,
        seatsShowAttendees: false,
        serviceType: GuideServiceTypes.GROUP_SESSION,
        sessionType: SessionType.PERSONAL,
        slotInterval: DEFAULT_DURATION,
        status: GuideProfileTypes.PRIVATE,
        testimonials: [],
        viewChatHistory: false,
        workspaceId: workspace?.id,
        restrictClientBooking: false
      };
    })
  );
}

export const DRAWER_DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER: FactoryProvider = {
  provide: DEFAULT_SESSION_TEMPLATE_VALUES,
  deps: [SessionTemplatePredefinedFieldsApiService, WorkspacesService, AuthService],
  useFactory: defaultSessionTemplateValuesDrawerFactory
};

@Injectable()
export class SessionTemplateDrawerService {
  constructor(private readonly drawerService: PuiDrawerService) {}

  open$<T extends SessionTemplateDrawerData>(data: T): PuiDrawerRef<T | undefined> {
    return this.drawerService.open<T, T>(SessionTemplateDrawerComponent, DRAWER_CONFIG, data);
  }
}
