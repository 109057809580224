import { InjectionToken, ValueProvider } from '@angular/core';
import { generate } from './simple-id-generator';

export const SIMPLE_ID = new InjectionToken('simpleId');

export const SimpleIdProvider: ValueProvider = {
  provide: SIMPLE_ID,
  multi: false,
  useValue: generate()
};
