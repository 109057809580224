import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoHeight]'
})
export class AutoHeightScrollbarDirective {
  // @ts-expect-error TS2564
  private contentWrapper: HTMLElement;

  constructor(private elem: ElementRef) {}

  @HostListener('updated')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onUpdate() {
    if (!this.contentWrapper) {
      this.contentWrapper = this.elem.nativeElement.querySelector('.ng-scroll-content');
    }
    if (this.elem) {
      this.elem.nativeElement.style.height = `${this.contentWrapper.clientHeight}px`;
    }
  }
}
