import { NgModule } from '@angular/core';
import { CheckboxComponent } from '@app/modules/ui-kit/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent],
  imports: [FormsModule]
})
export class CheckboxModule {}
