<div class="modal-header">
  <app-button className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <app-program-landing-template
    class="program-preview"
    [program]="program"
    [isPreview]="true"></app-program-landing-template>
</div>
