enum LocalStorageKeys {
  CREATE_TEAM = 'createTeam',
  CREATE_TEAM_POPUP = 'createTeamPopup',
  USER = 'user',
  LOG_ID_KEY = 'logId',
  CLIENT_INVITATION_CODE = 'client_invitation_code',
  SHARED_EVENT_ACTION_CODE = 'shared_event_action_code',
  GIFT_COUPON_ACTIVATION_HASH = 'gift_coupon_activation_hash',
  OUTGOING_RESOLUTION = 'outgoing_resolution',
  SESSION_RETRY_COUNTER = 'session_retry_counter',
  OT_SESSION_CONNECTED = 'OT_SESSION_CONNECTED'
}

export { LocalStorageKeys };
