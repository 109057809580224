import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';

export enum BookingStep {
  CHOOSE_SERVICE = 'choose-service',
  SCHEDULING = 'scheduling',
  INFORMATION = 'information',
  PAYMENT = 'payment',
  CONFIRMATION = 'confirmation',
  CHOOSE_HOST = 'choose-host',
  CANCELLATION = 'cancellation'
}

export enum ParentServices {
  PACKAGE = 'package',
  PROGRAM = 'program'
}

export interface StepperItem {
  id: BookingStep;
  name: string;
  path: string;
}

export const chooseService = (path: string): StepperItem => ({
  id: BookingStep.CHOOSE_SERVICE,
  name: `Choose service`,
  path
});

export const scheduling: StepperItem = {
  id: BookingStep.SCHEDULING,
  name: `Scheduling`,
  path: 'scheduling'
};
export const information: StepperItem = {
  id: BookingStep.INFORMATION,
  name: `Your information`,
  path: 'information'
};
export const payment: StepperItem = { id: BookingStep.PAYMENT, name: `Payment`, path: 'payment' };
export const confirmation: StepperItem = {
  id: BookingStep.CONFIRMATION,
  name: `Confirmation`,
  path: 'confirmation'
};
export const packageEnrollConfiramtion: StepperItem = {
  id: BookingStep.CONFIRMATION,
  name: `Confirmation`,
  path: 'confirmation/package-confirmation'
};
export const purchaseProgramConfirmation: StepperItem = {
  id: BookingStep.CONFIRMATION,
  name: `Confirmation`,
  path: 'confirmation/program-confirmation'
};

export const chooseHost: StepperItem = {
  id: BookingStep.CHOOSE_HOST,
  name: `Choose host`,
  path: 'choose-host'
};

// choose_individual
// choose_team
// choose_team_host
export const cancellationStep: StepperItem = {
  id: BookingStep.CANCELLATION,
  name: `Cancellation`,
  path: 'cancellation'
};

export const chooseServiceOfSoloGuideStep = chooseService('choose_individual');
export const chooseServiceOfTeamStep = chooseService('choose_individual');
export const chooseServiceOfTeamGuideStep = chooseService('choose_individual');

export const stepByTypeMap: Record<BookingTypes, StepperItem[]> = {
  [BookingTypes.INDIVIDUAL]: [chooseServiceOfSoloGuideStep, scheduling, information, confirmation],
  [BookingTypes.INDIVIDUAL_WITH_LOGIN]: [chooseServiceOfSoloGuideStep, scheduling, information, confirmation],
  [BookingTypes.TEAM]: [chooseServiceOfTeamStep, scheduling, information, confirmation],
  [BookingTypes.TEAM_HOSTS]: [chooseServiceOfTeamGuideStep, scheduling, information, confirmation],
  [BookingTypes.SESSION]: [scheduling, information, confirmation],
  [BookingTypes.SESSION_WITH_AUTHORIZATION]: [scheduling, information, confirmation],
  [BookingTypes.SESSION_CANCEL]: [scheduling, confirmation],
  [BookingTypes.SESSION_RESCHEDULE]: [scheduling, confirmation],
  [BookingTypes.SESSION_ACCEPT]: [scheduling, information, confirmation],
  [BookingTypes.SESSION_ACCEPTED]: [confirmation],
  [BookingTypes.SESSION_PAY_ACCEPTED]: [payment, confirmation],
  [BookingTypes.CANCELLATION]: [cancellationStep, confirmation],
  [BookingTypes.CHOOSE_HOST_FROM_DASHBOARD]: [chooseHost, scheduling, information, confirmation],
  [BookingTypes.CHOOSE_TEAM_HOST_WITH_AUTHORIZATION]: [chooseHost, scheduling, information, confirmation],
  [BookingTypes.CHOOSE_TEAM_WITH_HOST_WITH_AUTHORIZATION]: [
    chooseService('choose_team'),
    chooseHost,
    scheduling,
    information,
    confirmation
  ],
  [BookingTypes.RESCHEDULE]: [scheduling, confirmation],
  [BookingTypes.ENROLL_PACKAGE]: [information, packageEnrollConfiramtion],
  [BookingTypes.PURCHASE_PROGRAM]: [information, purchaseProgramConfirmation]
};
