import { Injectable, OnDestroy } from '@angular/core';
import { BrandingService } from '@app/core/branding/branding.service';
import { WindowProvider } from '@app/core/browser-window/window-provider';
import { BehaviorSubject, combineLatest, from, iif, Observable, of, Subject } from 'rxjs';
import { INavigationEntry } from '@app/modules/navigation';
import { AuthService } from '@app/core/auth/services';
import { UserRoles } from '@app/shared/enums/user-roles';
import { filter, map, shareReplay, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { IMenuLink } from '@app/screens/admin/types';
import { filterByRole } from '@app/modules/navigation/utils/filter-by-role';
import { menuLinkToNavigationEntry } from '@app/modules/navigation/utils/menu-link-to-navigation-entry';
import { parseLink } from '@app/modules/navigation/utils/parse-link';

@Injectable()
export class NavigationApiService implements OnDestroy {
  private readonly destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _sidenav$: BehaviorSubject<INavigationEntry[]> = new BehaviorSubject<INavigationEntry[]>([]);

  // eslint-disable-next-line rxjs/no-sharereplay
  readonly sidenav$ = this._sidenav$.asObservable().pipe(shareReplay(1));

  constructor(brandingService: BrandingService, windowService: WindowProvider, authService: AuthService) {
    const role$: Observable<UserRoles> = authService.onAuth().pipe(map(auth => auth?.RoleId));

    combineLatest([role$, brandingService.menuConfig$])
      .pipe(
        filter(([role]) => !!role),
        map(([role, { navLinks }]) => [role, navLinks]),
        switchMap(([role, navLinks]: [UserRoles, IMenuLink[]]) =>
          iif(
            () => env.enableLinksInMainHeader && !!navLinks?.length,
            from(parseLink(navLinks)).pipe(
              filter(link => !!filterByRole(link.visibleFor, role).length),
              // @ts-expect-error TS2322
              map<IMenuLink, INavigationEntry>(link => menuLinkToNavigationEntry(link, role, windowService)),
              toArray()
            ),
            of([])
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(entries => this._sidenav$.next(entries));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
