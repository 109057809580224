<div class="blocked-events">
  <h2 class="blocked-events__title" pui-typography="h2">
    <ng-container i18n="@@blockedEventsTitleStart">Conflicts with</ng-container>
    {{ ' ' }}
    {{ sessions.length }}
    {{ ' ' }}
    <ng-container i18n="@@blockedEventsTitleEnd">other sessions</ng-container>
  </h2>
  <p class="blocked-events__description" pui-typography="body-m">
    <ng-container i18n="@@blockedEventsDescriptionStart">These {{ sessions.length }} recurring sessions</ng-container>
    {{ ' ' }}
    <span pui-typography="body-m-bold" i18n="@@blockedEventsDescriptionBoldBlock">won’t be added</span>
    {{ ' ' }}
    <ng-container i18n="@@blockedEventsDescriptionEnd">
      because they conflict with existing events. If you want to add them please do it manually.
    </ng-container>
  </p>

  <div class="blocked-events__list">
    <ng-container *ngFor="let i of sessions">
      <div class="blocked-events__item">
        <div class="blocked-events__item-icon">
          <pui-icon svgIcon="pui:warning" size="s"></pui-icon>
        </div>

        <p class="blocked-events__item-time" pui-typography="body-s-bold">
          {{ i.range }}
        </p>
      </div>
    </ng-container>
  </div>

  <button pui-button appearance="primary" size="s" tabindex="1" (click)="dialogRef.close()">Ok</button>
</div>
