<div class="details">
  <div class="account-details">
    <div data-qa-id="calendar-account-email-title" class="name">{{ account.email }}</div>

    <div class="content">
      <div class="calendar" *ngFor="let calendar of account.calendars">
        <app-checkbox
          [disabled]="calendar.isSyncInProcess"
          [attr.data-qa-id]="'calendar-account-calendar-name-' + calendar.name + '-checkbox'"
          [value]="calendar.isSynced"
          (change)="changeSync(calendar, $event)">
          {{ calendar.name }}
          <ng-container *ngIf="calendar.isDefault" i18n="@@calendarAccountPrimary">(primary)</ng-container>
        </app-checkbox>
      </div>
    </div>
  </div>
</div>

<div class="buttons">
  <app-button
    (click)="disconnect.emit(account.id)"
    data-qa-id="calendar-account-disconnect-button"
    className="btn-link-danger"
    i18n="@@calendarSourceCardDisconnectButton"
    class="disconnect-btn">
    Disconnect
  </app-button>
</div>
