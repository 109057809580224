import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SchedulingStoreService } from '@app/modules/service-scheduling/services/scheduling-store.service';
import { setSeriviceSchedulingStep } from '@app/modules/service-scheduling/store/service-scheduling/service-scheduling.actions';
import { Store } from '@ngrx/store';

export const INIT_SERVICE_SCHEDULING_RESOLVER_FLOW_DATA = 'INIT_SERVICE_SCHEDULING_RESOLVER_FLOW_DATA';

@Injectable()
export class InitServiceSchedulingResolver implements Resolve<void> {
  constructor(private schedulingStoreService: SchedulingStoreService, private store$: Store) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const flowData = route.data[INIT_SERVICE_SCHEDULING_RESOLVER_FLOW_DATA];

    if (flowData) {
      this.store$.dispatch(setSeriviceSchedulingStep({ currentStep: flowData }));
    }

    this.schedulingStoreService.fetchAllServices();
  }
}
