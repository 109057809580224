import { Injectable } from '@angular/core';
import { IWidgetData, IWidgetService } from '@app/cdk/widgets';
import {
  BookingPopupComponent,
  SidenavCurrentBookingPopupView
} from '@app/modules/ui-kit/widgets/booking-popup/booking-popup.component';
import { ISidenavCurrentWithPopupView } from '@app/modules/sidenav/components/sidenav-current/interface';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { FloatingPopoverService } from '@app/modules/floating-popover/floating-popover.service';
import { UserService } from '@app/core/users/user.service';
import { SidenavModeService } from '@app/modules/sidenav/services/mode.service';
import { BookShareInfoService } from '@app/modules/embeddable-book-button/services/book-share-info.service';
import { filter, map } from 'rxjs/operators';
import { SidenavCurrentWithPopupViewComponent } from '@app/modules/sidenav/components/sidenav-current/view/current-view.component';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';

@Injectable()
export class CurrentGuideService
  implements IWidgetService<ISidenavCurrentWithPopupView<SidenavCurrentBookingPopupView>>
{
  view$: Observable<IWidgetData<ISidenavCurrentWithPopupView<SidenavCurrentBookingPopupView>>>;

  constructor(
    private readonly _authService: AuthService,
    private readonly _workspaceService: WorkspacesService,
    private readonly _popoverService: FloatingPopoverService,
    private readonly _modeService$: SidenavModeService,
    private readonly _bookShare: BookShareInfoService,
    private readonly user$: UserService
  ) {
    // @ts-expect-error TS2322
    this.view$ = combineLatest([this._workspaceService.activeWorkspace$, _modeService$, user$]).pipe(
      filter(([workspace, , user]) => !!workspace && !!user),
      map(([workspace, mode, user]) => {
        const { bookLink, teamBookLink } = _bookShare;
        return {
          component: SidenavCurrentWithPopupViewComponent,
          context: {
            avatar: {
              text: workspace.name[0],
              avatarUrl: WorkspaceUtility.isSolo(workspace) ? user!.photo : workspace.branding.icon,
              rounded: WorkspaceUtility.isSolo(workspace),
              background: workspace.branding?.color
            },
            title: workspace.name,
            subtitle: {
              teamBookingLink: teamBookLink,
              myBookingLink: bookLink,
              openPopup: (event, { myBookingLink, teamBookingLink }) => {
                event.preventDefault();
                event.stopPropagation();
                _popoverService.open({
                  origin: event.target as HTMLElement,
                  content: BookingPopupComponent,
                  data: { myBookingLink, teamBookingLink },
                  positionPair: [
                    {
                      originX: 'start',
                      overlayX: 'start',
                      originY: 'bottom',
                      overlayY: 'top'
                    }
                  ]
                });
              }
            },
            mode
          }
        };
      })
    );
  }
}
