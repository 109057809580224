import { ModuleTypes } from '../../program-module';
import { BaseModule, IBaseModuleDetails } from './base-module';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRestrictedModuleDetails extends IBaseModuleDetails {
  readonly type: ModuleTypes;
}

export class RestrictedModule extends BaseModule {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _type: ModuleTypes;
  readonly status = 'restricted';

  get type(): ModuleTypes {
    return this._type;
  }

  constructor(moduleDetails: Readonly<IRestrictedModuleDetails>) {
    super(moduleDetails);

    this._type = moduleDetails.type;
  }
}
