import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[puiMinMaxNumber], textarea[puiMinMaxNumber]'
})
export class PuiMaxMinNumberDirective {
  @Input() minNumber = 0;

  @Input() maxNumber = 9999;

  @HostListener('input', ['$event'])
  handleChange(event: Event): boolean {
    const value = Number((event.target as HTMLInputElement).value);

    if (value > this.maxNumber) {
      (event.target as HTMLInputElement).value = this.maxNumber.toString();
    }

    if (value < this.minNumber) {
      (event.target as HTMLInputElement).value = '';
    }

    return true;
  }
}
