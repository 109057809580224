<div class="pui-container session-widget__container" *puiLet="session.service as service">
  <!--HEADER-->
  <div class="pui-row">
    <div class="pui-col">
      <div class="service-heading">
        <span pui-typography="h3">{{ service.name }}</span>
      </div>
    </div>
  </div>

  <!--MAIN-->
  <div class="pui-row session-widget__main">
    <div class="pui-col session-widget__container" *ngIf="!session.isGroupSession; else chatTemplate">
      <app-notes-filters
        class="pui-space_bottom-5"
        [searchControl]="searchControl"
        [canShareNotes]="true"
        [clientId]="session.user.id"></app-notes-filters>

      <app-guide-events-notes-board
        class="pui-tabs_grow-1 pui-tabs_full"
        [eventId]="session.eventId"
        [user]="session.user"
        [serviceType]="serviceType"
        [searchControl]="searchControl"></app-guide-events-notes-board>
    </div>
  </div>
</div>

<ng-template #chatTemplate> Group session doesn't supoort the Notes feature :( </ng-template>
