<h5
  data-qa-id="client-session-invitations-session-invitations-title"
  class="title"
  i18n="@@sessionInvitationTitle"
  *ngIf="!hideHeader">
  Session invitations
</h5>
<span data-qa-id="client-session-invitations-new-badge" class="new-badge" i18n="@@new" *ngIf="!hideHeader">new</span>
<ng-template [ngIf]="invitations && invitations.length" [ngIfElse]="noSessionOffers">
  <ng-container
    *ngFor="let invitation of invitations | sortOnlySessionDate: 'dateStart':'asc'"
    [ngSwitch]="invitation.session.status">
    <app-client-session-invitation
      *ngSwitchCase="sessionStatuses.GUIDE_OFFER"
      [invitation]="invitation"></app-client-session-invitation>

    <app-rescheduling-client-session
      *ngSwitchCase="sessionStatuses.RESCHEDULE_BY_GUIDE"
      [clientSession]="invitation"></app-rescheduling-client-session>
  </ng-container>
</ng-template>

<ng-template #noSessionOffers>
  <div class="no-sessions" i18n="@@clientSessionInvitationsEmptyText">Session invitations will show up here</div>
</ng-template>
