import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PuiDialog, PuiDialogService } from '@awarenow/profi-ui-core';
import { take } from 'rxjs/operators';
import {
  createClientTag,
  createClientTagError,
  createClientTagSuccess,
  deleteClientTag,
  updateClientTag
} from '@app/screens/guide/guide-clients/guide-client/store/client-tags/client-tags-store.actions';
import { ClientTag } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/commons/types';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

const enum DialogOptionDeleteResult {
  CLOSE,
  DELETE_OPTION
}

@Injectable({ providedIn: 'root' })
export class ClientsTagsService {
  constructor(
    private readonly store$: Store<unknown>,
    private readonly dialogService: PuiDialogService,
    private readonly actions$: Actions
  ) {}

  create(tag: Partial<Omit<ClientTag, 'id'>>): Observable<{
    tag?: ClientTag;
    id?: ClientTag['id'];
  }> {
    this.store$.dispatch(createClientTag({ tag }));

    return this.actions$.pipe(ofType(createClientTagSuccess, createClientTagError));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  update(id: ClientTag['id'], update: Partial<Omit<ClientTag, 'id'>>) {
    this.store$.dispatch(updateClientTag({ id, update }));
  }

  deleteOptionConfirm(tagId: ClientTag['id']): void {
    const dialog = new PuiDialog();

    dialog.title = 'Delete tag';
    dialog.description = 'Deleting a tag removes it from all client records. Continue?';

    dialog.buttons = [
      {
        label: 'Delete',
        appearance: 'error',
        click: ref => {
          ref.close(DialogOptionDeleteResult.DELETE_OPTION);
        }
      },
      {
        label: 'Cancel',
        appearance: 'secondary',
        click: ref => {
          ref.close(DialogOptionDeleteResult.CLOSE);
        }
      }
    ];

    const ref = this.dialogService.open(dialog, {
      size: 's',
      hasCloseButton: true
    });

    ref.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result === DialogOptionDeleteResult.DELETE_OPTION) {
        this.store$.dispatch(deleteClientTag({ id: tagId }));
      }
    });
  }
}
