import { Directive, forwardRef, Input, Provider } from '@angular/core';
import { _MatAutocompleteTriggerBase } from '@angular/material/autocomplete';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  _countGroupLabelsBeforeOption,
  _getOptionScrollPosition,
  MatOptionSelectionChange
} from '@angular/material/core';
import { filter } from 'rxjs/operators';
import { DOWN_ARROW, ENTER, ESCAPE, hasModifierKey, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { UiAutocomplete } from './autocomplete';

export const APP_AUTOCOMPLETE_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UiAutocompleteTrigger),
  multi: true
};

@Directive({
  selector: `input[uiAutocomplete], textarea[uiAutocomplete]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-autocomplete-trigger mat-autocomplete-trigger',
    '[attr.autocomplete]': 'autocompleteAttribute',
    '[attr.role]': 'autocompleteDisabled ? null : "combobox"',
    '[attr.aria-autocomplete]': 'autocompleteDisabled ? null : "list"',
    '[attr.aria-activedescendant]': '(panelOpen && activeOption) ? activeOption.id : null',
    '[attr.aria-expanded]': 'autocompleteDisabled ? null : panelOpen.toString()',
    '[attr.aria-owns]': '(autocompleteDisabled || !panelOpen) ? null : autocomplete?.id',
    '[attr.aria-haspopup]': '!autocompleteDisabled',
    '(focusin)': '_handleFocus()',
    '(blur)': '_onTouched()',
    '(input)': '_handleInput($event)',
    '(keydown)': '_handleKeydown($event)'
  },
  exportAs: 'uiAutocompleteTrigger',
  providers: [APP_AUTOCOMPLETE_VALUE_ACCESSOR]
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiAutocompleteTrigger extends _MatAutocompleteTriggerBase {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _aboveClass = 'mat-autocomplete-panel-above';

  @Input('uiAutocomplete')
  get auto(): UiAutocomplete {
    return this.autocomplete;
  }

  set auto(value: UiAutocomplete) {
    if (value) {
      this.autocomplete = value;
    }
  }

  autocomplete!: UiAutocomplete;

  /**
   * Fires when the user interacts with options
   */
  optionSelections: Observable<MatOptionSelectionChange> = this.optionSelections.pipe(
    /**
     * If multiple selection is enabled, we skip user selection events
     * and implement our own logic to update the selected value (array of values).
     */
    filter(event => {
      if (this.autocomplete.multiple) {
        this.setValue(event);
      }

      return !this.autocomplete.multiple;
    })
  );

  setValue(event: MatOptionSelectionChange | null): void {
    if (event && event.source) {
      /**
       * For VALUE ACCESSOR set array of values AS value
       */
      this._onChange(this.autocomplete.selectedValues);

      /**
       * For AppAutocompleteSelectedMultipleEvent set array of options AS value
       */
      this.autocomplete._emitSelectEvent(event.source);
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _handleKeydown(event: KeyboardEvent): void {
    const { keyCode } = event;
    if (keyCode === ESCAPE && !hasModifierKey(event)) {
      event.preventDefault();
    }

    if (this.activeOption && keyCode === ENTER && this.panelOpen) {
      this.activeOption._selectViaInteraction();
      event.preventDefault();
    } else if (this.autocomplete) {
      const prevActiveItem = this.autocomplete._keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (this.panelOpen || keyCode === TAB) {
        this.autocomplete._keyManager.onKeydown(event);
      } else if (isArrowKey && !this.autocompleteDisabled) {
        this.openPanel();
      }

      if (isArrowKey || this.autocomplete._keyManager.activeItem !== prevActiveItem) {
        this.scrollToOption(this.autocomplete._keyManager.activeItemIndex || 0);
      }
    }
  }

  protected scrollToOption(index: number): void {
    const { autocomplete } = this;
    const labelCount = _countGroupLabelsBeforeOption(index, autocomplete.options, autocomplete.optionGroups);

    if (index === 0 && labelCount === 1) {
      autocomplete._setScrollTop(0);
    } else if (autocomplete.panel) {
      const option = autocomplete.options.toArray()[index];

      if (option) {
        const element = option._getHostElement();
        const newScrollPosition = _getOptionScrollPosition(
          element.offsetTop,
          element.offsetHeight,
          autocomplete._getScrollTop(),
          autocomplete.panel.nativeElement.offsetHeight
        );

        autocomplete._setScrollTop(newScrollPosition);
      }
    }
  }
}
