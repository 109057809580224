import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseIntegration } from '../../classes/base-integration';
import { ProfitwellService } from './profitwell.service';

@NgModule({
  imports: [CommonModule],
  providers: [ProfitwellService]
})
export class ProfitwellModule extends BaseIntegration {
  constructor(public service: ProfitwellService) {
    super(service);
  }
}
