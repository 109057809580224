import { User } from '@app/shared/interfaces/user';
import { IDiscount } from '@app/shared/interfaces/discount';
import { assertNever } from '@app/shared/utils/assertions';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';

export enum GuideRelationTypes {
  GUIDE_CONTACT = 'guideContact',
  GUIDE_CLIENT = 'guideClient'
}

// NOTE: minimum info about guide relation
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IServerGuideRelation {
  id: number;
  type: GuideRelationTypes;
  notes?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IContact {
  email?: string;
  phone?: string;
  organization?: string;
  firstName?: string;
  lastName?: string;
  tag?: string | null;
  archived?: boolean | null;
  archivedAt?: string | null;
  guides?: IWorkspaceMember[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideContact {
  id?: number;
  email: string;
  firstName?: string;
  lastName?: string;
  type: GuideRelationTypes.GUIDE_CONTACT;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IGuideClient {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  type: GuideRelationTypes.GUIDE_CLIENT;
  photo?: string;
}

export type IGuideRelation = IGuideContact | IGuideClient;

export abstract class CloneableUser extends User {
  // @ts-expect-error TS7006
  protected constructor(user) {
    super(user.id, user.firstName, user.lastName, user.photo, user.isOnline, user.lastTimezone);
  }

  // @ts-expect-error TS7010
  abstract clone();
}

export class GuideContact extends CloneableUser {
  static readonly localIdDelimiter = '_';

  isSupportUser: boolean;

  relationId: number;

  archived: boolean | null;

  archivedAt: string | null;

  contacts?: {
    email?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    organization?: string;
  };

  // Stage
  tag?: string | null;

  // Custom fields
  customFields!: {
    client_tags: number[];
    [key: string]: unknown;
  };

  // Guide tags
  tags!: { color: string; name: string; id: number }[];

  isNew = true;

  disabled = false;

  selected = false;

  createdAt: string;

  guides: IWorkspaceMember[];

  get localId(): string {
    return GuideContact.createLocalId(this);
  }

  get type(): GuideRelationTypes {
    return GuideRelationTypes.GUIDE_CONTACT;
  }

  // @ts-expect-error TS7006
  constructor(guideContact) {
    super(guideContact);

    const contacts = GuideContact.parseContactsArgument(guideContact.contacts);

    if (contacts) {
      this.contacts = contacts;
    }

    this.guides = guideContact.guides;
    this.archived = guideContact.archived;
    this.archivedAt = guideContact.archivedAt;
    this.relationId = guideContact.relationId;
    this.isSupportUser = guideContact.isSupportUser;
    this.createdAt = guideContact.createdAt;
    this.tag = guideContact.tag;
    // Custom field
    this.customFields = guideContact.customFields || {};
  }

  static parseContactsArgument(contactsArg: Partial<IContact> | null): Partial<IContact> | null {
    if (!contactsArg || (!contactsArg.email && !contactsArg.phone && !contactsArg.organization)) {
      return null;
    }

    const contacts: Partial<IContact> = {};

    if (contactsArg.email) {
      contacts.email = contactsArg.email;
    }

    if (contactsArg.phone) {
      contacts.phone = contactsArg.phone;
    }

    if (contactsArg.organization) {
      contacts.organization = contactsArg.organization;
    }

    if (contactsArg.firstName) {
      contacts.firstName = contactsArg.firstName;
    }

    if (contactsArg.lastName) {
      contacts.lastName = contactsArg.lastName;
    }

    return contacts;
  }

  static createLocalId<T extends { id: number; type: GuideRelationTypes; contacts?: { email?: string } }>(
    props: T
  ): string {
    return `${props.type}${GuideContact.localIdDelimiter}${props.id}`;
  }

  static parseLocalId(localId: string): { id: number; type: GuideRelationTypes } {
    const [typeStr, idStr] = localId.split(GuideContact.localIdDelimiter);

    const id = +idStr;

    if (Number.isNaN(id)) {
      throw new Error('Cannot parse local id. Id is not a number');
    }

    if (typeStr !== GuideRelationTypes.GUIDE_CONTACT && typeStr !== GuideRelationTypes.GUIDE_CLIENT) {
      throw new Error('Cannot parse local id. Incorrect relation type');
    }

    return { id, type: typeStr as GuideRelationTypes };
  }

  clone(): GuideContact {
    const args = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.photo,
      contacts: this.contacts,
      isNew: !!this.isNew,
      archived: this.archived,
      archivedAt: this.archivedAt,
      relationId: this.relationId,
      isSupportUser: this.isSupportUser,
      disabled: this.disabled,
      tag: this.tag,
      guides: this.guides
    };

    return new GuideContact(args);
  }
}

export class GuideClient extends GuideContact {
  isReferral: boolean;

  freeSessionsCount?: number;

  relationId: number;

  isSupportUser: boolean;

  get type(): GuideRelationTypes {
    return GuideRelationTypes.GUIDE_CLIENT;
  }

  // @ts-expect-error TS7006
  constructor(guideContact) {
    super(guideContact);

    this.freeSessionsCount = guideContact.freeSessionsCount;
    this.isReferral = guideContact.isReferral;
    this.isNew = guideContact.isNew;
    this.relationId = guideContact.relationId;
    this.isSupportUser = guideContact.isSupportUser;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clone() {
    const args = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.photo,
      isOnline: this.isOnline,
      lastTimezone: this.lastTimezone,
      isReferral: this.isReferral,
      freeSessionsCount: this.freeSessionsCount,
      contacts: this.contacts,
      guides: this.guides,
      isNew: !!this.isNew,
      archived: this.archived,
      archivedAt: this.archivedAt,
      isSupportUser: this.isSupportUser,
      relationId: this.relationId,
      disabled: this.disabled,
      tag: this.tag
    };

    return new GuideClient(args);
  }
}

export class ClientGuide extends CloneableUser {
  workspaceId: number;

  about?: string;

  discount?: IDiscount;

  freeFirstSession: boolean;

  freeAllSessions: boolean;

  namedUrl: string;

  pricePerHour: number;

  reviewReadySessionId?: number;

  specialization?: string;

  workspaceName?: string;

  workspaceType?: WorkspacesTypes;

  key: string;

  permission?: ServiceAssigneePermission;

  get name(): string {
    const workspaceName = `${
      this.workspaceName && this.workspaceType !== WorkspacesTypes.SOLO ? `(${this.workspaceName})` : ''
    }`;

    return `${this.firstName} ${this.lastName} ${workspaceName}`.trim();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(clientGuide: any) {
    super(clientGuide);

    this.namedUrl = clientGuide.namedUrl;
    this.pricePerHour = clientGuide.pricePerHour;
    this.freeFirstSession = clientGuide.freeFirstSession;
    this.freeAllSessions = clientGuide.freeAllSessions;
    this.discount = clientGuide.discount;
    this.about = clientGuide.about;
    this.specialization = clientGuide.specialization;
    this.isOnline = clientGuide.isOnline;
    this.reviewReadySessionId = clientGuide.reviewReadySessionId;
    this.workspaceId = clientGuide.workspaceId;
    this.workspaceName = clientGuide.workspaceName;
    this.workspaceType = clientGuide.workspaceType;
    this.key = clientGuide.key;
    this.permission = clientGuide.permission;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clone() {
    const args = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.photo,
      isOnline: this.isOnline,
      lastTimezone: this.lastTimezone
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    if (this.pricePerHour) {
      args.pricePerHour = this.pricePerHour;
    }

    if (this.freeFirstSession) {
      args.freeFirstSession = this.freeFirstSession;
    }

    if (this.freeAllSessions) {
      args.freeAllSessions = this.freeAllSessions;
    }

    if (this.namedUrl) {
      args.namedUrl = this.namedUrl;
    }

    if (this.about) {
      args.about = this.about;
    }

    if (this.specialization) {
      args.specialization = this.specialization;
    }

    if (this.discount) {
      args.discount = this.discount;
    }

    if (this.reviewReadySessionId) {
      args.reviewReadySessionId = this.reviewReadySessionId;
    }

    if (this.workspaceId) {
      args.workspaceId = this.workspaceId;
    }

    if (this.workspaceName) {
      args.workspaceName = this.workspaceName;
    }

    if (this.workspaceType) {
      args.workspaceType = this.workspaceType;
    }

    return new ClientGuide(args);
  }
}

export type GuideRelation = GuideContact | GuideClient;

export function guideRelationFactory(guideRelation: { type: GuideRelationTypes }): GuideRelation {
  switch (guideRelation.type) {
    case GuideRelationTypes.GUIDE_CONTACT:
      return new GuideContact(guideRelation);
    case GuideRelationTypes.GUIDE_CLIENT:
      return new GuideClient(guideRelation);
    default:
      return assertNever(guideRelation, '"type"-property not found in:');
  }
}

export function isAssignedToGuide(guideRelation: GuideRelation, guideMail: string): boolean {
  return !!guideRelation?.guides?.find(member => member.email === guideMail);
}

export function isGuideContact(guideRelation: GuideRelation): guideRelation is GuideContact {
  return guideRelation?.type === GuideRelationTypes.GUIDE_CONTACT;
}

export function isGuideClient(guideRelation: GuideRelation): guideRelation is GuideClient {
  return guideRelation.type === GuideRelationTypes.GUIDE_CLIENT;
}

export function isGuideRelation(testRelation: { type: string }): testRelation is GuideRelation {
  return (
    testRelation.type === GuideRelationTypes.GUIDE_CONTACT || testRelation.type === GuideRelationTypes.GUIDE_CLIENT
  );
}

export function relationTypeToPathResolver(type: GuideRelationTypes): string | null {
  if (type === GuideRelationTypes.GUIDE_CLIENT) {
    return 'clients';
  }
  if (type === GuideRelationTypes.GUIDE_CONTACT) {
    return 'contacts';
  }

  return null;
}
