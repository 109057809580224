import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  CRMClient,
  GuideClientsApiService
} from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { puiSortFunction } from '@awarenow/profi-ui-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { GuideServicesApiService } from '../../services/guide-services-api.service';
import { ServiceSchedulingLSService } from '../../services/scheduling-local-storage.service';
import { ServiceSchedulingService } from '../../services/service-scheduling.service';
import * as serviceSchedulingActions from './service-scheduling.actions';

const getFullName = ({ firstName, lastName }: CRMClient): string => `${firstName} ${lastName}`.trim();

const sortGuideClients = (clients: CRMClient[]): CRMClient[] =>
  clients.sort((clientA, clientB) => puiSortFunction('asc')(getFullName(clientA), getFullName(clientB)));

@Injectable()
export class ServiceSchedulingEffects {
  fetchAllServices$ = createEffect(() =>
    this.action$.pipe(
      ofType(serviceSchedulingActions.fetchAllServices),
      tap(() => this.serviceSchedulingService.openServicesListDrawer()),
      mergeMap(() =>
        this.guideServicesApiService.getGuideServices().pipe(
          map(({ services }) => ({
            type: serviceSchedulingActions.fetchAllServicesSuccess.type,
            services: services
          })),
          catchError(() =>
            of({
              type: serviceSchedulingActions.fetchAllServicesError.type
            })
          )
        )
      )
    )
  );

  fetchAllServicesSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(serviceSchedulingActions.fetchAllServicesSuccess),
      mergeMap(() =>
        this.guideClientsApiService.get().pipe(
          map(data => sortGuideClients(data.clients)),
          map(users => {
            const config = this.serviceSchedulingService.config$?.value;
            const options = this.serviceSchedulingService.options$?.value;

            return {
              type: serviceSchedulingActions.setClients.type,
              clients: options?.onlyClients
                ? users.filter(({ clientId }) => clientId !== undefined && clientId !== null)
                : users,
              chosenClientsIds: config.clientsIds?.length
                ? config.clientsIds
                : this.schedulingLocalStorageService.getChosenClientsIds()
            };
          }),
          catchError(() =>
            of({
              type: serviceSchedulingActions.fetchAllServicesError.type
            })
          )
        )
      )
    )
  );

  constructor(
    private readonly action$: Actions,
    private readonly guideClientsApiService: GuideClientsApiService,
    private readonly serviceSchedulingService: ServiceSchedulingService,
    private readonly schedulingLocalStorageService: ServiceSchedulingLSService,
    private readonly guideServicesApiService: GuideServicesApiService
  ) {}
}
