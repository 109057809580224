<header>
  <div class="logo">
    <a class="awarenow-logo" routerLink="/">
      <img [src]="mainLogo" />
    </a>
  </div>
  <div class="user row align-content-center">
    <div class="col user-block">
      <app-user-menu></app-user-menu>
    </div>
  </div>
</header>
