import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { BookingStep } from '@appWidget/modules/booking/consts';
import { BookingTypes } from '@appWidget/modules/booking/enums/booking-types';
import { LOCATION_ORIGIN } from '../../../../consts';

export const getPathWithBaseUrl = (path: string) => {
  return `${LOCATION_ORIGIN}/${path}`;
};
export const soloSessionPath = (nameOrId: string | number, templateId: number) =>
  `book/${nameOrId}/${templateId}/${BookingStep.SCHEDULING}`;

export const teamSessionPath = (teamNameOrWorkspaceId: string | number, templateId: number) =>
  `book/t/${teamNameOrWorkspaceId}/${templateId}/${BookingStep.CHOOSE_HOST}`;

export const getBookingRedirectRoute = (guideId: number, type: WorkspacesTypes, workspaceId: number | null): string => {
  if (type === WorkspacesTypes.SOLO) {
    return `/book/${guideId}`;
  }

  return workspaceId ? `/book/t/${workspaceId}` : '';
};

export const getServiceScheduleRedirectRoute = ({
  hostId,
  templateId,
  workspaceId,
  type
}: {
  hostId: number;
  templateId: number;
  workspaceId: number;
  type: WorkspacesTypes;
}): string => {
  let path = `book/t/${workspaceId}/${hostId}/${templateId}/${BookingStep.SCHEDULING}`;

  if (type === WorkspacesTypes.SOLO) {
    path = `book/${hostId}/${templateId}/${BookingStep.SCHEDULING}`;
  }

  path += `?type=${BookingTypes.SESSION_WITH_AUTHORIZATION}`;

  return getPathWithBaseUrl(path);
};
