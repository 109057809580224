<div class="row">
  <ng-container *ngFor="let weekday of weekdays; let i = index">
    <div class="col-6 item">
      <div class="weekday">
        <app-checkbox [value]="weekday.active" (change)="weekdayChanged(i)" [disabled]="disabled"></app-checkbox>
        <div class="name">{{ weekday.name }}</div>
      </div>
    </div>
  </ng-container>
</div>
