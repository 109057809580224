import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientNotesService } from '@app/modules/client-notes/client-notes.service';
import { ClientNotesApiService } from '@app/modules/client-notes/client-notes-api.service';
import { ClientNote } from '@app/modules/client-notes/client-notes.type';
import { INoteCardUpdateContent, NotesTypes, NotesTypesSortProp } from '@app/shared/interfaces/notes';
import { NoteActionModalComponent } from '@app/modules/user-notes/components/note-action-modal/note-action-modal.component';
import { modalResultToObservable$ } from '@app/shared/utils/modal-result-to-observable';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IClientNotesBoardState {
  notes: ClientNote[];
  leftCursor: string | null;
  rightCursor: string | null;
  leftCursorPinned: string | null;
  rightCursorPinned: string | null;
  leftNotesType: NotesTypes;
  rightNotesType: NotesTypes;
  scrollTo: number | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IExtendedClientNotesBoardState extends IClientNotesBoardState {
  countOfRestNotesToLoad: number;
}

@Injectable()
export class ClientNotesBoardService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _serviceId: symbol;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _limit = 15;

  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initialState = {
    notes: [],
    leftCursor: null,
    rightCursor: null,
    leftCursorPinned: null,
    rightCursorPinned: null,
    leftNotesType: NotesTypes.PINNED,
    rightNotesType: NotesTypes.PINNED,
    scrollTo: null
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _state$: BehaviorSubject<IClientNotesBoardState> = new BehaviorSubject(this._initialState);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _notesStatuses: { [id: string]: string } = {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _noteChangeSubscription: { [id: string]: Subscription } = {};

  constructor(
    private readonly _clientNotes: ClientNotesService,
    private readonly _clientNoteApi: ClientNotesApiService,
    protected readonly modal: NgbModal
  ) {
    this._serviceId = Symbol('ClientNotesBoardService');

    _clientNotes.clientNotesUpdate$
      .pipe(
        filter(({ serviceId }) => serviceId !== this._serviceId),
        switchMap(({ noteId }) => this._clientNoteApi.getNote$(noteId)),
        filter(({ id, pinned }) => {
          // if note not in the list and unpinned skip it
          const { notes } = this.state;
          const isNoteToUpdateInList = !!notes.find(({ id: currentId }) => currentId === id);
          return isNoteToUpdateInList || pinned;
        }),
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.destroy$),
        map(note => {
          const { contentDeltaFormat, content, contentText, updatedAt, pinned: pinnedStatus, pinnedAt, id } = note;
          const {
            notes: currentNotes,
            rightCursor,
            rightNotesType,
            rightCursorPinned,
            leftCursor,
            leftNotesType,
            leftCursorPinned,
            ...restState
          } = this.state;

          const noteToUpdate = currentNotes.find(({ id: currentId }) => currentId === id);

          const rightBoundaryCondition =
            rightNotesType === NotesTypes.STANDARD ||
            // @ts-expect-error TS2531
            (rightNotesType === NotesTypes.PINNED && rightCursorPinned && rightCursorPinned < pinnedAt);

          if (!noteToUpdate && rightBoundaryCondition) {
            // note not in the list and pinned
            const [unsortedPinnedNotes, standardNotes] = this._splitNotesByPinStatus([note, ...currentNotes]);
            const pinnedNotes = this._sortNotesBy(unsortedPinnedNotes, NotesTypesSortProp.PINNED);

            const updatedNotes = [...pinnedNotes, ...standardNotes];

            return {
              ...restState,
              notes: updatedNotes,
              rightCursor,
              rightNotesType,
              rightCursorPinned,
              leftCursor,
              leftNotesType,
              leftCursorPinned,
              scrollTo: null,
              countOfRestNotesToLoad: 0
            };
          }

          const noteChangedPinStatus = noteToUpdate && noteToUpdate.pinned !== pinnedStatus;

          // @ts-expect-error TS2532
          noteToUpdate.contentDeltaFormat = contentDeltaFormat;
          // @ts-expect-error TS2532
          noteToUpdate.contentText = contentText;
          // @ts-expect-error TS2532
          noteToUpdate.content = content;
          // @ts-expect-error TS2532
          noteToUpdate.updatedAt = updatedAt;
          // @ts-expect-error TS2532
          noteToUpdate.pinned = pinnedStatus;
          // @ts-expect-error TS2532
          noteToUpdate.pinnedAt = pinnedAt;

          let notes = [...currentNotes];

          if (noteChangedPinStatus && pinnedStatus === true) {
            const [unsortedPinnedNotes, standardNotes] = this._splitNotesByPinStatus(currentNotes);
            const pinnedNotes = this._sortNotesBy(unsortedPinnedNotes, NotesTypesSortProp.PINNED);
            notes = [...pinnedNotes, ...standardNotes];
          }

          if (noteChangedPinStatus && pinnedStatus === false) {
            const shouldRemoveNoteFromList =
              (rightNotesType === NotesTypes.PINNED && rightCursorPinned) ||
              (rightNotesType === NotesTypes.STANDARD && rightCursor && rightCursor > updatedAt);

            if (shouldRemoveNoteFromList) {
              const updatedNotes = currentNotes.filter(({ id: currentId }) => currentId !== id);
              return {
                ...restState,
                notes: updatedNotes,
                rightCursor,
                rightNotesType,
                rightCursorPinned,
                leftCursor,
                leftNotesType,
                leftCursorPinned,
                scrollTo: null,
                countOfRestNotesToLoad: 1
              };
            }

            const [pinnedNotes, unsortedStandardNotes] = this._splitNotesByPinStatus(currentNotes);
            const standardNotes = this._sortNotesBy(unsortedStandardNotes, NotesTypesSortProp.STANDARD);
            notes = [...pinnedNotes, ...standardNotes];
          }

          return {
            ...restState,
            rightCursor,
            rightNotesType,
            rightCursorPinned,
            leftCursor,
            leftNotesType,
            leftCursorPinned,
            notes,
            scrollTo: null,
            countOfRestNotesToLoad: 0
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }
      });

    _clientNotes.clientNoteCreate$
      .pipe(
        switchMap(({ noteId }) => this._clientNoteApi.getNote$(noteId)),
        map(note => this._updateStateAfterNoteAdded(this.state, [note])),
        takeUntil(this.destroy$)
      )
      .subscribe(state => this.setState(state));

    _clientNotes.clientNoteDelete$
      .pipe(
        map(({ noteId: deleteId }) => this._updateStateAfterNoteRemoved(this.state, deleteId)),
        takeUntil(this.destroy$)
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }
      });

    _clientNotes.grantAccess$
      .pipe(
        switchMap(({ noteIds }) => this._clientNoteApi.getSharedNotes$(noteIds)),
        map(sharedNotes => this._updateStateAfterNoteAdded(this.state, sharedNotes)),
        takeUntil(this.destroy$)
      )
      .subscribe(state => this.setState(state));

    _clientNotes.revokeAccess$
      .pipe(
        map(({ noteIds: [deleteId] }) => this._updateStateAfterNoteRemoved(this.state, deleteId)),
        takeUntil(this.destroy$)
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get state$() {
    return this._state$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get state() {
    return this._state$.getValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setState(state: IClientNotesBoardState) {
    this._state$.next(state);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateNote({ index, text, content, html }: INoteCardUpdateContent, stopEditing: boolean) {
    const { notes, rightCursor, leftCursor, ...restNotes } = this.state;
    const currentNote = notes[index];
    const noteId = currentNote.id;
    const noteHtmlBeforeChange = currentNote.content;

    if (text && text.trim() === '' && stopEditing) {
      this.deleteNote(index);
      return;
    }

    if (noteHtmlBeforeChange === html) {
      return;
    }
    const currentNoteStatus = this._notesStatuses[noteId] || 'saved';
    switch (currentNoteStatus) {
      case 'unsaved':
      case 'saved':
        this._notesStatuses[noteId] = 'save-pending';
        this._noteChangeSubscription[noteId] = this._clientNoteApi
          .updateNote$(noteId, {
            content: html,
            contentDeltaFormat: content,
            contentText: text
          })
          .subscribe(
            ({ content: contentHtml, contentDeltaFormat, contentText, updatedAt }) => {
              if (stopEditing) {
                // TODO: create new note via ClientNote class
                const updatedNotes = notes.map(note =>
                  note.id === currentNote.id
                    ? {
                        ...note,
                        contentDeltaFormat,
                        contentText,
                        content: contentHtml,
                        updatedAt
                      }
                    : note
                );

                this.setState({
                  ...restNotes,
                  notes: updatedNotes,
                  rightCursor,
                  leftCursor,
                  scrollTo: null
                });
              }
              this._clientNotes.updateNote({
                serviceId: this._serviceId,
                noteId
              });

              this._notesStatuses[noteId] = 'saved';
            },
            () => {
              this._notesStatuses[noteId] = 'unsaved';
            }
          );
        break;
      case 'save-pending':
        if (this._noteChangeSubscription[noteId]) {
          this._noteChangeSubscription[noteId].unsubscribe();
        }
        this._notesStatuses[noteId] = 'saved';
        this.updateNote({ index, text, content, html }, stopEditing);
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  deleteNote(index: number) {
    const { id: deleteId } = this.state.notes[index];
    this._clientNoteApi
      .deleteNote$(deleteId)
      .pipe(map(() => this._updateStateAfterNoteRemoved(this.state, deleteId)))
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }

        this._clientNotes.deleteNote({
          serviceId: this._serviceId,
          noteId: deleteId
        });
      });
  }

  showAllNotes(search?: string): void {
    this._clientNoteApi
      .getNotes$({ direct: true, cursor: null, limit: this._limit, pinned: true, search })
      .pipe(
        map(pinnedNotesResponse => {
          const countOfRestNotesToLoad = this._limit - pinnedNotesResponse.notes.length;

          if (countOfRestNotesToLoad > 0) {
            return {
              ...this.state,
              notes: [...pinnedNotesResponse.notes],
              rightCursor: null,
              rightCursorPinned: pinnedNotesResponse.cursor,
              rightNotesType: NotesTypes.STANDARD,
              scrollTo: null,
              countOfRestNotesToLoad
            };
          }

          return {
            ...this.state,
            notes: [...pinnedNotesResponse.notes],
            rightCursor: null,
            rightCursorPinned: pinnedNotesResponse.cursor,
            rightNotesType: NotesTypes.PINNED,
            scrollTo: null,
            countOfRestNotesToLoad: 0
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad, search);
        }
      });
  }

  // TODO: This should be rewritten, before implementing search in notes widget
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  showAdjacentNotes(id: number) {
    // This is temporary solution, due to lack of time
    this.showAllNotes();
  }

  loadEarliestNotes(count: number | null, search?: string): void {
    const { rightCursor, rightCursorPinned, rightNotesType } = this.state;
    const isPinned = rightNotesType === NotesTypes.PINNED;
    const currentCursor = isPinned ? rightCursorPinned : rightCursor;
    const limit = count || this._limit;

    this._clientNoteApi
      .getNotes$({ direct: true, cursor: currentCursor, limit, pinned: isPinned, search })
      .pipe(
        map(noteResponse => {
          const restState = { ...this.state, scrollTo: null };
          const currentNotes = [...restState.notes, ...noteResponse.notes];
          const notesUniqueById = [...new Map(currentNotes.map(item => [item['id'], item])).values()];

          const countOfRestNotesToLoad = limit - noteResponse.notes.length;
          if (countOfRestNotesToLoad > 0 && isPinned) {
            return {
              ...restState,
              notes: notesUniqueById,
              rightCursor: null,
              rightCursorPinned: noteResponse.cursor,
              rightNotesType: NotesTypes.STANDARD,
              countOfRestNotesToLoad
            };
          }

          return {
            ...restState,
            notes: notesUniqueById,
            rightCursor: !isPinned ? noteResponse.cursor : rightCursor,
            rightCursorPinned: isPinned ? noteResponse.cursor : rightCursorPinned,
            rightNotesType,
            countOfRestNotesToLoad: 0
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }
      });
  }

  pinNote(index: number): void {
    const { id: pinnedId } = this.state.notes[index];
    this._clientNoteApi
      .pinNote$(pinnedId)
      .pipe(
        map(({ pinnedAt }) => {
          const { notes: currentNotes, rightCursorPinned, rightNotesType, ...restState } = this.state;

          if (rightNotesType === NotesTypes.PINNED && rightCursorPinned && rightCursorPinned < pinnedAt) {
            const updatedNotes = currentNotes.filter(({ id: currentId }) => currentId !== pinnedId);

            return {
              ...restState,
              notes: updatedNotes,
              rightNotesType,
              rightCursorPinned,
              scrollTo: null,
              countOfRestNotesToLoad: 1
            };
          }

          const updatedNotes = currentNotes.map(note => {
            if (note.id === pinnedId) {
              note.pinned = true;
              note.pinnedAt = pinnedAt;
            }
            return note;
          });

          const [pinnedNotes, restNotes] = this._splitNotesByPinStatus(updatedNotes);
          const sortedPinnedNotes = this._sortNotesBy(pinnedNotes, NotesTypesSortProp.PINNED);

          return {
            ...restState,
            notes: [...sortedPinnedNotes, ...restNotes],
            rightNotesType,
            rightCursorPinned,
            scrollTo: pinnedId,
            countOfRestNotesToLoad: 0
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }

        this._clientNotes.updateNote({
          serviceId: this._serviceId,
          noteId: pinnedId
        });
      });
  }

  unpinNote(index: number): void {
    const { id: pinnedId, updatedAt } = this.state.notes[index];

    const { componentInstance, result } = this.modal.open(NoteActionModalComponent, { centered: true });

    componentInstance.message = `Are you sure you want to remove this pinned note?`;
    componentInstance.title = `Un-pin note?`;
    componentInstance.rejectText = `Cancel`;
    componentInstance.confirmText = `Un-pin note`;

    modalResultToObservable$(result)
      .pipe(
        filter(confirm => confirm),
        switchMap(() => this._clientNoteApi.unpinNote$(pinnedId)),
        map(() => {
          const { notes: currentNotes, rightNotesType, rightCursor, rightCursorPinned, ...restState } = this.state;
          if (
            (rightNotesType === NotesTypes.PINNED && rightCursorPinned) ||
            (rightNotesType === NotesTypes.STANDARD && rightCursor && rightCursor > updatedAt)
          ) {
            const updatedNotes = currentNotes.filter(({ id: currentId }) => currentId !== pinnedId);
            return {
              ...restState,
              notes: updatedNotes,
              rightNotesType,
              rightCursor,
              rightCursorPinned,
              scrollTo: null,
              countOfRestNotesToLoad: 1
            };
          }

          const updatedNotes = currentNotes.map(note => {
            if (note.id === pinnedId) {
              note.pinned = false;
            }
            return note;
          });

          const [pinnedNotes, restNotes] = this._splitNotesByPinStatus(updatedNotes);
          const sortedRestNotes = this._sortNotesBy(restNotes, NotesTypesSortProp.STANDARD);

          return {
            ...restState,
            notes: [...pinnedNotes, ...sortedRestNotes],
            rightNotesType,
            rightCursor,
            rightCursorPinned,
            scrollTo: null,
            countOfRestNotesToLoad: 1
          };
        })
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadEarliestNotes(countOfRestNotesToLoad);
        }

        this._clientNotes.updateNote({
          serviceId: this._serviceId,
          noteId: pinnedId
        });
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  private _splitNotesByPinStatus(currentNotes: ClientNote[]) {
    return currentNotes.reduce(
      ([pinned, rest], note) => {
        return note.pinned ? [[...pinned, note], rest] : [pinned, [...rest, note]];
      },
      [[], []] as [ClientNote[], ClientNote[]]
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sortNotesBy(notes: ClientNote[], prop: keyof ClientNote): ClientNote[] {
    return notes.sort(({ [prop]: propA }, { [prop]: propB }) => {
      // @ts-expect-error TS2533
      if (propA < propB) {
        return 1;
      }
      // @ts-expect-error TS2533
      if (propA > propB) {
        return -1;
      }
      return 0;
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateStateAfterNoteRemoved(
    state: IClientNotesBoardState,
    deleteId: number
  ): IExtendedClientNotesBoardState {
    const { notes } = state;

    const [updatedNotes, removedNote]: [ClientNote[], ClientNote | null] = notes.reduce(
      ([filteredNotes, noteToDelete], currentNote) =>
        currentNote.id !== deleteId ? [[...filteredNotes, currentNote], noteToDelete] : [filteredNotes, currentNote],
      [[], null] as [ClientNote[], ClientNote | null]
    );

    const isNoteRemoved = !!removedNote;
    const [pinnedNotes, restNotes] = this._splitNotesByPinStatus(updatedNotes);

    if (!isNoteRemoved) {
      return { ...state, countOfRestNotesToLoad: 0 };
    }

    let { rightCursor, rightCursorPinned, rightNotesType } = state;

    if (pinnedNotes.length === 0) {
      rightCursorPinned = null;
    }

    if (restNotes.length === 0) {
      rightCursor = null;
      rightNotesType = NotesTypes.PINNED;
    }

    rightCursor =
      rightNotesType === NotesTypes.STANDARD && restNotes.length
        ? restNotes[restNotes.length - 1].updatedAt
        : rightCursor;
    rightCursorPinned =
      rightNotesType === NotesTypes.PINNED && pinnedNotes.length
        ? pinnedNotes[pinnedNotes.length - 1].pinnedAt
        : rightCursorPinned;

    return {
      ...state,
      notes: updatedNotes,
      rightCursor,
      rightCursorPinned,
      rightNotesType,
      scrollTo: null,
      countOfRestNotesToLoad: 1
    };
  }

  // TODO: if notes count less than limit, then we can add more notes
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateStateAfterNoteAdded(
    state: IClientNotesBoardState,
    incomingNotes: ClientNote[]
  ): IClientNotesBoardState {
    const { notes, rightCursor, leftCursor, rightNotesType, leftNotesType, ...restState } = state;
    const notesToAdd = incomingNotes.filter(({ updatedAt }) => {
      const leftBoundaryCondition =
        leftNotesType === NotesTypes.PINNED ||
        (leftNotesType === NotesTypes.STANDARD && !!leftCursor && leftCursor > updatedAt);
      const rightBoundaryCondition =
        rightNotesType === NotesTypes.STANDARD && ((!!rightCursor && rightCursor < updatedAt) || notes.length === 0);

      return leftBoundaryCondition && rightBoundaryCondition;
    });

    const [pinnedNotes, restNotes] = this._splitNotesByPinStatus([...notesToAdd, ...notes]);
    const sortedStandardNotes = this._sortNotesBy(restNotes, NotesTypesSortProp.STANDARD);
    let cursor = rightCursor;

    if (rightNotesType === NotesTypes.STANDARD) {
      cursor = sortedStandardNotes[sortedStandardNotes.length - 1].updatedAt;
    }

    return {
      ...restState,
      notes: [...pinnedNotes, ...sortedStandardNotes],
      rightCursor: cursor,
      leftCursor,
      rightNotesType,
      leftNotesType,
      scrollTo: null
    };
  }
}
