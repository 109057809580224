<div class="info">
  <h2 transloco="theGuide">The coach</h2>
  <app-user-info [user]="guide" [uiPayload]="userDescription"></app-user-info>

  <ng-template #userDescription>
    {{ guide.specialization }}
  </ng-template>

  <div class="about" *ngIf="!programSessions && guide?.about">
    <p>{{ guide?.about }}</p>
    <app-button className="btn-link" [routerLink]="['/', guide.namedUrl]" i18n="@@readFullBio">
      Read full bio
    </app-button>
  </div>

  <div class="sessions-info available" *ngIf="programSessions && programSessions.length === 1">
    <p>
      <ng-container i18n="@@thereAre">There are</ng-container>
      <strong>{{ programSession.total }} {{ sessionTypeToStringMap[programSession.type] }}</strong>
      <ng-container transloco="withTheGuide">with the coach</ng-container>
      .
      <ng-container i18n="@@youHave">You have</ng-container>
      <strong>
        {{ programSession.left }}
        <ng-container i18n="@@available">available</ng-container>
      </strong>
      .
    </p>
  </div>

  <div class="sessions-info available" *ngIf="programSessions && programSessions.length > 1">
    <h6>
      <ng-container i18n="@@youHave">You have</ng-container>
      :
    </h6>
    <p *ngFor="let session of programSessions">
      <strong>{{ session.total }}</strong>
      {{ sessionTypeToStringMap[session.type] }} (
      <strong>
        {{ session.left }}
        <ng-container i18n="@@available">available</ng-container>
      </strong>
      )
    </p>
  </div>
</div>

<div class="controls">
  <ng-template [ngIf]="programSessions">
    <app-button className="btn-main btn-book-session" (click)="bookSession.emit()" i18n="@@bookASession">
      Book a session
    </app-button>
  </ng-template>

  <ng-template [ngIf]="!programSessions">
    <app-button className="btn-primary btn-book-session" (click)="bookSession.emit()" i18n="@@bookASession">
      Book a session
    </app-button>
  </ng-template>
  <app-button className="btn-lite btn-lg" [routerLink]="'/client/chat/' + guide.id" i18n="@@askAQuestion">
    Ask a question
  </app-button>
</div>
