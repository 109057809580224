<ng-container *ngIf="label && leftIcon">
  <div class="attribute">
    <pui-icon class="attribute__icon" [svgIcon]="leftIcon" size="s"></pui-icon>
    <div class="attribute__label" [disableTooltip]="!showTooltip" [ngbTooltip]="tooltipText">
      <ng-container *ngIf="!labelRouterLink; else labelWithLink">{{ label }}</ng-container>
    </div>
  </div>
</ng-container>

<ng-template #labelWithLink>
  <a target="_blank" pui-link [href]="labelRouterLink">{{ label }}</a>
</ng-template>
