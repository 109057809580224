import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss']
})
export class RangeDatepickerComponent implements OnInit {
  DEFAULT_FROM_VALUE = '';
  DEFAULT_TO_VALUE = '';
  DEFAULT_SELFPACED_VALUE = false;

  form = this.fb.group({
    from: this.fb.control(this.DEFAULT_FROM_VALUE),
    to: this.fb.control(this.DEFAULT_TO_VALUE),
    selfPaced: this.fb.control(this.DEFAULT_SELFPACED_VALUE)
  });

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  @Output()
  selectItem = new EventEmitter();

  @ViewChild(NgbDatepicker, { static: true })
  // @ts-expect-error TS2564
  datepicker: NgbDatepicker;

  constructor(private fb: FormBuilder, calendar: NgbCalendar, public i18n: NgbDatepickerI18n) {
    this.fromDate = calendar.getToday();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    const { from, to } = this.form.controls;
    // @ts-expect-error TS2322
    this.fromDate = from.value ? this.fromIsoToNgbDate(from.value) : null;
    this.toDate = to.value ? this.fromIsoToNgbDate(to.value) : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getForm() {
    return this.form;
  }

  // @ts-expect-error TS7031
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateForm({ from, to, selfPaced }) {
    this.form.patchValue({ from, to, selfPaced }, { emitEvent: false });

    if (
      from === this.DEFAULT_FROM_VALUE &&
      to === this.DEFAULT_TO_VALUE &&
      !!selfPaced === this.DEFAULT_SELFPACED_VALUE
    ) {
      this.form.markAsPristine();
    } else {
      this.form.markAsDirty();
    }

    // @ts-expect-error TS2322
    this.fromDate = from ? this.fromIsoToNgbDate(from) : null;
    this.toDate = to ? this.fromIsoToNgbDate(to) : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  resetForm() {
    this.form.patchValue(
      {
        from: this.DEFAULT_FROM_VALUE,
        to: this.DEFAULT_TO_VALUE,
        selfPaced: this.DEFAULT_SELFPACED_VALUE
      },
      { emitEvent: false }
    );
    // @ts-expect-error TS2322
    this.fromDate = null;
    this.toDate = null;
    this.hoveredDate = null;
    this.form.markAsPristine();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getValues() {
    return this.form.value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setIsoDateToCalendar(isoString: string) {
    const date = this.fromIsoToNgbDate(isoString);
    if (date) {
      this.datepicker.focusDate(date);
      this.datepicker.focusSelect();
    }
  }

  fromIsoToNgbDate(isoString: string): NgbDate | null {
    const date = DateTime.fromISO(isoString);
    return date.isValid ? this.fromDateTimeToNgbDate(date) : null;
  }

  fromDateTimeToNgbDate(date: DateTime): NgbDate | null {
    return date ? new NgbDate(date.year, date.month, date.day) : null;
  }

  fromNgbDateToDateTime(ngbDate: NgbDate): DateTime | null {
    if (!ngbDate) {
      return null;
    }
    const { day, month, year } = ngbDate;
    return DateTime.fromObject({ day, month, year });
  }

  fromNgbDateToIsoDate(ngbDate: NgbDate): string | null {
    // @ts-expect-error TS2531
    return ngbDate ? this.fromNgbDateToDateTime(ngbDate).toISO() : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  navigate(number: number) {
    const { state, calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  today() {
    const { calendar } = this.datepicker;
    const today = calendar.getToday();
    this.datepicker.navigateTo(today);
    this.datepicker.focusDate(today);
    this.datepicker.focusSelect();
    this.fromDate = today;
    this.toDate = null;
    this.form.patchValue({
      from: this.fromNgbDateToIsoDate(this.fromDate),
      // @ts-expect-error TS2345
      to: this.fromNgbDateToIsoDate(this.toDate)
    });
    this.form.markAsDirty();
    this.selectItem.emit(this.getValues());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else if (this.fromDate && this.toDate && this.isInside(date)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.form.patchValue({
      from: this.fromNgbDateToIsoDate(this.fromDate),
      // @ts-expect-error TS2345
      to: this.fromNgbDateToIsoDate(this.toDate)
    });

    this.form.markAsDirty();

    this.selectItem.emit(this.getValues());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSelfPacedChange() {
    this.selectItem.emit(this.getValues());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isFrom(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isTo(date: NgbDate) {
    return date.equals(this.toDate);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  afterFrom(date: NgbDate) {
    return date && this.fromDate && date.after(this.fromDate);
  }
}
