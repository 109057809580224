import { Pipe, PipeTransform } from '@angular/core';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';

@Pipe({
  name: 'sessionPlatform'
})
export class SessionPlatformPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _platformName;

  constructor(private readonly _runtimeConfigService: RuntimeConfigService) {
    this._platformName = this._runtimeConfigService.get('platformName');
  }

  transform(connectionType: SessionConnectionTypes, address?: string | null): string {
    if (!connectionType) {
      return '';
    }

    switch (connectionType) {
      case SessionConnectionTypes.IN_PLATFORM:
        return `${this._platformName}`;
      case SessionConnectionTypes.ZOOM:
        return `Zoom`;
      case SessionConnectionTypes.PHONE:
        return ` Phone call`;
      case SessionConnectionTypes.IN_PERSON:
        return address || ` In-person`;
      default:
        return `${this._platformName}`;
    }
  }
}
