import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CallInitiators } from '@app/shared/enums/call-initiators';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { SessionTypes } from '@app/shared/enums/session-types';
import { IServerSessionGuide, SimpleSession } from '@app/shared/interfaces/session';

import { IClientGuideSession } from '../../types/types';

@Component({
  selector: 'app-client-dashboard-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionDetailsComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _declinedSessionStatuses = [
    SessionStatuses.CANCELLED,
    SessionStatuses.DECLINED,
    SessionStatuses.GUIDE_OFFER_CANCELLED,
    SessionStatuses.GUIDE_OFFER_DECLINED
  ];

  @Input()
  clientSession!: IClientGuideSession;

  SessionConnectionTypes = SessionConnectionTypes;
  SessionTypes = SessionTypes;
  CallInitiators = CallInitiators;

  get isSessionDeclined(): boolean {
    return (
      this.clientSession != null &&
      this.clientSession.session != null &&
      this._declinedSessionStatuses.some(status => status === this.clientSession.session.status)
    );
  }

  get showConnectionType(): boolean {
    return [SessionConnectionTypes.IN_PERSON, SessionConnectionTypes.PHONE].includes(
      this.clientSession.session.connectionType
    );
  }

  // TODO: Use pui-icon component instead of this
  get sessionClasses(): { [index: string]: string } {
    return {
      [SessionStatuses.APPROVED]: 'session-confirmed',
      [SessionStatuses.CANCELLED]: 'session-cancelled',
      [SessionStatuses.DECLINED]: 'session-declined',
      [SessionStatuses.PENDING_APPROVEMENT]: 'session-pending',
      [SessionStatuses.GUIDE_OFFER]: 'session-awaiting-confirmation',
      [SessionStatuses.GUIDE_OFFER_CANCELLED]: 'session-cancelled',
      [SessionStatuses.GUIDE_OFFER_DECLINED]: 'session-declined',
      [SessionStatuses.DONE]: 'session-completed',
      [SessionStatuses.RESCHEDULE_BY_CLIENT]: 'session-pending',
      [SessionStatuses.IN_PROGRESS_OLD]: 'session-in-progress',
      [SessionStatuses.RESCHEDULE_BY_GUIDE]: 'session-awaiting-confirmation'
    };
  }

  get shouldShowDeclineSection(): boolean {
    return this.isSessionDeclined && !!this.clientSession?.session?.declineReason?.reason;
  }

  get session(): SimpleSession | null {
    return this.clientSession?.session ?? null;
  }

  get host(): IServerSessionGuide | undefined {
    // @ts-expect-error TS2322
    return this.clientSession.session?.guide;
  }

  get groupSessionOrInstance(): boolean {
    return (
      this.clientSession?.session?.serviceType === SessionTypes.GROUP_SESSION ||
      this.clientSession?.session?.serviceType === SessionTypes.GROUP_INSTANCE
    );
  }
}
