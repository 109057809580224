import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FactoryProvider, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspace } from '@app/modules/workspaces/types';
import { SessionTemplatePredefinedFields } from '@app/screens/guide/guide-sessions-templates/services';
import { GuideProfileTypes } from '@app/shared/enums/guide-profile-types';
import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import { SessionType } from '@app/shared/enums/session-type';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

import { CallInitiator, ServiceAssigneePermission, SessionTemplateInterface } from '../types';

export const DEFAULT_DURATION = 60;

export const DEFAULT_SESSION_TEMPLATE_VALUES = new InjectionToken<Observable<SessionTemplateInterface>>(
  'DefaultSessionTemplateValues'
);

export function defaultSessionTemplateValuesFactory(
  activatedRoute: ActivatedRoute,
  workspace: WorkspacesService
): Observable<SessionTemplateInterface> {
  return combineLatest([activatedRoute.data, workspace.activeWorkspace$]).pipe(
    map(
      ([data, workspace]: [
        data: { sessionTemplatePredefinedFields: SessionTemplatePredefinedFields },
        workspace: IWorkspace
      ]) => {
        const { sessionTemplatePredefinedFields } = data; // [ToDo] undefined when edit

        return {
          afterEventBuffer: sessionTemplatePredefinedFields?.afterEventBuffer || 0,
          availabilities: sessionTemplatePredefinedFields?.schedules || [],
          beforeEventBuffer: sessionTemplatePredefinedFields?.beforeEventBuffer || 0,
          bookingLimitsFrequency: null,
          coverImage: null,
          coverImageThumb: null,
          customAvailability: null,
          descriptionMarkup: '',
          descriptionRepresentation: '',
          descriptionText: '',
          disableGuests: true,
          duration: DEFAULT_DURATION,
          hasAutoConfirmation: false,
          hasChat: false,
          hidePrice: false,
          hosts: [],
          limitFutureBookings: null,
          location: {
            connectionType: SessionConnectionTypes.IN_PLATFORM,
            callInitiator: CallInitiator.CLIENT
          },
          minimumBookingNotice: sessionTemplatePredefinedFields?.minimumBookingNotice ?? 1,
          name: '',
          permission: ServiceAssigneePermission.OWNER,
          price: null,
          recurrence: null,
          removeFromChatOnSessionEnd: false,
          scheduleId: null,
          seatsPerTimeSlot: null,
          seatsShowAttendees: false,
          serviceType: GuideServiceTypes.GROUP_SESSION,
          sessionType: SessionType.PERSONAL,
          slotInterval: DEFAULT_DURATION,
          status: GuideProfileTypes.PRIVATE,
          testimonials: [],
          viewChatHistory: false,
          workspaceId: workspace?.id,
          restrictClientBooking: false
        };
      }
    )
  );
}

export const DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER: FactoryProvider = {
  provide: DEFAULT_SESSION_TEMPLATE_VALUES,
  deps: [ActivatedRoute, WorkspacesService],
  useFactory: defaultSessionTemplateValuesFactory
};
