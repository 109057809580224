import { IRecurrenceRule } from '@app/modules/guide-session/types';

function areOptionalValuesEqual<T>(v1: T, v2: T, comparer?: (val1: T, val2: T) => boolean): boolean {
  return (v1 == null && v2 == null) || (v1 != null && v2 != null && (comparer ? comparer(v1, v2) : v1 === v2));
}

// NOTE for small arrays only
function areOptionalArraysEqual<T>(v1: T[], v2: T[]): boolean {
  return (
    (v1 == null && v2 == null) || (v1 != null && v2 != null && v1.length === v2.length && v1.every(i => v2.includes(i)))
  );
}

export function areRecurrenceRulesEqual(rRule1: IRecurrenceRule, rRule2: IRecurrenceRule): boolean {
  if (rRule1 == null || rRule2 == null) {
    return rRule1 == null && rRule2 == null;
  }

  // @ts-expect-error TS7053
  // eslint-disable-next-line id-length
  const a = ['freq', 'count'].every(prop => areOptionalValuesEqual(rRule1[prop], rRule2[prop]));
  // @ts-expect-error TS7053
  // eslint-disable-next-line id-length
  const b = ['byday', 'bysetpos'].every(prop => areOptionalArraysEqual(rRule1[prop], rRule2[prop]));
  // eslint-disable-next-line id-length
  const c = areOptionalValuesEqual(
    rRule1.until,
    rRule2.until,
    (d1: string, d2: string) => new Date(d1).valueOf() === new Date(d2).valueOf()
  );

  return a && b && c;
}
