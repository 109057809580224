import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconStore {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  init(): Promise<void> {
    return new Promise<void>(resolve => {
      this.iconRegistry.addSvgIconSetInNamespace(
        'icons',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icons.svg')
      );

      this.iconRegistry.addSvgIcon(
        'chip-remove',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/chip-remove.svg')
      );

      this.iconRegistry.addSvgIcon('plus', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/plus.svg'));

      this.iconRegistry.addSvgIcon(
        'dropdown-arrow',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/select-dropdown-arrow.svg')
      );

      this.iconRegistry.addSvgIcon(
        'online-status',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Chat/online status.svg')
      );

      this.iconRegistry.addSvgIcon(
        'delete-small',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Delete/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'arrow-right',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/arrow/small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'duplicate',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Copy/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'embed',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Embed/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'share',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Share/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'kebab-menu',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Menu/Default.svg')
      );

      this.iconRegistry.addSvgIcon(
        'hamburger',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Menu/Hamburger.svg')
      );

      this.iconRegistry.addSvgIcon(
        'download',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Download/Default.svg')
      );

      this.iconRegistry.addSvgIcon('search', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/loupe.svg'));

      this.iconRegistry.addSvgIcon(
        'logout',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Logout/Default.svg')
      );

      this.iconRegistry.addSvgIcon(
        'message',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Message/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'message-filled',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Message/Filled.svg')
      );

      this.iconRegistry.addSvgIcon(
        'show-more',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Show more/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'show-more-filled',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Show more/Filled.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'no-camera-available',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/no camera available.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'no-mic-available',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/no mic available.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'chat',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/chat.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'end-call',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/end call.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'mic',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/mic.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'mic-cam-settings',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/mic cam settings.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'mic-off',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/mic off.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'notes',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/notes.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'participants',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/participants.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'record',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/record.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'share',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/share.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'stop-recording',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/stop recording.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'stop-sharing',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/stop sharing.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'video',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/video.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'video-off',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/video off.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'calls',
        'end-call',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Calls/end call.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'stepper',
        'current',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/stepper/current.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'stepper',
        'complete',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/stepper/complete.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'stepper',
        'incomplete',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/stepper/incomplete.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'add',
        'default',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Add/Default.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'participants',
        'default',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Participants/Default.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'chat',
        'emoji',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Chat/emoji.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'chat',
        'file',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Chat/file.svg')
      );
      this.iconRegistry.addSvgIconInNamespace(
        'chat',
        'send',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Chat/send.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'chats',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/chats.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'clients',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/clients.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'community',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/community.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'courses',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/courses.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'dashboard',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/dashboard.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'forms',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/forms.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'globe',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/globe.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'house',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/house.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'info',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/info.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'notifications',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/notifications.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'services',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/services.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'session room',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/session room.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'settings',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/settings.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'subscriptions',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/subscriptions.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'user',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/user.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'wallet',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/wallet.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'blog',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/blog.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'calendar',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/calendar.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'subscriptions',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/subscriptions.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'user',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/user.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'wallet',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/wallet.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'link',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/link.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'two-arrow-left',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/2 arrow left.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'one-arrow-left',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/1 arrow left.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'one-arrow-up',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/1 arrow up.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'one-arrow-down',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/1 arrow down.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'one-arrow-right',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/1 arrow right.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'two-arrow-right',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/2 arrow right.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'menu',
        'toggle',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/menu/toggle.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'notification',
        'alert',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/NotificationIcon/Alert.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'notification',
        'done',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/NotificationIcon/Done.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'clock',
        'default',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Clock/clock.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'export',
        'default',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/export-icon.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'info',
        'default',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/info.svg')
      );

      // Email

      this.iconRegistry.addSvgIconInNamespace(
        'emails',
        'people',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Emails/people.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'emails',
        'time',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Emails/time.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'emails',
        'place',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Emails/place.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'emails',
        'package',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Emails/Includes.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'emails',
        'date',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Emails/date.svg')
      );

      this.iconRegistry.addSvgIcon(
        'cancel',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Cancel/Small.svg')
      );

      this.iconRegistry.addSvgIcon(
        'warning',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/UI Icons/Warning/default.svg')
      );

      this.iconRegistry.addSvgIconInNamespace(
        'statuses',
        'pending',
        this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/Base icons/Status/Pending.svg')
      );

      resolve();
    });
  }
}
