import { NgModule } from '@angular/core';
import { ProposeFormsService } from './propose-forms.service';
import { ProposeFormsComponent } from './propose-forms.component';
import { ProposeFormsRoutingModule } from './propose-forms-routing.module';

@NgModule({
  declarations: [ProposeFormsComponent],
  providers: [ProposeFormsService],
  imports: [ProposeFormsRoutingModule]
})
export class ProposeFormsModule {}
