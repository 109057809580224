import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ILocale } from '@env/locale.interface';
import { LanguageService } from '@app/core/language/language.service';
import { Languages } from '@app/shared/enums/languages';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { WindowService } from '@app/core/window/window.service';
import { resolveRedirectUrl } from '@app/shared/utils/url';
import { take } from 'rxjs/operators';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  // @ts-expect-error TS2564
  availableLanguages: string[];

  // @ts-expect-error TS2564
  currentLanguage: string;

  // TODO: remove any
  languages = Languages as never;

  locale: ILocale;

  constructor(
    private readonly _languageService: LanguageService, // Todo: Should be merged with LocaleService
    private readonly _localStorageService: LocalStorageService,
    private readonly _localeService: LocaleService,
    private readonly _location: Location,
    private readonly _windowService: WindowService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.locale = this._localeService.getLocale();
  }

  ngOnInit(): void {
    this.setCurrentLanguage(this.locale.language);
  }

  private setCurrentLanguage(language: string): void {
    this.currentLanguage = language;
    // @ts-expect-error TS2532
    this.availableLanguages = this._runtimeConfigService
      .get('availableLanguages')
      .filter(lang => lang !== this.currentLanguage);
  }

  selectLanguage(lang: string): void {
    this._localStorageService.setItem('preferredLanguage', lang);
    this._languageService
      .saveSelectedLanguage(lang)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(
        () => {},
        () => {},
        () => {
          const redirectUrl = resolveRedirectUrl(
            lang,
            this._location.path(),
            // @ts-expect-error TS2345
            this._runtimeConfigService.get('defaultLanguage')
          );
          this._windowService.redirect(redirectUrl);
        }
      );
  }
}
