import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-wrong-system-time-modal',
  templateUrl: './wrong-system-time-modal.component.html',
  styleUrls: ['./wrong-system-time-modal.component.scss']
})
export class WrongSystemTimeModalComponent {
  constructor(private _modal: NgbActiveModal, @Inject(GLOBAL_WINDOW) private _browserWindow: Window) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  reload() {
    this._browserWindow.location?.reload();
  }

  dismiss(): void {
    this._modal.dismiss();
  }
}
