import { NgModule } from '@angular/core';
import { ExternalLinkComponent } from '@app/modules/external-link/external-link.component';
import { CommonModule } from '@angular/common';
import { PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';

@NgModule({
  imports: [CommonModule, PuiTypographyModule, PuiIconModule],
  declarations: [ExternalLinkComponent],
  exports: [ExternalLinkComponent]
})
export class ExternalLinkModule {}
