import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { IWorkspaceMember, MemberRoleEnum } from '@app/modules/workspaces/types';
import { customValidatorWrapper } from '@app/screens/guide/guide-profile/components/guide-edit-profile/form-validators/custom-validator-wrapper';
import { ServiceAssigneePermission, SessionTemplateHost } from '@app/screens/guide/guide-sessions-templates/types';
import { SessionType } from '@app/shared/enums/session-type';
import { PuiDestroyService, PuiDrawerConfig, PuiDrawerService } from '@awarenow/profi-ui-core';

import { AddHostsDrawerComponent } from '../../drawers/add-hosts-drawer/add-hosts-drawer.component';

export const sessionsTypes = [
  {
    title: 'Personal',
    value: SessionType.PERSONAL,
    description: `A client will book a 1:1 or a group session choosing a specific host while booking.`,
    icon: 'pui:select-user'
  },
  // {
  //   title: 'Collective',
  //   value: SessionType.COLLECTIVE,
  //   description: `A client will book a session with all hosts of that session.`
  // },
  {
    title: 'Round-robin and personal',
    value: SessionType.ROUND_ROBIN,
    description: `A client will book a session with a rotating host or choose a specific host while booking.`,
    icon: 'pui:cycle'
  }
];

export interface HostsForm {
  sessionType: SessionType;
  hosts: {
    selectedHosts: SessionTemplateHost[];
    search: string;
    hostsById: {};
  };
}

export const DRAWER_CONFIG: PuiDrawerConfig = {
  position: 'right',
  maxWidth: '600px'
};

@Component({
  selector: 'app-hosts-form',
  templateUrl: './hosts-form.component.html',
  styleUrls: ['./hosts-form.component.scss'],
  providers: [
    PuiDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HostsFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HostsFormComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostsFormComponent implements ControlValueAccessor, Validator {
  form = this.fb.group({
    sessionType: [SessionType.PERSONAL],
    hosts: [
      null,
      [
        customValidatorWrapper(control => {
          const { selectedHosts }: { selectedHosts: unknown[] | null } = control.value || {};

          if (!selectedHosts?.length) {
            return {
              required: true
            };
          }

          return null;
        }, `Hosts required. Please add at least one host.`)
      ]
    ]
  });

  sessionsTypes = sessionsTypes;

  constructor(
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private readonly drawer: PuiDrawerService,
    private readonly fb: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  onTouched: () => void = () => {};

  writeValue(value: HostsForm): void {
    if (value) {
      this.form.setValue(
        {
          ...value,
          hosts: {
            ...value.hosts,
            selectedHosts: value.hosts?.selectedHosts
          }
        },
        { emitEvent: true, onlySelf: true }
      );
    }
  }

  registerOnChange(fn: (value: { [key: string]: unknown }) => void): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      fn(value);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.form.get('hosts')?.errors || null;
  }

  openDrawer(): void {
    const hosts = this.form.value?.hosts;
    const ref = this.drawer.open<IWorkspaceMember[]>(AddHostsDrawerComponent, DRAWER_CONFIG, {
      hosts
    });

    ref.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe((selectedHosts = []) => {
      if (selectedHosts.length) {
        this.form.get(['hosts'])?.patchValue({
          ...hosts,
          selectedHosts: selectedHosts.map(host => ({
            ...host,
            permission: this.calcPermission(host)
          }))
        });
      }

      this.cdRef.detectChanges();
    });
  }

  removeMember(host: SessionTemplateHost): void {
    const onlySelected = this.form
      .get('hosts')
      ?.value.selectedHosts.filter(({ userId }: Pick<SessionTemplateHost, 'userId'>) => userId !== host.userId);

    this.form.get('hosts')?.patchValue({ selectedHosts: onlySelected });
  }

  private calcPermission(member: IWorkspaceMember): ServiceAssigneePermission {
    const hosts: SessionTemplateHost[] = this.form.value?.hosts?.selectedHosts;

    const host = hosts.find(host => host.userId === member.userId);

    if (host?.permission) {
      return host.permission;
    }

    return member.role === MemberRoleEnum.ADMIN ? ServiceAssigneePermission.OWNER : ServiceAssigneePermission.PROVIDER;
  }
}
