import { RELATIONS_STORE } from './commons/consts';
import { createAction, props } from '@ngrx/store';

export const fetchRelations = createAction(`${RELATIONS_STORE} Fetch`);
export const fetchRelationsSuccess = createAction(
  `${RELATIONS_STORE} Fetch success`,
  props<{ relations: unknown[] }>()
);
export const fetchRelationsError = createAction(`${RELATIONS_STORE} Fetch error`);

export const resetAll = createAction(`${RELATIONS_STORE} Reset`);
