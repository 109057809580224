import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { IContact } from '@app/core/users/types';
import { GuideContactsDetails, IGuideContactDetails, IGuideContactsStore } from '../types';

@Injectable()
export class GuideContactsServerStoreService implements IGuideContactsStore {
  readonly API_ENDPOINT = `${config.apiPath}/user/guide/relations/contacts`;

  constructor(private _http: HttpClient) {}

  addContacts$(contactsDetails: GuideContactsDetails): Observable<IGuideContactDetails[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(this.API_ENDPOINT, { contactsDetails });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateContact$(id: number, values: Partial<IContact>): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.put<any>(`${this.API_ENDPOINT}/${id}`, { values });
  }
}
