import { Pipe, PipeTransform } from '@angular/core';
import { Avatar } from '@awarenow/profi-ui-core/lib/components/avatar/pui-avatar.component';

/**
 * Example of usage
 * <avatar-group [items]="items | toAvatarGroup: 'name':'url'"></avatar-group>
 */
@Pipe({
  name: 'toAvatarGroup'
})
export class ToAvatarGroupPipe implements PipeTransform {
  transform(items: Object[], text: string, avatarUrl: string): Avatar[] {
    if (!items) {
      return [];
    }

    return items.map(subject => ({
      // @ts-expect-error TS7053
      text: subject[text],
      // @ts-expect-error TS7053
      avatarUrl: subject[avatarUrl]
    }));
  }
}
