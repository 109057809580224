import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  private readonly googleMapsUrl = 'https://maps.google.com/';

  generateGoogleMapsLink(query: string): string {
    let link = this.googleMapsUrl;

    if (query) {
      link = `${link}?q=${query}`;
    }

    return link;
  }
}
