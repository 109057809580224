import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, fromEvent, Subject, timer, of, merge } from 'rxjs';
import { auditTime, catchError, map, mergeMap } from 'rxjs/operators';
import { VERSION } from '@cnf/version';
import { isPlatformBrowser } from '@angular/common';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { DateTime } from 'luxon';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
// import { locale } from '@env/locale';

@Injectable({
  providedIn: 'root'
})
export class SystemVersionService {
  private readonly newVersion = new Subject();
  private remindLater = false;
  locale: ILocale;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get newVersion$(): Observable<any> {
    return this.newVersion.asObservable();
  }

  constructor(
    private _http: HttpClient,
    private _localeService: LocaleService,
    private readonly _runtimeConfigService: RuntimeConfigService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(GLOBAL_WINDOW) private window: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) platformId: any
  ) {
    if (this._runtimeConfigService.get('versionCheckEndpoint') && isPlatformBrowser(platformId) && this.window) {
      this.window.addEventListener('load', () => {
        this.runVerChecker();
      });
    }

    this.locale = this._localeService.getLocale();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  remindAfter(hours: number) {
    this.remindLater = true;
    timer(1000 * 60 * 60 * hours).subscribe(() => (this.remindLater = false));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private runVerChecker() {
    // check version only if user is active
    merge(
      fromEvent(this.window.document, 'scroll'),
      fromEvent(this.window.document, 'wheel'),
      fromEvent(this.window.document, 'touchstart'),
      fromEvent(this.window.document, 'mousedown'),
      fromEvent(this.window.document, 'mousemove'),
      fromEvent(this.window.document, 'keydown')
    )
      .pipe(
        auditTime(60000),
        mergeMap(() =>
          this._http
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .get<any>(
              `${this.locale.baseUrl}${this._runtimeConfigService.get(
                'versionCheckEndpoint'
              )}/${VERSION}?t=${DateTime.local().toMillis().toString(36)}`,
              { observe: 'response' }
            )
            .pipe(
              map(resp => resp.status === 205),
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              catchError(resp => of(false))
            )
        )
      )
      .subscribe((newVersion: boolean) => {
        if (newVersion && !this.remindLater) {
          this.newVersion.next();
        }
      });
  }
}
