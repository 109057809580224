import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetPaymentDateComponent } from './set-payment-date.component';
import {
  PuiAlertModule,
  PuiButtonModule,
  PuiDrawerContentModule,
  PuiIconModule,
  PuiInputDateModule,
  PuiInputModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SetPaymentDateComponent],
  imports: [
    CommonModule,
    PuiButtonModule,
    ReactiveFormsModule,
    PuiInputDateModule,
    PuiTypographyModule,
    PuiInputModule,
    PuiAlertModule,
    PuiIconModule,
    PuiDrawerContentModule
  ]
})
export class SetPaymentDateModule {}
