import { SessionConnectionTypes } from '@app/shared/enums/session-connection-types';
import {
  ClientSessionAttributeOptions,
  ClientSessionAttributesEnum,
  ClientSessionConnectionTypeAttribute
} from '@libs/business/client/client-session/modules';
import { getConnectionTypes } from '@libs/utils/location/location';

export const clientSessionConnectionTypeAttribute = ({
  platformName,
  connectionType,
  address,
  phoneForSessions,
  callInitiator,
  name,
  hideAddress,
  eventUuid
}: ClientSessionAttributeOptions): ClientSessionConnectionTypeAttribute | null => {
  if (!connectionType) {
    return null;
  }

  const attributeName = ClientSessionAttributesEnum.CONNECTION_TYPE;
  const response = getConnectionTypes({
    platformName,
    connectionType,
    address,
    phoneForSessions,
    callInitiator,
    name,
    hideAddress,
    eventUuid
  });

  if (!response) {
    return null;
  }

  return {
    type: attributeName,
    leftIcon: response.icon,
    label: response.label,
    labelRouterLink: response.url,
    showTooltip: connectionType === SessionConnectionTypes.PHONE,
    tooltipText: response.label
  };
};
