import { SessionTypes } from '@app/shared/enums/session-types';
import { SessionsTypes } from '@app/shared/enums/sessions-types';
import {
  AvailableSession,
  GroupSession,
  IServerAvailableSession,
  IServerSession,
  Session,
  SessionClient,
  SessionGuide,
  SimpleSession
} from '@app/shared/interfaces/session';

function buildSessionUser(serverSession: IServerSession): SessionGuide | SessionClient {
  const { client, guide } = serverSession;

  if (client) {
    return new SessionClient(client);
  }

  if (guide) {
    return new SessionGuide(guide);
  }

  throw new Error('Unknown session user');
}

function getClient(serverSession: IServerSession): SessionClient | null {
  const { client } = serverSession;

  return client ? new SessionClient(client) : null;
}

function getGuide(serverSession: IServerSession): SessionGuide | null {
  const { guide } = serverSession;

  return guide ? new SessionGuide(guide) : null;
}

export function convertServerSession(serverSession: IServerSession, collectionType?: SessionsTypes): Session {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { date, free, client, guide, hidePrice, ...sessionDetails } = serverSession;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).dateStart = date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).isSessionFree = !!free;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).hidePrice = hidePrice;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).collectionType = collectionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).guide = guide;

  if (sessionDetails.serviceType === SessionTypes.GROUP_SESSION) {
    if (sessionDetails.sessions && sessionDetails.sessions.length) {
      sessionDetails.sessions = sessionDetails.sessions.map(innerSession => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { client: clientAccount, guide: guideAccount, ...groupSessionInstance } = innerSession;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).dateStart = date;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).isSessionFree = !!free;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).collectionType = collectionType;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).user = buildSessionUser(innerSession);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).client = getClient(serverSession);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).guide = getGuide(serverSession);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (groupSessionInstance as any).declineData = innerSession.declineData;

        return groupSessionInstance;
      });
    }

    return new GroupSession(sessionDetails);
  }

  if (
    sessionDetails.serviceType === SessionTypes.SESSION ||
    sessionDetails.serviceType === SessionTypes.GROUP_INSTANCE
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (sessionDetails as any).user = buildSessionUser(serverSession);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (sessionDetails as any).client = getClient(serverSession);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (sessionDetails as any).guide = getGuide(serverSession);
    return new SimpleSession(sessionDetails);
  }

  throw new Error('Session type was not recognised');
}

export function convertServerSessions(serverSessions: IServerSession[], collectionType: SessionsTypes): Session[] {
  return serverSessions.map(serverSession => convertServerSession(serverSession, collectionType));
}

export function convertAvailableServerSessions(serverSessions: IServerAvailableSession[]): AvailableSession[] {
  return serverSessions
    .map(({ template, ...serverSession }) => {
      if (serverSession.availableSessions < 1) {
        return [];
      }

      return new Array(serverSession.availableSessions).fill(
        new AvailableSession({
          ...serverSession,
          name: template.name,
          restrictClientBooking: template.restrictClientBooking,
          serviceType: template.serviceType,
          duration: template.duration,
          workspace: template.workspace,
          sessionType: template.sessionType?.properties
        })
      );
    })
    .flat();
}

// @ts-expect-error TS7006
export function convertAfterSessionResult(serverSession: IServerSession, guide): SimpleSession {
  const { date, free, ...sessionDetails } = serverSession;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).dateStart = date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).isSessionFree = !!free;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (sessionDetails as any).user = new SessionGuide(guide);

  return new SimpleSession(sessionDetails);
}
