import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { startWith, takeUntil } from 'rxjs/operators';
import { MatAutocompleteOrigin, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UiChipListInput } from '@app/modules/ui-kit/_base/ui-chips-list/chip-list-input';
import { UiAutocomplete } from '@app/modules/ui-kit/_base/ui-autocomplete/autocomplete';
import { UiOption } from '@app/modules/ui-kit/_base/ui-option/option';
import { UiChipList } from '@app/modules/ui-kit/_base/ui-chips-list/chip-list';
import { PHRASES } from '../_common/localize';
import { AppFormFieldControl } from '@app/modules/ui-kit/form/components/form-field/form-field.component';

@Component({
  selector: 'ui-selector-multiple',
  templateUrl: 'template.html',
  styleUrls: ['selector.scss', '_select-all.scss', '../ui-option/option.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-selector'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => UiSelectorMultiple) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiSelectorMultiple implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  readonly text = PHRASES;

  typedValue = '';

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _selectionModel: SelectionModel<MatOption>;

  private readonly destroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  @Input() required = false;

  @Input() multiple = true;

  @Input() placeholder!: string;

  @Input('aria-orientation') ariaOrientation: 'horizontal' | 'vertical' = 'horizontal';

  @Input() errorStateMatcher!: ErrorStateMatcher;

  @Input() ariaLabel = '';

  @Input() tabIndex!: number;

  @Input() separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() pseudoCheckboxDisabled = false;

  @Input() overlayPanelClassName = '';

  @Input() autocompleteConnectedTo: MatAutocompleteOrigin | undefined;

  @Input() autocompleteDisabled = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() optionTemplateRef: TemplateRef<any> | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() emptyListTemplateRef: TemplateRef<any> | undefined;

  @Output() typed: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() inputTokenEnd: EventEmitter<string> = new EventEmitter<string>();

  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(UiChipListInput, { static: true }) chipListInput: UiChipListInput | undefined;

  @ViewChild(UiChipList) chipList: UiChipList | undefined;

  @ContentChildren(UiOption) optionsList: QueryList<UiOption> | undefined;

  focused = true;

  get autocomplete(): UiAutocomplete | undefined {
    return this.chipListInput?.autocomplete;
  }

  get options(): UiOption[] {
    return this._options;
  }

  set options(value: UiOption[]) {
    this._options = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _options: UiOption[] = [];

  @Input()
  get value(): unknown[] {
    return this._value;
  }

  set value(newValue: unknown[]) {
    if (newValue !== this._value || (this.multiple && Array.isArray(newValue))) {
      this._onChange(newValue);
      this._onTouched();
      this._value = newValue;
      // @ts-expect-error TS2532
      this.chipList.markForCheck();
      if (!this.autocompleteDisabled) {
        this._initializeSelection();
      }
      this.valueChanged.emit(this._value);
    }
  }

  // @ts-expect-error TS2322
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value: unknown[] = null;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? !!this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _disabled = false;

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get compareWith() {
    return this._compareWith;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (!fn) {
      return;
    }

    this._compareWith = fn;
    if (this._selectionModel && !this.autocompleteDisabled) {
      this._initializeSelection();
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _compareWith = (o1: any, o2: any) => o1 === o2;

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getChipLabel() {
    return this._getChipLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getChipLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getChipLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getChipLabel = (option: any) => option?.label ?? '';

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getOptionLabel() {
    return this._getOptionLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getOptionLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getOptionLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getOptionLabel = (option: any) => option?.label ?? '';

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  _onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private _changeDetectorRef: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<MatOption>(this.multiple);
    this.stateChanges.next();
  }

  ngAfterViewInit(): void {
    if (!this.autocompleteDisabled) {
      this._initSubscriptionsOnModelAndOptions();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
  }

  onInputTokenEnd(value: string): void {
    this.typedValue = '';
    this.inputTokenEnd.emit(value);
  }

  onToggleOption(event: MatAutocompleteSelectedEvent): void {
    const { option } = event;
    if (!option.value) {
      return;
    }

    let selected;
    if (this.multiple) {
      if (option.selected) {
        selected = [...(this.value || []), option.value];
      } else {
        // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
        selected = this.value?.filter((o: any) => !this._compareWith(o, option.value));
      }
    } else {
      selected = option?.value;
    }

    if (this.autocomplete?.allSelected) {
      // @ts-expect-error TS2532
      this.chipListInput.reset();
    }

    this.value = selected;
    this._updateDropdownPosition();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  onInputValueChange(value: any) {
    if (typeof value === 'string') {
      this.typedValue = value;
      this.typed.emit(value);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  onChipRemove(chipValue: any) {
    const prev = this.value;

    if (!this.autocompleteDisabled) {
      this._selectionModel.clear();
      this._updateDropdownPosition();
    }

    // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
    this.value = prev.filter((o: any) => !this._compareWith(o, chipValue));
  }

  onToggleAll(): void {
    const prev = this.value ?? [];
    let newValue;

    if (this.autocomplete?.allSelected) {
      this._selectionModel.clear();
      newValue = this._difference(prev, this.autocomplete?.optionsValues ?? []);
    } else {
      this._selectionModel.clear();
      newValue = [...prev, ...this._difference(this.autocomplete?.optionsValues ?? [], prev)];
    }

    // @ts-expect-error TS2532
    this.chipListInput.reset();

    this.value = newValue;
    this._updateDropdownPosition();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initSubscriptionsOnModelAndOptions(): void {
    this._selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe(event => {
      /** It is necessary to prevent multiple dispatch of value change events. */
      this.autocomplete?.disableEvents();
      event.added.forEach(option => !!option.value && option.select());
      event.removed.forEach(option => !!option.value && option.deselect());
      this.autocomplete?.enableEvents();
    });

    this.chipListInput?.autocomplete?.options?.changes
      ?.pipe(startWith(null), takeUntil(this.destroy$))
      .subscribe(() => {
        this._initializeSelection();
      });

    this.optionsList?.changes?.pipe(startWith(null), takeUntil(this.destroy$)).subscribe(() => {
      // @ts-expect-error TS2532
      this.options = this.optionsList.toArray();
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _setSelectionByValue(value: any | any[]): void {
    this._selectionModel.selected.forEach(option => option.setInactiveStyles());

    if (this.multiple && value) {
      if (!Array.isArray(value)) {
        throw Error('value must be an array type');
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value.forEach((currentValue: any) => this._selectValue(currentValue));
    } else {
      const correspondingOption = this._selectValue(value);

      if (correspondingOption) {
        this.chipListInput?.autocomplete?._keyManager?.updateActiveItem(correspondingOption);
      } else if (!this.chipListInput?.autocomplete.isOpen) {
        this.chipListInput?.autocomplete?._keyManager?.updateActiveItem(-1);
      }
    }

    this._changeDetectorRef.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _selectValue(value: any): MatOption | undefined {
    const correspondingOption = this._findOption(value);

    if (correspondingOption) {
      this._selectionModel.select(correspondingOption);
    }

    return correspondingOption;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initializeSelection(): void {
    Promise.resolve().then(() => {
      this._setSelectionByValue(this._value);
      this.stateChanges.next();
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _findOption(value: any): MatOption | undefined {
    return this.chipListInput?.autocomplete?.options?.find((option: MatOption) => {
      try {
        return option?.value != null && this._compareWith(option?.value, value);
      } catch (error) {
        return false;
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateDropdownPosition(): void {
    setTimeout(() => this.chipListInput?.trigger?.updatePosition());
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _difference(arrA: any[], arrB: any[]): any[] {
    return arrA?.filter(x => !arrB?.find(y => this._compareWith(x, y))) ?? [];
  }

  click(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // @ts-expect-error TS2532
    setTimeout(() => this.chipListInput.trigger.openPanel());
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isItemRemovable(value) {
    return value.removable !== undefined ? value.removable : true;
  }
}
