import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { CancelSessionModalComponent } from './components/cancel-session-modal/cancel-session-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, SharedModule, PlatformStyleModule],
  declarations: [CancelSessionModalComponent],
  exports: [CancelSessionModalComponent]
})
export class SessionCancellationModule {}
