import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';

import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { CancelPlanModalComponent } from '@app/modules/platform-subscriptions/components/cancel-plan-modal/cancel-plan-modal.component';
import { ConfirmPlanSwitchingModalComponent } from '@app/modules/platform-subscriptions/components/confirm-plan-switching-modal/confirm-plan-switching-modal.component';
import { PlatformSubscriptionComponent } from './platform-subscription/platform-subscription.component';
import {
  PuiButtonModule,
  PuiInputModule,
  PuiLinkModule,
  PuiModalModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { CancelPlatformPlanModalComponent } from '@app/modules/platform-subscriptions/components/cancel-platform-plan-modal/cancel-platform-plan-modal.component';
import { CancelKnowWhyModalComponent } from '@app/modules/platform-subscriptions/components/cancel-know-why-modal/cancel-know-why-modal.component';
import { CanceledPlatformPlanModalComponent } from '@app/modules/platform-subscriptions/components/canceled-platform-plan-modal/canceled-platform-plan-modal.component';
import { SubscribedPlatformPlanModalComponent } from '@app/modules/platform-subscriptions/components/subscribed-platform-plan-modal/subscribed-platform-plan-modal.component';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    PlatformStyleModule,
    CurrentPaymentModule,
    PuiTypographyModule,
    PuiModalModule,
    PuiInputModule,
    PuiButtonModule,
    PuiLinkModule,
    TranslocoRootModule
  ],
  declarations: [
    CancelPlanModalComponent,
    ConfirmPlanSwitchingModalComponent,
    PlatformSubscriptionComponent,
    CancelPlatformPlanModalComponent,
    CancelKnowWhyModalComponent,
    CanceledPlatformPlanModalComponent,
    SubscribedPlatformPlanModalComponent
  ],
  exports: [
    CancelPlanModalComponent,
    ConfirmPlanSwitchingModalComponent,
    PlatformSubscriptionComponent,
    CancelPlatformPlanModalComponent,
    CancelKnowWhyModalComponent,
    CanceledPlatformPlanModalComponent,
    SubscribedPlatformPlanModalComponent
  ],
  providers: []
})
export class PlatformSubscriptionsModule {}
