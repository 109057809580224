import { Component, Inject, Input } from '@angular/core';
import { environment } from '@env/environment';
import { IProduct } from '@app/screens/our-community/interfaces';
import { PUI_IS_MOBILE } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {
  guidesRoute = environment.guidesRoute;

  @Input()
  product!: IProduct;

  constructor(@Inject(PUI_IS_MOBILE) readonly isMobile$: Observable<boolean>) {}
}
