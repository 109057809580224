<section class="section form-section row" [ngClass]="{ 'not-standard': !standard }">
  <div class="col-md-3 offset-md-2 padding-right-md-05">
    <h3 class="section-title" i18n="{{i18nTitle}}">{{ title }}</h3>

    <div class="section-note" i18n="{{i18nNote}}">
      {{ note }}
    </div>
  </div>

  <div class="col-md-6">
    <ng-content select="[configuration-content]"></ng-content>
  </div>
</section>
