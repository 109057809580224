import { SimpleSession } from '@app/shared/interfaces/session';
import { Bookings } from '@appWidget/modules/booking/interfaces';

export enum CancellationType {
  CURRENT = 'current',
  RECURRING = 'recurring'
}

export interface CancellationConfirmationData {
  session: SimpleSession;
  bookings?: Bookings[];
  cancellationType: CancellationType;
}
