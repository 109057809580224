import { environment } from '@env/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/auth/services/auth.service';
import { Router } from '@angular/router';
import { UserRoles } from '@app/shared/enums/user-roles';
import { BrandingService } from '@app/core/branding/branding.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-platform-subscription-common',
  template: ''
})
export class PlatformSubscriptionCommonComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  constructor(private authService: AuthService, private router: Router, private brandingService: BrandingService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(({ homePageRoute }) => {
      let navigateUrl;
      switch (this.authService.user.RoleId) {
        case UserRoles.CLIENT:
          navigateUrl = '/client/settings/subscription';
          break;
        case UserRoles.GUIDE:
          navigateUrl = `/${environment.guideRoute}/settings/subscription`;
          break;
        default:
          navigateUrl = homePageRoute;
          break;
      }
      this.router.navigate([navigateUrl], { replaceUrl: true });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
