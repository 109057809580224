import { combineLatest, Observable, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticCopyTypes,
  AnalyticServiceTypes,
  AnalyticSourceTypes,
  InternalEvents
} from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { FormService } from '@app/core/form/form.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { IPublicProgram } from '@app/modules/program/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { WorkspaceUtility } from '@app/modules/workspaces/utils';
import { confirmChangesForNewUsersOnly } from '@app/screens/admin/decorators/confirm-changes-for-new-users-only';
import { makeUriFromString } from '@app/screens/blog/utils';
import { ServiceAssigneePermission } from '@app/screens/guide/guide-sessions-templates/types';
import { SaveOnRouteChanged } from '@app/screens/guide/guide-shared/interfaces';
import { ScrollToFirstInvalidDirective } from '@app/shared/directives/scroll-to-first-invalid.directive';
import { ClientProgram } from '@app/shared/interfaces/programs/client-programs';
import { normalizeFullName } from '@app/shared/utils/full-name';
import { PuiDestroyService, PuiDialogService, PuiDrawerService } from '@awarenow/profi-ui-core';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GuideProfileService } from '../../services/guide-profile.service';
import { GuideProgramClientsService } from '../../services/guide-program-clients.service';
import { GuideProgramOptionsService } from '../../services/guide-program-options.service';
import { GuideProgramStateService } from '../../services/guide-program-state.service';
import { ProgramCoauthorsService } from '../../services/program-coauthors.service';
import { ProgramInstructorsServicesService } from '../../services/program-instructors-services.service';
import {
  IProgramAuthor,
  ProgramAccessRoles,
  ProgramContent,
  ProgramModule,
  ProgramQuestionnaire,
  ProgramSettings
} from '../../types';
import { createProgramContentPreview, createProgramLandingPreview } from '../../utils';
import { convertUnsavedModuleIntoModule } from '../../utils/converters';
import { ProgramContentPreviewModalComponent } from '../program-content-preview-modal/program-content-preview-modal.component';
import { ProgramFormBaseComponent } from '../program-forms/program-form-base.component';
import { ProgramModulesPreviewModalComponent } from '../program-modules-preview-modal/program-modules-preview-modal.component';
import { ISelectedModule } from '../program-modules/program-modules.component';

enum EditorTabs {
  Clients = 'clients',
  PublicTab = 'public',
  Content = 'content',
  Settings = 'settings',
  Forms = 'questionnaires',
  Sharing = 'sharing',
  Assignees = 'assignees'
}

@Component({
  selector: 'app-program-editor',
  templateUrl: './program-editor.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/program.scss',
    './program-editor.component.scss'
  ],
  providers: [
    GuideProgramStateService,
    GuideProgramClientsService,
    GuideProfileService,
    ProgramCoauthorsService,
    ProgramInstructorsServicesService,
    PuiDestroyService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramEditorComponent extends ProgramFormBaseComponent implements OnInit, OnDestroy, SaveOnRouteChanged {
  programId: number | undefined;

  author: IProgramAuthor | undefined;

  private permission?: ServiceAssigneePermission;

  programTitle: string | undefined;

  private selectedModule: ISelectedModule | undefined;

  allCustomersCount = 0;

  accessRole!: ProgramAccessRoles;

  subscriptionInfo: {
    subscribersNumber: number;
    subscriptionDeactivated: boolean;
    subscriptionDeactivatedDate: string | null;
  } = {
    subscribersNumber: 0,
    subscriptionDeactivated: false,
    subscriptionDeactivatedDate: null
  };

  private tabCloseActions: Record<
    EditorTabs.PublicTab | EditorTabs.Content | EditorTabs.Settings | EditorTabs.Forms | string,
    () => boolean
  > = {
    public: () => this.updatePublicInfo(),
    content: () => this.updateContent(),
    settings: () => this.updateSettings(),
    questionnaires: () => this.updateQuestionnaires()
  };

  private windowUnloadActions: Record<
    EditorTabs.PublicTab | EditorTabs.Content | EditorTabs.Settings | EditorTabs.Forms | string,
    () => boolean
  > = {
    public: () => this.canUnloadPublicInfo(),
    content: () => this.canUnloadContent(),
    settings: () => this.canUnloadSettings(),
    questionnaires: () => this.updateQuestionnaires()
  };

  private guideRoute = environment.guideRoute;

  private imageRemoved = false;

  @ViewChild(ScrollToFirstInvalidDirective, { static: false })
  invalidContentElementScroll: ScrollToFirstInvalidDirective | undefined;

  baseUrl: string;

  readonly ProgramAccessRoles: typeof ProgramAccessRoles = ProgramAccessRoles;

  get assigneesTabVisible$(): Observable<boolean> {
    return this.workspaceService.isTeamAdmin$;
  }

  get isTeamAdmin(): boolean {
    return this.workspaceService.isAdmin;
  }

  get authorId(): number | undefined {
    return this.author?.id;
  }

  get tabIsEdited(): boolean {
    return this.formsIsEdited();
  }

  get tabIsValid(): boolean {
    return this.formsIsValid();
  }

  get canUnloadWindow(): boolean {
    const windowUnloadAction = this.windowUnloadActions[this.selectedTabId];
    return !windowUnloadAction || windowUnloadAction();
  }

  get editorId(): number {
    return this.auth.user.id;
  }

  get programEvents(): unknown {
    return this.settingsForm.value.events;
  }

  get programPolicy(): { disableContentAfterComplete: boolean; disableContentAfterRemoval: boolean } {
    const { disableContentAfterComplete, disableContentAfterRemoval } = this.settingsForm.value;
    return { disableContentAfterComplete, disableContentAfterRemoval };
  }

  get canEditClients(): boolean {
    const acceptableRoles: ProgramAccessRoles[] = [ProgramAccessRoles.INSTRUCTOR, ProgramAccessRoles.AUTHOR];
    if (!this.accessRole) {
      return false;
    }
    return acceptableRoles.includes(this.accessRole);
  }

  tabs: typeof EditorTabs = EditorTabs;

  selectedTabId: EditorTabs = EditorTabs.Clients;

  constructor(
    programOptions: GuideProgramOptionsService,
    programCoauthors: ProgramCoauthorsService,
    formBuilder: FormBuilder,
    auth: AuthService,
    analytics: AnalyticsService,
    workspaceService: WorkspacesService,
    drawer: PuiDrawerService,
    readonly dialog: PuiDialogService,
    @Inject(GLOBAL_WINDOW) private readonly browserWindow: Window,
    readonly programState: GuideProgramStateService,
    private readonly programClients: GuideProgramClientsService,
    private readonly profile: GuideProfileService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formService: FormService,
    private readonly modal: NgbModal,
    private readonly instructorsServices: ProgramInstructorsServicesService,
    private readonly localeService: LocaleService,
    private readonly destroy$: PuiDestroyService
  ) {
    super(programOptions, programCoauthors, formBuilder, analytics, auth, workspaceService, drawer, dialog);
    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.baseUrl = this.localeService.getLocale().baseUrl;
  }

  revertChanges(): void {
    this.init();
    this.imageRemoved = true;
  }

  ngOnInit(): void {
    this.programState.content$.pipe(takeUntil(this.destroy$)).subscribe((content: ProgramContent) => {
      if (!content.author && !this.isAdmin) {
        return;
      }
      if (!this.permission) {
        this.permission = content.permission;
      }

      if (content.author || this.isAdmin) {
        this.author = {
          name: normalizeFullName(content.author) || '',
          id: (content.author && content.author.id) || content.authorId!,
          permissions: {
            canEditSettings:
              (content.permission && WorkspaceUtility.editorPermissions.includes(content.permission)) || this.isAdmin
          }
        };
      }
      this.programTitle = content.name;
      this.setContentForm(content);
    });

    this.programState.questionnaires$
      .pipe(takeUntil(this.destroy$))
      .subscribe((questionnaires: ProgramQuestionnaire[]) => this.setQuestionnairesForm(questionnaires));

    this.programState.modules$
      .pipe(takeUntil(this.destroy$))
      .subscribe((modules: ProgramModule[]) => this.setModulesForm(modules));

    this.programState.settings$.pipe(takeUntil(this.destroy$)).subscribe((settings: ProgramSettings) => {
      this.subscriptionInfo.subscriptionDeactivated = !!settings.subscriptionDeactivated;
      this.subscriptionInfo.subscriptionDeactivatedDate = settings.subscriptionDeactivatedDate;
      this.setSettingsForm(settings);
    });

    this.programClients.clients$.pipe(takeUntil(this.destroy$)).subscribe(clients => {
      this.allCustomersCount = clients.flat().length;
      this.subscriptionInfo.subscribersNumber = clients.length;
    });

    this.route.paramMap
      .pipe(
        map(params => ({ programId: +params.get('id')!, activeTab: params.get('tab') })),
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        this.redirectIfInvalidUrl(params);

        const { programId, activeTab } = params;
        this.selectedTabId = activeTab as EditorTabs;

        if (this.programId !== programId) {
          this.programId = programId;
          this.programState.programId = programId;

          this.programState.loadProgram();
        }
      });

    this.programState.programAccessRole$.pipe(takeUntil(this.destroy$)).subscribe((accessRole: ProgramAccessRoles) => {
      this.accessRole = accessRole;
      const isAdmin = this.auth.isPlatformAdmin();

      if (accessRole === ProgramAccessRoles.AUTHOR && !isAdmin) {
        if (this.programId) {
          this.programClients.refresh(this.programId);
        }
      }
    });

    this.browserWindow.addEventListener('beforeunload', this.handleWindowUnload, false);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.browserWindow.removeEventListener('beforeunload', this.handleWindowUnload);
  }

  saveOnRouteChange(): void {
    const tabCloseAction = this.tabCloseActions[this.selectedTabId];
    if (tabCloseAction) {
      tabCloseAction();
    }
  }

  private formsIsEdited(): boolean {
    return this.contentForm.dirty || this.modulesForm.dirty || this.settingsForm.dirty || this.questionnairesForm.dirty;
  }

  private formsIsValid(): boolean {
    return this.modulesForm.valid && this.contentForm.valid && this.settingsForm.valid && this.questionnairesForm.valid;
  }

  closeEditor(): void {
    if (this.isAdmin) {
      this.router.navigate(['/', 'admin', 'default-services']);
    } else {
      this.router.navigate(['/', this.guideRoute, 'services']);
    }
  }

  handleModuleSelection(selectedModule: ISelectedModule): void {
    this.selectedModule = selectedModule;
  }

  linkCopied(copyType: AnalyticCopyTypes): void {
    this.programState.settings$
      .pipe(
        take(1),
        map(settings => settings.price),
        takeUntil(this.destroy$)
      )
      .subscribe(price =>
        this.analyticsService.event(InternalEvents.LINK_COPY, {
          copy_type: copyType,
          link_type: AnalyticSourceTypes.SERVICE,
          service_price: price,
          service_type: AnalyticServiceTypes.PROGRAM
        })
      );
  }

  previewPublicPage(): void {
    if (this.isPublicInfoAllowedToUpdate()) {
      combineLatest([
        of(this.convertFormDataToProgramContent()),
        of(this.convertFormModulesForPreview()),
        this.programOptions.options$,
        this.profile.getPublicProfile$()
      ])
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(previewParts => this.showPublicPagePreview(createProgramLandingPreview(previewParts)));
    }
  }

  previewContent(): void {
    if (this.isContentAllowedToUpdate()) {
      combineLatest([
        of(this.convertFormDataToProgramContent()),
        of(this.convertFormModulesForPreview()),
        of(this.auth.user)
      ])
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(previewParts => this.showProgramContentPreview(createProgramContentPreview(previewParts)));
    }
  }

  @confirmChangesForNewUsersOnly()
  updateContent(): boolean {
    const isContentAllowedToUpdate = this.isContentAllowedToUpdate();
    const valid = this.validateModulesForm();

    if (isContentAllowedToUpdate && valid) {
      this.programState.updateModules(this.modulesFormToProgramModules());

      if (this.imageRemoved) {
        this.programState.removeCover$().pipe(take(1), takeUntil(this.destroy$)).subscribe();
      }

      this.modulesForm.markAsPristine();
    }

    return isContentAllowedToUpdate;
  }

  @confirmChangesForNewUsersOnly()
  updatePublicInfo(): boolean {
    const isPublicInfoAllowedToUpdate = this.isPublicInfoAllowedToUpdate();

    if (isPublicInfoAllowedToUpdate && this.author && this.author.permissions.canEditSettings) {
      this.programState.updateContent(this.convertFormDataToProgramContent());
    }

    this.contentForm.markAsPristine();
    return isPublicInfoAllowedToUpdate;
  }

  @confirmChangesForNewUsersOnly()
  updateSettings(): boolean {
    const areSettingsAllowedToUpdate = this.areSettingsAllowedToUpdate();

    if (areSettingsAllowedToUpdate && this.author && this.author.permissions.canEditSettings) {
      this.programState.updateSettings(this.convertFormDataToProgramSettings());
    }

    this.settingsForm.markAsPristine();
    return areSettingsAllowedToUpdate;
  }

  @confirmChangesForNewUsersOnly()
  updateQuestionnaires(): boolean {
    const areSurveysAllowedToUpdate = this.areSurveysAllowedToUpdate();

    if (areSurveysAllowedToUpdate) {
      this.programState
        .updateSurveys$(this.convertFormDataToProgramSurveys())
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => this.questionnairesForm.markAsPristine());
    } else {
      this.questionnairesForm.markAsPristine();
    }

    return areSurveysAllowedToUpdate;
  }

  uploadImage(images: { coverImage: File; coverImageThumb: File }): void {
    this.programState
      .uploadCover$(images)
      .pipe(takeUntil(this.destroy$))
      .subscribe(urls => this.addCoverImages(urls));
    this.contentForm.markAsDirty();
    this.imageRemoved = false;
  }

  removeCover(): void {
    this.addCoverImages({ coverImage: null, coverImageThumb: null });
    this.contentForm.markAsDirty();
    this.imageRemoved = true;
  }

  deactivateProgram(data: { date: Date | null; message: string }): void {
    this.programState
      .deactivateProgram$(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ lastActiveDay }) => {
        this.subscriptionInfo.subscriptionDeactivated = true;
        this.subscriptionInfo.subscriptionDeactivatedDate = lastActiveDay;
      });
  }

  makeProgramUrl(path: string, id: number): string {
    return makeUriFromString(path, id);
  }

  private areSettingsAllowedToUpdate(): boolean {
    if (this.formService.markInvalidForm(this.settingsForm)) {
      this.scrollToInvalidContentElement();
      return false;
    }

    return !this.programState.isProgramLoading;
  }

  private areSurveysAllowedToUpdate(): boolean {
    return !this.programState.isProgramLoading;
  }

  private canUnloadContent(): boolean {
    return this.isContentAllowedToUpdate() && !this.programState.getModulesDiff(this.modulesFormToProgramModules());
  }

  private canUnloadPublicInfo(): boolean {
    return (
      this.isPublicInfoAllowedToUpdate() && !this.programState.getContentDiff(this.convertFormDataToProgramContent())
    );
  }

  private canUnloadSettings(): boolean {
    return (
      this.areSettingsAllowedToUpdate() && !this.programState.getSettingsDiff(this.convertFormDataToProgramSettings())
    );
  }

  private convertFormDataToProgramContent(): ProgramContent {
    const programId = this.programId;
    const author = this.programState.program.settings.author;
    const programContent = this.contentFormToProgramContent();
    const cleanContent = ProgramContent.clean(programContent);

    return new ProgramContent().setValues({
      ...cleanContent,
      id: programId!,
      author,
      permission: this.permission
    });
  }

  private convertFormModulesForPreview(): ProgramModule[] {
    return this.modulesFormToProgramModules().map(convertUnsavedModuleIntoModule);
  }

  private convertFormDataToProgramSettings(): ProgramSettings {
    const programId = this.contentForm.value.id;
    const programSettings = this.settingsFormToProgramSettings();
    const cleanSettings = ProgramSettings.clean(programSettings);

    return new ProgramSettings().setValues({
      ...cleanSettings,
      subscriptionDeactivated: this.subscriptionInfo.subscriptionDeactivated,
      subscriptionDeactivatedDate: this.subscriptionInfo.subscriptionDeactivatedDate,
      id: programId,
      permission: this.permission
    });
  }

  private convertFormDataToProgramSurveys(): ProgramQuestionnaire[] {
    const programSurveys = this.questionnairesFormToProgramSurveys();

    return (
      programSurveys // @ts-expect-error TS7006
        .filter(programSurvey => {
          return programSurvey.survey;
        })
        // @ts-expect-error TS7006
        .map(programSurvey => {
          return new ProgramQuestionnaire().setValues(programSurvey);
        })
    );
  }

  private handleWindowUnload(event: Event): void {
    if (!this.canUnloadWindow) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  private isContentAllowedToUpdate(): boolean {
    if (this.permission === ServiceAssigneePermission.PROVIDER) {
      return !this.programState.isProgramLoading;
    } else {
      return !this.programState.isProgramLoading && this.modulesForm.valid;
    }
  }

  private isPublicInfoAllowedToUpdate(): boolean {
    if (this.formService.markInvalidForm(this.contentForm)) {
      this.scrollToInvalidContentElement();
      return false;
    }

    return !this.programState.isProgramLoading;
  }

  private redirectIfInvalidUrl(urlParams: { programId: number; activeTab: string | null }): void {
    const { programId, activeTab } = urlParams;
    const acceptableUrls: EditorTabs[] = this.isAdmin
      ? [EditorTabs.PublicTab, EditorTabs.Content, EditorTabs.Settings, EditorTabs.Forms]
      : [
          EditorTabs.Clients,
          EditorTabs.PublicTab,
          EditorTabs.Content,
          EditorTabs.Settings,
          EditorTabs.Forms,
          EditorTabs.Sharing,
          EditorTabs.Assignees
        ];
    if (!acceptableUrls.includes(activeTab as EditorTabs)) {
      this.router
        .navigate(['/', this.guideRoute, 'programs', programId, EditorTabs.Clients], {
          replaceUrl: true
        })
        .then();
    }
  }

  private scrollToInvalidContentElement(): void {
    if (this.invalidContentElementScroll) {
      this.invalidContentElementScroll.scroll();
    }
  }

  private async showProgramContentPreview(preview: ClientProgram): Promise<void> {
    try {
      const { componentInstance, result } = this.modal.open(ProgramModulesPreviewModalComponent, {
        windowClass: 'full-screen-modal'
      });
      componentInstance.program = {
        ...preview,
        workspaceId: this.programState.program.settings.workspaceId
      };

      if (this.selectedModule) {
        componentInstance.selectedModuleId = this.selectedModule.id || this.selectedModule.localId;
      }

      await result;
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  private async showPublicPagePreview(preview: IPublicProgram): Promise<void> {
    try {
      const { componentInstance, result } = this.modal.open(ProgramContentPreviewModalComponent, {
        windowClass: 'full-screen-modal'
      });
      componentInstance.program = {
        ...preview,
        workspaceId: this.programState.program.settings.workspaceId
      };
      await result;
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  private validateModulesForm(): boolean {
    return !this.formService.markInvalidForm(this.modulesForm);
  }
}
