<a class="d-block to-contacts-btn d-lg-none" [routerLink]="'../select'"></a>
<div class="main-info">
  <app-group-chat-icon [letter]="chat.name | firstLetters | uppercase"></app-group-chat-icon>

  <div class="chat-name">
    <ng-template [ngIf]="isCoach" [ngIfElse]="clientChatName">
      <div>{{ chat.name }}</div>
      <app-button className="btn-link" (click)="openParticipantsModal()">
        {{ numOfParticipants }}
        <ng-container [ngPlural]="numOfParticipants">
          <ng-template i18n="@@participant:one" ngPluralCase="=1">participant</ng-template>
          <ng-template i18n="@@participant:many" ngPluralCase="other">participants</ng-template>
        </ng-container>
      </app-button>
    </ng-template>
    <ng-template #clientChatName>
      <app-button className="btn-link" (click)="goToServiceLanding()" *ngIf="chat.meta?.serviceId">
        {{ chat.name }}
      </app-button>
      <div *ngIf="!chat.meta?.serviceId">{{ chat.name }}</div>
    </ng-template>
  </div>

  <ng-template [ngIf]="!isCoach">
    <button
      pui-dropdown
      [pui-dropdown-content]="dropdownContent"
      pui-icon-button
      size="m"
      type="button"
      appearance="flat"
      shape="square">
      <pui-icon svgIcon="pui:more-vertical"></pui-icon>
    </button>

    <ng-template #dropdownContent let-close="close">
      <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
        <button pui-list-item="button" pui-a11y-keyboard-widget-item (click)="close(); openLeaveChatModal()">
          <pui-list-item-icon>
            <pui-icon svgIcon="pui:log-out" size="s"></pui-icon>
          </pui-list-item-icon>
          <span i18n="@@inGroupChatBannerLeaveChat">Leave chat</span>
        </button>
      </div>
    </ng-template>
  </ng-template>
</div>
