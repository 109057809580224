/**
 * Here is a function that return a new Date from given Date object to the start of the day
 * @param date {Date}
 * @return Date
 */
export const dayStart = (date: Date): Date => {
  const dayStart: Date = new Date(date);

  dayStart.setHours(0, 0, 0, 0);

  return dayStart;
};
