import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';

@Injectable()
export class NgbDateTimeStringAdapter extends NgbDateAdapter<string> {
  fromModel(dateString: string): NgbDateStruct {
    if (!dateString) {
      // @ts-expect-error TS2322
      return null;
    }

    const { year, month, day } = DateTime.fromISO(dateString, { setZone: true });

    return { year, month, day };
  }

  toModel(dateStruct: NgbDateStruct): string {
    if (!dateStruct) {
      // @ts-expect-error TS2322
      return null;
    }

    const dateTime = DateTime.fromObject(dateStruct);

    return dateTime.toISO();
  }
}
