/**
 * Background palette for light themes.
 */
const LIGHT_THEME_BACKGROUND_PALETTE: { [key: string]: string } = {
  'status-bar': '#cde7ed',
  'app-bar': '#edf7f9',
  background: '#ffffff',
  hover: 'rgba(3, 137, 164, 0.04)',
  card: '#ffffff',
  dialog: '#ffffff',
  'disabled-button': 'rgba(black, 0.12)',
  'raised-button': '#ffffff',
  'focused-button': '#d6dbde',
  'selected-button': '#cde7ed',
  'selected-disabled-button': '#9ad0da',
  'disabled-button-toggle': '#e6f3f6',
  'unselected-chip': '#cde7ed',
  'disabled-list-option': '#e6f3f6',
  tooltip: '#36a1b6',
  border: '#dde7ec',
  shadow: '#dfe8ed'
};

export { LIGHT_THEME_BACKGROUND_PALETTE };
