import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { AbstractControl, NgForm, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { GuideRelation, GuideRelationTypes, IContact } from '@app/core/users/types';
import { GuideContactsService } from '@app/modules/guide-client/services/guide-contacts.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { SALES_CLIENT_TAGS } from '@app/base/consts';
import { ContactViewModel } from '@app/screens/guide/guide-clients/guide-relations-table/services/api/clients-api.service';

enum EditableFields {
  EMAIL = 'email',
  PHONE = 'phone',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ORGANIZATION = 'organization',
  STAGE = 'tag'
}

const props = [
  ['firstName'],
  ['lastName'],
  ['contacts', 'email'],
  ['contacts', 'phone'],
  ['contacts', 'organization'],
  ['tag']
];

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  readonly EditableFields = EditableFields;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guideRelation: GuideRelation;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _saving = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isMembersSelectorSwitched = false;

  private destroy$ = new Subject();

  // @ts-expect-error TS2564
  @ViewChild('contactForm') contactForm: NgForm;

  // @ts-expect-error TS2564
  contact: IContact;

  isSupportTeam = false;

  isClientArchived = true;

  // TODO 🚲
  client!: ContactViewModel;

  /**
   * This is a simple solution to implement CRM.Stage.
   * It is not intended to be extended or further supported.
   * https://profi-io.atlassian.net/browse/PR-3803
   */
  readonly tags = SALES_CLIENT_TAGS;

  // @ts-expect-error TS7006
  readonly excludeCurrentTag = (currentTagName: string) => tag => tag.name !== currentTagName;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  clients$?: Observable<GuideRelation[]>;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  // @ts-expect-error TS2564
  disableAddNewClient: boolean;

  // eslint-disable-next-line @typescript-eslint/member-ordering,@typescript-eslint/naming-convention
  private _clientMembers: IWorkspaceMember[] = [];

  get clientMembers(): IWorkspaceMember[] {
    return this._clientMembers.filter(
      member => member.role && !this._guideRelation.guides.some(guide => guide.userId === member.userId)
    );
  }

  @Input()
  set guideRelation(guideRelation: GuideRelation) {
    this._guideRelation = guideRelation;
    this.client = new ContactViewModel(this._guideRelation);

    // @ts-expect-error TS2322
    this.isClientArchived = guideRelation.archived;
    this.isSupportTeam = guideRelation.isSupportUser;

    this.selectedMembers =
      guideRelation.guides && this.workspacesService.isAdmin
        ? guideRelation.guides.map(guide => ({ removable: false, ...guide }))
        : [];

    if (guideRelation) {
      this.contact = {};
      for (const keys of props) {
        const resValue = keys.reduce((object, key) => {
          // @ts-expect-error TS7053
          return (object || {})[key];
        }, guideRelation);
        const newKey = keys[keys.length - 1];
        // @ts-expect-error TS7053
        this.contact[newKey] = resValue || '';
      }

      if (this.isEditable) {
        setTimeout(() => {
          this.contactForm.setValue(this.contact);
        });
      }
    }

    // hack for multi-selector change detection
    this._clientMembers = this.cloneList(this._clientMembers);

    this.editableField = null;
  }

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/member-ordering
  editableField: EditableFields | null;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  selectedMembers: IWorkspaceMember[] = [];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isSelectedMembersChanged = false;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isAdmin = this.workspacesService.isAdmin;

  get isEditable(): boolean {
    return this._guideRelation?.type === GuideRelationTypes.GUIDE_CONTACT;
  }

  get isAssignable(): boolean {
    return this._guideRelation?.type === GuideRelationTypes.GUIDE_CLIENT && this.isAdmin;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _guideContactsService: GuideContactsService,
    private _guideClientsService: GuideClientsService,
    public workspacesService: WorkspacesService
  ) {}

  ngOnInit(): void {
    this.workspacesService.members$.pipe(takeUntil(this.destroy$)).subscribe(members => {
      this._clientMembers = members;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  cloneList<T>(list: T[]): T[] {
    return list.map(item => ({ ...item }));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  edit(field: EditableFields) {
    this.editableField = field;
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  save(input) {
    if (this._saving || input.invalid) {
      return;
    }

    this._saving = true;

    const { editableField } = this;
    const id =
      this._guideRelation.type === GuideRelationTypes.GUIDE_CONTACT
        ? this._guideRelation.id
        : this._guideRelation.relationId;

    this._guideContactsService
      // @ts-expect-error TS2464
      .updateContact$(id, { [editableField]: input.value })
      .pipe(
        finalize(() => {
          this._saving = false;
          this.editableField = null;
          this._changeDetector.detectChanges();
        })
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        // @ts-expect-error TS2538
        this.contact[editableField] = input.value;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  cancel() {
    this.editableField = null;
    this.contactForm.setValue(this.contact);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  changeArchiveStatus(value: boolean) {
    const id =
      this._guideRelation.type === GuideRelationTypes.GUIDE_CONTACT
        ? this._guideRelation.id
        : this._guideRelation.relationId;
    this._guideContactsService
      .updateContact$(id, { archived: value, archivedAt: value ? DateTime.local().toISO() : null })
      .pipe(finalize(() => this._changeDetector.markForCheck()))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => this._guideClientsService.refresh());
  }

  isEmail(email: string | undefined): boolean {
    return !Validators.email({ value: email } as AbstractControl);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  saveMembers() {
    if (this._guideRelation.type !== GuideRelationTypes.GUIDE_CLIENT) {
      return;
    }

    const guideIds = this.selectedMembers
      .filter(guide => {
        return !this._guideRelation.guides.some(selected => selected.userId === guide.userId);
      })
      .map(({ userId }) => userId);
    // @ts-expect-error TS2345
    this._guideClientsService.addGuides(this._guideRelation.id, guideIds, this.onSaveMembersHandler.bind(this));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSaveMembersHandler() {
    this.isSelectedMembersChanged = false;
    this._guideClientsService.refresh();
    this._changeDetector.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  editAssignee(changedItems: IWorkspaceMember[]) {
    if (!changedItems || !this._isMembersSelectorSwitched) {
      return;
    }
    this.selectedMembers = changedItems;
    const guidesDict = {};
    changedItems.forEach(guide => {
      // @ts-expect-error TS2538
      guidesDict[guide.userId] = guide;
    });
    this.isSelectedMembersChanged =
      changedItems.length !== this._guideRelation.guides.length ||
      // @ts-expect-error TS2538
      !this._guideRelation.guides.every(item => !!guidesDict[item.userId]);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  switchMembersSelector() {
    this._isMembersSelectorSwitched = true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getTag(tagName: string) {
    return !tagName ? null : this.tags.find(tag => tag.name === tagName);
  }
}
