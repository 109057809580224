import { MimeTypes } from '@app/core/files/types';

const GIGABYTE = 1073741824;
const MEGABYTE = 1048576;
const KILOBYTE = 1024;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function convertFileSize(bytes: number) {
  if (bytes > 1 && bytes < KILOBYTE) {
    return `${bytes} b`;
  }

  if (bytes >= KILOBYTE && bytes < MEGABYTE) {
    return `${(bytes / KILOBYTE).toFixed(1)} Kb`;
  }

  if (bytes >= MEGABYTE && bytes < GIGABYTE) {
    return `${(bytes / MEGABYTE).toFixed(1)} Mb`;
  }

  if (bytes >= GIGABYTE) {
    return `${(bytes / GIGABYTE).toFixed(1)} Gb`;
  }

  return '~ 1 b';
}

export function defineMimeType(type: string): MimeTypes {
  if (type.startsWith('image')) {
    return MimeTypes.IMAGE;
  }

  if (type.startsWith('video')) {
    return MimeTypes.VIDEO;
  }

  return MimeTypes.OTHER;
}
