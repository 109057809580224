import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import {
  MatAutocompleteOrigin,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { UiOption } from '@app/modules/ui-kit/_base/ui-option/option';
import { AppFormFieldControl } from '@app/modules/ui-kit/form/components/form-field/form-field.component';

@Component({
  selector: 'ui-selector-single',
  templateUrl: 'template.html',
  styleUrls: ['selector.scss', '../ui-option/option.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-selector',
    '[attr.aria-label]': 'ariaLabel'
  },
  providers: [{ provide: AppFormFieldControl, useExisting: forwardRef(() => UiSelectorSingle) }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiSelectorSingle implements ControlValueAccessor, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  typedValue = '';

  @Input()
  get value(): unknown {
    return this._value;
  }

  set value(newValue: unknown) {
    if (newValue !== this._value) {
      this._onChange(newValue);
      this._onTouched();
      this._value = newValue;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value: unknown = null;

  @Input()
  get disabled(): boolean {
    return this.ngControl ? !!this.ngControl.disabled : this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _disabled = false;

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get getOptionLabel() {
    return this._getOptionLabel;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set getOptionLabel(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._getOptionLabel = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _getOptionLabel = (option: any) => option?.label ?? '';

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get displayWith() {
    return this._displayWith;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set displayWith(fn: (option: any) => string) {
    if (!fn) {
      return;
    }
    this._displayWith = fn;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  private _displayWith = (option: any) => option?.label ?? '';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() ariaLabel = '';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() placeholder = '';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() overlayPanelClassName = '';

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  @Input() emptyListTemplateRef: TemplateRef<any> | undefined;

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  @Input() optionTemplateRef: TemplateRef<any> | undefined;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() autocompleteConnectedTo: MatAutocompleteOrigin | undefined;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() typed: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() addClient: EventEmitter<void> = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ContentChildren(UiOption) optionsList: QueryList<UiOption> | undefined;

  get options(): UiOption[] {
    return this._options;
  }

  set options(value: UiOption[]) {
    this._options = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering,@typescript-eslint/naming-convention
  protected _options: UiOption[] = [];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly stateChanges = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  _onChange: (value: any) => void = () => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/explicit-function-return-type
  _onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this._initSubscriptionsOnModelAndOptions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
  }

  click(event: MouseEvent, trigger: MatAutocompleteTrigger): void {
    event.stopPropagation();
    event.preventDefault();

    trigger.openPanel();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTyped(value: string | any): void {
    if (typeof value === 'string') {
      this.typedValue = value;
      this.typed.emit(value);
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (value || value === null) {
      this.value = value;
      this.selected.emit(value);
    } else {
      this.addClient.emit();
    }
  }

  onClosed(input: MatInput): void {
    this.closed.emit();
    input.value = this.displayWith(this.value) ?? '';
    this.reset();
  }

  reset(): void {
    this.typedValue = '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.value = obj;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _initSubscriptionsOnModelAndOptions(): void {
    // eslint-disable-next-line rxjs/no-unsafe-takeuntil
    this.optionsList?.changes?.pipe(takeUntil(this.destroy$), startWith([])).subscribe(() => {
      // @ts-expect-error TS2532
      this.options = this.optionsList.toArray();
    });
  }
}
