import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PuiButtonModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputModule,
  PuiSelectModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { SharedModule } from '@libs/shared/shared.module';

import { ChangeTimezoneModalModule } from './change-timezone-modal/change-timezone-modal.module';
import { TimezoneFormComponent } from './timezone-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PuiTypographyModule,
    PuiIconModule,
    PuiSelectModule,
    ChangeTimezoneModalModule,
    PuiButtonModule,
    PuiInputModule,
    SharedModule,
    PuiDropdownModule
  ],
  declarations: [TimezoneFormComponent],
  exports: [TimezoneFormComponent]
})
export class TimezoneFormModule {}
