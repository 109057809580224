import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftCouponActivationComponent } from './gift-coupon-activation.component';
import { GiftCouponActivationGuard } from './gift-coupon-activation.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [GiftCouponActivationComponent],
  exports: [GiftCouponActivationComponent],
  providers: [GiftCouponActivationGuard]
})
export class CouponActivationModule {}
