import { IUser } from '@app/shared/interfaces/user';

export enum QuizEntityPolicy {
  All = 'all',
  SelectedPrograms = 'selected_programs',
  SelectedSessions = 'selected_sessions'
}

export enum QuizClientsPolicy {
  All = 'all',
  Author = 'author'
}

export enum QuizEnds {
  FixedCount = 'fixed_count',
  Never = 'never',
  SpecificDate = 'specific_date'
}

export enum QuizPattern {
  Daily = 'daily',
  Monthly = 'monthly',
  Once = 'once',
  Weekly = 'weekly'
}

export enum QuizSendPolicy {
  AfterBuyProgram = 'after_buy_program',
  AfterEachBooking = 'after_each_booking',
  AfterEachSession = 'after_each_session',
  AfterFirstBooking = 'after_first_booking',
  AfterFirstSession = 'after_first_session',
  HourBeforeEachSession = 'hour_before_each_session',
  Scheduling = 'scheduling'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuizBot extends IUser {
  bot: boolean;
  chatId?: string;
  creator: number;
  date?: string;
  firstDate?: string;
  lastQuestionDate?: string;
  lastScheduleId?: number;
  name: string;
  schedules: ISchedule[];
  type: string;
}

export interface QuizAnswer {
  id: number;
  quizId: number;
  questionId: number;
  text: string;
  time: string;
  optionId?: number;
}

export interface QuizUserSchedule {
  id: number;
  templateId: number;
  userId: number;
  status: string;
  date: string;
  sent: boolean;
  expired: boolean;
  complete: boolean;
  answers?: QuizAnswer[];
}

export interface QuizQuestionOption {
  id: number;
  text: string;
  isCorrect: boolean;
  other: boolean;
  explanation?: string | null;
}

export enum QuizQuestionType {
  SHORT_ANSWER = 'short_answer',
  LONG_ANSWER = 'long_answer',
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  FILE_UPLOAD = 'file_upload',
  SIGNATURE = 'signature',
  TEXT = 'text',
  QUIZ = 'quiz'
}

export enum QuizValidationType {
  AT_LEAST_ONE_ANSWER_REQUIRED = 'AT_LEAST_ONE_ANSWER_REQUIRED',
  ALL_ANSWERS_REQUIRED = 'ALL_ANSWERS_REQUIRED'
}

export interface QuizQuestion {
  id: number;
  templateId: number;
  question: string;
  description: string;
  options: QuizQuestionOption[];
  type: QuizQuestionType;
  createdAt: string; // @example "2023-08-09T10:31:37.698Z"
  updatedAt: string; // @example "2023-08-09T10:31:37.698Z"
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IClientQuiz {
  avatar: string;
  creator: number;
  description: string;
  id: number;
  programId?: number;
  questions: QuizQuestion[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedules?: any[];
  status?: string;
  title: string;
  userSchedules: QuizUserSchedule[];
}

export interface GuideQuiz {
  avatar: string;
  creator: number;
  description: string;
  id: number;
  templateId: number;
  programId?: number;
  questions: QuizQuestion[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedules?: any[];
  status?: string;
  title: string;
  userSchedule: QuizUserSchedule;
  quizTemplate: IClientQuiz;
  session: unknown;
}

export interface QuizClientAnswer {
  questionId: number;
  text: string;
  optionId?: number;
}

export interface QuizAnswers {
  quizId: number;
  scheduleId: number;
  answers: QuizClientAnswer[];
}

export interface QuizLatestAnswers {
  quizId: number;
  messageId: number;
  answers: QuizClientAnswer[];
}

export interface QuizSchedule {
  id: number;
  pattern: QuizPattern;
  templateId: number;
  date: string;
  until: string;
  from: string;
  fireCount: number;
  ends: QuizEnds;
  weekdays: string;
  days: number;
  monthday: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISchedule {
  id?: number | string;
  pattern?: QuizPattern;
  templateId?: number | string;
  date?: string;
  until?: string;
  createdAt?: string;
  updatedAt?: string;
  from?: string;
  weekdays?: string;
  days?: string;
  fireCount?: number | string;
  ends?: string;
  monthday?: string;
  sendPolicy?: QuizSendPolicy;
  clientsPolicy?: QuizClientsPolicy;
}
