<app-button
  data-qa-id="service-booking-modal-close-button"
  class="close-btn"
  (click)="modal.dismiss()"
  className="close"></app-button>

<app-service-booking
  [bookingOptions]="bookingOptions"
  (bookingDetailsChange)="updateBookingDetails($event)"
  (requestMoreOptions)="requestMoreOptions()"></app-service-booking>
