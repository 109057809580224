import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrencyService } from '@app/core/currency/currency.service';
import { SubscriptionStatus } from '@app/shared/enums/subscription';
import { ISubscription } from '@app/shared/interfaces/subscription';
import { SubscriptionType } from '@app/shared/enums/subscription';

@Component({
  selector: 'app-client-subscriptions',
  templateUrl: './client-subscriptions.component.html',
  styleUrls: ['./client-subscriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSubscriptionsComponent {
  readonly SubscriptionStatus = SubscriptionStatus;
  readonly SubscriptionType = SubscriptionType;
  readonly subscriptionReqStrings = {
    month: `month`,
    year: `year`,
    week: `week`
  };

  @Input()
  // @ts-expect-error TS2564
  subscriptions: ISubscription[];

  @Output()
  cancelSubscription = new EventEmitter<number>();

  @Output()
  reactivateSubscription = new EventEmitter<number>();

  constructor(public currency: CurrencyService) {}
}
