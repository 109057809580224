import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ROOT_PATH, ROUTER_OUTLET } from './routes';

const routes: Routes = [
  {
    outlet: ROUTER_OUTLET,
    path: '',
    children: [
      {
        path: ROOT_PATH,
        loadChildren: () =>
          import('./modules/choose-forms/choose-forms.module').then(module => module.ChooseFormsModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProposeFormsRoutingModule {}
