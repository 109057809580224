import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CommonBehaviorsModule } from '@app/modules/common-behaviors/common-behaviors.module';
import { PackageModule } from '@app/modules/package/package.module';
import { ServicesModule } from '@app/modules/services/services.module';
import { SharedModule } from '@app/shared/shared.module';

import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollbarModule } from '@app/modules/scrollbar/scrollbar.module';
import { PricingModule } from '@app/modules/pricing/pricing.module';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';

import { PackageLandingComponent } from './components/package-landing/package-landing.component';
import { PublicPackageComponent } from './components/public-package/public-package.component';

import { PublicPackagesService } from './services/public-package.service';
import { PuiButtonModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { SessionTemplateModule } from '@app/modules/session-template/session-template.module';

@NgModule({
  imports: [
    CommonModule,
    PackageModule,
    ServicesModule,
    SharedModule,
    CommonBehaviorsModule,
    PlatformStyleModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgScrollbarModule,
    ScrollbarModule,
    PricingModule,
    CurrentPaymentModule,
    PuiButtonModule,
    SessionTemplateModule,
    PuiTypographyModule
  ],
  declarations: [PackageLandingComponent, PublicPackageComponent],
  exports: [PackageLandingComponent],
  providers: [PublicPackagesService]
})
export class PackagesModule {}
