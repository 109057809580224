import { Observable, Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { IFloatingPopoverCloseEvent } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FloatingPopoverRef<T = any> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _closed$ = new Subject<IFloatingPopoverCloseEvent<T>>();

  get closed$(): Observable<IFloatingPopoverCloseEvent<T>> {
    return this._closed$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public overlay: OverlayRef, public content: any, public data: T) {
    overlay.backdropClick().subscribe(() => this.handleClose('backdropClick', data));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  close(data?: T) {
    this.handleClose('close', data);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private handleClose(type, data) {
    this.overlay.dispose();
    this._closed$.next({ type, data });
    this._closed$.complete();
  }
}
