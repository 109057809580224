import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, map, mapTo, tap } from 'rxjs/operators';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import config from '@app/core/config/config';
import { PaymentMethods } from '@app/shared/enums/payment-methods';
import { GuideServiceTypes, IBookingResult } from '@app/shared/interfaces/services';
import { IGiftCardPaymentDetails } from '@app/shared/interfaces/gift-coupons';
import { environment } from '@env/environment';
import { AuthService } from '@app/core/auth/services/auth.service';
import { PostPopupWindowService } from '@app/core/paypal/post-popup-window-service';
import { SessionsTypes } from '@app/shared/enums/sessions-types';

import { PayService, Payment } from '../types';

const APPROVE_SESSION_OFFER_ENDPOINT = `${config.apiPath}/auth/paypal/session-offer/approve`;

const BOOK_SESSION_ENDPOINT = `${config.apiPath}/auth/paypal/session-by-template/books`;

const OFFER_RESCHEDULE_ENDPOINT = `${config.apiPath}/auth/paypal/offers-reschedule`;

const GIFT_CARD_ENDPOINT = `${config.apiPath}/auth/paypal/gift-coupon`;

const PROGRAMS_ENDPOINT = `${config.apiPath}/auth/paypal/programs`;

const PACKAGES_ENDPOINT = `${config.apiPath}/auth/paypal/packages`;

const PLATFORM_PLAN_ENDPOINT = `${config.apiPath}/auth/paypal/platform-plans`;

const REPAY_OVERDRAFT_ENDPOINT = `${config.apiPath}/auth/paypal/overdraft/repay`;

const MESSAGE_TYPE = 'paypal_payment_response';

const WINDOW_TITLE = 'paypal_payment_window';

@Injectable({
  providedIn: 'root'
})
export class PayWithPaypalService implements PayService {
  constructor(
    private _auth: AuthService,
    private _popupWindow: PostPopupWindowService,
    private _analytics: AnalyticsService
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
  init(payment: Payment) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionOffer$(id: number): Observable<any> {
    const sessionData = { id };
    return this._run$(APPROVE_SESSION_OFFER_ENDPOINT, sessionData).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any = {}) =>
        this._analytics.event(InternalEvents.ACCEPT_SESSION_OFFER, {
          session: data.session,
          paymentMethod: PaymentMethods.PAYPAL
        })
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service$(bookingDetails: any): Observable<IBookingResult> {
    if ([GuideServiceTypes.SESSION, GuideServiceTypes.GROUP_SESSION].includes(bookingDetails.type)) {
      return this.session$(bookingDetails) as Observable<IBookingResult>;
    }

    if (bookingDetails.type === GuideServiceTypes.PACKAGE) {
      return this.package$(bookingDetails.serviceId) as Observable<IBookingResult>;
    }

    if (bookingDetails.type === GuideServiceTypes.PROGRAM) {
      return this.program$(bookingDetails.serviceId) as Observable<IBookingResult>;
    }

    return throwError('Unknown service type.');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session$(bookingDetails: any): Observable<IBookingResult> {
    return this._run$(BOOK_SESSION_ENDPOINT, bookingDetails).pipe(
      map(session => ({
        id: session.id,
        serviceId: bookingDetails.serviceId,
        serviceType: bookingDetails.type
      }))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reschedule$(id: number, date: string, sessionType: SessionsTypes, message?: string): Observable<any> {
    return this._run$(OFFER_RESCHEDULE_ENDPOINT, { id, date, message });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coupon$(giftPayment: IGiftCardPaymentDetails): Observable<any> {
    return this._run$(GIFT_CARD_ENDPOINT, giftPayment);
  }

  program$(programId: number): Observable<IBookingResult> {
    return this._run$(`${PROGRAMS_ENDPOINT}/orders`, { programId }).pipe(
      mapTo({ id: programId, serviceId: programId, serviceType: GuideServiceTypes.PROGRAM })
    );
  }

  package$(packageId: number): Observable<IBookingResult> {
    return this._run$(`${PACKAGES_ENDPOINT}/orders`, { packageId }).pipe(
      mapTo({ id: packageId, serviceId: packageId, serviceType: GuideServiceTypes.PACKAGE })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  platformPlan$(planType: string): Observable<any> {
    return this._run$(`${PLATFORM_PLAN_ENDPOINT}/switch`, { planType });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overdraft$(): Observable<any> {
    return this._run$(REPAY_OVERDRAFT_ENDPOINT, {});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptionReactivate$(): Observable<any> {
    return throwError('Not implemented for PayPal');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
  private _run$<T, U = any>(endpoint: string, postData: T): Observable<U> {
    const fullPostData = { ...postData, token: this._auth.user.authToken };
    return this._popupWindow.openPopup$(WINDOW_TITLE, endpoint, fullPostData, environment.apiHost, MESSAGE_TYPE).pipe(
      concatMap(message => {
        if (message.error) {
          return throwError(message.error);
        }

        return of(message.payload || null);
      })
    );
  }
}
