import {
  ClientTimelineEvent,
  TimelineEventType,
  UserInfo
} from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/modules/guide-client-history/types';
import { normalizeFullName } from '@app/shared/utils/full-name';

export class ClientTimelineEventUtils {
  /**
   * Return host of { ClientTimelineEvent }
   * @param event {ClientTimelineEvent}
   */
  static getHost(event: ClientTimelineEvent): UserInfo {
    return event.hostedBy || event.guide;
  }

  /**
   *
   * @param clientTimelineEvent
   */
  static getAuthor(clientTimelineEvent: ClientTimelineEvent): UserInfo {
    // quizId only for self guide forms
    if (clientTimelineEvent.quizId) {
      return clientTimelineEvent.guide;
    }

    switch (clientTimelineEvent.eventType) {
      case TimelineEventType.SESSION_OFFERED_BY_HOST:
      case TimelineEventType.SESSION_CANCELLED_BY_HOST:
      case TimelineEventType.SESSION_RESCHEDULED_BY_HOST:
      case TimelineEventType.SESSION_OFFERED_BY_ADMIN:
      case TimelineEventType.SESSION_CANCELLED_BY_ADMIN:
      case TimelineEventType.SESSION_RESCHEDULED_BY_ADMIN:
      case TimelineEventType.SESSION_MISSED_BY_HOST:
        return clientTimelineEvent.guide;
      default:
        return clientTimelineEvent.client;
    }
  }

  /**
   *
   * @param eventType
   * @param productName
   */
  static getDescription({
    eventType,
    productName,
    quizId,
    quizName,
    recurringSessionsNumber
  }: ClientTimelineEvent): string {
    const session = `a session`;
    const sessions = `sessions`;

    const sessionText = recurringSessionsNumber
      ? `${recurringSessionsNumber} <strong>${sessions}</strong>`
      : `<strong>${session}</strong>`;

    switch (eventType) {
      case TimelineEventType.SESSION_OFFERED_BY_HOST:
      case TimelineEventType.SESSION_OFFERED_BY_ADMIN:
        return `Offered <strong>${session}</strong> ${productName}`;
      case TimelineEventType.SESSION_CANCELLED_BY_CLIENT:
      case TimelineEventType.SESSION_CANCELLED_BY_HOST:
      case TimelineEventType.SESSION_CANCELLED_BY_ADMIN:
        return `Canceled ${sessionText} ${productName}`;
      case TimelineEventType.SESSION_RESCHEDULED_BY_HOST:
      case TimelineEventType.SESSION_RESCHEDULED_BY_ADMIN:
      case TimelineEventType.SESSION_RESCHEDULED_BY_CLIENT:
        return `Rescheduled <strong>session<strong> ${productName}`;
      case TimelineEventType.SESSION_COMPLETED:
        return `Completed <strong>${session}</strong> ${productName}`;
      case TimelineEventType.SESSION_BOOKED:
        return `Booked <strong>${session}</strong> ${productName}`;
      case TimelineEventType.SESSION_ACCEPTED:
        return `Accepted <strong>${session}</strong> ${productName}`;
      case TimelineEventType.SESSION_MISSED_BY_CLIENT:
      case TimelineEventType.SESSION_MISSED_BY_HOST:
        return `Missed <strong>${session}</strong> ${productName}`;
      case TimelineEventType.CLIENT_ENROLLED_PROGRAM:
        return `Enrolled in <strong>a program</strong> ${productName}`;
      case TimelineEventType.CLIENT_ENROLLED_PACKAGE:
        return `Enrolled in <strong>a package</strong> ${productName}`;
      case TimelineEventType.FORM_REQUEST:
        return `Received a ${
          quizId
            ? ` form <a href="/coach/surveys/answers/${quizId}">${quizName}</a>`
            : `<strong>form</strong> ${productName}`
        }`;
      case TimelineEventType.FORM_SUBMIT:
        return `Submitted a ${
          quizId
            ? ` form <a href="/coach/surveys/answers/${quizId}">${quizName}</a>`
            : `<strong>form</strong> ${productName}`
        }`;
      default:
        return `Unknown event`;
    }
  }

  /**
   *
   * @param event
   */
  static getDescriptionWithGuide(event: ClientTimelineEvent, hasHostedByDisplayed: boolean): string {
    const host = ClientTimelineEventUtils.getHost(event);
    const description = ClientTimelineEventUtils.getDescription(event);

    if (event.quizId) {
      // only for self forms
      if (event.productName) {
        return `${description} for ${event.productName}`;
      }
      return `${description}`;
    }

    const hostedBy = hasHostedByDisplayed ? `${'hosted by'} ${normalizeFullName(host)}` : ``;

    return host ? `${description} ${hostedBy}` : description;
  }
}
