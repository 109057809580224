<div [formGroup]="form">
  <div *ngIf="form.controls.length === 0">
    <span class="empty-list-block" i18n="@@noConditionsAdded">No conditions added yet</span>
  </div>

  <ng-container *ngIf="form.controls.length">
    <div
      *ngFor="let setting of form.controls; let settingIndex = index"
      [formGroupName]="settingIndex"
      class="setting-row separated">
      <div class="row-inner">
        <!-- Trigger -->
        <div class="select-wrapper">
          <label pui-typography="body-s-bold">
            <ng-container i18n="@@when">When</ng-container>
          </label>

          <pui-select
            class="item-selector"
            id="sendPolicy"
            formControlName="sendPolicy"
            (ngModelChange)="handleSendPolicyChanged($event, settingIndex)"
            puiPlaceholder="Choose trigger"
            i18n-puiPlaceholder="@@chooseTrigger"
            size="s">
            <pui-option
              [value]="QuizSendPolicy.AfterEachBooking"
              i18n-label="@@afterEachBooking"
              label="At the time of each booking">
            </pui-option>
            <pui-option
              [value]="QuizSendPolicy.AfterFirstBooking"
              i18n-label="@@atTimeOfFirstBooking"
              label="At the time of first booking">
            </pui-option>
            <pui-option
              [value]="QuizSendPolicy.AfterEachSession"
              i18n-label="@@afterEachSession"
              label="After each session"></pui-option>
            <pui-option
              [value]="QuizSendPolicy.AfterFirstSession"
              i18n-label="@@afterFirstSession"
              label="After first session">
            </pui-option>
            <pui-option
              [value]="QuizSendPolicy.AfterBuyProgram"
              i18n-label="@@afterJoiningACourse"
              label="After joining a program">
            </pui-option>
            <pui-option
              [value]="QuizSendPolicy.HourBeforeEachSession"
              i18n-label="@@hourBeforeEachSession"
              label="1 hour before each session">
            </pui-option>
            <pui-option [value]="QuizSendPolicy.Scheduling" label="Scheduling..." i18n-label="@@scheduling">
            </pui-option>
          </pui-select>

          <app-form-control-error [control]="form.controls[settingIndex].controls?.sendPolicy">
            <ng-container>
              <div pui-typography="body-s" class="select-item-error select-trigger-error">
                <div class="icon"></div>
                <span i18n="@@pleaseSelectTrigger">Please select trigger</span>
              </div>
            </ng-container>
          </app-form-control-error>
        </div>

        <!-- Recipient -->
        <div class="select-wrapper">
          <label pui-typography="body-s-bold">
            <ng-container
              *ngIf="
                matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
                  QuizSendPolicy.AfterFirstBooking,
                  QuizSendPolicy.AfterEachBooking
                ]);
                else thenSendFormTo
              "
              i18n="@@thenShowFormTo"
              >Then show form to</ng-container
            >

            <ng-template #thenSendFormTo>
              <ng-container i18n="@@thenSendFormTo">Then send form to</ng-container>
            </ng-template>
          </label>

          <pui-select
            class="item-selector"
            id="clientsPolicy"
            formControlName="clientsPolicy"
            puiPlaceholder="Choose option"
            i18n-puiPlaceholder="@@chooseOption"
            [disabled]="form.controls[settingIndex].controls.clientsPolicy.disabled"
            size="s">
            <pui-option [value]="QuizClientsPolicy.All" i18n-label="@@client" label="Client"></pui-option>
            <pui-option
              *ngIf="
                matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
                  QuizSendPolicy.AfterFirstSession,
                  QuizSendPolicy.AfterEachSession,
                  QuizSendPolicy.HourBeforeEachSession
                ])
              "
              [value]="QuizClientsPolicy.Author"
              i18n-label="@@myself"
              label="Myself"></pui-option>
          </pui-select>
        </div>
      </div>

      <ng-container
        *ngIf="
          matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
            QuizSendPolicy.AfterEachBooking,
            QuizSendPolicy.AfterEachSession,
            QuizSendPolicy.AfterBuyProgram,
            QuizSendPolicy.HourBeforeEachSession,
            QuizSendPolicy.Scheduling
          ])
        ">
        <div *ngIf="form.controls[settingIndex].controls?.sendPolicy?.value" class="row-inner">
          <div class="select-wrapper full-width">
            <label pui-typography="body-s-bold">
              <ng-container i18n="@@applyTo">Apply to</ng-container>
            </label>

            <pui-select
              class="item-selector"
              id="entityPolicy"
              formControlName="entityPolicy"
              (ngModelChange)="handleEntityPolicyChanged($event, settingIndex)"
              size="s">
              <pui-option
                [value]="QuizEntityPolicy.All"
                label="All sessions"
                i18n-label="@@allSessions"
                *ngIf="
                  matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
                    QuizSendPolicy.AfterEachBooking,
                    QuizSendPolicy.AfterEachSession,
                    QuizSendPolicy.HourBeforeEachSession
                  ])
                ">
              </pui-option>

              <pui-option
                [value]="QuizEntityPolicy.SelectedSessions"
                label="Selected sessions"
                i18n-label="@@selectedSessions"
                *ngIf="
                  matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
                    QuizSendPolicy.AfterEachBooking,
                    QuizSendPolicy.AfterEachSession,
                    QuizSendPolicy.HourBeforeEachSession
                  ])
                ">
              </pui-option>

              <pui-option
                [value]="QuizEntityPolicy.All"
                label="All programs"
                i18n-label="@@allPrograms"
                *ngIf="
                  matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
                    QuizSendPolicy.AfterBuyProgram,
                    QuizSendPolicy.Scheduling
                  ])
                ">
              </pui-option>
              <pui-option
                [value]="QuizEntityPolicy.SelectedPrograms"
                label="Selected programs"
                i18n-label="@@selectedPrograms"
                *ngIf="
                  matchPolicy(form.controls[settingIndex].controls.sendPolicy.value, [
                    QuizSendPolicy.AfterBuyProgram,
                    QuizSendPolicy.Scheduling
                  ])
                ">
              </pui-option>
            </pui-select>
          </div>
        </div>

        <ng-template #itemTemplate let-item="item" let-active="active">
          <div class="program-item">
            <app-checkbox [value]="active" (click)="$event.preventDefault()"></app-checkbox>
            <div class="info">
              <div class="name">{{ item.name || ' ' }}</div>
              <div *ngIf="item.subtitle" class="subtitle">{{ item.subtitle }}</div>
            </div>
          </div>
        </ng-template>

        <app-universal-selector
          *ngIf="form.controls[settingIndex].controls?.entityPolicy?.value === QuizEntityPolicy.SelectedPrograms"
          formControlName="programs"
          [all]="universalPrograms$ | async"
          [itemTemplate]="itemTemplate"
          [buttonTemplate]="selectProgramButtonTemplate"
          caption="Select programs"
          i18n-caption="@@selectProgramsText"
          [emptyListModalTitle]="emptyProgramsListModalTitle"
          [emptyListModalDescription]="emptyProgramsListModalDescription">
          <ng-template #selectProgramButtonTemplate let-action="action">
            <button
              i18n="@@selectProgramsText"
              (click)="action()"
              class="add-condition"
              [disabled]="universalProgramsLoading$ | async">
              Select programs
            </button>
          </ng-template>
        </app-universal-selector>

        <app-form-control-error [control]="form.controls[settingIndex].controls?.programs">
          <ng-container>
            <div pui-typography="body-s" class="select-item-error">
              <div class="icon"></div>
              <span i18n="@@minOneProgramSelect">Please select at least 1 program</span>
            </div>
          </ng-container>
        </app-form-control-error>

        <app-universal-selector
          *ngIf="form.controls[settingIndex].controls?.entityPolicy?.value === QuizEntityPolicy.SelectedSessions"
          formControlName="sessions"
          [all]="universalServices$ | async"
          [itemTemplate]="itemTemplate"
          [buttonTemplate]="selectSessionsButtonTemplate"
          caption="Select sessions"
          i18n-caption="@@selectSessionsText"
          [emptyListModalTitle]="emptySessionListModalTitle"
          [emptyListModalDescription]="emptySessionListModalDescription">
          <ng-template #selectSessionsButtonTemplate let-action="action">
            <button
              i18n="@@selectSessionsText"
              (click)="action()"
              class="add-condition"
              [disabled]="universalServicesLoading$ | async">
              Select sessions
            </button>
          </ng-template>
        </app-universal-selector>

        <app-form-control-error [control]="form.controls[settingIndex].controls?.sessions">
          <ng-container>
            <div pui-typography="body-s" class="select-item-error">
              <div class="icon"></div>
              <span i18n="@@minOneSessionSelect">Please select at least 1 session</span>
            </div>
          </ng-container>
        </app-form-control-error>
      </ng-container>

      <div class="row-inner">
        <app-quiz-scheduling
          [ngStyle]="{
            display:
              form.controls[settingIndex].controls.sendPolicy.value === QuizSendPolicy.Scheduling ? 'block' : 'none'
          }"
          formControlName="schedule"></app-quiz-scheduling>
      </div>

      <button
        (click)="deleteRow(settingIndex)"
        class="delete-btn"
        placement="top"
        ngbTooltip="Remove condition"
        i18n-ngbTooltip="@@removeCondition"></button>
    </div>
  </ng-container>

  <button i18n="@@addNewCondition" (click)="addRow()" class="add-condition">Add new condition</button>
</div>
