import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { DiffDetails } from '../util-types';
import { BaseModule, cleanBaseModule, IBaseModuleDetails } from './base-module';
import { ModuleAccessTypes } from './module-access-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IContentModuleDetails extends IBaseModuleDetails {
  content: string | null;
}

export class ContentModule extends BaseModule {
  readonly content: string | null;

  // @ts-expect-error TS2564
  accessType: ModuleAccessTypes;

  get moduleType(): ModuleTypes {
    return ModuleTypes.CONTENT;
  }

  constructor(contentModuleDetails: Readonly<IContentModuleDetails>) {
    super(contentModuleDetails);

    this.content = contentModuleDetails.content;
  }

  getDiffFrom(anotherContentModule: Readonly<ContentModule>): DiffDetails<IContentModuleDetails> {
    const baseDiff = super.getDiffFrom(anotherContentModule);

    const didContentChange = this.content !== anotherContentModule.content;

    if (!didContentChange && !baseDiff) {
      return null;
    }

    const diff: DiffDetails<IContentModuleDetails> = baseDiff
      ? { ...baseDiff }
      : {
          id: this.id,
          localId: this.localId,
          order: this.order
        };

    if (didContentChange) {
      diff.content = anotherContentModule.content;
    }

    return diff;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON(): any {
    return {
      ...super.toJSON(),
      content: this.content,
      moduleType: this.moduleType
    };
  }
}

export function cleanContentModule(contentModule: Readonly<IContentModuleDetails>): IContentModuleDetails {
  return {
    ...cleanBaseModule(contentModule),
    content: (contentModule.content && contentModule.content.trim()) || null
  };
}
