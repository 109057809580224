// eslint-disable-next-line max-classes-per-file
import { Observable } from 'rxjs';
import { GuideServiceTypes, IBookingResult } from '@app/shared/interfaces/services';
import { IGiftCardPaymentDetails } from '@app/shared/interfaces/gift-coupons';
import { PaymentOptions } from '@app/shared/enums/payment-options';
import { SessionsTypes } from '@app/shared/enums/sessions-types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IStripeCard {
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
}

export enum PaymentType {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  WALLET = 'wallet',
  PAYMENT_LINK = 'payment_link',
  PAYMENT_REQUISITES = 'payment_requisites'
}

export abstract class Payment {
  abstract type: PaymentType;

  abstract saveCard?: boolean;

  abstract isNewPaymentMethod?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract paymentData: any | null;

  abstract useCardId?: string;
}

export class StripePayment implements Payment {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _card: IStripeCard;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _saveCard: boolean;

  readonly type = PaymentType.STRIPE;

  readonly isNewPaymentMethod = true;

  set paymentData(value: IStripeCard) {
    this._card = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentData() {
    return this._card;
  }

  set saveCard(value: boolean) {
    this._saveCard = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get saveCard() {
    return this._saveCard;
  }
}

export class PaypalPayment implements Payment {
  readonly type = PaymentType.PAYPAL;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentData() {
    return null;
  }
}

export class WalletPayment implements Payment {
  readonly type = PaymentType.WALLET;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentData() {
    return this.type;
  }
}

export class RequisitesPayment implements Payment {
  readonly type = PaymentType.PAYMENT_REQUISITES;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentData() {
    return this.type;
  }
}

export class LinkPayment implements Payment {
  readonly type = PaymentType.PAYMENT_LINK;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get paymentData() {
    return this.type;
  }
}

export abstract class PayService {
  abstract init(payment: Payment): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract service$(bookingDetails: any): Observable<IBookingResult>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract reschedule$(id: number, date: string, sessionType: SessionsTypes, message?: string): Observable<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract sessionOffer$(id: number): Observable<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract session$(bookingDetails: any): Observable<IBookingResult>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract coupon$(giftPayment: IGiftCardPaymentDetails): Observable<any>;

  abstract program$(
    programId: number,
    meta?: { serviceHost?: number; serviceType: GuideServiceTypes; paymentOption?: PaymentOptions | null }
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Observable<any>;

  abstract package$(
    packageId: number,
    meta?: { serviceType: GuideServiceTypes; paymentOption?: PaymentOptions | null }
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Observable<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract platformPlan$(planType: string, planRecurrency?: any): Observable<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract subscriptionReactivate$(id: number): Observable<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract overdraft$(): Observable<any>;
}
