import { Injectable } from '@angular/core';
import { GuideOfferApiService, ITimeRangesRequestOptions } from '@app/core/session/guide-offer-api.service';
import { ISchedule, IScheduleProvider, SCHEDULE_PROVIDER } from '@app/modules/schedule-boards';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GuideAvailabilityProviderService implements IScheduleProvider<ISchedule> {
  // eslint-disable-next-line rxjs/no-ignored-replay-buffer,@typescript-eslint/naming-convention
  private readonly _schedule$ = new ReplaySubject<ISchedule>();

  get schedule$(): Observable<ISchedule> {
    return this._schedule$.asObservable();
  }

  constructor(private readonly _api: GuideOfferApiService) {}

  refresh(options: { date: string } & ITimeRangesRequestOptions): void {
    const { date, ...otherOptions } = options;

    this._api
      .getFreeTimeRanges$(date, otherOptions)
      .pipe(map(ranges => ({ ranges })))
      .subscribe(schedule => this._schedule$.next(schedule));
  }
}

export const GUIDE_AVAILABILITY_PROVIDERS = [
  {
    provide: SCHEDULE_PROVIDER,
    deps: [GuideOfferApiService],
    useClass: GuideAvailabilityProviderService
  }
];
