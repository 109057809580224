<div class="header">{{ platformName }} wiki</div>

<div class="term-category">
  <p class="term-category-name">{{ term?.category?.name }}</p>
</div>

<div class="term-name">{{ term?.name }}</div>

<div class="term-description" [innerHTML]="term?.description"></div>

<div class="footer">
  <app-button className="btn-main" routerLink="/wiki" i18n="@@blogItemWikiLinkButton">
    Go to {{ platformName }} wiki
  </app-button>
</div>
