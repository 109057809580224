import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UserMenuModule } from '@app/modules/user-menu/user-menu.module';

import { MembershipLayoutComponent } from './membership-layout.component';

@NgModule({
  imports: [CommonModule, RouterModule, UserMenuModule],
  declarations: [MembershipLayoutComponent]
})
export class MembershipLayoutModule {}
