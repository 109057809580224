import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {
  transform<T extends { name: string }>(items: T[], searchText: string): T[] {
    if (!items || !items.length) {
      return [];
    }

    const searchQuery = searchText ? searchText.trim().toLowerCase() : searchText;

    return searchQuery ? items.filter(user => user.name && user.name.toLowerCase().includes(searchQuery)) : items;
  }
}
