import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticServiceTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { ClientGuide } from '@app/core/users/types';
import { ClientSessionsActionsService } from '@app/screens/client/client-dashboard/services/client-sessions-actions.service';
import { SessionTypes } from '@app/shared/enums/session-types';
import { IClientSessionActionsMenuItem } from '@app/shared/interfaces/session';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';

import { ClientSessionsCommunicationsService } from '../../services/client-sessions-communications.service';
import { Guide, IClientGuideSession } from '../../types/types';

enum InvitationActions {
  Reschedule = 'reschedule',
  Decline = 'decline'
}

const declineOfferMsg = `Decline`;

@Component({
  selector: 'app-client-session-invitation',
  templateUrl: './client-session-invitation.component.html',
  styleUrls: ['../../styles/session.scss', '../../../../../../scss/sessions/sessions-invitations.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSessionInvitationComponent implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _actions: IClientSessionActionsMenuItem[] | null = null;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _invitation: IClientGuideSession;

  private readonly destroy$: Subject<void> = new Subject<void>();

  readonly SessionTypes = SessionTypes;

  @Input()
  set invitation(invitation: IClientGuideSession) {
    this._invitation = invitation;

    if (invitation.session.serviceType === SessionTypes.SESSION && !this._actions) {
      this._actions = [
        {
          icon: 'pui:x-circle',
          title: declineOfferMsg,
          className: 'icon-cancel',
          action: InvitationActions.Decline
        }
      ];
    } else {
      this._actions = null;
    }
  }

  get actions(): IClientSessionActionsMenuItem[] | null {
    return this._actions;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get invitation(): IClientGuideSession {
    return this._invitation;
  }

  get sessionClass(): string[] {
    return [
      'session-pending',
      this.invitation.session.isSessionFree ? 'free' : '',
      !!this.invitation && !!this.invitation.session && !!this.invitation.session.note ? 'has-comment' : ''
    ];
  }

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _clientSessionAction: ClientSessionsActionsService,
    private readonly _emitter: ClientSessionsCommunicationsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  accept(): void {
    this._emitter.acceptSessionOffer(this.invitation);

    this._clientSessionAction.sessionOfferSucceeded$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const { payRate, serviceType } = this.invitation.session;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const analyticsData: any = {
        servicePrice: payRate,
        source: AnalyticSourceTypes.INVITATION
      };

      if (serviceType === SessionTypes.GROUP_INSTANCE) {
        analyticsData.serviceType = AnalyticServiceTypes.GROUP_SESSION;
      }

      if (serviceType === SessionTypes.SESSION) {
        analyticsData.serviceType = AnalyticServiceTypes.INDIVIDUAL_SESSION;
      }

      this._analyticsService.event(InternalEvents.SERVICE_BOOK, analyticsData);
    });
  }

  reschedule(): void {
    this._emitter.rescheduleSession(this.invitation);
  }

  decline(): void {
    this._emitter.declineSessionOffer(this.invitation);
  }

  buildDirectChatId(user: ClientGuide | Guide): string {
    return buildDirectChatLinkId(user);
  }
}
