import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

import { DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER } from '../../services/session-template-default-values-provider.service';
import { SessionTemplateEditorService } from '../../services/session-template-editor.service';
import { SessionTemplateServerStoreService } from '../../services/session-template-server-store.service';

@Component({
  selector: 'app-session-template-builder',
  templateUrl: './session-template-builder.component.html',
  styleUrls: ['../../../../../../scss/guide-services/main-container.scss', './session-template-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DEFAULT_SESSION_TEMPLATE_VALUES_PROVIDER, SessionTemplateEditorService, SessionTemplateServerStoreService]
})
export class SessionTemplateBuilderComponent {
  readonly GuideServiceTypes = GuideServiceTypes;

  constructor(readonly builder: SessionTemplateEditorService) {}

  close(): void {
    this.builder.close();
  }
}
