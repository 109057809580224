import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GroupSessionModule, ModuleInstructor, SessionModule } from '@app/shared/interfaces/programs/client-programs';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { isActiveStatus } from '@app/shared/enums/session-statuses';

@Component({
  selector: 'app-session-module',
  templateUrl: './session-module.component.html',
  styleUrls: ['./session-module.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'session-module'
  }
})
export class SessionModuleComponent {
  @Input()
  // @ts-expect-error TS2564
  module: SessionModule | GroupSessionModule;

  @Output()
  book = new EventEmitter();

  @Output()
  reschedule = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  readonly GuideServiceTypes = GuideServiceTypes;

  get hosts(): ModuleInstructor[] {
    if (this.module.session && isActiveStatus(this.module.session.status)) {
      // @ts-expect-error TS2532
      return this.module?.hosts?.filter(host => host.id === this.module.session.guideId);
    }
    // @ts-expect-error TS2322
    return this.module.hosts;
  }
}
