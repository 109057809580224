/* eslint-disable */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class IntercomService {
  private readonly isBrowser: boolean;

  constructor(
    @Inject(GLOBAL_WINDOW) private readonly _browserWindow: Window | any,
    @Inject(PLATFORM_ID) private readonly _platform: any,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.isBrowser = isPlatformBrowser(this._platform);
  }

  init(): void {
    const INTERCOM_APP_ID = this._runtimeConfigService.get('INTERCOM_APP_ID');

    if (!INTERCOM_APP_ID) {
      return;
    }

    const window = this._browserWindow;

    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        // @ts-expect-error TS7006
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
          var x = d.getElementsByTagName('script')[0];
          // @ts-expect-error TS2531
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  // @ts-expect-error TS7006
  load(config): void {
    if (!this._runtimeConfigService.get('INTERCOM_APP_ID') && this._isIntercomExists()) {
      return;
    }
    try {
      this._browserWindow.Intercom('boot', config);
    } catch (e) {}
  }

  // @ts-expect-error TS7006
  update(config): void {
    if (!this._runtimeConfigService.get('INTERCOM_APP_ID') && this._isIntercomExists()) {
      return;
    }
    try {
      this._browserWindow.Intercom('update', config);
    } catch (e) {}
  }

  private _isIntercomExists(): boolean {
    return typeof this._browserWindow.Intercom === 'function';
  }

  openIntercom(): void {
    if (this.isBrowser && this._isIntercomExists()) {
      this._browserWindow.Intercom('show');
    }
  }
}
