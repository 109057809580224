import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { SessionTemplateHost } from '@app/screens/guide/guide-sessions-templates/types';
import { PuiDestroyService, PuiDrawerRef } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-add-hosts-drawer',
  templateUrl: './add-hosts-drawer.component.html',
  styleUrls: ['./add-hosts-drawer.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddHostsDrawerComponent implements AfterViewInit {
  readonly selectedHosts$ = new BehaviorSubject<IWorkspaceMember[]>([]);
  readonly workspacesMembers$ = this.workspacesService.members$.pipe(
    map(members => {
      return members
        .filter((member: IWorkspaceMember) => !!member.userId && member.role)
        .map((member: IWorkspaceMember) => ({ ...member, id: member.userId }));
    })
  );

  constructor(
    private readonly drawerRef: PuiDrawerRef<
      IWorkspaceMember[],
      {
        hosts: {
          selectedHosts: SessionTemplateHost[];
          search: string;
          hostsById: { [key: string]: unknown };
        };
      }
    >,
    private workspacesService: WorkspacesService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  ngAfterViewInit(): void {
    const data = this.drawerRef.data;
    if (data?.hosts) {
      this.selectedHosts$.next(
        data?.hosts?.selectedHosts.map(
          ({ userId }) => this.workspacesService.members.find(member => member.userId === userId)!
        )
      );
    }
  }

  save(): void {
    this.drawerRef.close(this.selectedHosts$.value);
  }

  onChangeHost(selectedHosts: IWorkspaceMember[]): void {
    this.selectedHosts$.next(selectedHosts);
  }
}
