import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClientNotesModule } from '@app/modules/client-notes/client-notes.module';
import { CurrentPaymentModule } from '@app/modules/current-payment/current-payment.module';
import { ExternalCalendarsAccountsModule } from '@app/modules/external-calendars-accounts';
import { GuideServiceModule } from '@app/modules/guide-service/guide-service.module';
import { PlatformSubscriptionsModule } from '@app/modules/platform-subscriptions/platform-subscriptions.module';
import { ServicesModule } from '@app/modules/services/services.module';
import { SessionActionResultModule } from '@app/modules/session-action-result/session-action-result.module';
import { SessionCancellationModule } from '@app/modules/session-cancellation/session-cancellation.module';
import { SearchInputModule } from '@app/modules/ui-kit/search-input/search-input.module';
import { EventStatusComponentModule } from '@app/shared/components/event-status/event-status.module';
import { SharedModule } from '@app/shared/shared.module';
import { PuiAvatarModule, PuiButtonModule, PuiIconModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { AvailableClientSessionComponent } from './components/available-client-session/available-client-session.component';
import { AvailableClientSessionsComponent } from './components/available-client-sessions/available-client-sessions.component';
import { ClientGuidesListComponent } from './components/client-guides-list/client-guides-list.component';
import { ClientSessionInvitationComponent } from './components/client-session-invitation/client-session-invitation.component';
import { ClientSessionInvitationsComponent } from './components/client-session-invitations/client-session-invitations.component';
import { ClientSubscriptionsComponent } from './components/client-subscriptions/client-subscriptions.component';
import { ExternalCalendarsWidgetComponent } from './components/external-calendars-widget/external-calendars-widget.component';
import { PastClientSessionComponent } from './components/past-client-session/past-client-session.component';
import { PastClientSessionsComponent } from './components/past-client-sessions/past-client-sessions.component';
import { ReschedulingClientSessionComponent } from './components/rescheduling-client-session/rescheduling-client-session.component';
import { SessionDetailsComponent } from './components/session-details/session-details.component';
import { UpcomingClientSessionComponent } from './components/upcoming-client-session/upcoming-client-session.component';
import { UpcomingClientSessionsComponent } from './components/upcoming-client-sessions/upcoming-client-sessions.component';
import { ClientGuideSessionRangeFormatterPipe } from './pipes/client-guide-session-range-formatter.pipe';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    CommonModule,
    ExternalCalendarsAccountsModule,
    FormsModule,
    NgbModule,
    RouterModule,
    EventStatusComponentModule,
    SessionActionResultModule,
    SessionCancellationModule,
    SharedModule,
    ServicesModule,
    SearchInputModule,
    ClientNotesModule,
    PlatformSubscriptionsModule,
    PlatformStyleModule,
    CurrentPaymentModule,
    PuiTypographyModule,
    PuiIconModule,
    GuideServiceModule,
    PuiButtonModule,
    PuiAvatarModule,
    TranslocoRootModule
  ],
  exports: [SessionDetailsComponent],
  declarations: [
    ClientGuidesListComponent,
    ClientSessionInvitationComponent,
    ClientSessionInvitationsComponent,
    ExternalCalendarsWidgetComponent,
    PastClientSessionComponent,
    PastClientSessionsComponent,
    ReschedulingClientSessionComponent,
    SessionDetailsComponent,
    UpcomingClientSessionComponent,
    UpcomingClientSessionsComponent,
    ClientSubscriptionsComponent,
    AvailableClientSessionsComponent,
    AvailableClientSessionComponent,
    ClientGuideSessionRangeFormatterPipe
  ]
})
export class ClientDashboardModule {}
