<dl>
  <pui-avatar
    class="user-photo"
    [avatarUrl]="notification.details?.user?.photo"
    [text]="(notification.details?.user?.firstName + ' ' + notification.details?.user?.lastName).trim()"></pui-avatar>
  <dt class="notification-title">
    <span
      class="notification-name"
      [ngClass]="{ 'client-name': notification.details?.quizClientsPolicy !== QuizClientPolicy.Author }"
      (click)="
        notification.details?.quizClientsPolicy !== QuizClientPolicy.Author &&
          showClientDetails(notification.details?.fromClientId)
      ">
      {{ notification.details?.user?.firstName }} {{ notification.details?.user?.lastName }}
    </span>
    <span class="notification-date">
      {{ notification.dateCreated | notificationDateFormatter }}
    </span>
  </dt>
  <dd>
    <span class="description-text">
      {{ notification.description }}

      <app-button className="btn-link" routerLink="/{{ formLink }}">
        ({{ notification.details?.title?.trim() }})
      </app-button>

      <ng-container *ngIf="notification.details?.quizClientsPolicy === QuizClientPolicy.Author">
        for {{ notification.details.session?.name }}
        <ng-container
          *ngIf="
            notification.details.session?.client &&
            notification.details.session?.serviceType === GuideServiceType.SESSION
          ">
          with {{ notification.details.session?.client | userName }}
        </ng-container>
      </ng-container>
    </span>
  </dd>
</dl>
