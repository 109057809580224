import { NotificationsService } from 'angular2-notifications';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticClientTypes,
  AnalyticServiceTypes,
  AnalyticSourceTypes,
  InternalEvents
} from '@app/core/analytics/types';
import { LocaleService } from '@app/core/locale/locale.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideRelation } from '@app/core/users/types';
import { Alert } from '@app/modules/alerts/types/alert';
import {
  DeleteClientModalComponent,
  IDeleteModalResponse
} from '@app/screens/guide/guide-programs/components/delete-client-modal/delete-client-modal.component';
import { GuideProgramAnswersService } from '@app/screens/guide/guide-programs/services/guide-program-answers.service';
import { GuideProgramStateService } from '@app/screens/guide/guide-programs/services/guide-program-state.service';
import { IUser } from '@app/shared/interfaces/user';
import { splitArrayByExistingUserId } from '@app/shared/utils/split-array';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GuideProgramClientsService } from '../../services/guide-program-clients.service';
import {
  ClientInvitation,
  ClientModuleProgress,
  ClientModuleStatuses,
  ClientUnEnrollType,
  GuideClientProgram,
  LocalProgramService,
  QuizModule
} from '../../types';
import { updateClientModuleProgressStatus } from '../../utils/client-progress-builder';
import {
  IModalResponse as IInvitationsModalResponse,
  InviteClientsModalComponent
} from '../invite-clients-modal/invite-clients-modal.component';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspace } from '@app/modules/workspaces/types';
import { UserService } from '@app/core';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-program-clients',
  templateUrl: './program-clients.component.html',
  styleUrls: [
    '../../../../../modules/guide-service-editor/common-styles/forms.scss',
    './program-clients.component.scss'
  ]
})
export class ProgramClientsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _dateTimeLocale: string;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _programName: string;

  // @ts-expect-error TS2564
  alert: Alert;

  /**
   * @deprecated Use this.clientModules$
   */
  clientModules: ClientModuleProgress[] = [];

  clients: GuideClientProgram[] = [];

  deletedClients: GuideClientProgram[] = [];

  disableAddNewClient = false;

  guideRelations: GuideRelation[] = [];

  invitedClients: GuideClientProgram[] = [];

  invitations: ClientInvitation[] = [];

  progress = {};

  // @ts-expect-error TS2564
  selectedClient: IUser;

  // @ts-expect-error TS2564
  selectedClientId: number;

  // @ts-expect-error TS2564
  selectedInvitedClient: ClientInvitation;

  canDownloadAnswers = false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  clientId = new BehaviorSubject<number>(null);

  activeWorkspace: IWorkspace | null = null;
  user: IUser | null = null;

  readonly clientModules$: Observable<ClientModuleProgress[]> = this.clientId.pipe(
    // distinctUntilChanged(),
    debounceTime(200),
    switchMap((clientId: number) =>
      this.clientsService.getClientProgress$(this.programId, clientId).pipe(filter(progress => !!progress))
    ),
    map(progress => progress.modules),
    takeUntil(this.destroy$)
  );

  @Input()
  editable = false;

  @Input()
  // @ts-expect-error TS2564
  programId: number;

  @Input()
  // @ts-expect-error TS7008
  programEvents;

  @Input()
  programLink = '';

  @Input()
  // @ts-expect-error TS7008
  programPolicy;

  @Input()
  services: LocalProgramService[] = [];

  @Input()
  // @ts-expect-error TS2564
  subscriptionDeactivated: boolean;

  get today(): string {
    return new Date().toLocaleDateString(this._dateTimeLocale);
  }
  constructor(
    readonly clientsService: GuideProgramClientsService,
    private guideClientsService: GuideClientsService,
    private modal: NgbModal,
    private programState: GuideProgramStateService,
    private notifications: NotificationsService,
    private analyticsService: AnalyticsService,
    private localeService: LocaleService,
    private programAnswersService: GuideProgramAnswersService,
    private activatedRoute: ActivatedRoute,
    private workspaceService: WorkspacesService,
    private user$: UserService,
    private cdr: ChangeDetectorRef
  ) {
    this._dateTimeLocale = this.localeService.getLocale().dateTimeLocale;
  }

  ngOnInit(): void {
    this.initAllClientsSub();
    this.initClientProgressSub();
    this.initNameSub();
    this.initProgramModulesSub();
    this.initClientsLimitAlert();
    this.initRelationsSub();

    this.clientsService.refresh(this.programId);

    // First update the clientId value based on query parameters in the URL.
    const clientIdFromQuery$: Observable<string> = this.activatedRoute.queryParams.pipe(
      take(1),
      map(({ clientId }) => clientId),
      filter(clientId => clientId !== undefined)
    );
    clientIdFromQuery$.pipe(takeUntil(this.destroy$)).subscribe(clientId => {
      this.selectClient(Number(clientId));
    });

    this.workspaceService.activeWorkspace$.pipe(takeUntil(this.destroy$)).subscribe(activeWorkspace => {
      this.activeWorkspace = activeWorkspace;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: IUser | undefined) => {
      this.user = user || null;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get canDeleteInvitation(): boolean {
    if (this.activeWorkspace?.type === WorkspacesTypes.SOLO) {
      return true;
    }

    if (this.activeWorkspace?.type === WorkspacesTypes.TEAM && this.workspaceService.isTeamAdmin) {
      return true;
    }

    if (this.activeWorkspace?.type === WorkspacesTypes.TEAM && this.user?.id === this.selectedInvitedClient.invitedBy) {
      return true;
    }

    return false;
  }

  private initRelationsSub(): void {
    this.guideClientsService.relations$.pipe(takeUntil(this.destroy$)).subscribe(relations => {
      this.guideRelations = relations;
    });
  }

  private initClientsLimitAlert(): void {
    this.guideClientsService.clientsLimitAlert.pipe(takeUntil(this.destroy$)).subscribe(alert => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      this.alert = alert;
      this.disableAddNewClient = !!alert;
    });
  }

  private initProgramModulesSub(): void {
    this.programState.modules$
      .pipe(
        map(modules => modules.some(module => module instanceof QuizModule)),
        takeUntil(this.destroy$)
      )
      .subscribe(value => (this.canDownloadAnswers = value));
  }

  private initNameSub(): void {
    this.programState.content$.pipe(takeUntil(this.destroy$)).subscribe(content => (this._programName = content.name));
  }

  private initClientProgressSub(): void {
    this.clientId
      .pipe(
        distinctUntilChanged(),
        switchMap((clientId: number) => {
          this.clientModules = [];

          return this.clientsService.getClientProgress$(this.programId, clientId).pipe(filter(progress => !!progress));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        progress => {
          this.clientModules = progress.modules;
          // @ts-expect-error TS2345
          this.progress[this.clientId.value] = this.calculateProgress(this.clientModules);
        },
        err => {
          throw new Error(err);
        }
      );
  }

  private initAllClientsSub(): void {
    this.clientsService.allClients$
      .pipe(takeUntil(this.destroy$))
      .subscribe(([clients, deleteClients, invitedClients, invitations]) => {
        // deleted
        this.deletedClients = deleteClients;

        // clients
        this.clients = clients;
        if (clients && clients.length) {
          const selectedClientId =
            this.selectedClientId && this.clients.some(client => client.id === this.selectedClientId)
              ? this.selectedClientId
              : this.clients[0].id;
          this.selectClient(selectedClientId);
        } else {
          this.selectedClientId = -1;
        }

        // invited contacts
        if (this.editable) {
          this.invitations = invitations;
        }

        // invited clients
        this.invitedClients = invitedClients;

        this.cdr.markForCheck();
      });
  }

  async addClient(): Promise<void> {
    if (this.subscriptionDeactivated) {
      return;
    }

    try {
      const { result, componentInstance } = this.modal.open(InviteClientsModalComponent, {
        size: 'lg',
        beforeDismiss: () => false
      });

      componentInstance.invitedClients = this.invitedClients;
      componentInstance.invites = this.invitations;
      componentInstance.guideRelations = this.guideRelations;
      componentInstance.enrolledClients = this.clients;
      componentInstance.disableAddNewClient = this.disableAddNewClient;
      componentInstance.type = 'program';
      componentInstance.canAddByEmail = !this.alert;

      const clientInvitations: IInvitationsModalResponse | null = await result;

      if (clientInvitations && clientInvitations.invitations && clientInvitations.invitations.length) {
        this.clientsService
          .sendInvitations$(this.programId, clientInvitations.invitations, clientInvitations.type)
          .pipe(
            tap(invitations => {
              this.invitations = invitations;
            }),
            switchMap(() => this.programState.settings$),
            takeUntil(this.destroy$)
          )
          .subscribe(settings => {
            const [internal, external] = splitArrayByExistingUserId(clientInvitations.invitations);

            const analyticsData: {
              servicePrice: number;
              serviceType: AnalyticServiceTypes.PROGRAM;
              source: AnalyticSourceTypes.SERVICE;
              numberOfClients?: number;
              clientType?: AnalyticClientTypes;
            } = {
              servicePrice: clientInvitations.type === 'program_prepaid' ? 0 : settings.price || 0,
              serviceType: AnalyticServiceTypes.PROGRAM,
              source: AnalyticSourceTypes.SERVICE
            };

            if (internal.length) {
              analyticsData.numberOfClients = internal.length;
              analyticsData.clientType = AnalyticClientTypes.INTERNAL;
              this.analyticsService.event(InternalEvents.CLIENT_INVITE, analyticsData);
            }

            if (external.length) {
              analyticsData.numberOfClients = external.length;
              analyticsData.clientType = AnalyticClientTypes.EXTERNAL;
              this.analyticsService.event(InternalEvents.CLIENT_INVITE, analyticsData);
            }
            this.clientsService.refresh(this.programId);
            this.cdr.detectChanges();
          });
      }
    } catch (error) {}
  }

  async deleteClient(user: GuideClientProgram): Promise<void> {
    try {
      const { result, componentInstance } = this.modal.open(DeleteClientModalComponent, {
        size: 'lg',
        beforeDismiss: () => false
      });

      componentInstance.programName = this._programName;
      componentInstance.client = user;

      if (this.programPolicy && this.programPolicy.disableContentAfterRemoval && user.status !== 'expelled') {
        componentInstance.isExpulsion = true;
      }

      const { deleteClient, unEnrollType }: IDeleteModalResponse = await result;
      if (deleteClient) {
        this.clientsService.unEnroll(this.programId, user.id, unEnrollType);
      }
    } catch (error) {}
  }

  completeClientModule(completionDetails: { readonly clientId: number; readonly moduleId: number }): void {
    const { clientId, moduleId } = completionDetails;
    this.clientsService
      .completeClientModule$(clientId, this.programId, moduleId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clientModules = this.clientModules.map(clientModule =>
          clientModule.id === moduleId ? updateClientModuleProgressStatus(clientModule, 'seen') : clientModule
        );
      });
  }

  convertDate(isoDate: string): string {
    return formatDate(isoDate, 'mediumDate', this._dateTimeLocale).replace(',', '');
  }

  deleteInvitation(): void {
    this.clientsService
      .deleteInvitation$(this.programId, this.selectedInvitedClient.inviteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(invites => (this.invitations = invites));
  }

  deleteInvitedClient(userId: number): void {
    this.clientsService.unEnroll(this.programId, userId, ClientUnEnrollType.FullDelete);
  }

  onCopied(): void {
    this.notifications.success(`Copied`);
  }

  onCopyFailed(): void {
    this.notifications.error(`Copy failed`);
  }

  onEmbeddableCodeCopied(): void {
    this.notifications.success(`Invitation link copied`);
  }

  onEmbeddableCodeCopyFailed(): void {
    this.notifications.error(`Invitation link copy failed`);
  }

  selectClient(
    /**
     * Default value will update current state
     */
    clientId: number = this.clientId.value
  ): void {
    if (!clientId) {
      return;
    }

    // @ts-expect-error TS2322
    // eslint-disable-next-line id-length
    this.selectedClient = this.clients.find(c => c.id === clientId);
    this.selectedClientId = clientId;

    this.clientId.next(clientId);
  }

  selectInvited(inviteId: number | null): void {
    // @ts-expect-error TS2322
    // eslint-disable-next-line id-length
    this.selectedInvitedClient = this.invitations.find(c => c.inviteId === inviteId);
  }

  downloadAnswers(programId: number): void {
    this.programAnswersService
      .downloadCSV$(programId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, 'answers.csv');
      });
  }

  // @ts-expect-error TS7031
  onDownloadClientModuleAnswers({ moduleId }): void {
    this.programAnswersService
      .downloadClientModuleCSV$(this.selectedClientId, this.programId, moduleId)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, `${this._programName}_${this.today}_${this.selectedClient.name}.csv`);
      });
  }

  private calculateProgress(clientModules: { status: ClientModuleStatuses }[]): number {
    if (!clientModules.length) {
      return 0;
    }

    const completeModulesCount = clientModules.reduce((num, { status }) => num + (status === 'seen' ? 1 : 0), 0);

    return completeModulesCount / clientModules.length;
  }
}
