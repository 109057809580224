import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IUser } from '@app/shared/interfaces/user';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { buildUsersGuideMapKey, getUserKey } from '@app/core/users/utils';
import config from '../config/config';
import { AuthService } from '../auth/services/auth.service';
import { GuideClientsService } from '../users/guide-clients.service';
import { ClientGuidesService } from '../users/client-guides.service';
import { ChatUser } from './types';

@Injectable()
export class ChatsUsersService {
  constructor(
    private _auth: AuthService,
    private _http: HttpClient,
    private _guideClients: GuideClientsService,
    private clientGuidesService: ClientGuidesService
  ) {}

  addGuideClient$(guide: Readonly<{ id: number; workspaceId: number }>): Observable<boolean> {
    return this._http
      .put(`${config.apiPath}/user/client/guides`, { guideId: guide.id, workspaceId: guide.workspaceId })
      .pipe(
        catchError(error => throwError({ error })),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((response: any) => !response.error)
      );
  }

  getUser$({ id, workspaceId }: { id: number | string; workspaceId?: number }): Observable<ChatUser | null> {
    if (this._auth.user.RoleId === UserRoles.GUIDE) {
      if (typeof id === 'string' || typeof id === 'undefined') {
        return this._guideClients.getUser$(id);
      }

      if (typeof id === 'number') {
        return this._guideClients.getClient$(id);
      }
    }

    if (this._auth.user.RoleId === UserRoles.CLIENT) {
      // @ts-expect-error TS2322
      return this.clientGuidesService.getUser$(buildUsersGuideMapKey({ id: id as number, workspaceId }) as string);
    }

    return of(null);
  }

  getContacts$(): Observable<GuideContact[]> {
    return this._guideClients.users$.pipe(
      map(users => users.filter(user => user.type === GuideRelationTypes.GUIDE_CONTACT || user.isNew))
    );
  }

  getUsers$(): Observable<ChatUser[]> {
    return this._auth.user.RoleId === UserRoles.GUIDE ? this._guideClients.clients$ : this.clientGuidesService.users$;
  }

  getUsersAsDictionary$(): Observable<{ [id: number]: IUser } | { [id: string]: IUser }> {
    return this.getUsers$().pipe(
      map(users => {
        return users.reduce((dict, user) => {
          const key = getUserKey(user);
          // @ts-expect-error TS7053
          dict[key] = user;
          return dict;
        }, {});
      })
    );
  }
}
