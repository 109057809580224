import { Injectable } from '@angular/core';
import { StyleBreakpointsProviderService } from '@app/modules/ui-kit/_base/_common/screen/services/style-breakpoints-provider.service';
import { combineLatest, Observable } from 'rxjs';
import { MOBILE_HIDDEN_ALERTS } from '@app/modules/alerts/const';
import { map } from 'rxjs/operators';
import { AlertKey, Alert } from '../types/alert';

import { AlertsStore } from './helpers/alerts-store';
import { AlertTemplates } from './helpers/alert-templates';

@Injectable()
export class AlertsService extends AlertsStore {
  constructor(private _styleBreakpointsProviderService: StyleBreakpointsProviderService) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  alerts$: Observable<Alert[]> = combineLatest([this.alerts$, this._styleBreakpointsProviderService.isMobile$]).pipe(
    map(([alerts, isMobile]: [Alert[], boolean]) => {
      return isMobile
        ? alerts.filter(({ template }: Pick<Alert, 'template'>) => !MOBILE_HIDDEN_ALERTS.includes(template))
        : alerts;
    })
  );

  close(id: number): void {
    this.deleteAlert(id);
  }

  closeByTemplate(key: AlertKey, smoothClose = false): void {
    if (smoothClose) {
      this.updateAlertsByTemplate(key, { isClosing: true });
    } else {
      this.deleteAlertsByTemplate(key);
    }
  }

  create(alert: Alert): void {
    this.addAlert(alert);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  template(key: AlertKey, data?: any) {
    // @ts-expect-error TS2345
    this.addAlert(AlertTemplates.get(key, data || null));
  }
}
