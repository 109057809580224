import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BlogAuthorGuide, BlogItemPodcast, IArticleSelection } from '../../types';
import { makeUriFromString } from '@app/screens/blog/utils';

@Component({
  selector: 'app-blog-item-podcast',
  templateUrl: './blog-item-podcast.component.html',
  styleUrls: ['./blog-item-podcast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogItemPodcastComponent {
  @Input()
  // @ts-expect-error TS2564
  podcast: BlogItemPodcast;

  @Output()
  select = new EventEmitter<IArticleSelection>();

  @Output()
  selectAuthor = new EventEmitter<string>();

  @HostListener('click')
  emitSelect(): void {
    this.select.emit({
      articleId: this.podcast.id,
      // @ts-expect-error TS2322
      authorUrl: this.podcast.author ? this.podcast.author.namedUrl : null
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get link() {
    return makeUriFromString(this.podcast.title, this.podcast.id);
  }

  get isPodcastAuthorGuide(): boolean {
    return !!this.podcast && !!this.podcast.author && this.podcast.author instanceof BlogAuthorGuide;
  }

  // eslint-disable-next-line id-length
  emitSelectAuthor(e: Event): void {
    e.stopPropagation();
    // @ts-expect-error TS2532
    this.selectAuthor.emit(this.podcast.author.namedUrl);
  }
}
