import { Inject, Injectable, OnDestroy, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { IntegrationService } from '../../interfaces/integration-service';
import { isPlatformBrowser } from '@angular/common';
import { ProfitwellConfig } from './profitwell.config';
import { AuthService } from '@app/core/auth/services';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { UserRoles } from '@app/shared/enums/user-roles';

declare const profitwell: (mode: 'start', options: { user_email?: string }) => void;

const runProfitwellScript = (email?: string): void => {
  if (!profitwell) {
    return;
  }

  if (!email) {
    profitwell('start', {});
    return;
  }

  profitwell('start', { user_email: email });
};

const createProfitwellScript = (
  window: Window,
  renderer: Renderer2,
  options: ProfitwellConfig
): HTMLScriptElement | null => {
  if (!window || !renderer) {
    return null;
  }

  const script: HTMLScriptElement = renderer.createElement('script');
  script.type = 'text/javascript';
  script.id = 'profitwell-js';
  script.setAttribute('data-pw-auth', options.pwAuth);
  script.async = true;
  renderer.appendChild(window.document.head, script);
  script.src = `https://public.profitwell.com/js/profitwell.js?auth=${options.pwAuth}`;

  // This is part of the profitwell integration
  // @ts-expect-error TS7015
  window['profitwell'] =
    // @ts-expect-error TS7015
    window['profitwell'] ||
    function () {
      // @ts-expect-error TS7015
      (window['profitwell'].q = window['profitwell'].q || []).push(
        window,
        document,
        'profitwell',
        'script',
        'https://public.profitwell.com/js/profitwell.js'
      );
    };

  return script;
};

@Injectable()
export class ProfitwellService implements IntegrationService, OnDestroy {
  // @ts-expect-error TS2564
  private readonly renderer: Renderer2;

  // @ts-expect-error TS2564
  private readonly window: Window;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(AuthService) private auth: AuthService,
    @Inject(PLATFORM_ID) platformId: Object,
    rendererFactory: RendererFactory2
  ) {
    if (isPlatformBrowser(platformId) && typeof window !== 'undefined') {
      this.renderer = rendererFactory.createRenderer(window.document, null);
      this.window = window;
    }
  }

  init(options: ProfitwellConfig): void {
    let lastUserRoleId: UserRoles;

    const profitwellScript = createProfitwellScript(this.window, this.renderer, options);

    if (!profitwellScript) {
      return;
    }

    profitwellScript.addEventListener('load', () => {
      // first run for anonymous
      runProfitwellScript();

      // runProfitwellScript after User logged in
      this.auth
        .onAuth()
        .pipe(
          tap(() => (lastUserRoleId = this.auth.getUserRoleId())),
          filter(() => lastUserRoleId === UserRoles.GUIDE),
          takeUntil(this.destroy$)
        )
        .subscribe(() => runProfitwellScript(this.auth.user.email));

      // runProfitwellScript after User logged out
      this.auth
        .onLogout()
        .pipe(
          filter(() => lastUserRoleId === UserRoles.GUIDE),
          takeUntil(this.destroy$)
        )
        .subscribe(() => runProfitwellScript());
    });

    profitwellScript.addEventListener('error', error => {
      console.warn('Error with Profitwell script', error);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
