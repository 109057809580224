<div class="modal-header">
  <app-button className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <app-program-modules-board
    class="wrapper"
    [program]="program"
    [moduleId]="selectedModuleId || -1"
    [isPreview]="true"></app-program-modules-board>
</div>
