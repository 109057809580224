import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GuideQuizService } from '@app/screens/guide/guide-programs/services/guide-quiz.service';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { GuideQuiz, IQuizDetails } from '@app/screens/guide/guide-programs/types';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-load-module-form',
  templateUrl: './load-module-form.component.html',
  styleUrls: ['./load-module-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuideQuizService]
})
export class LoadModuleFormComponent implements OnInit, OnDestroy {
  chosenQuizId: FormControl = new FormControl();

  quizzes: GuideQuiz[] = [];

  private destroy$ = new Subject();

  constructor(
    private readonly _guideQuizService: GuideQuizService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _drawerRef: PuiDrawerRef<IQuizDetails>
  ) {}

  uploadQuiz(): void {
    let quiz = null;
    if (this.chosenQuizId.value) {
      quiz = this.quizzes.find(quiz => quiz.id === +this.chosenQuizId.value);
    }
    // @ts-expect-error TS2345
    this._drawerRef.close(quiz);
  }

  ngOnInit(): void {
    this._guideQuizService
      .getGuideQuizzes$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(quizzes => {
        this.quizzes = quizzes;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
