import { QuizQuestionType } from '@app/core/quizzes/types';
import { ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { assertNever } from '@app/shared/utils/assertions';

import {
  ClientChoiceQuestionAnswer,
  ClientModuleProgress,
  ClientModuleStatuses,
  ClientQuestionAnswer,
  ContentModuleProgress,
  FileUploadQuestionProgress,
  GroupSessionModuleProgress,
  isContentModuleProgress,
  isGroupSessionModuleProgress,
  isQuizModuleProgress,
  isSessionModuleProgress,
  LongAnswerQuestionProgress,
  MultipleChoiceQuestionProgress,
  ProgramClientProgress,
  ProgramClientProgressResponse,
  ProgramModuleClientProgress,
  ProgramModuleQuizQuestionProgress,
  QuizChoiceQuestionProgress,
  QuizModuleProgress,
  QuizProgress,
  QuizProgressQuestion,
  SessionModuleProgress,
  ShortAnswerQuestionProgress,
  SignatureQuestionProgress,
  SingleChoiceQuestionProgress,
  UnansweredQuestionProgress
} from '../types';

export function buildQuizProgressQuestion(
  quizQuestionDetails: ProgramModuleQuizQuestionProgress
): QuizProgressQuestion {
  if (!quizQuestionDetails.answers || !quizQuestionDetails.answers.length) {
    return new UnansweredQuestionProgress({
      id: quizQuestionDetails.id,
      question: quizQuestionDetails.question,
      questionType: quizQuestionDetails.type
    });
  }

  if (quizQuestionDetails.answers[0].optionId) {
    const questionDetails = {
      id: quizQuestionDetails.id,
      question: quizQuestionDetails.question,
      questionType: quizQuestionDetails.type,
      options: quizQuestionDetails.options,
      answers: quizQuestionDetails.answers
        ? quizQuestionDetails.answers.map(
            answer =>
              new ClientChoiceQuestionAnswer({
                text: answer.text,
                optionText: answer.optionText,
                // @ts-expect-error TS2322
                isCorrect: answer.isCorrect,
                // @ts-expect-error TS2322
                optionId: answer.optionId,
                // @ts-expect-error TS2322
                order: answer.order
              })
          )
        : null
    };
    switch (quizQuestionDetails.type) {
      case QuizQuestionType.QUIZ:
        // @ts-expect-error TS2345
        return new QuizChoiceQuestionProgress(questionDetails);
      case QuizQuestionType.MULTIPLE_CHOICE:
        // @ts-expect-error TS2345
        return new MultipleChoiceQuestionProgress(questionDetails);
      case QuizQuestionType.SINGLE_CHOICE:
        // @ts-expect-error TS2345
        return new SingleChoiceQuestionProgress(questionDetails);
    }
  }

  if (quizQuestionDetails.type === QuizQuestionType.SIGNATURE) {
    return new SignatureQuestionProgress({
      id: quizQuestionDetails.id,
      question: quizQuestionDetails.question,
      questionType: quizQuestionDetails.type,
      // @ts-expect-error TS2322
      answers: quizQuestionDetails.answers.map(answer => ({ text: answer.text, file: answer.file }))
    });
  }

  if (quizQuestionDetails.type === QuizQuestionType.FILE_UPLOAD) {
    return new FileUploadQuestionProgress({
      id: quizQuestionDetails.id,
      question: quizQuestionDetails.question,
      questionType: quizQuestionDetails.type,
      // @ts-expect-error TS2322
      answers: quizQuestionDetails.answers.map(answer => ({ text: answer.text, file: answer.file }))
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TODO error TS2367
  if (quizQuestionDetails.type === QuizQuestionType.FILE_UPLOAD) {
    return new FileUploadQuestionProgress({
      id: quizQuestionDetails.id,
      question: quizQuestionDetails.question,
      questionType: quizQuestionDetails.type,
      // @ts-expect-error TS2322
      answers: quizQuestionDetails.answers.map(answer => ({ text: answer.text, file: answer.file }))
    });
  }

  if (quizQuestionDetails.type === QuizQuestionType.SHORT_ANSWER) {
    return new ShortAnswerQuestionProgress({
      id: quizQuestionDetails.id,
      question: quizQuestionDetails.question,
      questionType: quizQuestionDetails.type,
      // @ts-expect-error TS2322
      answers: quizQuestionDetails.answers
        ? quizQuestionDetails.answers.map(
            answer =>
              new ClientQuestionAnswer({
                // @ts-expect-error TS2322
                text: answer.text,
                file: answer.file
              })
          )
        : null
    });
  }

  return new LongAnswerQuestionProgress({
    id: quizQuestionDetails.id,
    question: quizQuestionDetails.question,
    questionType: quizQuestionDetails.type,
    // @ts-expect-error TS2322
    answers: quizQuestionDetails.answers
      ? quizQuestionDetails.answers.map(
          answer =>
            new ClientQuestionAnswer({
              // @ts-expect-error TS2322
              text: answer.text,
              file: answer.file
            })
        )
      : null
  });
}

export function buildProgramClientModuleProgress(
  clientModuleProgressDetails: ProgramModuleClientProgress
): ClientModuleProgress {
  switch (clientModuleProgressDetails.moduleType) {
    case ModuleTypes.CONTENT:
      return new ContentModuleProgress({
        id: clientModuleProgressDetails.id,
        order: clientModuleProgressDetails.order,
        title: clientModuleProgressDetails.title,
        status: clientModuleProgressDetails.status,
        // @ts-expect-error TS2322
        content: clientModuleProgressDetails.content,
        completionType: clientModuleProgressDetails.completionType
      });
    case ModuleTypes.QUIZ:
      return new QuizModuleProgress({
        id: clientModuleProgressDetails.id,
        order: clientModuleProgressDetails.order,
        title: clientModuleProgressDetails.title,
        status: clientModuleProgressDetails.status,
        completionType: clientModuleProgressDetails.completionType,
        // @ts-expect-error TS2322
        quiz: clientModuleProgressDetails.quiz
          ? new QuizProgress({
              date: clientModuleProgressDetails.quiz.date,
              status: clientModuleProgressDetails.quiz.status,
              questions: clientModuleProgressDetails.quiz.questions.map(buildQuizProgressQuestion)
            })
          : null
      });
    case ModuleTypes.GROUP_SESSION:
      return new GroupSessionModuleProgress({
        id: clientModuleProgressDetails.id,
        order: clientModuleProgressDetails.order,
        title: clientModuleProgressDetails.title,
        status: clientModuleProgressDetails.status,
        completionType: clientModuleProgressDetails.completionType,
        sessionTemplate: clientModuleProgressDetails.sessionTemplate,
        serviceId: clientModuleProgressDetails.serviceId!,
        guideId: clientModuleProgressDetails?.guideId,
        eventId: clientModuleProgressDetails?.eventId,
        service: clientModuleProgressDetails.service,
        sessionId: clientModuleProgressDetails.sessionId,
        session: clientModuleProgressDetails.session
      });
    case ModuleTypes.SESSION:
      return new SessionModuleProgress({
        id: clientModuleProgressDetails.id,
        order: clientModuleProgressDetails.order,
        title: clientModuleProgressDetails.title,
        status: clientModuleProgressDetails.status,
        completionType: clientModuleProgressDetails.completionType,
        serviceId: clientModuleProgressDetails.serviceId!,
        guideId: clientModuleProgressDetails?.guideId,
        eventId: clientModuleProgressDetails?.eventId,
        sessionTemplate: clientModuleProgressDetails.sessionTemplate,
        service: clientModuleProgressDetails.service,
        sessionId: clientModuleProgressDetails.sessionId,
        session: clientModuleProgressDetails.session
      });
    default:
      // @ts-expect-error TS2322
      return null;
  }
}

export function buildProgramClientProgress(
  programClientProgressDetails: ProgramClientProgressResponse
): ProgramClientProgress {
  return new ProgramClientProgress({
    modules: programClientProgressDetails.modules.map(buildProgramClientModuleProgress)
  });
}

export function updateClientModuleProgressStatus(
  clientModuleProgress: ClientModuleProgress,
  status: ClientModuleStatuses
): ClientModuleProgress {
  if (isContentModuleProgress(clientModuleProgress)) {
    return new ContentModuleProgress({
      ...clientModuleProgress,
      status
    });
  }

  if (isQuizModuleProgress(clientModuleProgress)) {
    return new QuizModuleProgress({
      ...clientModuleProgress,
      status
    });
  }

  if (isGroupSessionModuleProgress(clientModuleProgress)) {
    return new GroupSessionModuleProgress({
      ...clientModuleProgress,
      status
    });
  }

  if (isSessionModuleProgress(clientModuleProgress)) {
    return new SessionModuleProgress({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ...(<SessionModuleProgress>clientModuleProgress),
      status
    });
  }

  return assertNever(clientModuleProgress, '"moduleType"-property not found in:');
}
