import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { QuizService } from '@app/core/quizzes/quiz.service';
import {
  GuideQuiz,
  IClientQuiz,
  QuizAnswer,
  QuizQuestion,
  QuizQuestionOption,
  QuizQuestionType,
  QuizValidationType
} from '@app/core/quizzes/types';
import { NotificationsService } from 'angular2-notifications';
import { FormService } from '@app/core';
import { ChatsService } from '@app/core/chat/chats.service';
import { DirectChatSummary } from '@app/core/chat/types';
import { isChoiceQuestion } from '@app/screens/client/client-programs/utils';

@Component({
  selector: 'app-quiz-answers',
  templateUrl: './quiz-answers.component.html',
  styleUrls: ['./quiz-answers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizAnswersComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  // @ts-expect-error TS2564
  canSubmit: boolean;

  // @ts-expect-error TS2564
  clientQuiz: GuideQuiz;
  // @ts-expect-error TS2564
  quizTemplate: IClientQuiz;

  editMode = false;

  // @ts-expect-error TS2564
  form: FormGroup;

  // @ts-expect-error TS2564
  guideName: string;

  // @ts-expect-error TS2564
  questionsById: { [key: number]: QuizQuestion };

  quizChatId = '';

  ready = false;

  // @ts-expect-error TS2564
  selectedSlot: number;

  submitAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  submitDisabled = false;

  answerIsSaving = false;

  validationType: QuizValidationType = QuizValidationType.AT_LEAST_ONE_ANSWER_REQUIRED;

  get isSaved(): boolean {
    if (!this.canEdit) {
      return true;
    }
    return !this.editMode && this.canEdit && this.canSubmit;
  }

  get canEdit(): boolean {
    return true;
  }

  get notAnswered(): boolean {
    // @ts-expect-error TS2532
    return this.clientQuiz.userSchedule.answers.every((answer: QuizAnswer) => {
      const { type }: Pick<QuizQuestion, 'type'> = this.questionsById[answer.questionId];
      let validation: boolean;
      switch (type) {
        case QuizQuestionType.SHORT_ANSWER:
        case QuizQuestionType.LONG_ANSWER:
        case QuizQuestionType.FILE_UPLOAD:
          validation = !answer.text || !answer.text.trim();
          break;
        case QuizQuestionType.MULTIPLE_CHOICE:
        case QuizQuestionType.SINGLE_CHOICE:
        case QuizQuestionType.QUIZ:
          validation = !!answer.optionId;
          break;
        case QuizQuestionType.TEXT:
          validation = true;
          break;
      }
      // @ts-expect-error TS2454
      return validation;
    });
  }

  constructor(
    private quizService: QuizService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private notificationService: NotificationsService,
    private formService: FormService,
    @Inject(GLOBAL_WINDOW) private _browserWindow: Window,
    private route: ActivatedRoute,
    private _chat: ChatsService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.handleWindowUnload = this.handleWindowUnload.bind(this);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({ answers: [[], []] });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(response => {
      // QuizQuestionType.TEXT questions don't require answers
      // @ts-expect-error TS7006
      const answersWhichShouldHaveAnswer = response.answers.filter(answer => answer.type !== QuizQuestionType.TEXT);
      let submitAvailable: boolean;

      // Form with only QuizQuestionType.TEXT questions can be submitted without answering
      if (!answersWhichShouldHaveAnswer.length) {
        submitAvailable = true;
      } else {
        const choiceAnswersAreOK = this.areChoiceAnswersOK(answersWhichShouldHaveAnswer);
        const answersWithTextAreOK = this.areAnswersWithTextOK(answersWhichShouldHaveAnswer);
        const miscellaneousAnswersAreOK = this.areMiscellaneousAnswersOK(answersWhichShouldHaveAnswer);

        // We use || condition, since we just can't submit empty form
        // ( except the case, when the quiz contains only QuizQuestionType.TEXT questions )
        submitAvailable = choiceAnswersAreOK || answersWithTextAreOK || miscellaneousAnswersAreOK;
      }
      this.submitAvailable$.next(submitAvailable);
    });
    const routeParam$: Observable<ParamMap> = this.route.paramMap;

    routeParam$
      .pipe(
        take(1),
        // @ts-expect-error TS2531
        switchMap(i => this.quizService.getGuideQuiz(+i.get('id'))),
        finalize(() => (this.ready = true)),
        takeUntil(this.destroy$)
      )
      .subscribe(quiz => this.loadClientQuiz(quiz));

    combineLatest([this._chat.chatsSummaries$, routeParam$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([chats, routeParam]) => {
        // @ts-expect-error TS2531
        const quizId = +routeParam.get('id');
        const chat = (chats as DirectChatSummary[]).find(_chat => _chat.user && Math.abs(_chat.user.id) === quizId);
        if (chat) {
          this.quizChatId = chat.id;
        }

        this.cdr.markForCheck();
      });

    // @ts-expect-error TS2531
    // eslint-disable-next-line id-length
    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe(q => (this.selectedSlot = +q.get('scheduleId')));
  }

  private areChoiceAnswersOK(
    answersWhichShouldHaveAnswer: { answer: { options: [] }; type: QuizQuestionType }[]
  ): boolean {
    const choiceAnswers = answersWhichShouldHaveAnswer.filter(answer => isChoiceQuestion(answer.type));

    if (choiceAnswers.length) {
      return this.validationType === QuizValidationType.AT_LEAST_ONE_ANSWER_REQUIRED
        ? choiceAnswers.some(answer => answer.answer.options.length)
        : choiceAnswers.every(answer => answer.answer.options.length);
    }
    return false;
  }

  private areAnswersWithTextOK(
    answersWhichShouldHaveAnswer: { answer: { text: string }; type: QuizQuestionType }[]
  ): boolean {
    const answersWithText = answersWhichShouldHaveAnswer.filter(
      answer => answer.type === QuizQuestionType.SHORT_ANSWER || answer.type === QuizQuestionType.LONG_ANSWER
    );

    if (answersWithText.length) {
      return this.validationType === QuizValidationType.AT_LEAST_ONE_ANSWER_REQUIRED
        ? answersWithText.some(answer => answer.answer.text !== null && answer.answer.text !== '')
        : answersWithText.every(answer => answer.answer.text !== null && answer.answer.text !== '');
    }
    return false;
  }

  private areMiscellaneousAnswersOK(
    answersWhichShouldHaveAnswer: { answer: { text: string }; type: QuizQuestionType }[]
  ): boolean {
    const miscellaneousAnswers = answersWhichShouldHaveAnswer.filter(
      answer => answer.type === QuizQuestionType.FILE_UPLOAD || answer.type === QuizQuestionType.SIGNATURE
    );

    if (miscellaneousAnswers.length) {
      return this.validationType === QuizValidationType.AT_LEAST_ONE_ANSWER_REQUIRED
        ? miscellaneousAnswers.some(answer => answer.answer.text !== null && answer.answer.text !== '')
        : miscellaneousAnswers.every(answer => answer.answer.text !== null && answer.answer.text !== '');
    }
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadClientQuiz(clientQuiz: GuideQuiz): void {
    this.quizTemplate = clientQuiz.quizTemplate;
    this.clientQuiz = clientQuiz;
    this.questionsById = {};

    clientQuiz.questions?.forEach(i => (this.questionsById[i.id] = i));

    this.updateForm();
    this.cdr.markForCheck();
  }

  submit(): void {
    this.answerIsSaving = true;
    this.submit$()
      // @ts-expect-error TS2554
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        // @ts-expect-error TS2345
        response => {
          this.submitDisabled = false;
          if (!response) {
            return;
          }
          this.notificationService.success(`Success', 'Saved`);
          this.editMode = false;
          this.answerIsSaving = false;

          this.cdr.markForCheck();
        },
        () => {
          this.submitDisabled = false;
          this.notificationService.error(`Error', 'An error occurred`);
          this.answerIsSaving = false;

          this.cdr.markForCheck();
        }
      );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  submit$() {
    if (!this.form.value.answers) {
      this.notificationService.error(`There is nothing to save`);
      return of(null);
    }

    const userAnswers = this.form.value.answers
      // @ts-expect-error TS7006
      .filter(questionAnswer => !!questionAnswer.answer)
      // @ts-expect-error TS7031
      .map(({ id: questionId, answer: { text, options } }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const answer = { questionId, text } as any;

        if (options && options.length) {
          // @ts-expect-error TS7031
          answer.options = options.map(({ id }) => id);
        }

        return answer;
      });

    return this.quizService.saveGuideAnswers({
      quizId: this.clientQuiz.templateId,
      scheduleId: this.clientQuiz.userSchedule.id,
      answers: userAnswers
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  private handleWindowUnload(event: Event): void {
    if (!this.isSaved) {
      event.preventDefault();
    }
  }

  private updateForm(): void {
    const answers = this.clientQuiz.userSchedule?.answers;
    const questionAnswers = this.clientQuiz.questions.map((i: QuizQuestion) => {
      // @ts-expect-error TS2322
      const currentQuestionAnswers: QuizAnswer[] = answers?.filter((j: QuizAnswer) => j.questionId === i.id);
      // @ts-expect-error TS2322
      const currentQuestionAnswer: QuizAnswer = currentQuestionAnswers?.find(Boolean);
      if (i.options) {
        const answer = currentQuestionAnswers.map((ans: QuizAnswer) => ans.optionId);
        const answerText = currentQuestionAnswers.find((ans: QuizAnswer) => ans.text);
        const options = i.options.filter((option: QuizQuestionOption) => answer.includes(option.id));
        return {
          ...i,
          answer: {
            text: answerText ? answerText.text : null,
            questionId: i.id,
            type: i.type,
            options
          },
          time: currentQuestionAnswer ? DateTime.fromISO(currentQuestionAnswer.time) : null
        };
      }

      // if quiz does not have options it should behave like long answer
      if (i.type === QuizQuestionType.QUIZ) {
        i.type = QuizQuestionType.LONG_ANSWER;
      }

      return {
        ...i,
        answer: { text: currentQuestionAnswer ? currentQuestionAnswer.text : null },
        time: currentQuestionAnswer ? DateTime.fromISO(currentQuestionAnswer.time) : null
      };
    });

    this.canSubmit = this.canEdit;

    this.editMode = this.notAnswered;
    this.form.setValue({ answers: questionAnswers });
  }
}
