import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';
import { CurrencyService } from '@app/core/currency/currency.service';
import { CurrencyPipe } from '@angular/common';
import { getDefaultFragments, getDefaultTemplates } from '../utils/get-defaults';
import { platformDateTime } from '../functions/platform-datetime';
import {
  AnyDate,
  DateTimeOptions,
  DateTimeRangeResolverOptions,
  DateTimeResolverOptions,
  Fragment,
  PlatformStyleSchema,
  SingleTemplate,
  Template
} from '../model/interfaces';

export const PLATFORM_STYLE_CONFIG = new InjectionToken<unknown>('PlatformStyleConfig');

@Injectable()
export class PlatformStyle {
  private LOCALE;

  private TIMEZONE;

  get locale(): ILocale {
    return this.LOCALE;
  }

  defaultCurrency = this.currencyService.defaultCurrency;

  defaultCurrencySign = this.currencyService.defaultCurrencySign;

  constructor(
    @Inject(PLATFORM_STYLE_CONFIG) private config: PlatformStyleSchema,
    private _localeService: LocaleService,
    private currencyService: CurrencyService,
    private currencyPipe: CurrencyPipe
  ) {
    this.LOCALE = this._localeService.getLocale();
    this.TIMEZONE = new Date().getTimezoneOffset();
  }

  datetime(value: AnyDate, template: SingleTemplate | Fragment[], templateOptions?: DateTimeOptions): string {
    const localeParams = this.LOCALE;
    const timezone = templateOptions && templateOptions.timezone;
    const resolveOptions: DateTimeResolverOptions = {
      value,
      templateOptions: { ...templateOptions, localeParams, timezone },
      fragments: getDefaultFragments(this.LOCALE, this.config),
      templates: getDefaultTemplates(this.LOCALE, this.config)
    };

    return platformDateTime(template, resolveOptions);
  }

  datetimeRange(value: AnyDate[], template: Template, templateOptions?: DateTimeOptions): string {
    const localeParams = this.LOCALE;
    const timezone = templateOptions?.timezone ?? this.TIMEZONE;

    const params: DateTimeRangeResolverOptions = {
      value,
      templateOptions: {
        ...templateOptions,
        localeParams,
        // @ts-expect-error TS2322
        timezone
      }
    };

    return platformDateTimeRange(template, params);
  }

  setLocale(locale: ILocale): PlatformStyle {
    if (locale) {
      this.LOCALE = locale;
    }
    return this;
  }

  setTimezone(timezone: string): PlatformStyle {
    if (timezone) {
      // @ts-expect-error TS2322
      this.TIMEZONE = timezone;
    }
    return this;
  }

  transformCurrency(value: string | number): string {
    return this.currencyPipe.transform(+value, this.defaultCurrency, this.defaultCurrencySign, '1.0-2') || '';
  }
}
