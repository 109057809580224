<div class="modal-header">
  <h4
    data-qa-id="program-cover-modal-set-program-cover-title"
    class="modal-title"
    i18n="@@setProgramCover"
    *ngIf="windowTitle === 'program'">
    Set program cover
  </h4>
  <h4
    data-qa-id="program-cover-modal-set-session-cover-title"
    class="modal-title"
    i18n="@@setSessionCover"
    *ngIf="windowTitle === 'session'">
    Set session cover
  </h4>
  <h4
    data-qa-id="program-cover-modal-set-package-cover-title"
    class="modal-title"
    i18n="@@setPackageCover"
    *ngIf="windowTitle === 'package'">
    Set package cover
  </h4>
  <app-button data-qa-id="program-cover-modal-close-button" className="close" (click)="dismiss()"></app-button>
</div>

<div class="modal-body">
  <image-cropper
    #imageCropper
    class="image-cropper"
    data-qa-id="program-cover-modal-image-cropper"
    outputType="base64"
    [maintainAspectRatio]="true"
    [format]="imageFormat"
    [imageQuality]="coverVariantsSettings[variantIndex]?.quality"
    [resizeToWidth]="coverVariantsSettings[variantIndex]?.resizeToWidth"
    [aspectRatio]="coverVariantsSettings[variantIndex]?.aspectRatio"
    [imageChangedEvent]="event"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"></image-cropper>
</div>

<div class="modal-footer">
  <app-button
    data-qa-id="program-cover-modal-next-button"
    buttonType="button"
    className="btn-primary btn-md"
    (click)="next()">
    <ng-template [ngIf]="variantIndex < coverVariantsSettings.length" [ngIfElse]="save">
      <ng-container i18n="@@next">Next</ng-container>
    </ng-template>
    <ng-template #save i18n="@@save">Save</ng-template>
  </app-button>
  <app-button
    data-qa-id="program-cover-modal-cancel-button"
    buttonType="button"
    className="btn-link"
    (click)="cancel()"
    i18n="@@cancel">
    Cancel
  </app-button>
</div>
