import { locale as env } from '@env/locale.main';
import { ILocale } from '@env/locale.interface';
import {
  DEFAULT_FRAGMENTS,
  DEFAULT_TEMPLATES,
  FragmentsRecord,
  PlatformStyleSchema,
  TemplatesRecord
} from '../model/interfaces';
import { PLATFORM_STYLE_SCHEMA } from '../config';

function getDefaultLocale(): ILocale {
  return env.en;
}

function getDefaultFragments(
  locale: ILocale = getDefaultLocale(),
  schema: PlatformStyleSchema = PLATFORM_STYLE_SCHEMA
): FragmentsRecord {
  const customFragments =
    (schema &&
      schema[locale.dateTimeLocale] &&
      schema[locale.dateTimeLocale].datetime &&
      schema[locale.dateTimeLocale].datetime.fragments) ||
    {};

  return { ...DEFAULT_FRAGMENTS, ...customFragments };
}

function getDefaultTemplates(
  locale: ILocale = getDefaultLocale(),
  schema: PlatformStyleSchema = PLATFORM_STYLE_SCHEMA
): TemplatesRecord {
  const customTemplates =
    (schema &&
      schema[locale.dateTimeLocale] &&
      schema[locale.dateTimeLocale].datetime &&
      schema[locale.dateTimeLocale].datetime.templates) ||
    {};

  return { ...DEFAULT_TEMPLATES, ...customTemplates };
}

export { getDefaultLocale, getDefaultFragments, getDefaultTemplates };
