<div class="availability" *ngIf="availability">
  <span
    pui-typography="{{ accentName ? 'body-s-bold' : 'body-s' }}"
    class="availability__name"
    [class.availability__name--accent]="accentName"
    >{{ availability.name
    }}<ng-container *ngIf="availability.defaultSchedule && showNamePostfix">
      <span class="availability__name-postfix">
        {{ isOwnAvailability ? '(my default)' : '(default)' }}
      </span>
    </ng-container></span
  >
  <div pui-typography="body-s" class="availability__time">
    <span *ngFor="let day of workingDates; let last = last">
      <ng-container *ngFor="let weekDay of day.days; let lastDay = last">
        <ng-container *ngIf="weekDay !== 'Weekdays'; else weekdays"
          ><span>{{ weekDay }}{{ lastDay ? ': ' : ', ' }}</span></ng-container
        >
      </ng-container>

      <ng-container *ngFor="let slot of day.values; let lastSlot = last">
        <span>
          {{ slot }}
          <ng-container *ngIf="!lastSlot">{{ ' - ' }}</ng-container>
        </span>
      </ng-container>

      <span>
        <ng-container *ngIf="last; else comma">({{ day.timezone }})</ng-container>
      </span>
    </span>
  </div>
</div>

<ng-template #weekdays>
  <span i18n="@@availabilityWeekDays">Weekdays: </span>
</ng-template>

<ng-template #comma>
  {{ ', ' }}
</ng-template>
