import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@awarenow/profi-ui-core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WindowManagerService<Message = unknown> {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  open(type: string, ...args: Parameters<typeof Window.prototype.open>): Observable<[MessageEvent<Message>, Window]> {
    const proxy = this.window.open(...args);

    // @ts-expect-error TS2322
    return fromEvent<MessageEvent<Message>>(proxy, 'message').pipe(
      filter((event: MessageEvent) => event.data.type === type && event.origin === this.window.location.origin),
      map(event => [event, proxy])
    );
  }

  // @ts-expect-error TS7006
  postMessage(type, message: Message) {
    this.window.postMessage({ type, ...message });
  }
}
