import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentWithLoader } from '@app/modules/ui-kit/_base/ui-loader/abs';

// This will be improved
@Component({
  selector: 'app-element-loading',
  templateUrl: './element-loading.component.html',
  styleUrls: ['./element-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementLoadingComponent extends AbsComponentWithLoader {}
