import { Injectable, Injector } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

import { AuthService } from '@app/core/auth/services/auth.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { Observable, race, timer } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';

@Injectable()
export class CanBecomeAGuideGuard implements CanActivate, CanLoad {
  constructor(private _injector: Injector, private _router: Router) {}

  canActivate(): Observable<boolean> {
    return this.checkIfCanBecomeAGuide();
  }

  canLoad(): Observable<boolean> {
    return this.checkIfCanBecomeAGuide();
  }

  checkIfCanBecomeAGuide(): Observable<boolean> {
    const auth = this._injector.get(AuthService);

    return race(auth.onAuth(), timer(500)).pipe(
      mapTo(!auth.isAuthorized || (auth.user.RoleId === UserRoles.CLIENT && !auth.user.hasAlternativeProfile)),
      tap(canBecomeAGuide => {
        if (!canBecomeAGuide) {
          this._router.navigate(['/dashboard']);
        }
      }),
      take(1)
    );
  }
}
