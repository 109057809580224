import { IDiscount } from '@app/shared/interfaces/discount';
import { User } from '@app/shared/interfaces/user';
import { UserRoles } from '@app/shared/enums/user-roles';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IUserInfo {
  id: number;
  role?: number;
  bot?: boolean;
  isBlocked?: boolean;
}

class ChatUserBrief extends User {
  bot?: boolean;

  botType?: string;

  isBlocked?: boolean;

  role?: UserRoles;

  key?: string;

  // @ts-expect-error TS7006
  constructor(profile) {
    super(profile.id, profile.firstName, profile.lastName, profile.photo, profile.isOnline, profile.lastTimezone);

    this.bot = profile.bot;
    this.botType = profile.botType;
    this.isBlocked = profile.isBlocked;
    this.role = profile.role;
  }
}

export class ChatUserDetailed extends ChatUserBrief {
  about?: string;

  discount?: IDiscount;

  freeFirstSession?: boolean;

  lastTimezone?: string;

  namedUrl?: string;

  parent?: number;

  pricePerHour?: number;

  specialization?: string;

  email?: string;

  key?: string;

  get profileLink(): string | null | undefined {
    return this.namedUrl || this.id + '';
  }

  // @ts-expect-error TS7006
  constructor(profile) {
    super(profile);

    // @ts-expect-error TS2322
    this.pricePerHour = profile.pricePerHour ? +profile.pricePerHour : null;
    this.freeFirstSession = profile.freeFirstSession;
    this.namedUrl = profile.namedUrl;
    this.about = profile.about;
    this.specialization = profile.specialization;
    this.discount = profile.discount;
    this.lastTimezone = profile.lastTimezone;
    this.parent = profile.parent;
    this.email = profile.contacts?.email;
    this.workspaceId = profile.workspaceId;
  }

  clone(): ChatUserDetailed {
    const constructorArgs = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      photo: this.photo,
      isOnline: this.isOnline,
      lastTimezone: this.lastTimezone,
      bot: this.bot,
      botType: this.botType,
      parent: this.parent,
      email: this.email,
      workspaceId: this.workspaceId
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    if (this.pricePerHour) {
      constructorArgs.pricePerHour = this.pricePerHour;
    }

    if (this.freeFirstSession) {
      constructorArgs.freeFirstSession = this.freeFirstSession;
    }

    if (this.namedUrl) {
      constructorArgs.namedUrl = this.namedUrl;
    }

    if (this.about) {
      constructorArgs.about = this.about;
    }

    if (this.specialization) {
      constructorArgs.specialization = this.specialization;
    }

    if (this.discount) {
      constructorArgs.discount = this.discount;
    }

    if (this.email) {
      constructorArgs.email = this.email;
    }

    if (this.workspaceId) {
      constructorArgs.workspaceId = this.workspaceId;
    }

    return new ChatUserDetailed(constructorArgs);
  }
}

export type ChatUser = ChatUserBrief | ChatUserDetailed;

export function isChatUserDetailed(user: ChatUser): user is ChatUserDetailed {
  return user instanceof ChatUserDetailed;
}
