import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SessionTypes } from '@app/shared/enums/session-types';

@Component({
  selector: 'app-chat-service-separator',
  templateUrl: './chat-service-separator.component.html',
  styleUrls: ['./chat-service-separator.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'ui-app-chat-service-separator' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatServiceSeparatorComponent {
  @Input()
  // @ts-expect-error TS2564
  service: { title: string; serviceType: SessionTypes };
}
