<div *ngFor="let slot of items" class="time-slot-container" [ngClass]="{ 'time-slot-container--modal': isModal }">
  <ng-container
    *ngIf="
      slot.guide && (slot.guide.firstName || slot.guide.lastName);
      then detailedView;
      else minimalisticView
    "></ng-container>
  <ng-template #detailedView>
    <div
      class="time-slot--detailed"
      [attr.data-qa-id]="'time-slot-detailed-' + slot?.label"
      [class.active]="itemsComparator(time, slot)"
      (click)="selectTime(slot)"
      role="button"
      aria-pressed="false">
      <p class="slot-label">
        {{ slot?.label }}
      </p>
      <p class="slot-provider">
        {{ slot.guide | userName }}
      </p>
    </div>
  </ng-template>
  <ng-template #minimalisticView>
    <div
      class="time-slot"
      [attr.data-qa-id]="'time-slot-' + slot?.label"
      [class.active]="itemsComparator(time, slot)"
      (click)="selectTime(slot)"
      role="button"
      aria-pressed="false">
      {{ slot?.label }}
    </div>
  </ng-template>
</div>
