import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseMarkdownLink'
})
export class ParseMarkdownLinkPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  transform(value: any, ...args: any[]): { text: string; url: string } {
    if (value) {
      const match = /\[(.+)]\((https?:\/\/[^\s]+)(?: "(.+)")?\)|(https?:\/\/[^\s]+)/gi.exec(value);
      // @ts-expect-error TS2488
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [full, text, url] = match;
      return { text, url };
    }
    return { text: '', url: '' };
  }
}
