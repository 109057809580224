import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SessionEvent } from '@app/core/shared-event-actions/types';

@Component({
  selector: 'app-session-result-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionDetailsComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _session: SessionEvent;

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _sessionLink: string | null;

  @Input()
  set session(value: SessionEvent) {
    this._session = value;

    if (value) {
      this._sessionLink = value.links && value.links.length ? value.links[0].startUrl || value.links[0].joinUrl : null;
    }
  }

  get session(): SessionEvent {
    return this._session;
  }

  get sessionLink(): string | null {
    return this._sessionLink;
  }
}
