import { config } from '@app/core';

const API_PATH = config.apiPath;

export const ALL_BLOGS_ARTICLES_URL = `${API_PATH}/public/blogs/articles`;
export const LATEST_ARTICLES_URL = `${ALL_BLOGS_ARTICLES_URL}/latest`;
export const USER_BLOG_ARTICLES_URL = `${API_PATH}/public/blogs/users`;

export const BLOG_ARTICLE_URL = `${API_PATH}/public/blogs/articles`;
export const BLOG_ARTICLE_RELATED_ARTICLES_URL = `${API_PATH}/public/blogs/articles/related`;

export const BLOG_TAGS_URL = `${API_PATH}/public/blogs/tags`;
export const BLOG__ARTICLE_RELATED_TAGS_URL = `${BLOG_TAGS_URL}/related`;

export const BLOG_EDITOR_ARTICLE_URL = `${API_PATH}/user/common/blog`;
export const BLOG_IMAGE_UPLOAD_URL = `${API_PATH}/user/common/blog/upload-image`;
export const BLOG_EDITOR_TAGS_URL = `${API_PATH}/user/common/tags`;
export const BLOG_SEND_OUT = `${API_PATH}/user/common/blog/send-out`;

export const ALL_BUSINESS_BLOG_ARTICLES_URL = `${API_PATH}/public/blogs/business/articles`;
export const LATEST_BUSINESS_ARTICLES_URL = `${ALL_BUSINESS_BLOG_ARTICLES_URL}/latest`;
export const USER_BUSINESS_BLOG_ARTICLES_URL = `${API_PATH}/public/blogs/business/users`;
export const BUSINESS_BLOG_ARTICLE_RELATED_ARTICLES_URL = `${API_PATH}/public/blogs/business/articles/related`;
