import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OurCommunityComponent } from '@app/screens/our-community/our-community.component';

export const routes: Routes = [
  {
    path: '',
    component: OurCommunityComponent
  }
];

@NgModule({
  imports: [[RouterModule.forChild(routes)]],
  exports: [RouterModule]
})
export class OurCommunityRoutingModule {}
