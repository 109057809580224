<ng-container>
  <a class="article" [routerLink]="['/blog/post', link]">
    <span class="text-adjust">
      <span class="title">{{ article?.title | cutString: 40 }}</span>

      <span class="description">{{ article?.description | cutString: 80 }}</span>
    </span>

    <span class="tags">
      <ng-container *ngFor="let tag of article.tags">
        <span class="tag">{{ tag.name }}</span>
      </ng-container>
    </span>

    <span class="cover-image" [style.backgroundImage]="article?.coverImage | safe: 'styleUrl'"></span>
  </a>
  <div class="footer">
    <div class="footer-inner">
      <ng-container *ngIf="isArticleAuthorGuide && !hideAuthor">
        <app-user-info
          class="user-info"
          [user]="article?.author"
          [photoSize]="28"
          (click)="emitSelectAuthor($event)"></app-user-info>
      </ng-container>

      <span class="comments-and-likes" *ngIf="disqusId" [ngClass]="{ only: !isArticleAuthorGuide }">
        <span class="comments disqus-comment-count" [attr.data-disqus-identifier]="article.disqus.id"></span>
        <span class="likes"></span>
      </span>
    </div>
  </div>
</ng-container>
