import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { ILocale } from '@env/locale.interface';
import platformDateTimeRange from '@platformStyle/functions/platform-datetime-range';

type SessionRange = { dateStart: string; duration: number } | { dateStart: string; dateEnd: string };

@Pipe({
  name: 'sessionRangeFormatter',
  pure: true
})
export class SessionRangeFormatterPipe implements PipeTransform {
  private readonly locale: ILocale;

  constructor(private _localeService: LocaleService) {
    this.locale = this._localeService.getLocale();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(sessionRange: SessionRange, timezone?: string, userDefinedFormat?: string): string {
    const start = sessionRange['dateStart'];
    // @ts-expect-error TS7053
    const end = sessionRange['dateEnd'];
    // @ts-expect-error TS7053
    const duration = sessionRange['duration'];

    return platformDateTimeRange('DATE, TIME', {
      value: [start, end],
      templateOptions: {
        durationInMinutes: duration,
        timezone,
        showTimezone: !!timezone,
        localeParams: this.locale
      }
    });
  }
}
