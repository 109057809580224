/* eslint-disable */
import { ActivatedRoute } from '@angular/router';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LogService } from '@app/core/log/log.service';
import { SegmentEvents } from '@app/core/analytics/types';
import { isPlatformBrowser } from '@angular/common';
import { take } from 'rxjs/operators';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { LOCATION_ORIGIN } from '../../../consts';

@Injectable({ providedIn: 'root' })
export class SegmentService {
  private readonly _isBrowser: boolean;

  constructor(
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private readonly _platformId,
    @Inject(GLOBAL_WINDOW) private readonly _browserWindow: Window | any,
    private readonly _logService: LogService,
    private readonly _route: ActivatedRoute,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this._isBrowser = isPlatformBrowser(_platformId);
  }

  // @ts-expect-error TS7006
  init(pageTracking): void {
    if (!this._isBrowser || !this._runtimeConfigService.get('SEGMENT_API_KEY')) {
      return;
    }

    const window = this._browserWindow;
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware'
        ];
        // @ts-expect-error TS7006
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var t = analytics.methods[e];
          analytics[t] = analytics.factory(t);
        }
        // @ts-expect-error TS7006
        analytics.load = function (e, t) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + e + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          // @ts-expect-error TS2531
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = t;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(this._runtimeConfigService.get('SEGMENT_API_KEY'));
        if (pageTracking) {
          analytics.page();
        }
      }
  }

  event(eventName: SegmentEvents | string, params: any): void {
    this._route.queryParams.pipe(take(1)).subscribe(({ utm_source }) => {
      if (utm_source) {
        params.utm_source = utm_source;
      }

      if (document.referrer && !document.referrer.includes(LOCATION_ORIGIN)) {
        params.referrer = document.referrer;
      }

      this._track(eventName, params);
    });
  }

  identify(userId: number, userProperties: any): void {
    this._identify(userId, userProperties);
  }

  // @ts-expect-error TS7019
  private _track(...args): void {
    try {
      if (this._browserWindow.analytics && this._runtimeConfigService.get('SEGMENT_API_KEY')) {
        this._browserWindow.analytics.track(...args);
      }
    } catch (err) {
      this._logService.error(err, 'Segment analytics track error');
    }
  }

  private _identify(userId: number, userProperties: any): void {
    try {
      if (this._browserWindow.analytics && this._runtimeConfigService.get('SEGMENT_API_KEY')) {
        this._browserWindow.analytics.identify(
          // @ts-expect-error TS2532
          `${this._runtimeConfigService.get('platformName').toLowerCase()}-${userId}`,
          userProperties
        );
      }
    } catch (err) {
      this._logService.error(err, 'Segment analytics identify error');
    }
  }
}
