import { DateTime } from 'luxon';

import { formatDate } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { inject } from '@angular/core';
import { LocaleService } from '@app/core/locale/locale.service';
import { IWeekDay } from '@app/modules/guide-session/types';
import { Timezones } from '@app/shared/utils/timezones';
import { ILocale } from '@env/locale.interface';
import { platformDateTime } from '@platformStyle/functions/platform-datetime';

// import { locale } from '@env/locale';
import { IOption } from '../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IIsoDayTime {
  iso: string;
  label: string;
}

export function generateIsoWeekDays(locale: ILocale): IWeekDay[] {
  const baseDate = new Date(2019, 6, 1); // date points to monday
  const weekDays = [];

  for (let isoDayId = 1; isoDayId <= 7; isoDayId++) {
    weekDays.push({
      id: isoDayId,
      name: formatDate(baseDate, 'EEEE', locale.dateTimeLocale)
    });
    baseDate.setDate(baseDate.getDate() + 1);
  }

  return weekDays;
}

export function generateTimezones(): IOption[] {
  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getTimezoneOption = tzName => {
    const dt = DateTime.local().setZone(tzName);
    return {
      name: `${tzName} (UTC${dt.offset !== 0 ? dt.toFormat('Z') : ''})`,
      value: tzName
    };
  };

  const localTimezone = DateTime.local().zoneName;

  return [].concat(
    // @ts-expect-error TS2769
    [getTimezoneOption(localTimezone)],
    Timezones.filter(tzName => tzName !== localTimezone).map(tzName => getTimezoneOption(tzName))
  );
}

export function setWeekStart<T extends { id: number }>(weekDays: T[], firstDay: number): T[] {
  if (weekDays[0].id === firstDay) {
    return weekDays;
  }

  const newWeekDays = [];
  for (let i = 0; i < weekDays.length; i++) {
    newWeekDays[weekDays[i].id % 7] = weekDays[i];
  }

  return newWeekDays;
}

export class SimpleTimeGenerator {
  locale: ILocale;

  constructor(private _localeService: LocaleService) {
    this.locale = this._localeService.getLocale();
  }

  generate(timeIntervalInMinutes = 60): IIsoDayTime[] {
    if (timeIntervalInMinutes <= 0) {
      throw Error('Argument cannot be negative or equal zero.');
    }

    if (timeIntervalInMinutes > 60) {
      throw Error('Argument is to high.');
    }

    const timeInterval = Math.round(timeIntervalInMinutes);
    const hours = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += timeInterval) {
        const date = DateTime.local().set({ hour, minute: minutes });

        hours.push({
          iso: this.formatIso(hour, minutes),
          label: platformDateTime('TIME', {
            value: date,
            templateOptions: { localeParams: this.locale }
          })
        });
      }
    }

    hours.push({
      iso: '24:00',
      label: platformDateTime('TIME', {
        value: DateTime.local().set({ hour: 24, minute: 0 }).toISO(),
        templateOptions: { localeParams: this.locale }
      })
    });

    return hours;
  }

  private numToString(num: number, addPadding = true): string {
    if (num >= 10) {
      return '' + num;
    }

    return addPadding ? '0' + num : '' + num;
  }

  // @ts-expect-error TS7006
  private formatIso(hour, minutes): string {
    return `${this.numToString(hour)}:${this.numToString(minutes)}`;
  }
}
