// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export function registerVideoBlot(Quill: any) {
  const BlockEmbed = Quill.import('blots/block/embed');

  class VideoBlot extends BlockEmbed {
    static blotName = 'video';
    static tagName = 'div';
    static className = 'embed-video-container';

    // @ts-expect-error TS2564
    domNode: HTMLElement;

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static create(url) {
      const node = super.create();

      const iFrame = document.createElement('iframe');
      iFrame.setAttribute('src', url);
      iFrame.setAttribute('frameborder', '0');
      iFrame.setAttribute('allowfullscreen', 'true');
      iFrame.classList.add('embed-video');

      node.appendChild(iFrame);

      return node;
    }

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static formats(node) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const format = {} as { height: any; width: any };

      const iFrame = node.querySelector('iframe');

      if (iFrame.hasAttribute('height')) {
        format.height = iFrame.getAttribute('height');
      }

      if (iFrame.hasAttribute('width')) {
        format.width = iFrame.getAttribute('width');
      }

      return format;
    }

    // @ts-expect-error TS7006
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static value(node) {
      const iFrame = node.querySelector('iframe');

      return iFrame.getAttribute('src');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format(formatName: string, value: any): void {
      if (formatName === 'height' || formatName === 'width') {
        if (value) {
          this.domNode.setAttribute(formatName, value);
        } else {
          this.domNode.removeAttribute(formatName);
        }
      } else {
        super.format(formatName, value);
      }
    }
  }

  Quill.register(VideoBlot);
}
