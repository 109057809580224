import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISessionActionResult } from '../../types';

@Component({
  selector: 'app-session-action-result-modal',
  templateUrl: './session-action-result-modal.component.html',
  styleUrls: ['./session-action-result-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionActionResultModalComponent {
  @Input()
  // @ts-expect-error TS2564
  actionResult: ISessionActionResult;

  @Input()
  // @ts-expect-error TS2564
  canConfirm: boolean;

  @Input()
  // @ts-expect-error TS2564
  authorise: () => {} | null;

  get showFooter(): boolean | null {
    return !!this.authorise || this.canConfirm;
  }

  constructor(public modal: NgbActiveModal) {}

  emitAuthorise(): void {
    this.authorise();
    this.modal.dismiss();
  }
}
