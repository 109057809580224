import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
  Inject,
  ChangeDetectorRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgScrollbar } from 'ngx-scrollbar';
import { ChatBoardMessageTypes } from '../../types';
import { ActiveChatService } from '@app/core/chat/active-chat.service';
import { PuiDestroyService } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-chat-scroll',
  templateUrl: './chat-scroll.component.html',
  styleUrls: ['./chat-scroll.component.scss'],
  providers: [PuiDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatScrollComponent implements AfterViewInit {
  @ViewChildren('messageRef') messages!: QueryList<ElementRef<HTMLElement>>;
  @ViewChild(NgScrollbar, { static: false }) scrollbar!: NgScrollbar;

  readonly ChatBoardMessageTypes = ChatBoardMessageTypes;
  readonly activeChat$ = this.activeChatService.activeChat$;

  hasButton = false;

  @Input()
  eventId!: number;

  @Output()
  sendMessage = new EventEmitter<string>();

  @Output()
  loadHistory = new EventEmitter<boolean>();

  private allowScroll = false;

  constructor(
    private readonly activeChatService: ActiveChatService,
    private cdRef: ChangeDetectorRef,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  ngAfterViewInit(): void {
    this.scrollToLastMessage();

    // Handle every time when messages list is changed.
    this.messages.changes
      .pipe(
        filter(() => this.allowScroll),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        // Required to set false. Because it this.messages.changes updates every time.
        this.allowScroll = false;

        // Update ngx-scroll component
        this.scrollbar.update();
        // Then scroll to last message.
        this.scrollToLastMessage();
      });

    this.scrollbar.scrolled.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const { scrollTop, scrollMaxY } = this.scrollbar.viewport;

      const diffY = scrollMaxY - scrollTop;

      // Show "Scroll to bottom" when user scroll to up.
      this.hasButton = diffY > 40;
      this.cdRef.markForCheck();

      // Load previous messages when scroll to top.
      if (scrollTop <= 120) {
        this.activeChatService.loadHistory();
      }
    });

    // Enable global scroll only when chat inizialized.
    this.activeChatService.chatId$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.allowScroll = true;
    });

    // Handle when add new message by user.
    this.activeChatService.sentMessage$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // Update ngx-scroll component
      this.scrollbar.update();
      // Then scroll to last message.
      this.scrollToLastMessage(true);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackMessage(index: number, message: any): any {
    if (message.type === ChatBoardMessageTypes.DATE_SEPARATOR) {
      return message.payload;
    }
    if (message.type === ChatBoardMessageTypes.SERVICE_SEPARATOR) {
      return message.payload.title;
    }
    if (message.type === ChatBoardMessageTypes.MESSAGE) {
      return message.payload.id + message.payload.status;
    }
    if (message.type === ChatBoardMessageTypes.JOINED_CHAT) {
      return message.payload.id;
    }
    if (message.type === ChatBoardMessageTypes.FILE) {
      return message.payload.status === 'temp'
        ? message.payload.id + message.payload.fileUploadStatus
        : message.payload.id + message.payload.status;
    }
  }

  scrollToLastMessage(withSmoothScroll?: boolean): void {
    this.scrollbar?.scrollToElement(this.messages.last, !withSmoothScroll ? { duration: 0 } : undefined);
  }
}
