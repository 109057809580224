import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { TFA_SMS_OTP_SERVICE } from '@app/modules/sms-otp-input/tokens';
import { UserRoles } from '@app/shared/enums/user-roles';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { TfaChallengeInfo } from '../../interfaces/tfa-challendge-info.interface';
import { IUser } from '@app/shared/interfaces/user';

@Component({
  selector: 'tfa-sms-auth',
  templateUrl: './tfa-sms-auth.component.html',
  styleUrls: ['../../../../screens/auth/auth.scss', '../../styles/auth.scss', './tfa-sms-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PuiDestroyService]
})
export class TfaSmsAuthComponent implements OnInit {
  // @ts-expect-error TS2564
  @Input() tfaInfo: TfaChallengeInfo;

  @Input() onlyClient = true;
  @Input() onlyGuide = false;

  @Output()
  afterSignIn = new EventEmitter();

  @Output()
  useBackupCode = new EventEmitter();

  constructor(
    @Inject(TFA_SMS_OTP_SERVICE) private readonly authService: AuthService,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>
  ) {}

  ngOnInit(): void {
    this.authService.setTfaAuthToken(this.tfaInfo.tfaChallengeAuthToken);
  }

  onValidCodeTyped(user: IUser): void {
    of()
      .pipe(
        mergeMap(() => {
          if (
            (this.onlyClient && this.authService.user.RoleId === UserRoles.GUIDE) ||
            (this.onlyGuide && this.authService.user.RoleId === UserRoles.CLIENT)
          ) {
            return this.authService.checkAlternativeAccount();
          }

          return of(null);
        }),
        mergeMap(checkRes => {
          if (checkRes) {
            if (checkRes.hasAlternativeProfile) {
              return this.authService.signinAlternativeAccount(true);
            }

            return throwError({ alternativeFailed: true });
          }

          return of();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.afterSignIn.emit();
        },
        error => {
          if (error.alternativeFailed) {
            this.authService.authorize(user);
          }
        }
      );
  }
}
