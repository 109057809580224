import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterByNameOrEmail } from './filter-by-name-or-email';

@NgModule({
  declarations: [FilterByNameOrEmail],
  exports: [FilterByNameOrEmail],
  imports: [CommonModule]
})
export class FilterByNameOrEmailModule {}
