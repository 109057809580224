<ng-container *ngIf="screen$ | async as screen">
  <div data-qa-id="workspace-menu" class="menu-wrapper" [ngClass]="{ mobile: screen.Mobile }">
    <div *ngIf="screen.Mobile" class="workspaces-header">
      <div class="back-button">
        <button pui-icon-button size="s" shape="square" appearance="flat" (click)="closeWorkspaceMenu()">
          <pui-icon svgIcon="pui:chevron-left"></pui-icon>
        </button>
      </div>

      <span i18n="@@createTeamWorkspaces">Workspaces</span>
    </div>
    <div class="workspaces-container">
      <form class="create-team-form" [formGroup]="workspaceForm">
        <div appRadioGroup class="app-radio-group" formControlName="workspaceControl">
          <div
            [attr.data-qa-id]="'workspace-menu-workspace-item-' + workspace?.name"
            class="workspace-item"
            *ngFor="let workspace of workspaces">
            <label class="radio-label" (click)="switchWorkspace(workspace.id)">
              <span class="radio-container">
                <input
                  type="radio"
                  [name]="'workspace'"
                  [checked]="workspace.id === activeWorkspace.workspaceId"
                  [value]="workspace.id" />
                <span class="checkmark"></span>
              </span>
              <pui-card>
                <pui-card-header>
                  <div
                    class="workspace-title"
                    pui-card-header-title
                    pui-tooltip-direction="top-right"
                    [pui-tooltip]="workspace.name">
                    {{ workspace.name }}
                  </div>
                  <span
                    *ngIf="workspace | workspaceSubheader as subtitle"
                    pui-card-header-subheader
                    class="workspace-subtitle">
                    {{ subtitle }}
                  </span>
                  <pui-avatar
                    pui-card-header-icon
                    size="l"
                    color="white"
                    [avatarUrl]="workspace | workspaceAvatar"
                    [background]="workspace?.branding?.color || '#119199'"
                    [rounded]="workspace | workspaceIsSolo"
                    [text]="workspace.name"></pui-avatar>
                </pui-card-header>
              </pui-card>
            </label>
          </div>
        </div>
      </form>
    </div>
    <ul class="workspace-actions-list">
      <li class="workspace-actions-item" *ngIf="canCreateWorkspace">
        <button data-qa-id="workspace-menu-create-workspace-button" pui-link (click)="createTeamWorkspace($event)">
          <pui-icon svgIcon="pui:users" size="s"></pui-icon>
          <span class="pui-space_left-1">
            <ng-container i18n="@@createTeamWorkspace">Create team workspace</ng-container>
          </span>
        </button>
      </li>
      <li class="workspace-actions-item">
        <button data-qa-id="workspace-menu-logout-button" pui-link (click)="signOut()">
          <pui-icon svgIcon="pui:log-out" size="s" color="accent"></pui-icon>
          <span class="pui-space_left-1">
            <ng-container i18n="@@logoutGuide">Log out of all workspaces</ng-container>
          </span>
        </button>
      </li>
    </ul>
  </div>
</ng-container>
