import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PublicAssignee } from '@app/modules/services/interfaces';
import { SessionType } from '@app/shared/enums/session-type';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { getServiceScheduleRedirectRoute } from '@app/modules/services/utils/get-booking-path';

@Component({
  selector: 'app-service-hosts',
  templateUrl: './service-hosts.component.html',
  styleUrls: ['./service-hosts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceHostsComponent {
  MIN_HOSTS_COUNT = 5;

  showAllHosts = false;

  @Input()
  workspaceId!: number;

  @Input()
  templateId!: number;

  @Input()
  showBookButton = true;

  @Input()
  sessionType!: SessionType;

  @Input()
  type!: WorkspacesTypes;

  @Input()
  isPackage = false;

  @Input()
  assignees: PublicAssignee[] = [];

  getAssignees(): PublicAssignee[] {
    return this.showAllHosts ? this.assignees : this.assignees.slice(0, this.MIN_HOSTS_COUNT);
  }

  getChatLink(hostId: number): string {
    return this.workspaceId ? buildDirectChatLinkId({ id: hostId, workspaceId: this.workspaceId }) : '';
  }

  toggleShowAllHosts() {
    this.showAllHosts = !this.showAllHosts;
  }

  getBookingLink(hostId: number, templateId: number, workspaceId: number): string {
    return getServiceScheduleRedirectRoute({
      hostId,
      templateId,
      workspaceId,
      type: this.type
    });
  }

  getTitle(): string {
    if (this.type === WorkspacesTypes.SOLO || this.assignees.length === 1) {
      return `Host`;
    }

    if (this.sessionType === SessionType.COLLECTIVE || this.isPackage) {
      return `Hosts`;
    }

    return `Choose host`;
  }
}
