import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';

import type { GuideServices } from '@app/modules/service-scheduling/types';
export enum ServiceSchedulingStep {
  PROPOSE_SERVICE_LIST = 'PROPOSE_SERVICE_LIST',
  SERVICE_SCHEDULE_LIST = 'SERVICE_SCHEDULE_LIST',
  CHOOSE_HOST = 'CHOOSE_HOST',
  SERVICE_FORM = 'SERVICE_FORM',
  PROGRAM_AND_PACKAGE_FORM = 'PROGRAM_AND_PACKAGE_FORM'
}

export const serviceSchedulingStepsPriorityIndex: { [key in ServiceSchedulingStep]: number } = {
  // 1
  [ServiceSchedulingStep.PROPOSE_SERVICE_LIST]: 1,
  [ServiceSchedulingStep.SERVICE_SCHEDULE_LIST]: 1,
  // 2
  [ServiceSchedulingStep.CHOOSE_HOST]: 2,
  // 3
  [ServiceSchedulingStep.SERVICE_FORM]: 3,
  [ServiceSchedulingStep.PROGRAM_AND_PACKAGE_FORM]: 3
};

export type StepsHistory = {
  [key in ServiceSchedulingStep]?: { previousStep: ServiceSchedulingStep | null; index: number };
};

export interface GuideServicesState {
  status: 'initial' | 'idle' | 'loading';
  chosenClients: CRMClient[];
  allClients: CRMClient[];
  services: GuideServices.RootObject[];
  currentStep: ServiceSchedulingStep | null;
  stepsHistory: StepsHistory;
}
