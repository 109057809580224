<div class="d-flex align-items-center" [ngSwitch]="type" [attr.data-qa-id]="type + '-session-type-title'">
  <ng-container *ngSwitchCase="SessionType.ROUND_ROBIN">
    <ng-container *ngIf="withIcon">
      <pui-icon class="pui-space_right-1" [size]="iconSize" svgIcon="pui:cycle"></pui-icon>
    </ng-container>
    <span i18n="@@sessionTypeRoundRobinAndPersonal">Round robin and personal</span>
  </ng-container>
  <ng-container *ngSwitchCase="SessionType.COLLECTIVE">
    <ng-container *ngIf="withIcon">
      <pui-icon class="pui-space_right-1" [size]="iconSize" svgIcon="pui:users"></pui-icon>
    </ng-container>
    <span i18n="@@sessionTypeCollective">Collective</span>
  </ng-container>
  <ng-container *ngSwitchCase="SessionType.PERSONAL">
    <ng-container *ngIf="withIcon">
      <pui-icon class="pui-space_right-1" [size]="iconSize" svgIcon="pui:select-user"></pui-icon>
    </ng-container>
    <span i18n="@@sessionTypePersonal">Personal</span>
  </ng-container>
</div>
