<div [formGroup]="form">
  <div class="form-group">
    <label pui-typography="body-s-bold" class="required" for="quizName" i18n="@@formTitle">Form title</label>
    <input type="text" id="quizName" formControlName="quizName" class="form-control" />
    <app-form-control-error
      [show]="
        form.controls['quizName'].errors &&
        form.controls['quizName'].errors.required &&
        form.controls['quizName'].touched
      ">
      <ng-container i18n="@@formTitleRequired">Form title required</ng-container>
    </app-form-control-error>
    <app-form-control-error [show]="form.controls['quizName'].errors && form.controls['quizName'].errors.maxlength">
      <ng-container i18n="@@formTitleMaxLength">Form title maximum length is 100</ng-container>
    </app-form-control-error>
  </div>

  <div class="form-group">
    <label pui-typography="body-s-bold" for="welcomeMessage" i18n="@@description">Description</label>
    <textarea formControlName="welcomeMessage" class="form-control" id="welcomeMessage" cols="30" rows="5"></textarea>
    <app-form-control-error [control]="form.controls['welcomeMessage']">
      <ng-container i18n="@@descriptionMsgMaxLength">Description message maximum length is 2000</ng-container>
    </app-form-control-error>
  </div>

  <div class="form-group">
    <label i18n="@@avatar">Avatar</label>

    <div class="avatar-wrap">
      <div
        class="avatar"
        *ngFor="let avatar of avatars; let i = index"
        (click)="selectAvatar(i)"
        [class.active]="i === selectedAvatar"
        [ngStyle]="{ 'background-image': 'url(' + avatar + ')' }"></div>

      <div
        class="avatar"
        *ngIf="uploadedAvatar"
        (click)="selectAvatar(-1)"
        [class.active]="-1 === selectedAvatar"
        [ngStyle]="{ 'background-image': 'url(' + uploadedAvatar + ')' }"></div>
      <label class="upload">
        <input type="file" (change)="avatarChangeEvent($event)" accept="image/jpeg, image/png" />
      </label>
    </div>
  </div>
</div>
