/* eslint-disable */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { GLOBAL_WINDOW } from '@app/core/browser-window/window-provider';
import { IntercomService } from '@app/core/intercom/intercom.service';
import { UserRoles } from '@app/shared/enums/user-roles';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { IAnalyticParams, IntercomUserProperties } from '@app/core/analytics/types';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { LOCATION_ORIGIN } from '../consts';

interface Membership {
  amount: number;
  currentPeriodEnd: string;
  endless: boolean;
  id: number;
  isTrial: boolean;
  name: string;
  platformPlanType: string;
  recurrency: string;
  roles: number[];
  status: string;
  cancelReason?: string;
}

interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  RoleId: number;
  hashedEmail: string;
  User?: any;
  membership?: Membership;
  createdAt: string;
  analyticStats: IAnalyticParams;
}

interface AdditionalData {
  createdAt?: string;
  cancelledAt?: string;
  clientsCount?: number;
  referrer?: string;
  landing?: string;
  visitDate?: string;
}

const userRolesHash = {
  1: 'Client',
  2: 'Guide',
  3: 'Admin'
};

@Injectable({ providedIn: 'root' })
export class ThirdPartyToolsService {
  private isSession = false;

  private readonly _isBrowser: boolean;

  constructor(
    @Inject(GLOBAL_WINDOW) private readonly _browserWindow: Window | any,
    // @ts-expect-error TS7006
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly _analyticsService: AnalyticsService,
    private readonly _intercomService: IntercomService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  initThirdPartyTools(): void {
    if (this._isBrowser) {
      this.checkIfSession();

      if (!this.isSession) {
        this._intercomService.init();
      }

      this.initFullStory();

      this._analyticsService.initSegment();

      this.initHubSpot();

      this.initAddThis();

      this.initFirstpromoter();

      this.initHelpHero();
    }
  }

  // @ts-expect-error TS7006
  configureThirdPartyTools(user): void {
    if (this._isBrowser) {
      this.setFullStoryUser(user);
      if (!this.isSession) {
        this.setIntercomUser(user);
      }
      this.setHelpHeroUser(user);
    }
  }

  // Todo: remove after 01.01.2022
  setSegmentUser(user: User, additionalData: AdditionalData = {}) {
    // if (!environment.SEGMENT_API_KEY) {
    //   return;
    // }
    // const { analytics } = this._browserWindow;
    // if (analytics) {
    //   const userData: any = {
    //     name: `${user.firstName} ${user.lastName}`,
    //     email: user.email,
    //     role: userRolesHash[user.RoleId],
    //     signup_date: new Date(user.createdAt).toUTCString(),
    //     lists: [
    //       {
    //         id: user.RoleId === 2 ? ACTIVE_CAMPAIGN_COACHES_LIST_ID : ACTIVE_CAMPAIGN_CLIENTS_LIST_ID,
    //         status: 'active'
    //       }
    //     ]
    //   };
    //
    //   if (userData.role === userRolesHash['2'] && user.membership) {
    //     userData.plan = user.membership.name;
    //     userData.subscription_amount = user.membership.amount;
    //     userData.subscription_recurrence = user.membership.recurrency;
    //     userData.subscription_status = user.membership.status;
    //   }
    //
    //   if (additionalData.createdAt) {
    //     userData.first_payment_date = new Date(additionalData.createdAt).toUTCString();
    //   }
    //
    //   if (additionalData.cancelledAt) {
    //     userData.cancellation_date = new Date(additionalData.cancelledAt).toUTCString();
    //   }
    //
    //   if (additionalData.clientsCount) {
    //     userData.clients_amount = additionalData.clientsCount;
    //   }
    //
    //   if (user.membership && user.membership.cancelReason) {
    //     userData.cancelation_reason = user.membership.cancelReason;
    //   }
    //
    //   userData.referrer = decodeURIComponent(additionalData.referrer);
    //   userData.landing = decodeURIComponent(additionalData.landing);
    // analytics.identify(`${this._runtimeConfigService.get('platformName').toLowerCase()}-${user.id}`, userData);
    // }
  }

  private checkIfSession() {
    if (this._browserWindow.location.pathname.substr(0, 8) === '/session') {
      this.isSession = true;
    }
  }

  private initFullStory() {
    if (!this._runtimeConfigService.get('FULLSTORY_ORG')) {
      return;
    }
    const window = this._browserWindow;
    window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
    window['_fs_org'] = this._runtimeConfigService.get('FULLSTORY_ORG');
    window['_fs_namespace'] = 'FS';
    (function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        }
        return;
      }
      // @ts-expect-error TS2322
      g = m[e] = function (a, b, s) {
        // @ts-expect-error TS2532
        g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
      };
      // @ts-expect-error TS2532
      g.q = [];
      // @ts-expect-error TS2322
      o = n.createElement(t);
      // @ts-expect-error TS2532
      o.async = 1;
      // @ts-expect-error TS2532
      o.crossOrigin = 'anonymous';
      // @ts-expect-error TS2532
      o.src = 'https://' + m._fs_script;
      // @ts-expect-error TS2322
      y = n.getElementsByTagName(t)[0];
      // @ts-expect-error TS2532
      y.parentNode.insertBefore(o, y);
      // @ts-expect-error TS2532
      g.identify = function (i, v, s) {
        // @ts-expect-error TS2722
        g(l, { uid: i }, s);
        // @ts-expect-error TS2722
        if (v) g(l, v, s);
      };
      // @ts-expect-error TS2532
      g.setUserVars = function (v, s) {
        // @ts-expect-error TS2722
        g(l, v, s);
      };
      // @ts-expect-error TS2532
      g.event = function (i, v, s) {
        // @ts-expect-error TS2722
        g('event', { n: i, p: v }, s);
      };
      // @ts-expect-error TS2532
      g.anonymize = function () {
        // @ts-expect-error TS2532
        g.identify(!!0);
      };
      // @ts-expect-error TS2532
      g.shutdown = function () {
        // @ts-expect-error TS2722
        g('rec', !1);
      };
      // @ts-expect-error TS2532
      g.restart = function () {
        // @ts-expect-error TS2722
        g('rec', !0);
      };
      // @ts-expect-error TS2532
      g.log = function (a, b) {
        // @ts-expect-error TS2722
        g('log', [a, b]);
      };
      // @ts-expect-error TS2532
      g.consent = function (a) {
        // @ts-expect-error TS2722
        g('consent', !arguments.length || a);
      };
      // @ts-expect-error TS2532
      g.identifyAccount = function (i, v) {
        // @ts-expect-error TS2322
        o = 'account';
        v = v || {};
        v.acctId = i;
        // @ts-expect-error TS2722
        g(o, v);
      };
      // @ts-expect-error TS2532
      g.clearUserCookie = function () {};
      // @ts-expect-error TS2532
      g._w = {};
      // @ts-expect-error TS2322
      y = 'XMLHttpRequest';
      // @ts-expect-error TS2532
      g._w[y] = m[y];
      // @ts-expect-error TS2322
      y = 'fetch';
      // @ts-expect-error TS2532
      g._w[y] = m[y];
      // @ts-expect-error TS2538
      if (m[y])
        // @ts-expect-error TS2538
        m[y] = function () {
          // @ts-expect-error TS2532
          return g._w[y].apply(this, arguments);
        };
      // @ts-expect-error TS2532
      g._v = '1.2.0';
    })(window, document, window['_fs_namespace'], 'script', 'user');
  }

  private setFullStoryUser(user: User) {
    if (!this._runtimeConfigService.get('FULLSTORY_ORG')) {
      return;
    }
    const FS: any = this._browserWindow.FS;

    if (FS) {
      FS.identify(user.id, {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        // @ts-expect-error TS7053
        role: userRolesHash[user.RoleId],
        // TODO: Add your own custom user variables here, details at
        // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
        platformHost: LOCATION_ORIGIN
      });
    }
  }

  private setIntercomUser(user: User): any {
    if (!this._runtimeConfigService.get('INTERCOM_APP_ID') || !user) {
      return;
    }

    if (user.RoleId !== UserRoles.GUIDE) {
      return;
    }

    let intercomConfig: any = {
      app_id: this._runtimeConfigService.get('INTERCOM_APP_ID')
    };

    if (user) {
      intercomConfig = {
        ...intercomConfig,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        role: userRolesHash[user.RoleId],
        user_id: user.email,
        user_hash: user.hashedEmail,
        // @ts-expect-error TS2345
        ...(user.analyticStats && { initial_referrer: decodeURIComponent(user.analyticStats.referrer) }),
        // @ts-expect-error TS2345
        ...(user.analyticStats && { landing: decodeURIComponent(user.analyticStats.landing) }),
        ...(user.analyticStats &&
          user.analyticStats.firstPlatformSubscriptionDate && {
            [IntercomUserProperties.FIRST_PAYMENT_DATE]: user.analyticStats.firstPlatformSubscriptionDate
          })
      };
    }

    if (user.membership) {
      intercomConfig = {
        ...intercomConfig,
        ...(user.membership.name && { [IntercomUserProperties.PLAN]: user.membership.name }),
        ...(user.membership.status && { subscription_status: user.membership.status }),
        ...(user.membership.isTrial && {
          trial_expiration_date: user.membership.currentPeriodEnd
        })
      };
    }

    this._intercomService.load(intercomConfig);
  }

  private initHubSpot() {
    if (!this._runtimeConfigService.get('HUBSPOT_ID')) {
      return;
    }
    const w: any = this._browserWindow;
    const d: any = w.document;

    const s = d.createElement('script');
    s.id = 'hs-script-loader';
    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.src = `//js.hs-scripts.com/${this._runtimeConfigService.get('HUBSPOT_ID')}.js`;

    d.body.appendChild(s);
  }

  private initAddThis() {
    // Go to www.addthis.com/dashboard to customize your tools
    if (!this._runtimeConfigService.get('ADDTHIS_PROFILE_ID')) {
      return;
    }
    const w: any = this._browserWindow;
    const d: any = w.document;

    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${this._runtimeConfigService.get('ADDTHIS_PROFILE_ID')}`;

    d.body.appendChild(s);
  }

  private initFirstpromoter() {
    if (
      !this._runtimeConfigService.get('FIRSTPROMOTER_ID') &&
      !this._runtimeConfigService.get('FIRSTPROMOTER_DOMAIN')
    ) {
      return;
    }
    const window: any = this._browserWindow;
    const document: any = window.document;
    const FIRSTPROMOTER_ID = this._runtimeConfigService.get('FIRSTPROMOTER_ID'),
      FIRSTPROMOTER_DOMAIN = this._runtimeConfigService.get('FIRSTPROMOTER_DOMAIN');
    (function () {
      var t = document.createElement('script');
      (t.type = 'text/javascript'),
        (t.async = !0),
        (t.src = 'https://cdn.firstpromoter.com/fprom.js'),
        (t.onload = t.onreadystatechange =
          function () {
            var t = this.readyState;
            if (!t || 'complete' == t || 'loaded' == t)
              try {
                window.$FPROM.init(FIRSTPROMOTER_ID, FIRSTPROMOTER_DOMAIN);
              } catch (t) {}
          });
      var e = document.getElementsByTagName('script')[0];
      e.parentNode.insertBefore(t, e);
    })();
  }

  private initHelpHero() {
    if (!this._runtimeConfigService.get('HELP_HERO_ID')) {
      return;
    }

    const window: any = this._browserWindow;
    const document: any = window.document;
    (function (w, d, a) {
      var h = w.HelpHero;
      if (!h) {
        var i: any = function () {
          i.q.push(arguments);
        };
        i.q = [];
        w.HelpHero = i;

        function l() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//app.helphero.co/embed/' + a;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        }

        if (w.addEventListener) {
          w.addEventListener('load', l, false);
        }
      }
    })(window, document, this._runtimeConfigService.get('HELP_HERO_ID'));
  }

  private setHelpHeroUser(user: User) {
    if (!this._runtimeConfigService.get('HELP_HERO_ID')) {
      return;
    }

    const userId = this._runtimeConfigService.get('helpHeroPrefix')
      ? `${user.id}-${this._runtimeConfigService.get('helpHeroPrefix')}`
      : user.id;

    this._browserWindow.HelpHero('identify', userId, {
      // @ts-expect-error TS7053
      role: userRolesHash[user.RoleId],
      created_at: new Date(user.createdAt).getTime()
    });
  }
}
