import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonBehaviorsModule } from '@app/modules/common-behaviors/common-behaviors.module';
import { TimePickerGridModule } from '@app/modules/ui-kit/schedule/time-picker-grid/time-picker-grid.module';

import { MultiDateScheduleTimePickerComponent } from './components/multi-date-schedule-time-picker/multi-date-schedule-time-picker.component';

@NgModule({
  imports: [CommonBehaviorsModule, CommonModule, FormsModule, NgSelectModule, TimePickerGridModule],
  declarations: [MultiDateScheduleTimePickerComponent],
  exports: [MultiDateScheduleTimePickerComponent]
})
export class MultiDateScheduleTimePickerModule {}
