import { IUser } from '@app/shared/interfaces/user';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const buildUsersGuideMapKey = ({ id, workspaceId }: Required<Pick<IUser, 'id' | 'workspaceId'>>) =>
  `${id}_${workspaceId}`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getUserKey = <TUser extends { id: number; key?: string }>(user: TUser) => user.key || user.id;

export { buildUsersGuideMapKey, getUserKey };
