import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@app/shared/shared.module';

import { PhoneVerificationService } from './services/phone-verification.service';
import { PhoneVerificationFacadeService } from './services/phone-verification-facade.service';
import { PhoneVerificationModalComponent } from './components/phone-verification-modal/phone-verification-modal.component';
import { PhoneVerificationComponent } from './phone-verification.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, SharedModule],
  declarations: [PhoneVerificationComponent, PhoneVerificationModalComponent],
  providers: [PhoneVerificationService, PhoneVerificationFacadeService]
})
export class PhoneVerificationModule {}
