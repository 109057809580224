<div class="modal-header">
  <h3>{{ title }}</h3>
</div>
<div class="modal-body">
  <form [formGroup]="tagsForm">
    <div class="search-control">
      <button class="btn search-btn"></button>
      <input
        id="name"
        type="text"
        class="form-control search-input"
        formControlName="search"
        placeholder="Search"
        i18n-placeholder="@@search"
        (input)="filter()" />
    </div>
  </form>
  <ng-scrollbar [ngStyle]="{ 'height.px': 348 }" [visibility]="'hover'" [appearance]="'compact'">
    <div class="elements-list">
      <ng-container *ngFor="let group of filtered; index as i">
        <ng-container *ngIf="group.children.length > 0">
          <div *ngIf="i > 0" class="dropdown-divider"></div>
          <h6 class="header" *ngIf="!groupTemplate && group.name">{{ group.name }}</h6>
          <ng-container
            *ngIf="groupTemplate"
            [ngTemplateOutlet]="groupTemplate"
            [ngTemplateOutletContext]="{ group: group }"></ng-container>
          <ng-container *ngIf="!itemTemplate">
            <div
              *ngFor="let element of group.children"
              (click)="select(element.id)"
              class="tag"
              [ngClass]="{ active: selected.includes(element.id) }">
              <span>{{ element.name }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="itemTemplate">
            <div *ngFor="let element of group.children" (click)="select(element.id)">
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ item: element, active: selected.includes(element.id) }"></ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>
<div class="modal-footer">
  <app-button className="btn-primary" (click)="save()" i18n="submit">Submit</app-button>
  <app-button className="btn-lite" (click)="cancel()" i18n="cancel">Cancel</app-button>
</div>
