import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { UiDestroyService } from '@app/modules/ui-kit/_base/_common/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { ISidenavFooterHost } from '../../interfaces';
import { SidenavFooterSlotEnum, SidenavHostEnum } from '../../enums';
import { ViewWidgetService } from '../../services';
import { SidenavFooterSlotType } from '../../types';

@Component({
  selector: 'app-sidenav-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'app-sidenav-footer'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavFooterComponent implements ISidenavFooterHost, AfterViewInit {
  readonly name = SidenavHostEnum.Footer;

  readonly slots: SidenavFooterSlotEnum[] = [SidenavFooterSlotEnum.SwitchAccount];

  @ViewChild('switchAccount')
  // @ts-expect-error TS2564
  switchAccount: CdkPortalOutlet;

  constructor(
    readonly service: ViewWidgetService<SidenavFooterSlotType>,
    @Inject(UiDestroyService) private readonly _destroy$: UiDestroyService
  ) {}

  ngAfterViewInit(): void {
    this.service.attachHost(this);
    this.service.observeWidgets$(this).pipe(takeUntil(this._destroy$)).subscribe();
  }
}
