import { Injectable } from '@angular/core';
import { catchError, finalize, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { INoteEditorContent, NotesTypes, NotesTypesSortProp } from '@app/shared/interfaces/notes';
import { GuideNotesApiService } from '@app/modules/guide-notes/guide-notes-api.service';
import { GuideNotesService } from '@app/modules/guide-notes/guide-notes.service';
import { GuideNotesBoardService } from '@app/modules/guide-notes/guide-notes-board.service';
import { BehaviorSubject, of } from 'rxjs';
import { PuiDrawerService } from '@awarenow/profi-ui-core';
import { GuideRelationShareNotesComponent } from '@app/modules/guide-client/components/guide-relation-share-notes/guide-relation-share-notes.component';
import { DRAWER_CONFIG } from '@app/modules/guide-client/services';

@Injectable()
export class GuideEventsNotesBoardService extends GuideNotesBoardService {
  protected relationList: string[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly _serviceId: symbol;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _isLoadingSubj = new BehaviorSubject<boolean>(true);
  readonly isLoading$ = this._isLoadingSubj.asObservable();

  constructor(
    protected _guideNotes: GuideNotesService,
    protected _guideNotesApi: GuideNotesApiService,
    protected readonly modal: NgbModal,
    private readonly _drawer: PuiDrawerService
  ) {
    super(_guideNotes, _guideNotesApi, modal, {
      notes: [],
      cursor: null,
      cursorPinned: null,
      notesType: NotesTypes.PINNED,
      eventId: null,
      clientIds: null,
      scrollTo: null
    });

    this._serviceId = Symbol('GuideEventsNotesBoardService');
  }

  create({ text, content, html }: INoteEditorContent): void {
    if (!html) {
      return;
    }
    // @ts-expect-error TS2339
    const { eventId, clientIds, ...rest } = this.state;
    const note = {
      content: html,
      contentText: text,
      contentDeltaFormat: content
    };

    this._guideNotesApi.createEventNote$(eventId, clientIds, note).subscribe(newNote => {
      // @ts-expect-error TS2339
      const { notesType, notes: currentNotes } = this.state;

      if (notesType === NotesTypes.STANDARD) {
        const [pinnedNotes, standardNotes] = this._splitNotesByPinStatus([newNote, ...currentNotes]);
        const sortedStandardNotes = this._sortNotesBy(standardNotes, NotesTypesSortProp.STANDARD);
        const cursor = sortedStandardNotes[sortedStandardNotes.length - 1].updatedAt;

        this.setState({
          ...rest,
          notes: [...pinnedNotes, ...sortedStandardNotes],
          cursor,
          eventId,
          clientIds,
          scrollTo: newNote.id
        });
      }
      this._guideNotes.createNote({
        serviceId: this._serviceId,
        noteId: newNote.id
      });
      this._resetEditorState$.next();
    });
  }

  loadNotes(search?: string): void {
    // @ts-expect-error TS2339
    const { clientIds } = this.state;

    const [relationId] = clientIds;
    const relationType = GuideRelationTypes.GUIDE_CLIENT;

    this._guideNotesApi
      // @ts-expect-error TS2345
      .getRelationNotes$(relationId, relationType, null, this._limit, true, search)
      .pipe(
        map(pinnedNotesResponse => {
          const countOfRestNotesToLoad = this._limit - pinnedNotesResponse.notes.length;

          if (countOfRestNotesToLoad > 0) {
            return {
              ...this.state,
              notes: [...pinnedNotesResponse.notes],
              cursor: null,
              cursorPinned: pinnedNotesResponse.cursor,
              notesType: NotesTypes.STANDARD,
              scrollTo: null,
              countOfRestNotesToLoad
            };
          }

          return {
            ...this.state,
            notes: [...pinnedNotesResponse.notes],
            cursor: null,
            cursorPinned: pinnedNotesResponse.cursor,
            notesType: NotesTypes.PINNED,
            scrollTo: null,
            countOfRestNotesToLoad: 0
          };
        }),
        catchError(() => of({ ...this.state }))
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        // @ts-expect-error TS2345
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad, search);
        } else {
          this._isLoadingSubj.next(false);
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadMoreNotes(count?: number, search?: string) {
    if (count === 0) {
      return;
    }

    // @ts-expect-error TS2339
    const { eventId, notesType, cursorPinned, cursor, clientIds } = this.state;
    const limit = count || this._limit;
    const isPinned = notesType === NotesTypes.PINNED;
    const currentCursor = isPinned ? cursorPinned : cursor;
    const [relationId] = clientIds;
    const relationType = GuideRelationTypes.GUIDE_CLIENT;

    this._guideNotesApi
      // @ts-expect-error TS2345
      .getRelationNotes$(relationId, relationType, currentCursor, limit, isPinned, search)
      .pipe(
        map(noteResponse => {
          // @ts-expect-error TS2531
          const currentNotes = [...this.state.notes, ...noteResponse.notes];
          const countOfRestNotesToLoad = limit - noteResponse.notes.length;
          const restState = {
            clientIds,
            eventId,
            scrollTo: null
          };

          if (countOfRestNotesToLoad > 0 && isPinned) {
            return {
              ...restState,
              notes: currentNotes,
              cursor: null,
              cursorPinned: noteResponse.cursor,
              notesType: NotesTypes.STANDARD,
              countOfRestNotesToLoad
            };
          }

          return {
            ...restState,
            notes: currentNotes,
            cursor: !isPinned ? noteResponse.cursor : cursor,
            cursorPinned: isPinned ? noteResponse.cursor : cursorPinned,
            notesType,
            countOfRestNotesToLoad: 0
          };
        }),
        finalize(() => this._isLoadingSubj.next(false))
      )
      .subscribe(({ countOfRestNotesToLoad, ...state }) => {
        this.setState(state);

        if (countOfRestNotesToLoad > 0) {
          this.loadMoreNotes(countOfRestNotesToLoad, search);
        }
      });
  }

  grantViewerAccess(index: number): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, clientIds } = this.state;
    // TODO: Temp solution
    const [clientId] = clientIds;
    if (!clientId) {
      return;
    }
    const { id: sharedId } = currentNotes[index];
    this._guideNotesApi.shareNotesWithClient$(clientId, [sharedId]).subscribe(() => {
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this._guideNotesApi.getSharedNotes$([sharedId]).subscribe(notes => this._updateStateAfterNotesAreShared(notes));
      this._guideNotes.grantAccess({
        serviceId: this._serviceId,
        noteIds: [sharedId]
      });
    });
  }

  revokeViewerAccess(index: number): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, clientIds } = this.state;
    // TODO: Temp solution
    const [clientId] = clientIds;
    if (!clientId) {
      return;
    }
    const { id: sharedId } = currentNotes[index];
    this._guideNotesApi.revokeViewerAccessForClient$(clientId, [sharedId]).subscribe(() => {
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this._guideNotesApi.getSharedNotes$([sharedId]).subscribe(notes => this._updateStateAfterNotesAreShared(notes));
      this._guideNotes.revokeViewerAccess({
        serviceId: this._serviceId,
        noteIds: [sharedId]
      });
    });
  }

  setRelationList(clientIds: number[]): void {
    this.relationList = clientIds.map(id => GuideContact.createLocalId({ id, type: GuideRelationTypes.GUIDE_CLIENT }));
  }

  // @ts-expect-error TS7006
  manageAccess(index): void {
    // @ts-expect-error TS2339
    const { notes: currentNotes, clientIds } = this.state;
    const [clientId] = clientIds;
    if (!clientId) {
      return;
    }
    const { id: noteId } = currentNotes[index];

    this._drawer.open(
      GuideRelationShareNotesComponent,
      {
        ...DRAWER_CONFIG,
        hideCloseButton: true
      },
      { clientId, noteId }
    );
  }
}
