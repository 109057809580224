<a class="podcast" [routerLink]="['/blog/post', link]">
  <span class="info-col">
    <span class="podcast-name">
      podcast
      <span *ngIf="podcast?.index">#{{ podcast?.index }}</span>
    </span>
    <span class="podcast-title">{{ podcast.title | cutString: 50 }}</span>
    <span class="date-and-duration">
      <span class="date">{{ podcast?.date }}</span>
    </span>
  </span>
  <div class="image-col" [style.backgroundImage]="podcast?.coverImage | safe: 'styleUrl'"></div>
</a>
