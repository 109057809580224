<div class="modal-header">
  <h4 class="modal-title" transloco="wrongSystemTime">Wrong system time</h4>
</div>

<div class="modal-body" transloco="wrongSystemTimeNote">
  We’ve noticed that your system time is not correct. Please check your timezone. We recommend setting automatic
  synchronization for your date, time and timezone in your laptop’s (or phone’s) settings to avoid system confusions.
</div>

<div class="modal-footer">
  <app-button className="btn-primary btn-md" (click)="reload()" i18n="done">Done</app-button>
</div>
