<footer class="container">
  <div class="row no-gutters inner">
    <div class="col-6 col-lg-4 logo-col">
      <a
        class="awarenow-logo"
        *ngIf="config.logoUrl.type === URL_TYPE.RELATIVE; else absolute"
        [routerLink]="'/' + config.logoUrl.value">
        <img [src]="mainLogo" />
      </a>
    </div>
    <div class="col-6 col-lg-4 security">
      <div
        class="money-back"
        [ngbTooltip]="moneyBackTooltip"
        tooltipClass="money-back-tooltip"
        placement="top"
        *ngIf="config.displayMoneyBackGuarantee">
        <div class="icon"></div>
        <div class="money-back-text">
          <div class="percents" i18n="@@moneyBackGuaranteePercent">100%</div>
          <div class="label" i18n="@@moneyBackGuarantee">Money back guarantee</div>
        </div>
      </div>
      <div class="hipaa" *ngIf="config.displayHIPAACompliant">
        <div class="icon"></div>
      </div>
    </div>
    <div class="col footer-links">
      <ul class="links">
        <li><a href="{{ config.urlTerms }}" target="_blank" i18n="@@terms" rel="noopener">Terms</a></li>
        <li><a href="{{ config.urlPrivacy }}" target="_blank" i18n="@@privacy" rel="noopener">Privacy</a></li>
        <li>
          <a href="mailto:{{ config.emailHelp }}">{{ config.emailHelp }}</a>
        </li>
      </ul>
      <app-language-selector *ngIf="canShowLanguageSelector"></app-language-selector>
    </div>
    <div *ngIf="false" class="col social-links">
      <ul class="links">
        <li><a *ngIf="config.facebook" [href]="config.facebook" class="facebook" rel="noopener"></a></li>
        <li><a *ngIf="config.twitter" [href]="config.twitter" class="twitter" rel="noopener"></a></li>
        <li><a *ngIf="config.instagram" [href]="config.instagram" class="instagram" rel="noopener"></a></li>
      </ul>
    </div>
    <div *ngIf="false" class="col phone"></div>
  </div>
  <div class="row no-gutters d-none d-sm-block">
    <div class="col disclaimer" *ngIf="displayDisclaimer" i18n="@@copyright">
      COPYRIGHT ©{{ curYear }} {{ platformName }}. MEDICAL DISCLAIMER: The opinions expressed on this site and by
      {{ platformName }} and it's contractor providers are published for educational and informational purposes only,
      and are not intended as a diagnosis, treatment or as a substitute for professional medical advice, diagnosis and
      treatment. Please consult a local physician or other health care professional for your specific health care and/or
      medical needs or concerns. Information on this website is provided for informational purposes only and not
      intended to replace a one-on-one relationship with a qualified healthcare professional and is not intended as
      medical advice. {{ platformName | titlecase }} encourages you to make your own health care decisions based upon
      your research and in partnership with a qualified healthcare professional. If you have or suspect that you have a
      medical problem, contact your health care provider promptly. Do not disregard professional medical advice or delay
      in seeking professional advice because of something you have read on this website. Information provided on this
      website and the use by you of any products or services referenced on this website DOES NOT create a doctor-patient
      relationship between you and {{ platformName }}. Information and statements regarding dietary supplements have not
      been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any
      disease. Copyright © 2019 {{ platformName }}. All Rights Reserved.
    </div>
  </div>
</footer>

<app-powered-by-link *ngIf="displayPoweredBy || showPoweredBy" utmCampaign="footer"></app-powered-by-link>

<ng-template #moneyBackTooltip>
  <ng-container i18n="@@moneyBackTooltip">
    If you are not satisfied with our services and are still within 10 days of your original session purchase date, your
    request for a refund will be honored. You can request it by contacting our support
  </ng-container>
  <span class="help-email">{{ config.emailHelp }}</span>
  .
</ng-template>

<ng-template #absolute>
  <a class="awarenow-logo" href="{{ config.logoUrl.value }}">
    <img [src]="mainLogo" />
  </a>
</ng-template>
