<pui-data-box>
  <ng-container *ngIf="!hideHeader" ngProjectAs="[pui-data-box-title]">
    <h4 pui-typography="h4" i18n="@@upcomingSessionTitle">Upcoming sessions</h4>
  </ng-container>

  <div pui-data-box-body>
    <ng-template [ngIf]="sessions && sessions.length" [ngIfElse]="noUpcomingSessions">
      <div class="upcoming-sessions-list">
        <ng-container *ngFor="let clientSession of sessions | sortOnlySessionDate: 'dateStart':'asc'; let last = last">
          <app-session
            class="pui-space_bottom-5 pui-space_top-5"
            [session]="
              clientSession
                | normalizeSession
                | sessionNameWithParentGroupName: clientSession.session?.serviceParent?.name
            "
            [attr.data-qa-id]="'upcoming-client-session-item-' + clientSession?.session?.name"></app-session>

          <ng-container *ngIf="!last">
            <pui-divider></pui-divider>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #noUpcomingSessions>
      <div class="no-sessions" i18n="@@upcomingClientSessionsEmptyText">Upcoming sessions will show up here</div>
    </ng-template>
  </div>
</pui-data-box>
