import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import {
  isContentModule,
  isMultipleChoiceQuestion,
  isQuizChoiceQuestion,
  isQuizModule,
  isSingleChoiceQuestion,
  ProgramModule,
  QuizModule,
  QuizStatuses
} from '@app/shared/interfaces/programs/client-programs';
import { ModuleCompletionTypes } from '@app/shared/interfaces/programs/program-module';
import { QuizQuestionType } from '@app/core/quizzes/types';

@Component({
  selector: 'app-module-actions-board',
  templateUrl: './module-actions-board.component.html',
  styleUrls: ['./module-actions-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-actions-board'
  }
})
export class ModuleActionsBoardComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _doesModuleHaveActions: boolean;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _module: ProgramModule;

  get doesModuleHaveActions(): boolean {
    return this._doesModuleHaveActions;
  }

  @Input()
  set module(value: ProgramModule) {
    this._module = value;
    this.setModuleActionsFlag();
  }

  get module(): ProgramModule {
    return this._module;
  }

  @Input()
  isNextModuleAvailable = false;

  @Output()
  complete = new EventEmitter();

  @Output()
  nextModule = new EventEmitter();

  /**
   * Only for quiz modules
   */
  @Output()
  finishQuiz = new EventEmitter<{ moduleId: number }>();

  /**
   * Only for quiz modules
   */
  @Output()
  retryQuiz = new EventEmitter<{ moduleId: number }>();

  canRetryQuiz = false;
  canFinishQuiz = false;

  readonly ModuleCompletionTypes = ModuleCompletionTypes;

  private setModuleActionsFlag(): void {
    this.setFlags();

    if (!this.module || this.module.status === 'restricted') {
      this._doesModuleHaveActions = false;
      return;
    }

    if (this.module.status === 'allowed') {
      this._doesModuleHaveActions = this.module.completionType !== ModuleCompletionTypes.AUTO;
      return;
    }

    if (this.module.completionType !== ModuleCompletionTypes.AUTO) {
      this._doesModuleHaveActions = true;
      return;
    }

    if (isContentModule(this.module)) {
      this._doesModuleHaveActions = true;
      return;
    }

    if (isQuizModule(this.module)) {
      this._doesModuleHaveActions = this.module.quiz.status === QuizStatuses.COMPLETED;
      return;
    }

    this._doesModuleHaveActions = this.module?.session != null && this.module.session.status === SessionStatuses.DONE;
  }

  private setFlags(): void {
    if (!(this.module instanceof QuizModule)) {
      return;
    }

    const questions = this.module.quiz.questions || [];
    const answersCount = questions.reduce((count, { answer }) => count + (answer ? 1 : 0), 0);
    const isIncompleteQuizWithOptions =
      (questions.some(isMultipleChoiceQuestion) ||
        questions.some(isSingleChoiceQuestion) ||
        questions.some(isQuizChoiceQuestion)) &&
      (!this.module.quiz.status || this.module.quiz.status !== QuizStatuses.COMPLETED);

    const filteredQuestions = questions.filter(question => question.type !== QuizQuestionType.TEXT);
    this.canFinishQuiz = isIncompleteQuizWithOptions ? filteredQuestions.length === answersCount : false;
    this.canRetryQuiz = isIncompleteQuizWithOptions ? answersCount > 0 : false;
  }
}
