<ng-template #noResponse>
  <div class="no-response">
    <div><img src="assets/images/programs/file.svg" alt="" /></div>
    <p class="no-response-message" i18n="@@moduleQuizNoResponse">No client response on this date</p>
  </div>
</ng-template>

<div class="questions-list" [formGroup]="form" *ngIf="!showNoResponseMessage; else noResponse">
  <ng-container formArrayName="questions">
    <div class="questions" *ngFor="let question of formQuestions.controls; let i = index">
      <ng-container *ngIf="showMultiChoice(question.value)">
        <app-quiz-choice-answer
          [key]="displayKey ? i + 1 : null"
          [formControl]="question"
          class="answer choice-answer"
          [isCorrectDisplay]="
            isCorrectDisplay &&
            (question.value.type === QuizQuestionType.QUIZ || question.value.questionType === QuizQuestionType.QUIZ)
          "
          [multi]="
            question.value.type === QuizQuestionType.MULTIPLE_CHOICE || question.value.type === QuizQuestionType.QUIZ
          "></app-quiz-choice-answer>
      </ng-container>

      <ng-container
        *ngIf="
          question.value.type === QuizQuestionType.LONG_ANSWER || question.value.type === QuizQuestionType.SHORT_ANSWER
        ">
        <app-quiz-custom-answer
          [key]="displayKey ? i + 1 : null"
          [formControl]="question"
          class="answer custom-answer"></app-quiz-custom-answer>
      </ng-container>

      <ng-container *ngIf="question.value.type === QuizQuestionType.FILE_UPLOAD">
        <app-quiz-file-upload-answer
          [key]="displayKey ? i + 1 : null"
          [formControl]="question"
          class="answer custom-answer"></app-quiz-file-upload-answer>
      </ng-container>

      <ng-container *ngIf="question.value.type === QuizQuestionType.SIGNATURE">
        <app-quiz-signature-answer
          [key]="displayKey ? i + 1 : null"
          [formControl]="question"
          class="answer custom-answer"></app-quiz-signature-answer>
      </ng-container>

      <ng-container
        *ngIf="question.value.type === QuizQuestionType.TEXT || (question.value | isUnansweredQuestionProgress)">
        <app-quiz-text-question
          [key]="displayKey ? i + 1 : null"
          [formControl]="question"
          class="answer custom-answer"></app-quiz-text-question>
      </ng-container>
    </div>
  </ng-container>
</div>
