import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticCopyTypes, AnalyticSourceTypes, InternalEvents } from '@app/core/analytics/types';
import { BookShareInfoService } from '../../services/book-share-info.service';
import { EmbeddableBookButtonModalComponent } from '../embeddable-book-button-modal/embeddable-book-button-modal.component';

@Component({
  selector: 'app-book-link-provider',
  templateUrl: './book-link-provider.component.html',
  styleUrls: ['./book-link-provider.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-app-book-link-provider'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookLinkProviderComponent {
  @Input()
  enableNotifications = true;

  analyticCopyTypes = AnalyticCopyTypes;

  bookLink: string;

  constructor(
    private _bookShareInfo: BookShareInfoService,
    private _notifications: NotificationsService,
    private _analyticsService: AnalyticsService,
    private _modal: NgbModal
  ) {
    // @ts-expect-error TS2322
    this.bookLink = _bookShareInfo.bookLink;
  }

  onBookLinkCopied(copyType: AnalyticCopyTypes): void {
    this._analyticsService.event(InternalEvents.LINK_COPY, {
      copy_type: copyType,
      link_type: AnalyticSourceTypes.BOOKING
    });
    if (this.enableNotifications) {
      const message = `Book link copied`;
      this._notifications.success(message);
    }
  }

  onBookLinkCopyFailed(): void {
    if (this.enableNotifications) {
      const message = `Book link copy failed`;
      this._notifications.error(message);
    }
  }

  async openBookWidget(): Promise<void> {
    this._modal.open(EmbeddableBookButtonModalComponent, { windowClass: 'embeddable-book-modal' });
  }
}
