import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatStepper } from '@app/modules/ui-kit/_base/ui-stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-team-stepper',
  templateUrl: './create-team-stepper.component.html',
  styleUrls: ['./create-team-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateTeamStepperComponent {
  // @ts-expect-error TS2564
  @ViewChild('stepper') private stepper: MatStepper;

  GUIDE_LIST_ROUTE = environment.guideRoute;

  completed = {
    0: false,
    1: false
  };

  isCompleted = false;

  constructor(private readonly _router: Router, private _modal: NgbActiveModal) {}

  onWorkspaceCreated(): void {
    this.completed[0] = true;
    setTimeout(() => this.stepper.next());
  }

  onStepChange($event: StepperSelectionEvent): void {
    if ($event.previouslySelectedIndex > $event.selectedIndex) {
      $event.previouslySelectedStep.reset();
      $event.selectedStep.reset();
      // @ts-expect-error TS7053
      this.completed[$event.selectedIndex] = false;
      // @ts-expect-error TS7053
      this.completed[$event.previouslySelectedIndex] = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onInviteHandler(invited: boolean) {
    if (invited) {
      this._router.navigate([`/${this.GUIDE_LIST_ROUTE}/services`], { replaceUrl: true }).then(() => {
        this._modal.dismiss();
      });
    }
  }
}
