import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TimezonesService } from '@app/core/timezones/timezones.service';
import { IMultiDateScheduleGroup } from '@app/modules/ui-kit/schedule';
import {
  LOCALE_PROVIDERS,
  MULTI_DATE_SCHEDULE_SLOTS_BUILD_STRATEGY_PROVIDER,
  MULTI_DATE_SCHEDULE_TIME_SLOTS_PROVIDER,
  SCHEDULE_DATE_FORMAT_STRATEGY_PROVIDER,
  SCHEDULE_FORMATS_PROVIDERS,
  SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER,
  ScheduleTimeSlotsTimezoneFactory
} from '../../services';
import { ISchedule, ITimeSlot } from '../../types';

@Component({
  selector: 'app-multi-date-schedule-board',
  templateUrl: './multi-date-schedule-board.component.html',
  styleUrls: ['./multi-date-schedule-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LOCALE_PROVIDERS,
    SCHEDULE_FORMATS_PROVIDERS,
    MULTI_DATE_SCHEDULE_TIME_SLOTS_PROVIDER,
    MULTI_DATE_SCHEDULE_SLOTS_BUILD_STRATEGY_PROVIDER,
    SCHEDULE_DATE_FORMAT_STRATEGY_PROVIDER,
    SCHEDULE_TIME_FORMAT_STRATEGY_PROVIDER
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'multi-date-schedule-board'
  }
})
export class MultiDateScheduleBoardComponent {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _timezone: string;

  @Input()
  set timezone(value: string) {
    this._timezone = value;
    this.timeSlotsProvider.changeTimezone(value);
  }

  @Output()
  readonly timeSelect = new EventEmitter<ITimeSlot>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get timezone(): string {
    return this._timezone;
  }

  constructor(
    public timeSlotsProvider: ScheduleTimeSlotsTimezoneFactory<ISchedule, string, IMultiDateScheduleGroup<ITimeSlot>>,
    public timezones$: TimezonesService
  ) {}
}
