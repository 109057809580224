import { Router, ActivatedRoute, Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

// [TODO] Move to types
// [TODO] Probably this is a bad name
// eslint-disable-next-line @typescript-eslint/naming-convention
interface IProgramFilterRawParams {
  priceFrom?: string[];
  priceTo?: string[];
  startDateFrom?: string[];
  startDateTo?: string[];
  startDateSelfPaced?: string[];
  issue?: string[];
  approach?: string[];
  practitioner?: string[];
  language?: string[];
  sorting?: string[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IProgramFilterRefinedParams {
  priceFrom?: string;
  priceTo?: string;
  startDateFrom?: string;
  startDateTo?: string;
  startDateSelfPaced?: string;
  issue?: number[];
  approach?: number[];
  practitioner?: number[];
  language?: number[];
  sorting?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgramsRouteFilterAdapter {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _listOfFilterParams = [
    'priceFrom',
    'priceTo',
    'startDateFrom',
    'startDateTo',
    'startDateSelfPaced',
    'issue',
    'approach',
    'practitioner',
    // 'language', // [TODO] Uncomment when programs will have languages again
    'sorting'
  ];

  constructor(private _route: ActivatedRoute, private _router: Router, private _location: Location) {}

  // [TODO] Probably this is a bad name, need to change it
  private refineParams(params: Params): IProgramFilterRefinedParams {
    const filteredParams = this._listOfFilterParams.reduce((filteredMap, paramName) => {
      const value = params[paramName];
      const distinctParamValue = this.distinct([].concat(value));
      return value ? { ...filteredMap, [paramName]: distinctParamValue } : filteredMap;
    }, {});
    return this.normalizeParams(filteredParams);
  }

  private distinct(arr: string[]): string[] {
    return Object.keys(arr.reduce((list, item) => ({ ...list, [item]: true }), {}));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private takeFirst<T>([first, ...rest]: T[]): T {
    return first;
  }

  private normalizeParams({
    priceFrom,
    priceTo,
    startDateFrom,
    startDateTo,
    startDateSelfPaced,
    issue,
    approach,
    practitioner,
    language,
    sorting
  }: IProgramFilterRawParams): IProgramFilterRefinedParams {
    let normalizedParams: IProgramFilterRefinedParams = {};
    if (priceFrom) {
      normalizedParams = {
        ...normalizedParams,
        priceFrom: this.takeFirst(priceFrom)
      };
    }
    if (priceTo) {
      normalizedParams = {
        ...normalizedParams,
        priceTo: this.takeFirst(priceTo)
      };
    }
    if (startDateFrom) {
      normalizedParams = {
        ...normalizedParams,
        startDateFrom: this.takeFirst(startDateFrom)
      };
    }
    if (startDateTo) {
      normalizedParams = {
        ...normalizedParams,
        startDateTo: this.takeFirst(startDateTo)
      };
    }
    if (startDateSelfPaced) {
      normalizedParams = {
        ...normalizedParams,
        startDateSelfPaced: this.takeFirst(startDateSelfPaced)
      };
    }
    if (issue) {
      normalizedParams = {
        ...normalizedParams,
        issue: issue.map((id: string) => Number(id))
      };
    }
    if (approach) {
      normalizedParams = {
        ...normalizedParams,
        approach: approach.map((id: string) => Number(id))
      };
    }
    if (practitioner) {
      normalizedParams = {
        ...normalizedParams,
        practitioner: practitioner.map((id: string) => Number(id))
      };
    }
    if (language) {
      normalizedParams = {
        ...normalizedParams,
        language: language.map((id: string) => Number(id))
      };
    }
    if (sorting) {
      normalizedParams = {
        ...normalizedParams,
        sorting: this.takeFirst(sorting)
      };
    }
    return normalizedParams;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get filterParams$() {
    return this._route.queryParams.pipe(map(queryParams => this.refineParams(queryParams)));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setFilterParams(queryParams: Params) {
    // [TODO] Alternative way. Update url then emmit params. This should help to avoid page reload.
    // const tree = this._router.createUrlTree([],
    //   {
    //     relativeTo: this._route,
    //     queryParams,
    //     queryParamsHandling: 'merge'
    //   });
    //
    // this._location.go(tree.toString());
    // console.log(this._route);
    return from(
      this._router.navigate([], {
        relativeTo: this._route,
        queryParams,
        queryParamsHandling: 'merge'
      })
    );
  }
}
