<form [formGroup]="form">
  <pui-toggle formControlName="enabled" (change)="toggleRecurring($event.checked)" #enableRecurring>
    <div class="d-flex flex-column pui-space_bottom-2">
      <span pui-typography="body-s-bold" i18n="@@RecurringSession">Recurring session</span>
      <span
        class="recurrence-form__toggle-description"
        pui-typography="body-s"
        i18n="@@AllowClientsCanSubscribeForRecurringSession"
        >Allow clients to subscribe for recurring sessions.</span
      >
    </div>

    <div class="d-flex" *ngIf="enableRecurring.checked">
      <div>
        <div class="pui-space_bottom-1">
          <span pui-typography="body-s-bold" i18n="@@ForAMaximumOf">For a maximum of</span>
        </div>
        <div class="recurrence-form__session-max-count" pui-group [collapsed]="true">
          <pui-input-field>
            <input
              updateValueAndValidity
              formControlName="count"
              pui-input-text
              type="text"
              [textMask]="{ mask: maximumMask, guide: false }"
              puiMinMaxNumber
              [maxNumber]="MAX_COUNT"
              [minNumber]="1" />
          </pui-input-field>
          <button class="disabled-button" [disabled]="true" pui-button i18n="@@recurrenceSessions">sessions</button>
        </div>
        <pui-input-error [formControl]="form.controls.count" role="alert"></pui-input-error>
      </div>
    </div>
  </pui-toggle>
</form>
