const NAMESPACE = 'system-notifications';
const DELIMETER = ':';

export const loadNotificationsEvent = `${NAMESPACE}${DELIMETER}load-notifications`;
export const notificationsLoadedEvent = `${NAMESPACE}${DELIMETER}notifications-loaded`;

export const loadRecentNotificationsEvent = `${NAMESPACE}${DELIMETER}load-recent-notifications`;
export const recentNotificationsLoadedEvent = `${NAMESPACE}${DELIMETER}recent-notifications-loaded`;

export const markNotificationsReadEvent = `${NAMESPACE}${DELIMETER}mark-read`;
export const notificationsWereMarkedReadEvent = `${NAMESPACE}${DELIMETER}marked-read`;

export const newNotificationReceived = `${NAMESPACE}${DELIMETER}notification-created`;
