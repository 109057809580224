import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { filter, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { UserRoles } from '@app/shared/enums/user-roles';
import { ChatsService } from './chats.service';
import { ChatUpdateTypes, IChatUpdate, IAttachment, IFileUploading, IChatMessage } from './types';
import { AuthService } from '../auth/services/auth.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { InternalEvents } from '../analytics/types';
import { convertChatUpdateToBoardActiveChat } from '@app/screens/chat/utils/converters';

@Injectable()
export class ActiveChatService implements OnDestroy {
  // @ts-expect-error TS2345
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _chatUpdate$ = new BehaviorSubject<IChatUpdate>(null);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatId: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _isContact$ = new BehaviorSubject<boolean>(false);

  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _chatUpdateSubscription: Subscription;

  get isContact$(): Observable<boolean> {
    return this._isContact$;
  }

  get chatUpdate$(): Observable<IChatUpdate> {
    return this._chatUpdate$;
  }

  activeChat$ = this._chatUpdate$.pipe(
    filter(update => !update || update.type !== ChatUpdateTypes.CHAT_REMOVED),
    map(convertChatUpdateToBoardActiveChat)
  );

  // Emit when chat changed.
  chatId$ = this.activeChat$.pipe(
    pairwise(),
    filter(([prev, cur]) => prev.id !== cur.id),
    map(([, cur]) => cur.id)
  );

  /**
   *  Emit when you send a new message
   */
  sentMessage$ =
    // At first handle chat is change
    this.chatId$.pipe(
      switchMap(() =>
        // Then wait messages changes.
        this.activeChat$.pipe(
          map(chat => chat.messages),
          // Last message.
          map(messages => messages.slice(-1)[0]),
          // Check that message sent by user.
          filter(message => {
            if (!message) {
              return false;
            }

            return (message.payload as IChatMessage)?.sender?.id === this._authService.user.id;
          }),
          pairwise(),
          // Check what messsage is new.
          filter(([prev, cur]) => (prev.payload as IChatMessage).id !== (cur.payload as IChatMessage).id),
          map(([, cur]) => cur)
        )
      )
    );

  constructor(
    private readonly _chats: ChatsService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _authService: AuthService
  ) {}

  ngOnDestroy(): void {
    if (this._chatUpdateSubscription) {
      this._chatUpdateSubscription.unsubscribe();
    }
  }

  loadHistory(reversed = false): void {
    this._chats.loadHistory(this._chatId, reversed);
  }

  restart(chatOrUserId: string, workspaceId?: number, eventId?: number | null): void {
    if (this._chatUpdateSubscription) {
      this._chatUpdateSubscription.unsubscribe();
    }

    this._chatUpdateSubscription = merge(
      this._chats.startChat$(chatOrUserId, eventId).pipe(
        tap(chat => {
          this._chatId = chat.id;
        }),
        map(chat => ({ chatId: chat.id, chat } as IChatUpdate))
      ),
      this._chats.chatUpdate$
    )
      .pipe(
        tap(({ chatId, chat, type }) => {
          if (type === ChatUpdateTypes.TEMP_CHAT_PERSISTED && chatId === this._chatId && chat) {
            this._chatId = chat.id;
          }
        }),
        filter(({ chatId }) => chatId === this._chatId),
        tap(({ type }) => {
          if (type && type === ChatUpdateTypes.CHAT_REMOVED) {
            // @ts-expect-error TS2322
            this._chatId = null;
          }
        }),
        tap(({ chat }) => chat && chat.messages && chat.messages.length && this._chats.markMessagesRead(this._chatId))
      )
      .subscribe(chatUpdate => {
        this._chatUpdate$.next(chatUpdate);
        this._isContact$.next(false);
      });
  }

  // @ts-expect-error TS7006
  sendMessage(text: string, attachment?: IAttachment, meta?): void {
    if (this._authService.user.RoleId === UserRoles.GUIDE) {
      this._analyticsService.event(InternalEvents.MESSAGE_SENT_BY_COACH, {});
    }
    if (this._authService.user.RoleId === UserRoles.CLIENT) {
      this._analyticsService.event(InternalEvents.MESSAGE_SENT_BY_CLIENT, {});
    }
    // @ts-expect-error TS2345
    this._chats.sendMessage(this._chatId, text, attachment, meta);
  }

  uploadFile(file: File): Observable<IFileUploading | null> {
    return this._chats.uploadFile(this._chatId, file);
  }
}
