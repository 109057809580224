import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SimpleSession } from '@app/shared/interfaces/session';
import { ISessionInfo } from '@app/shared/interfaces/services';
import { ProgramSessionTemplateInterface } from '@app/modules/program/types';

@Component({
  selector: 'app-quiz-session-info',
  templateUrl: './quiz-session-info.component.html',
  styleUrls: ['./quiz-session-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// TODO: refactor in PR-5709
export class QuizSessionInfoComponent {
  readonly MAX_AVATARS = 2;

  @Input()
  session: (ISessionInfo<ProgramSessionTemplateInterface> & SimpleSession) | undefined;

  get sessionHasMultipleClients(): boolean {
    return !!this.session && !!this.session.clients && this.session.clients?.length > 1;
  }
}
