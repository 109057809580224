import { NotificationsService } from 'angular2-notifications';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { InternalEvents } from '@app/core/analytics/types';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BrandingService } from '@app/core/branding/branding.service';
import { CurrencyService } from '@app/core/currency/currency.service';
import { LocaleService } from '@app/core/locale/locale.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { CurrentPaymentService } from '@app/modules/current-payment/services/current-payment.service';
import { PaymentGateways } from '@app/shared/enums/payment-gateways';
import { PaymentMethods } from '@app/shared/enums/payment-methods';
import { UserRoles } from '@app/shared/enums/user-roles';
import { GlobalConfig } from '@cnf/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGiftCoupon } from '../../types';
import { InfoModalComponent } from '../info-modal/info-modal.component';

const rangeMin = 50;

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-gift-coupon-selector',
  templateUrl: './gift-coupon-selector.component.html',
  styleUrls: ['../../../../shared/directives/radio/radio.scss', './gift-coupon-selector.component.scss']
})
export class GiftCouponSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject();

  readonly MAX_COMMENT_LENGTH = 2000;

  readonly FIRST_NAME_MAX_LENGTH = 50;

  readonly LAST_NAME_MAX_LENGTH = 50;

  readonly MY_ACCOUNT_FORM_KEY = 'giftFormMyAccount';

  readonly RECIPIENT_INFO_FORM_KEY = 'giftFormRecipientInfo';

  readonly PaymentMethods: typeof PaymentMethods = PaymentMethods;

  readonly paymentGateway;

  readonly PaymentGateways: typeof PaymentGateways = PaymentGateways;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCardInfo: any = {};

  // @ts-expect-error TS2564
  giftCouponMyInfoForm: FormGroup;

  // @ts-expect-error TS2564
  giftCouponRecipientInfoForm: FormGroup;

  // @ts-expect-error TS2564
  isAuthenticated: boolean;

  // @ts-expect-error TS2564
  isLoading: boolean;

  currentStep = 0;

  // @ts-expect-error TS2564
  value: number;

  config = {
    urlPrivacy: '#',
    urlTerms: '#'
  };

  disableSavedCards = true;

  platformName: string;

  isOtherCurrencySide: boolean;

  @ViewChild('sliderTip', { static: false })
  // @ts-expect-error TS2564
  sliderTipRef: ElementRef;

  constructor(
    public currency: CurrencyService,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _modal: NgbModal,
    private _notifications: NotificationsService,
    private _localStorageService: LocalStorageService,
    private _currentPaymentsService: CurrentPaymentService,
    private _analyticsService: AnalyticsService,
    private _localeService: LocaleService,
    private _brandingService: BrandingService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {
    this.isOtherCurrencySide = this._localeService.getLocale().dateTimeLocale === 'it';
    // @ts-expect-error TS2322
    this.platformName = this._runtimeConfigService.get('platformName');
    this.paymentGateway = this._runtimeConfigService.get('paymentGateway');
  }

  ngAfterViewInit(): void {
    const myInfoValue = this._localStorageService.getItem(this.MY_ACCOUNT_FORM_KEY);
    if (myInfoValue && !this.isAuthenticated) {
      const val = JSON.parse(myInfoValue);
      if (val.firstName && val.lastName && val.email) {
        this.giftCouponMyInfoForm.setValue(val);
      }
    }
    const recipientInfoValue = this._localStorageService.getItem(this.RECIPIENT_INFO_FORM_KEY);
    if (recipientInfoValue) {
      const val = JSON.parse(recipientInfoValue);
      if (val.firstName && val.lastName && val.email) {
        this.giftCouponRecipientInfoForm.setValue(val);
      }
    }
  }

  ngOnInit(): void {
    this.giftCouponMyInfoForm = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(this.FIRST_NAME_MAX_LENGTH)]],
      lastName: ['', [Validators.required, Validators.maxLength(this.LAST_NAME_MAX_LENGTH)]],
      email: ['', [Validators.required, Validators.email]],
      value: [50]
    });
    this.giftCouponRecipientInfoForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.maxLength(this.MAX_COMMENT_LENGTH)]],
      firstName: ['', [Validators.required, Validators.maxLength(this.FIRST_NAME_MAX_LENGTH)]],
      lastName: ['', [Validators.required, Validators.maxLength(this.LAST_NAME_MAX_LENGTH)]],
      agree: ['', [Validators.requiredTrue]]
    });

    this._authService
      .onAuth()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        if (user) {
          if (user.firstName) {
            this.giftCouponMyInfoForm.controls.firstName.setValue(user.firstName);
            this.giftCouponMyInfoForm.controls.firstName.disable();
          }
          if (user.lastName) {
            this.giftCouponMyInfoForm.controls.lastName.setValue(user.lastName);
            this.giftCouponMyInfoForm.controls.lastName.disable();
          }
          if (user.email) {
            this.giftCouponMyInfoForm.controls.email.setValue(user.email);
            this.giftCouponMyInfoForm.controls.email.disable();
          }
          this.isAuthenticated = true;
          if (user.RoleId === UserRoles.CLIENT) {
            this.disableSavedCards = false;
          }
        } else {
          this.giftCouponMyInfoForm.controls.firstName.enable();
          this.giftCouponMyInfoForm.controls.lastName.enable();
          this.giftCouponMyInfoForm.controls.email.enable();
          this.isAuthenticated = false;
        }
      });

    this._analyticsService.event(InternalEvents.GIFT_COUPON);
    this._brandingService.globalConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => this._setConfig(config));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  nextPartOne(): void {
    if (this.isLoading) {
      return;
    }
    this.value = this.giftCouponMyInfoForm.controls.value.value;
    if (this.giftCouponMyInfoForm.invalid) {
      Object.keys(this.giftCouponMyInfoForm.controls).forEach(key => {
        const control = this.giftCouponMyInfoForm.controls[key];
        control.markAsDirty();
        control.markAsTouched();
      });
      return;
    }
    this._localStorageService.setItem(this.MY_ACCOUNT_FORM_KEY, JSON.stringify(this.giftCouponMyInfoForm.value));
    this.currentStep++;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _openInfoModal(): void {
    const ref = this._modal.open(InfoModalComponent, { centered: true });
    ref.componentInstance.isAuthenticated = this.isAuthenticated;
  }

  nextPartTwo(): void {
    if (this.isLoading) {
      return;
    }
    if (this.giftCouponRecipientInfoForm.invalid) {
      Object.keys(this.giftCouponRecipientInfoForm.controls).forEach(key => {
        const control = this.giftCouponRecipientInfoForm.controls[key];
        control.markAsDirty();
        control.markAsTouched();
      });
      return;
    }
    this._localStorageService.setItem(
      this.RECIPIENT_INFO_FORM_KEY,
      JSON.stringify(this.giftCouponRecipientInfoForm.value)
    );
    this.currentStep++;
  }

  back(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  nextPartThree(): void {
    if (this.isLoading) {
      return;
    }
    const giftCoupon = {} as IGiftCoupon;

    const senderValues = this.giftCouponMyInfoForm.getRawValue();
    const receiverValues = this.giftCouponRecipientInfoForm.getRawValue();
    giftCoupon.value = this.value;
    giftCoupon.receiverEmail = receiverValues.email;
    giftCoupon.receiverName = `${receiverValues.firstName} ${receiverValues.lastName}`;

    if (senderValues.email) {
      giftCoupon.senderEmail = senderValues.email;
    }

    if (senderValues.firstName) {
      giftCoupon.senderName = `${senderValues.firstName} ${senderValues.lastName}`;
    }

    if (receiverValues.comment) {
      giftCoupon.senderComment = receiverValues.comment;
    }

    this.isLoading = true;

    this._currentPaymentsService
      .pay()
      .coupon$({
        receiverEmail: giftCoupon.receiverEmail,
        receiverName: giftCoupon.receiverName,
        // @ts-expect-error TS2322
        senderEmail: giftCoupon.senderEmail,
        // @ts-expect-error TS2322
        senderName: giftCoupon.senderName,
        // @ts-expect-error TS2322
        senderComment: giftCoupon.senderComment,
        value: giftCoupon.value
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isLoading = false;
          this._localStorageService.removeItem(this.MY_ACCOUNT_FORM_KEY);
          this._localStorageService.removeItem(this.RECIPIENT_INFO_FORM_KEY);
          this._openInfoModal();
        },
        error => {
          this.isLoading = false;

          if (error.isAborted) {
            return;
          }

          if (error.message) {
            const title = `Unfortunately, we were unable to send your gift card`;
            this._notifications.error(title, error.message);
          }
        }
      );
  }

  // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
  valueChanged(e: any): void {
    let val = +e.target.value.replace(/\$/g, '');

    if (!Number.isNaN(val)) {
      if (val < rangeMin) {
        val = rangeMin;
      }

      e.target.value = this.currency.defaultCurrencySign + Math.floor(val / 50) * 50;
      this.giftCouponMyInfoForm.controls.value.setValue(val);
    } else {
      e.target.value = this.currency.defaultCurrencySign + this.giftCouponMyInfoForm.controls.value.value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _setConfig({ urlPrivacy, urlTerms }: GlobalConfig): void {
    this.config.urlTerms = urlTerms;
    this.config.urlPrivacy = urlPrivacy;
  }
}
