<ng-container [formGroup]="moduleForm">
  <div class="form-group">
    <label class="label top-label" for="module_title" i18n="@@title">Title</label>
    <pui-text-editor
      class="module-title-editor"
      id="module_title"
      data-qa-id="content-module-title-editor"
      formControlName="titleDeltaFormat"
      format="object"
      placeholder="Insert text here ..."
      i18n-placeholder="@@contentModuleComponentInsertText"
      [modules]="moduleTitleQuillModules"
      (contentChanged)="titleChange.emit($event)"></pui-text-editor>
  </div>

  <div class="form-group" *ngIf="!hideInstructor">
    <div class="composite-label side-by-side">
      <label class="label top-label" i18n="@@moduleInstructor" for="module_instructor">Instructor</label>
      <a
        class="tooltip-button"
        ngbTooltip="Instructor will be allowed to edit this module and will be notified of all module activity"
        placement="top"
        i18n-ngbTooltip="@@moduleInstructorTooltip"></a>
    </div>
    <app-module-instructor formControlName="instructor" id="module_instructor"></app-module-instructor>
  </div>

  <div class="form-group">
    <app-module-activation-select
      formControlName="activation"
      [options]="moduleActivations"
      [moduleFormTouched]="moduleForm.get('activation')?.touched"></app-module-activation-select>
  </div>

  <div class="form-group">
    <label class="label top-label" i18n="@@contentModuleCompletionOptions">Completion options</label>
    <app-module-completion-select
      [moduleType]="moduleForm.value.moduleType"
      data-qa-id="content-module-completion-options-select"
      formControlName="completionType"></app-module-completion-select>
  </div>

  <div class="form-group content-group">
    <label class="label top-label" for="module_content" i18n="@@readingMaterial">Module content</label>
    <app-text-editor-image
      class="module-content-editor"
      id="module_content"
      qaId="content-module-module-content-editor"
      formControlName="content"
      placeholder="Insert content here..."
      i18n-placeholder="@@contentModuleComponentInsertContent"
      [extendedVideoUploader]="true"></app-text-editor-image>
  </div>
</ng-container>
