import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { BookingService } from '@app/modules/book-service/services/booking.service';
import { ClientNotesApiService } from '@app/modules/client-notes/client-notes-api.service';
import { ClientNotesService } from '@app/modules/client-notes/client-notes.service';
import { ClientAllNotesComponent } from '@app/modules/client-notes/components/client-all-notes/client-all-notes.component';
import { ModuleSelectorComponent } from '@app/modules/program/components/module-selector/module-selector.component';
import { QuizModuleComponent } from '@app/modules/program/components/quiz-module/quiz-module.component';
import { WorkspacesTypes } from '@app/shared/enums/workspaces-types';
import { INoteEditorContent } from '@app/shared/interfaces/notes';
import {
  ClientProgram,
  GroupSessionModule,
  IClientProgramModuleNote,
  IClientQuestionAnswer,
  isContentModule,
  isGroupSessionModule,
  isQuizModule,
  isRestrictedModule,
  isSessionModule,
  ModuleStatuses,
  ProgramModule,
  QuizModule,
  QuizStatuses,
  SessionModule
} from '@app/shared/interfaces/programs/client-programs';
import { ModuleCompletionTypes, ModuleTypes } from '@app/shared/interfaces/programs/program-module';
import { SimpleSession } from '@app/shared/interfaces/session';
import { PUI_IS_DESKTOP, PuiDrawerService } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-program-modules-board',
  templateUrl: './program-modules-board.component.html',
  styleUrls: ['./program-modules-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramModulesBoardComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _program!: ClientProgram;
  isAdmin = this.authService.isPlatformAdmin();

  currentModuleIndex = -1;

  progressValue = 0;

  currentNoteStatus!: string;

  private noteChangeSubscription!: Subscription;

  private serviceId = Symbol('ProgramModulesBoardComponent');

  private destroy$ = new Subject<void>();

  activeId: 'path' | 'note' = 'path';

  readonly ModuleCompletionTypes = ModuleCompletionTypes;

  readonly ModuleTypes = ModuleTypes;

  private formChangedByUser = false;

  @Input()
  autoSave = false;

  @Input()
  isPreview = false;

  @Input()
  moduleId = -1;

  @Input()
  set program(value: ClientProgram) {
    this._program = value;
    this.updateProgress();
  }

  @Output()
  finishQuiz = new EventEmitter<{ moduleId: number }>();

  @Output()
  moduleComplete = new EventEmitter<number>();

  @Output()
  moduleOpened = new EventEmitter();

  @Output()
  retryQuiz = new EventEmitter<{ moduleId: number }>();

  @Output()
  startProgram = new EventEmitter();

  @Output()
  submitQuiz = new EventEmitter<{ moduleId: number; answers: IClientQuestionAnswer[] }>();

  @Output()
  sessionAction = new EventEmitter<{
    moduleId: number;
    type: 'book' | 'reschedule' | 'cancel';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
  }>();

  @ViewChild('moduleSelect', { static: true })
  moduleSelect!: ModuleSelectorComponent;

  @ViewChild('mainPanelScrollBar', { static: false })
  mainPanelScrollBar!: NgScrollbar;

  @ViewChild('quizModule', { static: false })
  quizModule!: QuizModuleComponent;

  constructor(
    @Inject(PUI_IS_DESKTOP) readonly isDesktop: Observable<boolean>,
    private readonly clientNotes: ClientNotesService,
    private readonly clientNotesApiService: ClientNotesApiService,
    private readonly drawer: PuiDrawerService,
    private readonly cdr: ChangeDetectorRef,
    private authService: AuthService,
    private bookingService: BookingService
  ) {}

  get currentModule(): ProgramModule {
    return this.programModules[this.currentModuleIndex];
  }

  get currentModuleNote(): IClientProgramModuleNote | null {
    return this.currentModule && this.currentModule.note;
  }

  get isContentModule(): boolean {
    return this.currentModule != null && isContentModule(this.currentModule);
  }

  get isGroupSessionModule(): boolean {
    return this.currentModule != null && isGroupSessionModule(this.currentModule);
  }

  get isLastModule(): boolean {
    return this.currentModuleIndex === this.programModules.length - 1;
  }

  get isNextModuleAccessible(): boolean {
    return !this.isLastModule && ['allowed', 'seen'].includes(this.programModules[this.currentModuleIndex + 1].status);
  }

  get isProgramFinished(): boolean {
    return (
      this.isLastModule &&
      this.currentModule &&
      this.currentModule.status === 'seen' &&
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (!this.isQuizModule || (<QuizModule>this.currentModule).quiz.status === QuizStatuses.COMPLETED)
    );
  }

  get isProgramNotStarted(): boolean {
    return !!this.program && this.program.notStarted;
  }

  get isProgramStarted(): boolean {
    return !!this.program && !this.isProgramNotStarted;
  }

  get isProgramUpcoming(): boolean {
    return !this.program || (this.currentModuleIndex < 0 && !this.isProgramNotStarted);
  }

  get isQuizModule(): boolean {
    return this.currentModule != null && isQuizModule(this.currentModule);
  }

  get isSessionModule(): boolean {
    return this.currentModule != null && isSessionModule(this.currentModule);
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get program(): ClientProgram {
    return this._program;
  }

  get programModules(): ProgramModule[] {
    return (this.program && this.program.modules) || [];
  }

  // [TODO] Move this logic into service
  ngOnInit(): void {
    this.clientNotes.clientNotesUpdate$
      .pipe(
        filter(
          ({ serviceId, noteId }) =>
            // @ts-expect-error TS2769
            serviceId !== this.serviceId && this.currentModuleNote && noteId === this.currentModuleNote.id
        ),
        switchMap(({ noteId }) => this.clientNotesApiService.getNote$(noteId)),
        takeUntil(this.destroy$)
      )
      .subscribe(({ contentDeltaFormat, contentText, content, updatedAt }) => {
        const module = this.currentModule;
        // @ts-expect-error TS2322
        module.note = { ...module.note, contentDeltaFormat, contentText, content, updatedAt };
        this.cdr.detectChanges();
      });

    this.clientNotes.clientNoteCreate$
      .pipe(
        filter(({ serviceId }) => serviceId !== this.serviceId),
        switchMap(({ noteId }) => this.clientNotesApiService.getNote$(noteId)),
        takeUntil(this.destroy$)
      )
      .subscribe(note => {
        const module = this.currentModule;
        // @ts-expect-error TS2322
        module.note = note;
        this.cdr.detectChanges();
      });

    this.clientNotes.clientNoteDelete$
      .pipe(
        // @ts-expect-error TS2769
        filter(({ noteId }) => this.currentModuleNote && noteId === this.currentModuleNote.id),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const module = this.currentModule;
        module.note = null;
        this.currentNoteStatus = 'new';
        // @ts-expect-error TS2322
        this.noteChangeSubscription = undefined;
        this.cdr.detectChanges();
      });

    this.selectFirstAvailableModule();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  book(): void {
    // TODO: PR-5909 refactor this, same code in client-session-actions.service.ts
    const serviceId = (this.currentModule as GroupSessionModule).service?.id as number;
    if (this.program.workspaceType === WorkspacesTypes.SOLO) {
      const guideId = this.program.author.id;

      this.bookingService.openSoloGuideAvailableProgramSession(
        guideId,
        this.program.id,
        serviceId,
        this.program.workspaceType
      );
    } else {
      const workspaceId = this.currentModule.instructor?.workspaceId as number;

      this.bookingService.openTeamAvailableProgramSession(
        workspaceId,
        this.program.id,
        serviceId,
        this.program.workspaceType as WorkspacesTypes
      );
    }
  }

  nextModule(): void {
    this.selectFirstAvailableModule();
  }

  retryQuizByModule(event: { moduleId: number }): void {
    this.retryQuiz.emit(event);
  }

  selectModule(module: ProgramModule): void {
    if (!module || isRestrictedModule(module)) {
      return;
    }

    this.changeModule(module);
  }

  changeModule(module: ProgramModule): void {
    this.formChangedByUser = false;
    this.currentModuleIndex = this.programModules.findIndex(programModule => programModule === module);

    if (this.moduleSelect) {
      this.moduleSelect.selectModule(module);
    }

    if (
      module.status === 'allowed' &&
      module.completionType === ModuleCompletionTypes.AUTO &&
      ![ModuleTypes.SESSION, ModuleTypes.GROUP_SESSION].includes(module.type)
    ) {
      this.moduleComplete.emit(module.id);
    }

    this.moduleOpened.emit(module.id);
    this.currentNoteStatus = this.currentModule.note ? 'saved' : 'new';
    // @ts-expect-error TS2322
    this.noteChangeSubscription = undefined;
    this.cdr.detectChanges();
  }

  onQuizSubmit(quizDetails: { moduleId: number; answers: IClientQuestionAnswer[] }): void {
    if (this.isPreview && this.isNextModuleAccessible) {
      this.nextModule();
      return;
    }

    this.submitQuiz.emit(quizDetails);
  }

  reschedule(): void {
    // TODO: 5909 need to add typed session to program response
    const sessionModule = this.currentModule as SessionModule | GroupSessionModule;
    const session: SimpleSession = {
      ...sessionModule.session,
      templateId: sessionModule.service?.id
    } as SimpleSession;
    this.sessionAction.emit({
      moduleId: this.currentModule.id,
      type: 'reschedule',
      payload: {
        session,
        instructor: { ...this.currentModule.instructor, workspaceType: this.program.workspaceType }
      }
    });
  }

  cancel(): void {
    this.sessionAction.emit({
      moduleId: this.currentModule.id,
      type: 'cancel',
      payload: {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        session: (<SessionModule | GroupSessionModule>this.currentModule).session
      }
    });
  }

  private calculateCurrentModuleIndex(programModules: ProgramModule[]): number {
    const [numberOfSeenModules, lastModuleSeen] = programModules.reduce(
      (counters, module, index) => {
        if (module.status === 'seen') {
          counters[0] = counters[0] + 1;
          counters[1] = index;
        }

        return counters;
      },
      [0, -1]
    );

    this.progressValue = programModules.length ? numberOfSeenModules / programModules.length : 0;

    let currentModuleIndex = -1;

    if (lastModuleSeen > -1) {
      currentModuleIndex = lastModuleSeen;
    } else if (programModules.length) {
      currentModuleIndex = 0;
    }

    return currentModuleIndex;
  }

  private selectFirstAvailableModule(): void {
    const firstAvailableModule = this.programModules.find(module => module.status === ModuleStatuses.ALLOWED);
    // @ts-expect-error TS2345
    this.selectModule(firstAvailableModule);
  }

  private updateProgress(): void {
    const programModules = this.programModules;

    let moduleIndex =
      // eslint-disable-next-line id-length
      this.moduleId > 0 ? this.programModules.findIndex(m => m.id === this.moduleId) : this.currentModuleIndex;

    if (moduleIndex === -1) {
      moduleIndex = this.calculateCurrentModuleIndex(programModules);
    }

    if (moduleIndex > -1) {
      const module = programModules[moduleIndex];

      this.selectModule(module);
    }
  }

  // [TODO] Move this logic into service
  onNoteChange({ html, content, text }: INoteEditorContent, stopEditing: boolean): void {
    const module = this.currentModule;
    const moduleId = module.id;
    const programId = this._program.id;
    const currentNoteHtml = module.note && module.note.content;
    if (text && text.trim() === '' && stopEditing && module.note && module.note.id) {
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      this.clientNotesApiService.deleteNote$(module.note.id).subscribe(() => {
        module.note = null;
        this.currentNoteStatus = 'new';
        // @ts-expect-error TS2322
        this.noteChangeSubscription = undefined;
        this.cdr.detectChanges();
      });
      return;
    }
    switch (this.currentNoteStatus) {
      case 'unsaved-new':
      case 'new':
        if (!html || html.trim() === '') {
          return;
        }
        this.currentNoteStatus = 'new-pending';
        this.cdr.detectChanges();
        this.noteChangeSubscription = this.clientNotesApiService
          .createNote$(programId, moduleId, {
            content: html,
            contentDeltaFormat: content,
            contentText: text
          })
          .pipe(finalize(() => this.cdr.detectChanges()))
          // eslint-disable-next-line rxjs-angular/prefer-takeuntil
          .subscribe(
            note => {
              // @ts-expect-error TS2322
              module.note = note;
              this.currentNoteStatus = 'saved';
              this.clientNotes.createNote({
                serviceId: this.serviceId,
                noteId: note.id
              });
            },
            () => {
              this.currentNoteStatus = 'unsaved-new';
            }
          );
        break;
      case 'unsaved':
      case 'saved':
        if (currentNoteHtml === html && this.currentNoteStatus === 'saved') {
          return;
        }
        this.currentNoteStatus = 'save-pending';
        this.cdr.detectChanges();
        this.noteChangeSubscription = this.clientNotesApiService
          // @ts-expect-error TS2531
          .updateNote$(module.note.id, {
            content: html,
            contentDeltaFormat: content,
            contentText: text
          })
          .pipe(finalize(() => this.cdr.detectChanges()))
          // eslint-disable-next-line rxjs-angular/prefer-takeuntil
          .subscribe(
            ({ content: contentHtml, contentDeltaFormat, contentText, updatedAt }) => {
              if (stopEditing) {
                // @ts-expect-error TS2322
                module.note = {
                  ...module.note,
                  contentDeltaFormat,
                  contentText,
                  content: contentHtml,
                  updatedAt
                };
              } else {
                // @ts-expect-error TS2322
                module.note = { ...module.note, updatedAt };
              }
              this.clientNotes.updateNote({
                serviceId: this.serviceId,
                // @ts-expect-error TS2531
                noteId: module.note.id
              });
              this.currentNoteStatus = 'saved';
            },
            () => {
              this.currentNoteStatus = 'unsaved';
            }
          );
        break;
      case 'save-pending':
        if (currentNoteHtml === html) {
          return;
        }
        if (this.noteChangeSubscription) {
          this.noteChangeSubscription.unsubscribe();
        }
        this.currentNoteStatus = 'saved';
        this.cdr.detectChanges();
        this.onNoteChange({ html, content, text }, stopEditing);
        break;
      case 'new-pending':
        return;
    }
  }

  onAllNotesShow(): void {
    this.drawer.open(ClientAllNotesComponent, { position: 'right', maxWidth: '600px' }, { type: 'all-notes' });
  }
}
