<div *ngIf="state && message" class="notification-wrapper">
  <pui-icon [svgIcon]="icons[state]" size="s"></pui-icon>
  <span class="pui-space_left-1 pui-space_right-1">
    {{ message }}
  </span>
</div>
<ng-container *ngIf="!!_popoverRef">
  <button (click)="_popoverRef.close()" pui-icon-button size="xxs" appearance="adaptive" [ghost]="true" shape="square">
    <pui-icon svgIcon="pui:x" size="m"></pui-icon>
  </button>
</ng-container>
