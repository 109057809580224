import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GuideServiceTypes } from '@app/shared/interfaces/services';

@Component({
  selector: 'app-guide-service-editor-landing',
  templateUrl: './guide-service-editor-landing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceEditorLandingComponent {
  @Input() type!: GuideServiceTypes;

  @Input() title!: string;

  @Input() link?: string;

  @Output() closeEditor = new EventEmitter();
}
