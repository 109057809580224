import { Fragment, Resolver, ResolverOptions, Template, TemplatesRecord } from '../../model/interfaces';
import { getDefaultLocale, getDefaultTemplates } from '../../utils/get-defaults';

export default function toFragment(template: Template, params?: ResolverOptions): Fragment[] {
  const locale = (params && params.templateOptions && params.templateOptions.locale) || getDefaultLocale();
  const templates: TemplatesRecord = {
    ...getDefaultTemplates(locale),
    ...((params && params.templates) || ({} as TemplatesRecord))
  };

  let fragments;

  if (typeof templates[template] === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fragments = (templates[template] as Resolver<any>)(params && params.value, params && params.templateOptions);
  } else {
    fragments = templates[template];
  }

  return fragments || [];
}
