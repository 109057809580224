import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientGuide } from '@app/core/users/types';
import { buildDirectChatLinkId } from '@app/shared/utils/direct-chat-user-id-builder';
import { ClientSessionsCommunicationsService } from '../../services/client-sessions-communications.service';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-client-guides-list',
  templateUrl: './client-guides-list.component.html',
  styleUrls: [
    '../../../../../../scss/dashboard/related-users.scss',
    '../../../../../../scss/menus/button-with-vertical-dots.scss',
    './client-guides-list.component.scss'
  ]
})
export class ClientGuidesListComponent {
  // @ts-expect-error TS7008
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _guides;

  @Input()
  set guides(value: ClientGuide[]) {
    this._guides = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get guides() {
    return this._guides;
  }

  @Output()
  bookSession = new EventEmitter<ClientGuide>();

  // @ts-expect-error TS2564
  searchText: string;

  constructor(private _emitter: ClientSessionsCommunicationsService) {}

  buildDirectChatId(user: ClientGuide): string {
    return buildDirectChatLinkId(user);
  }

  emitLeaveReview(sessionId: number): void {
    this._emitter.leaveReview(sessionId);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackByFn(index, item): any {
    return item.id + item.isOnline;
  }
}
