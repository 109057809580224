import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IntegrationService } from '../../interfaces/integration-service';
import { LoopedinConfig } from './loopedin.config';

interface LoopedIn {
  sidebar: {
    notificationCount: number;
    toggle(): void;
  };
}

const appendLoopedinScript = (window: Window, renderer: Renderer2, config: LoopedinConfig): void => {
  // @ts-expect-error TS7015
  window['li_sidebar'] = {
    workspace_id: config.workspace_id,
    auto_init: true
  };

  const script1 = renderer.createElement('script');
  script1.defer = true;
  script1.src = config.src;
  const script0 = window.document.getElementsByTagName('script')[0];
  script0.parentNode?.insertBefore(script1, script0);
};

@Injectable({
  providedIn: 'root'
})
export class LoopedinService implements IntegrationService {
  private readonly renderer: Renderer2 | undefined;

  private readonly window: Window | undefined;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly rendererFactory: RendererFactory2
  ) {
    if (isPlatformBrowser(this.platformId) && typeof window !== 'undefined') {
      this.renderer = this.rendererFactory.createRenderer(window.document, null);
      this.window = window;
    }
  }

  init(config: LoopedinConfig): void {
    if (!this.window || !this.renderer) {
      return;
    }

    appendLoopedinScript(this.window, this.renderer, config);
  }

  toggle(): void {
    const loopedin = this.getLoopedin();
    if (!loopedin) {
      return;
    }

    loopedin.sidebar.toggle();
  }

  notificationCount(): number {
    const loopedin = this.getLoopedin();
    if (!loopedin) {
      return 0;
    }

    return loopedin.sidebar.notificationCount;
  }

  getLoopedin(): LoopedIn | null {
    if (!this.window) {
      return null;
    }

    // @ts-expect-error TS7015
    const loopedin = this.window['LoopedIn'] as LoopedIn;

    if (!loopedin) {
      console.warn('LoopedIn script is not installed.');

      return null;
    }

    return loopedin;
  }
}
