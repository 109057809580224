import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UniversalSelectorModalComponent } from './components/selector-modal/universal-selector-modal.component';
import { UniversalSelectorGroup } from '@app/shared/components/universal-selector/types';
import { PuiDialog, PuiDialogService } from '@awarenow/profi-ui-core';

@Component({
  selector: 'app-universal-selector',
  templateUrl: './universal-selector.component.html',
  styleUrls: ['./universal-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniversalSelectorComponent),
      multi: true
    }
  ]
})
export class UniversalSelectorComponent implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _all: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  named: any = [];

  @Input()
  caption = 'Add tags';

  @Input()
  limit = Infinity;

  @Input()
  buttonTemplate!: TemplateRef<{ action: () => {} }>;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemTemplate!: TemplateRef<{ item: any; active: boolean }>;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupTemplate!: TemplateRef<{ group: any }>;

  @Input()
  set all(value: UniversalSelectorGroup[]) {
    if (value && value.length) {
      this._all = value;

      // eslint-disable-next-line id-length
      for (const v of value) {
        if (v && v.children) {
          for (const element of v.children) {
            this.named[element.id] = {
              name: element.name,
              description: element.description,
              subtitle: element.subtitle
            };
          }
        }
      }
    }
  }

  @Input()
  emptyListModalTitle = null;

  @Input()
  emptyListModalDescription = null;

  @Output()
  valueChanged = new EventEmitter<number[]>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/explicit-function-return-type
  get all() {
    return this._all;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    // @ts-expect-error TS7006
    return this._value.filter(i => this.named[i]);
  }

  @Input()
  // eslint-disable-next-line id-length, @typescript-eslint/no-explicit-any
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.valueChanged.emit(this._value);
      this.onChange(v);
    }
  }
  constructor(
    private readonly modalService: NgbModal,
    private readonly dialog: PuiDialogService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  writeValue(value: any) {
    if (value) {
      if (typeof value === 'object' && value.length) {
        this._value = value;
      } else {
        this._value = [value];
      }
    } else {
      this._value = [];
    }
    this.valueChanged.emit(this._value);
    this.onChange(value);
    this.cdr.markForCheck();
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onChange = _ => {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  openModal(): void {
    if (
      (!this.all || this.all.length === 0 || this.all[0].children.length === 0) &&
      this.emptyListModalTitle &&
      this.emptyListModalDescription
    ) {
      const dialog = new PuiDialog();

      dialog.title = this.emptyListModalTitle;
      dialog.description = this.emptyListModalDescription;

      this.dialog.open(dialog, {
        size: 's',
        hasCloseButton: true
      });

      return;
    }

    const { result, componentInstance } = this.modalService.open(UniversalSelectorModalComponent, {
      size: 'lg',
      centered: true
    });

    componentInstance.all = this.all;
    componentInstance.title = this.caption;
    componentInstance.limit = this.limit;
    componentInstance.selectedElements = this.value;
    componentInstance.itemTemplate = this.itemTemplate;
    componentInstance.groupTemplate = this.groupTemplate;

    result.then(({ selected }) => this.writeValue(selected)).catch(() => {});
  }

  // @ts-expect-error TS7006
  delete(index): void {
    const elements = [];
    for (let i = 0; i < this.value.length; i += 1) {
      if (i !== index) {
        elements.push(this.value[i]);
      }
    }
    this.writeValue(elements);
  }

  getTooltipText(item: { name?: string; subtitle?: string }): string {
    let text = `${item.name || ''}`;

    if (item.subtitle) {
      text += ` (${item.subtitle})`;
    }

    return text;
  }
}
