<ng-container [formGroup]="moduleForm">
  <div class="form-group">
    <app-module-activation-select
      formControlName="activation"
      [options]="moduleActivations"
      [moduleFormTouched]="moduleForm.get('activation')?.touched"></app-module-activation-select>
  </div>

  <div class="form-group">
    <label class="label top-label" i18n="@@quizModuleCompletionOptions">Completion options</label>
    <app-module-completion-select
      [moduleType]="moduleForm.value.moduleType"
      formControlName="completionType"></app-module-completion-select>
  </div>
  <div class="questions">
    <h4 pui-typography="h4" i18n="@@questions">Questions</h4>
    <a
      *ngIf="!moduleForm.disabled"
      pui-link
      data-qa-id="quiz-module-upload-questions-from-form-button"
      disabled="true"
      class="questions-link"
      (click)="onLoadQuiz()">
      <pui-icon svgIcon="pui:download" size="xxs"></pui-icon>
      <span class="pui-space_left-1" i18n="@@programModuleFormUploadQuestions">Upload questions from form</span>
    </a>
  </div>
  <div class="form-group">
    <label class="label top-label" i18n="@@title">Title</label>
    <pui-text-editor
      class="module-title-editor"
      id="module_title"
      data-qa-id="quiz-module-title-editor"
      formControlName="titleDeltaFormat"
      format="object"
      [modules]="quillModules"
      (contentChanged)="titleChange.emit($event)"></pui-text-editor>
  </div>

  <div class="form-group">
    <label class="label top-label" i18n="@@quizModuleDescriptionText">
      Welcome message (explain the purpose of this follow-up questions)
    </label>
    <pui-text-editor
      class="module-description-editor"
      id="module_description"
      data-qa-id="quiz-module-welcome-message-editor"
      formControlName="descriptionDeltaFormat"
      format="object"
      [modules]="quillModules"
      (contentChanged)="descriptionChange.emit($event)"></pui-text-editor>
  </div>

  <div class="form-group" *ngIf="!hideInstructor">
    <div class="composite-label side-by-side">
      <label class="label top-label" i18n="@@moduleInstructor" for="module_instructor">Instructor</label>
      <a
        class="tooltip-button"
        ngbTooltip="Instructor will be allowed to edit this module and will be notified of all module activity"
        placement="top"
        i18n-ngbTooltip="@@moduleInstructorTooltip"></a>
    </div>
    <app-module-instructor formControlName="instructor" id="module_instructor"></app-module-instructor>
  </div>

  <div formGroupName="quiz" class="quiz">
    <div class="form-group" formArrayName="questions">
      <ng-container
        *ngFor="let question of quizQuestions.controls; let questionIndex = index"
        [formGroupName]="questionIndex">
        <div class="form-group">
          <div class="top-bar">
            <label class="label top-label q-label">
              <ng-container i18n="@@question">Question</ng-container>
              {{ questionIndex + 1 }}
            </label>
            <div class="actions">
              <ng-select
                [attr.data-qa-id]="'quiz-module-question-type-select-' + questionIndex"
                formControlName="type"
                [readonly]="question.value.id"
                [searchable]="false"
                [clearable]="false">
                <ng-option [value]="QuizQuestionType.SHORT_ANSWER" i18n="@@shortAnswer">Short answer</ng-option>
                <ng-option [value]="QuizQuestionType.LONG_ANSWER" i18n="@@longAnswer">Long answer</ng-option>
                <ng-option [value]="QuizQuestionType.TEXT" i18n="@@textQuestion">Text</ng-option>
                <ng-option [value]="QuizQuestionType.SINGLE_CHOICE" i18n="@@singleChoice">Single choice</ng-option>
                <ng-option [value]="QuizQuestionType.MULTIPLE_CHOICE" i18n="@@multipleChoice">
                  Multiple choice
                </ng-option>
                <ng-option [value]="QuizQuestionType.QUIZ" i18n="@@quizModuleQuizTitle">Quiz</ng-option>
                <ng-option [value]="QuizQuestionType.FILE_UPLOAD" i18n="@@fileUpload">File upload</ng-option>
                <ng-option [value]="QuizQuestionType.SIGNATURE" i18n="@@signature">Signature</ng-option>
              </ng-select>
              <button
                *ngIf="quizQuestions.length > 1"
                (click)="removeQuestionAtIndex(questionIndex)"
                [attr.data-qa-id]="'quiz-module-delete-question-button-' + questionIndex"
                class="remove"
                placement="top"
                ngbTooltip="Delete question"
                i18n-ngbTooltip="@@deleteQuestion"></button>
            </div>
          </div>

          <app-text-editor-image
            [qaId]="'quiz-module-question-editor-' + questionIndex"
            class="question-content-editor"
            formControlName="question"
            [extendedVideoUploader]="true"></app-text-editor-image>

          <div
            class="form-group question-options-group"
            *ngIf="
              question.get('type').value === QuizQuestionType.SINGLE_CHOICE ||
              question.get('type').value === QuizQuestionType.MULTIPLE_CHOICE
            "
            formArrayName="options">
            <div
              class="question-option-group"
              *ngFor="let option of getQuizQuestionOptions(questionIndex).controls; let optionIndex = index"
              [formGroupName]="optionIndex">
              <div class="question-option-row">
                <div class="question-option">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="text"
                    (keyup)="handleQuestionOptionKeyUp($event, question, questionIndex, optionIndex)"
                    placeholder="Answer choice"
                    i18n-placeholder="@@answerChoice" />
                </div>
                <app-button
                  class="remove-option-btn"
                  className="btn close"
                  ngbTooltip="Remove answer"
                  i18n-ngbTooltip="@@removeAnswer"
                  (click)="removeQuestionOptionAtIndex(questionIndex, optionIndex)"></app-button>
              </div>
            </div>
          </div>

          <div
            class="form-group has-option-explanation-group"
            *ngIf="
              question.get('type').value === QuizQuestionType.SINGLE_CHOICE ||
              question.get('type').value === QuizQuestionType.MULTIPLE_CHOICE
            ">
            <app-checkbox formControlName="hasOtherOption" i18n="@@addOtherOption">
              Add an "Other" answer option with comment field
            </app-checkbox>
            <div
              class="question-option-row other-option"
              *ngIf="question.get('hasOtherOption')?.value"
              [formGroup]="question.get('other')">
              <div class="question-option">
                <pui-input-field>
                  <input
                    pui-input-text
                    type="text"
                    class="form-control"
                    formControlName="text"
                    placeholder="Custom option answer"
                    i18n-placeholder="@@quizQuestionsCustomTextPlaceholder" />
                  <pui-input-error formControlName="text" role="alert"></pui-input-error>
                </pui-input-field>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.SHORT_ANSWER">
            <input
              type="text"
              class="form-control"
              readonly
              placeholder="Answer goes here..."
              i18n-placeholder="@@answerGoesHere" />
          </div>

          <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.LONG_ANSWER">
            <textarea
              type="text"
              class="form-control"
              readonly
              placeholder="Answer goes here..."
              i18n-placeholder="@@answerGoesHere"></textarea>
          </div>

          <div class="form-group" *ngIf="question.get('type').value === QuizQuestionType.SIGNATURE">
            <div class="signature-wrap">
              <div class="canvas"></div>
              <div class="bar">
                <div class="btns-wrap">
                  <app-button className="btn-link" i18n="@@quizQuestionsSignatureClear">Clear</app-button>
                  <app-button className="btn-link" i18n="@@quizQuestionsSignatureUndo">Undo</app-button>
                </div>
                <span i18n="@@signAbove">Sign above</span>
              </div>
            </div>
            <p i18n="@@signingPolicy" class="signing-policy">
              In signing this document with an electronic signature, I agree and understand that this signature is
              considered as legally valid as a handwritten signature.
            </p>
          </div>

          <ng-template [ngIf]="question.get('type').value === QuizQuestionType.QUIZ">
            <div class="form-group has-option-group">
              <app-checkbox
                data-qa-id="quiz-module-add-answer-options-checkbox"
                formControlName="hasOptions"
                i18n="@@addAnswerOptions">
                Add answer options
              </app-checkbox>
            </div>

            <div
              class="form-group question-options-group"
              *ngIf="question.get('hasOptions').value"
              formArrayName="options">
              <div
                class="question-option-group"
                *ngFor="
                  let option of getQuizQuestionOptions(questionIndex).controls;
                  let optionIndex = index;
                  let optionsCount = count
                "
                [formGroupName]="optionIndex">
                <div class="question-option-row">
                  <div class="question-option">
                    <div
                      class="is-question-option-correct-wrapper"
                      ngbTooltip="Correct answer"
                      i18n-ngbTooltip="@@correctAnswer"
                      [attr.data-qa-id]="'quiz-module-question-option-checkbox-' + questionIndex + '-' + optionIndex"
                      placement="bottom">
                      <app-checkbox formControlName="isCorrect"></app-checkbox>
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      formControlName="text"
                      [attr.data-qa-id]="'quiz-module-question-option-input-' + questionIndex + '-' + optionIndex"
                      (keyup)="handleQuestionOptionKeyUp($event, question, questionIndex, optionIndex)" />
                  </div>

                  <a
                    (click)="removeQuestionOptionAtIndex(questionIndex, optionIndex)"
                    class="remove-option-btn"
                    [attr.data-qa-id]="'quiz-module-remove-question-option-button-' + questionIndex + '-' + optionIndex"
                    *ngIf="optionsCount > 1"></a>
                </div>

                <div class="question-option-explanation-group">
                  <textarea
                    *ngIf="question.get('hasOptionExplanation').value"
                    class="form-control"
                    formControlName="explanation"></textarea>
                </div>
              </div>
            </div>

            <div
              class="form-group has-option-explanation-group"
              *ngIf="question.get('hasOptions').value && explanationsEnabled">
              <app-checkbox formControlName="hasOptionExplanation" i18n="@@addExplanationForCorrectAnswers">
                Add explanation of correct / incorrect answers
              </app-checkbox>
            </div>
          </ng-template>

          <ng-template [ngIf]="question.get('type').value === QuizQuestionType.FILE_UPLOAD">
            <div class="form-group" [attr.data-qa-id]="'quiz-module-question-upload-file-button-' + questionIndex">
              <div class="image-container">
                <div class="image-wrapper">
                  <label for="file" class="image-label">
                    <span i18n="@@uploadFileQuizModule">Upload file</span>
                  </label>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="block" *ngIf="moduleForm.enabled">
    <div class="new-question" (click)="toggleQuestionTypes()">
      <app-button data-qa-id="quiz-module-add-question-button" className="btn-add btn-lite">
        <ng-container i18n="@@addQuestion">Add question</ng-container>
      </app-button>
      <span class="add-question-arrow" [ngClass]="{ 'menu-open': showQuestionTypes }"></span>
    </div>
    <div class="question-types-wrap" [ngClass]="{ hidden: !showQuestionTypes }">
      <div class="question-types" (click)="toggleQuestionTypes()">
        <div
          data-qa-id="quiz-module-short-answer-type-button"
          class="question-type short-answer"
          (click)="addQuestion.emit(QuizQuestionType.SHORT_ANSWER)">
          <ng-container i18n="@@quizModuleShortAnswerTitle">Short answer</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-long-answer-type-button"
          class="question-type short-answer"
          (click)="addQuestion.emit(QuizQuestionType.LONG_ANSWER)">
          <ng-container i18n="@@quizModuleLongAnswerTitle">Long answer</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-single-choice-type-button"
          class="question-type single-choice"
          (click)="addQuestion.emit(QuizQuestionType.SINGLE_CHOICE)">
          <ng-container i18n="@@quizModuleSingleChoiceTitle">Single choice</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-file-upload-type-button"
          class="question-type file-upload"
          (click)="addQuestion.emit(QuizQuestionType.FILE_UPLOAD)">
          <ng-container i18n="@@quizModuleFileUploadTitle">File upload</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-quiz-type-button"
          class="question-type multiple-choice"
          (click)="addQuestion.emit(QuizQuestionType.QUIZ)">
          <ng-container i18n="@@quizModuleQuizTitle">Quiz</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-multiple-choice-type-button"
          class="question-type multiple-choice"
          (click)="addQuestion.emit(QuizQuestionType.MULTIPLE_CHOICE)">
          <ng-container i18n="@@quizModuleMultipleChoiceTitle">Multiple choice</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-signature-type-button"
          class="question-type signature"
          (click)="addQuestion.emit(QuizQuestionType.SIGNATURE)">
          <ng-container i18n="@@quizModuleSignatureTitle">Signature</ng-container>
        </div>
        <div
          data-qa-id="quiz-module-text-type-button"
          class="question-type text"
          (click)="addQuestion.emit(QuizQuestionType.TEXT)">
          <ng-container i18n="@@quizModuleTextQuestionTitle">Text</ng-container>
        </div>
        <div class="question-type"></div>
      </div>
    </div>
  </div>
</ng-container>
