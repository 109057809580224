import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingCancellationComponent } from '@appWidget/modules/booking-cancellation/booking-cancellation.component';

import { BookingCancellationConfirmationComponent } from './routes/confirmation/booking-cancellation-confirmation.component';
import { BookingCancellationConfirmationModule } from './routes/confirmation/booking-cancellation-confirmation.module';
import { BookingCancellationDetailsComponent } from './routes/details/booking-cancellation-details.component';
import { BookingCancellationDetailsModule } from './routes/details/booking-cancellation-details.module';

const cancellationDetailsStep = {
  id: 'details',
  name: 'cancellation',
  path: 'details'
};

const cancellationConfirmationStep = {
  id: 'confirmation',
  name: 'Confirmation',
  path: 'confirmation'
};

const CANCELLATION_STEPS = [cancellationDetailsStep, cancellationConfirmationStep];

const routes: Routes = [
  {
    path: ':sessionId',
    data: {
      steps: CANCELLATION_STEPS
    },
    component: BookingCancellationComponent,
    children: [
      {
        path: cancellationDetailsStep.path,
        data: {
          step: cancellationDetailsStep
        },
        component: BookingCancellationDetailsComponent,
        // TODO: was changed in PR-8791 because decline session from external calendars didn't work
        loadChildren: () => BookingCancellationDetailsModule
      },
      {
        path: cancellationConfirmationStep.path,
        data: {
          step: cancellationConfirmationStep
        },
        component: BookingCancellationConfirmationComponent,
        // TODO: was changed in PR-8791 because decline session from external calendars didn't work
        loadChildren: () => BookingCancellationConfirmationModule
      },
      {
        path: '**',
        redirectTo: cancellationDetailsStep.path
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingCancellationRoutingModule {}
