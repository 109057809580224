<section class="section form-section">
  <div class="row">
    <div class="col-md-4 offset-md-4">
      <h6
        data-qa-id="guide-service-sharing-editor-share-direct-link-title"
        class="sub-title"
        i18n="@@sessionTemplateSharingDirectLink">
        Share direct link
      </h6>
      <p
        class="note"
        data-qa-id="guide-service-sharing-editor-direct-link-text"
        i18n="@@guideServiceSharingEditorSharingLinkNote">
        This link sends clients directly to the
        <app-service-type [type]="type"></app-service-type>
        page
      </p>
      <div class="input-group share-link-wrapper">
        <input
          data-qa-id="guide-service-sharing-editor-share-link-input"
          type="text"
          readonly
          class="form-control shared-link"
          [value]="link"
          (copy)="onStandardCopied()" />

        <div class="input-group-append">
          <button
            data-qa-id="guide-service-sharing-editor-copy-link-button"
            type="button"
            class="input-group-text"
            ngxClipboard
            [cbContent]="link"
            (cbOnSuccess)="onBtnCopied()"
            (cbOnError)="onCopyFailed()"
            i18n="@@copyLink">
            Copy link
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 offset-md-4">
      <h6 class="sub-title" i18n="@@sessionTemplateSharingSocialMedia">Share on social media</h6>

      <app-guide-service-social-links
        [serviceLink]="link"
        (linkClicked)="linkCopied.emit($event)"></app-guide-service-social-links>
    </div>
  </div>

  <div class="row" *ngIf="false">
    <div class="col-md-4 offset-md-4">
      <h6 class="sub-title" i18n="@@sessionTemplateSharingInvitation">Invite to book</h6>
      <p>
        <ng-container i18n="@@sessionTemplateSharingInvitationNote">
          Send clients an email invite to book this
        </ng-container>
        <app-service-type [type]="type"></app-service-type>
      </p>
      <app-button className="btn-primary" i18n="@@sessionTemplateSharingInvitationButton">Invite to book</app-button>
    </div>
  </div>
</section>
