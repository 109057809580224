import { ChangeDetectorRef, Directive, OnInit, Optional, Self } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, NgControl } from '@angular/forms';
import { FormService } from '@app/core';
import { UpdateValueAndValidityService } from '@app/modules/session-forms/directives/update-value-and-validity/update-value-and-validity.service';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[updateValueAndValidity]',
  providers: [PuiDestroyService]
})
export class UpdateValueAndValidityDirective implements OnInit {
  constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly formUtils: FormService,
    private readonly form: ControlContainer,
    private readonly update$: UpdateValueAndValidityService,
    private readonly destroy$: PuiDestroyService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const form = this.form?.control;
    if (!(form instanceof FormGroup) && !(form instanceof FormArray)) {
      console.error(`Not provided FormGroup or FormArray for updateValueAndValidity directive.`);
      return;
    }
    this.update$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.formUtils.markInvalidForm(form);
      this.cdr.markForCheck();
    });
  }
}
