import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OfferGuideApiService } from '@app/modules/service-scheduling/services/offer-guide-api.service';
import { PuiInputCheckboxModule } from '@awarenow/profi-ui-core';
import { SharedModule } from '@libs/shared/shared.module';

import { RecurrenceFormComponent } from './recurrence-form.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, ReactiveFormsModule, PuiInputCheckboxModule],
  declarations: [RecurrenceFormComponent],
  providers: [OfferGuideApiService],
  exports: [RecurrenceFormComponent]
})
export class RecurrenceFormModule {}
