<div class="modal-header">
  <h3 class="modal-title" i18n="@@embeddableModalEmbeddableBookingButtonHeader">Embeddable Booking Button</h3>

  <div class="modal-note" i18n="@@embeddableModalHeaderNote">
    Customize the display of the Booking Button and include the code anywhere in your page's HTML to add the Booking
    Widget to your website.
  </div>

  <app-button class="close-btn" className="close" (click)="modal.dismiss()"></app-button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-sm-4">
      <h6 class="section-title" i18n="@@embeddableModalCustomization">Customization</h6>

      <app-book-button-customizer [formControl]="buttonOptionsControl"></app-book-button-customizer>
    </div>

    <div class="col-sm-8">
      <h6 class="section-title" i18n="@@embeddableModalHeaderEmbedCode">Embed code</h6>

      <textarea
        readonly
        [(ngModel)]="embeddableCode"
        (copy)="onEmbeddableCodeCopied(analyticCopyTypes.STANDARD)"
        class="embeddable-code"
        autosize></textarea>
    </div>
  </div>
</div>

<div class="modal-footer">
  <app-button
    className="btn-primary"
    i18n="@@embeddableModalCopyCode"
    ngxClipboard
    [cbContent]="embeddableCode"
    (cbOnSuccess)="onEmbeddableCodeCopied(analyticCopyTypes.BUTTON)"
    (cbOnError)="onEmbeddableCodeCopyFailed()">
    Copy code
  </app-button>
</div>
