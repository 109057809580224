import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { IServiceItemComponent } from '@app/modules/guide-service/types';

@Component({
  selector: 'app-guide-service-item',
  templateUrl: './guide-service-item.component.html',
  styleUrls: ['./guide-service-item.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'ui-app-guide-service-item'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideServiceItemComponent<T> {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _context: { service: T } & IServiceItemComponent;

  @Input()
  editorLink: unknown | string;

  @Input()
  // @ts-expect-error TS2564
  publicLink: string;

  @Input()
  // @ts-expect-error TS2564
  copyText: string;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionsMenu: TemplateRef<any>;

  @Input()
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceContainer: TemplateRef<any>;

  @Input()
  set service(service: T) {
    this._context = { service, editorLink: this.editorLink, publicLink: this.publicLink, copyText: this.copyText };
  }

  get context(): { service: T } {
    return this._context;
  }
}
