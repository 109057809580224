<div>
  <div class="pui-space_bottom-4">
    <h2 i18n="@@AddHosts" pui-typography="h2">Add hosts</h2>
  </div>

  <div class="pui-space_bottom-4">
    <span i18n="@@AddHostsDescription" pui-typography="body-m"
      >Enter a team member’s name or email to invite them to provide this service.</span
    >
  </div>

  <label pui-typography="body-s-bold">Name or email</label>

  <app-user-select
    class="add-hosts__user-select"
    placeholder="Host"
    i18n-placeholder="@@host"
    errorMessage="Host is required"
    i18n-errorMessage="@@hostIsRequred"
    [selectionList]="workspacesMembers$ | async"
    [preselectedList]="selectedHosts$ | async"
    (changeSelectedList)="onChangeHost($event)"></app-user-select>
</div>

<div class="timezone-drawer-wrapper__button">
  <button (click)="save()" pui-button>
    <span i18n="@@SendInvite">Send invite</span>
  </button>
</div>
