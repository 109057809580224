import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-iframe-uploader',
  templateUrl: './iframe-uploader.component.html',
  styleUrls: ['./iframe-uploader.component.scss']
})
export class IframeUploaderComponent implements OnInit {
  // @ts-expect-error TS2564
  uploadIframeForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.uploadIframeForm = this.formBuilder.group({
      iframeUrl: ['', [Validators.required]]
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  save() {
    if (!this.uploadIframeForm.valid) {
      return;
    }
    const url = this.uploadIframeForm.controls['iframeUrl'].value;
    this.modal.close(url);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  cancel() {
    this.modal.dismiss();
  }
}
