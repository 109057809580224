import { DateTime } from 'luxon';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable, OnDestroy } from '@angular/core';
import { GuidePublicServicesService } from '@app/core/public/guide-public-services.service';
import { ITimeRangesRequestOptions } from '@app/core/session/guide-offer-api.service';
import { GuideClientsService } from '@app/core/users/guide-clients.service';
import { GuideRelation } from '@app/core/users/types';
import { IGuideService } from '@app/modules/book-service';
import { ISessionTimeFrame } from '@app/modules/book-session/services/types';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { IWorkspaceMember } from '@app/modules/workspaces/types';
import { GuideCalendarEventService } from '@app/screens/guide/guide-sessions/services/events';
import { GuidePublicService, GuideServiceTypes } from '@app/shared/interfaces/services';
import { generateTimezoneOptions, ITimezoneOption } from '@app/shared/utils/generate-timezones';
// eslint-disable-next-line no-restricted-imports
import { toggleCalendarFilterGuide } from '@libs/list/src/store/guide-calendar-filter-store/guide-calendar-filter-store.actions';
import { Store } from '@ngrx/store';

export interface ScheduleSessionServiceOptions {
  sessionsKind: 'individual' | 'group' | 'all';
  shouldServiceChangeOnHost?: boolean;
}

@Injectable()
export class ScheduleSessionService implements OnDestroy {
  defaultDate = {
    date: null,
    timezone: DateTime.local().zoneName
  };
  defaultService = null;

  // State
  // @ts-expect-error TS2345
  readonly clientChange$ = new BehaviorSubject<GuideRelation>(null);
  readonly hostChange$ = new BehaviorSubject<IWorkspaceMember>(this._getDefaultHost());
  readonly sessionChange$ = new BehaviorSubject<IGuideService | null>(null);
  readonly dateChange$ = new BehaviorSubject<{
    date: string;
    timezone?: string | null;
    // TODO ?
    isRecurring?: unknown;
    recurrence?: unknown;
    recurrenceAfterCount?: unknown;
    recurrenceEndType?: unknown;
    recurrenceUntilDate?: unknown;
    // @ts-expect-error TS2345
  } | null>(this.defaultDate);
  readonly messageToClient$ = new BehaviorSubject<unknown>(null);

  // Utils
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _options!: ScheduleSessionServiceOptions;
  private destroy$ = new Subject<void>();

  // Data
  clients: Observable<GuideRelation[]> = this.guideClientsService.users$;
  hosts: Observable<IWorkspaceMember[]> = this.workspacesService.members$.pipe(
    // Exclude team members without workspace role.
    map(members => members.filter(({ role }) => !!role))
  );
  // @ts-expect-error TS2322
  sessions: Observable<GuidePublicService[]> = of(null);

  // Settings
  timeslots: ISessionTimeFrame[] = [];
  timezones: ITimezoneOption[] = generateTimezoneOptions();

  // TODO remove observer
  isTeamAdmin$ = this.workspacesService.isTeamAdmin$;
  isTeamAdmin = this.workspacesService.isTeamAdmin;

  constructor(
    private readonly guideClientsService: GuideClientsService,
    private readonly workspacesService: WorkspacesService,
    private readonly servicesService: GuidePublicServicesService,
    private readonly guideCalendarEventService: GuideCalendarEventService,
    private readonly store$: Store
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  init(options: ScheduleSessionServiceOptions) {
    this._options = options;

    // Handle on state change
    this.handleHostChange();
    this.handleDateChange();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private handleHostChange() {
    // When host value change
    this.hostChange$.pipe(filter(host => !!host)).subscribe(host => {
      // Set list session of host
      this.updateHostServices();

      // Reset values
      if (this._options.shouldServiceChangeOnHost || this._options.shouldServiceChangeOnHost === undefined) {
        // @ts-expect-error TS2345
        this.defaultService = host.userId === this.defaultService?.guideId ? this.defaultService : null;
        this.sessionChange$.next(this.defaultService);
      }

      // @ts-expect-error TS2345
      this.dateChange$.next(this.defaultDate);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private updateHostServices() {
    const { userId, workspaceId }: IWorkspaceMember = this.hostChange$.getValue();

    this.sessions = this.servicesService.getServices(
      // @ts-expect-error TS2345
      workspaceId,
      userId,
      this._options.sessionsKind === 'all' ? undefined : this._options.sessionsKind
    );

    if (userId) {
      this.store$.dispatch(toggleCalendarFilterGuide({ guideId: userId, selected: true }));
    }
  }

  // Always return your current {IWorkspaceMember}
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _getDefaultHost(): IWorkspaceMember {
    // Current user guideId
    const { guideId } = this.workspacesService.workspace;

    // @ts-expect-error TS2322
    return this.workspacesService.members.find(({ userId }) => userId === guideId);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private handleDateChange() {
    // client, service, date

    // eslint-disable-next-line id-length
    const dateChange$ = this.dateChange$.pipe(filter(v => !!v));
    const clientChange$ = this.clientChange$;
    const serviceChange$ = this.sessionChange$;

    // Handle events to update date
    //
    merge(dateChange$, clientChange$, serviceChange$).subscribe(() => {
      if (!this.dateChange$.getValue()) {
        return;
      }

      // @ts-expect-error TS2339
      const { date, timezone } = this.dateChange$.getValue();
      const service: IGuideService & { serviceType: GuideServiceTypes } =
        this.sessionChange$.getValue() as IGuideService & { serviceType: GuideServiceTypes };

      // TODO
      if (!date || !service) {
        return;
      }

      const { userId: guideId, workspaceId } = this.hostChange$.getValue();

      const options: ITimeRangesRequestOptions = { ...this.getRangeRequestOptions(), guideId, workspaceId, timezone };
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.guideCalendarEventService.getFreeTimeRanges$(date, options).subscribe(timeslots => {
        this.timeslots = timeslots;
      });
    });
  }

  private getRangeRequestOptions(): ITimeRangesRequestOptions {
    const client = this.clientChange$.getValue();
    const service: IGuideService & { serviceType: GuideServiceTypes } =
      this.sessionChange$.getValue() as IGuideService & { serviceType: GuideServiceTypes };
    const dateWithZone = this.dateChange$.getValue();

    if (!dateWithZone || !service || !service.id) {
      // @ts-expect-error TS2322
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectDateArgs: any = { ...dateWithZone, duration: service.duration };

    if (
      [GuideServiceTypes.GROUP_SESSION, GuideServiceTypes.WEBINAR].includes(service.type) ||
      [GuideServiceTypes.GROUP_SESSION, GuideServiceTypes.WEBINAR].includes(service.serviceType)
    ) {
      selectDateArgs.templateId = service.id;
    }

    if (client && client.id) {
      selectDateArgs.clientId = client.id;
    }

    return { ...selectDateArgs };
  }
}
