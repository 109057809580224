import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { contactsReducer } from './contacts.reducer';
import { ContactsEffects } from './contacts.effects';
import { CONTACTS_STORE_KEY } from './commons/consts';

@NgModule({
  imports: [StoreModule.forFeature(CONTACTS_STORE_KEY, contactsReducer), EffectsModule.forFeature([ContactsEffects])]
})
export class ContactsStoreModule {}
