import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-external-calendars-accounts-modal',
  templateUrl: './external-calendars-accounts-modal.component.html',
  styleUrls: ['./external-calendars-accounts-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'external-calendars-accounts-modal'
  }
})
export class ExternalCalendarsAccountsModalComponent {
  constructor(readonly modal: NgbActiveModal) {}
}
