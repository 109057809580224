import { GuideServices } from '@app/modules/service-scheduling/types';
import { SubscriptionRecurrency } from '@app/shared/enums/subscription';
import { GuideServiceTypes } from '@app/shared/interfaces/services';
import { normalizeFullName } from '@app/shared/utils/full-name';

import { SessionViewModel } from '../types';

export function mapPackageSessionToVM(
  session: GuideServices.Session,
  parent: GuideServices.RootObject
): SessionViewModel {
  return {
    id: session.sessionTemplateId,
    name: session.template.name,
    disableGuests: session.template.disableGuests,
    price: null,
    type: session.template.serviceType || GuideServiceTypes.GROUP_SESSION,
    seatsPerTimeSlot: session.template.seatsPerTimeSlot,
    sessionType: session.template.sessionType,
    duration: session.template.length,
    recurring: session.template.recurring,
    connectionType: session.template.location.connectionType,
    team: session.template.team.map(data => ({ ...data, fullname: data.guide?.fullname, photo: data.photo })),
    parentService: {
      id: parent.id,
      type: parent.type,
      name: parent.name
    }
  };
}

export function mapProgramModuleToVM(module: GuideServices.Module, parent: GuideServices.RootObject): SessionViewModel {
  return {
    id: module.serviceId!,
    name: module.service!.name,
    disableGuests: module.service!.disableGuests,
    price: null,
    type: module.moduleType as GuideServiceTypes,
    sessionType: module.service!.sessionType,
    seatsPerTimeSlot: module.service!.seatsPerTimeSlot,
    startDate: parent.startDate,
    recurring: module.service!.recurring,
    duration: module.service!.length,
    connectionType: module.service!.location.connectionType,
    team: module?.service!.team.map(data => ({ ...data, fullname: normalizeFullName(data) || '', photo: data.photo })),
    parentService: {
      id: parent.id,
      type: parent.type,
      name: parent.name
    },
    location: module.service?.location
  };
}

export function mapServiceToVM(session: GuideServices.RootObject & { count?: number }): SessionViewModel {
  return {
    id: session.id,
    count: session.count,
    name: session.name,
    type: session.type,
    disableGuests: session.disableGuests,
    price: session?.isFree ? 0 : session.price!,
    subscriptionPrice: session.subscriptionPrice,
    subscriptionRecurrency: session.subscriptionRecurrency as SubscriptionRecurrency,
    totalPayments: session.totalPayments,
    duration: session.duration!,
    hidePrice: session.hidePrice || session.isHiddenPrice,
    sessionType: session.sessionType,
    seatsPerTimeSlot: session.seatsPerTimeSlot,
    connectionType: session.connectionType,
    recurring: session.recurring,
    startDate: session.startDate,
    sessions: session?.sessions,
    team: session.team.map(data => ({
      ...data,
      fullname: data.username || normalizeFullName(data) || '',
      photo: data.photo
    }))
  };
}
