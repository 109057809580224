// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentModule } from '@app/shared/interfaces/programs/client-programs';

@Component({
  selector: 'app-content-module',
  templateUrl: './content-module.component.html',
  styleUrls: ['./content-module.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'content-module'
  }
})
export class ContentModuleComponent {
  @Input()
  // @ts-expect-error TS2564
  module: ContentModule;
}
