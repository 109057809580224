import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiListOption, UiSelectionList } from './selection-list';
import { MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { UiList, UiListItem, UiNavList } from './list';

@NgModule({
  declarations: [UiSelectionList, UiListOption, UiNavList, UiListItem, UiList],
  exports: [UiSelectionList, UiListOption, UiNavList, UiListItem, UiList],
  imports: [CommonModule, MatRippleModule, MatPseudoCheckboxModule]
})
export class UiListModule {}
