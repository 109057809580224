<ng-container *ngIf="serviceHosts; then teamList; else loading"></ng-container>

<ng-template #teamList>
  <div class="team-container">
    <div class="header">
      <div class="header--title">
        <p i18n="@@chooseHost">Choose host</p>
      </div>
    </div>

    <div class="team-list">
      <div
        [attr.data-qa-id]="'service-host-selection-host-item-' + host.id"
        class="team-list-element"
        *ngFor="let host of serviceHosts; trackBy: trackByHost"
        (click)="selectHost(host)">
        <pui-paper appearance="flat">
          <pui-card>
            <pui-card-header>
              <pui-avatar pui-card-header-icon [avatarUrl]="host.photo" [text]="host.firstName"></pui-avatar>

              <span pui-card-header-title>
                <div class="host-and-location">
                  {{ host | userName }}
                  <span class="location" pui-typography="body-s">
                    <pui-icon class="pui-place" svgIcon="pui:place" size="xs" aria-hidden="true"></pui-icon>
                    {{ host.connectionType | sessionPlatform: host.address }}
                  </span>
                </div>
              </span>

              <pui-icon pui-card-header-action svgIcon="pui:chevron-right" size="m"></pui-icon>
            </pui-card-header>
          </pui-card>
        </pui-paper>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loading>
  <app-element-loading [loadingState]="true"></app-element-loading>
</ng-template>
