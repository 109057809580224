import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SessionStatuses } from '@app/shared/enums/session-statuses';

@Component({
  selector: 'app-event-status',
  styleUrls: [`./event-status.component.scss`],
  templateUrl: './event-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventStatusComponent implements OnInit {
  @Input() status!: SessionStatuses;

  @Input() onlyIcon = false;

  @Input() isGuide?: boolean;

  @Input() isSessionPaid?: boolean;

  Statuses = SessionStatuses;

  approvedSessionString!: string;

  ngOnInit(): void {
    if (this.isSessionPaid) {
      this.approvedSessionString = `Confirmed and paid`;
    } else {
      this.approvedSessionString = `Confirmed`;
    }
  }

  isPendingSessionStatus(status: SessionStatuses): SessionStatuses | undefined {
    if (status === SessionStatuses.RESCHEDULE_BY_CLIENT && !this.isGuide) {
      return SessionStatuses.RESCHEDULE_BY_CLIENT;
    }
    if (status === SessionStatuses.RESCHEDULE_BY_GUIDE && this.isGuide) {
      return SessionStatuses.RESCHEDULE_BY_GUIDE;
    }
    if (status === SessionStatuses.GUIDE_OFFER && this.isGuide) {
      return SessionStatuses.GUIDE_OFFER;
    }
    if (status === SessionStatuses.PENDING_APPROVEMENT && !this.isGuide) {
      return SessionStatuses.PENDING_APPROVEMENT;
    }
  }

  isAwaitingSessionStatus(status: SessionStatuses): SessionStatuses | undefined {
    if (status === SessionStatuses.RESCHEDULE_BY_CLIENT && this.isGuide) {
      return SessionStatuses.RESCHEDULE_BY_CLIENT;
    }
    if (status === SessionStatuses.RESCHEDULE_BY_GUIDE && !this.isGuide) {
      return SessionStatuses.RESCHEDULE_BY_GUIDE;
    }
    if (status === SessionStatuses.GUIDE_OFFER && !this.isGuide) {
      return SessionStatuses.GUIDE_OFFER;
    }
    if (status === SessionStatuses.PENDING_APPROVEMENT && this.isGuide) {
      return SessionStatuses.PENDING_APPROVEMENT;
    }
  }
}
