<div class="modal-header no-border">
  <app-button class="closeBtn" (click)="modal.dismiss()" className="close"></app-button>
</div>
<div class="modal-body">
  <p class="notification" [ngSwitch]="type">
    <ng-container i18n="@@paymentWithOuterLinkText" *ngSwitchCase="'paymentWithOuterLink'">
      Thank you, we will contact you via email to confirm payment.
    </ng-container>
  </p>
</div>
