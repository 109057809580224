import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class DisqusWidgetsService {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
  get DISQUSWIDGETS() {
    return this._document.defaultView.DISQUSWIDGETS;
  }

  // @ts-expect-error TS7006
  constructor(@Inject(DOCUMENT) private _document) {}
}
