import { QuizQuestionType } from '@app/core/quizzes/types';
import { getConstantOrderArraysPatch } from '@app/screens/guide/guide-programs/types/helpers';
import { DiffDetails, IDiff } from '../util-types';
import { QuestionOption } from './question-option';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IQuestionDetails {
  id?: number;
  localId: number;
  question: string;
  type: QuizQuestionType;
  options?: QuestionOption[] | null;
}

export class Question implements IDiff<IQuestionDetails> {
  readonly id?: number;
  // @ts-expect-error TS2564
  readonly localId: number;
  // @ts-expect-error TS2564
  readonly question: string;
  // @ts-expect-error TS2564
  readonly type: QuizQuestionType;
  readonly options?: QuestionOption[] | null;

  constructor(questionDetails: Readonly<IQuestionDetails>) {
    if (!questionDetails) {
      throw new Error('Invalid argument');
    }

    Object.entries(questionDetails).forEach(([key, value]) => {
      if (value !== undefined) {
        // @ts-expect-error TS7053
        this[key] = value;
      }
    });
  }

  getDiffFrom(newQuestionValue: Readonly<Question>): DiffDetails<IQuestionDetails> | null {
    const diff: DiffDetails<IQuestionDetails> = {
      id: this.id,
      localId: this.localId
    };

    const optionsDiff = Object.entries(
      getConstantOrderArraysPatch(this.options || [], newQuestionValue.options || [])
    ).reduce((innerDiff, [key, value]) => (value.length ? { ...innerDiff, [key]: value } : innerDiff), {});

    const didQuestionChange = this.question !== newQuestionValue.question;
    const didTypeChange = this.type !== newQuestionValue.type;
    const didOptionsChange = Object.keys(optionsDiff).length;

    if (!didQuestionChange && !didTypeChange && !didOptionsChange) {
      return null;
    }

    if (didQuestionChange) {
      diff.question = newQuestionValue.question;
    }

    if (didTypeChange) {
      diff.type = newQuestionValue.type;
    }

    if (didOptionsChange) {
      diff.options = optionsDiff;
    }

    return diff;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cleanQuestionDetails(dirtyQuestion: Readonly<IQuestionDetails> | Readonly<any>): IQuestionDetails {
  return {
    id: dirtyQuestion.id,
    localId: dirtyQuestion.localId,
    question: (dirtyQuestion.question && dirtyQuestion.question.trim()) || null,
    type: dirtyQuestion.type,
    options: dirtyQuestion.options
  };
}
