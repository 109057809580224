import { NotificationsService } from 'angular2-notifications';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import config from '@app/core/config/config';
import { PublicPackage } from '@app/screens/packages/interfaces';
import { PUBLIC_PACKAGES_ENDPOINT } from '@app/shared/constants/endpoints';

@Injectable()
export class PublicPackagesService {
  private readonly CLIENT_PACKAGES_ENDPOINT = `${config.apiPath}/user/client/packages`;

  constructor(private _http: HttpClient, private _notifications: NotificationsService) {}

  getPackage$(packageId: number): Observable<PublicPackage> {
    return this._http.get<{ package: PublicPackage }>(`${PUBLIC_PACKAGES_ENDPOINT}/${packageId}`).pipe(
      map(data => {
        return data.package;
      }),
      catchError(error => {
        const notFound = error.status === 404;
        return throwError({ ...error, notFound });
      })
    );
  }

  isAlreadyEnrolled$(packageId: number): Observable<boolean> {
    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(`${this.CLIENT_PACKAGES_ENDPOINT}/enrolled/${packageId}`)
        .pipe(map(response => response.isCustomerEnrolled))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activateInvitation$(code: string): Observable<any> {
    return this._http.post(`${this.CLIENT_PACKAGES_ENDPOINT}/invitations`, { code });
  }
}
