import { Pipe, PipeTransform } from '@angular/core';
import { isUnansweredQuestionProgress, QuizProgressQuestion } from '@app/screens/guide/guide-programs/types';

@Pipe({
  name: 'isUnansweredQuestionProgress'
})
export class IsUnansweredQuestionPipe implements PipeTransform {
  transform(question: QuizProgressQuestion): boolean {
    return isUnansweredQuestionProgress(question);
  }
}
