import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, distinctUntilKeyChanged, takeUntil, tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import config from '../config/config';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IZoomAccount {
  email: string;
}

@Injectable()
export class ZapierService implements OnDestroy {
  private readonly ZAPIER_TOKEN_ENDPOINT = `${config.apiPath}/user/common/alternative-connections/zapier/token`;

  private destroy$ = new Subject();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _token$ = new ReplaySubject<any>(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get token$(): Observable<any> {
    return this._token$.asObservable();
  }

  constructor(
    private _http: HttpClient,
    private _notifications: NotificationsService,
    private _workspaceService: WorkspacesService
  ) {
    // ToDo When the workspace is changed, the event fires twice.
    //  This can lead to unexpected behavior in different parts of the platform.
    //  PR-3810 https://profi-io.atlassian.net/browse/PR-3810
    this._workspaceService.activeWorkspace$
      .pipe(distinctUntilKeyChanged('id'), takeUntil(this.destroy$))
      .subscribe(() => this._loadTokenIfExists$());
  }

  ngOnDestroy(): void {
    this._token$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteToken(): Observable<any> {
    return this._http.delete(this.ZAPIER_TOKEN_ENDPOINT).pipe(
      catchError(() => {
        const title = `Failed to remove zapier token`;
        this._notifications.error(title);
        return of(false);
      }),
      tap(disconnected => {
        if (disconnected) {
          this._token$.next(null);
        }
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAToken(): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(this.ZAPIER_TOKEN_ENDPOINT, {}).pipe(
      catchError(() => {
        this._notifications.error(`Failed to create a zapier token`);
        return of(false);
      }),
      tap(token => this._token$.next(token))
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _loadTokenIfExists$(): void {
    this._http
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .get<any>(this.ZAPIER_TOKEN_ENDPOINT)
      .pipe(
        catchError(() => {
          this._notifications.error(`Failed to load zapier token`);
          return of(null);
        })
      )
      .subscribe(token => this._token$.next(token));
  }
}
