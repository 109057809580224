import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';
import { UserNotesModule } from '@app/modules/user-notes/user-notes.module';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { ClientNotesListComponent } from '@app/modules/client-notes/components/client-notes-list/client-notes-widget.component';
import { SharedModule as SharedCoreModule } from '@libs/shared/shared.module';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiIconModule,
  PuiInputModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';

import { ClientNotesWidgetComponent } from './components/client-notes-widget/client-notes-widget.component';
import { ClientAllNotesComponent } from './components/client-all-notes/client-all-notes.component';
import { ClientAllNotesBoardComponent } from './components/client-all-notes-board/client-all-notes-board.component';
import { ClientNoteCardMetaComponent } from './components/client-note-card-meta/client-note-card-meta.component';

@NgModule({
  declarations: [
    ClientNotesWidgetComponent,
    ClientAllNotesComponent,
    ClientAllNotesBoardComponent,
    ClientNoteCardMetaComponent,
    ClientNotesListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    SharedModule,
    NgbModule,
    NgScrollbarModule,
    UserNotesModule,
    PlatformStyleModule,
    PuiAvatarModule,
    PuiTypographyModule,
    PuiInputModule,
    PuiIconModule,
    SharedCoreModule,
    PuiButtonModule
  ],
  exports: [ClientNotesWidgetComponent, ClientAllNotesBoardComponent, ClientNotesListComponent]
})
export class ClientNotesModule {}
