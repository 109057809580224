import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IGuideService,
  IServiceBookingOptions,
  IServiceBookingResult,
  ServiceBookingService
} from '@app/modules/book-service';
import { SessionStatuses } from '@app/shared/enums/session-statuses';
import { GroupSessionModule, ModuleInstructor, SessionModule } from '@app/shared/interfaces/programs/client-programs';
import { SimpleSession } from '@app/shared/interfaces/session';
import { IHaveDateRange } from '@app/shared/utils/date';

import { ModuleSessionStates } from '../../types';
import { convertSessionToIHaveDateRange, detectModuleIndividualSessionState } from '../../utils';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IProgramModuleSessionClientAction {
  id: number;
  title: string;
  svgIcon: string;
  className?: string;
  clickEvent: (instructor: ModuleInstructor) => void;
}

@Component({
  selector: 'app-module-individual-session',
  templateUrl: './module-individual-session.component.html',
  styleUrls: ['./module-individual-session.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'module-individual-session'
  }
})
export class ModuleIndividualSessionComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _moduleSessionState = ModuleSessionStates.CAN_BOOK;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _session!: SimpleSession;

  readonly ModuleSessionStates = ModuleSessionStates;

  readonly SessionStatuses = SessionStatuses;

  readonly bookDisabled$!: Observable<boolean>;

  @Input()
  instructor?: ModuleInstructor;

  @Input()
  // @ts-expect-error TS2564
  module: SessionModule | GroupSessionModule;

  @Input()
  set session(value: SimpleSession) {
    this._session = value;
    this._moduleSessionState = detectModuleIndividualSessionState(value);
  }

  @Output()
  book = new EventEmitter();

  @Output()
  reschedule = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  get moduleSessionsState(): ModuleSessionStates {
    return this._moduleSessionState;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get session(): SimpleSession {
    return this._session;
  }

  get sessionDateRange(): IHaveDateRange | null {
    return this.session != null ? convertSessionToIHaveDateRange(this.session) : null;
  }

  get menuItems(): IProgramModuleSessionClientAction[] {
    const cancelSession = {
      id: 1,
      title: `Cancel session`,
      clickEvent: this.emitCancel.bind(this),
      svgIcon: 'pui:x-circle',
      className: 'delete-btn'
    };
    const rescheduleSession = {
      id: 2,
      title: `Reschedule`,
      clickEvent: this.emitReschedule.bind(this),
      svgIcon: 'pui:rotate-right'
    };

    if (this.module && !this.module.service?.restrictClientBooking) {
      return [rescheduleSession, cancelSession];
    } else {
      return [cancelSession];
    }
  }

  constructor(serviceBooking: ServiceBookingService<IServiceBookingOptions<IGuideService>, IServiceBookingResult>) {
    this.bookDisabled$ = serviceBooking.bookingInProcess$;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitCancel(instructor: ModuleInstructor) {
    this.module.instructor = instructor;
    this.cancel.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitReschedule(instructor: ModuleInstructor) {
    this.module.instructor = instructor;
    this.reschedule.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  emitBook(instructor: ModuleInstructor) {
    this.module.instructor = instructor;
    this.book.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  menuItemsTrackBy(index: number, menuItem: IProgramModuleSessionClientAction) {
    return menuItem.id;
  }

  getDateTime(): IHaveDateRange | null {
    if (
      this.moduleSessionsState !== ModuleSessionStates.NO_STATE &&
      this.moduleSessionsState !== ModuleSessionStates.CAN_BOOK
    ) {
      return this.sessionDateRange;
    }

    return null;
  }

  isSessionMenuAvailable(): boolean {
    const status = this.session.status;
    return status === SessionStatuses.DONE || status === SessionStatuses.IN_PROGRESS_OLD;
  }
}
